let branch = localStorage.getItem('branch')
// let branchs = JSON.parse(branch)

// console.log("locatA",locatA)
let dotaLocat = [
  {
    value: branch,
    label: branch,
  },
];

// console.log("dotaLocat",dotaLocat)

export default dotaLocat;