import { Col, Form, Modal, Row, Button, Card, Divider, Checkbox } from 'antd'
import React, { useRef } from 'react'
import { useReactToPrint } from "react-to-print";
import { PrinterOutlined } from '@ant-design/icons'

//css
import '../css/modaloffer.css'
import logo from '../image/logo.jpg'


function ModalOffer({ open, close }) {
    const [form] = Form.useForm()
    const conponentPDF = useRef();



    const generatePDF = useReactToPrint({
        content: () => conponentPDF.current,
        documentTitle: "CarRate0",
        // onAfterPrint:()=>alert("Data saved in PDF")
    });

    const onChange = (e) => {
        console.log(`checked = ${e.target.value}`);
    };
    const handleCancel = () => {
        close(false);
    };

    const handleSubmit = () => {

    }

    return (
        <>
            <Modal
                open={open}
                onCancel={handleCancel}
                width={900}
                footer={[
                    <Button
                        style={{ margin: '5px', background: '#cf1322', color: '#ffffff' }}
                        icon={<PrinterOutlined />}
                        onClick={handleCancel}>
                        ยกเลิก
                    </Button>,
                    <Button
                        style={{ margin: '5px', background: '#597ef7', color: '#ffffff' }}
                        icon={<PrinterOutlined />}
                        onClick={generatePDF}>
                        พิมพ์
                    </Button>,

                ]}
            >
                <div ref={conponentPDF}>
                    <Form
                        name="basic"
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}
                        style={{
                            maxWidth: '100%',
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        form={form}
                        onFinish={handleSubmit}
                        autoComplete="off"
                    >
                        <Row justify={'center'} gutter={32}>
                            <Col className='gutter-row' span={4}>
                                เล่มที่
                            </Col>
                            <Col className='gutter-row' span={5} style={{ textAlign: 'right' }}>
                                <img src={logo} alt="logo" style={{ width: '50px' }} />
                            </Col>
                            <Col className='gutter-row' span={9} style={{ textAlign: 'center' }}>
                                <h2 style={{ margin: 0, textAlign: 'left' }}><b>บริษัทวัน ลิสซิ่ง จำกัด</b></h2>
                                <p style={{ margin: 0, textAlign: 'left', color: '#061178' }}> <b>ONE LEASING CO.,LTD.</b></p>
                            </Col>
                            <Col className='gutter-row' span={4}>
                                เลขที่
                            </Col>
                        </Row>
                        <Divider style={{ margin: '5px' }} />
                        <Row gutter={32} >
                            <Col span={24} className='gutter-row' >
                                <Row gutter={32} style={{ margin: 3 }}>
                                    <Col span={16} className='gutter-row' style={{ textAlign: 'left' }}>
                                        <Form.Item style={{ margin: 2 }}>
                                            {/* <b>เลขที่สัญญา</b> */}
                                            เลขที่สัญญา
                                        </Form.Item>
                                    </Col>
                                    <Col span={8} className='gutter-row' style={{ textAlign: 'left' }}>
                                        <Form.Item style={{ margin: 2 }}>
                                            {/* <b>วันที่</b> */}
                                            วันที่
                                        </Form.Item>
                                    </Col>
                                    <Col span={16} className='gutter-row' style={{ textAlign: 'left' }}>
                                        <Form.Item style={{ margin: 2 }}>
                                            {/* <b>ชื่อ-สกุลผู้เช่าซื้อ</b> */}
                                            ชื่อ-สกุลผู้เช่าซื้อ
                                        </Form.Item>
                                    </Col>
                                    <Col span={8} className='gutter-row' style={{ textAlign: 'left' }}>
                                        <Form.Item style={{ margin: 2 }}>
                                            {/* <b>เลขที่บัตร</b> */}
                                            เลขที่บัตร
                                        </Form.Item>
                                    </Col>
                                    <Col span={16} className='gutter-row' style={{ textAlign: 'left' }}>
                                        <Form.Item style={{ margin: 2 }}>
                                            {/* <b>ชื่อ-สกุลผู้ค้ำ 1.</b> */}
                                            ชื่อ-สกุลผู้ค้ำ 1.
                                        </Form.Item>
                                    </Col>
                                    <Col span={8} className='gutter-row' style={{ textAlign: 'left' }}>
                                        <Form.Item style={{ margin: 2 }}>
                                            {/* <b>เลขที่บัตร</b> */}
                                            เลขที่บัตร
                                        </Form.Item>
                                    </Col>
                                    <Col span={16} className='gutter-row' style={{ textAlign: 'left' }}>
                                        <Form.Item style={{ margin: 2 }}>
                                            {/* <b>ชื่อ-สกุลผู้ค้ำ 2.</b> */}
                                            ชื่อ-สกุลผู้ค้ำ 2.
                                        </Form.Item>
                                    </Col>
                                    <Col span={8} className='gutter-row' style={{ textAlign: 'left' }}>
                                        <Form.Item style={{ margin: 2 }}>
                                            {/* <b>เลขที่บัตร</b> */}
                                            เลขที่บัตร
                                        </Form.Item>
                                    </Col>
                                    <Col span={16} className='gutter-row' style={{ textAlign: 'left' }}>
                                        <Form.Item style={{ margin: 2 }}>
                                            {/* <b>ชื่อ-สกุลผู้ค้ำ 3.</b> */}
                                            ชื่อ-สกุลผู้ค้ำ 3.
                                        </Form.Item>
                                    </Col>
                                    <Col span={8} className='gutter-row' style={{ textAlign: 'left' }}>
                                        <Form.Item style={{ margin: 2 }}>
                                            {/* <b>เลขที่บัตร</b> */}
                                            เลขที่บัตร
                                        </Form.Item>
                                    </Col>

                                    <Col span={16} className='gutter-row' style={{ textAlign: 'left' }}>
                                        <Form.Item style={{ margin: 2 }}>
                                            {/* <b>ชื่อ-สกุลผู้ค้ำ 4.</b> */}
                                            ชื่อ-สกุลผู้ค้ำ 4.
                                        </Form.Item>
                                    </Col>
                                    <Col span={8} className='gutter-row' style={{ textAlign: 'left' }}>
                                        <Form.Item style={{ margin: 2 }}>
                                            {/* <b>เลขที่บัตร</b> */}
                                            เลขที่บัตร
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Card style={{ width: '100%', marginTop: '5px' }}>
                                    <Row gutter={32} style={{ margin: 0 }}>
                                        {/* {productTypeId ?
                                        <>

                                        </>
                                        :
                                        <>
                                            
                                        </>
                                        } */}
                                        <Col span={8} className='gutter-row'>
                                            <Form.Item style={{ margin: 2 }}>
                                                <u>ข้อมูล</u> ยี่ห้อ/โฉนดที่ดิน
                                            </Form.Item>
                                            <Form.Item style={{ margin: 2 }}>
                                                สี
                                            </Form.Item>
                                            <Form.Item style={{ margin: 2 }}>
                                                วันจดทะเบียน
                                            </Form.Item>
                                        </Col>
                                        <Col span={8} className='gutter-row'>
                                            <Form.Item style={{ margin: 2 }}>
                                                รุ่น / แบบ
                                            </Form.Item>
                                            <Form.Item style={{ margin: 2 }}>
                                                ทะเบียน / เลขที่ดิน
                                            </Form.Item>
                                            <Form.Item style={{ margin: 2 }}>
                                                เลขตัวรถ / เลขโฉนด
                                            </Form.Item>
                                        </Col>
                                        <Col span={8} className='gutter-row'>
                                            <Form.Item style={{ margin: 2 }}>
                                                ประเภท
                                            </Form.Item>
                                            <Form.Item style={{ margin: 2 }}>
                                                จังหวัด
                                            </Form.Item>
                                            <Form.Item style={{ margin: 2 }}>
                                                เลขเครื่อง / ระวาง
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Card>
                                <Row gutter={32} style={{ margin: 3 }}>
                                    <Col style={{ textAlign: 'center' }}>
                                        <Checkbox.Group>
                                            <Checkbox value={1} isPacked={true} onChange={onChange}>ย้ายไฟแนนซ์</Checkbox>
                                            <Checkbox value={2} isPacked={true} onChange={onChange}>ถือเล่มมา</Checkbox>
                                            <Checkbox value={3} isPacked={true} onChange={onChange}>ปรับโครงสร้าง</Checkbox>
                                            <Checkbox value={4} isPacked={true} onChange={onChange}>ซื้อ - ขาย</Checkbox>
                                            <Checkbox value={5} isPacked={true} onChange={onChange}>ค้ำ - ไม่โอน</Checkbox>
                                            <Checkbox value={6} isPacked={true} onChange={onChange}>ค้ำ - โอน</Checkbox>
                                            <Checkbox value={7} isPacked={true} onChange={onChange}>ไม่ค้ำ - ไม่โอน</Checkbox>
                                            <Checkbox value={8} isPacked={true} onChange={onChange}>ไม่ค้ำ - โอน</Checkbox>
                                        </Checkbox.Group>
                                    </Col>
                                </Row>
                                <Row gutter={32} style={{ marginTop: 5, marginLeft: 5 }}>
                                    <Col span={24} className='gutter-row'>
                                        <b style={{ fontSize: '15px' }}><u>ค่าใช้จ่ายและเงื่อนไขการผ่อนชำระ</u></b>
                                    </Col>
                                </Row>
                                <Row gutter={32} style={{ margin: 3 }}>
                                    <Col span={9} className='gutter-row'>
                                        <Form.Item style={{ margin: 2 }}>
                                            ย้ายไฟแนนซ์เดิม
                                            <b style={{ marginLeft: '20px', }}><u>50,000</u></b>
                                        </Form.Item>
                                        <Form.Item style={{ margin: 2 }}>
                                            ยอดปิด
                                            <b style={{ marginLeft: '20px', }}><u>50,000</u></b>
                                        </Form.Item>
                                        <Form.Item style={{ margin: 2 }}>
                                            ค่าจัด-โอน
                                            <b style={{ marginLeft: '20px', }}><u>1000</u></b>
                                        </Form.Item>
                                        <Form.Item style={{ margin: 2 }}>
                                            ค่าอนุมัติ/บริการ
                                            <b style={{ marginLeft: '20px', }}><u>1000</u></b>
                                        </Form.Item>
                                        <Form.Item style={{ margin: 2 }}>
                                            ค่าน้ำมัน
                                            <b style={{ marginLeft: '20px', }}><u>1000</u></b>
                                        </Form.Item>
                                        <Form.Item style={{ margin: 2 }}>
                                            ค่าทางด่วนที่ดิน
                                            <b style={{ marginLeft: '20px', }}><u>1000</u></b>
                                        </Form.Item>
                                        <Form.Item style={{ margin: 2 }}>
                                            ค่าเลี่ยงประกัน
                                            <b style={{ marginLeft: '20px', }}><u>1000</u></b>
                                        </Form.Item>
                                        <Form.Item style={{ margin: 2 }}>
                                            ค่าแจ้งย้าย
                                            <b style={{ marginLeft: '20px', }}><u>1000</u></b>
                                        </Form.Item>
                                        <Form.Item style={{ margin: 2 }}>
                                            ค่าฝากตรวจ
                                            <b style={{ marginLeft: '20px', }}><u>1000</u></b>
                                        </Form.Item>
                                        <Form.Item style={{ margin: 2 }}>
                                            ค่าภาษี+พรบ.
                                            <b style={{ marginLeft: '20px', }}><u>1000</u></b>
                                        </Form.Item>
                                        <Form.Item style={{ margin: 2 }}>
                                            ยอดคงเหลือ
                                            <b style={{ marginLeft: '20px', }}><u>1000</u></b>
                                        </Form.Item>
                                    </Col>
                                    <Col span={3} className='gutter-row'>
                                        <Form.Item style={{ margin: 2 }}>
                                            {/* <b>บาท</b> */}
                                        </Form.Item>
                                        <Form.Item style={{ margin: 2 }}>
                                            บาท
                                        </Form.Item>
                                        <Form.Item style={{ margin: 2 }}>
                                            บาท
                                        </Form.Item>
                                        <Form.Item style={{ margin: 2 }}>
                                            บาท
                                        </Form.Item>
                                        <Form.Item style={{ margin: 2 }}>
                                            บาท
                                        </Form.Item>
                                        <Form.Item style={{ margin: 2 }}>
                                            บาท
                                        </Form.Item>
                                        <Form.Item style={{ margin: 2 }}>
                                            บาท
                                        </Form.Item>
                                        <Form.Item style={{ margin: 2 }}>
                                            บาท
                                        </Form.Item>
                                        <Form.Item style={{ margin: 2 }}>
                                            บาท
                                        </Form.Item>
                                        <Form.Item style={{ margin: 2 }}>
                                            บาท
                                        </Form.Item>
                                        <Form.Item style={{ margin: 2 }}>
                                            บาท
                                        </Form.Item>
                                    </Col>
                                    <Col span={9} className='gutter-row'>
                                        <Form.Item style={{ margin: 2, fontSize: '20px' }}>
                                            <b style={{ fontSize: '20px' }}>ยอดจัด</b>
                                            <b style={{ marginLeft: '20px', fontSize: '20px' }}><u>1000</u></b>
                                        </Form.Item>
                                        <Form.Item style={{ margin: 2 }}>
                                            ดอกเบี้ย
                                            <b style={{ marginLeft: '20px' }}><u>12.06</u></b>
                                        </Form.Item>
                                        <Form.Item style={{ margin: 2 }}>
                                            จำนวนงวด
                                            <b style={{ marginLeft: '20px' }}><u>36</u></b>
                                        </Form.Item>
                                        <Form.Item style={{ margin: 2 }}>
                                            งวดละ
                                            <b style={{ marginLeft: '20px' }}><u>36</u></b>
                                        </Form.Item>
                                        <Form.Item style={{ margin: 2 }} >
                                            รวมราคา
                                            <b style={{ marginLeft: '20px' }}><u>40,000</u></b>

                                        </Form.Item>
                                        <Form.Item style={{ margin: 2 }} >
                                            ส่งงวดทุกวันที่
                                            <b style={{ marginLeft: '20px' }}><u>10/10/2566</u></b>
                                        </Form.Item>
                                        <Form.Item style={{ margin: 2 }}>
                                            ส่งงวดแรกวันที่
                                            <b style={{ marginLeft: '20px' }}><u>10/10/2566</u></b>
                                        </Form.Item>
                                        <Form.Item style={{ margin: 2, fontSize: '20px' }}>
                                            <b style={{ fontSize: '18px' }}><u>ค่าแนะนำ</u></b>
                                        </Form.Item>
                                        <Form.Item style={{ margin: 2 }}>
                                            รหัสลูกค้า
                                            <b style={{ marginLeft: '20px' }}><u>34234234</u></b>
                                        </Form.Item>
                                        <Form.Item style={{ margin: 2 }}>
                                            ชื่อ-สกุล
                                            <b style={{ marginLeft: '20px' }}><u>KOKO</u></b>
                                        </Form.Item>
                                        <Form.Item style={{ margin: 2 }}>
                                            จำนวนเงิน
                                            <b style={{ marginLeft: '20px' }}><u>20,000</u></b>
                                        </Form.Item>
                                        <Form.Item style={{ margin: 2 }}>
                                            ผู้อนุมัติ
                                            <b style={{ marginLeft: '20px' }}><u>admin</u></b>
                                        </Form.Item>
                                    </Col>
                                    <Col span={3} className='gutter-row'>
                                        <Form.Item style={{ margin: 2 }}>

                                        </Form.Item>
                                        <Form.Item style={{ margin: 2 }}>
                                            +VAT
                                        </Form.Item>
                                        <Form.Item style={{ margin: 2 }}>
                                            งวด
                                        </Form.Item>
                                        <Form.Item style={{ margin: 2 }}>
                                            บาท
                                        </Form.Item>
                                        <Form.Item style={{ margin: 2 }}>
                                            บาท
                                        </Form.Item>
                                        <Form.Item style={{ margin: 2 }}>
                                            {/*บาท */}
                                        </Form.Item>
                                        <Form.Item style={{ margin: 2 }}>
                                            {/*บาท */}
                                        </Form.Item>
                                        <Form.Item style={{ margin: 2 }}>
                                            {/*บาท */}
                                        </Form.Item>
                                        <Form.Item style={{ margin: 2 }}>
                                            {/*บาท */}
                                        </Form.Item>
                                        <Form.Item style={{ margin: 2 }}>
                                            {/*บาท */}
                                        </Form.Item>
                                        <Form.Item style={{ margin: 2 }}>
                                            บาท
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={32} style={{ margin: 3 }}>
                                    <Card className='gutter-row' style={{ width: '100%', margin: 0 }}>
                                        <Row gutter={32}>
                                            <b style={{ margin: 2 }}>
                                                <u>หมายเหตุ</u>
                                            </b>
                                        </Row>
                                        <Row gutter={32}>
                                            asdadsadsadsdasdasd
                                        </Row>
                                    </Card>
                                </Row>
                                <Row gutter={32} style={{ margin: 3 }}>
                                    <Col className='gutter-row' span={6}>
                                        <Form.Item label='การตลาด'>
                                            asdasdsadsdasd
                                        </Form.Item>
                                    </Col>
                                    <Col className='gutter-row' span={6}>
                                        <Form.Item label='ผู้เสนอ'>
                                            asdasdasdasdasd
                                        </Form.Item>
                                    </Col>
                                    <Col className='gutter-row' span={6}>
                                        <Form.Item label='ผู้อนุมัติ'>
                                            asdasdasdasdasda
                                        </Form.Item>
                                    </Col>
                                    <Col className='gutter-row' span={6}>
                                        <Form.Item label='ผู้ตรวจ'>
                                            asdasdasdasdasd
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Modal>
        </>
    )
};

export default ModalOffer
