import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Layout, Drawer, Affix, Button, theme, Row, Col } from "antd";
import Sidenav from "./Sidenav";
import Headers from "./Header";
// import Footer from "./Footer";
import Contents from "./Contents";
import { Link } from 'react-router-dom';
import './SlideNav.css'
import drawerHeader from "../../assets/images/drawable-header.png";


import { COLOR } from "../../utils/theme";
import { CloseOutlined, MenuOutlined } from "@ant-design/icons";

const { Header: Content, Sider } = Layout;

function Main() {
  const [visible, setVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState("right");
  const [placementSidenav, setPlacementSidenav] = useState("left");
  // const [sidenavColor, setSidenavColor] = useState(COLOR.primary);
  const [sidenavType, setSidenavType] = useState("transparent");
  const [fixed, setFixed] = useState(false);

  const openDrawer = () => setVisible(!visible);
  const openDrawerSidenav = () => setOpen(!open);
  const handleSidenavType = (type) => setSidenavType(type);
  // const handleSidenavColor = (color) => setSidenavColor(color);
  const handleFixedNavbar = (type) => setFixed(type);

  let { pathname } = useLocation();
  pathname = pathname.replace("/", "");

  const [titleName, setTitleName] = useState("หน้าแรก");
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);
  // const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  // const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [openNaves, setOpenNaves] = useState(false);

  // console.log("กว้าง", screenWidth);
  // console.log("สูง", screenHeight);
  useEffect(() => {
    // console.log(titleName);

    if (pathname === "rtl") {
      setPlacement("left");
      setPlacementSidenav("right");
    } else {
      setPlacement("right");
      setPlacementSidenav("left");
    }
    // const handleResize = () => {
    //   setScreenWidth(window.innerWidth);
    //   setScreenHeight(window.innerHeight);
    // };


    // window.addEventListener('resize', handleResize);

    // return () => {
    //   window.removeEventListener('resize', handleResize);
    // };

  }, [pathname]);

  const openNav = () => {
    setOpenNaves(true)

    console.log("เปิด")
    document.getElementById("mySidenav").style.width = "260px";
    document.getElementById("main").style.marginLeft = "260px";
  }
  const closeNav = () => {
    setOpenNaves(false)
    console.log("ปิด")
    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("main").style.marginLeft = "0";
  }

  const Click = () => {
    {
      openNaves === false ? openNav() : closeNav()
    }

  }

  return (
    <>
      <Layout
        className={`layout-dashboard ${pathname === "profile" ? "layout-profile" : ""
          } ${pathname === "rtl" ? "layout-dashboard-rtl" : ""}`}
      >
        <div
          id="mySidenav"
          className="sidenav"
        >
          <Row gutter={32} justify={'center'} align={'middle'}>
            <Col span={24} style={{ textAlign: 'center', }}>
              <img src={drawerHeader} width={260} height={80} alt="" />
            </Col>
          </Row>
          <Row justify={'center'}>
            <Col span={24}>
              <Sidenav onClick={setTitleName} />
            </Col>
          </Row>
        </div>

        <div id="main">
          {/* <Row gutter={18} style={{ marginBottom: '15px' }}> */}
          {/* <Col span={2} style={{ fontSize: '25px', color: 'black', textAlign: 'center' }} >
              <MenuOutlined onClick={Click} />
            </Col> */}
          {/* <Col span={22} style={{ textAlign: 'right', }}> */}

          <Headers className="stick"
            name={<MenuOutlined style={{ color: 'white', }} onClick={Click} />}
            onPress={showSidebar}
            subName={<b style={{ color: 'white', }}>{titleName}</b>}
            // subName={titleName}
            // handleSidenavColor={handleSidenavColor}
            handleSidenavType={handleSidenavType}
            handleFixedNavbar={handleFixedNavbar}
          >

          </Headers>
          {/* </Col> */}
          {/* </Row> */}
          {<Contents className="mains" />}
        </div>
      </Layout >
    </>
  )
}

export default Main
