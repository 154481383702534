export const FIFTEENPERCENT = [
  //  installment จำนวนผ่อน
  //  interest ดอกเบี้ย
  {
    installment: 12,
    interest: 0.090258312,
  },
  {
    installment: 18,
    interest: 0.062384787,
  },
  {
    installment: 24,
    interest: 0.048486648,
  },
  {
    installment: 30,
    interest: 0.040178543,
  },
  {
    installment: 36,
    interest: 0.034665329,
  },
  {
    installment: 42,
    interest: 0.030749061,
  },
  {
    installment: 48,
    interest: 0.027830748,
  },
  {
    installment: 54,
    interest: 0.025577601,
  },
  {
    installment: 60,
    interest: 0.02378993,
  },
  {
    installment: 66,
    interest: 0.022340647,
  },
  {
    installment: 72,
    interest: 0.021145013,
  },
  {
    installment: 78,
    interest: 0.020144356,
  },
  {
    installment: 84,
    interest: 0.019296755,
  },
  {
    installment: 90,
    interest: 0.018571461,
  },
  {
    installment: 96,
    interest: 0.017945405,
  },
  {
    installment: 102,
    interest: 0.017400941,
  },
  {
    installment: 108,
    interest: 0.016924337,
  },
  {
    installment: 114,
    interest: 0.016504749,
  },
  {
    installment: 120,
    interest: 0.016133496,
  },
]

export const TWENTYFOURPERCENT = [
  {
    installment: 12,
    interest: 0.094559597,
  },
  {
    installment: 18,
    interest: 0.066702102,
  },
  {
    installment: 24,
    interest: 0.052871097,
  },
  {
    installment: 30,
    interest: 0.044649922,
  },
  {
    installment: 36,
    interest: 0.039232853,
  },
  {
    installment: 42,
    interest: 0.035417295,
  },
  {
    installment: 48,
    interest: 0.032601836,
  },
  {
    installment: 54,
    interest: 0.030452262,
  },
  {
    installment: 60,
    interest: 0.028767966,
  },
  {
    installment: 66,
    interest: 0.027421223,
  },
  {
    installment: 72,
    interest: 0.026326831,
  },
  {
    installment: 78,
    interest: 0.025425759,
  },
  {
    installment: 84,
    interest: 0.024675812,
  },
  {
    installment: 90,
    interest: 0.024046017,
  },
  {
    installment: 96,
    interest: 0.023513127,
  },
  {
    installment: 102,
    interest: 0.023059353,
  },
  {
    installment: 108,
    interest: 0.022670848,
  },
  {
    installment: 114,
    interest: 0.022336679,
  },
  {
    installment: 120,
    interest: 0.022048097,
  },
]
