import React, { useEffect, useState } from 'react'
import { Button, Form, Card, Input, Col, Row, Spin, Space, Divider, Table } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import { SearchOutlined } from '@ant-design/icons';
// import { useDispatch } from 'react-redux';

export default function Main() {
  // const [form] = Form.useForm()
  // const dispatch = useDispatch()
  // const token = localStorage.getItem('token');
  const branch = localStorage.getItem('branch');
  // const [api, contextHolder] = notification.useNotification();
  // const [axiosData, setAxiosData] = useState([]);
  const [arrayTable, setArrayTable] = useState();
  const [dataPost, setDataPost] = useState({ day1: "2015-01-01", day2: "", isu: 0, searchType: "", contno: "" })
  const [loading, setLoading] = useState(false)
  // const [dataCont, setDataCont] = useState()
  // const [modalCont, setModalCont] = useState(false)
  // const { Countdown } = Statistic;

  useEffect(() => {
    console.log("api search-contno-db2")
    // loadData()
  }, [])

  const onSearch = async () => {
    if (branch === "MIT" || branch === "UD" || branch === "LEX" || branch === "S4") {
      if (dataPost?.contno) {
        setLoading(true)
        setTimeout(async () => {
          // const tk = JSON.parse(token)
          // const headers = {
          //   "Authorization": `Bearer ${tk}`,
          //   "Menu": JSON.stringify("5")
          // }
          var mixData = { contno: dataPost.contno }
          console.log("mixData", mixData)
          // await axios.post(loadContMKLeaving, mixData, { headers: headers })
          await axios.post("https://api-search-contno-db2-i67f6gaaqa-as.a.run.app/api-db2/check-cont-issu", mixData)
            .then(async (res) => {
              console.log("res.data Cont", res.data)
              if (res.data) {
                setArrayTable(res.data)
                // setAxiosData(res.data)
              } else {
                setArrayTable([])
                // setAxiosData([])
              }
            }).catch((err) => {
              console.log(err)
              alert("ไม่พบข้อมูล !!!")
            })
          setLoading(false)
        }, 2000)
      } else {
        alert("กรุณากรอกเลขสัญญา !!!")
      }
    } else {
      alert("คุณไม่มีสิทธิ์ใช้งานเมนูนี้ !")
    }
  }
  const currencyFormatOne = (amount) => {
    if (amount) {
      return Number(amount)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    } else {
      return "-"
    }
  }
  const columns = [
    {
      title: "ลำดับ",
      dataIndex: "index",
      key: 'index',
      align: 'center',
      //   fixed: true,
      width: "10%",
      render: (text, object, index) => index + 1
    },
    {
      title: "วันที่",
      dataIndex: "SDATE",
      width: "10%",
      align: 'center',
      render: (text, record) => (
        <Space size="middle">
          {
            record.SDATE !== "" ?
              <Space>
                <>{dayjs(record.SDATE).format("DD-MM-YYYY")}</>
              </Space>
              : null
          }
        </Space>
      ),
      defaultSortOrder: 'descend',
    },
    {
      title: "รายละเอียดสัญญา",
      dataIndex: "CONTNO",
      key: 'CONTNO',
      align: 'center',
      width: "30%",
      render: (text, record) => (
        <>
          <div>สาขา: {record.LOCAT}</div>
          <div>เลขสัญญา: {record.CONTNO}</div>
          <div>การตลาด: <Space style={{ color: "red" }}><b>{record.SALCOD}</b></Space></div>
          {
            record.ISSUNO !== "" ?
              <div>มาจากเลขสัญญา: {record.ISSUNO}</div>
              : null
          }
        </>
      ),
    },
    {
      title: "ชื่อ-สกุล(ผู้กู้)",
      dataIndex: "SNAM",
      key: 'SNAM',
      align: 'center',
      width: "30%",
      render: (text, record) => (
        <>
          <div>ชื่อ: {record.SNAM} {record.NAME1} {record.NAME2}</div>
          <div>เกรด: {record.GRDCOD}</div>
        </>
      ),
    },
    {
      title: "รายละเอียดขอกู้",
      dataIndex: "balanc",
      align: 'center',
      width: "30%",
      render: (text, record) => (
        <>
              <div>ราคาเช่าซื้อครั้งที่แล้ว: {currencyFormatOne(record.balanc)}</div>
              <div>งวด: {currencyFormatOne(record.T_NOPAY)}</div>
              <div>ยอดที่ชำระมาแล้วทั้งหมด: {currencyFormatOne(record.smpay)}</div>
              <div>จำนวนงวดที่ผ่อนมาแล้ว: {currencyFormatOne(record.SMPAYTOT_UPAY)}</div>
        </>
      ),
    },
    {
      title: "Action",
      key: 'Action',
      align: 'center',
      width: "30%",
      render: (text, record) => {
        return (
          <>
            <Space>
              <Button
                type="primary"
                style={{ backgroundColor: "green", width: "50px" }}
                icon={<SearchOutlined />}
                onClick={() => {
                  // setModalCont(true)
                  // setDataCont(record)
                }}
              >
              </Button>
            </Space>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Card style={{ height: '100%' }}>
        <Row>
          <Col span={24}>
            <div className='text-center'>
              <h2>เช็คสัญญา(ทดสอบ)</h2>
            </div>
          </Col>
          <Divider />
          <Col span={24} style={{ textAlign: 'center' }}>
            <Spin spinning={loading} size='large' tip="Loading...">
              <Space>
                <Form.Item label="กรอกเลขสัญญา">
                  <Input onChange={(e) => setDataPost({ ...dataPost, contno: e.target.value })}></Input>
                </Form.Item>
                <Form.Item label="">
                  <Button type='primary' onClick={onSearch} icon={<SearchOutlined />}>ค้นหา</Button>
                </Form.Item>
              </Space>
            </Spin>
          </Col>
          <Table
            dataSource={arrayTable}
            columns={columns}
            scroll={{
              x: 1500,
              y: 400,
            }}
            style={{ padding: 20 }}
          >
          </Table>
        </Row>
      </Card>
    </>
  )
}
