import React, { useState, useEffect } from 'react'
import { Button, Form, Input, Select, Divider, Row, Col, Modal, Radio, Space, Spin } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import { addphoneandcus } from '../../../file_mid/all_api';

function ModalAddPhone({ open, close, fucnAdd, shootdata, notification3, notification4, sendback }) {

    const [form] = Form.useForm();
    const [idCus, setIdCus] = useState({ customerId: shootdata?.customerId })
    const [Phone, setPhone] = useState({});
    const [loading, setLoading] = useState(false);
    const handleCancel = () => {
        close(false);
    };

    // console.log("shootdata",shootdata)

    const handleSubmit = async () => {
        setLoading(true)
        const reult = { customer: idCus, phone: [{ ...Phone }] }
        // console.log("reult",reult)
        await axios.post(addphoneandcus, reult)
            .then(res => {
                if (res.status === 200) {
                    fucnAdd({ Phone })
                    sendback(dayjs())
                    notification3('top')
                    setTimeout(() => {
                        setLoading(false)
                        close(false)
                    }, [1500])
                }
            }).catch((err) => {
                if (err.response.request.status === 400) {
                    notification4('top')
                    setLoading(false)
                }
                console.log("err", err)
            })
        setLoading(false)
    }
    return (
        <>
            <Modal
                open={open}
                centered
                onCancel={handleCancel}
                width={600}
                footer={[null]}>
                <Spin spinning={loading} size='large' tip=" Loading... ">
                    <Form
                        form={form}
                        name='fucnAdd'
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                        style={{
                            maxWidth: '100%',
                        }}
                        onFinish={handleSubmit}>
                        <Row>
                            <Col span={24} style={{ fontSize: '30px', textAlign: 'center' }} ><u>เพิ่มช่องทางการติดต่อ</u></Col>
                        </Row>
                        <Divider />
                        <Row gutter={{ sx: 8, sm: 16, md: 24, lg: 32 }} >
                            <Col className='gutter-row' span={24}>
                                <Form.Item label='ชนิด' name='phoneType'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <Select
                                        placeholder={'เลือก'}
                                        onChange={(value) => setPhone({ ...Phone, phoneType: value })}
                                        options={[
                                            {
                                                label: 'เบอร์มือถือ',
                                                value: 'เบอร์มือถือ',
                                            },
                                            {
                                                label: 'เบอร์ที่ทำงาน',
                                                value: 'เบอร์ที่ทำงาน',
                                            },
                                            {
                                                label: 'เบอร์บ้าน',
                                                value: 'เบอร์บ้าน',
                                            }
                                        ]} />
                                </Form.Item>
                                <Form.Item label="เบอร์" name='telp'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]} >
                                    <Input onChange={(e) => setPhone({ ...Phone, telp: e.target.value })} />
                                </Form.Item>
                                <Form.Item label="ส่ง sms" name="sendSms"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <Radio.Group onChange={(e) => setPhone({ ...Phone, sendSms: e.target.value })} >
                                        <Radio value="1"> ส่ง </Radio>
                                        <Radio value="0"> ไม่ส่ง </Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item label="หมายเหตุ" name='note' >
                                    <Input onChange={(e) => setPhone({ ...Phone, note: e.target.value })} />
                                </Form.Item>
                                <Divider />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} style={{ textAlign: 'center' }}>
                                <Space>
                                    <Button type="primary" htmlType="submit" style={{ background: "green" }}  >บันทึก</Button>
                                    <Button type="primary" onClick={handleCancel} style={{ background: "red" }}>ยกเลิก</Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        </>
    )
};
export default ModalAddPhone
