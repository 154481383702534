 //caseSuccess   //nomder
  const datacolumns = [
    {
      title: "ลำดับ",
      dataIndex: "key",
      key: "key",
      render: (text, record, index) => index + 1,
    },
    {
      title: "สาขา",
      dataIndex: "locat",
      key: "locat",
      sorter: (a, b) => a.locat.localeCompare(b.locat),
      defaultSortOrder: 'ascend',
    },
    {
      title: "เคสผ่าน",
      dataIndex: "caseSuccess",
      key: "caseSuccess",
    },
    {
      title: "เคสไม่จบ",
      dataIndex: "caseFail",
      key: "caseFail",
    },
    {
      title: "REJECT/ไม่ผ่าน",
      dataIndex: "caseReject",
      key: "caseReject",
    },
    {
      title: "รวมส่งมาเคาะ/ สาขา",
      dataIndex: "caseAll",
      key: "caseAll",
    },
    {
      title: "PRE",
      dataIndex: "casePre",
      key: "casePre",
    },
    {
      title: "คิดเป็น % ",
      dataIndex: "casePercent",
      key: "casePercent",
    },
  ];
export default datacolumns;
