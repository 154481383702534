import React from 'react';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Divider, Row, Col, Card, Modal, Space, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addguarantors } from '../../../file_mid/all_api';

export default function GEDetail({ close, count, setCount, next, prev, fucnEdit, saveclose, getData, dataindex, cha, chp, cusIdMain, dataGua }) {

    const { customers } = useSelector((state) => ({ ...state }))
    const { Addresss } = useSelector((state) => ({ ...state }))
    const { Phones } = useSelector((state) => ({ ...state }))

    const [dataCustomer, setDataCustomer] = useState({})
    const [preDataCustomer, setPreDataCustomer] = useState({ ...getData, customerId2: getData.customerId, customerId: cusIdMain })
    const [dataAddress, setDataAddresss] = useState([])
    const [dataPhones, setDataPhones] = useState([])

    const [getIndex, setGetIndex] = useState(dataindex);
    const [dataMix, setDataMix] = useState({})

    const { confirm } = Modal
    const [form] = Form.useForm();

    useEffect(() => {
        if (customers.data.customerId === undefined) {
            // setPreDataCustomer({...getData, customerId2: getData.customerId, customerId: cusIdMain})
            setDataCustomer({ ...getData, customerId2: getData.customerId, customerId: cusIdMain })
        } else if (customers.data.customerId !== undefined) {
            setDataCustomer(customers.data)
        }
        if (Addresss.data === undefined) {
            setDataAddresss(getData.dataAddress)
        } else if (Addresss.data !== undefined) {
            setDataAddresss(Addresss.data)
        }
        if (Phones.data === undefined) {
            setDataPhones(getData.dataPhones)
        } else if (Phones.data !== undefined) {
            setDataPhones(Phones.data)
        }
    }, [])

    // console.log("cusIdMain", cusIdMain)
    // console.log("preDataCustomer", preDataCustomer)
    useEffect(() => {
        // setDataCustomer({ ...dataCustomer.customerId: cusIdMain })

        if (dataCustomer && dataAddress && dataPhones !== undefined) {
            setDataMix({ ...dataCustomer, dataAddress, dataPhones })
        }
        setGetIndex(dataindex)
    }, [dataCustomer, dataAddress, dataPhones])


    // console.log("customers.data.customerId",customers.data.customerId)
    // console.log("Addresss.data.customerId",Addresss.data)

    const handleSubmit = async () => {
        if (dataAddress === undefined) {
            cha('top')
        }
        if (dataPhones === undefined) {
            chp('top')
        }
        if (dataAddress && dataPhones !== undefined) {
            delete dataMix.status
            // console.log("dataMix", dataMix)
            await axios.post(addguarantors, dataMix)
                .then(res => {
                    if (res.status === 200) {
                        // console.log("res", res.data)
                        // console.log("55555", {...res.data, ...dataGua})
                        ExportToCard({ ...dataGua, ...res.data })
                    }
                }).catch((err) => {
                    console.log("err", err)
                })
        }
    }


    const ExportToCard = (value) => {
        // console.log("value", value)
        fucnEdit({ value, getIndex })
        saveclose()
    }

    const handleCancel = () => {
        close(false)
    }

    const onChangeBack = () => {
        prev()
    }

    const renderItem = ({ item, index }) => {

        return (
            <Form form={form}>
                <Row style={{ margin: '3px' }} justify={'center'} >
                    <Card
                        key={index} // index เป็นตัวกำหนดคีย์
                        title={
                            (item.addressStatus === true || item.AddressStatus === 1 || item.AddressStatus === true) ? <u>ใช้งานที่อยู่</u> : <u>ไม่ได้ใช้งาน</u>
                        }
                        style={{ width: '300px', textAlign: 'center' }} >
                        <Col span={24} style={{ textAlign: 'left' }}>
                            <Form.Item style={{ marginBottom: 0 }} label='เลขทะเบียนบ้าน' >
                                <b>{item.houseRegistrationNumber}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='บ้านเลขที่'>
                                <b>{item.houseNumber}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='ซอย'>
                                <b>{item.soi}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='ถนน'>
                                <b>{item.road}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='หมู่บ้าน'>
                                <b>{item.village}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='ตำบล'>
                                <b>{item.subdistrict}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='อำเภอ'>
                                <b>{item.district}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='จังหวัด'>
                                <b>{item.province}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='รหัสไปรษณีย์'>
                                <b>{item.zipCode}</b>
                            </Form.Item>
                            <Divider></Divider>
                            <Form.Item style={{ marginBottom: 0 }} label='เป็นที่อยู่'>
                                <b>
                                    {(item.addressOnIdcard === true || item.AddressOnIdcard === 1 || item.AddressOnIdcard === true) && "ตามบัตรประชาชน "}
                                    {(item.houseRegistration === true || item.HouseRegistration === 1 || item.HouseRegistration === true) && "ตามทะเบียนบ้าน "}
                                    {(item.workAddress === true || item.WorkAddress === 1 || item.WorkAddress === true) && "ตามสถานที่ทำงาน "}
                                    {(item.otherAddress === true || item.OtherAddress === 1 || item.OtherAddress === true) && "อื่นๆ "}
                                    {(item.currentAddress === true || item.CurrentAddress === 1 || item.CurrentAddress === true) && "ที่อยู่ปัจจุบัน "}
                                    {(item.sendDocuments === true || item.SendDocuments === 1 || item.SendDocuments === true) && "ที่อยู่ส่งเอกสาร "}
                                </b>
                            </Form.Item>
                        </Col>
                    </Card>
                </Row>
            </Form>
        )
    }
    const renderPhone = ({ item, index }) => {

        return (
            <Form form={form}>
                <Row style={{ margin: '3px' }} justify={'center'} >
                    <Card
                        key={index} // index เป็นตัวกำหนดคีย์
                        style={{ textAlign: 'center' }}
                        title={<u>{item.phoneType}</u>}
                    >
                        <Col span={24} style={{ textAlign: 'left' }}>
                            <Form.Item style={{ marginBottom: 0 }} label='เบอร์'>
                                <b>{item.telp}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='sendSms'>
                                {
                                    item.sendSms === "0" && (<b>ไม่ส่ง</b>)
                                }
                                {
                                    item.sendSms === "1" && (<b>ส่ง</b>)
                                }
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label="หมายเหตุ">
                                {item.note}
                            </Form.Item>
                        </Col>
                    </Card>
                </Row>
            </Form>
        )
    }
    return (
        <>
            <Card>
                <Row>
                    <Col span={24} style={{ fontSize: '30px', textAlign: 'center' }} >รายละเอียด</Col>
                    <Divider />
                </Row>
                <Form >
                    <Row>
                        <Col span={24} style={{ fontSize: '25px' }} >ข้อมูลส่วนตัว</Col>
                        <Col span={4}></Col>
                        <Col span={6}>
                            <Form.Item label="เลขบัตรประชาชน" style={{ margin: 0 }}>
                                <b>{dataCustomer.identificationId}</b>
                            </Form.Item>
                            <Form.Item label="ชื่อ-สกุล" style={{ margin: 0 }}>
                                <b>{dataCustomer.snam}{dataCustomer.firstname} {dataCustomer.lastname}</b>
                            </Form.Item>
                            <Form.Item label="เกิดวันที่" style={{ margin: 0 }}>
                                <b>{dataCustomer.birthdate}</b>
                            </Form.Item>

                            <Form.Item label="ความสัมพันธ์กับผู้กู้" style={{ margin: 0 }}>
                                <b>{dataGua.description}</b>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="ชื่อเล่น" style={{ margin: 0 }}>
                                <b>{dataCustomer.nickname}</b>
                            </Form.Item>
                            <Form.Item label="สถานที่ออกบัตร" style={{ margin: 0 }}>
                                <b>{dataCustomer.issuby}</b>
                            </Form.Item>
                            <Form.Item label="สาขา" style={{ margin: 0 }}>
                                <b>{dataGua.locat}</b>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="เพศ" style={{ margin: 0 }}>
                                <b>{dataCustomer.gender}</b>
                            </Form.Item>
                            <Form.Item label="วันที่บัตรหมดอายุ" style={{ margin: 0 }}>
                                <b>{dataCustomer.expdt}</b>
                            </Form.Item>
                            <Form.Item label="วันที่ออกบัตร" style={{ margin: 0 }}>
                                <b>{dataCustomer.issudt}</b>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider />
                    <Row>
                        <Col span={24}>
                            <Row>
                                <Col span={24} style={{ fontSize: '25px' }} >รายละเอียดที่อยู่</Col>
                            </Row>
                            <Row justify={'center'}>
                                {
                                    dataAddress.map((item, index) => {
                                        return renderItem({ item, index })
                                    })
                                }
                            </Row>
                        </Col>
                    </Row>
                    <Divider />
                    <Row>
                        <Col span={24}>
                            <Row>
                                <Col span={24} style={{ fontSize: '25px' }} >ช่องทางการติดต่อ</Col>
                            </Row>
                            <Row justify={'center'}>
                                {
                                    dataPhones.map((item, index) => {
                                        return renderPhone({ item, index })
                                    })
                                }
                            </Row>
                        </Col>
                    </Row>
                    <Divider />
                    <Row>
                        <Col className='gutter-row' span={24}>
                        </Col>
                    </Row>
                    <div style={{ textAlign: "center" }} >
                        <Space>
                            <Button type="primary" style={{ background: '#bfbfbf', color: '#000000', }} onClick={onChangeBack}>ย้อนกลับ</Button>
                            <Button type="primary" onClick={handleCancel} style={{ background: "red" }}>ยกเลิก</Button>
                            <Button type="primary" htmlType="submit" style={{ background: "green" }} onClick={handleSubmit}>บันทึก</Button>
                        </Space>
                    </div>
                </Form>
            </Card>
        </>
    )
};
