import React, { useState } from 'react'
import { Form, Modal, Row, Col, Card, Button, Tag, Divider, message, notification, Space } from 'antd'
import dayjs from 'dayjs';
import axios from "axios";
import { customerconfirmland } from '../../file_mid/all_api';

export default function ModalCusConfirm({ open, dataFromTable, oldData, close, closeAll }) {

    const token = localStorage.getItem('token');
    const [loading, setLoading] = useState(false)
    const { confirm } = Modal;

    const [reSendData, setReSendData] = useState(dataFromTable);
    const [api, contextHolder] = notification.useNotification();

    console.log("dataFromTable", dataFromTable)

    const customerConfirm = async (checkOK) => {
        setLoading(true)
        if (checkOK === true) {
            var data = { landLoanId: dataFromTable?.landLoanId, approvalStatus: "ลูกค้าคอนเฟิร์ม", cusIsAccepted: true, cusIsAcceptedDate: dayjs() }
        } else {
            var data = { landLoanId: dataFromTable?.landLoanId, approvalStatus: "ลูกค้าปฏิเสธ", cusIsAccepted: false, cusIsAcceptedDate: dayjs() }
        }
        console.log("data", data)
        await axios.put(customerconfirmland, data)
            .then(res => {
                if (res.status === 200) {
                    setLoading(false)
                    if (data.approvalStatus === "ลูกค้าคอนเฟิร์ม") {
                        message.success('ผู้กู้ยอมรับยอดที่อนุมัติ')
                    }
                    setTimeout(() => {
                        // handleCancel();
                        closeAll(false)
                    }, 2000)
                }
            }).catch((err) => {
                console.log("err", err)
            })
    };

    const sendNew = (placement) => {
        api.success({
            message: "ส่งเคสสำเร็จ",
            description:
                'ส่งเคสไปยังผู้ที่เกี่ยวข้องแล้ว',
            placement,
        });
    };

    const handleCancel = () => {
        close(false)
    }

    const showConfirmOK = () => {
        confirm({
            title: 'ผู้กู้ยอมรับยอดที่อนุมัติ...?',
            onOk() {
                var checkOK = true
                customerConfirm(checkOK)
                setTimeout(() => {

                }, 1500)
            },
            onCancel() {
            },
        });
    };

    const showConfirmCancel = () => {
        confirm({
            title: 'ผู้กู้ปฏิเสธยอดที่อนุมัติ...?',
            onOk() {
                var checkOK = false
                customerConfirm(checkOK)

            },
            onCancel() {
            },
        });
    };


    const renderOldData = ({ item, index }) => {

        let color

        if (item?.landLoanDetails.approvalStatus === "รอธุรการรับ") {
            color = "gold"
        }
        if (item?.landLoanDetails.approvalStatus === "รอการวิเคราะห์") {
            color = "gold"
        }
        if (item?.landLoanDetails.approvalStatus === "ลูกค้าคอนเฟิร์ม") {
            color = "gold"
        }
        if (item?.landLoanDetails.approvalStatus === "รอวิเคราะห์") {
            color = "cyan"
        }
        if (item?.landLoanDetails.approvalStatus === "รอเคาะราคา") {
            color = "gold"
        }
        if (item?.landLoanDetails.approvalStatus === "ผ่านการวิเคราะห์") {
            color = "geekblue"
        }
        if (item?.landLoanDetails.approvalStatus === "เคาะราคาแล้ว") {
            color = "green"
        }
        if (item?.landLoanDetails.approvalStatus === "ไม่ผ่านการวิเคราะห์") {
            color = "red"
        }
        if (item?.landLoanDetails.approvalStatus === "ผ่านพิจารณา") {
            color = "geekblue"
        }
        if (item?.landLoanDetails.approvalStatus === "รออนุมัติ") {
            color = "lime"
        }
        if (item?.landLoanDetails.approvalStatus === "อนุมัติ") {
            color = "green"
        }
        if (item?.landLoanDetails.approvalStatus === "อนุมัติแบบมีเงื่อนไข") {
            color = "green"
        }
        if (item?.landLoanDetails.approvalStatus === "โทรติด") {
            color = "magenta"
        }
        if (item?.landLoanDetails.approvalStatus === "โทรไม่ติด(ติดต่อไม่ได้)") {
            color = "volcano"
        }
        if (item?.landLoanDetails.approvalStatus === "ไม่อนุมัติ") {
            color = "red"
        }
        if (item?.landLoanDetails.approvalStatus === "ลูกค้าปฏิเสธ") {
            color = "red"
        }
        if (item?.landLoanDetails.approvalStatus === "รอทำสัญญา") {
            color = "green"
        }
        if (item?.landLoanDetails.approvalStatus === "รออนุมัติราคารถ") {
            color = "orange"
        }
        if (item?.landLoanDetails.approvalStatus === "รอพิจารณา") {
            color = "cyan"
        }
        return (
            <>
                <Row gutter={32} justify={'center'}>
                    <Col className='gutter-row' span={24}>
                        <>
                            <Card
                                key={index}
                                style={{ width: '100%', textAlign: 'left' }}>
                                <>
                                    <div style={{ marginBottom: 0, textAlign: 'right' }}>
                                        <Tag color={color}>{item.landLoanDetails.approvalStatus}</Tag>
                                    </div>
                                    {
                                        item.landLoanDetails.landLoanId === dataFromTable.landLoanId ?
                                            <>
                                                <div style={{ marginBottom: 0, textAlign: 'right' }}>
                                                    <Tag color="green">เคสปัจจุบัน</Tag>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div style={{ marginBottom: 0, textAlign: 'right' }}>
                                                    <Tag color="red">เคสที่เคยเสนอมาแล้ว</Tag>
                                                </div>
                                            </>
                                    }

                                    <Divider orientation="left">รายละเอียดผู้ขอกู้</Divider>
                                    {
                                        item?.customerAdLand.nationalId === "นิติบุคคล" ?
                                            <>
                                                <div style={{ marginBottom: 0, }}>
                                                    <span>ประเภท : </span> <b>{item.customerAdLand.nationalId}</b>
                                                </div>
                                                <div style={{ marginBottom: 0, }}>
                                                    <span>เลขประจำตัวผู้เสียภาษี : </span> <b>{item.customerAdLand.identificationId}</b>
                                                </div>
                                                <div style={{ marginBottom: 0, }}>
                                                    <span>
                                                        {item.customerAdLand.snam} : </span> <b>{item.customerAdLand.firstname} {item.customerAdLand.lastname}</b>
                                                </div>
                                                <div style={{ marginBottom: 0, }}>
                                                    <span>วันจดทะเบียน : </span> <b> {item.customerAdLand.birthdate}</b>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div style={{ marginBottom: 0 }}>
                                                    <span>เลขบัตรประชาชน : </span>
                                                    <b>{item.customerAdLand.identificationId}</b>
                                                </div>
                                                <div style={{ marginBottom: 0 }}>
                                                    <span>ชื่อ-สกุล : </span>
                                                    <b>{item.customerAdLand.snam}{item.customerAdLand.firstname} {item.customerAdLand.lastname}</b>
                                                </div>
                                                <div style={{ marginBottom: 0 }}>
                                                    <span>ชื่อเล่น : </span>
                                                    <b>{item.customerAdLand.nickname}</b>
                                                </div>
                                                <div style={{ marginBottom: 0 }}>
                                                    <span>เพศ : </span>
                                                    <b>{item.customerAdLand.gender === "M" ? "ชาย" : "หญิง"}</b>
                                                </div>
                                                <Divider orientation="left">รายละเอียดที่ดิน</Divider>
                                                <div style={{ marginBottom: 0 }}>
                                                    <span>ประเภทเอกสาร : </span>
                                                    <b>{item.productTypeLand === 1 ? "นส.3" : "นส.4"}</b>
                                                </div>
                                                <div style={{ marginBottom: 0 }}>
                                                    <span>เลขโฉนดที่ดิน : </span>
                                                    <b>{item.numberLand}</b>
                                                </div>
                                                <div style={{ marginBottom: 0 }}>
                                                    <span>เลขที่ดินหรือระวาง : </span>
                                                    <b>{item.numberLandlawang}</b>
                                                </div>
                                                <div style={{ marginBottom: 0 }}>
                                                    <span>จังหวัด : </span>
                                                    <b>{item.provinces}</b>
                                                </div>
                                                <div style={{ marginBottom: 0 }}>
                                                    <span>อำเภอ : </span>
                                                    <b>{item.district}</b>
                                                </div>
                                                <div style={{ marginBottom: 0 }}>
                                                    <span>พื้นที่ไร่ : </span>
                                                    <b>{item.rai}</b> ไร่
                                                </div>
                                                <div style={{ marginBottom: 0 }}>
                                                    <span>พื้นที่งาน : </span>
                                                    <b>{item.workArea}</b> งาน
                                                </div>
                                                <div style={{ marginBottom: 0 }}>
                                                    <span>พื้นที่ตารางวา : </span>
                                                    <b>{item.squareWaArea}</b> ตารางวา
                                                </div>
                                                <div style={{ marginBottom: 0 }}>
                                                    <span>ราคาประเมินที่ดิน : </span>
                                                    <b>{item.landPrice}</b> บาท/ตารางวา
                                                </div>
                                                <div style={{ marginBottom: 0 }}>
                                                    <span>ราคารวม : </span>
                                                    <b>{item.resultLandPrice}</b> บาท
                                                </div>
                                            </>
                                    }
                                    <Divider orientation="left">ยอดที่อนุมัติ</Divider>
                                    {
                                        item?.landLoanDetails?.approvedLoanAmount !== null ?
                                            <>
                                                <div style={{ backgroundColor: 'lightgreen' }}>
                                                    <div style={{ marginBottom: 0 }}>
                                                        <span>ยอดจัด : </span>
                                                        <b>{item.landLoanDetails.approvedLoanAmount}</b> บาท
                                                    </div>
                                                    <div style={{ marginBottom: 0 }}>
                                                        <span>จำนวนงวด : </span>
                                                        <b>{item.landLoanDetails.approvedLoanTerm}</b> งวด
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div>
                                                    <div style={{ marginBottom: 0 }}>
                                                        <span>ยอดจัด : </span>
                                                        <b style={{ color: "red" }}>*ยังไม่อนุมัติ!</b>
                                                    </div>
                                                    <div style={{ marginBottom: 0 }}>
                                                        <span>จำนวนงวด : </span>
                                                        <b style={{ color: "red" }}>*ยังไม่อนุมัติ!</b>
                                                    </div>
                                                </div>
                                            </>
                                    }
                                    <Divider orientation="left">การเสนอ</Divider>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>เสนอเมื่อ : </span>
                                        <b>{dayjs(item.landInput).format("DD/MM/YYYY HH:mm")}</b>
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>ผู้เสนอเคส : </span>
                                        <b>{item.landLoanDetails.proposalBy}</b>
                                    </div>
                                </>
                            </Card>
                        </>
                    </Col>
                </Row>
            </>
        )
    }


    return (
        <Modal open={open} onCancel={handleCancel} width={'1100px'}
            footer={[
                <Row justify={'center'}>
                    <Space>
                        <Button key="back" type='primary' style={{ backgroundColor: "gray" }} onClick={handleCancel}>
                            ปิด
                        </Button>
                        <Button
                            type='primary'
                            danger
                            onClick={showConfirmCancel}
                        >
                            ผู้กู้ไม่ยอมรับ
                        </Button>
                        <Button
                            type='primary'
                            style={{ backgroundColor: 'green' }}
                            onClick={showConfirmOK}
                        >
                            ยืนยันผู้กู้ยอมรับ
                        </Button>
                    </Space>
                </Row>
            ]} >

            <Row justify={'center'}>
                <Col className='gutter-row' span={24} style={{ fontSize: '25px', textAlign: 'center' }}>
                    <b>รายละเอียดเคสที่เคยเสนอมา</b>
                </Col>
            </Row>
            <Row justify={'center'}>
                <Divider style={{ margin: 5 }} />
                <Form
                    labelCol={{
                        span: 12,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    style={{
                        width: '90%',
                    }}
                    autoComplete="off"
                    initialValues={{ remember: true }}
                >
                    <div className='main'>
                        <>
                            <Row gutter={32} justify={'center'}>
                                {
                                    oldData?.map((item, index) => {
                                        return renderOldData({ item, index })
                                    })
                                }
                            </Row>
                        </>
                        {/* <Divider /> */}
                        {contextHolder}
                    </div>
                </Form>
            </Row>

        </Modal>
    )
}; 
