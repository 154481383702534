import dayjs from "dayjs";


export function DataExcelPay(allData, arrayTable) {
    arrayTable?.map((e) => {
        console.log("e", e)
        var data = {}
        data.CONTNO = e.CONTNO
        data.NCSHPRC = e.NCSHPRC
        data.T_NOPAY = e.T_NOPAY
        data.DUEDATE = dayjs(e.DUEDATE).format('YYYY-MM-DD');
        data.DELAYDAY = e.DELAYDAY
        data.PAYDATE = dayjs(e.PAYDATE).format('YYYY-MM-DD');
        data.FLRATE = e.FLRATE
        data.TONBALANCE = e.TONBALANCE
        data.NOPAY = e.NOPAY
        data.DUEAMT = e.DUEAMT
        data.DUEINTEFF = e.DUEINTEFF
        data.DUETONEFF = e.DUETONEFF
        data.PAYAMT = e.PAYAMT
        data.PAYINTEFF = e.PAYINTEFF
        data.PAYTON = e.PAYTON
        data.FOLLOWAMT = e.FOLLOWAMT
        data.PAYFOLLOW = e.PAYFOLLOW
        data.PL_TONBALANCE = e.PL_TONBALANCE
        data.PL_CAPITAL = e.PL_CAPITAL
        data.PL_FUPAY = e.PL_FUPAY
        data.PL_DUEAMT = e.PL_DUEAMT
        data.PL_DUEINTEFF = e.PL_DUEINTEFF
        data.PL_DUETONEFF = e.PL_DUETONEFF
        data.PL_PAYAMT = e.PL_PAYAMT
        data.PL_PAYINTEFF = e.PL_PAYINTEFF
        data.PL_PAYTON = e.PL_PAYTON
        data.AS_TONBALANCE = e.AS_TONBALANCE
        data.AS_CAPITAL = e.AS_CAPITAL
        data.AS_FUPAY = e.AS_FUPAY
        data.AS_DUEAMT = e.AS_DUEAMT
        data.AS_DUEINTEFF = e.AS_DUEINTEFF
        data.AS_DUETONEFF = e.AS_DUETONEFF
        data.AS_PAYAMT = e.AS_PAYAMT
        data.AS_PAYINTEFF = e.AS_PAYINTEFF
        data.AS_PAYTON = e.AS_PAYTON
        data.INPUTDATE = dayjs(e.INPUTDATE).format('YYYY-MM-DD');
        // data.ALLPAYTON = e.ALLPAYTON
        // data.ALLPAYTONPL = e.ALLPAYTONPL
        // data.ALLPAYTONAS = e.ALLPAYTONAS
        allData.push(data)
        return null
    })
    return allData
}
