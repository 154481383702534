import React, { useState, useEffect, useRef } from 'react'
import { Form, Modal, Row, Col, Image, Space, Button, Divider, Spin } from 'antd'
import { PrinterOutlined } from '@ant-design/icons';
import { useReactToPrint } from "react-to-print";

export default function PrintImg({ open, close, adData }) {
    const [loading, setLoading] = useState(false)
    const [user, setUser] = useState(adData)
    const [form] = Form.useForm()
    const conponentPDF = useRef();
    const generatePDF = useReactToPrint({
        content: () => conponentPDF.current,
        documentTitle: "AD",
    });

    // console.log("a", adData)
    // console.log("user", user)
    useEffect(() => {
    }, [])


    const handleOk = () => {
        close(false);
    };

    const handleCancel = () => {
        close(false);
    };
    return (
        <Modal open={open} onOk={handleOk} onCancel={handleCancel} width={'1000px'}
            footer={[
                <Row justify={'center'}>
                    <Button key="back" type='primary' danger onClick={handleCancel}>
                        ปิด
                    </Button>
                </Row>
            ]} >
            <Row justify={'center'}>
                <Col className='gutter-row' span={24} style={{ fontSize: '25px', textAlign: 'center' }}>
                    <b>ปริ้นท์รูปภาพ</b>
                </Col>
            </Row>
            <Divider style={{ margin: 5 }} />
            <Row justify={'center'}>
                <Spin spinning={loading} size='large' tip=" Loading... ">
                    <Form
                        name="basic"
                        labelCol={{
                            span: 12,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        form={form}
                        autoComplete="off"
                    >
                        <Row gutter={32}>
                            <Col className='gutter-row' span={24} style={{ textAlign: 'center' }}>
                                <div ref={conponentPDF}>
                                    {user && user?.map((e, index) => {
                                        // console.log("e", e)
                                        return (
                                            <div key={index}>
                                                <Row justify={'center'}>
                                                    <Space direction='vertical'>
                                                        <Image
                                                            style={{ marginTop: '15px',marginBottom: '100px' }}
                                                            width={"720px"}
                                                            src={`data:${e?.Attr};base64,${e?.data?.toString('base64')}`}
                                                            alt={`Image ${index + 1}`}
                                                        />
                                                        <p></p>
                                                    </Space>
                                                </Row>
                                            </div>
                                        )
                                    })}
                                </div>
                                <Button
                                    style={{ margin: '5px', background: '#597ef7', color: '#ffffff' }}
                                    onClick={() => generatePDF()}
                                    icon={<PrinterOutlined />}
                                >
                                    พิมพ์รูป
                                </Button>

                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Row>
        </Modal>
    )
}
