import { createSlice } from "@reduxjs/toolkit";

const initialState = {

}

const emailDataSlice = createSlice({
    name: "phone",
    initialState,
    reducers: {

        addEmail: (state, action) => {
            state.data = action.payload
            // console.log(current(state))
        },

    }

})

export const { addEmail } = emailDataSlice.actions
export default emailDataSlice.reducer