import React, { useState, useEffect } from 'react'
import { Form, Modal, Row, Col, Image, Space, Card, Button, Tag, Divider } from 'antd'
import dayjs from 'dayjs';
import axios from "axios";
import TextArea from 'antd/es/input/TextArea';
import { checklandpn, findlandpn, getImagess } from '../../file_mid/all_api';

export default function ModalDetail({ open, dataFromTable, close }) {

    const token = localStorage.getItem('token');
    const [dataLand, setDataLand] = useState(dataFromTable);
    const [newDataLand, setNewDataLand] = useState([]);
    const [form] = Form.useForm()

    const [note, setNote] = useState([])

    useEffect(() => {
        setDataLand(dataFromTable)
        //setImageBlobzz(JSON.parse(dataFromTable.images))
        loadData()
        loadDataNew() // Array
    }, [])

    useEffect(() => {
        newDataLand.map((e, index) => {
            form.setFieldsValue({
                [`storyLand${index}`]: e?.storyLand,
            })
        })
    }, [newDataLand])

    // console.log("dataFromTable", dataFromTable)
    // console.log("newDataLand", newDataLand)
    // console.log("mainLand", mainLand)

    const loadData = async () => {
        await axios.post(checklandpn, { numberLand: dataFromTable.numberLand, provinces: dataFromTable.province, district: dataFromTable.district })
            .then(res => {
                if (res.status === 200) {
                    // console.log("LandLand", res.data)
                    setNote(res.data.notes)
                }
            }).catch((err) => {
                console.log("err", err)
            })
    }

    const loadDataNew = async () => {
        await axios.post(findlandpn, { landLoanId: dataFromTable.landLoanId })
            .then(res => {
                if (res.status === 200) {
                    setNewDataLand(res.data)
                }
            }).catch((err) => {
                console.log("err", err)
            })
    }


    const handleOk = () => {
        // console.log('ok', dataLand)
        close(false);
    };

    const handleCancel = () => {
        close(false);
    };


    const currencyFormatOne = (amount) => {
        return Number(amount)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }

    let color

    if (dataLand?.approvalStatus === "รอธุรการรับ") {
        color = "gold"
    }
    if (dataLand?.approvalStatus === "รออนุมัติราคาที่ดิน") {
        color = "orange"
    }
    if (dataLand?.approvalStatus === "รอเคาะราคา") {
        color = "gold"
    }
    if (dataLand?.approvalStatus === "เคาะราคาแล้ว") {
        color = "orange"
    }
    if (dataLand?.approvalStatus === "รอวิเคราะห์") {
        color = "cyan"
    }
    if (dataLand?.approvalStatus === "ผ่านการวิเคราะห์") {
        color = "geekblue"
    }
    if (dataLand?.approvalStatus === "ขอเอกสารเพิ่ม") {
        color = "geekblue"
    }
    if (dataLand?.approvalStatus === "รอพิจารณา") {
        color = "cyan"
    }
    if (dataLand?.approvalStatus === "ผ่านพิจารณา") {
        color = "geekblue"
    }
    if (dataLand?.approvalStatus === "รออนุมัติ") {
        color = "lime"
    }
    if (dataLand?.approvalStatus === "อนุมัติ") {
        color = "green"
    }
    if (dataLand?.approvalStatus === "รอทำสัญญา") {
        color = "green"
    }
    if (dataLand?.approvalStatus === "โทรติด") {
        color = "magenta"
    }
    if (dataLand?.approvalStatus === "โทรไม่ติด(ติดต่อไม่ได้)") {
        color = "volcano"
    }
    if (dataLand?.approvalStatus === "ไม่อนุมัติ") {
        color = "red"
    }
    if (dataLand?.approvalStatus === "ไม่ผ่านพิจารณา") {
        color = "red"
    }
    if (dataLand?.approvalStatus === "ไม่ผ่านการวิเคราะห์") {
        color = "red"
    }
    if (dataLand?.approvalStatus === "ลูกค้าปฏิเสธ") {
        color = "red"
    }
    if (dataLand?.approvalStatus === "ปัดตก") {
        color = "red"
    }

    const renderNoteAD = ({ item, index }) => {
        return (
            <>
                <Row gutter={32} justify={'center'}>
                    <Col className='gutter-row' span={24}>
                        <>
                            <Card
                                key={index}
                                style={{ width: '270px', textAlign: 'left' }}>
                                <>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>สาเหตุ : </span>
                                        <b>{item?.cause}</b>
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>ผู้สร้างหมายเหตุ : </span>
                                        <b>{item?.noteBy}</b>
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>สถานะที่ทำการหมายเหตุ : </span>
                                        <b>{item?.status}</b>
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>เมื่อวันที่ : </span>
                                        <b>{dayjs(item?.noteDate).format("DD-MM-YYYY")}</b>
                                    </div>
                                </>
                            </Card>
                        </>
                    </Col>
                </Row>
            </>
        )
    }

    const renderLandData = ({ item, index }) => { //render Land Array
        return (
            <>
                {/* <Row gutter={32} justify={'center'}>
                    <Col className='gutter-row' span={24}>
                        <Card>
                            <Divider orientation='left'><b><u>รายละเอียดที่ดิน ({item?.numberLand})</u></b></Divider>
                            <aside style={{ width: '90%' }}>
                                <div style={{ marginBottom: 0 }}>
                                    <span>ประเภทเอกสาร : </span>{<b>{item?.productTypeLand === 1 ? "นส.3" : "นส.4"}</b>}
                                </div>
                                <div style={{ marginBottom: 0 }}>
                                    <span>เลขโฉนดที่ดิน : </span>{<b>{item?.numberLand}</b>}
                                </div>
                                <div style={{ marginBottom: 0 }}>
                                    <span>เลขที่ดินหรือระวาง : </span>{<b>{item?.numberLandlawang}</b>}
                                </div>
                                <div style={{ marginBottom: 0 }}>
                                    <span>จังหวัด : </span>{<b>{item?.provinces}</b>}
                                </div>
                                <div style={{ marginBottom: 0 }}>
                                    <span>อำเภอ : </span>{<b>{item?.district}</b>}
                                </div>
                                <div style={{ marginBottom: 0 }}>
                                    <span>พื้นที่ไร่ : </span>{<b>{item?.rai}</b>}
                                    <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                        ไร่
                                    </span>
                                </div>
                                <div style={{ marginBottom: 0 }}>
                                    <span>พื้นที่งาน : </span>{<b>{item?.workArea}</b>}
                                    <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                        งาน
                                    </span>
                                </div>
                                <div style={{ marginBottom: 0 }}>
                                    <span>พื้นที่ตารางวา : </span>{<b>{currencyFormatOne(item?.squareWaArea)}</b>}
                                    <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                        บาท
                                    </span>
                                </div>
                                <div style={{ marginBottom: 0 }}>
                                    <span>ราคาประเมินที่ดิน : </span> {<b>{item?.landPrice}</b>}
                                    <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                        บาท / ตารางวา
                                    </span>
                                </div>
                                <div style={{ marginBottom: 0 }}>
                                    <span>ราคารวม : </span> {<b>{item?.resultLandPrice}</b>}
                                    <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                        บาท
                                    </span>
                                </div>
                            </aside>
                            <Divider />
                            <Divider orientation={'left'}><b><u>สตอรี่รายละเอียดที่ดิน อธิบายข้อมูลของที่ดินแปลงนั้น / ที่นา ที่บ้าน บ้านกี่หลัง ({item?.numberLand})</u></b></Divider>
                            <Form.Item name={`storyLand${index}`} label="">
                                <TextArea style={{ color: 'blue' }} rows={5} disabled />
                            </Form.Item>
                        </Card>
                    </Col>
                </Row> */}
                <Col className='gutter-row' span={24}>
                    <>
                        <Card key={index} style={{ width: '100%', textAlign: 'left' }}>
                            <Col style={{ marginBottom: 0, textAlign: 'right' }}>
                                <Tag color={color}>{item.landLoanDetails.approvalStatus}</Tag>
                            </Col>
                            <Divider orientation={'left'}><b><u>รายละเอียดที่ดิน ({item?.numberLand}) {newDataLand.length > 1 ? <>{item.landMain ? <b style={{ color: "red" }} >(ที่ดินหลัก)</b> : null}</> : null} </u></b></Divider>
                            <Row gutter={32} justify={'center'}>
                                <aside style={{ width: '90%' }}>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>กลุ่มสินค้า : </span>{<b>{item?.landLoanDetails.productType}</b>}
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>ประเภทเอกสาร : </span>{<b>{item?.productTypeLand === 1 ? "นส.3" : "นส.4"}</b>}
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>เลขโฉนดที่ดิน : </span>{<b>{item?.numberLand}</b>}
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>เลขที่ดินหรือระวาง : </span>{<b>{item?.numberLandlawang}</b>}
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>จังหวัด : </span>{<b>{item?.provinces}</b>}
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>อำเภอ : </span>{<b>{item?.district}</b>}
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>พื้นที่ไร่ : </span>{<b>{item?.rai}</b>}
                                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                            ไร่
                                        </span>
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>พื้นที่งาน : </span>{<b>{item?.workArea}</b>}
                                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                            งาน
                                        </span>
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>พื้นที่ตารางวา : </span>{<b>{currencyFormatOne(item?.squareWaArea)}</b>}
                                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                            บาท
                                        </span>
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>ราคาประเมินที่ดิน : </span> {<b>{item?.landPrice}</b>}
                                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                            บาท / ตารางวา
                                        </span>
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>ราคารวม : </span> {<b>{item?.resultLandPrice}</b>}
                                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                            บาท
                                        </span>
                                    </div>

                                </aside>
                            </Row>
                            <Divider orientation={'left'}><b><u>สตอรี่รายละเอียดที่ดิน ({item?.numberLand})</u></b></Divider>
                            <Form.Item name={`storyLand${index}`} label="">
                                <TextArea style={{ color: 'blue' }} rows={5} disabled />
                            </Form.Item>
                        </Card>
                    </>
                </Col>
            </>
        )
    }
    const renderCoordinates = ({ item, index }) => {
        return (
            <>
                <div>
                    <span>รายละเอียดพิกัด ({item?.numberLand}) : </span>
                    <a href={`https://www.google.com/maps?q=${item.parcellat},${item.parcellon}`} target="_blank">
                        {`${item.parcellat},${item.parcellon}`}
                    </a>
                </div>
            </>
        )
    }
    const renderApproval = ({ item, index }) => {
        return (
            <>
                <aside style={{ width: '90%' }}>
                    <div style={{ marginBottom: 0 }}>
                        <span>ยอดที่อนุมัติ ({item?.numberLand}) : </span>{<b>{currencyFormatOne(item?.approval?.minPrice)} - {currencyFormatOne(item?.approval?.maxPrice)}</b>}
                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                            บาท
                        </span>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>หมายเหตุ : </span>{<b>{item?.approval?.note}</b>}
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>หมายเหตุการจดรวม : </span>{<b>{item?.approval?.mixNote}</b>}
                    </div>
                </aside>
                {/* <Divider /> */}
            </>
        )
    }
    const renderTitleDeed = ({ item, index }) => {
        return (
            <>
                <Row justify={'left'}><b><u>รูปโฉนด/หน้า-หลัง ({item.numberLand})</u></b></Row>
                <Space>
                    <Row gutter={32} justify={'center'}>
                        {item?.img?.map((e, index) => {
                            var PreAaproveSplit = []
                            PreAaproveSplit = e.pathImage.split("/")
                            if (PreAaproveSplit[3] !== "13") {
                                if (e.type === 3) {
                                    return (
                                        <>
                                            <Image width={"10%"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                        </>
                                    );
                                }
                            }
                            return null;
                        })}
                    </Row>
                </Space>
                <Divider />
            </>
        )
    }
    const renderDivision = ({ item, index }) => {
        return (
            <>
                <Row justify={'left'}><b><u>ใบแบ่งใบต่อ ({item.numberLand})</u></b></Row>
                <Space>
                    <Row gutter={32} justify={'center'}>
                        {item?.img?.map((e, index) => {
                            var PreAaproveSplit = []
                            PreAaproveSplit = e.pathImage.split("/")
                            if (PreAaproveSplit[3] !== "13") {
                                if (e.type === 10) {
                                    return (
                                        <>
                                            <Image width={"10%"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                        </>
                                    );
                                }
                            }
                            return null;
                        })}
                    </Row>
                </Space>
                <Divider />
            </>
        )
    }
    const renderLawang = ({ item, index }) => {
        return (
            <>
                <Row justify={'left'}><b><u>ระวาง ({item.numberLand})</u></b></Row>
                <Space>
                    <Row gutter={32} justify={'center'}>
                        {item?.img?.map((e, index) => {
                            var PreAaproveSplit = []
                            PreAaproveSplit = e.pathImage.split("/")
                            if (PreAaproveSplit[3] !== "13") {
                                if (e.type === 11) {
                                    return (
                                        <>
                                            <Image width={"10%"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                        </>
                                    );
                                }
                            }
                            return null;
                        })}
                    </Row>
                </Space>
                <Divider />
            </>
        )
    }
    const renderLandBuildings = ({ item, index }) => {
        return (
            <>
                <Row justify={'left'}><b><u>รูปที่ดิน/สิ่งปลูกสร้าง-ที่ดินปัจจุบัน ({item.numberLand})</u></b></Row>
                <Space>
                    <Row gutter={32} justify={'center'}>
                        {item?.img?.map((e, index) => {
                            var PreAaproveSplit = []
                            PreAaproveSplit = e.pathImage.split("/")
                            if (PreAaproveSplit[3] !== "13") {
                                if (e.type === 1) {
                                    return (
                                        <>
                                            <Image width={"10%"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                        </>
                                    );
                                }
                            }
                            return null;
                        })}
                    </Row>
                </Space>
                <Divider />

            </>
        )
    }

    return (
        <>
            <Modal open={open} onOk={handleOk} onCancel={handleCancel} width={'1180px'}
                footer={[null]} >

                <Row justify={'center'}>
                    <Col className='gutter-row' span={24} style={{ fontSize: '25px', textAlign: 'center' }}>
                        <b>รายละเอียด</b>
                    </Col>
                </Row>
                <Row justify={'center'}>
                    <Divider style={{ margin: 5 }} />
                    <Form
                        labelCol={{
                            span: 12,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                        style={{
                            width: '90%',
                        }}
                        form={form}
                        // onFinish={handleSubmit}
                        autoComplete="off"
                        initialValues={{ remember: true }}
                    >
                        <div className='main'>
                            <Card style={{ width: '100%' }}>
                                <Row justify={'center'}>
                                    <Col span={24} style={{ textAlign: 'right' }}>
                                        <div style={{ margin: 0 }}>
                                            ชื่อการตลาดที่เสนอ : <Tag color={color}>{dataLand?.proposalBy}</Tag>
                                        </div>
                                        <div style={{ margin: 0 }}>
                                        </div>
                                    </Col>
                                    <Col span={24} style={{ textAlign: 'right' }}>
                                        <div style={{ margin: 0 }}>
                                            สถานะ : <Tag color={color}>{dataLand?.approvalStatus}</Tag>
                                        </div>
                                        <div style={{ margin: 0 }}>
                                        </div>
                                    </Col>
                                </Row>
                                <Divider />
                                <>
                                    {/* <Row justify={'left'}><b><u>รายละเอียดที่ดิน ({dataLand?.numberLand})</u></b></Row> */}
                                    <Row gutter={32} justify={'center'}>
                                        {
                                            newDataLand?.map((item, index) => {
                                                return renderLandData({ item, index })
                                            })
                                        }

                                    </Row>
                                    {/* <Divider /> */}
                                    {/* <Row justify={'center'}>
                                        <Col span={22}>
                                            <span><b style={{ fontSize: '25' }}>สตอรี่รายละเอียดที่ดิน</b></span> <p />
                                            <Form.Item name="storyLand" label="">
                                                <TextArea
                                                    name='storyLand'
                                                    style={{ color: 'blue' }}
                                                    rows={8}
                                                    disabled
                                                >
                                                </TextArea>
                                                {
                                                    newDataLand?.map((item, index) => {
                                                        return renderLandStory({ item, index })
                                                    })
                                                }
                                            </Form.Item>

                                        </Col>
                                    </Row> */}
                                </>
                                <Divider />
                                {
                                    dataLand?.parcellat ?
                                        <>
                                            <Row justify={'left'}><b><u>พิกัด</u></b></Row>
                                            <Row gutter={32} justify={'center'}>
                                                <aside style={{ width: '90%' }}>
                                                    <div
                                                        style={{ marginBottom: 0 }}
                                                    >

                                                        {
                                                            newDataLand?.map((item, index) => {
                                                                return renderCoordinates({ item, index })
                                                            })
                                                        }
                                                        {/* <a href={`https://www.google.com/maps?q=${dataLand.parcellat},${dataLand.parcellon}`} target="_blank">
                                                            {`${dataLand.parcellat},${dataLand.parcellon}`}
                                                        </a> */}
                                                    </div>
                                                </aside>
                                            </Row>
                                        </>
                                        : null
                                }
                                <Divider />
                                <>
                                    <Row justify={'left'}><b><u>รายละเอียดยอดที่อนุมัติ</u></b></Row>
                                    <Row gutter={32} justify={'center'}>
                                        {
                                            newDataLand?.map((item, index) => {
                                                return renderApproval({ item, index })
                                            })
                                        }
                                    </Row>
                                </>
                                {
                                    note?.length > 0 ?
                                        <>
                                            <Divider />
                                            <Row justify={'left'}>
                                                <b><u>หมายเหตุ</u></b>
                                            </Row>
                                            <Row gutter={32} justify={'center'}>
                                                {
                                                    note?.map((item, index) => {
                                                        return renderNoteAD({ item, index })
                                                    })
                                                }

                                            </Row>
                                        </>
                                        : null
                                }
                                <Divider />
                                {
                                    newDataLand?.map((item, index) => {
                                        return renderTitleDeed({ item, index })
                                    })
                                }
                                {
                                    newDataLand?.map((item, index) => {
                                        return renderDivision({ item, index })
                                    })
                                }
                                {
                                    newDataLand?.map((item, index) => {
                                        return renderLawang({ item, index })
                                    })
                                }
                                {
                                    newDataLand?.map((item, index) => {
                                        return renderLandBuildings({ item, index })
                                    })
                                }

                                {/* <>
                                    <Row justify={'left'}><b><u>รูปโฉนด/หน้า-หลัง</u></b></Row>
                                    <Space>
                                        <Row gutter={32} justify={'center'}>
                                            {imageBlobzz?.map((e, index) => {
                                                if (e.type === 3) {
                                                    return (
                                                        <>
                                                            <Image width={"20%"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                        </>

                                                    );
                                                }
                                                return null;
                                            })}
                                        </Row>
                                    </Space>

                                    <Divider />
                                    <Row justify={'left'}><b><u>ใบแบ่งใบต่อ</u></b></Row>
                                    <Space>
                                        <Row gutter={32} justify={'center'}>
                                            {imageBlobzz?.map((e, index) => {
                                                if (e.type === 10) {
                                                    return (
                                                        <>
                                                            <Image width={"20%"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                        </>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </Row>
                                    </Space>
                                    <Divider />
                                    <Row justify={'left'}><b><u>ระวาง</u></b></Row>
                                    <Space>
                                        <Row gutter={32} justify={'center'}>
                                            {imageBlobzz?.map((e, index) => {
                                                if (e.type === 11) {
                                                    return (
                                                        <>
                                                            <Image width={"20%"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                        </>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </Row>
                                    </Space>
                                    <Divider />
                                    <Row justify={'left'}><b><u>รูปที่ดิน/สิ่งปลูกสร้าง-ที่ดินปัจจุบัน</u></b></Row>
                                    <Space>
                                        <Row gutter={32} justify={'center'}>
                                            {imageBlobzz?.map((e, index) => {
                                                if (e.type === 1) {
                                                    return (
                                                        <>
                                                            <Image width={"20%"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                        </>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </Row>
                                    </Space>
                                    <Divider />
                                    <Col className='gutter-row' span={24} style={{ textAlign: 'right' }}>
                                        <Space>
                                            <Button key="back" type='primary' danger onClick={handleCancel}>ปิด</Button>
                                        </Space>
                                    </Col>
                                </> */}
                                <Col className='gutter-row' span={24} style={{ textAlign: 'right' }}>
                                    <Space>
                                        <Button key="back" type='primary' danger onClick={handleCancel}>ปิด</Button>
                                    </Space>
                                </Col>
                            </Card>
                        </div>
                    </Form>
                </Row>
            </Modal >
        </>
    )
}
