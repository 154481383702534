import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Button, Form, Card, Col, Row, Spin, Space, Divider, Modal, message, Image, notification } from 'antd';
import { PlusOutlined, UpOutlined, DownOutlined, EditOutlined, StopOutlined } from '@ant-design/icons';
import axios from 'axios';
import dayjs from 'dayjs';

// import AddGuarantoesNewAd from './AddGuarantoesNewAd';
import ModalEditGuarantor from './Guarantor/ModalEditGuarantor'
import ModalAddGuarantor from './Guarantor/ModalAddGuarantor'
import TextArea from 'antd/es/input/TextArea';
import { checkgu, deleteFolderImgGuaCar, deleteFolderImgGuaLand, editgarno, storeDBLine, updatgarno1, getAllFolderGuaImg } from '../../../file_mid/all_api';

function AddGuarantorsAd({ getData, changepage, page }) {

    const [form] = Form.useForm();
    const [form1] = Form.useForm()
    const guarantor = useSelector(state => state.guarantor)
    const token = localStorage.getItem('token');
    const un = localStorage.getItem('username');
    const [api, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(false)
    const [guarantors, setGuarantors] = useState([]);
    const [modalEditGuarantor, setModalEditGuarantor] = useState(false) // customer

    const [openAddGuarantor, setOpenAddGuarantor] = useState(false);

    const [infor] = useState({ ...getData });
    const [getimages, setGetImages] = useState([]);
    const [dataPostCustomer] = useState({ ...guarantor?.data })

    const [sendback, setSendback] = useState(dayjs())
    const [saveTypeLoan, setSaveTypeLoan] = useState()

    const { confirm } = Modal

    const [dataedit, setDataEdit] = useState([]); //เซตสำหรับแก้ไขข้อมูล 
    const [index1, setIndex] = useState(); //set index send to page edit
    const [indexedit, setIndexEdit] = useState(0); //set index send to page edit 
    const [addCount, setAddCount] = useState(0);

    const [checkEditGarno, setCheckEditGarno] = useState(false) // เปิด/ปิด แก้ไขลำดับคนค้ำ

    useEffect(() => {
        var dataloadGua = { typeLoan: guarantor.data.typeLoan, loanId: guarantor.data.loanId }
        setSaveTypeLoan({ typeLoan: guarantor.data.typeLoan, loanId: guarantor.data.loanId })
        loadGuarantors(dataloadGua)
        getImg()
        if (guarantor.data.typeLoan === "car") {
            if (getData?.carLoanDetails?.notPass?.loanId !== 0) {
                form.setFieldsValue(
                    {
                        ...getData?.carLoanDetails?.notPass
                    }
                )
            }
            if (getData?.carLoanDetails?.noteResend?.loanId !== 0) {
                form.setFieldsValue(
                    {
                        ...getData?.carLoanDetails?.noteResend
                    }
                )
            }
        } else {
            if (getData?.landLoanDetails?.notPass?.loanId !== 0) {
                form.setFieldsValue(
                    {
                        ...getData?.landLoanDetails?.notPass
                    }
                )
            }
            if (getData?.landLoanDetails?.noteResend?.loanId !== 0) {
                form.setFieldsValue(
                    {
                        ...getData?.landLoanDetails?.noteResend
                    }
                )
            }
        }
    }, [dataedit, indexedit, modalEditGuarantor, sendback])

    useEffect(() => {
        if (guarantors?.length > 0) {
            countType()
        }
    }, [guarantors])

    useEffect(() => {
        if (addCount === 2) {
            Deletegarno()
        }
    }, [addCount])

    const loadGuarantors = async (value) => {
        await axios.post(checkgu, value)
            .then(res => {
                if (res.status === 200) {
                    setGuarantors(res.data)
                } else if (res.status === 204) {
                    // console.log("ไม่พบข้อมูล")
                }
            }).catch((err) => {
                console.log("err", err)
            })
    }

    const getImg = async () => {
        if (dataPostCustomer?.typeLoan === "car") {
            var path = `one_images/car/${getData?.carLoanDetails?.cars?.carId}/5`
            // console.log("path-Car", path)
        } else {
            var path = `one_images/land/${getData?.landLoanDetails?.land?.landId}/5`
            // console.log("path-Land", path)
        }
        setLoading(true)
        axios.get(`${getAllFolderGuaImg}/${path}?token=${token}`)
            .then(res => {
                if (res.data) {
                    // console.log("imgs", res.data)
                    setGetImages(res.data)
                    setLoading(false)
                }
            })
            .catch((err) => {
                console.log("err", err)
                setLoading(false)
            })
        setLoading(false)
    }

    const countType = async () => {
        // const lastItem = guarantors[guarantors.length - 1];
        // const newType = lastItem.pathImg.split("/")
        // const intTpye = parseInt(newType[4])
        // // setIndexEdit(intTpye)
        // if (isNaN(intTpye)) {
        //     var newIntType = guarantors.length
        //     // console.log("if")
        // } else {
        //     // console.log("else")
        //     var newIntType = intTpye
        // }
        // setIndexEdit(newIntType)
        var yy = []
        guarantors.map((e) => {
            const newType = e.pathImg.split("/")
            const intTpye = parseInt(newType[4])
            if (isNaN(intTpye)) {
                yy.push(0)
            } else {
                yy.push(intTpye)
            }
        })
        var maxNumber = Math.max(...yy);
        if (isNaN(maxNumber)) {
            var newIntType = guarantors.length

        } else {
            var newIntType = maxNumber
        }
        setIndexEdit(newIntType)
    }

    const handleOpen = () => {
        setOpenAddGuarantor(true)
    }

    const handleSubmit = async () => {
        setLoading(true)
        changepage(page + 1)
        setLoading(false)
    }

    const Deletegarno = async () => {
        await axios.put(updatgarno1, guarantors)
            .then(res => {
                if (res.status === 200) {
                    if (res.data.length === 1) {
                        setGuarantors([])
                    }
                    setAddCount(0)
                }
            })
            .catch(err => {
                console.log(err)
            }
            )
    }

    const handleOpenEditGarno = () => {
        setCheckEditGarno(true)
    }
    const handleCloseEditGarno = () => {
        setSendback(dayjs())
        setCheckEditGarno(false)
    }

    const handleSwap = (index1, index2) => {
        const newData = [...guarantors];
        [newData[index1], newData[index2]] = [newData[index2], newData[index1]];
        setGuarantors(newData);
    };

    const handleEditGarno = async () => {
        await axios.put(editgarno, guarantors)
            .then(res => {
                if (res.status === 200) {
                    editGarnoSucc('top')
                    setSendback(dayjs())
                    setCheckEditGarno(false)

                }
            })
            .catch((err) => {
                console.log("err", err)
                setLoading(false)
            })
    }

    const editGarnoSucc = (placement) => {
        api.success({
            message: <b>แก้ไขลำดับคนค้ำสำเร็จ</b>,
            // description: 'กรุณาเพิ่มที่อยู่ อย่างน้อย 1 ที่',
            placement,
        });
    }

    const genGuarantors = ({ item, index }) => {
        var itemSplit = []
        if (item?.pathImg) {
            itemSplit = item.pathImg.split("/")
        }

        const handleEdit = (dataedit, index1) => {
            setIndex(index1) //เซตค่า index ให้กับชุด ข้อมูล
            setDataEdit({ dataedit, index1 }) //ส่งข้อมูลด index ไปหน้าแก้ไข
            setModalEditGuarantor(true);
        }

        const showConfirmDelete = async ({ dataedit, index }) => {
            const folder = []
            const split = dataedit?.pathImg.split('/')[4];
            getimages.map((item, index1) => {
                var image = {}
                // console.log("split = ", item?.pathImage.split('/')[4], split);
                if (item?.pathImage.split('/')[4] === split) {
                    image.attr = item.attr
                    image.data = item.data
                    image.idImage = item.idImage
                    image.pathImage = item.pathImage
                    folder.push(image)
                }
            })
            // console.log("folder", folder)
            del({ dataedit, folder })
        }

        // console.log("dataPostCustomer?.typeLoan ", dataPostCustomer?.typeLoan)

        const axiosDeleteGua = async ({ mix }) => {
            // console.log("mix", mix);
            if (dataPostCustomer?.typeLoan === "car") {
                setLoading(true)
                await axios.delete(deleteFolderImgGuaCar, { data: mix })
                    .then(res => {
                        // console.log("res.data", res)
                        setTimeout(() => {
                            // const deletearray = [...guarantors] // กระจายข้อมูลเข้าไปในตัวแปร deletearray
                            // deletearray.splice(index, 1); // เป็นการลบข้อมูลใน Array ตาม index ที่เลือกตาม Card
                            // // dispatch(addGuarantor(deletearray))
                            // setGuarantors(deletearray);  // เซตค่าเข้าไปเก็บใน dataArray
                            message.success('ลบข้อมูลคนค้ำสำเร็จ')
                            if (guarantors.length === 1) {
                                setAddCount(2)
                                // console.log("1")
                            } else {
                                // console.log("2")
                                const data = guarantors.filter(item => item.garno !== index + 1);

                                // เปลี่ยน "garno" ที่มีค่าเป็น 3 เป็น 2
                                data.forEach((item, index) => {
                                    item.garno = index + 1;
                                });
                                setGuarantors(data)
                                setAddCount(2)
                            }
                        }, 1500)
                        setLoading(false)
                    })
                    .catch((err) => {
                        console.log("err", err)
                        setLoading(false)
                    })
                setLoading(false)

            } else {
                setLoading(true)
                await axios.delete(deleteFolderImgGuaLand, { data: mix })
                    .then(res => {
                        // console.log("res.data", res)
                        setTimeout(() => {
                            // const deletearray = [...guarantors] // กระจายข้อมูลเข้าไปในตัวแปร deletearray
                            // deletearray.splice(index, 1); // เป็นการลบข้อมูลใน Array ตาม index ที่เลือกตาม Card
                            // // dispatch(addGuarantor(deletearray))
                            // setGuarantors(deletearray);  // เซตค่าเข้าไปเก็บใน dataArray
                            message.success('ลบข้อมูลคนค้ำสำเร็จ')
                            if (guarantors.length === 1) {
                                setAddCount(2)
                                // console.log("1")
                            } else {
                                // console.log("2")
                                const data = guarantors.filter(item => item.garno !== index + 1);

                                // เปลี่ยน "garno" ที่มีค่าเป็น 3 เป็น 2
                                data.forEach((item, index) => {
                                    item.garno = index + 1;
                                });
                                setGuarantors(data)
                                setAddCount(2)
                            }
                        }, 1500)
                        setLoading(false)
                    })
                    .catch((err) => {
                        console.log("err", err)
                        setLoading(false)
                    })
                setLoading(false)

            }

        }

        const handleNoti = async (path) => {
            const ez = { nameInput: un, path: path }
            await axios.post(storeDBLine, ez)
                .then(res => {
                    if (res.data) {
                        console.log("OK")
                    }
                })
                .catch((err) =>
                    console.log(err)
                )
            setLoading(false)
        }

        const del = async ({ dataedit, folder }) => {
            const mix = {
                inPath: dataedit.pathImg, // pathImg:"one_images/land/894/5/2"
                guaImg: folder,
            }
            // console.log("mix", mix)
            await axiosDeleteGua({ mix })
            await handleNoti(dataedit.pathImg)
        }

        const handleDelete = (dataedit, index) => {
            // console.log("dataedit", dataedit)
            confirm({
                title: 'คุณต้องการที่จะลบข้อมูลหรือไม่...?',
                content: 'กด OK เพื่อยืนยันการลบคนค้ำประกันและรูปภาพทั้งหมดของผู้กู้',
                onOk() {
                    showConfirmDelete({ dataedit, index })
                },
                onCancel() {
                    message.error('ยกเลิกการลบข้อมูล')
                },
            });
        };

        return (
            <Form
                name="basic"
                labelCol={{
                    span: 12,
                }}
                wrapperCol={{
                    span: 12,
                }}
                style={{
                    maxWidth: '100%',
                }}
                initialValues={{
                    remember: true,
                }}
                form={form1}
                onFinish={handleSubmit}
                autoComplete="off"
            >
                <Card style={{ width: '100%' }}>
                    <Row justify={'center'}><b><u>คนค้ำที่ {index + 1}</u></b></Row>
                    <Col span={24}>
                        <Form.Item style={{ margin: 0 }} label='เลขบัตรประชาชน' >
                            <b>{item?.identificationId}</b>
                        </Form.Item>
                    </Col>
                    <Row justify={'center'} gutter={32} className='main2'>
                        <Col className='gutter-row' span={12}>
                            <Form.Item style={{ margin: 0 }} label='ชื่อ-สกุล' >
                                <b>{item?.firstname}</b>
                            </Form.Item>
                            <Form.Item style={{ margin: 0 }} label='เพศ' >
                                <b>{item?.gender === "M" ? "ชาย" : "หญิง"}</b>
                            </Form.Item>
                            <Form.Item style={{ margin: 0 }} label='ความสัมพันกับผู้กู้' >
                                <b>{item?.description}</b>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item style={{ margin: 0 }} label='สกุล' >
                                <b>{item?.lastname}</b>
                            </Form.Item>
                            <Form.Item style={{ margin: 0 }} label='ชื่อเล่น' >
                                <b>{item?.nickname}</b>
                            </Form.Item>
                            {item?.phones?.length >= 1 ?
                                <>
                                    <Form.Item label='เบอร์โทร' style={{ margin: 0 }}>
                                        <b>{item?.phones[0].telp}</b>
                                    </Form.Item>
                                </>
                                :
                                <>
                                    <Form.Item label='เบอร์โทร' style={{ margin: 0 }}>
                                        <b>{item?.phones}</b>
                                    </Form.Item>
                                </>

                            }
                        </Col>
                        <Divider style={{ margin: 5 }} />
                    </Row>
                    <Row gutter={32} justify={'center'} >
                        {/* <span>รูปภาพ : </span> */}
                        {/* <Col span={12} > */}
                        {/* <Form.Item label='รูปภาพ' style={{ margin: 0 }}> */}
                        {/* <b>{item?.phones}</b> */}
                        {itemSplit.length > 0 ? (
                            <>
                                {getimages?.map((items, index) => {
                                    //console.log("items", items)
                                    const segments = items.pathImage.split("/")
                                    if (itemSplit[4] === segments[4]) {
                                        return (
                                            // <Space>
                                            <Image width={'100px'} key={index} src={`data:${items?.Attr};base64,${items?.data?.toString('base64')}`} alt={`Image ${index + 1}`} />
                                            // {/* </Space> */}
                                        )
                                    }
                                })}
                            </>
                        ) : null
                        }
                        {/* </Form.Item> */}
                        {/* </Col> */}
                    </Row>
                    <Divider style={{ margin: 5 }} />
                    <Row justify={'center'}>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <Space>
                                <Button type="primary" danger onClick={() => { handleDelete(guarantors[index], index); }}>
                                    ลบคนค้ำ
                                </Button>
                                <Button type="primary" style={{ backgroundColor: '#DCD9D4', color: "black" }} onClick={() => { handleEdit(guarantors[index], index); }}>
                                    แก้ไขคนค้ำ
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Card>
            </Form>
        )
    }

    const genGuarantorsEditGarno = ({ item, index }) => {

        return (
            <Form
                name="basic"
                labelCol={{
                    span: 12,
                }}
                wrapperCol={{
                    span: 24,
                }}
                style={{
                    maxWidth: '100%',
                }}
                initialValues={{
                    remember: true,
                }}
                form={form1}
                autoComplete="off"
            >
                <Card style={{ width: '100%' }}>
                    <Row justify={'center'}>
                        <Col span={18}>
                            <Row justify={'center'}><b><u>คนค้ำที่ {item.garno}</u></b></Row>
                            {/* <Row justify={'center'}><b><u>คนค้ำที่ {index + 1}</u></b></Row> */}
                            <Row>
                                <Col span={12}> <Form.Item style={{ margin: 0 }} label='ชื่อ-สกุล' >
                                    <b>{item?.firstname} {item?.lastname}</b>
                                </Form.Item>
                                    <Form.Item style={{ margin: 0 }} label='ชื่อเล่น' >
                                        <b>{item?.nickname}</b>
                                    </Form.Item>
                                    <Form.Item style={{ margin: 0 }} label='ความสัมพันกับผู้กู้' >
                                        <b>{item?.description}</b>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item style={{ margin: 0 }} label='เลขบัตรประชาชน' >
                                        <b>{item?.identificationId}</b>
                                    </Form.Item>

                                    <Form.Item style={{ margin: 0 }} label='เพศ' >
                                        <b>{item?.gender === "M" ? "ชาย" : "หญิง"}</b>
                                    </Form.Item>
                                    {
                                        item?.phones?.length >= 1 ?
                                            <>
                                                <Form.Item label='เบอร์โทร' style={{ margin: 0 }}>
                                                    <b>{item?.phones[0].telp}</b>
                                                </Form.Item>
                                            </>
                                            :
                                            <>
                                                <Form.Item label='เบอร์โทร' style={{ margin: 0 }}>
                                                    <b>{item?.phones}</b>
                                                </Form.Item>
                                            </>
                                    }
                                </Col>
                            </Row>
                        </Col>
                        <Col span={6} style={{ textAlign: 'right' }}>
                            {
                                checkEditGarno === true ?
                                    <>
                                        <Row justify={'center'}>
                                            <Button icon={<UpOutlined />} onClick={() => handleSwap(index, index - 1)} disabled={index === 0}>
                                                เลื่อนขึ้น
                                            </Button>
                                        </Row>
                                        <Row justify={'center'}>
                                            <Button icon={<DownOutlined />} onClick={() => handleSwap(index, index + 1)} disabled={index === guarantors.length - 1}>
                                                เลื่อนลง
                                            </Button>
                                        </Row>
                                    </>
                                    : null
                            }
                        </Col>
                    </Row>
                </Card >
            </Form >
        )
    }

    return (
        <>
            <Row justify={'center'}>
                <Card style={{ width: '1100px' }}>

                    <Col style={{ textAlign: 'center' }} >
                        <b><h2>ข้อมูลคนค้ำประกัน</h2></b>
                    </Col>
                    <Spin spinning={loading} size='large' tip=" Loading... ">
                        <Row justify={'center'}>
                            <Form
                                name="basic"
                                labelCol={{
                                    span: 12,
                                }}
                                wrapperCol={{
                                    span: 24,
                                }}
                                style={{
                                    width: '100%',
                                }}
                                initialValues={{
                                    remember: true,
                                }}
                                form={form}
                                onFinish={handleSubmit}
                                autoComplete="off"
                            >
                                <Row >
                                    <Col span={24}>
                                        {
                                            guarantor.data.typeLoan === "car" ?
                                                <Row>
                                                    {
                                                        getData?.carLoanDetails?.notPass?.loanId !== 0 ?
                                                            <>
                                                                {
                                                                    getData?.carLoanDetails?.notPass?.npGuarantor === false ?
                                                                        <>
                                                                            <Col span={12}>
                                                                                <Form.Item label='หมายเหตุ(ธุรการ)' name='npGuarantorNote' style={{ textAlign: 'center' }}>
                                                                                    <TextArea rows={5} name='npGuarantorNote' disabled />
                                                                                </Form.Item>
                                                                            </Col>
                                                                        </>
                                                                        : null
                                                                }
                                                            </>
                                                            : null
                                                    }
                                                    {
                                                        getData?.carLoanDetails?.noteResend?.loanId !== 0 ?
                                                            <>
                                                                {
                                                                    getData?.carLoanDetails?.noteResend?.guarantorNote !== null ?
                                                                        <>
                                                                            <Col span={12}>
                                                                                <Form.Item label='หมายเหตุ(การตลาด)' name='guarantorNote' style={{ textAlign: 'center' }}>
                                                                                    <TextArea rows={5} name='guarantorNote' disabled />
                                                                                </Form.Item>
                                                                            </Col>
                                                                        </>
                                                                        : null
                                                                }
                                                            </>
                                                            : null
                                                    }
                                                </Row>
                                                :
                                                <Row>
                                                    {
                                                        getData?.landLoanDetails?.notPass?.loanId !== 0 ?
                                                            <>
                                                                {
                                                                    getData?.landLoanDetails?.notPass?.npGuarantor === false ?
                                                                        <>
                                                                            <Col span={12}>
                                                                                <Form.Item label='หมายเหตุ(ธุรการ)' name='npGuarantorNote' style={{ textAlign: 'center' }}>
                                                                                    <TextArea rows={5} name='npGuarantorNote' disabled />
                                                                                </Form.Item>
                                                                            </Col>
                                                                        </>
                                                                        : null
                                                                }
                                                            </>
                                                            : null
                                                    }
                                                    {
                                                        getData?.landLoanDetails?.noteResend?.loanId !== 0 ?
                                                            <>
                                                                {
                                                                    getData?.landLoanDetails?.noteResend?.guarantorNote !== null ?
                                                                        <>
                                                                            <Col span={12}>
                                                                                <Form.Item label='หมายเหตุ(การตลาด)' name='guarantorNote' style={{ textAlign: 'center' }}>
                                                                                    <TextArea rows={5} name='guarantorNote' disabled />
                                                                                </Form.Item>
                                                                            </Col>
                                                                        </>
                                                                        : null
                                                                }
                                                            </>
                                                            : null
                                                    }
                                                </Row>
                                        }
                                    </Col>
                                </Row>
                                <Divider style={{ margin: 5 }} />
                                {
                                    checkEditGarno === false ?
                                        <>
                                            <Row>
                                                <Col span={24}>
                                                    {
                                                        guarantors?.length > 0 ?
                                                            <>
                                                                <Space direction="vertical" style={{ display: 'flex', }}>
                                                                    {
                                                                        guarantors?.map((item, index) => {
                                                                            return genGuarantors({ item, index, key: `{item.guarantors} - ${index}` });
                                                                        })}
                                                                </Space>

                                                            </>
                                                            :
                                                            null
                                                    }
                                                </Col>
                                            </Row>
                                        </>
                                        :
                                        <>
                                            <Row>
                                                <Col span={24}>
                                                    {
                                                        guarantors?.length > 0 ?
                                                            <>
                                                                <Space direction="vertical" style={{ display: 'flex', }}>
                                                                    {guarantors?.map((item, index) => {
                                                                        return genGuarantorsEditGarno({ item, index, key: `{item.guarantors} - ${index}` });
                                                                    })}
                                                                </Space>
                                                            </>
                                                            :
                                                            null
                                                    }
                                                </Col>

                                            </Row>

                                        </>
                                }


                                <Row justify={'center'} style={{ margin: 10 }} align={'middle'}>
                                    <Col span={24} style={{ textAlign: 'center' }}>
                                        {/* <Form.Item label='' name='imgGuarantor'> */}
                                        {/* <Space> */}
                                        {
                                            checkEditGarno === false ?
                                                <>
                                                    <Row justify={'center'} >
                                                        <Button type='primary' onClick={handleOpen} icon={<PlusOutlined />}>เพิ่มคนค้ำ</Button>
                                                    </Row>
                                                    {
                                                        guarantors?.length > 1 ?
                                                            <>
                                                                <Row>
                                                                    <Col span={24} style={{ textAlign: 'right' }}>
                                                                        <Button type='primary' style={{ backgroundColor: "#faad14", color: "black" }} onClick={handleOpenEditGarno} icon={<EditOutlined />}>แก้ลำดับคนค้ำ</Button>
                                                                    </Col>

                                                                </Row>
                                                            </>
                                                            : null
                                                    }
                                                </>

                                                :
                                                <>
                                                    <Space>
                                                        <Button type='primary' style={{ backgroundColor: "green" }} onClick={() => handleEditGarno(true)}>บันทึกลำดับผู้ค้ำ</Button>
                                                        <Button type='primary' danger onClick={handleCloseEditGarno} icon={<StopOutlined />}>ยกเลิกลำดับคนค้ำ</Button>
                                                    </Space>
                                                </>
                                        }
                                        {/* </Space> */}
                                        {/* </Form.Item> */}
                                    </Col>
                                </Row>
                                <Row justify={'center'}>
                                    <h3><b style={{ color: 'red' }}>***ตรวจสอบข้อมูลคนค้ำว่าถูกต้อง แล้วกดต่อไป***</b></h3>
                                </Row>
                                <Divider style={{ margin: 5 }} />
                                <Row justify={'center'} >
                                    <Space>
                                        {/* <Button type="text" style={{ background: "#e0e0e0" }} onClick={(e) => { handleGoHome(e) }}>
                                            ย้อนกลับ
                                        </Button> */}
                                        <Button type="primary" htmlType="submit">
                                            ต่อไป
                                        </Button>
                                    </Space>
                                </Row>

                            </Form>
                        </Row>
                    </Spin >
                </Card >
            </Row >
            {contextHolder}
            {
                modalEditGuarantor ?
                    <ModalEditGuarantor
                        open={modalEditGuarantor}
                        close={setModalEditGuarantor}
                        shootdata={dataedit} // โยนค่าไปหน้าแก้ไข ตาม index 
                        infor={infor} //ข้อมูลที่มีอยู่โยนไปหน้าเพิ่มคนค้ำ
                        getimages={getimages}
                        dataindex={index1}
                        setSendback={setSendback}
                        indexedit={indexedit}
                    // guarantors={guarantors}
                    />
                    : null
            }
            {
                openAddGuarantor ?
                    <ModalAddGuarantor
                        // <AddGuarantoesNewAd
                        open={openAddGuarantor}
                        close={setOpenAddGuarantor}
                        pregarno={guarantors.length}
                        indexedit={indexedit}
                        dataloadGua={saveTypeLoan}
                        setSendback={setSendback}
                        getData={getData}
                        oData={guarantors}
                    />
                    :
                    null
            }
        </>
    )
}

export default AddGuarantorsAd