import { PlusOutlined } from '@ant-design/icons'
import { Button, Card, Divider, Form, Row, Space, Spin, Modal, Col, message, Image, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import axios from 'axios';
// import { useDispatch } from 'react-redux';
import { addGuarantor } from '../../../redux/Guarantor';
import AddGua from './guarantor/AddGua';
import EditGua from './guarantor/EditGua';
import { getAllFolderGuaImg, deleteFolderImgGua } from '../../file_mid/all_api';

function GuarantorLand({ close, changepage, data, page  }) {

    const addGuarantors = localStorage.getItem('addGuarantor')
    const dataAddGuarantor = JSON.parse(addGuarantors)
    const [getimages, setGetImages] = useState([]);
    const [api, contextHolder] = notification.useNotification();
    const [modaleditGuarantor, setModalEditGuarantor] = useState(false) // customer
    const { confirm } = Modal
    const [loading, setLoading] = useState(false)
    const [isModalAddGua, setIsModalAddGua] = useState(false)
    const [isModalEditGua, setIsModalEditGua] = useState(false)
    const [guarantors, setGuarantors] = useState([]);

    const [dataedit, setDataEdit] = useState([]); //เซตสำหรับแก้ไขข้อมูล 
    const [index1, setIndex] = useState(); //set index send to page edit
    const [indexedit, setIndexEdit] = useState(0); //set index send to page edit 
    // const dispatch = useDispatch();
    const [form1] = Form.useForm()
    const token = localStorage.getItem('token');
    const [sendB, setSendB] = useState()

    useEffect(() => {
        getImg()
    }, [dataedit, indexedit, modaleditGuarantor, sendB])

    useEffect(() => {
        if (dataAddGuarantor?.length > 0) {
            setGuarantors(dataAddGuarantor)
            countType()
        }
    }, [])

    const onChangeAddGua = () => {
        setIsModalAddGua(true)
    }

    const handleGo = () => {
        changepage(page + 1)
    }
    const handleBack = () => {
        changepage(page - 1)
    }
    const handleClose = () => {
        close()
    }

    const countType = async () => {
        const lastItem = dataAddGuarantor[dataAddGuarantor.length - 1];
        const newType = lastItem.pathImg.split("/")
        const intTpye = parseInt(newType[4])
        setIndexEdit(intTpye)
    }

    const getImg = async () => {
        const path = `one_images/land/${data.landId}/5`
        setLoading(true)
        await axios.get(`${getAllFolderGuaImg}/${path}?token=${token}`)
            .then(res => {
                if (res.data) {
                    setGetImages(res.data)
                    setLoading(false)
                }
            })
            .catch((err) => {
                console.log("err", err)
                setLoading(false)
            })
        setLoading(false)
    }

    const showConfirmCancel = () => {
        close(false)
    };

    const handleSubmit = async () => {
        setLoading(true)
        if (guarantors.length > 0) {
            if (guarantors !== undefined) {
                let checkphones = 0
                let checknickname = 0
                guarantors.map((e, index) => {
                    if (e.nickname === undefined) {
                        checkphones = 1
                        const CheckAddrGua = (placement) => {
                            api.error({
                                message: <b>กรุณาเพิ่มที่อยู่ของผู้ค้ำ</b>,
                                description: `กรุณาเพิ่มที่อยู่ผู้ค้ำ ลำดับที่ ${index + 1}`,
                                placement,
                            });
                        }
                        CheckAddrGua("top")
                    }
                    if (e.phones === undefined) {
                        checknickname = 1
                        const CheckPhnGua = (placement) => {
                            api.error({
                                message: <b>กรุณาเพิ่มเบอร์โทรศัพท์ของผู้ค้ำ</b>,
                                description: `กรุณาเพิ่มเบอร์โทรศัพท์ของผู้ค้ำ ลำดับที่ ${index + 1}`,
                                placement,
                            });
                        }
                        CheckPhnGua("top")
                    }
                })
                if (checkphones !== 1 && checknickname !== 1) {
                    // dispatch(addGuarantor(guarantors))
                    localStorage.setItem('addGuarantor', JSON.stringify(guarantors))
                    handleGo()

                }
            } else {
                // dispatch(addGuarantor(guarantors))
                localStorage.setItem('addGuarantor', JSON.stringify(guarantors))
                handleGo()

            }

        } else {
            alert('กรุณาเพิ่มคนค้ำ')
        }

        setLoading(false)
    }

    const getAddData = ({ AddData2, indexedit1 }) => {
        // console.log('AddData2', AddData2, indexedit1)
        // console.log('indexedit1 + 1', indexedit1 + 1)
        setIndexEdit(indexedit1 + 1)

        if (guarantors?.length === 0) {
            const AddDatanew = AddData2    //ประกาศตัวแปรเพื่อเก็บค่าที่เพิ่มเข้ามาไปเซตให้ dataArray
            // console.log('1')
            setGuarantors([...AddDatanew]);
        } else if (guarantors?.length >= 1) {
            // console.log('2')
            const AddDatanew = AddData2   //ประกาศตัวแปรเพื่อเก็บค่าที่เพิ่มเข้ามาไปเซตให้ dataArray
            setGuarantors([...guarantors, ...AddDatanew]);//เก็บค่า state เดิมไว้ พร้อมส่ง type ไปด้วย เพื่อบอกค่าที่ส่ง
        } else {
            // console.log('AddData2 indexedit1', AddData2, indexedit1)
        }

    }

    // แก้ไขข้อมูลจากหน้า AddressEditData
    const fucnEdit = (data, getIndex) => {
        let Theedit = [...guarantors]
        Theedit[getIndex] = data[0]
        //ข้อมูลใหม่แทนที่ข้อมูลเดิมในช่อง Input
        setGuarantors([...Theedit]);
    }

    const genGuarantors = ({ item, index }) => {

        var itemSplit = []
        if (item?.pathImg) {
            itemSplit = item.pathImg.split("/")
        }
        const handleEdit = (dataedit, index1) => {
            setIndex(index1) //เซตค่า index ให้กับชุด ข้อมูล
            setDataEdit({ dataedit, index1 }) //ส่งข้อมูลด index ไปหน้าแก้ไข
            setIsModalEditGua(true);
        }

        // modal deleteImage
        const showConfirmDelete = async ({ dataedit, index }) => {
            setLoading(true)
            const mix = { inPath: dataedit.pathImg }
            await axios.delete(deleteFolderImgGua, { data: mix })
                .then(res => {
                    setTimeout(() => {
                        const deletearray = [...guarantors] // กระจายข้อมูลเข้าไปในตัวแปร deletearray
                        deletearray.splice(index, 1); // เป็นการลบข้อมูลใน Array ตาม index ที่เลือกตาม Card
                        // dispatch(addGuarantor(deletearray))
                        localStorage.setItem('addGuarantor', JSON.stringify(deletearray))
                        setGuarantors(deletearray);  // เซตค่าเข้าไปเก็บใน dataArray
                        message.success('ลบข้อมูลคนค้ำสำเร็จ')
                    }, 1500)
                    setLoading(false)
                })
                .catch((err) => {
                    console.log("err", err)
                    setLoading(false)
                })
            setLoading(false)


        }

        const showConfirm = (dataedit, index) => {
            confirm({
                title: 'คุณต้องการที่จะลบคนค้ำ...?',
                onOk() {
                    handleDelete(dataedit, index)
                },
                onCancel() {
                    // message.success('ยกเลิก')
                },
            });
        };

        const handleDelete = (dataedit, index) => {
            showConfirmDelete({ dataedit, index })
        }


        return (
            <Form
                name="basic"
                labelCol={{
                    span: 12,
                }}
                wrapperCol={{
                    span: 24,
                }}
                style={{
                    maxWidth: '100%',
                }}
                initialValues={{
                    remember: true,
                }}
                form={form1}
                onFinish={handleSubmit}
                autoComplete="off"
            >
                <Card style={{ width: '100%' }}                >
                    <Row justify={'center'}><b><u>คนค้ำที่ {index + 1}</u></b></Row>
                    <div style={{ textAlign: 'center' }}>
                        <Form.Item style={{ margin: 3 }} label='' >
                            <span>เลขบัตรประชาชน : </span>
                            <b>{item?.identificationId}</b>
                        </Form.Item>
                    </div>
                    <Row justify={'center'} gutter={32} className='main2'>
                        <Col className='gutter-row' span={24}>
                            <aside style={{ width: '100%', }} >
                                <div style={{ textAlign: 'left' }}>
                                    <Form.Item style={{ margin: 3 }} label='' >
                                        <span>ชื่อ : </span>
                                        <b>{item?.firstname}</b>
                                    </Form.Item>
                                </div>
                                <div style={{ textAlign: 'left' }}>
                                    <Form.Item style={{ margin: 3 }} label='' >
                                        <span>นามสกุล : </span>
                                        <b>{item?.lastname}</b>
                                    </Form.Item>
                                </div>
                                <div style={{ textAlign: 'left' }}>
                                    <Form.Item style={{ margin: 3 }} label='' >
                                        <span>ชื่อเล่น : </span>
                                        <b>{item?.nickname}</b>
                                    </Form.Item>
                                </div>
                                <div style={{ textAlign: 'left' }}>
                                    {item?.phones?.length >= 1 ?
                                        <>
                                            <Form.Item label='' style={{ margin: 3 }}>
                                                <span>เบอร์โทร : </span>
                                                <b>{item?.phones[0].telp}</b>
                                            </Form.Item>
                                        </>
                                        :
                                        <Form.Item label='' style={{ margin: 0 }}>
                                            <span>เบอร์โทร : </span>
                                            <b>{item?.phones}</b>
                                        </Form.Item>
                                    }
                                </div>
                                {/* <div style={{ textAlign: 'left' }}>
                                    <Form.Item style={{ margin: 3 }} label=''>
                                        <span>วันเกิด : </span>
                                        <b>{item?.birthdate}</b>
                                    </Form.Item>
                                </div> */}
                                <div style={{ textAlign: 'left' }}>
                                    <Form.Item style={{ margin: 3 }} label='' >
                                        <span>เพศ : </span>
                                        <b>{item?.gender}</b>
                                    </Form.Item>
                                </div>
                            </aside>
                        </Col>
                        <Divider style={{ margin: 5 }} />
                    </Row>
                    <Row gutter={32} justify={'center'}>
                        <span>รูปภาพ : </span>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            {
                                itemSplit.length > 0 ? (
                                    <>
                                        {getimages?.map((items, index) => {
                                            const segments = items.pathImage.split("/")
                                            if (itemSplit[4] === segments[4]) {
                                                return (
                                                    // <img width={'300px'} key={index} style={{ margin: 5 }} src={createImageUrl(items.image64)} alt={items.filename} />
                                                    <Image width={'100px'} key={index} src={`data:${items?.Attr};base64,${items?.data?.toString('base64')}`} alt={`Image ${index + 1}`} />
                                                )
                                            }

                                        })}
                                    </>
                                ) : null
                            }
                        </Col>
                    </Row>
                    <Divider style={{ margin: 5 }} />
                    <Row justify={'center'}>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <Space>
                                <Button type="text"
                                    style={{ background: "red", color: 'white' }}
                                    onClick={() => { showConfirm(guarantors[index], index); }}
                                >
                                    ลบ
                                </Button>
                                <Button type="text"
                                    style={{ background: "#e0e0e0" }}
                                    onClick={() => { handleEdit(guarantors[index], index); }}
                                >
                                    แก้ไข
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Card>
            </Form>
        )
    }

    return (
        <Card style={{ width: '100%' }}>
            <Spin spinning={loading} size='large' tip=" Loading... ">
                <Row justify={'center'}> <b><h2>คนค้ำประกัน</h2></b></Row>
                <Divider />
                <Row justify={'center'}>
                    <Col span={24} style={{ textAlign: "right" }}>
                        <Button icon={<PlusOutlined />} onClick={onChangeAddGua}>เพิ่มคนค้ำ</Button>
                    </Col>
                    {/* <Col span={24}>

                    </Col> */}
                    <Divider />
                    <Row justify={'center'}>
                        {guarantors?.length > 0 ?
                            <>
                                {guarantors?.map((item, index) => {
                                    return genGuarantors({ item, index, key: `{item.identificationId} - ${index}` });
                                })}
                            </>
                            :
                            null
                        }
                    </Row>
                </Row>
                <Divider />
                <Row justify={'center'} style={{ marginTop: '15px' }} >
                    <Space>
                        <Button type="primary" style={{ background: "#bfbfbf" }} onClick={handleBack}>
                            ย้อนกลับ
                        </Button>
                        <Button type="primary" style={{ background: "red" }} onClick={showConfirmCancel}>
                            ยกเลิก
                        </Button>
                        <Button type="primary" style={{ background: "green" }} onClick={handleSubmit} >
                            ต่อไป
                        </Button>
                    </Space>
                </Row>
                {/* </Form> */}
                {contextHolder}
            </Spin>
            {
                isModalAddGua ?
                    <AddGua
                        open={isModalAddGua}
                        close={setIsModalAddGua}
                        dataGuarantor={guarantors} //ข้อมูลที่มีอยู่โยนไปหน้าเพิ่มคนค้ำ
                        getAddData={getAddData}
                        dataindex={index1}
                        indexedit={indexedit}
                        datafromtable={data}
                    />
                    : null
            }
            {
                isModalEditGua ?
                    <EditGua
                        open={isModalEditGua}
                        close={setIsModalEditGua}
                        sb={setSendB}
                        dataGuarantor={guarantors}
                        shootdata={dataedit} // 
                        fucnEdit={fucnEdit}
                        dataindex={index1}
                        datafromtable={data}
                    />
                    : null
            }
        </Card >
    )
}

export default GuarantorLand