import React, { useState, useEffect } from 'react'
import { Form, Input, Row, Col, Button, Select, Space, Radio, DatePicker, Card } from 'antd'
import axios from 'axios'
import dayjs from 'dayjs';
import { edit } from '../../file_mid/all_api';

function EditCustomer({ getData, onCancel, changepage, sendback, notification, notification2 }) {

    const [holds, setHolds] = useState();
    const [, setTypeCompany] = useState('หจก.');
    const [, setTypeCompanyed] = useState('หจก.');
    const [dataModalEdit, setDataModalEdit] = useState(getData)
    const [form] = Form.useForm();

    useEffect(() => {
        setDataModalEdit(getData)
        setHolds(getData?.nationalId)
        if (getData !== "") {
            form.setFieldsValue(
                {
                    ...getData,
                    birthDate: dayjs(getData?.birthDate),
                    issudt: dayjs(getData?.issudt),
                    expdt: dayjs(getData?.expdt),
                }
            )
        }
    }, [getData])
    const onChangeGo = () => {
        changepage(2)
    }
    const handleCancel = () => {
        onCancel(false);
    }

    const handleSubmit = async () => {
        await axios.put(edit, dataModalEdit)
            .then(res => {
                if (res.status === 200) {
                    sendback(dayjs())
                    notification('top')
                    setTimeout(() => {
                        changepage(2)
                    }, 1000)
                } else {
                    notification2('top')
                }
            }).catch((err) => console.log(err))
    };

    const handleProduct = (value) => {
        setHolds(value)
        setDataModalEdit({ ...dataModalEdit, nationalId: value })
    }
    const handletypeCompany = (value) => {
        setTypeCompany(value)
        setDataModalEdit({ ...dataModalEdit, snam: value })
    }
    const handletypeCompanyed = (value) => {
        setTypeCompanyed(value)
        setDataModalEdit({ ...dataModalEdit, lastname: value })
    }
    const inputTaxId = (e) => {
        setDataModalEdit({ ...dataModalEdit, identificationId: e.target.value })
    }
    return (
        <div>
            <Row justify={'center'}>
                <Row justify={'center'} style={{ margin: '5px', fontSize: '18px' }} >แก้ไขข้อมูลส่วนตัว</Row>
                <Card style={{ width: '900px' }}>
                    {/* <Spin spinning={loading} size='large' tip=" Loading... "> */}
                    {/* <Divider orientation="center" plain>
                        <h3></h3>
                    </Divider> */}
                    <Form
                        form={form}
                        labelCol={{
                            span: 12,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                        onFinish={handleSubmit}>
                        <Row gutter={32} justify={'center'}>
                            <Col span={24}>
                                <Row justify={'center'}>
                                    <Form.Item label='ประเภท' name='nationalId'
                                        style={{ textAlign: 'center', width: '300px' }}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input !',
                                            },]}>
                                        <Select
                                            disabled
                                            placeholder={'เลือก'}
                                            style={{ textAlign: 'center', width: '250px', height: '40px', }}
                                            defaultValue={'บุคคลธรรมดา'}
                                            name='legal'
                                            onChange={(value) => handleProduct(value)}
                                            options={[
                                                {
                                                    label: 'บุคคลธรรมดา',
                                                    value: 'บุคคลธรรมดา',
                                                },
                                                {
                                                    label: 'นิติบุคคล',
                                                    value: 'นิติบุคคล',
                                                },
                                            ]}
                                        />
                                    </Form.Item>
                                </Row>
                                {holds === "นิติบุคคล" ?
                                    <>
                                        <Row className='main2' justify={'center'}>
                                            <Col span={24}>
                                                <aside style={{ width: '85%' }}>
                                                    <div>
                                                        <Form.Item label='ประเภทห้าง' name='snam'
                                                            style={{ textAlign: 'center', width: '300px' }}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !',
                                                                },]}>
                                                            <Select
                                                                placeholder={'เลือก'}
                                                                style={{ width: '250px', height: '40px', }}
                                                                name='snam'
                                                                initialvalues={1}
                                                                onChange={(value) => handletypeCompany(value)}
                                                                options={[
                                                                    {
                                                                        label: 'หจก.',
                                                                        value: 'หจก',
                                                                    },
                                                                    {
                                                                        label: 'บริษัท',
                                                                        value: 'บริษัท',
                                                                    },
                                                                ]}
                                                            />


                                                        </Form.Item>
                                                    </div>
                                                    <div>
                                                        <Form.Item
                                                            label='เลขประจำตัวผู้เสียภาษี'
                                                            name='identificationId'
                                                            style={{ textAlign: 'center', width: '300px' }}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !',
                                                                },]}>

                                                            <Input disabled type='text' maxLength={13}
                                                                name='identificationId'
                                                                // disabled={CheckIdCard ? false : true}
                                                                style={{ width: '250px', marginRight: '5px', marginLeft: '5px', color: 'black' }}
                                                                //  onChange={(e) => setDataPost({ ...dataPost, taxID: e.target.value }, isThaiNationalID(e.target.value))}></Input>
                                                                onChange={(e) => inputTaxId(e)}></Input>
                                                        </Form.Item>

                                                    </div>

                                                    {/* {typeCompany } */}
                                                    <div>
                                                        <Form.Item label='ชื่อบริษัท' name='firstname'
                                                            style={{ textAlign: 'center', width: '300px' }}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !',
                                                                },]}
                                                        >
                                                            <Input type='text'
                                                                style={{ width: '250px', color: 'black' }}
                                                                onChange={(e) => setDataModalEdit({ ...dataModalEdit, firstname: e.target.value })}>

                                                            </Input>

                                                        </Form.Item>
                                                    </div>
                                                    <div>
                                                        <Form.Item label='' name='lastname'
                                                            style={{ textAlign: 'center', width: '300px' }}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !',
                                                                },]}
                                                        >
                                                            <Select
                                                                placeholder={'เลือก'}
                                                                style={{ textAlign: 'left', height: '40px', width: '250px' }}
                                                                name='lastname'
                                                                initialvalues={'จำกัด'}
                                                                onChange={(value) => handletypeCompanyed(value)}
                                                                options={[
                                                                    {
                                                                        label: 'มหาชน',
                                                                        value: 'มหาชน',
                                                                    },
                                                                    {
                                                                        label: 'จำกัด',
                                                                        value: 'จำกัด',
                                                                    },
                                                                ]}
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                    <div>
                                                        <Form.Item label='วันจดทะเบียน' name='birthDate'
                                                            style={{ textAlign: 'center', width: '300px' }}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !',
                                                                },]}>
                                                            <DatePicker format={'YYYY-MM-DD'}
                                                                style={{ height: '40px', width: '250px', color: 'black' }}
                                                                onChange={(e) => {
                                                                    if (e) {
                                                                        setDataModalEdit({ ...dataModalEdit, birthDate: e.format('YYYY-MM-DD') })
                                                                    }
                                                                }} />
                                                        </Form.Item>
                                                    </div>
                                                </aside>
                                            </Col>
                                        </Row>

                                    </>
                                    :
                                    <>
                                        <Row className='main2' justify={'center'}>
                                            <Col span={24} style={{ textAlign: 'center', marginLeft: '15px' }}>
                                                <aside style={{ width: '85%' }}>
                                                    <Form.Item label='เลขประจำตัวประชาชน' name='identificationId'
                                                        style={{ width: '300px' }}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !',
                                                            },]}>
                                                        <Input disabled type='text' style={{ width: '250px' }} onChange={e => setDataModalEdit({ ...dataModalEdit, identificationId: e.target.value })} />
                                                    </Form.Item>
                                                    <Form.Item label='คำนำหน้าชื่อ' name='snam'
                                                        style={{ width: '300px' }}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !',
                                                            },]}>
                                                        <Radio.Group style={{ width: '250px' }} onChange={(e) => setDataModalEdit({ ...dataModalEdit, snam: e.target.value })} >
                                                            <Radio value="นาย"> นาย </Radio>
                                                            <Radio value="นาง"> นาง </Radio>
                                                            <Radio value="นางสาว"> นางสาว </Radio>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                    <Form.Item label='ชื่อ' name='firstname' style={{ width: '300px' }}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !',
                                                            },]}>
                                                        <Input type='text' style={{ width: '250px' }} onChange={e => setDataModalEdit({ ...dataModalEdit, firstName: e.target.value })} />
                                                    </Form.Item>
                                                    <Form.Item label='นามสกุล' name='lastname' style={{ width: '300px' }}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !',
                                                            },]}>
                                                        <Input type='text' style={{ width: '250px' }} onChange={e => setDataModalEdit({ ...dataModalEdit, lastName: e.target.value })} />
                                                    </Form.Item>
                                                    <Form.Item label='ชื่อเล่น' name='nickname' style={{ width: '300px' }}>
                                                        <Input type='text' style={{ width: '250px' }} onChange={e => setDataModalEdit({ ...dataModalEdit, nickname: e.target.value })} />
                                                    </Form.Item>
                                                    <Form.Item label='เกิดวันที่' name='birthDate' style={{ width: '300px' }}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !',
                                                            },]}>
                                                        <DatePicker
                                                            format={'YYYY-MM-DD'}
                                                            style={{ width: '250px', height: "40px" }}
                                                            name="birthDate"
                                                            onChange={(e) => {
                                                                if (e) {
                                                                    setDataModalEdit({ ...dataModalEdit, birthDate: e.format('YYYY-MM-DD') })
                                                                }
                                                            }} />
                                                    </Form.Item>
                                                    <Form.Item label='เพศ' name='gender' style={{ width: '300px' }}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !',
                                                            },]}>
                                                        <Select

                                                            style={{ width: '250px', height: "40px" }}
                                                            onChange={(value) => setDataModalEdit({ ...dataModalEdit, gender: value })}
                                                            options={[
                                                                {
                                                                    label: 'ชาย',
                                                                    value: 'M',
                                                                },
                                                                {
                                                                    label: 'หญิง',
                                                                    value: 'F',
                                                                },
                                                            ]}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item label='เจ้าพนักงานออกบัตร' name='issuby' style={{ width: '300px' }}>
                                                        <Input type='text' style={{ width: '250px' }} onChange={e => setDataModalEdit({ ...dataModalEdit, issuby: e.target.value })} />
                                                    </Form.Item>
                                                    <Form.Item label='วันออกบัตร' name='issudt' style={{ width: '300px' }}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !',
                                                            },]}>
                                                        <DatePicker format={'YYYY-MM-DD'} name='issudt' style={{ width: '250px', height: "40px" }}
                                                            onChange={(e) => {
                                                                if (e) {
                                                                    setDataModalEdit({ ...dataModalEdit, issudt: e.format('YYYY-MM-DD') })
                                                                }
                                                            }} />
                                                    </Form.Item>
                                                    <Form.Item label='วันบัตรหมดอายุ' name='expdt' style={{ width: '300px' }}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !',
                                                            },]}>
                                                        <DatePicker format={'YYYY-MM-DD'} name='expdt' style={{ width: '250px', height: "40px" }}
                                                            onChange={(e) => {
                                                                if (e) {
                                                                    setDataModalEdit({ ...dataModalEdit, expdt: e.format('YYYY-MM-DD') })
                                                                }
                                                            }} />
                                                    </Form.Item>
                                                </aside>
                                            </Col>
                                        </Row>
                                    </>
                                }
                            </Col>
                        </Row>
                        <Row >
                            <Col span={24} style={{ textAlign: 'center' }}>
                                <Space>
                                    <Button type="primary" onClick={handleCancel} style={{ background: "red" }}>ยกเลิก</Button>
                                    <Button type="primary" htmlType="submit" style={{ background: "green" }}>บันทึก</Button>
                                    <Button type="primary" onClick={onChangeGo}>ต่อไป</Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                    {/* </Spin> */}
                </Card>
            </Row>
        </div>
    )
};

export default EditCustomer