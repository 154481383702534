import React, { useState } from 'react'
import { Modal, Row, Col, Radio, notification } from 'antd'
import { useDispatch, useSelector } from 'react-redux';
import { addCustomer } from '../../../redux/Customer';
import { addLand } from '../../../redux/Land';

//import EditCustomer from './EditCustomer';
import EditLand from './EditLand';

export default function ModalEditer({ open, close }) {
    
    const [api, contextHolder] = notification.useNotification();
    const customers = useSelector(state => state.customers)
    const [count, setCount] = useState('2')
    const dispatch = useDispatch()

    const handleCancel = () => {
        close(false);
        dispatch(addCustomer())
        dispatch(addLand())
    };

    const NotiSuccess = (placement) => {
        api.success({
            message: <b>แก้ไขข้อมูลแล้ว</b>,
            placement,
        });
    }
    const NotiError = (placement) => {
        api.error({
            message: <b>แก้ไขข้อมูลส่วนไม่สำเร็จ</b>,
            placement,
        });
    }
    const NotiErrorIMG = (placement) => {
        api.warning({
            message: <b>ไม่พบรูปภาพ</b>,
            placement,
        });
    }
    const AddImgSucc = (placement) => {
        api.success({
            message: <b>เพิ่มรูปภาพแล้ว</b>,
            placement,
        });
    }
    const DeleteImgSucc = (placement) => {
        api.success({
            message: <b>ลบรูปภาพแล้ว</b>,
            placement,
        });
    }
    const DeleteImgError = (placement) => {
        api.error({
            message: <b>ลบรูปภาพไม่สำเร็จ</b>,
            placement,
        });
    }
  return (
    <div>
    <Modal open={open} onCancel={handleCancel} width={1000} footer={null}>
        <Row>
            <Col span={24} style={{ textAlign: 'center' }}>
                <Radio.Group value={count} onChange={(e) => setCount(e.target.value)} block>
                    {/* <Radio.Button value="1">ข้อมูลส่วนตัว</Radio.Button> */}
                    <Radio.Button value="2">รายละเอียด</Radio.Button>
                </Radio.Group>
            </Col>
        </Row>
        {/* <Card> */}
        {/* {count === '1' && (<EditCustomer onCancel={handleCancel} changepage={setCount} notification={NotiSuccess} notification2={NotiError} />)} */}
        {count === '2' && (<EditLand onCancel={handleCancel} changepage={setCount} editData={NotiSuccess} AddImgSucc={AddImgSucc} DeleteImgSucc={DeleteImgSucc} DeleteImgError={DeleteImgError}  />)}
        {/* </Card> */}
    </Modal>
    {contextHolder}
</div>
  )
}
