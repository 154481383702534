import React, { useState, useEffect } from "react";
import { EditOutlined, SearchOutlined, ReadOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Table, Divider, Space, Card, Tag, Typography, Select, DatePicker, Radio, Spin, Pagination } from 'antd';

import { useDispatch } from 'react-redux';
import { addCustomer } from '../../redux/Customer';
import { addCar } from '../../redux/Car';
import { addGuarantor } from '../../redux/Guarantor';

import dayjs from "dayjs";
import axios from "axios";

import { colorApprovalStatus, backc, textc } from "../file_mid/status_color";
import { oppgre } from "../file_mid/all_options";
import { pgmaindatare } from "../file_mid/all_api";
import ModalAcceptRe from "./modals/ModalAcceptRe";
import ModalApproveRe from "./modals/ModalApproveRe";
import ModalAccept from "../check_info_PG/modals/ModalAccept";
import ModalPreApprove from "../check_info_PG/modals/ModalPreApprove";
import ModalInfoADPGDC from "../file_mid/info/ModalInfoADPGDC";
import ModalNote from "../report_pg/Modal/Note";
const { Text } = Typography;
// const { Countdown } = Statistic;
export default function Main_Pg_Re() {
  const currentDateTime = dayjs()
  const currentDateDay2 = dayjs()
  const showDay1 = dayjs("2023-01-01")
  const [dataPost, setDataPost] = useState({ sec: 0, days1: dayjs("2023-01-01"), days2: currentDateDay2, approvalStatus: "ยื่นคำร้อง" })
  const dispatch = useDispatch()
  const [recordData, setRecordData] = useState();
  const user = localStorage.getItem('username');
  const branch = localStorage.getItem('branch');
  const token = localStorage.getItem('token')
  const [isModalAccept, setIsModalAccept] = useState(false); // Approve
  const [isModalApproveRe, setIsModalApproveRe] = useState(false); // PreApprove
  const [isModalPreApprove, setIsModalPreApprove] = useState(false); // PreApprove
  const [axiosData, setAxiosData] = useState([]); // Table
  const [arrayTable, setArrayTable] = useState(); // Array Table
  // const [api, contextHolder] = notification.useNotification();
  // const [statusNoti, setStatusNoti] = useState("0");
  const [modalAccept, setModalAccept] = useState(false); // Approve
  const [editData, setEditData] = useState();
  const [countStatus2, setCountStatus2] = useState([]);
  const [loading, setLoading] = useState(false)
  const [testPage, setTestPage] = useState();
  const [ssPage, setSsPage] = useState({ numberPage: 1, pageSize: 10 });
  const [currentPage, setCurrentPage] = useState(1)
  const [query, setQuery] = useState("");
  const [isModalInfoADPGDC, setIsModalInfoADPGDC] = useState(false);
  const [isModalNote, setIsModalNote] = useState(false)
  const [isOpenNewNote, setIsOpenNewNote] = useState()

  // console.log('arrayTable',arrayTable)

  useEffect(() => {
    if (!isModalAccept && !isModalApproveRe && !isModalPreApprove && !modalAccept && !isModalInfoADPGDC) {
      loadData();
      // loadDataCount2()
    }
    // }
  }, [dataPost.approvalStatus, dataPost.days1, dataPost.days2, isModalInfoADPGDC, isModalAccept, isModalApproveRe, isModalPreApprove, modalAccept, ssPage])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      // console.log(query)
      searchLoadData(query)
    }, 1500)
    return () => clearTimeout(delayDebounceFn)
  }, [query])

  const searchLoadData = async (data) => {
    // console.log("searchLoadData data", data)
    if (data !== "") {
      // console.log("OK searchLoadData data")
      setCurrentPage(1)
      await loadData(data)
    }
  }
  const search = async (data) => {
    if (data.target.value !== "") {
      setQuery(data.target.value)
    } else {
      setSsPage({ numberPage: 1, pageSize: 10 })
      setCurrentPage(1)
      setDataPost({ sec: 0, days1: dayjs("2023-01-01"), days2: currentDateDay2, approvalStatus: "ยื่นคำร้อง" })
      setQuery("")
    }
  };
  const onChangeKeyWord = (value) => {
    // console.log("value", value)
    // setKeyWord(value)
    setQuery("")
    setDataPost({ ...dataPost, approvalStatus: value })
    setCurrentPage(1)
    setSsPage({ numberPage: 1, pageSize: 10 })
  }
  const onChangePagination = (e, pageSize) => {
    // console.log(e, pageSize)
    setCurrentPage(e)
    setSsPage({ numberPage: e, pageSize: pageSize })
  }
  const loadDataCount2 = async (data) => {
    const ez = data.filter(
      (item) =>
        item.ApprovalStatus === dataPost?.approvalStatus
    );
    // console.log("ez", ez)
    // console.log("data", data)
    setTestPage(...ez)
    setCountStatus2(data)
  }
  const loadData = async (data) => {
    setLoading(true)
    const tk = JSON.parse(token)
    const headers = {
      "Authorization": `Bearer ${tk}`,
      "Menu": JSON.stringify("5")
    }
    let mainData
    // if (e === "re") {
    //   mainData = { reviewedBy: user, approvalStatus: "ทั้งหมด", day1: dataPost.days1, day2: dataPost.days2 }
    // } else {
    if (query !== "" && data !== "stop") {
      mainData = { reviewedBy: user, approvalStatus: dataPost.approvalStatus, numberPage: ssPage?.numberPage, pageSize: ssPage?.pageSize, keyword: query, day1: dataPost.days1, day2: dataPost.days2 }
    } else {
      if (data === "stop") {
        mainData = { reviewedBy: user, approvalStatus: "ยื่นคำร้อง", numberPage: ssPage?.numberPage, pageSize: ssPage?.pageSize, day1: dataPost.days1, day2: dataPost.days2 }
      } else {
        mainData = { reviewedBy: user, approvalStatus: dataPost.approvalStatus, numberPage: ssPage?.numberPage, pageSize: ssPage?.pageSize, day1: dataPost.days1, day2: dataPost.days2 }
      }
    }
    // }
    // console.log("mainData", mainData)
    if (branch === "MIT" || branch === "UD" || branch === "LEX" || branch === "S4") {
      await axios.post(pgmaindatare, mainData, { headers: headers })
        .then(async (res) => {
          // console.log("res.data", res.data)
          if (res.data !== null) {
            if (res.data.ShowTablePGRE) {
              setArrayTable(res.data.ShowTablePGRE)
              setAxiosData(res.data.ShowTablePGRE)
            } else {
              setArrayTable([])
              setAxiosData([])
              setTestPage()
            }
            if (res.data.Countstatus) {
              loadDataCount2(res.data.Countstatus)
            } else {
              setTestPage()
              setCountStatus2([])
            }
          } else {
            setArrayTable([])
            setAxiosData([])
          }
          // setTimeout(() => {
          // }, 5000)
          setLoading(false)
        }).catch((err) => console.log(err))
      setLoading(false)
    } else {
      // await axios.post(pgmaindataksm, mainData, { headers: headers })
      //   .then(async (res) => {
      //     if (res.data !== null) {
      //       console.log("res.data", res.data)
      //       setArrayTable(res.data)
      //       setAxiosData(res.data)
      //       await loadCountData();
      //     } else {
      //       setArrayTable([])
      //       setAxiosData([])
      //     }
      //   }).catch((err) => console.log(err))
      setLoading(false)
    }
  }

  // const loadCountData = async () => {
  //   const tk = JSON.parse(token)
  //   const headers = {
  //     "Authorization": `Bearer ${tk}`,
  //     "Menu": JSON.stringify("5")
  //   }
  //   var mainData = { reviewedBy: user, approvalStatus: dataPost.approvalStatus, day1: dataPost.days1, day2: dataPost.days2 }
  //   if (branch === "MIT" || branch === "UD" || branch === "LEX" || branch === "S4") {
  //     await axios.post(pgmaincountre, mainData, { headers: headers })
  //       .then(async (res) => {
  //         console.log("loadCountData", res.data)
  //         if (res.data === null) {
  //           setCData([])
  //         } else {
  //           setCData(res.data)
  //           // cData.push(...countStatus2)
  //         }
  //       }).catch((err) => console.log(err))
  //   } else {
  //     // await axios.post(pgmaincountksm, mainData, { headers: headers })
  //     //   .then((res) => {
  //     //     if (res.data === null) {
  //     //       setCData([])
  //     //     } else {
  //     //       setCData(res.data)
  //     //     }
  //     //   }).catch((err) => console.log(err))
  //   }
  // }


  // const loadDataCount2 = async (data) => {
  //   // const tk = JSON.parse(token)
  //   // const headers = {
  //   //   "Authorization": `Bearer ${tk}`,
  //   //   "Menu": JSON.stringify("5")
  //   // }
  //   // let mainData
  //   // mainData = { reviewedBy: user, approvalStatus: "ทั้งหมด", day1: dataPost.days1, day2: dataPost.days2 }
  //   // if (branch === "MIT" || branch === "UD" || branch === "LEX" || branch === "S4") {
  //   //   await axios.post(pgmaindatare, mainData, { headers: headers })
  //   //     .then(async (res) => {
  //   //       if (res.data !== null) {

  //   // const combinedData = res.data

  //   const combinedData = data
  //     .flatMap(item => [item.approvalStatusLoan, item.approvalStatus])
  //     .filter(status => status !== null && status !== undefined);

  //   const statusCounts = combinedData.reduce((acc, status) => {
  //     acc[status] = (acc[status] || 0) + 1;
  //     return acc;
  //   }, {});

  //   const resultArray = Object.entries(statusCounts).map(([status, count]) => ({
  //     ApprovalStatus: status,
  //     totalCount: count
  //   }));

  //   const totalAllStatus = resultArray.reduce((acc, item) => acc + item.totalCount, 0);
  //   console.log("resultArray", resultArray)
  //   console.log("totalAllStatus", totalAllStatus)
  //   setCountStatus2([
  //     ...resultArray,
  //     {
  //       ApprovalStatus: "ทั้งหมด",
  //       totalCount: totalAllStatus
  //     }
  //   ]);
  //   //}
  //   //     }).catch((err) => console.log(err))
  //   // } else {

  //   // }
  // }

  // const search = (data) => {
  //   // อนาคต ควรหาใน database โดยตรง
  //   const greaterThanTen = axiosData.filter(
  //     (item) =>
  //       // item.firstName.toLowerCase().includes(data.target.value) ||
  //       // item.lastName.toLowerCase().includes(data.target.value) ||
  //       item.ISSUNO.toLowerCase().includes(data.target.value)
  //   );
  //   setArrayTable(greaterThanTen)
  // };

  const columns = [
    {
      fixed: true,
      title: "ลำดับ",
      dataIndex: "index",
      key: 'index',
      width: "12%",
      align: 'center',
      render: (text, object, index) => index + 1
    },
    {
      title: "วันที่เสนอ",
      dataIndex: "ftime",
      align: 'center',
      width: "12%",
      render: (text, record) => (
        <>{dayjs(record.ftime).format("DD-MM-YYYY HH:mm")}</>
      ),
    },
    {
      title: "รายละเอียด",
      dataIndex: "ISSUNO",
      width: "22%",
      align: 'center',
      render: (text, record) => (
        <>
          <div>เลขสัญญา {record.ISSUNO}</div>
          <div>{record.firstName} {record.lastName}</div>
          <div>
            <div>เสนอโดย <Space style={{ color: "red" }}><b>{record.proposalBy}</b></Space></div>
            {
              record.reviewedByLoan !== null ?
                <>
                  <div>ธุรการที่ได้เคส <Space style={{ color: "red" }}><b>{record.reviewedByLoan}</b></Space></div>
                </>
                : null
            }
          </div >
        </>
      )
    },
    {
      key: 'productLoanType',
      title: "ประเภทขอกู้",
      dataIndex: "productLoanType",
      width: "15%",
      align: 'center',
      render: (text, record) => (
        <>
          <Tag color="geekblue">{record.productLoanType}</Tag>
        </>
      ),
    },
    {
      key: 'approvalStatus',
      title: "สถานะ",
      dataIndex: "approvalStatus",
      width: "10%",
      align: 'center',
      render: (text, record) => {
        let color
        color = colorApprovalStatus(record.productLoanType, record.approvalStatus)
        return (
          <>
            <Tag color={color}>
              {record.loanId != 0 ? <>{record?.approvalStatusLoan}</> : <>{record?.approvalStatus} </>}
            </Tag>
          </>
        );
      },
    },
    {
      title: "วันที่ผ่านการรับคำร้อง",
      dataIndex: "approvalDate",
      width: "10%",
      align: 'center',
      render: (text, record) => (
        <Space size="middle">
          {
            record.acceptDatePg ?
              <Space>
                <>{dayjs(record.acceptDatePg).format("DD-MM-YYYY")}</>
              </Space>
              : <Text type="secondary">ยังไม่ผ่านการรับคำร้อง</Text>
          }
        </Space>
      ),
    },
    // {
    //   title: "สถานะปัจจุบัน",
    //   dataIndex: "approvalStatusLoan",
    //   width: "20%",
    //   align: 'center',
    //   render: (text, record) => (
    //     < Space size="middle" >
    //       {
    //         record.approvalStatus === "เสนอเคสรีแล้ว" && !record.approvalStatusLoan ?
    //           <>
    //             <Tag color={"red"}>
    //               การยังไม่มีการดำเนินการ
    //             </Tag>
    //           </>
    //           :
    //           <>
    //             <Tag color={"geekblue"}>
    //               {record.approvalStatusLoan}
    //             </Tag>
    //           </>
    //       }
    //     </Space >
    //   ),
    // },
    {
      title: "Action",
      align: 'center',
      width: "10%",
      render: (record) => {
        const newOfferDate = dayjs(record.newOfferDate).startOf('day'); // วันที่ปลดรี แบบนับแต่วันที่
        const currentDate = dayjs().startOf('day'); // วันที่ปัจจุบัน แบบนับแต่วันที่
        const compareDates = newOfferDate < currentDate
        const appOne = record.approvalStatus === "ยื่นคำร้อง"
        const appTwo = record.approvalStatus === "รับคำร้อง"
        const appThree = record.approvalStatus === "คำร้องไม่ถูกต้อง"
        return (
          <Space size="middle">
            {
              record.approvalStatus === "ระงับเคส" ?
                <>
                  <Space size="middle">
                    <Tag color="geekblue">กรุณาติดต่อเร่งรัดภาคสนาม</Tag>
                  </Space>
                </>
                : (compareDates && appOne) || // ยื่นคำร้อง
                  (compareDates && appTwo) || // รับคำร้อง
                  (compareDates && appThree) ? // คำร้องไม่ถูกต้อง
                  <>
                    <Tag color="red">ใบหมดอายุ {dayjs(record.newOfferDate).format("DD/MM/YYYY")}</Tag>
                  </> :
                  record.approvalStatus === "ยื่นคำร้อง" ?
                    <Button type="primary" style={{ backgroundColor: 'green' }} onClick={() => {
                      setRecordData(record)
                      setIsModalAccept(true)
                    }}>รับงาน</Button>
                    : record.approvalStatusLoan === "ผ่านการวิเคราะห์" ?
                      <>
                        <Space>
                          <Button type="primary" style={{ backgroundColor: 'green' }} onClick={() => {
                            setModalAccept(true)
                            setEditData(record)
                          }}>รับงาน</Button>
                        </Space>
                      </>
                      : record.approvalStatusLoan === "รออนุมัติ" ?
                        <>
                          <Space>
                            <Button onClick={() => {
                              let dataCar = { carId: record.carId, typeLoan: record.typeLoan, loanId: record.loanId }
                              setIsModalPreApprove(true)
                              dispatch(addCustomer(record.customerId))
                              dispatch(addCar(record.carId))
                              dispatch(addGuarantor(dataCar))
                            }}><EditOutlined /></Button>
                            <Button onClick={() => {
                              let dataCar = { carId: record.carId, typeLoan: record.typeLoan, loanId: record.loanId }
                              dispatch(addGuarantor(dataCar))
                              setRecordData(record)
                              setIsModalInfoADPGDC(true)
                            }}><SearchOutlined /></Button>
                          </Space>
                        </>
                        :
                        <>
                          {
                            record.approvalStatusLoan ?
                              <>
                                <Button onClick={() => {
                                  setRecordData(record)
                                  setIsModalInfoADPGDC(true)
                                }}><SearchOutlined /></Button>
                              </>
                              :
                              <>
                                <Button onClick={() => {
                                  let dataCar = { carId: record.carId, typeLoan: record.typeLoan, loanId: record.loanId }
                                  dispatch(addGuarantor(dataCar))
                                  setRecordData(record)
                                  setIsModalInfoADPGDC(true)
                                }}><SearchOutlined /></Button>
                              </>

                          }
                        </>
            }
          </Space>
        )
      },
    },
    {
      title: "หมายเหตุ",
      // dataIndex: "approvalStatus",
      align: 'center',
      width: "5%",
      render: (text, record) => (
        <Button
          onClick={() => {
            setIsOpenNewNote({carLandId:record.carId,typeLoan:record.typeLoan}) 
            setIsModalNote(true) 
          }}
        >
         <ReadOutlined />
        </Button>

      ),
    },
  ];

  // const currencyFormatOne = (amount) => {
  //   return Number(amount)
  //     .toFixed(2)
  //     .replace(/\d(?=(\d{3})+\.)/g, '$&,')
  // }

  return (
    <>
      <Card
        bordered={false}
        style={{
          width: 'auto',
        }}
      >
        <Row style={{ textAlign: 'center' }}>
          <Col span={24}>
            <Spin spinning={loading} size='large' tip=" Loading... ">
              <div className='text-center'>
                <h2>ตรวจสอบข้อมูลการอนุมัติเคสรี</h2>
              </div>
              <Row gutter={32} justify={'center'}>
                <Row gutter={24} justify={'center'} style={{ margin: 10 }}>
                  <Col className='gutter-row' span={24} style={{ textAlign: 'center' }}>
                    <Space>
                      <>{"เลือกวันที่ต้องการแสดงเคส :"}</>
                      <DatePicker format={'YYYY-MM-DD'}
                        defaultValue={showDay1}
                        style={{ height: '40px' }}
                        onChange={(e) => {
                          if (e) {
                            setDataPost({ ...dataPost, days1: e.format('YYYY-MM-DD') })
                          }
                        }} />
                      <>{"ถึง"}</>
                      <DatePicker format={'YYYY-MM-DD'}
                        defaultValue={currentDateTime}
                        style={{ height: '40px' }}
                        onChange={(e) => {
                          if (e) {
                            setDataPost({ ...dataPost, days2: e.format('YYYY-MM-DD') })
                          }
                        }} />
                    </Space>
                  </Col>
                </Row>

                <Row gutter={24} justify={'center'} style={{ margin: 10 }}>
                  <Col className='gutter-row' span={24} style={{ textAlign: 'center' }}>
                    <Space>
                      <>{"เลือกสถานะ :"}</>
                      <Select
                        // defaultValue={"ยื่นคำร้อง"}
                        value={dataPost.approvalStatus}
                        style={{ width: 200 }}
                        // onChange={(value) => setDataPost({ ...dataPost, approvalStatus: value })}
                        onChange={(value) => onChangeKeyWord(value)}
                        options={oppgre}
                      />
                    </Space>
                  </Col>
                </Row>
                <Divider style={{ margin: 5 }} />
                <Radio.Group disabled >
                  {/* {
                  cData
                    .sort((a, b) => {
                      const order = [
                        "ลูกค้ายกเลิก",
                        "ยื่นคำร้อง",
                        "ปฏิเสธคำร้อง",
                        "รับคำร้อง",
                        "เสร็จสิ้น",
                        "เสนอเคสรีแล้ว",
                        "รออนุมัติ",
                        "ไม่อนุมัติ",
                        "อนุมัติ",
                        "อนุมัติแบบมีเงื่อนไข",
                        "ทั้งหมด",
                      ];
                      return order.indexOf(a.ApprovalStatus) - order.indexOf(b.ApprovalStatus);
                    })
                    .map((item) => (
                      <Radio.Button
                        style={{
                          backgroundColor: backc[item.ApprovalStatus],
                          color: textc[item.ApprovalStatus]
                        }}
                        value={item.ApprovalStatus}
                      >
                        {item.ApprovalStatus} <b>{item.totalCount}</b>
                      </Radio.Button>
                    ))} */}
                  {
                    countStatus2
                      .sort((a, b) => {
                        const order = [
                          "ยื่นคำร้อง",
                          "ปฏิเสธคำร้อง",
                          "คำร้องไม่ถูกต้อง",
                          "รับคำร้อง",
                          "เสนอเคสรีแล้ว",
                          "รอธุรการรับ",
                          "รอวิเคราะห์",
                          "ไม่ผ่านการวิเคราะห์",
                          "ผ่านการวิเคราะห์",
                          "รออนุมัติ",
                          "ไม่อนุมัติ",
                          "อนุมัติ",
                          "อนุมัติแบบมีเงื่อนไข",
                          "ลูกค้าปฏิเสธ",
                          "ลูกค้าคอนเฟิร์ม",
                          "รอตรวจสอบเอกสาร",
                          "รอทำสัญญา",
                          "Reject",
                          "ทั้งหมด",
                        ];
                        return order.indexOf(a.ApprovalStatus) - order.indexOf(b.ApprovalStatus);
                      })
                      .map((item) => (
                        <Radio.Button
                          style={{
                            backgroundColor: backc[item.ApprovalStatus],
                            color: textc[item.ApprovalStatus]
                          }}
                          value={item.ApprovalStatus}
                        >
                          {item.ApprovalStatus} <b>{item.totalCount}</b>
                        </Radio.Button>
                      ))}
                </Radio.Group>
              </Row>
              <Row gutter={32} style={{ margin: 10 }}>
                <Col className='gutter-row' span={24} style={{ textAlign: 'right' }}>
                  <Input.Search
                    style={{ width: '250px' }}
                    placeholder="ค้นหา...."
                    onChange={search}
                    name="search"
                    id="search"
                    value={query}
                  />
                </Col>
              </Row>
              <Row gutter={32}>
                <Col span={24}>
                  <Table
                    pagination={false}
                    rowKey={(record) => record.uid}
                    dataSource={arrayTable}
                    columns={columns}
                    scroll={{
                      x: 1500,
                      y: 400,
                    }}
                    style={{ padding: 20 }}
                  >
                  </Table>
                  <Divider />
                  <Pagination
                    current={currentPage}
                    onChange={onChangePagination}
                    onShowSizeChange={onChangePagination}
                    defaultPageSize={10}
                    defaultCurrent={1}
                    total={testPage?.totalCount}
                  />
                  <Divider />
                </Col>
              </Row>
            </Spin>
          </Col>
        </Row>
      </Card>
      {
        isModalAccept ?
          <ModalAcceptRe
            open={isModalAccept}
            close={setIsModalAccept}
            dataFromTable={recordData}
          />
          : null
      }
      {
        isModalApproveRe ?
          <ModalApproveRe
            open={isModalApproveRe}
            close={setIsModalApproveRe}
            dataFromTable={recordData}
          />
          : null
      }
      {
        modalAccept ?
          <ModalAccept
            open={modalAccept}
            close={setModalAccept}
            dataFromTable={editData}
          // setStatusNoti={setStatusNoti}
          />
          : null
      }
      {
        isModalPreApprove ?
          <ModalPreApprove
            open={isModalPreApprove}
            close={setIsModalPreApprove}
          />
          : null
      }
      {
        isModalInfoADPGDC ?
          <ModalInfoADPGDC
            open={isModalInfoADPGDC}
            close={setIsModalInfoADPGDC}
            dataFromTable={recordData}
            typeLoans={recordData.typeLoan}
            checkPosi={"pg"} />
          : null
      }
      {
        isModalNote ?
          <ModalNote open={isModalNote} close={setIsModalNote} dataFromTable={isOpenNewNote} />
          : null
      }
      {/* {contextHolder} */}
    </>
  )
}
