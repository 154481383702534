import { useEffect, useState } from "react";
import { Col, Menu, Row, message } from "antd";
import { Link, NavLink, useLocation } from "react-router-dom";
import axios from "axios";
import {
  HomeOutlined as HomeOutlinedIcon,
  UserAddOutlined as UserAddOutlinedIcon,
  CarOutlined as CarOutlinedIcon,
  DollarOutlined as DollarOutlinedIcon,
  FrownOutlined as FrownOutlinedIcon,
  IdcardOutlined as IdcardOutlinedIcon,
  SolutionOutlined as SolutionOutlinedIcon,
  SaveOutlined as SaveOutlinedIcon,
  FileDoneOutlined as FileDoneOutlinedIcon,
  ExceptionOutlined as ExceptionOutlinedIcon,
  CheckOutlined as CheckOutlinedIcon,
  CarOutlined,
  FormOutlined,
  PhoneOutlined,
  UserOutlined
} from "@ant-design/icons";
import drawerHeader from "../../assets/images/drawable-header.png";
import { useSelector } from 'react-redux';
import logo from './location_3396770.png'

import { checkToken, testToken } from "../../pages/file_mid/all_api";
import MainOperators from "../../pages/operators/MainOperators";

function Sidenav({ color, onClick }) {
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    // console.log("aa")
    loadData()
  }, [])

  const handleLogout = () => {
    localStorage.removeItem('username')
    localStorage.removeItem('menu')
    localStorage.removeItem('token')
    localStorage.removeItem('nickname')
    localStorage.removeItem('nicknameSalcod')
    localStorage.removeItem('branch')
    localStorage.removeItem('firstname')
    localStorage.removeItem('lastname')
    localStorage.removeItem('idSep')
    localStorage.removeItem('idDepartment')
    localStorage.removeItem('roleId')
    window.location.reload(false)
    //navigate('/')
  };



  const loadData = async () => {
    const tk = JSON.parse(token)
    const headers = {
      "Authorization": `Bearer ${tk}`,
      "Menu": JSON.stringify("1")
    }
    await axios.get(testToken, { headers: headers })
      .then((res) => {
        // console.log("res.data", res.data)
      }).catch((err) => { handleLogout() })


    // const fpPromise = import('https://openfpcdn.io/fingerprintjs/v4')
    //   .then(FingerprintJS => FingerprintJS.load())
    // // Get the visitor identifier when you need it.
    // fpPromise
    //   .then(fp => fp.get())
    //   .then(result => {
    //     // This is the visitor identifier:
    //     const visitorId = result.visitorId
    //     console.log(visitorId)
    //   })
  }

  const [activeItem, setActiveItem] = useState(null);

  const { pathname } = useLocation();
  const page = pathname.replace("/", "");
  // const { users } = useSelector((state) => ({ ...state }))
  const token = localStorage.getItem('token')
  const menu = localStorage.getItem('menu')
  const menu2 = localStorage.getItem('menu2')
  const list = JSON.parse(menu)
  // const list2 = JSON.parse(menu2)
  // const jsonString = `${list2}`
  // const listt2 = JSON.parse(jsonString)
  // console.log('menu2',jsonString)
  // console.log('menu',menu)


  const menuItems = [
    {
      key: 1,
      pageName: "home",
      path: "/",
      icon: <HomeOutlinedIcon />,
      label: "หน้าแรก V. 4.2",
      children: []
    },
    {
      key: 2,
      pageName: "offercase",
      path: "/offercase",
      icon: <ExceptionOutlinedIcon />,
      label: "เสนอเคส",
      children: []
    },
    {
      key: 3,
      pageName: "checkinfo",
      path: null,
      icon: <FormOutlined />,
      label: "ตรวจสอบข้อมูล",
      children: [
        {
          key: 31,
          icon: <CarOutlined />,
          pageName: "checkInfoCar",
          path: "/checkInfoCar",
          label: "รถ",
        },
        {
          key: 32,
          icon: <img src={logo} width={'15px'} lt="" />,
          pageName: "checkInfoLand",
          path: "/checkInfoLand",
          label: "พรี-ที่ดิน",
        },
        {
          key: 33,
          icon: <img src={logo} width={'15px'} lt="" />,
          pageName: "preLandPn",
          path: "/preLandPn",
          label: "ขอยอดที่ดิน",
        },
        {
          key: 34,
          icon: <SolutionOutlinedIcon />,
          pageName: "case-re",
          path: "/case-re",
          label: "สัญญาทั้งหมดของคุณ",
        },
        {
          key: 35,
          icon: <ExceptionOutlinedIcon />,
          pageName: "offercase-re",
          path: "/offercase-re",
          label: "ขอยอดเคสรี(รถ)",
        },
        {
          key: 36,
          icon: <ExceptionOutlinedIcon />,
          pageName: "offercaseland-re",
          path: "/offercaseland-re",
          label: "ขอยอดเคสรี(ที่ดิน)",
        },
      ],
    },
    {
      key: 4,
      pageName: "checkAd",
      path: null,
      icon: <FormOutlined />,
      label: "ตรวจสอบข้อมูลธุรการ",
      children: [
        {
          key: 41,
          icon: <ExceptionOutlinedIcon />,
          pageName: "checkInfoAd",
          path: "/checkInfoAd",
          label: "ตรวจสอบข้อมูล(ธุรการ)",
        },
        {
          key: 42,
          icon: <ExceptionOutlinedIcon />,
          pageName: "formLandAd",
          path: "/formLandAd",
          label: "แบบฟอร์มชุดจดที่ดิน",
        },
        {
          key: 43,
          icon: <ExceptionOutlinedIcon />,
          pageName: "formLandRedemption",
          path: "/formLandRedemption ",
          label: "แบบฟอร์มชุดไถ่ถอนที่ดิน",
        },
      ]

    },
    {
      key: 5,
      pageName: "approvalPG",
      path: null,
      icon: <FormOutlined />,
      label: "ตรวจสอบข้อมูล",
      children: [
        {
          key: 51,
          icon: <FormOutlined />,
          pageName: "checkInfoPG",
          path: "/checkInfoPG",
          label: "การอนุมัติ",
        },
        {
          key: 52,
          icon: <FormOutlined />,
          pageName: "checkinfopgRE",
          path: "/checkinfopgRE",
          label: "การอนุมัติเคสรี",
        },
        {
          key: 53,
          icon: <FormOutlined />,
          pageName: "checkInfoPGRECont",
          path: "/checkInfoPGRECont",
          label: "กำหนดรี/ปรับ/เปลี่ยน",
        },
        {
          key: 54,
          icon: <ExceptionOutlinedIcon />,
          pageName: "checkInfoPGMKLeaving",
          path: "/checkInfoPGMKLeaving",
          label: "สัญญาการตลาดที่ออก",
        },
        {
          key: 55,
          icon: <ExceptionOutlinedIcon />,
          pageName: "checkInfoPGMKChange",
          path: "/checkInfoPGMKChange",
          label: "เปลี่ยนการตลาด",
        },
        {
          key: 56,
          icon: <FormOutlined />,
          pageName: "approvalRockingPG",
          path: "/approvalRockingPG",
          label: "โยกงาน",
        },
        {
          key: 57,
          icon: <FormOutlined />,
          pageName: "approvalStopWorkPG",
          path: "/approvalStopWorkPG",
          label: "หยุดรับงาน",
        },
        {
          key: 58,
          icon: <FormOutlined />,
          pageName: "reportWorkPG",
          path: "/reportWorkPG",
          label: "รายงาน",
        },
      ]

    },
    {
      key: 6,
      pageName: "detailPN",
      path: null,
      icon: <FormOutlined />,
      label: "ตรวจสอบข้อมูลพื่หนุ่ม",
      children: [
        {
          key: 61,
          icon: <img src={logo} width={'15px'} lt="" />,
          pageName: "checkInfoPN",
          path: "/checkInfoPN",
          label: "ประเมินที่ดิน",
        },
        {
          key: 62,
          icon: <img src={logo} width={'15px'} lt="" />,
          pageName: "checkInfoRePN",
          path: "/checkInfoRePN",
          label: "ประเมินที่ดิน(เคสรี)",
        },
        {
          key: 63,
          icon: <FormOutlined />,
          pageName: "report-pn",
          path: "/report-pn",
          label: "รายงาน",
        },
        {
          key: 64,
          icon: <FormOutlined />,
          pageName: "report-pn2",
          path: "/report-pn2",
          label: "รายงาน2",
        },
        {
          key: 65,
          icon: <FormOutlined />,
          pageName: "report-pn3",
          path: "/report-pn3",
          label: "รายงาน3",
        },
        {
          key: 66,
          icon: <FormOutlined />,
          pageName: "report-pn4",
          path: "/report-pn4",
          label: "รายงาน4",
        },
      ]
    },
    {
      key: 7,
      pageName: "detailManager",
      path: null,
      icon: <FormOutlined />,
      label: "ตรวจสอบข้อมูลผู้จัดการ",
      children: [
        {
          key: 71,
          icon: <FormOutlined />,
          pageName: "checkInfoManager",
          path: "/checkInfoManager",
          label: "ตารางตรวจสอบข้อมูล",
        },
        {
          key: 72,
          icon: <FormOutlined />,
          pageName: "report-mg",
          path: "/report-mg",
          label: "รายงาน",
        },
        {
          key: 73,
          icon: <FormOutlined />,
          pageName: "report-mg2",
          path: "/report-mg2",
          label: "รายงาน2",
        },
        {
          key: 74,
          icon: <FormOutlined />,
          pageName: "report-mg3",
          path: "/report-mg3",
          label: "รายงาน3",
        },
        {
          key: 75,
          icon: <FormOutlined />,
          pageName: "report-mg4",
          path: "/report-mg4",
          label: "รายงาน4",
        },
      ]
    },
    {
      key: 8,
      pageName: "checkInfoBoss",
      // path: "/checkInfoBoss",
      icon: <FormOutlined />,
      label: "ตรวจสอบข้อมูลบอส",
      children: [
        {
          key: 81,
          icon: <FormOutlined />,
          pageName: "checkInfoBoss",
          path: "/checkInfoBoss",
          label: "ตารางตรวจสอบข้อมูล",
        },
        {
          key: 82,
          icon: <FormOutlined />,
          pageName: "checkInfoBossReport",
          path: "/checkInfoBossReport",
          label: "รายงาน",
        },
      ]
    },
    {
      key: 9,
      pageName: "addcustomer",
      path: "/addcustomer",
      icon: <UserAddOutlinedIcon />,
      label: "บันทึกประวัติลูกค้า",
      children: []
    },
    {
      key: 10,
      pageName: "addproperty",
      path: "/addproperty",
      icon: <CarOutlinedIcon />,
      label: "บันทึกสืบทรัพย์สิน",
      children: []
    },
    {
      key: 11,
      pageName: "addloan",
      path: "/addloan",
      icon: <DollarOutlinedIcon />,
      label: "บันทึกสัญญาเงินกู้",
      children: []
    },
    {
      key: 12,
      pageName: "blacklist",
      path: "/blacklist",
      icon: <FrownOutlinedIcon />,
      label: "สอบถามข้อมูล Blacklist",
      children: []
    },
    {
      key: 13,
      pageName: "customer-history",
      path: "/customer-history",
      icon: <IdcardOutlinedIcon />,
      label: "สอบถามข้อมูลประวัติลูกค้า",
      children: []
    },
    {
      key: 14,
      pageName: "loan-history",
      path: "/loan-history",
      icon: <SolutionOutlinedIcon />,
      label: "สอบถามข้อมูลลูกหนี้เงินกู้",
      children: []
    },
    {
      key: 15,
      pageName: "payment-daily",
      path: "/payment-daily",
      icon: <SaveOutlinedIcon />,
      label: "บันทึกชำระประจำวัน",
      children: []
    },
    {
      key: 16,
      pageName: "receipt",
      path: "/receipt",
      icon: <FileDoneOutlinedIcon />,
      label: "พิมพ์ใบเสร็จรับเงิน",
      children: []
    },
    {
      key: 17,
      pageName: "rush",
      path: "/rush",
      icon: <UserAddOutlinedIcon />,
      label: "เร่งรัด",
      children: []
    },
    {
      key: 18,
      pageName: "permis",
      path: "/permis",
      icon: <UserAddOutlinedIcon />,
      label: "กำหนดสิทธิ์",
      children: []
    },
    {
      key: 19,
      pageName: "tableuser",
      path: "/tableuser",
      icon: <UserAddOutlinedIcon />,
      label: "แก้ไขสิทธิ์สมาชิก",
      children: []
    },
    {
      key: 20,
      pageName: "comment",
      path: "/comment",
      icon: <ExceptionOutlinedIcon />,
      label: "memo",
      children: []
    },
    {
      key: 21,
      pageName: "checkDocuments",
      path: "/checkDocuments",
      icon: <FormOutlined />,
      label: "ตรวจสอบเอกสาร",
      children: []
    },
    {
      key: 22,
      pageName: "apRateCar",
      path: "/apRateCar",
      icon: <CarOutlinedIcon />,
      label: "อนุมัติเรทรถ",
      children: []
    },
    {
      key: 23,
      pageName: "operators",
      path: "/operators",
      icon: <PhoneOutlined />,
      label: "โอเปอร์เรเตอร์",
      children: []
    },
    {
      key: 24,
      pageName: "managers",
      path: "/managers",
      icon: <UserOutlined />,
      label: "HR",
      children: [
        {
          key: 241,
          icon: <UserAddOutlinedIcon />,
          pageName: "listofemployees",
          path: "/listofemployees",
          // path: "/signUp",
          label: "รายชื่อพนักงาน",
        },
        {
          key: 242,
          icon: <UserAddOutlinedIcon />,
          pageName: "createRights",
          path: "/createRights",
          label: "สร้างสิทธิ์",
        },

      ]
    },
    {
      key: 25,
      pageName: "expediteTheDebt",
      path: "/expeditethedebt",
      icon: <UserOutlined />,
      label: "เร่งรัดหนี้สิน",
      children: [
        {
          key: 251,
          icon: <UserAddOutlinedIcon />,
          pageName: "expeditethedebtcars",
          path: "/expeditethedebtcars",
          label: "รถ",
        },
        {
          key: 252,
          icon: <FormOutlined />,
          pageName: "followCase",
          path: "/followCase",
          label: "ติดตามเคส",
        },
        {
          key: 253,
          icon: <UserAddOutlinedIcon />,
          pageName: "suspensioncase",
          path: "/suspensioncase",
          label: "เคสที่โดนระงับ",
        },

        // เผื่อกรณีมีที่ดินเพิ่ม
        // {
        //   key: 252,
        //   icon: <UserAddOutlinedIcon />,
        //   pageName: "expediteTheDebtLands",
        //   path: "/expediteTheDebtLands",
        //   label: "ที่ดิน",
        // },

      ]
    },
    {
      key: 26,
      pageName: "finance",
      // path: null,
      icon: <SolutionOutlinedIcon />,
      label: "การเงิน",
      children: [
        {
          key: 261,
          icon: <IdcardOutlinedIcon />,
          pageName: "checkInfoFinance",
          path: "/checkInfoFinance",
          label: "เช็คสัญญา",
        },
      ]
    },
    {
      key: 27,
      pageName: "reportResult",
      // path: null,
      icon: <FormOutlined />,
      label: "reportResult",
      children: [
        {
          key: 271,
          icon: <FormOutlined />,
          pageName: "infoResult",
          path: "/infoResult",
          label: "Result",
        },
      ]
    }
  ];

  // console.log('lisst',list)
  const data = Object.keys(list)
  // console.log('data',data)
  // const combinedObject1 = list ? aa.map(o => Object.assign(menuItems.find(o2 => o2.key == o))) : [];
  const combinedObject1 = data.map(o => {
    const menuItem = menuItems.find(o2 => o2.key == o);
    if (menuItem) {
      const filteredChildren = menuItem.children ? menuItem.children.filter(child => data.includes(child.key.toString())) : [];
      return {
        ...menuItem,
        children: filteredChildren
      };
    }
    return null;
  }).filter(Boolean);

  // console.log('combinedObject1',combinedObject1)

  const handleClick = async (value) => {
    await loadData()
    // console.log(value);
    setActiveItem(value);
    onClick(value);
  };
  // const style = {

  // }

  const renderMenuItem = (item) => {
    // console.log("er", item)
    return (
      item.children.length === 0 ?
        <>
          <Menu
            className="aa"
            mode="inline"
            style={{
              height: '100%', width: 260,
            }}
          >
            <Menu.Item
              className="a"
              key={item.key}
              // title={item.label}
              icon={item.icon}
              onClick={() => {
                handleClick(item.label);
              }}
              style={{
                background: activeItem === item.label ? '#002766' : 'transparent',
                color: activeItem !== item.label ? 'black' : 'white'
              }}
            >
              <b>
                {item.label}
              </b>
              {item.path && (
                <NavLink
                  to={item.path}
                  key={item.key}
                  onClick={() => setActiveItem(null)}
                  style={{
                    borderRadius: '8px',
                    background: activeItem === item.label ? '#262626' : 'transparent',
                    color: activeItem !== item.label ? 'black' : 'black'
                  }}
                >
                  <span className="icon">
                    <b style={{ marginRight: '10px' }}>{item.icon}</b>
                    <b >{item.label}</b>
                  </span>
                </NavLink>
              )
              }
              {/* <NavLink to={item.path} key={item.key}>
              <span className="icon" // style={{//   background: page === item.pageName ? color : "",// }}
              >
                <b style={{ marginRight: '10px', lineHeight: '32px' }} >{item.icon}</b>
              </span>
              <span className="label"><b>{item.label}</b></span>

            </NavLink> */}

            </Menu.Item>
          </Menu>
        </>
        :
        <>
          <Menu
            mode="inline"
          // style={{
          //   height: '100%', width: 260,
          //   // background: activeItem === item.label ? '#262626' : 'transparent',
          // }}
          >
            <Menu.SubMenu
              title={<b style={{ color: 'black' }}>{item.label}</b>}
              icon={<b style={{ color: 'black' }}>{item.icon}</b>}
              style={{
                background: activeItem === item.label ? '#002766' : 'transparent',
                color: activeItem !== item.label ? 'black' : 'white'
              }}
            >
              {item.children && (
                item.children.map(child => (
                  <Menu.Item

                    key={child.key}
                    onClick={() => {
                      handleClick(child.label);
                    }}
                  // style={{
                  //   background: activeItem === item.label ? '#262626' : '',
                  //   color: activeItem === item.label ? 'black' : 'black'
                  // }}
                  // style={{
                  //   background: activeItem === item.label ? '#002766' : 'transparent',
                  //   color: activeItem !== item.label ? 'black' : 'white'
                  // }}
                  >
                    <NavLink
                      to={child.path}
                      key={child.key}
                    // onClick={() => setActiveItem(null)} // Reset activeItem when clicking a link
                    // style={{
                    //   background: activeItem === item.label ? '#262626' : 'transparent',
                    //   color: activeItem === item.label ? 'black' : 'black'
                    // }}
                    >
                      <span className="icon">
                        <b style={{ marginRight: '10px' }}>{child.icon}</b>
                        <b className="label">{child.label}</b>
                      </span>
                    </NavLink>
                  </Menu.Item>
                ))
              )
              }
            </Menu.SubMenu>
          </Menu>
        </>
    )
  }

  return (
    <>
      {/* <div>
        <img src={drawerHeader} width={180} alt="" />
      </div> */}

      <Menu mode="vertical">
        {combinedObject1.map((item) => renderMenuItem(item))}
      </Menu>

    </>
  )
}

export default Sidenav;
