
export function setTypeImg(value) {
    var PreAaproveSplit = []
    PreAaproveSplit = value.pathImage.split("/")
    if (PreAaproveSplit[3] === "13") {
        if (value.type === 1) {
            return "ใบประเมิน,รูปรถ,สัญญาไฟแนนซ์เดิม,ใบเสร็จไฟแนนซ์เดิม,ที่ดิน (ใหม่)"
        } else if (value.type === 2) {
            return "หน้าคู่มือ (ใหม่)"
        } else if (value.type === 3) {
            return "โฉนด (ใหม่)"
        } else if (value.type === 4) {
            return "คนกู้ (ใหม่)"
        } else if (value.type === 5) {
            return "คนค้ำ (ใหม่)"
        } else if (value.type === 6) {
            return "ประวัติคนกู้,ทะเบียน,อาชีพ (ใหม่)"
        } else if (value.type === 7) {
            return "statement (pdf) (ใหม่)"
        } else if (value.type === 8) {
            return "statement (รูปภาพ) (ใหม่)"
        } else if (value.type === 9) {
            return "ประวัติคนค้ำ,ทะเบียนบ้าน,อาชีพ,อื่นๆ (ใหม่)"
        } else if (value.type === 10) {
            return "ใบแบ่งใบต่อ (ใหม่)"
        } else if (value.type === 11) {
            return "ระวาง (ใหม่)"
        } else if (value.type === 12) {
            return "เอกสารเพิ่มเติม (ใหม่)"
        } else {
            return "-"
        }
    } else {
        if (value.type === 1) {
            return "ใบประเมิน,รูปรถ,สัญญาไฟแนนซ์เดิม,ใบเสร็จไฟแนนซ์เดิม,ที่ดิน"
        } else if (value.type === 2) {
            return "หน้าคู่มือ"
        } else if (value.type === 3) {
            return "โฉนด"
        } else if (value.type === 4) {
            return "คนกู้"
        } else if (value.type === 5) {
            return "คนค้ำ"
        } else if (value.type === 6) {
            return "ประวัติคนกู้,ทะเบียน,อาชีพ"
        } else if (value.type === 7) {
            return "statement (pdf)"
        } else if (value.type === 8) {
            return "statement (รูปภาพ)"
        } else if (value.type === 9) {
            return "ประวัติคนค้ำ,ทะเบียนบ้าน,อาชีพ,อื่นๆ"
        } else if (value.type === 10) {
            return "ใบแบ่งใบต่อ"
        } else if (value.type === 11) {
            return "ระวาง"
        } else if (value.type === 12) {
            return "เอกสารเพิ่มเติม"
        } else {
            return "-"
        }
    }
}


export function setTypeImgGuarantor(value) {
    if (value) {
        return `คนค้ำ ลำดับที่ ${value}`
    } else {
        return "-"
    }
}
// 1 ใบประเมิน / รูปรถ / สัญญาไฟแนนซ์เดิม / ใบเสร็จไฟแนนซ์เดิม / ที่ดิน
// 2 หน้าคู่มือ
// 3 โฉนด
// 4 คนกู้
// 5 คนค้ำ
// 6 ประวัติคนกู้ / ทะเบียน / อาชีพ
// 7 statement (pdf)
// 8 statement (รูปภาพ)
// 9 ประวัติคนค้ำ / ทะเบียนบ้าน/ อาชีพ/อื่นๆ
// 10 ใบแบ่งใบต่อ
// 11 ระวาง
// 12 เอกสารเพิ่มเติม
// 13 Pre-Aaprove