import React from 'react';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Divider, Row, Col, Card, Modal, Space, message } from 'antd';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import ModalAddPhone from './PhoneModals/ModalAddPhone';
import ModalAddSocial from './SocialModals/ModalAddSocial';
import ModalAddEmail from './EmailModals/ModalAddEmail';
import ModalEditPhone from './PhoneModals/ModalEditPhone';
import ModalEditSocial from './SocialModals/ModalEditSocial';
import ModalEditEmail from './EmailModals/ModalEditEmail';
import { deleteemail, deletephone, deletesocial, findcarall, findlandall } from '../../../file_mid/all_api';

export default function Contact({ dataMix, page, changepage, close, notification, notification2, notification3, notification4, notification5, sendback }) {

    // const { guarantor } = useSelector((state) => ({ ...state }))
    // const { customers } = useSelector((state) => ({ ...state }))
    const guarantor = useSelector(state => state.guarantor)
    const customers = useSelector(state => state.customers)
    // const [messageApi, contextHolder] = message.useMessage();
    // const [loading, setLoading] = useState(false)

    const [form] = Form.useForm();

    const [index1, setIndex] = useState(); //set index send to page edit
    const [dataPost, setDataPost] = useState(customers.data); //มาจากรีดัค

    const [dataeditP, setDataEditP] = useState([]); //เซตสำหรับแก้ไขข้อมูล Phone
    const [dataeditS, setDataEditS] = useState([]); //เซตสำหรับแก้ไขข้อมูล Social
    const [dataeditE, setDataEditE] = useState([]); //เซตสำหรับแก้ไขข้อมูล Email

    const { confirm } = Modal

    // Open Modals
    const [modalAddPhone, setModalAddPhone] = useState(false);
    const [modalAddSocial, setModalAddSocial] = useState(false);
    const [modalAddEmail, setModalAddEmail] = useState(false);

    const [modalEditPhone, setModalEditPhone] = useState(false);
    const [modalEditSocial, setModalEditSocial] = useState(false);
    const [modalEditEmail, setModalEditEmail] = useState(false);

    const [savePhone, setSavePhone] = useState([]); // เก็บ Telp
    const [saveSocial, setSaveSocial] = useState([]); // เก็บ Socail
    const [saveEmail, setSaveEmail] = useState([]); // เก็บ Email

    useEffect(() => {
        setDataPost(customers.data)
        if (guarantor.data.typeLoan === "car") {
            loadDataCar()
        } else if (guarantor.data.typeLoan === "land") {
            loadDataLand()
        }
    }, [dataPost, modalAddPhone, modalAddSocial, modalAddEmail])

    const loadDataCar = async (e) => {
        if (dataPost !== undefined) {
            await axios.post(findcarall, dataMix)
                .then(res => {
                    if (res.status === 200) {
                        if (res.data.phones.length !== 0) {
                            setSavePhone(res.data.phones) // ข้อมูล
                            // setLoading(false)
                        }
                        if (res.data.socials.length !== 0) {
                            setSaveSocial(res.data.socials) // ข้อมูล
                            // setLoading(false)
                        }
                        if (res.data.emails.length !== 0) {
                            setSaveEmail(res.data.emails) // ข้อมูล
                            // setLoading(false)
                        }
                    }
                }).catch((err) => {
                    console.log("err", err)
                })
        }
    }
    const loadDataLand = async (e) => {
        if (dataPost !== undefined) {
            await axios.post(findlandall, dataMix)
                .then(res => {
                    if (res.status === 200) {
                        if (res.data.phones.length !== 0) {
                            setSavePhone(res.data.phones) // ข้อมูล
                            // setLoading(false)
                        }
                        if (res.data.socials.length !== 0) {
                            setSaveSocial(res.data.socials) // ข้อมูล
                            // setLoading(false)
                        }
                        if (res.data.emails.length !== 0) {
                            setSaveEmail(res.data.emails) // ข้อมูล
                            // setLoading(false)
                        }
                    }
                }).catch((err) => {
                    console.log("err", err)
                })
        }
    }

    const deleteDataPhone = async (value) => {
        // setLoading(true)
        const test2 = value
        await axios.delete(deletephone, { data: test2, })
            .then((res) => {
                if (res.status === 200) {
                    sendback(dayjs())
                    notification5('top')
                    // setLoading(false)
                }
                else {
                    error()
                }
            }).catch((err) => console.log(err))
        // setLoading(false)
    }
    const deleteDataSocial = async (value) => {
        // setLoading(true)
        const test2 = value
        await axios.delete(deletesocial, { data: test2, })
            .then((res) => {
                if (res.status === 200) {
                    sendback(dayjs())
                    notification5('top')
                    // setLoading(false)
                }
                else {
                    error()
                }
            }).catch((err) => console.log(err))
        // setLoading(false)
    }
    const deleteDataEmail = async (value) => {
        // setLoading(true)
        const test2 = value
        await axios.delete(deleteemail, { data: test2, })
            .then((res) => {
                if (res.status === 200) {
                    sendback(dayjs())
                    notification5('top')
                    // setLoading(false)
                }
                else {
                    error()
                }
            }).catch((err) => console.log(err))
        // setLoading(false)
    }

    const error = () => {
        Modal.error({
            title: 'ไม่สำเร็จ',
            content: 'กรุณาลองใหม่อีกครั้ง',
        })
    }
    const showConfirm = (value, index) => {
        confirm({
            title: 'คุณต้องการที่จะลบข้อมูลหรือไม่...?',
            content: 'กด OK เพื่อยืนยันการลบ',
            onOk() {
                const deletearray = [...savePhone]
                deletearray.splice(index, 1)
                setSavePhone(deletearray)
                deleteDataPhone(value)
            },
            onCancel() {
                message.error('ยกเลิกการลบข้อมูล')
            },
        });
    };
    const showConfirm2 = (value, index) => {
        confirm({
            title: 'คุณต้องการที่จะลบข้อมูลหรือไม่...?',
            content: 'กด OK เพื่อยืนยันการลบ',
            onOk() {
                const deletearray = [...saveSocial]
                deletearray.splice(index, 1)
                setSaveSocial(deletearray)
                deleteDataSocial(value)
            },
            onCancel() {
                message.error('ยกเลิกการลบข้อมูล')
            },
        });
    };
    const showConfirm3 = (value, index) => {
        confirm({
            title: 'คุณต้องการที่จะลบข้อมูลหรือไม่...?',
            content: 'กด OK เพื่อยืนยันการลบ',
            onOk() {
                const deletearray = [...saveEmail]
                deletearray.splice(index, 1)
                setSaveEmail(deletearray)
                deleteDataEmail(value)
            },
            onCancel() {
                message.error('ยกเลิกการลบข้อมูล')
            },
        });
    };

    const handleCancel = () => {
        close(false)
    }
    const onChangeBack = () => {
        changepage(page - 1)
    }
    const onChangeGo = () => {
        changepage(page + 1)
    }

    const fucnAddPhone = (value) => {
        if (savePhone.length === 0) {
            setSavePhone(pre => [...pre, { ...value.addressadd }]);
        } else {
            setSavePhone(pre => [...pre, { ...value.addressadd }]);
        }
        sendback(dayjs())
    }
    const fucnAddSocial = (value) => {
        if (saveSocial.length === 0) {
            setSaveSocial(pre => [...pre, { ...value.social }]);
        } else {
            setSaveSocial(pre => [...pre, { ...value.social }]);
        }
        sendback(dayjs())
    }
    const fucnAddEmail = (value) => {
        if (saveEmail.length === 0) {
            setSaveEmail(pre => [...pre, { ...value.email }]);
        } else {
            setSaveEmail(pre => [...pre, { ...value.email }]);
        }
        sendback(dayjs())
    }

    // แก้ไขข้อมูลจากหน้า AddressEditData
    const fucnEditPhone = ({ getData, getIndex }) => {
        let Theedit = [...savePhone]
        Theedit[getIndex] = getData
        //ข้อมูลใหม่แทนที่ข้อมูลเดิมในช่อง Input
        setSavePhone(Theedit);
        sendback(dayjs())
    }
    const fucnEditSocial = ({ getData, getIndex }) => {
        let Theedit = [...saveSocial]
        Theedit[getIndex] = getData
        //ข้อมูลใหม่แทนที่ข้อมูลเดิมในช่อง Input
        setSaveSocial(Theedit);
        sendback(dayjs())
    }
    const fucnEditEmail = ({ getData, getIndex }) => {
        let Theedit = [...saveEmail]
        Theedit[getIndex] = getData
        //ข้อมูลใหม่แทนที่ข้อมูลเดิมในช่อง Input
        setSaveEmail(Theedit);
        sendback(dayjs())
    }

    const renderPhone = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
        // index ตอนนี้เป็นข้อมูลจาก Object
        const handleEditPhone = (dataedit, index1) => {
            setIndex(index1) //เซตค่า index ให้กับชุด ข้อมูล
            setDataEditP({ dataedit, index1 }) //ส่งข้อมูลด index ไปหน้าแก้ไข
            setModalEditPhone(true);
        }
        return (
            <Form form={form}>
                <Row style={{ margin: '3px' }} justify={'center'} >
                    <Card
                        key={index} // index เป็นตัวกำหนดคีย์
                        style={{ textAlign: 'center' }}
                        title={<u>{item.phoneType}</u>}
                    >
                        {/* <div > */}
                        <Col span={24} style={{ textAlign: 'left' }}>
                            <Form.Item style={{ marginBottom: 0 }} label='เบอร์'>
                                {item.telp}
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='sendSms'>
                                {
                                    item.sendSms === "0" && ("ไม่ส่ง")
                                }
                                {
                                    item.sendSms === "1" && ("ส่ง")
                                }
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label="หมายเหตุ">
                                {item.note}
                            </Form.Item>
                            <Divider></Divider>
                            <Row justify={'center'} gutter={{ sx: 8, sm: 16, md: 24, lg: 32 }}>
                                <Form.Item>
                                    {/* ปุ่มลบข้อมูล */}
                                    <Button
                                        style={{ margin: '10px', background: '#f5222d', color: 'white', marginBottom: 0 }}
                                        icon={<DeleteOutlined />}
                                        // onClick={() => { deleteData(savePhone[index]) }}
                                        onClick={() => showConfirm(savePhone[index], index)}
                                    >ลบ</Button>
                                </Form.Item>
                                <Form.Item>
                                    {/* ปุ่มแก้ไขข้อมูล */}
                                    <Button
                                        style={{ margin: '10px', background: '#bfbfbf', color: '#000000', marginBottom: 0 }}
                                        type='submit'
                                        onClick={() => { handleEditPhone(savePhone[index], index) }}
                                    >แก้ไข</Button>
                                </Form.Item>
                            </Row>
                        </Col>
                        {/* </div> */}
                    </Card>
                </Row>
            </Form >
        )
    }

    const renderSocial = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์

        // index ตอนนี้เป็นข้อมูลจาก Object
        const handleEditSocial = (dataedit, index1) => {
            setIndex(index1) //เซตค่า index ให้กับชุด ข้อมูล
            setDataEditS({ dataedit, index1 }) //ส่งข้อมูลด index ไปหน้าแก้ไข
            setModalEditSocial(true);
            //console.log("edit")
        }
        return (
            <Form form={form}>
                <Row style={{ margin: '3px' }} justify={'center'} >
                    <Card
                        key={index} // index เป็นตัวกำหนดคีย์
                        style={{ textAlign: 'center' }}
                        title={<u>{item.socialType.toUpperCase()}</u>}
                    >
                        <Col span={24} style={{ textAlign: 'left' }}>
                            <Form.Item style={{ marginBottom: 0 }} label='แอคเคาท์'>
                                {item.socialData}
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='รับข่าวสาร'>
                                {
                                    item.sendGetNews === "0" && ("ไม่รับ")
                                }
                                {
                                    item.sendGetNews === "1" && ("รับ")
                                }
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label="หมายเหตุ">
                                {item.note}
                            </Form.Item>
                            <Divider></Divider>
                            <Row justify={'center'} gutter={{ sx: 8, sm: 16, md: 24, lg: 32 }}>
                                <Form.Item>
                                    {/* ปุ่มลบข้อมูล */}
                                    <Button
                                        style={{ margin: '10px', background: '#f5222d', color: 'white', marginBottom: 0 }}
                                        icon={<DeleteOutlined />}
                                        // onClick={() => { deleteData(savePhone[index]) }}
                                        onClick={() => showConfirm2(saveSocial[index], index)}
                                    >ลบ</Button>
                                </Form.Item>
                                <Form.Item>
                                    {/* ปุ่มแก้ไขข้อมูล */}
                                    <Button
                                        style={{ margin: '10px', background: '#bfbfbf', color: '#000000', marginBottom: 0 }}
                                        type='submit'
                                        onClick={() => { handleEditSocial(saveSocial[index], index); }}
                                    >แก้ไข</Button>
                                </Form.Item>
                            </Row>
                        </Col>
                    </Card>

                </Row>
            </Form>
        )
    }

    const renderEmail = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์

        // index ตอนนี้เป็นข้อมูลจาก Object
        const handleEditEmail = (dataedit, index1) => {
            setIndex(index1) //เซตค่า index ให้กับชุด ข้อมูล
            setDataEditE({ dataedit, index1 }) //ส่งข้อมูลด index ไปหน้าแก้ไข
            setModalEditEmail(true);
            //console.log("edit")
        }
        return (
            <Form form={form}>
                <Row style={{ margin: '3px' }} justify={'center'} >
                    <Card
                        key={index} // index เป็นตัวกำหนดคีย์
                        style={{ textAlign: 'center' }}
                        title={<u>E-mail</u>}
                    >
                        <Col span={24} style={{ textAlign: 'left' }}>
                            {/* <b>Email</b> */}
                            <Form.Item style={{ marginBottom: 0 }} label='E-Mail'>
                                {item.emailData}
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='รับข่าวสาร'>
                                {
                                    item.sendGetNews === "0" && ("ไม่รับ")
                                }
                                {
                                    item.sendGetNews === "1" && ("รับ")
                                }
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label="หมายเหตุ">
                                {item.note}
                            </Form.Item>
                            <Divider></Divider>
                            <Row justify={'center'} gutter={{ sx: 8, sm: 16, md: 24, lg: 32 }}>
                                <Form.Item>
                                    {/* ปุ่มลบข้อมูล */}
                                    <Button
                                        style={{ margin: '10px', background: '#f5222d', color: 'white', marginBottom: 0 }}
                                        icon={<DeleteOutlined />}
                                        // onClick={() => { deleteData(savePhone[index]) }}
                                        onClick={() => showConfirm3(saveEmail[index], index)}
                                    >ลบ</Button>
                                </Form.Item>
                                <Form.Item>
                                    {/* ปุ่มแก้ไขข้อมูล */}
                                    <Button
                                        style={{ margin: '10px', background: '#bfbfbf', color: '#000000', marginBottom: 0 }}
                                        type='submit'
                                        onClick={() => { handleEditEmail(saveEmail[index], index); }}
                                    >แก้ไข</Button>
                                </Form.Item>
                            </Row>
                        </Col>
                    </Card>

                </Row>
            </Form>
        )
    }

    return (
        <>
            <Card style={{ color: '#4096ff' }}>
                <Form>
                    <Row>
                        <Col className='gutter-row' span={24}>
                            {/* <Row> */}
                            <Col span={24} style={{ fontSize: '30px', textAlign: 'center' }} ><u>เบอร์ติดต่อ</u></Col>
                            {/* </Row> */}
                            <Divider />
                            <Row gutter={32}>
                                <Col span={24} style={{ textAlign: 'right' }}>
                                    {/* ปุ่มเพิ่มข้อมูล */}

                                    <Button type='primary' onClick={() => { setModalAddPhone(true); }}> <PlusOutlined />เบอร์ติดต่อ</Button>
                                </Col>
                            </Row>
                            <Row justify={'center'}>
                                {
                                    savePhone.map((item, index) => {
                                        return renderPhone({ item, index, key: `{item.identificationId} - ${index}` })
                                    })
                                }
                            </Row>
                            <Divider />
                            <Row gutter={32}>
                                <Col span={24} style={{ fontSize: '30px', textAlign: 'center' }} ><u>สื่อออนไลน์</u></Col>
                                {/* <Divider /> */}
                                <Col span={24} style={{ textAlign: 'right' }}>
                                    {/* ปุ่มเพิ่มข้อมูล */}
                                    <Button type='primary' onClick={() => { setModalAddSocial(true); }}> <PlusOutlined />สื่อออนไลน์</Button>
                                </Col>
                            </Row>
                            <Row justify={'center'}>
                                {
                                    saveSocial.map((item, index) => {
                                        return renderSocial({ item, index, key: `{item.identificationId} - ${index}` })
                                    })
                                }
                            </Row>
                            <Divider />
                            <Row gutter={32}>
                                <Col span={24} style={{ fontSize: '30px', textAlign: 'center' }} ><u>อีเมล</u></Col>
                                {/* <Divider /> */}
                                <Col span={24} style={{ textAlign: 'right' }}>
                                    {/* ปุ่มเพิ่มข้อมูล */}
                                    <Button type='primary' onClick={() => { setModalAddEmail(true); }}> <PlusOutlined />อีเมล</Button>
                                </Col>
                            </Row>
                            <Row justify={'center'}>
                                {
                                    saveEmail.map((item, index) => {
                                        return renderEmail({ item, index, key: `{item.identificationId} - ${index}` })
                                    })
                                }
                            </Row>
                        </Col>
                    </Row>
                    <Divider />
                    <div style={{ textAlign: "center" }} >
                        <Space>
                            <Button type="primary" style={{ background: '#bfbfbf', color: '#000000' }} onClick={onChangeBack}>ย้อนกลับ</Button>
                            <Button type="primary" onClick={handleCancel} style={{ background: "red" }}>ยกเลิก</Button>
                            <Button type="primary" onClick={onChangeGo}>ต่อไป</Button>
                        </Space>
                    </div>
                </Form>
            </Card>
            {
                //เพื่มข้อมูลในการ์ด
                modalAddPhone ?
                    <ModalAddPhone
                        open={modalAddPhone}
                        close={setModalAddPhone}
                        fucnAdd={fucnAddPhone}
                        shootdata={dataPost}
                        notification3={notification3}
                        notification4={notification4}
                    />
                    : null
            }
            {
                //เพื่มข้อมูลในการ์ด
                modalAddSocial ?
                    <ModalAddSocial
                        open={modalAddSocial}
                        close={setModalAddSocial}
                        fucnAdd={fucnAddSocial}
                        shootdata={dataPost}
                        notification3={notification3}
                        notification4={notification4}
                    />
                    : null
            }
            {
                //เพื่มข้อมูลในการ์ด
                modalAddEmail ?
                    <ModalAddEmail
                        open={modalAddEmail}
                        close={setModalAddEmail}
                        fucnAdd={fucnAddEmail}
                        shootdata={dataPost}
                        notification3={notification3}
                        notification4={notification4}
                    />
                    : null
            }
            {
                //แก้ไขข้อมูลในการ์ด
                modalEditPhone ?
                    <ModalEditPhone
                        open={modalEditPhone} // เซตการเปิด Modal จากหน้า AddressEditData
                        close={setModalEditPhone} // เซตการปิด Modal จากหน้า AddressEditData
                        shootdata={dataeditP} // 
                        fucnEdit={fucnEditPhone}
                        dataindex={index1}
                        notification={notification}
                        notification2={notification2}
                    />
                    : null
            }
            {
                //แก้ไขข้อมูลในการ์ด
                modalEditSocial ?
                    <ModalEditSocial
                        open={modalEditSocial} // เซตการเปิด Modal จากหน้า AddressEditData
                        close={setModalEditSocial} // เซตการปิด Modal จากหน้า AddressEditData
                        shootdata={dataeditS} // 
                        fucnEdit={fucnEditSocial}
                        dataindex={index1}
                        notification={notification}
                        notification2={notification2}
                    />
                    : null
            }
            {
                //แก้ไขข้อมูลในการ์ด
                modalEditEmail ?
                    <ModalEditEmail
                        open={modalEditEmail} // เซตการเปิด Modal จากหน้า AddressEditData
                        close={setModalEditEmail} // เซตการปิด Modal จากหน้า AddressEditData
                        shootdata={dataeditE} // 
                        fucnEdit={fucnEditEmail}
                        dataindex={index1}
                        notification={notification}
                        notification2={notification2}
                    />
                    : null
            }
            {/* {contextHolder} */}
        </>
    )

};
