import React, { useEffect, useState } from 'react'
import { Table, Button, Input, Modal, Typography, Tabs, Space } from 'antd';
import axios from 'axios'
import RateCarModal from './RateCarModal'
import AllCars from './AllCars'
import { render } from '@testing-library/react';
import { getprice } from '../../file_mid/all_api';
//import button1 from "./../../../assets/styles/button";

export default function TableRateCar({ open, close, productTypeCar, countoffer }) {

    const [carsData, setCarsData] = useState([]) // API
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [arrTable, setArrTable] = useState() // ตาราง
    const [selectData, setSelectData] = useState({})
    const [detail, setDetail] = useState({})


    // console.log("productTypeCar", productTypeCar)
    // console.log("countoffer",countoffer)

    const searchData = (e) => {
        const arr = carsData.filter((item) =>
            item.brand.toLowerCase().includes(e.target.value) ||
            item.models.toLowerCase().includes(e.target.value)
        )
        setArrTable(arr)
    }

    ////////////// ส่ง type_id กับ idmodel ///////////////////////////////////////////////
    const onPriceClick = (value) => {
        //console.log("test",value)
        setDetail(value)
        getPrice(value.type_id, value.idmodel)
        //console.log('type_id',value.type_id)
        //console.log('idmodel',value.idmodel)

    }
    const getPrice = async (typeId, carId) => {
        //console.log('typeid', typeId)
        //console.log('idmodel', carId)
        await axios.get(getprice, {
            params: { typeId, carId },
        }).then((res) => {
            setSelectData(res.data)
            setIsModalOpen(true)
        })
            .catch((err) => alert('ไม่พบข้อมูล ราคารถ'))
    }
    /////////////////////////////////////////////////////////////////////////////////////
    const handlesubmit = (e) => {
        close(false)
    }
    const handleCancel = (e) => {
        close(false)
    };
    const handleCancel2 = (e) => {
        setIsModalOpen(e)
    };

    const handleCloseAllModal = (value) => {
        //console.log("value",value) // false
        setIsModalOpen(value)
        close(value)
        //unlock(true)
    }

    const [key, setKey] = useState(productTypeCar); // กำหนดค่าเริ่มต้นของ key เป็น 1

    // สร้างฟังก์ชันเพื่อเปลี่ยนค่า key เมื่อมีการคลิก
    const handleTabClick = (newKey) => {
        setKey(newKey);
    }

    const onChange = (key) => {
        if (key == 1) {
            render(<AllCars note={"/cars"} data={getCar} />)
        }
        if (key == 2) {
            render(<AllCars note={"/tractors"} data={getCar} />)
        }
        if (key == 3) {
            render(<AllCars note={"/trucks"} data={getCar} />)
        }
        //console.log("key",key)
    }
    const getCar = (values) => {
        //console.log(values)
        setArrTable(values)
        setCarsData(values)
    }

    const items = [
        {
            key: '1',
            value: '1',
            label: `รถยนต์`,
            children: <AllCars note={"/cars"} data={getCar} />,
        },
        {
            key: '2',
            value: '2',
            label: `รถเครื่องจักการเกษตร`,
            // children: <AllCars note={"/tractors"} data={getCar}/>,
        },
        {
            key: '3',
            value: '3',
            label: `รถบรรทุก`,
            // children: <AllCars note={"/trucks"} data={getCar}/>,
        },

    ]

    const columns = [
        {
            key: 'idmodel',
            title: 'รหัส',
            dataIndex: 'idmodel',
            render: (record) => {
                return <p style={{ fontSize: 12 }}>{record}</p>
            },
        },
        {
            key: 'brand',
            title: 'ยี่ห้อ',
            dataIndex: 'brand',
            render: (record) => {
                return <p style={{ fontSize: 12 }}>{record}</p>
            },

        },
        {
            key: 'models',
            title: 'โมเดล',
            dataIndex: 'models',
            render: (record) => {
                return <p style={{ fontSize: 12 }}>{record}</p>
            },
        },
        {
            render: (record) => (
                <Button onClick={() => { onPriceClick(record) }}>เลือก</Button>
            ),
        },
    ]

    return (

        <Modal
            title="หาค่างวด"
            open={open}
            width={900}
            onCancel={handleCancel}
            footer={[
                <Button onClick={handlesubmit} htmlType="submit">
                    ยืนยัน
                </Button>,

                <Button onClick={handleCancel} key="close">
                    ยกเลิก
                </Button>
            ]}
        >
            <h2 style={{ textAlign: 'center' }}>เรท ราคารถ</h2>
            {/* <Tabs defaultActiveKey='1' items={items} onChange={onChange} /> */}
            <Space>
                {productTypeCar === 3 ?
                    <>
                        <Button onClick={() => handleTabClick(3)}>รถบรรทุก</Button>
                    </>
                    : productTypeCar === 2 ?
                        <>
                            <Button onClick={() => handleTabClick(2)}>รถเครื่องจักการเกษตร</Button>
                        </> : productTypeCar === 1 ?
                            <>
                                <Button onClick={() => handleTabClick(1)}>รถยนต์</Button>
                            </> : null
                }



            </Space>
            {key === 1 && <AllCars note="/cars" data={getCar} />}
            {key === 2 && <AllCars note="/tractors" data={getCar} />}
            {key === 3 && <AllCars note="/trucks" data={getCar} />}
            <Input.Search placeholder="ค้นหา....." onChange={searchData} />
            <Table bordered scroll={{ x: 700 }} dataSource={arrTable} columns={columns} />
            {
                isModalOpen ?
                    <RateCarModal open={isModalOpen} close={handleCloseAllModal} close2={handleCancel2} priceData={selectData} cars1={detail} countoffer={countoffer} />
                    : null
            }
            <div>
                <Typography>
                    {`* เรทราคาที่ให้อาจปรับตามสภาวะราคาตลาดได้ทุกเมื่อ`}
                </Typography>

                <Typography >
                    {`** ถ้าไม่มีเรทราคาที่จัด ให่โทรขออนุมัติยอดก่อน`}
                </Typography>
                <Typography >
                    {`*** เรทราคาที่ให้ สภาพรถจะต้องเป็นรถที่ไม่คว่ำหรือชนหนักมา`}
                </Typography>
                <Typography >
                    {`* เปลี่ยนเครื่องลบ 30,000-60,000 บาท`}
                </Typography>

                <Typography >
                    {`** เปลี่ยนสีลบ 10,000-40,000 บาท`}
                </Typography>

                <Typography >
                    {`* ถ้าลูกค้าให้ใบชำระค่างวดย้อนหลัง 6 เดือนอย่างน้อย 5 งวด และค่างวดใหม่น้อยกว่าเดิม 15% ให้ยอดจัดเพิ่ม 5%`}
                </Typography>
            </div>
        </Modal>
    )
}
