import React, { useEffect, useState } from 'react'
import { Button, Input, Form, DatePicker, Card, Col, Row, Select, Radio, Space, Divider, Upload, Modal, Image, message, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { addCustomer } from '../../../../../redux/Customer';
import { addImage } from '../../../../../redux/Image';
// import { guarantor } from '../../../../../redux/Guarantor';
import Compressor from 'compressorjs'
import { showrelationship1, storeDBLine, uploadImgGuaTable, getFolderGuaImg, deleteImgGua } from '../../../../file_mid/all_api';

function GECustomer({ next, close, theData, index, infor, showImage, getimages, setSendback, indexedit }) {

    // console.log("theData", theData)
    const token = localStorage.getItem('token');
    const un = localStorage.getItem('username');
    const guarantors = useSelector(state => state.guarantor)
    const [fileListGuarantors, setFileListGuarantors] = useState([]);
    const [fileListGuarantors1, setFileListGuarantors1] = useState([]);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [loading, setLoading] = useState(false);
    const customers = useSelector(state => state.customers)
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [dataPost, setDataPost] = useState({})
    const [value, setValue] = useState([])
    const [getimgs, setGetImgs] = useState([]);
    const [sendbacks, setSendbacks] = useState()
    // const [oldImgs, setOldImgs] = useState([])
    const { confirm } = Modal
    // const [dataCus, setDataCus] = useState(customers.data)

    // console.log("theDataGE", theData)

    useEffect(() => {
        loadRelationship()
        setDataPost(theData)
        // setSendback("555")
        form.setFieldsValue(
            {
                ...theData,
                birthDate: customers.data.birthDate ? dayjs(customers.data.birthDate) : (theData.birthDate ? dayjs(theData.birthDate) : ""),
                issudt: customers.data.issudt ? dayjs(customers.data.issudt) : (theData.issudt ? dayjs(theData.issudt) : ""),
                expdt: customers.data.expdt ? dayjs(customers.data.expdt) : (theData.expdt ? dayjs(theData.expdt) : ""),

            }
        )
        if (customers.data.customerId !== undefined) {
            setDataPost(customers.data)
            form.setFieldsValue(
                {
                    ...customers.data,
                    birthDate: customers.data.birthDate ? dayjs(customers.data.birthDate) : (theData.birthDate ? dayjs(theData.birthDate) : ""),
                    issudt: customers.data.issudt ? dayjs(customers.data.issudt) : (theData.issudt ? dayjs(theData.issudt) : ""),
                    expdt: customers.data.expdt ? dayjs(customers.data.expdt) : (theData.expdt ? dayjs(theData.expdt) : ""),
                }
            )
        }
        if (theData?.pathImg) {
            getImg()
        }

    }, [theData, sendbacks])

    const getImg = async () => {
        var path = theData?.pathImg
        // console.log("path", path)
        setLoading(true)
        axios.get(`${getFolderGuaImg}/${path}?token=${token}`)
            .then(res => {
                if (res.data) {
                    // console.log("res.dataGE", res.data)
                    setGetImgs(res.data)
                    dispatch(addImage(res.data))
                    setFileListGuarantors([])
                    setFileListGuarantors1([])
                    setLoading(false)
                }
            })
            .catch((err) => {
                console.log("err", err)
                setLoading(false)
            })
        setLoading(false)
    }

    const loadRelationship = async () => {
        await axios.get(showrelationship1)
            .then(res => {
                if (res.status === 200) {
                    setValue(res.data)
                }
            }).catch((err) => console.log(err))
    };


    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
    };
    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };
    const handleCancel1 = () => {
        setPreviewVisible(false);
    }

    function createImageUrl(imageData) {
        return imageData.image64;
    }

    const handleChangeGuarantors1 = async ({ fileList }) => {
        setLoading(true)
        var itemSplit = []
        // if (theData.pathImg) {
        //     itemSplit = theData.pathImg.split("/")
        // }

        if (theData.pathImg === "") {
            itemSplit[4] = indexedit + 1
            // itemSplit[4] = theData.garno
        } else {
            itemSplit = theData.pathImg.split("/")
        }
        // console.log("itemSplit", itemSplit[4])
        setFileListGuarantors(fileList);
        var imageListGuarantors = []
        var i = 0
        for (const file of fileList) {
            const compressedFile = await new Promise((resolve) => {
                new Compressor(file.originFileObj, {
                    quality: 0.1, // เช่น, 60%
                    success: (result) => {
                        resolve(result);
                    },
                });
            });
            var image = {}
            var filename = file.name
            var image64 = await getBase64(compressedFile)
            //var image64 = file.originFileObj
            image.filename = filename
            image.image64 = image64
            image.type = `5.${itemSplit[4]}`
            imageListGuarantors.push(image)
            i++
        }
        setFileListGuarantors1({ ...fileListGuarantors1, imageListGuarantors });
        setLoading(false)
    };

    // console.log("fileListGuarantors1", fileListGuarantors1)

    const handleNoti = async (path) => {
        const ez = { nameInput: un, path: path }
        await axios.post(storeDBLine, ez)
            .then(res => {
                if (res.data) {
                    console.log("OK")
                }
            })
            .catch((err) =>
                console.log(err)
            )
        setLoading(false)
    }

    const showConfirmdel = async (e, index, segments) => {
        const id = e.idImage
        const path = e.pathImage
        console.log("id", id)
        console.log("path", path)
        confirm({
            title: 'คุณต้องการที่จะลบรูปภาพนี้ใช่หรือไม่...?',
            onOk() {
                // setSendback(55)
                axios.delete(`${deleteImgGua}/${e.pathImage}`)
                    .then(async res => {
                        console.log("res delete", res)
                        await handleNoti(path)
                        setSendback(dayjs())
                        setSendbacks(dayjs())
                    })
                    .catch((err) =>
                        console.log(err)

                    )

            },
            onCancel() {
                message.error('ยกเลิกการลบรูปภาพ');
            },
        });

    };

    // console.log("fileListGuarantors1", fileListGuarantors1)
    // console.log("dataPost", dataPost)

    const axi = async (InputImage) => {
        console.log("InputImage", InputImage)
        setLoading(true)
        await axios.post(uploadImgGuaTable, InputImage)
            .then(response => {
                if (response) {
                    if (dataPost.pathImg === "") {
                        dispatch(addCustomer({ ...dataPost, pathImg: `one_images/${dataPost.typeLoan}/${dataPost.carLandId}/5/${indexedit + 1}` }))
                    } else {
                        dispatch(addCustomer(dataPost))
                    }
                    console.log("response", response)
                    // dispatch(addCustomer(dataPost))
                    setLoading(false)
                    setSendbacks(dayjs())
                    message.success('บันทึกสำเร็จ')
                    // close(false)
                }
            }).catch((err) => {
                console.log("err", err)
                setLoading(false)
            })
    }


    const handleSubmit = async (e) => {
        console.log("dataPost", dataPost)
        //const imageListGuarantorPush = [...theData.imgs];
        const imageListGuarantorPush1 = [];
        if (fileListGuarantors1?.imageListGuarantors) {
            // imageListGuarantorPush.push(...fileListGuarantors1?.imageListGuarantors)
            imageListGuarantorPush1.push(...fileListGuarantors1?.imageListGuarantors)
        }
        // console.log("imageListGuarantorPush1", imageListGuarantorPush1)
        if (guarantors?.data?.typeLoan === "car") {
            var id = 1
        } else {
            var id = 2
        }
        const InputImage = {
            mainImg: { carId: guarantors?.data?.carId.toString(), carInput: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"), productTypeId: id, img: imageListGuarantorPush1 }
        }
        console.log("InputImage", InputImage)
        await axi(InputImage)
        // delete dataPost.address
        // delete dataPost.phones
        // dispatch(addCustomer(dataPost))
        // onChangeGo()
    }

    const onChangeGo = () => {
        next()
    }

    const handleCancel = () => {
        close()
    }

    const changeDescription = (value) => {
        setDataPost({ ...dataPost, description: value })
    }

    return (
        <Card >
            <Spin spinning={loading} size='large' tip=" Loading... ">
                <div>
                    <div>
                        <Row>
                            <Col span={24} style={{ fontSize: '30px', textAlign: 'center' }}>ข้อมูลส่วนตัว</Col>
                        </Row>
                    </div>
                    <Divider></Divider>
                    <Form
                        labelCol={{
                            span: 12,
                        }}
                        wrapperCol={{
                            span: 12,
                        }}
                        form={form}
                        onFinish={handleSubmit}
                        autoComplete="off">
                        <Row>
                            <Col span={10}>
                                <Form.Item label='เลขประจำตัวประชาชน' name='identificationId'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <Input type='text' maxLength={13} disabled
                                    // onChange={(e) => inputIdCard(e)}
                                    >
                                    </Input>
                                </Form.Item>
                                <Form.Item label='รูปบัตรประชาชน'>
                                    <Input type="file" multiple={true} disabled
                                    // onChange={handleFileInputChange} 
                                    >
                                    </Input>
                                </Form.Item>
                                <Form.Item label="คำนำหน้าชื่อ" name="snam"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <Radio.Group onChange={(e) => setDataPost({ ...dataPost, snam: e.target.value })} >
                                        <Radio value="นาย"> นาย </Radio>
                                        <Radio value="นาง"> นาง </Radio>
                                        <Radio value="นางสาว"> นางสาว </Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item label='ชื่อ' name='firstname'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}
                                >
                                    <Input type='text'
                                        onChange={(e) => setDataPost({ ...dataPost, firstname: e.target.value })}></Input>
                                </Form.Item>
                                <Form.Item label='นามสกุล' name='lastname'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <Input type='text'
                                        onChange={(e) => setDataPost({ ...dataPost, lastname: e.target.value })}></Input>
                                </Form.Item>

                                <Form.Item label='ชื่อเล่น' name='nickname'>
                                    <Input type='text'
                                        onChange={(e) => setDataPost({ ...dataPost, nickname: e.target.value })}></Input>
                                </Form.Item>
                            </Col>
                            <Col span={2}>
                                <Form.Item>
                                    <Button type="primary" disabled
                                    // onClick={(e) => { handleIdCard(e) }}
                                    >ตรวจสอบ</Button>
                                </Form.Item>
                            </Col>
                            <Col span={10}>
                                {/* <Form.Item label='ประเทศ' name='nationalId'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <Input type='text'
                                        onChange={(e) => setDataPost({ ...dataPost, nationalId: e.target.value })}></Input>
                                </Form.Item> */}
                                <Form.Item label='เกิดวันที่' name='birthDate'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <DatePicker format={'YYYY-MM-DD'} style={{ width: '100%' }}
                                        onChange={(e) => {
                                            if (e) {
                                                setDataPost({ ...dataPost, birthDate: e.format('YYYY-MM-DD') })
                                            }
                                        }} />
                                </Form.Item>
                                <Form.Item label='เพศ' name='gender'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <Select
                                        placeholder={'เลือก'}
                                        style={{
                                            width: '100%',
                                        }}
                                        onChange={(value) => setDataPost({ ...dataPost, gender: value })}
                                        options={[
                                            {
                                                label: 'ชาย',
                                                value: 'M',
                                            },
                                            {
                                                label: 'หญิง',
                                                value: 'F',
                                            },
                                        ]} />
                                </Form.Item>
                                <Form.Item label='เจ้าพนักงานออกบัตร' name='issuby'
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: 'Please input !',
                                //     },]}
                                >
                                    <Input type='text'
                                        onChange={(e) => setDataPost({ ...dataPost, issuby: e.target.value })}></Input>
                                </Form.Item>
                                <Form.Item label='วันออกบัตร' name='issudt'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <DatePicker format={'YYYY-MM-DD'} style={{ width: '100%' }}
                                        onChange={(e) => {
                                            if (e) {
                                                setDataPost({ ...dataPost, issudt: e.format('YYYY-MM-DD') })
                                            }
                                        }} />
                                </Form.Item>
                                <Form.Item label='วันบัตรหมดอายุ' name='expdt'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <DatePicker format={'YYYY-MM-DD'} style={{ width: '100%' }}
                                        onChange={(e) => {
                                            if (e) {
                                                setDataPost({ ...dataPost, expdt: e.format('YYYY-MM-DD') })
                                            }
                                        }} />
                                </Form.Item>
                                {/* <Form.Item label='ความสัมพันกับผู้กู้' name='description'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input !',
                                    },]}>
                                <Input type='text'
                                    onChange={(e) => setDataPost({ ...dataPost, description: e.target.value })}></Input>
                            </Form.Item> */}
                                <Form.Item label='ความสัมพันธ์กับผู้กู้' name='description'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !'
                                        },]}>
                                    <Select
                                        placeholder="เลือกความสัมพันธ์"
                                        onChange={changeDescription}
                                        options={value}
                                    />
                                </Form.Item>

                            </Col>
                            <Divider />
                            <div>
                                <Row justify={'left'} > <span><b>เพิ่มเอกสารผู้ค้ำ :</b></span></Row>
                                <Row justify={'center'}>
                                    <Form.Item
                                        label=''
                                        name='imgGuarantor'
                                        style={{ textAlign: 'center' }}
                                    >
                                        <Upload
                                            multiple={true}
                                            listType="picture-card"
                                            fileList={fileListGuarantors}
                                            onPreview={handlePreview}
                                            onChange={handleChangeGuarantors1}
                                            // onChange={handleChangeGuarantor}
                                            beforeUpload={() => false}
                                        >
                                            {fileListGuarantors?.length >= 10 ? null : (
                                                <div>
                                                    <PlusOutlined />
                                                    <div className="ant-upload-text">upload</div>
                                                </div>
                                            )}
                                        </Upload>
                                        <Modal open={previewVisible} onCancel={handleCancel1}>
                                            <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                        </Modal>
                                    </Form.Item>
                                </Row>
                            </div>
                            <Divider />
                            <div>
                                <Row style={{ marginLeft: 5 }}><span><b>รูปเอกสารผู้ค้ำ :</b></span></Row>
                                <Row justify={'center'}>
                                    {getimgs?.map((items, index) => {
                                        // const segments = items.pathImage.split("/")

                                        // if (itemSplit[4] === segments[4]) {
                                        //console.log("test",getimages.length)
                                        return (
                                            // <img width={'300px'} key={index} style={{ margin: 5 }} src={createImageUrl(items.image64)} alt={items.filename} />
                                            <Row gutter={32} >
                                                <Col span={24} >
                                                    <Image width={'100px'} key={index} src={`data:${items?.Attr};base64,${items?.data?.toString('base64')}`} alt={`Image ${index + 1}`} />
                                                    <p><Button onClick={() => showConfirmdel(items, index)} type='primary' danger>ลบรูปภาพ</Button></p>
                                                </Col>
                                            </Row>
                                        )
                                        // }

                                    })}
                                </Row>

                                <Divider />
                            </div>
                        </Row>
                        <div style={{ textAlign: "center" }}>
                            <Space>
                                <Button type="primary" style={{ background: "red" }} onClick={handleCancel}>ยกเลิก</Button>
                                <Button type="primary" htmlType="submit" style={{ background: "green" }}>บันทึก</Button>
                                <Button type="primary" onClick={onChangeGo}>ต่อไป</Button>
                            </Space>
                        </div>
                    </Form>
                </div>
            </Spin>
        </Card>
    )
};


export default GECustomer