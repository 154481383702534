import React, { useState, useEffect } from 'react'
import { Form, Modal, Row, Col, Image, Space, Card, Button, Tag, Divider, message, Spin, Upload, notification } from 'antd'
import dayjs from 'dayjs';
import axios from "axios";
import TextArea from 'antd/es/input/TextArea';
import Compressor from 'compressorjs';
import { SendOutlined, PlusOutlined } from '@ant-design/icons'
import ModalMainMix from './ModalMainMix';
import { findlandall, findlandpn, mklandmix, resendlandmk, getImagess } from '../../file_mid/all_api';

export default function ModalInfoLand({ open, dataFromTable, close }) {
    const [api, contextHolder] = notification.useNotification();
    const token = localStorage.getItem('token');
    // const [imageBlobzz, setImageBlobzz] = useState([]);
    const [dataLand] = useState(dataFromTable);
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const { confirm } = Modal;
    const [reSendData, setReSendData] = useState({});
    //const des = JSON.parse(dataFromTable.des)
    const [des, setDes] = useState([]);
    // const [land, setLand] = useState();
    const [number, setNumber] = useState(0);
    const [checkImage, setCheckImage] = useState();

    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');

    //เพิ่มรูป
    const [fileListLand] = useState([]);
    const [fileListDeep] = useState([]);
    const [fileListCustomerLeafDivider] = useState([]);
    const [fileListCustomerBurden] = useState([]);

    const [newDataLand, setNewDataLand] = useState([]);
    const [isModalMainMix, setIsModalMainMix] = useState(false);
    const [dataEditIndex, setDataEditIndex] = useState()
    const [checkEdit, setCheckEdit] = useState(false)
    const [dataEdit, setDataEdit] = useState()
    const [editMode, setEditMode] = useState(false)

    const [dataForMix, setDataForMix] = useState({
        landLoanId: dataFromTable?.landLoanId,
        findLandPN: []
    })

    // const [allImg, setAllImg] = useState([])

    useEffect(() => {
        loadData()
        loadMixData()
        form.setFieldsValue(
            {
                storyLand: dataFromTable.storyLand ? dataFromTable.storyLand : '',
            }
        )
    }, [])

    useEffect(() => {
        setDataForMix({ ...dataForMix, findLandPN: newDataLand })
    }, [newDataLand])

    useEffect(() => {
        dataForMix.findLandPN.map((e, index) => {
            form.setFieldsValue({
                [`storyLand${index}`]: e?.storyLand,
            })
        })
    }, [dataForMix])

    console.log("dataForMix", dataForMix)

    const loadData = async () => {
        await axios.post(findlandall, { carId: dataFromTable.landId, customerId: dataFromTable.customerId })
            .then(res => {
                if (res.status === 200) {
                    setDes(res.data.landLoanDetails.notes)
                    setReSendData(res.data)
                    // setLand(res.data.landLoanDetails.land)
                }
            }).catch((err) => {
                console.log("err", err)
            })
    }

    const handleSave = async () => {
        // if (fileListGuarantor1?.imageListGuarantorImage?.length >= 1) {
        //     handleScanIdCardGuarantor(fileListGuarantor1.imageListGuarantorImage)
        // }
        setTimeout(() => {
            message.success('บันทึกสำเร็จ')
            setNumber(1)
        }, 2000)
    };

    const successMix = (placement) => {
        api.success({
            message: <b>เพิ่มข้อมูลสำเร็จ</b>,
            // description:
            //     'กรุณา "ปิด" หน้าส่งเอกสารไปตรวจสอบ แล้วเข้าใหม่',
            placement,
        });
    }
    const successSend = (placement) => {
        api.success({
            message: <b>ส่งเคสสำเร็จ</b>,
            // description:
            //     'กรุณา "ปิด" หน้าส่งเอกสารไปตรวจสอบ แล้วเข้าใหม่',
            placement,
        });
    }

    const errorMix = (placement) => {
        api.error({
            message: <b>เพิ่มข้อมูลไม่สำเร็จ</b>,
            description:
                'เพิ่มข้อมูลไม่สำเร็จ กรุณาติดต่อ IT',
            placement,
        });
    }
    const errorSend = (placement) => {
        api.error({
            message: <b>ส่งเคสไม่สำเร็จ</b>,
            description:
                'เพิ่มข้อมูลไม่สำเร็จ กรุณาติดต่อ IT',
            placement,
        });
    }


    const showConfirm = () => {
        confirm({
            title: 'คุณต้องการที่จะเสนอเคสนี้...?',
            onOk() {
                handleMixLandSend()
            },
            onCancel() {
            },
        });
    };

    const handleMixLandSend = async () => {
        axios.post(mklandmix, dataForMix)
            .then(res => {
                if (res.status === 200) {
                    console.log("OK")
                    successMix('top')
                    handleNewSend()
                } else {
                    errorMix('top')
                }
            }).catch((err) =>
                errorMix('top')
                // console.log(err)
            )
    }
    const handleNewSend = async () => {
        var mix = { ...reSendData.landLoanDetails, land: { ...reSendData.landLoanDetails.land, carInput: dayjs().format("YYYY-MM-DD") }, approvalStatus: "รอพี่หนุ่มรับ", rejectStatus: false, }
        delete mix.notes
        axios.put(resendlandmk, mix)
            .then(res => {
                if (res.status === 200) {
                    successSend("top")
                    setTimeout(() => {
                        close(false)
                    }, 1500);
                } else {
                    errorSend("top")
                }
            }).catch((err) =>
                errorSend("top")
                // console.log(err)
            )
    }

    const handleLand = async (value, index) => {
        const newFileList = [];

        for (const removedFile of fileListLand.filter(file => file.status === 'removed')) {
            if (dataForMix.findLandPN[index].img2.some(img => img.filename === removedFile.name && img.type === 1)) {
                const updatedImg2 = dataForMix.findLandPN[index].img2.filter(img => !(img.filename === removedFile.name && img.type === 1));
                const updatedFindLandPN = [...dataForMix.findLandPN];
                updatedFindLandPN[index] = { ...updatedFindLandPN[index], img2: updatedImg2 };
                setDataForMix({ ...dataForMix, findLandPN: updatedFindLandPN });
            }
        }
        value.fileList.forEach((file) => {
            if (!fileListLand.some((f) => f.uid === file.uid)) {
                newFileList.push(file);
            }
        });

        // for (const removedFile of fileListLand.filter(file => file.status === 'removed')) {
        //     if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 3)) {
        //         const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 3));
        //         setAllImg(updatedAllImg);
        //     }
        // }
        // fileList.forEach((file) => {
        //     if (!fileListLand.some((f) => f.uid === file.uid)) {
        //         newFileList.push(file);
        //     }
        // });
        fileListLand.push(...newFileList)
        setCheckImage(value.fileList);
        setLoading(false)
    };

    const handleDeep = async (value, index) => {
        const newFileList = [];

        for (const removedFile of fileListDeep.filter(file => file.status === 'removed')) {
            if (dataForMix.findLandPN[index].img2.some(img => img.filename === removedFile.name && img.type === 3)) {
                const updatedImg2 = dataForMix.findLandPN[index].img2.filter(img => !(img.filename === removedFile.name && img.type === 3));
                const updatedFindLandPN = [...dataForMix.findLandPN];
                updatedFindLandPN[index] = { ...updatedFindLandPN[index], img2: updatedImg2 };
                setDataForMix({ ...dataForMix, findLandPN: updatedFindLandPN });
            }
        }
        value.fileList.forEach((file) => {
            if (!fileListDeep.some((f) => f.uid === file.uid)) {
                newFileList.push(file);
            }
        });

        // for (const removedFile of fileListDeep.filter(file => file.status === 'removed')) {
        //     if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 3)) {
        //         const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 3));
        //         setAllImg(updatedAllImg);
        //     }
        // }
        // fileList.forEach((file) => {
        //     if (!fileListDeep.some((f) => f.uid === file.uid)) {
        //         newFileList.push(file);
        //     }
        // });
        fileListDeep.push(...newFileList)
        setCheckImage(value.fileList);
        setLoading(false)
    };
    const handleChangeCustomerLeafDivider = async (value, index) => {
        const newFileList = [];

        for (const removedFile of fileListCustomerLeafDivider.filter(file => file.status === 'removed')) {
            if (dataForMix.findLandPN[index].img2.some(img => img.filename === removedFile.name && img.type === 10)) {
                const updatedImg2 = dataForMix.findLandPN[index].img2.filter(img => !(img.filename === removedFile.name && img.type === 10));
                const updatedFindLandPN = [...dataForMix.findLandPN];
                updatedFindLandPN[index] = { ...updatedFindLandPN[index], img2: updatedImg2 };
                setDataForMix({ ...dataForMix, findLandPN: updatedFindLandPN });
            }
        }
        value.fileList.forEach((file) => {
            if (!fileListCustomerLeafDivider.some((f) => f.uid === file.uid)) {
                newFileList.push(file);
            }
        });

        // for (const removedFile of fileListCustomerLeafDivider.filter(file => file.status === 'removed')) {
        //     if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 3)) {
        //         const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 3));
        //         setAllImg(updatedAllImg);
        //     }
        // }
        // fileList.forEach((file) => {
        //     if (!fileListCustomerLeafDivider.some((f) => f.uid === file.uid)) {
        //         newFileList.push(file);
        //     }
        // });
        fileListCustomerLeafDivider.push(...newFileList)
        setCheckImage(value.fileList);
        setLoading(false)
    };
    const handleChangeCustomerBurden = async (value, index) => {

        // const newFileList = [];
        // for (const removedFile of fileListCustomerBurden.filter(file => file.status === 'removed')) {
        //     if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 11)) {
        //         const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 11));
        //         setAllImg(updatedAllImg);
        //     }
        // }

        const newFileList = [];
        for (const removedFile of fileListCustomerBurden.filter(file => file.status === 'removed')) {
            if (dataForMix.findLandPN[index].img2.some(img => img.filename === removedFile.name && img.type === 11)) {
                const updatedImg2 = dataForMix.findLandPN[index].img2.filter(img => !(img.filename === removedFile.name && img.type === 11));
                const updatedFindLandPN = [...dataForMix.findLandPN];
                updatedFindLandPN[index] = { ...updatedFindLandPN[index], img2: updatedImg2 };
                setDataForMix({ ...dataForMix, findLandPN: updatedFindLandPN });
            }
        }
        value.fileList.forEach((file) => {
            if (!fileListCustomerBurden.some((f) => f.uid === file.uid)) {
                newFileList.push(file);
            }
        });
        fileListCustomerBurden.push(...newFileList)
        setCheckImage(value.fileList);
        setLoading(false)
    };

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
    };
    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const handleCancel = () => {
        close(false)
    }
    const handleCancelPreview = () => {
        setPreviewVisible(false);
    }

    const handleOk = async () => {
        close(false);
    };

    const handleAddLand = async () => {
        setIsModalMainMix(true);
    };

    const currencyFormatOne = (amount) => {
        return Number(amount)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }

    const loadMixData = async () => {
        await axios.post(findlandpn, { landLoanId: dataFromTable.landLoanId })
            .then(res => {
                if (res.status === 200) {
                    var addIMG2 = res.data.map((data) => {
                        return Object.assign({}, data, { img2: [] });
                    })
                    setNewDataLand(addIMG2)
                }
            }).catch((err) => {
                console.log("err", err)
            })
    }

    let color

    if (dataLand?.approvalStatus === "รอธุรการรับ") {
        color = "gold"
    }
    if (dataLand?.approvalStatus === "รอพี่หนุ่มรับ") {
        color = "gold"
    }
    if (dataLand?.approvalStatus === "รออนุมัติราคารถ") {
        color = "orange"
    }
    if (dataLand?.approvalStatus === "รอเคาะราคา") {
        color = "gold"
    }
    if (dataLand?.approvalStatus === "เคาะราคาแล้ว") {
        color = "orange"
    }
    if (dataLand?.approvalStatus === "รอพิจารณา") {
        color = "cyan"
    }
    if (dataLand?.approvalStatus === "ผ่านพิจารณา") {
        color = "geekblue"
    }
    if (dataLand?.approvalStatus === "ขอเอกสารเพิ่ม") {
        color = "geekblue"
    }
    if (dataLand?.approvalStatus === "รออนุมัติ") {
        color = "lime"
    }
    if (dataLand?.approvalStatus === "อนุมัติ") {
        color = "green"
    }
    if (dataLand?.approvalStatus === "อนุมัติแบบมีเงื่อนไข") {
        color = "green"
    }
    if (dataLand?.approvalStatus === "โทรติด") {
        color = "magenta"
    }
    if (dataLand?.approvalStatus === "โทรไม่ติด(ติดต่อไม่ได้)") {
        color = "volcano"
    }
    if (dataLand?.approvalStatus === "ไม่อนุมัติ") {
        color = "red"
    }
    if (dataLand?.approvalStatus === "ปัดตก") {
        color = "red"
    }

    const handleEditLand = async (value, index) => {
        setCheckEdit(true)
        setDataEdit(value)
        setDataEditIndex(index)
        setIsModalMainMix(true)
    }

    const handleEditMode = async () => {
        if (editMode) {
            setEditMode(false)
        } else {
            setEditMode(true)
        }
    }

    const funcEdit = (value, index) => {
        let swapData = [...newDataLand]
        swapData[index] = { ...value, img2: [] }
        setNewDataLand(swapData);
    }

    const funcAdd = (value) => {
        // setNewDataLand(pre => [...pre, { ...value }]);
        // setDataForMix(pre => [...pre.findLandPN, { ...value }]);
        setDataForMix(prevData => ({ ...prevData, findLandPN: [...prevData.findLandPN, { ...value, img2: [] }] }));

    }

    const handleDeleteLand = (value, index) => {
        confirm({
            title: 'ต้องการที่จะยกเลิกจดรวมข้อมูลหรือไม่...?',
            content: 'กด OK เพื่อยืนยันการยกเลิกจดรวม',
            onOk() {
                const deletearray = [...newDataLand]
                deletearray.splice(index, 1)
                setNewDataLand(deletearray)
            },
            onCancel() {
            },
        });
    };

    // const compressImage = async (ez, types, index) => {
    //     var image = {};
    //     return new Promise(async (resolve) => {
    //         if (types !== 7) {
    //             if (ez.size > 4000000) {
    //                 console.log("4m ไม่ให้ส่ง");
    //                 resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
    //             } else {
    //                 let quality;
    //                 if (ez.size >= 2500000 && ez.size <= 4000000) {
    //                     quality = 0.1;
    //                 } else if (ez.size >= 2000000 && ez.size <= 2499999) {
    //                     quality = 0.15;
    //                 } else if (ez.size >= 1500000 && ez.size <= 1999999) {
    //                     quality = 0.2;
    //                 } else if (ez.size >= 1000000 && ez.size <= 1499999) {
    //                     quality = 0.3;
    //                 } else {
    //                     quality = 0.5;
    //                 }

    //                 try {
    //                     // ใช้ Promise เพื่อบีบอัดรูปภาพ
    //                     const compressedFile = await new Promise((resolveCompress) => {
    //                         new Compressor(ez, {
    //                             quality: quality,
    //                             success: (result) => {
    //                                 resolveCompress(result);
    //                             },
    //                         });
    //                     });

    //                     const fileOptions = {
    //                         type: compressedFile.type,
    //                         lastModified: Date.now(),
    //                     };
    //                     const compressedFileAsFile = new File([compressedFile], ez.name, fileOptions);
    //                     image.imageCompressedFile = compressedFileAsFile;
    //                     image.type = types


    //                     image.filename = filename
    //                     image.image64 = image64
    //                     image.type = types
    //                     // if (landId === undefined) {
    //                     //     image.landId = landId
    //                     // } else {
    //                     //     image.landId = landId
    //                     // }
    //                     // allImg.push(image);

    //                     if (dataForMix?.findLandPN[index]?.img2 === undefined) {
    //                         setDataForMix({
    //                             ...dataForMix,
    //                             findLandPN: dataForMix?.findLandPN?.map((item, idx) =>
    //                                 idx === index ? { ...item, img2: [image] } : item
    //                             )
    //                         });
    //                     } else {
    //                         dataForMix.findLandPN[index].img2.push(image);
    //                     }
    //                     //setAllImg((prevAllImg) => [...prevAllImg, image]);

    //                     // ตรงนี้คือการ resolve ไฟล์ที่ถูกบีบอัด
    //                     resolve(image);
    //                 } catch (error) {
    //                     console.error('Image compression error:', error);
    //                     resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
    //                 }
    //             }
    //         } else {
    //             // ... (โลจิกสำหรับ type 7)
    //             image.imageCompressedFile = ez;
    //             image.type = types
    //             // allImg.push(image);
    //             //setAllImg((prevAllImg) => [...prevAllImg, image]);
    //             resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
    //         }
    //     });
    // };


    const compressImage = async (ez, types, index) => {
        var image = {};
        return new Promise(async (resolve) => {
            if (types !== 7) {
                if (ez.size > 4000000) {
                    console.log("4m ไม่ให้ส่ง");
                    resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
                } else {
                    let quality;
                    if (ez.size >= 2500000 && ez.size <= 4000000) {
                        quality = 0.1;
                    } else if (ez.size >= 2000000 && ez.size <= 2499999) {
                        quality = 0.15;
                    } else if (ez.size >= 1500000 && ez.size <= 1999999) {
                        quality = 0.2;
                    } else if (ez.size >= 1000000 && ez.size <= 1499999) {
                        quality = 0.3;
                    } else {
                        quality = 0.5;
                    }

                    try {
                        // ใช้ Promise เพื่อบีบอัดรูปภาพ
                        const compressedFile = await new Promise((resolveCompress) => {
                            new Compressor(ez, {
                                quality: quality,
                                success: (result) => {
                                    resolveCompress(result);
                                },
                            });
                        });

                        // แบบเก่า
                        // const fileOptions = {
                        //     type: compressedFile.type,
                        //     lastModified: Date.now(),
                        // };
                        // const compressedFileAsFile = new File([compressedFile], ez.name, fileOptions);
                        // image.imageCompressedFile = compressedFileAsFile;
                        // image.type = types
                        // allImg.push(image);

                        // แบบ base64
                        var filename = ez.name
                        var image64 = await getBase64(compressedFile)
                        image.filename = filename
                        image.image64 = image64
                        image.type = types
                        // allImg.push(image)

                        if (dataForMix?.findLandPN[index]?.img2 === undefined) {
                            setDataForMix({
                                ...dataForMix,
                                findLandPN: dataForMix?.findLandPN?.map((item, idx) =>
                                    idx === index ? { ...item, img2: [image] } : item
                                )
                            });
                        } else {
                            dataForMix.findLandPN[index].img2.push(image);
                        }

                        // ตรงนี้คือการ resolve ไฟล์ที่ถูกบีบอัด
                        resolve(image);
                    } catch (error) {
                        console.error('Image compression error:', error);
                        resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
                    }
                }
            } else {
                // ... (โลจิกสำหรับ type 7)
                image.imageCompressedFile = ez;
                image.type = types
                // allImg.push(image);
                resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
            }
        });
    };
    const beforeUpts = async (file, ty, index) => {
        // console.log("landId", landId)
        try {
            // setLoading(true)
            const compressedFile = await compressImage(file, ty, index);
            return Promise.resolve(compressedFile);
        } catch (error) {
            console.error('Image compression error:', error);
            message.error('Image compression failed.');
            return Promise.reject(); // เปลี่ยนให้ return Promise.reject() เมื่อไม่สามารถบีบอัดได้
        }
    };

    const renderDataNote = ({ item, index }) => {
        return (
            <>
                <Row gutter={32} justify={'center'}>
                    <Col className='gutter-row' span={24}>
                        <Card key={index} style={{ width: '270px', textAlign: 'left' }}>
                            <div style={{ marginBottom: 0 }}>
                                <span>สาเหตุ : </span>
                                <b>{item.cause}</b>
                            </div>
                            <div style={{ marginBottom: 0 }}>
                                <span>หมายเหตุ : </span>
                                <b>{item.note}</b>
                            </div>
                            <div style={{ marginBottom: 0 }}>
                                <span>ผู้สร้างหมายเหตุ : </span>
                                <b>{item.noteBy}</b>
                            </div>
                            <div style={{ marginBottom: 0 }}>
                                <span>สถานะที่ทำการหมายเหตุ : </span>
                                <b>{item.status}</b>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }
    const renderLandData = ({ item, index }) => {
        return (
            <>
                <Row gutter={32} justify={'center'}>
                    <Col className='gutter-row' span={24}>
                        <>
                            <Divider orientation={'left'}><b><u>รายละเอียดที่ดิน ({item?.numberLand}) {newDataLand.length > 1 ? <>{item.landMain ? <b style={{ color: "red" }} >(ที่ดินหลัก)</b> : null}</> : null}</u></b></Divider>
                            <Row gutter={32} justify={'center'}>
                                <aside style={{ width: '90%' }}>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>ประเภทเอกสาร : </span>{<b>{item?.productTypeLand === 1 ? "นส.3" : "นส.4"}</b>}
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>เลขโฉนดที่ดิน : </span>{<b>{item?.numberLand}</b>}
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>เลขที่ดินหรือระวาง : </span>{<b>{item?.numberLandlawang}</b>}
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>จังหวัด : </span>{<b>{item?.provinces}</b>}
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>อำเภอ : </span>{<b>{item?.district}</b>}
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>พื้นที่ไร่ : </span>{<b>{item?.rai}</b>}
                                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                            ไร่
                                        </span>
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>พื้นที่งาน : </span>{<b>{item?.workArea}</b>}
                                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                            งาน
                                        </span>
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>พื้นที่ตารางวา : </span>{<b>{currencyFormatOne(item?.squareWaArea)}</b>}
                                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                            บาท
                                        </span>
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>ราคาประเมินที่ดิน : </span> {<b>{item?.landPrice}</b>}
                                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                            บาท / ตารางวา
                                        </span>
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>ราคารวม : </span> {<b>{item?.resultLandPrice}</b>}
                                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                            บาท
                                        </span>
                                    </div>

                                </aside>
                                {
                                    editMode ?
                                        <>
                                            {
                                                index === 0 ?
                                                    null :
                                                    <>
                                                        <Space direction='vertical'>
                                                            {/* <Button style={{ width: "100px", backgroundColor: "#DCD9D4" }} onClick={() => handleEditLand(dataForMix.findLandPN[index], index)}>เปลี่ยนที่ดิน</Button> */}
                                                            <Button style={{ width: "100px" }} type='primary' danger onClick={() => handleDeleteLand(dataForMix.findLandPN[index], index)}>ยกเลิกจดรวม</Button>
                                                        </Space>
                                                    </>
                                            }
                                        </> : null
                                }
                            </Row>
                            <Divider orientation={'left'}><b><u>สตอรี่รายละเอียดที่ดิน ({item?.numberLand})</u></b></Divider>
                            <Form.Item name={`storyLand${index}`} label="">
                                <TextArea style={{ color: 'blue' }} rows={5} disabled />
                            </Form.Item>
                        </>
                    </Col>
                </Row>
            </>
        )

    }
    const renderCoordinates = ({ item, index }) => { //render Land Array
        return (
            <>
                <div>
                    <span>รายละเอียดพิกัด ({item?.numberLand}) : </span>
                    {
                        item.parcellat === null ?
                            null
                            :
                            <a href={`https://www.google.com/maps?q=${item.parcellat},${item.parcellon}`} target="_blank">
                                {`${item.parcellat},${item.parcellon}`}
                            </a>
                    }

                </div>
            </>
        )
    }
    const renderApproval = ({ item, index }) => {
        return (
            <>
                <aside style={{ width: '90%' }}>
                    <div style={{ marginBottom: 0 }}>
                        <span>ยอดที่อนุมัติ ({item?.numberLand}) : </span>{<b>{item?.approval?.minPrice ? currencyFormatOne(item?.approval?.minPrice) : 0} - {item?.approval?.maxPrice ? currencyFormatOne(item?.approval?.maxPrice) : 0}</b>}
                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                            บาท
                        </span>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>หมายเหตุ : </span>{<b>{item?.approval?.note ? item?.approval?.note : "-"}</b>}
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>หมายเหตุการจดรวม : </span>{<b>{item?.approval?.mixNote ? item?.approval?.mixNote : "-"}</b>}
                    </div>
                </aside>
            </>
        )
    }
    const renderTitleDeed = ({ item, index }) => { //render Land Array
        return (
            <>
                <Row justify={'left'}><b><u>รูปโฉนด/หน้า-หลัง ({item.numberLand})</u></b></Row>
                <Row gutter={32} justify={'center'}>
                    <Space>
                        <Row justify={'center'}>
                            {item?.img?.map((e, index) => {
                                if (e.type === 3) {
                                    return (
                                        <Row gutter={32} justify={'center'}>
                                            <Col span={24} style={{ textAlign: 'center' }}>
                                                <Image style={{ margin: 3 }} width={'100px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                            </Col>
                                        </Row>
                                    );
                                }
                                return null;
                            })}
                        </Row>
                        {dataFromTable?.approvalStatus === "ขอเอกสารเพิ่ม" ?
                            <>
                                <div className='gutter-row' span={12}>
                                    <b> <Form.Item label='เพิ่มรูป' name='imageIDGuCar'
                                    >
                                        <Upload
                                            name='imageIDGuCar'
                                            multiple={true}
                                            listType="picture-card"
                                            // fileList={fileListDeep}
                                            onPreview={handlePreview}
                                            // onChange={handleDeep}
                                            onChange={(value) => handleDeep(value, index)}
                                            // beforeUpload={() => false}
                                            beforeUpload={(file) => beforeUpts(file, 3, index).then(() => false).catch(() => true)}
                                        >
                                            <div>
                                                <PlusOutlined />
                                                <div className="ant-upload-text">upload</div>
                                            </div>
                                        </Upload>
                                        <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                            <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                        </Modal>
                                    </Form.Item>
                                    </b>
                                </div>
                            </>
                            : null
                        }
                    </Space>
                </Row>
                <Divider />
            </>
        )
    }
    const renderDivision = ({ item, index }) => { //render Land Array
        return (
            <>
                <Row justify={'left'}><b><u>ใบแบ่งใบต่อ ({item.numberLand})</u></b></Row>
                <Row gutter={32} justify={'center'}>
                    <Space>
                        <Row justify={'center'}>
                            {item?.img?.map((e, index) => {
                                if (e.type === 10) {
                                    return (
                                        <>
                                            <Row gutter={32} justify={'center'}>
                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                    <Image style={{ margin: 3 }} width={'100px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                </Col>
                                            </Row>


                                        </>
                                    );
                                }
                                return null;
                            })}
                        </Row>
                        {dataFromTable?.approvalStatus === "ขอเอกสารเพิ่ม" ?
                            <>
                                <div className='gutter-row' span={12}>
                                    <b> <Form.Item label='เพิ่มรูป' name='imageIDGuCar'
                                    >
                                        <Upload
                                            name='imageIDGuCar'
                                            multiple={true}
                                            listType="picture-card"
                                            // fileList={fileListCustomerLeafDivider}
                                            onPreview={handlePreview}
                                            // onChange={handleChangeCustomerLeafDivider}
                                            onChange={(value) => handleChangeCustomerLeafDivider(value, index)}
                                            // beforeUpload={() => false}
                                            beforeUpload={(file) => beforeUpts(file, 10, index).then(() => false).catch(() => true)}
                                        >
                                            <div>
                                                <PlusOutlined />
                                                <div className="ant-upload-text">upload</div>
                                            </div>
                                        </Upload>
                                        <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                            <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                        </Modal>
                                    </Form.Item>
                                    </b>
                                </div>
                            </>
                            : null
                        }
                    </Space>
                </Row>
                <Divider />
            </>
        )
    }
    const renderLawang = ({ item, index }) => { //render Land Array
        return (
            <>
                <Row justify={'left'}><b><u>ระวาง ({item.numberLand})</u></b></Row>
                <Row gutter={32} justify={'center'}>
                    <Space>
                        <Row justify={'center'}>
                            {item?.img?.map((e, index) => {
                                if (e.type === 11) {
                                    return (
                                        <>
                                            <Row gutter={32} justify={'center'}>
                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                    <Image style={{ margin: 3 }} width={'100px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                </Col>
                                            </Row>
                                        </>
                                    );
                                }
                                return null;
                            })}
                        </Row>
                        {dataFromTable?.approvalStatus === "ขอเอกสารเพิ่ม" ?
                            <>
                                <div className='gutter-row' span={12}>
                                    <b> <Form.Item label='เพิ่มรูป' name='imageIDGuCar'
                                    >
                                        <Upload
                                            name='imageIDGuCar'
                                            multiple={true}
                                            listType="picture-card"
                                            // fileList={fileListCustomerBurden}
                                            onPreview={handlePreview}
                                            onChange={(value) => handleChangeCustomerBurden(value, index)}
                                            // beforeUpload={() => false}
                                            beforeUpload={(file) => beforeUpts(file, 11, index).then(() => false).catch(() => true)}
                                        >
                                            <div>
                                                <PlusOutlined />
                                                <div className="ant-upload-text">upload</div>
                                            </div>
                                        </Upload>
                                        <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                            <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                        </Modal>
                                    </Form.Item>
                                    </b>
                                </div>
                            </>
                            : null
                        }
                    </Space>
                </Row>
                <Divider />
            </>
        )
    }

    const renderLandBuildings = ({ item, index }) => { //render Land Array
        return (
            <>
                <Row justify={'left'}><b><u>รูปที่ดิน/สิ่งปลูกสร้าง-ที่ดินปัจจุบัน ({item.numberLand})</u></b></Row>
                <Row gutter={32} justify={'center'}>
                    <Space>
                        <Row justify={'center'}>
                            {item?.img?.map((e, index) => {
                                if (e.type === 1) {
                                    return (
                                        <div style={{ margin: 3 }}>
                                            <Image width={"100px"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                        </div>
                                    );
                                }
                                return null;
                            })}
                        </Row>
                        {dataFromTable?.approvalStatus === "ขอเอกสารเพิ่ม" ?
                            <>
                                <div className='gutter-row' span={12}>
                                    <b> <Form.Item label='เพิ่มรูป' name='imageIDGuCar'
                                    >
                                        <Upload
                                            name='imageIDGuCar'
                                            multiple={true}
                                            listType="picture-card"
                                            // fileList={fileListLand}
                                            onPreview={handlePreview}
                                            // onChange={handleLand}
                                            onChange={(value) => handleLand(value, index)}
                                            // beforeUpload={() => false}
                                            beforeUpload={(file) => beforeUpts(file, 1, index).then(() => false).catch(() => true)}
                                        >
                                            <div>
                                                <PlusOutlined />
                                                <div className="ant-upload-text">upload</div>
                                            </div>
                                        </Upload>
                                        <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                            <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                        </Modal>
                                    </Form.Item>
                                    </b>
                                </div>
                            </>
                            : null
                        }
                    </Space>
                </Row>
                <Divider />
            </>
        )
    }


    return (
        <Modal open={open} onOk={handleOk} onCancel={handleCancel} width={'1200px'}
            footer={[
                <Row justify={'center'}>
                    <Space>
                        <Button key="back" type='primary' danger onClick={handleCancel}>
                            ปิด
                        </Button>
                        {
                            dataFromTable?.approvalStatus === "ขอเอกสารเพิ่ม" ?
                                <>
                                    <Button key="back" type='primary' style={{ backgroundColor: "#F7A419" }} onClick={handleAddLand}>
                                        ผูกโฉนด
                                    </Button>
                                    {
                                        dataForMix.findLandPN.length > 1 ?
                                            <>
                                                {
                                                    editMode ?
                                                        <Button type='primary' style={{ backgroundColor: "orange" }} onClick={handleEditMode} >ยกเลิกแก้ไข</Button>
                                                        :
                                                        <Button type='primary' style={{ backgroundColor: "orange" }} onClick={handleEditMode} >แก้ไข</Button>
                                                }
                                            </>
                                            : null
                                    }
                                    {
                                        checkImage?.length >= 1
                                            ?
                                            <>
                                                <Button type='primary' onClick={handleSave}>
                                                    บันทึกข้อมูล
                                                </Button>
                                            </> :
                                            <>
                                                <Button type='primary' disabled onClick={handleSave}
                                                    style={{ color: 'success' }}
                                                >
                                                    บันทึกข้อมูล
                                                </Button>
                                                {number === 1 ? <>
                                                    <Button type='primary' icon={<SendOutlined />} onClick={showConfirm}>
                                                        ส่งเคสอีกครั้ง
                                                    </Button>
                                                </> : null}
                                            </>
                                    }

                                    {number === 1 ? <>
                                        <Button type='primary' icon={<SendOutlined />} onClick={showConfirm}>
                                            ส่งเคสอีกครั้ง
                                        </Button>
                                    </> : null}
                                </>
                                : null
                        }
                    </Space>

                </Row>
            ]} >

            <Row justify={'center'}>
                <Col className='gutter-row' span={24} style={{ fontSize: '25px', textAlign: 'center' }}>
                    <b>รายละเอียด</b>
                </Col>
            </Row>
            <Row justify={'center'}>
                <Divider style={{ margin: 5 }} />
                <Form
                    labelCol={{
                        span: 12,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    style={{
                        width: '90%',
                    }}
                    autoComplete="off"
                    initialValues={{ remember: true }}
                    form={form}
                >
                    <div className='main'>
                        <Card style={{ width: '100%' }}>
                            <Spin spinning={loading} size='large' tip=" Loading... ">
                                <Row justify={'center'}>
                                    <Col span={24} style={{ textAlign: 'right' }}>
                                        <div style={{ margin: 0 }}>
                                            ชื่อการตลาดที่เสนอ : <Tag color="geekblue">{dataLand?.proposalBy}</Tag>
                                        </div>
                                        <div style={{ margin: 0 }}>
                                        </div>
                                    </Col>
                                    <Col span={24} style={{ textAlign: 'right' }}>
                                        <div style={{ margin: 0 }}>
                                            สถานะ : <Tag color={color}>{dataLand?.approvalStatus}</Tag>
                                        </div>
                                    </Col>
                                </Row>
                                <Divider />
                                <Col gutter={32} justify={'center'}>
                                    {
                                        dataForMix.findLandPN?.map((item, index) => {
                                            return renderLandData({ item, index })
                                        })
                                    }
                                </Col>
                                <Divider />
                                {
                                    dataLand?.parcellat ?
                                        <>
                                            <Row justify={'left'}><b><u>พิกัด</u></b></Row>
                                            {
                                                dataForMix.findLandPN?.map((item, index) => {
                                                    return renderCoordinates({ item, index })
                                                })
                                            }
                                        </>
                                        : null
                                }
                                <Divider />
                                <Row justify={'left'}><b><u>ราคาประเมินที่ดิน</u></b></Row>
                                <Row gutter={32} justify={'center'}>
                                    {
                                        dataForMix.findLandPN?.map((item, index) => {
                                            return renderApproval({ item, index })
                                        })
                                    }
                                </Row>
                                <Divider />
                                <Row justify={'left'}><Col span={24}><b><u>หมายเหตุ</u></b></Col></Row>
                                <Row gutter={32} justify={'center'}>
                                    {des?.length >= 1 ?
                                        des?.map((item, index) => {
                                            if (item.note_by !== null) {
                                                return renderDataNote({ item, index });
                                            } else {
                                                return <>* ยังไม่เพิ่มหมายเหตุ!</>
                                            }
                                        })
                                        : <>* ยังไม่เพิ่มหมายเหตุ!</>
                                    }
                                </Row>
                                <Divider />
                                {
                                    dataForMix.findLandPN?.map((item, index) => {
                                        return renderLandBuildings({ item, index })
                                    })
                                }
                                {
                                    dataForMix.findLandPN?.map((item, index) => {
                                        return renderTitleDeed({ item, index })
                                    })
                                }
                                {
                                    dataForMix.findLandPN?.map((item, index) => {
                                        return renderDivision({ item, index })
                                    })
                                }
                                {
                                    dataForMix.findLandPN?.map((item, index) => {
                                        return renderLawang({ item, index })
                                    })
                                }
                            </Spin>
                        </Card>
                    </div>
                </Form>
            </Row>
            {contextHolder}
            {/* isModalAddLand */}
            {/* {
                isModalAddLand ?
                    <ModalAddLand open={isModalAddLand} close={setIsModalAddLand} />
                    : null
            } */}
            {
                isModalMainMix ?
                    <ModalMainMix
                        open={isModalMainMix}
                        close={setIsModalMainMix}
                        funcAdd={funcAdd}

                        dataEdit={dataEdit}
                        dataEditIndex={dataEditIndex}
                        checkEdit={checkEdit}
                        setCheckEdit={setCheckEdit}
                        funcEdit={funcEdit}
                        dataForCheckDup={dataLand}
                        setDataEdit={setDataEdit}
                        setDataEditIndex={setDataEditIndex}
                        newDataLand={dataForMix.findLandPN}
                    />
                    : null
            }
        </Modal>
    )
}
