import { createSlice } from "@reduxjs/toolkit";

const initialState = {

}

const landDataSlice = createSlice ({
    name: "land",
    initialState,
    reducers: {
        addLand: (state, action) => {
            state.data = action.payload
        }
    }
    
})



export const { addLand } = landDataSlice.actions
export default landDataSlice.reducer
