import { Card, Divider, Form, Row, Select, Spin, notification, Input, Button, Space, Modal, Upload, message, Image } from 'antd'
import React, { useEffect, useState } from 'react'
import Compressor from 'compressorjs';
import { PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import axios from 'axios';
import { getImagess, mutips2 } from '../../../file_mid/all_api';

function UploadImgResend({ close, page, changepage, data, dataRe, sendback }) {

    const [api, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(false);
    const [showImg, setShowImg] = useState([]);
    const [allImg, setAllImg] = useState([]);
    const [fileListCustomerCar, setFileListCustomerCar] = useState([]);
    const [previewImage, setPreviewImage] = useState('');
    const [previewVisible, setPreviewVisible] = useState(false); //รูป
    const newFormData = new FormData();
    const token = localStorage.getItem('token');

    // console.log("data", data)
    // console.log("dataCont", dataCont)
    // console.log("dataRe", dataRe)

    useEffect(() => {
        if (dataRe?.cars?.img?.length > 0) {
            setShowImg(dataRe?.cars?.img)
        }
    }, [])


    const handleSubmit = async () => {
        if (allImg?.length > 0) {
            await imgSend(allImg)
        } else {
            handleGo()
        }
    }

    const imgSend = (resultImgs) => {
        setLoading(true)
        return new Promise(async (resolve, reject) => {
            try {
                var status;
                newFormData.append('idcus', data?.carId);
                newFormData.append('tm', dayjs().format("YYYY-MM-DDTHH:mm:ssZ"));

                for (const file of resultImgs) {
                    var image = {}
                    newFormData.append('file', file.imageCompressedFile);
                    newFormData.append("type", file.type);
                    image.type = file.type
                }
                // console.log("newFormData", newFormData)
                const response = await axios.post(mutips2, newFormData);
                if (response.data) {
                    setAllImg([])
                    console.log("TY", response.data);
                    status = response.status;
                    resolve(status);
                }
            } catch (error) {
                console.log("error", error)
                setLoading(false);
            } finally {
                setLoading(false);
                console.log("OK")
                sendback(dayjs())
                handleGo()
            }
        });
    };


    const handleClose = () => {
        close()
    }

    const handleGo = () => {
        changepage(page + 1)
    }

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
    };

    const compressImage = async (ez, types) => {

        // console.log("ez, types", ez, types)
        var image = {};
        return new Promise(async (resolve) => {
            if (types !== 7) {
                if (ez.size > 4000000) {
                    console.log("4m ไม่ให้ส่ง");
                    resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
                } else {
                    let quality;
                    if (ez.size >= 2500000 && ez.size <= 4000000) {
                        quality = 0.1;
                    } else if (ez.size >= 2000000 && ez.size <= 2499999) {
                        quality = 0.15;
                    } else if (ez.size >= 1500000 && ez.size <= 1999999) {
                        quality = 0.2;
                    } else if (ez.size >= 1000000 && ez.size <= 1499999) {
                        quality = 0.3;
                    } else {
                        quality = 0.5;
                    }

                    try {
                        // ใช้ Promise เพื่อบีบอัดรูปภาพ
                        const compressedFile = await new Promise((resolveCompress) => {
                            new Compressor(ez, {
                                quality: quality,
                                success: (result) => {
                                    resolveCompress(result);
                                },
                            });
                        });

                        const fileOptions = {
                            type: compressedFile.type,
                            lastModified: Date.now(),
                        };
                        const compressedFileAsFile = new File([compressedFile], ez.name, fileOptions);
                        image.imageCompressedFile = compressedFileAsFile;
                        image.type = types

                        // แบบ base64
                        var filename = ez.name
                        // var image64 = await getBase64(compressedFile)
                        // var image64 = file.originFileObj
                        image.filename = filename
                        // image.image64 = image64
                        // image.type = types

                        console.log("image", image)

                        allImg.push(image);
                        // setAllImg(prevAllImg => [...prevAllImg, image]);
                        // setAllImg((prevAllImg) => [...prevAllImg, image]);

                        // ตรงนี้คือการ resolve ไฟล์ที่ถูกบีบอัด
                        resolve(image);
                    } catch (error) {
                        console.error('Image compression error:', error);
                        resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
                    }
                }
            } else {
                // ... (โลจิกสำหรับ type 7)
                image.imageCompressedFile = ez;
                image.type = types
                allImg.push(image);
                //setAllImg((prevAllImg) => [...prevAllImg, image]);
                resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
            }
        });
    };

    const beforeUpts = async (file, ty) => {
        try {
            setLoading(true)
            const compressedFile = await compressImage(file, ty);
            return Promise.resolve(compressedFile);
        } catch (error) {
            console.error('Image compression error:', error);
            message.error('Image compression failed.');
            return Promise.reject(); // เปลี่ยนให้ return Promise.reject() เมื่อไม่สามารถบีบอัดได้
        }
    };

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };


    const handleChangeCustomerCar = async ({ fileList }) => { // 1

        setLoading(true)
        for (const removedFile of fileListCustomerCar.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.filename === removedFile.name && img.type === 14)) {
                const updatedAllImg = allImg.filter(img => !(img.filename === removedFile.name && img.type === 14));
                setAllImg(updatedAllImg);
            }
        }

        setFileListCustomerCar(fileList);
        setLoading(false)
    };

    // //////////// โชว์รูปภาพ ////////////////
    // function createImageUrl(imageData) {
    //     return imageData.image64;
    // }

    // const handleDeleteImg = (indexToRemove, type) => {
    //     setShowImg(showImg.filter((_, index) => index !== indexToRemove && type === type));
    // };

    const handleCancel = () => {
        setPreviewVisible(false);
    }

    // console.log("allImg", allImg)

    return (
        <Card style={{ width: "100%" }}>
            <Divider orientation={'center'} style={{ fontSize: '25px' }}><b><u>เพิ่มรูป</u></b></Divider>
            <Spin spinning={loading} size='large' tip=" Loading... ">
                <Form
                    name="basic"
                    labelCol={{
                        span: 4,
                    }}
                    // wrapperCol={{
                    //     span: 24,
                    // }}
                    initialValues={{
                        remember: true,
                    }}
                    // form={form}
                    onFinish={handleSubmit}
                    autoComplete="off"
                >
                    {/* <Row justify={"center"}> */}
                    {/* <aside style={{ width: '90%' }}> */}
                    {/* <Row justify={'center'}>
                            <b style={{ fontSize: '18px', textAlign: 'center', marginBottom: '15px' }}><u>รูปประกอบการขออนุมัติ (อัพโหลดไฟล์ละไม่เกิน 3MB)</u></b>
                        </Row> */}
                    {/* <Row justify={'center'}> */}
                    <b>
                        {/* {showImg?.map((imageData, index) => {
                            if (imageData.type === 14) {
                                return (
                                    <Row justify={'center'}>
                                        <img width={'150px'} key={index} style={{ margin: 5 }} src={createImageUrl(imageData)} alt={imageData.filename} />
                                        <Button onClick={() => handleDeleteImg(index, imageData.type)}>ลบ</Button>
                                    </Row>
                                );
                            }
                            return null;
                        })} */}

                        {showImg?.map((imageData, index) => {
                            if (imageData.type === 14) {
                                return (
                                    // <Row justify={'center'}>
                                    <Image width={'100px'} key={index} src={`${getImagess}/${imageData.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                    // </Row>
                                );
                            }
                            return null;
                        })}
                        <Divider />
                        <Row justify={'center'}>
                            <Form.Item
                            // style={{ textAlign: 'center' }}
                            >
                                <Upload
                                    accept="image/*"
                                    multiple={true}
                                    // style={{ textAlign: 'center' }}
                                    listType="picture-card"
                                    fileList={fileListCustomerCar}
                                    onPreview={handlePreview}
                                    onChange={handleChangeCustomerCar}
                                    beforeUpload={(file) => beforeUpts(file, 14).then(() => false).catch(() => true)}
                                >
                                    {/* {fileListCustomerCar?.length >= 20 ? null : ( */}
                                    <div>
                                        <PlusOutlined />
                                        <div className="ant-upload-text">upload</div>
                                    </div>
                                    {/* )} */}
                                </Upload>
                                <Modal open={previewVisible} onCancel={handleCancel}>
                                    <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                </Modal>
                            </Form.Item>
                        </Row>
                    </b>
                    {/* </Row> */}
                    {/* </aside> */}
                    <Divider style={{ margin: 5 }} />
                    <Row justify={'center'} >
                        <Space>
                            <Button type="primary" style={{ background: "red" }} onClick={handleClose}>
                                ยกเลิก
                            </Button>
                            <Button type="primary" htmlType="submit" style={{ background: "green" }} >
                                บันทึก
                            </Button>
                        </Space>
                    </Row>
                    {/* </Row> */}
                </Form>
            </Spin>
            {contextHolder}
        </Card >
    )
}

export default UploadImgResend