import React, { useState, useEffect } from "react";
import { PlusCircleFilled, HomeFilled, EditOutlined, SearchOutlined, CreditCardOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Table, Spin, Tag, Space, Card, Radio, Divider, Modal, Statistic, notification, Typography, Pagination } from 'antd';

import axios from "axios";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import ModalEditer from "./modal/ModalEditer";
import ModalInfoLand from "./modal/ModalInfoLand";
import SendModalEditer from "./send_case/SendModalEditer";

import { useDispatch } from "react-redux";
import '../css/Media.css'
import { addCustomer } from '../../redux/Customer';
import { addCar } from '../../redux/Car';
import { addAddress } from '../../redux/Address';
import { addPhone } from '../../redux/Phone';
import { addLoan } from '../../redux/Loan';
import { addOldLoan } from '../../redux/OldLoan';
import { addImage } from '../../redux/Image';
import { addLand } from '../../redux/Land';
import { addLoanLand } from '../../redux/LoanLand';
import { addOldLoanLand } from '../../redux/OldLoanLand';
import { addGuarantor } from '../../redux/Guarantor';
import { addCareerIncome } from '../../redux/CareerIncome';
import { addOccupation } from '../../redux/Occupation';
import { addSocial } from '../../redux/Social';
import { addEmail } from '../../redux/Email';

import { colorApprovalStatus, colorProductLoanType, backc, textc } from "../file_mid/status_color";
import { orderLand } from "../file_mid/all_options";
import { getallpreland, findprelandinfo, newfindprelandinfo, searchfindallpre } from "../file_mid/all_api";
import ModalInfoMK from "../file_mid/info/ModalInfoMK";
const { Countdown } = Statistic;

export default function MainAppLand() {
    const { Text } = Typography;
    const [api, contextHolder] = notification.useNotification();
    const token = localStorage.getItem('token')
    const menu = localStorage.getItem('menu')
    dayjs.extend(utc);
    const dispatch = useDispatch()
    // const [newData, setNewData] = useState();
    const [editData, setEditData] = useState();
    const [isModalEditer, setIsModalEditer] = useState(false);
    const [isModalInfoLand, setIsModalInfoLand] = useState(false);
    const [sendModalEditer, setSendModalEditer] = useState(false);
    const [axiosData, setAxiosData] = useState([]);
    const [arrayTable, setArrayTable] = useState();
    const [cData, setCData] = useState([]);
    const [keyWord, setKeyWord] = useState("ทั้งหมด");
    // const { confirm } = Modal;
    const [loading, setLoading] = useState(false)
    const [swap, setSwap] = useState(false)
    const [maxPage, setMaxPage] = useState();
    const [ssPage, setSsPage] = useState({ numberPage: 1, pageSize: 10 });
    const [query, setQuery] = useState("");
    const [isModalInfoMK, setIsModalInfoMK] = useState(false);

    useEffect(() => {
        localStorage.removeItem('addCustomer');
        localStorage.removeItem('addAddress');
        localStorage.removeItem('addPhone');
        localStorage.removeItem('addCar');
        localStorage.removeItem('addGuarantor');
        localStorage.removeItem('addCareerIncome');
        localStorage.removeItem('addLoan');
        localStorage.removeItem('addOldLoan');
        localStorage.removeItem('addLand');
        localStorage.removeItem('addLoanLand');
        localStorage.removeItem('addOldLoanLand');
        localStorage.removeItem('addOccupation');
        localStorage.removeItem('addEmail');
        localStorage.removeItem('addSocial');
        localStorage.removeItem('addImage');
        localStorage.removeItem('addBroker');
        dispatch(addCustomer())
        dispatch(addAddress())
        dispatch(addGuarantor())
        dispatch(addCareerIncome())
        dispatch(addPhone())
        dispatch(addCar())
        dispatch(addLoan())
        dispatch(addOldLoan())
        dispatch(addImage())
        dispatch(addLand())
        dispatch(addLoanLand())
        dispatch(addOldLoanLand())
        dispatch(addOccupation())
        dispatch(addSocial())
        dispatch(addEmail())
    }, [])

    useEffect(() => {
        loadData()
        // loadCountData()
    }, [keyWord, isModalEditer, isModalInfoLand, ssPage, isModalInfoMK])

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            console.log(query)
            searchLoadData(query)
        }, 1500)

        return () => clearTimeout(delayDebounceFn)
    }, [query])

    const loadCountData = async (data) => {
        // const tk = JSON.parse(token)
        // const headers = {
        //     "Authorization": `Bearer ${tk}`,
        //     "Menu": JSON.stringify("3")
        // }
        // setLoading(true)
        // await axios.get(getallpreland, { headers: headers })
        //     .then((res) => {
        // console.log("easy =", res.data)
        const initialValue = 0;
        const sumWithInitial = data.reduce(
            (accumulator, currentValue) => accumulator + currentValue.A,
            initialValue
        );
        data.push({ ApprovalStatus: 'ทั้งหมด', A: sumWithInitial })
        if (data) {
            const filteredData = data.find(item => item.ApprovalStatus === keyWord);
            setMaxPage(filteredData);
        }
        setCData(data)
        //         setLoading(false)
        //     }).catch((err) => console.log(err))
        // setLoading(false)
    }

    const loadData = async () => {
        console.log("loadData")
        const tk = JSON.parse(token)
        const headers = {
            "Authorization": `Bearer ${tk}`,
            "Menu": JSON.stringify("3")
        }
        setLoading(true)
        // await axios.get(`${findprelandinfo}/${keyWord}`, { headers: headers })
        await axios.get(`${newfindprelandinfo}/${keyWord}/${ssPage.numberPage}/${ssPage.pageSize}`, { headers: headers })
            // await axios.get(`http://localhost:8080/mid/newfindprelandinfo/${keyWord}/${ssPage.numberPage}/${ssPage.pageSize}`, { headers: headers })
            .then((res) => {
                console.log("b", res.data)
                setArrayTable(res.data.GetInfoLandPre)
                setAxiosData(res.data.GetInfoLandPre)
                loadCountData(res.data.Countsttatus)
                setSwap(false)
                setQuery("")
                setLoading(false)
            }).catch((err) => console.log(err))
        setLoading(false)
    }

    const searchLoadData = async (data) => {
        if (data !== "") {
            console.log("searchLoadData data", data)
            setLoading(true)
            const tk = JSON.parse(token)
            const headers = {
                "Authorization": `Bearer ${tk}`,
                "Menu": JSON.stringify("3")
            }
            // await axios.get(`${searchFindAllPre}/${keyWord}`, { headers: headers })
            await axios.get(`${searchfindallpre}/${keyWord}/${data}`, { headers: headers })
                // await axios.get(`http://localhost:8080/mid/searchfindallpre/${keyWord}/${data}`, { headers: headers })
                .then((res) => {
                    console.log("searchLoadData", res.data)
                    setArrayTable(res.data)
                    setAxiosData(res.data)
                    setSwap(true)
                    setLoading(false)
                }).catch((err) => {
                    console.log(err)
                    setLoading(false)
                })
        }
    }

    const search = async (data) => {
        const validInput = data.replace(/[^0-9]/g, '');
        console.log("data", validInput)
        if (validInput !== "") {
            setQuery(validInput)
        } else {
            setKeyWord("ทั้งหมด")
            setQuery("")
            setTimeout(async () => {
                await loadData()
            }, 1500)
        }
    };

    const onChangePagination = (e, pageSize) => {
        console.log(e, pageSize)
        setSsPage({ numberPage: e, pageSize: pageSize })
    }

    const onChangeAP = (value) => {
        console.log(value)
        setKeyWord(value)
        setSsPage({ numberPage: 1, pageSize: 10 })
    }

    const SuccSend = (placement) => {
        api.success({
            message: <b>ส่งเคสใหม่สำเร็จ</b>,
            placement,
        });
    }

    const columns = [
        {
            title: "ลำดับ",
            dataIndex: "index",
            key: 'index',
            align: 'center',
            // fixed: true,
            width: 80,
            render: (text, object, index) => index + 1
        },
        {
            key: 'numberLand',
            title: "รายละเอียด",
            dataIndex: "numberLand",
            width: 350,
            align: 'center',
            render: (text, record) => {
                const renderArea = () => {
                    return ((parseFloat(record.rai) * 400)) + ((parseFloat(record.workArea) * 100)) + parseFloat(record.squareWaArea)
                }
                return (
                    <>
                        <div>{record.numberLand} {record.numberLandLawang} </div>
                        <div>{record.province} {record.district} </div>
                        <div>{renderArea()} {record.squareWaArea && "ตารางวา"} {currencyFormatOne(record.landPrice)} {"บาทต่อตารางวา"}</div>
                    </>
                );
            }
        },
        {
            title: "วันที่เสนอ",
            dataIndex: "landInput",
            width: 120,
            align: 'center',
            sorter: {
                compare: (a, b) => dayjs(a.landInput) - dayjs(b.landInput),
                multiple: 1,
            },
            render: (text, record) => (
                <Space size="middle">
                    {
                        record.landInput !== "0001-01-01T00:00:00Z" ?
                            <Space>
                                <>{dayjs(record.landInput).format("DD-MM-YYYY HH:mm")}</>
                            </Space>
                            : null
                    }
                </Space>
            ),
            defaultSortOrder: 'descend',
        },
        {
            title: "วันที่รับ",
            dataIndex: "approvalDate",
            width: 180,
            align: 'center',
            sorter: {
                compare: (a, b) => dayjs(a.approvalDate) - dayjs(b.approvalDate),
                multiple: 2,
            },
            render: (text, record) => (
                <Space size="middle">
                    {
                        record.approvalDate !== "0001-01-01T00:00:00Z" && record.approvalDate !== null ?
                            <Space>
                                <>{dayjs(record.approvalDate).format("DD-MM-YYYY HH:mm")}</>
                            </Space>
                            : <Text type="secondary">ยังไม่รับงาน</Text>
                    }
                </Space>
            ),
        },
        {
            title: "ระยะเวลาที่คาดว่าจะเสร็จ",
            dataIndex: "",
            width: 120,
            align: 'center',
            render: (text, record) => (
                <Space size="middle">
                    {
                        record.approvalDate !== "0001-01-01T00:00:00Z" && record.approvalDate !== null ?
                            <Space>
                                <Countdown value={dayjs(record.approvalDate).add(3, 'hour')} />
                            </Space>
                            : <Text type="secondary">ยังไม่รับงาน</Text>
                    }
                </Space>
            ),
        },
        {
            title: "สถานะ",
            dataIndex: "approvalStatus",
            align: 'center',
            width: 280,
            render: (text, record) => {
                let color
                let color2
                color = colorApprovalStatus(record?.productLoanLandType, record?.approvalStatus)
                color2 = colorProductLoanType(record?.productLoanLandType)
                return (
                    <>
                        <Tag color={color}>
                            {record.approvalStatus.toUpperCase()}
                        </Tag>
                        <Space>
                            <Tag color={color2}>
                                {record.productLoanLandType}
                            </Tag>
                        </Space>
                    </>
                );
            },
        },
        {
            title: "Action",
            key: 'Action',
            align: 'center',
            width: 280,
            render: (_, record) => (
                <Space size="middle">
                    {
                        record.approvalStatus === "ระงับเคส" ?
                            <Tag color="geekblue">กรุณาติดต่อเร่งรัดภาคสนาม</Tag>
                            : record.approvalStatus === "รอพี่หนุ่มรับ" && !record.priceStatus ?
                                <Space>
                                    <Button onClick={() => {
                                        setIsModalEditer(true)
                                        dispatch(addCustomer({
                                            CreatedAt: record.CreatedAt,
                                            IsAccepted: record.IsAccepted,
                                            LandDateStatus: record.LandDateStatus,
                                            UpdatedAt: record.LandDateStatus,
                                            approvalDate: record.approvalDate,
                                            approvalStatus: record.approvalStatus,
                                            approvedBy: record.approvedBy,
                                            approvedLoanAmount: record.approvedLoanAmount,
                                            birthdate: record.birthdate,
                                            customerApprovedLoan: record.customerApprovedLoan,
                                            customerId: record.customerId,
                                            district: record.district,
                                            expdt: record.expdt,
                                            firstname: record.firstname,
                                            gcode: record.gcode,
                                            gender: record.gender,
                                            identificationId: record.identificationId,
                                            issuby: record.issuby,
                                            issudt: record.issudt,
                                            landContno: record.landContno,
                                            landId: record.landId,
                                            landInput: record.landInput,
                                            landLoanId: record.landLoanId,
                                            landStatus: record.landStatus,
                                            landTypeId: record.landTypeId,
                                            lastname: record.lastname,
                                            nationalId: record.nationalId,
                                            nickname: record.nickname,
                                            productLoanLandType: record.productLoanLandType,
                                            productType: record.productType,
                                            productTypeLand: record.productTypeLand,
                                            proposalBy: record.proposalBy,
                                            province: record.province,
                                            reviewedBy: record.reviewedBy,
                                            snam: record.snam,
                                            subdistrict: record.subdistrict,
                                            numberLand: record.numberLand,
                                            numberLandLawang: record.numberLandLawang,
                                            landMemo: record.landMemo,
                                            storyLand: record.storyLand,
                                            parcellat: record.parcellat,
                                            parcellon: record.parcellon,
                                            rai: parseInt(record?.rai),
                                            workArea: parseInt(record?.workArea),
                                            squareWaArea: parseFloat(record?.squareWaArea),
                                            landPrice: parseInt(record?.landPrice),
                                            resultLandPrice: parseInt(record?.resultLandPrice),
                                            loanAmounttLand: parseFloat(record?.loanAmounttLand),
                                            loanLandTerm: parseFloat(record?.loanLandTerm),
                                            interestRateLand: parseFloat(record?.interestRateLand),
                                            interesttLand: parseFloat(record?.interesttLand),
                                            monthlyPaymentLand: parseInt(record?.monthlyPaymentLand),
                                            activeStatus: record?.activeStatus,
                                            oldLandLoanId: record.oldLandLoanId,
                                            oldLandDownPayment: record.oldLandDownPayment,
                                            oldLandInterestRate: record.oldLandInterestRate,
                                            oldLandLoanAmount: parseInt(record?.oldLandLoanAmount),
                                            oldLandMonthlyPayment: parseInt(record?.oldLandMonthlyPayment),
                                            oldLandLoanTerm: parseInt(record?.oldLandLoanTerm),
                                            oldLandKangPayment: parseInt(record?.oldLandKangPayment),
                                            oldLandKangPaymentTerm: parseInt(record?.oldLandKangPaymentTerm),
                                        }))
                                    }}><EditOutlined /></Button>
                                    <Button onClick={() => {
                                        setIsModalInfoMK(true)
                                        setEditData(record)
                                    }}><SearchOutlined /></Button>
                                </Space>
                                : record.approvalStatus === "เคาะราคาแล้ว" ?
                                    <Space>
                                        <Button
                                            onClick={() => {
                                                //console.log("record",record)
                                                setEditData(record)
                                                setSendModalEditer(true);
                                                dispatch(addCustomer(record))
                                                dispatch(addSocial(record))
                                            }}
                                        ><CreditCardOutlined /></Button>
                                        <Button onClick={() => {
                                            setIsModalInfoMK(true)
                                            setEditData(record)
                                        }}><SearchOutlined /></Button>
                                    </Space>
                                    :
                                    <>
                                        <Button onClick={() => {
                                            setIsModalInfoMK(true)
                                            setEditData(record)
                                        }}><SearchOutlined /></Button>
                                    </>

                    }
                </Space>
            ),
        },
    ];

    const currencyFormatOne = (amount) => {
        return Number(amount)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }

    return (
        <>
            <Card>
                <Row gutter={32} style={{ textAlign: 'center' }}>
                    <Col>
                        <Spin spinning={loading} size='large' tip=" Loading... ">
                            <div className='text-center'>
                                <h2>ตรวจสอบข้อมูลขอยอดที่ดิน(การตลาด)</h2>
                            </div>
                            <Row gutter={32} justify={'center'}>
                                <Radio.Group value={keyWord} onChange={(e) => onChangeAP(e.target.value)} >
                                    {/* <Radio.Group value={keyWord} onChange={(e) => setKeyWord(e.target.value)} > */}
                                    {
                                        cData.sort((a, b) => {
                                            const order = orderLand
                                            return order.indexOf(a.ApprovalStatus) - order.indexOf(b.ApprovalStatus);
                                        })
                                            .map((item, index) => (
                                                <Radio.Button
                                                    key={index}
                                                    style={{
                                                        width: '150px',
                                                        border: '2px solid',
                                                        backgroundColor: backc[item.ApprovalStatus],
                                                        color: textc[item.ApprovalStatus]
                                                    }}
                                                    value={item.ApprovalStatus}>{item.ApprovalStatus} <b>{item.A}</b></Radio.Button>
                                            ))}

                                </Radio.Group>

                            </Row>
                            <Row gutter={32} style={{ margin: 10 }}>
                                <Col className='gutter-row' span={24} style={{ textAlign: 'right' }}>
                                    <Input.Search
                                        value={query}
                                        style={{ width: '300px' }}
                                        placeholder="ค้นหาจากเลขโฉนด/เลขที่ดิน...."
                                        onChange={(e) => search(e.target.value)}
                                        // onSearch={search}
                                        name="search"
                                        id="search"
                                        size="large"
                                        loading={loading}
                                    />
                                </Col>
                            </Row>
                            <Divider />
                            <Row gutter={32}>
                                <Col span={24}>

                                    <Table
                                        pagination={swap}
                                        rowKey={(record) => record.uid}
                                        scroll={{
                                            x: 1700,
                                            y: 400,
                                        }}
                                        dataSource={arrayTable}
                                        columns={columns}
                                    >
                                    </Table>
                                    {
                                        !swap ?
                                            <>
                                                <Divider />
                                                <Pagination onChange={onChangePagination} onShowSizeChange={onChangePagination} defaultPageSize={10} defaultCurrent={1} total={maxPage?.A} />
                                                <Divider />
                                            </>
                                            : null
                                    }
                                </Col>
                            </Row>

                            <Col span={24} style={{ textAlign: 'center' }}>
                                <Space>
                                    <Button href="/offercase" type="primary" ><PlusCircleFilled />เพิ่ม</Button>
                                    <Button href="/" type="primary"><HomeFilled />หน้าหลัก</Button>
                                </Space>
                            </Col>
                        </Spin>
                    </Col>
                </Row>
            </Card>
            {
                isModalEditer ?
                    <ModalEditer open={isModalEditer} close={setIsModalEditer} />
                    : null
            }
            {
                isModalInfoLand ?
                    <ModalInfoLand open={isModalInfoLand} close={setIsModalInfoLand} dataFromTable={editData} SuccSend={SuccSend} />
                    : null
            }
            {
                isModalInfoMK ?
                    <ModalInfoMK open={isModalInfoMK} close={setIsModalInfoMK} dataFromTable={editData} typeLoan={"landappland"} />
                    : null
            }
            {
                sendModalEditer ?
                    <SendModalEditer open={sendModalEditer} close={setSendModalEditer} dataFromTable={editData} />
                    : null
            }
            {contextHolder}
        </>
    )
}
