import React, { useEffect, useState } from 'react'
import { Modal, Col, Row, Space, notification, Steps, Spin } from 'antd';
import axios from 'axios';
// import dayjs from 'dayjs';

import { useDispatch, useSelector } from 'react-redux';
import { addCustomer } from '../../../../../redux/Customer';
import { addPhone } from '../../../../../redux/Phone';
import { addAddress } from '../../../../../redux/Address';
import { addCar } from '../../../../../redux/Car';
import { addImage } from '../../../../../redux/Image';
import { addOccupation } from '../../../../../redux/Occupation';

import GECustomer from './GECustomer';
import GEAddress from './GEAddress';
import GEPhone from './GEPhone';
import GEDetail from './GEDetail';
import GECareer from './GECareer';
import { loadOJS } from '../../../../file_mid/all_api';

export default function ModalEditGuarantor({ open, close, dataindex, shootdata, infor, getimages, setSendback, indexedit }) {
    const customers = useSelector(state => state.customers)
    const cars = useSelector(state => state.cars)
    // const guarantors = useSelector(state => state.guarantor)

    const [loading, setLoading] = useState(false);

    const [theData] = useState(shootdata.dataedit);
    const [index] = useState(shootdata.index1);
    const [saveCusIdMain] = useState(customers.data);
    const [saveCarId] = useState(cars.data);

    // const [previewVisible, setPreviewVisible] = useState(false);
    // const [previewImage, setPreviewImage] = useState('');
    const [showImage, setShowImage] = useState([]);
    // const [foldernum, setFoldernum] = useState();

    const [api, contextHolder] = notification.useNotification();
    const dispatch = useDispatch()
    const [current, setCurrent] = useState(0);
    const { confirm } = Modal

    // console.log("dataindex", dataindex)
    // console.log("showImage", showImage)
    // console.log("theData", theData)
    // console.log("showImage", showImage)

    useEffect(() => {
        loadCareer()
        setData()
        mapImg()
    }, [])

    console.log("GG")

    const mapImg = () => {
        {
            const folder = []
            const split = theData?.pathImg.split('/')[4];
            // setFoldernum(split)
            getimages?.map((item, index) => {
                const segments = item.pathImage.split("/")[4]
                console.log("segments", segments)
                var image = {}

                // const count = `${index + 1}`
                // console.log("split = ", item?.pathImage.split('/')[4], split);
                if (item?.pathImage.split('/')[4] === split) {
                    image.attr = item.attr
                    image.data = item.data
                    image.idImage = item.idImage
                    image.pathImage = item.pathImage
                    folder.push(image)
                }

            })
            console.log("folder", folder)
            setShowImage(folder)
        }
    }

    const loadCareer = async () => {
        const id = { customerId: theData?.customerId }
        setLoading(true)
        await axios.post(loadOJS, id)
            .then((res) => {
                if (res.data) {
                    // console.log("res", res.data)
                    console.log("res.data")
                    // setDataCareer(res.data)
                    dispatch(addOccupation(res.data))
                } else {
                    dispatch(addOccupation())
                }
                setLoading(false)
            })
            .catch((err) => console.log(err))
        setLoading(false)
    }

    const setData = async () => {
        dispatch(addCustomer(theData))
        dispatch(addAddress(theData.address))
        dispatch(addPhone(theData.phones))
    };

    const next = () => {
        setCurrent(current + 1);
    };
    const prev = () => {
        setCurrent(current - 1);
    };


    const InsertSuccess = (placement) => {
        api.success({
            message: <b>เพิ่มข้อมูลสำเร็จ</b>,
            placement,
        });
    }
    const EditSuccess = (placement) => {
        api.success({
            message: <b>แก้ไขข้อมูลสำเร็จ</b>,
            placement,
        });
    }
    const DeleteSuccess = (placement) => {
        api.success({
            message: <b>ลบข้อมูลสำเร็จ</b>,
            placement,
        });
    }

    const CheckAddressER = (placement) => {
        api.error({
            message: <b>เพิ่มข้อมูลไม่สำเร็จ</b>,
            description: 'กรุณาเพิ่มที่อยู่ อย่างน้อย 1 ที่',
            placement,
        });
    }
    const CheckPhoneER = (placement) => {
        api.error({
            message: <b>เพิ่มข้อมูลไม่สำเร็จ</b>,
            description: 'กรุณาเพิ่มเบอร์โทรศัพท์ อย่างน้อย 1 เบอร์',
            placement,
        });
    }
    const CheckCareer = (placement) => {
        api.error({
            message: <b>เพิ่มข้อมูลไม่สำเร็จ</b>,
            description: 'กรุณาเพิ่มอาชีพ - รายได้ อย่างน้อย 1 อาชีพ',
            placement,
        });
    }
    // const ChecImageER = (placement) => {
    //     api.success({
    //         message: <b>เพิ่มรูปสำเร็จ</b>,
    //         // description: 'กรุณาเพิ่มเบอร์โทรศัพท์ อย่างน้อย 1 เบอร์',
    //         placement,
    //     });
    // }
    const CheckDesER = (placement) => {
        api.error({
            message: <b>เพิ่มข้อมูลไม่สำเร็จ</b>,
            description: 'กรุณาเพิ่มความสัมพันธ์กับผู้กู้',
            placement,
        });
    }

    const showConfirm = () => {
        confirm({
            title: 'คุณต้องการที่ปิดหน้าต่างข้อมูลผู้กู้หรือไม่...?',
            content: 'หากกดปิดหน้าต่างผู้กู้ข้อมูลจะไม่ถูกบันทึก กด OK เพื่อยืนยัน',
            onOk() {
                Onclose();
            },
            onCancel() {
            },
        });
    };

    const onChange = (value) => {
        console.log('onChange:', value);
        setCurrent(value);
    };

    const Onclose = () => {
        close(false);
        // console.log("cusIdMain ModalEditGuarantor",cusIdMain)
        dispatch(addCustomer(saveCusIdMain))
        dispatch(addCar(saveCarId))
        dispatch(addImage())
        dispatch(addAddress())
        dispatch(addPhone())
        dispatch(addOccupation())
    };

    return (
        <Modal title="แก้ไขข้อมูล" open={open} onCancel={showConfirm} width={1200} footer={null} >
            <Spin spinning={loading} size='large' tip=" Loading... ">
                <Space direction="vertical" size="middle" style={{ display: 'flex' }}>

                    <Row>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <Steps
                                current={current}
                                onChange={onChange}
                                items={[
                                    {
                                        title: 'ข้อมูลส่วนตัว',
                                    },
                                    {
                                        title: 'รายละเอียดที่อยู่',
                                    },
                                    {
                                        title: 'ช่องทางการติดต่อ',
                                    },
                                    // {
                                    //     title: 'เพิ่มรูป',
                                    // },
                                    {
                                        title: 'อาชีพ - รายได้',
                                    },
                                    {
                                        title: 'สรุปรายละเอียด/บันทึกข้อมูล',
                                    },
                                ]}
                            />
                        </Col>
                    </Row>
                    {current === 0 && (<GECustomer close={showConfirm} theData={theData} infor={infor} index={index} next={next} showImage={showImage} getimages={getimages} setSendback={setSendback} indexedit={indexedit} />)}
                    {current === 1 && (<GEAddress close={showConfirm} theData={theData} infor={infor} index={index} n1={InsertSuccess} e1={EditSuccess} d1={DeleteSuccess} next={next} prev={prev} cha={CheckAddressER} />)}
                    {current === 2 && (<GEPhone close={showConfirm} theData={theData} infor={infor} index={index} n1={InsertSuccess} e1={EditSuccess} d1={DeleteSuccess} next={next} prev={prev} chp={CheckPhoneER} />)}
                    {/* {current === 3 && (<GEAddImages close={showConfirm} getimages={getimages} foldernum={foldernum} theData={theData} infor={infor} index={index} next={next} prev={prev} chp={ChecImageER} />)} */}
                    {current === 3 && (<GECareer close={showConfirm} theData={theData} infor={infor} index={index} n1={InsertSuccess} e1={EditSuccess} d1={DeleteSuccess} next={next} prev={prev} cha={CheckCareer} />)}
                    {current === 4 && (<GEDetail close={showConfirm} dataindex={dataindex} theData={theData} getimages={getimages} shootdata={shootdata} infor={infor} index={index} n1={InsertSuccess} e1={EditSuccess} d1={DeleteSuccess} prev={prev} saveclose={Onclose} cha={CheckAddressER} chp={CheckPhoneER} chd={CheckDesER} occ={CheckCareer} />)}

                    {/* <Row gutter={32} justify={'center'}>
                        <Col span={24} style={{ fontSize: '20px', textAlign: 'center' }}><b><u>เอกสารเพิ่มเติมสำหรับคนค้ำประกัน</u></b></Col>

                    </Row>
                    <Row gutter={32} justify={'center'}>
                        <Row justify={'center'}>
                            <Form.Item
                                label='เพิ่มรูป'
                                name='imgGuarantor'
                                style={{ textAlign: 'center' }}
                            >
                                <Upload
                                    multiple={true}
                                    listType="picture-card"
                                    fileList={fileListGuarantors}
                                    onPreview={handlePreview}
                                    onChange={handleChangeGuarantors1}
                                    // onChange={handleChangeGuarantor}
                                    beforeUpload={() => false}
                                >
                                    {fileListGuarantors?.length >= 10 ? null : (
                                        <div>
                                            <PlusOutlined />
                                            <div className="ant-upload-text">upload</div>
                                        </div>
                                    )}
                                </Upload>
                                <Modal open={previewVisible} onCancel={handleCancel1}>
                                    <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                </Modal>
                            </Form.Item>
                        </Row>
                    </Row>
                    <Row justify={'center'}>
                        <Button onClick={submitImg} style={{ background: 'green', color: 'white' }}>บันทุกรูปภาพใหม่</Button>
                    </Row> */}
                    {/* <Divider style={{ margin: '5' }} />
                    <Row gutter={32} justify={'center'}>
                        <span>รูปภาพคนค้ำ : </span>
                        {showImage?.map((items, index) => {
                            // const segments = items.pathImage.split("/")

                            // if (itemSplit[4] === segments[4]) {
                            //console.log("test",getimages.length)
                            return (
                                // <img width={'300px'} key={index} style={{ margin: 5 }} src={createImageUrl(items.image64)} alt={items.filename} />
                                <Row gutter={32} >
                                    <Col span={24} >
                                        <Image width={'100px'} key={index} src={`data:${items?.Attr};base64,${items?.data?.toString('base64')}`} alt={`Image ${index + 1}`} />
                                        <p><Button onClick={() => showConfirmdel(items, index)} type='primary' danger>ลบรูปภาพ</Button></p>
                                    </Col>
                                </Row>
                            )
                            // }

                        })}
                    </Row> */}
                </Space>
                {contextHolder}
            </Spin>
        </Modal >

    )
};
