import React, { useState, useRef } from "react";
import axios from "axios";
import { Button, Row, Col, Table, Form, DatePicker, Select } from "antd";
import { useReactToPrint } from "react-to-print";
//import InputSelectLocat from "./inputSelectLocat";
import DotaColums from "./dotaColums";
import DotaLocat from "./dotaLocat";
import DataCase from "./dotaCase";
import ExportExcel from "./exportExcel";
import { getlandpn1 } from "../../file_mid/all_api";

const { RangePicker } = DatePicker;
function TabelLoanMarketing() {
  //เรียก API
  //const [axiosData, setData] = useState([]);
  //ประกาศตัวแปรรับ ค่าวันที่ ถึงวันที่
  const [inputDate, setInputDate] = useState([]);
  //ประกาศตัวแปรรับ InputSelect
  const [inputLocat, setInputLocat] = useState([]);
  //ประกาศตัวแปรรับ value InputSelectMarketing
  const [marketingAll, setMarketingAll] = useState([]);
  //ประกาศตัวแปรรับ ส่งค่า InputSelectMarketing
  const [marketing, setMarketing] = useState([]);
  //ประกาศตัวแปรรับ tabel
  const [dataTabel, setDataTabel] = useState([]);

  //F-form-1
  const onFinish = (values) => {
    console.log("Success:", values);
  };
  //F-form-2
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  //เซ็ต ค่าวันที่ ถึงวันที่
  const onChangeDate = (dateSelect2) => {
    // console.log("e1: ", e1);
    //console.log("dateSelect: ", dateSelect2);
    setInputDate(dateSelect2);
  };

  //เซ็ต InputSelect
  const onChangeLocal = async (value) => {
    //console.log(`selected ${value}`);
    setInputLocat(value);
    await axios
      .post(
        getlandpn1,
        {
          days1: inputDate[0],
          days2: inputDate[1],
          approvalStatus: "ทั้งหมด",
        }
      )
      .then((res) => {
        //เซ็ต InputSelectMarketing
        if (value === "ทั้งหมด") {
          //console.log("111",value)
          const proposalBy = [
            ...new Set(res.data.map((item) => item.proposalBy)),
          ].sort();
          //console.log("proposalBy", proposalBy);
          setMarketingAll(proposalBy);
        } else if (value === "KSM") {
          //console.log("222",value)
          const filteredProposalByUD = res.data.filter((item) =>
            item.branch.includes("K10")
          );
          //console.log("222",filteredProposalByUD)
          const proposalBy = [
            ...new Set(filteredProposalByUD.map((item) => item.proposalBy)),
          ].sort();
          //console.log("proposalBy", proposalBy);
          setMarketingAll(proposalBy);
        } else {
          //console.log("333",value)
          const filteredProposalByUD = res.data.filter(
            (item) => item.branch === value
          );
          //console.log("333",filteredProposalByUD)
          const proposalBy = [
            ...new Set(filteredProposalByUD.map((item) => item.proposalBy)),
          ].sort();
          //console.log("proposalBy", proposalBy);
          setMarketingAll(proposalBy);
        }
      })
      .catch((err) => console.log(err.message));
  };

  //เซ็ต ค่าวันที่ ถึงวันที่
  const onChangeMarketing = (value) => {
    //console.log("e1234: ", value);
    setMarketing(value);
  };

  //Button1
  const onClickA = async () => {
    await axios
      .post(
        getlandpn1,
        {
          days1: inputDate[0],
          days2: inputDate[1],
          approvalStatus: "ทั้งหมด",
        }
      )
      .then((res) => {
        //เซ็ต InputSelectMarketing
        const today = new Date();
        const formattedDate = today.toISOString().split("T")[0];
        let filterProposalBy;

        if (inputDate.length === 0) {
          alert("เลือกวันที่ - ถึงวันที่ก่อน");
        } else if (
          inputDate[0] > formattedDate ||
          inputDate[2] > formattedDate
        ) {
          alert(
            "ยังไม่มีข้อมูลวันที่" +
            JSON.stringify(inputDate[0]) +
            "-" +
            JSON.stringify(inputDate[1])
          );
        } else if (inputLocat.length === 0) {
          alert("เลือกสาขาก่อน");
        } else {
          //console.log("1:",inputLocat,"2:",marketing,"3:",inputDate[0],"4:",inputDate[1]);

          // สร้างออบเจ็กต์เพื่อเก็บข้อมูลการอนุมัติตามผู้เสนอข้อเสนอ
          const proposalBy = {};

          // นับจำนวนข้อมูลแยกตามผู้เสนอข้อเสนอและสถานะการอนุมัติ
          res.data.forEach((item) => {
            if (!proposalBy[item.proposalBy]) {
              proposalBy[item.proposalBy] = {
                caseAll: 0,
                caseSuccess: 0,
                caseWait: 0,
                caseFail: 0,
              };
            }
            // เพิ่มจำนวนทั้งหมดของ proposalBy
            proposalBy[item.proposalBy].caseAll++;

            switch (item.approvalStatus) {
              case DataCase[0]:
              case DataCase[1]:
              case DataCase[2]:
              case DataCase[3]:
              case DataCase[4]:
              case DataCase[5]:
              case DataCase[6]:
              case DataCase[7]:
              case DataCase[8]:
              case DataCase[9]:
              case DataCase[10]:
              case DataCase[11]:
              case DataCase[12]:
                proposalBy[item.proposalBy].caseSuccess++;
                break;
              case DataCase[13]:
              case DataCase[14]:
              case DataCase[15]:
                proposalBy[item.proposalBy].caseWait++;
                break;
              case DataCase[16]:
                proposalBy[item.proposalBy].caseFail++;
                break;
              default:
                break;
            }
          });
          // แปลงผลลัพธ์เป็นอาร์เรย์และเรียงลำดับตามชื่อผู้เสนอข้อเสนอ
          // const result = Object.entries(filterProposalBy).map(([key, value]) => ({
          //   userMarketing: key,
          //   ...value,
          // }));
          //setDataTabel(result);
          if (marketing.length !== 0) {
            filterProposalBy = Object.keys(proposalBy)
              .filter((key) => key.includes(marketing))
              .reduce((obj, key) => {
                obj[key] = proposalBy[key];
                return obj;
              }, {});
          } else if (inputLocat === "KSM") {
            console.log("inputLocat", inputLocat);
            filterProposalBy = Object.keys(proposalBy)
              .filter((key) => key.includes("K000"))
              .reduce((obj, key) => {
                obj[key] = proposalBy[key];
                return obj;
              }, {});
          } else if (inputLocat !== "ทั้งหมด") {
            filterProposalBy = Object.keys(proposalBy)
              .filter((key) => key.includes(inputLocat))
              .reduce((obj, key) => {
                obj[key] = proposalBy[key];
                return obj;
              }, {});
          } else {
            filterProposalBy = proposalBy;
          }
          const result = Object.entries(filterProposalBy).map(
            ([key, value]) => ({
              userMarketing: key,
              ...value,
            })
          );
          setDataTabel(result);
        }
      })
      .catch((err) => console.log(err.message));
  };
  //ExportPDF
  const conponentPDF = useRef();
  const generatePDF = useReactToPrint({
    content: () => conponentPDF.current,
    documentTitle: "รายงานสรุปเคสที่ดินตามพนักงาน",
    onAfterPrint: () => alert("Data saved in PDF"),
  });

  return (
    <>
      <center>
        <h1>รายงานสรุปเคสขอยอดที่ดินตามพนักงาน</h1>
        <label>สาขา :</label>{" "}
        <label style={{ color: "#0D36EE" }}>{inputLocat}</label>
        <label> พนักงาน :</label>
        <label style={{ color: "#0D36EE" }}>{marketing}</label>
        <label> วันที่ :</label>
        <label style={{ color: "#0D36EE" }}>{inputDate[0]}</label>
        <label> ถึงวันที่ :</label>{" "}
        <label style={{ color: "#0D36EE" }}>{inputDate[1]}</label>
        <br />
        <br />
      </center>
      <Row justify={"center"}>
        <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Row>
            <Col xs={14} sm={16} md={18} lg={20} xl={24}>
              <RangePicker
                placeholder={["เลือกวันที่", "ถึงวันที่"]}
                style={{ margin: "0 4px 4px" }}
                format="YYYY-MM-DD"
                onChange={(dateSelect, dateSelect2) => {
                  onChangeDate(dateSelect2);
                }}
              />
              <Select
                style={{ width: "110px", margin: "0 4px 4px" }}
                showSearch
                placeholder="สาขา"
                optionFilterProp="children"
                onChange={(value) => {
                  onChangeLocal(value);
                }}
                options={DotaLocat}
                value={inputLocat}
              //defaultValue={DotaLocat[0]}
              />
              <Select
                style={{ width: "110px", margin: "0 4px 4px" }}
                showSearch
                placeholder="การตลาด"
                optionFilterProp="children"
                options={marketingAll.map((item) => ({
                  value: item,
                  label: item,
                }))}
                onChange={(value) => {
                  onChangeMarketing(value);
                }}
              />
              <Button
                type="primary"
                // onClick={postData}
                onClick={onClickA}
                style={{ margin: "0 2px 4px" }}
              >
                ค้นหา
              </Button>
              <Button
                type="primary"
                danger
                onClick={generatePDF}
                style={{ margin: "0 2px 4px" }}
              >
                PDF
              </Button>
              <ExportExcel columns={DotaColums} dataSource={dataTabel} />
            </Col>
          </Row>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          ></Form.Item>
        </Form>
      </Row>
      <Row ref={conponentPDF} style={{ width: "100%" }} justify={"center"}>
        <style>
          {`@media print {.ant-pagination {display: none !important;}.report-name {display: block;}}           
      @media screen {.report-name {display: none;}}`}
        </style>
        <br />
        <br />
        <p className="report-name" style={{ fontSize: "18px" }}>
          {" "}
          รายงานสรุปเคสขอยอดที่ดินตามพนักงาน :{" "}
          <b style={{ color: "#0D36EE" }}>{inputLocat} </b>
          พนักงาน : <b style={{ color: "#0D36EE" }}>{marketing} </b>
          วันที่ : <b style={{ color: "#0D36EE" }}>{inputDate[0]} </b>
          ถึงวันที่ : <b style={{ color: "#0D36EE" }}>{inputDate[1]} </b>
        </p>
        <Table
          columns={DotaColums}
          dataSource={dataTabel.map((item, index) => ({ ...item, key: index }))}
          //dataSource={dataTabel}
          style={{ width: "1500px" }}
        />
      </Row>
    </>
  );
}
export default TabelLoanMarketing;
