import DataCase from "../Data/DataCaseCar";
function CountCaseByLocat(typeCarLand, inputYearMonth, checkLocat, checkLocatAll, dataAPI) {
  console.log("ข้อมูลทั้งหมด:", dataAPI);
  //เช็คปี/เดือน approvalDate โดยรับค่าปี/เดือน จากหน้าบ้าน
  // const [year, month] = inputYearMonth.split("/").map(Number); // Split and convert to numbers
  // const currentYear = year;
  // const currentMonth = month;

  // const checkDataAPIDate = dataAPI.filter((item) => {
  //   const approvalDate = new Date(item.approvalDate);
  //   const approvalYear = approvalDate.getFullYear();
  //   const approvalMonth = approvalDate.getMonth() + 1; // Months are zero-indexed, so add 1
  //   return approvalYear === currentYear && approvalMonth === currentMonth;
  // });
  // console.log("ข้อมูลหลังเลือกเดือน:", checkDataAPIDate);
  //เช็คว่าเป็นรถ
  //checkDataAPIDate
  const checkDataAPI = dataAPI.filter((item) =>
    item.dataType.includes(typeCarLand)
  );
  let dataCheckCase;
  if (checkLocat == null) {
    if (checkLocatAll === "ทั้งหมด") {
      //console.log("Success3:", checkLocatAll);
      dataCheckCase = checkDataAPI;
    } else if (checkLocatAll === "ALLONE") {
      //console.log("Success1:", checkLocatAll);
      const filtercheckAPI = checkDataAPI.filter(
        (item) =>
          item.branch === "MIT" ||
          item.branch === "UD" ||
          item.branch === "LEX" ||
          item.branch === "S4"
      );
      dataCheckCase = filtercheckAPI;
    } else if (checkLocatAll === "ALLKSM") {
      //console.log("Success2:", checkLocatAll);
      const filtercheckAPI = checkDataAPI.filter((item) =>
        item.branch.includes("K10")
      );
      dataCheckCase = filtercheckAPI;
    }
  } else {
    //console.log("Success10:", checkLocat, checkLocatAll);
    const filtercheckAPI = checkDataAPI.filter((item) =>
      item.branch.includes(checkLocat)
    );
    dataCheckCase = filtercheckAPI;
  }

  console.log("ข้อมูลหลังเลือกประเภทรถ:", dataCheckCase);
  // นับจำนวนข้อมูลแยกตามผู้เสนอข้อเสนอและสถานะการอนุมัติ
  const proposalBy = {};
  dataCheckCase.forEach((item) => {
    let prefix = item.branch;
    switch (prefix) {
      case "K1001":
        prefix = "ประตูน้ำ-ขอนแก่น";
        break;
      case "K1002":
        prefix = "รื่นรมย์";
        break;
      case "K1003":
        prefix = "บ้านไผ่";
        break;
      case "K1004":
        prefix = "กระนวน";
        break;
      case "K1005":
        prefix = "มหาสารคาม";
        break;
      case "K1006":
        prefix = "สกลนคร";
        break;
      case "K1007":
        prefix = "สุรินทร์";
        break;
      case "K1008":
        prefix = "โคราช-นครราชสีมา";
        break;
      case "K1009":
        prefix = "อุดรธานี";
        break;
      default:
        break;
    }
    if (!proposalBy[prefix]) {
      proposalBy[prefix] = {
        caseSuccess: 0,
        caseFail: 0,
        caseReject: 0,
        casePre: 0,
        caseAll: 0,
        casePercent: 0,
      };
    }
    // เพิ่มจำนวนทั้งหมดของ proposalBy
    proposalBy[prefix].caseAll++;
    switch (item.approvalStatus) {
      case DataCase[0]:
        proposalBy[prefix].caseSuccess++;
        break;
      case DataCase[1]:
      case DataCase[2]:
        proposalBy[prefix].caseFail++;
        break;
      case DataCase[3]:
        proposalBy[prefix].caseReject++;
        break;
      default:
        break;
    }
    switch (item.productLoanType) {
      case DataCase[4]:
      case DataCase[5]:
      case DataCase[6]:
      case DataCase[7]:
      case DataCase[8]:
      case DataCase[9]:
      case DataCase[10]:
      case DataCase[11]:
      case DataCase[12]:
      case DataCase[13]:
      case DataCase[14]:
      case DataCase[15]:
        proposalBy[prefix].casePre++;
        break;
      default:
        break;
    }
    //คำนวณหา % casePercent
    const { caseFail, caseReject, casePre, caseAll } = proposalBy[prefix];
    proposalBy[prefix].casePercent = (
      ((caseFail + caseReject - casePre) / caseAll) *
      100
    ).toFixed(2);
  });

  //console.log("proposalBy", proposalBy);
  return Object.entries(proposalBy).map(([key, value]) => ({
    locat: key,
    ...value,
  }));
}
export default CountCaseByLocat;
