import React from 'react';
import { useState, useEffect } from 'react';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Divider, Row, Col, Card, Modal, Space, message, Spin } from 'antd';
import axios from 'axios';

import { useDispatch, useSelector } from 'react-redux';
import { addOccupation } from '../../../../../redux/Occupation';

import AddCareerED from './Modals/Career/AddCareerED';
import EditCareerED from './Modals/Career/EditCareerED';
import { deleteoccgua } from '../../../../file_mid/all_api';

export default function GECareer({ close, cha, next, prev, theData, index, infor }) {
    const dispatch = useDispatch()
    const occupations = useSelector(state => state.occupations)

    const [preOcc] = useState(occupations.data); //มาจากรีดัค
    const [form] = Form.useForm();
    const [dataedit, setDataEdit] = useState([]); //เซตสำหรับแก้ไขข้อมูล 
    const [index1, setIndex] = useState(); //set index send to page edit
    const [dataCareer, setDataCareer] = useState([]);
    const [loading, setLoading] = useState(false); // loading
    const [countSave, setCountSave] = useState(false);
    const { confirm } = Modal
    const [modaladddata, setModalAddData] = useState(false);
    const [modaleditdata, setModalEditData] = useState(false);

    // console.log("theData", theData)
    // console.log("dataCareer", dataCareer)

    useEffect(() => {
        if (theData?.customerId > 0 && preOcc === undefined) {
            //loadCareer()
        } else {
            console.log("preOcc")
            setDataCareer(occupations.data)
        }
    }, [])

    useEffect(() => {
        if (countSave === true) {
            handleSubmit()
        }
    }, [modaleditdata, modaladddata, dataCareer])

    const onChangeBack = () => {
        prev()
    }

    const onChangeGo = () => {
        dispatch(addOccupation(dataCareer))
        next()
    }

    const handleSubmit = () => {
        if (dataCareer?.length === 0 || dataCareer?.length === undefined) {
            cha("top")
        } else {
            if (dataCareer !== undefined) {
                dispatch(addOccupation(dataCareer))
                setCountSave(false)
            }
            // onChangeGo()
        }
    }
    const handleCancel = () => {
        close(false)
    }

    const deleteData = async (value) => {
        const test2 = value
        // console.log("delete", test2)
        // await axios.delete('http://localhost:8080/api/delete-occ-gua', { data: test2 })
        await axios.delete(deleteoccgua, { data: test2 })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(addOccupation(dataCareer))
                }
                else {
                    // error()
                }
            }).catch((err) => console.log(err))
    }

    const showConfirm = (value, index) => {
        // console.log("showConfirm", value)
        confirm({
            title: 'คุณต้องการที่จะลบข้อมูลหรือไม่...?',
            content: 'กด OK เพื่อยืนยันการลบ',
            onOk() {
                const deletearray = [...dataCareer]
                deletearray.splice(index, 1)
                setDataCareer(deletearray)
                if (value.addressId !== 0) {
                    deleteData(value)
                } else {
                    dispatch(addOccupation(dataCareer))
                }

            },
            onCancel() {
                message.error('ยกเลิกการลบข้อมูล')
            },
        });
    };

    //ดึงข้อมูลมากจาก Modal addData
    const fucnAdd = (value) => {
        // console.log("value",value)
        if (dataCareer.length === 0) {
            setDataCareer(pre => [...pre, { ...value.career }]);
        } else {
            setDataCareer(pre => [...pre, { ...value.career }]);
        }
        dispatch(addOccupation(dataCareer))
    }
    // แก้ไขข้อมูลจากหน้า AddressEditData
    const fucnEdit = ({ getData, getIndex }) => {
        let Theedit = [...dataCareer]
        Theedit[getIndex] = getData
        //ข้อมูลใหม่แทนที่ข้อมูลเดิมในช่อง Input
        setDataCareer(Theedit);
        dispatch(addOccupation(dataCareer))
    }
    const renderItem = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
        // index ตอนนี้เป็นข้อมูลจาก Object
        console.log("item", item)
        const handleEdit = (dataedit, index1) => {
            setIndex(index1) //เซตค่า index ให้กับชุด ข้อมูล
            setDataEdit({ dataedit, index1 }) //ส่งข้อมูลด index ไปหน้าแก้ไข
            setModalEditData(true);
        }

        return (
            <Form form={form}>
                <Row style={{ margin: '3px' }} justify={'center'} >
                    <Card
                        key={index}
                        title={
                            <u>อาชีพที่ {index + 1}</u>
                        }
                        style={{ width: '300px' }} >
                        <Col span={24}>
                            <Form.Item style={{ marginBottom: 0 }} label='กลุ่มอาชีพ' >
                                <b>{item.desc}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='อาชีพ'>
                                <b>{item.descSub}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='สาขาอาชีพ'>
                                <b>{item.descSection}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='ชื่อบริษัท(สถานที่ทำงาน)'>
                                <b>{item.companyName}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='หมายเหตุ(อาชีพ)'>
                                <b>{item?.occupationDescription ? item.occupationDescription : ""}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='รายได้ / เดือน'>
                                <b>{item.incomeMonth}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='หมายเหตุ(รายได้)'>
                                <b>{item?.incomeMainDescription ? item.incomeMainDescription : ""}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='รายจ่าย / เดือน'>
                                <b>{item.expensesMonth}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='หมายเหตุ(รายจ่าย)'>
                                <b>{item?.expensesMonthDescription ? item.expensesMonthDescription : ""}</b>
                            </Form.Item>
                            <Divider></Divider>
                            <Row justify={'center'} gutter={{ sx: 8, sm: 16, md: 24, lg: 32 }}>
                                <Form.Item>
                                    <Button
                                        style={{ margin: '10px', background: '#f5222d', color: 'white', marginBottom: 0 }}
                                        icon={<DeleteOutlined />}
                                        onClick={() => showConfirm(dataCareer[index], index)}
                                    >ลบ</Button>
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        style={{ margin: '10px', background: '#bfbfbf', color: '#ffffff', marginBottom: 0 }}
                                        type='submit'
                                        onClick={() => { handleEdit(dataCareer[index], index); }}
                                    >แก้ไข</Button>
                                </Form.Item>
                            </Row>
                        </Col>
                    </Card>
                </Row>
            </Form>
        )
    }
    return (
        <>
            <Card style={{ color: '#4096ff' }}>
                <Spin spinning={loading} size='large' tip=" Loading... ">
                    <Form>
                        <Row>
                            <Col className='gutter-row' span={24}>
                                <Row>
                                    <Col span={24} style={{ fontSize: '30px', textAlign: 'center' }} >รายละเอียดอาชีพ - รายได้</Col>
                                </Row>
                                <Divider />
                                <Row gutter={32}>
                                    <Col span={24} style={{ textAlign: 'right' }}>
                                        <Button type='primary' onClick={() => { setModalAddData(true); }}> <PlusOutlined />เพิ่มข้อมูลอาชีพ - รายได้</Button>
                                    </Col>
                                </Row>
                                <Row justify={'center'}>
                                    {
                                        dataCareer.map((item, index) => {
                                            return renderItem({ item, index, key: `{item.occupationJobPositionId} - ${index}` })
                                        })
                                    }
                                </Row>
                            </Col>
                        </Row>
                        <Divider />
                        <div style={{ textAlign: "center" }} >
                            <Space>
                                <Button type="primary" style={{ background: '#bfbfbf', color: '#000000', }} onClick={onChangeBack}>ย้อนกลับ</Button>
                                <Button type="primary" onClick={handleCancel} style={{ background: "red" }}>ยกเลิก</Button>
                                {/* <Button type="primary" htmlType="submit" onClick={handleSubmit}>ต่อไป</Button> */}
                                <Button type="primary" onClick={onChangeGo}> ต่อไป</Button>
                            </Space>
                        </div>
                    </Form>
                </Spin>
            </Card>
            {
                //เพื่มข้อมูลในการ์ด
                modaladddata ?
                    <AddCareerED open={modaladddata} close={setModalAddData} fucnAdd={fucnAdd} cusId={theData.customerId} setCountSave={setCountSave} />
                    : null
            }
            {
                //แก้ไขข้อมูลในการ์ด
                modaleditdata ?
                    <EditCareerED open={modaleditdata} close={setModalEditData} shootdata={dataedit} fucnEdit={fucnEdit} dataindex={index1} setCountSave={setCountSave} />
                    : null
            }
        </>
    )
}
