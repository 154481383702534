import React, { useState, useEffect } from 'react'
import { Form, Modal, Row, Spin, Space, Button, Divider, Card, Input, Select, notification } from 'antd'
import axios from 'axios';
import dayjs from 'dayjs';
import { GetProvince, GetAmphur1, editcasereland, AllData } from '../../../file_mid/all_api';
import currency from 'currency.js';

export default function EditLand({ close, page, changepage, data, dataCont, dataRe, sendback }) {
    // const { confirm } = Modal
    const { Option } = Select;
    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(false);
    const currentTime = dayjs();
    const formattedTime = currentTime.format('YYYY-MM-DDTHH:mm:ssZ');

    const [checkLand, setCheckLand] = useState(false)
    const addLands = localStorage.getItem('addLand')
    const dataAddLand = JSON.parse(addLands)

    const [dataPostLand, setDataPostLand] = useState({   // ที่ดิน
        // landTypeId: 2,
        // productTypeLand: 2,
        landInput: formattedTime,
        landDateStatus: formattedTime,
        // rai: 0,
        // workArea: 0,
        // squareWaArea: 0,
        // landPrice: 0,
        // resultLandPrice: 0,
        // landPriceStatus: false,
    })

    const [pvcodeS, setPvcodeS] = useState({});
    const [amcodeS, setAmcodeS] = useState({});

    const [provinces, setProvinces] = useState([]);
    const [district, setDistricts] = useState([]);

    const sendSuccess = (placement) => {
        api.success({
            message: <b>บันทึกสำเร็จ</b>,
            //   description:
            //     'สถานะถูกเปลี่ยนจาก รอธุการรับ เป็น รอพิจารณาแล้ว',
            placement,
        });
    }
    const sendError = (placement) => {
        api.error({
            message: <b>บันทึกไม่สำเร็จ</b>,
            //   description:
            //     'สถานะถูกเปลี่ยนจาก รอธุการรับ เป็น รอพิจารณาแล้ว',
            placement,
        });
    }

    const handleGo = () => {
        changepage(page + 1)
    }
    const handleClose = () => {
        close()
    }

    useEffect(() => {
        if (addLands) {
            // console.log("1")
            getProvinces()
            // PostProvince(dataRe .province)
            setTimeout(() => {
                GetAmphur(dataAddLand.district)
            }, 2000)
            setDataPostLand(dataAddLand)
            form.setFieldsValue({
                ...dataAddLand,
                // numberLandLawang: "",
                // rai: "",
                // workArea: "",
                // squareWaArea: "",
                // landPrice: "",
                // resultLandPrice: "",
            })
            setCheckLand(true)
        } else {
            // console.log("2")
            getProvinces()
            if (dataCont) {
                let Province = (dataCont[0]?.provinces);
                Province = Province.replace(/\u0000/, '');
                let District = (dataCont[0]?.district);
                District = District.replace(/\u0000/, ' ');
                let newNumberLand = (dataCont[0]?.numberLand);
                newNumberLand = newNumberLand.replace(/\.\s*|\(.*?\)|,.*|\/.*/g, '');
                if (dataCont[0]?.productTypeLand === "โฉนด") {
                    setDataPostLand({
                        ...dataPostLand,
                        ...dataCont[0],
                        productTypeLand: 2,
                        province: Province,
                        district: District,
                        numberLand: newNumberLand,
                    })
                } else {
                    setDataPostLand({
                        ...dataPostLand,
                        ...dataCont[0],
                        productTypeLand: 1,
                        province: Province,
                        district: District,
                        numberLand: newNumberLand,
                    })
                }
                form.setFieldsValue({
                    productTypeLand: dataCont[0]?.productTypeLand === "โฉนด" ? "นส.4 จ." : "นส.3 ก.",
                    province: dataCont[0]?.provinces,
                    district: dataCont[0]?.district,
                    numberLand: newNumberLand,
                })
            }
        }
    }, [addLands])

    /////////////// ที่อยู่ ของ ที่ดิน //////////////////
    const getProvinces = async () => {
        setLoading(true)
        await axios.get(GetProvince)
            .then((res) => {
                setProvinces(res.data)
                res.data.result.map((e) => {
                    if (e?.pvnamethai === dataCont[0]?.provinces) {
                        setPvcodeS(e.pvcode)
                    }
                })
                setLoading(false)
            }).catch((err) => {
                console.error(err);
            })
        setLoading(false)
    }

    const GetAmphur = async (value) => {
        const am = { pvcode: value }
        setLoading(true)
        await axios.post(GetAmphur1, am)
            .then((res) => {
                setDistricts(res.data)
                setAmcodeS(res.data[0].amcode)
                setLoading(false)
            }).catch((err) => {
                console.error(err);
            })
        setLoading(false)
    }

    const handleSubmit = () => {
        setLoading(true)
        let result = {
            // carId: data?.carId,
            // carBaab: dataPost2?.carBaab,
            // carBrand: dataPost2?.carBrand,
            // carModel: dataPost2?.carModel,
            // carPrice: dataPost2?.carPrice,
            // carPlateNumber: dataPost2?.carPlateNumber,
            // carProvince: dataPost2?.carProvince,
            // productTypeCar: dataPost2?.productTypeCar,
            // carPriceStatus: dataPost2?.carPriceStatus,
            // carInput: formattedTime,
            // carDateStatus: formattedTime
            landId: data.landId,
            productTypeLand: dataPostLand.productTypeLand,
            province: dataPostLand.province,
            district: dataPostLand.district,
            numberLand: dataPostLand.numberLand,
            numberLandLawang: dataPostLand.numberLandLawang,
            rai: dataPostLand.rai,
            workArea: dataPostLand.workArea,
            squareWaArea: dataPostLand.squareWaArea,
            landPrice: dataPostLand.landPrice,
            resultLandPrice: dataPostLand.resultLandPrice,
            landInput: formattedTime,
            landDateStatus: formattedTime,
            Parcellat: dataPostLand.parcellat,
            Parcellon: dataPostLand.parcellon,
            subdistrict: dataPostLand.subdistrict,
            // landPriceStatus: false
        }

        // console.log("result", result)
        // setLoading(false)
        if (result.productTypeLand === 1) {
            handleSubmitTwo(result)
        } else {
            if (checkLand) {
                handleSubmitTwo(result)
            } else {
                errorSubmit("top")
            }
        }
    };

    const handleSubmitTwo = async (value) => {
        setLoading(true)
        await axios.put(editcasereland, value)
            .then((res) => {
                if (res.status === 200) {
                    sendback(dayjs())
                    sendSuccess('top')
                    localStorage.setItem('addLand', JSON.stringify(dataPostLand))
                    setTimeout(() => {
                        handleGo()
                    }, 1000)
                    setLoading(false)
                }
            }).catch((err) => {
                console.log(err)
                sendError('top')
                setLoading(false)
            })
    }

    const onTypeLoanChange = (value) => {
        setDataPostLand({
            ...dataPostLand,
            productLoanLandType: value
        })
        // setDataLoanTyped(value)
    }

    const currencyFormatOne = (amount) => {
        if (amount) {
            return Number(amount)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, '$&,')
        }
    }


    /////////////////// เลือก 1 = นส.3 กับ 2 = นส.4 //////////////////////
    const handleProductLand = (value) => {
        if (value === 1) {
            setDataPostLand({
                ...dataPostLand,
                productTypeLand: value,
                numberLand: "-",
                numberLandLawang: "-",
                rai: 0,
                workArea: 0,
                squareWaArea: 0,
                landPrice: 0,
                resultLandPrice: 0,
                landPriceStatus: false,
                parcellat: null,
                parcellon: null,
                subdistrict: null,
            })
            form.setFieldsValue(
                {
                    numberLand: "",
                    numberLandLawang: "",
                    rai: "",
                    workArea: "",
                    squareWaArea: "",
                    landPrice: "",
                    resultLandPrice: "",
                    province: "",
                    district: "",
                }
            )
        } else {
            setDataPostLand({
                ...dataPostLand,
                productTypeLand: value,
                numberLand: "-",
                numberLandLawang: "-",
                rai: 0,
                workArea: 0,
                squareWaArea: 0,
                landPrice: 0,
                resultLandPrice: 0,
                landPriceStatus: false,
            })
            form.setFieldsValue(
                {
                    numberLand: "",
                    numberLandLawang: "",
                    rai: "",
                    workArea: "",
                    squareWaArea: "",
                    landPrice: "",
                    resultLandPrice: "",
                    province: "",
                    district: "",
                }
            )
        }
    }
    ///////////////// กรณี นส.3 ///////////////////////////
    const handleProvinceChange1 = async (value, key) => {
        setPvcodeS(key["key"])
        setDataPostLand({ ...dataPostLand, province: value });
        form.setFieldsValue(
            {
                numberLand: "",
                numberLandLawang: "",
                rai: "",
                workArea: "",
                squareWaArea: "",
                district: "",
            }
        )
        setLoading(true)
        const test = { pvcode: key["key"] }
        await axios.post(GetAmphur1, test)
            .then((res) => {
                setDistricts(res.data)
                setLoading(false)
            }).catch((err) => {
                console.error(err);
            })
        setLoading(false)
    };
    const handleDistrictChange2 = (value, key) => {
        setAmcodeS(key["key"])
        setDataPostLand({ ...dataPostLand, district: value });
        form.setFieldsValue(
            {
                numberLand: "",
                numberLandLawang: "",
                rai: "",
                workArea: "",
                squareWaArea: "",
            }
        )

    };

    //นส.4
    const handleProvinceChange = async (value, key) => {
        setPvcodeS(key["key"])
        setDataPostLand({ ...dataPostLand, province: value });
        form.setFieldsValue(
            {
                numberLand: "",
                numberLandLawang: "",
                rai: "",
                workArea: "",
                squareWaArea: "",
                landPrice: "",
                resultLandPrice: "",
                district: "",
            }
        )
        setLoading(true)
        const test = { pvcode: key["key"] }
        await axios.post(GetAmphur1, test)
            .then((res) => {
                setDistricts(res.data)
                setLoading(false)
            }).catch((err) => {
                console.error(err);
            })
        setLoading(false)
    };
    const handleDistrictChange = (value, key) => {
        setAmcodeS(key["key"])
        setDataPostLand({ ...dataPostLand, district: value });
        form.setFieldsValue(
            {
                numberLand: "",
                numberLandLawang: "",
                rai: "",
                workArea: "",
                squareWaArea: "",
                landPrice: "",
                resultLandPrice: "",
            }
        )

    };
    const handleNumberLand = (e) => {
        setCheckLand(false)
        setDataPostLand({ ...dataPostLand, numberLand: e.target.value })
    };
    const handleCheckLand = async () => {
        // let numberLandNew = (dataCont[0].numberLand);
        // // numberLandNew = numberLandNew.replace(/\(1 ?\)/, '') 
        var provids = pvcodeS
        var amphurs = amcodeS
        var landNos = dataPostLand.numberLand
        //const tokens = "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IjEiLCJyZWdpc3Rlcl90eXBlX3NlcSI6IjgiLCJ1c2VyX2dyb3VwX2RhdGFfbGlzdCI6IjEsMSwwLDAsMCwwLDAsMSIsInBlcnNvbmFsX2lkIjoiIiwianRpIjoiOTllYmE2OTAtMGFmOC00M2NhLTg2OTktYWUwZDI4N2ZjY2EzIiwiaWF0IjoiMDkvMjcvMjAyMyAwMzoyMDoxNSIsIm5iZiI6MTY5NTc4NDgxNSwiZXhwIjoxNjk1ODcxMjE1LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDozMDAwMS8iLCJhdWQiOiJMYW5kc01hcHMifQ.Lw3h4tjSbzjscWqkuA9FutwzXPY8UEb49D4ZdsdqKRfR0fwFunY7i4pg5Rdc70Vrw8jDR1qhtQLn5SEqxPguoQ"

        var result = { pvcode: provids, amcode: amphurs, landNo: landNos }
        // console.log("result =", result)
        const headers = {
            "Content-Type": "application/json",
            // "Authorization": `Bearer ${tokens}`,
        }
        setLoading(true)
        await axios.post(AllData, result, { headers: headers })
            .then((response) => {
                if (response.data) {
                    const squareWaArea = parseFloat(response.data.result[0].wa + "." + response.data.result[0].subwa)
                    const landPricePerWa = parseInt(response.data.result[0].landprice.replace(/,/g, ""))
                    const totalWa = (
                        ((parseInt(response.data?.result[0].rai) * 400)) +
                        ((parseInt(response.data?.result[0].ngan) * 100)) +
                        parseFloat(response.data?.result[0].wa + "." + response.data?.result[0].subwa)
                    )
                    const totalPrice = currency(totalWa * landPricePerWa).value
                    console.log("รวมตารางวาทั้งหมด =", totalPrice.toFixed(1));
                    if (landPricePerWa > 0) {
                        setDataPostLand({
                            ...dataPostLand,
                            subdistrict: response.data?.result[0].tumbolname,
                            parcellat: response.data?.result[0].parcellat,
                            parcellon: response.data?.result[0].parcellon,
                            numberLandLawang: response.data?.result[0].utm,
                            rai: parseInt(response.data?.result[0].rai),
                            workArea: parseInt(response.data?.result[0].ngan),
                            squareWaArea: parseFloat(squareWaArea),
                            landPrice: parseInt(response.data.result[0].landprice.replace(/,/g, "")) || 0,
                            resultLandPrice: totalPrice || 0,
                            landPriceStatus: true
                        });
                        form.setFieldsValue(
                            {
                                numberLandLawang: response.data?.result[0].utm,
                                rai: parseInt(response.data?.result[0].rai),
                                workArea: parseInt(response.data?.result[0].ngan),
                                squareWaArea: squareWaArea,
                                landPrice: currencyFormatOne(parseInt(response.data.result[0].landprice.replace(/,/g, ""))) || 0,
                                resultLandPrice: currencyFormatOne(totalPrice) || 0,
                            }
                        )
                    } else {
                        setDataPostLand({
                            ...dataPostLand,
                            subdistrict: response.data?.result[0].tumbolname,
                            parcellat: response.data?.result[0].parcellat,
                            parcellon: response.data?.result[0].parcellon,
                            numberLandLawang: response.data?.result[0].utm,
                            rai: parseInt(response.data?.result[0].rai),
                            workArea: parseInt(response.data?.result[0].ngan),
                            squareWaArea: parseFloat(squareWaArea),
                            landPrice: 0,
                            resultLandPrice: 0,
                            landPriceStatus: false
                        });
                        form.setFieldsValue(
                            {
                                numberLandLawang: response.data?.result[0].utm,
                                rai: parseInt(response.data?.result[0].rai),
                                workArea: parseInt(response.data?.result[0].ngan),
                                squareWaArea: squareWaArea,
                                landPrice: 0,
                                resultLandPrice: 0,
                            }
                        )
                    }
                    setLoading(false)
                } else {
                    console.log("DATA =", response.data)
                }
            })
            .catch((err) => {
                setDataPostLand({
                    ...dataPostLand,
                    parcellat: "-",
                    parcellon: "-",
                    numberLandLawang: "-",
                    rai: 0,
                    workArea: 0,
                    squareWaArea: 0,
                    landPrice: 0,
                    resultLandPrice: 0,
                    landPriceStatus: false
                });
                errorLand()
            })
            .finally(() => {
                setLoading(false);
            })
        setLoading(false)
        setCheckLand(true)
    }
    const errorLand = () => {
        setDataPostLand({
            ...dataPostLand,
            numberLandLawang: null,
            rai: null,
            workArea: null,
            squareWaArea: null,
            landPrice: 0,
            resultLandPrice: 0,
            landPriceStatus: false
        });
        form.setFieldsValue(
            {
                numberLandLawang: "",
                rai: "",
                workArea: "",
                squareWaArea: "",
                landPrice: 0,
                resultLandPrice: 0,
            }
        )
        Modal.error({
            title: 'ไม่พบข้อมูล',
            content: 'กรุณาตรวจสอบข้อมูลที่กรอกอีกครั้ง',
        })
    }

    const errorSubmit = (placement) => {
        api.error({
            message: <b>ยังไม่กดตรวจสอบที่ดิน</b>,
            description: `กรณีที่มีการกรอก เลขโฉนดที่ดินต้องกตรวจสอบทุกครั้ง`,
            placement,
        });
    }

    return (
        <Card style={{ width: "100%" }}>
            <Divider orientation={'center'} style={{ fontSize: '25px' }}><b><u>ข้อมูลรถ</u></b></Divider>
            <Spin spinning={loading} size='large' tip=" Loading... ">
                <Form
                    name="basic"
                    labelCol={{
                        span: 4,
                    }}
                    // wrapperCol={{
                    //     span: 24,
                    // }}
                    initialValues={{
                        remember: true,
                    }}
                    form={form}
                    onFinish={handleSubmit}
                    autoComplete="off"
                >
                    <Row justify={"center"}>
                        {
                            dataCont ?
                                <>
                                    <Divider orientation={'left'}><b><u>สินค้า</u></b></Divider>
                                    <aside style={{ width: '90%' }}>
                                        <div style={{ marginBottom: 3 }}>
                                            <span>ประเภทเอกสาร : </span><b>{dataCont[0]?.productTypeLand === "โฉนด" ? "นส.4 จ." : "นส.3 ก."}</b>
                                        </div>
                                        <div style={{ marginBottom: 3 }}>
                                            <span>จังหวัด : </span><b>{dataCont[0]?.provinces}</b>
                                        </div>
                                        <div style={{ marginBottom: 3 }}>
                                            <span>อำเภอ : </span><b>{dataCont[0]?.district}</b>
                                        </div>
                                        <div style={{ marginBottom: 3 }}>
                                            <span>เลขโฉนดที่ดิน : </span><b>{dataCont[0]?.numberLand}</b>
                                        </div>
                                        <div style={{ marginBottom: 3 }}>
                                            <span>ระวาง : </span><b>{dataCont[0]?.numberLandlawang}</b>
                                        </div>
                                    </aside>
                                </>
                                : null
                        }
                        <Divider orientation={'left'}><b><u>รายละเอียด</u></b></Divider>
                        <aside style={{ width: '90%' }}>
                            <Form.Item label='ประเภทเอกสาร' name='productTypeLand'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input !'
                                    },]}>
                                <Select placeholder={'เลือก'} style={{ height: '40px' }}
                                    onChange={(value) => handleProductLand(value)}
                                >
                                    <Option value={1}>นส.3 ก.</Option>
                                    <Option value={2}>นส.4 จ.</Option>
                                </Select>
                            </Form.Item>
                            {/* นส3 */}
                            {
                                dataPostLand?.productTypeLand === 1 ?
                                    <>
                                        <Form.Item name='province' label='จังหวัด'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input !'
                                                },]}>
                                            <Select
                                                style={{ height: '40px' }}
                                                showSearch
                                                loading={loading}
                                                name='province'
                                                onChange={handleProvinceChange1}
                                            >
                                                {provinces?.result?.map((pro, index) => (
                                                    <Option key={pro.pvcode} value={pro.pvnamethai}>
                                                        {pro.pvnamethai}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name='district' label='อำเภอ'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input !'
                                                },]}>
                                            <Select
                                                style={{ height: '40px' }}
                                                showSearch
                                                loading={loading}
                                                name='district'
                                                placeholder="อำเภอ"
                                                onChange={handleDistrictChange2}
                                            >
                                                {district?.map((dis, index) => (
                                                    <Option key={dis.amcode} value={dis.amnamethai}>
                                                        {dis.amnamethai}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item label='เลขที่โฉนด/เลขที่' name='numberLand'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input !'
                                                },]}>
                                            <Input type='text'
                                                onChange={(e) => setDataPostLand({ ...dataPostLand, numberLand: e.target.value })}></Input>
                                        </Form.Item>
                                        <Form.Item label='พื้นที่ไร่' name='rai'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input !'
                                                },]}>
                                            <Input type='number' suffix="ไร่"
                                                onChange={(e) => setDataPostLand({ ...dataPostLand, rai: parseInt(e.target.value) })}></Input>
                                        </Form.Item>
                                        <Form.Item label='พื้นที่งาน' name='workArea'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input !'
                                                },]}>
                                            <Input type='number' suffix="งาน"
                                                onChange={(e) => setDataPostLand({ ...dataPostLand, workArea: parseInt(e.target.value) })}></Input>
                                        </Form.Item>
                                        <Form.Item label='พื้นที่ตารางวา' name='squareWaArea'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input !'
                                                },]}>
                                            <Input type='number' suffix="ตารางวา"
                                                onChange={(e) => setDataPostLand({ ...dataPostLand, squareWaArea: parseFloat(e.target.value) })}></Input>
                                        </Form.Item>
                                    </>
                                    :
                                    <>
                                        <Form.Item name='province' label='จังหวัด'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input !'
                                                },]}>
                                            <Select
                                                style={{ height: '40px' }}
                                                showSearch
                                                loading={loading}
                                                name='province'
                                                onChange={handleProvinceChange}
                                            >
                                                {provinces?.result?.map((pro, index) => (
                                                    <Option key={pro.pvcode} value={pro.pvnamethai}>
                                                        {pro.pvnamethai}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name='district' label='อำเภอ'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input !'
                                                },]}>
                                            <Select
                                                style={{ height: '40px' }}
                                                showSearch
                                                loading={loading}
                                                name='district'
                                                placeholder="อำเภอ"
                                                onChange={handleDistrictChange}
                                            >
                                                {district?.map((dis, index) => (
                                                    <Option key={dis.amcode} value={dis.amnamethai}>
                                                        {dis.amnamethai}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item label='เลขโฉนดที่ดิน' name='numberLand'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input !'
                                                },]}>
                                            <Input type='text' onChange={(e) => handleNumberLand(e)}></Input>
                                        </Form.Item>
                                        <Form.Item label='ตรวจสอบ'>
                                            <Button type="primary" disabled={loading} onClick={handleCheckLand}>ตรวจสอบ</Button>
                                        </Form.Item>
                                        <Form.Item label='ระวาง' name='numberLandLawang'>
                                            <Input type='text' disabled style={{ color: 'black' }}
                                                onChange={(e) => setDataPostLand({ ...dataPostLand, numberLandLawang: e.target.value })}></Input>
                                        </Form.Item>
                                        <Form.Item label='พื้นที่ไร่' name='rai'>
                                            <Input type='text' suffix="ไร่" disabled style={{ color: 'black' }}
                                                onChange={(e) => setDataPostLand({ ...dataPostLand, rai: parseInt(e.target.value) })}></Input>
                                        </Form.Item>
                                        <Form.Item label='พื้นที่งาน' name='workArea'>
                                            <Input type='text' suffix="งาน" disabled style={{ color: 'black' }}
                                                onChange={(e) => setDataPostLand({ ...dataPostLand, workArea: parseInt(e.target.value) })}></Input>
                                        </Form.Item>
                                        <Form.Item label='พื้นที่ตารางวา' name='squareWaArea'>
                                            <Input type='text' suffix="ตารางวา" disabled style={{ color: 'black' }}
                                                onChange={(e) => setDataPostLand({ ...dataPostLand, squareWaArea: parseFloat(e.target.value) })}></Input>
                                        </Form.Item>
                                        <Form.Item label='ราคาประเมินที่ดิน' name='landPrice'>
                                            <Input type='text' suffix="บาทต่อตารางวา" disabled style={{ color: 'black' }}
                                                onChange={(e) => setDataPostLand({ ...dataPostLand, landPrice: parseInt(e.target.value) })}></Input>
                                        </Form.Item>
                                        <Form.Item label='ราคารวม' name='resultLandPrice'>
                                            <Input type='text' suffix="บาท" disabled style={{ color: 'black' }}
                                                onChange={(e) => setDataPostLand({ ...dataPostLand, resultLandPrice: parseInt(e.target.value) })}></Input>
                                        </Form.Item>
                                    </>
                            }
                            {/* นส4 */}
                        </aside>
                        <Divider style={{ margin: 5 }} />
                        <Row justify={'center'} >
                            <Space>
                                <Button type="primary" style={{ background: "red" }} onClick={handleClose}>
                                    ยกเลิก
                                </Button>
                                <Button type="primary" htmlType="submit" style={{ background: "green" }} >
                                    บันทึก
                                </Button>
                            </Space>
                        </Row>
                    </Row>
                </Form>
            </Spin>
            {contextHolder}
        </Card >
        // {/* </Row > */}
    )
}

