import React from 'react'
import { Form, Modal, Row, Col, Image, Space, Card, Button, Divider } from 'antd'
import '../../css/Media.css'
import { getImagess } from '../../file_mid/all_api';

function ModalShowImg({ open, close, imageBlobzz }) {
    const token = localStorage.getItem('token');
    const [form] = Form.useForm()

    const handleCancel = () => {
        close(false)
    }

    return (
        <Modal title="แสดงรูปภาพ" open={open} onCancel={handleCancel} width={'1200px'}
            footer={[
                <Row justify={'center'}>
                    <Space>
                        <Button key="back" type='primary' danger onClick={handleCancel}>
                            ปิด
                        </Button>
                    </Space>
                </Row >
            ]} >
            <Row justify={'center'}>
                {/* <Divider style={{ margin: 5 }} /> */}
                <Form
                    labelCol={{
                        span: 12,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    style={{
                        width: '90%',
                    }}
                    form={form}
                    autoComplete="off"
                    initialValues={{ remember: true }}
                >
                    {/* <Card style={{ width: '100%' }}> */}
                    <Row justify={'left'}><b><u>รูปบัตรประชาชนผู้ทำสัญญา</u></b></Row>
                    <Row gutter={32} justify={'center'}>
                        <Space direction="vertical" >
                            {imageBlobzz?.map((e, index) => {
                                var PreAaproveSplit = []
                                PreAaproveSplit = e.pathImage.split("/")
                                if (PreAaproveSplit[3] !== "13") {
                                    if (e.type === 4) {
                                        return (
                                            <>
                                                <Row gutter={32} justify={'center'}>
                                                    <Col span={24} style={{ textAlign: 'center' }}>
                                                        <Image width={'200px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                    </Col>
                                                </Row>
                                            </>
                                        );
                                    }
                                }
                                return null;
                            })}
                        </Space>
                    </Row>
                    <Divider />
                    <Row justify={'left'}><b><u>หน้าคู่มือ</u></b></Row>
                    <Row gutter={32} justify={'center'}>
                        <Space direction="vertical">
                            <Row justify={'center'}>
                                {imageBlobzz?.map((e, index) => {
                                    var PreAaproveSplit = []
                                    PreAaproveSplit = e.pathImage.split("/")
                                    if (PreAaproveSplit[3] !== "13") {
                                        if (e.type === 2) {
                                            return (
                                                <>
                                                    <Row gutter={32} justify={'center'}>
                                                        <Col span={24} style={{ textAlign: 'center' }}>
                                                            <Image width={'200px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                        </Col>
                                                    </Row>
                                                </>
                                            );
                                        }
                                    }
                                    return null;
                                })}
                            </Row>
                        </Space>
                    </Row>
                    <Divider />
                    <Row justify={'left'}><b><u>ใบประเมิน / รูปรถ / สัญญาไฟแนนซ์เดิม / ใบเสร็จไฟแนนซ์เดิม</u></b></Row>
                    <Row gutter={32} justify={'center'}>
                        <Space direction="vertical">
                            <Row justify={'center'}>
                                {imageBlobzz?.map((e, index) => {
                                    var PreAaproveSplit = []
                                    PreAaproveSplit = e.pathImage.split("/")
                                    if (PreAaproveSplit[3] !== "13") {
                                        if (e.type === 1) {
                                            return (
                                                <>
                                                    <Row gutter={32} justify={'center'}>
                                                        <Col span={24} style={{ textAlign: 'center' }}>
                                                            <Image width={'200px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                        </Col>
                                                    </Row>
                                                </>
                                            );
                                        }
                                    }
                                    return null;
                                })}
                            </Row>
                        </Space>
                    </Row>
                    <Divider />
                    <Row justify={'left'}><b><u>ประวัติผู้กู้ / ทะเบียนบ้าน / อาชีพ</u></b></Row>
                    <Row gutter={32} justify={'center'}>
                        <Space direction="vertical" >
                            <Row justify={'center'}>
                                {imageBlobzz?.map((e, index) => {
                                    var PreAaproveSplit = []
                                    PreAaproveSplit = e.pathImage.split("/")
                                    if (PreAaproveSplit[3] !== "13") {
                                        if (e.type === 6) {
                                            return (
                                                <Row gutter={32} justify={'center'}>
                                                    <Col span={24} style={{ textAlign: 'center' }}>
                                                        <Image width={'200px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                    </Col>
                                                </Row>
                                            );
                                        }
                                    }
                                    return null;
                                })}
                            </Row>
                        </Space>
                    </Row>
                    <Divider />
                    <Row justify={'left'}><b><u>รูปสเตทเมนต์ผู้กู้</u></b></Row>
                    <Row gutter={32} justify={'center'}>
                        <Space direction="vertical" >
                            <Row justify={'center'}>
                                {imageBlobzz?.map((e, index) => {
                                    var PreAaproveSplit = []
                                    PreAaproveSplit = e.pathImage.split("/")
                                    if (PreAaproveSplit[3] !== "13") {
                                        if (e.type === 8) {
                                            return (
                                                <Row gutter={32} justify={'center'}>
                                                    <Col span={24} style={{ textAlign: 'center' }}>
                                                        <Image width={'200px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                    </Col>
                                                </Row>
                                            );
                                        }
                                    }
                                    return null;
                                })}
                            </Row>
                        </Space>
                    </Row>
                    <Divider />
                    <Row justify={'left'}><b><u>รูปสเตทเมนต์ผู้กู้ PDF</u></b></Row>
                    <Row gutter={32} justify={'center'}>
                        <Space direction="vertical" >
                            {imageBlobzz?.map((e, index) => {
                                var PreAaproveSplit = []
                                PreAaproveSplit = e.pathImage.split("/")
                                if (PreAaproveSplit[3] !== "13") {
                                    if (e.type === 7) {
                                        return (
                                            <Row gutter={32} justify={'center'}>
                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                    <Space direction='vertical'>
                                                        <object data={`${getImagess}/${e.pathImage}?token=${token}`} type="application/pdf" width="350px" height="450px">
                                                            <p>Alternative text - include a link <a href={`${getImagess}/${e.pathImage}?token=${token}`}>to the PDF!</a></p>
                                                        </object>
                                                        <p></p>
                                                    </Space>
                                                </Col>
                                            </Row>
                                        )
                                    }
                                }
                                return null;
                            })}
                        </Space>
                    </Row>
                    <Divider />
                    <Row justify={'left'}><b><u>เอกสารเพิ่มเติม</u></b></Row>
                    <Row gutter={32} justify={'center'}>
                        <Space direction="vertical" >
                            <Row justify={'center'}>
                                {imageBlobzz?.map((e, index) => {
                                    var PreAaproveSplit = []
                                    PreAaproveSplit = e.pathImage.split("/")
                                    if (PreAaproveSplit[3] !== "13") {
                                        if (e.type === 12) {
                                            return (
                                                <Row gutter={32} justify={'center'}>
                                                    <Col span={24} style={{ textAlign: 'center' }}>
                                                        <Image width={'200px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                    </Col>
                                                </Row>
                                            );
                                        }
                                    }
                                    return null;
                                })}
                            </Row>
                        </Space>
                    </Row>
                    {/* </Card> */}
                </Form>
            </Row>
        </Modal >
    )
};

export default ModalShowImg