import React, { useState, useEffect } from 'react'
import { Modal, Form, Button, Input, Card, Space, Select, Row, Col, notification, message, Divider, InputNumber } from 'antd'
import axios from 'axios';
import dayjs from 'dayjs';
import { approvalPriceNew, approve, reject, shownotepn, shownotepnre } from '../../file_mid/all_api';

export default function NotePN({ open, close, appStatus, shootdata, newDataPn, close2, result, newdataLand, dataFromTable, showOldData2 }) {
    const { TextArea } = Input;
    // console.log("dataFromTable =", dataFromTable)
    // console.log("newdataLand", newdataLand)
    // console.log("shootdata", shootdata)
    const us = localStorage.getItem('username')

    const currentTime = dayjs();
    //const delayedTime = currentTime.add(7, 'hour');
    const formattedTime = currentTime.format('YYYY-MM-DDTHH:mm:ssZ');

    const [dataModalEdit, setDataModalEdit] = useState(shootdata);
    const [note, setNote] = useState({});
    const [numForCheck, setNumForCheck] = useState({});
    const [options, setOptions] = useState([]);
    const [checkNote, setCheckNote] = useState(0);
    const [addNote, setAddNote] = useState({ notePn: '' });
    const [dis, setDis] = useState(false);
    const [multiModeData, setMultiModeData] = useState([]);
    const [multiMode, setMultiMode] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [form] = Form.useForm()
    const [formAdd] = Form.useForm()
    const [statusAccept, setstatusAccept] = useState({
        customerId: '', landId: '', loanId: '', approvalStatus: '', note: '',
    });

    useEffect(() => {
        pickData()
    }, [])
    useEffect(() => {
        if (appStatus === "อนุมัติ") {
            loadNoteName();
        } else if ((appStatus === "ขอเอกสารเพิ่ม")) {
            loadNoteNameRe();
        }
        if (newdataLand.length > 1) {
            setMultiMode(true)
            setMultiModeData(newdataLand)
            handleSetUp()
        } else {
            setMultiModeData(newdataLand)
            handleSetUp()
        }

    }, [numForCheck])

    // console.log("multiModeData", multiModeData)
    console.log("multiModeData", multiModeData)

    const pickData = () => {
        if (shootdata?.loanAmounttLand > 0) {
            if (appStatus === "อนุมัติ") {
                if (result === null) {
                    setstatusAccept({
                        customerId: dataModalEdit.customerId,
                        landId: dataModalEdit.landId,
                        landLoanId: dataModalEdit.landLoanId,
                        approvalStatus: "อนุมัติ",
                        note: statusAccept.note,
                    })
                    setDataModalEdit({
                        ...dataModalEdit,
                        approvalStatus: "อนุมัติ",
                        approvalDate: formattedTime,
                        inputPriceDate: formattedTime,
                        priceStatus: true
                    })
                } else {
                    setstatusAccept({
                        customerId: dataModalEdit.customerId,
                        landId: dataModalEdit.landId,
                        landLoanId: dataModalEdit.landLoanId,
                        approvalStatus: "อนุมัติ",
                        note: statusAccept.note,
                    })
                    setDataModalEdit({
                        ...dataModalEdit,
                        approvalStatus: "อนุมัติ",
                        approvalDate: formattedTime,
                        inputPriceDate: formattedTime,
                        priceStatus: true,
                        minPrice: parseInt(result?.approval?.minPrice),
                        maxPrice: parseInt(result?.approval?.maxPrice),
                        note: result?.approval?.note,
                    })
                    newdataLand.map((e, index) => {
                        // console.log("e", e)
                        // console.log("index", index)
                        if (e?.approval.note === "") {
                            form.setFieldsValue({
                                [`minPrice${index}`]: e?.approval?.minPrice ? e?.approval?.minPrice : 0,
                                [`maxPrice${index}`]: e?.approval?.maxPrice ? e?.approval?.maxPrice : 0,
                                [`note${index}`]: null,
                            })
                        } else {
                            form.setFieldsValue({
                                [`minPrice${index}`]: e?.approval?.minPrice ? e?.approval?.minPrice : 0,
                                [`maxPrice${index}`]: e?.approval?.maxPrice ? e?.approval?.maxPrice : 0,
                                [`note${index}`]: e?.approval?.note,
                            })
                        }

                    })
                    // form.setFieldsValue({
                    //     minPrice: newdataLand?.approval?.minPrice,
                    //     maxPrice: result?.approval?.maxPrice,
                    //     note: result?.approval?.note,
                    // })
                }
            } else if (appStatus === "ปัดตก") {
                setstatusAccept({
                    customerId: dataModalEdit.customerId,
                    landId: dataModalEdit.landId,
                    loanId: dataModalEdit.landLoanId,
                    approvalStatus: "ปัดตก",
                    note: statusAccept.note
                })
                setDataModalEdit({
                    ...dataModalEdit,
                    approvalStatus: "ปัดตก",
                    approvalDate: formattedTime,
                    inputPriceDate: formattedTime,
                    priceStatus: false,
                    minPrice: 0,
                    maxPrice: 0,
                })
            } else if (appStatus === "ขอเอกสารเพิ่ม") {
                setstatusAccept({
                    customerId: dataModalEdit.customerId,
                    landId: dataModalEdit.landId,
                    loanId: dataModalEdit.landLoanId,
                    approvalStatus: "ขอเอกสารเพิ่ม",
                    note: statusAccept.note
                })
                setDataModalEdit({
                    ...dataModalEdit,
                    approvalStatus: "ขอเอกสารเพิ่ม",
                    approvalDate: formattedTime,
                    inputPriceDate: formattedTime,
                    priceStatus: false,
                    minPrice: 0,
                    maxPrice: 0,
                })
            }
        } else {
            if (appStatus === "อนุมัติ") {
                if (result === null) {
                    setstatusAccept({
                        customerId: dataModalEdit.customerId,
                        landId: dataModalEdit.landId,
                        landLoanId: dataModalEdit.landLoanId,
                        approvalStatus: "อนุมัติ",
                        note: statusAccept.note
                    })
                    setDataModalEdit({
                        ...dataModalEdit,
                        approvalStatus: "อนุมัติ",
                        approvalDate: formattedTime,
                        inputPriceDate: formattedTime,
                        priceStatus: true,
                    })
                } else {
                    setstatusAccept({
                        customerId: dataModalEdit.customerId,
                        landId: dataModalEdit.landId,
                        landLoanId: dataModalEdit.landLoanId,
                        approvalStatus: "อนุมัติ",
                        note: statusAccept.note
                    })
                    setDataModalEdit({
                        ...dataModalEdit,
                        approvalStatus: "อนุมัติ",
                        approvalDate: formattedTime,
                        inputPriceDate: formattedTime,
                        priceStatus: true,
                        minPrice: parseInt(result?.approval?.minPrice),
                        maxPrice: parseInt(result?.approval?.maxPrice),
                        note: result?.approval?.note,
                    })
                    newdataLand.map((e, index) => {
                        // console.log("e", e)
                        // console.log("index", index)
                        if (e?.approval.note === "") {
                            form.setFieldsValue({
                                [`minPrice${index}`]: e?.approval?.minPrice ? e?.approval?.minPrice : 0,
                                [`maxPrice${index}`]: e?.approval?.maxPrice ? e?.approval?.maxPrice : 0,
                                [`note${index}`]: null,
                            })
                        } else {
                            form.setFieldsValue({
                                [`minPrice${index}`]: e?.approval?.minPrice ? e?.approval?.minPrice : 0,
                                [`maxPrice${index}`]: e?.approval?.maxPrice ? e?.approval?.maxPrice : 0,
                                [`note${index}`]: e?.approval?.note,
                            })
                        }

                    })
                    // form.setFieldsValue({
                    //     minPrice: newdataLand?.approval?.minPrice,
                    //     maxPrice: result?.approval?.maxPrice,
                    //     note: result?.approval?.note,
                    // })
                }
            } else if (appStatus === "ปัดตก") {
                setstatusAccept({
                    customerId: dataModalEdit.customerId,
                    landId: dataModalEdit.landId,
                    loanId: dataModalEdit.landLoanId,
                    approvalStatus: "ปัดตก",
                    note: statusAccept.note
                })
                setDataModalEdit({
                    ...dataModalEdit,
                    approvalStatus: "ปัดตก",
                    approvalDate: formattedTime,
                    inputPriceDate: formattedTime,
                    priceStatus: false,
                    minPrice: 0,
                    maxPrice: 0,
                })
            } else if (appStatus === "ขอเอกสารเพิ่ม") {
                setstatusAccept({
                    customerId: dataModalEdit.customerId,
                    landId: dataModalEdit.landId,
                    loanId: dataModalEdit.landLoanId,
                    approvalStatus: "ขอเอกสารเพิ่ม",
                    note: statusAccept.note
                })
                setDataModalEdit({
                    ...dataModalEdit,
                    approvalStatus: "ขอเอกสารเพิ่ม",
                    approvalDate: formattedTime,
                    inputPriceDate: formattedTime,
                    priceStatus: false,
                    minPrice: 0,
                    maxPrice: 0,
                })
            }
        }
    }

    const loadNoteName = async () => {
        await axios.get(shownotepn)
            .then(res => {
                if (res.status === 200) {
                    // console.log("loadNoteName", res.data)
                    setOptions(res.data)
                }
            }).catch((err) => console.log(err))
    };

    const loadNoteNameRe = async () => {
        await axios.get(shownotepnre)
            .then(res => {
                if (res.status === 200) {
                    // console.log("loadNoteNameRe", res.data)
                    setOptions(res.data)
                }
            }).catch((err) => console.log(err))
    };

    const handleMixNote = (value) => {
        setMultiModeData(prevData => prevData.map((item, index) => ({
            ...item,
            approval: {
                ...item.approval,
                mixNote: value.target.value
            }
        })));
    }

    const handleSetUp = () => {
        setMultiModeData(prevData => prevData.map((item, index) => ({
            ...item,
            approval: {
                ...item.approval,
                id: item?.approval?.id,
                customerId: dataModalEdit?.customerId,
                landId: item?.landId,
                landLoanId: dataModalEdit?.landLoanId,
                priceStatus: true,
                proposalPriceBy: us,
                approvalDate: dayjs(),
                inputPriceDate: dayjs()
            }
        })));
    }

    const handleCancel = () => {
        close(false)
    }
    const handleCancel2 = () => {
        close2(false)
    }
    const handleOk = async () => {
        // const result = { ...dataModalEdit, proposalPriceBy: us }
        console.log("new =", { landLoanId: dataFromTable.landLoanId, findLandPN: multiModeData })
        var checkNumber = 0
        multiModeData.map((e, index) => {
            if (e.approval.minPrice > e.approval.maxPrice) {
                checkNumber = 1
            }
        })
        if (checkNumber === 1) {
            alert("กรุณาตรวจสอบยอดที่อนุมัติอีกครั้ง")
        } else {
            console.log("yes")
            newDataPn(dataModalEdit)
            await axios.post(approvalPriceNew, { landLoanId: dataFromTable.landLoanId, findLandPN: multiModeData }) // แบบใหม่ ส่งเป็น array
                .then(res => {
                    if (res.status === 200) {
                        message.success('บันทึกสำเร็จ');
                        close(false);
                    } else if (res.status === 204) {
                        // NotiErrorIMG("top")
                    }
                }).catch(err => {
                    console.log(err)
                })
            // } else {
            //     alert("กรุณาตรวจสอบยอดที่อนุมัติอีกครั้ง")
            // }
        }

    }

    const handleOkRe = async () => {
        // var NoteData = { ...guarantor.data, cause, ...statusAccept, noteBy: user, rejectStatus: false, noteDate: dayjs().format('YYYY-MM-DDTHH:mm:ssZ'), checkNote: "0" }
        // const result = { ...dataModalEdit, proposalPriceBy: us }
        const result = {
            approvalStatus: dataModalEdit.approvalStatus,
            carId: dataModalEdit.landId,
            cause: dataModalEdit.note,
            checkNote: "0",
            loanId: dataModalEdit.landLoanId,
            noteBy: us,
            noteDate: dayjs().format('YYYY-MM-DDTHH:mm:ssZ'),
            rejectStatus: false,
            status: "ขอเอกสารเพิ่มเติม",
            typeLoan: "land"
        }
        // console.log("result =", result)

        await axios.put(approve, result)
            .then(res => {
                if (res.status === 200) {
                    message.success('ขอเอกสารเพิ่มเติมสำเร็จ');
                    setTimeout(() => {
                        close(false);
                    }, 1500);
                }
            }).catch((err) => console.log(err))
    }

    const sendReject = async () => {
        var rejectData = {
            approvalStatus: dataModalEdit.approvalStatus,
            carId: dataModalEdit.landId,
            cause: dataModalEdit.note,
            loanId: dataModalEdit.landLoanId,
            note: dataModalEdit.note,
            noteBy: us,
            rejectStatus: true,
            status: "ปัดตก",
            noteDate: dayjs().format('YYYY-MM-DDTHH:mm:ssZ'),
            rejectDate: dayjs().format('YYYY-MM-DDTHH:mm:ssZ'),
            typeLoan: "land"
        }
        await axios.post(reject, rejectData)
            .then(res => {
                if (res.status === 200) {
                    message.success('ปัดตกสำเร็จ');
                    setTimeout(() => {
                        close(false);
                    }, 1500);
                }
            }).catch((err) => console.log(err))
    };

    const confirmSubmit = () => {
        console.log("11")
        Modal.confirm({
            title: 'บันทึกข้อมูล',
            content: 'คุณต้องการบันทึกข้อมูลใช่หรือไม่ ?',
            onOk() {
                if (appStatus === "อนุมัติ") {
                    // console.log("1")
                    handleOk()
                }
                else if (appStatus === "ปัดตก") {
                    // console.log("2")
                    sendReject()
                } else if (appStatus === "ขอเอกสารเพิ่ม") {
                    handleOkRe()
                    // console.log("3")
                }

            },
            onCancel() {
                message.error('ยกเลิก');
            },
        });
    };

    const handleChange = (value, index) => {
        setNote(`${value}`)
        setDataModalEdit({ ...dataModalEdit, note: `${value}` })
    }

    const handleNoteMultimode = (value, index) => {
        // console.log("handleNoteMultimode", `${value}`)
        // console.log("index", index)
        if (multiMode) {
            setMultiModeData(prevData => prevData.map((item, i) => i === index ? { ...item, approval: { ...item.approval, note: `${value}` } } : item));
        } else {
            setNote(`${value}`)
            setDataModalEdit({ ...dataModalEdit, note: `${value}` })
            setMultiModeData(prevData => prevData.map((item, i) => i === index ? { ...item, approval: { ...item.approval, note: `${value}` } } : item));
        }
    }

    const renderLandData = ({ item, index }) => {
        return (
            <>
                <Divider orientation='left'>เลขโฉนดที่ดิน ({item.numberLand})</Divider>
                <Col className='gutter-row' span={24} style={{ textAlign: 'center' }}>
                    <Form.Item label="หมายเหตุ" name={`note${index}`}>
                        <Select
                            mode="multiple"
                            style={{
                                width: '100%',
                            }}
                            size="large"
                            placeholder="เลือกหมายเหตุ"
                            onChange={(e) => handleNoteMultimode(e, index)}
                            options={options}
                        />
                    </Form.Item>
                </Col>
                <Col className='gutter-row' span={12} style={{ textAlign: 'center' }}>
                    <Form.Item label='ยอดขั้นต่ำ' name={`minPrice${index}`}
                        rules={[
                            {
                                required: true,
                                message: 'กรุณากรอกยอดที่อนุมัติขั้นต่ำ'
                            },
                        ]}>
                        <InputNumber
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            size="large"
                            style={{ width: '100%', color: 'black' }}
                            // onChange={(value) => setMultiModeData({ ...multiModeData, [index]: { ...multiModeData[index], approval: { ...multiModeData[index].approval, minPrice: parseFloat(value) } } })}
                            onChange={(value) => {
                                setMultiModeData(prevData => prevData.map((item, i) => i === index ? { ...item, approval: { ...item.approval, minPrice: parseFloat(value) } } : item));
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col className='gutter-row' span={12} style={{ textAlign: 'center' }}>
                    <Form.Item label='ยอดสูงสุด' name={`maxPrice${index}`}
                        rules={[
                            {
                                required: true,
                                message: 'กรุณากรอกยอดที่อนุมัติสูงสุด'
                            },
                        ]}>
                        <InputNumber
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            size="large"
                            style={{ width: '100%', color: 'black' }}
                            // onChange={(value) => setMultiModeData({ ...multiModeData, [index]: { ...multiModeData[index], approval: { ...multiModeData[index].approval, maxPrice: parseFloat(value) } } })}
                            onChange={(value) => {
                                setMultiModeData(prevData => prevData.map((item, i) => i === index ? { ...item, approval: { ...item.approval, maxPrice: parseFloat(value) } } : item));
                            }}
                        />
                    </Form.Item>
                </Col>
                <Divider />
            </>
        )
    }

    return (
        <div>
            <Modal title="กรุณายืนยันข้อมูล" open={open} onCancel={handleCancel2} footer={[null]} width={800}>
                <Card>
                    <Form
                        onFinish={confirmSubmit}
                        form={form}
                        labelCol={{
                            span: 6,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}>
                        <Row>
                            <Col span={24}>
                                <Form.Item>
                                    <b><u>{appStatus}</u></b>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                {/* {
                                    appStatus === "อนุมัติ" ?
                                        <Form.Item label="หมายเหตุ" name="note">
                                            <Select
                                                mode="multiple"
                                                style={{
                                                    width: '100%',
                                                }}
                                                size="large"
                                                placeholder="เลือกหมายเหตุ"
                                                onChange={handleChange}
                                                options={options}
                                            />
                                        </Form.Item>
                                        : null
                                } */}
                                {
                                    appStatus === "ขอเอกสารเพิ่ม" ?
                                        <Form.Item label="หมายเหตุ" name="note">
                                            <Select
                                                mode="multiple"
                                                style={{
                                                    width: '100%',
                                                }}
                                                size="large"
                                                placeholder="เลือกหมายเหตุ"
                                                onChange={handleChange}
                                                options={options}
                                            />
                                        </Form.Item>
                                        : null
                                }

                                {
                                    appStatus === "ปัดตก" ?
                                        <Form.Item label='หมายเหตุ' name='note'>
                                            <TextArea rows={4} onChange={(e) => setDataModalEdit({ ...dataModalEdit, note: e.target.value })} />
                                        </Form.Item>
                                        : null
                                }
                                {
                                    appStatus === "อนุมัติ" ?
                                        <>
                                            {
                                                multiMode ?
                                                    <Row gutter={32} justify={'center'}>
                                                        {
                                                            newdataLand?.map((item, index) => {
                                                                return renderLandData({ item, index })
                                                            })
                                                        }
                                                        <Col span={24}>
                                                            <Form.Item label='หมายเหตุรวม' name='mixNote'>
                                                                <TextArea name='mixNote' onChange={(e) => handleMixNote(e)}></TextArea>
                                                            </Form.Item>

                                                        </Col>
                                                    </Row>
                                                    :
                                                    <Row gutter={32} justify={'center'}>
                                                        {
                                                            newdataLand?.map((item, index) => {
                                                                return renderLandData({ item, index })
                                                            })
                                                        }
                                                    </Row>
                                                // <Row gutter={32}>
                                                //     <Col className='gutter-row' span={12} style={{ textAlign: 'center' }}>
                                                //         <Form.Item
                                                //             label='ยอดขั้นต่ำ'
                                                //             name='minPrice'
                                                //             rules={[
                                                //                 {
                                                //                     required: true,
                                                //                     message: 'กรุณากรอกยอดที่อนุมัติขั้นต่ำ'
                                                //                 },
                                                //             ]}
                                                //         >
                                                //             <InputNumber
                                                //                 formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                //                 parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                //                 size="large"
                                                //                 style={{ width: '100%', color: 'black' }}
                                                //                 onChange={(value) => setDataModalEdit({ ...dataModalEdit, minPrice: parseFloat(value) })}
                                                //             />
                                                //         </Form.Item>
                                                //     </Col>
                                                //     <Col className='gutter-row' span={12} style={{ textAlign: 'center' }}>
                                                //         <Form.Item
                                                //             label='ยอดสูงสุด'
                                                //             name='maxPrice'
                                                //             rules={[
                                                //                 {
                                                //                     required: true,
                                                //                     message: 'กรุณากรอกยอดที่อนุมัติสูงสุด'
                                                //                 },
                                                //             ]}
                                                //         >
                                                //             <InputNumber
                                                //                 formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                //                 parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                //                 size="large"
                                                //                 style={{ width: '100%', color: 'black' }}
                                                //                 onChange={(value) => setDataModalEdit({ ...dataModalEdit, maxPrice: parseFloat(value) })}
                                                //             />
                                                //         </Form.Item>
                                                //     </Col>
                                                // </Row>
                                            }
                                        </>
                                        : null
                                }
                            </Col>
                        </Row>
                        <Divider />
                        <Row>
                            <Col span={24} >
                                <div style={{ textAlign: 'center' }}>
                                    <Space>
                                        <Button key="back" type='primary' danger onClick={handleCancel2}>ปิด</Button>
                                        <Button htmlType='submit' type='primary' style={{ backgroundColor: "Green" }}>บันทึก</Button>
                                        {/* <Button type='primary' style={{ backgroundColor: "Green" }} onClick={confirm}>บันทึก</Button> */}
                                    </Space>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </Modal>
            {contextHolder}
        </div>
    )
}
