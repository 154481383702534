import { createSlice } from "@reduxjs/toolkit";

const initialState = {

}

const carDataSlice = createSlice ({
    name: "car",
    initialState,
    reducers: {
        
        addCar: (state, action) => {
            state.data = action.payload
            // console.log(current(state))
        },
        
    }
    
})

export const { addCar } = carDataSlice.actions
export default carDataSlice.reducer