import React, { useState, useEffect } from 'react'
import { Modal, message, Form, Button, Card, Divider, Space, Row, Col, Spin } from 'antd'
import axios from 'axios';
import { cancelTime } from '../../file_mid/all_api';

export default function Cancel({ open, dataFromTable, close }) {
    const [loading, setLoading] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [dataModalEdit, setDataModalEdit] = useState();

    useEffect(() => {
        setDataModalEdit(dataFromTable)
    }, [])

    const handleOk = async () => {
        setLoading(true)
        await axios.put(cancelTime, dataModalEdit)
            .then(res => {
                if (res) {
                    //console.log("scc", res)
                    messageApi.open({
                        title: 'success',
                        content: 'บันทึกสำเร็จ',
                    });
                    setTimeout(() => {
                        close(false);
                    }, 1500)
                }
            }).catch((err) => console.log(err))
        setLoading(false)
    };
    const handleCancel = () => {
        close(false);
    };
    return (
        <div>
            <Modal title="กรุณายืนยันข้อมูล (ยกเลิกวันหยุด)" open={open} onCancel={handleCancel}
                footer={[null]}>
                <Card>
                    <Spin spinning={loading} size='large' tip=" Loading... ">
                        <Divider orientation="center"><b>รายละเอียด</b></Divider>
                        <Form
                            name="basic"
                            labelCol={{
                                span: 12,
                            }}
                            wrapperCol={{
                                span: 24,
                            }}
                            onFinish={handleOk}
                        >
                            <Row gutter={32}>
                                <Col span={24} >
                                    <Form.Item label='ทีม' name='team' style={{ marginBottom: 3, fontSize: '20px' }}>
                                        <b>{dataModalEdit?.team}</b>
                                    </Form.Item>
                                    <Form.Item label='รหัสธุรการ' name='name' style={{ marginBottom: 3, fontSize: '20px' }}>
                                        <b>{dataModalEdit?.name}</b>
                                    </Form.Item>
                                    <Form.Item label='ชื่อธุรการ' name='nickname' style={{ marginBottom: 3, fontSize: '20px' }}>
                                        <b>{dataModalEdit?.nickname}</b>
                                    </Form.Item>
                                    {/* <Form.Item label='สถานะ' name='leaveStatus' style={{ marginBottom: 3 }}>
                                {
                                    dataModalEdit?.leaveStatus === "no leave" ?
                                        <>
                                            <span>สถานะ : </span> <b>{"รับงาน"}</b>
                                        </> :
                                        <>
                                            <span>สถานะ : </span> <b>{"หยุดรับงาน"}</b>
                                        </>
                                }
                            </Form.Item> */}
                                    <Form.Item label='วันที่หยุดรับงาน' name='stopDay' style={{ marginBottom: 3, fontSize: '20px' }}>
                                        <b>{dataModalEdit?.stopDay}</b>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Divider />
                                <Col span={24} style={{ textAlign: "center" }}>
                                    <Space>
                                        <Button key="back" type='primary' danger onClick={handleCancel}>ปิด</Button>
                                        <Button type='primary' htmlType='submit' disabled={loading} style={{ backgroundColor: "Green" }}>ยืนยัน</Button>
                                    </Space>
                                </Col>
                            </Row>
                        </Form>
                    </Spin>
                </Card>
            </Modal>
            {contextHolder}
        </div>
    )
}
