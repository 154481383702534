import React, { useState, useEffect } from 'react'
import { Modal, Form, Button, Tag, Card, Space, Select, notification, Row, Col, Spin } from 'antd'
import axios from 'axios';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';
import { addCustomer } from '../../../redux/Customer';
import { addCar } from '../../../redux/Car';
import { addAddress } from '../../../redux/Address';
import { addPhone } from '../../../redux/Phone';
import { addGuarantor } from '../../../redux/Guarantor';
import { rockingWorkCar, rockingWorkLand, selectcase } from '../../file_mid/all_api';

export default function Confirm({ open, close, shootdata, typeData, closeAll }) {
    const token = localStorage.getItem('token')
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const [options, setOptions] = useState([]);
    const [formAdd] = Form.useForm()
    const [api, contextHolder] = notification.useNotification();
    const [form] = Form.useForm()
    const [oDataType, setODataType] = useState();
    const [mixData, setMixData] = useState({});

    // console.log("shootdata =", shootdata)
    // console.log("typeData =", typeData)

    useEffect(() => {
        if (typeData?.car === "car") {
            setMixData(shootdata)
        } else {
            setMixData(shootdata)
        }
        setODataType(typeData)
        loadCauseName()
    }, [])

    const loadCauseName = async () => {
        const tk = JSON.parse(token)
        const headers = {
            "Authorization": `Bearer ${tk}`,
            "Menu": JSON.stringify("5")
        }
        //await axios.get('http://localhost:8080/AuthCase/select-case', { headers: headers })
        await axios.get(selectcase, { headers: headers })
            .then(res => {
                if (res.status === 200) {
                    setOptions(res.data)
                }
            }).catch((err) => console.log(err))
    };
    const handleCancel = () => {
        close(false);
    };
    const handleOk = async () => {
        setLoading(true)
        //console.log("mixData", mixData)
        if (oDataType?.car === "car") {
            await axios.put(rockingWorkCar, mixData)
                //await axios.put('http://localhost:8070/ApiCase/rockingWorkCar', mixData)
                .then((res) => {
                    console.log("OK Car")
                    success()
                })
                .catch((err) => console.log(err))
        } else {
            await axios.put(rockingWorkLand, mixData)
                //await axios.put('http://localhost:8070/ApiCase/rockingWorkLand', mixData)
                .then((res) => {
                    console.log("OK Land")
                    success()
                })
                .catch((err) => console.log(err))
        }
        setLoading(false)
    };

    const success = () => {
        Modal.success({
            title: 'บันทึกรายการสำเร็จ',
            onOk: () => {
                dispatch(addCustomer())
                dispatch(addCar())
                dispatch(addAddress())
                dispatch(addPhone())
                dispatch(addGuarantor())
                close(false);
                closeAll(false);
                setLoading(false)
            }
        })
    }
    const handleChangeType = (value) => {
        //console.log("value-case", value)
        setMixData({ ...mixData, productTypeCase: value })
    }
    const handleChange = (value) => {
        //console.log("value-name", value)
        setMixData({ ...mixData, case: value })
    }
    return (
        <div>
            <Modal title="กรุณายืนยันข้อมูล" open={open} onCancel={handleCancel} footer={[null]} width={800}>
                <Card>
                    <Spin spinning={loading} size='large' tip=" Loading... ">
                        <Form
                            onFinish={handleOk}
                            form={form}
                            labelCol={{
                                span: 6,
                            }}
                            wrapperCol={{
                                span: 16,
                            }}>
                            <Row>
                                <Col span={24}>
                                    <Form.Item>
                                        <b><u>โยกงาน-ฝากงาน</u></b>
                                    </Form.Item>
                                    {
                                        oDataType?.car === "car" ?
                                            <>
                                                <Form.Item>
                                                    <b>ธุรการที่ได้รับเคสนี้ : <Tag color={"green"}>{mixData?.carLoanDetails?.reviewedBy}</Tag></b>
                                                </Form.Item>
                                            </>
                                            :
                                            <>
                                                <Form.Item>
                                                    <b>ธุรการที่ได้รับเคสนี้ : <Tag color={"green"}>{mixData?.landLoanDetails?.reviewedBy}</Tag></b>
                                                </Form.Item>
                                            </>
                                    }
                                </Col>
                                <Col span={22}>

                                    <Form.Item label='ประเภท' name='productTypeCase'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'กรุณาเลือกประเภท !',
                                            },]}>
                                        <Select
                                            placeholder={'เลือก'}
                                            onChange={handleChangeType}
                                            options={[
                                                {
                                                    label: 'โยกงาน',
                                                    value: 'โยกงาน',
                                                },
                                                {
                                                    label: 'ฝากงาน',
                                                    value: 'ฝากงาน',
                                                },
                                            ]}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label="รายชื่อธุรการ"
                                        name="case"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'กรุณากรอกสาเหตุ !'
                                            },]}>
                                        <Select
                                            //mode="multiple"
                                            style={{
                                                width: '100%',
                                            }}
                                            size="large"
                                            placeholder="เลือก"
                                            onChange={handleChange}
                                            options={options}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} >
                                    <div style={{ textAlign: 'center' }}>
                                        {
                                            loading === false ?
                                                <>
                                                    <Space>
                                                        <Button key="back" type='primary' danger onClick={handleCancel}>ปิด</Button>
                                                        <Button type='primary' htmlType='submit' style={{ backgroundColor: "Green" }}>ยืนยัน</Button>
                                                    </Space>
                                                </>
                                                :
                                                <>
                                                    <Space>
                                                        <Button key="back" type='primary' disabled >ปิด</Button>
                                                        <Button type='primary' style={{ backgroundColor: "Green" }} disabled >ยืนยัน</Button>
                                                    </Space>
                                                </>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </Spin>
                </Card>
            </Modal>
            {contextHolder}
        </div>
    )
}
