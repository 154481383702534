import React from 'react';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Divider, Row, Col, Card, Modal, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addPhone } from '../../../../redux/Phone';

import AddPED from './Phone/AddPED';
import EditPED from './Phone/EditPED';
import { deletephone2 } from '../../../file_mid/all_api';

export default function EditPhoneGua({ close, next, prev, chp, theData }) {
    const Phones = useSelector(state => state.Phones)
    const dispatch = useDispatch()
    const [form] = Form.useForm();
    const [index1, setIndex] = useState(); //set index send to page edit
    const [prePhone] = useState(Phones.data);
    const [dataeditP, setDataEditP] = useState([]); //เซตสำหรับแก้ไขข้อมูล Phone
    const [savePhone, setSavePhone] = useState([]); // เก็บ Telp
    const [countSave, setCountSave] = useState(false);
    const { confirm } = Modal

    // Open Modals
    const [modalAddPhone, setModalAddPhone] = useState(false);
    const [modalEditPhone, setModalEditPhone] = useState(false);

    useEffect(() => {
        if (prePhone !== undefined) {
            setSavePhone(Phones.data)
        } else if (theData.phones !== undefined) {
            setSavePhone(theData.phones)
        } else if (theData.phones !== undefined) {
            setSavePhone(theData.phones)
        }
    }, [])

    useEffect(() => {
        if (countSave === true) {
            handleSubmit()
        }
    }, [modalAddPhone, modalEditPhone, savePhone])

    const showConfirm = (value, index) => {
        confirm({
            title: 'คุณต้องการที่จะลบข้อมูลหรือไม่...?',
            content: 'กด OK เพื่อยืนยันการลบ',
            onOk() {
                const deletearray = [...savePhone]
                deletearray.splice(index, 1)
                setSavePhone(deletearray)
                setCountSave(true)
                if (value.phoneId !== 0) {
                    deleteDataPhone(value)
                } else {
                    dispatch(addPhone(savePhone))
                }
            },
            onCancel() {
            },
        });
    }

    const deleteDataPhone = async (value) => {
        const test2 = value
        await axios.delete(deletephone2, { data: test2, })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(addPhone(savePhone))
                }
            }).catch((err) => console.log(err))
    }

    const handleSubmit = () => {
        if (savePhone.length === 0 || savePhone.length === undefined) {
            chp("top")
        } else {
            dispatch(addPhone(savePhone))
        }
    }

    const handleCancel = () => {
        close(false)
    }

    const onChangeBack = () => {
        dispatch(addPhone(savePhone))
        prev()
    }

    const onChangeGo = () => {
        dispatch(addPhone(savePhone))
        next()
    }

    const fucnAddPhone = (value) => {
        if (savePhone.length === 0) {
            setSavePhone(pre => [...pre, { ...value.dataPhone }]);
        } else {
            setSavePhone(pre => [...pre, { ...value.dataPhone }]);
        }
        dispatch(addPhone(savePhone))
    }

    // แก้ไขข้อมูลจากหน้า AddressEditData
    const fucnEditPhone = ({ getData, getIndex }) => {
        let Theedit = [...savePhone]
        Theedit[getIndex] = getData
        //ข้อมูลใหม่แทนที่ข้อมูลเดิมในช่อง Input
        setSavePhone(Theedit);
        dispatch(addPhone(savePhone))
    }

    const renderPhone = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
        // index ตอนนี้เป็นข้อมูลจาก Object
        const handleEditPhone = (dataedit, index1) => {
            setIndex(index1) //เซตค่า index ให้กับชุด ข้อมูล
            setDataEditP({ dataedit, index1 }) //ส่งข้อมูลด index ไปหน้าแก้ไข
            setModalEditPhone(true);
            //console.log("edit")
        }
        return (
            <Form form={form}>
                <Row style={{ margin: '3px' }} justify={'center'} >
                    <Card
                        key={index} // index เป็นตัวกำหนดคีย์
                        style={{ textAlign: 'center' }}
                        title={<u>{item.phoneType}</u>}
                    >
                        {/* <div > */}
                        <Col span={24} style={{ textAlign: 'left' }}>
                            <Form.Item style={{ marginBottom: 0 }} label='เบอร์'>
                                {item.telp}
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='sendSms'>
                                {
                                    item.sendSms === "0" && ("ไม่ส่ง")
                                }
                                {
                                    item.sendSms === "1" && ("ส่ง")
                                }
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label="หมายเหตุ">
                                {item.note}
                            </Form.Item>
                            <Divider></Divider>
                            <Row justify={'center'} gutter={{ sx: 8, sm: 16, md: 24, lg: 32 }}>
                                <Form.Item>
                                    {/* ปุ่มลบข้อมูล */}
                                    <Button
                                        style={{ margin: '10px', background: '#f5222d', color: 'white', marginBottom: 0 }}
                                        icon={<DeleteOutlined />}
                                        // onClick={() => { deleteData(savePhone[index]) }}
                                        onClick={() => showConfirm(savePhone[index], index)}
                                    >ลบ</Button>
                                </Form.Item>
                                <Form.Item>
                                    {/* ปุ่มแก้ไขข้อมูล */}
                                    <Button
                                        style={{ margin: '10px', background: '#bfbfbf', color: '#ffffff', marginBottom: 0 }}
                                        type='submit'
                                        onClick={() => { handleEditPhone(savePhone[index], index) }}
                                    >แก้ไข</Button>
                                </Form.Item>
                            </Row>
                        </Col>
                        {/* </div> */}
                    </Card>
                </Row>
            </Form>
        )
    }

    return (
        <>
            <Card style={{ color: '#4096ff' }}>
                <Form>
                    <Row>
                        <Col className='gutter-row' span={24}>
                            {/* <Row> */}
                            <Col span={24} style={{ fontSize: '30px', textAlign: 'center' }} >ช่องทางการติดต่อ</Col>
                            {/* </Row> */}
                            <Divider />
                            <Row gutter={32} >
                                <Col span={24} style={{ textAlign: 'right' }}>
                                    {/* ปุ่มเพิ่มข้อมูล */}

                                    <Button type='primary' onClick={() => { setModalAddPhone(true); }}> <PlusOutlined />เบอร์ติดต่อ</Button>
                                </Col>
                            </Row>
                            <Row justify={'center'} >
                                {
                                    savePhone.map((item, index) => {
                                        return renderPhone({ item, index, key: `{item.phones} - ${index}` })
                                    })
                                }
                            </Row>

                        </Col>
                    </Row>
                    <Divider />
                    <div style={{ textAlign: "center" }} >
                        <Space>
                            <Button type="primary" style={{ background: '#bfbfbf', color: '#000000', }} onClick={onChangeBack}>ย้อนกลับ</Button>
                            <Button type="primary" onClick={handleCancel} style={{ background: "red" }}>ยกเลิก</Button>
                            <Button type="primary" onClick={onChangeGo}> ต่อไป</Button>
                        </Space>
                    </div>
                </Form>
            </Card>
            {
                //เพื่มข้อมูลในการ์ด
                modalAddPhone ?
                    <AddPED
                        open={modalAddPhone}
                        close={setModalAddPhone}
                        fucnAdd={fucnAddPhone}
                        cusId={theData.customerId}
                        setCountSave={setCountSave}
                    />
                    : null
            }
            {
                //แก้ไขข้อมูลในการ์ด
                modalEditPhone ?
                    <EditPED
                        open={modalEditPhone} // เซตการเปิด Modal จากหน้า AddressEditData
                        close={setModalEditPhone} // เซตการปิด Modal จากหน้า AddressEditData
                        shootdata={dataeditP} // 
                        fucnEdit={fucnEditPhone}
                        dataindex={index1}
                        setCountSave={setCountSave}
                    />
                    : null
            }
        </>
    )

};
