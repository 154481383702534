import React, { useState, useEffect } from "react";
import DataColumn from "../Data/DataColumns";
import "../css/table.css";
import { Table, Flex, Tag } from "antd";

const TabB = ({ dataApiCheckLocatLand }) => {
  // เก็บข้อมูล ApiCheckLocatLand รวม
  const [sumLand, setSumLand] = useState({
    caseSuccess: 0,
    caseFail: 0,
    caseReject: 0,
    casePre: 0,
    caseAll: 0,
  });
  // คำนวณผลรวมเมื่อข้อมูล dataApiCheckLocatLand เปลี่ยนแปลง
  useEffect(() => {
    if (dataApiCheckLocatLand && dataApiCheckLocatLand.length > 0) {
      const summary = dataApiCheckLocatLand.reduce(
        (acc, currentValue) => {
          acc.caseSuccess += currentValue.caseSuccess;
          acc.caseFail += currentValue.caseFail;
          acc.caseReject += currentValue.caseReject;
          acc.casePre += currentValue.casePre;
          acc.caseAll += currentValue.caseAll;
          return acc;
        },
        { caseSuccess: 0, caseFail: 0, caseReject: 0, casePre: 0, caseAll: 0 }
      );
      setSumLand(summary);
    }
  }, [dataApiCheckLocatLand]);

  return (
    <>
      <Flex gap="4px 0" wrap style={{ marginBottom: "10px" }}>
        <Tag color="magenta" className="TagA1">
          รวมส่งมาเคาะทั้งหมด : {sumLand.caseAll}
        </Tag>
        <Tag color="green" className="TagA1">
          รวมเคสผ่าน : {sumLand.caseSuccess}
        </Tag>
        <Tag color="orange" className="TagA1">
          รวมเคสไม่จบ : {sumLand.caseFail}
        </Tag>
        <Tag color="red" className="TagA1">
          รวมREJECT/ไม่ผ่าน : {sumLand.caseReject}
        </Tag>
        <Tag color="blue" className="TagA1">
          รวมPRE : {sumLand.casePre}
        </Tag>
      </Flex>
      <Table
        dataSource={
          dataApiCheckLocatLand
            ? dataApiCheckLocatLand.map((item, index) => ({
                ...item,
                key: index,
              }))
            : []
        }
        columns={DataColumn}
      />
    </>
  );
};

export default TabB;
