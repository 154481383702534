import DotaColumsPdf from "./dotaColumsPdf";
const dotaColums = [
  {
    title: "ชื่อ-นามสกุล",
    dataIndex: "name",
    key: "name",
    render: (text, record) => (
      <>
        {record.snam}
        {record.firstname} {record.lastname}
      </>
    ),
  },
  {
    title: "สถานะเคส",
    dataIndex: "approvalStatus",
    key: "approvalStatus",
  },
  {
    title: "เลขที่โฉนด",
    dataIndex: "numberLand",
    key: "numberLand",
  },
  {
    title: "อำเภอ",
    dataIndex: "district",
    key: "district",
  },
  {
    title: "จังหวัด",
    dataIndex: "province",
    key: "province",
  },
  {
    title: "เลือก",
    dataIndex: "name",
    key: "name",
    render: (text, record) => (
      <DotaColumsPdf record={record}></DotaColumsPdf>
      
    ),
  },
];
// onClick={() => handleClick(record)}
// const handleClick = (record) => {
//   // ทำสิ่งที่คุณต้องการเมื่อปุ่มถูกคลิก
//   console.log("คุณเลือก: ", record);
// };


export default dotaColums;
