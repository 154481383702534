import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom"
import View_image from './images/View_image';

export default function Main_image() {
    return (
        <Routes>
            <Route path="/" element={<View_image />} />
        </Routes>
    )
}
