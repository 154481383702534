import React,{ useState, useEffect } from 'react';
import THBText from 'thai-baht-text';
import picTop from "./picture/picTop.jpg"; 
//import picLower from "./picture/picLower.jpg"; 
import { Row, Col} from "antd";
import DotaThaiMonths from "./dotaThaiMonths";
import './css/page2.css';
const Page2 = (props) => {
  // เข้าถึงค่า dataRecord ผ่าน props.dataRecord
  const { dataRecord } = props;
  let inputDateTextYear2 = parseInt(dataRecord.inputDateText2.substring(0, 4))+543;
  let inputDateTextMonth2 = dataRecord.inputDateText2.substring(5, 7);
  // สร้างรายการเดือนภาษาไทย
  let thaiMonth2 = DotaThaiMonths[parseInt(inputDateTextMonth2) - 1]; // แปลงเลขเดือนเป็นชื่อเดือนภาษาไทย
  let inputDateTextDay2 = dataRecord.inputDateText2.substring(8, 10);

  // ทำสิ่งที่ต้องการกับ dataRecord ได้ต่อไปจากนี้
  let moneyText = THBText(dataRecord.approvedLoanAmount);
  let nameCus = dataRecord.snam+dataRecord.firstname+" "+dataRecord.lastname;
  const nameF = localStorage.getItem('firstname');
  const nameL = localStorage.getItem('lastname');
  const nameFL = "นางสาว"+nameF+" "+nameL
  console.log("nameFL",nameFL);
  //ตรวจสอบ ตำบล
  const [checkTumB, setcheckTumB] = useState(dataRecord.subdistrict);
  useEffect(() => {
    if (!dataRecord.subdistrict) {
      setcheckTumB(dataRecord.inputDateText6);
    }
  }, [dataRecord]);
  return (
  <>
    <div style={{ height: "1123px",width: "794px"}}>
    <div style={{ height: "50px"}}></div>
    <img
          src={picTop}
          alt=""
          style={{ width: "794px", height: "100px",display: "flex",
            justifyContent: "center", }}
        />
    <div style={{ padding: '36px'}}>
      <Row>
      <Col span={24} className="Colbold24C">รายงานการประชุม</Col>
      <Col span={24} className="Colbold24C">บริษัท วัน มันนี่ จำกัด</Col>
      <br/><br/><br/><br/>
      <Col span={18} className="Col24">ประชุมครั้งที่ {dataRecord.inputDateText7}/{((new Date().getFullYear())+543)}</Col>
      <Col span={6} className="Col24">เริ่มประชุมเวลา 9.00 น.</Col>

      <Col span={16} className="Colbold24C"></Col>
      <Col span={8} className="Colbold24C">วันที่ {inputDateTextDay2} {thaiMonth2} {inputDateTextYear2}</Col>

      <Col span={24} className="Colbold24L">คณะกรรมการที่เข้าร่วมการประชุม</Col>

      <Col span={2} className="Col22"></Col>
      <Col span={1} className="Col22">1.</Col>
      <Col span={6} className="Col22">นายทัชชล ลีศิริกุล</Col>
      <Col span={2} className="Col22"></Col>
      <Col span={12} className="Col22">ประธาน</Col>

      <Col span={2} className="Col22"></Col>
      <Col span={1} className="Col22">2.</Col>
      <Col span={6} className="Col22">นางสาวรัมภา อรุณเดชาชัย</Col>
      <Col span={2} className="Col22"></Col>
      <Col span={12} className="Col22">กรรมการ</Col>
      <br/><br/>
      <Col span={2} className="Col22"></Col>
      <Col span={2} className="Col22">วาระที่1.</Col>
      <Col span={20} className="Col22">เรื่องที่ประธานแจ้งให้ที่ประชุมทราบ</Col>

      <Col span={2} className="Col22"></Col>
      <Col span={2} className="Col22" style={{lineHeight: '28px'}}>วาระที่2.</Col>
      <Col span={18}>
      <div className="div22" style={{lineHeight: '28px'}}>
      ประธานเสนอที่ประชุมพิจารณา {dataRecord.inputCase} โฉนดเลขที่ {dataRecord.numberLand} เลขที่ดิน {dataRecord.inputDateText3}&nbsp;
      ตำบล{checkTumB} อำเภอ{dataRecord.district} จังหวัด{dataRecord.province}&nbsp; 
      กรรมสิทธิ์ {nameCus} เพื่อเป็นประกันการกู้ยืมเงินของ {nameCus} ที่ได้กู้ยืมเงินไปจากบริษัท วัน มันนี่ จำกัด
      วงเงินจำนอง {parseFloat(dataRecord.approvedLoanAmount).toLocaleString()} บาท ({moneyText}) ในอัตราดอกเบี้ยร้อยละ 15 ต่อปี
      นำส่งเดือนละครั้งและให้ถือสัญญาจำนองเป็นหลักฐาน การกู้ยืมเงินด้วยหากต้องบังคับจำนอง
      ขายทอดตลาดทรัพย์ที่จำนองเงินไม่พอชำระหนี้ ผู้จำนองยอมใช้ส่วนที่ขาดจนครบถ้วน
      โดยให้ผู้รับจำนองสามารถยึดทรัพย์สินอื่นนอกจากทรัพย์ที่จำนองได้
      </div>
      </Col>
      <Col span={2}></Col>

      <Col span={4} className="Col22"></Col>
      <Col span={3} className="Col22" style={{ fontWeight: 'bold' }}>มติที่ประชุม</Col>
      <Col span={17} className="Col22">พิจารณาแล้ว มีมติเป็นเอกฉันท์ให้รับจำนองที่ดินแปลงดังกล่าวได้</Col>
      <Col span={4} className="Col22"></Col>
      <Col span={20} className="Col22">ปิดการประชุมเวลา 9.30 น.</Col>
      <br/><br/><br/><br/><br/><br/>
      <Col span={2} className="Col22"></Col>
      <Col span={1} className="Col22">ลงชื่อ</Col>
      <Col span={8} className="ColUnderline"></Col>
      <Col span={1} className="Col22"></Col>
      <Col span={2} className="Col22"></Col>
      <Col span={1} className="Col22">ลงชื่อ</Col>
      <Col span={8} className="ColUnderline"></Col>
      <Col span={1} className="Col22"></Col>

      <Col span={2} className="Col22"></Col>
      <Col span={10} className="Col22C">(นายทัชชล ลีศิริกุล)</Col>
      <Col span={2} className="Col22"></Col>
      <Col span={10} className="Col22C">{nameFL}</Col>

      <Col span={2} className="Col22"></Col>
      <Col span={10} className="Col22C">ประธานกรรมการที่ประชุม</Col>
      <Col span={2} className="Col22"></Col>
      <Col span={10} className="Col22C">เลขานุการที่ประชุม</Col>
      </Row>
    </div> 
    </div>
    
   
    {/* <img
          src={picLower}
          alt=""
          style={{ width: "794px",display: "flex",justifyContent: "center", height: "40px",bottom: 0 }}
        />    */}
  </>
  );
}
export default Page2;