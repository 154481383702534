import { createSlice } from "@reduxjs/toolkit";

const initialState = {

}

const socialDataSlice = createSlice({
    name: "phone",
    initialState,
    reducers: {

        addSocial: (state, action) => {
            state.data = action.payload
            // console.log(current(state))
        },

    }

})

export const { addSocial } = socialDataSlice.actions
export default socialDataSlice.reducer