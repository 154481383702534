import { Modal } from 'antd';
import React, { useState } from 'react'
import ModalAddData from './ModalAddData';
import ModalUpImg from './ModalUpImg';

function ModalMainMix({ open, close, funcAdd, dataEdit, dataEditIndex, checkEdit, setCheckEdit, funcEdit, newDataLand, setDataEdit, setDataEditIndex, dataForCheckDup }) {

    const [page, setPage] = useState(2);
    const [dataSendBack, setDataSendBack] = useState();

    // console.log("page", page)
    // console.log("dataSendBack", dataSendBack)
    // console.log("dataEdit", dataEdit)
    // console.log("dataEditIndex", dataEditIndex)
    // console.log("checkEdit", checkEdit)
    // console.log("newDataLand", newDataLand)

    const handleClose = () => {
        setDataEdit()
        setDataEditIndex()
        setCheckEdit(false)
        close(false);
    }

    return (

        <Modal open={open} onCancel={handleClose} footer={[null]}>
            {page === 1 && (<ModalUpImg
                close={handleClose}
                page={page}
                setPage={setPage}
                dataSendBack={dataSendBack}
                setDataSendBack={setDataSendBack}
                dataEdit={dataEdit}
            />)}
            {page === 2 && (<ModalAddData
                close={handleClose}
                page={page}
                setPage={setPage}
                dataSendBack={dataSendBack}
                setDataSendBack={setDataSendBack}
                funcAdd={funcAdd}
                dataEdit={dataEdit}
                dataEditIndex={dataEditIndex}
                checkEdit={checkEdit}
                setCheckEdit={setCheckEdit}
                funcEdit={funcEdit}
                newDataLand={newDataLand}
                dataForCheckDup={dataForCheckDup} 
                />)}
        </Modal>
    )
}

export default ModalMainMix