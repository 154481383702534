import React, { useState, useEffect } from 'react'
import { Modal, Row, Col, Radio, Space, notification, Divider, Card } from 'antd'
import axios from 'axios';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { addCustomer } from '../../../redux/Customer';
import { addCar } from '../../../redux/Car';
import { addAddress } from '../../../redux/Address';
import { addPhone } from '../../../redux/Phone';
import { addGuarantor } from '../../../redux/Guarantor';

import EditCustomerDoc from './EditCustomerDoc';
import Address from './EditAddressDoc';
import EditProductDoc from './EditProductDoc';
import EditAllSocialDoc from './EditAllSocialDoc';
import EditCareerDoc from './EditCareerDoc';
import EditGuarantorDoc from './EditGuarantorDoc';
import EditOtherDoc from './EditOtherDoc';
import ConclusionDoc from './ConclusionDoc';
import { findcarcd, findlandcd } from '../../file_mid/all_api';


function MainEditDoc({ open, close, dataFromTable }) {

    const [dataMix] = useState({ carId: dataFromTable?.carId, customerId: dataFromTable.customerId }); //มาจากรีดัค
    const [api, contextHolder] = notification.useNotification();
    const [getData, setGetData] = useState();
    const [count, setCount] = useState(6)
    const [resetPage, setResetPage] = useState(dayjs())
    const [dataFromTables, setDataFromTables] = useState()
    const [notes, setNotes] = useState([])
    const [notesCon, setNotesCon] = useState()
    const [syncData, setSyncData] = useState()

    const dispatch = useDispatch()

    useEffect(() => {
        setDataFromTables(dataFromTable)
        if (dataFromTable.car === "car") {
            loadDataCar()
        } else {
            loadDataLand()
        }
    }, [resetPage, count])

    useEffect(() => {
        if (dataFromTable.car === "car") {
            if (syncData?.cars?.carOldLoanDetails?.carId === 0) {
                delete syncData?.cars?.carOldLoanDetails
            }
            delete syncData?.cars?.img
            delete syncData?.cars?.carLoanDetails.notes
            delete syncData?.cars?.carLoanDetails.checkbox
        } else {
            if (syncData?.lands?.landOldLoanDetails.landId === 0) {
                delete syncData?.lands?.landrOldLoanDetails
            }
            delete syncData?.lands?.img
            delete syncData?.lands?.landLoanDetails.notes
            delete syncData?.lands?.landLoanDetails.checkbox
        }
    }, [syncData])

    const loadDataCar = async (e) => {
        await axios.post(findcarcd, dataMix)
            .then(res => {
                if (res.status === 200) {
                    // console.log("car", res.data)
                    setGetData(res.data)
                    if (res.data.cars.carLoanDetails.notes.length > 0) {
                        res.data.cars.carLoanDetails.notes.map((e) => {
                            if (e.status === "อนุมัติแบบมีเงื่อนไข") {
                                setNotesCon(e)
                            }
                        })
                        setNotes(res.data.cars.carLoanDetails.notes)
                    }
                    setSyncData(res.data)
                }
            }).catch((err) => {
                console.log("err", err)
            })
    }
    const loadDataLand = async (e) => {
        await axios.post(findlandcd, dataMix)
            .then(res => {
                if (res.status === 200) {
                    // console.log("land", res.data)
                    setGetData(res.data)
                    if (res.data.lands.landLoanDetails.notes.length > 0) {
                        res.data.lands.landLoanDetails.notes.map((e) => {
                            if (e.status === "อนุมัติแบบมีเงื่อนไข") {
                                setNotesCon(e)
                            }
                        })
                        setNotes(res.data.lands.landLoanDetails.notes)
                    }
                    setSyncData(res.data)
                }
            }).catch((err) => {
                console.log("err", err)
            })
    }

    const Onclose = () => {
        close(false);
        dispatch(addCustomer())
        dispatch(addCar())
        dispatch(addAddress())
        dispatch(addPhone())
        dispatch(addGuarantor())
    };


    const NotiSuccess = (placement) => {
        api.success({
            message: <b>แก้ไขข้อมูลสำเร็จ</b>,
            //   description:
            //     'สถานะถูกเปลี่ยนจาก รอธุการรับ เป็น รอพิจารณาแล้ว',
            placement,
        });

    }
    const NotiError = (placement) => {
        api.error({
            message: <b>แก้ไขข้อมูลไม่สำเร็จ</b>,
            //   description:
            //     'สถานะถูกเปลี่ยนจาก รอธุการรับ เป็น รอพิจารณาแล้ว',
            placement,
        });

    }
    const NotiSuccessAdd = (placement) => {
        api.success({
            message: <b>เพิ่มข้อมูลสำเร็จ</b>,
            //   description:
            //     'สถานะถูกเปลี่ยนจาก รอธุการรับ เป็น รอพิจารณาแล้ว',
            placement,
        });
    }
    const NotiErrorAdd = (placement) => {
        api.error({
            message: <b>เพิ่มข้อมูลไม่สำเร็จ</b>,
            //   description:
            //     'สถานะถูกเปลี่ยนจาก รอธุการรับ เป็น รอพิจารณาแล้ว',
            placement,
        });

    }
    const NotiNotFond = (placement) => {
        api.error({
            message: <b>ไม่พบข้อมูลผู้ค้ำประกัน</b>,
            placement,
        });

    }
    const NotiDelete = (placement) => {
        api.success({
            message: <b>ลบข้อมูลสำเร็จ</b>,
            //   description:
            //     'สถานะถูกเปลี่ยนจาก รอธุการรับ เป็น รอพิจารณาแล้ว',
            placement,
        });
    }
    const DeleteError = (placement) => {
        api.error({
            message: <b>ลบข้อมูลไม่สำเร็จ</b>,
            placement,
        });
    }

    const CheckAddressER = (placement) => {
        api.error({
            message: <b>เพิ่มข้อมูลไม่สำเร็จ</b>,
            description: 'กรุณาเพิ่มที่อยู่ อย่างน้อย 1 ที่',
            placement,
        });
    }

    return (
        <div>
            <Modal title="ตรวจสอบ/แก้ไขข้อมูล" open={open} onCancel={Onclose} width={1100} footer={null}>
                <Space
                    direction="vertical"
                    size="middle"
                    style={{
                        display: 'flex',
                    }}>
                    <Row>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <Radio.Group value={count} onChange={(e) => setCount(e.target.value)}>
                                {/* <Radio.Button value={1}>ข้อมูลส่วนตัว</Radio.Button>
                                <Radio.Button value={2}>รายละเอียดที่อยู่</Radio.Button>
                                <Radio.Button value={3}>รายละเอียดสินค้า</Radio.Button>
                                <Radio.Button value={4}>ช่องทางการติดต่อ</Radio.Button>
                                <Radio.Button value={5}>รายได้และอาชีพ</Radio.Button> */}
                                <Radio.Button value={6}>คนค้ำประกัน</Radio.Button>
                                <Radio.Button value={7}>อัพโหลดเอกสารเพิ่มเติม</Radio.Button>
                                <Radio.Button value={8}>รายละเอียด</Radio.Button>
                            </Radio.Group>
                            {
                                dataFromTable.car === "car" ?
                                    <>
                                        {
                                            getData?.cars?.carLoanDetails?.approvalConditions === true ?
                                                <>
                                                    <Divider />
                                                    <Row justify={'left'}><Col span={24} style={{ color: "red" }}><b><u>อนุมัติแบบมีเงื่อนไข!</u></b></Col></Row>
                                                    <Card
                                                        // key={notesConIndex}
                                                        style={{ textAlign: 'left' }}>
                                                        <>
                                                            <div style={{ marginBottom: 0 }}>
                                                                <span>สาเหตุ : </span>
                                                                <b>{notesCon?.cause}</b>
                                                            </div>
                                                            <div style={{ marginBottom: 0 }}>
                                                                <span>หมายเหตุ : </span>
                                                                <b>{notesCon?.note}</b>
                                                            </div>
                                                            <div style={{ marginBottom: 0 }}>
                                                                <span>ผู้สร้างหมายเหตุ : </span>
                                                                <b>{notesCon?.noteBy}</b>
                                                            </div>
                                                            <div style={{ marginBottom: 0 }}>
                                                                <span>สถานะที่ทำการหมายเหตุ : </span>
                                                                <b>{notesCon?.status}</b>
                                                            </div>
                                                        </>
                                                    </Card>
                                                    <Divider />
                                                </>
                                                :
                                                null
                                        }
                                    </>
                                    :
                                    <>
                                        {
                                            getData?.lands?.landLoanDetails?.approvalConditions === true ?
                                                <>
                                                    <Divider />
                                                    <Row justify={'left'}><Col span={24} style={{ color: "red" }}><b><u>อนุมัติแบบมีเงื่อนไข!</u></b></Col></Row>
                                                    <Card
                                                        // key={notesConIndex}
                                                        style={{ textAlign: 'left' }}>
                                                        <>
                                                            <div style={{ marginBottom: 0 }}>
                                                                <span>สาเหตุ : </span>
                                                                <b>{notesCon?.cause}</b>
                                                            </div>
                                                            <div style={{ marginBottom: 0 }}>
                                                                <span>หมายเหตุ : </span>
                                                                <b>{notesCon?.note}</b>
                                                            </div>
                                                            <div style={{ marginBottom: 0 }}>
                                                                <span>ผู้สร้างหมายเหตุ : </span>
                                                                <b>{notesCon?.noteBy}</b>
                                                            </div>
                                                            <div style={{ marginBottom: 0 }}>
                                                                <span>สถานะที่ทำการหมายเหตุ : </span>
                                                                <b>{notesCon?.status}</b>
                                                            </div>
                                                        </>
                                                    </Card>
                                                    <Divider />
                                                </>
                                                :
                                                null
                                        }
                                    </>

                            }
                        </Col>
                    </Row>
                    {count === 1 && (<EditCustomerDoc dt={dataFromTables} getData={getData} close={Onclose} page={count} changepage={setCount} sendback={setResetPage} notification={NotiSuccess} notification2={NotiError} />)}
                    {count === 2 && (<Address getData={getData} close={Onclose} page={count} changepage={setCount} sendback={setResetPage} notification={NotiSuccess} notification2={NotiError} notification3={NotiSuccessAdd} notification4={NotiErrorAdd} notification5={NotiDelete} />)}
                    {count === 3 && (<EditProductDoc dt={dataFromTables} getData={getData} close={Onclose} page={count} changepage={setCount} sendback={setResetPage} deleteData={NotiDelete} editData={NotiSuccess} addImg={NotiSuccessAdd} DeleteError={DeleteError} />)}
                    {count === 4 && (<EditAllSocialDoc dataMix={dataMix} getData={getData} close={Onclose} page={count} sendback={setResetPage} changepage={setCount} notification={NotiSuccess} notification2={NotiError} notification3={NotiSuccessAdd} notification4={NotiErrorAdd} notification5={NotiDelete} />)}
                    {count === 5 && (<EditCareerDoc datashow={getData} close={Onclose} page={count} sendback={setResetPage} changepage={setCount} />)}
                    {count === 6 && (<EditGuarantorDoc getData={getData} close={Onclose} page={count} sendback={setResetPage} changepage={setCount} dataFromTable={dataFromTable} notes={notes} />)}
                    {count === 7 && (<EditOtherDoc dt={dataFromTables} getData={getData} close={Onclose} page={count} changepage={setCount} sendback={setResetPage} notification={NotiSuccess} notification2={NotiError} />)}
                    {count === 8 && (<ConclusionDoc dt={dataFromTables} getData1={getData} close={Onclose} sendback={setResetPage} page={count}
                        changepage={setCount} notification={NotiSuccess} notification2={NotiError} notification3={NotiSuccessAdd}
                        notification4={NotiErrorAdd} notification5={NotiDelete} NotiNotFond={NotiNotFond} addrER={CheckAddressER}
                    />)}
                </Space>
            </Modal>
            {contextHolder}
        </div>
    )
}

export default MainEditDoc