import React, { useState, useEffect } from 'react'
import { Form, Button, Row, Col, Upload, Divider, Space, Modal, Image, Spin, Card, message } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import axios from 'axios'
import dayjs from 'dayjs'
import Compressor from 'compressorjs';
import { useSelector, useDispatch } from 'react-redux';
import { addCustomer } from '../../../redux/Customer';
import { addAddress } from '../../../redux/Address';
import { addPhone } from '../../../redux/Phone';
// import { addImage } from '../../../redux/Image';
// import { addOldLoan } from '../../../redux/OldLoan';
// import { addCar } from '../../../redux/Car';
// import { addLoan } from '../../../redux/Loan';
import { addLand } from '../../../redux/Land';
import { addLoanLand } from '../../../redux/LoanLand';
// import { addOldLoanLand } from '../../../redux/OldLoanLand';
import { addGuarantor } from '../../../redux/Guarantor';
import { addCareerIncome } from '../../../redux/CareerIncome';
import { checkNewCCAP, findlandall, mutips4, testupload, getImagess } from '../../file_mid/all_api'
// import { addOccupation } from '../../../redux/Occupation';
// import { addEmail } from '../../../redux/Email';

export default function SendUploadImg({ onCancel, changepage, newDataLand }) {
    // const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const dispatch = useDispatch()
    // const [dataModalEdit, setDataModalEdit] = useState({})
    // const [form] = Form.useForm();
    const [loading, setLoading] = useState(false)
    // const [sell, setSell] = useState(true)
    const customers = useSelector(state => state.customers)
    // const guarantor = useSelector(state => state.guarantor)
    const Social = useSelector(state => state.Social)
    const token = localStorage.getItem('token');
    const [imageBlobzz, setImageBlobzz] = useState([]);
    // const [dataLand, setDataLand] = useState(customers.data);
    const [show, setShow] = useState(Social.data);
    // const [dataCareer, setDataCareer] = useState([]);
    const [idcustomer, setIDCustomer] = useState()
    // const { confirm } = Modal;
    // const [guarantor1, setGuarantor1] = useState([])
    // const [address, setAddresss] = useState([])
    // const [dataPhoneCus, setDataPhoneCus] = useState([]);
    const [dataPostGuarantor, setDataPostGuarantor] = useState([]) // customerGuarantor
    // const [dataEmail, setDataEmail] = useState([]);
    // const [dataSocials, setDataSocials] = useState([]);
    // const [reSendData, setReSendData] = useState({});
    //const des = JSON.parse(dataFromTable.des)
    const [appLand, setAppLand] = useState({
        landId: customers?.data?.landId,
        landLoanId: customers?.data?.landLoanId,
        approvalDate: customers?.data?.approvalDate,
        minPrice: customers?.data?.minPrice,
        maxPrice: customers?.data?.maxPrice,
        priceStatus: customers?.data?.priceStatus,
        inputPriceDate: customers?.data?.inputPriceDate,
        proposalPriceBy: customers?.data?.proposalPriceBy,
        note: customers?.data?.note,
        rejectDate: customers?.data?.rejectDate,
        rejectStatus: customers?.data?.rejectStatus,
    })
    const [land, setLand] = useState({
        landId: customers?.data?.landId,
        landLoanId: customers?.data?.landLoanId,
        landInput: customers?.data?.landInput,
        productTypeLand: customers?.data?.productTypeLand,
        gcode: customers?.data?.gcode,
        numberLand: customers?.data?.numberLand,
        numberLandLawang: customers?.data?.numberLandLawang,
        province: customers?.data?.province,
        district: customers?.data?.district,
        subdistrict: customers?.data?.subdistrict,
        rai: customers?.data?.rai,
        workArea: customers?.data?.workArea,
        squareWaArea: customers?.data?.squareWaArea,
        landPrice: customers?.data?.landPrice,
        resultLandPrice: customers?.data?.resultLandPrice,
        LandDateStatus: customers?.data?.LandDateStatus,
        landContno: customers?.data?.landContno,
        landStatus: customers?.data?.landStatus,
        landPriceStatus: customers?.data?.landPriceStatus,
    })
    const [loanLand, setLoanLand] = useState({
        landLoanId: customers?.data?.landLoanId,
        productType: customers?.data?.productType,
        productLoanLandType: customers?.data?.productLoanLandType,
        loanAmounttLand: customers?.data?.loanAmounttLand,
        loanLandTerm: customers?.data?.loanLandTerm,
        interestRateLand: customers?.data?.interestRateLand,
        interesttLand: customers?.data?.interesttLand,
        monthlyPaymentLand: customers?.data?.monthlyPaymentLand,
        proposalBy: customers?.data?.proposalBy,
        reviewedBy: customers?.data?.reviewedBy,
        approvalStatus: customers?.data?.approvalStatus,
        approvedLoanAmount: customers?.data?.approvedLoanAmount,
        approvedBy: customers?.data?.approvedBy,
        approvalDate: customers?.data?.approvalDate,
        IsAccepted: customers?.data?.IsAccepted,
        customerApprovedLoan: customers?.data?.customerApprovedLoan,
        branch: customers?.data?.branch,
        activeStatus: customers?.data?.activeStatus,
    })
    // const [number, setNumber] = useState(0);
    const [checkImage, setCheckImage] = useState();

    const [fileList, setFileList] = useState([]) // รูปบัตรประชาชน
    const [dataPost, setDataPost] = useState({}) // customer
    const [dataAddress, setDataAddress] = useState({}) // address
    const [dataPhone, setDataPhone] = useState({}) // phone
    const [count, setCount] = useState()
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');

    //เพิ่มรูป
    const [fileListCustomerImage, setFileListCustomer] = useState([]);
    const [fileListCustomerImageCareer, setFileListCustomerImageCareer] = useState([]);
    const [fileListStatement, setFileListStateMent] = useState([]);
    const [fileListStatementPDF, setFileListStateMentPDF] = useState([]);
    // const [fileListOther, setFileListOther] = useState([]);
    const [fileListLand, setFileListLand] = useState([]);
    const [fileListDeep, setFileListDeep] = useState([]);
    // const [fileListGuarantor, setFileListGuarantor] = useState([]);
    const [fileListCustomerLeafDivider, setFileListCustomerLeafDivider] = useState([]);
    const [fileListCustomerBurden, setFileListCustomerBurden] = useState([]);

    // const [fileListCustomer1, setFileListCustomer1] = useState([]);
    // const [fileListCustomerImageCareer1, setFileListCustomerImageCareer1] = useState([]);
    // const [fileListStatement1, setFileListStateMent1] = useState([]);
    // const [fileListStatementPDF1, setFileListStateMentPDF1] = useState([]);
    // const [fileListOther1, setFileListOther1] = useState([]);
    // const [fileListLand1, setFileListLand1] = useState([]);
    // const [fileListDeep1, setFileListDeep1] = useState([]);
    // const [fileListGuarantor1, setFileListGuarantor1] = useState([]);

    /*เก็บรูปทั้งหมด*/
    const [allImg, setAllImg] = useState([]);
    const newFormData = new FormData();
    const [resultImage, setResultImage] = useState([]);

    useEffect(() => {
        loadData()
        if (Social.data) {
            setLand({
                ...land,
                landId: Social?.data?.landId,
                landLoanId: Social?.data?.landLoanId,
                landInput: Social?.data?.landInput,
                productTypeLand: Social?.data?.productTypeLand,
                gcode: Social?.data?.gcode,
                numberLand: Social?.data?.numberLand,
                numberLandLawang: Social?.data?.numberLandLawang,
                province: Social?.data?.province,
                district: Social?.data?.district,
                subdistrict: Social?.data?.subdistrict,
                rai: Social?.data?.rai,
                workArea: Social?.data?.workArea,
                squareWaArea: Social?.data?.squareWaArea,
                landPrice: Social?.data?.landPrice,
                resultLandPrice: Social?.data?.resultLandPrice,
                LandDateStatus: Social?.data?.LandDateStatus,
                landContno: Social?.data?.landContno,
                landStatus: Social?.data?.landStatus,
                landPriceStatus: Social?.data?.landPriceStatus,
                landMemo: Social?.data?.landMemo,
                storyLand: Social?.data?.storyLand,
                parcellat: Social?.data?.parcellat,
                parcellon: Social?.data?.parcellon,

            })
            setLoanLand({
                ...loanLand,
                landLoanId: Social?.data?.landLoanId,
                productType: Social?.data?.productType,
                productLoanLandType: Social?.data?.productLoanLandType,
                loanAmounttLand: Social?.data?.loanAmounttLand,
                loanLandTerm: Social?.data?.loanLandTerm,
                interestRateLand: Social?.data?.interestRateLand,
                interesttLand: Social?.data?.interesttLand,
                monthlyPaymentLand: Social?.data?.monthlyPaymentLand,
                proposalBy: Social?.data?.proposalBy,
                reviewedBy: Social?.data?.reviewedBy,
                approvalStatus: Social?.data?.approvalStatus,
                approvedLoanAmount: Social?.data?.approvedLoanAmount,
                approvedBy: Social?.data?.approvedBy,
                approvalDate: Social?.data?.approvalDate,
                IsAccepted: Social?.data?.IsAccepted,
                customerApprovedLoan: Social?.data?.customerApprovedLoan,
                branch: Social?.data?.branch,
                activeStatus: Social?.data?.activeStatus,
            })
            setAppLand({
                ...appLand,
                landId: Social?.data?.landId,
                landLoanId: Social?.data?.landLoanId,
                approvalDate: Social?.data?.approvalDate,
                minPrice: Social?.data?.minPrice,
                maxPrice: Social?.data?.maxPrice,
                priceStatus: Social?.data?.priceStatus,
                inputPriceDate: Social?.data?.inputPriceDate,
                proposalPriceBy: Social?.data?.proposalPriceBy,
                note: Social?.data?.note,
                rejectDate: Social?.data?.rejectDate,
                rejectStatus: Social?.data?.rejectStatus,
            })
        }
        // loadDataNew()
    }, [])

    const addImg = async (resultImg) => {
        //console.log("resultImg =", resultImg)
        if (allImg?.ts1?.length > 0) {
            await logFormData(allImg.ts1, 1, resultImg)
        }
        if (allImg?.ts2?.length > 0) {
            await logFormData(allImg.ts2, 2, resultImg)
        }
        if (allImg?.ts3?.length > 0) {
            await logFormData(allImg.ts3, 3, resultImg)
        }
        if (allImg?.ts4?.length > 0) {
            await logFormData(allImg.ts4, 4, resultImg)
        }
        // if (allImg?.ts5?.length > 0) {
        //     await logFormData(allImg.ts5, 5, resultImg)
        // }
        if (allImg?.ts6?.length > 0) {
            await logFormData(allImg.ts6, 6, resultImg)
        }
        if (allImg?.ts7?.length > 0) {
            await logFormData(allImg.ts7, 7, resultImg)
        }
        if (allImg?.ts8?.length > 0) {
            await logFormData(allImg.ts8, 8, resultImg)
        }
        if (allImg?.ts9?.length > 0) {
            await logFormData(allImg.ts9, 9, resultImg)
        }
        if (allImg?.ts10?.length > 0) {
            await logFormData(allImg.ts10, 10, resultImg)
        }
        if (allImg?.ts11?.length > 0) {
            await logFormData(allImg.ts11, 11, resultImg)
        }
        if (allImg?.ts12?.length > 0) {
            await logFormData(allImg.ts12, 12, resultImg)
        }
    }

    const logFormData = async (ts, type, resultImg) => {
        //  var imageListImage = []
        for (const ez of ts) {
            var image = {}
            if (type !== 7) {
                if (ez.size > 3000000) {
                    console.log("3m ไม่ให้ส่ง");
                } else {
                    let quality;
                    if (ez.size >= 2500000 && ez.size <= 3000000) {
                        quality = 0.1;
                    } else if (ez.size >= 2000000 && ez.size <= 2499999) {
                        quality = 0.15;
                    } else if (ez.size >= 1500000 && ez.size <= 1999999) {
                        quality = 0.2;
                    } else if (ez.size >= 1000000 && ez.size <= 1499999) {
                        quality = 0.3;
                    } else {
                        quality = 0.5;
                    }
                    const compressedFile = await new Promise((resolve) => {
                        new Compressor(ez.originFileObj, {
                            quality: quality,
                            success: (result) => {
                                resolve(result);
                            },
                        });
                    });
                    var filename = ez.name
                    var image64 = await getBase64(compressedFile)
                    image.filename = filename
                    image.image64 = image64
                    image.type = type
                    resultImg.push(image)
                }
            }
            else {
                var filename = ez.name
                var image64 = await getBase64(ez.originFileObj)
                image.filename = filename
                image.image64 = image64
                image.type = type
                resultImg.push(image)
            }
        }
        return resultImg
    };


    const handleCancel = () => {
        onCancel(false);
    }

    // console.log("land", land)
    // console.log("loanLand", loanLand)

    const loadData = async () => {
        await axios.post(findlandall, { carId: show.landId, customerId: show.customerId })
            .then(res => {
                if (res.status === 200) {
                    // console.log("LandLand", res.data)
                    setImageBlobzz(res.data.landLoanDetails.land.img)
                    // setLand(res.data.landLoanDetails.land)
                }
            }).catch((err) => {
                console.log("err", err)
            })
    }
    ////////////// ข้อมูลยูสในเดต้าเบส ////////////////////////////////////////////////////////////////
    const loadDataOne = async (data) => {
        //console.log("data =", data)
        const one = { identificationId: data.identificationId }
        //console.log("CUS", data.identificationId)
        if (!data.identificationId) {
            console.log("ไม่มีเลขบัตรคนกู้")
            alert('ไม่สามารถอ่านบัตรผู้ขอกู้ได้')
            setDataPost()
            setDataPhone()
            setDataAddress({})
            dispatch(addCareerIncome())
        } else {
            setIDCustomer(data.identificationId)
            setLoading(true)
            await axios.post(checkNewCCAP, one)
                .then((res) => {
                    if (res.data) {
                        message.info('มีข้อมูลในฐานข้อมูล')
                        if (window.confirm('คุณต้องการใช้ข้อมูลในฐานข้อมูลหรือไม่ ?')) {
                            // Save it!
                            setDataPost(res.data)
                            setDataPhone(res.data.phones[0])
                            setDataAddress(res.data.address[0])
                            if (res.data.ojs[0]) {
                                dispatch(addCareerIncome(res.data.ojs[0]))
                            }
                            setLoading(false)
                        } else {
                            // Do nothing!
                            console.log("dataAddress", data)
                            setDataPost({ ...data, customerId: res.data.customerId })
                            if (res.data.phones) {
                                setDataPhone({ ...dataPhone, customerId: res.data.customerId, phoneId: res.data.phones[0].phoneId })
                            }
                            setLoading(false)
                        }
                    }
                    else {
                        //alert('ไม่พบข้อมูลในฐานข้อมูล')
                        message.info('ไม่พบข้อมูลในฐานข้อมูล')
                        setDataPost({ ...data, customerId: 0 })
                        splitAddress(...data.address)
                        setDataAddress({ ...data.address })
                        setDataAddress({ ...dataAddress, customerId: 0, addressId: 0 })
                        setLoading(false)
                    }
                })
                .catch((err) => console.log(err))
            setLoading(false)
        }
    }
    /////////// ดึงข้อมูลจากรูปบัตรประชาชน /////////////////////////////////////////////////////////////////  
    const handleScanIdCard = async (value) => {
        const imgId = { image: value }
        setLoading(true)
        const headers = { 'Content-Type': 'application/json' }
        await axios.post(testupload, imgId, { headers: headers })
            .then(res => {
                //console.log("img", res.data)
                if (res.data) {
                    //setDataPost(res.data[0])
                    //alert('สามารถอ่านได้')
                    splitAddress(res.data[0])
                    loadDataOne(res.data[0])
                    setLoading(false)
                }
                else {
                    alert('ไม่สามารถอ่านบัตรได้')
                    //message.info('ไม่สามารถอ่านได้')
                    setDataPost({})
                    setDataAddress({})
                    setDataPhone({})
                    setLoading(false)
                }
            })
            .catch((err) => alert('ไม่สามารถอ่านบัตรได้'))
        setLoading(false)
    }
    ////////////// รับไฟล์รูปบัตร /////////////////////////////////
    const handleFileInputChange = async (event) => {
        if (event.length > 0) {
            var file = event[0].originFileObj
            const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg']
            // const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
            if (file) {
                if (validImageTypes.includes(file.type)) {
                    const reader = new FileReader(); // สร้าง FileReader object
                    reader.onloadend = () => { // ฟังก์ชันที่ทำงานเมื่ออ่านไฟล์เสร็จสิ้น    
                        const base64Data = reader.result; // รับข้อมูล Base64 จาก FileReader result
                        //console.log('Base64 Data:', base64Data);
                        setFileList({ image: base64Data })
                        handleScanIdCard(base64Data)
                    }
                    reader.readAsDataURL(file) // อ่านไฟล์และแปลงเป็น Base64    
                }
            }
        }
    }
    //////////////// เก็บแอสเดรส ////////////////////////////////////
    const splitAddress = (value) => {
        let updatedCount = { ...count }
        //console.log("โหลด",value.address)
        let part = value.address.split(" ")
        if (part[1].includes("หมู่ที่") === true) {
            //setHouseNumber(part[0] + part[1] + part[2])
            updatedCount.houseNumber = part[0] + part[1] + part[2]
        } else {
            //setHouseNumber(part[0])
            updatedCount.houseNumber = part[0]
        }
        //setMoo(part[2])
        //setDataAddress({...dataAddress, moo: part[2]})
        for (let i = 0; i <= part.length; i++) {
            if (part[i]?.includes("ซ.") === true) {
                let s = part[i].split("ซ.")
                //setSoi(s[1])
                updatedCount.soi = s[1]
            }
            if (part[i]?.includes("ถ.") === true) {
                let tanon = part[i].split("ถ.")
                //setRoad(tanon[1])
                updatedCount.road = tanon[1]
            }
            if (part[i]?.includes("ต.") === true) {
                let bn = part[i].split("ต.")
                //setSubdistrict(bn[1])
                updatedCount.subdistrict = bn[1]
            }
            if (part[i]?.includes("อ.") === true) {
                let oo = part[i].split("อ.")
                let oop = oo[1].split(",")
                if (oo[1]?.includes(",") === false) {
                    //setDistrict(oo[1])
                    updatedCount.district = oo[1]
                }
                if (oop[1]?.includes("จ.") === true) {
                    //setDistrict(oop[0])
                    updatedCount.district = oop[0]
                    let oops = oop[1].split("จ.")
                    //setProvince(oops[1])
                    updatedCount.province = oops[1]
                }
            }
            if (part[i]?.includes("จ.") === true) {
                let jh = part[i].split("จ.")
                //setProvince(jh[1])
                updatedCount.province = jh[1]
            }
        }
        setDataAddress({
            ...dataAddress,
            customerId: 0,
            addressId: 0,
            houseNumber: updatedCount.houseNumber,
            houseRegistrationNumber: updatedCount.houseRegistrationNumber,
            village: updatedCount.village,
            zipCode: updatedCount.zipCode,
            soi: updatedCount.soi,
            road: updatedCount.road,
            subdistrict: updatedCount.subdistrict,
            district: updatedCount.district,
            province: updatedCount.province,
            addressStatus: true,
            addressOnIdcard: true,
            workAddress: false,
            otherAddress: false,
            currentAddress: false,
            sendDocuments: false,
            houseRegistration: false,
        })
    }
    /////////// ดึงข้อมูลจากรูปบัตรประชาชนคนค้ำ /////////////////////////////////////////////////////////////////  
    const handleScanIdCardGuarantor = async (value) => {
        var dataListGuarantor = []
        for (let i = 0; i < value.length; i++) {
            var imgId = { image: value[i].image64 }
            setLoading(true)
            const headers = { 'Content-Type': 'application/json' }
            await axios.post(testupload, imgId, { headers: headers })
                .then(async (res) => {
                    delete res.data[0].address
                    if (res.data) {
                        //console.log(res.data[0])
                        const b = await loadDataOneGuarantor(res.data[0])
                        dataListGuarantor.push({ ...b[0], imgs: value[i] })
                        setLoading(false)
                    }
                    else {
                        message.info('ไม่สามารถอ่านได้')
                        setLoading(false)
                    }
                })
                .catch((err) => console.log(err))
            setLoading(false)
        }
        dispatch(addGuarantor(dataListGuarantor))
    }
    ////////////// ข้อมูลยูสในเดต้าเบสคนค้ำ ////////////////////////////////////////////////////////////////
    const loadDataOneGuarantor = async (data) => {
        // console.log("DATA", data.identificationId)
        // console.log("ids", idcustomer)
        setLoading(true)
        if (!data?.identificationId) {
            console.log("ไม่มีเลขบัตรคนค้ำ")
            //alert('ไม่สามารถอ่านบัตรคนค้ำประกันได้')
            //showConfirmGu()
        } else if (data.identificationId === idcustomer) {
            console.log("คนกู้คนค้ำซ้ำกัน")
            //showConfirm()
            setLoading(false)
        } else {
            // if (data.identificationId === idcustomer) {
            //     showConfirm()
            //     setLoading(false)
            // } else {
            var dataListGuarantor = []
            if (data) {
                var one = { identificationId: data.identificationId }
                setLoading(true)
                await axios.post(checkNewCCAP, one)
                    .then((res) => {
                        if (res.data) {
                            //alert('มีข้อมูลในฐานข้อมูล')
                            message.info('คนค้ำมีข้อมูลในฐานข้อมูล')
                            // delete res.data.address
                            // delete res.data.phones
                            // delete res.data.carLoanDetails
                            // delete res.data.emails
                            delete res.data.ojs
                            // delete res.data.socials
                            dataListGuarantor.push(res.data)
                            setDataPostGuarantor({ ...dataPostGuarantor, ...data, customerId: 0 })
                            setLoading(false)
                            //setIsModalOpen(true)
                            // if (window.confirm('คุณต้องการใช้ข้อมูลในฐานข้อมูลหรือไม่ ?')) {
                            //     console.log("RES-DATAผู้ค้ำ", res.data)
                            //     dataListGuarantor.push(res.data)
                            //     // console.log("dataListGuarantor =", dataListGuarantor)
                            //     setDataPostGuarantor(res.data)
                            // } else {
                            //     // Do nothing!
                            //     console.log("dataAddressGuarantor", data)
                            //     // splitAddress(data)
                            //     // setDataAddress({ ...data })
                            // }

                        }
                        else {
                            // alert('คนค้ำไม่พบข้อมูลในฐานข้อมูล')
                            message.info('ไม่พบข้อมูลในฐานข้อมูล')
                            // console.log("RES-DATA NO =", data)
                            dataListGuarantor.push(data)
                            setDataPostGuarantor({ ...dataPostGuarantor, ...data, customerId: 0 })
                            setLoading(false)
                            // setDataAddressGuarantor({ ...dataAddressGuarantor, customerId: 0, addressId: 0 })
                            // setDataPhoneGuarantor({})
                        }
                    })
                    .catch((err) => console.log(err))
                setLoading(false)
            }
            // console.log("dataListGuarantor =", dataListGuarantor)
            return dataListGuarantor
            // dispatch(addGuarantor(dataListGuarantor))
            // }
        }
        setLoading(false)
    }

    const imgSend = (resultImgs) => {
        return new Promise(async (resolve, reject) => {
            try {
                var status;
                // newFormData.append('idcus', land?.landId);
                //newFormData.append('productTypeId', "1");
                newFormData.append('tm', dayjs().format("YYYY-MM-DDTHH:mm:ssZ"));

                for (const file of resultImgs) {
                    var image = {}
                    newFormData.append('file', file.imageCompressedFile);
                    newFormData.append("type", file.type);
                    newFormData.append("idcus", file.landId);
                    image.type = file.type
                    resultImage.push(image)
                }
                const response = await axios.post(mutips4, newFormData); // upload img ที่ดินแบบจดรวม
                if (response.data) {
                    //success(randomTenDigitNumber);
                    console.log("TY", response.data);
                    //dispatch(addSocial(response.data))
                    status = response.status;
                    resolve(status);
                }
            } catch (error) {
                error(error);
                reject(error);
            } finally {
                //setLoading(false);
                console.log("OK")
            }
        });
    };

    const handleSubmit = async () => {
        setLoading(true)


        if (allImg?.length > 0) {
            await imgSend(allImg)
        }

        var newResultLand = { ...land, approval: { ...appLand } }

        dispatch(addCustomer({ ...dataPost }))
        dispatch(addAddress({ ...dataAddress }));
        dispatch(addPhone({ ...dataPhone }));
        dispatch(addLand(newResultLand))
        dispatch(addLoanLand({ ...loanLand }))
        changepage('2')
        setLoading(false)
    };

    ///////////////////////////////////อัพโหลดรูปภาพใหม่/////////////////////////////////////////////
    const compressImage = async (ez, types, landId) => {
        var image = {};
        return new Promise(async (resolve) => {
            if (types !== 7) {
                if (ez.size > 4000000) {
                    console.log("4m ไม่ให้ส่ง");
                    resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
                } else {
                    let quality;
                    if (ez.size >= 2500000 && ez.size <= 4000000) {
                        quality = 0.1;
                    } else if (ez.size >= 2000000 && ez.size <= 2499999) {
                        quality = 0.15;
                    } else if (ez.size >= 1500000 && ez.size <= 1999999) {
                        quality = 0.2;
                    } else if (ez.size >= 1000000 && ez.size <= 1499999) {
                        quality = 0.3;
                    } else {
                        quality = 0.5;
                    }

                    try {
                        // ใช้ Promise เพื่อบีบอัดรูปภาพ
                        const compressedFile = await new Promise((resolveCompress) => {
                            new Compressor(ez, {
                                quality: quality,
                                success: (result) => {
                                    resolveCompress(result);
                                },
                            });
                        });

                        const fileOptions = {
                            type: compressedFile.type,
                            lastModified: Date.now(),
                        };
                        const compressedFileAsFile = new File([compressedFile], ez.name, fileOptions);
                        image.imageCompressedFile = compressedFileAsFile;
                        image.type = types
                        image.landId = landId
                        allImg.push(image);
                        //setAllImg((prevAllImg) => [...prevAllImg, image]);

                        // ตรงนี้คือการ resolve ไฟล์ที่ถูกบีบอัด
                        resolve(image);
                    } catch (error) {
                        console.error('Image compression error:', error);
                        resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
                    }
                }
            } else {
                // ... (โลจิกสำหรับ type 7)
                image.imageCompressedFile = ez;
                image.type = types
                image.landId = landId
                allImg.push(image);
                //setAllImg((prevAllImg) => [...prevAllImg, image]);
                resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
            }
        });
    };
    const beforeUpts = async (file, ty, landId) => {
        console.log("landId", landId)
        try {
            // setLoading(true)
            const compressedFile = await compressImage(file, ty, landId);
            return Promise.resolve(compressedFile);
        } catch (error) {
            console.error('Image compression error:', error);
            message.error('Image compression failed.');
            return Promise.reject(); // เปลี่ยนให้ return Promise.reject() เมื่อไม่สามารถบีบอัดได้
        }
    };

    const handleCustomerImage = async ({ fileList }) => {
        for (const removedFile of fileListCustomerImage.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 4)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 4));
                setAllImg(updatedAllImg);
            }
        }
        setFileListCustomer(fileList);
        if (fileList) {
            handleFileInputChange(fileList);
        } else {
            handleFileInputChange([]);
        }
        setLoading(false)
    };


    const handleCustomerImageCareer = async ({ fileList }) => {
        for (const removedFile of fileListCustomerImageCareer.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 6)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 6));
                setAllImg(updatedAllImg);
            }
        }
        setFileListCustomerImageCareer(fileList);
        setCheckImage(fileList);
        setLoading(false)
    };
    const handleLand = async ({ fileList }) => {

        const newFileList = [];

        for (const removedFile of fileListLand.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 1)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 1));
                setAllImg(updatedAllImg);
            }
        }

        fileList.forEach((file) => {
            if (!fileListLand.some((f) => f.uid === file.uid)) {
                newFileList.push(file);
            }
        });

        fileListLand.push(...newFileList)
        // setFileListLand(fileList);
        setCheckImage(fileList);
        setLoading(false)
    };

    const handleChangeCustomerLeafDivider = async ({ fileList }) => { // 10

        const newFileList = [];

        for (const removedFile of fileListCustomerLeafDivider.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 10)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 10));
                setAllImg(updatedAllImg);
            }
        }
        fileList.forEach((file) => {
            if (!fileListCustomerLeafDivider.some((f) => f.uid === file.uid)) {
                newFileList.push(file);
            }
        });
        fileListCustomerLeafDivider.push(...newFileList)
        // setFileListCustomerLeafDivider(fileList);
        setCheckImage(fileList);
        setLoading(false)
    };
    const handleChangeCustomerBurden = async ({ fileList }) => { // 11

        const newFileList = [];

        for (const removedFile of fileListCustomerBurden.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 11)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 11));
                setAllImg(updatedAllImg);
            }
        }

        fileList.forEach((file) => {
            if (!fileListCustomerBurden.some((f) => f.uid === file.uid)) {
                newFileList.push(file);
            }
        });

        fileListCustomerBurden.push(...newFileList);
        // setFileListCustomerBurden(fileList);
        setCheckImage(fileList);
        setLoading(false)

    };

    console.log("fileListDeep", fileListDeep)
    console.log("all", allImg)

    const handleDeep = async ({ fileList }) => { // 3
        // console.log("handleDeep", fileList)
        const newFileList = [];
        for (const removedFile of fileListDeep.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 3)) {
                // console.log("1")
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 3));
                setAllImg(updatedAllImg);
            }
        }
        fileList.forEach((file) => {
            if (!fileListDeep.some((f) => f.uid === file.uid)) {
                newFileList.push(file);
            }
        });

        fileListDeep.push(...newFileList);
        setCheckImage(fileList);
        setLoading(false)
    };

    const handleStatement = async ({ fileList }) => {
        for (const removedFile of fileListStatement.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 8)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 8));
                setAllImg(updatedAllImg);
            }
        }
        setFileListStateMent(fileList);
        setCheckImage(fileList);
        setLoading(false)
    };
    const handleStatementPDF = async ({ fileList }) => {
        for (const removedFile of fileListStatementPDF.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 7)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 7));
                setAllImg(updatedAllImg);
            }
        }
        setFileListStateMentPDF(fileList);
        setCheckImage(fileList);
        setLoading(false)
    };

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
    };
    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const handleCancelPreview = () => {
        setPreviewVisible(false);
    }

    const renderTitleDeed = ({ item, index }) => {
        return (
            <>
                <Row justify={'left'}><b>รูปโฉนด/หน้า-หลัง ({item.numberLand}) <u style={{ color: 'red' }}>(อัพโหลดไฟล์ไม่เกิน 3MB)</u></b></Row>
                <Row gutter={32} justify={'center'}>
                    <Space>
                        <Row justify={'center'}>
                            {item?.img?.map((e, index) => {
                                if (e.type === 3) {
                                    return (
                                        <>
                                            <Row gutter={32} justify={'center'}>
                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                    <Image style={{ margin: 3 }} width={'100px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                </Col>

                                            </Row>
                                        </>
                                    );
                                }
                                return null;
                            })}
                        </Row>
                        <div className='gutter-row' span={12}>
                            <b> <Form.Item label='เพิ่มรูป' name="imageIDGuCar">
                                <Upload
                                    accept="image/*"
                                    name="imageIDGuCar"
                                    multiple={true}
                                    listType="picture-card"
                                    // fileList={index === 0 ? fileListDeep.file1 : fileListDeep.file2}
                                    onPreview={handlePreview}
                                    onChange={handleDeep}
                                    beforeUpload={(file) => beforeUpts(file, 3, item.landId).then(() => false).catch(() => true)}
                                >
                                    {/* {fileListDeep?.length >= 10 ? null : ( */}
                                    <div>
                                        <PlusOutlined />
                                        <div className="ant-upload-text">upload</div>
                                    </div>
                                    {/* )} */}
                                </Upload>
                                <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                    <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                </Modal>
                            </Form.Item>
                            </b>
                        </div>
                    </Space>
                </Row>
                <Divider />
            </>
        )
    }
    const renderDivision = ({ item, index }) => {
        return (
            <>
                <Row justify={'left'}><b>ใบแบ่งใบต่อ ({item.numberLand}) <u style={{ color: 'red' }}>(อัพโหลดไฟล์ไม่เกิน 3MB)</u></b></Row>
                <Row gutter={32} justify={'center'}>
                    <Space>
                        <Row justify={'center'}>
                            {item?.img?.map((e, index) => {
                                if (e.type === 10) {
                                    return (
                                        <>
                                            <Row gutter={32} justify={'center'}>
                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                    <Image style={{ margin: 3 }} width={'100px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                </Col>

                                            </Row>
                                        </>
                                    );
                                }
                                return null;
                            })}
                        </Row>
                        <div className='gutter-row' span={12}>
                            <b> <Form.Item label='เพิ่มรูป' name='imageIDGuCar'
                            >
                                <Upload
                                    accept="image/*"
                                    name='imageIDGuCar'
                                    multiple={true}
                                    listType="picture-card"
                                    // fileList={fileListCustomerLeafDivider}
                                    onPreview={handlePreview}
                                    onChange={handleChangeCustomerLeafDivider}
                                    beforeUpload={(file) => beforeUpts(file, 10, item.landId).then(() => false).catch(() => true)}
                                >
                                    {fileListCustomerLeafDivider?.length >= 10 ? null : (
                                        <div>
                                            <PlusOutlined />
                                            <div className="ant-upload-text">upload</div>
                                        </div>
                                    )}
                                </Upload>
                                <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                    <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                </Modal>
                            </Form.Item>
                            </b>
                        </div>
                    </Space>
                </Row>
                <Divider />
            </>
        )
    }
    const renderLawang = ({ item, index }) => {
        return (
            <>
                <Row justify={'left'}><b>ระวาง ({item.numberLand}) <u style={{ color: 'red' }}>(อัพโหลดไฟล์ไม่เกิน 3MB)</u></b></Row>
                <Row gutter={32} justify={'center'}>
                    <Space>
                        <Row justify={'center'}>
                            {item?.img?.map((e, index) => {
                                if (e.type === 11) {
                                    return (
                                        <>
                                            <Row gutter={32} justify={'center'}>
                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                    <Image style={{ margin: 3 }} width={'100px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                </Col>

                                            </Row>
                                        </>
                                    );
                                }
                                return null;
                            })}
                        </Row>
                        <div className='gutter-row' span={12}>
                            <b> <Form.Item label='เพิ่มรูป' name='imageIDGuCar'
                            >
                                <Upload
                                    accept="image/*"
                                    name='imageIDGuCar'
                                    multiple={true}
                                    listType="picture-card"
                                    // fileList={fileListCustomerBurden}
                                    onPreview={handlePreview}
                                    onChange={handleChangeCustomerBurden}
                                    beforeUpload={(file) => beforeUpts(file, 11, item.landId).then(() => false).catch(() => true)}
                                >
                                    {fileListCustomerBurden?.length >= 10 ? null : (
                                        <div>
                                            <PlusOutlined />
                                            <div className="ant-upload-text">upload</div>
                                        </div>
                                    )}
                                </Upload>
                                <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                    <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                </Modal>
                            </Form.Item>
                            </b>
                        </div>
                    </Space>
                </Row>
                <Divider />
            </>
        )
    }
    const renderLandBuildings = ({ item, index }) => {
        return (
            <>
                <Row justify={'left'}><b>ใบประเมิน/รูปที่ดิน/สิ่งปลูกสร้าง-ที่ดินปัจจุบัน ({item.numberLand}) <u style={{ color: 'red' }}>(อัพโหลดไฟล์ไม่เกิน 3MB)</u></b></Row>
                <Row gutter={32} justify={'center'}>
                    <Space>
                        <Row justify={'center'}>
                            {item?.img?.map((e, index) => {
                                if (e.type === 1) {
                                    return (
                                        <>
                                            <Row gutter={32} justify={'center'}>
                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                    <Image style={{ margin: 3 }} width={'100px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                </Col>
                                            </Row>
                                        </>
                                    );
                                }
                                return null;
                            })}
                        </Row>
                        <div className='gutter-row' span={12}>
                            <b> <Form.Item label='เพิ่มรูป' name='imageIDGuCar'
                            >
                                <Upload
                                    accept="image/*"
                                    name='imageIDGuCar'
                                    multiple={true}
                                    listType="picture-card"
                                    // fileList={fileListLand}
                                    onPreview={handlePreview}
                                    onChange={handleLand}
                                    // beforeUpload={() => false}
                                    beforeUpload={(file) => beforeUpts(file, 1, item.landId).then(() => false).catch(() => true)}
                                >
                                    {fileListLand?.length >= 10 ? null : (
                                        <div>
                                            <PlusOutlined />
                                            <div className="ant-upload-text">upload</div>
                                        </div>
                                    )}
                                </Upload>
                                <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                    <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                </Modal>
                            </Form.Item>
                            </b>
                        </div>
                    </Space>
                </Row>
                <Divider />
            </>
        )
    }

    return (
        <>
            <Row justify={'center'}>
                <Col className='gutter-row' span={24} style={{ fontSize: '25px', textAlign: 'center' }}>
                    <b>อัปโหลดรูป</b>
                </Col>
            </Row>
            <Spin spinning={loading} size='large' tip=" Loading... ">
                <Row justify={'center'}>
                    <Divider style={{ margin: 5 }} />

                    <Form
                        labelCol={{
                            span: 12,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                        style={{
                            width: '90%',
                        }}
                        autoComplete="off"
                        onFinish={handleSubmit}
                        initialValues={{ remember: true }}
                    >
                        <div className='main'>
                            <Card style={{ width: '100%' }}>
                                {/* <Spin spinning={loading} size='large' tip=" Loading... "> */}
                                <Divider style={{ margin: 5 }} />
                                <Row justify={'left'}><b>รูปบัตรประชาชนผู้ขอกู้ <u style={{ color: 'red' }}>(อัพโหลดไฟล์ไม่เกิน 3MB)</u></b></Row>
                                <Row justify={'center'} style={{ margin: 5 }}><b><h4 style={{ color: 'red' }}><u>ถ้าเป็น "นิติบุคคล" ห้ามอัพรูปในช่องผู้ทำสัญญา</u></h4></b></Row>
                                <Row gutter={32} justify={'center'}>
                                    <Space direction="vertical" >
                                        <Row justify={'center'}>
                                            {imageBlobzz?.map((e, index) => {
                                                if (e.type === 4) {
                                                    return (
                                                        <>
                                                            <Row gutter={32} justify={'center'}>
                                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                                    <Image style={{ margin: 3 }} width={'100px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </Row>
                                        <div className='gutter-row' span={12}>
                                            <b> <Form.Item label='เพิ่มรูป' name='imageIDGuCar'
                                            >
                                                <Upload
                                                    accept="image/*"
                                                    name='imageIDGuCar'
                                                    // multiple={true}
                                                    listType="picture-card"
                                                    fileList={fileListCustomerImage}
                                                    onPreview={handlePreview}
                                                    onChange={handleCustomerImage}
                                                    //beforeUpload={() => false}
                                                    beforeUpload={(file) => beforeUpts(file, 4, land.landId).then(() => false).catch(() => true)}
                                                >
                                                    {fileListCustomerImage?.length >= 1 ? null : (
                                                        <div>
                                                            <PlusOutlined />
                                                            <div className="ant-upload-text">upload</div>
                                                        </div>
                                                    )}
                                                </Upload>
                                                <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                                    <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                </Modal>
                                            </Form.Item>
                                            </b>
                                        </div>
                                    </Space>
                                </Row>
                                <Divider />

                                {
                                    newDataLand?.map((item, index) => {
                                        return renderTitleDeed({ item, index })
                                    })
                                }
                                {
                                    newDataLand?.map((item, index) => {
                                        return renderDivision({ item, index })
                                    })
                                }
                                {
                                    newDataLand?.map((item, index) => {
                                        return renderLawang({ item, index })
                                    })
                                }
                                {
                                    newDataLand?.map((item, index) => {
                                        return renderLandBuildings({ item, index })
                                    })
                                }

                                {/* <Row justify={'left'}><b><u>รูปโฉนด/หน้า-หลัง (อัพโหลดไฟล์ไม่เกิน 3MB)</u></b></Row>
                                <Row gutter={32} justify={'center'}>
                                    <Space>
                                        <Row justify={'center'}>
                                            {imageBlobzz?.map((e, index) => {
                                                if (e.type === 3) {
                                                    return (
                                                        <>
                                                            <Row gutter={32} justify={'center'}>
                                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                                    <Image style={{ margin: 3 }} width={'100px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                </Col>

                                                            </Row>
                                                        </>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </Row>
                                        <div className='gutter-row' span={12}>
                                            <b> <Form.Item label='เพิ่มรูป' name='imageIDGuCar'
                                            >
                                                <Upload
                                                    accept="image/*"
                                                    name='imageIDGuCar'
                                                    multiple={true}
                                                    listType="picture-card"
                                                    fileList={fileListDeep}
                                                    onPreview={handlePreview}
                                                    onChange={handleDeep}
                                                    // beforeUpload={() => false}
                                                    beforeUpload={(file) => beforeUpts(file, 3).then(() => false).catch(() => true)}
                                                >
                                                    {fileListDeep?.length >= 10 ? null : (
                                                        <div>
                                                            <PlusOutlined />
                                                            <div className="ant-upload-text">upload</div>
                                                        </div>
                                                    )}
                                                </Upload>
                                                <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                                    <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                </Modal>
                                            </Form.Item>
                                            </b>
                                        </div>
                                    </Space>
                                </Row>
                                <Divider />
                                <Row justify={'left'}><b><u>ใบแบ่งใบต่อ (อัพโหลดไฟล์ไม่เกิน 3MB)</u></b></Row>
                                <Row gutter={32} justify={'center'}>
                                    <Space>
                                        <Row justify={'center'}>
                                            {imageBlobzz?.map((e, index) => {
                                                if (e.type === 10) {
                                                    return (
                                                        <>
                                                            <Row gutter={32} justify={'center'}>
                                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                                    <Image style={{ margin: 3 }} width={'100px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                </Col>

                                                            </Row>
                                                        </>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </Row>
                                        <div className='gutter-row' span={12}>
                                            <b> <Form.Item label='เพิ่มรูป' name='imageIDGuCar'
                                            >
                                                <Upload
                                                    accept="image/*"
                                                    name='imageIDGuCar'
                                                    multiple={true}
                                                    listType="picture-card"
                                                    fileList={fileListCustomerLeafDivider}
                                                    onPreview={handlePreview}
                                                    onChange={handleChangeCustomerLeafDivider}
                                                    // beforeUpload={() => false}
                                                    beforeUpload={(file) => beforeUpts(file, 10).then(() => false).catch(() => true)}
                                                >
                                                    {fileListCustomerLeafDivider?.length >= 10 ? null : (
                                                        <div>
                                                            <PlusOutlined />
                                                            <div className="ant-upload-text">upload</div>
                                                        </div>
                                                    )}
                                                </Upload>
                                                <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                                    <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                </Modal>
                                            </Form.Item>
                                            </b>
                                        </div>
                                    </Space>
                                </Row>
                                <Divider />
                                <Row justify={'left'}><b><u>ระวาง (อัพโหลดไฟล์ไม่เกิน 3MB)</u></b></Row>
                                <Row gutter={32} justify={'center'}>
                                    <Space>
                                        <Row justify={'center'}>
                                            {imageBlobzz?.map((e, index) => {
                                                if (e.type === 11) {
                                                    return (
                                                        <>
                                                            <Row gutter={32} justify={'center'}>
                                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                                    <Image style={{ margin: 3 }} width={'100px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                </Col>

                                                            </Row>
                                                        </>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </Row>
                                        <div className='gutter-row' span={12}>
                                            <b> <Form.Item label='เพิ่มรูป' name='imageIDGuCar'
                                            >
                                                <Upload
                                                    accept="image/*"
                                                    name='imageIDGuCar'
                                                    multiple={true}
                                                    listType="picture-card"
                                                    fileList={fileListCustomerBurden}
                                                    onPreview={handlePreview}
                                                    onChange={handleChangeCustomerBurden}
                                                    // beforeUpload={() => false}
                                                    beforeUpload={(file) => beforeUpts(file, 11).then(() => false).catch(() => true)}
                                                >
                                                    {fileListCustomerBurden?.length >= 10 ? null : (
                                                        <div>
                                                            <PlusOutlined />
                                                            <div className="ant-upload-text">upload</div>
                                                        </div>
                                                    )}
                                                </Upload>
                                                <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                                    <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                </Modal>
                                            </Form.Item>
                                            </b>
                                        </div>
                                    </Space>
                                </Row>
                                <Divider />
                                <Row justify={'left'}><b><u>ใบประเมิน/รูปที่ดิน/สิ่งปลูกสร้าง-ที่ดินปัจจุบัน(อัพโหลดไฟล์ไม่เกิน 3MB)</u></b></Row>
                                <Row gutter={32} justify={'center'}>
                                    <Space>
                                        <Row justify={'center'}>
                                            {imageBlobzz?.map((e, index) => {
                                                if (e.type === 1) {
                                                    return (
                                                        <>
                                                            <Row gutter={32} justify={'center'}>
                                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                                    <Image style={{ margin: 3 }} width={'100px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </Row>
                                        <div className='gutter-row' span={12}>
                                            <b> <Form.Item label='เพิ่มรูป' name='imageIDGuCar'
                                            >
                                                <Upload
                                                    accept="image/*"
                                                    name='imageIDGuCar'
                                                    multiple={true}
                                                    listType="picture-card"
                                                    fileList={fileListLand}
                                                    onPreview={handlePreview}
                                                    onChange={handleLand}
                                                    // beforeUpload={() => false}
                                                    beforeUpload={(file) => beforeUpts(file, 1).then(() => false).catch(() => true)}
                                                >
                                                    {fileListLand?.length >= 10 ? null : (
                                                        <div>
                                                            <PlusOutlined />
                                                            <div className="ant-upload-text">upload</div>
                                                        </div>
                                                    )}
                                                </Upload>
                                                <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                                    <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                </Modal>
                                            </Form.Item>
                                            </b>
                                        </div>
                                    </Space>
                                </Row>
                                <Divider /> */}
                                <Row justify={'left'}><b>ประวัติคนกู้ / ทะเบียนบ้าน / อาชีพ <u style={{ color: 'red' }}>(อัพโหลดไฟล์ไม่เกิน 3MB)</u></b></Row>
                                <Row gutter={32} justify={'center'}>
                                    <Space direction="vertical" >
                                        <Row justify={'center'}>
                                            {imageBlobzz?.map((e, index) => {
                                                if (e.type === 6) {
                                                    return (
                                                        <>
                                                            <Row gutter={32} justify={'center'}>
                                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                                    <Image style={{ margin: 3 }} width={'100px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </Row>
                                        <div className='gutter-row' span={12}>
                                            <b> <Form.Item label='เพิ่มรูป' name='imageIDGuCar'
                                            >
                                                <Upload
                                                    accept="image/*"
                                                    name='imageIDGuCar'
                                                    multiple={true}
                                                    listType="picture-card"
                                                    fileList={fileListCustomerImageCareer}
                                                    onPreview={handlePreview}
                                                    onChange={handleCustomerImageCareer}
                                                    // beforeUpload={() => false}
                                                    beforeUpload={(file) => beforeUpts(file, 6, land.landId).then(() => false).catch(() => true)}
                                                >
                                                    {fileListCustomerImageCareer?.length >= 10 ? null : (
                                                        <div>
                                                            <PlusOutlined />
                                                            <div className="ant-upload-text">upload</div>
                                                        </div>
                                                    )}
                                                </Upload>
                                                <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                                    <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                </Modal>
                                            </Form.Item>
                                            </b>
                                        </div>
                                    </Space>
                                </Row>
                                <Divider />
                                <Row justify={'left'}><b>สเตทเม้น รูปภาพ <u style={{ color: 'red' }}>(อัพโหลดไฟล์ไม่เกิน 3MB)</u></b></Row>
                                <Row gutter={32} justify={'center'}>
                                    <Space direction="vertical" >
                                        <Row justify={'center'}>
                                            {imageBlobzz?.map((e, index) => {
                                                if (e.type === 8) {
                                                    return (
                                                        <>
                                                            <Row gutter={32} justify={'center'}>
                                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                                    <Image style={{ margin: 3 }} width={'100px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                </Col>

                                                            </Row>
                                                        </>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </Row>
                                        <div className='gutter-row' span={12}>
                                            <b> <Form.Item label='เพิ่มรูป' name='imageIDGuCar'
                                            >
                                                <Upload
                                                    accept="image/*"
                                                    name='imageIDGuCar'
                                                    multiple={true}
                                                    listType="picture-card"
                                                    fileList={fileListStatement}
                                                    onPreview={handlePreview}
                                                    onChange={handleStatement}
                                                    // beforeUpload={() => false}
                                                    beforeUpload={(file) => beforeUpts(file, 8, land.landId).then(() => false).catch(() => true)}
                                                >
                                                    {fileListStatement?.length >= 10 ? null : (
                                                        <div>
                                                            <PlusOutlined />
                                                            <div className="ant-upload-text">upload</div>
                                                        </div>
                                                    )}
                                                </Upload>
                                                <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                                    <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                </Modal>
                                            </Form.Item>
                                            </b>
                                        </div>
                                    </Space>
                                </Row>
                                <Divider />
                                <Row justify={'left'}><b>สเตทเม้น PDF <u style={{ color: 'red' }}>(อัพโหลดไฟล์ไม่เกิน 3MB)</u></b></Row>
                                <Row gutter={32} justify={'center'}>
                                    <Space direction="vertical" >
                                        <Row justify={'center'}>
                                            {imageBlobzz?.map((e, index) => {
                                                if (e.type === 7) {
                                                    return (
                                                        <>
                                                            <Row gutter={32} justify={'center'}>
                                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                                    <Space direction='vertical'>
                                                                        <object data={`${getImagess}/${e.pathImage}?token=${token}`} type="application/pdf" width="350px" height="450px">
                                                                            <p>Alternative text - include a link <a href={`${getImagess}/${e.pathImage}?token=${token}`}>to the PDF!</a></p>
                                                                        </object>
                                                                        <p></p>
                                                                    </Space>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    )
                                                }
                                                return null;
                                            })}
                                        </Row >
                                        <div className='gutter-row' span={12}>
                                            <b> <Form.Item label='เพิ่มรูป' name='imageIDGuCar'
                                            >
                                                <Upload
                                                    accept="application/pdf"
                                                    name='imageIDGuCar'
                                                    multiple={true}
                                                    listType="picture-card"
                                                    fileList={fileListStatementPDF}
                                                    onPreview={handlePreview}
                                                    onChange={handleStatementPDF}
                                                    // beforeUpload={() => false}
                                                    beforeUpload={(file) => beforeUpts(file, 7, land.landId).then(() => false).catch(() => true)}
                                                >
                                                    {fileListStatementPDF?.length >= 10 ? null : (
                                                        <div>
                                                            <PlusOutlined />
                                                            <div className="ant-upload-text">upload</div>
                                                        </div>
                                                    )}
                                                </Upload>
                                                <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                                    <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                </Modal>
                                            </Form.Item>
                                            </b>
                                        </div>
                                    </Space>
                                </Row>
                                <Divider style={{ margin: 5 }} />
                                <Row justify={'center'} >
                                    <Space>
                                        <Button type="text" style={{ background: "#e0e0e0" }} onClick={handleCancel}>
                                            ยกเลิก
                                        </Button>
                                        <Button type="primary" htmlType="submit"  >
                                            ต่อไป
                                        </Button>
                                    </Space>
                                </Row>
                                {/* </Spin> */}
                            </Card>
                        </div>
                    </Form>

                </Row>
            </Spin>
        </>
    )
}
