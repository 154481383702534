import React, { useState, useEffect, useRef } from 'react'
import { Form, Spin, Divider, Col, Row, Space, Tag, Image, Button, Card } from 'antd';
import { HomeOutlined, PrinterOutlined, } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from "react-to-print";
import ModalDrescriptionPrint from '../reports/ModalDrescriptionPrint'
import ModalOfferPrint from '../reports/ModalOffer';
import axios from 'axios';

import { addCustomer } from '../../../redux/Customer'
import { addAddress } from '../../../redux/Address'
import { addPhone } from '../../../redux/Phone'
import { addCar } from '../../../redux/Car'
import { addLoan } from '../../../redux/Loan'
import { addOldLoan } from '../../../redux/OldLoan'
import { addImage } from '../../../redux/Image'
import { addCareerIncome } from '../../../redux/CareerIncome';
import { addGuarantor } from '../../../redux/Guarantor';
import { loadimage, getImagess } from '../../file_mid/all_api';

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

function Drescription() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const conponentPDF = useRef();
    const [form] = Form.useForm()

    const generatePDF = useReactToPrint({
        content: () => conponentPDF.current,
        documentTitle: "CarRate0",
        // onAfterPrint:()=>alert("Data saved in PDF")
    });

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');

    const [loading, setLoading] = useState(false)
    const [modalDrescriptionPrint, setModalDrescriptionPrint] = useState(false); // Info Print
    const [modalOfferPrint, setModalOfferPrint] = useState(false); // Info Print
    //From Redux
    const { Phones } = useSelector(state => (state))
    const { customers } = useSelector(state => (state))
    const { guarantor } = useSelector(state => (state))
    const { cars } = useSelector(state => (state))
    const { loans } = useSelector(state => (state))
    const { oldloans } = useSelector(state => (state))
    const { imgs } = useSelector(state => (state))
    //Set State
    const [cus, setCus] = useState(customers.data)
    const [address, setAddresss] = useState(customers.data.address)
    const [phones, setPhones] = useState(Phones.data)
    const [email, setEmail] = useState(customers.data)
    const [socials, setSocials] = useState(customers.data)
    const [car, setCar] = useState(cars.data)
    const [carloan, setCarLoan] = useState(loans.data)
    const [caroldloan, setCarOldLoan] = useState(oldloans.data)
    const [guarantors, setGuarantor] = useState(guarantor.data)
    const [guarantorsAddress, setGuarantorAddress] = useState(guarantor.data)

    const token = localStorage.getItem('token');
    const [dataCustomer, setDataCustomer] = useState([]);
    const [dataAddress, setDataAddress] = useState([]);
    const [dataPhoneCus, setDataPhoneCus] = useState([]);
    const [dataPhone, setDataPhone] = useState([]);
    const [dataEmail, setDataEmail] = useState([]);
    const [dataSocials, setDataSocials] = useState([]);
    const [dataCar, setdataCar] = useState([]);
    const [dataCarLoan, setDataCarLoan] = useState([]);
    const [dataCarOldLoan, setDataCarOldLoan] = useState([]);
    const [dataGuarantor, setDataGuarantor] = useState([]);
    const [dataGuarantorAddress, setDataGuarantorAddress] = useState([]);
    const [imageBlobzz, setImageBlobzz] = useState([]);
    const fileList = imgs.fileList

    useEffect(() => {
        loadImage()
        setDataCustomer({ ...cus })
        setDataAddress({ ...address })
        setDataPhoneCus([...cus.phones])
        setDataPhone({ ...phones })
        setDataEmail({ ...email.emails[0] })
        setDataSocials({ ...socials.socials[0] })
        setdataCar({ ...car.DATA.carLoanDetails[0].cars[0] })
        setDataCarLoan({ ...carloan })
        setDataCarOldLoan({ ...caroldloan })
        setDataGuarantor({ ...guarantors })
        setDataGuarantorAddress({ ...guarantorsAddress.address[0] })
    }, [])

    const loadImage = async () => {
        await axios.post(loadimage, { carId: cars.data.DATA.carLoanDetails[0].cars[0].carId })
            .then(res => {
                if (res.status === 200) {
                    console.log("imageCAR", res.data)
                    setImageBlobzz(res.data)
                } else if (res.status === 204) {
                    // NotiErrorIMG("top")
                }
            }).catch(err => {
                console.log(err)
            })
    }

    // const printOffer = () => {
    //     setModalOfferPrint(true)
    // }
    const handleSubmit = async (e) => {
    }
    //buuton Previos 
    const handlePrevios = (e) => {
        navigate('/addcustomer/guarantor')
    }

    const handletoMainpage = (e) => {
        dispatch(addCustomer())
        dispatch(addAddress())
        dispatch(addPhone())
        dispatch(addCar())
        dispatch(addLoan())
        dispatch(addOldLoan())
        dispatch(addImage())
        dispatch(addCareerIncome())
        dispatch(addGuarantor())
        setLoading(false)
        navigate('/addcustomer')
    }

    //ImageShow
    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    //print Report
    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/')));
    };
    const renderItemPhones = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
        return (
            <>
                <Form
                    name='basic'
                    labelCol={{
                        span: 16,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    style={{
                        width: '100%',
                    }}
                    autoComplete="off"
                    initialValues={{ remember: true }}
                >
                    <Row gutter={32}>
                        <Col className='gutter-row' span={24}>
                            <Form.Item
                                style={{ marginBottom: 0 }}
                                label={item.phoneType}
                            >
                                <b> {item.telp}</b>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </>
        )
    }
    const renderItemAddress = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
        return (
            <>
                <Form
                    name='basic'
                    labelCol={{
                        span: 12,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    style={{
                        width: '100%',
                    }}
                    autoComplete="off"
                    initialValues={{ remember: true }}
                >
                    <Row gutter={32}>
                        <Col className='gutter-row' span={9}>
                            <Form.Item
                                style={{ marginBottom: 0 }}
                                label='เลขทะเบียนบ้าน'
                            >
                                <b>{item.houseRegistrationNumber}</b>
                            </Form.Item>
                            <Form.Item
                                style={{ marginBottom: 0 }}
                                label='ถนน'
                            >
                                <b>{item.road}</b>
                            </Form.Item>
                            <Form.Item
                                style={{ marginBottom: 0 }}
                                label='ตำบล'
                            >
                                <b>{item.subdistrict}</b>
                            </Form.Item>

                        </Col>
                        <Col className='gutter-row' span={7}>
                            <Form.Item
                                style={{ marginBottom: 0 }}
                                label='บ้านเลขที่'
                            >
                                <b>{item.houseNumber}</b>
                            </Form.Item>
                            <Form.Item
                                style={{ marginBottom: 0 }}
                                label='หมู่บ้าน'
                            >
                                <b>{item.village}</b>
                            </Form.Item>
                            <Form.Item
                                style={{ marginBottom: 0, textAlign: 'center' }}
                                label='อำเภอ'
                            >
                                <b>{item.district}</b>
                            </Form.Item>

                        </Col>
                        <Col className='gutter-row' span={8}>
                            <Form.Item
                                style={{ marginBottom: 0, textAlign: 'center' }}
                                label='หมู่ / ซอย'
                            >
                                <b>{item.soi}</b>
                            </Form.Item>

                            <Form.Item
                                style={{ marginBottom: 0, textAlign: 'center' }}
                                label='จังหวัด'
                            >
                                <b>{item.province}</b>
                            </Form.Item>
                            <Form.Item
                                style={{ marginBottom: 0, textAlign: 'center' }}
                                label='รหัสไปรษณีย์'
                            >
                                <b>{item.zipCode}</b>
                            </Form.Item>

                        </Col>
                    </Row>
                </Form>
                <Divider style={{ margin: 5 }} />
            </>
        )
    }

    return (
        <Row justify={'center'}>
            <Card style={{ width: '100%', margin: 5 }} ref={conponentPDF}>
                <Spin spinning={loading} size='large' tip=" Loading... ">
                    <Row justify={'center'}>
                        <Col className='gutter-row' span={24} style={{ fontSize: '25px', textAlign: 'center' }}>
                            <b>รายละเอียดและคนค้ำประกัน</b>
                        </Col>
                    </Row>
                    <Divider style={{ margin: 5 }} />
                    <Form
                        labelCol={{
                            span: 12,
                        }}
                        wrapperCol={{
                            span: 18,
                        }}
                        style={{
                            width: '100%',
                        }}
                        form={form}
                        onFinish={handleSubmit}
                        autoComplete="off"
                        initialValues={{ remember: true }}
                    >
                        <Row gutter={32} justify={'center'}>
                            <Card style={{ width: '1000px' }}>
                                <Row gutter={32}>
                                    <Col span={11} className='gutter-row' style={{ textAlign: 'right' }}>
                                    </Col>
                                    <Col span={7} className='gutter-row' style={{ textAlign: 'right' }}>
                                        <Form.Item>
                                            แบบเอกสาร : <b><Tag color='cyan'>{dataCarLoan.productLoanType}</Tag></b>
                                        </Form.Item>
                                    </Col>
                                    <Col span={6} className='gutter-row' style={{ textAlign: 'right' }}>
                                        <Form.Item>
                                            สถานะ : <b><Tag color='cyan'>{dataCarLoan.approvalStatus}</Tag></b>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row justify={'left'}><b><u>รายละเอียดผู้ขอกู้</u></b></Row>
                                <Row gutter={32}>
                                    <Col className='gutter-row' span={8}>

                                        <Form.Item
                                            style={{ marginBottom: 0, textAlign: 'center' }}
                                            label='ชื่อ-นามสกุล'
                                        >
                                            <b>{dataCustomer.firstname + '   ' + dataCustomer.lastname}</b>
                                        </Form.Item>

                                        <Form.Item
                                            style={{ marginBottom: 0, textAlign: 'center' }}
                                            label='ชื่อเล่น'
                                        >
                                            <b>{dataCustomer.nickname}</b>
                                        </Form.Item>
                                    </Col>
                                    <Col className='gutter-row' span={7}>

                                        <Form.Item
                                            style={{ marginBottom: 0, textAlign: 'center' }}
                                            label='เลขบัตรประชาชน'
                                        >
                                            <b>{dataCustomer.identificationId}</b>
                                        </Form.Item>
                                        <Row gutter={32}>
                                            <Col className='gutter-row' span={24}>
                                                <Form.Item
                                                    style={{ marginBottom: 0, textAlign: 'center' }}
                                                >
                                                    <b>
                                                        {/* {dataCustomer.phones.telp} */}
                                                        {
                                                            dataPhoneCus.map((item, index) => {
                                                                return renderItemPhones({ item, index })
                                                            })
                                                        }
                                                    </b>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className='gutter-row' span={9}>
                                        <Form.Item
                                            style={{ margin: 0, textAlign: 'center' }}
                                            label='เพศ'
                                        >
                                            <b>{dataCustomer.gender}</b>
                                        </Form.Item>
                                        <Form.Item
                                            style={{ marginBottom: 0, textAlign: 'center' }}
                                            label={dataEmail ? <>email</> : null}
                                        >
                                            <b>{dataEmail.emailData}</b>
                                        </Form.Item>
                                        <Form.Item
                                            style={{ marginBottom: 0, textAlign: 'center' }}
                                            label={dataSocials.socialType}
                                        >
                                            <b>{dataSocials.socialData}</b>
                                        </Form.Item>

                                    </Col>
                                </Row>
                            </Card>
                        </Row>
                        {/* ที่อยู่ */}
                        <>
                            <Row gutter={32} justify={'center'}>
                                <Card style={{ width: '1000px' }}>
                                    <Row justify={'left'}><b><u>รายละเอียดที่อยู่</u></b></Row>
                                    <Row justify={'center'} gutter={32}>
                                        {
                                            address.map((item, index) => {
                                                return renderItemAddress({ item, index })
                                            })
                                        }
                                        {/* <Col className='gutter-row' span={9}>
                                            <Form.Item
                                                style={{ marginBottom: 0 }}
                                                label='เลขทะเบียนบ้าน'
                                            >
                                                <b>{dataAddress.houseRegistrationNumber}</b>
                                            </Form.Item>
                                            <Form.Item
                                                style={{ marginBottom: 0 }}
                                                label='ถนน'
                                            >
                                                <b>{dataAddress.road}</b>
                                            </Form.Item>
                                            <Form.Item
                                                style={{ marginBottom: 0 }}
                                                label='ตำบล'
                                            >
                                                <b>{dataAddress.subdistrict}</b>
                                            </Form.Item>

                                        </Col>
                                        <Col className='gutter-row' span={8}>
                                            <Form.Item
                                                style={{ marginBottom: 0 }}
                                                label='บ้านเลขที่'
                                            >
                                                <b>{dataAddress.houseNumber}</b>
                                            </Form.Item>
                                            <Form.Item
                                                style={{ marginBottom: 0 }}
                                                label='หมู่บ้าน'
                                            >
                                                <b>{dataAddress.village}</b>
                                            </Form.Item>
                                            <Form.Item
                                                style={{ marginBottom: 0, textAlign: 'center' }}
                                                label='อำเภอ'
                                            >
                                                <b>{dataAddress.district}</b>
                                            </Form.Item>

                                        </Col>
                                        <Col className='gutter-row' span={7}>
                                            <Form.Item
                                                style={{ marginBottom: 0, textAlign: 'center' }}
                                                label='หมู่ / ซอย'
                                            >
                                                <b>{address.soi}</b>
                                            </Form.Item>

                                            <Form.Item
                                                style={{ marginBottom: 0, textAlign: 'center' }}
                                                label='จังหวัด'
                                            >
                                                <b>{dataAddress.province}</b>
                                            </Form.Item>
                                            <Form.Item
                                                style={{ marginBottom: 0, textAlign: 'center' }}
                                                label='รหัสไปรษณีย์'
                                            >
                                                <b>{dataAddress.zipCode}</b>
                                            </Form.Item>
                                        </Col> */}
                                    </Row>
                                </Card>
                            </Row>
                        </>
                        {dataCarLoan.productLoanType === 'ย้ายไฟแนนซ์' ?
                            <>
                                <Row gutter={32} justify={'center'}>
                                    <Card style={{ width: '1000px' }}>
                                        <Row justify={'left'}><b><u>รายละเอียดย้ายไฟแนนซ์เดิม</u></b></Row>
                                        <Row justify={'center'} gutter={32}>
                                            <Col className='gutter-row' span={8}>
                                                <Form.Item
                                                    style={{ marginBottom: 0, textAlign: 'center' }}
                                                    label='ยอดปิดไฟแนนซ์'
                                                >
                                                    <b>{dataCarOldLoan.oldLoanAmount}</b>
                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                        บาท
                                                    </span>
                                                </Form.Item>
                                                <Form.Item
                                                    style={{ marginBottom: 0, textAlign: 'center' }}
                                                    label='ค่างวด / เดือน'
                                                >
                                                    <b>{dataCarOldLoan.oldMonthlyPayment}</b>
                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                        บาท
                                                    </span>
                                                </Form.Item>
                                            </Col>
                                            <Col className='gutter-row' span={8}>
                                                <Form.Item
                                                    style={{ marginBottom: 0, textAlign: 'center' }}
                                                    label='จำนวนงวดที่ผ่อน'
                                                >
                                                    <b>{dataCarOldLoan.oldLoanTerm}</b>
                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                        งวด
                                                    </span>
                                                </Form.Item>
                                                <Form.Item
                                                    style={{ marginBottom: 0, textAlign: 'center' }}
                                                    label='ยอดที่ค้างทั้งหมด'
                                                >
                                                    <b>{dataCarOldLoan.oldKangPayment}</b>
                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                        บาท
                                                    </span>
                                                </Form.Item>
                                            </Col>
                                            <Col className='gutter-row' span={8}>
                                                <Form.Item
                                                    style={{ marginBottom: 0, textAlign: 'center' }}
                                                    label='ค้างกี่งวด'
                                                >
                                                    <b>{dataCarOldLoan.oldKangPaymentTerm}</b>
                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                        งวด
                                                    </span>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Row>
                            </>
                            :
                            <>
                                <Row gutter={32} justify={'center'}>
                                    <Card style={{ width: '1000px' }}>
                                        <Row justify={'left'}><b><u>รายละเอียดถือเล่มมา</u></b></Row>
                                        {dataCarLoan.productType === 'เช่าซื้อ' ?
                                            <>
                                                <Row justify={'center'} gutter={32}>
                                                    <Col className='gutter-row' span={8}>
                                                        <Form.Item
                                                            style={{ marginBottom: 0, textAlign: 'center' }}
                                                            label='ยอดกู้เช่าซื้อ'
                                                        >
                                                            <b>{dataCarLoan.loanAmountt}</b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </Form.Item>
                                                        <Form.Item
                                                            style={{ marginBottom: 0, textAlign: 'center' }}
                                                            label='จำนวนงวดเช่าซื้อ'
                                                        >
                                                            <b>{dataCarLoan.installmentAmountt}</b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col className='gutter-row' span={8}>
                                                        <Form.Item
                                                            style={{ marginBottom: 0, textAlign: 'center' }}
                                                            label='ดอกเบี้ยเช่าซื้อ'
                                                        >
                                                            <b>{dataCarLoan.interestt}</b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                งวด
                                                            </span>
                                                        </Form.Item>
                                                        <Form.Item
                                                            style={{ marginBottom: 0, textAlign: 'center' }}
                                                            label='จำนวนผ่อนต่อเดือนเช่าซื้อ'
                                                        >
                                                            <b>{dataCarLoan.monthlyPayment}</b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col className='gutter-row' span={8}>
                                                        <Form.Item
                                                            style={{ marginBottom: 0, textAlign: 'center' }}
                                                            label='ผ่อนรวมดอกเบี้ยเช่าซื้อ'
                                                        >
                                                            <b>{dataCarLoan.installmentWithInterestt}</b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </Form.Item>
                                                        <Form.Item
                                                            style={{ marginBottom: 0, textAlign: 'center' }}
                                                            label='ผู้เสนอเคสเช่าซื้อ'
                                                        >
                                                            <b>{dataCarLoan.proposalBy}</b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </Form.Item>
                                                        <b> <Form.Item
                                                            style={{ margin: 0, textAlign: 'center' }}
                                                            label='เรทรถ'
                                                        >
                                                            <b style={{ fontSize: '20px' }}><u>{dataCar.carPrice}</u></b>

                                                            <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                                                บาท
                                                            </span>
                                                        </Form.Item>
                                                        </b>
                                                    </Col>

                                                </Row>
                                            </>
                                            :
                                            <>
                                                <Row justify={'center'} gutter={32}>
                                                    <Col className='gutter-row' span={8}>
                                                        <Form.Item
                                                            style={{ marginBottom: 0, textAlign: 'center' }}
                                                            label='ยอดกู้ p-loan'
                                                        >
                                                            <b>{dataCarLoan.loanAmountt}</b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </Form.Item>
                                                        <Form.Item
                                                            style={{ marginBottom: 0, textAlign: 'center' }}
                                                            label='จำนวนงวด p-loan'
                                                        >
                                                            <b>{dataCarLoan.installmentAmountt}</b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col className='gutter-row' span={8}>
                                                        <Form.Item
                                                            style={{ marginBottom: 0, textAlign: 'center' }}
                                                            label='ดอกเบี้ย p-loan'
                                                        >
                                                            <b>{dataCarLoan.interestt}</b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                งวด
                                                            </span>
                                                        </Form.Item>
                                                        <Form.Item
                                                            style={{ marginBottom: 0, textAlign: 'center' }}
                                                            label='จำนวนผ่อน/เดือน p-loan'
                                                        >
                                                            <b>{dataCarLoan.monthlyPayment}</b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col className='gutter-row' span={8}>
                                                        <Form.Item
                                                            style={{ marginBottom: 0, textAlign: 'center' }}
                                                            label='ผ่อนรวมดอกเบี้ย p-loan'
                                                        >
                                                            <b>{dataCarLoan.installmentWithInterestt}</b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </Form.Item>
                                                        <Form.Item
                                                            style={{ marginBottom: 0, textAlign: 'center' }}
                                                            label='ผู้เสนอเคส p-loan'
                                                        >
                                                            <b>{dataCarLoan.proposalBy}</b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </Form.Item>
                                                        <b> <Form.Item
                                                            style={{ margin: 0, textAlign: 'center' }}
                                                            label='เรทรถ'
                                                        >
                                                            <b style={{ fontSize: '20px' }}><u>{dataCar.carPrice}</u></b>

                                                            <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                                                บาท
                                                            </span>
                                                        </Form.Item>
                                                        </b>
                                                    </Col>
                                                </Row>
                                            </>
                                        }
                                    </Card>
                                </Row>
                            </>
                        }
                        {dataCar.productTypeCar === 1 || dataCar.productTypeCar === 2 || dataCar.productTypeCar === 3 ?
                            <>
                                <Row gutter={32} justify={'center'}>
                                    <Card style={{ width: '1000px' }}>
                                        <Row justify={'left'}><b><u>รายละเอียดรถ</u></b></Row>
                                        <Row gutter={32} justify={'center'}>
                                            <Col className='gutter-row' span={8}>
                                                <Form.Item
                                                    style={{ marginBottom: 0, textAlign: 'center' }}
                                                    label='ยี่ห้อ'
                                                >
                                                    <b>{dataCar.carBrand}</b>
                                                </Form.Item>
                                                <Form.Item
                                                    style={{ marginBottom: 0, textAlign: 'center' }}
                                                    label='เลขทะเบียน'
                                                >
                                                    <b>{dataCar.carPlateNumber}</b>
                                                </Form.Item>
                                                <Form.Item
                                                    style={{ marginBottom: 0, textAlign: 'center' }}
                                                    label='เลขเครื่อง'
                                                >
                                                    <b>{dataCar.carEngineNumber}</b>
                                                </Form.Item>
                                            </Col>
                                            <Col className='gutter-row' span={8}>

                                                <Form.Item
                                                    style={{ marginBottom: 0, textAlign: 'center' }}
                                                    label='รุ่นสินค้า'
                                                >
                                                    <b>{dataCar.carModel}</b>
                                                </Form.Item>
                                                <Form.Item
                                                    style={{ marginBottom: 0, textAlign: 'center' }}
                                                    label='เลขคัสซี'
                                                >
                                                    <b>{dataCar.carChassisNumber}</b>
                                                </Form.Item>
                                                <Form.Item
                                                    style={{ marginBottom: 0, textAlign: 'center' }}
                                                    label='สี'
                                                >
                                                    <b>{dataCar.carColor}</b>
                                                </Form.Item>


                                                {/* <Form.Item
                                            style={{ marginBottom: 0, textAlign: 'center' }}
                                            label='จังหวัด'
                                        >
                                            <b>{dataCar.carBrand}</b>
                                        </Form.Item>  */}
                                            </Col>
                                            <Col className='gutter-row' span={8}>
                                                <Form.Item
                                                    style={{ marginBottom: 0, textAlign: 'left' }}
                                                    label='แบบรถ'
                                                >
                                                    <b>{dataCar.carBaab}</b>
                                                </Form.Item>
                                                <Form.Item
                                                    style={{ marginBottom: 0, textAlign: 'center' }}
                                                    label='ปีรถ'
                                                >
                                                    <b>{dataCar.carYear}</b>
                                                </Form.Item>


                                            </Col>
                                        </Row>
                                    </Card>
                                </Row>
                            </>
                            :
                            <>
                                <Row gutter={32} justify={'center'}>
                                    <Card style={{ width: '1000px' }}>
                                        <Row justify={'left'}><b><u>รายละเอียดที่ดิน</u></b></Row>
                                        <Row gutter={32} justify={'center'}>
                                            <Col className='gutter-row' span={8}>
                                                <Form.Item
                                                    style={{ marginBottom: 0, textAlign: 'center' }}
                                                    label='เลขโฉนด'
                                                >
                                                    {/* <b>{getData.car.carBrand}</b> */}
                                                </Form.Item>

                                            </Col>
                                            <Col className='gutter-row' span={8}>
                                                <Form.Item
                                                    style={{ marginBottom: 0, textAlign: 'center' }}
                                                    label='ขนาด'
                                                >
                                                    {/* <b>{getData.car.carModel}</b> */}
                                                </Form.Item>

                                            </Col>
                                            <Col className='gutter-row' span={8}>
                                                <Form.Item
                                                    style={{ marginBottom: 0, textAlign: 'center' }}
                                                    label='สี'
                                                >
                                                    {/* <b>{getData.car.carColor}</b> */}
                                                </Form.Item>

                                            </Col>
                                        </Row>
                                    </Card>
                                </Row>
                            </>
                        }
                        {/* คนค้ำ */}
                        <Row gutter={32} justify={'center'}>
                            <Card style={{ width: '1000px' }}>
                                <Row justify={'left'}><b><u>คนค้ำประกัน</u></b></Row>
                                <Row gutter={32} justify={'center'}>
                                    <Col className='gutter-row' span={8}>
                                        <Form.Item
                                            style={{ marginBottom: 0, textAlign: 'center' }}
                                            label='ชื่อ-นามสกุล'
                                        >
                                            <b>{dataGuarantor.firstname + ' ' + dataGuarantor.lastname}</b>
                                        </Form.Item>
                                    </Col>
                                    <Col className='gutter-row' span={8}>
                                        <Form.Item
                                            style={{ marginBottom: 0, textAlign: 'center' }}
                                            label='เลขบัตรประชาชน'
                                        >
                                            <b>{dataGuarantor.identificationId}</b>
                                        </Form.Item>
                                    </Col>
                                    <Col className='gutter-row' span={8}>
                                        <Form.Item
                                            style={{ marginBottom: 0, textAlign: 'center' }}
                                            label='เบอร์โทร'
                                        >
                                            <b>{dataGuarantor.telp}</b>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={32} >
                                    <Col className='gutter-row' span={24}>
                                        <Form.Item
                                            style={{ marginBottom: 0, textAlign: 'center' }}
                                        >
                                            <span>ที่อยู่ : </span>
                                            <b>
                                                {
                                                    dataGuarantorAddress.houseNumber + ' ' +
                                                    "หมู่ " + dataGuarantorAddress.soi + ' ' +
                                                    "ถ." + dataGuarantorAddress.road + ' ' +
                                                    "ต." + dataGuarantorAddress.subdistrict + ' ' +
                                                    "อ." + dataGuarantorAddress.district + ' ' +
                                                    "จ." + dataGuarantorAddress.province + ' '
                                                    + dataGuarantorAddress.zipCode

                                                }
                                            </b>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card>
                        </Row>
                        {dataCar.productTypeId !== 1 ?
                            <>
                                <Row gutter={32} justify={'center'}>
                                    <Card style={{ width: '1000px' }}>
                                        <Row justify={'left'}><b><u>รูปรถ</u></b></Row>
                                        <Row gutter={32} justify={'center'}>
                                            <Space>
                                                {imageBlobzz.map((e, index) => (
                                                    <Image width={400} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                ))}
                                            </Space>
                                        </Row>
                                    </Card>
                                </Row>
                            </>
                            :
                            <>
                                <Row gutter={32} justify={'center'}>
                                    <Card style={{ width: '1000px' }}>
                                        <Row justify={'left'}><b><u>รูปที่ดิน</u></b></Row>
                                        <Row gutter={32} justify={'center'}>
                                            <Space>
                                                {imageBlobzz.map((e, index) => (
                                                    <Image width={400} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                ))}
                                            </Space>
                                        </Row>
                                    </Card>
                                </Row>
                            </>
                        }
                    </Form>
                </Spin>
            </Card>
            <Card style={{ margin: 0, width: '100%' }}>
                <Row gutter={32} justify={'center'}>
                    {/* <Button
                        style={{ margin: '5px', background: '#597ef7', color: '#ffffff' }}
                        onClick={printOffer}
                        icon={<PrinterOutlined />}
                    >
                        พิมพ์ใบเสนอราคา
                    </Button> */}
                    <Button
                        style={{ margin: '5px', background: '#597ef7', color: '#ffffff' }}
                        onClick={generatePDF}
                        icon={<PrinterOutlined />}
                    >
                        พิมพ์รายละเอียด
                    </Button>
                </Row>
                <Row gutter={32} justify={'center'}>
                    <Col className='gutter-row' span={12} style={{ textAlign: 'left' }}>
                        <Button
                            style={{ margin: '3px', marginBottom: 0, background: '#bfbfbf', color: '#000000', }}
                            type='primary'
                            onClick={handlePrevios}
                        >
                            ย้อนกลับ
                        </Button>
                    </Col>
                    <Col className='gutter-row' span={12} style={{ textAlign: 'right' }}>
                        <Button
                            style={{ margin: '5px', background: '#597ef7', color: '#ffffff' }}
                            onClick={handletoMainpage}
                            icon={<HomeOutlined />}
                        >
                            หน้าหลัก
                        </Button>
                    </Col>
                </Row>
            </Card>
            {
                modalDrescriptionPrint ?
                    <ModalDrescriptionPrint
                        open={modalDrescriptionPrint}
                        close={setModalDrescriptionPrint}
                    />
                    : null
            }
            {
                modalOfferPrint ?
                    <ModalOfferPrint
                        open={modalOfferPrint}
                        close={setModalOfferPrint}
                    />
                    : null
            }
        </Row>
    )
}

export default Drescription

