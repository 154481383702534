import React, { useState } from "react";
import { Layout, Button, Row, Col, Typography, Form, Input, Card, Modal, Spin, Divider } from "antd";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { resetpassword } from "./file_mid/all_api";

const { Text, Title } = Typography;
const { Footer, Content } = Layout;

export default function ResetPass() {
    const user = localStorage.getItem('username');
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [dataPost, setDataPost] = useState({})

    const updatePass = async () => {
        // console.log("user ", {username: user})
        // console.log("pass ", {password: dataPost.password1})
        const result = { username: user, password: dataPost.password1 }
        //console.log(result)
        setLoading(true)
        await axios.put(resetpassword, result)
            .then((res) => {
                if (res.data.status === "success") {
                    alert('เปลี่ยนรหัสผ่านสำเร็จ')
                    localStorage.removeItem('token')
                    localStorage.removeItem('username')
                    localStorage.removeItem('menu')
                    window.location.reload(false)
                    setLoading(false)
                }
                else {
                    alert('ข้อมูลไม่ครบถ้วน')
                    setLoading(false)
                }
            })
            .catch((err) => {
                if (err.response.request.status === 400) {
                    alert('ข้อมูลไม่ครบถ้วน')
                }
                else {
                    alert('ข้อมูลไม่ครบถ้วน')
                }
                setLoading(false)
            })
    };

    const onFinish = () => {
        if (dataPost.password1 !== '' && dataPost.password2 !== '') {
            if (dataPost.password1 === dataPost.password2) {
                updatePass()
            } else alert('รหัสผ่านไม่ตรงกัน')
        } else alert('ข้อมูลไม่ครบถ้วน')
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };
    return (
        <Card>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: 0,
                marginBottom: 10,
            }}>
                <div>
                    <Row style={{ textAlign: 'center', marginTop: '30px', marginBottom: '20px' }} >
                        <Col span={24}>
                            <b style={{ textAlign: 'center', color: '#002766', fontSize: '30px' }}>
                                <u>เปลี่ยนรหัสผ่านใหม่</u>
                            </b>
                        </Col>
                    </Row>
                </div>
                <Spin spinning={loading} size='large' tip=" Loading... ">
                    <Form
                        labelCol={{
                            span: 12,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}

                        form={form}
                        onFinish={onFinish}
                        autoComplete="off"
                    >
                        <Card style={{ padding: '15px' }}>
                            <Row justify={'center'}>
                                <Form.Item
                                    label={<span style={{ lineHeight: '32px', color: '#002766', fontFamily: 'sans - serif', fontSize: '18px' }}><b>รหัสผ่านใหม่</b></span>}
                                    name='password1'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your password!',
                                        },]}
                                >
                                    <Input.Password type='text' placeholder="รหัสผ่านใหม่"
                                        onChange={(e) => setDataPost({ ...dataPost, password1: e.target.value })}></Input.Password>
                                </Form.Item>

                                <Form.Item
                                    label={<span style={{ lineHeight: '32px', color: '#002766', fontFamily: 'sans - serif', fontSize: '18px' }}><b>ยืนยันรหัสผ่าน</b></span>}
                                    name='password2'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your password!',
                                        },]}
                                >
                                    <Input.Password type='text' placeholder="ยืนยันรหัสผ่าน"
                                        onChange={(e) => setDataPost({ ...dataPost, password2: e.target.value })}></Input.Password>
                                </Form.Item>

                                <div style={{ textAlign: "center" }}>
                                    <Button type="text" htmlType="submit" style={{ color: '#ffffff', background: 'green' }} >
                                        บันทึก</Button>
                                </div >
                            </Row>
                        </Card>

                    </Form>
                    <Divider />
                    <div
                        style={{
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            fontSize: '15px'
                        }}
                    >
                        <Text type="danger" style={{ fontSize: '18px' }}>
                            หากทำการเปลี่ยนรหัสผ่านแล้ว กรุณาจดจำรหัสผ่านของท่านให้ดี
                        </Text>
                        <Text type="danger" style={{ fontSize: '18px' }}>
                            เพราะทางแผนก IT ไม่สามารถทราบรหัสของท่านได้
                        </Text>
                        <Text type="danger" style={{ fontSize: '18px' }}>
                            นอกจากจะทำการรีเซ็ตรหัสผ่านให้ใหม่
                        </Text>
                    </div>
                </Spin>
            </div >
        </Card >
    )
}
