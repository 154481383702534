import './../App.css';
import './../modals/Index.css';
import { Button, Col, Row, Table, Input, Switch, Card, notification, Spin, Modal, message } from 'antd';
import React, { useState, useEffect } from 'react';
import ModalEdit from './../modals/ModalEdit';
import ModalCreate from './../modals/ModalCreate';
import axios from 'axios';
import SetPassword from './../modals/SetPassword';
import ModalSwitch from './../modals/ModalSwitch';
import dayjs from 'dayjs';
import SetPermissions from './../modals/SetPermissions';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { getuser, getUser, updateSwitch, updeateSwitch } from '../../file_mid/all_api';

const { Search } = Input;
const { confirm } = Modal;

const Listofemployees = () => {
    const pageSize = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const idSep = localStorage.getItem('idSep');
    const idDepartment = localStorage.getItem('idDepartment');
    const roleId = localStorage.getItem('roleId');

    const [filteredMembers, setFilteredMembers] = useState([]);
    const [members, setMembers] = useState([]);
    const [modalSetPassword, setModalSetPassword] = useState(false);
    const [modalSwitch, setModalSwitch] = useState(false);
    const [modalCreate, setModalCreate] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [modalSetPermissions, setModalSetPermissions] = useState(false);
    const [selectedMember, setSelectedMember] = useState(null);
    const [userID, setUserID] = useState({});
    const [loading, setLoading] = useState(false)
    const [check, setCheck] = useState({});
    const [api, contextHolder] = notification.useNotification();
    const [searchText, setSearchText] = useState("");
    const [genUsername, setGenUsername] = useState({
        UD: 0,
        LEX: 0,
        MIT: 0,
        S4: 0,
        K: 0,
        KSM: 0,

    })

// console.log('roleId',roleId)
// console.log('Type of roleId:', typeof roleId);
// console.log('idDepartment', idDepartment);
// console.log('idSep:', idSep);

    useEffect(() => {
        if (!modalSetPassword && !modalSwitch && !modalCreate && !openEdit && !modalSetPermissions) {
            fetchData();
            // setLoading(false);
        }
    }, [modalSetPassword, modalSwitch, modalCreate, openEdit, modalSetPermissions])

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${getuser}`);
            if (response.data) {
                setMembers(response.data);
                setFilteredMembers(response.data);


                let userIdCounts = {
                    UD: 0,
                    MIT: 0,
                    LEX: 0,
                    S4: 0,
                    K: 0,
                    KSM: 0,
                };
                let max = 0;
                let usernameUD = [];
                let usernameMIT = [];
                let usernameLEX = [];
                let usernameS4 = [];
                let usernameK = [];
                let usernameKSM = [];

                response.data?.map((member) => {
                    const userId = member.username;

                    if (userId.startsWith('UD')) {
                        userIdCounts.UD++;
                        const udNumber = parseInt(userId.slice(2));
                        if (udNumber > max) {
                            max = udNumber;
                        }
                        usernameUD.push(udNumber)

                    } else if (userId.startsWith('MIT')) {
                        userIdCounts.MIT++;
                        const mitNumber = parseInt(userId.slice(3));
                        if (mitNumber > max) {
                            max = mitNumber;
                        }
                        usernameMIT.push(mitNumber)

                    } else if (userId.startsWith('LEX')) {
                        userIdCounts.LEX++;
                        const lexNumber = parseInt(userId.slice(3));
                        if (lexNumber > max) {
                            max = lexNumber;
                        }
                        usernameLEX.push(lexNumber)

                    } else if (userId.startsWith('S4')) {
                        userIdCounts.S4++;
                        const s4Number = parseInt(userId.slice(2));
                        if (s4Number > max) {
                            max = s4Number;
                        }
                        usernameS4.push(s4Number)

                    } else if (userId.startsWith('K') && !userId.startsWith('KSM')) {
                        userIdCounts.K++;
                        const kNumber = parseInt(userId.slice(1));

                        if (!isNaN(kNumber)) {
                            if (kNumber > max) {
                                max = kNumber;
                            }
                            usernameK.push(kNumber);
                        }

                    } else if (userId.startsWith('KSM')) {
                        userIdCounts.KSM++;
                        const ksmNumber = parseInt(userId.slice(3));
                        if (!isNaN(ksmNumber)) {
                            if (ksmNumber > max) max = ksmNumber;
                            usernameKSM.push(ksmNumber);
                        }
                    }

                });

                let maxUD = Math.max(...usernameUD);
                let maxLEX = Math.max(...usernameLEX);
                let maxMIT = Math.max(...usernameMIT);
                let maxS4 = Math.max(...usernameS4);
                let maxK = Math.max(...usernameK);
                let maxKSM = Math.max(...usernameKSM);
                setGenUsername({
                    UD: maxUD,
                    LEX: maxLEX,
                    MIT: maxMIT,
                    S4: maxS4,
                    K: maxK,
                    KSM: maxKSM,
                })
                setUserID(userIdCounts)

            }
            setLoading(false);
            // console.log("responsedata", response.data)
        } catch (error) {
            console.error('Error fetching user:', error);
            setLoading(false);
        }
    };

    const openNotification = (placement) => {
        api.info({
            message: 'บันทึกสำเร็จ',
            description: 'ผู้ใช้นี้ถูกเปลี่ยนสถานะเรียบร้อย',
            placement,
        });
    };

    const upDateSwitch = async (member) => {
        const id = member.id;
        // `${getcontnoJojonoi}/${data?.CONTNO}`
        await axios.put(`${updateSwitch}/${id}`, member)
            .then(response => {
                // console.log('response.data', response.data);
                if (response.data === 'ok') {
                    openNotification('top');
                    setTimeout(() => {
                        // close(false);
                    }, 2000)
                }
                // message.success('แก้ไขข้อมูลข้อมูลสำเร็จ');
                fetchData(); // Fetch updated data after switch
            })
            .catch(error => {
                console.error('Error updating user:', error);
            });
    }

    const handleActiveChange = (checked, record) => {
        confirm({
            title: record.active_status ? `คุณต้องการปิดใช้งานผู้ใช้ ${record.username} หรือไม่ ?` : `คุณต้องการเปิดใช้งานผู้ใช้ ${record.username} หรือไม่ ?`,
            content: record.active_status ? 'ผู้ใช้นี้จะไม่สามารถใช้งานได้' : '',
            okText: 'ใช่',
            icon: <ExclamationCircleOutlined />,
            okType: 'danger',
            cancelText: 'ยกเลิก',
            width: 500,
            onOk() {
                const updatedMember = {
                    ...record,
                    active_status: checked
                };
                setCheck(checked);
                setSelectedMember(updatedMember);
                upDateSwitch(updatedMember);
                // console.log('active_status', record.active_status);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const onSearch = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchText(value);

        const filteredData = members.filter(member =>
            member.name.toLowerCase().includes(value) ||
            member.lastname.toLowerCase().includes(value) ||
            member.branch.toLowerCase().includes(value) ||
            member.nickname.toLowerCase().includes(value) ||
            member.username.toLowerCase().includes(value) ||
            member.position.toLowerCase().includes(value) ||
            member.department.toLowerCase().includes(value)
        );

        setFilteredMembers(filteredData);
    };

    const handleResetPassword = member => {
        setSelectedMember(member);
        setModalSetPassword(true)
    };

    const openSetPermissions = member => {
        setSelectedMember(member);
        setModalSetPermissions(true);
    };

    const onOpenEdit = member => {
        // setLoading(true);
        setSelectedMember(member);
        setOpenEdit(true);
        // console.log('member1111', member)
    };

    const createMember = member => {
        // setLoading(true);
        setModalCreate(true);
    };

    const addMember = newMember => {
        setMembers([
            ...members,
            { ...newMember, key: members.length + 1, no: members.length + 1 },
        ]);
        setFilteredMembers([
            ...filteredMembers,
            { ...newMember, key: filteredMembers.length + 1, no: filteredMembers.length + 1 },
        ]);
    };

    const updateMember = updatedMember => {
        const updatedMembers = members.map(member =>
            member.key === updatedMember.key ? updatedMember : member
        );
        setMembers(updatedMembers);
        setFilteredMembers(updatedMembers);
    };

    const handleTableChange = (pagination) => {
        setCurrentPage(pagination.current);
    };

    const columns = [
        {
            title: 'ลำดับที่่',
            // dataIndex: 'id',
            align: 'center',
            key: 'index',
            width: '5%',
            render: (text, record, index) => (currentPage - 1) * pageSize + index + 1,
        },
        {
            title: 'สถานะ',
            align: 'center',
            width: '5%',
            key: 'active_status',
            render: (record, index) => (
                <Switch
                    key={index}
                    checked={record.active_status}
                    onChange={(checked) => handleActiveChange(checked, record)}
                />
            ),
        },
        // {
        //     title: 'ID',
        //     dataIndex: 'id',
        //     key: 'id',
        //     width: '5%',
        // },
        {
            title: 'Username',
            align: 'center',
            dataIndex: 'username',
            key: 'username',
            width: '7%',
        },
        {
            title: 'ชื่อ-นามสกุล',
            align: 'center',
            dataIndex: 'fullname',
            key: 'fullname',
            width: '12%',
            render: (text, record) => `${record.name} ${record.lastname}`
        },
        {
            title: 'ชื่อเล่น',
            align: 'center',
            dataIndex: 'nickname',
            key: 'nickname',
            width: '5%',
        },
        {
            title: 'วันที่เริ่มงาน',
            align: 'center',
            dataIndex: 'created_at',
            width: '5%',
            key: 'created_at',
            render: (text, record) => `${dayjs(record.created_at).format('DD-MM-YYYY')}`
        },
        {
            title: 'วันที่แก้ไข',
            align: 'center',
            dataIndex: 'updated_at',
            key: 'updated_at',
            width: '5%',
            render: (text, record) => `${dayjs(record.updated_at).format('DD-MM-YYYY')}`
        },
        {
            title: 'สาขา',
            align: 'center',
            dataIndex: 'branch',
            key: 'branch',
            width: '5%',
        },
        {
            title: 'ตำแหน่ง',
            align: 'center',
            dataIndex: 'position_description',
            key: 'position_description',
            width: '5%',
        },
        {
            title: 'แผนก',
            align: 'center',
            dataIndex: 'department_description',
            key: 'department_description',
            width: '5%',
        },

        ...(idDepartment === '1' || idSep === '1' ? [
            {
                title: 'กำหนดสิทธิ์',
                align: 'center',
                key: 'set_permissions',
                width: '7%',
                render: (record) => (
                    <Button onClick={() => openSetPermissions(record)}>กำหนดสิทธิ์</Button>
                ),
            }
        ] : []),

        {
            title: 'แก้ไขข้อมูล',
            align: 'center',
            width: '5%',
            dataIndex: '',
            key: 'x',
            render: (record) => (
                <Button onClick={() => onOpenEdit(record)} > Edit </Button>
            ),
        },
        {
            title: 'รีเซ็ตรหัสผ่าน',
            align: 'center',
            width: '5%',
            dataIndex: '',
            key: 'x',
            render: (record) => (
                <Button
                    onClick={() => handleResetPassword(record)}
                    className='button-reset'
                >
                    Reset
                </Button>
            ),
        },
    ];

    const dataSource = filteredMembers.map(member => ({
        ...member,
        key: member.id
    }));

    // console.log('dataSource',dataSource)

    return (
        <>
            {contextHolder}
            <Card
                bordered={false}
            >
                <Spin spinning={loading} size='large' tip=" Loading... ">
                    <div className="table" >
                        <Row justify={'center'}>
                            <Col>
                                <h1>รายชื่อพนักงาน</h1>
                            </Col>
                        </Row>
                        <Row justify={'center'} >
                            {/* <Col span={7}></Col> */}
                            <Col span={16} style={{ display: 'flex', alignItems: 'center' }}>
                                <Input
                                    placeholder="ค้นหาพนักงาน"
                                    onChange={onSearch}
                                    value={searchText}
                                    className='search-input'
                                />
                            </Col>
                            {/* <Col span={7}></Col> */}
                            <Col span={22}>
                                <Row justify={'end'}>
                                    <Button
                                        onClick={createMember}
                                        className='button-create'
                                        style={{ margin: 20 }}
                                    >
                                        + เพิ่มผู้ใช้งาน
                                    </Button>
                                </Row>
                            </Col>
                        </Row>
                        <Row justify={'center'}>
                            <Col span={23}>
                                <Table
                                    rowKey={(record) => record.uid}
                                    className="table-body"
                                    columns={columns}
                                    dataSource={dataSource}
                                    pagination={{
                                        pageSize: 10,
                                        showSizeChanger: false,
                                    }}
                                    onChange={handleTableChange}

                                />
                            </Col>
                        </Row>

                        {openEdit && (
                            <ModalEdit
                                open={openEdit}
                                close={setOpenEdit}
                                member={selectedMember}
                                members={members}
                                updateMember={updateMember}
                            />
                        )}
                        {modalSetPassword && (
                            <SetPassword
                                open={modalSetPassword}
                                close={setModalSetPassword}
                                member={selectedMember}
                            // updateMember={updateMember}
                            />
                        )}
                        {modalSwitch && (
                            <ModalSwitch
                                open={modalSwitch}
                                close={setModalSwitch}
                                member={selectedMember}
                                updateMember={updateMember}
                                check={check}
                            />
                        )}
                        {modalCreate && (
                            <ModalCreate
                                open={modalCreate}
                                close={setModalCreate}
                                members={members}
                                addMember={addMember}
                                userId={genUsername}
                                check={false}

                            />
                        )}
                        {modalSetPermissions && (
                            <SetPermissions
                                open={modalSetPermissions}
                                close={setModalSetPermissions}
                                member={selectedMember}
                            // updateMember={updateMember}
                            />

                        )}
                    </div>
                </Spin>
            </Card>
        </>
    );
};

export default Listofemployees