import React, { useState, useEffect } from 'react'
import { Button, Form, Input, Select, Divider, Row, Col, Modal, Checkbox } from 'antd';
import axios from 'axios';
import useSelection from 'antd/es/table/hooks/useSelection';
import { getocc, getsection, getsub, updatecustomerocc } from '../../file_mid/all_api';

function ModalEditCareerIncome({ open, close, dataindex, shootdata, fucnEdit }) {
    const [loading, setLoading] = useState(false)

    const { customers } = useSelection((state) => ({ ...state }))
    const [occupation, setOccupation] = useState([]) // getdatabase occ
    const [sub, setSub] = useState([]) // getdatabase sub
    const [section, setSection] = useState([]) // getdatabase section

    const [form] = Form.useForm();
    const [getData, setGetData] = useState({ ...shootdata.dataedit });
    const [getIndex, setGetIndex] = useState(dataindex);
    const [count, setCount] = useState({ ...shootdata.dataedit })

    console.log("getData", getData)
    console.log("getIndex", getIndex)
    console.log("count", count)


    useEffect(() => {
        setGetData({ ...shootdata.dataedit })
        setGetIndex(dataindex)
        form.setFieldsValue(
            {
                ...getData
            }
        )
        loadOccupation()
    }, [shootdata])

    useEffect(() => {
        if (getData) {
            loadSubOccupation(count.occupationalGroup)
            loadOccupationSection(count.governmentPrivate)
        }
    }, [])

    const success = () => {
        Modal.success({
            title: 'บันทึกสำเร็จ',
            content: 'แก้ไขข้อมูลเรียบร้อย',
        })
    }
    const error = () => {
        Modal.error({
            title: 'บันทึกไม่สำเร็จ',
            content: 'กรุณาตรวจสอบข้อมูลอีกครั้ง',
        })
    }

    //elements
    const handleCancel = () => { //กดปิด Modal
        close(false);
    };

    //handleSubmit
    const handleSubmit = async () => {

        const result = {
            customer: { customerId: getData?.customerId },
            ojs: {
                occupationJobPositionId: getData?.occupationJobPositionId,
                occupationalGroup: getData?.occupationalGroup,
                governmentPrivate: getData?.governmentPrivate,
                jobPosition: getData?.jobPosition,
                companyName: getData?.companyName,
                occupationDescription: getData?.occupationDescription,
                mainOccupation: getData?.mainOccupation
            },
            income: { incomesMainId: getData?.incomesMainId, incomeMonth: getData?.incomeMonth, incomeMainDescription: getData?.incomeMainDescription },
            expenses: { expensesId: getData?.expensesId, expensesMonth: getData?.expensesMonth, expensesMonthDescription: getData?.expensesMonthDescription }
        }
        console.log("result: ", result);
        setLoading(true)
        await axios.put(updatecustomerocc, result)
            .then((res) => {
                //console.log("rr", res.data)
                setLoading(false)
                fucnEdit({ getData, getIndex })
                success()
                close(false);
            })
            .catch((err) => error())
        setLoading(false)
        // close(false);
    }
    // Data Select Form
    const loadOccupation = async () => {
        setLoading(true)
        await axios.get(getocc)
            .then((res) => {
                setOccupation(res.data)
                setLoading(false)
            })
            .catch((err) => console.log(err))
        setLoading(false)
    }

    const loadSubOccupation = async (value) => {
        console.log("sub = ", value)
        if (Number.isInteger(value)) {
            // ทำอะไรกับค่าจำนวนเต็ม
            const idOccupation = { idOccupation: value }
            setLoading(true)
            await axios.post(getsub, idOccupation)
                .then((res) => {
                    setSub(res.data)
                    setLoading(false)
                    form.setFieldsValue(
                        {
                            governmentPrivate: "",
                            jobPosition: "",
                            companyName: "",
                        }
                    )
                })
                .catch((err) => console.log(err))
            setLoading(false)
        } else {
            // ทำอะไรกับค่าอื่น ๆ
            const desc = { desc: value } // ชื่อไทย
            setLoading(true)
            await axios.post(getsub, desc)
                .then((res) => {
                    setSub(res.data)
                    setLoading(false)
                    form.setFieldsValue(
                        {
                            governmentPrivate: "",
                            jobPosition: "",
                            companyName: "",
                        }
                    )
                })
                .catch((err) => console.log(err))
            setLoading(false)
        }
    }

    const loadOccupationSection = async (value) => {
        console.log("section = ", value)
        if (Number.isInteger(value)) {
            const idSub = { idSub: value }
            setLoading(true)
            await axios.post(getsection, idSub)
                .then((res) => {
                    setSection(res.data)
                    setLoading(false)
                    form.setFieldsValue(
                        {
                            jobPosition: "",
                            companyName: "",
                        }
                    )
                })
                .catch((err) => console.log(err))
            setLoading(false)
        } else {
            // ทำอะไรกับค่าอื่น ๆ
            const desc = { descSub: value } // ชื่อไทย
            setLoading(true)
            await axios.post(getsection, desc)
                .then((res) => {
                    setSection(res.data)
                    setLoading(false)
                    form.setFieldsValue(
                        {
                            jobPosition: "",
                            companyName: "",
                        }
                    )
                })
                .catch((err) => console.log(err))
            setLoading(false)
        }
    }

    const getOccupation = (value) => {
        setGetData({ ...getData, occupationalGroup: occupation.find((e) => e.idOccupation === value).desc })
        loadSubOccupation(value)
    }

    const getSub = (value) => {
        setGetData({ ...getData, governmentPrivate: sub.find((e) => e.idSub === value).descSub })
        loadOccupationSection(value)
    }

    const getSection = (value) => {
        setGetData({ ...getData, jobPosition: section.find((e) => e.idSection === value).descSection })
    }
    return (
        <>
            {/* Modal แก้ไข้ */}
            <Modal
                open={open}
                onCancel={handleCancel}
                centered
                width={900}
                footer={[null]}>
                <Form
                    form={form}
                    name='add'
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    style={{
                        maxWidth: 900,
                    }}
                    autoComplete="off"
                    initialValues={{ remember: true }}
                >
                    <Row>
                        <Col span={24} style={{ fontSize: '30px', textAlign: 'center' }} ><u>แก้ไขอาชีพและรายได้</u></Col>
                    </Row>
                    <Divider />
                    <Row gutter={24}>
                        <Col className="gutter-row" span={12}>
                            <Form.Item label='กลุ่มอาชีพ' name='occupationalGroup'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input !',
                                    },]}>
                                <Select
                                    placeholder={'เลือก'}
                                    onChange={getOccupation}
                                >
                                    {occupation?.map((item) => {
                                        return (
                                            <Select.Option key={item.idOccupation} value={item.idOccupation}>
                                                {item.desc}
                                            </Select.Option>
                                        )
                                    })}

                                </Select>
                            </Form.Item>

                            <Form.Item label='เลือกอาชีพ' name='governmentPrivate'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input !',
                                    },]}>
                                <Select
                                    placeholder={'เลือก'}
                                    onChange={getSub}
                                >
                                    {sub?.map((item) => {
                                        return (
                                            <Select.Option key={item.idSub} value={item.idSub}>
                                                {item.descSub}
                                            </Select.Option>
                                        )
                                    })}

                                </Select>
                            </Form.Item>

                            <Form.Item label='สาขาอาชีพ' name='jobPosition'>
                                <Select
                                    placeholder={'เลือก'}
                                    onChange={getSection}
                                >
                                    {section?.map((item) => {
                                        return (
                                            <Select.Option key={item.idSection} value={item.idSection}>
                                                {item.descSection}
                                            </Select.Option>
                                        )
                                    })}

                                </Select>
                            </Form.Item>

                            <Form.Item label='ชื่อบริษัท สถานที่ทำงาน' name='companyName'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input !',
                                    },]}>
                                <Input type='text'
                                    onChange={(e) => setGetData({ ...getData, companyName: e.target.value })}></Input>
                            </Form.Item>

                            <Form.Item label='หมายเหตุ' name='occupationDescription' >
                                <Input type='text'
                                    onChange={(e) => setGetData({ ...getData, occupationDescription: e.target.value })}></Input>
                            </Form.Item>

                            <Form.Item label='เป็นอาชีพหลักหรือไม่' name='mainOccupation' >
                                <Checkbox defaultChecked={true} onChange={(e) => setGetData({ ...getData, mainOccupation: e.target.checked })}>ใช่</Checkbox>
                            </Form.Item>
                            <Divider />
                        </Col>
                        <Col className="gutter-row" span={12}>
                            <Form.Item label='รายได้ต่อเดือน' name='incomeMonth'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input !',
                                    },]}>
                                <Input type='text' suffix='บาท'
                                    onChange={(e) => setGetData({ ...getData, incomeMonth: parseInt(e.target.value) })}></Input>
                            </Form.Item>

                            <Form.Item label='อื่นๆ' name='note' >
                                <Input type='text' suffix='บาท'
                                    onChange={(e) => setGetData({ ...getData, note: parseInt(e.target.value) })}></Input>
                            </Form.Item>

                            <Form.Item label='หมายเหตุ' name='incomeMainDescription' >
                                <Input type='text'
                                    onChange={(e) => setGetData({ ...getData, incomeMainDescription: e.target.value })}></Input>
                            </Form.Item>
                            <Form.Item label='ค่าใช้จ่ายต่อ / เดือน' name='expensesMonth'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input !',
                                    },]}>
                                <Input type='text' suffix='บาท'
                                    onChange={(e) => setGetData({ ...getData, expensesMonth: parseInt(e.target.value) })}></Input>
                            </Form.Item>

                            <Form.Item label='หมายเหตุ' name='expensesMonthDescription' >
                                <Input type='text'
                                    onChange={(e) => setGetData({ ...getData, expensesMonthDescription: e.target.value })}></Input>
                            </Form.Item>

                            <Divider />
                        </Col>
                    </Row>
                    <Row gutter={32}>
                        <Col span={12} style={{ textAlign: 'right' }}>
                            <Button
                                type="primary"
                                style={{ background: 'red', color: '#ffffff', }}
                                onClick={handleCancel}>
                                ยกเลิก
                            </Button>
                        </Col>
                        <Col span={12} style={{ textAlign: 'left' }}>
                            <Button type="primary"
                                onClick={handleSubmit}
                            >
                                บันทึก
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    )
};

export default ModalEditCareerIncome
