import { Card, Form, Image, Row, Modal, message, Upload, Divider, Button, Space, Spin, Col } from 'antd'
import React, { useEffect, useState } from 'react'
import { PlusOutlined } from '@ant-design/icons';
import Compressor from 'compressorjs';
import { addImage } from '../../../redux/Image'
import { useDispatch, useSelector } from 'react-redux';
import { getImagess } from '../../file_mid/all_api';

function UploadImg({ data, onCancel, changepage, dataOld, againMode }) {

    const [allImg, setAllImg] = useState([]);
    const [showImg64, setShowImg64] = useState([]) // โชว์รูป
    const [previewVisible, setPreviewVisible] = useState(false); //รูป
    const token = localStorage.getItem('token');
    const [previewImage, setPreviewImage] = useState('');
    const [loading, setLoading] = useState(false)
    const [fileListCustomerCar, setFileListCustomerCar] = useState([]);
    const [fileListCustomerLand, setFileListCustomerLand] = useState([]);
    const [checkUpload, setCheckUpload] = useState(true); //เช็คอัพรูป
    const { imgs } = useSelector((state) => ({ ...state }))
    const { confirm } = Modal
    const dispatch = useDispatch()

    useEffect(() => {
        if (imgs.data) {
            setShowImg64(imgs.data)
        }
    }, [])

    useEffect(() => {
        if (againMode) {
            if (allImg?.length > 0 || showImg64?.length > 0 || dataOld?.img?.length > 0) {
                setCheckUpload(false)
            } else {
                setCheckUpload(true)
            }
        } else {
            if (allImg.length > 0 || showImg64.length > 0) {
                setCheckUpload(false)
            } else {
                setCheckUpload(true)
            }
        }
    }, [allImg, showImg64, dataOld])

    // console.log("fileListCustomerDocument", fileListCustomerDocument)

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
    };

    const handleCancel = () => {
        setPreviewVisible(false);
    }

    const compressImage = async (ez, types) => {
        var image = {};
        return new Promise(async (resolve) => {
            if (types !== 7) {
                if (ez.size > 4000000) {
                    console.log("4m ไม่ให้ส่ง");
                    resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
                } else {
                    let quality;
                    if (ez.size >= 2500000 && ez.size <= 4000000) {
                        quality = 0.1;
                    } else if (ez.size >= 2000000 && ez.size <= 2499999) {
                        quality = 0.15;
                    } else if (ez.size >= 1500000 && ez.size <= 1999999) {
                        quality = 0.2;
                    } else if (ez.size >= 1000000 && ez.size <= 1499999) {
                        quality = 0.3;
                    } else {
                        quality = 0.5;
                    }

                    try {
                        // ใช้ Promise เพื่อบีบอัดรูปภาพ
                        const compressedFile = await new Promise((resolveCompress) => {
                            new Compressor(ez, {
                                quality: quality,
                                success: (result) => {
                                    resolveCompress(result);
                                },
                            });
                        });

                        // แบบ base64
                        var filename = ez.name
                        var image64 = await getBase64(compressedFile)
                        //var image64 = file.originFileObj
                        image.filename = filename
                        image.image64 = image64
                        image.type = types
                        // console.log("image", image)
                        // allImg.push(image);
                        setAllImg(prevAllImg => [...prevAllImg, image]);
                        // setAllImg((prevAllImg) => [...prevAllImg, image]);
                        // ตรงนี้คือการ resolve ไฟล์ที่ถูกบีบอัด
                        resolve(image);
                    } catch (error) {
                        console.error('Image compression error:', error);
                        resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
                    }
                }
            } else {
                // ... (โลจิกสำหรับ type 7)
                image.imageCompressedFile = ez;
                image.type = types
                allImg.push(image);
                //setAllImg((prevAllImg) => [...prevAllImg, image]);
                resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
            }
        });
    };

    const handleChangeCustomerCar = async ({ fileList }) => { // 1
        setLoading(true)
        for (const removedFile of fileListCustomerCar.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.filename === removedFile.name && img.type === 14)) {
                const updatedAllImg = allImg.filter(img => !(img.filename === removedFile.name && img.type === 14));
                setAllImg(updatedAllImg);
            }
        }
        setFileListCustomerCar(fileList);
        setLoading(false)
    };

    const handleChangeCustomerLand = async ({ fileList }) => { // 1
        setLoading(true)
        for (const removedFile of fileListCustomerLand.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.filename === removedFile.name && img.type === 14)) {
                const updatedAllImg = allImg.filter(img => !(img.filename === removedFile.name && img.type === 14));
                setAllImg(updatedAllImg);
            }
        }
        setFileListCustomerLand(fileList);
        setLoading(false)
    };

    const beforeUpts = async (file, ty) => {
        try {
            setLoading(true)
            const compressedFile = await compressImage(file, ty);
            return Promise.resolve(compressedFile);
        } catch (error) {
            console.error('Image compression error:', error);
            message.error('Image compression failed.');
            return Promise.reject(); // เปลี่ยนให้ return Promise.reject() เมื่อไม่สามารถบีบอัดได้
        }
    };

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const showConfirmCancel = () => {
        confirm({
            title: 'คุณต้องการยกเลิก...?',
            onOk() {
                onCancel(false);
            },
            onCancel() {
            },
        });
    };

    //////////// โชว์รูปภาพ ////////////////
    function createImageUrl(imageData) {
        return imageData.image64;
    }

    const handleSubmit = () => {
        if (showImg64) {
            showImg64.map((e) => {
                allImg.push(e)
            })
        }
        dispatch(addImage(allImg))
        if (data.PRODUCTS === "car") {
            changepage("2")
        } else {
            changepage("4")
        }
    }


    const handleDeleteImg = (indexToRemove, type) => {
        setShowImg64(showImg64.filter((_, index) => index !== indexToRemove && type === type));
    };

    return (
        <Card style={{ width: '100%' }}>
            <Spin spinning={loading} size='large' tip=" Loading... ">
                <Form
                    name="basic"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={handleSubmit}
                >
                    <div className='main' style={{ textAlign: 'center' }}>
                        {
                            data.PRODUCTS === "car" ?
                                <>
                                    <Row justify={'center'}>
                                        <b style={{ fontSize: '18px', textAlign: 'center', marginBottom: '15px' }}><u>รูปประกอบการขออนุมัติ (อัพโหลดไฟล์ละไม่เกิน 3MB)</u></b>
                                    </Row>
                                    <Row justify={'center'}>
                                        <b>
                                            {showImg64?.map((imageData, index) => {
                                                if (imageData.type === 14) {
                                                    return (
                                                        <Row justify={'center'}>
                                                            <img width={'150px'} key={index} style={{ margin: 5 }} src={createImageUrl(imageData)} alt={imageData.filename} />
                                                            <Button onClick={() => handleDeleteImg(index, imageData.type)}>ลบ</Button>
                                                        </Row>
                                                    );
                                                }
                                                return null;
                                            })}
                                            <Row justify={'center'}>
                                                <Form.Item
                                                    style={{ textAlign: 'center' }}
                                                >
                                                    <Upload
                                                        accept="image/*"
                                                        multiple={true}
                                                        style={{ textAlign: 'center' }}
                                                        listType="picture-card"
                                                        fileList={fileListCustomerCar}
                                                        onPreview={handlePreview}
                                                        onChange={handleChangeCustomerCar}
                                                        beforeUpload={(file) => beforeUpts(file, 14).then(() => false).catch(() => true)}
                                                    >
                                                        <div>
                                                            <PlusOutlined />
                                                            <div className="ant-upload-text">upload</div>
                                                        </div>
                                                        {/* )} */}
                                                    </Upload>
                                                    <Modal open={previewVisible} onCancel={handleCancel}>
                                                        <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                    </Modal>
                                                </Form.Item>
                                            </Row>
                                        </b>
                                    </Row>
                                </>
                                :
                                <>
                                    <Col span={22}>
                                        <Row justify={'center'}>
                                            <b style={{ fontSize: '18px', textAlign: 'center', marginBottom: '15px' }}><u>รูปประกอบการขออนุมัติ (อัพโหลดไฟล์ละไม่เกิน 3MB)</u></b>
                                        </Row>
                                        <Row justify={'center'}>
                                            <div className='gutter-row' span={12}>
                                                <b>
                                                    {showImg64?.map((imageData, index) => {
                                                        if (imageData.type === 14) {
                                                            return (
                                                                <Row justify={'center'}>
                                                                    <img width={'150px'} key={index} style={{ margin: 5 }} src={createImageUrl(imageData)} alt={imageData.filename} />
                                                                    <Button onClick={() => handleDeleteImg(index, imageData.type)}>ลบ</Button>
                                                                </Row>
                                                            );
                                                        }
                                                        return null;
                                                    })}
                                                    <Row justify={'center'}>
                                                        <Form.Item
                                                            style={{ textAlign: 'center' }}
                                                        >
                                                            <Upload
                                                                accept="image/*"
                                                                multiple={true}
                                                                style={{ textAlign: 'center' }}
                                                                listType="picture-card"
                                                                fileList={fileListCustomerLand}
                                                                onPreview={handlePreview}
                                                                onChange={handleChangeCustomerLand}
                                                                beforeUpload={(file) => beforeUpts(file, 14).then(() => false).catch(() => true)}
                                                            >
                                                                {fileListCustomerLand?.length >= 20 ? null : (
                                                                    <div>
                                                                        <PlusOutlined />
                                                                        <div className="ant-upload-text">upload</div>
                                                                    </div>
                                                                )}
                                                            </Upload>
                                                            <Modal open={previewVisible} onCancel={handleCancel}>
                                                                <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                            </Modal>
                                                        </Form.Item>
                                                    </Row>
                                                </b>
                                            </div>
                                        </Row>
                                    </Col>
                                </>
                        }
                        {
                            againMode ?
                                <>
                                    <Divider />
                                    <Row justify={'center'}>
                                        <b style={{ fontSize: '18px', textAlign: 'center', marginBottom: '15px' }}><u>รูปที่เคยเสนอมาแล้ว</u></b>
                                    </Row>
                                    {dataOld?.img?.map((e, index) => {
                                        if (e.type === 14) {
                                            return (
                                                <>
                                                    <Space>
                                                        <Image width={100} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                    </Space>
                                                </>
                                            );
                                        }
                                        return null;
                                    })}
                                </>
                                : null
                        }
                    </div>
                    <Divider />
                    <Row justify={'center'} >
                        <Space>
                            <Button type="primary" style={{ background: "red" }} onClick={showConfirmCancel}>
                                ยกเลิก
                            </Button>
                            <Button type="primary" disabled={checkUpload} htmlType="submit" style={{ background: "green" }} >
                                ต่อไป
                            </Button>
                        </Space>
                    </Row>

                </Form >
            </Spin>
        </Card >
    )
}

export default UploadImg