import React, { useState, useEffect } from "react";
// import { Routes, Route, HashRouter } from "react-router-dom";
// import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import Permis from "./pages/Permis";
import Main from "./components/layout/Main";
// import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import { useSelector } from "react-redux";

export default function App() {
  //  const { users } = useSelector((state) => ({ ...state }))
  const users = useSelector((state) => state.users);
  const token = localStorage.getItem("token");

  // if (!users.userData) {
  if (!token) {
    return (
      <>
        <SignIn />
      </>
    );
  }
  return (
    <>
      <Main />
    </>
  );
}
