import React, { useEffect, useState } from 'react'
import axios from 'axios';
import dayjs from 'dayjs';
// import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Divider, Row, Col, Card, Space, Spin, Select, InputNumber, notification } from 'antd';
import BrokerEdit from './BrokerAd/BrokerEdit';
import BrokerNew from '../../../offer_case/savecustomer/BrokerNew';
import { detailBroker, customerbroker, createbroker } from '../../../file_mid/all_api';

export default function DetailBroker({ getData, close, changepage, page, setResetPage }) {
    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();
    const [dataBroker, setDataBroker] = useState([])
    const [loading, setLoading] = useState(false); // loading
    const [dataedit, setDataEdit] = useState();
    const [typeAmt, setTypeAmt] = useState();
    const [modaleditdata, setModalEditData] = useState(false);
    const [modalBrokerNew, setModalBrokerNew] = useState(false);
    const [check630, setCheck630] = useState(false);
    const [check627, setCheck627] = useState(false);
    const [resetPage2, setResetPage2] = useState();
    const [customerBroker, setCustomerBroker] = useState([]);
    const [addBrokerData, setAddBrokerData] = useState({
        IdBroker: null,
        amount: null,
        approvalService: null
    });

    useEffect(() => {
        loadBroker()
        loadCustomerBroker()
    }, [getData, modaleditdata, resetPage2])

    const loadBroker = async () => {
        const result = { loanId: getData?.carLoanDetails?.loanId }
        setLoading(true)
        await axios.post(detailBroker, result)
            .then((res) => {
                if (res.data) {
                    console.log("OK", res.data)
                    // setDataBroker(null)
                    setDataBroker(res.data)
                    var ID
                    res.data.map((data) => {
                        if (data.payfor === "630") {
                            setTimeout(() => {
                                setCheck630(true)
                            }, [10])
                        }
                        if (data.payfor === "627") {
                            setTimeout(() => {
                                setCheck627(true)
                            }, [10])
                        }
                        ID = data.idBroker
                        return null;
                    })
                    setAddBrokerData({
                        IdBroker: ID,
                    })
                }
            })
            .catch((err) => console.log(err))
        setLoading(false)
    }

    const loadCustomerBroker = async () => {
        setLoading(true)
        await axios.get(customerbroker)
            .then(response => {
                console.log("OK")
                setCustomerBroker(response.data);
            }).catch(error => {
                console.error(error);
            });
        setLoading(false)
    }

    const onChangeBack = () => {
        changepage(page - 1)
    }

    const onChangeGo = () => {
        changepage(page + 1)
    }

    const handleCancel = () => {
        close(false)
    }

    const currencyFormatOne = (amount) => {
        if (amount) {
            return Number(amount)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, '$&,')
        } else {
            return 0
        }
    }

    const handleEdits = (value, number) => {
        // console.log(value)
        setDataEdit(value)
        setTypeAmt(number)
        setModalEditData(true);
    }

    const fucnEdit = () => {
        setResetPage(dayjs())
    }

    const onBroker = async (value) => {
        // console.log("value",value)
        setAddBrokerData({ ...addBrokerData, IdBroker: value })
    }

    const addBroker = () => {
        setModalBrokerNew(true)
    }

    const notSend = (placement) => {
        api.error({
            message: <b>เพิ่มข้อมูลไม่สำเร็จ</b>,
            description: 'ไม่พบการกรอกข้อมูล',
            placement,
        });
    }
    const succSend = (placement) => {
        api.success({
            message: <b>เพิ่มข้อมูลสำเร็จ</b>,
            // description: 'ไม่พบการกรอกข้อมูล',
            placement,
        });
    }

    const handleSubmit = async () => {
        if (addBrokerData?.IdBroker === null && addBrokerData?.amount === null && addBrokerData?.approvalService === null) {
            notSend("top")
        } else {
            var sendData = {
                ...addBrokerData,
                customerId: getData?.customerId,
                loanId: getData?.carLoanDetails?.loanId,
                carLandId: getData?.carLoanDetails?.cars?.carId,
                inputDate: dayjs(),
                ad: getData?.carLoanDetails?.reviewedBy,
                mk: getData?.carLoanDetails?.proposalBy
            }
            // console.log("sendData", sendData)
            // await axios.post(`http://localhost:8080/api/createbroker`, sendData)
            await axios.post(createbroker, sendData)
                .then(res => {
                    if (res.status === 200) {
                        succSend("top")
                        setResetPage2(dayjs())
                    }
                }).catch(error => {
                    console.error(error);
                });
        }
    }

    console.log("getData", getData)
    console.log("addBrokerData", addBrokerData)

    const render630 = ({ item, index }) => {

        const handleEdit = (dataedit, index1, number) => {
            console.log(dataedit, index1, number)
            setDataEdit(dataedit) //ส่งข้อมูลด index ไปหน้าแก้ไข
            setTypeAmt(number)
            setModalEditData(true);
        }

        return (
            <Form form={form}>
                <Spin spinning={loading} size='large' tip="Loading...">
                    <Row style={{ margin: '3px' }} justify={'center'} >
                        {
                            item.payfor === "630" ?
                                <Card key={index} style={{ width: '600px', textAlign: 'center' }} >
                                    <Col span={24} style={{ textAlign: 'left' }}>
                                        <Space>
                                            <Form.Item style={{ marginBottom: 0 }} label='จำนวนเงิน' >
                                                <b>{currencyFormatOne(item.payamt)}</b>
                                            </Form.Item>
                                            <Button
                                                style={{ margin: '10px', background: '#bfbfbf', color: '#000000', marginBottom: 0 }}
                                                type='submit'
                                                onClick={() => { handleEdit(item, index, 630); }}
                                            >แก้ไข</Button>
                                        </Space>
                                    </Col>
                                </Card>
                                :
                                null
                        }
                    </Row>
                </Spin>
            </Form>
        )
    }
    const render627 = ({ item, index }) => {

        const handleEdit = (dataedit, index1, number) => {
            console.log(dataedit, index1, number)
            setDataEdit(dataedit) //ส่งข้อมูลด index ไปหน้าแก้ไข
            setTypeAmt(number)
            setModalEditData(true);
        }

        return (
            <Form form={form}>
                <Spin spinning={loading} size='large' tip="Loading...">
                    <Row style={{ margin: '3px' }} justify={'center'} >
                        {
                            item.payfor === "627" ?
                                <Card key={index} style={{ width: '600px', textAlign: 'center' }} >
                                    <Col span={24} style={{ textAlign: 'left' }}>
                                        <Space>
                                            <Form.Item style={{ marginBottom: 0 }} label='ค่าอนุมัติ/บริการ' >
                                                <b>{currencyFormatOne(item.payamt)}</b>
                                            </Form.Item>
                                            <Button
                                                style={{ margin: '10px', background: '#bfbfbf', color: '#000000', marginBottom: 0 }}
                                                type='submit'
                                                onClick={() => { handleEdit(item, index, 627); }}
                                            >แก้ไข</Button>
                                        </Space>
                                    </Col>
                                </Card>
                                :
                                null
                        }
                    </Row>
                </Spin>
            </Form>
        )
    }

    return (
        <>
            <Card>
                <Row>
                    <Col span={24} style={{ textAlign: 'center' }} ><h2>ค่าแนะนำ</h2></Col>
                </Row>
                <Divider />
                <Form
                    name="basic"
                    labelCol={{
                        span: 9,
                    }}
                    wrapperCol={{
                        span: 15,
                    }}
                    onFinish={handleSubmit}
                >
                    <Row>
                        <Col span={24} style={{ textAlign: 'right' }}>
                            <Button type="primary" onClick={addBroker}>
                                เพิ่มผู้แนะนำ
                            </Button>
                        </Col>
                    </Row>
                    {
                        dataBroker?.length > 0 ?
                            <>
                                <Row style={{ margin: '3px' }} justify={'center'} >
                                    {
                                        dataBroker[0]?.idBroker !== "" && dataBroker[0]?.idBroker !== undefined && dataBroker[0]?.idBroker !== null ?
                                            <Card style={{ width: '600px', textAlign: 'center' }} >
                                                <Col span={24} style={{ textAlign: 'left' }}>
                                                    <Space>
                                                        <span>ผู้แนะนำ :</span> <b>{dataBroker[0]?.snam + ' ' + dataBroker[0]?.firstname + ' ' + dataBroker[0]?.lastname}</b>
                                                        <Button
                                                            style={{ margin: '10px', background: '#bfbfbf', color: '#000000', marginBottom: 0 }}
                                                            type='submit'
                                                            onClick={() => { handleEdits(dataBroker[0], 0); }}
                                                        >แก้ไข</Button>
                                                    </Space>
                                                </Col>
                                            </Card>
                                            :
                                            null
                                    }
                                </Row>
                                {/* <Row justify={'center'}> */}
                                {
                                    dataBroker?.map((item, index) => {
                                        return render630({ item, index, key: `{item.id} - ${index}` })
                                    })
                                }
                                {/* </Row>
                                <Row justify={'center'}> */}
                                {
                                    dataBroker?.map((item, index) => {
                                        return render627({ item, index, key: `{item.id} - ${index}` })
                                    })
                                }
                                {/* </Row> */}
                                {
                                    check630 ?
                                        null
                                        :
                                        <Row justify={'center'}>
                                            <Form.Item label='จำนวนเงิน' name='amount'>
                                                <InputNumber
                                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                    size="large"
                                                    style={{ width: '240px', height: '40px', color: 'black' }}
                                                    onChange={(value) => {
                                                        setAddBrokerData({ ...addBrokerData, amount: parseFloat(value) });
                                                    }}
                                                />
                                            </Form.Item>
                                        </Row>
                                }
                                {
                                    check627 ?
                                        null
                                        :
                                        <Row justify={'center'}>
                                            <Form.Item label='ค่าอนุมัติ/บริการ' name='approvalService'>
                                                <InputNumber
                                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                    size="large"
                                                    style={{ width: '240px', height: '40px', color: 'black' }}
                                                    onChange={(value) => {
                                                        setAddBrokerData({ ...addBrokerData, approvalService: parseFloat(value) });
                                                    }}
                                                />
                                            </Form.Item>
                                        </Row>
                                }
                            </>
                            :
                            <>
                                <Row gutter={32} justify={'center'}>
                                    {/* <Col span={24} style={{ textAlign: 'right' }}>
                                        <Button type="primary" onClick={addBroker}>
                                            เพิ่มผู้แนะนำ
                                        </Button>
                                    </Col> */}
                                    <Col className='gutter-row' span={24}>
                                        <Form.Item label='ผู้แนะนำ'
                                            name='idBroker'
                                        // style={{ lineHeight: '32px' }}
                                        >
                                            <Select
                                                showSearch
                                                style={{ width: '240px', height: '40px' }}
                                                onChange={onBroker}
                                                optionFilterProp="children"
                                                placeholder="Search to Select"
                                                filterOption={(inputValue, option) =>
                                                    option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                                                }
                                            >
                                                {customerBroker?.map((e) => {
                                                    // console.log("e",e)
                                                    return (
                                                        <Select.Option key={e.idBroker} value={e.idBroker}>
                                                            {e.firstname} {e.lastname}
                                                        </Select.Option>
                                                    )
                                                })}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item label='จำนวนเงิน' name='amount'>
                                            <InputNumber
                                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                size="large"
                                                style={{ width: '240px', height: '40px', color: 'black' }}
                                                onChange={(value) => {
                                                    setAddBrokerData({ ...addBrokerData, amount: parseFloat(value) });
                                                }}
                                            />
                                        </Form.Item>
                                        <Form.Item label='ค่าอนุมัติ/บริการ' name='approvalService'>
                                            <InputNumber
                                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                size="large"
                                                style={{ width: '240px', height: '40px', color: 'black' }}
                                                onChange={(value) => {
                                                    setAddBrokerData({ ...addBrokerData, approvalService: parseFloat(value) });
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                    }
                    {/* <Divider /> */}

                    <Divider />

                    <div style={{ textAlign: "center" }} >
                        <Space>
                            <Button type="primary" style={{ background: '#bfbfbf', color: '#000000', }} onClick={onChangeBack}>ย้อนกลับ</Button>
                            <Button type="primary" onClick={handleCancel} style={{ background: "red" }}>ยกเลิก</Button>
                            <Button type="primary" htmlType="submit" style={{ background: "green" }} >บันทึก</Button>
                            <Button type="primary" style={{ color: '#ffffff', }} onClick={onChangeGo}>ต่อไป</Button>
                        </Space>
                    </div>
                </Form>
                {contextHolder}
            </Card>
            {
                //แก้ไขข้อมูลในการ์ด
                modaleditdata ?
                    <BrokerEdit
                        open={modaleditdata}
                        close={setModalEditData}
                        shootdata={dataedit}
                        fucnEdit={fucnEdit}
                        typeAmt={typeAmt}
                    // notification={notification}
                    // notification2={notification2}
                    />
                    : null
            }
            {
                //เพื่มผู้แนะนำ
                modalBrokerNew ?
                    <BrokerNew
                        open={modalBrokerNew}
                        close={setModalBrokerNew}
                    />
                    : null
            }
            {/* {contextHolder} */}
        </>
    )
}
