import React, { useState, useEffect } from 'react'
import { Button, Form, Select, Col, Row, Input, Card, Spin, Space, Divider } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import Currency from 'currency.js';

import { useSelector } from 'react-redux';
import TableRateCar from '../../offer_case/rate_car/TableRateCar';
import { HirePurchase } from '../../offer_case/hire_purchase/HirePurchase';
import { Installment } from '../../offer_case/ploan/Installment';
import Yearpick from './Yearpick';
import { productLoanTypeCar } from '../../file_mid/all_options';
import { dataModel, edit, getprice, loadBrand, loadModel, loadProvinces } from '../../file_mid/all_api';

export default function EditCarLone({ getData, changepage, onCancel }) {

    const [loading, setLoading] = useState(false)
    const { Option } = Select;
    const customers = useSelector(state => state.customers)
    const cars = useSelector(state => state.cars)
    const [form] = Form.useForm()

    const [selectProduct, setSelectProduct] = useState({}) // 1 รถ , 2 ที่ดิน
    const [dataPost2, setDataPost2] = useState({ productTypeCar: 1 })  //car
    const [money, setMoney] = useState({}) //loan
    const [moneyOld, setMoneyOld] = useState({}) //loanOld
    const [typeMoney, setTypeMoney] = useState({})
    const [provincesCar, setProvincesCar] = useState([]);

    ///////////////// ตาราง ///////////////////////////////
    const [openTable, setOpenTable] = useState(false); //ตารางค่างวด p-loan
    const [openTableHP, setOpenTableHP] = useState(false); //ตารางค่างวด เช่าซื้อ
    const [openTableCar, setOpenTableCar] = useState(false); //ตารางเรทรถ

    /////////////////// รถ ////////////////////////////////////
    const [resultData, setResultData] = useState([]) // API
    const [typeData, setTypeData] = useState([]) // API
    const [brandData, setBrandData] = useState([]) // API
    const [modelData, setModelData] = useState([]) // API
    const [yearPrice, setYearPrice] = useState([])
    const [typeSelected, setTypeSelected] = useState(null)

    ////////////////// select car ////////////////////////////////
    const [counterBrand, setCounterBrand] = useState(false)
    const [counterModel, setCounterModel] = useState(false)
    const [counterCarBaab, setCounterCarBaab] = useState(false)

    //////////////// เลือก p-loan หรือ เช่าซื้อ /////////////////////
    const [checkPCH, setCheckPCH] = useState({ PLorCH: getData.carLoanDetails?.productType })

    useEffect(() => {
        if (cars.data?.brand_id !== undefined) {
            setDataPost2({ ...cars.data, carYear: cars.data.carYear.toString(), carId: getData.carLoanDetails?.cars?.carId, productTypeId: 1 })
            setSelectProduct({ ...selectProduct, productTypeId: 1 })
            setMoney({ ...getData.carLoanDetails })
            setMoneyOld({ ...getData.carLoanDetails.cars.oldLoanDetails })
            setTypeData(getData?.carLoanDetails.cars?.productTypeCar)
            setTypeSelected(getData?.carLoanDetails.cars?.productTypeCar)
            loadDataBrand(getData?.carLoanDetails.cars?.productTypeCar)
            form.setFieldsValue({
                ...getData.carLoanDetails,
                ...getData.carLoanDetails.cars,
                ...checkPCH,
                ...getData.carLoanDetails.cars.oldLoanDetails,
                ...cars.data,
                productTypeId: 1,
            })
        } else {
            if (getData.carLoanDetails?.cars?.carId !== undefined) {
                setDataPost2({ ...getData.carLoanDetails.cars, productTypeId: 1 })
                setSelectProduct({ ...selectProduct, productTypeId: 1 })
                setMoney({ ...getData.carLoanDetails })
                setMoneyOld({ ...getData.carLoanDetails.cars.oldLoanDetails })
                setTypeData(getData?.carLoanDetails.cars?.productTypeCar)
                setTypeSelected(getData?.carLoanDetails.cars?.productTypeCar)
                loadDataBrand(getData?.carLoanDetails.cars?.productTypeCar)
                setCheckPCH({ PLorCH: getData.carLoanDetails.productType })
                if (getData.carLoanDetails.productLoanType === "ย้ายไฟแนนซ์" || getData.carLoanDetails.productLoanType === "Pre-Aaprove-ย้ายไฟแนนซ์") {
                    setMoneyOld({ ...getData.carLoanDetails.cars.oldLoanDetails, productLoanType: getData.carLoanDetails.productLoanType })
                }
                form.setFieldsValue({
                    ...getData.carLoanDetails,
                    ...getData.carLoanDetails.cars.oldLoanDetails,
                    ...checkPCH,
                    ...getData.carLoanDetails.cars,
                    productTypeId: 1,
                })
            }
        }
        fetchAllProvinces()
    }, [cars.data])

    useEffect(() => {
        if (money && dataPost2.productTypeCar > 1 && checkPCH.PLorCH === "เช่าซื้อ") {
            car_Calculate()
        }
        if (money && dataPost2.productTypeCar === 1 && checkPCH.PLorCH === "เช่าซื้อ1") {
            car_Calculate()
        }
    }, [money?.loanAmount, money?.interestRate, money?.loanTerm, money?.installmentAmountt, moneyOld?.oldLoanAmount, moneyOld?.oldMonthlyPayment, moneyOld?.oldLoanTerm, moneyOld?.oldKangPayment])

    const handleCancel = () => {
        onCancel(false)
    }
    const onChangeBack = () => {
        changepage(1)
    }

    const handleSubmit = async () => {
        var MixData = { ...moneyOld, ...dataPost2, ...money, TypeLoan: "car" }
        await axios.put(edit, MixData)
            .then(res => {
                if (res.status === 200) {
                    setTimeout(() => {
                        handleCancel()
                    }, 1000);
                }
            }).catch(error => {
                console.error(error);
            });

    }

    //////////////// จังหวัด รถ /////////////////////
    const fetchAllProvinces = async () => {
        await axios.get(loadProvinces)
            .then(response => {
                setProvincesCar(response.data);
            }).catch(error => {
                console.error(error);
            });
    }

    const handleProvinceChangeCar = (value, element) => {
        setDataPost2({ ...dataPost2, carProvince: value, });
    };

    const handleMoney = (values) => {

        setMoney({
            ...money,
            interestRate: values?.interestRate,
            loanAmount: values?.loanAmountt,
            loanTerm: values?.installmentAmountt,
            interest: values?.interestt,
            monthlyPayment: values?.monthlyPayment,
            installmentWithInterest: values?.installmentWithInterestt,
            // approvalStatus: values?.approvalStatus,
            productType: checkPCH.PLorCH,
            PLorCH: checkPCH.PLorCH
        })
        form.setFieldsValue(
            {
                loanAmount: values?.loanAmountt,
                loanTerm: values?.installmentAmountt,
                interest: values?.interestt,
                monthlyPayment: values?.monthlyPayment,
                installmentWithInterest: values?.installmentWithInterestt,
            }
        )
    }
    const handleMoneyHP = (values) => {
        setMoney({
            ...money,
            interestRate: values?.interestRate,
            loanAmount: values?.loanAmountt,
            loanTerm: values?.installmentAmountt,
            interest: values?.interestt,
            monthlyPayment: values?.monthlyPayment,
            installmentWithInterest: values?.installmentWithInterestt,
            // approvalStatus: values?.approvalStatus,
            productType: checkPCH.PLorCH,
            PLorCH: checkPCH.PLorCH
        })
        form.setFieldsValue(
            {
                loanAmount: values?.loanAmountt,
                loanTerm: values?.installmentAmountt,
                interest: values?.interestt,
                monthlyPayment: values?.monthlyPayment,
                installmentWithInterest: Currency(values?.installmentWithInterestt),
            }
        )
    }
    ////////////// รถ ///////////////////
    const handleChangeType = (value) => {
        if (value === 'ย้ายไฟแนนซ์' || value === "Pre-Aaprove-ย้ายไฟแนนซ์") {
            setMoney({ ...money, productLoanType: value, productType: checkPCH.PLorCH })
            setMoneyOld({ ...moneyOld, productLoanType: value })
            setTypeMoney({ ...typeMoney, productLoanType: 'ย้ายไฟแนนซ์', productType: checkPCH.PLorCH })
            //setTypeMoney({ ...typeMoney, productLoanType: value, approvalDate: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"), productType: "รถ" })
        }
        else {
            setMoney({ ...money, productLoanType: value, productType: checkPCH.PLorCH })
            setTypeMoney({ ...typeMoney, productLoanType: 'ถือเล่มมา', productType: checkPCH.PLorCH })
            setMoneyOld({ oldLoanId: getData.carLoanDetails.cars.oldLoanDetails.oldLoanId })
            form.setFieldsValue(
                {
                    oldKangPayment: '',
                    oldKangPaymentTerm: '',
                    oldLoanAmount: '',
                    oldLoanTerm: '',
                    oldMonthlyPayment: '',
                }
            )
        }
    }
    /////////////// เลือก p-loan หรือ เช่าซื้อ ///////////////////
    const handleChangePLorCH = (value) => {
        if (value === "p-loan") {
            setCheckPCH({ PLorCH: value })
            setMoney({
                ...money,
                loanAmount: "",
                loanTerm: "",
                interest: "",
                interestRate: "",
                monthlyPayment: "",
                installmentWithInterest: "",
                PLorCH: value,
                productType: value
            })
            form.setFieldsValue(
                {
                    loanAmount: "",
                    loanTerm: "",
                    interest: "",
                    interestRate: "",
                    monthlyPayment: "",
                    installmentWithInterest: "",
                }
            )
        } else if (value === "เช่าซื้อ1") {
            setCheckPCH({ PLorCH: value })
            setMoney({
                ...money,
                loanAmount: "",
                loanTerm: "",
                interest: "",
                interestRate: "",
                monthlyPayment: "",
                installmentWithInterest: "",
                PLorCH: value,
                productType: value
            })
            form.setFieldsValue(
                {
                    loanAmount: "",
                    loanTerm: "",
                    interest: "",
                    interestRate: "",
                    monthlyPayment: "",
                    installmentWithInterest: "",
                }
            )
        } else {
            setCheckPCH({ PLorCH: value })
            setMoney({
                ...money,
                loanAmount: "",
                loanTerm: "",
                interest: "",
                interestRate: "",
                monthlyPayment: "",
                installmentWithInterest: "",
                PLorCH: value,
                productType: value
            })
            form.setFieldsValue(
                {
                    loanAmount: "",
                    loanTerm: "",
                    interest: "",
                    interestRate: "",
                    monthlyPayment: "",
                    installmentWithInterest: "",
                }
            )
        }
    }

    //////////// กรณีไม่มีรถ /////////////////////
    const AddBrand = () => {
        setCounterBrand(true)
        setCounterModel(true)
        setCounterCarBaab(true)
        form.setFieldsValue(
            {
                carBrand: '',
                carModel: '',
                carBaab: '',
                carYear: '',
                carPrice: 0,
            }
        )
    }
    const AddBrands = () => {
        setCounterBrand(false)
        setCounterModel(false)
        setCounterCarBaab(false)
        form.setFieldsValue(
            {
                carBrand: '',
                carModel: '',
                carBaab: '',
                carYear: '',
                carPrice: 0,
            }
        )
    }
    //////////// กรณีไม่มีรถ /////////////////////
    const AddModel = () => {
        setCounterModel(true)
        setCounterCarBaab(true)
        form.setFieldsValue(
            {
                carModel: '',
                carBaab: '',
                carYear: '',
                carPrice: 0,
            }
        )
    }
    const AddModels = () => {
        setCounterModel(false)
        setCounterCarBaab(false)
        form.setFieldsValue(
            {
                carModel: '',
                carBaab: '',
                carYear: '',
                carPrice: 0,
            }
        )
    }
    //////////// กรณีไม่มีรถ /////////////////////
    const AddCarBaab = () => {
        setCounterCarBaab(true)
        form.setFieldsValue(
            {
                carBaab: '',
                carYear: '',
                carPrice: 0,
            }
        )
    }
    const AddCarBaabs = () => {
        setCounterCarBaab(false)
        form.setFieldsValue(
            {
                carBaab: '',
                carYear: '',
                carPrice: 0,
            }
        )
    }
    const onYeehor = (e) => {
        //console.log("carBrand",e.target.value)
        setDataPost2({ ...dataPost2, carBrand: e.target.value })
        //setCarSelected(e)
    }

    const onMoChange = (e) => {
        setDataPost2({ ...dataPost2, carModel: e.target.value })
    }
    const onDeChange = (e) => {
        setDataPost2({ ...dataPost2, carBaab: e.target.value })
    }

    ////// ตัวอย่างรถ กับ ตารางค่างวด ////////
    const getTable = () => {
        setOpenTable(true)
    }
    const getTableHP = () => {
        setOpenTableHP(true)
    }
    const getTableCar = () => {
        setOpenTableCar(true)
    }
    /////////////////////////////////////

    const loadDataBrand = async (value) => { //รถยนต์ รถเครื่องจักร รถบรรทุก
        setLoading(true)
        await axios.get(`${loadBrand}${value}`)
            .then((res) => {
                setBrandData(res.data)
                setLoading(false)
            })
            .catch((err) => console.log(err))
        setLoading(false)
    }

    const loadDataModel = async (value) => {
        setLoading(true)
        await axios.get(dataModel + value)
            .then((res) => {
                setModelData(res.data)
                setLoading(false)
            })
            .catch((err) => console.log(err))
        setLoading(false)
    }
    const getResult = async (value) => {
        setLoading(true)
        const result = await axios.get(loadModel,
            { params: { typeId: typeSelected, modelId: value }, })
        setResultData(result.data)
        setLoading(false)
    }

    const onTypeChange = (value) => {
        setDataPost2({ ...dataPost2, productTypeCar: value, carYear: "0", carPrice: 0, carPriceStatus: false, carInput: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"), carDateStatus: dayjs().format("YYYY-MM-DDTHH:mm:ssZ") })
        setTypeData(value)
        setTypeSelected(value)
        loadDataBrand(value)
        form.setFieldsValue(
            {
                carProvince: "",
                carPlateNumber: "",
                loanAmount: "",
                loanTerm: "",
                interest: "",
                interestRate: "",
                monthlyPayment: "",
                installmentWithInterest: "",
                oldLoanAmount: "",
                oldMonthlyPayment: "",
                oldLoanTerm: "",
                oldKangPayment: "",
                oldKangPaymentTerm: ""
            }
        )
    }
    const onBrandChange = (value) => {
        setDataPost2({ ...dataPost2, carBrand: brandData.find((a) => a.idbrand === value).brand })
        loadDataModel(value)
    }
    const onModelChange = (value) => {
        setCounterBrand(false)
        setDataPost2({ ...dataPost2, carModel: modelData.find((a) => a.idmodel_te === value).model })
        getResult(value)
    }
    const onDetailChange = (value) => {
        setDataPost2({ ...dataPost2, carBaab: resultData.find((a) => a.idmodel === value).models })
        getPrice(typeData, value)
    }
    const getPrice = async (typeId, carId) => {
        setLoading(true)
        await axios.get(getprice, { params: { typeId, carId }, })
            .then((res) => {
                if (res.data) {
                    setYearPrice(res.data)
                    setLoading(false)
                }
            })
            .catch((err) => alert('ไม่พบข้อมูล ราคารถ'))
        setLoading(false)
    }
    const onResult = (k, v) => {
        const pYear = parseInt(v.key)
        if (k) {
            setDataPost2({ ...dataPost2, carPriceStatus: true, carPrice: k, carYear: pYear.toString(), carInput: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"), carDateStatus: dayjs().format("YYYY-MM-DDTHH:mm:ssZ") })
            parseInt(dataPost2.carYear)
            form.setFieldsValue({
                carPrice: v.value
            })

        }
    }

    /////////////////// เลือก 1 รถ, 2 ที่ดิน ///////////////////////
    const handleProduct = (value) => {
        setSelectProduct({ ...selectProduct, productTypeId: value })
        if (value === 1) {
            setCheckPCH({ PLorCH: "p-loan" })
            setDataPost2({ ...dataPost2, productTypeId: value, productTypeCar: 1 })
            form.setFieldsValue(
                {
                    PLorCH: "p-loan",
                    numberLand: "",
                    numberLandlawang: "",
                    rai: "",
                    workArea: "",
                    squareWaArea: "",
                    landPrice: "",
                    resultLandPrice: "",
                    provinces: "",
                    district: "",
                    subdistricts: "",
                    zipcode: "",
                    productLoanLandType: "",
                    loanAmountLand: "",
                    loanLandTerm: "",
                    interestRateLand: "",
                    interestLand: "",
                    monthlyPaymentLand: "",
                    installmentWithInterestLand: "",
                }
            )
        }
    }

    const car_Calculate = () => {
        if (money.loanAmount && money.interestRate && money.loanTerm) {
            var interestRateCar = parseFloat(money.interestRate) / 100 // อัตราดอก / 100
            var rateCar = ((parseFloat(money.loanAmount) * Currency(interestRateCar, { precision: 5 }).value) * parseInt(money.loanTerm)) // (ยอดจัด * อัตราดอกที่หาร100แล้ว ,precision: 3 คือทศนิยม 3 )*งวด
            var loanTermCar = Math.ceil(Currency((rateCar + parseFloat(money.loanAmount)) / parseInt(money.loanTerm), { precision: 2 }).value) // (ดอกเบี้ยทั้งหมด + ยอดจัด)/ งวด
            var newLoanTermCar = Math.ceil(Currency(loanTermCar * 0.07)) + loanTermCar // งวดละใหม่ = ( งวดละ * 0.07 ) + งวดละ
            var resultRateCar = (newLoanTermCar * parseInt(money.loanTerm)) - parseFloat(money.loanAmount) // งวดละใหม่ * งวด - ยอดจัด
            var newResultPriceCar = newLoanTermCar * parseInt(money.loanTerm) // งวดละใหม่ * งวด
            setMoney({
                ...money,
                monthlyPayment: newLoanTermCar,
                interest: resultRateCar,
                installmentWithInterest: newResultPriceCar
            })
            form.setFieldsValue(
                {
                    monthlyPayment: newLoanTermCar,
                    interest: resultRateCar,
                    installmentWithInterest: newResultPriceCar
                }
            )
        }
        else {
            form.setFieldsValue(
                {
                    monthlyPayment: 0,
                    interest: 0,
                    installmentWithInterest: 0
                }
            )
        }
    }

    const currencyFormatOne = (amount) => {
        return Number(amount)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }

    return (
        <>
            <Card bordered={false} style={{ width: 'auto' }}>
                <Spin spinning={loading} size='large' tip=" Loading... ">
                    <Row style={{ textAlign: 'center', marginTop: '30px' }} >
                        <Col span={24} style={{ fontSize: '30px' }} >รายละเอียด</Col>
                    </Row>
                    <Row justify={'center'}>
                        <Form
                            labelCol={{
                                span: 8,
                            }}
                            wrapperCol={{
                                span: 16,
                            }}
                            style={{
                                maxWidth: '100%',
                            }}
                            form={form}
                            onFinish={handleSubmit}
                            autoComplete="off"
                            initialValues={{ remember: true }}
                        >
                            <Row justify={'center'} gutter={32}>
                                <div style={{ marginRight: 10 }} >
                                    <Form.Item label='กลุ่มสินค้า' name='productTypeId'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input !'
                                            },]}>
                                        <Select placeholder={'เลือก'} disabled
                                            style={{ height: "40px" }}
                                            onChange={(value) => handleProduct(value)}
                                        //onChange={(value) => setDataPost2({ ...dataPost2, productTypeId: value })}
                                        >
                                            <Option value={1}>รถ</Option>
                                            <Option value={2}>ที่ดิน</Option>
                                        </Select>
                                    </Form.Item>

                                    {/* รถ */}

                                    {customers.data && selectProduct?.productTypeId !== 2 && (
                                        <>
                                            <Form.Item label='เรทรถ'>
                                                <Button type='primary' onClick={getTableCar} >ดู</Button>
                                                {
                                                    openTableCar ?
                                                        <TableRateCar open={openTableCar} close={setOpenTableCar} productTypeCar={dataPost2?.productTypeCar} />
                                                        : null
                                                }
                                            </Form.Item>

                                            <Form.Item label='ชนิดรถ' name='productTypeCar'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input !'
                                                    },]}>
                                                <Select placeholder={'เลือก'} disabled allowClear style={{ height: "40px" }}
                                                    onChange={onTypeChange}>
                                                    <Option value={1}>รถยนต์</Option>
                                                    <Option value={2}>รถเครื่องจักการเกษตร</Option>
                                                    <Option value={3}>รถบรรทุก</Option>
                                                </Select>
                                            </Form.Item>
                                            <Row gutter={32}>
                                                <Col className='gutter-row' span={21}>
                                                    <Form.Item label='ยี่ห้อ' name='carBrand' style={{ lineHeight: '32px' }}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        {!counterBrand ? (
                                                            <Select
                                                                showSearch
                                                                style={{ height: "40px" }}
                                                                onChange={onBrandChange}
                                                                optionFilterProp="children"
                                                                placeholder="Search to Select"
                                                                filterOption={(inputValue, option) =>
                                                                    option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                                                                }
                                                            >
                                                                {brandData?.map((e) => {
                                                                    return (
                                                                        <Select.Option key={e.idbrand} value={e.idbrand}>
                                                                            {e.brand} ({e.brand_th})
                                                                        </Select.Option>
                                                                    )
                                                                })}
                                                            </Select>
                                                        ) : (<>

                                                            {counterBrand ? (<Input style={{ height: "40px" }} onChange={onYeehor} />) : (<Select
                                                                style={{ height: "40px" }}
                                                                showSearch
                                                                onChange={onBrandChange}
                                                                placeholder="Search to Select"
                                                                optionFilterProp="children"
                                                                filterOption={
                                                                    (inputValue, option) =>
                                                                        option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                                                                }
                                                            >
                                                                {modelData?.map((e) => {
                                                                    return (
                                                                        <Select.Option key={e.idmodel_te} value={e.idmodel_te}>
                                                                            {e.model} {e.model_te ? e.model_te : null}
                                                                        </Select.Option>
                                                                    )
                                                                })}
                                                            </Select>)
                                                            }
                                                        </>

                                                        )}
                                                    </Form.Item>
                                                </Col>
                                                <Col className='gutter-row' span={1} style={{ textAlign: 'center' }}>
                                                    {counterBrand === false && (
                                                        <Button onClick={AddBrand} style={{ height: '33px', lineHeight: '15px' }}>
                                                            +
                                                        </Button>)
                                                    }
                                                    {counterBrand === true && (
                                                        <Button onClick={AddBrands} >
                                                            -
                                                        </Button>
                                                    )}
                                                </Col>
                                            </Row>
                                            <Row gutter={32}>
                                                <Col className='gutter-row' span={21}>
                                                    <Form.Item label='รุ่นรถ' name='carModel'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        {counterBrand ? ( //ไม่จริงเข้าตรงนี้
                                                            <>
                                                                {!counterModel ? (
                                                                    <Select //ไม่จริงเข้าตรงนี้
                                                                        showSearch
                                                                        style={{ height: "40px" }}
                                                                        onChange={onModelChange}
                                                                        placeholder="Search to Select"
                                                                        optionFilterProp="children"
                                                                        filterOption={(inputValue, option) =>
                                                                            option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                                                                        }
                                                                    >
                                                                        {modelData?.map((e) => {
                                                                            return (
                                                                                <Select.Option key={e.idmodel_te} value={e.idmodel_te}>
                                                                                    {e.model} {e.model_te ? e.model_te : null}
                                                                                </Select.Option>
                                                                            )
                                                                        })}
                                                                    </Select>
                                                                ) : (

                                                                    <Input style={{ height: "40px" }} onChange={onMoChange} />
                                                                )}

                                                            </>
                                                        ) : (<>

                                                            {counterModel ? (<Input onChange={onMoChange} />) : (<Select
                                                                style={{ height: "40px" }}
                                                                showSearch
                                                                onChange={onModelChange}
                                                                placeholder="Search to Select"
                                                                optionFilterProp="children"
                                                                filterOption={
                                                                    (inputValue, option) =>
                                                                        option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                                                                }
                                                            >
                                                                {modelData?.map((e) => {
                                                                    return (
                                                                        <Select.Option key={e.idmodel_te} value={e.idmodel_te}>
                                                                            {e.model} {e.model_te ? e.model_te : null}
                                                                        </Select.Option>
                                                                    )
                                                                })}
                                                            </Select>)
                                                            }
                                                        </>
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                                <Col className='gutter-row' span={1} style={{ textAlign: 'center' }}>
                                                    {counterModel === false && (
                                                        <Button onClick={AddModel} style={{ height: '33px', lineHeight: '15px' }}>
                                                            +
                                                        </Button>)
                                                    }
                                                    {counterModel === true && (
                                                        <Button onClick={AddModels} >
                                                            -
                                                        </Button>
                                                    )}
                                                </Col>
                                            </Row>
                                            <Row gutter={32}>
                                                <Col className='gutter-row' span={21}>
                                                    <Form.Item label='แบบรถ' name='carBaab'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        {counterBrand ? (
                                                            <>
                                                                {!counterCarBaab ? (
                                                                    <Select
                                                                        style={{ height: "40px" }}
                                                                        showSearch
                                                                        onChange={(value) => { onDetailChange(value) }}
                                                                        placeholder="Search to Select"

                                                                    >
                                                                        {resultData?.map((e) => {
                                                                            return (
                                                                                <Select.Option key={e.idmodel} value={e.idmodel}>
                                                                                    {e.models} {e.idmodel ? e.idmodel : null}
                                                                                </Select.Option>
                                                                            )
                                                                        })}
                                                                    </Select>
                                                                ) : (

                                                                    <Input style={{ height: "40px" }} onChange={onDeChange} />
                                                                )}
                                                            </>
                                                        ) : (<>

                                                            {counterCarBaab ? (<Input onChange={onDeChange} />) : (<Select
                                                                style={{ height: "40px" }}
                                                                showSearch
                                                                onChange={(value) => { onDetailChange(value) }}
                                                                placeholder="Search to Select"
                                                            >
                                                                {resultData?.map((e) => {
                                                                    return (
                                                                        <Select.Option key={e.idmodel} value={e.idmodel}>
                                                                            {e.models} {e.idmodel ? e.idmodel : null}
                                                                        </Select.Option>
                                                                    )
                                                                })}
                                                            </Select>)
                                                            }
                                                        </>
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                                <Col className='gutter-row' span={1} style={{ textAlign: 'center' }}>
                                                    {counterCarBaab === false && (
                                                        <Button onClick={AddCarBaab} style={{ height: '33px', lineHeight: '15px' }}>
                                                            +
                                                        </Button>)
                                                    }
                                                    {counterCarBaab === true && (
                                                        <Button onClick={AddCarBaabs} >
                                                            -
                                                        </Button>
                                                    )}
                                                </Col>
                                            </Row>
                                            <Form.Item label='ปีรถ' name='carYear'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input !'
                                                    },]}>
                                                {counterBrand ? (
                                                    <>
                                                        {!counterCarBaab ? (
                                                            <Select
                                                                style={{ height: "40px" }}
                                                                onChange={(k, v) => (onResult(k, v))}
                                                            >
                                                                {Object.entries(yearPrice).map(([k, v]) => {
                                                                    if (k.charAt(0) === 'p' && v) {
                                                                        const year = k.replace('p', '')
                                                                        return (
                                                                            <Select.Option key={year} value={v * 1000}>
                                                                                {year}
                                                                            </Select.Option>
                                                                        )
                                                                    }
                                                                })}
                                                            </Select>
                                                        ) : (
                                                            <Yearpick
                                                                picker="year"
                                                                style={{ height: "40px" }}
                                                                onChange={(e, string) => {
                                                                    if (e) {
                                                                        let year = e.$y
                                                                        setDataPost2({ ...dataPost2, carYear: year })
                                                                    }
                                                                }} />
                                                        )}

                                                    </>
                                                )
                                                    :
                                                    (
                                                        <>
                                                            {counterCarBaab ? (
                                                                <Yearpick
                                                                    picker="year"
                                                                    style={{ height: "40px" }}
                                                                    onChange={(e, string) => {
                                                                        if (e) {
                                                                            let year = e.$y
                                                                            setDataPost2({ ...dataPost2, carYear: year })
                                                                        }
                                                                    }} />
                                                            ) : (
                                                                <Select
                                                                    style={{ height: "40px" }}
                                                                    onChange={(k, v) => (onResult(k, v))}
                                                                >
                                                                    {Object.entries(yearPrice).map(([k, v]) => {
                                                                        if (k.charAt(0) === 'p' && v) {
                                                                            const year = k.replace('p', '')
                                                                            return (
                                                                                <Select.Option key={year} value={v * 1000}>
                                                                                    {year}
                                                                                </Select.Option>
                                                                            )
                                                                        }
                                                                    })}
                                                                </Select>
                                                            )}
                                                        </>

                                                    )}
                                            </Form.Item>
                                            <Form.Item label='ราคา' name='carPrice'>
                                                <Input disabled defaultValue={0} style={{ height: "40px" }}
                                                    onChange={(e) => setDataPost2({ ...dataPost2, carPrice: e.value })} />
                                            </Form.Item>

                                            <Form.Item label='จังหวัด' name='carProvince'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input !',
                                                    },]}>
                                                <Select
                                                    showSearch
                                                    name='carProvince'
                                                    style={{ height: "40px" }}
                                                    placeholder='จังหวัด'
                                                    onChange={(key, value) => { handleProvinceChangeCar(key, value) }}
                                                >
                                                    {provincesCar?.map((pro, index) => (
                                                        <Option key={pro.provinceId} value={pro.provinceName}>
                                                            {pro.provinceName}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>

                                            <Form.Item label='เลขทะเบียน' name='carPlateNumber'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input !',
                                                    },]}>
                                                <Input style={{ height: "40px" }}
                                                    onChange={(e) => setDataPost2({ ...dataPost2, carPlateNumber: e.target.value })} />
                                            </Form.Item>
                                        </>
                                    )}
                                </div>
                                <div style={{ marginLeft: 10 }}>
                                    {/* โลนรถ */}
                                    {customers.data && selectProduct?.productTypeId !== 2 && (
                                        <>
                                            <Form.Item label='ประเภทขอกู้รถ' name='productLoanType'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input !',
                                                    },]}>
                                                <Select
                                                    placeholder={'เลือก'}
                                                    style={{ height: '40px' }}
                                                    onChange={(value) => { handleChangeType(value) }}
                                                    options={productLoanTypeCar}
                                                />
                                            </Form.Item>
                                            {
                                                dataPost2.productTypeCar === 1 ?
                                                    <Form.Item label='ประเภทค่างวด' name='PLorCH'>
                                                        <Select
                                                            placeholder={'เลือก'}
                                                            style={{ height: '40px' }}
                                                            onChange={(value) => { handleChangePLorCH(value) }}
                                                            options={[
                                                                {
                                                                    label: 'P-LOAN',
                                                                    value: 'p-loan',
                                                                },
                                                                {
                                                                    label: 'เช่าซื้อ',
                                                                    value: 'เช่าซื้อ',
                                                                },
                                                                {
                                                                    label: 'เช่าซื้อ (0.84-0.75)',
                                                                    value: 'เช่าซื้อ1',
                                                                },
                                                            ]}
                                                        />
                                                    </Form.Item>
                                                    :
                                                    <Form.Item label='ประเภทค่างวด' name='PLorCH'>
                                                        <Select
                                                            placeholder={'เลือก'}
                                                            style={{ height: '40px' }}
                                                            onChange={(value) => { handleChangePLorCH(value) }}
                                                            options={[
                                                                {
                                                                    label: 'P-LOAN',
                                                                    value: 'p-loan',
                                                                },
                                                                {
                                                                    label: 'เช่าซื้อ',
                                                                    value: 'เช่าซื้อ',
                                                                },
                                                            ]}
                                                        />
                                                    </Form.Item>
                                            }


                                            {/* P-LOAN */}
                                            {checkPCH && typeof checkPCH.PLorCH !== 'undefined' && (checkPCH.PLorCH === "p-loan") ? (
                                                <>
                                                    <Form.Item label='P-LOAN'>
                                                        <Button type="primary" onClick={getTable}>
                                                            ตารางค่างวด P-LOAN
                                                        </Button>
                                                        {
                                                            openTable ?
                                                                <Installment open={openTable} close={setOpenTable} money={handleMoney} />
                                                                : null
                                                        }
                                                    </Form.Item>

                                                    <Form.Item label='ยอดจัด' name='loanAmount'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input disabled style={{ color: 'black' }} suffix="บาท"
                                                            onChange={(e) => setMoney({ ...money, loanAmount: e.target.value })} />
                                                    </Form.Item>

                                                    <Form.Item label='จำนวนงวด' name='loanTerm'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input disabled style={{ color: 'black' }} suffix="งวด"
                                                            onChange={(e) => setMoney({ ...money, loanTerm: e.target.value })} />
                                                    </Form.Item>

                                                    <Form.Item label='งวดละ' name='monthlyPayment'>
                                                        <Input disabled style={{ color: 'black' }} suffix="บาท"
                                                            onChange={(e) => setMoney({ ...money, monthlyPayment: e.target.value })} />
                                                    </Form.Item>

                                                    <Form.Item label='ดอกเบี้ย' name='interest'>
                                                        <Input disabled style={{ color: 'black' }} suffix="บาท"
                                                            onChange={(e) => setMoney({ ...money, interest: e.target.value })} />
                                                    </Form.Item>

                                                    <Form.Item label='รวมราคา' name='installmentWithInterest'>
                                                        <Input disabled style={{ color: 'black' }} suffix="บาท"
                                                            defaultValue={currencyFormatOne(~~getData?.carLoanDetails?.loanAmount + ~~getData?.carLoanDetails?.interest)}
                                                            onChange={(e) => setMoney({ ...money, installmentWithInterest: e.target.value })} />
                                                    </Form.Item>
                                                </>
                                            ) : null}

                                            {/* เช่าซื้อ */}

                                            {dataPost2 && typeof dataPost2.productTypeCar !== 'undefined' && (dataPost2.productTypeCar === 1 && checkPCH.PLorCH === "เช่าซื้อ") ? (
                                                <>
                                                    <Form.Item label='เช่าซื้อ'>
                                                        <Button type="primary" onClick={getTableHP}>
                                                            ตารางค่างวด เช่าซื้อ
                                                        </Button>
                                                        {
                                                            openTableHP ?
                                                                <HirePurchase open={openTableHP} close={setOpenTableHP} money={handleMoneyHP} />
                                                                : null
                                                        }
                                                    </Form.Item>

                                                    <Form.Item label='ยอดจัด' name='loanAmount'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !',
                                                            },]}>
                                                        <Input disabled style={{ color: 'black' }} suffix="บาท"
                                                            onChange={(e) => setMoney({ ...money, loanAmount: e.target.value })} />
                                                    </Form.Item>

                                                    <Form.Item label='จำนวนงวด' name='loanTerm'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !',
                                                            },]}>
                                                        <Input disabled style={{ color: 'black' }} suffix="งวด"
                                                            onChange={(e) => setMoney({ ...money, loanTerm: e.target.value })} />
                                                    </Form.Item>

                                                    <Form.Item label='งวดละ' name='monthlyPayment'>
                                                        <Input disabled style={{ color: 'black' }} suffix="บาท"
                                                            onChange={(e) => setMoney({ ...money, monthlyPayment: e.target.value })} />
                                                    </Form.Item>

                                                    <Form.Item label='ดอกเบี้ย' name='interest'>
                                                        <Input disabled style={{ color: 'black' }} suffix="บาท"
                                                            onChange={(e) => setMoney({ ...money, interest: e.target.value })} />
                                                    </Form.Item>

                                                    <Form.Item label='ราคารวม' name='installmentWithInterest'>
                                                        <Input disabled style={{ color: 'black' }} suffix="บาท"
                                                            defaultValue={getData?.carLoanDetails?.monthlyPayment * getData?.carLoanDetails?.loanTerm} />
                                                    </Form.Item>
                                                </>
                                            ) : dataPost2.productTypeCar === 1 && checkPCH.PLorCH === "เช่าซื้อ1" ?
                                                <>
                                                    <Form.Item label='ยอดจัด' name='loanAmount'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='number' suffix="บาท" name="loanAmount" style={{ color: 'black' }}
                                                            onChange={(e) => setMoney({ ...money, loanAmount: parseInt(e.target.value) })} />
                                                    </Form.Item>

                                                    <Form.Item label='อัตราดอกเบี้ย' name='interestRate'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Select
                                                            placeholder={'เลือกอัตราดอกเบี้ย'}
                                                            style={{ height: '40px' }}
                                                            onChange={(e) => setMoney({ ...money, interestRate: parseFloat(e) })}
                                                            options={[
                                                                {
                                                                    label: '0.84%',
                                                                    value: 0.84,
                                                                },
                                                                {
                                                                    label: '0.75%',
                                                                    value: 0.75,
                                                                },
                                                            ]}
                                                        />
                                                    </Form.Item>

                                                    <Form.Item label='จำนวนงวด' name='loanTerm'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='number' suffix="งวด" name="loanTerm" style={{ color: 'black' }}
                                                            onChange={(e) => setMoney({ ...money, loanTerm: parseInt(e.target.value) })} />
                                                        {/* onChange={(e) => { car_Calculate(e) }} /> */}
                                                    </Form.Item>

                                                    <Form.Item label='งวดละ' name='monthlyPayment'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='number' suffix="บาท" disabled style={{ color: 'black' }}
                                                            onChange={(e) => setMoney({ ...money, monthlyPayment: parseInt(e.target.value) })} />
                                                    </Form.Item>

                                                    <Form.Item label='ดอกเบี้ย' name='interest'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='number' suffix="บาท" disabled style={{ color: 'black' }}
                                                            onChange={(e) => setMoney({ ...money, interest: parseInt(e.target.value) })} />
                                                    </Form.Item>

                                                    <Form.Item label='รวมราคา' name='installmentWithInterest'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='number' suffix="บาท" disabled style={{ color: 'black' }}
                                                            defaultValue={currencyFormatOne(~~getData?.carLoanDetails?.loanAmount + ~~getData?.carLoanDetails?.interest)}
                                                            onChange={(e) => setMoney({ ...money, installmentWithInterest: parseInt(e.target.value) })} />
                                                    </Form.Item>
                                                </>
                                                : null
                                            }

                                            {dataPost2 && typeof dataPost2.productTypeCar !== 'undefined' && (dataPost2.productTypeCar === 2 && checkPCH.PLorCH === "เช่าซื้อ") ? (
                                                <>
                                                    <Form.Item label='ยอดจัด' name='loanAmount'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='number' suffix="บาท" name="loanAmount" style={{ color: 'black' }}
                                                            onChange={(e) => setMoney({ ...money, loanAmount: parseInt(e.target.value) })} />
                                                    </Form.Item>

                                                    <Form.Item label='อัตราดอกเบี้ย' name='interestRate'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='number' suffix="% +VAT" name="interestRate" style={{ color: 'black' }}
                                                            onChange={(e) => setMoney({ ...money, interestRate: parseFloat(e.target.value) })} />
                                                    </Form.Item>

                                                    <Form.Item label='จำนวนงวด' name='loanTerm'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='number' suffix="งวด" name="loanTerm" style={{ color: 'black' }}
                                                            onChange={(e) => setMoney({ ...money, loanTerm: parseInt(e.target.value) })} />
                                                    </Form.Item>

                                                    <Form.Item label='งวดละ' name='monthlyPayment'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='number' suffix="บาท" disabled style={{ color: 'black' }}
                                                            onChange={(e) => setMoney({ ...money, monthlyPayment: parseInt(e.target.value) })} />
                                                    </Form.Item>

                                                    <Form.Item label='ดอกเบี้ย' name='interest'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='number' suffix="บาท" disabled style={{ color: 'black' }}
                                                            onChange={(e) => setMoney({ ...money, interest: parseInt(e.target.value) })} />
                                                    </Form.Item>

                                                    <Form.Item label='รวมราคา' name='installmentWithInterest'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='number' suffix="บาท" disabled style={{ color: 'black' }}
                                                            defaultValue={currencyFormatOne(~~getData?.carLoanDetails?.loanAmount + ~~getData?.carLoanDetails?.interest)}
                                                            onChange={(e) => setMoney({ ...money, installmentWithInterest: parseInt(e.target.value) })} />
                                                    </Form.Item>
                                                </>
                                            ) : null}

                                            {dataPost2 && typeof dataPost2.productTypeCar !== 'undefined' && (dataPost2.productTypeCar === 3 && checkPCH.PLorCH === "เช่าซื้อ") ? (
                                                <>
                                                    <Form.Item label='ยอดจัด' name='loanAmount'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='number' suffix="บาท" style={{ color: 'black' }}
                                                            onChange={(e) => setMoney({ ...money, loanAmount: parseInt(e.target.value) })} />
                                                    </Form.Item>

                                                    <Form.Item label='อัตราดอกเบี้ย' name='interestRate'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='number' suffix="% +VAT" style={{ color: 'black' }}
                                                            onChange={(e) => setMoney({ ...money, interestRate: parseFloat(e.target.value) })} />
                                                    </Form.Item>

                                                    <Form.Item label='จำนวนงวด' name='loanTerm'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='number' suffix="งวด" style={{ color: 'black' }}
                                                            onChange={(e) => setMoney({ ...money, loanTerm: parseInt(e.target.value) })} />
                                                    </Form.Item>

                                                    <Form.Item label='งวดละ' name='monthlyPayment'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='number' suffix="บาท" disabled style={{ color: 'black' }}
                                                            onChange={(e) => setMoney({ ...money, monthlyPayment: parseInt(e.target.value) })} />
                                                    </Form.Item>

                                                    <Form.Item label='ดอกเบี้ย' name='interest'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='number' suffix="บาท" disabled style={{ color: 'black' }}
                                                            onChange={(e) => setMoney({ ...money, interest: parseInt(e.target.value) })} />
                                                    </Form.Item>

                                                    <Form.Item label='รวมราคา' name='installmentWithInterest'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='number' suffix="บาท" disabled style={{ color: 'black' }}
                                                            defaultValue={currencyFormatOne(~~getData?.carLoanDetails?.loanAmount + ~~getData?.carLoanDetails?.interest)}
                                                            onChange={(e) => setMoney({ ...money, installmentWithInterest: parseInt(e.target.value) })} />
                                                    </Form.Item>
                                                </>
                                            ) : null}

                                            {(moneyOld && moneyOld.productLoanType === 'ย้ายไฟแนนซ์') || (moneyOld && moneyOld.productLoanType === 'Pre-Aaprove-ย้ายไฟแนนซ์') ? (
                                                <>
                                                    <Form.Item label='ปิดไฟแนนซ์จาก' name='issuno'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='text' style={{ color: 'black' }}
                                                            onChange={(e) => setMoneyOld({ ...moneyOld, issuno: e.target.value })} />
                                                    </Form.Item>
                                                    <Form.Item label='ยอดปิดไฟแนนซ์' name='oldLoanAmount'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='number' suffix="บาท" style={{ color: 'black' }}
                                                            onChange={(e) => setMoneyOld({ ...moneyOld, oldLoanAmount: parseInt(e.target.value) })} />
                                                    </Form.Item>

                                                    <Form.Item label='ค่างวด/เดือน' name='oldMonthlyPayment'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='number' suffix="บาท" style={{ color: 'black' }}
                                                            onChange={(e) => setMoneyOld({ ...moneyOld, oldMonthlyPayment: parseInt(e.target.value) })} />
                                                    </Form.Item>

                                                    <Form.Item label='จำนวนผ่อนกี่งวด' name='oldLoanTerm'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='number' suffix="งวด" style={{ color: 'black' }}
                                                            onChange={(e) => setMoneyOld({ ...moneyOld, oldLoanTerm: parseInt(e.target.value) })} />
                                                    </Form.Item>

                                                    {/* <Form.Item label='ยอดที่ค้างทั้งหมด' name='oldKangPayment'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='number' suffix="บาท" style={{ color: 'black' }}
                                                            onChange={(e) => setMoneyOld({ ...moneyOld, oldKangPayment: parseInt(e.target.value) })} />
                                                    </Form.Item>

                                                    <Form.Item label='ค้างกี่งวด' name='oldKangPaymentTerm'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !'
                                                            },]}>
                                                        <Input type='number' suffix="งวด" style={{ color: 'black' }}
                                                            onChange={(e) => setMoneyOld({ ...moneyOld, oldKangPaymentTerm: parseInt(e.target.value) })} />
                                                    </Form.Item> */}
                                                </>
                                            ) : null}
                                        </>
                                    )}
                                    <Form.Item label='ผู้เสนอเคส' name='proposalBy'>
                                        <Input disabled style={{ color: 'black' }}
                                            onChange={(e) => setMoney({ ...money, proposalBy: e.target.value })} />
                                    </Form.Item>
                                </div>
                            </Row>
                            <Divider style={{ margin: 5 }} />
                            <Row justify={'center'}>
                                <Space>
                                    <Button type="text" style={{ background: "#bfbfbf" }} onClick={onChangeBack}>
                                        ย้อนกลับ
                                    </Button>
                                    <Button type="primary" danger onClick={handleCancel}>
                                        ยกเลิก
                                    </Button>
                                    <Button type="primary" htmlType="submit" style={{ background: 'green' }}>
                                        บันทึก
                                    </Button>
                                </Space>
                            </Row>
                        </Form>
                    </Row>
                </Spin >
            </Card >
        </>
    )
}
