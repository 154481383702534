import React, { useState, useEffect } from 'react'
import { Modal, Form, Button, Row, Spin, Input, Divider, Col, Space, Upload, message, Image } from 'antd'
import dayjs from 'dayjs';
import Compressor from 'compressorjs';
import { PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import { addcusforgua, uploadImgGuaTable, getFolderGuaImg, deleteImgGuaCar } from '../../../file_mid/all_api';

const { confirm } = Modal;

function EditGua({ open, close, dataindex, shootdata, sb, preData }) {

    const [form] = Form.useForm()
    const token = localStorage.getItem('token');
    const [loading, setLoading] = useState(false);
    const [getData, setGetData] = useState({ ...shootdata.dataedit });
    const [getimages, setImages] = useState([]);
    const [getIndex, setGetIndex] = useState(dataindex);
    const [fileListGuarantors, setFileListGuarantors] = useState([]);
    const [fileListGuarantors1, setFileListGuarantors1] = useState([]);

    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');

    // console.log("getData888", getData)

    useEffect(() => {
        getImg()
        setGetData(shootdata.dataedit)
        setGetIndex(dataindex)
        if (shootdata.dataedit) {
            form.setFieldsValue({
                ...shootdata.dataedit,
                telp: shootdata?.dataedit?.phones[0]?.telp
            })
        }

    }, [])

    const getImg = async () => {
        setLoading(true)
        await axios.get(`${getFolderGuaImg}/${shootdata?.dataedit?.pathImg}?token=${token}`)
            .then(res => {
                if (res.data) {
                    setImages(res.data)
                    setLoading(false)
                }
            })
            .catch((err) => {
                console.log("err", err)
                setLoading(false)
            })
        setLoading(false)
    }

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
    };
    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };
    const handleCancel1 = () => {
        setPreviewVisible(false);
    }
    //////////////////// รูปเอกสารผู้ค้ำ ///////////////////////
    const handleChangeGuarantors1 = async ({ fileList }) => {
        setLoading(true)
        console.log("fileList", fileList)
        setFileListGuarantors(fileList);
        var imageListGuarantors = []
        var i = 0
        for (const file of fileList) {
            var segments = []
            if (getData.pathImg === "") {
                segments[4] = getData.garno
            } else {
                segments = getData.pathImg.split("/")
            }

            // const segments = getData.pathImg.split("/")
            // console.log("segments", segments[4])

            const compressedFile = await new Promise((resolve) => {
                new Compressor(file.originFileObj, {
                    quality: 0.1, // เช่น, 60%
                    success: (result) => {
                        resolve(result);
                    },
                });
            });
            var image = {}
            var filename = file.name
            var image64 = await getBase64(compressedFile)
            //var image64 = file.originFileObj
            image.filename = filename
            image.image64 = image64
            image.type = `5.${segments[4]}`
            imageListGuarantors.push(image)
            i++
        }
        setFileListGuarantors1({ ...fileListGuarantors1, imageListGuarantors });
        setLoading(false)
    };

    const handleCancel = () => {
        close(false);
    };

    const showConfirmSend = ({ getIndex, InputImage }) => {
        setLoading(true)
        confirm({
            title: 'บันทึกข้อมูลคนค้ำ?',
            onOk() {
                handleSubmitOne()
            },
            onCancel() {
                message.error('ยกเลิก')
                setLoading(false)
            },
        });

    }

    const handleSubmitOne = async () => {
        if (getData.pathImg === "") {
            console.log("if")
            await axios.post(addcusforgua, [{ ...getData, pathImg: `one_images/${getData?.typeLoan}/${getData?.carlandId}/5/${getData?.garno}` }])
                .then(res => {
                    if (res.status === 200) {
                        handleSubmitTwo()
                        console.log("OK")
                    }
                })
                .catch((err) => {
                    console.log("err", err)
                    setLoading(false)
                })
        } else {
            console.log("else")
            await axios.post(addcusforgua, [getData])
                .then(res => {
                    if (res.status === 200) {
                        handleSubmitTwo()
                        console.log("OK")
                    }
                })
                .catch((err) => {
                    console.log("err", err)
                    setLoading(false)
                })
        }
    }


    const handleSubmitTwo = () => {
        console.log("getDataasds : ", getData, getIndex);
        const imageListGuarantorPush = [];
        if (fileListGuarantors1?.imageListGuarantors) {
            imageListGuarantorPush.push(...fileListGuarantors1?.imageListGuarantors)
        }
        console.log("imageListGuarantorPush", imageListGuarantorPush)
        var data
        // data = [{ ...getData, imgs: imageListGuarantorPushs }] //ต่อ array
        console.log("data", data, getIndex)
        console.log("imageListGuarantorPush", imageListGuarantorPush)
        const InputImage = {
            mainImg: { carId: (shootdata.dataedit.carlandId).toString(), carInput: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"), productTypeId: 1, img: imageListGuarantorPush }
        }
        console.log("InputImage", InputImage)
        // DataImagesGuEdit({ getIndex, InputImage })
        if (InputImage?.mainImg?.img?.length > 0) {
            // console.log("11")
            handleSubmitThree(InputImage)
        } else {
            sb(false)
            setLoading(false)
            setTimeout(() => {
                close(false);
            }, 1500)
        }

    }

    const handleSubmitThree = async (InputImage) => {
        console.log("InputImage", InputImage)
        setLoading(true)
        await axios.post(uploadImgGuaTable, InputImage)
            .then(response => {
                if (response) {
                    console.log("response", response)
                    sb(false)
                    setLoading(false)
                    setTimeout(() => {
                        close(false);
                    }, 1500)
                }
            }).catch((err) => {
                console.log("err", err)
            })
    }

    const showConfirm = async (e, index) => {
        console.log("e, index", e, index)
        confirm({
            title: 'คุณต้องการที่จะลบรูปภาพนี้ใช่หรือไม่...?',
            onOk() {
                setLoading(true)
                axios.delete(`${deleteImgGuaCar}/${e.idImage}/${e.pathImage}`)
                    .then(res => {
                        console.log("res delete", res)
                        setTimeout(() => {
                            // console.log("resAddGu =", res.data)
                            const updatedImageBlobzz = [...getimages];
                            updatedImageBlobzz.splice(index, 1);
                            setImages(updatedImageBlobzz);
                            // DeleteImgSucc("top")
                            setLoading(false)
                        }, 2000)
                    })
                    .catch((err) =>
                        console.log(err)
                    )
            },
            onCancel() {
                message.error('ยกเลิกการลบรูปภาพ');
            },
        });
        setLoading(false)
    };

    const handlePhoneChange = (e) => {
        // const updatedGuarantors = getData;

        // if (getData[0]?.phones !== undefined) {
        //     updatedGuarantors[0].phones[0].telp = e.target.value;
        //     setGetData(updatedGuarantors);
        // }
        // else {
        setGetData({ ...getData, phones: [{ phoneId: 1241, telp: e.target.value, phoneType: "เบอร์ติดต่อ", sendSms: "1" }] })
        // }
    };


    return (
        <Modal open={open} onCancel={handleCancel} footer={[null]}>
            <Spin spinning={loading} size='large' tip=" Loading... ">
                <Form
                    name="basic"
                    labelCol={{
                        span: 12,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    style={{
                        width: '100%',
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    form={form}
                    onFinish={showConfirmSend}
                    autoComplete="off"
                >
                    <Row justify={'center'}><b><u>แก้ไขข้อมูลคนค้ำ</u></b></Row>
                    <Divider style={{ margin: 5 }} />
                    <Row justify={'center'} className='main2'>
                        <Form.Item name='identificationId' label='เลขบัตรประชาชน'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input !',
                                },]}
                        >
                            <Input
                                disabled
                                name='identificationId'
                                placeholder="ชื่อ"
                                style={{ color: 'black' }}
                                initialvalues={getData?.identificationId}
                                onChange={(e) => setGetData({ ...getData, identificationId: e.target.value })}
                            />
                        </Form.Item>
                        <aside>
                            <div>
                                <Form.Item name='firstname' label='ชื่อ'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}
                                >
                                    <Input
                                        name='firstname'
                                        disabled={preData}
                                        placeholder="ชื่อ"
                                        style={{ color: 'black' }}
                                        initialvalues={getData?.firstname}
                                        onChange={(e) => setGetData({ ...getData, firstname: e.target.value })}
                                    />
                                </Form.Item>
                                <Form.Item name='lastname' label='นามสกุล'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}
                                >
                                    <Input
                                        name='lastname'
                                        disabled={preData}
                                        placeholder="นามสกุล"
                                        style={{ color: 'black' }}
                                        initialvalues={getData?.lastname}
                                        onChange={(e) => setGetData({ ...getData, lastname: e.target.value })}

                                    />
                                </Form.Item>
                            </div>
                            <div>
                                <Form.Item name='nickname' label='ชื่อเล่น'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}
                                >
                                    <Input
                                        name='nickname'
                                        disabled={preData}
                                        placeholder="ชื่อเล่น"
                                        style={{ color: 'black' }}
                                        initialvalues={getData?.nickname}
                                        onChange={(e) => setGetData({ ...getData, nickname: e.target.value })}

                                    />
                                </Form.Item>
                                <Form.Item name='telp' label='เบอร์โทร'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}
                                >
                                    <Input name='telp' disabled={preData} style={{ color: 'black' }} placeholder="เบอร์โทร" onChange={handlePhoneChange} />
                                </Form.Item>
                                <Divider />
                                <Row justify={'left'} > <span><b>รูปเอกสารผู้ค้ำ :</b></span></Row>
                                <Row justify={'center'}>
                                    <Form.Item
                                        label=''
                                        name='imgGuarantor'
                                        style={{ textAlign: 'center' }}
                                    >
                                        <Upload
                                            multiple={true}
                                            listType="picture-card"
                                            fileList={fileListGuarantors}
                                            onPreview={handlePreview}
                                            onChange={handleChangeGuarantors1}
                                            // onChange={handleChangeGuarantor}
                                            beforeUpload={() => false}
                                        >
                                            {fileListGuarantors?.length >= 10 ? null : (
                                                <div>
                                                    <PlusOutlined />
                                                    <div className="ant-upload-text">upload</div>
                                                </div>
                                            )}
                                        </Upload>
                                        <Modal open={previewVisible} onCancel={handleCancel1}>
                                            <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                        </Modal>
                                    </Form.Item>
                                </Row>
                                <Divider />
                                <Row justify={'center'}>
                                    <Col span={24}>
                                        <>
                                            <Row justify={'left'} > <span><b>เอกสารผู้ค้ำ :</b></span></Row>
                                            {getimages?.map((item, index) => {
                                                // console.log("item", item)
                                                return (
                                                    <>
                                                        <Row justify={'center'}>
                                                            <Col span={24} style={{ textAlign: 'center' }}>
                                                                {/*กรณีโชว์รูปโดยที่ยังไม่เข้าฐานข้อมูล */}
                                                                {/* <img width={'300px'} key={index} style={{ margin: 5 }} src={createImageUrl(item.image64)} alt={item.filename} /> */}
                                                                {/* ลบทีละ 1 รูป */}
                                                                {/* <p><Button onClick={() => showConfirm(item, index)} type='primary' danger>ลบรูปภาพ</Button></p> */}
                                                                {/* <h1>{item.pathImage}</h1> */}
                                                                {/*กรณีโชว์รูปที่เข้าฐานข้อมูลแล้ว */}
                                                                <Image width={'150px'} key={index} src={`data:${item?.Attr};base64,${item?.data.toString('base64')}`} alt={`Image ${index + 1}`} />
                                                                <p><Button onClick={() => showConfirm(item, index)} type='primary' danger>ลบรูปภาพ</Button></p>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                )

                                            })}

                                        </>

                                    </Col>
                                </Row>
                                {/* } */}
                            </div>
                        </aside>
                    </Row>
                    <Divider />
                    <Row>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <Space>
                                <Button type="primary" onClick={handleCancel} style={{ background: "red" }}>ยกเลิก</Button>
                                <Button type="primary" htmlType="submit" style={{ background: "green" }} >บันทึก</Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </Modal >
    )
}

export default EditGua