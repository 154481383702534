import React, { useEffect, useState } from 'react'
import { Button, Form, Card, Col, Row, message, Space, Divider, Spin, Image, Modal, Checkbox } from 'antd';
import axios from 'axios';
import PrintImg from './PrintImg';
import FileSaver from 'file-saver';
import JSZip from 'jszip';
import { setTypeImgGuarantor } from '../../../file_mid/type_img';
import { storeDBLine, getImagess, getAllFolderGuaImg, downloadImagessPre, deleteNewUpCar, deleteNewUpLand } from '../../../file_mid/all_api';

export default function Guarantor({ getData, DeleteError, deleteData, checkType, guarantor1, checkSendNEW }) {
    const un = localStorage.getItem('username');
    const token = localStorage.getItem('token');
    const view = localStorage.getItem('viewAD')
    const detail = JSON.parse(view)
    const [dataModalEdit, setDataModalEdit] = useState(getData);
    const [messageApi, contextHolder] = message.useMessage();
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const { confirm } = Modal;
    const [getimages, setGetImages] = useState([])
    const [adData, setAdData] = useState([])
    const [printImg, setPrintImg] = useState(false)
    // const [cb, setCb] = useState(false)
    const [checkedImages, setCheckedImages] = useState([]);

    // console.log("guarantor1", guarantor1)
    // console.log("detail", detail)
    // console.log("getimages", getimages)
    useEffect(() => {
        setDataModalEdit(getData)
        if (guarantor1 && guarantor1?.length > 0 && printImg === false) {
            getImg()
        }
    }, [getData, printImg])

    const getImg = async () => {
        setLoading(true)
        if (detail?.car === "car") {
            const path = `one_images/car/${detail?.carId}/5`
            // console.log("path", path)
            await axios.get(`${getAllFolderGuaImg}/${path}?token=${token}`)
                // await axios.get(`http://localhost:8080/ApimgGet/GetAllFolderGuaImg/${path}?token=${token}`)
                .then(res => {
                    if (res.data) {
                        console.log("res.data car", res.data)
                        setGetImages(res.data)
                        onChangeCheckboxAll(true, res.data)
                        setLoading(false)
                    } else {
                        setGetImages([])
                    }
                })
                .catch((err) => {
                    console.log("err", err)
                    setLoading(false)
                })
        } else {
            const path = `one_images/land/${detail?.carId}/5`
            console.log("path", path)
            await axios.get(`${getAllFolderGuaImg}/${path}?token=${token}`)
                .then(res => {
                    if (res.data) {
                        console.log("res.data land", res.data)
                        setGetImages(res.data)
                        onChangeCheckboxAll(true, res.data)
                        setLoading(false)
                    } else {
                        setGetImages([])
                    }
                })
                .catch((err) => {
                    console.log("err", err)
                    setLoading(false)
                })
        }
        setLoading(false)
    }

    window.addEventListener('beforeunload', function () {
        localStorage.removeItem('viewAD');
    })

    const onChangeCheckboxAll = (isChecked, dataME) => {
        // console.log(isChecked, dataME)
        setLoading(true)
        if (isChecked) {
            // setCb(true)
            if (dataME?.length > 0) {
                var apSplits = []
                var resImg = []
                for (const ez of dataME) {
                    var image = {}
                    apSplits = ez.pathImage.split("/")
                    // console.log("apSplits", apSplits[4])
                    // console.log("ez", ez)
                    image.garno = apSplits[4]
                    image.pathImage = ez.pathImage
                    image.idImage = ez.idImage
                    resImg.push(image)
                }
            }
            setCheckedImages(resImg);
        } else {
            // setCb(false)
            setCheckedImages([])
        }
        setLoading(false)
    };
    const downloadImages = async () => {
        // console.log("checkedImages",checkedImages)
        setLoading(true)
        const names = detail.firstName + detail.lastName
        if (checkedImages.length > 0) {
            try {
                const zip = new JSZip();
                for (const image of checkedImages) {
                    // console.log("image.pathImage",image.pathImage)
                    const folderName = setTypeImgGuarantor(image.garno);
                    const folder = zip.folder(folderName); // สร้างโฟลเดอร์ใหม่ใน ZIP
                    const response = await fetch(`${downloadImagessPre}/${image.pathImage}?token=${token}`);
                    const blob = await response.blob();
                    folder.file(`${image.idImage}.jpg`, blob);
                }
                // สร้างไฟล์ ZIP
                const zipBlob = await zip.generateAsync({ type: 'blob' });
                // บันทึกไฟล์ ZIP และดาวน์โหลด
                FileSaver.saveAs(zipBlob, `${names} ประวัติคนค้ำ.zip`);
            } catch (error) {
                console.error('Error downloading images:', error);
            }
        } else {
            alert('ไม่พบรูปภาพ');
        }
        setLoading(false)
    };

    // const handleChangepage = () => {
    //     changepage(page + 1)
    // }
    // const onChangeBack = () => {
    //     changepage(page - 1)
    // }

    const handlePrint = () => {
        // console.log("adData", adData)
        if (adData?.length > 0) {
            setPrintImg(true)
        } else {
            messageApi.open({
                type: 'warning',
                content: 'กรุณากดเลือกรูปภาพ !!!',
                style: {
                    marginTop: '20vh',
                },
            });
        }
    }

    const onChangeCheckbox = (e, items) => {
        //console.log("e", e.target.checked)
        //console.log("items", items)
        if (e.target.checked === true) {
            adData.push(items)
        } else {
            for (const removedFile of adData.filter(file => e.target.checked === false)) {
                if (adData.some(img => img.idImage === removedFile.idImage)) {
                    const updatedAllImg = adData.filter(img => !(img.idImage === removedFile.idImage));
                    setAdData(updatedAllImg);
                }
            }
        }
    }

    const showConfirm = (e, index) => {
        confirm({
            title: 'คุณต้องการที่จะลบรูปภาพนี้ใช่หรือไม่...?',
            onOk() {
                // message.success('ลบรูปภาพสำเร็จ');
                if (checkType.car === "car") {
                    handleDeleteCar(e, index)
                } else {
                    handleDeleteLand(e, index)
                }
            },
            onCancel() {
                // message.error('ยกเลิกการลบรูปภาพ');
            },
        });
    };

    const handleDeleteCar = async (e, index) => {
        const id = e.idImage
        const path = e.pathImage
        setLoading(true)
        await axios.delete(`${deleteNewUpCar}/${id}/${path}`)
            .then(async res => {
                if (res.data) {
                    const updatedImageBlobzz = [...dataModalEdit];
                    updatedImageBlobzz.splice(index, 1);
                    setDataModalEdit(updatedImageBlobzz);
                    await handleNoti(path)
                }
            })
            .catch((err) =>
                DeleteError("top")
            )
        setLoading(false)
    }

    const handleDeleteLand = async (e, index) => {
        const id = e.idImage
        const path = e.pathImage
        setLoading(true)
        await axios.delete(`${deleteNewUpLand}/${id}/${path}`)
            .then(async res => {
                if (res.data) {
                    const updatedImageBlobzz = [...dataModalEdit];
                    updatedImageBlobzz.splice(index, 1);
                    setDataModalEdit(updatedImageBlobzz);
                    await handleNoti(path)
                }
            })
            .catch((err) =>
                DeleteError("top")
            )
        setLoading(false)
    }

    const handleNoti = async (path) => {
        const ez = { nameInput: un, path: path }
        await axios.post(storeDBLine, ez)
            .then(res => {
                if (res.data) {
                    console.log("OK")
                    deleteData("top")
                    setLoading(false)
                }
            })
            .catch((err) =>
                DeleteError("top")
            )
        setLoading(false)
    }

    const renderGuarantor = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
        // console.log("Item", item, index)
        var itemSplit = []
        if (item?.pathImg) {
            itemSplit = item.pathImg.split("/")
        }
        return (
            <>
                <Card style={{ width: '120%' }}>
                    <div><b><u>ลำดับที่ {index + 1}</u></b></div>
                    <aside style={{ width: '90%' }}>
                        <div style={{ marginBottom: 0 }}>
                            <span>ชื่อ-นามสกุล : </span><b>{item?.snam + ' ' + item?.firstname + ' ' + item?.lastname}</b>
                        </div>
                        <Divider style={{ margin: 5 }} />
                        {
                            itemSplit.length > 0 ? (
                                <>
                                    <Image.PreviewGroup>
                                        <Row gutter={32} justify={'center'}>
                                            <Space direction="vertical">
                                                {getimages?.map((items, index) => {
                                                    const segments = items.pathImage.split("/")
                                                    if (itemSplit[4] === segments[4]) {
                                                        // console.log("test",getimages.length)
                                                        return (
                                                            <>
                                                                <Checkbox style={{ margin: 10 }} key={index} onChange={(e) => onChangeCheckbox(e, items)}>
                                                                    <Image style={{ marginBottom: 24 }} width={300} key={index} src={`data:${items?.Attr};base64,${items?.data?.toString('base64')}`} alt={`Image ${index + 1}`} />
                                                                </Checkbox>
                                                            </>
                                                        )
                                                    }
                                                    return null
                                                })}
                                            </Space>
                                        </Row>
                                    </Image.PreviewGroup>
                                </>
                            ) : null
                        }
                    </aside>
                </Card>
            </>
        )
    }

    return (
        <div>
            <Card style={{ padding: 20 }} className="modal-img">
                <div style={{ textAlign: 'center' }}>
                    <Row style={{ textAlign: 'center' }} >
                        <Col span={24} style={{ fontSize: '30px' }} >ประวัติคนค้ำ / ทะเบียนบ้าน / อาชีพ</Col>
                    </Row>
                    <Row style={{ textAlign: 'right' }} >
                        <Col span={24} style={{ fontSize: '30px' }} >
                            <Space>
                                {/* <Checkbox defaultChecked={true} disabled style={{ margin: 10 }} onChange={(es) => onChangeCheckboxAll(es.target.checked)}>
                    เลือกรูปรูปบัตรประชาชน
                  </Checkbox> */}
                                <Button style={{ background: '#bfbfbf', color: '#000000', }} onClick={downloadImages}>ดาวน์โหลดรูปภาพ</Button>
                            </Space>
                        </Col>
                    </Row>
                    <Divider></Divider>
                    <Spin spinning={loading} size='large' tip=" Loading... ">
                        <Form form={form} >
                            {
                                getimages?.length > 0 ?
                                    <>
                                        <Row justify={'left'}><b><u>ประวัติคนค้ำ / ทะเบียนบ้าน / อาชีพ </u></b></Row>
                                        <Row gutter={32} justify={'center'}>
                                            <Space direction="vertical" >
                                                {
                                                    guarantor1?.map((item, index) => {
                                                        return renderGuarantor({ item, index })
                                                    })
                                                }
                                            </Space>
                                        </Row>
                                        <Divider />
                                    </>
                                    :
                                    <>
                                        <Image.PreviewGroup>
                                            <Row justify={'left'}><b><u>ประวัติคนค้ำ / ทะเบียนบ้าน / อาชีพ </u></b></Row>
                                            <Row gutter={32} justify={'center'}>
                                                <Space direction="vertical" >
                                                    {dataModalEdit?.map((e, index) => {
                                                        if (e.type === 5) {
                                                            return (
                                                                <>
                                                                    <Image width={350} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                    <p><Button disabled={checkSendNEW} onClick={() => showConfirm(e, index)} type='primary' danger>ลบรูปภาพ</Button></p>

                                                                </>
                                                            );
                                                        }
                                                        return null;
                                                    })}
                                                </Space>
                                            </Row>
                                            <Divider />
                                            <Row gutter={32} justify={'center'}>
                                                <Space direction="vertical" >
                                                    {dataModalEdit?.map((e, index) => {
                                                        if (e.type === 9) {
                                                            return (
                                                                <>
                                                                    <Image width={350} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                    <p><Button disabled={checkSendNEW} onClick={() => showConfirm(e, index)} type='primary' danger>ลบรูปภาพ</Button></p>
                                                                </>
                                                            );
                                                        }
                                                        return null;
                                                    })}
                                                </Space>
                                            </Row>
                                        </Image.PreviewGroup>
                                        <Divider />
                                    </>
                            }
                            <div style={{ textAlign: "center" }} >
                                <Space>
                                    {/* <Button type="primary" style={{ background: '#bfbfbf', color: '#000000', }} onClick={onChangeBack}>ย้อนกลับ</Button> */}
                                    <Button type="primary" style={{ backgroundColor: "green" }} onClick={handlePrint}>
                                        หน้าต่างปริ้น
                                    </Button>
                                    {/* <Button type="primary" onClick={handleChangepage}>
                                        ต่อไป
                                    </Button> */}
                                </Space>
                            </div>
                        </Form>
                    </Spin>
                </div>
            </Card>
            {contextHolder}
            {
                printImg ?
                    <PrintImg open={printImg} close={setPrintImg} adData={adData} />
                    : null
            }
        </div>
    )
}
