import React, { useState } from 'react'
import { Button, Form, Input, Divider, Row, Col, Modal, Radio, Space, Spin } from 'antd';
import axios from 'axios';
import { createemail1 } from '../../../../file_mid/all_api';

export default function ModalAddEmail({ open, close, fucnAdd, shootdata, notification3, notification4 }) {

    const [form] = Form.useForm();
    const [idCus] = useState({ customerId: shootdata })
    const [email, setEmail] = useState({});
    const [loading, setLoading] = useState(false);

    const handleCancel = () => {
        close(false);
    };

    const handleSubmit = async () => {
        setLoading(true)
        const reult = { customer: idCus, emails: [{ ...email }] }
        await axios.post(createemail1, reult)
            .then(res => {
                if (res.status === 200) {
                    fucnAdd({ email })
                    notification3('top')
                    setLoading(false)
                    close(false);
                }
            }).catch((err) => {
                if (err.response.request.status === 400) {
                    notification4('top')
                    setLoading(false)
                }
                console.log("err", err)
            })
        setLoading(false)
    }

    return (
        <>
            <Modal
                open={open}
                centered
                onCancel={handleCancel}
                width={600}
                footer={[null]} >
                <Spin spinning={loading} size='large' tip=" Loading... ">
                    <Form
                        form={form}
                        name='fucnAdd'
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                        style={{
                            maxWidth: '100%',
                        }}
                        onFinish={handleSubmit}>
                        <Row>
                            <Col span={24} style={{ fontSize: '30px', textAlign: 'center' }} ><u>เพิ่ม e-mail</u></Col>
                        </Row>
                        <Divider />
                        <Row gutter={{ sx: 8, sm: 16, md: 24, lg: 32 }} >
                            <Col className='gutter-row' span={24}>
                                <Form.Item label="E-Mail" name='emailData'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <Input onChange={(e) => setEmail({ ...email, emailData: e.target.value })} />
                                </Form.Item>
                                <Form.Item label="รับข่าวสาร" name="sendGetNews"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <Radio.Group onChange={(e) => setEmail({ ...email, sendGetNews: e.target.value })} >
                                        <Radio value="1"> รับ </Radio>
                                        <Radio value="0"> ไม่รับ </Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item label="หมายเหตุ" name='note' >
                                    <Input onChange={(e) => setEmail({ ...email, note: e.target.value })} />
                                </Form.Item>
                                <Divider />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} style={{ textAlign: 'center' }}>
                                <Space>
                                    <Button type="primary" onClick={handleCancel} style={{ background: "red" }}>ยกเลิก</Button>
                                    <Button type="primary" htmlType="submit" style={{ background: "green" }} >บันทึก</Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        </>
    )
}
