import React, { useState, useEffect } from 'react'
import { Button, Form, Select, Col, Row, Input, Modal, Card, Upload, Spin, Space, InputNumber, Divider, AutoComplete } from 'antd';
import axios from 'axios';
import { PlusOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
// import utc from 'dayjs/plugin/utc';
// import timezone from 'dayjs/plugin/timezone';
import Currency from 'currency.js';
// import TextArea from 'antd/es/input/TextArea';

import Yearpick from '../../check_infoCar/modals/Yearpick'

import { HirePurchase } from '../hire_purchase/HirePurchase';
import { Installment } from '../ploan/Installment';
import { useDispatch, useSelector } from 'react-redux';
import { addCustomer } from '../../../redux/Customer';
import { addAddress } from '../../../redux/Address';
import { addPhone } from '../../../redux/Phone';
import { addCar } from '../../../redux/Car';
import { addLoan } from '../../../redux/Loan';
import { addOldLoan } from '../../../redux/OldLoan';
import { addImage } from '../../../redux/Image';
import { addLand } from '../../../redux/Land';
import { addLoanLand } from '../../../redux/LoanLand';
import { addOldLoanLand } from '../../../redux/OldLoanLand';
import '../../css/Media.css'

import TableRateCar from '../rate_car/TableRateCar';
import { productLoanTypeCar, productLoanTypeLand, loanPLorCH, loanPLorCHBig } from "../../file_mid/all_options";
import { AllData, GetAmphur1, GetProvince, PostProvince1, dataModel, getprice, loadProvinces, ratecarsmodel } from '../../file_mid/all_api';

export default function AddDetail() {
    const { TextArea } = Input;
    const currentTime = dayjs();
    const formattedTime = currentTime.format('YYYY-MM-DDTHH:mm:ssZ');
    const [modal, contextHolder] = Modal.useModal();
    const [form] = Form.useForm()
    const user = localStorage.getItem('username');
    const branch = localStorage.getItem('branch');
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const { Option } = Select;

    const customers = useSelector(state => state.customers)
    const cars = useSelector(state => state.cars)
    const loans = useSelector(state => state.loans)
    const oldloans = useSelector(state => state.oldloans)
    const lands = useSelector(state => state.lands)
    const loansLand = useSelector(state => state.loansLand)
    const oldLoansLand = useSelector(state => state.oldLoansLand)
    const imgs = useSelector(state => state.imgs)
    const guarantor = useSelector(state => state.guarantor)

    const addCustomers = localStorage.getItem('addCustomer')
    const addCars = localStorage.getItem('addCar')
    const addLoans = localStorage.getItem('addLoan')
    const addOldLoans = localStorage.getItem('addOldLoan')
    const addLands = localStorage.getItem('addLand')
    const addLoanLands = localStorage.getItem('addLoanLand')
    const addOldLoanLands = localStorage.getItem('addOldLoanLand')

    const dataAddCustomer = JSON.parse(addCustomers)
    const dataAddCars = JSON.parse(addCars)
    const dataAddLoans = JSON.parse(addLoans)
    const dataAddOldLoans = JSON.parse(addOldLoans)
    const dataAddLands = JSON.parse(addLands)
    const dataAddLoanLands = JSON.parse(addLoanLands)
    const dataAddOldLoanLands = JSON.parse(addOldLoanLands)

    let countoffer = 1;

    const [selectProduct, setSelectProduct] = useState({}) // 1 รถ , 2 ที่ดิน
    //////////////////// ที่ดิน /////////////////////////////
    const [dataPostLand, setDataPostLand] = useState({   // ที่ดิน
        landTypeId: 2,
        productTypeLand: 2,
        landInput: formattedTime,
        landDateStatus: formattedTime,
        rai: 0,
        workArea: 0,
        squareWaArea: 0,
        landPrice: 0,
        resultLandPrice: 0,
        landPriceStatus: false,
    })
    const [moneyLand, setMoneyLand] = useState({ loanAmounttLand: 50000, interestRateLand: 1.1 }) //loan land
    const [moneyOldLand, setMoneyOldLand] = useState({}) //loanOld land
    const [typeMoneyLand, setTypeMoneyLand] = useState({})

    const [pvcodeS, setPvcodeS] = useState({});
    const [amcodeS, setAmcodeS] = useState({});

    const [provinces, setProvinces] = useState([]);
    const [district, setDistricts] = useState([]);

    /////////////////////////////////////////////////////
    const [dataPost1, setDataPost1] = useState({}) //customer
    const [dataPost2, setDataPost2] = useState({ productTypeCar: 1, carBrand: "" })  //car
    const [money, setMoney] = useState({}) //loan
    const [moneyOld, setMoneyOld] = useState({}) //loanOld
    const [typeMoney, setTypeMoney] = useState({})
    const [khangTerm, setKhangTerm] = useState({})
    const [provincesCar, setProvincesCar] = useState([]);

    ///////////////// ตาราง ///////////////////////////////
    const [openTable, setOpenTable] = useState(false); //ตารางค่างวด p-loan
    const [openTableHP, setOpenTableHP] = useState(false); //ตารางค่างวด เช่าซื้อ
    const [openTableCar, setOpenTableCar] = useState(false); //ตารางเรทรถ
    const [count, setCount] = useState(false) //ย้ายไฟแนนซ์

    /////////////////// รถ ////////////////////////////////////
    const [resultData, setResultData] = useState([]) // API
    const [typeData, setTypeData] = useState([]) // API
    const [brandData, setBrandData] = useState([]) // API
    const [modelData, setModelData] = useState([]) // API
    const [yearPrice, setYearPrice] = useState([])
    const [typeSelected, setTypeSelected] = useState(null)
    //const [brandSelected, setBrandSelected] = useState(null)
    //const [carSelected, setCarSelected] = useState(null)

    ////////////////// select car ////////////////////////////////
    const [counterBrand, setCounterBrand] = useState(false)
    const [counterModel, setCounterModel] = useState(false)
    const [counterCarBaab, setCounterCarBaab] = useState(false)
    // const [counterModal, setCounterModal] = useState(false)
    // const [counterYear, setCounterYear] = useState(false)

    ///////////////// อัปโหลดรูป //////////////////////////////////////
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [fileList, setFileList] = useState([]);
    const [fileListBook, setFileListBook] = useState([]);
    const [fileListResult1, setFileListResult1] = useState([]);
    const [fileListResult2, setFileListResult2] = useState([]);
    // const [productTypeCar, setproductTypeCar] = useState();

    //////////////// เลือก p-loan หรือ เช่าซื้อ /////////////////////
    const [checkPCH, setCheckPCH] = useState({ PLorCH: "p-loan" })

    const [image, setImage] = useState([]);
    const [guarantors, setGuarantors] = useState(guarantor?.data);
    //const [numDetailImg, setNumDetailImg] = useState({ numDetailImg: 0 })
    const [selectTypeCar, setSelectTypeCar] = useState()
    //const [numImg, setNumImg] = useState({ numImg: 1 })
    const [checkinfo, setCheckInfo] = useState()
    const [checkButtonLand, setCheckButtonLand] = useState(0)

    // console.log("checkinfo", checkinfo)

    const config = {
        title: 'ความหมายของประเภทขอกู้ ?',
        // width: '100%',
        content: (
            <>
                {
                    checkinfo === "ถือเล่มมา" ?
                        <>
                            <b>ถือเล่มมา:</b> หน้าคู่มือหรือโฉนดไม่ได้ติดไฟแนนซ์อื่นอยู่
                        </>
                        : checkinfo === "ย้ายไฟแนนซ์" ?
                            <>
                                <b>ย้ายไฟแนนซ์:</b> หน้าคู่มือติดไฟแนนซ์อื่นอยู่
                            </>
                            : checkinfo === "ปรับโครงสร้าง" ?
                                <>
                                    <b>ปรับโครงสร้าง:</b> ลูกค้าเดิม ผ่อนไม่ไหว ให้ปรับโครงสร้าง (เฮียให้ยอด) (ไม่ได้เงิน)
                                </>
                                : checkinfo === "ซื้อ-ขาย" ?
                                    <>
                                        <b>ซื้อ-ขาย:</b> หน้าคู่มือ เป็นชื่อของเจ้าของ (จะซื้อรถมาเป็นของตัวเอง) แต่ชื่อยังเป็นเจ้าของคนเดิม
                                    </>
                                    : checkinfo === "Pre-Aaprove-ถือเล่มมา" ?
                                        <>
                                            <b>Pre-Aaprove-ถือเล่มมา:</b> เสนอมาเพื่อรู้เรทรถว่าได้เท่าไหร่
                                        </>
                                        : checkinfo === "Pre-Aaprove-ซื้อ-ขาย" ?
                                            <>
                                                <b>Pre-Aaprove-ซื้อ-ขาย:</b>ขอเสนอจะซื้อรถ ก่อนที่จะทำสัญญา
                                            </>
                                            : checkinfo === "Pre-Aaprove-ย้ายไฟแนนซ์" ?
                                                <>
                                                    <b>Pre-Aaprove-ย้ายไฟแนนซ์:</b> ขอยอดก่อนที่จะเอามาเขาไฟแนนซ์นี้ (จากไฟแนนซ์อื่น)
                                                </>
                                                : checkinfo === "Pre-Aaprove-รีโอน" ?
                                                    <>
                                                        <b>Pre-Aaprove-รีโอน:</b> ลูกค้าเดิม (2,8) อยากรีสัญญาเพื่อเพิ่มยอด เลยขอยอดเข้ามาก่อน
                                                    </>
                                                    : checkinfo === "เปลี่ยนสัญญา" ?
                                                        <>
                                                            <b>เปลี่ยนสัญญา:</b> มีสัญญาอยู่แล้ว แต่เปลี่ยนคนกู้ใหม่ (ไม่ได้เงิน)
                                                        </>
                                                        : checkinfo === "Pre-Aaprove-เปลี่ยนสัญญา" ?
                                                            <>
                                                                <b>Pre-Aaprove-เปลี่ยนสัญญา:</b> เสนอเข้ามาก่อน เปลี่ยนคนกู้
                                                            </>
                                                            : checkinfo === "รี+เปลี่ยนสัญญา" ?
                                                                <>
                                                                    <b>รี+เปลี่ยนสัญญา:</b> ลูกค้าเดิม แต่จะเปลี่ยนผู้กู้ (ได้เงิน)
                                                                </>
                                                                : checkinfo === "ปรับ+เปลี่ยนสัญญา" ?
                                                                    <>
                                                                        <b>ปรับ+เปลี่ยนสัญญา:</b> เป็นสัญญาเดิม เปลี่ยนคนกู้ (ไม่ได้เงิน)
                                                                    </>
                                                                    : checkinfo === "Pre-Aaprove-รี-Ploan" ?
                                                                        <>
                                                                            <b>Pre-Aaprove-รี-Ploan:</b> (3) เสนอเพื่อขอยอดเพิ่มเงิน (ได้เงิน)
                                                                        </>
                                                                        : checkinfo === "รี-3" ?
                                                                            <>
                                                                                <b>รี-3:</b> ลูกค้าเดิม มาขอยอดใหม่ เพื่อได้เงินทอน (ได้เงิน) การตลาดทำสัญญาแล้ว
                                                                            </>
                                                                            : checkinfo === "Pre-Aaprove-ปรับโครงสร้าง" ?
                                                                                <>
                                                                                    <b>Pre-Aaprove-ปรับโครงสร้าง:</b> การตลาดยังไม่ไป แต่ขอเสนอมา เพื่อรู้ว่าปรับได้หรือไม่
                                                                                </>
                                                                                : checkinfo === "Pre-Aaprove-รี" ?
                                                                                    <>
                                                                                        <b>Pre-Aaprove-รี:</b> ลูกค้าเดิม เสนอเข้ามา เพื่อขอยอดเพิ่ม (ได้เงิน) การตลาดยังไม่ได้ไป
                                                                                    </>
                                                                                    : checkinfo === "Pre-Aaprove-รี+เปลี่ยนสัญญา" ?
                                                                                        <>
                                                                                            <b>Pre-Aaprove-รี+เปลี่ยนสัญญา:</b> ลูกค้าเดิม เสนอเข้ามา เปลี่ยนคนกู้ใหม่ (ได้เงิน)
                                                                                        </>
                                                                                        : checkinfo === "Pre-Aaprove-Ploan" ?
                                                                                            <>
                                                                                                <b>Pre-Aaprove-Ploan:</b> เสนอเข้ามาก่อน เพื่อขอจัดบัญชี 3 ไม่โอนรถเป็นของบริษัท
                                                                                            </>
                                                                                            : null
                }
            </>
        ),
    };

    // console.log("!counterCarBaab", !counterCarBaab)

    useEffect(() => {
        setDataPost1(dataAddCustomer)
        setSelectProduct({ ...selectProduct, productTypeId: dataAddCustomer?.productTypeId })
        setSelectTypeCar({ ...selectTypeCar, productTypeCar: dataAddCustomer?.productTypeCar })
        setImage(imgs?.data)
        if (dataAddCustomer?.productTypeId === 1) { fetchAllProvinces() }
        if (dataAddCustomer?.productTypeId === 2) { getProvinces() }

        if (!dataAddCars && dataAddCustomer?.productTypeId === 1) {
            console.log("if1")
            setDataPost2({ ...dataPost2, productTypeCar: dataAddCustomer?.productTypeCar, carYear: 0, carPrice: 0, carPriceStatus: false, carInput: formattedTime, carDateStatus: formattedTime })
            setTypeData(dataAddCustomer?.productTypeCar)
            setTypeSelected(dataAddCustomer?.productTypeCar)
            loadDataBrand(dataAddCustomer?.productTypeCar)
            form.setFieldsValue({ proposalBy: user, PLorCH: "p-loan", carPrice: 0, productTypeCar: dataAddCustomer?.productTypeCar })
        }
        if (!dataAddLands) {
            form.setFieldsValue({ landTypeId: 2, proposalBy: user, loanAmounttLand: 50000 })
        }
        if (dataAddCars) {
            console.log("if2")
            //console.log("AA")
            setTypeData(dataAddCars?.productTypeCar)
            setTypeSelected(dataAddCars?.productTypeCar)
            loadDataBrand(dataAddCars?.productTypeCar)
            setSelectProduct({ ...selectProduct, productTypeId: 1 })
            //setDataPost1(customers.data)
            setDataPost2(dataAddCars)
            setMoney(dataAddLoans)
            // if (moneyOld?.productLoanType === "ย้ายไฟแนนซ์" || moneyOld?.productLoanType === "Pre-Aaprove-ย้ายไฟแนนซ์") {
            //     console.log("if2.1")
            //     setMoneyOld(moneyOld)
            // } else {
            //     console.log("if2.2")
            //     setMoneyOld(oldloans.data)
            // }

            if (moneyOld?.productLoanType) {
                // console.log("if2.1")
                setMoneyOld(moneyOld)
            } else {
                // console.log("if2.2")
                setMoneyOld(dataAddOldLoans)
            }

            // setMoneyOld()
            if (dataAddCars?.carPrice === 0) {
                AddCarBaab()
                var newYear = dataAddCars?.carYear

                form.setFieldsValue(
                    {
                        ...dataAddCustomer, ...dataAddOldLoans, ...dataAddCars,
                        proposalBy: user,
                        productLoanType: dataAddLoans?.productLoanType ? dataAddLoans?.productLoanType : '',
                        carPrice: currencyFormatOne(dataAddCars?.carPrice),
                        birthdate: dayjs(dataAddCustomer?.birthdate),
                        issudt: dayjs(dataAddCustomer?.issudt),
                        expdt: dayjs(dataAddCustomer?.expdt),
                        carInput: dayjs(dataAddCars?.carInput),
                        carYear: dayjs().year(newYear),
                    }
                )
            } else {
                form.setFieldsValue(
                    {
                        ...dataAddCustomer, ...dataAddOldLoans, ...dataAddCars,
                        // carBrand: cars.data.vehicle_brand,
                        // carModel: cars.data.vehicle_body_type,
                        // carBaab: cars.data.vehicle_model,
                        // carYear: cars.data.vehicle_model_year,
                        proposalBy: user,
                        productLoanType: dataAddLoans?.productLoanType ? dataAddLoans?.productLoanType : '',
                        carPrice: currencyFormatOne(dataAddCars?.carPrice),
                        birthdate: dayjs(dataAddCustomer?.birthdate),
                        issudt: dayjs(dataAddCustomer?.issudt),
                        expdt: dayjs(dataAddCustomer?.expdt),
                        carInput: dayjs(dataAddCars?.carInput),
                        // carYear: dayjs(cars?.data?.carYear),
                        // loanAmountt: currencyFormatOne(loans.data?.loanAmountt),
                        // interestt: currencyFormatOne(loans.data?.interestt),
                        // monthlyPayment: currencyFormatOne(loans.data?.monthlyPayment),
                        // installmentWithInterestt: currencyFormatOne(loans.data?.installmentWithInterestt),
                    }
                )
            }
        }
        if (dataAddLoans) {
            //console.log("BB")
            setCheckPCH(dataAddLoans)
            form.setFieldsValue(
                {
                    ...dataAddLoans,
                    loanAmountt: currencyFormatOne(dataAddLoans?.loanAmountt),
                    interestt: currencyFormatOne(dataAddLoans?.interestt),
                    monthlyPayment: currencyFormatOne(dataAddLoans?.monthlyPayment),
                    installmentWithInterestt: currencyFormatOne(dataAddLoans?.installmentWithInterestt),
                }
            )
        }
        if (dataAddLands) {
            // console.log("DD")
            getProvinces()
            GetAmphur(dataAddLands?.district)
            //PostAmphur(lands.data.district)
            PostProvince(dataAddLands?.province)
            setSelectProduct({ ...selectProduct, productTypeId: 2 })
            setDataPost1(dataAddCustomer)
            setDataPostLand(dataAddLands)
            setMoneyLand(dataAddLoanLands)
            setMoneyOldLand(dataAddOldLoanLands)
            form.setFieldsValue(
                {
                    ...dataAddCustomer, ...dataAddLands, ...dataAddLoanLands, ...dataAddOldLoanLands,
                    proposalBy: user,
                    birthdate: dayjs(dataAddCustomer?.birthdate),
                    issudt: dayjs(dataAddCustomer?.issudt),
                    expdt: dayjs(dataAddCustomer?.expdt),
                }
            )
        }
        //loadDataBrand()
    }, [cars.data])

    // useEffect(() => {
    //     if (cars.data) {
    //         setDataPost2(cars.data)
    //         form.setFieldsValue({ ...cars.data })
    //     }
    // }, [openTableCar])
    useEffect(() => {
        if (money && dataPost2.productTypeCar > 1 && checkPCH.PLorCH === "เช่าซื้อ") {
            // console.log("ss")
            car_Calculate()
            //car_Calculate_close()
        }
        if (money && dataPost2.productTypeCar > 0 && checkPCH.PLorCH === "เช่าซื้อ1") {
            // console.log("ss")
            car_Calculate()
            //car_Calculate_close()
        }
        // if (moneyLand && selectProduct.productTypeId === 2) { //แบบเดิม
        if (dataAddCustomer?.productTypeId === 2) {
            // console.log("XX")
            land_Calculate()
            //land_Calculate_close()
        }
        if (moneyOld && moneyOld?.productLoanType === 'ย้ายไฟแนนซ์') {
            // console.log("GG")
            // car_Calculate_close()
        }
        if (moneyOldLand && moneyOldLand?.productLoanLandType === 'ย้ายไฟแนนซ์') {
            // console.log("ez")
            land_Calculate_close()
        }
    }, [money?.loanAmountt, money?.interestRate, money?.installmentAmountt, moneyLand?.loanAmounttLand, moneyLand?.interestRateLand, moneyLand?.loanLandTerm, moneyOld?.oldLoanAmount, moneyOld?.oldMonthlyPayment, moneyOld?.oldLoanTerm, moneyOld?.oldKangPayment, moneyOldLand?.oldLandLoanAmount, moneyOldLand?.oldLandMonthlyPayment, moneyOldLand?.oldLandLoanTerm, moneyOldLand?.oldLandKangPayment])

    // console.log("productTypeCar", productTypeCar)
    // console.log("dataPost2", dataPost2)

    const handleSubmit = async () => {
        setLoading(true)
        if (money?.loanAmountt > 999999999 || moneyLand?.loanAmounttLand > 999999999 || money?.interestRate > 100 || moneyLand?.interestRateLand > 100) {
            alert("กรุณาตรวจสอบยอดขอกู้อีกครั้ง")
        } else {
            if (branch === 'MIT' || branch === 'S4' || branch === 'UD' || branch === 'LEX') {
                if (dataPost2.carPrice === 0) {
                    const dataPost22 = {
                        idmodel: '',
                        note: '',
                        cc: '',
                        carPrice: 0,
                        carChassisNumber: '',
                        carEngineNumber: '',
                        carColor: '',
                        carInput: formattedTime,
                        carDateStatus: formattedTime,
                        carPriceStatus: false,
                        carBrand: dataPost2?.carBrand,
                        carModel: dataPost2?.carModel,
                        carBaab: dataPost2?.carBaab,
                        carYear: dataPost2?.carYear,
                        carPlateNumber: dataPost2?.carPlateNumber,
                        carProvince: dataPost2?.carProvince,
                        productTypeCar: dataPost2?.productTypeCar,
                    }
                    //console.log("ddd", dataPost22)
                    dispatch(addCar(dataPost22))
                    dispatch(addLoan(money))
                    dispatch(addOldLoan(moneyOld))
                    dispatch(addLand())
                    dispatch(addLoanLand())
                    dispatch(addOldLoanLand())
                    localStorage.setItem('addCar', JSON.stringify(dataPost22))
                    localStorage.setItem('addLoan', JSON.stringify(money))
                    localStorage.setItem('addOldLoan', JSON.stringify(moneyOld))
                    localStorage.removeItem('addLand');
                    localStorage.removeItem('addLoanLand');
                    localStorage.removeItem('addOldLoanLand');
                    navigate('/offercase/addCareerincome')
                }
                if (dataAddCustomer?.productTypeId === 1 && dataPost2.carPrice !== 0) {
                    if (money?.loanAmountt > dataPost2?.carPrice && money?.productLoanType !== 'ย้ายไฟแนนซ์' && money?.productLoanType !== 'Pre-Aaprove-ย้ายไฟแนนซ์') {
                        alert("ไม่สามารถใส่วงเงินกู้เกินเรทรถได้")
                    } else {
                        console.log("car")
                        dispatch(addCar(dataPost2))
                        dispatch(addLoan(money))
                        dispatch(addOldLoan(moneyOld))
                        dispatch(addLand())
                        dispatch(addLoanLand())
                        dispatch(addOldLoanLand())
                        localStorage.setItem('addCar', JSON.stringify(dataPost2))
                        localStorage.setItem('addLoan', JSON.stringify(money))
                        localStorage.setItem('addOldLoan', JSON.stringify(moneyOld))
                        localStorage.removeItem('addLand');
                        localStorage.removeItem('addLoanLand');
                        localStorage.removeItem('addOldLoanLand');
                        navigate('/offercase/addCareerincome')
                    }
                }
                if (dataAddCustomer?.productTypeId === 2) {
                    console.log("land")
                    if (checkButtonLand === 1 && dataPostLand?.landTypeId === 2) {
                        alert("กรุณากดตรวจสอบ")
                    } else {
                        dispatch(addLand(dataPostLand))
                        dispatch(addLoanLand(moneyLand))
                        dispatch(addOldLoanLand(moneyOldLand))
                        dispatch(addCar())
                        dispatch(addLoan())
                        dispatch(addOldLoan())
                        localStorage.setItem('addLand', JSON.stringify(dataPostLand))
                        localStorage.setItem('addLoanLand', JSON.stringify(moneyLand))
                        localStorage.setItem('addOldLoanLand', JSON.stringify(moneyOldLand))
                        localStorage.removeItem('addCar');
                        localStorage.removeItem('addLoan');
                        localStorage.removeItem('addOldLoan');
                        navigate('/offercase/addCareerincome')
                    }
                }
            } else {
                if (dataPost2.carPrice === 0) {
                    const dataPost22 = {
                        idmodel: '',
                        note: '',
                        cc: '',
                        carPrice: 0,
                        carChassisNumber: '',
                        carEngineNumber: '',
                        carColor: '',
                        carInput: formattedTime,
                        carDateStatus: formattedTime,
                        carPriceStatus: false,
                        carBrand: dataPost2?.carBrand,
                        carModel: dataPost2?.carModel,
                        carBaab: dataPost2?.carBaab,
                        carYear: dataPost2?.carYear,
                        carPlateNumber: dataPost2?.carPlateNumber,
                        carProvince: dataPost2?.carProvince,
                        productTypeCar: dataPost2?.productTypeCar,
                    }
                    //console.log("ddd", dataPost22)
                    dispatch(addCar(dataPost22))
                    dispatch(addLoan(money))
                    dispatch(addOldLoan(moneyOld))
                    dispatch(addLand())
                    dispatch(addLoanLand())
                    dispatch(addOldLoanLand())
                    localStorage.setItem('addCar', JSON.stringify(dataPost22))
                    localStorage.setItem('addLoan', JSON.stringify(money))
                    localStorage.setItem('addOldLoan', JSON.stringify(moneyOld))
                    localStorage.removeItem('addLand');
                    localStorage.removeItem('addLoanLand');
                    localStorage.removeItem('addOldLoanLand');
                    if (money?.productType === "p-loan") {
                        navigate('/offercase/broker')
                    } else {
                        navigate('/offercase/addCareerincome')
                    }
                }
                if (dataAddCustomer?.productTypeId === 1 && dataPost2.carPrice !== 0) {
                    if (money?.loanAmountt > dataPost2?.carPrice && money?.productLoanType !== 'ย้ายไฟแนนซ์' && money?.productLoanType !== 'Pre-Aaprove-ย้ายไฟแนนซ์') {
                        alert("ไม่สามารถใส่วงเงินกู้เกินเรทรถได้")
                    } else {
                        console.log("car")
                        dispatch(addCar(dataPost2))
                        dispatch(addLoan(money))
                        dispatch(addOldLoan(moneyOld))
                        dispatch(addLand())
                        dispatch(addLoanLand())
                        dispatch(addOldLoanLand())
                        localStorage.setItem('addCar', JSON.stringify(dataPost2))
                        localStorage.setItem('addLoan', JSON.stringify(money))
                        localStorage.setItem('addOldLoan', JSON.stringify(moneyOld))
                        localStorage.removeItem('addLand');
                        localStorage.removeItem('addLoanLand');
                        localStorage.removeItem('addOldLoanLand');
                        if (money?.productType === "p-loan") {
                            navigate('/offercase/broker')
                        } else {
                            navigate('/offercase/addCareerincome')
                        }
                    }
                }
                // if (dataAddCustomer?.productTypeId === 2) {
                //     console.log("land")
                //     if (checkButtonLand === 1 && dataPostLand?.landTypeId === 2) {
                //         alert("กรุณากดตรวจสอบ")
                //     } else {
                //         dispatch(addLand(dataPostLand))
                //         dispatch(addLoanLand(moneyLand))
                //         dispatch(addOldLoanLand(moneyOldLand))
                //         dispatch(addCar())
                //         dispatch(addLoan())
                //         dispatch(addOldLoan())
                //         localStorage.setItem('addLand', JSON.stringify(dataPostLand))
                //         localStorage.setItem('addLoanLand', JSON.stringify(moneyLand))
                //         localStorage.setItem('addOldLoanLand', JSON.stringify(moneyOldLand))
                //         localStorage.removeItem('addCar');
                //         localStorage.removeItem('addLoan');
                //         localStorage.removeItem('addOldLoan');
                //         navigate('/offercase/addCareerincome')
                //     }
                // }
            }
        }
        setLoading(false)
    }
    //////////////// จังหวัด รถ /////////////////////
    const fetchAllProvinces = async () => {
        await axios.get(loadProvinces)
            .then(response => {
                setProvincesCar(response.data);
            }).catch(error => {
                console.error(error);
            });
    }

    const handleProvinceChangeCar = (value, element) => {
        setDataPost2({ ...dataPost2, carProvince: value, });
    };

    /////////////// ที่อยู่ ของ ที่ดิน //////////////////
    const getProvinces = async () => {
        // const tokens = "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IjEiLCJyZWdpc3Rlcl90eXBlX3NlcSI6IjgiLCJ1c2VyX2dyb3VwX2RhdGFfbGlzdCI6IjEsMSwwLDAsMCwwLDAsMSIsInBlcnNvbmFsX2lkIjoiIiwianRpIjoiYzg5MjliZjktOGQ2Ny00ODhkLWE0M2MtMWYyYTcyZGQ0ZmZjIiwiaWF0IjoiMDkvMjkvMjAyMyAwNDo1OTo1NCIsIm5iZiI6MTY5NTk2MzU5NCwiZXhwIjoxNjk2MDQ5OTk0LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDozMDAwMS8iLCJhdWQiOiJMYW5kc01hcHMifQ.BX_IYnEKbaNr8vvixELjaJlzllUcAGf-T7D1ZedJ7aty23GZTLjIGxO7YXrsM-yH1JUe6wLym0vdbyQ1bStSTQ"
        // const provids = pvcodeS
        // const amphurs = amcodeS
        // const landNos = dataPostLand.numberLand
        // const result = { pvcode: provids, amcode: amphurs, landNo: landNos }
        // //console.log("result =", result)
        // const headers = {
        //     // "Content-Type": "application/json",
        //     "Authorization": `Bearer ${tokens}`,
        // }
        setLoading(true)
        await axios.get(GetProvince)
            //await axios.get('https://landsmaps.dol.go.th/data/province.json')
            //await axios.get('https://landsmaps.dol.go.th/apiService/JWT/GetJWTAccessToken')
            //await axios.get('https://landsmaps.dol.go.th/apiService/LandsMaps/GetParcelByParcelNo/96/10/10001',{ headers: headers })
            .then((res) => {
                //console.log("Province =", res.data)
                setProvinces(res.data)
                setLoading(false)
            }).catch((err) => {
                console.error(err);
            })
        setLoading(false)
    }

    const PostProvince = async (value) => {
        // console.log("value =", value)
        const pr = { pvcode: value }
        //console.log("pr =", pr)
        setLoading(true)
        await axios.post(PostProvince1, pr)
            .then((res) => {
                //console.log("Province =", res.data)
                //console.log("pvcode =", res.data[0].pvcode)
                setPvcodeS(res.data[0].pvcode)
                setProvinces(res.data)
                setLoading(false)
            }).catch((err) => {
                console.error(err);
            })
        setLoading(false)
    }

    const GetAmphur = async (value) => {
        const am = { pvcode: value }
        //console.log("am =", am)
        setLoading(true)
        await axios.post(GetAmphur1, am)
            .then((res) => {
                //console.log("Amphur =", res.data)
                setDistricts(res.data)
                setAmcodeS(res.data[0].amcode)
                setLoading(false)
            }).catch((err) => {
                console.error(err);
            })
        setLoading(false)
    }

    const handleProvinceChange = async (value, key) => {
        setCheckButtonLand(1)
        //console.log("Province =", key["key"], value)
        setPvcodeS(key["key"])
        setDataPostLand({ ...dataPostLand, province: value });
        form.setFieldsValue(
            {
                numberLand: "",
                numberLandLawang: "",
                rai: "",
                workArea: "",
                squareWaArea: "",
                landPrice: "",
                resultLandPrice: "",
                district: "",
                // productLoanLandType: "",
                // loanAmounttLand: "",
                // loanLandTerm: "",
                // interestRateLand: "",
                // interesttLand: "",
                // monthlyPaymentLand: "",
                // installmentWithInterestLand: "",
            }
        )
        setLoading(true)
        const test = { pvcode: key["key"] }
        await axios.post(GetAmphur1, test)
            .then((res) => {
                setDistricts(res.data)
                setLoading(false)
            }).catch((err) => {
                console.error(err);
            })
        setLoading(false)
    };

    const handleDistrictChange = (value, key) => {
        setCheckButtonLand(1)
        //console.log("VV =", key["key"], value)
        setAmcodeS(key["key"])
        setDataPostLand({ ...dataPostLand, district: value });
        form.setFieldsValue(
            {
                numberLand: "",
                numberLandLawang: "",
                rai: "",
                workArea: "",
                squareWaArea: "",
                landPrice: "",
                resultLandPrice: "",
                // productLoanLandType: "",
                // loanAmounttLand: "",
                // loanLandTerm: "",
                // interestRateLand: "",
                // interesttLand: "",
                // monthlyPaymentLand: "",
                // installmentWithInterestLand: "",
            }
        )
    };

    const handleNumberLand = (e) => {
        setCheckButtonLand(1)
        setDataPostLand({ ...dataPostLand, numberLand: e.target.value })
    };

    ///////////////// กรณี นส.3 ///////////////////////////
    const handleProvinceChange1 = async (value, key) => {
        //console.log("Province =", key["key"], value)
        setPvcodeS(key["key"])
        setDataPostLand({ ...dataPostLand, province: value });
        form.setFieldsValue(
            {
                numberLand: "",
                numberLandLawang: "",
                rai: "",
                workArea: "",
                squareWaArea: "",
                district: "",
            }
        )
        setLoading(true)
        const test = { pvcode: key["key"] }
        await axios.post(GetAmphur1, test)
            .then((res) => {
                setDistricts(res.data)
                setLoading(false)
            }).catch((err) => {
                console.error(err);
            })
        setLoading(false)
    };

    const handleDistrictChange2 = (value, key) => {
        //console.log("VV =", key["key"], value)
        setAmcodeS(key["key"])
        setDataPostLand({ ...dataPostLand, district: value });
        form.setFieldsValue(
            {
                numberLand: "",
                numberLandLawang: "",
                rai: "",
                workArea: "",
                squareWaArea: "",
            }
        )

    };


    ////////////// ที่ดิน /////////////////////
    const handleChangeLandType = (value) => {
        // setMoneyLand({ ...moneyLand, productLoanLandType: value, proposalBy: user, productType: "ที่ดิน", approvalStatus: "รอธุรการรับ" })
        // form.setFieldsValue(
        //     {
        //         loanAmounttLand: '',
        //         loanLandTerm: '',
        //         interestRateLand: '',
        //         interesttLand: '',
        //         monthlyPaymentLand: '',
        //         installmentWithInterestLand: '',
        //     }
        // )
        if (value === 'ย้ายไฟแนนซ์' || value === 'Pre-Aaprove-ย้ายไฟแนนซ์') {
            setMoneyLand({ ...moneyLand, productLoanLandType: value, proposalBy: user, branch: branch, productType: "ที่ดิน", approvalStatus: "รอพี่หนุ่มรับ" })
            setMoneyOldLand({ ...moneyOldLand, productLoanLandType: value })
            // setTypeMoneyLand({ ...typeMoney, productLoanLandType: 'ย้ายไฟแนนซ์', productType: "ที่ดิน" })
        }
        else {
            setMoneyLand({ ...moneyLand, productLoanLandType: value, proposalBy: user, branch: branch, productType: "ที่ดิน", approvalStatus: "รอพี่หนุ่มรับ" })
            setMoneyOldLand("")
            form.setFieldsValue(
                {
                    //loanAmounttLand: '',
                    loanLandTerm: '',
                    //interestRateLand: '',
                    interesttLand: '',
                    monthlyPaymentLand: '',
                    installmentWithInterestLand: '',
                    oldLandLoanAmount: '',
                    oldLandMonthlyPayment: '',
                    oldLandLoanTerm: '',
                    oldLandKangPayment: '',
                    oldLandKangPaymentTerm: '',
                }
            )
        }
    }

    /////////////// โมดาว ค่า งวด p-loan และ แบบ เช่าซื้อ ///////////////////
    const currencyFormatOnes = (amount) => {
        return Number(amount).toFixed(0).replace(/\d(?=(\d{3})+(?!\d))/g, '$&,')
    }
    const currencyFormatOne = (amount) => {
        if (amount) {
            return Number(amount)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, '$&,')
        }
    }

    const handleMoney = (values) => {
        //console.log("ER1", values)
        //setMoney({ ...money, ...values, productType: checkPCH.PLorCH, proposalBy: user, ...checkPCH })
        setMoney({
            ...money,
            interestRate: values?.interestRate,
            loanAmountt: values?.loanAmountt,
            installmentAmountt: values?.installmentAmountt,
            interestt: values?.interestt,
            monthlyPayment: values?.monthlyPayment,
            installmentWithInterestt: values?.installmentWithInterestt,
            approvalStatus: "รอธุรการรับ",
            proposalBy: user,
            branch: branch,
            productType: checkPCH.PLorCH,
            PLorCH: checkPCH.PLorCH
        })
        form.setFieldsValue(
            {
                loanAmountt: currencyFormatOne(values?.loanAmountt),
                installmentAmountt: values?.installmentAmountt,
                interestt: currencyFormatOne(values?.interestt),
                monthlyPayment: currencyFormatOne(values?.monthlyPayment),
                installmentWithInterestt: currencyFormatOne(values?.installmentWithInterestt),
            }
        )
    }
    const handleMoneyHP = (values) => {
        //console.log("ER2", values)
        //setMoney({ ...money, ...values, productType: checkPCH.PLorCH, proposalBy: user, ...checkPCH })
        setMoney({
            ...money,
            interestRate: values?.interestRate,
            loanAmountt: values?.loanAmountt,
            installmentAmountt: values?.installmentAmountt,
            interestt: values?.interestt,
            monthlyPayment: values?.monthlyPayment,
            installmentWithInterestt: values?.installmentWithInterestt,
            approvalStatus: "รอธุรการรับ",
            proposalBy: user,
            branch: branch,
            productType: checkPCH.PLorCH,
            PLorCH: checkPCH.PLorCH
        })
        form.setFieldsValue(
            {
                loanAmountt: currencyFormatOne(values?.loanAmountt),
                installmentAmountt: values?.installmentAmountt,
                interestt: currencyFormatOne(values?.interestt),
                monthlyPayment: currencyFormatOne(values?.monthlyPayment),
                installmentWithInterestt: currencyFormatOne(values?.installmentWithInterestt),
            }
        )
    }
    ////////////// รถ ///////////////////
    const handleChangeType = (value) => {
        setCheckInfo(value)
        if (value === 'ย้ายไฟแนนซ์' || value === 'Pre-Aaprove-ย้ายไฟแนนซ์') {
            setMoney({ ...money, productLoanType: value, proposalBy: user, branch: branch, approvalStatus: "รอธุรการรับ", productType: checkPCH.PLorCH })
            setMoneyOld({ ...moneyOld, productLoanType: value })
            setTypeMoney({ ...typeMoney, productLoanType: 'ย้ายไฟแนนซ์', productType: checkPCH.PLorCH })
            //setTypeMoney({ ...typeMoney, productLoanType: value, approvalDate: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"), productType: "รถ" })
        }
        else {
            setMoney({ ...money, productLoanType: value, proposalBy: user, branch: branch, approvalStatus: "รอธุรการรับ", productType: checkPCH.PLorCH })
            setTypeMoney({ ...typeMoney, productLoanType: 'ถือเล่มมา', productType: checkPCH.PLorCH })
            setMoneyOld('')
            form.setFieldsValue(
                {
                    loanAmountt: "",
                    installmentAmountt: "",
                    interestt: "",
                    interestRate: "",
                    monthlyPayment: "",
                    installmentWithInterestt: "",
                    oldKangPayment: '',
                    oldKangPaymentTerm: '',
                    oldLoanAmount: '',
                    oldLoanTerm: '',
                    oldMonthlyPayment: '',
                }
            )
            //setTypeMoney({ ...typeMoney, productLoanType: value, approvalDate: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"), productType: "รถ" })
            //setCount(value)
        }
    }
    /////////////// เลือก p-loan หรือ เช่าซื้อ ///////////////////
    const handleChangePLorCH = (value) => {
        //setMoney({ ...money, PLorCH: value })
        if (value === "p-loan") {
            setCheckPCH({ PLorCH: value })
            setMoney({
                ...money, loanAmountt: "",
                installmentAmountt: "",
                interestt: "",
                interestRate: "",
                monthlyPayment: "",
                installmentWithInterestt: "",
                PLorCH: value,
                productType: value
            })
            form.setFieldsValue(
                {
                    loanAmountt: "",
                    installmentAmountt: "",
                    interestt: "",
                    interestRate: "",
                    monthlyPayment: "",
                    installmentWithInterestt: "",
                }
            )
        } else {
            localStorage.removeItem('addBroker');
            setCheckPCH({ PLorCH: value })
            setMoney({
                ...money, loanAmountt: "",
                installmentAmountt: "",
                interestt: "",
                interestRate: "",
                monthlyPayment: "",
                installmentWithInterestt: "",
                PLorCH: value,
                productType: value
            })
            form.setFieldsValue(
                {
                    loanAmountt: "",
                    installmentAmountt: "",
                    interestt: "",
                    interestRate: "",
                    monthlyPayment: "",
                    installmentWithInterestt: "",
                }
            )
        }
    }


    //////////// กรณีไม่มีรถ /////////////////////
    const AddBrand = () => {
        setCounterBrand(true)
        setCounterModel(true)
        setCounterCarBaab(true)
        setDataPost2({ ...dataPost2, carPrice: 0 })
        form.setFieldsValue(
            {
                carBrand: '',
                carModel: '',
                carBaab: '',
                carYear: '',
                carPrice: 0,
            }
        )
    }
    const AddBrands = () => {
        setCounterBrand(false)
        setCounterModel(false)
        setCounterCarBaab(false)
        setDataPost2({ ...dataPost2, carPrice: 0 })
        setMoney({
            ...money,
            loanAmountt: 0,
            installmentAmountt: 0,
            interestt: 0,
            interestRate: 0,
            monthlyPayment: 0,
            installmentWithInterestt: 0,
        })
        form.setFieldsValue(
            {
                carBrand: '',
                carModel: '',
                carBaab: '',
                carYear: '',
                carPrice: 0,
                loanAmountt: "",
                installmentAmountt: "",
                interestt: "",
                interestRate: "",
                monthlyPayment: "",
                installmentWithInterestt: "",
            }
        )
    }
    //////////// กรณีไม่มีรถ /////////////////////
    const AddModel = () => {
        setCounterModel(true)
        setCounterCarBaab(true)
        setDataPost2({ ...dataPost2, carPrice: 0 })
        form.setFieldsValue(
            {
                carModel: '',
                carBaab: '',
                carYear: '',
                carPrice: 0,
            }
        )
    }
    const AddModels = () => {
        setCounterModel(false)
        setCounterCarBaab(false)
        setDataPost2({ ...dataPost2, carPrice: 0 })
        setMoney({
            ...money,
            loanAmountt: 0,
            installmentAmountt: 0,
            interestt: 0,
            interestRate: 0,
            monthlyPayment: 0,
            installmentWithInterestt: 0,
        })
        form.setFieldsValue(
            {
                carModel: '',
                carBaab: '',
                carYear: '',
                carPrice: 0,
                loanAmountt: "",
                installmentAmountt: "",
                interestt: "",
                interestRate: "",
                monthlyPayment: "",
                installmentWithInterestt: "",
            }
        )
    }
    //////////// กรณีไม่มีรถ /////////////////////
    const AddCarBaab = () => {
        // console.log("-")
        setCounterCarBaab(true)
        setDataPost2({ ...dataPost2, carPrice: 0 })
        form.setFieldsValue(
            {
                carBaab: '',
                carYear: '',
                carPrice: 0,
            }
        )
    }
    const AddCarBaabs = () => {

        setCounterCarBaab(false)
        setDataPost2({ ...dataPost2, carPrice: 0 })
        setMoney({
            ...money,
            loanAmountt: 0,
            installmentAmountt: 0,
            interestt: 0,
            interestRate: 0,
            monthlyPayment: 0,
            installmentWithInterestt: 0,
        })
        form.setFieldsValue(
            {
                carBaab: '',
                carYear: '',
                carPrice: 0,
                loanAmountt: "",
                installmentAmountt: "",
                interestt: "",
                interestRate: "",
                monthlyPayment: "",
                installmentWithInterestt: "",
            }
        )
    }
    const onYeehor = (value) => {
        // console.log("value", value)
        setDataPost2({ ...dataPost2, carBrand: value })
        // setDataPost2({ ...dataPost2, carBrand: e.target.value })
        // //setCarSelected(e)
    }


    const onMoChange = (e) => {
        //console.log("Modal",e.target.value)
        setDataPost2({ ...dataPost2, carModel: e.target.value })
        //setCarSelected(e)
    }
    // const AddModal = () => {
    //     setCounterModal(true)
    // }
    const onDeChange = (e) => {
        //console.log("Baab",e.target.value)
        setDataPost2({ ...dataPost2, carBaab: e.target.value })
    }
    // const AddYear = () => {
    //     setCounterYear(true)
    // }
    const onReChange = (e) => {
        //console.log("Year",e.target.value)
        setDataPost2({ ...dataPost2, carYear: parseInt(e.target.value) })
    }

    ////// ตัวอย่างรถ กับ ตารางค่างวด ////////
    const getTable = () => {
        setOpenTable(true)
    }
    const getTableHP = () => {
        setOpenTableHP(true)
    }
    const getTableCar = () => {
        setOpenTableCar(true)
        setCounterCarBaab(false)
    }
    /////////////////////////////////////

    // console.log("counterCarBaab", counterCarBaab)

    const loadDataBrand = async (value) => { //รถยนต์ รถเครื่องจักร รถบรรทุก
        setLoading(true)
        await axios.get(`https://shark-app-j9jc9.ondigitalocean.app/ratecars/brand/${value}`)
            .then((res) => {
                console.log("res", res.data)
                setBrandData(res.data)
                setLoading(false)
                // form.setFieldsValue(
                //     {
                //         carBrand: "",
                //         carModel: "",
                //         carBaab: "",
                //         carYear: "",
                //         carPrice: 0
                //     }
                // )
            })
            .catch((err) => console.log(err))
        setLoading(false)
    }
    const loadDataModel = async (value) => {
        setLoading(true)
        await axios.get(dataModel + value)
            .then((res) => {
                setModelData(res.data)
                form.setFieldsValue(
                    {
                        carModel: "",
                        carBaab: "",
                        carYear: "",
                        carPrice: 0
                    }
                )
                setLoading(false)
            })
            .catch((err) => console.log(err))
        setLoading(false)
    }
    const getResult = async (value) => {
        setLoading(true)
        const result = await axios.get(ratecarsmodel,
            { params: { typeId: typeSelected, modelId: value }, })
        setResultData(result.data)
        setLoading(false)
    }

    const onTypeChange = (value) => {
        //console.log("ชนิด =", value)
        // if(value === 2 || value === 3) {
        //     form.setFieldsValue(
        //         {
        //             carBrand: "",
        //             carModel: "",
        //             carBaab: "",
        //             carYear: "",
        //             carPrice: 0
        //         }
        //     )
        // }
        setDataPost2({ ...dataPost2, productTypeCar: value, carYear: 0, carPrice: 0, carPriceStatus: false, carInput: formattedTime, carDateStatus: formattedTime })
        setTypeData(value)
        setTypeSelected(value)
        loadDataBrand(value)
        setMoney({
            ...money,
            loanAmountt: 0,
            installmentAmountt: 0,
            interestt: 0,
            interestRate: 0,
            monthlyPayment: 0,
            installmentWithInterestt: 0,
        })
        form.setFieldsValue(
            {
                // carProvince: "",
                // carPlateNumber: "",
                loanAmountt: "",
                installmentAmountt: "",
                interestt: "",
                interestRate: "",
                monthlyPayment: "",
                installmentWithInterestt: "",
                oldLoanAmount: "",
                oldMonthlyPayment: "",
                oldLoanTerm: "",
                oldKangPayment: "",
                oldKangPaymentTerm: ""
            }
        )
    }
    const onBrandChange = (value) => {
        //console.log("BRAND", value)
        setDataPost2({ ...dataPost2, carBrand: brandData.find((a) => a.idbrand === value).brand })
        //setBrandSelected(value)
        loadDataModel(value)
    }
    const onModelChange = (value) => {
        setCounterBrand(false)
        //console.log("model", value)
        setDataPost2({ ...dataPost2, carModel: modelData.find((a) => a.idmodel_te === value).model })
        //setCarSelected(value)
        getResult(value)
    }
    const onDetailChange = (value) => {
        //setCounterModal(false)
        setDataPost2({ ...dataPost2, carBaab: resultData.find((a) => a.idmodel === value).models })
        //console.log('type_id',typeData)
        //console.log('idmodel',value)
        getPrice(typeData, value)
    }
    const getPrice = async (typeId, carId) => {
        setLoading(true)
        //console.log('typeid', typeId)
        //console.log('idmodel', carId)
        await axios.get(getprice, { params: { typeId, carId }, })
            .then((res) => {
                if (res.data) {
                    //console.log("price",res.data)
                    setYearPrice(res.data)
                    setLoading(false)
                }
            })
            .catch((err) => alert('ไม่พบข้อมูล ราคารถ'))
        setLoading(false)
    }
    const onResult = (k, v) => {
        //setCounterYear(false)
        //console.log("KK", k)
        //console.log("VV", v)
        const pYear = parseInt(v.key)
        if (k) {
            setDataPost2({ ...dataPost2, carPriceStatus: true, carPrice: k, carYear: pYear, carInput: formattedTime, carDateStatus: formattedTime })
            parseInt(dataPost2.carYear)
            form.setFieldsValue({
                carPrice: currencyFormatOne(v.value)
            })
        }
    }
    /////////////////// เลือก 1 = นส.3 กับ 2 = นส.4 //////////////////////
    const handleProductLand = (value) => {
        // console.log("landTypeId =", value)
        if (value === 1) {
            setDataPostLand({
                ...dataPostLand,
                productTypeLand: value,
                landTypeId: value,
                numberLand: "-",
                numberLandLawang: "-",
                rai: 0,
                workArea: 0,
                squareWaArea: 0,
                landPrice: 0,
                resultLandPrice: 0,
                landPriceStatus: false,
                subdistrict: null,
            })
            setFileListResult2([]);
            setFileListResult1([]);
            form.setFieldsValue(
                {
                    numberLand: "",
                    numberLandLawang: "",
                    rai: "",
                    workArea: "",
                    squareWaArea: "",
                    landPrice: "",
                    resultLandPrice: "",
                    province: "",
                    district: "",
                }
            )
        } else {
            setDataPostLand({
                ...dataPostLand,
                productTypeLand: value,
                landTypeId: value,
                numberLand: "-",
                numberLandLawang: "-",
                rai: 0,
                workArea: 0,
                squareWaArea: 0,
                landPrice: 0,
                resultLandPrice: 0,
                landPriceStatus: false,
            })
            setFileListResult2([]);
            setFileListResult1([]);
            form.setFieldsValue(
                {
                    numberLand: "",
                    numberLandLawang: "",
                    rai: "",
                    workArea: "",
                    squareWaArea: "",
                    landPrice: "",
                    resultLandPrice: "",
                    province: "",
                    district: "",
                }
            )
        }
    }

    /////////////////// เลือก 1 รถ, 2 ที่ดิน ///////////////////////
    // const handleProduct = (value) => {
    //     //console.log("VV =", value)
    //     if (value === 1) {
    //         setSelectProduct({ ...selectProduct, productTypeId: value })
    //         dispatch(addLand())
    //         dispatch(addLoanLand())
    //         setCheckPCH({ PLorCH: "p-loan" })
    //         setDataPost2({ ...dataPost2, productTypeId: value, productTypeCar: 1 })
    //         setDataPostLand({})
    //         setMoneyLand({})
    //         //setMoneyOldLand({})
    //         setTypeMoneyLand({})
    //         form.setFieldsValue(
    //             {
    //                 PLorCH: "p-loan",
    //                 numberLand: "",
    //                 numberLandLawang: "",
    //                 rai: "",
    //                 workArea: "",
    //                 squareWaArea: "",
    //                 landPrice: "",
    //                 resultLandPrice: "",
    //                 province: "",
    //                 district: "",
    //                 subdistricts: "",
    //                 zipcode: "",
    //                 productLoanLandType: "",
    //                 //loanAmounttLand: "",
    //                 loanLandTerm: "",
    //                 //interestRateLand: "",
    //                 interesttLand: "",
    //                 monthlyPaymentLand: "",
    //                 installmentWithInterestLand: "",
    //             }
    //         )
    //     } else {
    //         setSelectProduct({ ...selectProduct, productTypeId: value })
    //         getProvinces()
    //         dispatch(addCar())
    //         dispatch(addLoan())
    //         dispatch(addOldLoan())
    //         setMoneyLand({ ...moneyLand, loanAmounttLand: 50000, interestRateLand: 1.1 })
    //         setDataPostLand({
    //             ...dataPostLand,
    //             productTypeId: value,
    //             landTypeId: 2,
    //             productTypeLand: 2,
    //             landInput: formattedTime,
    //             landDateStatus: formattedTime,
    //             rai: 0,
    //             workArea: 0,
    //             squareWaArea: 0,
    //             landPrice: 0,
    //             resultLandPrice: 0,
    //             landPriceStatus: false,
    //         })
    //         setDataPost2({})
    //         setMoneyOld({})
    //         setTypeMoney({})
    //         form.setFieldsValue(
    //             {
    //                 PLorCH: "",
    //                 productTypeCar: "",
    //                 carBrand: "",
    //                 carModel: "",
    //                 carBaab: "",
    //                 carYear: "",
    //                 carPrice: 0,
    //                 carTy: "",
    //                 carProvince: "",
    //                 carPlateNumber: "",
    //                 productLoanType: "",
    //                 loanAmountt: "",
    //                 installmentAmountt: "",
    //                 interestt: "",
    //                 monthlyPayment: "",
    //                 installmentWithInterestt: "",
    //                 oldLoanAmount: "",
    //                 oldMonthlyPayment: "",
    //                 oldLoanTerm: "",
    //                 oldKangPayment: "",
    //                 oldKangPaymentTerm: ""
    //             }
    //         )
    //     }
    // }

    ///////////////// อัปโหลดรูปภาพ //////////////////////////////
    const handleChange = async ({ fileList }) => {
        //console.log("fileList", fileList)
        setFileList(fileList);
        var imageList = []
        for (const file of fileList) {
            var image = {}
            var filename = file.name
            var image64 = await getBase64(file.originFileObj)
            image.filename = filename
            image.image64 = image64
            image.type = 1
            imageList.push(image)
        }
        setFileListResult1({ ...fileListResult1, imageList });
        //dispatch(addImage(imageList))
    };
    const handleChangeBook = async ({ fileList }) => {
        //console.log("fileListBook", fileList)
        setFileListBook(fileList);
        var imageListBook = []
        for (const file of fileList) {
            var image = {}
            var filename = file.name
            var image64 = await getBase64(file.originFileObj)
            image.filename = filename
            image.image64 = image64
            image.type = 2
            imageListBook.push(image)
        }
        setFileListResult2({ ...fileListResult2, imageListBook });
        //dispatch(addImage(imageListBook))
    };

    const handleChangeDeed = async ({ fileList }) => {
        setFileListBook(fileList);
        var imageListBook = []
        for (const file of fileList) {
            var image = {}
            var filename = file.name
            var image64 = await getBase64(file.originFileObj)
            image.filename = filename
            image.image64 = image64
            image.type = 3
            imageListBook.push(image)
        }
        setFileListResult2({ ...fileListResult2, imageListBook });
        //dispatch(addImage(imageListBook))
    };


    const handleCancel = () => {
        setPreviewVisible(false);
    }
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
    };
    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const errorLand = () => {
        Modal.error({
            title: 'ไม่พบข้อมูล',
            content: 'กรุณาตรวจสอบข้อมูลที่กรอกอีกครั้ง',
        })
    }

    const handleCheckLand = async () => {
        setCheckButtonLand(0)
        // console.log("pvcodeS =", pvcodeS)
        // console.log("amcodeS =", amcodeS)
        // console.log("dataPostLand.numberLand =", dataPostLand.numberLand)
        //const tokens = "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IjEiLCJyZWdpc3Rlcl90eXBlX3NlcSI6IjgiLCJ1c2VyX2dyb3VwX2RhdGFfbGlzdCI6IjEsMSwwLDAsMCwwLDAsMSIsInBlcnNvbmFsX2lkIjoiIiwianRpIjoiOTllYmE2OTAtMGFmOC00M2NhLTg2OTktYWUwZDI4N2ZjY2EzIiwiaWF0IjoiMDkvMjcvMjAyMyAwMzoyMDoxNSIsIm5iZiI6MTY5NTc4NDgxNSwiZXhwIjoxNjk1ODcxMjE1LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDozMDAwMS8iLCJhdWQiOiJMYW5kc01hcHMifQ.Lw3h4tjSbzjscWqkuA9FutwzXPY8UEb49D4ZdsdqKRfR0fwFunY7i4pg5Rdc70Vrw8jDR1qhtQLn5SEqxPguoQ"
        var provids = pvcodeS
        var amphurs = amcodeS
        var landNos = dataPostLand.numberLand
        var result = { pvcode: provids, amcode: amphurs, landNo: landNos }
        //console.log("result =", result)
        const headers = {
            "Content-Type": "application/json",
            // "Authorization": `Bearer ${tokens}`,
        }
        setLoading(true)
        await axios.post(AllData, result, { headers: headers })
            .then((response) => {
                if (response.data) {
                    // console.log("DATA =", response.data.result)
                    // console.log("ไร่ =", parseInt(response.data.result[0].rai))
                    // console.log("งาน =", parseInt(response.data.result[0].ngan))
                    // console.log("ตารางวา =", parseInt(response.data.result[0].wa + "." + response.data.result[0].subwa))
                    // console.log("จังหวัด =", response.data.result[0].provname)
                    // console.log("อำเภอ =", response.data.result[0].amphurname)
                    // console.log("ละติจูด =", response.data.result[0].parcellat)
                    // console.log("ลองติจูด =", response.data.result[0].parcellon)
                    // console.log("เลขระวาง =", response.data.result[0].utm)
                    // console.log("ราคาต่อตารางวา =", parseInt(response.data.result[0].landprice.replace(/,/g, "")))
                    const squareWaArea = parseFloat(response.data.result[0].wa + "." + response.data.result[0].subwa)
                    const landPricePerWa = parseInt(response.data.result[0].landprice.replace(/,/g, ""))
                    const totalWa = (
                        ((parseInt(response.data?.result[0].rai) * 400)) +
                        ((parseInt(response.data?.result[0].ngan) * 100)) +
                        parseFloat(response.data?.result[0].wa + "." + response.data?.result[0].subwa)
                    )
                    const totalPrice = Currency(totalWa * landPricePerWa).value
                    // console.log("รวมตารางวาทั้งหมด =", totalPrice.toFixed(1));
                    if (landPricePerWa > 0) {
                        setDataPostLand({
                            ...dataPostLand,
                            subdistrict: response.data?.result[0].tumbolname,
                            parcellat: response.data?.result[0].parcellat,
                            parcellon: response.data?.result[0].parcellon,
                            numberLandLawang: response.data?.result[0].utm,
                            rai: parseInt(response.data?.result[0].rai),
                            workArea: parseInt(response.data?.result[0].ngan),
                            squareWaArea: parseFloat(squareWaArea),
                            landPrice: parseInt(response.data.result[0].landprice.replace(/,/g, "")) || 0,
                            resultLandPrice: totalPrice || 0,
                            landPriceStatus: true
                        });
                        form.setFieldsValue(
                            {
                                numberLandLawang: response.data?.result[0].utm,
                                rai: parseInt(response.data?.result[0].rai),
                                workArea: parseInt(response.data?.result[0].ngan),
                                squareWaArea: squareWaArea,
                                landPrice: currencyFormatOne(parseInt(response.data.result[0].landprice.replace(/,/g, ""))) || 0,
                                resultLandPrice: currencyFormatOne(totalPrice) || 0,
                            }
                        )
                    } else {
                        setDataPostLand({
                            ...dataPostLand,
                            subdistrict: response.data?.result[0].tumbolname,
                            parcellat: response.data?.result[0].parcellat,
                            parcellon: response.data?.result[0].parcellon,
                            numberLandLawang: response.data?.result[0].utm,
                            rai: parseInt(response.data?.result[0].rai),
                            workArea: parseInt(response.data?.result[0].ngan),
                            squareWaArea: parseFloat(squareWaArea),
                            landPrice: 0,
                            resultLandPrice: 0,
                            landPriceStatus: false
                        });
                        form.setFieldsValue(
                            {
                                numberLandLawang: response.data?.result[0].utm,
                                rai: parseInt(response.data?.result[0].rai),
                                workArea: parseInt(response.data?.result[0].ngan),
                                squareWaArea: squareWaArea,
                                landPrice: 0,
                                resultLandPrice: 0,
                            }
                        )
                    }
                    setLoading(false)
                } else {
                    // console.log("DATA =", response.data)
                }
            })
            .catch((err) => {
                setDataPostLand({
                    ...dataPostLand,
                    parcellat: "-",
                    parcellon: "-",
                    numberLandLawang: "-",
                    rai: 0,
                    workArea: 0,
                    squareWaArea: 0,
                    landPrice: 0,
                    resultLandPrice: 0,
                    landPriceStatus: false
                });
                errorLand()
            })
            .finally(() => {
                setLoading(false);
            })
        setLoading(false)
    }

    // const land_Calculate = (event) => {
    //     if (moneyLand.loanAmounttLand && moneyLand.interestRateLand && moneyLand.loanLandTerm) {
    //         var interestRateLand = moneyLand.interestRateLand / 100 // อัตราดอก / 100
    //         var rate = ((moneyLand.loanAmounttLand * Currency(interestRateLand, { precision: 3 }).value) * event.target.value) // (ยอดจัด * อัตราดอกที่หาร100แล้ว ,precision: 3 คือทศนิยม 3 )*งวด
    //         var loanTerm = Math.ceil(Currency((rate + moneyLand.loanAmounttLand) / event.target.value, { precision: 2 }).value) // (ดอกเบี้ยทั้งหมด + ยอดจัด)/ งวด
    //         var resultRate = (loanTerm * event.target.value) - moneyLand.loanAmounttLand
    //         var resultPrice = loanTerm * event.target.value
    //         setMoneyLand({
    //             ...moneyLand,
    //             loanLandTerm: parseInt(event.target.value), //งวด
    //             monthlyPaymentLand: loanTerm,
    //             interesttLand: resultRate,
    //             installmentWithInterestLand: resultPrice
    //         })
    //         form.setFieldsValue(
    //             {
    //                 monthlyPaymentLand: loanTerm,
    //                 interesttLand: resultRate,
    //                 installmentWithInterestLand: resultPrice
    //             }
    //         )
    //     }
    //     else {
    //         form.setFieldsValue(
    //             {
    //                 monthlyPaymentLand: 0,
    //                 interesttLand: 0,
    //                 installmentWithInterestLand: 0
    //             }
    //         )    
    //     }
    // }

    const land_Calculate = () => {
        if (moneyLand.loanAmounttLand && moneyLand.interestRateLand && moneyLand.loanLandTerm) {
            var interestRateLand = moneyLand.interestRateLand / 100 // อัตราดอก / 100
            var rate = ((moneyLand.loanAmounttLand * Currency(interestRateLand, { precision: 5 }).value) * moneyLand.loanLandTerm) // (ยอดจัด * อัตราดอกที่หาร100แล้ว ,precision: 3 คือทศนิยม 3 )*งวด
            var loanTerm = Math.ceil(Currency((rate + moneyLand.loanAmounttLand) / moneyLand.loanLandTerm, { precision: 2 }).value) // (ดอกเบี้ยทั้งหมด + ยอดจัด)/ งวด
            var resultRate = (loanTerm * moneyLand.loanLandTerm) - moneyLand.loanAmounttLand
            var resultPrice = loanTerm * moneyLand.loanLandTerm
            setMoneyLand({
                ...moneyLand,
                // loanLandTerm: parseInt(event.target.value), //งวด
                monthlyPaymentLand: loanTerm,
                interesttLand: resultRate,
                installmentWithInterestLand: resultPrice
            })
            if (moneyLand.loanAmounttLand >= 50000 && moneyLand.loanAmounttLand <= 99999) {
                var feeLoan7 = moneyLand.loanAmounttLand * 0.07 // 7%
                //console.log("7%")
                if (feeLoan7 <= 5000) {
                    form.setFieldsValue(
                        {
                            fee: Currency(feeLoan7, { precision: 2 }).value,
                            monthlyPaymentLand: loanTerm,
                            interesttLand: resultRate,
                            installmentWithInterestLand: resultPrice
                        }
                    )
                } else {
                    form.setFieldsValue(
                        {
                            fee: 5000,
                            monthlyPaymentLand: loanTerm,
                            interesttLand: resultRate,
                            installmentWithInterestLand: resultPrice
                        }
                    )
                }
            }
            if (moneyLand.loanAmounttLand >= 100000 && moneyLand.loanAmounttLand <= 999999) {
                var feeLoan5 = moneyLand.loanAmounttLand * 0.05 // 5%
                //console.log("5%")
                form.setFieldsValue(
                    {
                        fee: Currency(feeLoan5, { precision: 2 }).value,
                        monthlyPaymentLand: loanTerm,
                        interesttLand: resultRate,
                        installmentWithInterestLand: resultPrice
                    }
                )
            }
            if (moneyLand.loanAmounttLand >= 1000000) {
                //console.log("3%")
                var feeLoan3 = moneyLand.loanAmounttLand * 0.03 // 3%
                form.setFieldsValue(
                    {
                        fee: Currency(feeLoan3, { precision: 2 }).value,
                        monthlyPaymentLand: loanTerm,
                        interesttLand: resultRate,
                        installmentWithInterestLand: resultPrice
                    }
                )
            }
        }
        else {
            form.setFieldsValue(
                {
                    fee: 0,
                    monthlyPaymentLand: 0,
                    interesttLand: 0,
                    installmentWithInterestLand: 0
                }
            )
        }
    }
    const land_Calculate_close = () => {
        if (moneyOldLand?.oldLandLoanAmount && moneyOldLand?.oldLandMonthlyPayment && moneyOldLand?.oldLandLoanTerm) {
            var loanTermKhangLand = moneyOldLand?.oldLandKangPayment / moneyOldLand?.oldLandMonthlyPayment
            setMoneyOld({
                ...moneyOldLand,
                oldLandKangPaymentTerm: Currency(loanTermKhangLand, { precision: 2 }).value,
            })
            form.setFieldsValue(
                {
                    oldLandKangPaymentTerm: Currency(loanTermKhangLand, { precision: 1 }).value,
                }
            )
        } else {
            console.log("")
        }
    }

    // const car_Calculate = (e) => {
    //     if (money.loanAmountt && money.interestRate) {
    //         var interestRateCar = money.interestRate / 100 // อัตราดอก / 100
    //         var rateCar = ((money.loanAmountt * Currency(interestRateCar, { precision: 3 }).value) * e.target.value) // (ยอดจัด * อัตราดอกที่หาร100แล้ว ,precision: 3 คือทศนิยม 3 )*งวด
    //         var loanTermCar = Math.ceil(Currency((rateCar + money.loanAmountt) / e.target.value, { precision: 2 }).value) // (ดอกเบี้ยทั้งหมด + ยอดจัด)/ งวด
    //         var resultRateCar = (loanTermCar * e.target.value) - money.loanAmountt
    //         var resultPriceCar = loanTermCar * e.target.value
    //     }
    //     setMoney({ 
    //         ...money,
    //         installmentAmountt: parseInt(e.target.value),
    //         monthlyPayment: loanTermCar,
    //         interestt: resultRateCar,
    //         installmentWithInterestt: resultPriceCar
    //     }) 
    //     form.setFieldsValue(
    //         {
    //             monthlyPayment: loanTermCar,
    //             interestt: resultRateCar,
    //             installmentWithInterestt: resultPriceCar
    //         }
    //     )
    // }
    // const car_Calculate_close = () => {
    //     if (moneyOld?.oldLoanAmount && moneyOld?.oldMonthlyPayment && moneyOld?.oldLoanTerm) {
    //         // console.log("oldLoanAmount", moneyOld.oldLoanAmount)
    //         // console.log("oldMonthlyPayment", moneyOld.oldMonthlyPayment)
    //         // console.log("oldLoanTerm", moneyOld.oldLoanTerm)
    //         // console.log("oldKangPayment", moneyOld.oldKangPayment)
    //         var loanTermKhang = moneyOld?.oldKangPayment / moneyOld?.oldMonthlyPayment
    //         // console.log("loanTermKhang", loanTermKhang)
    //         setMoneyOld({
    //             ...moneyOld,
    //             oldKangPaymentTerm: loanTermKhang,
    //         })
    //         form.setFieldsValue(
    //             {
    //                 oldKangPaymentTerm: loanTermKhang,
    //             }
    //         )
    //     } else {
    //         console.log("")
    //     }
    // }

    const car_Calculate = () => {
        if (money.loanAmountt && money.interestRate && money.installmentAmountt) {
            var interestRateCar = money.interestRate / 100 // อัตราดอก / 100
            var rateCar = ((money.loanAmountt * Currency(interestRateCar, { precision: 5 }).value) * money.installmentAmountt) // (ยอดจัด * อัตราดอกที่หาร100แล้ว ,precision: 3 คือทศนิยม 3 )*งวด
            var loanTermCar = Math.ceil(Currency((rateCar + money.loanAmountt) / money.installmentAmountt, { precision: 2 }).value) // (ดอกเบี้ยทั้งหมด + ยอดจัด)/ งวด
            var newLoanTermCar = Math.ceil(Currency(loanTermCar * 0.07)) + loanTermCar // งวดละใหม่ = ( งวดละ * 0.07 ) + งวดละ
            var resultRateCar = (newLoanTermCar * money.installmentAmountt) - money.loanAmountt // งวดละใหม่ * งวด - ยอดจัด
            //var resultPriceCar = loanTermCar * money.installmentAmountt // งวดละ * งวด
            var newResultPriceCar = newLoanTermCar * money.installmentAmountt // งวดละใหม่ * งวด

            // console.log("อัตราดอก =",interestRateCar)
            // console.log("rateCar =",rateCar,money.loanAmountt , Currency(interestRateCar, { precision: 5 }).value , money.installmentAmountt)
            // console.log("loanTermCar =",Math.ceil(Currency(loanTermCar*0.07))+loanTermCar)
            // console.log("resultRateCar =",resultRateCar)
            // console.log("resultPriceCar =",newLoanTermCar*money.installmentAmountt)

            setMoney({
                ...money,
                monthlyPayment: newLoanTermCar,
                interestt: resultRateCar,
                installmentWithInterestt: newResultPriceCar
            })
            form.setFieldsValue(
                {
                    monthlyPayment: newLoanTermCar,
                    interestt: resultRateCar,
                    installmentWithInterestt: newResultPriceCar
                }
            )
        }
        else {
            form.setFieldsValue(
                {
                    monthlyPayment: 0,
                    interestt: 0,
                    installmentWithInterestt: 0
                }
            )
        }
    }

    // const handleGoHome = () => {
    //     // dispatch(addCustomer())
    //     // dispatch(addAddress())
    //     // dispatch(addPhone())
    //     // dispatch(addCar())
    //     // dispatch(addLoan())
    //     // dispatch(addOldLoan())
    //     // dispatch(addImage())
    //     // dispatch(addLand())
    //     // dispatch(addLoanLand())
    //     navigate('/offercase/addcustomer')
    // }

    const handleGoHome = () => {
        // navigate('/offercase/addcustomer')
        navigate('/offercase/addguarantor')
    }

    const checkMoneyCar = (value) => {
        // console.log("vv =", test)
        // console.log("carPrice =", dataPost2.carPrice)
        if (money?.productLoanType === "ย้ายไฟแนนซ์" || money?.productLoanType === 'Pre-Aaprove-ย้ายไฟแนนซ์') {
            setMoney({ ...money, loanAmountt: parseInt(value) })
        } else {
            if (dataPost2?.carPrice === 0) {
                setMoney({ ...money, loanAmountt: parseInt(value) })
            } else {
                if (parseInt(value) > dataPost2?.carPrice) {
                    alert("ไม่สามารถใส่ยอดจัดเกินเรทรถได้")
                    form.setFieldsValue(
                        {
                            loanAmountt: 0,
                        }
                    )
                } else {
                    setMoney({ ...money, loanAmountt: parseInt(value) })
                }
            }
        }
    }
    const checkMoneyLandSmall = (value) => {
        // console.log("L =", value)
        // console.log("carPrice =", dataPost2.carPrice)
        setMoneyOldLand({ ...moneyOldLand, oldLandLoanAmount: parseInt(value.oldLandLoanAmount) })
    }
    const checkMoneyCarSmall = (value) => {
        // console.log("vv =", value)
        // console.log("carPrice =", dataPost2.carPrice)
        setMoneyOld({ ...moneyOld, oldLoanAmount: parseInt(value.oldLoanAmount) })
    }

    return (
        <Row justify={'center'}>
            <Card style={{ width: '1100px' }}>
                <Spin spinning={loading} size='large' tip=" Loading... ">
                    <Row style={{ textAlign: 'center', marginTop: '30px' }} >
                        <Col span={24} style={{ fontSize: '30px' }} >รายละเอียด</Col>
                    </Row>
                    <Divider />
                    <Form
                        labelCol={{
                            span: 12,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                        style={{
                            maxWidth: '100%',
                        }}
                        form={form}
                        onFinish={handleSubmit}
                        autoComplete="off"
                        initialValues={{ remember: true }}
                    >
                        <Row className='main2' justify={'center'}>
                            <Card style={{ width: '100%' }}>
                                <Col span={22}>
                                    <Row justify={'center'}>
                                        <aside style={{ width: '85%', textAlign: 'center' }}>
                                            <div>
                                                {/* รถ */}
                                                {dataAddCustomer?.productTypeId === 1 && (
                                                    <>
                                                        <Form.Item label='ชนิดรถ' name='productTypeCar'
                                                            style={{ textAlign: 'center', width: '200px', color: 'black' }}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !'
                                                                },]}>
                                                            <Select
                                                                //placeholder={'เลือก'} 
                                                                style={{ width: '240px', color: 'black', height: '40px' }}
                                                                allowClear
                                                                disabled
                                                                onChange={onTypeChange}>
                                                                <Option value={1}>รถยนต์</Option>
                                                                <Option value={2}>รถเครื่องจักการเกษตร</Option>
                                                                <Option value={3}>รถบรรทุก</Option>
                                                            </Select>
                                                        </Form.Item>
                                                        <Form.Item label='เรทรถ'
                                                            style={{ textAlign: 'left', width: '200px' }}
                                                        >
                                                            <Button type='primary' onClick={getTableCar}>ดู</Button>
                                                            {
                                                                openTableCar ?
                                                                    <TableRateCar
                                                                        open={openTableCar}
                                                                        close={setOpenTableCar}
                                                                        countoffer={countoffer}
                                                                        productTypeCar={dataPost2?.productTypeCar}
                                                                    />
                                                                    : null
                                                            }
                                                        </Form.Item>

                                                        <Row gutter={32}>
                                                            <Col className='gutter-row' span={16}>
                                                                <Form.Item label='ยี่ห้อ'
                                                                    name='carBrand'
                                                                    style={{ lineHeight: '32px', textAlign: 'center', width: '200px' }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please input !'
                                                                        },]}>
                                                                    {!counterBrand ? (
                                                                        <Select
                                                                            showSearch
                                                                            style={{ width: '240px', height: '40px' }}
                                                                            onChange={onBrandChange}
                                                                            optionFilterProp="children"
                                                                            placeholder="Search to Select"
                                                                            filterOption={(inputValue, option) =>
                                                                                option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                                                                            }
                                                                        >
                                                                            {brandData?.map((e) => {
                                                                                return (
                                                                                    <Select.Option key={e.idbrand} value={e.idbrand}>
                                                                                        {e.brand} ({e.brand_th})
                                                                                    </Select.Option>
                                                                                )
                                                                            })}
                                                                        </Select>
                                                                    ) : (<>

                                                                        {counterBrand ? (<AutoComplete
                                                                            options={brandData?.filter(item =>
                                                                                // console.log("item",item)
                                                                                item.brand?.toLowerCase().includes(dataPost2?.carBrand?.toLowerCase()) ||
                                                                                item.brand_th?.toLowerCase().includes(dataPost2?.carBrand?.toLowerCase())
                                                                            )
                                                                                .map(item => ({ value: item.brand }))}
                                                                            value={dataPost2?.carBrand ? dataPost2?.carBrand : ""}
                                                                            onChange={onYeehor}
                                                                            style={{ width: '240px' }}
                                                                        />)
                                                                            : (
                                                                                <Select
                                                                                    style={{ width: '240px', height: '40px' }}
                                                                                    showSearch
                                                                                    onChange={onBrandChange}
                                                                                    placeholder="Search to Select"
                                                                                    optionFilterProp="children"
                                                                                    filterOption={
                                                                                        (inputValue, option) =>
                                                                                            option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                                                                                    }
                                                                                >
                                                                                    {modelData?.map((e) => {
                                                                                        return (
                                                                                            <Select.Option key={e.idmodel_te} value={e.idmodel_te}>
                                                                                                {e.model} {e.model_te ? e.model_te : null}
                                                                                            </Select.Option>
                                                                                        )
                                                                                    })}
                                                                                </Select>)
                                                                        }
                                                                    </>

                                                                    )}
                                                                </Form.Item>

                                                            </Col>
                                                            <Col className='gutter-row' span={3} style={{ textAlign: 'left' }}>
                                                                {/* <Row align={'middle'} justify={'center'}>
                                                                        <Col span={24} style={{ textAlign: 'center' }}> */}
                                                                {counterBrand === false && (
                                                                    <Button onClick={AddBrand} style={{ height: '40px', lineHeight: '20px' }}>
                                                                        +
                                                                    </Button>)
                                                                }
                                                                {counterBrand === true && (
                                                                    <Button onClick={AddBrands} >
                                                                        -
                                                                    </Button>
                                                                )}
                                                                {/* </Col>
                                                                    </Row> */}
                                                            </Col>
                                                        </Row>
                                                        <Row gutter={32}>
                                                            <Col className='gutter-row' span={16}>
                                                                <Form.Item label='รุ่นรถ' name='carModel'
                                                                    style={{ textAlign: 'center', width: '200px' }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please input !'
                                                                        },]}>
                                                                    {counterBrand ? ( //ไม่จริงเข้าตรงนี้
                                                                        <>
                                                                            {!counterModel ? (
                                                                                <Select //ไม่จริงเข้าตรงนี้
                                                                                    showSearch
                                                                                    style={{ width: '240px', height: '40px' }}
                                                                                    onChange={onModelChange}
                                                                                    placeholder="Search to Select"
                                                                                    optionFilterProp="children"
                                                                                    filterOption={(inputValue, option) =>
                                                                                        option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                                                                                    }
                                                                                >
                                                                                    {modelData?.map((e) => {
                                                                                        return (
                                                                                            <Select.Option style={{ width: '250px' }} key={e.idmodel_te} value={e.idmodel_te}>
                                                                                                {e.model} {e.model_te ? e.model_te : null}
                                                                                            </Select.Option>
                                                                                        )
                                                                                    })}
                                                                                </Select>
                                                                            ) : (

                                                                                <Input style={{ width: '240px', height: '40px' }} onChange={onMoChange} />
                                                                            )}

                                                                        </>
                                                                    ) : (<>

                                                                        {counterModel ? (<Input onChange={onMoChange} />) : (
                                                                            <Select
                                                                                showSearch
                                                                                style={{ width: '240px', height: '40px' }}
                                                                                onChange={onModelChange}
                                                                                placeholder="Search to Select"
                                                                                optionFilterProp="children"
                                                                                filterOption={
                                                                                    (inputValue, option) =>
                                                                                        option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                                                                                }
                                                                            >
                                                                                {modelData?.map((e) => {
                                                                                    return (
                                                                                        <Select.Option style={{ width: '250px' }} key={e.idmodel_te} value={e.idmodel_te}>
                                                                                            {e.model} {e.model_te ? e.model_te : null}
                                                                                        </Select.Option>
                                                                                    )
                                                                                })}
                                                                            </Select>)
                                                                        }
                                                                    </>
                                                                    )}
                                                                </Form.Item>
                                                            </Col>
                                                            <Col className='gutter-row' span={3} style={{ textAlign: 'left' }}>
                                                                {counterModel === false && (
                                                                    <Button onClick={AddModel} style={{ height: '40px', lineHeight: '20px' }}>
                                                                        +
                                                                    </Button>)
                                                                }
                                                                {counterModel === true && (
                                                                    <Button onClick={AddModels} >
                                                                        -
                                                                    </Button>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                        <Row gutter={32}>
                                                            <Col className='gutter-row' span={16}>
                                                                <Form.Item label='แบบรถ' name='carBaab'
                                                                    style={{ textAlign: 'center', width: '200px' }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please input !'
                                                                        },]}>
                                                                    {counterBrand ? (
                                                                        <>
                                                                            {!counterCarBaab ? (
                                                                                <Select
                                                                                    showSearch
                                                                                    style={{ width: '240px', height: '40px' }}

                                                                                    onChange={(value) => { onDetailChange(value) }}
                                                                                    placeholder="Search to Select"

                                                                                >
                                                                                    {resultData?.map((e) => {
                                                                                        return (
                                                                                            <Select.Option style={{ width: '250px' }} key={e.idmodel} value={e.idmodel}>
                                                                                                {e.models} {e.idmodel ? e.idmodel : null}
                                                                                            </Select.Option>
                                                                                        )
                                                                                    })}
                                                                                </Select>
                                                                            ) : (

                                                                                <Input style={{ width: '240px', height: '40px' }} onChange={onDeChange} />
                                                                            )}
                                                                        </>
                                                                    ) : (<>

                                                                        {counterCarBaab ? (<Input style={{ width: '240px', height: '40px' }} onChange={onDeChange} />) : (
                                                                            <Select
                                                                                showSearch
                                                                                style={{ width: '240px', height: '40px' }}
                                                                                onChange={(value) => { onDetailChange(value) }}
                                                                                placeholder="Search to Select"
                                                                            >
                                                                                {resultData?.map((e) => {
                                                                                    return (
                                                                                        <Select.Option style={{ width: '250px' }} key={e.idmodel} value={e.idmodel}>
                                                                                            {e.models} {e.idmodel ? e.idmodel : null}
                                                                                        </Select.Option>
                                                                                    )
                                                                                })}
                                                                            </Select>)
                                                                        }
                                                                    </>
                                                                    )}
                                                                </Form.Item>
                                                            </Col>
                                                            <Col className='gutter-row' span={3} style={{ textAlign: 'left' }}>
                                                                {counterCarBaab === false && (
                                                                    <Button onClick={AddCarBaab} style={{ height: '40px', lineHeight: '20px' }}>
                                                                        +
                                                                    </Button>)
                                                                }
                                                                {counterCarBaab === true && (
                                                                    <Button onClick={AddCarBaabs} >
                                                                        -
                                                                    </Button>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                        <Form.Item label='ปีรถ' name='carYear'
                                                            style={{ textAlign: 'center', width: '200px' }}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !'
                                                                },]}>
                                                            {counterBrand ? (
                                                                <>
                                                                    {!counterCarBaab ? (
                                                                        <Select
                                                                            style={{ width: '240px', height: '40px' }}
                                                                            onChange={(k, v) => (onResult(k, v))}
                                                                        >
                                                                            {Object.entries(yearPrice).map(([k, v]) => {
                                                                                if (k.charAt(0) === 'p' && v) {
                                                                                    const year = k.replace('p', '')
                                                                                    return (
                                                                                        <Select.Option style={{ width: '250px' }} key={year} value={v * 1000}>
                                                                                            {year}
                                                                                        </Select.Option>
                                                                                    )
                                                                                }
                                                                            })}
                                                                        </Select>
                                                                    ) : (
                                                                        // <Input onChange={onReChange} />
                                                                        <Yearpick
                                                                            picker="year"
                                                                            name='carYear'
                                                                            style={{ width: '240px', height: '40px' }}
                                                                            // name="birthDate"
                                                                            onChange={(e, string) => {
                                                                                if (e) {
                                                                                    let year = e.$y
                                                                                    //let yearstr = year.toString();
                                                                                    // setDataModalEdit({ ...dataModalEdit, expdt: yearstr })
                                                                                    setDataPost2({ ...dataPost2, carYear: year })
                                                                                }
                                                                            }} />
                                                                    )}

                                                                </>
                                                            )
                                                                :
                                                                (
                                                                    <>
                                                                        {counterCarBaab ? (
                                                                            // <Input onChange={onReChange} style={{ width: '240px', height: '40px'  }} />
                                                                            <Yearpick
                                                                                picker="year"
                                                                                name='carYear'
                                                                                style={{ width: '240px', height: '40px' }}
                                                                                // name="birthDate"
                                                                                onChange={(e, string) => {
                                                                                    if (e) {
                                                                                        let year = e.$y
                                                                                        // console.log("e", e)
                                                                                        //let yearstr = year.toString();
                                                                                        // setDataModalEdit({ ...dataModalEdit, expdt: yearstr })
                                                                                        setDataPost2({ ...dataPost2, carYear: year })
                                                                                    }
                                                                                }} />
                                                                        ) : (
                                                                            <Select
                                                                                style={{ width: '240px', height: '40px' }}
                                                                                onChange={(k, v) => (onResult(k, v))}
                                                                            >
                                                                                {Object.entries(yearPrice).map(([k, v]) => {
                                                                                    if (k.charAt(0) === 'p' && v) {
                                                                                        const year = k.replace('p', '')
                                                                                        return (
                                                                                            <Select.Option style={{ width: '240px' }} key={year} value={v * 1000}>
                                                                                                {year}
                                                                                            </Select.Option>
                                                                                        )
                                                                                    }
                                                                                })}
                                                                            </Select>
                                                                        )}
                                                                    </>

                                                                )}
                                                        </Form.Item>

                                                        <Form.Item label='ราคา' name='carPrice'
                                                            style={{ textAlign: 'center', width: '200px' }}
                                                        >
                                                            <Input disabled defaultValue={0} style={{ color: 'black', width: '240px', height: '40px' }}
                                                                onChange={(e) => setDataPost2({ ...dataPost2, carPrice: e.value })} />
                                                        </Form.Item>

                                                        <Form.Item label='จังหวัด' name='carProvince'
                                                            style={{ textAlign: 'center', width: '200px' }}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !',
                                                                },]}>
                                                            <Select
                                                                showSearch
                                                                style={{ width: '240px', height: '40px' }}
                                                                name='carProvince'
                                                                placeholder='จังหวัด'
                                                                onChange={(key, value) => { handleProvinceChangeCar(key, value) }}
                                                            >
                                                                {provincesCar?.map((pro, index) => (
                                                                    <Option key={pro.provinceId} value={pro.provinceName}>
                                                                        {pro.provinceName}
                                                                    </Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>

                                                        <Form.Item label='เลขทะเบียน' name='carPlateNumber'
                                                            style={{ textAlign: 'center', width: '200px' }}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !',
                                                                },]}>
                                                            <Input
                                                                style={{ color: 'black', width: '240px', height: '40px' }}
                                                                onChange={(e) => setDataPost2({ ...dataPost2, carPlateNumber: e.target.value })} />
                                                        </Form.Item>


                                                    </>
                                                )}
                                                {/* ที่ดิน */}
                                                {dataAddCustomer?.productTypeId === 2 && (
                                                    <>
                                                        <Form.Item label='ประเภทเอกสาร' name='landTypeId'
                                                            style={{ textAlign: 'center', width: '300px' }}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !'
                                                                },]}>
                                                            <Select placeholder={'เลือก'}
                                                                style={{ width: '250px', height: '40px' }}
                                                                onChange={(value) => handleProductLand(value)}
                                                            //onChange={(value) => setDataPost2({ ...dataPost2, productTypeId: value })}
                                                            >
                                                                <Option value={1}>นส.3 ก.</Option>
                                                                <Option value={2}>นส.4 จ.</Option>
                                                            </Select>
                                                        </Form.Item>
                                                        {/* นส3 */}
                                                        {selectProduct && dataPostLand?.landTypeId === 1 && (
                                                            <>
                                                                <Col>
                                                                    <Form.Item name='province' label='จังหวัด'
                                                                        style={{ textAlign: 'center', width: '300px' }}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Please input !'
                                                                            },]}>
                                                                        <Select
                                                                            showSearch
                                                                            loading={loading}
                                                                            style={{ width: '250px', height: '40px' }}
                                                                            name='province'
                                                                            placeholder="จังหวัด"
                                                                            onChange={handleProvinceChange1}
                                                                        >
                                                                            {provinces?.result?.map((pro, index) => (
                                                                                <Option key={pro.pvcode} value={pro.pvnamethai}>
                                                                                    {pro.pvnamethai}
                                                                                </Option>
                                                                            ))}
                                                                        </Select>
                                                                    </Form.Item>

                                                                    <Form.Item name='district' label='อำเภอ'
                                                                        style={{ textAlign: 'center', width: '300px' }}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Please input !'
                                                                            },]}>
                                                                        <Select
                                                                            showSearch
                                                                            style={{ width: '250px', height: '40px' }}
                                                                            loading={loading}
                                                                            name='district'
                                                                            placeholder="อำเภอ"
                                                                            onChange={handleDistrictChange2}
                                                                        >
                                                                            {district?.map((dis, index) => (
                                                                                <Option key={dis.amcode} value={dis.amnamethai}>
                                                                                    {dis.amnamethai}
                                                                                </Option>
                                                                            ))}
                                                                        </Select>
                                                                    </Form.Item>

                                                                    <Form.Item label='เลขที่โฉนด/เลขที่' name='numberLand'
                                                                        style={{ textAlign: 'center', width: '300px' }}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Please input !'
                                                                            },]}>
                                                                        <Input type='text'
                                                                            style={{ width: '250px', height: '40px' }}
                                                                            onChange={(e) => setDataPostLand({ ...dataPostLand, numberLand: e.target.value })}></Input>
                                                                    </Form.Item>

                                                                    <Form.Item label='พื้นที่ไร่' name='rai'
                                                                        style={{ textAlign: 'center', width: '300px' }}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Please input !'
                                                                            },]}>
                                                                        <Input type='number' suffix="ไร่"
                                                                            style={{ width: '250px', height: '40px' }}
                                                                            onChange={(e) => setDataPostLand({ ...dataPostLand, rai: parseInt(e.target.value) })}></Input>
                                                                    </Form.Item>

                                                                    <Form.Item label='พื้นที่งาน' name='workArea'
                                                                        style={{ textAlign: 'center', width: '300px' }}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Please input !'
                                                                            },]}>
                                                                        <Input type='number' suffix="งาน"
                                                                            style={{ width: '250px', height: '40px' }}
                                                                            onChange={(e) => setDataPostLand({ ...dataPostLand, workArea: parseInt(e.target.value) })}></Input>
                                                                    </Form.Item>

                                                                    <Form.Item label='พื้นที่ตารางวา' name='squareWaArea'
                                                                        style={{ textAlign: 'center', width: '300px' }}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Please input !'
                                                                            },]}>
                                                                        <Input type='number' suffix="ตารางวา"
                                                                            style={{ width: '250px', height: '40px' }}
                                                                            onChange={(e) => setDataPostLand({ ...dataPostLand, squareWaArea: parseFloat(e.target.value) })}></Input>
                                                                    </Form.Item>

                                                                </Col>
                                                            </>
                                                        )}
                                                        {/* นส4 */}
                                                        {selectProduct && dataPostLand?.landTypeId === 2 && (
                                                            <>
                                                                <Col style={{ margin: 15 }}>
                                                                    <Form.Item name='province' label='จังหวัด'
                                                                        style={{ textAlign: 'center', width: '300px' }}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Please input !'
                                                                            },]}>
                                                                        <Select
                                                                            showSearch
                                                                            style={{ width: '250px', height: '40px' }}
                                                                            loading={loading}
                                                                            name='province'
                                                                            placeholder="จังหวัด"
                                                                            onChange={handleProvinceChange}
                                                                        >
                                                                            {provinces?.result?.map((pro, index) => (
                                                                                <Option key={pro.pvcode} value={pro.pvnamethai}>
                                                                                    {pro.pvnamethai}
                                                                                </Option>
                                                                            ))}
                                                                        </Select>
                                                                    </Form.Item>

                                                                    <Form.Item name='district' label='อำเภอ'
                                                                        style={{ textAlign: 'center', width: '300px' }}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Please input !'
                                                                            },]}>
                                                                        <Select
                                                                            showSearch
                                                                            style={{ width: '250px', height: '40px' }}
                                                                            loading={loading}
                                                                            name='district'
                                                                            placeholder="อำเภอ"
                                                                            onChange={handleDistrictChange}
                                                                        >
                                                                            {district?.map((dis, index) => (
                                                                                <Option key={dis.amcode} value={dis.amnamethai}>
                                                                                    {dis.amnamethai}
                                                                                </Option>
                                                                            ))}
                                                                        </Select>
                                                                    </Form.Item>

                                                                    <Form.Item label='เลขโฉนดที่ดิน' name='numberLand'
                                                                        style={{ textAlign: 'center', width: '300px' }}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Please input !'
                                                                            },]}>
                                                                        <Input type='text'
                                                                            style={{ width: '250px', height: '40px' }}
                                                                            //onChange={(e) => setDataPostLand({ ...dataPostLand, numberLand: e.target.value })}></Input>
                                                                            onChange={(e) => handleNumberLand(e)}></Input>
                                                                    </Form.Item>

                                                                    <Form.Item label='ตรวจสอบ'
                                                                        style={{ textAlign: 'left', width: '300px' }}
                                                                    >
                                                                        <Button type="primary" onClick={handleCheckLand}>ตรวจสอบ</Button>
                                                                    </Form.Item>

                                                                    <Form.Item label='ระวาง' name='numberLandLawang'>
                                                                        <Input type='text' disabled style={{ color: 'black', width: '250px', height: '40px' }}
                                                                            onChange={(e) => setDataPostLand({ ...dataPostLand, numberLandLawang: e.target.value })}></Input>
                                                                    </Form.Item>

                                                                    <Form.Item label='พื้นที่ไร่' name='rai'
                                                                        style={{ textAlign: 'center', width: '300px' }}
                                                                    >
                                                                        <Input type='text' suffix="ไร่" disabled style={{ color: 'black', width: '250px', height: '40px' }}
                                                                            onChange={(e) => setDataPostLand({ ...dataPostLand, rai: parseInt(e.target.value) })}></Input>
                                                                    </Form.Item>

                                                                    <Form.Item label='พื้นที่งาน' name='workArea'
                                                                        style={{ textAlign: 'center', width: '300px' }}
                                                                    >
                                                                        <Input type='text' suffix="งาน" disabled style={{ color: 'black', width: '250px', height: '40px' }}
                                                                            onChange={(e) => setDataPostLand({ ...dataPostLand, workArea: parseInt(e.target.value) })}></Input>
                                                                    </Form.Item>

                                                                    <Form.Item label='พื้นที่ตารางวา' name='squareWaArea'
                                                                        style={{ textAlign: 'center', width: '300px' }}
                                                                    >
                                                                        <Input type='text' suffix="ตารางวา" disabled style={{ color: 'black', width: '250px', height: '40px' }}
                                                                            onChange={(e) => setDataPostLand({ ...dataPostLand, squareWaArea: parseFloat(e.target.value) })}></Input>
                                                                    </Form.Item>

                                                                    <Form.Item label='ราคาประเมินที่ดิน' name='landPrice'
                                                                        style={{ textAlign: 'center', width: '300px' }}
                                                                    >
                                                                        <Input type='text' suffix="บาท / ตารางวา" disabled style={{ color: 'black', width: '250px', height: '40px' }}
                                                                            onChange={(e) => setDataPostLand({ ...dataPostLand, landPrice: parseInt(e.target.value) })}></Input>
                                                                    </Form.Item>

                                                                    <Form.Item label='ราคารวม' name='resultLandPrice'
                                                                        style={{ textAlign: 'center', width: '300px' }}
                                                                    >
                                                                        <Input type='text' suffix="บาท" disabled style={{ color: 'black', width: '250px', height: '40px' }}
                                                                            onChange={(e) => setDataPostLand({ ...dataPostLand, resultLandPrice: parseInt(e.target.value) })}></Input>

                                                                    </Form.Item>


                                                                </Col>
                                                            </>
                                                        )}

                                                    </>
                                                )}
                                            </div>
                                            <div>
                                                {/* โลนรถ */}
                                                {dataAddCustomer?.productTypeId === 1 && (
                                                    <>
                                                        <Form.Item label='ประเภทขอกู้รถ' name='productLoanType'
                                                            style={{ textAlign: 'center', width: '200px' }}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !',
                                                                },]}>
                                                            <Select
                                                                placeholder={'เลือก'}
                                                                style={{ width: '240px', height: '40px' }}
                                                                onChange={(value) => { handleChangeType(value) }}
                                                                options={productLoanTypeCar}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item label='' name=''
                                                            style={{ textAlign: 'center', width: '200px' }}
                                                        >
                                                            {
                                                                checkinfo !== undefined ?
                                                                    <Button type='primary'
                                                                        onClick={async () => {
                                                                            modal.info(config);
                                                                        }}>{checkinfo} คือ?</Button>
                                                                    : null
                                                            }

                                                        </Form.Item>

                                                        {
                                                            dataPost2.productTypeCar === 1 ?
                                                                <Form.Item label='ประเภทค่างวด' name='PLorCH'
                                                                    style={{
                                                                        textAlign: 'center', width: '200px'
                                                                    }}
                                                                >
                                                                    <Select
                                                                        placeholder={'เลือก'}
                                                                        style={{ width: '240px', height: '40px' }}
                                                                        onChange={(value) => { handleChangePLorCH(value) }}
                                                                        options={loanPLorCH}
                                                                    />
                                                                </Form.Item>
                                                                :
                                                                <Form.Item label='ประเภทค่างวด' name='PLorCH'
                                                                    style={{
                                                                        textAlign: 'center', width: '200px'
                                                                    }}
                                                                >
                                                                    <Select
                                                                        placeholder={'เลือก'}
                                                                        style={{ width: '240px', height: '40px' }}
                                                                        onChange={(value) => { handleChangePLorCH(value) }}
                                                                        options={loanPLorCHBig}
                                                                    />
                                                                </Form.Item>

                                                        }


                                                        {/* P-LOAN */}
                                                        {checkPCH && typeof checkPCH.PLorCH !== 'undefined' && (checkPCH.PLorCH === "p-loan") ? (
                                                            <>
                                                                <Form.Item label='P-LOAN'
                                                                    style={{ textAlign: 'center', width: '200px' }}
                                                                >
                                                                    <Button type="primary" onClick={getTable}>
                                                                        ตารางค่างวด P-LOAN
                                                                    </Button>
                                                                    {
                                                                        openTable ?
                                                                            <Installment open={openTable} close={setOpenTable} money={handleMoney} type={money} cp={dataPost2?.carPrice} />
                                                                            : null
                                                                    }
                                                                </Form.Item>

                                                                <Form.Item label='ยอดจัด' name='loanAmountt'
                                                                    style={{ textAlign: 'center', width: '200px' }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please input !'
                                                                        },]}>
                                                                    <Input disabled style={{ color: 'black', width: '240px', height: '40px' }}
                                                                        onChange={(e) => setMoney({ ...money, loanAmountt: e.target.value })} />
                                                                </Form.Item>

                                                                <Form.Item label='จำนวนงวด' name='installmentAmountt'
                                                                    style={{ textAlign: 'center', width: '200px' }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please input !'
                                                                        },]}>
                                                                    <Input disabled style={{ color: 'black', width: '240px', height: '40px' }}
                                                                        onChange={(e) => setMoney({ ...money, installmentAmountt: e.target.value })} />
                                                                </Form.Item>

                                                                <Form.Item label='งวดละ' name='monthlyPayment'
                                                                    style={{ textAlign: 'center', width: '200px' }}
                                                                >
                                                                    <Input disabled style={{ color: 'black', width: '240px', height: '40px' }}
                                                                        onChange={(e) => setMoney({ ...money, monthlyPayment: e.target.value })} />
                                                                </Form.Item>

                                                                <Form.Item label='ดอกเบี้ย' name='interestt'
                                                                    style={{ textAlign: 'center', width: '200px' }}
                                                                >
                                                                    <Input disabled style={{ color: 'black', width: '240px', height: '40px' }}
                                                                        onChange={(e) => setMoney({ ...money, interestt: e.target.value })} />
                                                                </Form.Item>

                                                                <Form.Item label='รวมราคา' name='installmentWithInterestt'
                                                                    style={{ textAlign: 'center', width: '200px' }}
                                                                >
                                                                    <Input disabled style={{ color: 'black', width: '240px', height: '40px' }}
                                                                        onChange={(e) => setMoney({ ...money, installmentWithInterestt: e.target.value })} />
                                                                </Form.Item>
                                                                <Form.Item label='ผู้เสนอเคส' name='proposalBy'
                                                                    style={{ textAlign: 'center', width: '200px' }}
                                                                >
                                                                    <Input disabled style={{ color: 'black', width: '240px', height: '40px' }}
                                                                        onChange={(e) => setMoney({ ...money, proposalBy: e.target.value })} />
                                                                    {/* {currencyFormatOne(money.installmentWithInterestt)} บาท */}
                                                                </Form.Item>

                                                            </>
                                                        ) : null}

                                                        {/* เช่าซื้อ */}

                                                        {dataPost2 && typeof dataPost2.productTypeCar !== 'undefined' && (dataPost2.productTypeCar === 1 && checkPCH.PLorCH === "เช่าซื้อ") ? (
                                                            <>
                                                                <Form.Item label='เช่าซื้อ'
                                                                    style={{ textAlign: 'center', width: '200px' }}
                                                                >
                                                                    <Button type="primary" onClick={getTableHP}>
                                                                        ตารางค่างวด เช่าซื้อ
                                                                    </Button>
                                                                    {
                                                                        openTableHP ?
                                                                            <HirePurchase open={openTableHP} close={setOpenTableHP} money={handleMoneyHP} type={money} cp={dataPost2?.carPrice} />
                                                                            : null
                                                                    }
                                                                </Form.Item>

                                                                <Form.Item label='ยอดจัด' name='loanAmountt'
                                                                    style={{ textAlign: 'center', width: '200px' }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please input !',
                                                                        },]}>
                                                                    <Input disabled style={{ color: 'black', width: '250px', height: '40px' }}
                                                                        onChange={(e) => setMoney({ ...money, loanAmountt: e.target.value })} />
                                                                </Form.Item>

                                                                <Form.Item label='จำนวนงวด' name='installmentAmountt'
                                                                    style={{ textAlign: 'center', width: '200px' }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please input !',
                                                                        },]}>
                                                                    <Input disabled style={{ color: 'black', width: '250px', height: '40px' }}
                                                                        onChange={(e) => setMoney({ ...money, installmentAmountt: e.target.value })} />
                                                                </Form.Item>

                                                                <Form.Item label='งวดละ' name='monthlyPayment'
                                                                    style={{ textAlign: 'center', width: '200px' }}
                                                                >
                                                                    <Input disabled style={{ color: 'black', width: '250px', height: '40px' }}
                                                                        onChange={(e) => setMoney({ ...money, monthlyPayment: e.target.value })} />
                                                                </Form.Item>

                                                                <Form.Item label='ดอกเบี้ย' name='interestt'
                                                                    style={{ textAlign: 'center', width: '200px' }}
                                                                >
                                                                    <Input disabled style={{ color: 'black', width: '250px', height: '40px' }}
                                                                        onChange={(e) => setMoney({ ...money, interestt: e.target.value })} />
                                                                </Form.Item>

                                                                <Form.Item label='ราคารวม' name='installmentWithInterestt'
                                                                    style={{ textAlign: 'center', width: '200px' }}
                                                                >
                                                                    <Input disabled style={{ color: 'black', width: '250px', height: '40px' }}
                                                                        onChange={(e) => setMoney({ ...money, installmentWithInterestt: e.target.value })} />
                                                                </Form.Item>
                                                                <Form.Item label='ผู้เสนอเคส' name='proposalBy'
                                                                    style={{ textAlign: 'center', width: '300px' }}
                                                                >
                                                                    <Input disabled style={{ color: 'black', width: '250px', height: '40px' }}
                                                                        onChange={(e) => setMoney({ ...money, proposalBy: e.target.value })} />
                                                                </Form.Item>
                                                            </>
                                                        ) : dataPost2 && typeof dataPost2.productTypeCar !== 'undefined' && (dataPost2.productTypeCar === 1 && checkPCH.PLorCH === "เช่าซื้อ1") ?
                                                            <>
                                                                <Form.Item label='ยอดจัด' name='loanAmountt'
                                                                    style={{ textAlign: 'center', width: '200px' }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please input !'
                                                                        },]}>
                                                                    <InputNumber
                                                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                                        size="large"
                                                                        style={{ width: '250px', color: 'black', height: '40px' }}
                                                                        onChange={(value) => checkMoneyCar(value)}
                                                                    />
                                                                </Form.Item>

                                                                {/* <Form.Item label='อัตราดอกเบี้ย1' name='interestRate'
                                                                    style={{ textAlign: 'center', width: '200px' }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please input !'
                                                                        },]}>
                                                                    <Input type='number' suffix="% +VAT"
                                                                        style={{ width: '250px', color: 'black' }}
                                                                        onChange={(e) => setMoney({ ...money, interestRate: parseFloat(e.target.value) })} />
                                                                </Form.Item> */}

                                                                <Form.Item label='อัตราดอกเบี้ย' name='interestRate'
                                                                    style={{ textAlign: 'center', width: '200px', height: '40px', marginBottom: '50px' }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please input !'
                                                                        },]}>
                                                                    <Select
                                                                        style={{ textAlign: 'center', width: '250px', height: '40px' }}
                                                                        placeholder={'เลือก'}
                                                                        onChange={(value) => setMoney({ ...money, interestRate: value })}
                                                                        label="จำนวนงวด"
                                                                        options={[
                                                                            { label: 0.75, value: 0.75 }, { label: 0.84, value: 0.84 },
                                                                        ]}
                                                                    >
                                                                    </Select>
                                                                </Form.Item>

                                                                <Form.Item label='จำนวนงวด' name='installmentAmountt'
                                                                    style={{ textAlign: 'center', width: '200px', height: '40px', marginBottom: '50px' }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please input !'
                                                                        },]}>
                                                                    <Select
                                                                        style={{ textAlign: 'center', width: '250px', height: '40px' }}
                                                                        placeholder={'เลือก'}
                                                                        onChange={(value) => setMoney({ ...money, installmentAmountt: value })}
                                                                        label="จำนวนงวด"
                                                                        options={[
                                                                            { label: '12', value: 12 }, { label: 18, value: 18 }, { label: '24', value: 24 },
                                                                            { label: '30', value: 30 }, { label: 36, value: 36 }, { label: '42', value: 42 },
                                                                            { label: '48', value: 48 }, { label: 54, value: 54 }, { label: '60', value: 60 },
                                                                            { label: '66', value: 66 }, { label: 72, value: 72 }, { label: '78', value: 78 },
                                                                            { label: '84', value: 84 }, { label: 90, value: 90 }, { label: '96', value: 96 },
                                                                            { label: '102', value: 102 }, { label: 108, value: 108 }, { label: '114', value: 114 },
                                                                            { label: '120', value: 120 }
                                                                        ]}
                                                                    >
                                                                    </Select>
                                                                </Form.Item>

                                                                <Form.Item label='งวดละ' name='monthlyPayment'
                                                                    style={{ textAlign: 'center', width: '200px' }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please input !'
                                                                        },]}>
                                                                    <Input type='number' disabled style={{ color: 'black', width: '250px', height: '40px' }}
                                                                        onChange={(e) => setMoney({ ...money, monthlyPayment: parseInt(e.target.value) })} />
                                                                </Form.Item>

                                                                <Form.Item label='ดอกเบี้ย' name='interestt'
                                                                    style={{ textAlign: 'center', width: '200px' }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please input !'
                                                                        },]}>
                                                                    <Input type='number' disabled style={{ color: 'black', width: '250px', height: '40px' }}
                                                                        onChange={(e) => setMoney({ ...money, interestt: parseInt(e.target.value) })} />
                                                                </Form.Item>

                                                                <Form.Item label='รวมราคา' name='installmentWithInterestt'
                                                                    style={{ textAlign: 'center', width: '200px' }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please input !'
                                                                        },]}>
                                                                    <Input type='number' disabled style={{ color: 'black', width: '250px', height: '40px' }}
                                                                        onChange={(e) => setMoney({ ...money, installmentWithInterestt: parseInt(e.target.value) })} />
                                                                </Form.Item>
                                                                <Form.Item label='ผู้เสนอเคส' name='proposalBy'
                                                                    style={{ textAlign: 'center', width: '300px' }}
                                                                >
                                                                    <Input disabled style={{ color: 'black', width: '250px', height: '40px' }}
                                                                        onChange={(e) => setMoney({ ...money, proposalBy: e.target.value })} />
                                                                </Form.Item>
                                                            </>
                                                            : null
                                                        }

                                                        {dataPost2 && typeof dataPost2.productTypeCar !== 'undefined' && (dataPost2.productTypeCar === 2 && checkPCH.PLorCH === "เช่าซื้อ") ? (
                                                            <>
                                                                <Form.Item label='ยอดจัด' name='loanAmountt'
                                                                    style={{ textAlign: 'center', width: '200px' }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please input !'
                                                                        },]}>

                                                                    <InputNumber
                                                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                                        size="large"
                                                                        style={{ color: 'black', width: '250px', height: '40px' }}
                                                                        onChange={(value) => checkMoneyCar(value)}
                                                                    />
                                                                </Form.Item>

                                                                <Form.Item label='อัตราดอกเบี้ย' name='interestRate'
                                                                    style={{ textAlign: 'center', width: '200px', height: '40px' }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please input !'
                                                                        },]}>
                                                                    <Input type='number' suffix="% +VAT" name="interestRate"
                                                                        style={{ width: '250px' }}
                                                                        onChange={(e) => setMoney({ ...money, interestRate: parseFloat(e.target.value) })} />
                                                                </Form.Item>

                                                                <Form.Item label='จำนวนงวด' name='installmentAmountt'
                                                                    style={{ textAlign: 'center', width: '200px', height: '40px', marginBottom: '50px' }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please input !'
                                                                        },]}>
                                                                    <Select
                                                                        style={{ textAlign: 'center', width: '250px', height: '40px' }}
                                                                        placeholder={'เลือก'}
                                                                        onChange={(value) => setMoney({ ...money, installmentAmountt: value })}
                                                                        label="จำนวนงวด"
                                                                        options={[
                                                                            { label: '12', value: 12 }, { label: 18, value: 18 }, { label: '24', value: 24 },
                                                                            { label: '30', value: 30 }, { label: 36, value: 36 }, { label: '42', value: 42 },
                                                                            { label: '48', value: 48 }, { label: 54, value: 54 }, { label: '60', value: 60 },
                                                                            { label: '66', value: 66 }, { label: 72, value: 72 }, { label: '78', value: 78 },
                                                                            { label: '84', value: 84 }, { label: 90, value: 90 }, { label: '96', value: 96 },
                                                                            { label: '102', value: 102 }, { label: 108, value: 108 }, { label: '114', value: 114 },
                                                                            { label: '120', value: 120 }
                                                                        ]}
                                                                    >
                                                                    </Select>
                                                                </Form.Item>

                                                                <Form.Item label='งวดละ' name='monthlyPayment'
                                                                    style={{ textAlign: 'center', width: '200px', height: '40px' }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please input !'
                                                                        },]}>
                                                                    <Input type='number' disabled style={{ color: 'black', width: '250px' }}
                                                                        onChange={(e) => setMoney({ ...money, monthlyPayment: parseInt(e.target.value) })} />
                                                                </Form.Item>

                                                                <Form.Item label='ดอกเบี้ย' name='interestt'
                                                                    style={{ textAlign: 'center', width: '200px', height: '40px' }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please input !'
                                                                        },]}>
                                                                    <Input type='number' disabled style={{ color: 'black', width: '250px' }}
                                                                        onChange={(e) => setMoney({ ...money, interestt: parseInt(e.target.value) })} />
                                                                </Form.Item>

                                                                <Form.Item label='รวมราคา' name='installmentWithInterestt'
                                                                    style={{ textAlign: 'center', width: '200px', height: '40px' }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please input !'
                                                                        },]}>
                                                                    <Input type='number' disabled style={{ color: 'black', width: '250px' }}
                                                                        onChange={(e) => setMoney({ ...money, installmentWithInterestt: parseInt(e.target.value) })} />
                                                                </Form.Item>
                                                                <Form.Item label='ผู้เสนอเคส' name='proposalBy'
                                                                    style={{ textAlign: 'center', width: '300px' }}
                                                                >
                                                                    <Input disabled style={{ color: 'black', width: '250px', height: '40px' }}
                                                                        onChange={(e) => setMoney({ ...money, proposalBy: e.target.value })} />
                                                                </Form.Item>
                                                            </>
                                                        ) : null}

                                                        {dataPost2 && typeof dataPost2.productTypeCar !== 'undefined' && (dataPost2.productTypeCar === 3 && checkPCH.PLorCH === "เช่าซื้อ") ? (
                                                            <>
                                                                <Form.Item label='ยอดจัด' name='loanAmountt'
                                                                    style={{ textAlign: 'center', width: '200px' }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please input !'
                                                                        },]}>
                                                                    <InputNumber
                                                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                                        size="large"
                                                                        style={{ width: '250px', color: 'black', height: '40px' }}
                                                                        onChange={(value) => checkMoneyCar(value)}
                                                                    />
                                                                </Form.Item>

                                                                <Form.Item label='อัตราดอกเบี้ย' name='interestRate'
                                                                    style={{ textAlign: 'center', width: '200px' }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please input !'
                                                                        },]}>
                                                                    <Input type='number' suffix="% +VAT"
                                                                        style={{ width: '250px', color: 'black' }}
                                                                        onChange={(e) => setMoney({ ...money, interestRate: parseFloat(e.target.value) })} />
                                                                </Form.Item>



                                                                <Form.Item label='จำนวนงวด' name='installmentAmountt'
                                                                    style={{ textAlign: 'center', width: '200px', height: '40px', marginBottom: '50px' }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please input !'
                                                                        },]}>
                                                                    <Select
                                                                        style={{ textAlign: 'center', width: '250px', height: '40px' }}
                                                                        placeholder={'เลือก'}
                                                                        onChange={(value) => setMoney({ ...money, installmentAmountt: value })}
                                                                        label="จำนวนงวด"
                                                                        options={[
                                                                            { label: '12', value: 12 }, { label: 18, value: 18 }, { label: '24', value: 24 },
                                                                            { label: '30', value: 30 }, { label: 36, value: 36 }, { label: '42', value: 42 },
                                                                            { label: '48', value: 48 }, { label: 54, value: 54 }, { label: '60', value: 60 },
                                                                            { label: '66', value: 66 }, { label: 72, value: 72 }, { label: '78', value: 78 },
                                                                            { label: '84', value: 84 }, { label: 90, value: 90 }, { label: '96', value: 96 },
                                                                            { label: '102', value: 102 }, { label: 108, value: 108 }, { label: '114', value: 114 },
                                                                            { label: '120', value: 120 }
                                                                        ]}
                                                                    >
                                                                    </Select>
                                                                </Form.Item>

                                                                <Form.Item label='งวดละ' name='monthlyPayment'
                                                                    style={{ textAlign: 'center', width: '200px' }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please input !'
                                                                        },]}>
                                                                    <Input type='number' disabled style={{ color: 'black', width: '250px', height: '40px' }}
                                                                        onChange={(e) => setMoney({ ...money, monthlyPayment: parseInt(e.target.value) })} />
                                                                </Form.Item>

                                                                <Form.Item label='ดอกเบี้ย' name='interestt'
                                                                    style={{ textAlign: 'center', width: '200px' }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please input !'
                                                                        },]}>
                                                                    <Input type='number' disabled style={{ color: 'black', width: '250px', height: '40px' }}
                                                                        onChange={(e) => setMoney({ ...money, interestt: parseInt(e.target.value) })} />
                                                                </Form.Item>

                                                                <Form.Item label='รวมราคา' name='installmentWithInterestt'
                                                                    style={{ textAlign: 'center', width: '200px' }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please input !'
                                                                        },]}>
                                                                    <Input type='number' disabled style={{ color: 'black', width: '250px', height: '40px' }}
                                                                        onChange={(e) => setMoney({ ...money, installmentWithInterestt: parseInt(e.target.value) })} />
                                                                </Form.Item>
                                                                <Form.Item label='ผู้เสนอเคส' name='proposalBy'
                                                                    style={{ textAlign: 'center', width: '300px' }}
                                                                >
                                                                    <Input disabled style={{ color: 'black', width: '250px', height: '40px' }}
                                                                        onChange={(e) => setMoney({ ...money, proposalBy: e.target.value })} />
                                                                </Form.Item>
                                                            </>
                                                        ) : null}

                                                        {(moneyOld && moneyOld?.productLoanType === 'ย้ายไฟแนนซ์') || (moneyOld && moneyOld?.productLoanType === 'Pre-Aaprove-ย้ายไฟแนนซ์') ?
                                                            <>
                                                                <Form.Item label='ปิดไฟแนนซ์จาก' name='issuno'
                                                                    style={{ textAlign: 'center', width: '300px' }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please input !'
                                                                        },]}>
                                                                    <Input type='text'
                                                                        style={{ color: 'black', width: '250px', height: '40px' }}
                                                                        onChange={(e) => setMoneyOld({ ...moneyOld, issuno: e.target.value.trim() })} />
                                                                </Form.Item>
                                                                <Form.Item label='ยอดปิด(ที่เดิม)' name='oldLoanAmount'
                                                                    style={{ textAlign: 'center', width: '300px' }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please input !'
                                                                        },]}>

                                                                    <InputNumber
                                                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                                        size="large"
                                                                        style={{ width: '250px', color: 'black', height: '40px' }}
                                                                        onChange={(value) => checkMoneyCarSmall({ oldLoanAmount: value })}
                                                                    />
                                                                </Form.Item>

                                                                <Form.Item label='ค่างวด/เดือน' name='oldMonthlyPayment'
                                                                    style={{ textAlign: 'center', width: '300px' }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please input !'
                                                                        },]}>

                                                                    <Input
                                                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                                        size="large"
                                                                        style={{ color: 'black', width: '250px', height: '40px' }}
                                                                        onChange={(e) => setMoneyOld({ ...moneyOld, oldMonthlyPayment: parseInt(e.target.value) })}
                                                                    />
                                                                </Form.Item>

                                                                <Form.Item label='จำนวนงวด' name='oldLoanTerm'
                                                                    style={{ textAlign: 'center', width: '300px' }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please input !'
                                                                        },]}>

                                                                    <Input
                                                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                                        size="large"
                                                                        style={{ color: 'black', width: '250px', height: '40px' }}
                                                                        onChange={(e) => setMoneyOld({ ...moneyOld, oldLoanTerm: parseInt(e.target.value) })}
                                                                    />
                                                                </Form.Item>

                                                                <Form.Item label='ผู้เสนอเคส' name='proposalBy'
                                                                    style={{ textAlign: 'center', width: '300px' }}
                                                                >
                                                                    <Input disabled style={{ color: 'black', width: '250px', height: '40px' }}
                                                                        onChange={(e) => setMoney({ ...money, proposalBy: e.target.value })} />
                                                                </Form.Item>

                                                            </>
                                                            : null}
                                                    </>
                                                )}
                                                {/* โลนที่ดิน */}

                                                {dataAddCustomer?.productTypeId === 2 && (
                                                    <>
                                                        <Form.Item label='ประเภทขอกู้ที่ดิน' name='productLoanLandType'
                                                            style={{ textAlign: 'center', width: '300px' }}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !',
                                                                },]}>
                                                            <Select
                                                                placeholder={'เลือก'}
                                                                style={{ width: '250px', height: '40px' }}
                                                                onChange={(value) => { handleChangeLandType(value) }}
                                                                options={productLoanTypeLand}
                                                            />
                                                        </Form.Item>

                                                        <Form.Item label='ยอดจัด' name='loanAmounttLand'
                                                            style={{ textAlign: 'center', width: '300px' }}

                                                        >

                                                            <InputNumber
                                                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                                size="large"
                                                                style={{ color: 'black', width: '250px', height: '40px' }}
                                                                onChange={(value) => setMoneyLand({ ...moneyLand, loanAmounttLand: parseInt(value) })}
                                                            />
                                                            {/* <span style={{ color: 'red' }}><u>ยอดจัดขั้นต่ำ 50,000 บาท</u></span> */}
                                                        </Form.Item>
                                                        <Form.Item
                                                            style={{ textAlign: 'center', width: '300px' }}
                                                        >
                                                            <span style={{ color: 'red' }}><u>ยอดจัดขั้นต่ำ 50,000 บาท</u></span>
                                                        </Form.Item>
                                                        <Form.Item label='อัตราดอกเบี้ย' name='interestRateLand'
                                                            style={{ textAlign: 'center', width: '300px' }}
                                                        >
                                                            <Input defaultValue={1.1} type='number' suffix="% +VAT" disabled
                                                                style={{ color: 'black', width: '250px' }}
                                                                onChange={(e) => setMoneyLand({ ...moneyLand, interestRateLand: parseFloat(e.target.value) })} />
                                                        </Form.Item>

                                                        <Form.Item label='ค่าธรรมเนียม' name='fee'
                                                            style={{ textAlign: 'center', width: '300px' }}
                                                        >
                                                            <Input defaultValue={3500} type='number' disabled suffix="บาท"
                                                                style={{ color: 'black', width: '250px', height: '40px' }}
                                                                onChange={(e) => setMoneyLand({ ...moneyLand, fee: parseFloat(e.target.value) })} />
                                                        </Form.Item>


                                                        <Form.Item label='จำนวนงวด' name='loanLandTerm'
                                                            style={{ textAlign: 'center', width: '300px', height: '40px', marginBottom: '50px' }}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !'
                                                                },]}>
                                                            <Select
                                                                style={{ textAlign: 'center', width: '300px', height: '40px' }}
                                                                placeholder={'เลือก'}
                                                                onChange={(value) => setMoneyLand({ ...moneyLand, loanLandTerm: value })}
                                                                label="จำนวนงวด"
                                                                options={[
                                                                    { label: '12', value: 12 }, { label: 18, value: 18 }, { label: '24', value: 24 },
                                                                    { label: '30', value: 30 }, { label: 36, value: 36 }, { label: '42', value: 42 },
                                                                    { label: '48', value: 48 }, { label: 54, value: 54 }, { label: '60', value: 60 },
                                                                    { label: '66', value: 66 }, { label: 72, value: 72 }, { label: '78', value: 78 },
                                                                    { label: '84', value: 84 }, { label: 90, value: 90 }, { label: '96', value: 96 },
                                                                    { label: '102', value: 102 }, { label: 108, value: 108 }, { label: '114', value: 114 },
                                                                    { label: '120', value: 120 }
                                                                ]}
                                                            >
                                                            </Select>
                                                        </Form.Item>

                                                        <Form.Item label='งวดละ' name='monthlyPaymentLand'
                                                            style={{ textAlign: 'center', width: '300px' }}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !'
                                                                },]}>
                                                            <Input type='number' suffix="บาท" disabled
                                                                style={{ color: 'black', width: '250px', height: '40px' }}
                                                                onChange={(e) => setMoneyLand({ ...moneyLand, monthlyPaymentLand: parseInt(e.target.value) })} />
                                                        </Form.Item>


                                                        <Form.Item label='ดอกเบี้ย' name='interesttLand'
                                                            style={{ textAlign: 'center', width: '300px' }}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !'
                                                                },]}>
                                                            <Input type='number' suffix="บาท" disabled
                                                                style={{ color: 'black', width: '250px', height: '40px' }}
                                                                onChange={(e) => setMoneyLand({ ...moneyLand, interesttLand: parseInt(e.target.value) })} />
                                                        </Form.Item>


                                                        <Form.Item label='รวมราคา' name='installmentWithInterestLand'
                                                            style={{ textAlign: 'center', width: '300px' }}
                                                        >
                                                            <Input disabled suffix="บาท"
                                                                style={{ color: 'black', width: '250px', height: '40px' }}
                                                                onChange={(e) => setMoneyLand({ ...moneyLand, installmentWithInterestLand: parseInt(e.target.value) })} />
                                                        </Form.Item>

                                                        {(moneyOldLand && moneyOldLand.productLoanLandType === 'ย้ายไฟแนนซ์') ||
                                                            (moneyOldLand && moneyOldLand.productLoanLandType === 'Pre-Aaprove-ย้ายไฟแนนซ์') ?
                                                            <>
                                                                <Form.Item label='ย้ายไฟแนนซ์จาก' name='issuno'
                                                                    style={{ textAlign: 'center', width: '300px' }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please input !'
                                                                        },]}>
                                                                    <Input type='text'
                                                                        style={{ color: 'black', width: '250px', height: '40px' }}
                                                                        onChange={(e) => setMoneyOldLand({ ...moneyOldLand, issuno: e.target.value.trim() })} />
                                                                </Form.Item>
                                                                <Form.Item label='ยอดปิดไฟแนนซ์' name='oldLandLoanAmount'
                                                                    style={{ textAlign: 'center', width: '300px' }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please input !'
                                                                        },]}>

                                                                    <InputNumber
                                                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                                        size="large"
                                                                        style={{ color: 'black', width: '250px', height: '40px' }}
                                                                        onChange={(value) => checkMoneyLandSmall({ oldLandLoanAmount: value })}
                                                                    />
                                                                </Form.Item>

                                                                <Form.Item label='ค่างวด/เดือน' name='oldLandMonthlyPayment'
                                                                    style={{ textAlign: 'center', width: '300px' }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please input !'
                                                                        },]}>

                                                                    <Input
                                                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                                        size="large"
                                                                        style={{ color: 'black', width: '250px', height: '40px' }}
                                                                        onChange={(e) => setMoneyOldLand({ ...moneyOldLand, oldLandMonthlyPayment: parseInt(e.target.value) })}
                                                                    />
                                                                </Form.Item>

                                                                <Form.Item label='จำนวนงวด' name='oldLandLoanTerm'
                                                                    style={{ textAlign: 'center', width: '300px' }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please input !'
                                                                        },]}>

                                                                    <Input
                                                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                                        size="large"
                                                                        style={{ color: 'black', width: '250px', height: '40px' }}
                                                                        onChange={(e) => setMoneyOldLand({ ...moneyOldLand, oldLandLoanTerm: parseInt(e.target.value) })}
                                                                    />
                                                                </Form.Item>


                                                            </>
                                                            : null}
                                                        <Col >
                                                            <Form.Item label='ผู้เสนอเคส' name='proposalBy'
                                                                style={{ textAlign: 'center', width: '300px' }}
                                                            >
                                                                <Input disabled style={{ color: 'black', width: '250px', height: '40px' }}
                                                                    onChange={(e) => setMoneyLand({ ...moneyLand, proposalBy: e.target.value })} />
                                                            </Form.Item>
                                                        </Col>
                                                    </>
                                                )}
                                            </div>
                                        </aside>
                                    </Row>
                                </Col>

                                {dataAddCustomer?.productTypeId === 2 && (
                                    <Row justify={'center'}>
                                        <Col span={22}>
                                            <span><b style={{ fontSize: '25' }}>สตอรี่รายละเอียดที่ดิน (อธิบายข้อมูลของที่ดินแปลงนั้น / ที่นา ที่บ้าน บ้านกี่หลัง)</b></span> <p />
                                            <Form.Item name="storyLand" label="">
                                                <TextArea
                                                    name='storyLand'
                                                    style={{ color: 'blue' }}
                                                    rows={8}
                                                    onChange={(e) => setDataPostLand({ ...dataPostLand, storyLand: e.target.value.trim() })}
                                                >
                                                </TextArea>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                )
                                }

                            </Card>
                            <Row justify={'center'}>
                                <Space>
                                    <Button
                                        type="text"
                                        style={{ background: '#bfbfbf', color: '#000000', }}
                                        onClick={(e) => { handleGoHome(e) }}
                                    >
                                        ย้อนกลับ
                                    </Button>
                                    <Button type="primary" htmlType="submit">
                                        ต่อไป
                                    </Button>
                                </Space>
                            </Row>
                        </Row>
                    </Form>
                </Spin>
            </Card>
            {contextHolder}
        </Row >
    )
}
