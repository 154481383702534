import React, { useState, useEffect } from 'react'
import { Button, Form, Select, Col, Row, Input, Modal, Card, Spin, Space, Divider } from 'antd';
import axios from 'axios';
// import { PlusOutlined } from '@ant-design/icons';
// import { Link, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import Currency from 'currency.js';

import Yearpick from './../../../check_infoCar/modals/Yearpick'

import { useDispatch, useSelector } from 'react-redux';
// import { addOldLoanLand } from '../../../../redux/OldLoanLand';
import { HirePurchase } from '../../../offer_case/hire_purchase/HirePurchase';
import { Installment } from '../../../offer_case/ploan/Installment';
import { addCar } from '../../../../redux/Car';
import { addLoan } from '../../../../redux/Loan';
import { addOldLoan } from '../../../../redux/OldLoan';
// import { addImage } from '../../../../redux/Image';
import { addLand } from '../../../../redux/Land';
import { addLoanLand } from '../../../../redux/LoanLand';
import TableRateCar from '../../../offer_case/rate_car/TableRateCar';
// import Compressor from 'compressorjs';
import TextArea from 'antd/es/input/TextArea';
import { productLoanTypeCar, productLoanTypeLand } from '../../../file_mid/all_options';
import {
    AllData, GetAmphur1, GetProvince, PostProvince1, dataModel, edit, getprice, loadProvinces,
    loadimage, ratecarsmodel, deleteNewUpCar, deleteNewUpLand
} from '../../../file_mid/all_api';
import { checkREPUPCHANGE } from '../../../file_mid/condition';

export default function AddDetail({ getData, changepage, page, sendback, close, deleteData, editData, addImg, DeleteError }) {
    // const token = localStorage.getItem('token');
    // const navigate = useNavigate()
    const { confirm } = Modal;
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const { Option } = Select;
    const guarantor = useSelector(state => state.guarantor)
    const customers = useSelector(state => state.customers)
    const cars = useSelector(state => state.cars)

    const [form] = Form.useForm()
    // const { lands } = useSelector((state) => ({ ...state }))
    // const { loansLand } = useSelector((state) => ({ ...state }))
    // const { oldLoansLand } = useSelector((state) => ({ ...state }))
    const [selectProduct, setSelectProduct] = useState({}) // 1 รถ , 2 ที่ดิน
    //////////////////// ที่ดิน /////////////////////////////
    const [dataPostLand, setDataPostLand] = useState({   // ที่ดิน
        landTypeId: 2,
        productTypeLand: 2,
        landInput: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"),
        landDateStatus: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"),
        rai: 0,
        workArea: 0,
        squareWaArea: 0,
        landPrice: 0,
        resultLandPrice: 0,
    })

    // console.log("getData", getData)

    const [imageBlobzz, setImageBlobzz] = useState([]);

    const [moneyLand, setMoneyLand] = useState({}) //loan land
    const [moneyOldLand, setMoneyOldLand] = useState({}) //loanOld land
    // const [typeMoneyLand, setTypeMoneyLand] = useState({})

    const [pvcodeS, setPvcodeS] = useState({});
    const [amcodeS, setAmcodeS] = useState({});

    const [provinces, setProvinces] = useState([]);
    const [district, setDistricts] = useState([]);

    /////////////////////////////////////////////////////
    // const [dataPost1, setDataPost1] = useState({}) //customer
    const [dataPost2, setDataPost2] = useState({ productTypeCar: 1 })  //car
    const [money, setMoney] = useState({}) //loan
    const [moneyOld, setMoneyOld] = useState({}) //loanOld
    const [typeMoney, setTypeMoney] = useState({})
    const [provincesCar, setProvincesCar] = useState([]);

    ///////////////// ตาราง ///////////////////////////////
    const [openTable, setOpenTable] = useState(false); //ตารางค่างวด p-loan
    const [openTableHP, setOpenTableHP] = useState(false); //ตารางค่างวด เช่าซื้อ
    const [openTableCar, setOpenTableCar] = useState(false); //ตารางเรทรถ
    // const [count, setCount] = useState(false) //ย้ายไฟแนนซ์

    /////////////////// รถ ////////////////////////////////////
    const [resultData, setResultData] = useState([]) // API
    const [typeData, setTypeData] = useState([]) // API
    const [brandData, setBrandData] = useState([]) // API
    const [modelData, setModelData] = useState([]) // API
    const [yearPrice, setYearPrice] = useState([])
    const [typeSelected, setTypeSelected] = useState(null)
    //const [brandSelected, setBrandSelected] = useState(null)
    //const [carSelected, setCarSelected] = useState(null)

    ////////////////// select car ////////////////////////////////
    const [counterBrand, setCounterBrand] = useState(false)
    const [counterModel, setCounterModel] = useState(false)
    const [counterCarBaab, setCounterCarBaab] = useState(false)
    // const [counterModal, setCounterModal] = useState(false)
    // const [counterYear, setCounterYear] = useState(false)

    ///////////////// อัปโหลดรูป //////////////////////////////////////
    // const [previewVisible, setPreviewVisible] = useState(false);
    // const [previewImage, setPreviewImage] = useState('');
    // const [fileList, setFileList] = useState([]);
    // const [fileListBook, setFileListBook] = useState([]);
    const [fileListResult1, setFileListResult1] = useState([]);
    const [fileListResult2, setFileListResult2] = useState([]);
    // const [fileListCustomerLeafDivider, setFileListCustomerLeafDivider] = useState([]);
    // const [fileListCustomerBurden, setFileListCustomerBurden] = useState([]);
    // const [fileListCustomerLeafDivider1, setFileListCustomerLeafDivider1] = useState([]);
    // const [fileListCustomerBurden1, setFileListCustomerBurden1] = useState([]);
    //////////////// เลือก p-loan หรือ เช่าซื้อ /////////////////////
    // const [checkPCH, setCheckPCH] = useState({ PLorCH: "" })
    const [checkPCH, setCheckPCH] = useState({})
    const [checkCPC, setCheckCPC] = useState(false) // รี ปรับ เปลี่ยน

    const currentTime = dayjs();
    const delayedTime = currentTime.add(7, 'hour');
    // const formattedTime = delayedTime.format('YYYY-MM-DDTHH:mm:ssZ');

    const options = [
        {
            value: "แดง",
            label: "แดง",
        },
        {
            value: "น้ำเงิน",
            label: "น้ำเงิน",
        },
        {
            value: "เหลือง",
            label: "เหลือง",
        },
        {
            value: "ขาว",
            label: "ขาว",
        },
        {
            value: "ดำ",
            label: "ดำ",
        },
        {
            value: "ม่วง",
            label: "ม่วง",
        },
        {
            value: "เขียว",
            label: "เขียว",
        },
        {
            value: "ส้ม",
            label: "ส้ม",
        },
        {
            value: "น้ำตาล",
            label: "น้ำตาล",
        },
        {
            value: "ชมพู",
            label: "ชมพู",
        },
        {
            value: "ฟ้า",
            label: "ฟ้า",
        },
        {
            value: "เทา",
            label: "เทา",
        },
    ];

    // console.log("dataPost2", dataPost2)

    useEffect(() => {
        if (guarantor.data.typeLoan === "car") {
            if (cars.data.brand_id !== undefined) {
                setDataPost2({ ...cars.data, carYear: cars.data.carYear.toString(), carId: getData.carLoanDetails?.cars?.carId, productTypeId: 1 })
                setSelectProduct({ ...selectProduct, productTypeId: 1 })
                setMoney({ ...getData.carLoanDetails })
                setMoneyOld({ ...getData.carLoanDetails.cars.oldLoanDetails })
                setTypeData(getData?.carLoanDetails.cars?.productTypeCar)
                setTypeSelected(getData?.carLoanDetails.cars?.productTypeCar)
                loadDataBrand(getData?.carLoanDetails.cars?.productTypeCar)
                form.setFieldsValue({
                    ...getData.carLoanDetails,
                    ...getData.carLoanDetails.cars,
                    ...checkPCH,
                    ...getData.carLoanDetails.cars.oldLoanDetails,
                    ...cars.data,
                    productTypeId: 1,
                })

            } else {
                if (getData.carLoanDetails?.cars?.carId !== undefined) {
                    setDataPost2({ ...getData.carLoanDetails.cars, productTypeId: 1 })
                    setSelectProduct({ ...selectProduct, productTypeId: 1 })
                    setMoney({ ...getData.carLoanDetails })
                    setMoneyOld({ ...getData.carLoanDetails.cars.oldLoanDetails })
                    setTypeData(getData?.carLoanDetails.cars?.productTypeCar)
                    setTypeSelected(getData?.carLoanDetails.cars?.productTypeCar)
                    loadDataBrand(getData?.carLoanDetails.cars?.productTypeCar)
                    if (getData.carLoanDetails.cars.carColor === "" || getData.carLoanDetails.cars.carColor === null) {
                        delete getData.carLoanDetails.cars.carColor
                    }
                    if (getData.carLoanDetails.productLoanType === "ย้ายไฟแนนซ์" || getData.carLoanDetails.productLoanType === "Pre-Aaprove-ย้ายไฟแนนซ์") {
                        // moneyOld.productLoanType
                        setMoneyOld({ ...getData.carLoanDetails.cars.oldLoanDetails, productLoanType: getData.carLoanDetails.productLoanType })
                    }
                    setCheckPCH({ PLorCH: getData.carLoanDetails.productType })
                    form.setFieldsValue({
                        ...getData.carLoanDetails,
                        ...getData.carLoanDetails.cars.oldLoanDetails,
                        ...checkPCH,
                        ...getData.carLoanDetails.cars,
                        PLorCH: getData.carLoanDetails.productType,
                        productTypeId: 1,
                    })
                }
            }
            if (getData?.carLoanDetails?.notPass?.loanId !== 0) {
                form.setFieldsValue(
                    {
                        ...getData?.carLoanDetails?.notPass
                    }
                )
            }
            if (getData?.carLoanDetails?.noteResend?.loanId !== 0) {
                form.setFieldsValue(
                    {
                        ...getData?.carLoanDetails?.noteResend
                    }
                )
            }
            if (checkREPUPCHANGE(getData?.carLoanDetails?.productLoanType)) {
                setCheckCPC(true)
            } else {
                setCheckCPC(false)
            }
        } else if (guarantor.data.typeLoan === "land") {
            fetchAllProvinces()
            getProvinces()
            GetAmphur(getData?.landLoanDetails?.land?.district)
            PostProvince(getData?.landLoanDetails?.land?.provinces)
            if (getData?.landLoanDetails?.land?.landId !== undefined) {

                setDataPostLand({ ...getData.landLoanDetails.land })
                setMoneyLand({
                    ...getData.landLoanDetails,
                    interestLand: parseInt(getData?.landLoanDetails?.interestLand),
                    interestRateLand: parseFloat(getData?.landLoanDetails?.interestRateLand),
                    loanAmountLand: parseInt(getData?.landLoanDetails?.loanAmountLand),
                    loanLandTerm: parseInt(getData?.landLoanDetails?.loanLandTerm),
                    monthlyPaymentLand: parseInt(getData?.landLoanDetails?.monthlyPaymentLand),
                })
                setCheckPCH({ PLorCH: getData.landLoanDetails.productType })
                setSelectProduct({ ...selectProduct, productTypeId: 2 })
                // setMoneyOldLand({ ...getData.landLoanDetails.land.oldLandLoanDetails })
                if (getData.landLoanDetails.productLoanLandType === "ย้ายไฟแนนซ์" || getData.landLoanDetails.productLoanLandType === "Pre-Aaprove-ย้ายไฟแนนซ์") {
                    // moneyOld.productLoanLandType
                    setMoneyOldLand({ ...getData.landLoanDetails.land.oldLandLoanDetails, productLoanLandType: getData.landLoanDetails.productLoanLandType })
                }
                form.setFieldsValue({
                    ...getData.landLoanDetails,
                    ...getData.landLoanDetails.land.oldLandLoanDetails,
                    ...checkPCH,
                    ...getData.landLoanDetails.land,
                    productTypeId: 2,
                })
            }
            if (getData?.landLoanDetails?.notPass?.loanId !== 0) {
                form.setFieldsValue(
                    {
                        ...getData?.landLoanDetails?.notPass
                    }
                )
            }
            if (getData?.landLoanDetails?.noteResend?.loanId !== 0) {
                form.setFieldsValue(
                    {
                        ...getData?.landLoanDetails?.noteResend
                    }
                )
            }
        }
        loadImage()
        fetchAllProvinces()
    }, [cars.data])

    // console.log("getData.landLoanDetails.land", getData.landLoanDetails.land)

    useEffect(() => {
        if (money && dataPost2.productTypeCar > 1 && checkPCH.PLorCH === "เช่าซื้อ") {
            // console.log("ss")
            car_Calculate()
        }
        if (money && dataPost2.productTypeCar === 1 && checkPCH.PLorCH === "เช่าซื้อ1") {
            // console.log("ss")
            car_Calculate()
        }
        if (moneyLand && selectProduct.productTypeId === 2) {
            // console.log("XX")
            land_Calculate()
        }
        if (moneyOld && moneyOld?.productLoanType === 'ย้ายไฟแนนซ์') {
            console.log("GG")
            car_Calculate_close()
        }
        if (moneyOldLand && moneyOldLand?.productLoanLandType === 'ย้ายไฟแนนซ์') {
            console.log("ez")
            land_Calculate_close()
        }
    }, [money?.loanAmount, money?.interestRate, money?.loanTerm, money?.installmentAmountt, moneyLand?.loanAmountLand, moneyLand?.interestRateLand, moneyLand?.loanLandTerm, moneyOld?.oldLoanAmount, moneyOld?.oldMonthlyPayment, moneyOld?.oldLoanTerm, moneyOld?.oldKangPayment, moneyOldLand?.oldLandLoanAmount, moneyOldLand?.oldLandMonthlyPayment, moneyOldLand?.oldLandLoanTerm, moneyOldLand?.oldLandKangPayment])

    const car_Calculate_close = () => {
        if (moneyOld?.oldLoanAmount && moneyOld?.oldMonthlyPayment && moneyOld?.oldLoanTerm) {
            // console.log("oldLoanAmount", moneyOld.oldLoanAmount)
            // console.log("oldMonthlyPayment", moneyOld.oldMonthlyPayment)
            // console.log("oldLoanTerm", moneyOld.oldLoanTerm)
            // console.log("oldKangPayment", moneyOld.oldKangPayment)
            var loanTermKhang = moneyOld?.oldKangPayment / moneyOld?.oldMonthlyPayment
            // console.log("loanTermKhang", loanTermKhang)
            setMoneyOld({
                ...moneyOld,
                oldKangPaymentTerm: loanTermKhang,
            })
            form.setFieldsValue(
                {
                    oldKangPaymentTerm: loanTermKhang,
                }
            )
        } else {
            console.log("")
        }
    }

    const land_Calculate_close = () => {
        if (moneyOldLand?.oldLandLoanAmount && moneyOldLand?.oldLandMonthlyPayment && moneyOldLand?.oldLandLoanTerm) {
            var loanTermKhangLand = moneyOldLand?.oldLandKangPayment / moneyOldLand?.oldLandMonthlyPayment
            setMoneyOld({
                ...moneyOldLand,
                oldLandKangPaymentTerm: Currency(loanTermKhangLand, { precision: 2 }).value,
            })
            form.setFieldsValue(
                {
                    oldLandKangPaymentTerm: Currency(loanTermKhangLand, { precision: 1 }).value,
                }
            )
        } else {
            console.log("")
        }
    }


    const handleCancel = () => {
        close(false)
    }

    const onChangeBack = () => {
        if (guarantor.data.typeLoan === "car") {
            dispatch(addCar(getData.carLoanDetails.cars.carId))
        } else {

        }
        changepage(page - 1)
    }

    const onChangeGo = () => {
        if (guarantor.data.typeLoan === "car") {
            dispatch(addCar(getData.carLoanDetails.cars.carId))
        } else {

        }
        changepage(page + 1)
    }

    const handleSubmit = async () => {

        if (guarantor.data.typeLoan === "car") {
            var MixData = { ...moneyOld, ...dataPost2, ...money, ...guarantor.data }
            delete MixData.approvalStatus
        } else {
            var MixData = { ...moneyOldLand, ...dataPostLand, ...moneyLand, ...guarantor.data }
            delete MixData.landStatus
            delete MixData.landInput
            delete MixData.landDateStatus
        }
        console.log("MixData", MixData)
        await axios.put(edit, MixData)
            .then(res => {
                if (res.status === 200) {
                    if (fileListResult1.length === 0 && fileListResult2.length === 0) {
                        console.log("บ่มีรูป")
                    } else {
                        if (guarantor.data.typeLoan === "car") {
                            // uploadImageCar()
                        } else if (guarantor.data.typeLoan === "land") {
                            // uploadImageLand()
                        }
                    }
                    sendback(dayjs())
                    editData("top")
                    setTimeout(() => {
                        onChangeGo()
                    }, 1000);
                }
            }).catch(error => {
                console.error(error);
            });
    }

    const handleDeleteCar = async (e, index) => {
        const id = e.idImage
        const path = e.pathImage
        setLoading(true)
        await axios.delete(`${deleteNewUpCar}/${id}/${path}`)
            .then(res => {
                if (res.data) {
                    // console.log("WP", res.data)
                    const updatedImageBlobzz = [...imageBlobzz];
                    updatedImageBlobzz.splice(index, 1);
                    setImageBlobzz(updatedImageBlobzz);
                    // successImg()
                    deleteData("top")
                    setLoading(false)
                }
            })
            .catch((err) =>
                DeleteError("top")
                // console.log(err)
            )
        setLoading(false)
    }
    const handleDeleteLand = async (e, index) => {
        const id = e.idImage
        const path = e.pathImage
        setLoading(true)
        await axios.delete(`${deleteNewUpLand}/${id}/${path}`)
            .then(res => {
                if (res.data) {
                    // console.log("WP", res.data)
                    const updatedImageBlobzz = [...imageBlobzz];
                    updatedImageBlobzz.splice(index, 1);
                    setImageBlobzz(updatedImageBlobzz);
                    // successImg()
                    deleteData("top")
                    setLoading(false)
                }
            })
            .catch((err) =>
                DeleteError("top")
                // console.log(err)
            )
        setLoading(false)
    }

    const showConfirm = (e, index) => {
        confirm({
            title: 'คุณต้องการที่จะลบรูปภาพนี้ใช่หรือไม่...?',
            onOk() {
                // message.success('ลบรูปภาพสำเร็จ');
                if (guarantor.data.typeLoan === "car") {
                    handleDeleteCar(e, index)
                } else {
                    handleDeleteLand(e, index)
                }
            },
            onCancel() {
                // message.error('ยกเลิกการลบรูปภาพ');
            },
        });
    };


    //////////////// จังหวัด รถ /////////////////////
    const fetchAllProvinces = async () => {
        await axios.get(loadProvinces)
            .then(response => {
                setProvincesCar(response.data);
            }).catch(error => {
                console.error(error);
            });
    }

    const handleProvinceChangeCar = (value, element) => {
        setDataPost2({ ...dataPost2, carProvince: value, });
    };

    /////////////// ที่อยู่ ของ ที่ดิน //////////////////
    const getProvinces = async () => {
        // const tokens = "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IjEiLCJyZWdpc3Rlcl90eXBlX3NlcSI6IjgiLCJ1c2VyX2dyb3VwX2RhdGFfbGlzdCI6IjEsMSwwLDAsMCwwLDAsMSIsInBlcnNvbmFsX2lkIjoiIiwianRpIjoiYzg5MjliZjktOGQ2Ny00ODhkLWE0M2MtMWYyYTcyZGQ0ZmZjIiwiaWF0IjoiMDkvMjkvMjAyMyAwNDo1OTo1NCIsIm5iZiI6MTY5NTk2MzU5NCwiZXhwIjoxNjk2MDQ5OTk0LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDozMDAwMS8iLCJhdWQiOiJMYW5kc01hcHMifQ.BX_IYnEKbaNr8vvixELjaJlzllUcAGf-T7D1ZedJ7aty23GZTLjIGxO7YXrsM-yH1JUe6wLym0vdbyQ1bStSTQ"
        // const provids = pvcodeS
        // const amphurs = amcodeS
        // const landNos = dataPostLand.numberLand
        // const result = { pvcode: provids, amcode: amphurs, landNo: landNos }
        // //console.log("result =", result)
        // const headers = {
        //     // "Content-Type": "application/json",
        //     "Authorization": `Bearer ${tokens}`,
        // }
        setLoading(true)
        await axios.get(GetProvince)
            //await axios.get('https://landsmaps.dol.go.th/data/province.json')
            //await axios.get('https://landsmaps.dol.go.th/apiService/JWT/GetJWTAccessToken')
            //await axios.get('https://landsmaps.dol.go.th/apiService/LandsMaps/GetParcelByParcelNo/96/10/10001',{ headers: headers })
            .then((res) => {
                //console.log("Province =", res.data)
                setProvinces(res.data)
                setLoading(false)
            }).catch((err) => {
                console.error(err);
            })
        setLoading(false)
    }

    const PostProvince = async (value) => {
        const pr = { pvcode: value }
        //console.log("pr =", pr)
        setLoading(true)
        await axios.post(PostProvince1, pr)
            .then((res) => {
                //console.log("Province =", res.data)
                //console.log("pvcode =", res.data[0].pvcode)
                setPvcodeS(res.data[0].pvcode)
                //setProvinces(res.data)
                setLoading(false)
            }).catch((err) => {
                console.error(err);
            })
        setLoading(false)
    }

    const GetAmphur = async (value) => {
        const am = { pvcode: value }
        //console.log("am =", am)
        setLoading(true)
        await axios.post(GetAmphur1, am)
            .then((res) => {
                //console.log("Amphur =", res.data)
                setDistricts(res.data)
                setAmcodeS(res.data[0].amcode)
                setLoading(false)
            }).catch((err) => {
                console.error(err);
            })
        setLoading(false)
    }

    const handleProvinceChange = async (value, key) => {
        //console.log("Province =", key["key"], value)
        setPvcodeS(key["key"])
        setDataPostLand({ ...dataPostLand, provinces: value });
        form.setFieldsValue(
            {
                numberLand: "",
                numberLandlawang: "",
                rai: "",
                workArea: "",
                squareWaArea: "",
                landPrice: "",
                resultLandPrice: "",
                district: "",
            }
        )
        setLoading(true)
        const test = { pvcode: key["key"] }
        await axios.post(GetAmphur1, test)
            .then((res) => {
                setDistricts(res.data)
                setLoading(false)
            }).catch((err) => {
                console.error(err);
            })
        setLoading(false)
    };

    const handleDistrictChange = (value, key) => {
        //console.log("VV =", key["key"], value)
        setAmcodeS(key["key"])
        setDataPostLand({ ...dataPostLand, district: value });
        form.setFieldsValue(
            {
                numberLand: "",
                numberLandlawang: "",
                rai: "",
                workArea: "",
                squareWaArea: "",
                landPrice: "",
                resultLandPrice: "",
                // productLoanLandType: "",
                // loanAmounttLand: "",
                // loanLandTerm: "",
                // interestRateLand: "",
                // interesttLand: "",
                // monthlyPaymentLand: "",
                // installmentWithInterestLand: "",
            }
        )

    };

    ///////////////// กรณี นส.3 ///////////////////////////

    const handleProvinceChange1 = async (value, key) => {
        //console.log("Province =", key["key"], value)
        setPvcodeS(key["key"])
        setDataPostLand({ ...dataPostLand, provinces: value });
        form.setFieldsValue(
            {
                numberLandlawang: "",
                rai: "",
                workArea: "",
                squareWaArea: "",
                district: "",
            }
        )
        setLoading(true)
        const test = { pvcode: key["key"] }
        await axios.post(GetAmphur1, test)
            .then((res) => {
                setDistricts(res.data)
                setLoading(false)
            }).catch((err) => {
                console.error(err);
            })
        setLoading(false)
    };

    const handleDistrictChange2 = (value, key) => {
        //console.log("VV =", key["key"], value)
        setAmcodeS(key["key"])
        setDataPostLand({ ...dataPostLand, district: value });
        form.setFieldsValue(
            {
                numberLandlawang: "",
                rai: "",
                workArea: "",
                squareWaArea: "",
            }
        )

    };


    ////////////// ที่ดิน /////////////////////
    const handleChangeLandType = (value) => {

        if (value === 'ย้ายไฟแนนซ์' || value === 'Pre-Aaprove-ย้ายไฟแนนซ์') {
            setMoneyLand({ ...moneyLand, productLoanLandType: value, productType: "ที่ดิน" })
            setMoneyOldLand({ ...moneyOldLand, productLoanLandType: value })
            // setTypeMoneyLand({ ...typeMoney, productLoanLandType: 'ย้ายไฟแนนซ์', productType: "ที่ดิน" })
        }
        else {
            setMoneyLand({ ...moneyLand, productLoanLandType: value, productType: "ที่ดิน" })
            setMoneyOldLand({ oldLandLoanId: moneyOldLand.oldLandLoanId })
            form.setFieldsValue(
                {
                    loanAmountLand: '',
                    loanLandTerm: '',
                    interestRateLand: '',
                    interestLand: '',
                    monthlyPaymentLand: '',
                    installmentWithInterestLand: '',
                    oldLandLoanAmount: '',
                    oldLandMonthlyPayment: '',
                    oldLandLoanTerm: '',
                    oldLandKangPayment: '',
                    oldLandKangPaymentTerm: '',
                }
            )
        }
    }

    /////////////// โมดาว ค่า งวด p-loan และ แบบ เช่าซื้อ ///////////////////
    const currencyFormatOnes = (amount) => {
        return Number(amount).toFixed(0).replace(/\d(?=(\d{3})+(?!\d))/g, '$&,')
    }

    const handleMoney = (values) => {
        setMoney({
            ...money,
            interestRate: values?.interestRate,
            loanAmount: values?.loanAmountt,
            loanTerm: values?.installmentAmountt,
            interest: values?.interestt,
            monthlyPayment: values?.monthlyPayment,
            installmentWithInterest: values?.installmentWithInterestt,
            approvalStatus: values?.approvalStatus,
            productType: checkPCH.PLorCH,
            PLorCH: checkPCH.PLorCH
        })
        form.setFieldsValue(
            {
                loanAmount: values?.loanAmountt,
                loanTerm: values?.installmentAmountt,
                interest: values?.interestt,
                monthlyPayment: values?.monthlyPayment,
                installmentWithInterest: values?.installmentWithInterestt,
            }
        )
    }
    const handleMoneyHP = (values) => {
        console.log("values", values)
        setMoney({
            ...money,
            interestRate: values?.interestRate,
            loanAmount: values?.loanAmountt,
            loanTerm: values?.installmentAmountt,
            interest: values?.interestt,
            monthlyPayment: values?.monthlyPayment,
            installmentWithInterest: values?.installmentWithInterestt,
            approvalStatus: values?.approvalStatus,
            productType: checkPCH.PLorCH,
            PLorCH: checkPCH.PLorCH
        })
        form.setFieldsValue(
            {
                loanAmount: values?.loanAmountt,
                loanTerm: values?.installmentAmountt,
                interest: values?.interestt,
                monthlyPayment: values?.monthlyPayment,
                installmentWithInterest: Currency(values?.installmentWithInterestt),
            }
        )
    }
    ////////////// รถ ///////////////////
    const handleChangeType = (value) => {
        if (value === 'ย้ายไฟแนนซ์' || value === 'Pre-Aaprove-ย้ายไฟแนนซ์') {
            setMoney({ ...money, productLoanType: value, productType: checkPCH.PLorCH })
            setMoneyOld({ ...moneyOld, productLoanType: value })
            setTypeMoney({ ...typeMoney, productLoanType: 'ย้ายไฟแนนซ์', productType: checkPCH.PLorCH })
            //setTypeMoney({ ...typeMoney, productLoanType: value, approvalDate: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"), productType: "รถ" })
        }
        else {
            setMoney({ ...money, productLoanType: value, productType: checkPCH.PLorCH })
            setTypeMoney({ ...typeMoney, productLoanType: 'ถือเล่มมา', productType: checkPCH.PLorCH })
            setMoneyOld({ oldLoanId: getData.carLoanDetails.cars.oldLoanDetails.oldLoanId })
            form.setFieldsValue(
                {
                    oldKangPayment: '',
                    oldKangPaymentTerm: '',
                    oldLoanAmount: '',
                    oldLoanTerm: '',
                    oldMonthlyPayment: '',
                }
            )
        }
    }
    /////////////// เลือก p-loan หรือ เช่าซื้อ ///////////////////
    const handleChangePLorCH = (value) => {
        if (value === "p-loan") {
            setCheckPCH({ PLorCH: value })
            setMoney({
                ...money,
                loanAmount: "",
                loanTerm: "",
                interest: "",
                interestRate: "",
                monthlyPayment: "",
                installmentWithInterest: "",
                PLorCH: value,
                productType: value
            })
            form.setFieldsValue(
                {
                    loanAmount: "",
                    loanTerm: "",
                    interest: "",
                    interestRate: "",
                    monthlyPayment: "",
                    installmentWithInterest: "",
                }
            )
        } else if (value === "เช่าซื้อ1") {
            setCheckPCH({ PLorCH: value })
            setMoney({
                ...money,
                loanAmount: "",
                loanTerm: "",
                interest: "",
                interestRate: "",
                monthlyPayment: "",
                installmentWithInterest: "",
                PLorCH: value,
                productType: value
            })
            form.setFieldsValue(
                {
                    loanAmount: "",
                    loanTerm: "",
                    interest: "",
                    interestRate: "",
                    monthlyPayment: "",
                    installmentWithInterest: "",
                }
            )
        } else {
            setCheckPCH({ PLorCH: value })
            setMoney({
                ...money,
                loanAmount: "",
                loanTerm: "",
                interest: "",
                interestRate: "",
                monthlyPayment: "",
                installmentWithInterest: "",
                PLorCH: value,
                productType: value
            })
            form.setFieldsValue(
                {
                    loanAmount: "",
                    loanTerm: "",
                    interest: "",
                    interestRate: "",
                    monthlyPayment: "",
                    installmentWithInterest: "",
                }
            )
        }
    }


    //////////// กรณีไม่มีรถ /////////////////////
    const AddBrand = () => {
        setCounterBrand(true)
        setCounterModel(true)
        setCounterCarBaab(true)
        setDataPost2({ ...dataPost2, carPrice: "0", carPriceStatus: false })
        form.setFieldsValue(
            {
                carBrand: '',
                carModel: '',
                carBaab: '',
                carYear: '',
                carPrice: 0,
            }
        )
    }
    const AddBrands = () => {
        setCounterBrand(false)
        setCounterModel(false)
        setCounterCarBaab(false)
        setDataPost2({ ...dataPost2, carPrice: "0", carPriceStatus: false })
        form.setFieldsValue(
            {
                carBrand: '',
                carModel: '',
                carBaab: '',
                carYear: '',
                carPrice: 0,
            }
        )
    }
    //////////// กรณีไม่มีรถ /////////////////////
    const AddModel = () => {
        setCounterModel(true)
        setCounterCarBaab(true)
        setDataPost2({ ...dataPost2, carPrice: "0", carPriceStatus: false })
        form.setFieldsValue(
            {
                carModel: '',
                carBaab: '',
                carYear: '',
                carPrice: 0,
            }
        )
    }
    const AddModels = () => {
        setCounterModel(false)
        setCounterCarBaab(false)
        setDataPost2({ ...dataPost2, carPrice: "0", carPriceStatus: false })
        form.setFieldsValue(
            {
                carModel: '',
                carBaab: '',
                carYear: '',
                carPrice: 0,
            }
        )
    }
    //////////// กรณีไม่มีรถ /////////////////////
    const AddCarBaab = () => {
        setCounterCarBaab(true)
        setDataPost2({ ...dataPost2, carPrice: "0", carPriceStatus: false })
        form.setFieldsValue(
            {
                carBaab: '',
                carYear: '',
                carPrice: 0,
            }
        )
    }
    const AddCarBaabs = () => {
        setCounterCarBaab(false)
        setDataPost2({ ...dataPost2, carPrice: "0", carPriceStatus: false })
        form.setFieldsValue(
            {
                carBaab: '',
                carYear: '',
                carPrice: 0,
            }
        )
    }
    const onYeehor = (e) => {
        //console.log("carBrand",e.target.value)
        setDataPost2({ ...dataPost2, carBrand: e.target.value })
        //setCarSelected(e)
    }


    const onMoChange = (e) => {
        //console.log("Modal",e.target.value)
        setDataPost2({ ...dataPost2, carModel: e.target.value })
        //setCarSelected(e)
    }
    // const AddModal = () => {
    //     setCounterModal(true)
    // }
    const onDeChange = (e) => {
        //console.log("Baab",e.target.value)
        setDataPost2({ ...dataPost2, carBaab: e.target.value })
    }
    // const AddYear = () => {
    //     setCounterYear(true)
    // }
    // const onReChange = (e) => {
    //     //console.log("Year",e.target.value)
    //     setDataPost2({ ...dataPost2, carYear: parseInt(e.target.value) })
    // }

    ////// ตัวอย่างรถ กับ ตารางค่างวด ////////
    const getTable = () => {
        setOpenTable(true)
    }
    const getTableHP = () => {
        setOpenTableHP(true)
    }
    const getTableCar = () => {
        setOpenTableCar(true)
    }
    /////////////////////////////////////

    const loadDataBrand = async (value) => { //รถยนต์ รถเครื่องจักร รถบรรทุก
        setLoading(true)
        await axios.get(`https://shark-app-j9jc9.ondigitalocean.app/ratecars/brand/${value}`)
            .then((res) => {
                setBrandData(res.data)
                setLoading(false)
                // form.setFieldsValue(
                //     {
                //         carBrand: "",
                //         carModel: "",
                //         carBaab: "",
                //         carYear: "",
                //         carPrice: 0
                //     }
                // )
            })
            .catch((err) => console.log(err))
        setLoading(false)
    }

    const loadDataModel = async (value) => {
        setLoading(true)
        await axios.get(dataModel + value)
            .then((res) => {
                setModelData(res.data)
                setLoading(false)
            })
            .catch((err) => console.log(err))
        setLoading(false)
    }
    const getResult = async (value) => {
        setLoading(true)
        const result = await axios.get(ratecarsmodel,
            { params: { typeId: typeSelected, modelId: value }, })
        setResultData(result.data)
        setLoading(false)
    }

    const onTypeChange = (value) => {
        //console.log("ชนิด =", value)
        // if(value === 2 || value === 3) {
        //     form.setFieldsValue(
        //         {
        //             carBrand: "",
        //             carModel: "",
        //             carBaab: "",
        //             carYear: "",
        //             carPrice: 0
        //         }
        //     )
        // }
        setDataPost2({ ...dataPost2, productTypeCar: value, carYear: "0", carPrice: 0, carPriceStatus: false, carInput: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"), carDateStatus: dayjs().format("YYYY-MM-DDTHH:mm:ssZ") })
        setTypeData(value)
        setTypeSelected(value)
        loadDataBrand(value)
        form.setFieldsValue(
            {
                carProvince: "",
                carPlateNumber: "",
                loanAmount: "",
                loanTerm: "",
                interest: "",
                interestRate: "",
                monthlyPayment: "",
                installmentWithInterest: "",
                oldLoanAmount: "",
                oldMonthlyPayment: "",
                oldLoanTerm: "",
                oldKangPayment: "",
                oldKangPaymentTerm: ""
            }
        )
    }
    const onBrandChange = (value) => {
        //console.log("BRAND", value)
        setDataPost2({ ...dataPost2, carBrand: brandData.find((a) => a.idbrand === value).brand })
        //setBrandSelected(value)
        loadDataModel(value)
    }
    const onModelChange = (value) => {
        setCounterBrand(false)
        //console.log("model", value)
        setDataPost2({ ...dataPost2, carModel: modelData.find((a) => a.idmodel_te === value).model })
        //setCarSelected(value)
        getResult(value)
    }
    const onDetailChange = (value) => {
        //setCounterModal(false)
        setDataPost2({ ...dataPost2, carBaab: resultData.find((a) => a.idmodel === value).models })
        //console.log('type_id',typeData)
        //console.log('idmodel',value)
        getPrice(typeData, value)
    }
    const getPrice = async (typeId, carId) => {
        setLoading(true)
        //console.log('typeid', typeId)
        //console.log('idmodel', carId)
        await axios.get(getprice, { params: { typeId, carId }, })
            .then((res) => {
                if (res.data) {
                    //console.log("price",res.data)
                    setYearPrice(res.data)
                    setLoading(false)
                }
            })
            .catch((err) => alert('ไม่พบข้อมูล ราคารถ'))
        setLoading(false)
    }
    const onResult = (k, v) => {
        //setCounterYear(false)
        //console.log("KK", k)
        //console.log("VV", v)
        const pYear = parseInt(v.key)
        if (k) {
            setDataPost2({ ...dataPost2, carPriceStatus: true, carPrice: k, carYear: pYear.toString(), carInput: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"), carDateStatus: dayjs().format("YYYY-MM-DDTHH:mm:ssZ") })
            // parseInt(dataPost2.carYear)
            form.setFieldsValue({
                carPrice: v.value
            })

        }
    }
    /////////////////// เลือก 1 = นส.3 กับ 2 = นส.4 //////////////////////
    const handleProductLand = (value) => {
        console.log("landTypeId =", value)
        if (value === 1) {
            setDataPostLand({
                ...dataPostLand,
                landTypeId: value,
                productTypeLand: value,
                numberLand: "-",
                rai: 0,
                workArea: 0,
                squareWaArea: 0,
                landPrice: 0,
                resultLandPrice: 0,
            })
            setFileListResult2([]);
            setFileListResult1([]);
            form.setFieldsValue(
                {
                    numberLand: "",
                    numberLandlawang: "",
                    rai: "",
                    workArea: "",
                    squareWaArea: "",
                    landPrice: "",
                    resultLandPrice: "",
                    provinces: "",
                    district: "",
                }
            )
        } else {
            setDataPostLand({
                ...dataPostLand,
                landTypeId: value,
                productTypeLand: value,
                numberLand: "-",
                rai: 0,
                workArea: 0,
                squareWaArea: 0,
                landPrice: 0,
                resultLandPrice: 0,
            })
            setFileListResult2([]);
            setFileListResult1([]);
            form.setFieldsValue(
                {
                    numberLand: "",
                    numberLandlawang: "",
                    rai: "",
                    workArea: "",
                    squareWaArea: "",
                    landPrice: "",
                    resultLandPrice: "",
                    provinces: "",
                    district: "",
                }
            )
        }
    }

    /////////////////// เลือก 1 รถ, 2 ที่ดิน ///////////////////////
    const handleProduct = (value) => {
        //console.log("VV =", value)
        setSelectProduct({ ...selectProduct, productTypeId: value })
        if (value === 1) {
            dispatch(addLand())
            dispatch(addLoanLand())
            setCheckPCH({ PLorCH: "p-loan" })
            setDataPost2({ ...dataPost2, productTypeId: value, productTypeCar: 1 })
            setDataPostLand({})
            setMoneyLand({})
            setMoneyOldLand({})
            // setTypeMoneyLand({})
            form.setFieldsValue(
                {
                    PLorCH: "p-loan",
                    numberLand: "",
                    numberLandlawang: "",
                    rai: "",
                    workArea: "",
                    squareWaArea: "",
                    landPrice: "",
                    resultLandPrice: "",
                    provinces: "",
                    district: "",
                    subdistricts: "",
                    zipcode: "",
                    productLoanLandType: "",
                    loanAmountLand: "",
                    loanLandTerm: "",
                    interestRateLand: "",
                    interestLand: "",
                    monthlyPaymentLand: "",
                    installmentWithInterestLand: "",
                }
            )
        } else {
            getProvinces()
            dispatch(addCar())
            dispatch(addLoan())
            dispatch(addOldLoan())
            setDataPostLand({
                ...dataPostLand,
                productTypeId: value,
                productTypeLand: 2,
                landInput: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"),
                landDateStatus: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"),
                rai: 0,
                workArea: 0,
                squareWaArea: 0,
                landPrice: 0,
                resultLandPrice: 0
            })
            setDataPost2({})
            setMoneyOld({})
            setTypeMoney({})
            form.setFieldsValue(
                {
                    PLorCH: "",
                    productTypeCar: "",
                    carBrand: "",
                    carModel: "",
                    carBaab: "",
                    carYear: "",
                    carPrice: 0,
                    carTy: "",
                    carProvince: "",
                    carPlateNumber: "",
                    productLoanType: "",
                    loanAmount: "",
                    loanTerm: "",
                    interest: "",
                    monthlyPayment: "",
                    installmentWithInterest: "",
                    oldLoanAmount: "",
                    oldMonthlyPayment: "",
                    oldLoanTerm: "",
                    oldKangPayment: "",
                    oldKangPaymentTerm: ""
                }
            )
        }
    }

    ///////////////// อัปโหลดรูปภาพ //////////////////////////////
    // const handleChange = async ({ fileList }) => {
    //     //console.log("fileList", fileList)
    //     setFileList(fileList);
    //     var imageList = []
    //     for (const file of fileList) {
    //         var image = {}
    //         var filename = file.name
    //         var image64 = await getBase64(file.originFileObj)
    //         image.filename = filename
    //         image.image64 = image64
    //         image.type = 1
    //         imageList.push(image)
    //     }
    //     setFileListResult1({ ...fileListResult1, imageList });
    //     //dispatch(addImage(imageList))
    // };
    // const handleChangeBook = async ({ fileList }) => {
    //     //console.log("fileListBook", fileList)
    //     setFileListBook(fileList);
    //     var imageListBook = []
    //     for (const file of fileList) {
    //         var image = {}
    //         var filename = file.name
    //         var image64 = await getBase64(file.originFileObj)
    //         image.filename = filename
    //         image.image64 = image64
    //         image.type = 2
    //         imageListBook.push(image)
    //     }
    //     setFileListResult2({ ...fileListResult2, imageListBook });
    //     //dispatch(addImage(imageListBook))
    // };

    // const handleChangeDeed = async ({ fileList }) => {
    //     setFileListBook(fileList);
    //     var imageListBook = []
    //     for (const file of fileList) {
    //         var image = {}
    //         var filename = file.name
    //         var image64 = await getBase64(file.originFileObj)
    //         image.filename = filename
    //         image.image64 = image64
    //         image.type = 3
    //         imageListBook.push(image)
    //     }
    //     setFileListResult2({ ...fileListResult2, imageListBook });
    //     //dispatch(addImage(imageListBook))
    // };

    // const handleChangeCustomerLeafDivider = async ({ fileList }) => {
    //     setFileListCustomerLeafDivider(fileList);
    //     var imageListCustomerLeafDivider = []

    //     const compressedFileList = await Promise.all(
    //         fileList.map(async (file) => {
    //             // Compress the image
    //             const compressedFile = await new Promise((resolve) => {
    //                 new Compressor(file.originFileObj, {
    //                     quality: 0.6, // เช่น, 60%
    //                     success: (result) => {
    //                         resolve(result);
    //                     },
    //                 });
    //             });
    //             return {
    //                 ...file,
    //                 originFileObj: compressedFile,
    //             };
    //         })
    //     );
    //     var i = 0
    //     for (const file of fileList) {
    //         var image = {}
    //         var filename = file.name
    //         var image64 = await getBase64(compressedFileList[i].originFileObj)
    //         image.filename = filename
    //         image.image64 = image64
    //         image.type = 10
    //         imageListCustomerLeafDivider.push(image)
    //         i++
    //     }
    //     setFileListCustomerLeafDivider1({ ...fileListCustomerLeafDivider1, imageListCustomerLeafDivider });
    // };
    // const handleChangeCustomerBurden = async ({ fileList }) => {
    //     setFileListCustomerBurden(fileList);
    //     var imageListCustomerBurden = []

    //     const compressedFileList = await Promise.all(
    //         fileList.map(async (file) => {
    //             // Compress the image
    //             const compressedFile = await new Promise((resolve) => {
    //                 new Compressor(file.originFileObj, {
    //                     quality: 0.6, // เช่น, 60%
    //                     success: (result) => {
    //                         resolve(result);
    //                     },
    //                 });
    //             });
    //             return {
    //                 ...file,
    //                 originFileObj: compressedFile,
    //             };
    //         })
    //     );
    //     var i = 0
    //     for (const file of fileList) {
    //         var image = {}
    //         var filename = file.name
    //         var image64 = await getBase64(compressedFileList[i].originFileObj)
    //         image.filename = filename
    //         image.image64 = image64
    //         image.type = 11
    //         imageListCustomerBurden.push(image)
    //         i++
    //     }
    //     setFileListCustomerBurden1({ ...fileListCustomerBurden1, imageListCustomerBurden });
    // };


    // const handleCancelImg = () => {
    //     setPreviewVisible(false);
    // }
    // const handlePreview = async (file) => {
    //     if (!file.url && !file.preview) {
    //         file.preview = await getBase64(file.originFileObj);
    //     }

    //     setPreviewImage(file.url || file.preview);
    //     setPreviewVisible(true);
    // };
    // const getBase64 = (file) => {
    //     return new Promise((resolve, reject) => {
    //         const reader = new FileReader();
    //         reader.readAsDataURL(file);
    //         reader.onload = () => resolve(reader.result);
    //         reader.onerror = (error) => reject(error);
    //     });
    // };

    const errorLand = () => {
        Modal.error({
            title: 'ไม่พบข้อมูล',
            content: 'กรุณาตรวจสอบข้อมูลที่กรอกอีกครั้ง',
        })
    }

    const handleCheckLand = async () => {
        console.log("pvcodeS =", pvcodeS)
        console.log("amcodeS =", amcodeS)
        console.log("dataPostLand.numberLand =", dataPostLand.numberLand)
        //const tokens = "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IjEiLCJyZWdpc3Rlcl90eXBlX3NlcSI6IjgiLCJ1c2VyX2dyb3VwX2RhdGFfbGlzdCI6IjEsMSwwLDAsMCwwLDAsMSIsInBlcnNvbmFsX2lkIjoiIiwianRpIjoiOTllYmE2OTAtMGFmOC00M2NhLTg2OTktYWUwZDI4N2ZjY2EzIiwiaWF0IjoiMDkvMjcvMjAyMyAwMzoyMDoxNSIsIm5iZiI6MTY5NTc4NDgxNSwiZXhwIjoxNjk1ODcxMjE1LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDozMDAwMS8iLCJhdWQiOiJMYW5kc01hcHMifQ.Lw3h4tjSbzjscWqkuA9FutwzXPY8UEb49D4ZdsdqKRfR0fwFunY7i4pg5Rdc70Vrw8jDR1qhtQLn5SEqxPguoQ"
        var provids = pvcodeS
        var amphurs = amcodeS
        var landNos = dataPostLand.numberLand
        var result = { pvcode: provids, amcode: amphurs, landNo: landNos }
        //console.log("result =", result)
        const headers = {
            "Content-Type": "application/json",
            // "Authorization": `Bearer ${tokens}`,
        }
        setLoading(true)
        await axios.post(AllData, result, { headers: headers })
            .then((response) => {
                if (response.data) {
                    // console.log("DATA =", response.data.result)
                    // console.log("ไร่ =", parseInt(response.data.result[0].rai))
                    // console.log("งาน =", parseInt(response.data.result[0].ngan))
                    // console.log("ตารางวา =", parseInt(response.data.result[0].wa + "." + response.data.result[0].subwa))
                    // console.log("จังหวัด =", response.data.result[0].provname)
                    // console.log("อำเภอ =", response.data.result[0].amphurname)
                    // console.log("ละติจูด =", response.data.result[0].parcellat)
                    // console.log("ลองติจูด =", response.data.result[0].parcellon)
                    // console.log("เลขระวาง =", response.data.result[0].utm)
                    // console.log("ราคาต่อตารางวา =", parseInt(response.data.result[0].landprice.replace(/,/g, "")))
                    const squareWaArea = parseFloat(response.data.result[0].wa + "." + response.data.result[0].subwa)
                    const landPricePerWa = parseInt(response.data.result[0].landprice.replace(/,/g, ""))
                    const totalWa = (
                        ((parseInt(response.data?.result[0].rai) * 400)) +
                        ((parseInt(response.data?.result[0].ngan) * 100)) +
                        parseFloat(response.data?.result[0].wa + "." + response.data?.result[0].subwa)
                    )
                    const totalPrice = Currency(totalWa * landPricePerWa).value
                    //console.log("รวมตารางวาทั้งหมด =", totalPrice.toFixed(1));
                    setDataPostLand({
                        ...dataPostLand,
                        subdistrict: response.data?.result[0].tumbolname,
                        numberLandlawang: response.data?.result[0].utm,
                        rai: parseInt(response.data?.result[0].rai),
                        workArea: parseInt(response.data?.result[0].ngan),
                        squareWaArea: squareWaArea,
                        landPrice: parseInt(response.data.result[0].landprice.replace(/,/g, "")) || 0,
                        resultLandPrice: totalPrice || 0
                    });
                    form.setFieldsValue(
                        {
                            numberLandlawang: response.data?.result[0].utm,
                            rai: parseInt(response.data?.result[0].rai),
                            workArea: parseInt(response.data?.result[0].ngan),
                            squareWaArea: squareWaArea,
                            landPrice: parseInt(response.data.result[0].landprice.replace(/,/g, "")) || 0,
                            resultLandPrice: totalPrice || 0,
                        }
                    )
                    setLoading(false)
                } else {
                    console.log("DATA =", response.data)
                }
            })
            .catch((err) => errorLand())
            .finally(() => {
                setLoading(false);
            })
        setLoading(false)
    }

    const land_Calculate = () => {
        if (moneyLand.loanAmountLand && moneyLand.interestRateLand && moneyLand.loanLandTerm) {
            var interestRateLand = parseFloat(moneyLand.interestRateLand) / 100 // อัตราดอก / 100
            var rate = (parseFloat(moneyLand.loanAmountLand) * Currency(interestRateLand, { precision: 3 }).value) * parseInt(moneyLand.loanLandTerm) // (ยอดจัด * อัตราดอกที่หาร100แล้ว ,precision: 3 คือทศนิยม 3 )*งวด
            var loanTerm = Math.ceil(Currency((rate + parseFloat(moneyLand.loanAmountLand)) / parseInt(moneyLand.loanLandTerm), { precision: 2 }).value) // (ดอกเบี้ยทั้งหมด + ยอดจัด)/ งวด
            var resultRate = (loanTerm * parseInt(moneyLand.loanLandTerm)) - moneyLand.loanAmountLand
            var resultPrice = loanTerm * parseInt(moneyLand.loanLandTerm)
            setMoneyLand({
                ...moneyLand,
                //loanLandTerm: parseInt(event.target.value), //งวด
                monthlyPaymentLand: loanTerm,
                interestLand: resultRate,
                installmentWithInterestLand: resultPrice
            })
            form.setFieldsValue(
                {
                    monthlyPaymentLand: loanTerm,
                    interestLand: resultRate,
                    installmentWithInterestLand: resultPrice
                }
            )
        }
        else {
            form.setFieldsValue(
                {
                    monthlyPaymentLand: 0,
                    interestLand: 0,
                    installmentWithInterestLand: 0
                }
            )
        }
    }

    // console.log("dataPost2", dataPost2)
    // console.log("money", money)
    // console.log("money.productLoanType", money?.productLoanType)


    const car_Calculate = () => {
        if (money.loanAmount && money.interestRate && money.loanTerm) {
            var interestRateCar = parseFloat(money.interestRate) / 100 // อัตราดอก / 100
            var rateCar = ((parseFloat(money.loanAmount) * Currency(interestRateCar, { precision: 5 }).value) * parseInt(money.loanTerm)) // (ยอดจัด * อัตราดอกที่หาร100แล้ว ,precision: 3 คือทศนิยม 3 )*งวด
            var loanTermCar = Math.ceil(Currency((rateCar + parseFloat(money.loanAmount)) / parseInt(money.loanTerm), { precision: 2 }).value) // (ดอกเบี้ยทั้งหมด + ยอดจัด)/ งวด
            var newLoanTermCar = Math.ceil(Currency(loanTermCar * 0.07)) + loanTermCar // งวดละใหม่ = ( งวดละ * 0.07 ) + งวดละ
            var resultRateCar = (newLoanTermCar * parseInt(money.loanTerm)) - parseFloat(money.loanAmount) // งวดละใหม่ * งวด - ยอดจัด
            var newResultPriceCar = newLoanTermCar * parseInt(money.loanTerm) // งวดละใหม่ * งวด
            setMoney({
                ...money,
                monthlyPayment: newLoanTermCar,
                interest: resultRateCar,
                installmentWithInterest: newResultPriceCar
            })
            form.setFieldsValue(
                {
                    monthlyPayment: newLoanTermCar,
                    interest: resultRateCar,
                    installmentWithInterest: newResultPriceCar
                }
            )
        }
        else {
            form.setFieldsValue(
                {
                    monthlyPayment: 0,
                    interest: 0,
                    installmentWithInterest: 0
                }
            )
        }
    }

    const loadImage = async () => {
        if (guarantor.data.typeLoan === "car") {
            await axios.post(loadimage, { carId: getData.carLoanDetails.cars.carId, typeLoan: guarantor.data.typeLoan })
                .then(res => {
                    if (res.status === 200) {
                        setImageBlobzz(res.data)
                    } else if (res.status === 204) {

                    }
                }).catch(err => {
                    console.log(err)
                })
        } else {
            await axios.post(loadimage, { landId: getData.landLoanDetails.land.landId, typeLoan: guarantor.data.typeLoan })
                .then(res => {
                    if (res.status === 200) {
                        setImageBlobzz(res.data)
                    } else if (res.status === 204) {

                    }
                }).catch(err => {
                    console.log(err)
                })
        }
    }

    const currencyFormatOne = (amount) => {
        return Number(amount)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }

    // console.log("getData?.carLoanDetails?.noteResend", getData?.carLoanDetails?.noteResend.productNote)
    // console.log("getData?.carLoanDetails?.otherNote", getData?.carLoanDetails?.noteResend.otherNote)
    // console.log("getData?.landLoanDetails?.noteResend", getData?.landLoanDetails?.noteResend.productNote)

    return (
        <Row justify={'center'}>
            <Card style={{ width: 900 }}>
                <Spin spinning={loading} size='large' tip=" Loading... ">
                    <Row style={{ textAlign: 'center', marginTop: '30px' }} >
                        <Col span={24} style={{ fontSize: '30px' }} >รายละเอียด</Col>
                    </Row>
                    <Form
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}
                        style={{
                            maxWidth: 900,
                        }}
                        form={form}
                        onFinish={handleSubmit}
                        autoComplete="off"
                        initialValues={{ remember: true }}
                    >
                        <Row gutter={32}>
                            <Col span={24}>
                                {
                                    guarantor.data.typeLoan === "car" ?
                                        <Row>
                                            {
                                                getData?.carLoanDetails?.notPass?.loanId !== 0 ?
                                                    <>
                                                        <Col span={12}>
                                                            {
                                                                getData?.carLoanDetails?.notPass.npProduct === false ?
                                                                    // <>
                                                                    <Form.Item label='หมายเหตุ(ธุรการ)' name='npProductNote' style={{ textAlign: 'center' }}>
                                                                        <TextArea rows={5} name='npProductNote' disabled />
                                                                    </Form.Item>
                                                                    //  </>
                                                                    : null
                                                            }
                                                            {
                                                                getData?.carLoanDetails?.notPass.npLoan === false ?
                                                                    // <>
                                                                    <Form.Item label='หมายเหตุ(ธุรการ)' name='npLoanNote' style={{ textAlign: 'center' }}>
                                                                        <TextArea rows={5} name='npLoanNote' disabled />
                                                                    </Form.Item>
                                                                    // </>
                                                                    : null
                                                            }
                                                            {
                                                                getData?.carLoanDetails?.notPass.npOldLoan === false ?
                                                                    // <>
                                                                    <Form.Item label='หมายเหตุ(ธุรการ)' name='npOldLoanNote' style={{ textAlign: 'center' }}>
                                                                        <TextArea rows={5} name='npOldLoanNote' disabled />
                                                                    </Form.Item>
                                                                    // </>
                                                                    : null
                                                            }
                                                        </Col>
                                                    </>
                                                    : null
                                            }
                                            {
                                                getData?.carLoanDetails?.noteResend?.loanId !== 0 ?
                                                    <>
                                                        <Col span={12}>
                                                            {
                                                                getData?.carLoanDetails?.noteResend.productNote !== null ?
                                                                    <>
                                                                        <Form.Item label='หมายเหตุ(การตลาด)' name='productNote' style={{ textAlign: 'center' }}>
                                                                            <TextArea rows={5} name='productNote' disabled />
                                                                        </Form.Item>
                                                                    </>
                                                                    : null
                                                            }
                                                            {/* {
                                                                getData?.carLoanDetails?.noteResend.otherNote !== null ?
                                                                    <>
                                                                        <Form.Item label='เอกสารอื่นๆ(การตลาด)' name='otherNote' style={{ textAlign: 'center' }}>
                                                                            <TextArea rows={5} name='otherNote' disabled />
                                                                        </Form.Item>
                                                                    </>
                                                                    : null
                                                            } */}
                                                        </Col>
                                                    </>
                                                    : null
                                            }
                                        </Row>
                                        :
                                        <Row>
                                            {
                                                getData?.landLoanDetails?.notPass?.loanId !== 0 ?
                                                    <>
                                                        <Col span={12}>
                                                            {
                                                                getData?.landLoanDetails?.notPass.npProduct === false ?
                                                                    <>
                                                                        <Form.Item label='หมายเหตุ(ธุรการ)' name='npProductNote' style={{ textAlign: 'center' }}>
                                                                            <TextArea rows={5} name='npProductNote' disabled />
                                                                        </Form.Item>
                                                                    </>
                                                                    : null
                                                            }
                                                            {
                                                                getData?.landLoanDetails?.notPass.npLoan === false ?
                                                                    <>
                                                                        <Form.Item label='หมายเหตุ(ธุรการ)' name='npLoanNote' style={{ textAlign: 'center' }}>
                                                                            <TextArea rows={5} name='npLoanNote' disabled />
                                                                        </Form.Item>
                                                                    </>
                                                                    : null
                                                            }
                                                            {
                                                                getData?.landLoanDetails?.notPass.npOldLoan === false ?
                                                                    <>
                                                                        <Form.Item label='หมายเหตุ(ธุรการ)' name='npOldLoanNote' style={{ textAlign: 'center' }}>
                                                                            <TextArea rows={5} name='npOldLoanNote' disabled />
                                                                        </Form.Item>

                                                                    </>
                                                                    : null
                                                            }
                                                        </Col>
                                                    </>
                                                    : null
                                            }
                                            {
                                                getData?.landLoanDetails?.noteResend?.loanId !== 0 ?
                                                    <>
                                                        <Col span={12}>
                                                            {
                                                                getData?.landLoanDetails?.noteResend.productNote !== null ?
                                                                    <>
                                                                        <Form.Item label='หมายเหตุ(การตลาด)' name='productNote' style={{ textAlign: 'center' }}>
                                                                            <TextArea rows={5} name='productNote' disabled />
                                                                        </Form.Item>
                                                                    </>
                                                                    : null
                                                            }
                                                            {/* {
                                                                getData?.landLoanDetails?.noteResend.otherNote !== null ?
                                                                    <>
                                                                        <Form.Item label='เอกสารอื่นๆ(การตลาด)' name='otherNote' style={{ textAlign: 'center' }}>
                                                                            <TextArea rows={5} name='otherNote' disabled />
                                                                        </Form.Item>
                                                                    </>
                                                                    : null
                                                            } */}
                                                        </Col>
                                                    </>
                                                    : null
                                            }
                                        </Row>
                                }
                            </Col>
                            <Divider />
                            <Col className="gutter-row" span={12}>
                                <Form.Item label='กลุ่มสินค้า' name='productTypeId'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !'
                                        },]}>
                                    <Select placeholder={'เลือก'} disabled
                                        onChange={(value) => handleProduct(value)}
                                    //onChange={(value) => setDataPost2({ ...dataPost2, productTypeId: value })}
                                    >
                                        <Option value={1}>รถ</Option>
                                        <Option value={2}>ที่ดิน</Option>
                                    </Select>
                                </Form.Item>

                                {/* รถ */}

                                {customers.data && selectProduct?.productTypeId !== 2 && (
                                    <>
                                        <Form.Item label='เรทรถ'>
                                            <Button type='primary' onClick={getTableCar} >ดู</Button>
                                            {
                                                openTableCar ?
                                                    <TableRateCar open={openTableCar} close={setOpenTableCar} productTypeCar={dataPost2?.productTypeCar} />
                                                    : null
                                            }
                                        </Form.Item>

                                        <Form.Item label='ชนิดรถ' name='productTypeCar'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input !'
                                                },]}>
                                            <Select placeholder={'เลือก'} allowClear
                                                onChange={onTypeChange}>
                                                <Option value={1}>รถยนต์</Option>
                                                <Option value={2}>รถเครื่องจักการเกษตร</Option>
                                                <Option value={3}>รถบรรทุก</Option>
                                            </Select>
                                        </Form.Item>
                                        <Row gutter={32}>
                                            <Col className='gutter-row' span={22}>
                                                <Form.Item label='ยี่ห้อ' name='carBrand' style={{ lineHeight: '32px' }}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    {!counterBrand ? (
                                                        <Select
                                                            showSearch
                                                            onChange={onBrandChange}
                                                            optionFilterProp="children"
                                                            placeholder="Search to Select"
                                                            filterOption={(inputValue, option) =>
                                                                option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                                                            }
                                                        >
                                                            {brandData?.map((e) => {
                                                                return (
                                                                    <Select.Option key={e.idbrand} value={e.idbrand}>
                                                                        {e.brand} ({e.brand_th})
                                                                    </Select.Option>
                                                                )
                                                            })}
                                                        </Select>
                                                    ) : (<>

                                                        {counterBrand ? (<Input onChange={onYeehor} />) : (<Select
                                                            showSearch
                                                            onChange={onBrandChange}
                                                            placeholder="Search to Select"
                                                            optionFilterProp="children"
                                                            filterOption={
                                                                (inputValue, option) =>
                                                                    option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                                                            }
                                                        >
                                                            {modelData?.map((e) => {
                                                                return (
                                                                    <Select.Option key={e.idmodel_te} value={e.idmodel_te}>
                                                                        {e.model} {e.model_te ? e.model_te : null}
                                                                    </Select.Option>
                                                                )
                                                            })}
                                                        </Select>)
                                                        }
                                                    </>

                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col className='gutter-row' span={1} style={{ textAlign: 'center' }}>
                                                {counterBrand === false && (
                                                    <Button onClick={AddBrand} style={{ height: '33px', lineHeight: '15px' }}>
                                                        +
                                                    </Button>)
                                                }
                                                {counterBrand === true && (
                                                    <Button onClick={AddBrands} >
                                                        -
                                                    </Button>
                                                )}
                                            </Col>
                                        </Row>
                                        <Row gutter={32}>
                                            <Col className='gutter-row' span={22}>
                                                <Form.Item label='รุ่นรถ' name='carModel'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    {counterBrand ? ( //ไม่จริงเข้าตรงนี้
                                                        <>
                                                            {!counterModel ? (
                                                                <Select //ไม่จริงเข้าตรงนี้
                                                                    showSearch
                                                                    onChange={onModelChange}
                                                                    placeholder="Search to Select"
                                                                    optionFilterProp="children"
                                                                    filterOption={(inputValue, option) =>
                                                                        option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                                                                    }
                                                                >
                                                                    {modelData?.map((e) => {
                                                                        return (
                                                                            <Select.Option key={e.idmodel_te} value={e.idmodel_te}>
                                                                                {e.model} {e.model_te ? e.model_te : null}
                                                                            </Select.Option>
                                                                        )
                                                                    })}
                                                                </Select>
                                                            ) : (

                                                                <Input onChange={onMoChange} />
                                                            )}

                                                        </>
                                                    ) : (<>

                                                        {counterModel ? (<Input onChange={onMoChange} />) : (<Select
                                                            showSearch
                                                            onChange={onModelChange}
                                                            placeholder="Search to Select"
                                                            optionFilterProp="children"
                                                            filterOption={
                                                                (inputValue, option) =>
                                                                    option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                                                            }
                                                        >
                                                            {modelData?.map((e) => {
                                                                return (
                                                                    <Select.Option key={e.idmodel_te} value={e.idmodel_te}>
                                                                        {e.model} {e.model_te ? e.model_te : null}
                                                                    </Select.Option>
                                                                )
                                                            })}
                                                        </Select>)
                                                        }
                                                    </>
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col className='gutter-row' span={1} style={{ textAlign: 'center' }}>
                                                {counterModel === false && (
                                                    <Button onClick={AddModel} style={{ height: '33px', lineHeight: '15px' }}>
                                                        +
                                                    </Button>)
                                                }
                                                {counterModel === true && (
                                                    <Button onClick={AddModels} >
                                                        -
                                                    </Button>
                                                )}
                                            </Col>
                                        </Row>
                                        <Row gutter={32}>
                                            <Col className='gutter-row' span={22}>
                                                <Form.Item label='แบบรถ' name='carBaab'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    {counterBrand ? (
                                                        <>
                                                            {!counterCarBaab ? (
                                                                <Select
                                                                    showSearch
                                                                    onChange={(value) => { onDetailChange(value) }}
                                                                    placeholder="Search to Select"

                                                                >
                                                                    {resultData?.map((e) => {
                                                                        return (
                                                                            <Select.Option key={e.idmodel} value={e.idmodel}>
                                                                                {e.models} {e.idmodel ? e.idmodel : null}
                                                                            </Select.Option>
                                                                        )
                                                                    })}
                                                                </Select>
                                                            ) : (

                                                                <Input onChange={onDeChange} />
                                                            )}
                                                        </>
                                                    ) : (<>

                                                        {counterCarBaab ? (<Input onChange={onDeChange} />) : (<Select
                                                            showSearch
                                                            onChange={(value) => { onDetailChange(value) }}
                                                            placeholder="Search to Select"
                                                        >
                                                            {resultData?.map((e) => {
                                                                return (
                                                                    <Select.Option key={e.idmodel} value={e.idmodel}>
                                                                        {e.models} {e.idmodel ? e.idmodel : null}
                                                                    </Select.Option>
                                                                )
                                                            })}
                                                        </Select>)
                                                        }
                                                    </>
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col className='gutter-row' span={1} style={{ textAlign: 'center' }}>
                                                {counterCarBaab === false && (
                                                    <Button onClick={AddCarBaab} style={{ height: '33px', lineHeight: '15px' }}>
                                                        +
                                                    </Button>)
                                                }
                                                {counterCarBaab === true && (
                                                    <Button onClick={AddCarBaabs} >
                                                        -
                                                    </Button>
                                                )}
                                            </Col>
                                        </Row>
                                        <Form.Item label='ปีรถ' name='carYear'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input !'
                                                },]}>
                                            {counterBrand ? (
                                                <>
                                                    {!counterCarBaab ? (
                                                        <Select
                                                            onChange={(k, v) => (onResult(k, v))}
                                                        >
                                                            {Object.entries(yearPrice).map(([k, v]) => {
                                                                if (k.charAt(0) === 'p' && v) {
                                                                    const year = k.replace('p', '')
                                                                    return (
                                                                        <Select.Option key={year} value={v * 1000}>
                                                                            {year}
                                                                        </Select.Option>
                                                                    )
                                                                }
                                                            })}
                                                        </Select>
                                                    ) : (
                                                        // <Input onChange={onReChange} />
                                                        <Yearpick
                                                            picker="year"
                                                            // name="birthDate"
                                                            onChange={(e, string) => {
                                                                if (e) {
                                                                    let year = e.$y
                                                                    let yearstr = year.toString();
                                                                    //let yearstr = year.toString();
                                                                    // setDataModalEdit({ ...dataModalEdit, expdt: yearstr })
                                                                    setDataPost2({ ...dataPost2, carYear: yearstr })
                                                                }
                                                            }} />
                                                    )}

                                                </>
                                            )
                                                :
                                                (
                                                    <>
                                                        {counterCarBaab ? (
                                                            // <Input onChange={onReChange} />
                                                            <Yearpick
                                                                picker="year"
                                                                // name="birthDate"
                                                                onChange={(e, string) => {
                                                                    if (e) {
                                                                        let year = e.$y
                                                                        //let yearstr = year.toString();
                                                                        // setDataModalEdit({ ...dataModalEdit, expdt: yearstr })
                                                                        setDataPost2({ ...dataPost2, carYear: year })
                                                                    }
                                                                }} />
                                                        ) : (
                                                            <Select
                                                                onChange={(k, v) => (onResult(k, v))}
                                                            >
                                                                {Object.entries(yearPrice).map(([k, v]) => {
                                                                    if (k.charAt(0) === 'p' && v) {
                                                                        const year = k.replace('p', '')
                                                                        return (
                                                                            <Select.Option key={year} value={v * 1000}>
                                                                                {year}
                                                                            </Select.Option>
                                                                        )
                                                                    }
                                                                })}
                                                            </Select>
                                                        )}
                                                    </>

                                                )}
                                        </Form.Item>

                                        {/* <Form.Item label='ยี่ห้อ' name='carBrand'>
                                            <Select
                                                showSearch
                                                onChange={onBrandChange}
                                                disabled={typeSelected ? false : true}
                                                optionFilterProp="children"
                                                placeholder="Search to Select"
                                                filterOption={(inputValue, option) =>
                                                    option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                                                }
                                            >
                                                {brandData?.map((e) => {
                                                    return (
                                                        <Select.Option key={e.idbrand} value={e.idbrand}>
                                                            {e.brand} ({e.brand_th})
                                                        </Select.Option>
                                                    )
                                                })}
                                            </Select>
                                        </Form.Item> */}

                                        {/* {counterBrand === false && (
                                            <Form.Item label='เพิ่ม'>
                                                <Button onClick={AddBrand} >+</Button>
                                            </Form.Item>
                                        )}
                                        {counterBrand === true && (
                                            <Form.Item label='ยกเลิก'>
                                                <Button onClick={AddBrands} >-</Button>
                                            </Form.Item>
                                        )} */}

                                        {/* <Form.Item label='รุ่นรถ' name='carModel'>
                                            {counterBrand === false && (
                                                <Select
                                                    showSearch
                                                    onChange={onModelChange}
                                                    placeholder="Search to Select"
                                                    disabled={typeSelected ? false : true}
                                                    optionFilterProp="children"
                                                    filterOption={(inputValue, option) =>
                                                        option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                                                    }
                                                >
                                                    {modelData?.map((e) => {
                                                        return (
                                                            <Select.Option key={e.idmodel_te} value={e.idmodel_te}>
                                                                {e.model} {e.model_te ? e.model_te : null}
                                                            </Select.Option>
                                                        )
                                                    })}
                                                </Select>
                                            )}
                                            {counterBrand === true && (
                                                <Input disabled={typeSelected ? false : true}
                                                    onChange={onMoChange} />
                                            )}
                                        </Form.Item> */}

                                        {/* <Form.Item label='แบบรถ' name='carBaab'>
                                            {counterBrand === false && (
                                                <Select
                                                    onChange={(value) => { onDetailChange(value) }}
                                                    disabled={typeSelected ? false : true}
                                                    placeholder="Search to Select"
                                                >
                                                    {resultData.map((e) => {
                                                        return (
                                                            <Select.Option key={e.idmodel} value={e.idmodel}>
                                                                {e.models} {e.idmodel ? e.idmodel : null}
                                                            </Select.Option>
                                                        )
                                                    })}
                                                </Select>
                                            )}
                                            {counterBrand === true && (
                                                <Input disabled={typeSelected ? false : true}
                                                    onChange={onDeChange} />
                                            )}
                                        </Form.Item> */}

                                        {/* <Form.Item label='ปีรถ' name='carYear'>
                                            {counterBrand === false && (
                                                <Select
                                                    onChange={(k, v) => (onResult(k, v))}
                                                    disabled={typeSelected ? false : true}
                                                >
                                                    {Object.entries(yearPrice).map(([k, v]) => {
                                                        if (k.charAt(0) === 'p' && v) {
                                                            const year = k.replace('p', '')
                                                            return (
                                                                <Select.Option key={year} value={v * 1000}>
                                                                    {year}
                                                                </Select.Option>
                                                            )
                                                        }
                                                    })}
                                                </Select>
                                            )}
                                            {counterBrand === true && (
                                                <Input disabled={typeSelected ? false : true}
                                                    onChange={onReChange} />
                                            )}
                                        </Form.Item> */}


                                        <Form.Item label='ราคา' name='carPrice'>
                                            <Input disabled defaultValue={0}
                                                onChange={(e) => setDataPost2({ ...dataPost2, carPrice: e.value })} />
                                        </Form.Item>

                                        <Form.Item label='จังหวัด' name='carProvince'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input !',
                                                },]}>
                                            <Select
                                                showSearch
                                                name='carProvince'
                                                placeholder='จังหวัด'
                                                onChange={(key, value) => { handleProvinceChangeCar(key, value) }}
                                            >
                                                {provincesCar?.map((pro, index) => (
                                                    <Option key={pro.provinceId} value={pro.provinceName}>
                                                        {pro.provinceName}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>

                                        <Form.Item label='เลขทะเบียน' name='carPlateNumber'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input !',
                                                },]}>
                                            <Input
                                                onChange={(e) => setDataPost2({ ...dataPost2, carPlateNumber: e.target.value })} />
                                        </Form.Item>
                                        <Form.Item label='เลขคัสซี' name='carChassisNumber'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input !',
                                                },]}>
                                            <Input disabled={checkCPC} onChange={(e) => setDataPost2({ ...dataPost2, carChassisNumber: e.target.value })} />
                                        </Form.Item>
                                        <Form.Item label='เลขเครื่อง' name='carEngineNumber'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input !',
                                                },]}>
                                            <Input disabled={checkCPC} onChange={(e) => setDataPost2({ ...dataPost2, carEngineNumber: e.target.value })} />
                                        </Form.Item>
                                        <Form.Item label='สี' name='carColor'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input !',
                                                },]}>
                                            <Select
                                                mode="multiple"
                                                style={{
                                                    width: '100%',
                                                }}
                                                size="large"
                                                placeholder="เลือกหมายเหตุ"
                                                onChange={(e) => setDataPost2({ ...dataPost2, carColor: `${e}` })}
                                                // onChange={handleChange}
                                                options={options}
                                            />

                                        </Form.Item>


                                    </>
                                )}
                                {/* ที่ดิน */}

                                {customers.data && selectProduct?.productTypeId === 2 && (
                                    <>
                                        <Form.Item label='ประเภทเอกสาร' name='productTypeLand'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input !'
                                                },]}>
                                            <Select placeholder={'เลือก'}
                                                disabled
                                                onChange={(value) => handleProductLand(value)}
                                            //onChange={(value) => setDataPost2({ ...dataPost2, productTypeId: value })}
                                            >
                                                <Option value={1}>นส.3</Option>
                                                <Option value={2}>นส.4</Option>
                                            </Select>
                                        </Form.Item>

                                        {selectProduct && dataPostLand?.productTypeLand === 1 && (
                                            <>
                                                <Form.Item name='provinces' label='จังหวัด'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <Select
                                                        disabled
                                                        showSearch
                                                        loading={loading}
                                                        name='provinces'
                                                        placeholder="จังหวัด"
                                                        onChange={handleProvinceChange1}
                                                    >
                                                        {provinces?.result?.map((pro, index) => (
                                                            <Option key={pro.pvcode} value={pro.pvnamethai}>
                                                                {pro.pvnamethai}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>

                                                <Form.Item name='district' label='อำเภอ'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <Select
                                                        disabled
                                                        showSearch
                                                        loading={loading}
                                                        name='district'
                                                        placeholder="อำเภอ"
                                                        onChange={handleDistrictChange2}
                                                    >
                                                        {district?.map((dis, index) => (
                                                            <Option key={dis.amcode} value={dis.amnamethai}>
                                                                {dis.amnamethai}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>

                                                <Form.Item label='เลขโฉนด' name='numberLand'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <Input type='text' disabled
                                                        onChange={(e) => setDataPostLand({ ...dataPostLand, numberLand: e.target.value })}></Input>
                                                </Form.Item>
                                                {/* <Form.Item label='เลขระวาง' name='numberLandlawang'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <Input type='text'
                                                        onChange={(e) => setDataPostLand({ ...dataPostLand, numberLandlawang: e.target.value })}></Input>
                                                </Form.Item> */}

                                                <Form.Item label='พื้นที่ไร่' name='rai'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <Input type='number' suffix="ไร่" disabled
                                                        onChange={(e) => setDataPostLand({ ...dataPostLand, rai: parseInt(e.target.value) })}></Input>
                                                </Form.Item>

                                                <Form.Item label='พื้นที่งาน' name='workArea'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <Input type='number' suffix="งาน" disabled
                                                        onChange={(e) => setDataPostLand({ ...dataPostLand, workArea: parseInt(e.target.value) })}></Input>
                                                </Form.Item>

                                                <Form.Item label='พื้นที่ตารางวา' name='squareWaArea'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <Input type='number' suffix="ตารางวา" disabled
                                                        onChange={(e) => setDataPostLand({ ...dataPostLand, squareWaArea: parseInt(e.target.value) })}></Input>
                                                </Form.Item>
                                            </>
                                        )}
                                        {selectProduct && dataPostLand?.productTypeLand === 2 && (
                                            <>
                                                <Form.Item name='provinces' label='จังหวัด'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <Select
                                                        disabled
                                                        showSearch
                                                        loading={loading}
                                                        name='provinces'
                                                        placeholder="จังหวัด"
                                                        onChange={handleProvinceChange}
                                                    >
                                                        {provinces?.result?.map((pro, index) => (
                                                            <Option key={pro.pvcode} value={pro.pvnamethai}>
                                                                {pro.pvnamethai}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>

                                                <Form.Item name='district' label='อำเภอ'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <Select
                                                        disabled
                                                        showSearch
                                                        loading={loading}
                                                        name='district'
                                                        placeholder="อำเภอ"
                                                        onChange={handleDistrictChange}
                                                    >
                                                        {district?.map((dis, index) => (
                                                            <Option key={dis.amcode} value={dis.amnamethai}>
                                                                {dis.amnamethai}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item label='เลขโฉนด' name='numberLand'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <Input type='text' disabled
                                                        onChange={(e) => setDataPostLand({ ...dataPostLand, numberLand: e.target.value })}></Input>
                                                </Form.Item>
                                                <Form.Item label='ตรวจสอบ'>
                                                    <Button type="primary" disabled onClick={handleCheckLand}>ตรวจสอบ</Button>
                                                </Form.Item>

                                                <Form.Item label='เลขที่ดินหรือระวาง' name='numberLandlawang'>
                                                    <Input type='text' disabled
                                                        onChange={(e) => setDataPostLand({ ...dataPostLand, numberLandlawang: e.target.value })}></Input>
                                                </Form.Item>



                                                <Form.Item label='พื้นที่ไร่' name='rai'>
                                                    <Input type='text' suffix="ไร่" disabled
                                                        onChange={(e) => setDataPostLand({ ...dataPostLand, rai: parseInt(e.target.value) })}></Input>
                                                </Form.Item>

                                                <Form.Item label='พื้นที่งาน' name='workArea'>
                                                    <Input type='text' suffix="งาน" disabled
                                                        onChange={(e) => setDataPostLand({ ...dataPostLand, workArea: parseInt(e.target.value) })}></Input>
                                                </Form.Item>

                                                <Form.Item label='พื้นที่ตารางวา' name='squareWaArea'>
                                                    <Input type='text' suffix="ตารางวา" disabled
                                                        onChange={(e) => setDataPostLand({ ...dataPostLand, squareWaArea: parseInt(e.target.value) })}></Input>
                                                </Form.Item>

                                                <Form.Item label='ราคาประเมินที่ดิน' name='landPrice'>
                                                    <Input type='text' suffix="บาทต่อตารางวา" disabled
                                                        onChange={(e) => setDataPostLand({ ...dataPostLand, landPrice: parseInt(e.target.value) })}></Input>
                                                </Form.Item>

                                                <Form.Item label='ราคารวม' name='resultLandPrice'>
                                                    <Input type='text' suffix="บาท" disabled
                                                        onChange={(e) => setDataPostLand({ ...dataPostLand, resultLandPrice: parseInt(e.target.value) })}></Input>
                                                </Form.Item>

                                            </>
                                        )}
                                    </>
                                )}
                            </Col>

                            <Col className="gutter-row" span={12}>

                                {/* โลนรถ */}

                                {customers.data && selectProduct?.productTypeId !== 2 && (
                                    <>
                                        <Form.Item label='ประเภทขอกู้รถ' name='productLoanType'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input !',
                                                },]}>
                                            <Select
                                                placeholder={'เลือก'}
                                                onChange={(value) => { handleChangeType(value) }}
                                                options={productLoanTypeCar}
                                            />
                                        </Form.Item>

                                        {/* <Form.Item label='ประเภทค่างวด' name='PLorCH'>
                                            <Select
                                                placeholder={'เลือก'}
                                                onChange={(value) => { handleChangePLorCH(value) }}
                                                options={[
                                                    {
                                                        label: 'P-LOAN',
                                                        value: 'p-loan',
                                                    },
                                                    {
                                                        label: 'เช่าซื้อ',
                                                        value: 'เช่าซื้อ',
                                                    },
                                                    {
                                                        label: 'เช่าซื้อ (0.84)-(0.75)',
                                                        value: 'เช่าซื้อ1',
                                                    }
                                                ]}
                                            />
                                        </Form.Item> */}

                                        {
                                            dataPost2.productTypeCar === 1 ?
                                                <Form.Item label='ประเภทค่างวด' name='PLorCH'>
                                                    <Select
                                                        placeholder={'เลือก'}
                                                        // style={{ width: '240px', height: '40px' }}
                                                        onChange={(value) => { handleChangePLorCH(value) }}
                                                        options={[
                                                            {
                                                                label: 'P-LOAN',
                                                                value: 'p-loan',
                                                            },
                                                            {
                                                                label: 'เช่าซื้อ',
                                                                value: 'เช่าซื้อ',
                                                            },
                                                            {
                                                                label: 'เช่าซื้อ(0.84)-(0.75)',
                                                                value: 'เช่าซื้อ1',
                                                            }
                                                        ]}
                                                    />
                                                </Form.Item>
                                                :
                                                <Form.Item label='ประเภทค่างวด' name='PLorCH'>
                                                    <Select
                                                        placeholder={'เลือก'}
                                                        onChange={(value) => { handleChangePLorCH(value) }}
                                                        options={[
                                                            {
                                                                label: 'P-LOAN',
                                                                value: 'p-loan',
                                                            },
                                                            {
                                                                label: 'เช่าซื้อ',
                                                                value: 'เช่าซื้อ',
                                                            },
                                                        ]}
                                                    />
                                                </Form.Item>
                                        }

                                        {/* P-LOAN */}
                                        {checkPCH && typeof checkPCH.PLorCH !== 'undefined' && (checkPCH.PLorCH === "p-loan") ? (
                                            <>
                                                <Form.Item label='P-LOAN'>
                                                    <Button type="primary" onClick={getTable}>
                                                        ตารางค่างวด P-LOAN
                                                    </Button>
                                                    {
                                                        openTable ?
                                                            <Installment open={openTable} close={setOpenTable} money={handleMoney} />
                                                            : null
                                                    }
                                                </Form.Item>

                                                <Form.Item label='ยอดจัด' name='loanAmount'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <Input disabled style={{ color: 'black' }} suffix="บาท"
                                                        onChange={(e) => setMoney({ ...money, loanAmount: parseInt(e.target.value) })} />
                                                </Form.Item>

                                                <Form.Item label='จำนวนงวด' name='loanTerm'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <Input disabled style={{ color: 'black' }} suffix="งวด"
                                                        onChange={(e) => setMoney({ ...money, loanTerm: e.target.value })} />
                                                </Form.Item>

                                                <Form.Item label='งวดละ' name='monthlyPayment'>
                                                    <Input disabled style={{ color: 'black' }} suffix="บาท"
                                                        onChange={(e) => setMoney({ ...money, monthlyPayment: e.target.value })} />
                                                </Form.Item>

                                                <Form.Item label='ดอกเบี้ย' name='interest'>
                                                    <Input disabled style={{ color: 'black' }} suffix="บาท"
                                                        onChange={(e) => setMoney({ ...money, interest: e.target.value })} />
                                                </Form.Item>

                                                <Form.Item label='รวมราคา' name='installmentWithInterest'>
                                                    <Input disabled style={{ color: 'black' }} suffix="บาท"
                                                        defaultValue={currencyFormatOne(~~getData?.carLoanDetails?.monthlyPayment * ~~getData?.carLoanDetails?.loanTerm)}
                                                        onChange={(e) => setMoney({ ...money, installmentWithInterest: e.target.value })} />
                                                </Form.Item>
                                            </>
                                        ) : null}

                                        {/* เช่าซื้อ */}

                                        {dataPost2 && typeof dataPost2.productTypeCar !== 'undefined' && (dataPost2.productTypeCar === 1 && checkPCH.PLorCH === "เช่าซื้อ") ? (
                                            <>
                                                <Form.Item label='เช่าซื้อ'>
                                                    <Button type="primary" onClick={getTableHP}>
                                                        ตารางค่างวด เช่าซื้อ
                                                    </Button>
                                                    {
                                                        openTableHP ?
                                                            <HirePurchase open={openTableHP} close={setOpenTableHP} money={handleMoneyHP} />
                                                            : null
                                                    }
                                                </Form.Item>

                                                <Form.Item label='ยอดจัด' name='loanAmount'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !',
                                                        },]}>
                                                    <Input disabled style={{ color: 'black' }} suffix="บาท"
                                                        onChange={(e) => setMoney({ ...money, loanAmount: parseInt(e.target.value) })} />
                                                </Form.Item>

                                                <Form.Item label='จำนวนงวด' name='loanTerm'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !',
                                                        },]}>
                                                    <Input disabled style={{ color: 'black' }} suffix="งวด"
                                                        onChange={(e) => setMoney({ ...money, loanTerm: e.target.value })} />
                                                </Form.Item>

                                                <Form.Item label='งวดละ' name='monthlyPayment'>
                                                    <Input disabled style={{ color: 'black' }} suffix="บาท"
                                                        onChange={(e) => setMoney({ ...money, monthlyPayment: e.target.value })} />
                                                </Form.Item>

                                                <Form.Item label='ดอกเบี้ย' name='interest'>
                                                    <Input disabled style={{ color: 'black' }} suffix="บาท"
                                                        onChange={(e) => setMoney({ ...money, interest: e.target.value })} />
                                                </Form.Item>

                                                <Form.Item label='ราคารวม' name='installmentWithInterest'>
                                                    <Input disabled style={{ color: 'black' }} suffix="บาท"
                                                        defaultValue={currencyFormatOne(~~getData?.carLoanDetails?.loanAmount + ~~getData?.carLoanDetails?.interest)}
                                                        onChange={(e) => setMoney({ ...money, installmentWithInterest: e.target.value })} />
                                                </Form.Item>
                                            </>
                                        ) : dataPost2.productTypeCar === 1 && checkPCH.PLorCH === "เช่าซื้อ1" ?
                                            <>
                                                <Form.Item label='ยอดจัด' name='loanAmount'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <Input type='number' suffix="บาท"
                                                        onChange={(e) => setMoney({ ...money, loanAmount: parseInt(e.target.value) })} />
                                                </Form.Item>

                                                <Form.Item label='อัตราดอกเบี้ย' name='interestRate'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <Select
                                                        placeholder={'เลือกอัตราดอกเบี้ย'}
                                                        style={{ height: '40px' }}
                                                        // onChange={(value) => { handleChangePLorCH(value) }}
                                                        onChange={(e) => setMoney({ ...money, interestRate: parseFloat(e) })}
                                                        options={[
                                                            {
                                                                label: '0.84%',
                                                                value: 0.84,
                                                            },
                                                            {
                                                                label: '0.75%',
                                                                value: 0.75,
                                                            },
                                                        ]}
                                                    />
                                                    {/* <Input type='number' suffix="% +VAT"
                                                        onChange={(e) => setMoney({ ...money, interestRate: parseFloat(e.target.value) })} /> */}
                                                </Form.Item>

                                                <Form.Item label='จำนวนงวด' name='loanTerm'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <Input type='number' suffix="งวด"
                                                        onChange={(e) => setMoney({ ...money, loanTerm: parseInt(e.target.value) })} />
                                                    {/* onChange={(e) => { car_Calculate(e) }} /> */}
                                                </Form.Item>

                                                <Form.Item label='งวดละ' name='monthlyPayment'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <Input type='number' name='monthlyPayment' suffix="บาท" disabled style={{ color: 'black' }}
                                                        onChange={(e) => setMoney({ ...money, monthlyPayment: parseInt(e.target.value) })} />
                                                </Form.Item>

                                                <Form.Item label='ดอกเบี้ย' name='interest'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <Input type='number' suffix="บาท" disabled style={{ color: 'black' }}
                                                        onChange={(e) => setMoney({ ...money, interest: parseInt(e.target.value) })} />
                                                </Form.Item>

                                                <Form.Item label='รวมราคา' name='installmentWithInterest'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <Input type='number' suffix="บาท" disabled style={{ color: 'black' }}
                                                        defaultValue={currencyFormatOne(~~getData?.carLoanDetails?.monthlyPayment * ~~getData?.carLoanDetails?.loanTerm)}
                                                        onChange={(e) => setMoney({ ...money, installmentWithInterest: parseInt(e.target.value) })} />
                                                </Form.Item>
                                            </>
                                            : null
                                        }

                                        {dataPost2 && typeof dataPost2.productTypeCar !== 'undefined' && (dataPost2.productTypeCar === 2 && checkPCH.PLorCH === "เช่าซื้อ") ? (
                                            <>
                                                <Form.Item label='ยอดจัด' name='loanAmount'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <Input type='number' suffix="บาท" name="loanAmount"
                                                        onChange={(e) => setMoney({ ...money, loanAmount: parseInt(e.target.value) })} />
                                                    {/* onChange={(e) => { car_Calculate(e) }} /> */}
                                                </Form.Item>

                                                <Form.Item label='อัตราดอกเบี้ย' name='interestRate'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <Input type='number' suffix="% +VAT" name="interestRate"
                                                        onChange={(e) => setMoney({ ...money, interestRate: parseFloat(e.target.value) })} />
                                                    {/* onChange={(e) => { car_Calculate(e) }} /> */}
                                                </Form.Item>

                                                <Form.Item label='จำนวนงวด' name='loanTerm'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <Input type='number' suffix="งวด" name="loanTerm"
                                                        onChange={(e) => setMoney({ ...money, loanTerm: parseInt(e.target.value) })} />
                                                    {/* onChange={(e) => { car_Calculate(e) }} /> */}
                                                </Form.Item>

                                                <Form.Item label='งวดละ' name='monthlyPayment'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <Input type='number' suffix="บาท" disabled style={{ color: 'black' }}
                                                        onChange={(e) => setMoney({ ...money, monthlyPayment: parseInt(e.target.value) })} />
                                                </Form.Item>

                                                <Form.Item label='ดอกเบี้ย' name='interest'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <Input type='number' suffix="บาท" disabled style={{ color: 'black' }}
                                                        onChange={(e) => setMoney({ ...money, interest: parseInt(e.target.value) })} />
                                                </Form.Item>

                                                <Form.Item label='รวมราคา' name='installmentWithInterest'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <Input type='number' suffix="บาท" disabled style={{ color: 'black' }}
                                                        defaultValue={currencyFormatOne(~~getData?.carLoanDetails?.monthlyPayment * ~~getData?.carLoanDetails?.loanTerm)}
                                                        onChange={(e) => setMoney({ ...money, installmentWithInterest: parseInt(e.target.value) })} />
                                                </Form.Item>
                                            </>
                                        ) : null}

                                        {dataPost2 && typeof dataPost2.productTypeCar !== 'undefined' && (dataPost2.productTypeCar === 3 && checkPCH.PLorCH === "เช่าซื้อ") ? (
                                            <>
                                                <Form.Item label='ยอดจัด' name='loanAmount'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <Input type='number' suffix="บาท"
                                                        onChange={(e) => setMoney({ ...money, loanAmount: parseInt(e.target.value) })} />
                                                </Form.Item>

                                                <Form.Item label='อัตราดอกเบี้ย' name='interestRate'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <Input type='number' suffix="% +VAT"
                                                        onChange={(e) => setMoney({ ...money, interestRate: parseFloat(e.target.value) })} />
                                                </Form.Item>

                                                <Form.Item label='จำนวนงวด' name='loanTerm'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <Input type='number' suffix="งวด"
                                                        onChange={(e) => setMoney({ ...money, loanTerm: parseInt(e.target.value) })} />
                                                    {/* onChange={(e) => { car_Calculate(e) }} /> */}
                                                </Form.Item>

                                                <Form.Item label='งวดละ' name='monthlyPayment'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <Input type='number' suffix="บาท" disabled style={{ color: 'black' }}
                                                        onChange={(e) => setMoney({ ...money, monthlyPayment: parseInt(e.target.value) })} />
                                                </Form.Item>

                                                <Form.Item label='ดอกเบี้ย' name='interest'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <Input type='number' suffix="บาท" disabled style={{ color: 'black' }}
                                                        onChange={(e) => setMoney({ ...money, interest: parseInt(e.target.value) })} />
                                                </Form.Item>

                                                <Form.Item label='รวมราคา' name='installmentWithInterest'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <Input type='number' suffix="บาท" disabled style={{ color: 'black' }}
                                                        defaultValue={currencyFormatOne(~~getData?.carLoanDetails?.monthlyPayment * ~~getData?.carLoanDetails?.loanTerm)}
                                                        onChange={(e) => setMoney({ ...money, installmentWithInterest: parseInt(e.target.value) })} />
                                                </Form.Item>
                                            </>
                                        ) : null}

                                        {(moneyOld && moneyOld.productLoanType === 'ย้ายไฟแนนซ์') || (moneyOld && moneyOld.productLoanType === 'Pre-Aaprove-ย้ายไฟแนนซ์') ? (
                                            <>
                                                <Form.Item label='ปิดไฟแนนซ์จาก' name='issuno'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <Input type='text'
                                                        style={{ color: 'black' }}
                                                        onChange={(e) => setMoneyOld({ ...moneyOld, issuno: e.target.value })} />
                                                </Form.Item>
                                                <Form.Item label='ยอดปิดไฟแนนซ์' name='oldLoanAmount'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <Input type='number' suffix="บาท"
                                                        onChange={(e) => setMoneyOld({ ...moneyOld, oldLoanAmount: parseInt(e.target.value) })} />
                                                </Form.Item>

                                                <Form.Item label='ค่างวด/เดือน' name='oldMonthlyPayment'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <Input type='number' suffix="บาท"
                                                        onChange={(e) => setMoneyOld({ ...moneyOld, oldMonthlyPayment: parseInt(e.target.value) })} />
                                                </Form.Item>

                                                <Form.Item label='จำนวนผ่อนกี่งวด' name='oldLoanTerm'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <Input type='number' suffix="งวด"
                                                        onChange={(e) => setMoneyOld({ ...moneyOld, oldLoanTerm: parseInt(e.target.value) })} />
                                                </Form.Item>
                                            </>
                                        ) : null}
                                    </>
                                )}
                                {/* โลนที่ดิน */}

                                {customers.data && selectProduct?.productTypeId === 2 && (
                                    <>
                                        <Form.Item label='ประเภทขอกู้ที่ดิน' name='productLoanLandType'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input !',
                                                },]}>
                                            <Select
                                                placeholder={'เลือก'}
                                                onChange={(value) => { handleChangeLandType(value) }}
                                                options={productLoanTypeLand}
                                            />
                                        </Form.Item>

                                        <Form.Item label='ยอดจัด' name='loanAmountLand'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input !'
                                                },]}>
                                            <Input
                                                type='number' suffix="บาท"
                                                onChange={(e) => setMoneyLand({ ...moneyLand, loanAmountLand: parseInt(e.target.value) })} />
                                        </Form.Item>


                                        <Form.Item label='อัตราดอกเบี้ย' name='interestRateLand'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input !'
                                                },]}>
                                            <Input type='number' suffix="% +VAT"
                                                onChange={(e) => setMoneyLand({ ...moneyLand, interestRateLand: parseFloat(e.target.value) })} />
                                        </Form.Item>

                                        <Form.Item label='จำนวนงวด' name='loanLandTerm'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input !'
                                                },]}>
                                            <Input type='number' suffix="งวด"
                                                onChange={(e) => setMoneyLand({ ...moneyLand, loanLandTerm: parseInt(e.target.value) })} />
                                        </Form.Item>

                                        <Form.Item label='งวดละ' name='monthlyPaymentLand'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input !'
                                                },]}>
                                            <Input type='number' suffix="บาท" disabled style={{ color: 'black' }}
                                                onChange={(e) => setMoneyLand({ ...moneyLand, monthlyPaymentLand: parseInt(e.target.value) })} />
                                        </Form.Item>

                                        <Form.Item label='ดอกเบี้ย' name='interestLand'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input !'
                                                },]}>
                                            <Input type='number' suffix="บาท" disabled style={{ color: 'black' }}
                                                onChange={(e) => setMoneyLand({ ...moneyLand, interestLand: parseInt(e.target.value) })} />
                                        </Form.Item>


                                        <Form.Item label='รวมราคา' name='installmentWithInterestLand'>
                                            <Input disabled style={{ color: 'black' }} suffix="บาท"
                                                defaultValue={currencyFormatOne(~~getData?.landLoanDetails?.interestLand * ~~getData?.landLoanDetails?.monthlyPaymentLand)}
                                                onChange={(e) => setMoney({ ...money, installmentWithInterestLand: parseInt(e.target.value) })} />
                                        </Form.Item>

                                        {(moneyOldLand && moneyOldLand?.productLoanLandType === 'ย้ายไฟแนนซ์') || (moneyOldLand && moneyOldLand?.productLoanLandType === 'Pre-Aaprove-ย้ายไฟแนนซ์') ? (
                                            <>
                                                <Form.Item label='ย้ายไฟแนนซ์จาก' name='issuno'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <Input type='text'
                                                        style={{ color: 'black' }}
                                                        onChange={(e) => setMoneyOldLand({ ...moneyOldLand, issuno: e.target.value })} />
                                                </Form.Item>
                                                <Form.Item label='ยอดปิดไฟแนนซ์ที่ดิน' name='oldLandLoanAmount'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <Input type='number' suffix="บาท"
                                                        onChange={(e) => setMoneyOldLand({ ...moneyOldLand, oldLandLoanAmount: parseInt(e.target.value) })} />
                                                </Form.Item>

                                                <Form.Item label='ค่างวด/เดือน' name='oldLandMonthlyPayment'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <Input type='number' suffix="บาท"
                                                        onChange={(e) => setMoneyOldLand({ ...moneyOldLand, oldLandMonthlyPayment: parseInt(e.target.value) })} />
                                                </Form.Item>

                                                <Form.Item label='จำนวนผ่อนกี่งวด' name='oldLandLoanTerm'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <Input type='number' suffix="งวด"
                                                        onChange={(e) => setMoneyOldLand({ ...moneyOldLand, oldLandLoanTerm: parseInt(e.target.value) })} />
                                                </Form.Item>

                                                {/* <Form.Item label='ยอดที่ค้างทั้งหมด' name='oldLandKangPayment'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <Input type='number' suffix="บาท"
                                                        onChange={(e) => setMoneyOldLand({ ...moneyOldLand, oldLandKangPayment: parseInt(e.target.value) })} />
                                                </Form.Item>

                                                <Form.Item label='ค้างกี่งวด' name='oldLandKangPaymentTerm'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <Input type='number' suffix="งวด" disabled
                                                        onChange={(e) => setMoneyOldLand({ ...moneyOldLand, oldLandKangPaymentTerm: parseInt(e.target.value) })} />
                                                </Form.Item> */}
                                            </>
                                        ) : null}
                                    </>
                                )}
                                <Form.Item label='ผู้เสนอเคส' name='proposalBy'>
                                    <Input disabled style={{ color: 'black' }}
                                        onChange={(e) => setMoney({ ...money, proposalBy: e.target.value })} />
                                </Form.Item>

                            </Col>
                        </Row>
                        <Row justify={'center'}>
                            <Space>
                                <Button type="text" style={{ background: "#bfbfbf" }} onClick={onChangeBack}>
                                    ย้อนกลับ
                                </Button>
                                <Button type="primary" danger onClick={handleCancel}>
                                    ยกเลิก
                                </Button>
                                <Button type="primary" htmlType="submit" style={{ background: 'green' }}>
                                    บันทึก
                                </Button>
                                <Button type="primary" onClick={onChangeGo}>
                                    ต่อไป
                                </Button>
                            </Space>
                        </Row>
                    </Form>
                </Spin>
            </Card>
        </Row >
    )
}
