import React, { useEffect, useState } from 'react'
import { Routes, Route } from "react-router-dom"
import AddCustomer from './savecustomer/AddCustomer'
import AddDetail from './savecustomer/AddDetail'
import Detail from './savecustomer/Detail'
import DetailLand from './savecustomer/DetailLand'
import UploadImages from './savecustomer/UploadImages'
import CareerIncome from './savecustomer/CareerIncome'
import PreLand from './savecustomer/PreLand'
import Broker from './savecustomer/Broker'

import { useDispatch } from 'react-redux';
import { addCustomer } from '../../redux/Customer';
import { addAddress } from '../../redux/Address';
import { addPhone } from '../../redux/Phone';
import { addCar } from '../../redux/Car';
import { addLoan } from '../../redux/Loan';
import { addOldLoan } from '../../redux/OldLoan';
import { addImage } from '../../redux/Image';
import { addLand } from '../../redux/Land';
import { addLoanLand } from '../../redux/LoanLand';
import { addOldLoanLand } from '../../redux/OldLoanLand';
import { addGuarantor } from '../../redux/Guarantor';
import { addCareerIncome } from '../../redux/CareerIncome';
import { addOccupation } from '../../redux/Occupation';
import { addEmail } from '../../redux/Email';
import { addSocial } from '../../redux/Social';
import AddGuarantors from './savecustomer/AddGuarantors'
// import AddGuarantorLegal from './savecustomer/AddGuarantorLegal'

export default function Main() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(addCustomer())
    dispatch(addAddress())
    dispatch(addPhone())
    dispatch(addCar())
    dispatch(addGuarantor())
    dispatch(addCareerIncome())
    dispatch(addLoan())
    dispatch(addOldLoan())
    dispatch(addImage())
    dispatch(addLand())
    dispatch(addLoanLand())
    dispatch(addOldLoanLand())
    dispatch(addOccupation())
    dispatch(addEmail())
    dispatch(addSocial())
  }, [])

  return (
    <Routes>
      <Route path="/" element={<UploadImages />} />
      <Route path="/preland" element={<PreLand />} />
      <Route path="/addcustomer" element={<AddCustomer />} />
      {/* <Route path="/addguarantorlegal" element={<AddGuarantorLegal />} /> */}
      <Route path="/addguarantor" element={<AddGuarantors />} />
      <Route path="/addDetail" element={<AddDetail />} />
      <Route path="/broker" element={<Broker />} />
      <Route path="/addCareerincome" element={<CareerIncome />} />
      <Route path="/result" element={<Detail />} />
      <Route path="/resultLand" element={<DetailLand />} />
    </Routes>
  )
}
