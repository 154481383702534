import React, { useState, useEffect } from 'react'
import { Button, Form, Input, Select, Divider, Row, Col, Modal, Checkbox, Space, Card } from 'antd';
import axios from 'axios';
import '../../offer_case/css/Media.css'
import { Option } from 'antd/es/mentions';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { addCareerIncome } from '../../../redux/CareerIncome';
import { addOccupation } from '../../../redux/Occupation';
import { addGuarantor } from '../../../redux/Guarantor';
import { addImage } from '../../../redux/Image';
import { addEmail } from '../../../redux/Email';
import { getocc, newgetsection, newgetsub } from '../../file_mid/all_api';

export default function SendOcc({ onCancel, changepage }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const customers = useSelector(state => state.customers)
  const careerIncome = useSelector(state => state.careerIncome)
  const guarantor = useSelector(state => state.guarantor)
  const imgs = useSelector(state => state.imgs)
  const { confirm } = Modal;
  const [loading, setLoading] = useState(false)
  const [occupation, setOccupation] = useState([]) // getdatabase occ
  const [sub, setSub] = useState([]) // getdatabase sub
  const [section, setSection] = useState([]) // getdatabase section
  const [cus, setCus] = useState({}) // customerId
  const [dataPost, setDataPost] = useState({ mainOccupation: true }) //เก็บอาชีพ
  const [countDataPost, setCountDataPost] = useState(0)

  const [money, setMoney] = useState({}) //เก็บรายได้
  const [expenses, setExpenses] = useState({}) //เก็บรายจ่าย
  const [getData, setGetData] = useState([]) //ดึงข้อมูลอาชีพ+รายได้ที่มีมา
  const [guarantors, setGuarantors] = useState(guarantor?.data);
  const [image, setImage] = useState([]);
  const [address, setAddress] = useState({});

  useEffect(() => {
    setImage(imgs?.data)
    if (customers?.data?.customerId === undefined) {
      setAddress({
      })
    }
    if (careerIncome?.data) {
      setExpenses({ ...careerIncome?.data?.income?.expenses })
      setMoney({ ...careerIncome?.data?.income })
      setDataPost({ ...careerIncome?.data })
      bpOccupation()
      bpSubOccupation(careerIncome?.data?.occupationalGroup)
      bpOccupationSection(careerIncome?.data?.governmentPrivate)
      form.setFieldsValue(
        {
          ...careerIncome?.data,
          ...careerIncome?.data?.income,
          ...careerIncome?.data?.income?.expenses
        }
      )
    } else {
      loadOccupation()
      setExpenses({})
      setMoney({})
      setDataPost({})
      form.setFieldsValue(
        {
          occupationalGroup: "",
          governmentPrivate: "",
          jobPosition: "",
          companyName: "",
          incomeMonth: '',
          expensesMonth: "",
        }
      )
    }
  }, [])

  const onChangeBack = () => {
    changepage('4')
  }

  const showConfirmCancel = () => {
    confirm({
        title: 'คุณต้องการยกเลิกการเสนอเคส...?',
        onOk() {
            onCancel(false);
        },
        onCancel() {
            // message.success('ยกเลิก')
        },
    });
};

  //ปุ่ม Submit in Modal Add Data Address
  const handleSubmit = () => {
    setLoading(true)
    //console.log("img", image)
    delete dataPost.income
    delete money.expenses
    // var imageGuarantors = [...image];
    // guarantors?.map((img, index) => {
    //   // console.log("img", img)
    //   // console.log("img11", img.imgs)
    //   imageGuarantors.push(img.imgs)
    //   setImage(imageGuarantors)
    // })
    // // dispatch(addEmail(imageGuarantors))
    // dispatch(addImage(imageGuarantors))

    //ส่งไปหลังบ้าน
    if (careerIncome?.data) {
      //var show = { ...dataPost, customerId: customers?.data.customerId, income: { ...money, incomesMainId: careerIncome?.data?.income?.incomesMainId, incomeMainDescription: null, expenses: { ...expenses, expensesId: careerIncome?.data?.income?.expenses.expensesId, expensesMonthDescription: null } } }
      //const req = { ojs: { ...dataPost, customerId: customers?.data.customerId }, income: { ...money, incomesMainId: careerIncome?.data?.income?.incomesMainId, incomeMainDescription: null }, expenses: { ...expenses, expensesId: careerIncome?.data?.income?.expenses.expensesId, expensesMonthDescription: null } }
      if (countDataPost === 0) {
        var show = {
          ...dataPost,
          occupationalGroupTH: occupation?.find((e) => e.idOccupation === careerIncome?.data?.occupationalGroup)?.desc,
          governmentPrivateTH: sub?.find((e) => e.idSub === careerIncome?.data?.governmentPrivate)?.descSub,
          jobPositionTH: section?.find((e) => e.idSection === careerIncome?.data?.jobPosition)?.descSection,
          customerId: customers?.data.customerId, income: { ...money, incomesMainId: careerIncome?.data?.income?.incomesMainId, incomeMainDescription: null, expenses: { ...expenses, expensesId: careerIncome?.data?.income?.expenses.expensesId, expensesMonthDescription: null } }
        }
        var req = {
          ojs: {
            ...dataPost,
            occupationalGroupTH: occupation?.find((e) => e.idOccupation === careerIncome?.data?.occupationalGroup)?.desc,
            governmentPrivateTH: sub?.find((e) => e.idSub === careerIncome?.data?.governmentPrivate)?.descSub,
            jobPositionTH: section?.find((e) => e.idSection === careerIncome?.data?.jobPosition)?.descSection,
            customerId: customers?.data.customerId
          }, income: { ...money, incomesMainId: careerIncome?.data?.income?.incomesMainId, incomeMainDescription: null }, expenses: { ...expenses, expensesId: careerIncome?.data?.income?.expenses.expensesId, expensesMonthDescription: null }
        }
      }
      if (countDataPost === 1) {
        var show = {
          ...dataPost, customerId: customers?.data.customerId,
          income: { ...money, incomesMainId: careerIncome?.data?.income?.incomesMainId, incomeMainDescription: null, expenses: { ...expenses, expensesId: careerIncome?.data?.income?.expenses.expensesId, expensesMonthDescription: null } }
        }
        var req = {
          ojs: {
            ...dataPost, customerId: customers?.data.customerId
          },
          income: { ...money, incomesMainId: careerIncome?.data?.income?.incomesMainId, incomeMainDescription: null }, expenses: { ...expenses, expensesId: careerIncome?.data?.income?.expenses.expensesId, expensesMonthDescription: null }
        }
      }
      // console.log("show", show)
      // console.log("req", req)
      // setLoading(true)
      dispatch(addOccupation({ ...req }))
      dispatch(addCareerIncome({ ...show }))
      changepage('6')
    } else {
      var show = { ...dataPost, customerId: customers?.data.customerId, mainOccupation: true, income: { ...money, mainOccupation: true, incomeMainDescription: null, expenses: { ...expenses, expensesMonthDescription: null } } }
      const req = { ojs: { ...dataPost, mainOccupation: true, customerId: customers?.data.customerId }, income: { ...money, incomeMainDescription: null }, expenses: { ...expenses, expensesMonthDescription: null } }
      // setLoading(true)
      dispatch(addOccupation({ ...req }))
      dispatch(addCareerIncome({ ...show }))
      changepage('6')
    }
    setLoading(false)
  }
  // data input Form 
  const handleInputDataAddress = (e) => {
    const { name, value } = e.target;
    setAddress({
      ...address, [name]: value,
    }) // เป็นการกระจายข้อมูลเข้าไปตามชื่อ  name
  }
  // Data Select Form
  const loadOccupation = async () => {
    setLoading(true)
    await axios.get(getocc)
      .then((res) => {
        setOccupation(res.data)
        setLoading(false)
      })
      .catch((err) => console.log(err))
    setLoading(false)
  }

  const loadSubOccupation = async (value) => {
    //console.log("sub",value)
    const idOccupation = { idOccupation: value }
    setLoading(true)
    await axios.post(newgetsub, idOccupation)
      .then((res) => {
        setSub(res.data)
        setLoading(false)
        form.setFieldsValue(
          {
            governmentPrivate: "",
            jobPosition: "",
            companyName: "",
          }
        )
      })
      .catch((err) => console.log(err))
    setLoading(false)
  }

  const loadOccupationSection = async (value) => {
    //console.log("section",value)
    const idSub = { idSub: value }
    setLoading(true)
    await axios.post(newgetsection, idSub)
      .then((res) => {
        setSection(res.data)
        setLoading(false)
        form.setFieldsValue(
          {
            jobPosition: "",
            companyName: "",
          }
        )
      })
      .catch((err) => console.log(err))
    setLoading(false)
  }
  // back page 
  const bpOccupation = async () => {
    setLoading(true)
    await axios.get(getocc)
      .then((res) => {
        //console.log("bp-occ", res.data)
        setOccupation(res.data)
        setLoading(false)
      })
      .catch((err) => console.log(err))
    setLoading(false)
  }

  const bpSubOccupation = async (value) => {
    //console.log("sub",value)
    const idOccupation = { idOccupation: value }
    setLoading(true)
    await axios.post(newgetsub, idOccupation)
      .then((res) => {
        //console.log("bp-sub", res.data)
        setSub(res.data)
        setLoading(false)
      })
      .catch((err) => console.log(err))
    setLoading(false)
  }

  const bpOccupationSection = async (value) => {
    //console.log("section",value)
    const idSub = { idSub: value }
    setLoading(true)
    await axios.post(newgetsection, idSub)
      .then((res) => {
        //console.log("bp-section", res.data)
        setSection(res.data)
        setLoading(false)
      })
      .catch((err) => console.log(err))
    setLoading(false)
  }

  const getOccupation = (value) => {
    setCountDataPost(1)
    //console.log("value", {occupation: occupation.find((e) => e.idOccupation === value).desc})
    //setDataPost({ ...dataPost, occupationalGroup: occupation.find((e) => e.idOccupation === value).desc })
    setDataPost({
      ...dataPost,
      governmentPrivate: "",
      governmentPrivateTH: "",
      jobPosition: "",
      companyName: "",
      occupationalGroup: value,
      occupationalGroupTH: occupation.find((e) => e.idOccupation === value).desc
  })
    loadSubOccupation(value)
  }

  const getSub = (value) => {
    setCountDataPost(1)
    //console.log("value", value)
    //setDataPost({ ...dataPost, governmentPrivate: sub.find((e) => e.idSub === value).descSub })
    setDataPost({
      ...dataPost,
      jobPosition: 32,
      jobPositionTH: "",
      idPloccupation: 12,
      idArgroup: 11,
      companyName: "",
      governmentPrivate: value,
      governmentPrivateTH: sub.find((e) => e.idSub === value).descSub,
      idPljob: sub.find((e) => e.idSub === value).idPljob
  })
    loadOccupationSection(value)
  }

  const getSection = (value) => {
    setCountDataPost(1)
    //console.log("value", value)
    //setDataPost({ ...dataPost, jobPosition: section.find((e) => e.idSection === value).descSection })
    setDataPost({
      ...dataPost,
      jobPosition: value,
      jobPositionTH: section.find((e) => e.idSection === value).descSection,
      idPloccupation: section.find((e) => e.idSection === value).idPloccupation,
      idArgroup: section.find((e) => e.idSection === value).idArgroup
    })
  }

  return (
    <>
      <Row justify={'center'}>
        <Card style={{ width: '1000px' }}>
          <Form
            form={form}
            onFinish={handleSubmit}
            name='add'
            labelCol={{
              span: 10,
            }}
            wrapperCol={{
              span: 24,
            }}
            style={{
              maxWidth: '90%',
            }}>
            <Row gutter={32}>
              <Col className='gutter-row' span={24} style={{ fontSize: '30px', textAlign: 'center' }} ><u>เพิ่มอาชีพ</u></Col>
            </Row>
            <Divider style={{ margin: 5 }} />
            <Row className='main2' justify={'center'}>
              <Card>
                <aside>
                  <div>
                    <Form.Item label='กลุ่มอาชีพ' name='occupationalGroup'
                      rules={[
                        {
                          required: true,
                          message: 'Please input !',
                        },]}>
                      <Select
                        placeholder={'เลือก'}
                        onChange={getOccupation}
                      >
                        {occupation?.map((item) => {
                          return (
                            <Select.Option key={item.idOccupation} value={item.idOccupation}>
                              {item.desc}
                            </Select.Option>
                          )
                        })}

                      </Select>
                    </Form.Item>

                    <Form.Item label='เลือกอาชีพ' name='governmentPrivate'
                      rules={[
                        {
                          required: true,
                          message: 'Please input !',
                        },]}>
                      <Select
                        placeholder={'เลือก'}
                        onChange={getSub}
                      >
                        {sub?.map((item) => {
                          return (
                            <Select.Option key={item.idSub} value={item.idSub}>
                              {item.descSub}
                            </Select.Option>
                          )
                        })}

                      </Select>
                    </Form.Item>

                    <Form.Item label='สาขาอาชีพ' name='jobPosition'>
                      <Select
                        placeholder={'เลือก'}
                        onChange={getSection}
                      >
                        {section?.map((item) => {
                          return (
                            <Select.Option key={item.idSection} value={item.idSection}>
                              {item.descSection}
                            </Select.Option>
                          )
                        })}

                      </Select>
                    </Form.Item>

                    <Form.Item label='ชื่อบริษัท สถานที่ทำงาน' name='companyName'
                      rules={[
                        {
                          required: true,
                          message: 'Please input !',
                        },]}>
                      <Input type='text'
                        style={{ color: 'black' }}
                        onChange={(e) => setDataPost({ ...dataPost, companyName: e.target.value })}></Input>
                    </Form.Item>

                    <Form.Item label='หมายเหตุ' name='occupationDescription' >
                      <Input type='text'
                        onChange={(e) => setDataPost({ ...dataPost, occupationDescription: e.target.value })}></Input>
                    </Form.Item>

                    <Form.Item label='เป็นอาชีพหลักหรือไม่' name='mainOccupation' >
                      <Checkbox defaultChecked={true} checked={dataPost} onChange={(e) => setDataPost({ ...dataPost, mainOccupation: e.target.checked })}>ใช่</Checkbox>
                    </Form.Item>
                  </div>

                  <div>
                    <Form.Item label='รายได้ต่อเดือน' name='incomeMonth'
                      rules={[
                        {
                          required: true,
                          message: 'Please input !',
                        },]}>
                      <Input type='text' suffix='บาท'
                        style={{ color: 'black' }}
                        onChange={(e) => setMoney({ ...money, incomeMonth: parseInt(e.target.value) })}></Input>
                    </Form.Item>

                    <Form.Item label='อื่นๆ' name='note' >
                      <Input type='text' suffix='บาท'
                        onChange={(e) => setMoney({ ...money, note: parseInt(e.target.value) })}></Input>
                    </Form.Item>

                    <Form.Item label='หมายเหตุ' name='incomeMainDescription' >
                      <Input type='text'
                        onChange={(e) => setMoney({ ...money, incomeMainDescription: e.target.value })}></Input>
                    </Form.Item>

                    {/* <Form.Item label='รายได้รวม / เดือน' name='totalIncomeMonth' >
                                        {currencyFormatOne(testM)} บาท
                                    </Form.Item>

                                    <Form.Item label='รายได้รวม / ปี' name='totalIncomeYear' >
                                        {currencyFormatOne(testY)} บาท
                                    </Form.Item> */}

                    <Form.Item label='ค่าใช้จ่ายต่อ / เดือน' name='expensesMonth'
                      rules={[
                        {
                          required: true,
                          message: 'Please input !',
                        },]}>
                      <Input type='text' suffix='บาท'
                        style={{ color: 'black' }}
                        onChange={(e) => setExpenses({ ...expenses, expensesMonth: parseInt(e.target.value) })}></Input>
                    </Form.Item>

                    <Form.Item label='หมายเหตุ' name='expensesMonthDescription' >
                      <Input type='text'
                        style={{ color: 'black' }}
                        onChange={(e) => setExpenses({ ...expenses, expensesMonthDescription: e.target.value })}></Input>
                    </Form.Item>

                    {/* <Form.Item label='หักค่าใช้จ่าย / เดือน' name='summaryOfExpenses' >
                                        {currencyFormatOne(testResult)} บาท
                                    </Form.Item> */}
                  </div>
                </aside>
              </Card>
            </Row>
            <Divider style={{ margin: 5 }} />
            <Row justify={'center'} >
              <Space>
                <Button type="primary" style={{ background: "#bfbfbf" }} onClick={(e) => { onChangeBack(e) }}>
                  ย้อนกลับ
                </Button>
                <Button type="primary" onClick={() => { showConfirmCancel() }} style={{ background: "red" }}>ยกเลิก</Button>
                <Button type="primary" htmlType="submit" style={{ background: "green" }}>
                  ต่อไป
                </Button>
              </Space>
            </Row>
          </Form>
        </Card>
      </Row>
    </>
  )
}
