import React, { useState, useEffect } from 'react'
import { Form, Input, Row, Col, Button, Select, Divider, Space, Modal, Spin, Card, InputNumber } from 'antd'
import axios from 'axios'
import dayjs from 'dayjs';
import TextArea from 'antd/es/input/TextArea';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { addCustomer } from '../../../redux/Customer';
import { addAddress } from '../../../redux/Address';
import { addPhone } from '../../../redux/Phone';
import { addImage } from '../../../redux/Image';
import { addLand } from '../../../redux/Land';
import { addLoanLand } from '../../../redux/LoanLand';
import { addOldLoanLand } from '../../../redux/OldLoanLand';
import Currency from 'currency.js';
import { productLoanTypeLand } from '../../file_mid/all_options';
import { GetAmphur1, GetProvince, PostProvince1, deleteNewUpLand } from '../../file_mid/all_api';

export default function SendEditLand({ onCancel, changepage, DeleteImgSucc, DeleteImgError }) {
    const customers = useSelector(state => state.customers)
    const lands = useSelector(state => state.lands)
    const loansLand = useSelector(state => state.loansLand)
    const oldLoansLand = useSelector(state => state.oldLoansLand)

    const currentTime = dayjs();
    //const delayedTime = currentTime.add(7, 'hour');
    const formattedTime = currentTime.format('YYYY-MM-DDTHH:mm:ssZ');
    const { confirm } = Modal;
    const dispatch = useDispatch()
    const { Option } = Select;
    const [form] = Form.useForm()
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('username');
    const [loading, setLoading] = useState(false)
    const [dataPost1, setDataPost1] = useState({}) //customer
    //const [dataModalEdit, setDataModalEdit] = useState(dataFromTable)
    const [dataModalEdit, setDataModalEdit] = useState({})
    const [imageBlobzz, setImageBlobzz] = useState([]);
    const [selectProduct, setSelectProduct] = useState({ productTypeId: 2 })
    //  const [dataModalEdit, setDataModalEdit] = useState({ ...dataFromTable.carLoanDetails, ...dataFromTable.oldLoanDetails, ...dataFromTable.car, })
    //////////////////// ที่ดิน /////////////////////////////
    const [dataPostLand, setDataPostLand] = useState({   // ที่ดิน
        // landInput: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"),
        // landDateStatus: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"),
        // rai: 0,
        // workArea: 0,
        // squareWaArea: 0,
        // landPrice: 0,
        // resultLandPrice: 0,
        // landPriceStatus: false,
    })
    const [moneyLand, setMoneyLand] = useState({}) //loan land
    const [moneyOldLand, setMoneyOldLand] = useState({}) //loanOld land
    const [typeMoneyLand, setTypeMoneyLand] = useState({})

    const [pvcodeS, setPvcodeS] = useState({});
    const [amcodeS, setAmcodeS] = useState({});

    const [provinces, setProvinces] = useState([]);
    const [district, setDistricts] = useState([]);

    ///////////////// อัปโหลดรูป //////////////////////////////////////
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [fileList, setFileList] = useState([]);
    const [fileListBook, setFileListBook] = useState([]);
    const [fileListResult1, setFileListResult1] = useState([]);
    const [fileListResult2, setFileListResult2] = useState([]);
    const [sell, setSell] = useState(true)

    useEffect(() => {
        if (lands.data) {
            //console.log("DD")
            //getProvinces()
            //GetAmphur(lands.data.district)
            //PostAmphur(lands.data.district)
            //PostProvince(lands.data.province)
            //setSelectProduct({ ...selectProduct, productTypeId: 2 })
            setDataPost1(customers.data)
            setDataPostLand(lands.data)
            setMoneyLand(loansLand.data)
            setMoneyOldLand(oldLoansLand.data)
            if (loansLand.data.loanAmounttLand === "0") {
                // console.log("1")
                form.setFieldsValue(
                    {
                        ...customers.data, ...lands.data, ...loansLand.data, ...oldLoansLand.data,
                        proposalBy: user,
                        loanLandTerm: "",
                        loanAmounttLand: "",
                        productLoanLandType: "",
                        birthdate: dayjs(customers.data?.birthdate),
                        issudt: dayjs(customers.data?.issudt),
                        expdt: dayjs(customers.data?.expdt),
                    }
                )
            } else {
                // console.log("2")
                form.setFieldsValue(
                    {
                        ...customers.data, ...lands.data, ...loansLand.data, ...oldLoansLand.data,
                        proposalBy: user,
                        birthdate: dayjs(customers.data?.birthdate),
                        issudt: dayjs(customers.data?.issudt),
                        expdt: dayjs(customers.data?.expdt),
                    }
                )
            }

        }
    }, [])

    // console.log("ez2", customers.data)
    // console.log("lands.data", lands.data)
    // console.log("loansLand.data", loansLand.data)
    // console.log("loansLand.data.loanAmounttLand", loansLand.data.loanAmounttLand)

    useEffect(() => {
        //console.log("XX")
        land_Calculate()
    }, [moneyLand?.loanAmounttLand, moneyLand?.interestRateLand, moneyLand?.loanLandTerm])

    useEffect(() => {
        if (moneyLand && (moneyLand?.productLoanLandType === 'ย้ายไฟแนนซ์' || moneyLand?.productLoanLandType === 'Pre-Aaprove-ย้ายไฟแนนซ์')) {
            land_Calculate_close()
        }
    }, [moneyOldLand?.oldLandKangPaymentTerm, moneyOldLand?.oldLandLoanAmount, moneyOldLand?.oldLandMonthlyPayment, moneyOldLand?.oldLandLoanTerm, moneyOldLand?.oldLandKangPayment])

    const onChangeBack = () => {
        changepage('2')
    }
    const showConfirmCancel = () => {
        confirm({
            title: 'คุณต้องการยกเลิกการเสนอเคส...?',
            onOk() {
                onCancel(false);
            },
            onCancel() {
                // message.success('ยกเลิก')
            },
        });
    };
    const handleSubmit = async () => {
        // delete dataModalEdit.OldLoanId
        if (moneyLand?.loanAmounttLand > 999999999 || moneyLand?.interestRateLand > 100) {
            alert("กรุณาตรวจสอบยอดขอกู้อีกครั้ง")
        } else {
            dispatch(addLand(dataPostLand))
            dispatch(addLoanLand(moneyLand))
            dispatch(addOldLoanLand(moneyOldLand))
            changepage('4')
        }
    }


    /////////////// ที่อยู่ ของ ที่ดิน //////////////////
    const getProvinces = async () => {
        setLoading(true)
        await axios.get(GetProvince)
            .then((res) => {
                //console.log("Province =", res.data)
                setProvinces(res.data)
                setLoading(false)
            }).catch((err) => {
                console.error(err);
            })
        setLoading(false)
    }

    const PostProvince = async (value) => {
        const pr = { pvcode: value }
        //console.log("pr =", pr)
        setLoading(true)
        await axios.post(PostProvince1, pr)
            .then((res) => {
                //console.log("Province =", res.data)
                //console.log("pvcode =", res.data[0].pvcode)
                setPvcodeS(res.data[0].pvcode)
                //setProvinces(res.data)
                setLoading(false)
            }).catch((err) => {
                console.error(err);
            })
        setLoading(false)
    }

    const GetAmphur = async (value) => {
        const am = { pvcode: value }
        //console.log("am =", am)
        setLoading(true)
        await axios.post(GetAmphur1, am)
            .then((res) => {
                //console.log("Amphur =", res.data)
                setDistricts(res.data)
                setAmcodeS(res.data[0].amcode)
                setLoading(false)
            }).catch((err) => {
                console.error(err);
            })
        setLoading(false)
    }

    const handleProvinceChange = async (value, key) => {
        //console.log("Province =", key["key"], value)
        setPvcodeS(key["key"])
        setDataModalEdit({ ...dataModalEdit, province: value });
        form.setFieldsValue(
            {
                numberLand: "",
                numberLandLawang: "",
                rai: "",
                workArea: "",
                squareWaArea: "",
                landPrice: "",
                resultLandPrice: "",
                district: "",
                // productLoanLandType: "",
                // loanAmounttLand: "",
                // loanLandTerm: "",
                // interestRateLand: "",
                // interesttLand: "",
                // monthlyPaymentLand: "",
                // installmentWithInterestLand: "",
            }
        )
        setLoading(true)
        const test = { pvcode: key["key"] }
        await axios.post(GetAmphur1, test)
            .then((res) => {
                setDistricts(res.data)
                setLoading(false)
            }).catch((err) => {
                console.error(err);
            })
        setLoading(false)
    };

    const handleDistrictChange = (value, key) => {
        //console.log("VV =", key["key"], value)
        setAmcodeS(key["key"])
        setDataModalEdit({ ...dataModalEdit, district: value });
        form.setFieldsValue(
            {
                numberLand: "",
                numberLandLawang: "",
                rai: "",
                workArea: "",
                squareWaArea: "",
                landPrice: "",
                resultLandPrice: "",
                // productLoanLandType: "",
                // loanAmounttLand: "",
                // loanLandTerm: "",
                // interestRateLand: "",
                // interesttLand: "",
                // monthlyPaymentLand: "",
                // installmentWithInterestLand: "",
            }
        )

    };

    ///////////////// กรณี นส.3 ///////////////////////////

    const handleProvinceChange1 = async (value, key) => {
        //console.log("Province =", key["key"], value)
        setPvcodeS(key["key"])
        setDataModalEdit({ ...dataModalEdit, province: value });
        form.setFieldsValue(
            {
                numberLand: "",
                numberLandLawang: "",
                rai: "",
                workArea: "",
                squareWaArea: "",
                district: "",
            }
        )
        setLoading(true)
        const test = { pvcode: key["key"] }
        await axios.post(GetAmphur1, test)
            .then((res) => {
                setDistricts(res.data)
                setLoading(false)
            }).catch((err) => {
                console.error(err);
            })
        setLoading(false)
    };

    const handleDistrictChange2 = (value, key) => {
        //console.log("VV =", key["key"], value)
        setAmcodeS(key["key"])
        setDataModalEdit({ ...dataModalEdit, district: value });
        form.setFieldsValue(
            {
                numberLand: "",
                numberLandLawang: "",
                rai: "",
                workArea: "",
                squareWaArea: "",
            }
        )

    };
    /////////////////// เลือก 1 = นส.3 กับ 2 = นส.4 //////////////////////
    const handleProductLand = (value) => {
        console.log("landType =", value)
        if (value === 1) {
            setDataPostLand({
                ...dataPostLand,
                productTypeLand: value,
                numberLand: "-",
                numberLandLawang: "-",
                rai: 0,
                workArea: 0,
                squareWaArea: 0,
                landPrice: 0,
                resultLandPrice: 0,
                landPriceStatus: false,
            })
            setFileListResult2([]);
            setFileListResult1([]);
            form.setFieldsValue(
                {
                    numberLand: "",
                    numberLandLawang: "",
                    rai: "",
                    workArea: "",
                    squareWaArea: "",
                    landPrice: "",
                    resultLandPrice: "",
                    province: "",
                    district: "",
                }
            )
        } else {
            setDataPostLand({
                ...dataPostLand,
                productTypeLand: value,
                numberLand: "-",
                numberLandLawang: "-",
                rai: 0,
                workArea: 0,
                squareWaArea: 0,
                landPrice: 0,
                resultLandPrice: 0,
                landPriceStatus: false,
            })
            setFileListResult2([]);
            setFileListResult1([]);
            form.setFieldsValue(
                {
                    numberLand: "",
                    numberLandLawang: "",
                    rai: "",
                    workArea: "",
                    squareWaArea: "",
                    landPrice: "",
                    resultLandPrice: "",
                    province: "",
                    district: "",
                }
            )
        }
    }

    ////////////// ที่ดิน /////////////////////
    const handleChangeLandType = (value) => {
        setDataPostLand({ ...dataPostLand, landInput: dayjs().format("YYYY-MM-DDTHH:mm:ssZ") })
        // setMoneyLand({ ...moneyLand, productLoanLandType: value, proposalBy: user, productType: "ที่ดิน", approvalStatus: "รอธุรการรับ" })
        // form.setFieldsValue(
        //     {
        //         loanAmounttLand: '',
        //         loanLandTerm: '',
        //         interestRateLand: '',
        //         interesttLand: '',
        //         monthlyPaymentLand: '',
        //         installmentWithInterestLand: '',
        //     }
        // )
        if (value === 'ย้ายไฟแนนซ์' || value === 'Pre-Aaprove-ย้ายไฟแนนซ์') {
            setMoneyLand({ ...moneyLand, productLoanLandType: value, proposalBy: user, productType: "ที่ดิน", activeStatus: true, approvalStatus: "รอธุรการรับ" })
            // setMoneyOldLand({ ...moneyOldLand, productLoanLandType: value })
            // setTypeMoneyLand({ ...typeMoney, productLoanLandType: 'ย้ายไฟแนนซ์', productType: "ที่ดิน" })
        }
        else {
            setMoneyLand({ ...moneyLand, productLoanLandType: value, proposalBy: user, productType: "ที่ดิน", activeStatus: true, approvalStatus: "รอธุรการรับ" })
            setMoneyOldLand()
            form.setFieldsValue(
                {
                    loanAmounttLand: '',
                    loanLandTerm: '',
                    interesttLand: '',
                    monthlyPaymentLand: '',
                    installmentWithInterestLand: '',
                    oldLandLoanAmount: '',
                    oldLandMonthlyPayment: '',
                    oldLandLoanTerm: '',
                    oldLandKangPayment: '',
                    oldLandKangPaymentTerm: '',
                }
            )
        }
    }
    const handleChangeLoanAmounttLand = (value) => {
        setDataPostLand({ ...dataPostLand, landInput: dayjs().format("YYYY-MM-DDTHH:mm:ssZ") })
        setMoneyLand({ ...moneyLand, loanAmounttLand: parseInt(value) })
    }


    ///////////////// อัปโหลดรูปภาพ //////////////////////////////
    const handleChange = async ({ fileList }) => {
        //console.log("fileList", fileList)
        setFileList(fileList);
        var imageList = []
        for (const file of fileList) {
            var image = {}
            var filename = file.name
            var image64 = await getBase64(file.originFileObj)
            image.filename = filename
            image.image64 = image64
            image.type = 1
            imageList.push(image)
        }
        setFileListResult1({ ...fileListResult1, imageList });
        //dispatch(addImage(imageList))
    };
    const handleChangeBook = async ({ fileList }) => {
        //console.log("fileListBook", fileList)
        setFileListBook(fileList);
        var imageListBook = []
        for (const file of fileList) {
            var image = {}
            var filename = file.name
            var image64 = await getBase64(file.originFileObj)
            image.filename = filename
            image.image64 = image64
            image.type = 2
            imageListBook.push(image)
        }
        setFileListResult2({ ...fileListResult2, imageListBook });
        //dispatch(addImage(imageListBook))
    };

    const handleChangeDeed = async ({ fileList }) => {
        setFileListBook(fileList);
        var imageListBook = []
        for (const file of fileList) {
            var image = {}
            var filename = file.name
            var image64 = await getBase64(file.originFileObj)
            image.filename = filename
            image.image64 = image64
            image.type = 3
            imageListBook.push(image)
        }
        setFileListResult2({ ...fileListResult2, imageListBook });
        //dispatch(addImage(imageListBook))
    };


    const handleCancel = () => {
        setPreviewVisible(false);
    }
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
    };
    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const errorLand = () => {
        Modal.error({
            title: 'ไม่พบข้อมูล',
            content: 'กรุณาตรวจสอบข้อมูลที่กรอกอีกครั้ง',
        })
    }

    const handleCheckLand = async () => {
        console.log("pvcodeS =", pvcodeS)
        console.log("amcodeS =", amcodeS)
        console.log("dataPostLand.numberLand =", dataModalEdit.numberLand)
        //const tokens = "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IjEiLCJyZWdpc3Rlcl90eXBlX3NlcSI6IjgiLCJ1c2VyX2dyb3VwX2RhdGFfbGlzdCI6IjEsMSwwLDAsMCwwLDAsMSIsInBlcnNvbmFsX2lkIjoiIiwianRpIjoiOTllYmE2OTAtMGFmOC00M2NhLTg2OTktYWUwZDI4N2ZjY2EzIiwiaWF0IjoiMDkvMjcvMjAyMyAwMzoyMDoxNSIsIm5iZiI6MTY5NTc4NDgxNSwiZXhwIjoxNjk1ODcxMjE1LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDozMDAwMS8iLCJhdWQiOiJMYW5kc01hcHMifQ.Lw3h4tjSbzjscWqkuA9FutwzXPY8UEb49D4ZdsdqKRfR0fwFunY7i4pg5Rdc70Vrw8jDR1qhtQLn5SEqxPguoQ"
        var provids = pvcodeS
        var amphurs = amcodeS
        var landNos = dataModalEdit.numberLand
        var result = { pvcode: provids, amcode: amphurs, landNo: landNos }
        //console.log("result =", result)
        const headers = {
            "Content-Type": "application/json",
            // "Authorization": `Bearer ${tokens}`,
        }
        setLoading(true)
        await axios.post("", result, { headers: headers })
            .then((response) => {
                if (response.data) {
                    // console.log("DATA =", response.data.result)
                    // console.log("ไร่ =", parseInt(response.data.result[0].rai))
                    // console.log("งาน =", parseInt(response.data.result[0].ngan))
                    // console.log("ตารางวา =", parseInt(response.data.result[0].wa + "." + response.data.result[0].subwa))
                    // console.log("จังหวัด =", response.data.result[0].provname)
                    // console.log("อำเภอ =", response.data.result[0].amphurname)
                    // console.log("ละติจูด =", response.data.result[0].parcellat)
                    // console.log("ลองติจูด =", response.data.result[0].parcellon)
                    // console.log("เลขระวาง =", response.data.result[0].utm)
                    // console.log("ราคาต่อตารางวา =", parseInt(response.data.result[0].landprice.replace(/,/g, "")))
                    const squareWaArea = parseFloat(response.data.result[0].wa + "." + response.data.result[0].subwa)
                    const landPricePerWa = parseInt(response.data.result[0].landprice.replace(/,/g, ""))
                    const totalWa = (
                        ((parseInt(response.data?.result[0].rai) * 400)) +
                        ((parseInt(response.data?.result[0].ngan) * 100)) +
                        parseFloat(response.data?.result[0].wa + "." + response.data?.result[0].subwa)
                    )
                    const totalPrice = Currency(totalWa * landPricePerWa).value
                    //console.log("รวมตารางวาทั้งหมด =", totalPrice.toFixed(1));
                    if (landPricePerWa > 0) {
                        setDataModalEdit({
                            ...dataModalEdit,
                            numberLandLawang: response.data?.result[0].utm,
                            rai: parseInt(response.data?.result[0].rai),
                            workArea: parseInt(response.data?.result[0].ngan),
                            squareWaArea: parseFloat(squareWaArea),
                            landPrice: parseInt(response.data.result[0].landprice.replace(/,/g, "")) || 0,
                            resultLandPrice: totalPrice || 0,
                            landPriceStatus: true
                        });
                    } else {
                        setDataModalEdit({
                            ...dataModalEdit,
                            numberLandLawang: response.data?.result[0].utm,
                            rai: parseInt(response.data?.result[0].rai),
                            workArea: parseInt(response.data?.result[0].ngan),
                            squareWaArea: parseFloat(squareWaArea),
                            landPrice: parseInt(response.data.result[0].landprice.replace(/,/g, "")) || 0,
                            resultLandPrice: totalPrice || 0,
                            landPriceStatus: false
                        });
                    }
                    form.setFieldsValue(
                        {
                            numberLandLawang: response.data?.result[0].utm,
                            rai: parseInt(response.data?.result[0].rai),
                            workArea: parseInt(response.data?.result[0].ngan),
                            squareWaArea: squareWaArea,
                            landPrice: currencyFormatOne(parseInt(response.data.result[0].landprice.replace(/,/g, ""))) || 0,
                            resultLandPrice: currencyFormatOne(totalPrice) || 0,
                        }
                    )
                    setLoading(false)
                } else {
                    console.log("DATA =", response.data)
                }
            })
            .catch((err) => errorLand())
            .finally(() => {
                setLoading(false);
            })
        setLoading(false)
    }

    const land_Calculate = () => {
        // console.log("moneyLand?.loanAmounttLand", moneyLand?.loanAmounttLand)
        // console.log("moneyLand?.interestRateLand", moneyLand?.interestRateLand)
        // console.log("moneyLand?.loanLandTerm", moneyLand?.loanLandTerm)
        if (moneyLand?.loanAmounttLand && moneyLand?.interestRateLand && moneyLand?.loanLandTerm) {
            var interestRateLand = moneyLand.interestRateLand / 100 // อัตราดอก / 100
            var rate = ((moneyLand.loanAmounttLand * Currency(interestRateLand, { precision: 5 }).value) * moneyLand.loanLandTerm) // (ยอดจัด * อัตราดอกที่หาร100แล้ว ,precision: 3 คือทศนิยม 3 )*งวด
            var loanTerm = Math.ceil(Currency((rate + moneyLand.loanAmounttLand) / moneyLand.loanLandTerm, { precision: 2 }).value) // (ดอกเบี้ยทั้งหมด + ยอดจัด)/ งวด
            var resultRate = (loanTerm * moneyLand.loanLandTerm) - moneyLand.loanAmounttLand
            var resultPrice = loanTerm * moneyLand.loanLandTerm
            setMoneyLand({
                ...moneyLand,
                //loanLandTerm: parseInt(event.target.value), //งวด
                monthlyPaymentLand: loanTerm,
                interesttLand: resultRate,
                installmentWithInterestLand: resultPrice
            })
            if (moneyLand.loanAmounttLand >= 50000 && moneyLand.loanAmounttLand <= 99999) {
                var feeLoan7 = moneyLand.loanAmounttLand * 0.07 // 7%
                //console.log("7%")
                if (feeLoan7 <= 5000) {
                    form.setFieldsValue(
                        {
                            fee: Currency(feeLoan7, { precision: 2 }).value,
                            monthlyPaymentLand: loanTerm,
                            interesttLand: resultRate,
                            installmentWithInterestLand: resultPrice
                        }
                    )
                } else {
                    form.setFieldsValue(
                        {
                            fee: 5000,
                            monthlyPaymentLand: loanTerm,
                            interesttLand: resultRate,
                            installmentWithInterestLand: resultPrice
                        }
                    )
                }
            }
            if (moneyLand.loanAmounttLand >= 100000 && moneyLand.loanAmounttLand <= 999999) {
                var feeLoan5 = moneyLand.loanAmounttLand * 0.05 // 5%
                //console.log("5%")
                form.setFieldsValue(
                    {
                        fee: Currency(feeLoan5, { precision: 2 }).value,
                        monthlyPaymentLand: loanTerm,
                        interesttLand: resultRate,
                        installmentWithInterestLand: resultPrice
                    }
                )
            }
            if (moneyLand.loanAmounttLand >= 1000000) {
                //console.log("3%")
                var feeLoan3 = moneyLand.loanAmounttLand * 0.03 // 3%
                form.setFieldsValue(
                    {
                        fee: Currency(feeLoan3, { precision: 2 }).value,
                        monthlyPaymentLand: loanTerm,
                        interesttLand: resultRate,
                        installmentWithInterestLand: resultPrice
                    }
                )
            }
            // form.setFieldsValue(
            //   {
            //     monthlyPaymentLand: loanTerm,
            //     interesttLand: resultRate,
            //     installmentWithInterestLand: resultPrice
            //   }
            // )
        }
        else {
            form.setFieldsValue(
                {
                    fee: 0,
                    monthlyPaymentLand: 0,
                    interesttLand: 0,
                    installmentWithInterestLand: 0
                }
            )
        }
    }

    const land_Calculate_close = () => {
        if (moneyOldLand?.oldLandLoanAmount && moneyOldLand?.oldLandMonthlyPayment && moneyOldLand?.oldLandLoanTerm) {
            var loanTermKhangLand = moneyOldLand?.oldLandKangPayment / moneyOldLand?.oldLandMonthlyPayment
            setMoneyOldLand({
                ...moneyOldLand,
                oldLandKangPaymentTerm: Currency(loanTermKhangLand, { precision: 2 }).value,
            })
            form.setFieldsValue(
                {
                    oldLandKangPaymentTerm: Currency(loanTermKhangLand, { precision: 1 }).value,
                }
            )
        } else {
            console.log("")
        }
    }

    const successImg = () => {
        Modal.success({
            title: 'success',
            content: 'ลบรูปภาพสำเร็จ',
        })
    }
    const errorImg = () => {
        Modal.error({
            title: 'error',
            content: 'เกิดข้อผิดพลาดในการลบรูปภาพ',
        })
    }
    const showConfirm = (e, index) => {
        confirm({
            title: 'คุณต้องการที่จะลบรูปภาพนี้ใช่หรือไม่...?',
            onOk() {
                // message.success('ลบรูปภาพสำเร็จ');
                handleDeleteImage(e, index)
            },
            onCancel() {
                // message.error('ยกเลิกการลบรูปภาพ');
            },
        });
    };

    const handleDeleteImage = async (e, index) => {
        const id = e.idImage
        const path = e.pathImage
        // console.log("idImage =", id)
        // console.log("pathImage =", path)
        setLoading(true)
        await axios.delete(`${deleteNewUpLand}/${id}/${path}`)
            .then(res => {
                if (res.data) {
                    console.log("WP", res.data)
                    const updatedImageBlobzz = [...imageBlobzz];
                    updatedImageBlobzz.splice(index, 1);
                    setImageBlobzz(updatedImageBlobzz);
                    // successImg()
                    DeleteImgSucc("top")
                    setLoading(false)
                }
            })
            .catch((err) =>
                // errorImg()
                DeleteImgError("top"))
        setLoading(false)
    }

    const currencyFormatOne = (amount) => {
        return Number(amount)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }

    return (
        <Row justify={'center'}>
            <Card style={{ width: '100%' }}>
                <Spin spinning={loading} size='large' tip=" Loading... ">
                    <Row style={{ textAlign: 'center' }} >
                        <Col span={24} style={{ fontSize: '25px' }} >รายละเอียด</Col>
                    </Row>
                    <Divider style={{ margin: 5 }} />
                    <Form
                        labelCol={{
                            span: 10,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                        style={{
                            width: '100%',
                        }}
                        form={form}
                        onFinish={handleSubmit}
                        autoComplete="off"
                        initialValues={{ remember: true }}>
                        <Row className='inputaside' gutter={18} justify={'center'}>
                            <div style={{ margin: 5 }}>
                                <Form.Item label='กลุ่มสินค้า' name='productTypeId'>
                                    <Select placeholder={'ที่ดิน'} disabled >
                                    </Select>
                                </Form.Item>
                                <Form.Item label='ประเภทเอกสาร' name='productTypeLand'>
                                    <Select disabled
                                        onChange={(value) => handleProductLand(value)}
                                    >
                                        <Option value={1}>นส.3 ก.</Option>
                                        <Option value={2}>นส.4 จ.</Option>
                                    </Select>
                                </Form.Item>

                                {dataPostLand && dataPostLand?.productTypeLand === 1 && (
                                    <>
                                        <Form.Item name='province' label='จังหวัด'>
                                            <Select
                                                disabled
                                                showSearch
                                                loading={loading}
                                                name='province'
                                                placeholder="จังหวัด"
                                                onChange={handleProvinceChange1}
                                            >
                                                {provinces?.result?.map((pro, index) => (
                                                    <Option key={pro.pvcode} value={pro.pvnamethai}>
                                                        {pro.pvnamethai}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>

                                        <Form.Item name='district' label='อำเภอ'>
                                            <Select
                                                disabled
                                                showSearch
                                                loading={loading}
                                                name='district'
                                                placeholder="อำเภอ"
                                                onChange={handleDistrictChange2}
                                            >
                                                {district?.map((dis, index) => (
                                                    <Option key={dis.amcode} value={dis.amnamethai}>
                                                        {dis.amnamethai}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>

                                        <Form.Item label='เลขที่โฉนด/เลขที่' name='numberLand'>
                                            <Input type='text' disabled
                                                onChange={(e) => setDataPostLand({ ...dataPostLand, numberLand: e.target.value })}></Input>
                                        </Form.Item>

                                        <Form.Item label='พื้นที่ไร่' name='rai'>
                                            <Input type='number' suffix="ไร่" disabled
                                                onChange={(e) => setDataPostLand({ ...dataPostLand, rai: parseInt(e.target.value) })}></Input>
                                        </Form.Item>

                                        <Form.Item label='พื้นที่งาน' name='workArea'>
                                            <Input type='number' suffix="งาน" disabled
                                                onChange={(e) => setDataPostLand({ ...dataPostLand, workArea: parseInt(e.target.value) })}></Input>
                                        </Form.Item>

                                        <Form.Item label='พื้นที่ตารางวา' name='squareWaArea'>
                                            <Input type='number' suffix="ตารางวา" disabled
                                                onChange={(e) => setDataPostLand({ ...dataPostLand, squareWaArea: parseInt(e.target.value) })}></Input>
                                        </Form.Item>

                                        {/* <Form.Item label='เพิ่มรูปภาพ'>
                                            <Upload
                                                multiple={true}
                                                listType="picture-card"
                                                fileList={fileList}
                                                onPreview={handlePreview}
                                                onChange={handleChange}
                                                beforeUpload={() => false}
                                            >
                                                {fileList.length >= 10 ? null : (
                                                    <div>
                                                        <PlusOutlined />
                                                        <div className="ant-upload-text">upload</div>
                                                    </div>
                                                )}
                                            </Upload>
                                            <Modal open={previewVisible} onCancel={handleCancel}>
                                                <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                            </Modal>
                                        </Form.Item>

                                        <Form.Item
                                            label={
                                                <div style={{ whiteSpace: 'pre-line', height: '4rem' }}>
                                                    เพิ่มรูปภาพโฉนด/ใบแบ่งใบต่อ/ระวาง
                                                </div>}
                                        >
                                            <Upload
                                                multiple={true}
                                                listType="picture-card"
                                                fileList={fileListBook}
                                                onPreview={handlePreview}
                                                onChange={handleChangeDeed}
                                                beforeUpload={() => false}
                                            >
                                                {fileList.length >= 10 ? null : (
                                                    <div>
                                                        <PlusOutlined />
                                                        <div className="ant-upload-text">upload</div>
                                                    </div>
                                                )}
                                            </Upload>
                                            <Modal open={previewVisible} onCancel={handleCancel}>
                                                <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                            </Modal>
                                        </Form.Item> */}

                                    </>
                                )}
                                {dataPostLand && dataPostLand?.productTypeLand === 2 && (
                                    <>
                                        <Form.Item name='province' label='จังหวัด'>
                                            <Select
                                                disabled
                                                showSearch
                                                loading={loading}
                                                name='province'
                                                placeholder="จังหวัด"
                                                onChange={handleProvinceChange}
                                            >
                                                {provinces?.result?.map((pro, index) => (
                                                    <Option key={pro.pvcode} value={pro.pvnamethai}>
                                                        {pro.pvnamethai}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>

                                        <Form.Item name='district' label='อำเภอ'>
                                            <Select
                                                disabled
                                                showSearch
                                                loading={loading}
                                                name='district'
                                                placeholder="อำเภอ"
                                                onChange={handleDistrictChange}
                                            >
                                                {district?.map((dis, index) => (
                                                    <Option key={dis.amcode} value={dis.amnamethai}>
                                                        {dis.amnamethai}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>

                                        <Form.Item label='เลขโฉนดที่ดิน' name='numberLand'>
                                            <Input type='text' disabled
                                                onChange={(e) => setDataPostLand({ ...dataPostLand, numberLand: e.target.value })}></Input>
                                        </Form.Item>

                                        {/* <Form.Item label='ตรวจสอบ'>
                                            <Button type="primary" onClick={handleCheckLand}>ตรวจสอบ</Button>
                                        </Form.Item> */}

                                        <Form.Item label='เลขที่ดินหรือระวาง' name='numberLandLawang'>
                                            <Input type='text' disabled style={{ color: 'black' }}
                                                onChange={(e) => setDataPostLand({ ...dataPostLand, numberLandLawang: e.target.value })}></Input>
                                        </Form.Item>

                                        <Form.Item label='พื้นที่ไร่' name='rai'>
                                            <Input type='text' suffix="ไร่" disabled style={{ color: 'black' }}
                                                onChange={(e) => setDataPostLand({ ...dataPostLand, rai: parseInt(e.target.value) })}></Input>
                                        </Form.Item>

                                        <Form.Item label='พื้นที่งาน' name='workArea'>
                                            <Input type='text' suffix="งาน" disabled style={{ color: 'black' }}
                                                onChange={(e) => setDataPostLand({ ...dataPostLand, workArea: parseInt(e.target.value) })}></Input>
                                        </Form.Item>

                                        <Form.Item label='พื้นที่ตารางวา' name='squareWaArea'>
                                            <Input type='text' suffix="ตารางวา" disabled style={{ color: 'black' }}
                                                onChange={(e) => setDataPostLand({ ...dataPostLand, squareWaArea: parseInt(e.target.value) })}></Input>
                                        </Form.Item>

                                        <Form.Item label='ราคาประเมินที่ดิน' name='landPrice'>
                                            <Input type='text' suffix="บาทต่อตารางวา" disabled style={{ color: 'black' }}
                                                onChange={(e) => setDataPostLand({ ...dataPostLand, landPrice: parseInt(e.target.value) })}></Input>
                                        </Form.Item>

                                        <Form.Item label='ราคารวม' name='resultLandPrice'>
                                            <Input type='text' suffix="บาท" disabled style={{ color: 'black' }}
                                                onChange={(e) => setDataPostLand({ ...dataPostLand, resultLandPrice: parseInt(e.target.value) })}></Input>
                                        </Form.Item>


                                        {/* <Form.Item label='เพิ่มรูปภาพที่ดิน'>
                                            <Upload
                                                multiple={true}
                                                listType="picture-card"
                                                fileList={fileList}
                                                onPreview={handlePreview}
                                                onChange={handleChange}
                                                beforeUpload={() => false}
                                            >
                                                {fileList.length >= 10 ? null : (
                                                    <div>
                                                        <PlusOutlined />
                                                        <div className="ant-upload-text">upload</div>
                                                    </div>
                                                )}
                                            </Upload>
                                            <Modal open={previewVisible} onCancel={handleCancel}>
                                                <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                            </Modal>
                                        </Form.Item>

                                        <Form.Item
                                            label={
                                                <div style={{ whiteSpace: 'pre-line', height: '4rem' }}>
                                                    เพิ่มรูปภาพโฉนด/ใบแบ่งใบต่อ/ระวาง
                                                </div>}
                                        >
                                            <Upload
                                                multiple={true}
                                                listType="picture-card"
                                                fileList={fileListBook}
                                                onPreview={handlePreview}
                                                onChange={handleChangeDeed}
                                                beforeUpload={() => false}
                                            >
                                                {fileList.length >= 10 ? null : (
                                                    <div>
                                                        <PlusOutlined />
                                                        <div className="ant-upload-text">upload</div>
                                                    </div>
                                                )}
                                            </Upload>
                                            <Modal open={previewVisible} onCancel={handleCancel}>
                                                <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                            </Modal>
                                        </Form.Item> */}
                                    </>
                                )}
                            </div>

                            <div style={{ margin: 5 }}>
                                <Form.Item label='ประเภทขอกู้ที่ดิน' name='productLoanLandType'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <Select
                                        // disabled={!sell}
                                        placeholder={'เลือก'}
                                        onChange={(value) => { handleChangeLandType(value) }}
                                        options={productLoanTypeLand}
                                    />
                                </Form.Item>

                                {sell === true ?
                                    <>
                                        <Form.Item label='ยอดจัด' name='loanAmounttLand'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input !'
                                                },]}>
                                            <InputNumber
                                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                size="large"
                                                style={{ width: '100%', color: 'black' }}
                                                // onChange={(value) => setMoneyLand({ ...moneyLand, loanAmounttLand: parseInt(value) })}
                                                onChange={(value) => { handleChangeLoanAmounttLand(value) }}
                                            />
                                        </Form.Item>
                                        <Form.Item>
                                            <span style={{ color: 'red' }}><u>ยอดจัดขั้นต่ำ 50,000 บาท</u></span>
                                        </Form.Item>
                                        <Form.Item label='อัตราดอกเบี้ย' name='interestRateLand'>
                                            <Input type='number' suffix="% +VAT" disabled defaultValue={1.1}
                                                onChange={(e) => setMoneyLand({ ...moneyLand, interestRateLand: parseFloat(e.target.value) })} />
                                        </Form.Item>


                                        <Form.Item label='ค่าธรรมเนียม' name='fee'>
                                            <Input type='number' disabled suffix="บาท" style={{ color: 'black' }}
                                                onChange={(e) => setMoneyLand({ ...moneyLand, fee: parseFloat(e.target.value) })} />
                                        </Form.Item>

                                        {/* <Form.Item label='จำนวนงวด' name='loanLandTerm'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input !'
                                                },]}>
                                            <Input type='number'
                                                onChange={(e) => setMoneyLand({ ...moneyLand, loanLandTerm: parseInt(e.target.value) })} />
                                        </Form.Item> */}

                                        <Form.Item label='จำนวนงวด' name='loanLandTerm'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input !'
                                                },]}>
                                            <Select
                                                placeholder={'เลือก'}
                                                onChange={(value) => setMoneyLand({ ...moneyLand, loanLandTerm: value })}
                                                label="จำนวนงวด"
                                                options={[
                                                    { label: '12', value: 12 }, { label: 18, value: 18 }, { label: '24', value: 24 },
                                                    { label: '30', value: 30 }, { label: 36, value: 36 }, { label: '42', value: 42 },
                                                    { label: '48', value: 48 }, { label: 54, value: 54 }, { label: '60', value: 60 },
                                                    { label: '66', value: 66 }, { label: 72, value: 72 }, { label: '78', value: 78 },
                                                    { label: '84', value: 84 }, { label: 90, value: 90 }, { label: '96', value: 96 },
                                                    { label: '102', value: 102 }, { label: 108, value: 108 }, { label: '114', value: 114 },
                                                    { label: '120', value: 120 }
                                                ]}
                                            >
                                            </Select>
                                        </Form.Item>

                                        <Form.Item label='งวดละ' name='monthlyPaymentLand'>
                                            <Input type='number' suffix="บาท" disabled style={{ color: 'black' }}
                                                onChange={(e) => setMoneyLand({ ...moneyLand, monthlyPaymentLand: parseInt(e.target.value) })} />
                                        </Form.Item>

                                        <Form.Item label='ดอกเบี้ย' name='interesttLand'>
                                            <Input type='number' suffix="บาท" disabled style={{ color: 'black' }}
                                                onChange={(e) => setMoneyLand({ ...moneyLand, interesttLand: parseInt(e.target.value) })} />
                                        </Form.Item>


                                        <Form.Item label='รวมราคา' name='installmentWithInterestLand'>
                                            <Input disabled style={{ color: 'black' }} suffix="บาท" />
                                        </Form.Item>

                                        {moneyLand && (moneyLand?.productLoanLandType === 'ย้ายไฟแนนซ์' || moneyLand?.productLoanLandType === 'Pre-Aaprove-ย้ายไฟแนนซ์') ? (
                                            <>
                                                <Form.Item label='ปิดไฟแนนซ์จาก' name='issuno'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <Input
                                                        type='text'
                                                        style={{ width: '100%', color: 'black' }}
                                                        onChange={(e) => setMoneyOldLand({ ...moneyOldLand, issuno: e.target.value.trim() })}
                                                    />
                                                </Form.Item>
                                                <Form.Item label='ยอดปิดไฟแนนซ์' name='oldLandLoanAmount'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <InputNumber
                                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                        size="large"
                                                        style={{ width: '100%', color: 'black' }}
                                                        onChange={(value) => setMoneyOldLand({ ...moneyOldLand, oldLandLoanAmount: parseInt(value) })}
                                                    />
                                                </Form.Item>

                                                <Form.Item label='ค่างวด/เดือน' name='oldLandMonthlyPayment'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <InputNumber
                                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                        size="large"
                                                        style={{ width: '100%', color: 'black' }}
                                                        onChange={(value) => setMoneyOldLand({ ...moneyOldLand, oldLandMonthlyPayment: parseInt(value) })}
                                                    />
                                                </Form.Item>

                                                <Form.Item label='จำนวนผ่อนกี่งวด' name='oldLandLoanTerm'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <InputNumber
                                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                        size="large"
                                                        style={{ width: '100%', color: 'black' }}
                                                        onChange={(value) => setMoneyOldLand({ ...moneyOldLand, oldLandLoanTerm: parseInt(value) })}
                                                    />
                                                </Form.Item>

                                            </>
                                        ) : null}
                                    </>
                                    : null}
                                <Form.Item label='ผู้เสนอเคส' name='proposalBy' style={{ textAlign: 'center' }} >
                                    <Input disabled style={{ color: 'black', width: '90%' }} />
                                </Form.Item>
                            </div>
                        </Row>
                        <Row justify={'center'}>
                            <Col span={22}>
                                <span><b style={{ fontSize: '25' }}>สตอรี่รายละเอียดที่ดิน</b></span> <p />
                                <Form.Item name="storyLand" label="">
                                    <TextArea
                                        name='storyLand'
                                        style={{ color: 'blue' }}
                                        rows={8}
                                        disabled                                        
                                    >
                                    </TextArea>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider />
                        <Row justify={'center'}>
                            <Space>
                                <Button type="primary" onClick={onChangeBack} style={{ background: '#bfbfbf' }}>ย้อนกลับ</Button>
                                <Button type="primary" onClick={() => { showConfirmCancel() }} style={{ background: "red" }}>ยกเลิก</Button>
                                <Button type="primary" htmlType="submit" style={{ background: "green" }}>ต่อไป</Button>
                            </Space>
                        </Row>
                    </Form>
                </Spin>
            </Card>
        </Row>
    )
}
