import React from "react";
import { Routes, Route } from "react-router-dom"
import View_imagePn from './modal_img/View_imagePn';

export default function Main_imagePn() {
    return (
        <Routes>
            <Route path="/" element={<View_imagePn />} />
        </Routes>
    )
}
