import React from "react";
import SearchData from "./searchData";
function Main() {
  return (
    <>
      <SearchData></SearchData>
    </>
  );
}
export default Main;
