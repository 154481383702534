import React, { useState, useEffect } from "react";
import axios from "axios";
import Dayjs from "dayjs";
import { Form, Input, DatePicker, Button } from "antd";
import "./css/stepStatus.css";
import { loaddetaileditdate, recovercase } from "../../file_mid/all_api";
const { TextArea } = Input;

const DataModal = ({ currentRecord }) => {
  //เก็บ Datailid
  const [datailid, setDatailid] = useState([]);
  const onChangeDate = (date, dateString) => {
    console.log(date, dateString);
  };
  const onChangeTextArea = (value) => {
    console.log("Change:", value.target.value);
  };
  //form-Error
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  //form-Finish
  const onFinish = async (values) => {
    //console.log("Success:", values);
    const inputNoteDate = Dayjs(values.inputNoteDate).format("DD/MM/YYYY HH:mm:ss");
    const inputNoteText = values.inputNoteText;
    //console.log("currentRecord",currentRecord.CONTNO);
    const CONTNO = currentRecord.CONTNO;
    const idCarsApproveCeo = currentRecord.id;
    //idCarsApproveCeo carId loanId reLoanId
    console.log("datailid", datailid);
    const carId = datailid.car.carId;
    const loanId = datailid.car.loanId;
    const reLoanId = datailid.car.carLoanDetailsRe.reLoanId;
    const inputBy = localStorage.getItem("username");
    const proposalBy = datailid.car.carLoanDetails.proposalBy;
    //console.log("proposalBy11", proposalBy);
    if (values) {
      await axios
        .post(recovercase, {
          CONTNO: CONTNO,
          idCarsApproveCeo: idCarsApproveCeo,
          carId: carId,
          loanId: loanId,
          reLoanId: reLoanId,
          newOfferDate: inputNoteDate,
          note: inputNoteText,
          inputBy: inputBy,
          proposalBy: proposalBy,
        })
        .then(function (response) {
          console.log(response);
          alert("เพิ่มเวลา สัญญา" + CONTNO + " สำเร็จ");
          window.location.reload();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };
  //ดึง id
  useEffect(() => {
    const fetchDataId = async () => {
      try {
        const res = await axios.post(
          loaddetaileditdate,
          { CONTNO: currentRecord.CONTNO }
        );
        console.log("res.data", res.data);
        setDatailid(res.data);
      } catch (err) {
        console.log(err.message);
      }
    };
    fetchDataId();
  }, [currentRecord]);

  return (
    <div>
      <p>เลขสัญญา: {currentRecord.CONTNO}</p>
      <Form
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item style={{ marginBottom: 10 }}>
          <Form.Item
            name="inputNoteDate"
            label="เลือกวันที่"
            style={{ marginBottom: 10 }}
            rules={[
              {
                required: true,
                message: "เลือกวันที่ก่อน!",
              },
            ]}
          >
            <DatePicker onChange={onChangeDate} />
          </Form.Item>
          <Form.Item
            name="inputNoteText"
            label="กรอกหมายเหตุ"
            rules={[
              {
                required: true,
                message: "กรอกหมายเหตุก่อน!",
              },
            ]}
            style={{ marginBottom: 10 }}
          >
            <TextArea
              showCount
              maxLength={100}
              onChange={onChangeTextArea}
              placeholder="กรอกหมายเหตุ"
              style={{
                height: 100,
                resize: "none",
              }}
            />
          </Form.Item>
          <center>
            <Button
              type="primary"
              htmlType="submit"
              style={{ backgroundColor: "green", borderColor: "green" }}
            >
              บันทึก
            </Button>
          </center>
        </Form.Item>
      </Form>
    </div>
  );
};

export default DataModal;
