import React, { useState, useEffect } from "react";
import { HomeFilled, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Row, Table, Spin, Space, Card } from 'antd';
//import { useDispatch } from 'react-redux';
import axios from "axios";
import dayjs from "dayjs";
import '../css/Media.css'
import ModalInfo from './model/ModalInfo';
import Cancel from './model/Cancel';
import { stopWork } from "../file_mid/all_api";

export default function Main() {
  // const user = localStorage.getItem('username');
  const token = localStorage.getItem('token')
  // const dispatch = useDispatch()
  const [isModalCancel, setIsModalCancel] = useState(false);
  const [isModalInfo, setIsModalInfo] = useState(false);
  const [arrayTable, setArrayTable] = useState([]);
  const [editData, setEditData] = useState()
  const [editDataCancel, setEditDataCancel] = useState()
  // const { confirm } = Modal;
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (isModalInfo === false && isModalCancel === false) {
      loadData()
    }
  }, [isModalInfo, isModalCancel])

  const loadData = async () => {
    setLoading(true)
    const tk = JSON.parse(token)
    const headers = {
      "Authorization": `Bearer ${tk}`,
      "Menu": JSON.stringify("5")
    }
    await axios.get(stopWork, { headers: headers })
      .then((res) => {
        console.log("Res", res.data)
        setArrayTable(res.data)
        setLoading(false)
      }).catch((err) => console.log(err))
    setLoading(false)
  }

  const columns = [
    {
      title: "ลำดับ",
      dataIndex: "index",
      key: 'index',
      width: "2%",
      align: 'center',
      render: (text, object, index) => index + 1
    },
    {
      title: <Row justify={"center"}><Col>รหัสพนักงาน</Col></Row>,
      dataIndex: "name",
      key: 'name',
      align: 'center',
      // fixed: true,
      width: "6%",

      render: (text, record) => (
        // console.log("record: " + record.reviewes_by),
        <b> {record.name} </b>
      ),
    },
    {
      title: <Row justify={"center"}><Col>ชื่อเล่น</Col></Row>,
      dataIndex: "nickname",
      key: 'nickname',
      align: 'center',
      // fixed: true,
      width: "7%",

      render: (text, record) => (
        // console.log("record: " + record.nickname),
        <b> {record.nickname} </b>
      ),
    },
    {
      title: <Row justify={"center"}><Col>ทีม</Col></Row>,
      dataIndex: "team",
      key: 'team',
      align: 'center',
      width: "5%",

    },
    {
      title: <Row justify={"center"}><Col>วันที่รับหยุดงาน</Col></Row>,
      dataIndex: "carInput",
      key: 'carInput',
      align: 'center',
      //fixed: true,
      width: "10%",

      render: (text, record) => (
        <Space size="middle">
          {
            record.stopDay !== null ?
              <Space>
                <b style={{ fontSize: '15px' }}>{dayjs(record.stopDay).format("DD-MM-YYYY")}</b>
              </Space>
              : "-"
          }
        </Space>
      ),
    },
    {
      title: <Row justify={"center"}><Col>วันที่เริ่มรับงาน</Col></Row>,
      dataIndex: "carInput",
      key: 'carInput',
      align: 'center',
      //fixed: true,
      width: "10%",

      render: (text, record) => (
        <Space size="middle">
          {
            record.onDay !== null ?
              <Space>
                <b style={{ fontSize: '15px' }}>{dayjs(record.onDay).format("DD-MM-YYYY")}</b>
              </Space>
              : "-"
          }
        </Space>
      ),
    },
    {
      title: "Action",
      align: 'center',
      width: "12.5%",
      render: (record) => (
        <Space size="middle">
          <Button style={{ backgroundColor: "blue", color: 'white' }}
            onClick={() => {
              //console.log("record", record)
              setIsModalInfo(true)
              // let dataCar = { carId: record.carId, typeLoan: record.car, loanId: record.loanId }
              // dispatch(addGuarantor(dataCar))
              setEditData(record)
            }}>ลงวันหยุด</Button>
          <Button style={{ backgroundColor: "red", color: 'white' }}
            onClick={() => {
              //console.log("record", record)
              setIsModalCancel(true)
              // let dataCar = { carId: record.carId, typeLoan: record.car, loanId: record.loanId }
              // dispatch(addGuarantor(dataCar))
              setEditDataCancel(record)
            }}> ยกเลิกวันหยุด </Button>
        </Space>
      ),
    },
  ];

  return <>
    <Card>
      <Row gutter={32} style={{ textAlign: 'center' }}>
        <Col>
          <Spin spinning={loading} size='large' tip=" Loading... ">
            <div className='text-center'>
              <h2><u>ตารางการหยุดรับงาน</u></h2>
            </div>


            <Row gutter={32} style={{ marginTop: '15px' }}>
              <Col span={24}>

                <Table
                  rowKey={(record) => record.uid}
                  scroll={{
                    x: 1500,
                    y: 400,
                  }}
                  dataSource={arrayTable}
                  columns={columns}
                >
                </Table>
              </Col>
            </Row>

            <Col span={24} style={{ textAlign: 'center' }}>
              <Space>
                <Button href="/" type="primary"><HomeFilled />หน้าหลัก</Button>
              </Space>
            </Col>
          </Spin>
        </Col>
      </Row>
    </Card >
    {
      isModalInfo ?
        <ModalInfo
          open={isModalInfo}
          close={setIsModalInfo}
          dataFromTable={editData}
        />
        : null
    }
    {
      isModalCancel ?
        <Cancel
          open={isModalCancel}
          close={setIsModalCancel}
          dataFromTable={editDataCancel}
        />
        : null
    }
  </>;
}
