import React, { useState, useEffect } from 'react'
import { Button, Form, Input, Select, Divider, Row, Col, Modal, InputNumber, Space, message, Spin } from 'antd';
import { Option } from 'antd/es/mentions';
import axios from 'axios';
import dayjs from 'dayjs';
import { updateDetail, customerbroker } from '../../file_mid/all_api';

export default function EditBrokerModal({ open, close, typeAmt, shootdata }) {
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const [getData, setGetData] = useState(shootdata);
    const [typeAmts, setTypeAmts] = useState(typeAmt);
    const [loading, setLoading] = useState(false);
    const [customerBroker, setCustomerBroker] = useState([]);

    useEffect(() => {
        console.log(typeAmt, shootdata)
        setGetData(shootdata)
        setTypeAmts(typeAmt)
        if (typeAmt === 0) {
            loadCustomerBroker()
            form.setFieldsValue({
                idCard: shootdata?.idCard
            })
        }
        if (typeAmt === 630) {
            form.setFieldsValue({
                amount: shootdata?.payamt
            })
        }
        if (typeAmt === 627) {
            form.setFieldsValue({
                approvalService: shootdata?.payamt
            })
        }
    }, [shootdata])

    const SuccSend = (placement) => {
        messageApi.success({
            message: <b>บันทึกข้อมูลสำเร็จ</b>,
            placement,
        });
    }
    const ErrSend = (placement) => {
        messageApi.error({
            message: <b>เกิดข้อผิดพลาดในการอัปเดตข้อมูล</b>,
            placement,
        });
    }
    const loadCustomerBroker = async () => {
        setLoading(true)
        await axios.get(customerbroker)
            // await axios.get(`http://localhost:8080/api-broker/customerbroker`)
            .then(response => {
                // console.log("response.data", response.data)
                setCustomerBroker(response.data);
            }).catch(error => {
                console.error(error);
            });
        setLoading(false)
    }

    //elements
    const handleCancel = () => { //กดปิด Modal
        close(false);
    };

    //handleSubmit
    const handleSubmit = async () => {
        // console.log("getData: ", getData);
        setLoading(true)
        await axios.post(updateDetail, getData)
            .then(response => {
                // console.log("ok data", response.data)
                SuccSend('top')
                close(false);
            }).catch(error => {
                ErrSend('top')
                console.error(error);
            });
        setLoading(false)
    }

    const onBroker = async (value) => {
        console.log("value", value)
        setGetData({ ...getData, idCustomerBroker: value })
    }

    return (
        <>
            <Modal
                open={open}
                onCancel={handleCancel}
                centered
                width={600}
                footer={[null]}
            >
                <Spin spinning={loading} size='large' tip=" Loading... ">
                    <Form
                        form={form}
                        name='editdata'
                        labelCol={{
                            span: 12,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                        style={{
                            maxWidth: '100%',
                        }}
                        onFinish={handleSubmit}
                    >
                        <Row>
                            <Col span={24} style={{ fontSize: '30px', textAlign: 'center' }} ><u>แก้ไขข้อมูล</u></Col>
                        </Row>
                        <Divider />
                        <Row justify={'center'}>
                            <aside style={{ width: '85%', textAlign: 'center' }}>
                                {/* <Col span={24} style={{ textAlign: 'center' }}> */}
                                {
                                    typeAmt === 0 ?
                                        <Form.Item label='ผู้แนะนำ'
                                            name='idCard'
                                            style={{ lineHeight: '32px', textAlign: 'center', width: '200px' }}
                                        >
                                            <Select
                                                showSearch
                                                style={{ width: '240px', height: '40px' }}
                                                onChange={onBroker}
                                                optionFilterProp="children"
                                                placeholder="Search to Select"
                                                filterOption={(inputValue, option) =>
                                                    option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                                                }
                                            >
                                                {customerBroker?.map((e) => {
                                                    return (
                                                        <Select.Option key={e.idCard} value={e.id}>
                                                            {e.snam} {e.firstname} {e.lastname}
                                                        </Select.Option>
                                                    )
                                                })}
                                            </Select>
                                        </Form.Item>
                                        : null
                                }
                                {
                                    typeAmt === 630 ?
                                        <Form.Item label='จำนวนเงิน' name='amount'
                                            style={{ textAlign: 'center', width: '200px' }}
                                        >
                                            <InputNumber
                                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                size="large"
                                                style={{ width: '240px', height: '40px', color: 'black' }}
                                                onChange={(value) => {
                                                    setGetData({ ...getData, payamt: parseFloat(value) });
                                                }}
                                            />
                                        </Form.Item>
                                        : null
                                }
                                {
                                    typeAmt === 627 ?
                                        <Form.Item label='ค่าอนุมัติ/บริการ' name='approvalService'
                                            style={{ textAlign: 'center', width: '200px' }}
                                        >
                                            <InputNumber
                                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                size="large"
                                                style={{ width: '240px', height: '40px', color: 'black' }}
                                                onChange={(value) => {
                                                    setGetData({ ...getData, payamt: parseFloat(value) });
                                                }}
                                            />
                                        </Form.Item>
                                        : null
                                }
                                <Divider />
                                {/* </Col> */}
                            </aside>
                        </Row>
                        <Row>
                            <Col span={24} style={{ textAlign: 'center' }}>
                                <Space>
                                    <Button type="primary" onClick={handleCancel} style={{ background: "red" }}>ยกเลิก</Button>
                                    <Button type="primary" htmlType="submit" style={{ background: "green" }}>บันทึก</Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
                {contextHolder}
            </Modal>
        </>
    )
}
