import React from "react";
import { Tabs, Card } from "antd";
import TabA from "./tabA";
import TabB from "./tabB";
const TabMain = ({ dataApiCheckLocatCar, dataApiCheckLocatLand }) => {
  const onChange = (key) => {
    console.log(key);
  };
  const items = [
    {
      key: "1",
      label: "สรุปยอดรวมรถตามสาขา",
      children: <TabA dataApiCheckLocatCar={dataApiCheckLocatCar}></TabA>,
    },
    {
      key: "2",
      label: "สรุปยอดรวมที่ดินตามสาขา",
      children: <TabB dataApiCheckLocatLand={dataApiCheckLocatLand}></TabB>,
    },
    {
      key: "3",
      label: "สรุปเคาะเคสรถตามสาขา",
      // children: (
      //   <TabC
      //     dataApiCheckLocatCar={dataApiCheckLocatCar}
      //   ></TabC>
      // ),
    },
    {
      key: "4",
      label: "สรุปเคาะเคสที่ดินตามสาขา",
      // children: (
      //   <TabD
      //     dataApiCheckLocatCar={dataApiCheckLocatCar}
      //   ></TabD>
      // ),
    },
  ];
  return (
    <>
      <Card className="cardSearch">
        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
      </Card>
    </>
  );
};
export default TabMain;
