import React, { useState, useEffect } from 'react'
import { Button, Form, Input, Select, Divider, Row, Col, Modal, Checkbox, message } from 'antd';
import { Option } from 'antd/es/mentions';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { createaddress, getdistrict, getsubdistrict, getzipcodes, loadProvinces ,getprovinceall, getdistrictall, getsubdistrictsall} from '../../file_mid/all_api';

function ModalAddDataAddress({ open, close, adddata, checkaddress, setcheckaddress }) {
    const [messageApi, contextHolder] = message.useMessage();
    //useSelector
    const idCustomer = useSelector((state) => (state.customers.data.customerId))
    //useForm
    const [formadddata] = Form.useForm();
    //states
    const [addressadd, setaddressadd] = useState({
        customerId: idCustomer,
        houseRegistrationNumber: '',
        houseNumber: '',
        soi: '',
        road: '',
        village: '',
        subdistrict: '',
        district: '',
        province: '',
        zipCode: '',
        addressStatus: false,
        addressOnIdcard: false,
        houseRegistration: false,
        workAddress: false,
        OtherAddress: false,
        currentAddress: false,
        sendDocuments: false,
    });
    const [provinces, setProvinces] = useState([]);
    const [district, setDistricts] = useState([]);
    const [subdistricts, setSubdistricts] = useState([]);
    const [zipcode, setZipCodes] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        axios.get(loadProvinces)
            .then((res) => {
                setProvinces(res.data)
            }).catch((err) => {
                console.error(err);
            })
    }, [addressadd])

    const handleProvinceChange = (value, key) => {
        console.log(key, value)
        setLoading(true)
        formadddata.setFieldsValue({ //set form values
            district: '',
            subdistrict: '',
            zipCode: '',
        })
        setaddressadd({ ...addressadd, province: value });
        axios.get(getdistrict + key.key)
            .then(response => {
                setLoading(false)
                setDistricts(response.data);
            }).catch(error => {
                console.error(error);
            });
    };

    const handleDistrictChange = (value, key) => {
        console.log(key, value)
        setLoading(true)
        formadddata.setFieldsValue({ //set form values
            subdistrict: '',
            zipCode: '',
        })
        setaddressadd({ ...addressadd, district: value });
        axios.get(getsubdistrict + key.key)
            .then(response => {
                setLoading(false)
                setSubdistricts(response.data);
            }).catch(error => {
                console.error(error);
            });
    };
    const handleSubdistrictChange = (value, key) => {
        console.log(key, value)
        setLoading(true)
        formadddata.setFieldsValue({ //set form values
            zipCode: '',
        })
        setaddressadd({ ...addressadd, subdistrict: value });
        axios.get(getzipcodes + key.key)
            .then(response => {
                setLoading(false)
                setZipCodes(response.data);
            }).catch(error => {
                console.error(error);
            });
    };

    const handleZipcodeChange = (value, key) => {
        console.log(key, value)
        setaddressadd({ ...addressadd, zipCode: value });
    };

    //checkBox status ของที่อยู่
    const handleCancel = () => {
        close(false);
    };
    //ปุ่ม Submit in Modal Add Data Address
    const handleSubmit = async () => {
        console.log("address submit: ", addressadd);
        await axios.post(createaddress, addressadd)
            .then((res) => {
                if (res.status === 200) {
                    messageApi.open({
                        type: 'success',
                        content: 'บันทึกสำเร็จ',
                    });
                } else {
                    messageApi.open({
                        type: 'error',
                        content: 'บันทึกไม่สำเร็จ',
                    });
                }
            })
            .catch((err) => console.log(err))
        // ...pre เป็นการดึงข้อมูลก่อนหน้าที่อยู่ใน store และ ...addressadd โยนค่าที่กรอกใหม่จาก Input Form เข้าไปใน setDataArray 
        //จะได้ข้อมูลชุดใหม่ขึ้นมาแสดงตามที่กรอกใหม่
        if (checkaddress === "0") {
            setaddressadd(pre => [...pre, { ...addressadd }]);
            adddata({ addressadd, provinces })
            setcheckaddress("1")
            close(false)
        } else {
            setaddressadd(pre => [...pre, { ...addressadd }]);
            adddata({ addressadd, provinces })
            setcheckaddress("1")
            close(false)
        }
    }

    // data input Form 
    const handleInputDataAddress = (e) => {
        const { name, value } = e.target;
        setaddressadd({
            ...addressadd, [name]: value,
        }) // เป็นการกระจายข้อมูลเข้าไปตามชื่อ  name
    }

    return (
        <>
            {/* Modal เพิ่มข้อมูล */}
            <Modal
                open={open}
                style={{ background: '#2f54eb' }}
                centered
                onCancel={handleCancel}
                width={600}
                footer={[
                    <Row
                        justify={'center'}
                    >
                        <Button key="back" onClick={handleCancel}>
                            ยกเลิก
                        </Button>
                        <Button
                            type="primary"
                            onClick={handleSubmit}
                        >
                            บันทึก
                        </Button>
                    </Row>
                ]}
            >
                <Form
                    form={formadddata}
                    onFinish={handleSubmit}
                    name='adddata'
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    style={{
                        maxWidth: '100%',
                    }}
                >
                    <Row
                        justify={'center'}
                    >
                        <h3 style={{ marginBottom: 0 }}>
                            <u>เพิ่มที่อยู่</u>
                        </h3>
                    </Row>
                    <Row
                        gutter={{ sx: 8, sm: 16, md: 24, lg: 32 }}
                    >
                        <Col className='gutter-row' span={24}>
                            <Form.Item
                                name='houseRegistrationNumber'
                                label="เลขทะเบียนบ้าน"
                            >
                                <Input
                                    name='houseRegistrationNumber'
                                    placeholder='เลขทะเบียนบ้าน'
                                    onChange={handleInputDataAddress}
                                />
                            </Form.Item>
                            <Form.Item
                                name='houseNumber'
                                label='บ้านเลขที่'
                            >
                                <Input
                                    name='houseNumber'
                                    placeholder='บ้านเลขที่'
                                    onChange={handleInputDataAddress}
                                />
                            </Form.Item>
                            <Form.Item
                                name='soi'
                                label='ซอย'
                            >
                                <Input
                                    name='soi'
                                    placeholder='ซอย'
                                    onChange={handleInputDataAddress}
                                />
                            </Form.Item>
                            <Form.Item
                                name='road'
                                label='ถนน'
                            >
                                <Input
                                    name='road'
                                    placeholder='ถนน'
                                    onChange={handleInputDataAddress}
                                />
                            </Form.Item>
                            <Form.Item name='village' label='หมู่บ้าน'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input !',
                                    },]}>
                                <Input name='village' placeholder="หมู่บ้าน" onChange={handleInputDataAddress} />
                            </Form.Item>
                            <Form.Item
                                name='province'
                                label='จังหวัด'
                            >
                                <Select
                                    showSearch
                                    loading={loading}
                                    name='province'
                                    placeholder="จังหวัด"
                                    onChange={handleProvinceChange}
                                >
                                    {provinces.map((pro, index) => (
                                        <Option key={pro.provinceId} value={pro.provinceName}>
                                            {pro.provinceName}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name='district'
                                label='อำเภอ'
                            >
                                <Select
                                    showSearch
                                    loading={loading}
                                    name='district'
                                    placeholder="อำเภอ"
                                    onChange={handleDistrictChange}
                                >
                                    {district.map((dis, index) => (
                                        <Option key={dis.districtId} value={dis.districtName}>
                                            {dis.districtName}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name='subdistrict'
                                label='ตำบล'
                            >
                                <Select
                                    showSearch
                                    loading={loading}
                                    name='subdistrict'
                                    placeholder='ตำบล'
                                    onChange={handleSubdistrictChange}
                                >
                                    {subdistricts.map((subdis, index) => (
                                        <Option key={subdis.subdistrictId} value={subdis.subdistrictName}>
                                            {subdis.subdistrictName}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name='zipCode'
                                label='รหัสไปรษณีย์'
                            >
                                <Select
                                    name='zipCode'
                                    placeholder="รหัสไปรษณีย์"
                                    onChange={handleZipcodeChange}
                                >
                                    {zipcode.map((zip, index) => (
                                        <Option key={zip.zipcodeId} value={zip.zipCode}>
                                            {zip.zipCode}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Divider><b>เป็นที่อยู่</b></Divider>
                            <Row
                                gutter={32}
                                justify={'center'}
                            >
                                <Checkbox
                                    key={0}
                                    checked={addressadd.addressStatus}
                                    name='addressStatus'
                                    onChange={(e) => {
                                        setaddressadd({ ...addressadd, addressStatus: e.target.checked });
                                    }}
                                >
                                    "ใช้งานอยู่"
                                </Checkbox>
                                <Checkbox
                                    key={1}
                                    checked={addressadd.addressOnIdcard}
                                    name='addressOnIdcard'
                                    onChange={(e) => {
                                        console.log(e)
                                        setaddressadd({ ...addressadd, addressOnIdcard: e.target.checked });
                                    }}
                                >
                                    "ตามบัตรประชาชน"
                                </Checkbox>
                                <Checkbox
                                    key={2}
                                    checked={addressadd.houseRegistration}
                                    name='houseRegistration'
                                    onChange={(e) => {
                                        setaddressadd({ ...addressadd, houseRegistration: e.target.checked });
                                    }}
                                >
                                    "ตามทะเบียนบ้าน"
                                </Checkbox>
                                <Checkbox
                                    key={3}
                                    checked={addressadd.workAddress}
                                    name='workAddress'
                                    onChange={(e) => {
                                        setaddressadd({ ...addressadd, workAddress: e.target.checked });
                                    }} setaddressadd
                                >
                                    "ตามสถานที่ทำงาน"
                                </Checkbox>
                                <Checkbox
                                    key={4}
                                    checked={addressadd.otherAddress}
                                    name='otherAddress'
                                    onChange={(e) => {
                                        setaddressadd({ ...addressadd, otherAddress: e.target.checked });
                                    }}
                                >
                                    "อื่นๆ"
                                </Checkbox>
                                <Checkbox
                                    key={5}
                                    checked={addressadd.currentAddress}
                                    name='currentAddress'
                                    onChange={(e) => {
                                        setaddressadd({ ...addressadd, currentAddress: e.target.checked });
                                    }}
                                >
                                    "ที่อยู่ปัจจุบัน"
                                </Checkbox>
                                <Checkbox
                                    key={6}
                                    checked={addressadd.sendDocuments}
                                    name='sendDocuments'
                                    onChange={(e) => {
                                        setaddressadd({ ...addressadd, sendDocuments: e.target.checked });
                                    }}
                                >
                                    "ที่อยู่ส่งเอกสาร"
                                </Checkbox>
                            </Row>
                            <Divider></Divider>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    )
};

export default ModalAddDataAddress
