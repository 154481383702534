
const oppgre =
    [
        {
            label: 'ทั้งหมด', value: 'ทั้งหมด',
        },
        {
            label: 'ยื่นคำร้อง', value: 'ยื่นคำร้อง',
        },
        {
            label: 'รับคำร้อง', value: 'รับคำร้อง',
        },
        {
            label: 'ปฏิเสธคำร้อง', value: 'ปฏิเสธคำร้อง',
        },
        {
            label: 'คำร้องไม่ถูกต้อง', value: 'คำร้องไม่ถูกต้อง',
        },
        {
            label: 'เสนอเคสรีแล้ว', value: 'เสนอเคสรีแล้ว',
        },
        {
            label: 'รอธุรการรับ', value: 'รอธุรการรับ',
        },
        {
            label: 'รอวิเคราะห์', value: 'รอวิเคราะห์',
        },
        {
            label: 'ผ่านการวิเคราะห์', value: 'ผ่านการวิเคราะห์',
        },
        {
            label: 'ไม่ผ่านการวิเคราะห์', value: 'ไม่ผ่านการวิเคราะห์',
        },
        {
            label: 'รออนุมัติ', value: 'รออนุมัติ',
        },
        {
            label: 'รออนุมัติราคารถ', value: 'รออนุมัติราคารถ',
        },
        {
            label: 'อนุมัติ', value: 'อนุมัติ',
        },
        {
            label: 'อนุมัติแบบมีเงื่อนไข', value: 'อนุมัติแบบมีเงื่อนไข',
        },
        {
            label: 'ไม่อนุมัติ', value: 'ไม่อนุมัติ',
        },
        {
            label: 'ลูกค้าคอนเฟิร์ม', value: 'ลูกค้าคอนเฟิร์ม',
        },
        {
            label: 'รอตรวจสอบเอกสาร', value: 'รอตรวจสอบเอกสาร',
        },
        {
            label: 'รอทำสัญญา', value: 'รอทำสัญญา',
        },
        {
            label: 'ลูกค้าปฏิเสธ', value: 'ลูกค้าปฏิเสธ',
        },
        {
            label: 'Reject', value: 'Reject',
        }
    ]
const op =
    [
        {
            label: 'ทั้งหมด', value: 'ทั้งหมด',
        },
        {
            label: 'รอธุรการรับ', value: 'รอธุรการรับ',
        },
        {
            label: 'รอวิเคราะห์', value: 'รอวิเคราะห์',
        },
        {
            label: 'ผ่านการวิเคราะห์', value: 'ผ่านการวิเคราะห์',
        },
        {
            label: 'ไม่ผ่านการวิเคราะห์', value: 'ไม่ผ่านการวิเคราะห์',
        },
        // {
        //   label: 'รอพิจารณา', value: 'รอพิจารณา',
        // },
        {
            label: 'รออนุมัติ', value: 'รออนุมัติ',
        },
        {
            label: 'รออนุมัติราคารถ', value: 'รออนุมัติราคารถ',
        },
        // {
        //   label: 'ผ่านพิจารณา', value: 'ผ่านพิจารณา',
        // },
        // {
        //   label: 'ไม่ผ่านพิจารณา', value: 'ไม่ผ่านพิจารณา',
        // },
        {
            label: 'อนุมัติ', value: 'อนุมัติ',
        },
        {
            label: 'อนุมัติแบบมีเงื่อนไข', value: 'อนุมัติแบบมีเงื่อนไข',
        },
        {
            label: 'ไม่อนุมัติ', value: 'ไม่อนุมัติ',
        },
        {
            label: 'ลูกค้าคอนเฟิร์ม', value: 'ลูกค้าคอนเฟิร์ม',
        },
        {
            label: 'รอตรวจสอบเอกสาร', value: 'รอตรวจสอบเอกสาร',
        },
        {
            label: 'รอทำสัญญา', value: 'รอทำสัญญา',
        },
        {
            label: 'ลูกค้าปฏิเสธ', value: 'ลูกค้าปฏิเสธ',
        },
        {
            label: 'Reject', value: 'Reject',
        }
    ]

const opre =
    [
        {
            label: 'ทั้งหมด', value: 'ทั้งหมด',
        },
        // {
        //     label: 'ลูกค้ายกเลิก', value: 'ลูกค้ายกเลิก',
        // },
        {
            label: 'ยื่นคำร้อง', value: 'ยื่นคำร้อง',
        },
        {
            label: 'รับคำร้อง', value: 'รับคำร้อง',
        },
        {
            label: 'ปฏิเสธคำร้อง', value: 'ปฏิเสธคำร้อง',
        },
        // {
        //     label: 'ลูกค้าปฏิเสธ', value: 'ลูกค้าปฏิเสธ',
        // },
        {
            label: 'ผ่านการวิเคราะห์', value: 'ผ่านการวิเคราะห์',
        },
        {
            label: 'รออนุมัติ', value: 'รออนุมัติ',
        },
        {
            label: 'อนุมัติ', value: 'อนุมัติ',
        },
        // {
        //     label: 'เสร็จสิ้น', value: 'เสร็จสิ้น',
        // },
        // {
        //     label: 'อนุมัติแบบมีเงื่อนไข', value: 'อนุมัติแบบมีเงื่อนไข',
        // },
        // {
        //     label: 'ไม่อนุมัติ', value: 'ไม่อนุมัติ',
        // },
    ]


const orderCar = [
    "รอธุรการรับ",
    "รออนุมัติราคารถ",
    "รอวิเคราะห์",
    "ผ่านการวิเคราะห์",
    "ไม่ผ่านการวิเคราะห์",
    "รออนุมัติ",
    "อนุมัติ",
    "ไม่อนุมัติ",
    "รอทำสัญญา",
    "ลูกค้าปฏิเสธ",
    "Reject",
    "ทั้งหมด",
]

const orderLand = [
    "รอพี่หนุ่มรับ",
    "รอธุรการรับ",
    "ขอเอกสารเพิ่มเติม",
    "รอวิเคราะห์",
    "ผ่านการวิเคราะห์",
    "ไม่ผ่านการวิเคราะห์",
    "รออนุมัติ",
    "อนุมัติ",
    "ไม่อนุมัติ",
    "รอทำสัญญา",
    "ลูกค้าปฏิเสธ",
    "Reject",
    "ทั้งหมด",
]

const orderAD = [
    "K0000001",
    "K0000002",
    "K0000003",
    "K0000057",
    "K0000063",
    "K0000065",
    "K0000074",
    "K0000075",
    "K0000076",
    "MIT00034",
    "MIT00026",
    "MIT00075",
    "MIT00092",
    "MIT00109",
    "MIT00115",
    "MIT00117",
    "MIT00122",
]

const productLoanTypeCar = [
    {
        label: 'ถือเล่มมา',
        value: 'ถือเล่มมา',
    },
    {
        label: 'ย้ายไฟแนนซ์',
        value: 'ย้ายไฟแนนซ์',
    },
    // {
    //     label: 'ปรับโครงสร้าง',
    //     value: 'ปรับโครงสร้าง',
    // },
    {
        label: 'ซื้อ-ขาย',
        value: 'ซื้อ-ขาย',
    },
    {
        label: 'Pre-Aaprove-ถือเล่มมา',
        value: 'Pre-Aaprove-ถือเล่มมา',
    },
    {
        label: 'Pre-Aaprove-ซื้อ-ขาย',
        value: 'Pre-Aaprove-ซื้อ-ขาย',
    },
    {
        label: 'Pre-Aaprove-ย้ายไฟแนนซ์',
        value: 'Pre-Aaprove-ย้ายไฟแนนซ์',
    },
    // {
    //     label: 'Pre-Aaprove-รีโอน',
    //     value: 'Pre-Aaprove-รีโอน',
    // },
    // {
    //     label: 'เปลี่ยนสัญญา',
    //     value: 'เปลี่ยนสัญญา',
    // },
    // {
    //     label: 'Pre-Aaprove-เปลี่ยนสัญญา',
    //     value: 'Pre-Aaprove-เปลี่ยนสัญญา',
    // },
    // {
    //     label: 'รี+เปลี่ยนสัญญา',
    //     value: 'รี+เปลี่ยนสัญญา',
    // },
    // {
    //     label: 'ปรับ+เปลี่ยนสัญญา',
    //     value: 'ปรับ+เปลี่ยนสัญญา',
    // },
    // {
    //     label: 'Pre-Aaprove-รี-Ploan',
    //     value: 'Pre-Aaprove-รี-Ploan',
    // },
    // {
    //     label: 'รี-3',
    //     value: 'รี-3',
    // },
    // {
    //     label: 'Pre-Aaprove-ปรับโครงสร้าง',
    //     value: 'Pre-Aaprove-ปรับโครงสร้าง',
    // },
    // {
    //     label: 'Pre-Aaprove-รี',
    //     value: 'Pre-Aaprove-รี',
    // },
    // {
    //     label: 'Pre-Aaprove-รี+เปลี่ยนสัญญา',
    //     value: 'Pre-Aaprove-รี+เปลี่ยนสัญญา',
    // },
    {
        label: 'Pre-Aaprove-Ploan',
        value: 'Pre-Aaprove-Ploan',
    },
]

const productLoanTypeLand = [
    {
        label: 'ถือโฉนด(ที่ดิน)',
        value: 'ถือโฉนด(ที่ดิน)',
    },
    {
        label: 'ย้ายไฟแนนซ์',
        value: 'ย้ายไฟแนนซ์',
    },
    // {
    //     label: 'ปรับโครงสร้าง',
    //     value: 'ปรับโครงสร้าง',
    // },
    {
        label: 'ซื้อ-ขาย',
        value: 'ซื้อ-ขาย',
    },
    {
        label: 'ที่ดิน+บ้าน',
        value: 'ที่ดิน+บ้าน',
    },
    {
        label: 'ฝากโฉนด',
        value: 'ฝากโฉนด',
    },
    {
        label: 'Pre-Aaprove-ถือโฉนด(ที่ดิน)',
        value: 'Pre-Aaprove-ถือโฉนด(ที่ดิน)',
    },

    {
        label: 'Pre-Aaprove-ซื้อ-ขาย',
        value: 'Pre-Aaprove-ซื้อ-ขาย',
    },
    {
        label: 'Pre-Aaprove-ย้ายไฟแนนซ์',
        value: 'Pre-Aaprove-ย้ายไฟแนนซ์',
    },
    {
        label: 'Pre-Aaprove-ฝากโฉนด',
        value: 'Pre-Aaprove-ฝากโฉนด',
    },
]

const loanPLorCH = [
    {
        label: 'P-LOAN',
        value: 'p-loan',
    },
    {
        label: 'เช่าซื้อ',
        value: 'เช่าซื้อ',
    },
    {
        label: 'เช่าซื้อ (0.84-0.75)',
        value: 'เช่าซื้อ1',
    }
]

const loanPLorCHBig = [
    {
        label: 'P-LOAN',
        value: 'p-loan',
    },
    {
        label: 'เช่าซื้อ',
        value: 'เช่าซื้อ',
    },
]

// const productLoanTypeCarRE = [
// {
//     label: 'ปรับโครงสร้าง',
//     value: 'ปรับโครงสร้าง',
// },
// {
//     label: 'เปลี่ยนสัญญา',
//     value: 'เปลี่ยนสัญญา',
// },
// {
//     label: 'รี+เปลี่ยนสัญญา',
//     value: 'รี+เปลี่ยนสัญญา',
// },
// {
//     label: 'ปรับ+เปลี่ยนสัญญา',
//     value: 'ปรับ+เปลี่ยนสัญญา',
// },
// {
//     label: 'รี-3',
//     value: 'รี-3',
// },
//     {
//         label: 'Pre-Aaprove-รีโอน',
//         value: 'Pre-Aaprove-รีโอน',
//     },
//     {
//         label: 'Pre-Aaprove-เปลี่ยนสัญญา',
//         value: 'Pre-Aaprove-เปลี่ยนสัญญา',
//     },
//     {
//         label: 'Pre-Aaprove-รี-Ploan',
//         value: 'Pre-Aaprove-รี-Ploan',
//     },
//     {
//         label: 'Pre-Aaprove-ปรับโครงสร้าง',
//         value: 'Pre-Aaprove-ปรับโครงสร้าง',
//     },
//     {
//         label: 'Pre-Aaprove-รี',
//         value: 'Pre-Aaprove-รี',
//     },
//     {
//         label: 'Pre-Aaprove-รี+เปลี่ยนสัญญา',
//         value: 'Pre-Aaprove-รี+เปลี่ยนสัญญา',
//     },
// ]

const countStatusRe = [
    {
        approvalStatus: 'ทั้งหมด',
    },
    {
        approvalStatus: 'ยื่นคำร้อง',
    },
    {
        approvalStatus: 'คำร้องไม่ถูกต้อง',
    },
    {
        approvalStatus: 'รับคำร้อง',
    },
    {
        approvalStatus: 'ปฏิเสธคำร้อง',
    },
    {
        approvalStatus: 'ระงับเคส',
    },
    {
        approvalStatus: 'เสนอเคสรีแล้ว',
    }
    // {
    //     approvalStatus: 'ลูกค้ายกเลิก',
    // },

    // {
    //     approvalStatus: 'รออนุมัติ',
    // },
    // {
    //     approvalStatus: 'อนุมัติ',
    // },
    // {
    //     approvalStatus: 'ไม่อนุมัติ',
    // },
]



const productLoanTypeCarRE = [
    {
        label: 'Pre-Aaprove-รีโอน',
        value: 'Pre-Aaprove-รีโอน',
    },
    {
        label: 'Pre-Aaprove-รี-Ploan',
        value: 'Pre-Aaprove-รี-Ploan',
    },
    {
        label: 'Pre-Aaprove-รี',
        value: 'Pre-Aaprove-รี',
    },
    // {
    //     label: 'Pre-Aaprove-รี+เปลี่ยนสัญญา',
    //     value: 'Pre-Aaprove-รี+เปลี่ยนสัญญา',
    // },
    // {
    //     label: 'Pre-Aaprove-ปรับโครงสร้าง',
    //     value: 'Pre-Aaprove-ปรับโครงสร้าง',
    // },
]

const productLoanTypeCarPAP = [
    {
        label: 'Pre-Aaprove-ปรับโครงสร้าง',
        value: 'Pre-Aaprove-ปรับโครงสร้าง',
    },
]

const productLoanTypeCarCHANGE = [
    {
        label: 'Pre-Aaprove-เปลี่ยนสัญญา',
        value: 'Pre-Aaprove-เปลี่ยนสัญญา',
    },
    {
        label: 'Pre-Aaprove-รี+เปลี่ยนสัญญา',
        value: 'Pre-Aaprove-รี+เปลี่ยนสัญญา',
    },
]

const productLoanTypeLandRE = [
    {
        label: 'Pre-Aaprove-รีโอน',
        value: 'Pre-Aaprove-รีโอน',
    },
    // {
    //     label: 'Pre-Aaprove-รี-Ploan',
    //     value: 'Pre-Aaprove-รี-Ploan',
    // },
    {
        label: 'Pre-Aaprove-รี',
        value: 'Pre-Aaprove-รี',
    },
    // {
    //     label: 'Pre-Aaprove-ปรับโครงสร้าง',
    //     value: 'Pre-Aaprove-ปรับโครงสร้าง',
    // },
    // {
    //     label: 'Pre-Aaprove-รี+เปลี่ยนสัญญา',
    //     value: 'Pre-Aaprove-รี+เปลี่ยนสัญญา',
    // },
]

const productLoanTypeLandPAP = [
    {
        label: 'Pre-Aaprove-ปรับโครงสร้าง',
        value: 'Pre-Aaprove-ปรับโครงสร้าง',
    },
]

const productLoanTypeLandCHANGE = [
    {
        label: 'Pre-Aaprove-เปลี่ยนสัญญา',
        value: 'Pre-Aaprove-เปลี่ยนสัญญา',
    },
    {
        label: 'Pre-Aaprove-รี+เปลี่ยนสัญญา',
        value: 'Pre-Aaprove-รี+เปลี่ยนสัญญา',
    },
]

const nopay = [
    {
        value: 12,
        label: 12,
    },
    {
        value: 18,
        label: 18,
    },
    {
        value: 24,
        label: 24,
    },
    {
        value: 30,
        label: 30,
    },
    {
        value: 36,
        label: 36,
    },
    {
        value: 42,
        label: 42,
    },
    {
        value: 48,
        label: 48,
    },
    {
        value: 54,
        label: 54,
    },
    {
        value: 60,
        label: 60,
    },
    {
        value: 66,
        label: 66,
    },
    {
        value: 72,
        label: 72,
    },
    {
        value: 78,
        label: 78,
    },
    {
        value: 84,
        label: 84,
    },
    {
        value: 90,
        label: 90,
    },
    {
        value: 96,
        label: 96,
    },
    {
        value: 102,
        label: 102,
    },
    {
        value: 108,
        label: 108,
    },
    {
        value: 114,
        label: 114,
    },
    {
        value: 120,
        label: 120,
    },
]

const loanTermMid = [
    {
        value: 12,
        label: 12,
    },
    {
        value: 18,
        label: 18,
    },
    {
        value: 24,
        label: 24,
    },
    {
        value: 30,
        label: 30,
    },
    {
        value: 36,
        label: 36,
    },
    {
        value: 42,
        label: 42,
    },
    {
        value: 48,
        label: 48,
    },
    {
        value: 54,
        label: 54,
    },
    {
        value: 60,
        label: 60,
    },
    {
        value: 66,
        label: 66,
    },
    {
        value: 72,
        label: 72,
    },
    {
        value: 78,
        label: 78,
    },
    {
        value: 84,
        label: 84,
    },
    {
        value: 90,
        label: 90,
    },
    {
        value: 96,
        label: 96,
    },
    {
        value: 102,
        label: 102,
    },
    {
        value: 108,
        label: 108,
    },
    {
        value: 114,
        label: 114,
    },
    {
        value: 120,
        label: 120,
    },
]

// เปลี่ยน product_loan_type สถานะอนุมัติ เท่านั้น
const optionsLandPLT = [
    {
        label: 'ถือโฉนด(ที่ดิน)',
        value: 'ถือโฉนด(ที่ดิน)',
    },
    {
        label: 'ซื้อ-ขาย',
        value: 'ซื้อ-ขาย',
    },
    {
        label: 'ที่ดิน+บ้าน',
        value: 'ที่ดิน+บ้าน',
    },
    {
        label: 'ฝากโฉนด',
        value: 'ฝากโฉนด',
    },
    {
        label: 'Pre-Aaprove-ถือโฉนด(ที่ดิน)',
        value: 'Pre-Aaprove-ถือโฉนด(ที่ดิน)',
    },
    {
        label: 'Pre-Aaprove-ซื้อ-ขาย',
        value: 'Pre-Aaprove-ซื้อ-ขาย',
    },
    {
        label: 'Pre-Aaprove-ฝากโฉนด',
        value: 'Pre-Aaprove-ฝากโฉนด',
    },
]
const optionsCarPLT = [
    {
        label: 'ถือเล่มมา',
        value: 'ถือเล่มมา',
    },
    {
        label: 'ซื้อ-ขาย',
        value: 'ซื้อ-ขาย',
    },
    {
        label: 'Pre-Aaprove-ถือเล่มมา',
        value: 'Pre-Aaprove-ถือเล่มมา',
    },
    {
        label: 'Pre-Aaprove-ซื้อ-ขาย',
        value: 'Pre-Aaprove-ซื้อ-ขาย',
    },
    {
        label: 'Pre-Aaprove-Ploan',
        value: 'Pre-Aaprove-Ploan',
    },
]
const optionsPLTOld = [
    {
        label: 'ย้ายไฟแนนซ์',
        value: 'ย้ายไฟแนนซ์',
    },
    {
        label: 'Pre-Aaprove-ย้ายไฟแนนซ์',
        value: 'Pre-Aaprove-ย้ายไฟแนนซ์',
    },
]

// เปลี่ยนจาก อนุมัติ/อนุมัติแบบมีเงื่อนไข/ไม่อนุมัติ 
const optionsChange = [
    {
        label: 'รอวิเคราะห์',
        value: 'รอวิเคราะห์',
    },
    {
        label: 'ผ่านการวิเคราะห์',
        value: 'ผ่านการวิเคราะห์',
    },
    {
        label: 'รออนุมัติ',
        value: 'รออนุมัติ',
    }
]

export {
    op, opre, orderCar, orderLand, orderAD, productLoanTypeCar, productLoanTypeLand,
    loanPLorCH, loanPLorCHBig, productLoanTypeCarRE, countStatusRe,
    productLoanTypeCarPAP, nopay, loanTermMid, productLoanTypeCarCHANGE,
    productLoanTypeLandRE, productLoanTypeLandPAP, productLoanTypeLandCHANGE, oppgre,
    optionsLandPLT, optionsCarPLT, optionsPLTOld, optionsChange
};