import React from 'react'
import { Routes, Route } from "react-router-dom"
import TabelLoanCarMarketing from "./report_mg4/tabelLoanCarMarketing";

export default function Main_Report4() {
  return (
    <Routes>
      <Route path="/" element={<TabelLoanCarMarketing />} />
    </Routes>
  )
}

// รายงาน 4
