import React, { useRef, useState, useEffect } from "react";
import { Button, Table } from "antd";
import { useReactToPrint } from "react-to-print";
import "./css/pagePicture.css";

function DataTebelColums({ dataTabel, inputNumber, inputNumber2}) {
  const [dataRecord, setDataRecord] = useState([]);
  const [triggerPDF, setTriggerPDF] = useState(false);

  const DataColums = [
    {
      title: "ชื่อ-นามสกุล",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <>
          {record.snam}
          {record.firstname} {record.lastname}
        </>
      ),
    },
    {
      title: "สถานะเคส",
      dataIndex: "approvalStatus",
      key: "approvalStatus",
    },
    {
      title: "เลขที่โฉนด",
      dataIndex: "numberLand",
      key: "numberLand",
    },
    {
      title: "อำเภอ",
      dataIndex: "district",
      key: "district",
    },
    {
      title: "จังหวัด",
      dataIndex: "province",
      key: "province",
    },
    {
      title: "เลือก",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Button type="primary" onClick={() => handleButtonClick(record)}>
          เลือก
        </Button>
      ),
    },
  ];

  const conponentPDF = useRef();
  const generatePDF = useReactToPrint({
    content: () => conponentPDF.current,
    documentTitle: "ชุดไถ่ถอนที่ดิน",
  });
 
  const handleButtonClick = (record) => {
    setDataRecord(record);
    setTriggerPDF(true); // Trigger PDF generation after state update
  };

  useEffect(() => {
    if (triggerPDF) {
      generatePDF();
      setTriggerPDF(false); // Reset the trigger
    }
  }, [triggerPDF, generatePDF]);

  let textWatermark = "ข้าพเจ้าบริษัท วัน มันนี่ จำกัด โดยนายทัชชล ลีศิริกุล (กรรมการแทน) ได้รับชำระเงินจาก";
  let textWatermark2 = dataRecord.snam+dataRecord.firstname+' '+dataRecord.lastname+' ครบตามจำนวนแล้วอนุญาตให้ ไถ่ถอนโฉนดเลขที่ '+dataRecord.numberLand+' หน้าสำรวจ '+inputNumber+' เลขที่ดิน '+inputNumber2;

  return (
    <>
      <Table
        columns={DataColums}
        dataSource={dataTabel.map((item, index) => ({ ...item, key: index }))}
        style={{ width: "1500px" }}
      />
      <div className="print-only" ref={conponentPDF} justify={"center"}>
        <div className="container">
          <div className="divTextWatermark">{textWatermark}</div>
          <div className="divTextWatermark2">{textWatermark2}</div>
          <div className="divImgP11" />
        </div>
        <div className="container">
          <div className="divTextWatermark">{textWatermark}</div>
          <div className="divTextWatermark2">{textWatermark2}</div>
          <div className="divImgP12" />
        </div>
        <div className="container">
          <div className="divTextWatermark">{textWatermark}</div>
          <div className="divTextWatermark2">{textWatermark2}</div>
          <div className="divImgP21" />
        </div>
        <div className="container">
          <div className="divTextWatermark">{textWatermark}</div>
          <div className="divTextWatermark2">{textWatermark2}</div>
          <div className="divImgP22" />
        </div>
        <div className="container">
          <div className="divTextWatermark">{textWatermark}</div>
          <div className="divTextWatermark2">{textWatermark2}</div>
          <div className="divImgP23" />
        </div>
        <div className="container">
          <div className="divTextWatermark">{textWatermark}</div>
          <div className="divTextWatermark2">{textWatermark2}</div>
          <div className="divImgP24" />
        </div>
        <div className="container">
          <div className="divTextWatermark">{textWatermark}</div>
          <div className="divTextWatermark2">{textWatermark2}</div>
          <div className="divImgP25" />
        </div>
        <div className="container">
          <div className="divTextWatermark">{textWatermark}</div>
          <div className="divTextWatermark2">{textWatermark2}</div>
          <div className="divImgP0" />
        </div>
      </div>
    </>
  );
}
export default DataTebelColums;
