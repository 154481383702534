import React, { useState, useEffect } from 'react'
import { Button, Form, Input, Select, Divider, Row, Col, Modal, Radio, Space, Spin } from 'antd';
import { Option } from 'antd/es/mentions';
import axios from 'axios';
import { updatesocial } from '../../../file_mid/all_api';

export default function ModalEditSocial({ open, close, dataindex, shootdata, fucnEdit, notification, notification2 }) {
    const [form] = Form.useForm();
    const [getData, setGetData] = useState({ ...shootdata.dataedit });
    const [getIndex, setGetIndex] = useState(dataindex);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setGetData({ ...shootdata.dataedit })
        setGetIndex(dataindex)
        form.setFieldsValue(
            {
                ...getData
            }
        )
    }, [shootdata])

    //elements
    const handleCancel = () => { //กดปิด Modal
        close(false);
    };
    //handleSubmit
    const handleSubmit = async () => {
        // console.log("Edit: ", getData)
        setLoading(true)
        await axios.put(updatesocial, getData)
            .then(res => {
                if (res.status === 200) {
                    notification('top')
                    fucnEdit({ getData, getIndex })
                    setLoading(false)
                    close(false);
                }
            }).catch((err) => {
                if (err.response.request.status === 400) {
                    notification2('top')
                    setLoading(false)
                }
                console.log("err", err)
            })
        setLoading(false)
    }

    return (
        <>
            <Modal
                open={open}
                onCancel={handleCancel}
                style={{ background: '#2f54eb' }}
                centered
                width={600}
                footer={[null]}>
                <Spin spinning={loading} size='large' tip=" Loading... ">
                    <Form
                        form={form}
                        name='editdata'
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                        style={{
                            maxWidth: '100%',
                        }}
                        onFinish={handleSubmit}>
                        <Row>
                            <Col span={24} style={{ fontSize: '30px', textAlign: 'center' }} ><u>แก้ไขสื่อออนไลน์</u></Col>
                        </Row>
                        <Divider />
                        <Row>
                            <Col className='gutter-row' span={24}>
                                <Form.Item label='social' name='socialType'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <Input onChange={(e) => setGetData({ ...getData, socialType: e.target.value })} />
                                </Form.Item>
                                <Form.Item label='ชื่อ' name='socialData'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <Input onChange={(e) => setGetData({ ...getData, socialData: e.target.value })} />
                                </Form.Item>
                                <Form.Item label='รับข่าวสาร' name='sendGetNews'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <Radio.Group onChange={(e) => setGetData({ ...getData, sendGetNews: e.target.value })} >
                                        <Radio value="1"> รับ </Radio>
                                        <Radio value="0"> ไม่รับ </Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item label="หมายเหตุ" name='note'>
                                    <Input onChange={(e) => setGetData({ ...getData, note: e.target.value })} />
                                </Form.Item>
                                <Divider />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} style={{ textAlign: 'center' }}>
                                <Space>
                                    <Button type="primary" onClick={handleCancel} style={{ background: "red" }}>ยกเลิก</Button>
                                    <Button type="primary" htmlType="submit" style={{ background: "green" }} >บันทึก</Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        </>
    )
};
