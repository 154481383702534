import React, { useState } from 'react';
import { Button, Modal, Row, Col, Input, Space, message, Form, notification, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import axios from 'axios';
import { updatepassword } from '../../file_mid/all_api';


const { Text } = Typography;

const SetPassword = ({ open, close, member }) => {
  const { id } = useParams();

  const [newPassword, setNewPassword] = useState({ ...member });
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  // console.log("member", member) 
  // console.log("dataReset", updateMember)

  const upDatePassword = async () => {
    let updatedMember = { ...member, password: newPassword }
    await axios.put(`${updatepassword}/${member.id}`, updatedMember)
      .then(res => {
        if (res.data === 'ok') {
          openNotification('top');
          setTimeout(() => {
            close(false);
          }, 2000)

        }
      })
      .catch(error => {
        console.error('Error updating user:', error);
      });
    // alert('แก้ไขรหัสผ่านสำเร็จ')
    // message.success('แก้ไขรหัสผ่านสำเร็จ');

  }

  const handleOk = async () => {
    if (newPassword !== confirmPassword) {
      message.error('รหัสผ่านไม่ตรงกัน');
      return;
    }
    setConfirmLoading(true);
    await upDatePassword(id, { password: newPassword });
    setConfirmLoading(false);

  };

  const handleCancel = () => {
    // console.log('Clicked cancel button');
    close(false);
  };

  const handleChangePassword = (e) => {
    setNewPassword(e)
  };

  const handleChangePasswordConfirm = (e) => {
    setConfirmPassword(e)
  };
  // console.log('newPassword', newPassword)
  // console.log('confirmPassword', confirmPassword)

  const toggleShowPassword = () => {
    setShowPassword1(!showPassword1);
  };
  
  const toggleShowPasswordConfirm = () => {
    setShowPassword2(!showPassword2);
  };

  const openNotification = (placement) => {
    api.info({
      message: 'บันทึกสำเร็จ',
      description: 'รหัสผ่านถูกบันทึกแล้ว.',
      placement,
    });
  };

  return (

    <>
      {contextHolder}
      <Modal
        open={open}
        title="รหัสผ่านใหม่"
        onOk={handleOk}
        confirmLoading={confirmLoading}
        footer={null}
        onCancel={handleCancel}
      >
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Row justify={'center'}>
              <Form.Item label="Username" name="username" style={{ textAlign: 'center' }}>
                <Text>{member.username}</Text>
                {/* <Text>{`${formData.name} ${formData.lastname}`}</Text> */}
              </Form.Item>
            </Row>
            <Form.Item name="password" label="รหัสผ่านใหม่" style={{ marginBottom: 0 }} required>
              <Input
                name="password"
                type={showPassword1 ? 'text' : 'password'}
                placeholder="รหัสผ่านใหม่"
                className='input'
                onChange={(e) => handleChangePassword(e.target.value)}
                suffix={
                  showPassword1 ? (
                    <EyeInvisibleOutlined onClick={toggleShowPassword} />
                  ) : (
                    <EyeOutlined onClick={toggleShowPassword} />
                  )
                }
              />
            </Form.Item>
            <Form.Item name="password1" label="ยืนยันรหัสผ่าน" style={{ marginBottom: 0 }} required >
              <Input
                name="password1"
                type={showPassword2 ? 'text' : 'password'}
                placeholder="ยืนยันรหัสผ่านใหม่"
                className='input'
                onChange={(e) => handleChangePasswordConfirm(e.target.value)}
                suffix={
                  showPassword2 ? (
                    <EyeInvisibleOutlined onClick={toggleShowPasswordConfirm} />
                  ) : (
                    <EyeOutlined onClick={toggleShowPasswordConfirm} />
                  )
                }
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row justify="end" align="middle" style={{ height: '100%' }}>
              <Space >

                <Button
                  key="cancel"
                  className='button-cancel'
                  onClick={handleCancel}

                >
                  ยกเลิก
                </Button>
                <Button
                  key="save"
                  type='submit'
                  className='button-save'
                  onClick={() => {
                    handleOk();
                  }}

                >
                  บันทึก
                </Button>
              </Space>
            </Row>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default SetPassword;
