import { Button, Form, Card, Col, Row, Spin, Space, Upload, Modal, Divider, message, Select, Image } from 'antd';
import React, { useState, useEffect } from 'react'
import { PlusOutlined } from '@ant-design/icons';
import '../css/Media.css'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import Compressor from 'compressorjs';

import { addCustomer } from '../../../redux/Customer';
import { addAddress } from '../../../redux/Address';
import { addPhone } from '../../../redux/Phone';
import { addImage } from '../../../redux/Image';
import { addOldLoan } from '../../../redux/OldLoan';
import { addCar } from '../../../redux/Car';
import { addLoan } from '../../../redux/Loan';
import { addLand } from '../../../redux/Land';
import { addLoanLand } from '../../../redux/LoanLand';
import { addOldLoanLand } from '../../../redux/OldLoanLand';
import { addGuarantor } from '../../../redux/Guarantor';
import { addCareerIncome } from '../../../redux/CareerIncome';
import { addOccupation } from '../../../redux/Occupation';
import { addEmail } from '../../../redux/Email';
import { addSocial } from '../../../redux/Social';
import { bookCar, bookCarBig, checkCCAP, mutips1, testupload, getImagess } from '../../file_mid/all_api';
const { Option } = Select;

function UploadImages() {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const token = localStorage.getItem('token');
    const addCustomers = localStorage.getItem('addCustomer')
    const addCars = localStorage.getItem('addCar')
    const addPhones = localStorage.getItem('addPhone')
    const addAddresss = localStorage.getItem('addAddress')
    const addImages = localStorage.getItem('addImage')
    const dataAddCustomer = JSON.parse(addCustomers)
    const dataAddCars = JSON.parse(addCars)
    const dataAddPhone = JSON.parse(addPhones)
    const dataAddAddress = JSON.parse(addAddresss)
    const dataAddImages = JSON.parse(addImages)

    // const customers = useSelector(state => state.customers)
    // const Addresss = useSelector(state => state.Addresss)
    // const Phones = useSelector(state => state.Phones)

    // const { customers, Addresss, Phones } = useSelector((state) => state)
    // const { customers } = useSelector((state) => ({ ...state }))
    // const { Addresss } = useSelector((state) => ({ ...state }))
    // const { Phones } = useSelector((state) => ({ ...state })) 
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [fileListCustomer, setFileListCustomer] = useState([]);
    const [fileListCustomerCar, setFileListCustomerCar] = useState([]);
    const [fileListCustomerLand, setFileListCustomerLand] = useState([]);
    const [fileListCustomerDocument, setFileListCustomerDocument] = useState([]);
    const [fileListCustomerDeed, setFileListCustomerDeed] = useState([]);
    const [fileListCustomerLeafDivider, setFileListCustomerLeafDivider] = useState([]);
    const [fileListCustomerBurden, setFileListCustomerBurden] = useState([]);
    const [fileListCustomerStatementImage, setFileListCustomerStatementImage] = useState([]);
    const [fileListCustomerStatementPDF, setFileListCustomerStatementPDF] = useState([]);
    const [fileListCustomerCareer, setFileListCustomerCareer] = useState([]);
    // const [fileListCustomerOther, setFileListCustomerOther] = useState([]);
    // const [fileListGuarantor, setFileListGuarantor] = useState([]);

    // const [fileListCustomer1, setFileListCustomer1] = useState([]);
    // const [fileListCustomerCar1, setFileListCustomerCar1] = useState([]);
    // const [fileListCustomerLand1, setFileListCustomerLand1] = useState([]);
    const [fileListCustomerDocument1, setFileListCustomerDocument1] = useState([]);
    // const [fileListCustomerDeed1, setFileListCustomerDeed1] = useState([]);
    // const [fileListCustomerLeafDivider1, setFileListCustomerLeafDivider1] = useState([]);
    // const [fileListCustomerBurden1, setFileListCustomerBurden1] = useState([]);
    // const [fileListCustomerStatementImage1, setFileListCustomerStatementImage1] = useState([]);
    // const [fileListCustomerStatementPDF1, setFileListCustomerStatementPDF1] = useState([]);
    // const [fileListCustomerCareer1, setFileListCustomerCareer1] = useState([]);
    // const [fileListCustomerOther1, setFileListCustomerOther1] = useState([]);
    const [fileListGuarantor1, setFileListGuarantor1] = useState([]);
    /*เก็บรูปทั้งหมด*/
    const [allImg, setAllImg] = useState([]);
    const [dataAddImage, setDataAddImage] = useState([]) // โชว์รูป

    const [selectProduct, setSelectProduct] = useState(1) // 1 รถ , 2 ที่ดิน
    const [selectTypeCar, setSelectTypeCar] = useState(1) // 1 รถ , 2 ที่ดิน
    const [fileList, setFileList] = useState([]) // รูปบัตรประชาชน
    const [count] = useState()
    const [idcustomer, setIDCustomer] = useState()
    //const [idGuarantor, setIDGuarantor] = useState()
    const { confirm } = Modal

    const [dataAddress, setDataAddress] = useState({}) // address
    const [dataPost, setDataPost] = useState({}) // customer
    const [dataPhone, setDataPhone] = useState({}) // phone
    // const [dataCareerIncome, setDataCareerIncome] = useState({}) // phone

    const [dataPostGuarantor, setDataPostGuarantor] = useState([]) // customerGuarantor
    const newFormData = new FormData();

    useEffect(() => {
        if (dataAddCustomer) {
            setDataPost(dataAddCustomer)
            setSelectProduct({ productTypeId: dataAddCustomer?.productTypeId })
            setSelectTypeCar({ productTypeCar: dataAddCustomer?.productTypeCar })
            form.setFieldsValue({ ...dataAddCustomer })
            if (dataAddPhone) {
                setDataPhone(dataAddPhone)
            }
            if (dataAddAddress) {
                setDataAddress(dataAddAddress)
            }
            if (dataAddImages?.length > 0) {
                setDataAddImage(dataAddImages)
            }
        } else {
            // localStorage.removeItem('addCustomer');
            // localStorage.removeItem('addAddress');
            // localStorage.removeItem('addPhone');
            // localStorage.removeItem('addCar');
            // localStorage.removeItem('addGuarantor');
            // localStorage.removeItem('addCareerIncome');
            // localStorage.removeItem('addLoan');
            // localStorage.removeItem('addOldLoan');
            // localStorage.removeItem('addLand');
            // localStorage.removeItem('addLoanLand');
            // localStorage.removeItem('addOldLoanLand');
            // localStorage.removeItem('addOccupation');
            // localStorage.removeItem('addEmail');
            // localStorage.removeItem('addSocial');
            // localStorage.removeItem('addImage');
            dispatch(addCustomer())
            dispatch(addAddress())
            dispatch(addPhone())
            dispatch(addCar())
            dispatch(addGuarantor())
            dispatch(addCareerIncome())
            dispatch(addLoan())
            dispatch(addOldLoan())
            dispatch(addImage())
            dispatch(addLand())
            dispatch(addLoanLand())
            dispatch(addOldLoanLand())
            dispatch(addOccupation())
            dispatch(addEmail())
            dispatch(addSocial())
            setSelectProduct({ ...selectProduct, productTypeId: 1 })
            form.setFieldsValue({ productTypeId: 1, })
        }
    }, [])

    function generateRandomNumber() {
        if (dataAddCustomer?.preImg !== "" && dataAddCustomer?.preImg !== undefined && dataAddCustomer?.preImg !== null) {
            return dataAddCustomer?.preImg
        } else {
            // สร้างเลขสุ่มตั้งแต่ 0 ถึง 9999999999 (10 หลัก)
            const randomNumber = Math.floor(Math.random() * 10000000000);
            // ให้เลขนี้เป็น string และเพิ่ม 3 นำหน้าในกรณีที่มีน้อยกว่า 10 หลัก
            const randomNumberString = randomNumber.toString().padStart(10, '3');
            return randomNumberString;
        }
    }
    const imgSend = (resultImgs, randomTenDigitNumber) => {
        return new Promise(async (resolve, reject) => {
            try {
                var status;
                if (dataAddCustomer?.preImg !== "" && dataAddCustomer?.preImg !== undefined && dataAddCustomer?.preImg !== null) {
                    newFormData.append('idcus', dataAddCustomer?.preImg);
                } else {
                    newFormData.append('idcus', `${0}${randomTenDigitNumber}`);
                }
                newFormData.append('productTypeId', selectProduct.productTypeId);

                for (const file of resultImgs) {
                    newFormData.append('file', file.imageCompressedFile);
                    newFormData.append("type", file.type);
                }
                console.log("newFormData", newFormData)
                const response = await axios.post(mutips1, newFormData);
                if (response.data) {
                    //success(randomTenDigitNumber);
                    console.log("TY", response.data);
                    if (dataAddImages?.length > 0) {
                        for (const file of response.data) {
                            dataAddImage.push(file)
                        }
                        // dispatch(addSocial(dataAddImage))
                        localStorage.setItem('addImage', JSON.stringify(dataAddImage))
                    } else {
                        dispatch(addSocial(response.data))
                        localStorage.setItem('addImage', JSON.stringify(response.data))
                    }
                    status = response.status;
                    resolve(status);
                }
            } catch (error) {
                error(error);
                reject(error);
            } finally {
                //setLoading(false);
                console.log("OK")
            }
        });
    };
    const addImg = async (resultImg) => {
        //console.log("resultImg =", resultImg)
        if (allImg?.ts1?.length > 0) {
            await logFormData(allImg.ts1, 1, resultImg)
        }
        if (allImg?.ts2?.length > 0) {
            await logFormData(allImg.ts2, 2, resultImg)
        }
        if (allImg?.ts3?.length > 0) {
            await logFormData(allImg.ts3, 3, resultImg)
        }
        if (allImg?.ts4?.length > 0) {
            await logFormData(allImg.ts4, 4, resultImg)
        }
        // if (allImg?.ts5?.length > 0) {
        //     await logFormData(allImg.ts5, 5, resultImg)
        // }
        if (allImg?.ts6?.length > 0) {
            await logFormData(allImg.ts6, 6, resultImg)
        }
        if (allImg?.ts7?.length > 0) {
            await logFormData(allImg.ts7, 7, resultImg)
        }
        if (allImg?.ts8?.length > 0) {
            await logFormData(allImg.ts8, 8, resultImg)
        }
        if (allImg?.ts9?.length > 0) {
            await logFormData(allImg.ts9, 9, resultImg)
        }
        if (allImg?.ts10?.length > 0) {
            await logFormData(allImg.ts10, 10, resultImg)
        }
        if (allImg?.ts11?.length > 0) {
            await logFormData(allImg.ts11, 11, resultImg)
        }
        if (allImg?.ts12?.length > 0) {
            await logFormData(allImg.ts12, 12, resultImg)
        }
    }

    const logFormData = async (ts, type, resultImg) => {
        //  console.log("ts",ts)
        //  var imageListImage = []
        for (const ez of ts) {
            var image = {}
            if (type !== 7) {
                if (ez.size > 4000000) {
                    console.log("4m ไม่ให้ส่ง");
                } else {
                    let quality;
                    if (ez.size >= 2500000 && ez.size <= 4000000) {
                        quality = 0.1;
                    } else if (ez.size >= 2000000 && ez.size <= 2499999) {
                        quality = 0.15;
                    } else if (ez.size >= 1500000 && ez.size <= 1999999) {
                        quality = 0.2;
                    } else if (ez.size >= 1000000 && ez.size <= 1499999) {
                        quality = 0.3;
                    } else {
                        quality = 0.5;
                    }
                    const compressedFile = await new Promise((resolve) => {
                        new Compressor(ez.originFileObj, {
                            quality: quality,
                            success: (result) => {
                                resolve(result);
                            },
                        });
                    });
                    let filename = ez.name
                    let image64 = await getBase64(compressedFile)
                    image.filename = filename
                    image.image64 = image64
                    image.type = type
                    resultImg.push(image)
                }
            }
            // else if (type === 2) {
            //     const compressedFile = await new Promise((resolve) => {
            //         new Compressor(ez.originFileObj, {
            //             quality: 0.6, // เช่น, 60%
            //             success: (result) => {
            //                 resolve(result);
            //             },
            //         });
            //     });
            //     let filename = ez.name
            //     let image64 = await getBase64(compressedFile)
            //     image.filename = filename
            //     image.image64 = image64
            //     image.type = type
            //     resultImg.push(image)
            // }
            else {
                let filename = ez.name
                let image64 = await getBase64(ez.originFileObj)
                image.filename = filename
                image.image64 = image64
                image.type = type
                resultImg.push(image)
            }
        }
        return resultImg
    };

    const handleSubmit = async () => {
        setLoading(true)
        const randomTenDigitNumber = generateRandomNumber();
        console.log("randomTenDigitNumber", randomTenDigitNumber)
        if (dataPost) {
            delete dataPost?.address
            delete dataPost?.phones
            delete dataPost?.address
            delete dataPost?.carLoanDetails
            delete dataPost?.emails
            delete dataPost?.ojs
            delete dataPost?.socials
        }
        // var resultImg = []
        // await addImg(resultImg)
        //console.log("allImg", allImg)
        if (selectProduct?.productTypeId === 1 || selectProduct?.productTypeId === 2) {
            if (allImg?.length > 0) {
                await imgSend(allImg, randomTenDigitNumber)
            }
            // console.log("1-2")
            if (selectTypeCar?.productTypeCar === 1 || selectTypeCar?.productTypeCar === 2) { /*รถยนต์ + รถเกษตร*/
                console.log("รถเล็ก")
                if (fileListCustomerDocument?.length > 0) {
                    //if (allImg?.ts2?.length > 0) {
                    console.log("มีเล่มรถเล็ก")
                    await handleDocumentsCar() // functions read Document Car 
                } else {
                    console.log("ไม่มีเล่มรถเล็ก")
                }
            } else if (selectTypeCar?.productTypeCar === 3) { /*รถบรรทุก*/
                console.log("รถใหญ่")
                if (fileListCustomerDocument?.length > 0) {
                    //if (allImg?.ts2?.length > 0) {
                    console.log("มีเล่มรถใหญ่")
                    await handleDocumentsTruckandVehicles() // functions read Document Car
                } else {
                    console.log("ไม่มีเล่มรถใหญ่")
                }
            } else {
                console.log("ไม่ใช่รถ")
            }
            //setTimeout(async () => {
            // const Car = []
            // const Land = []
            if (fileListGuarantor1?.imageListGuarantor?.length > 0) {
                await handleScanIdCardGuarantor(fileListGuarantor1.imageListGuarantor)
            }
            // dispatch(addImage(resultImg))
            // dispatch(addEmail(resultImg))
            if (selectProduct?.productTypeId === 1) { // รถ
                if (dataAddCustomer) {
                    if (dataAddCustomer?.productTypeCar !== selectTypeCar?.productTypeCar) {
                        localStorage.removeItem('addLoan', JSON.stringify())
                        localStorage.removeItem('addOldLoan', JSON.stringify())
                        if (dataAddCars) {
                            localStorage.setItem('addCar', JSON.stringify({
                                ...dataAddCars,
                                carBrand: "",
                                carPrice: "",
                                carYear: "",
                                carBaab: "",
                                carModel: "",
                                productTypeCar: selectTypeCar.productTypeCar
                            }))
                        }
                    }
                }
                if (dataAddCustomer?.preImg !== "" && dataAddCustomer?.preImg !== undefined && dataAddCustomer?.preImg !== null) {
                    dispatch(addCustomer({ ...dataPost, ...selectProduct, ...selectTypeCar, preImg: dataAddCustomer?.preImg }));
                    localStorage.setItem('addCustomer', JSON.stringify({ ...dataPost, ...selectProduct, ...selectTypeCar, preImg: dataAddCustomer?.preImg }))
                } else {
                    dispatch(addCustomer({ ...dataPost, ...selectProduct, ...selectTypeCar, preImg: `${0}${randomTenDigitNumber}` }));
                    localStorage.setItem('addCustomer', JSON.stringify({ ...dataPost, ...selectProduct, ...selectTypeCar, preImg: `${0}${randomTenDigitNumber}` }))
                }
                dispatch(addAddress({ ...dataAddress }));
                dispatch(addPhone({ ...dataPhone }));
                localStorage.setItem('addAddress', JSON.stringify({ ...dataAddress }))
                localStorage.setItem('addPhone', JSON.stringify({ ...dataPhone }))
                // dispatch(addCareerIncome({ ...dataCareerIncome }));
                navigate('/offercase/addcustomer')
                setLoading(false)
            }
            if (selectProduct?.productTypeId === 2) { // พรีที่ดิน
                if (dataAddCustomer?.preImg !== "" && dataAddCustomer?.preImg !== undefined && dataAddCustomer?.preImg !== null) {
                    dispatch(addCustomer({ ...dataPost, ...selectProduct, preImg: dataAddCustomer?.preImg }));
                    localStorage.setItem('addCustomer', JSON.stringify({ ...dataPost, ...selectProduct, preImg: dataAddCustomer?.preImg }))
                } else {
                    dispatch(addCustomer({ ...dataPost, ...selectProduct, preImg: `${0}${randomTenDigitNumber}` }));
                    localStorage.setItem('addCustomer', JSON.stringify({ ...dataPost, ...selectProduct, preImg: `${0}${randomTenDigitNumber}` }))
                }
                dispatch(addAddress({ ...dataAddress }));
                dispatch(addPhone({ ...dataPhone }));
                localStorage.setItem('addAddress', JSON.stringify({ ...dataAddress }))
                localStorage.setItem('addPhone', JSON.stringify({ ...dataPhone }))
                // dispatch(addCareerIncome({ ...dataCareerIncome }));
                // {
                //     fileListGuarantor1?.length !== 0 ?
                //         (
                //             dispatch(addGuarantor({ ...dataPostGuarantor, phones: [], nickname: [] }))
                //         )
                //         :
                //         (
                //             dispatch(addGuarantor(...dataPostGuarantor))
                //         )
                // }
                navigate('/offercase/addcustomer')
                setLoading(false)
            }
            //}, 1000)
        } else {
            console.log("3 ขอยอดที่ดิน")
            // const Land = []
            var resultImg = []
            await addImg(resultImg)
            //console.log("resultImg",resultImg)
            //setTimeout(() => {
            //setLoading(true)
            // if (fileListCustomerLand1?.imageListCustomerLand) {
            //     Land.push(...fileListCustomerLand1?.imageListCustomerLand)
            // }
            // if (fileListCustomerDeed1?.imageListCustomerDeed) {
            //     Land.push(...fileListCustomerDeed1?.imageListCustomerDeed)
            // }
            // if (fileListCustomerLeafDivider1?.imageListCustomerLeafDivider) {
            //     Land.push(...fileListCustomerLeafDivider1?.imageListCustomerLeafDivider)
            // }
            // if (fileListCustomerBurden1?.imageListCustomerBurden) {
            //     Land.push(...fileListCustomerBurden1?.imageListCustomerBurden)
            // }
            dispatch(addCustomer({ ...dataPost, ...selectProduct, ...selectTypeCar }))
            dispatch(addImage(resultImg))
            dispatch(addEmail(resultImg))
            //dispatch(addImage(Land))
            navigate('/offercase/preland')
            setLoading(false)
            //}, 1000)
        }
        setLoading(false)
    }
    const compressImage = async (ez, types) => {
        var image = {};
        return new Promise(async (resolve) => {
            if (types !== 7) {
                if (ez.size > 4000000) {
                    console.log("4m ไม่ให้ส่ง");
                    resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
                } else {
                    let quality;
                    if (ez.size >= 2500000 && ez.size <= 4000000) {
                        quality = 0.1;
                    } else if (ez.size >= 2000000 && ez.size <= 2499999) {
                        quality = 0.15;
                    } else if (ez.size >= 1500000 && ez.size <= 1999999) {
                        quality = 0.2;
                    } else if (ez.size >= 1000000 && ez.size <= 1499999) {
                        quality = 0.3;
                    } else {
                        quality = 0.5;
                    }

                    try {
                        // ใช้ Promise เพื่อบีบอัดรูปภาพ
                        const compressedFile = await new Promise((resolveCompress) => {
                            new Compressor(ez, {
                                quality: quality,
                                success: (result) => {
                                    resolveCompress(result);
                                },
                            });
                        });

                        const fileOptions = {
                            type: compressedFile.type,
                            lastModified: Date.now(),
                        };
                        const compressedFileAsFile = new File([compressedFile], ez.name, fileOptions);
                        image.imageCompressedFile = compressedFileAsFile;
                        image.type = types
                        allImg.push(image);
                        //setAllImg((prevAllImg) => [...prevAllImg, image]);

                        // ตรงนี้คือการ resolve ไฟล์ที่ถูกบีบอัด
                        resolve(image);
                    } catch (error) {
                        console.error('Image compression error:', error);
                        resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
                    }
                }
            } else {
                // ... (โลจิกสำหรับ type 7)
                image.imageCompressedFile = ez;
                image.type = types
                allImg.push(image);
                //setAllImg((prevAllImg) => [...prevAllImg, image]);
                resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
            }
        });
    };
    const beforeUpts = async (file, ty) => {
        //console.log("file", file)
        try {
            setLoading(true)
            const compressedFile = await compressImage(file, ty);
            //fileListCustomerCar.push({originFileObj:file})
            //เปลี่ยนไปใช้ไฟล์ที่ถูกบีบอัดแล้ว
            //allImg.push(compressedFile)
            //    image1.push(compressedFile)
            //console.log('Image=',file)
            //setLoading(false);
            return Promise.resolve(compressedFile);
        } catch (error) {
            console.error('Image compression error:', error);
            message.error('Image compression failed.');
            return Promise.reject(); // เปลี่ยนให้ return Promise.reject() เมื่อไม่สามารถบีบอัดได้
        }
    };

    async function convertFileToBlob(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                const blob = new Blob([reader.result], { type: file.type });
                resolve(blob);
            };
            reader.onerror = reject;

            reader.readAsArrayBuffer(file.originFileObj);
        });
    }

    /////////////////// เลือก 1 รถ, 2 ที่ดิน ///////////////////////
    const handleProduct = (value) => {
        if (value === 1) {
            //form.setFieldsValue({ productTypeCar: 1, })
            setSelectProduct({ ...selectProduct, productTypeId: value })
            dispatch(addLand())
            dispatch(addLoanLand())
            setFileListCustomer([]) //set ค่าว่าง
            // setFileListGuarantor([]) //set ค่าว่าง
            setFileListCustomerCar([]) //set ค่าว่าง
            setFileListCustomerDocument([]) //set ค่าว่าง
            setFileListCustomerStatementImage([]) //set ค่าว่าง
            setFileListCustomerStatementPDF([]) //set ค่าว่าง
            setFileListCustomerCareer([]) //set ค่าว่าง
            // setFileListCustomerOther([]) //set ค่าว่าง
            setFileListCustomerLeafDivider([])
            setFileListCustomerBurden([])
            setAllImg([])
        } else if (value === 2) {
            form.setFieldsValue({ productTypeCar: "", })
            setSelectProduct({ ...selectProduct, productTypeId: value })
            setSelectTypeCar()
            dispatch(addCar())
            dispatch(addLoan())
            setFileListCustomer([]) //set ค่าว่าง
            // setFileListGuarantor([]) //set ค่าว่าง
            setFileListCustomerLand([]) //set ค่าว่าง
            setFileListCustomerDeed([]) //set ค่าว่าง
            setFileListCustomerStatementImage([]) //set ค่าว่าง
            setFileListCustomerStatementPDF([]) //set ค่าว่าง
            setFileListCustomerCareer([]) //set ค่าว่าง
            // setFileListCustomerOther([]) //set ค่าว่าง
            setFileListCustomerLeafDivider([])
            setFileListCustomerBurden([]);
            setAllImg([])
        } else {
            form.setFieldsValue({ productTypeCar: "", })
            setSelectProduct({ ...selectProduct, productTypeId: value })
            setSelectTypeCar()
            dispatch(addCar())
            dispatch(addLoan())
            dispatch(addLand())
            dispatch(addLoanLand())
            setFileListCustomer([]) //set ค่าว่าง
            // setFileListGuarantor([]) //set ค่าว่าง
            setFileListCustomerCar([]) //set ค่าว่าง
            setFileListCustomerLand([]) //set ค่าว่าง
            setFileListCustomerDeed([]) //set ค่าว่าง
            setFileListCustomerDocument([]) //set ค่าว่าง
            setFileListCustomerStatementImage([]) //set ค่าว่าง
            setFileListCustomerStatementPDF([]) //set ค่าว่าง
            setFileListCustomerCareer([]) //set ค่าว่าง
            // setFileListCustomerOther([]) //set ค่าว่าง
            setFileListCustomerLeafDivider([])
            setFileListCustomerBurden([]);
            setAllImg([])
            setDataAddImage([])
            localStorage.removeItem('addCustomer');
            localStorage.removeItem('addAddress');
            localStorage.removeItem('addPhone');
            localStorage.removeItem('addCar');
            localStorage.removeItem('addGuarantor');
            localStorage.removeItem('addCareerIncome');
            localStorage.removeItem('addLoan');
            localStorage.removeItem('addOldLoan');
            localStorage.removeItem('addLand');
            localStorage.removeItem('addLoanLand');
            localStorage.removeItem('addOldLoanLand');
            localStorage.removeItem('addOccupation');
            localStorage.removeItem('addEmail');
            localStorage.removeItem('addSocial');
            localStorage.removeItem('addImage');
        }
    }
    const handleProductTypeCar = (value) => {
        setSelectTypeCar({ ...selectTypeCar, productTypeCar: value })
        console.log("value: " + value)
    }

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
    };
    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };
    const handleCancel = () => {
        setPreviewVisible(false);
    }
    ///////////////// อัปโหลดรูปภาพ //////////////////////////////
    const handleChangeCustomer = async ({ fileList }) => { // 4
        // setLoading(true)
        // setAllImg({ ...allImg, ts4: fileList });
        // console.log("fileList", fileList)
        // console.log("allImg", allImg)
        // console.log("fileListCustomer", fileListCustomer)

        // for (const removedFile of fileListCustomer.filter(file => file.status === 'removed')) {
        //     //console.log('Removed File:', removedFile);
        //     if (allImg.some(img => img.imageCompressedFile.name === removedFile.name)) {
        //         const updatedAllImg = allImg.filter(img => img.imageCompressedFile.name !== removedFile.name);
        //         //console.log("Updated allImg:", updatedAllImg);
        //         setAllImg(updatedAllImg)
        //     }
        // }
        for (const removedFile of fileListCustomer.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 4)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 4));
                setAllImg(updatedAllImg);
            }
        }

        setFileListCustomer(fileList);
        // var imageListCustomer = []
        // // const compressedFileList = await Promise.all(
        // //     fileList.map(async (file) => {
        // //         // Compress the image
        // //         const compressedFile = await new Promise((resolve) => {
        // //             new Compressor(file.originFileObj, {
        // //                 quality: 0.6, // เช่น, 60%
        // //                 success: (result) => {
        // //                     resolve(result);
        // //                 },
        // //             });
        // //         });

        // //         // Update the file with the compressed version
        // //         return {
        // //             ...file,
        // //             originFileObj: compressedFile,
        // //         };
        // //     })
        // // );
        // var i = 0
        // for (const file of fileList) {
        //     // var quality
        //     // if (file.size < 1000000) {
        //     //     console.log("1")
        //     //     quality = 1
        //     // }
        //     // else{
        //     //     console.log("0.6")
        //     //     quality = 0.6
        //     // }
        //     const compressedFile = await new Promise((resolve) => {
        //         new Compressor(file.originFileObj, {
        //             quality: 0.6, // เช่น, 60%
        //             success: (result) => {
        //                 resolve(result);
        //             },
        //         });
        //     });
        //     var image = {}
        //     let filename = file.name
        //     //let image64 = await getBase64(compressedFileList[i].originFileObj)
        //     let image64 = await getBase64((compressedFile))
        //     //let image64 = file.originFileObj
        //     image.filename = filename
        //     image.image64 = image64
        //     image.type = 4
        //     imageListCustomer.push(image)
        //     i++
        // }
        // setFileListCustomer1({ ...fileListCustomer1, imageListCustomer });
        if (fileList) {
            handleFileInputChange(fileList);
        } else {
            handleFileInputChange([]);
        }
        setLoading(false)
    };

    const handleChangeCustomerCar = async ({ fileList }) => { // 1
        // setLoading(true)
        // setAllImg({ ...allImg, ts1: fileList });

        for (const removedFile of fileListCustomerCar.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 1)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 1));
                setAllImg(updatedAllImg);
            }
        }

        setFileListCustomerCar(fileList);
        // var imageListCustomerCar = []
        // // const compressedFileList = await Promise.all(
        // //     fileList.map(async (file) => {
        // //         // Compress the image
        // //         const compressedFile = await new Promise((resolve) => {
        // //             new Compressor(file.originFileObj, {
        // //                 quality: 0.6, // เช่น, 60%
        // //                 success: (result) => {
        // //                     resolve(result);
        // //                 },
        // //             });
        // //         });
        // //         // Update the file with the compressed version
        // //         return {
        // //             ...file,
        // //             originFileObj: compressedFile,
        // //         };
        // //     })
        // // );
        // var i = 0
        // for (const file of fileList) {
        //     const compressedFile = await new Promise((resolve) => {
        //         new Compressor(file.originFileObj, {
        //             quality: 0.6, // เช่น, 60%
        //             success: (result) => {
        //                 resolve(result);
        //             },
        //         });
        //     });
        //     var image = {}
        //     let filename = file.name
        //     //let image64 = await getBase64(compressedFileList[i].originFileObj)
        //     let image64 = await getBase64(compressedFile)
        //     //let image64 = file.originFileObj
        //     image.filename = filename
        //     image.image64 = image64
        //     image.type = 1
        //     imageListCustomerCar.push(image)
        //     i++
        // }
        // setFileListCustomerCar1({ ...fileListCustomerCar1, imageListCustomerCar });
        setLoading(false)
    };
    const handleChangeCustomerLand = async ({ fileList }) => { // 1
        // setLoading(true)
        if (selectProduct?.productTypeId === 3) {
            setAllImg({ ...allImg, ts1: fileList });
        }
        else {
            for (const removedFile of fileListCustomerLand.filter(file => file.status === 'removed')) {
                if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 1)) {
                    const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 1));
                    setAllImg(updatedAllImg);
                }
            }
        }
        setFileListCustomerLand(fileList);

        // var imageListCustomerLand = []
        // // const compressedFileList = await Promise.all(
        // //     fileList.map(async (file) => {
        // //         // Compress the image
        // //         const compressedFile = await new Promise((resolve) => {
        // //             new Compressor(file.originFileObj, {
        // //                 quality: 0.6, // เช่น, 60%
        // //                 success: (result) => {
        // //                     resolve(result);
        // //                 },
        // //             });
        // //         });
        // //         // Update the file with the compressed version
        // //         return {
        // //             ...file,
        // //             originFileObj: compressedFile,
        // //         };
        // //     })
        // // );
        // var i = 0
        // for (const file of fileList) {
        //     const compressedFile = await new Promise((resolve) => {
        //         new Compressor(file.originFileObj, {
        //             quality: 0.6, // เช่น, 60%
        //             success: (result) => {
        //                 resolve(result);
        //             },
        //         });
        //     });
        //     var image = {}
        //     let filename = file.name
        //     let image64 = await getBase64(compressedFile)
        //     //let image64 = file.originFileObj
        //     image.filename = filename
        //     image.image64 = image64
        //     image.type = 1
        //     imageListCustomerLand.push(image)
        //     i++
        // }
        // setFileListCustomerLand1({ ...fileListCustomerLand1, imageListCustomerLand });
        setLoading(false)
    };

    /////////อ่านเล่มรถ/////////// 
    const handleChangeCustomerDocument = async ({ fileList }) => { // 2
        // setLoading(true)
        // setAllImg({ ...allImg, ts2: fileList });
        for (const removedFile of fileListCustomerDocument.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 2)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 2));
                setAllImg(updatedAllImg);
            }
        }
        console.log("fileList book", fileList)
        setFileListCustomerDocument(fileList);
        var imageListCustomerDocument = []
        const formData = new FormData()
        // var i = 0
        for (const file of fileList) {
            // const compressedFile = await new Promise((resolve) => {
            //     new Compressor(file.originFileObj, {
            //         quality: 0.6, // เช่น, 60%
            //         success: (result) => {
            //             resolve(result);
            //         },
            //     });
            // });
            // var image = {}
            // let filename = file.name
            // let image64 = await getBase64(compressedFile)
            // let image64 = file.originFileObj
            // image.filename = filename
            // image.image64 = image64
            // image.type = 2
            // imageListCustomerDocument.push(image)
            const blob = await convertFileToBlob(file);
            formData.append('image', blob, file.name);
            formData.append('productTypeCar', selectTypeCar.productTypeCar);
            // i++
        }
        // const ageValues = formData.getAll('image');
        // const typeValues = formData.getAll('productTypeCar');
        // console.log("ageValues =", ageValues)
        // console.log("typeValues =", typeValues)
        setFileList(formData)
        setFileListCustomerDocument1({ ...fileListCustomerDocument1, imageListCustomerDocument });
        setLoading(false)
    };
    const handleChangeCustomerDeed = async ({ fileList }) => { // 3
        // setLoading(true)
        if (selectProduct?.productTypeId === 3) {
            setAllImg({ ...allImg, ts3: fileList });
        } else {
            for (const removedFile of fileListCustomerDeed.filter(file => file.status === 'removed')) {
                if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 3)) {
                    const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 3));
                    setAllImg(updatedAllImg);
                }
            }
        }
        setFileListCustomerDeed(fileList);
        // var imageListCustomerDeed = []
        // // const compressedFileList = await Promise.all(
        // //     fileList.map(async (file) => {
        // //         // Compress the image
        // //         const compressedFile = await new Promise((resolve) => {
        // //             new Compressor(file.originFileObj, {
        // //                 quality: 0.6, // เช่น, 60%
        // //                 success: (result) => {
        // //                     resolve(result);
        // //                 },
        // //             });
        // //         });

        // //         // Update the file with the compressed version
        // //         return {
        // //             ...file,
        // //             originFileObj: compressedFile,
        // //         };
        // //     })
        // // );
        // var i = 0
        // for (const file of fileList) {
        //     const compressedFile = await new Promise((resolve) => {
        //         new Compressor(file.originFileObj, {
        //             quality: 0.6, // เช่น, 60%
        //             success: (result) => {
        //                 resolve(result);
        //             },
        //         });
        //     });
        //     var image = {}
        //     let filename = file.name
        //     let image64 = await getBase64(compressedFile)
        //     //let image64 = file.originFileObj
        //     image.filename = filename
        //     image.image64 = image64
        //     image.type = 3
        //     imageListCustomerDeed.push(image)
        //     i++
        // }
        // setFileListCustomerDeed1({ ...fileListCustomerDeed1, imageListCustomerDeed });
        setLoading(false)
    };
    const handleChangeCustomerLeafDivider = async ({ fileList }) => { // 10
        // setLoading(true)
        if (selectProduct?.productTypeId === 3) {
            setAllImg({ ...allImg, ts10: fileList });
        } else {
            for (const removedFile of fileListCustomerLeafDivider.filter(file => file.status === 'removed')) {
                if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 10)) {
                    const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 10));
                    setAllImg(updatedAllImg);
                }
            }
        }
        setFileListCustomerLeafDivider(fileList);
        // var imageListCustomerLeafDivider = []
        // // const compressedFileList = await Promise.all(
        // //     fileList.map(async (file) => {
        // //         // Compress the image
        // //         const compressedFile = await new Promise((resolve) => {
        // //             new Compressor(file.originFileObj, {
        // //                 quality: 0.6, // เช่น, 60%
        // //                 success: (result) => {
        // //                     resolve(result);
        // //                 },
        // //             });
        // //         });
        // //         // Update the file with the compressed version
        // //         return {
        // //             ...file,
        // //             originFileObj: compressedFile,
        // //         };
        // //     })
        // // );
        // var i = 0
        // for (const file of fileList) {
        //     const compressedFile = await new Promise((resolve) => {
        //         new Compressor(file.originFileObj, {
        //             quality: 0.6, // เช่น, 60%
        //             success: (result) => {
        //                 resolve(result);
        //             },
        //         });
        //     });
        //     var image = {}
        //     let filename = file.name
        //     let image64 = await getBase64(compressedFile)
        //     //let image64 = file.originFileObj
        //     image.filename = filename
        //     image.image64 = image64
        //     image.type = 10
        //     imageListCustomerLeafDivider.push(image)
        //     i++
        // }
        // setFileListCustomerLeafDivider1({ ...fileListCustomerLeafDivider1, imageListCustomerLeafDivider });
        setLoading(false)
    };
    const handleChangeCustomerBurden = async ({ fileList }) => { // 11
        // setLoading(true)
        if (selectProduct?.productTypeId === 3) {
            setAllImg({ ...allImg, ts11: fileList });
        } else {
            for (const removedFile of fileListCustomerBurden.filter(file => file.status === 'removed')) {
                if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 11)) {
                    const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 11));
                    setAllImg(updatedAllImg);
                }
            }
        }
        setFileListCustomerBurden(fileList);
        // var imageListCustomerBurden = []
        // // const compressedFileList = await Promise.all(
        // //     fileList.map(async (file) => {
        // //         // Compress the image
        // //         const compressedFile = await new Promise((resolve) => {
        // //             new Compressor(file.originFileObj, {
        // //                 quality: 0.6, // เช่น, 60%
        // //                 success: (result) => {
        // //                     resolve(result);
        // //                 },
        // //             });
        // //         });

        // //         // Update the file with the compressed version
        // //         return {
        // //             ...file,
        // //             originFileObj: compressedFile,
        // //         };
        // //     })
        // // );
        // var i = 0
        // for (const file of fileList) {
        //     const compressedFile = await new Promise((resolve) => {
        //         new Compressor(file.originFileObj, {
        //             quality: 0.6, // เช่น, 60%
        //             success: (result) => {
        //                 resolve(result);
        //             },
        //         });
        //     });
        //     var image = {}
        //     let filename = file.name
        //     let image64 = await getBase64(compressedFile)
        //     //let image64 = file.originFileObj
        //     image.filename = filename
        //     image.image64 = image64
        //     image.type = 11
        //     imageListCustomerBurden.push(image)
        //     i++
        // }
        // setFileListCustomerBurden1({ ...fileListCustomerBurden1, imageListCustomerBurden });
        setLoading(false)
    };
    const handleChangeCustomerStatementImage = async ({ fileList }) => { // 8
        // setLoading(true)
        // setAllImg({ ...allImg, ts8: fileList });
        for (const removedFile of fileListCustomerStatementImage.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 8)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 8));
                setAllImg(updatedAllImg);
            }
        }
        setFileListCustomerStatementImage(fileList);
        // var imageListCustomerStatementImage = []
        // // const compressedFileList = await Promise.all(
        // //     fileList.map(async (file) => {
        // //         // Compress the image
        // //         const compressedFile = await new Promise((resolve) => {
        // //             new Compressor(file.originFileObj, {
        // //                 quality: 0.6, // เช่น, 60%
        // //                 success: (result) => {
        // //                     resolve(result);
        // //                 },
        // //             });
        // //         });

        // //         // Update the file with the compressed version
        // //         return {
        // //             ...file,
        // //             originFileObj: compressedFile,
        // //         };
        // //     })
        // // );
        // var i = 0
        // for (const file of fileList) {
        //     const compressedFile = await new Promise((resolve) => {
        //         new Compressor(file.originFileObj, {
        //             quality: 0.6, // เช่น, 60%
        //             success: (result) => {
        //                 resolve(result);
        //             },
        //         });
        //     });
        //     var image = {}
        //     let filename = file.name
        //     let image64 = await getBase64(compressedFile)
        //     //let image64 = file.originFileObj
        //     image.filename = filename
        //     image.image64 = image64
        //     image.type = 8
        //     imageListCustomerStatementImage.push(image)
        //     i++
        // }
        // setFileListCustomerStatementImage1({ ...fileListCustomerStatementImage1, imageListCustomerStatementImage });
        setLoading(false)
    };

    const handleChangeCustomerStatementPDF = async ({ fileList }) => { // 7
        // setLoading(true)
        // setAllImg({ ...allImg, ts7: fileList });
        for (const removedFile of fileListCustomerStatementPDF.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 7)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 7));
                setAllImg(updatedAllImg);
            }
        }
        setFileListCustomerStatementPDF(fileList);
        //console.log("fileList",fileList)
        // var imageListCustomerStatementPDF = []
        // for (const file of fileList) {
        //     var image = {}
        //     let filename = file.name
        //     let image64 = await getBase64(file.originFileObj)
        //     //let image64 = file.originFileObj
        //     image.filename = filename
        //     image.image64 = image64
        //     image.type = 7
        //     imageListCustomerStatementPDF.push(image)
        // }
        // setFileListCustomerStatementPDF1({ ...fileListCustomerStatementPDF1, imageListCustomerStatementPDF });
        setLoading(false)
    };
    const handleChangeCustomerCareer = async ({ fileList }) => { // 6
        // setLoading(true)
        // setAllImg({ ...allImg, ts6: fileList });
        for (const removedFile of fileListCustomerCareer.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 6)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 6));
                setAllImg(updatedAllImg);
            }
        }
        setFileListCustomerCareer(fileList);
        // var imageListCustomerCareer = []
        // // const compressedFileList = await Promise.all(
        // //     fileList.map(async (file) => {
        // //         // Compress the image
        // //         const compressedFile = await new Promise((resolve) => {
        // //             new Compressor(file.originFileObj, {
        // //                 quality: 0.6, // เช่น, 60%
        // //                 success: (result) => {
        // //                     resolve(result);
        // //                 },
        // //             });
        // //         });
        // //         // Update the file with the compressed version
        // //         return {
        // //             ...file,
        // //             originFileObj: compressedFile,
        // //         };
        // //     })
        // // );
        // var i = 0
        // for (const file of fileList) {
        //     const compressedFile = await new Promise((resolve) => {
        //         new Compressor(file.originFileObj, {
        //             quality: 0.6, // เช่น, 60%
        //             success: (result) => {
        //                 resolve(result);
        //             },
        //         });
        //     });
        //     var image = {}
        //     let filename = file.name
        //     let image64 = await getBase64(compressedFile)
        //     //let image64 = file.originFileObj
        //     image.filename = filename
        //     image.image64 = image64
        //     image.type = 6
        //     imageListCustomerCareer.push(image)
        //     i++
        // }
        // setFileListCustomerCareer1({ ...fileListCustomerCareer1, imageListCustomerCareer });
        setLoading(false)
    };

    // const handleChangeCustomerOther = async ({ fileList }) => { // 9
    //     // setLoading(true)
    //     // setAllImg({ ...allImg, ts9: fileList });
    //     for (const removedFile of fileListCustomerOther.filter(file => file.status === 'removed')) {
    //         if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 9)) {
    //             const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 9));
    //             setAllImg(updatedAllImg);
    //         }
    //     }
    //     setFileListCustomerOther(fileList);
    //     // var imageListCustomerOther = []
    //     // // const compressedFileList = await Promise.all(
    //     // //     fileList.map(async (file) => {
    //     // //         // Compress the image
    //     // //         const compressedFile = await new Promise((resolve) => {
    //     // //             new Compressor(file.originFileObj, {
    //     // //                 quality: 0.6, // เช่น, 60%
    //     // //                 success: (result) => {
    //     // //                     resolve(result);
    //     // //                 },
    //     // //             });
    //     // //         });

    //     // //         // Update the file with the compressed version
    //     // //         return {
    //     // //             ...file,
    //     // //             originFileObj: compressedFile,
    //     // //         };
    //     // //     })
    //     // // );
    //     // //if (file?.uid === fileList[fileList.length - 1]?.uid || fileListCustomerOther1?.length != fileList?.length) {
    //     // for (const file of fileList) {
    //     //     const compressedFile = await new Promise((resolve) => {
    //     //         new Compressor(file.originFileObj, {
    //     //             quality: 0.6, // เช่น, 60%
    //     //             success: (result) => {
    //     //                 resolve(result);
    //     //             },
    //     //         });
    //     //     });
    //     //     var image = {}
    //     //     let filename = file.name
    //     //     let image64 = await getBase64(compressedFile)
    //     //     //let image64 = file.originFileObj
    //     //     image.filename = filename
    //     //     image.image64 = image64
    //     //     image.type = 9
    //     //     imageListCustomerOther.push(image)
    //     // }
    //     // setFileListCustomerOther1({ ...fileListCustomerOther1, imageListCustomerOther });
    //     //}
    //     setLoading(false)
    // };

    // const handleRemoveImage = (index) => {
    //     // Remove image from preview list
    //     const newPreviewList = [...fileListCustomerOther];
    //     newPreviewList.splice(index, 1);
    //     setFileListCustomerOther(newPreviewList);

    //     // Remove image from imageListCustomerOther1
    //     setFileListCustomerOther1((prev) => {
    //         const newImageList = [...(prev.imageListCustomerOther || [])];
    //         newImageList.splice(index, 1);
    //         return { imageListCustomerOther: newImageList };
    //     });
    // };
    // const handleChangeCustomerOther = async (e) => {
    //     const files = Array.from(e.target.files);
    //     //console.log("files =", files)
    //     try {
    //         // const compressedFileList = await Promise.all(
    //         //     files.map(async (file) => {
    //         //         const compressedFile = await new Promise((resolve) => {
    //         //             new Compressor(file, {
    //         //                 quality: 0.6,
    //         //                 success: (result) => {
    //         //                     resolve(result);
    //         //                 },
    //         //             });
    //         //         });
    //         //         return {
    //         //             ...file,
    //         //             originFileObj: compressedFile,
    //         //         };
    //         //     })
    //         // );
    //         // preview images
    //         // setFileListCustomerOther((prev) => [
    //         //     ...prev,
    //         //     ...compressedFileList.map((file) => URL.createObjectURL(file.originFileObj)),
    //         // ]);
    //         const newFileList = await Promise.all(
    //             files.map(async (file) => {
    //                 return new Promise((resolve) => {
    //                     new Compressor(file, {
    //                         quality: 0.6,
    //                         success: async (result) => {
    //                             const filename = result.name;
    //                             const image64 = await getBase64(result);
    //                             // เรียก resolve เมื่อทั้งหมดเสร็จสิ้น
    //                             resolve({
    //                                 filename,
    //                                 image64,
    //                                 type: 9,
    //                             });
    //                         },
    //                     });
    //                 });
    //             })
    //         );

    //         setFileListCustomerOther((prev) => [...prev, ...newFileList]);
    //         setFileListCustomerOther1((prev) => ({
    //             imageListCustomerOther: [...(prev.imageListCustomerOther || []), ...newFileList],
    //         }));
    //         // const newImageList = await Promise.all(
    //         //     compressedFileList.map(async (file) => {
    //         //         const filename = file.originFileObj.name;
    //         //         const image64 = await getBase64(file.originFileObj);
    //         //         //const image64 = file.originFileObj;
    //         //         return {
    //         //             filename,
    //         //             image64,
    //         //             type: 9,
    //         //         };
    //         //     })
    //         // );
    //         // setFileListCustomerOther1((prev) => ({
    //         //     imageListCustomerOther: [...(prev.imageListCustomerOther || []), ...newImageList],
    //         // }));
    //     } catch (err) {
    //         console.error("Error during file processing:", err);
    //     }
    // };

    // const uploadImage = async options => {
    //     const { onSuccess, onError, file, onProgress } = options;
    //     try {
    //       console.log(file)
    //       onSuccess("Ok");
    //     } catch (err) {
    //       console.log("Eroor: ", err);
    //       const error = new Error("Some error");
    //       onError({ err });
    //     }
    //   };

    // คนค้ำ
    // const handleChangeGuarantor = async ({ fileList }) => {
    //     // setLoading(true)
    //     // setAllImg({ ...allImg, ts5: fileList });
    //     setFileListGuarantor(fileList);
    //     var imageListGuarantor = []
    //     // const compressedFileList = await Promise.all(
    //     //     fileList.map(async (file) => {
    //     //         // Compress the image
    //     //         const compressedFile = await new Promise((resolve) => {
    //     //             new Compressor(file.originFileObj, {
    //     //                 quality: 0.6, // เช่น, 60%
    //     //                 success: (result) => {
    //     //                     resolve(result);
    //     //                 },
    //     //             });
    //     //         });

    //     //         // Update the file with the compressed version
    //     //         return {
    //     //             ...file,
    //     //             originFileObj: compressedFile,
    //     //         };
    //     //     })
    //     // );
    //     var i = 0
    //     for (const file of fileList) {
    //         const compressedFile = await new Promise((resolve) => {
    //             new Compressor(file.originFileObj, {
    //                 quality: 0.6, // เช่น, 60%
    //                 success: (result) => {
    //                     resolve(result);
    //                 },
    //             });
    //         });
    //         var image = {}
    //         let filename = file.name
    //         let image64 = await getBase64(compressedFile)
    //         //let image64 = file.originFileObj
    //         image.filename = filename
    //         image.image64 = image64
    //         image.type = 5
    //         imageListGuarantor.push(image)
    //         i++
    //     }
    //     setFileListGuarantor1({ ...fileListGuarantor1, imageListGuarantor });
    //     // if (imageListGuarantor) {
    //     //     handleScanIdCardGuarantor(imageListGuarantor);
    //     // } else {
    //     //     handleScanIdCardGuarantor([]);
    //     // }
    //     setLoading(false)
    // };

    // const showConfirmGu = (value, index) => {
    //     setLoading(true)
    //     confirm({
    //         title: 'ไม่สามารถอ่านบัตรคนค้ำประกันได้ !',
    //         content: 'คุณต้องการดำเนินการต่อหรือไม่ ?',
    //         onOk() {
    //             navigate('/offercase/addcustomer')
    //             setLoading(false)
    //         },
    //         onCancel() {
    //             setLoading(false)
    //         },
    //     });
    // }

    const showConfirm = (value, index) => {
        setLoading(true)
        confirm({
            title: 'คนกู้และคนค้ำประกันเป็นคนเดียวกัน...?',
            content: 'กรุณาเลือกบัตรใหม่',
            onOk() {
                setDataPostGuarantor({})
                dispatch(addCustomer())
                dispatch(addAddress())
                dispatch(addPhone())
                dispatch(addCar())
                dispatch(addGuarantor())
                dispatch(addCareerIncome())
                dispatch(addLoan())
                dispatch(addOldLoan())
                dispatch(addImage())
                dispatch(addLand())
                dispatch(addLoanLand())
                dispatch(addOldLoanLand())
                dispatch(addOccupation())
                dispatch(addEmail())
                setFileListGuarantor1({})
                navigate('/offercase')
                setLoading(false)
            },
            onCancel() {
                message.error('ยกเลิก')
                setDataPostGuarantor({})
                dispatch(addCustomer())
                dispatch(addAddress())
                dispatch(addPhone())
                dispatch(addCar())
                dispatch(addGuarantor())
                dispatch(addCareerIncome())
                dispatch(addLoan())
                dispatch(addOldLoan())
                dispatch(addImage())
                dispatch(addLand())
                dispatch(addLoanLand())
                dispatch(addOldLoanLand())
                dispatch(addOccupation())
                dispatch(addEmail())
                setFileListGuarantor1({})
                navigate('/offercase')
                setLoading(false)
            },
        });
    }

    ////////////// รับไฟล์รูปบัตร /////////////////////////////////
    const handleFileInputChange = async (event) => {
        if (event.length > 0) {
            var file = event[0].originFileObj
            const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg']
            // const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
            if (file) {
                if (validImageTypes.includes(file.type)) {
                    const reader = new FileReader(); // สร้าง FileReader object
                    reader.onloadend = () => { // ฟังก์ชันที่ทำงานเมื่ออ่านไฟล์เสร็จสิ้น    
                        const base64Data = reader.result; // รับข้อมูล Base64 จาก FileReader result
                        //console.log('Base64 Data:', base64Data);
                        //setFileList({ image: base64Data })
                        handleScanIdCard(base64Data)
                    }
                    reader.readAsDataURL(file) // อ่านไฟล์และแปลงเป็น Base64    
                }
            }
        }
    }

    ////////////// รับไฟล์รูปบัตรคนค้ำ /////////////////////////////////
    // const handleFileInputChangeGuarantor = (event) => {
    //     console.log("evnet", event)
    //     // setFileListCustomer1(event) //set to redux
    //     // setStep(1)
    //     // const file = event.target.files[0];
    //     var file = event[0].originFileObj
    //     console.log("file =", file)
    //     const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg']
    //     if (file) {
    //         if (validImageTypes.includes(file.type)) {
    //             const reader = new FileReader(); // สร้าง FileReader object
    //             reader.onloadend = () => { // ฟังก์ชันที่ทำงานเมื่ออ่านไฟล์เสร็จสิ้น    
    //                 const base64Data = reader.result; // รับข้อมูล Base64 จาก FileReader result
    //                 //console.log('Base64 Data:', base64Data);
    //                 setFileList({ image: base64Data })
    //                 handleScanIdCardGuarantor(base64Data)
    //             }
    //             reader.readAsDataURL(file) // อ่านไฟล์และแปลงเป็น Base64    
    //         }
    //     }
    // }
    /////////// ดึงข้อมูลจากรูปบัตรประชาชน /////////////////////////////////////////////////////////////////  
    const handleScanIdCard = async (value) => {
        const imgId = { image: value }
        setLoading(true)
        const headers = { 'Content-Type': 'application/json' }
        await axios.post(testupload, imgId, { headers: headers })
            .then(res => {
                //console.log("img", res.data)
                if (res.data) {
                    //setDataPost(res.data[0])
                    //alert('สามารถอ่านได้')
                    splitAddress(res.data[0])
                    loadDataOne(res.data[0])
                    setLoading(false)
                }
                else {
                    alert('ไม่สามารถอ่านบัตรได้')
                    //message.info('ไม่สามารถอ่านได้')
                    setDataPost({})
                    setDataAddress({})
                    setDataPhone({})
                    setLoading(false)
                }
            })
            .catch((err) => alert('ไม่สามารถอ่านบัตรได้'))
        setLoading(false)
    }
    /////////// ดึงข้อมูลจากรูปบัตรประชาชนคนค้ำ /////////////////////////////////////////////////////////////////  
    const handleScanIdCardGuarantor = async (value) => {
        // console.log("A", value)
        var dataListGuarantor = []
        for (let i = 0; i < value?.length; i++) {
            var imgId = { image: value[i].image64 }
            //setLoading(true)
            const headers = { 'Content-Type': 'application/json' }
            await axios.post(testupload, imgId, { headers: headers })
                .then(async (res) => {
                    delete res.data[0].address
                    if (res.data) {
                        //console.log(res.data[0])
                        const b = await loadDataOneGuarantor(res.data[0])
                        dataListGuarantor.push({ ...b[0], imgs: value[i] })
                        //setLoading(false)
                    }
                    else {
                        //alert('ไม่สามารถอ่านบัตรคนค้ำประกันได้')
                        //console.log("ค้ำ =", res.data)
                        //message.info('ไม่สามารถอ่านได้')
                        //setLoading(false)
                    }
                })
                .catch((err) => console.log("err =", err))
            //setLoading(false)
        }
        dispatch(addGuarantor(dataListGuarantor))
    }
    ////////////// ข้อมูลยูสในเดต้าเบส ////////////////////////////////////////////////////////////////
    const loadDataOne = async (data) => {
        //console.log("data =", data)
        const one = { identificationId: data.identificationId }
        //console.log("CUS", data.identificationId)
        if (!data.identificationId) {
            console.log("ไม่มีเลขบัตรคนกู้")
            alert('ไม่สามารถอ่านบัตรผู้ขอกู้ได้')
            setDataPost()
            setDataPhone()
            setDataAddress()
            dispatch(addCareerIncome())
            localStorage.removeItem('addCareerIncome');
        } else {
            setIDCustomer(data.identificationId)
            setLoading(true)
            await axios.post(checkCCAP, one)
                .then((res) => {
                    if (res.data) {
                        message.info('มีข้อมูลในฐานข้อมูล')
                        if (window.confirm('คุณต้องการใช้ข้อมูลในฐานข้อมูลหรือไม่ ?')) {
                            // Save it!
                            setDataPost(res.data)
                            setDataPhone(res.data.phones[0])
                            setDataAddress(res.data.address[0])
                            // setDataCareerIncome(res.data.ojs[0])
                            if (res.data?.ojs[0]) {
                                dispatch(addCareerIncome(res.data.ojs[0]))
                                localStorage.setItem('addCareerIncome', JSON.stringify(res.data.ojs[0]))
                            }
                            if (res.data.expdt == null || res.data.issudt == null) {
                                form.setFieldsValue(
                                    {
                                        ...res.data,
                                        telp: res?.data?.phones[0]?.telp,
                                        birthdate: dayjs(res?.data?.birthdate),
                                        issudt: '',
                                        expdt: '',
                                    }
                                )
                                setLoading(false)
                            }
                            else {
                                form.setFieldsValue(
                                    {
                                        ...res.data,
                                        telp: res?.data?.phones[0]?.telp,
                                        birthdate: dayjs(res?.data?.birthdate),
                                        issudt: dayjs(res?.data?.issudt),
                                        expdt: dayjs(res?.data?.expdt),
                                    }
                                )
                                setLoading(false)
                            }
                        } else {
                            // Do nothing!
                            console.log("dataAddress", data)
                            setDataPost({ ...data, customerId: res.data.customerId })
                            if (res.data.phones) {
                                setDataPhone({ ...dataPhone, customerId: res.data.customerId, phoneId: res.data.phones[0].phoneId })
                            }
                            // setDataCareerIncome()
                            // setDataAddress({ ...dataAddress, customerId: res.data.customerId, addressId: res.data.address[0].addressId })
                            if (data.expdt == null || data.issudt == null) {
                                form.setFieldsValue(
                                    {
                                        ...data,
                                        nickname: '',
                                        gender: '',
                                        birthdate: dayjs(data?.birthdate),
                                        issudt: '',
                                        expdt: '',
                                        telp: '',
                                    }
                                )
                                setLoading(false)
                            }
                            else {
                                form.setFieldsValue(
                                    {
                                        ...data,
                                        nickname: '',
                                        gender: '',
                                        birthdate: dayjs(data?.birthdate),
                                        issudt: dayjs(data?.issudt),
                                        expdt: dayjs(data?.expdt),
                                        telp: '',
                                    }
                                )
                            }
                        }
                        setLoading(false)
                    }
                    else {
                        //alert('ไม่พบข้อมูลในฐานข้อมูล')
                        message.info('ไม่พบข้อมูลในฐานข้อมูล')
                        setDataPost({ ...data, customerId: 0 })
                        splitAddress(...data.address)
                        setDataAddress({ ...data.address })
                        setDataAddress({ ...dataAddress, customerId: 0, addressId: 0 })

                        if (data.expdt == null || data.issudt == null) {
                            form.setFieldsValue(
                                {
                                    ...data,
                                    nickname: '',
                                    gender: '',
                                    birthdate: dayjs(data?.birthdate) || "",
                                    issudt: '',
                                    expdt: '',
                                    telp: '',
                                }
                            )
                            setLoading(false)
                        }
                        else {
                            form.setFieldsValue(
                                {
                                    ...data,
                                    nickname: '',
                                    gender: '',
                                    birthdate: dayjs(data?.birthdate) || "",
                                    issudt: '',
                                    expdt: '',
                                    telp: '',
                                }
                            )
                        }
                        setLoading(false)
                    }
                })
                .catch((err) => console.log(err))
            setLoading(false)
        }
    }
    ////////////// ข้อมูลยูสในเดต้าเบสคนค้ำ ////////////////////////////////////////////////////////////////
    const loadDataOneGuarantor = async (data) => {
        console.log("DATA", data.identificationId)
        console.log("ids", idcustomer)
        setLoading(true)
        if (!data?.identificationId) {
            console.log("ไม่มีเลขบัตรคนค้ำ")
            //alert('ไม่สามารถอ่านบัตรคนค้ำประกันได้')
            //showConfirmGu()
        }
        else if (data.identificationId === idcustomer) {
            showConfirm()
            setLoading(false)
        } else {
            var dataListGuarantor = []
            if (data) {
                var one = { identificationId: data.identificationId }
                setLoading(true)
                await axios.post(checkCCAP, one)
                    .then((res) => {
                        if (res.data) {
                            // alert('มีข้อมูลในฐานข้อมูล')
                            message.info('คนค้ำมีข้อมูลในฐานข้อมูล')
                            delete res.data.address
                            delete res.data.carLoanDetails
                            delete res.data.emails
                            delete res.data.ojs
                            delete res.data.socials
                            dataListGuarantor.push(res.data)
                            setDataPostGuarantor({ ...dataPostGuarantor, ...data, customerId: 0 })
                            setLoading(false)
                            // setTimeout(() => {
                            //     navigate('/offercase/addcustomer')
                            // }, 1000)
                            //setIsModalOpen(true)
                            // if (window.confirm('คุณต้องการใช้ข้อมูลในฐานข้อมูลหรือไม่ ?')) {
                            //     console.log("RES-DATAผู้ค้ำ", res.data)
                            //     dataListGuarantor.push(res.data)
                            //     // console.log("dataListGuarantor =", dataListGuarantor)
                            //     setDataPostGuarantor(res.data)
                            // } else {
                            //     // Do nothing!
                            //     console.log("dataAddressGuarantor", data)
                            //     // splitAddress(data)
                            //     // setDataAddress({ ...data })
                            // }

                        }
                        else {
                            // alert('คนค้ำไม่พบข้อมูลในฐานข้อมูล')
                            message.info('ไม่พบข้อมูลในฐานข้อมูล')
                            console.log("RES-DATA NO =", data)
                            dataListGuarantor.push(data)
                            setDataPostGuarantor({ ...dataPostGuarantor, ...data, customerId: 0 })
                            setLoading(false)
                            // setDataAddressGuarantor({ ...dataAddressGuarantor, customerId: 0, addressId: 0 })
                            // setDataPhoneGuarantor({})
                        }
                    })
                    .catch((err) => console.log(err))
                setLoading(false)
            }
            // console.log("dataListGuarantor =", dataListGuarantor)
            return dataListGuarantor
            //dispatch(addGuarantor(dataListGuarantor))

        }
        setLoading(false)
    }
    //////////////// เก็บแอสเดรส ////////////////////////////////////
    const splitAddress = (value) => {
        let updatedCount = { ...count }
        //console.log("โหลด",value.address)
        let part = value.address.split(" ")
        if (part[1].includes("หมู่ที่") === true) {
            //setHouseNumber(part[0] + part[1] + part[2])
            updatedCount.houseNumber = part[0] + part[1] + part[2]
        } else {
            //setHouseNumber(part[0])
            updatedCount.houseNumber = part[0]
        }
        //setMoo(part[2])
        //setDataAddress({...dataAddress, moo: part[2]})
        for (let i = 0; i <= part.length; i++) {
            if (part[i]?.includes("ซ.") === true) {
                let s = part[i].split("ซ.")
                //setSoi(s[1])
                updatedCount.soi = s[1]
            }
            if (part[i]?.includes("ถ.") === true) {
                let tanon = part[i].split("ถ.")
                //setRoad(tanon[1])
                updatedCount.road = tanon[1]
            }
            if (part[i]?.includes("ต.") === true) {
                let bn = part[i].split("ต.")
                //setSubdistrict(bn[1])
                updatedCount.subdistrict = bn[1]
            }
            if (part[i]?.includes("อ.") === true) {
                let oo = part[i].split("อ.")
                let oop = oo[1].split(",")
                if (oo[1]?.includes(",") === false) {
                    //setDistrict(oo[1])
                    updatedCount.district = oo[1]
                }
                if (oop[1]?.includes("จ.") === true) {
                    //setDistrict(oop[0])
                    updatedCount.district = oop[0]
                    let oops = oop[1].split("จ.")
                    //setProvince(oops[1])
                    updatedCount.province = oops[1]
                }
            }
            if (part[i]?.includes("จ.") === true) {
                let jh = part[i].split("จ.")
                //setProvince(jh[1])
                updatedCount.province = jh[1]
            }
        }
        setDataAddress({
            ...dataAddress,
            customerId: 0,
            addressId: 0,
            houseNumber: updatedCount.houseNumber,
            houseRegistrationNumber: updatedCount.houseRegistrationNumber,
            village: updatedCount.village,
            zipCode: updatedCount.zipCode,
            soi: updatedCount.soi,
            road: updatedCount.road,
            subdistrict: updatedCount.subdistrict,
            district: updatedCount.district,
            province: updatedCount.province,
            addressStatus: true,
            addressOnIdcard: true,
            workAddress: false,
            otherAddress: false,
            currentAddress: false,
            sendDocuments: false,
            houseRegistration: false,
        })
    }
    // //////////////// เก็บแอสเดรสคนค้ำ ////////////////////////////////////
    // const splitAddressGuarantor = (value) => {
    //     let updatedCount = { ...count }
    //     //console.log("โหลด",value.address)
    //     let part = value.address.split(" ")
    //     if (part[1].includes("หมู่ที่") === true) {
    //         //setHouseNumber(part[0] + part[1] + part[2])
    //         updatedCount.houseNumber = part[0] + part[1] + part[2]
    //     } else {
    //         //setHouseNumber(part[0])
    //         updatedCount.houseNumber = part[0]
    //     }
    //     //setMoo(part[2])
    //     //setDataAddress({...dataAddress, moo: part[2]})
    //     for (let i = 0; i <= part.length; i++) {
    //         if (part[i]?.includes("ซ.") === true) {
    //             let s = part[i].split("ซ.")
    //             //setSoi(s[1])
    //             updatedCount.soi = s[1]
    //         }
    //         if (part[i]?.includes("ถ.") === true) {
    //             let tanon = part[i].split("ถ.")
    //             //setRoad(tanon[1])
    //             updatedCount.road = tanon[1]
    //         }
    //         if (part[i]?.includes("ต.") === true) {
    //             let bn = part[i].split("ต.")
    //             //setSubdistrict(bn[1])
    //             updatedCount.subdistrict = bn[1]
    //         }
    //         if (part[i]?.includes("อ.") === true) {
    //             let oo = part[i].split("อ.")
    //             let oop = oo[1].split(",")
    //             if (oo[1]?.includes(",") === false) {
    //                 //setDistrict(oo[1])
    //                 updatedCount.district = oo[1]
    //             }
    //             if (oop[1]?.includes("จ.") === true) {
    //                 //setDistrict(oop[0])
    //                 updatedCount.district = oop[0]
    //                 let oops = oop[1].split("จ.")
    //                 //setProvince(oops[1])
    //                 updatedCount.province = oops[1]
    //             }
    //         }
    //         if (part[i]?.includes("จ.") === true) {
    //             let jh = part[i].split("จ.")
    //             //setProvince(jh[1])
    //             updatedCount.province = jh[1]
    //         }
    //     }
    //     setDataAddressGuarantor({
    //         ...dataAddressGuarantor,
    //         customerId: 0,
    //         addressId: 0,
    //         houseNumber: updatedCount.houseNumber,
    //         houseRegistrationNumber: updatedCount.houseRegistrationNumber,
    //         village: updatedCount.village,
    //         zipCode: updatedCount.zipCode,
    //         soi: updatedCount.soi,
    //         road: updatedCount.road,
    //         subdistrict: updatedCount.subdistrict,
    //         district: updatedCount.district,
    //         province: updatedCount.province,
    //         addressStatus: true,
    //         addressOnIdcard: true,
    //         workAddress: false,
    //         otherAddress: false,
    //         currentAddress: false,
    //         sendDocuments: false,
    //         houseRegistration: false,
    //     })
    // }
    const handleDocumentsCar = async () => {  //โยนไปปุ่ม submits
        console.log("รกเล็ก")
        //setLoading(true)
        await axios.post(bookCar, fileList) //ตัวจริง
            .then(res => {
                if (res.data) {
                    console.log("รถเล็ก", res.data)
                    // setDataDocument(res.data.result)
                    dispatch(addCar({
                        ...res.data.result,
                        carColor: res.data.result.vehicle_color,
                        carProvince: res.data.result.vehicle_license_province,
                        carPlateNumber: res.data.result.vehicle_license_number,
                        carChassisNumber: res.data.result.vehicle_chassis_number,
                        carEngineNumber: res.data.result.vehicle_engine_number,
                        productTypeCar: selectTypeCar.productTypeCar
                    }))
                    localStorage.setItem('addCar', JSON.stringify({
                        ...res.data.result,
                        carColor: res.data.result.vehicle_color,
                        carProvince: res.data.result.vehicle_license_province,
                        carPlateNumber: res.data.result.vehicle_license_number,
                        carChassisNumber: res.data.result.vehicle_chassis_number,
                        carEngineNumber: res.data.result.vehicle_engine_number,
                        productTypeCar: selectTypeCar.productTypeCar
                    }))
                    //setLoading(false)
                }
            })
            .catch((err) => console.error('เกิดข้อผิดพลาดในการอัปโหลดรูปภาพ:', err))
        //setLoading(false)
    };
    const handleDocumentsTruckandVehicles = async () => { //โยนไปปุ่ม submit
        //setLoading(true)
        await axios.post(bookCarBig, fileList) //ตัวจริง
            .then(res => {
                if (res.data) {
                    // console.log("รถใหญ่", res.data)
                    // setDataDocument(res.data.result)
                    dispatch(addCar({
                        ...res.data.result,
                        carColor: res.data.result.vehicle_color,
                        carProvince: res.data.result.vehicle_license_province,
                        carPlateNumber: res.data.result.vehicle_license_number,
                        carChassisNumber: res.data.result.vehicle_chassis_number,
                        carEngineNumber: res.data.result.vehicle_engine_number,
                        productTypeCar: selectTypeCar.productTypeCar
                    }))
                    localStorage.setItem('addCar', JSON.stringify({
                        ...res.data.result,
                        carColor: res.data.result.vehicle_color,
                        carProvince: res.data.result.vehicle_license_province,
                        carPlateNumber: res.data.result.vehicle_license_number,
                        carChassisNumber: res.data.result.vehicle_chassis_number,
                        carEngineNumber: res.data.result.vehicle_engine_number,
                        productTypeCar: selectTypeCar.productTypeCar
                    }))
                    //setLoading(false)
                }
            })
            .catch((err) => console.error('เกิดข้อผิดพลาดในการอัปโหลดรูปภาพ:', err))
        //setLoading(false)
    };

    return (
        <Row justify={'center'}>
            <Card style={{ width: '1000px' }}>
                <Spin spinning={loading} size='large' tip=" Loading... ">
                    <div className='text-center'>
                        <h2>อัปโหลดรูปภาพเสนอเคส</h2>
                    </div>
                    <Divider />
                    <Row justify={'center'}>
                        <Form
                            name="basic"
                            labelCol={{
                                span: 12,
                            }}

                            style={{
                                width: '90%',
                            }}
                            initialValues={{
                                remember: true,
                            }}
                            form={form}
                            onFinish={handleSubmit}
                            autoComplete="off"
                        >
                            {/* <Card style={{ margin: 0 }}> */}
                            <Col className='main1'>
                                <Row justify={'center'}>
                                    <b>
                                        <Form.Item label='เลือกเสนอเคส' name='productTypeId'
                                            style={{ textAlign: 'center', width: '300px' }}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your username!',
                                                },
                                            ]}
                                        >
                                            <Select placeholder={'เลือก'}
                                                style={{ width: '250px' }}
                                                onChange={(value) => handleProduct(value)}
                                            >
                                                <Option value={1}>รถ</Option>
                                                {/* <Option value={2}>พรี-ที่ดิน</Option> */}
                                                <Option value={3}>ขอยอดที่ดิน</Option>
                                            </Select>
                                        </Form.Item>
                                    </b>
                                </Row>

                                {selectProduct?.productTypeId === 1 ?
                                    <>
                                        <Row justify={'center'}>
                                            <b>
                                                <Form.Item label='เลือกประเภทรถ' name='productTypeCar'
                                                    style={{ textAlign: 'center', width: '300px' }}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input your username!',
                                                        },
                                                    ]}
                                                >
                                                    <Select placeholder={'เลือก'}
                                                        style={{ width: '250px' }}
                                                        onChange={(value) => handleProductTypeCar(value)}
                                                    >
                                                        <Option value={1}>รถยนต์</Option>
                                                        <Option value={2}>รถเครื่องจักการเกษตร</Option>
                                                        <Option value={3}>รถบรรทุก</Option>
                                                    </Select>
                                                </Form.Item>
                                            </b>
                                        </Row>
                                        <Divider style={{ margin: 5 }} />
                                        <Row justify={'left'}><b><h3><u>รูปบัตรประชาชน (อัพโหลดไฟล์ไม่เกิน 3MB)</u></h3></b></Row>
                                        <Row justify={'center'} style={{ margin: 5 }}><b><h4 style={{ color: 'red' }}><u>ถ้าเป็น "นิติบุคคล" ห้ามอัพรูปในช่องผู้ทำสัญญา</u></h4></b></Row>
                                        <Row justify={'center'}>
                                            <aside justify={'center'}>
                                                <div className='gutter-row' span={12}>
                                                    <b>
                                                        {dataAddImage?.map((imageData, index) => {
                                                            if (imageData.type === "4") {
                                                                return (
                                                                    <Row justify={'center'}>
                                                                        <Image width={'200px'} key={index} src={`${getImagess}/${imageData.image}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                    </Row>
                                                                );
                                                            }
                                                            return null;
                                                        })}
                                                        <Form.Item label='ผู้ทำสัญญา'
                                                            style={{ textAlign: 'center' }}
                                                        >
                                                            <Upload
                                                                accept="image/*"
                                                                maxCount={1}
                                                                // multiple={true}
                                                                style={{ textAlign: 'center' }}
                                                                listType="picture-card"
                                                                fileList={fileListCustomer}
                                                                onPreview={handlePreview}
                                                                onChange={handleChangeCustomer}
                                                                //beforeUpload={() => false}
                                                                beforeUpload={(file) => beforeUpts(file, 4).then(() => false).catch(() => true)}
                                                            >
                                                                {fileListCustomer?.length >= 1 ? null : (
                                                                    <div>
                                                                        <PlusOutlined />
                                                                        <div className="ant-upload-text">upload</div>
                                                                    </div>
                                                                )}
                                                            </Upload>
                                                            <Modal open={previewVisible} onCancel={handleCancel}>
                                                                <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                            </Modal>
                                                        </Form.Item>
                                                    </b>
                                                </div>
                                            </aside>
                                        </Row>
                                        <Divider style={{ margin: 5 }} />
                                        {selectTypeCar?.productTypeCar === 1 || selectTypeCar?.productTypeCar === 2 || selectTypeCar?.productTypeCar === 3 ?
                                            <>
                                                <Row justify={'left'}><b><h3><u>หน้าคู่มือ (อัพโหลดไฟล์ไม่เกิน 3MB)</u></h3></b></Row>
                                                <Row justify={'center'}>
                                                    <aside justify={'center'}>
                                                        <div className='gutter-row' span={12}>
                                                            <b>
                                                                {dataAddImage?.map((imageData, index) => {
                                                                    if (imageData.type === "2") {
                                                                        return (
                                                                            <Row justify={'center'}>
                                                                                <Image width={'200px'} key={index} src={`${getImagess}/${imageData.image}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                            </Row>
                                                                        );
                                                                    }
                                                                    return null;
                                                                })}
                                                                <Form.Item
                                                                    style={{ textAlign: 'center' }}
                                                                >
                                                                    <Upload
                                                                        accept="image/*"
                                                                        maxCount={1}
                                                                        // multiple={true}
                                                                        listType="picture-card"
                                                                        style={{ textAlign: 'center' }}
                                                                        fileList={fileListCustomerDocument}
                                                                        onPreview={handlePreview}
                                                                        onChange={handleChangeCustomerDocument}
                                                                        //beforeUpload={() => false}
                                                                        beforeUpload={(file) => beforeUpts(file, 2).then(() => false).catch(() => true)}
                                                                    >
                                                                        {fileListCustomerDocument?.length >= 1 ? null : (
                                                                            <div>
                                                                                <PlusOutlined />
                                                                                <div className="ant-upload-text">upload</div>
                                                                            </div>
                                                                        )}
                                                                    </Upload>
                                                                    <Modal open={previewVisible} onCancel={handleCancel}>
                                                                        <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                                    </Modal>
                                                                </Form.Item>
                                                            </b>
                                                        </div>
                                                    </aside>
                                                </Row>
                                            </>
                                            : null
                                        }
                                        <Divider style={{ margin: 5 }} />
                                        <Row justify={'left'}><b><h3><u>ใบประเมิน / รูปรถ / สัญญาไฟแนนซ์เดิม / ใบเสร็จไฟแนนซ์เดิม (อัพโหลดไฟล์ไม่เกิน 3MB)</u></h3></b></Row>
                                        <Row justify={'center'}>
                                            <aside>
                                                <b>
                                                    {dataAddImage?.map((imageData, index) => {
                                                        if (imageData.type === "1") {
                                                            return (
                                                                <Row justify={'center'}>
                                                                    <Image width={'200px'} key={index} src={`${getImagess}/${imageData.image}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                </Row>
                                                            );
                                                        }
                                                        return null;
                                                    })}
                                                    <Form.Item
                                                        style={{ textAlign: 'center' }}
                                                    >
                                                        <Upload
                                                            accept="image/*"
                                                            multiple={true}
                                                            style={{ textAlign: 'center' }}
                                                            listType="picture-card"
                                                            fileList={fileListCustomerCar}
                                                            onPreview={handlePreview}
                                                            onChange={handleChangeCustomerCar}
                                                            //beforeUpload={() => false}
                                                            beforeUpload={(file) => beforeUpts(file, 1).then(() => false).catch(() => true)}
                                                        // beforeUpload={(file) => {
                                                        //     setFileListCustomerCar([...fileListCustomerCar, file])
                                                        //     return false;
                                                        // }}
                                                        >
                                                            {fileListCustomerCar?.length >= 20 ? null : (
                                                                <div>
                                                                    <PlusOutlined />
                                                                    <div className="ant-upload-text">upload</div>
                                                                </div>
                                                            )}
                                                        </Upload>
                                                        <Modal open={previewVisible} onCancel={handleCancel}>
                                                            <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                        </Modal>
                                                    </Form.Item>
                                                </b>
                                            </aside>
                                        </Row>
                                        <Divider style={{ margin: 5 }} />
                                        <Row justify={'left'}><b><h3><u>ประวัติผู้กู้ / ทะเบียนบ้าน / อาชีพ (อัพโหลดไฟล์ไม่เกิน 3MB)</u></h3></b></Row>
                                        <Row justify={'center'}>
                                            <aside justify={'center'}>
                                                <b>
                                                    {dataAddImage?.map((imageData, index) => {
                                                        if (imageData.type === "6") {
                                                            return (
                                                                <Row justify={'center'}>
                                                                    <Image width={'200px'} key={index} src={`${getImagess}/${imageData.image}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                </Row>
                                                            );
                                                        }
                                                        return null;
                                                    })}
                                                    <Form.Item
                                                        style={{ textAlign: 'center' }}
                                                    >
                                                        <Upload
                                                            accept="image/*"
                                                            multiple={true}
                                                            style={{ textAlign: 'center' }}
                                                            listType="picture-card"
                                                            fileList={fileListCustomerCareer}
                                                            onPreview={handlePreview}
                                                            onChange={handleChangeCustomerCareer}
                                                            //beforeUpload={() => false}
                                                            beforeUpload={(file) => beforeUpts(file, 6).then(() => false).catch(() => true)}
                                                        // beforeUpload={beforeUpload}
                                                        // beforeUpload={(file) => {
                                                        //     setFileListCustomerCareer([...fileListCustomerCareer, file])
                                                        //     console.log("setFileListCustomerCareer", fileListCustomerCareer)
                                                        //     return false;
                                                        // }}
                                                        >
                                                            {fileListCustomerCareer?.length >= 20 ? null : (
                                                                <div>
                                                                    <PlusOutlined />
                                                                    <div className="ant-upload-text">upload</div>
                                                                </div>
                                                            )}
                                                        </Upload>
                                                        <Modal open={previewVisible} onCancel={handleCancel}>
                                                            <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                        </Modal>
                                                    </Form.Item>
                                                </b>

                                            </aside>
                                        </Row>
                                        <Divider style={{ margin: 5 }} />
                                        <Row justify={'left'}><b><h3><u>รูปสเตทเมนต์ผู้กู้ (อัพโหลดไฟล์ไม่เกิน 3MB)</u></h3></b></Row>
                                        <Row justify={'center'}>
                                            <Col span={24}>
                                                {/* <aside justify={'center'}> */}
                                                <Row justify={'center'}><b><h3><u>ไฟล์ภาพ</u></h3></b></Row>
                                                <Row justify={'center'}>
                                                    <b>
                                                        {dataAddImage?.map((imageData, index) => {
                                                            if (imageData.type === "8") {
                                                                return (
                                                                    <Row justify={'center'}>
                                                                        <Image width={'200px'} key={index} src={`${getImagess}/${imageData.image}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                    </Row>
                                                                );
                                                            }
                                                            return null;
                                                        })}
                                                        <Form.Item
                                                            style={{ textAlign: 'center' }}
                                                        >
                                                            <Upload
                                                                accept="image/*"
                                                                multiple={true}
                                                                style={{ textAlign: 'center' }}
                                                                listType="picture-card"
                                                                fileList={fileListCustomerStatementImage}
                                                                onPreview={handlePreview}
                                                                onChange={handleChangeCustomerStatementImage}
                                                                //beforeUpload={() => false}
                                                                beforeUpload={(file) => beforeUpts(file, 8).then(() => false).catch(() => true)}
                                                            // beforeUpload={(file) => {
                                                            //     setFileListCustomerStatementImage([...fileListCustomerStatementImage, file])
                                                            //     return false;
                                                            // }}
                                                            >
                                                                {fileListCustomerStatementImage?.length >= 20 ? null : (
                                                                    <div>
                                                                        <PlusOutlined />
                                                                        <div className="ant-upload-text">upload</div>
                                                                    </div>
                                                                )}
                                                            </Upload>
                                                            <Modal open={previewVisible} onCancel={handleCancel}>
                                                                <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                            </Modal>
                                                        </Form.Item>
                                                    </b>
                                                </Row>
                                                {/* <div className='gutter-row' span={12}>
                                                        <Row justify={'center'}><b><h3><u>ไฟล์ PDF</u></h3></b></Row>
                                                        <b> <Form.Item
                                                            style={{ textAlign: 'center', width: '300px' }}
                                                        >
                                                            <Upload
                                                                accept="application/pdf"
                                                                multiple={true}
                                                                style={{ textAlign: 'center' }}
                                                                listType="picture-card"
                                                                fileList={fileListCustomerStatementPDF}
                                                                onPreview={handlePreview}
                                                                onChange={handleChangeCustomerStatementPDF}
                                                                //beforeUpload={() => false}
                                                                beforeUpload={(file) => beforeUpts(file, 7).then(() => false).catch(() => true)}
                                                            >
                                                                {fileListCustomerStatementPDF?.length >= 10 ? null : (
                                                                    <div>
                                                                        <PlusOutlined />
                                                                        <div className="ant-upload-text">upload</div>
                                                                    </div>
                                                                )}
                                                            </Upload>
                                                            <Modal open={previewVisible} onCancel={handleCancel}>
                                                                <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                            </Modal>
                                                        </Form.Item>
                                                        </b>
                                                    </div> */}
                                                {/* </aside> */}
                                            </Col>
                                        </Row>
                                        <Divider style={{ margin: 5 }} />
                                        <Row justify={'left'}><b><h3><u>รูปสเตทเมนต์ผู้กู้</u></h3></b></Row>
                                        <Row justify={'center'}>
                                            <Col span={24}>
                                                {/* <aside justify={'center'}> */}
                                                {/* <div className='gutter-row'>
                                                        <Row justify={'center'}><b><h3><u>ไฟล์ภาพ</u></h3></b></Row>
                                                        <b> <Form.Item
                                                            style={{ textAlign: 'center', width: '300px' }}
                                                        >
                                                            <Upload
                                                                accept="image/*"
                                                                multiple={true}
                                                                style={{ textAlign: 'center' }}
                                                                listType="picture-card"
                                                                fileList={fileListCustomerStatementImage}
                                                                onPreview={handlePreview}
                                                                onChange={handleChangeCustomerStatementImage}
                                                                //beforeUpload={() => false}
                                                                beforeUpload={(file) => beforeUpts(file, 8).then(() => false).catch(() => true)}
                                                            // beforeUpload={(file) => {
                                                            //     setFileListCustomerStatementImage([...fileListCustomerStatementImage, file])
                                                            //     return false;
                                                            // }}
                                                            >
                                                                {fileListCustomerStatementImage?.length >= 20 ? null : (
                                                                    <div>
                                                                        <PlusOutlined />
                                                                        <div className="ant-upload-text">upload</div>
                                                                    </div>
                                                                )}
                                                            </Upload>
                                                            <Modal open={previewVisible} onCancel={handleCancel}>
                                                                <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                            </Modal>
                                                        </Form.Item>
                                                        </b>

                                                    </div> */}
                                                <Row justify={'center'}><b><h3><u>ไฟล์ PDF</u></h3></b></Row>
                                                <Row justify={'center'}>
                                                    <b>
                                                        {dataAddImage?.map((imageData, index) => {
                                                            if (imageData.type === "7") {
                                                                return (
                                                                    <Row justify={'center'}>
                                                                        <object data={`${getImagess}/${imageData.image}?token=${token}`} type="application/pdf" width="350px" height="450px">
                                                                            <p>Alternative text - include a link <a href={`${getImagess}/${imageData.image}?token=${token}`}>to the PDF!</a></p>
                                                                        </object>
                                                                        <p></p>
                                                                    </Row>
                                                                );
                                                            }
                                                            return null;
                                                        })}
                                                        <Form.Item
                                                            style={{ textAlign: 'center' }}
                                                        >
                                                            <Upload
                                                                accept="application/pdf"
                                                                multiple={true}
                                                                style={{ textAlign: 'center' }}
                                                                listType="picture-card"
                                                                fileList={fileListCustomerStatementPDF}
                                                                onPreview={handlePreview}
                                                                onChange={handleChangeCustomerStatementPDF}
                                                                //beforeUpload={() => false}
                                                                beforeUpload={(file) => beforeUpts(file, 7).then(() => false).catch(() => true)}
                                                            >
                                                                {fileListCustomerStatementPDF?.length >= 10 ? null : (
                                                                    <div>
                                                                        <PlusOutlined />
                                                                        <div className="ant-upload-text">upload</div>
                                                                    </div>
                                                                )}
                                                            </Upload>
                                                            <Modal open={previewVisible} onCancel={handleCancel}>
                                                                <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                            </Modal>
                                                        </Form.Item>
                                                    </b>
                                                </Row>
                                                {/* </aside> */}
                                            </Col>
                                        </Row>

                                        <Divider style={{ margin: 5 }} />
                                    </>
                                    : null
                                }
                                {selectProduct?.productTypeId === 2 ?
                                    <>
                                        {/* ที่ดิน */}
                                        <Divider style={{ margin: 5 }} />
                                        <Row justify={'left'}><b><h3><u>รูปบัตรประชาชน (อัพโหลดไฟล์ไม่เกิน 3MB)</u></h3></b></Row>
                                        <Row justify={'center'}>
                                            <Col span={24} style={{ textAlign: 'center' }}>
                                                <aside gutter={24} justify={'center'}>
                                                    <div className='gutter-row' span={12}>
                                                        <b>
                                                            {dataAddImage?.map((imageData, index) => {
                                                                if (imageData.type === "4") {
                                                                    return (
                                                                        <Row justify={'center'}>
                                                                            <Image width={'200px'} key={index} src={`${getImagess}/${imageData.image}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                        </Row>
                                                                    );
                                                                }
                                                                return null;
                                                            })}
                                                            <Form.Item label='ผู้ทำสัญญา'
                                                                style={{ textAlign: 'center', width: '300px' }}
                                                            >
                                                                <Upload
                                                                    accept="image/*"
                                                                    multiple={true}
                                                                    style={{ textAlign: 'center' }}
                                                                    listType="picture-card"
                                                                    fileList={fileListCustomer}
                                                                    onPreview={handlePreview}
                                                                    onChange={handleChangeCustomer}
                                                                    //beforeUpload={() => false}
                                                                    beforeUpload={(file) => beforeUpts(file, 4).then(() => false).catch(() => true)}
                                                                >
                                                                    {fileListCustomer?.length >= 1 ? null : (
                                                                        <div>
                                                                            <PlusOutlined />
                                                                            <div className="ant-upload-text">upload</div>
                                                                        </div>
                                                                    )}
                                                                </Upload>
                                                                <Modal open={previewVisible} onCancel={handleCancel}>
                                                                    <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                                </Modal>
                                                            </Form.Item>
                                                        </b>
                                                    </div>
                                                </aside>
                                            </Col>
                                        </Row>
                                        <Divider style={{ margin: 5 }} />

                                        <Row className='main' justify={'center'}>
                                            <aside style={{ width: '90%' }}>
                                                <div>
                                                    {dataAddImage?.map((imageData, index) => {
                                                        if (imageData.type === "3") {
                                                            return (
                                                                <Row justify={'center'}>
                                                                    <Image width={'200px'} key={index} src={`${getImagess}/${imageData.image}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                </Row>
                                                            );
                                                        }
                                                        return null;
                                                    })}
                                                    <Row justify={'left'}><b><h3><u>รูปโฉนด/หน้า-หลัง</u></h3></b></Row>
                                                    <b> <Form.Item
                                                        style={{ textAlign: 'center', width: '300px' }}
                                                    >
                                                        <Upload
                                                            accept="image/*"
                                                            multiple={true}
                                                            style={{ textAlign: 'center' }}
                                                            listType="picture-card"
                                                            fileList={fileListCustomerDeed}
                                                            onPreview={handlePreview}
                                                            onChange={handleChangeCustomerDeed}
                                                            //beforeUpload={() => false}
                                                            beforeUpload={(file) => beforeUpts(file, 3).then(() => false).catch(() => true)}
                                                        // beforeUpload={(file) => {
                                                        //     setFileListCustomerDeed([...fileListCustomerDeed, file])
                                                        //     return false;
                                                        // }}
                                                        >
                                                            {fileListCustomerDeed?.length >= 20 ? null : (
                                                                <div>
                                                                    <PlusOutlined />
                                                                    <div className="ant-upload-text">upload</div>
                                                                </div>
                                                            )}
                                                        </Upload>
                                                        <Modal open={previewVisible} onCancel={handleCancel}>
                                                            <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                        </Modal>
                                                    </Form.Item>
                                                    </b>

                                                </div>
                                                <div>
                                                    <Row justify={'left'}><b><h3><u>ใบแบ่งใบต่อ</u></h3></b></Row>
                                                    <b>
                                                        {dataAddImage?.map((imageData, index) => {
                                                            if (imageData.type === "10") {
                                                                return (
                                                                    <Row justify={'center'}>
                                                                        <Image width={'200px'} key={index} src={`${getImagess}/${imageData.image}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                    </Row>
                                                                );
                                                            }
                                                            return null;
                                                        })}
                                                        <Form.Item
                                                            style={{ textAlign: 'center', width: '300px' }}
                                                        >
                                                            <Upload
                                                                accept="image/*"
                                                                multiple={true}
                                                                style={{ textAlign: 'center' }}
                                                                listType="picture-card"
                                                                fileList={fileListCustomerLeafDivider}
                                                                onPreview={handlePreview}
                                                                onChange={handleChangeCustomerLeafDivider}
                                                                //beforeUpload={() => false}
                                                                beforeUpload={(file) => beforeUpts(file, 10).then(() => false).catch(() => true)}
                                                            >
                                                                {fileListCustomerLeafDivider?.length >= 20 ? null : (
                                                                    <div>
                                                                        <PlusOutlined />
                                                                        <div className="ant-upload-text">upload</div>
                                                                    </div>
                                                                )}
                                                            </Upload>
                                                            <Modal open={previewVisible} onCancel={handleCancel}>
                                                                <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                            </Modal>
                                                        </Form.Item>
                                                    </b>

                                                </div>
                                                <div>
                                                    <Row justify={'left'}><b><h3><u>ระวาง</u></h3></b></Row>
                                                    <b>
                                                        {dataAddImage?.map((imageData, index) => {
                                                            if (imageData.type === "11") {
                                                                return (
                                                                    <Row justify={'center'}>
                                                                        <Image width={'200px'} key={index} src={`${getImagess}/${imageData.image}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                    </Row>
                                                                );
                                                            }
                                                            return null;
                                                        })}
                                                        <Form.Item
                                                            style={{ textAlign: 'center', width: '300px' }}
                                                        >
                                                            <Upload
                                                                accept="image/*"
                                                                multiple={true}
                                                                style={{ textAlign: 'center' }}
                                                                listType="picture-card"
                                                                fileList={fileListCustomerBurden}
                                                                onPreview={handlePreview}
                                                                onChange={handleChangeCustomerBurden}
                                                                //beforeUpload={() => false}
                                                                beforeUpload={(file) => beforeUpts(file, 11).then(() => false).catch(() => true)}
                                                            >
                                                                {fileListCustomerBurden?.length >= 20 ? null : (
                                                                    <div>
                                                                        <PlusOutlined />
                                                                        <div className="ant-upload-text">upload</div>
                                                                    </div>
                                                                )}
                                                            </Upload>
                                                            <Modal open={previewVisible} onCancel={handleCancel}>
                                                                <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                            </Modal>
                                                        </Form.Item>
                                                    </b>
                                                </div>
                                            </aside>
                                        </Row>
                                        <Divider style={{ margin: 5 }} />
                                        <Row justify={'left'}><b><h3><u>ใบประเมิน/รูปที่ดิน/สิ่งปลูกสร้าง-ที่ดินปัจจุบัน(อัพโหลดไฟล์ไม่เกิน 3MB)</u></h3></b></Row>
                                        <Row justify={'center'}>
                                            <aside gutter={24} justify={'center'}>
                                                <div className='gutter-row' span={12}>
                                                    <b>
                                                        {dataAddImage?.map((imageData, index) => {
                                                            if (imageData.type === "1") {
                                                                return (
                                                                    <Row justify={'center'}>
                                                                        <Image width={'200px'} key={index} src={`${getImagess}/${imageData.image}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                    </Row>
                                                                );
                                                            }
                                                            return null;
                                                        })}
                                                        <Form.Item
                                                            style={{ textAlign: 'center', width: '300px' }}
                                                        >
                                                            <Upload
                                                                accept="image/*"
                                                                multiple={true}
                                                                style={{ textAlign: 'center' }}
                                                                listType="picture-card"
                                                                fileList={fileListCustomerLand}
                                                                onPreview={handlePreview}
                                                                onChange={handleChangeCustomerLand}
                                                                //beforeUpload={() => false}
                                                                beforeUpload={(file) => beforeUpts(file, 1).then(() => false).catch(() => true)}
                                                            >
                                                                {fileListCustomerLand?.length >= 20 ? null : (
                                                                    <div>
                                                                        <PlusOutlined />
                                                                        <div className="ant-upload-text">upload</div>
                                                                    </div>
                                                                )}
                                                            </Upload>
                                                            <Modal open={previewVisible} onCancel={handleCancel}>
                                                                <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                            </Modal>
                                                        </Form.Item>
                                                    </b>
                                                </div>
                                            </aside>
                                        </Row>
                                        <Divider style={{ margin: 5 }} />
                                        <Row justify={'left'}><b><h3><u>ประวัติคนกู้ / ทะเบียนบ้าน / อาชีพ (อัพโหลดไฟล์ไม่เกิน 3MB)</u></h3></b></Row>
                                        <Row justify={'center'}>
                                            <aside gutter={24} justify={'center'}>
                                                <div className='gutter-row' span={12}>
                                                    <b>
                                                        {dataAddImage?.map((imageData, index) => {
                                                            if (imageData.type === "6") {
                                                                return (
                                                                    <Row justify={'center'}>
                                                                        <Image width={'200px'} key={index} src={`${getImagess}/${imageData.image}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                    </Row>
                                                                );
                                                            }
                                                            return null;
                                                        })}
                                                        <Form.Item
                                                            style={{ textAlign: 'center', width: '300px' }}
                                                        >
                                                            <Upload
                                                                accept="image/*"
                                                                multiple={true}
                                                                style={{ textAlign: 'center' }}
                                                                listType="picture-card"
                                                                fileList={fileListCustomerCareer}
                                                                onPreview={handlePreview}
                                                                onChange={handleChangeCustomerCareer}
                                                                //beforeUpload={() => false}
                                                                beforeUpload={(file) => beforeUpts(file, 6).then(() => false).catch(() => true)}
                                                            // beforeUpload={(file) => {
                                                            //     setFileListCustomerCareer([...fileListCustomerCareer, file])
                                                            //     return false;
                                                            // }}
                                                            >
                                                                {fileListCustomerCareer?.length >= 15 ? null : (
                                                                    <div>
                                                                        <PlusOutlined />
                                                                        <div className="ant-upload-text">upload</div>
                                                                    </div>
                                                                )}
                                                            </Upload>
                                                            <Modal open={previewVisible} onCancel={handleCancel}>
                                                                <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                            </Modal>
                                                        </Form.Item>
                                                    </b>
                                                </div>

                                            </aside>
                                        </Row>
                                        <Divider style={{ margin: 5 }} />
                                        <Row justify={'left'}><b><h3><u>รูปสเตทเมนต์ผู้กู้ (อัพโหลดไฟล์ไม่เกิน 3MB)</u></h3></b></Row>
                                        <Row justify={'center'}>
                                            <Col span={24}>
                                                <aside gutter={24} justify={'center'}>
                                                    <div className='gutter-row' span={12}>
                                                        <Row justify={'center'}><b><h3><u>ไฟล์ภาพ</u></h3></b></Row>
                                                        <b>
                                                            {dataAddImage?.map((imageData, index) => {
                                                                if (imageData.type === "8") {
                                                                    return (
                                                                        <Row justify={'center'}>
                                                                            <Image width={'200px'} key={index} src={`${getImagess}/${imageData.image}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                        </Row>
                                                                    );
                                                                }
                                                                return null;
                                                            })}
                                                            <Form.Item
                                                                style={{ textAlign: 'center', width: '300px' }}
                                                            >
                                                                <Upload
                                                                    accept="image/*"
                                                                    multiple={true}
                                                                    style={{ textAlign: 'center' }}
                                                                    listType="picture-card"
                                                                    fileList={fileListCustomerStatementImage}
                                                                    onPreview={handlePreview}
                                                                    onChange={handleChangeCustomerStatementImage}
                                                                    //beforeUpload={() => false}
                                                                    beforeUpload={(file) => beforeUpts(file, 8).then(() => false).catch(() => true)}
                                                                // beforeUpload={(file) => {
                                                                //     setFileListCustomerStatementImage([...fileListCustomerStatementImage, file])
                                                                //     return false;
                                                                // }}
                                                                >
                                                                    {fileListCustomerStatementImage?.length >= 20 ? null : (
                                                                        <div>
                                                                            <PlusOutlined />
                                                                            <div className="ant-upload-text">upload</div>
                                                                        </div>
                                                                    )}
                                                                </Upload>
                                                                <Modal open={previewVisible} onCancel={handleCancel}>
                                                                    <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                                </Modal>
                                                            </Form.Item>
                                                        </b>

                                                    </div>
                                                    <div className='gutter-row' span={12}>
                                                        <Row justify={'center'}><b><h3><u>ไฟล์ PDF</u></h3></b></Row>
                                                        <b>
                                                            {dataAddImage?.map((imageData, index) => {
                                                                if (imageData.type === "7") {
                                                                    return (
                                                                        <Row justify={'center'}>
                                                                            <object data={`${getImagess}/${imageData.image}?token=${token}`} type="application/pdf" width="350px" height="450px">
                                                                                <p>Alternative text - include a link <a href={`${getImagess}/${imageData.image}?token=${token}`}>to the PDF!</a></p>
                                                                            </object>
                                                                            <p></p>
                                                                        </Row>
                                                                    );
                                                                }
                                                                return null;
                                                            })}
                                                            <Form.Item
                                                                style={{ textAlign: 'center', width: '300px' }}
                                                            >
                                                                <Upload
                                                                    accept="application/pdf"
                                                                    multiple={true}
                                                                    style={{ textAlign: 'center' }}
                                                                    listType="picture-card"
                                                                    fileList={fileListCustomerStatementPDF}
                                                                    onPreview={handlePreview}
                                                                    onChange={handleChangeCustomerStatementPDF}
                                                                    //beforeUpload={() => false}
                                                                    beforeUpload={(file) => beforeUpts(file, 7).then(() => false).catch(() => true)}
                                                                >
                                                                    {fileListCustomerStatementPDF?.length >= 10 ? null : (
                                                                        <div>
                                                                            <PlusOutlined />
                                                                            <div className="ant-upload-text">upload</div>
                                                                        </div>
                                                                    )}
                                                                </Upload>
                                                                <Modal open={previewVisible} onCancel={handleCancel}>
                                                                    <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                                </Modal>
                                                            </Form.Item>
                                                        </b>
                                                    </div>
                                                </aside>
                                            </Col>
                                        </Row>
                                        <Divider style={{ margin: 5 }} />
                                    </>
                                    : null
                                }
                                {selectProduct?.productTypeId === 3 ?
                                    <>
                                        <Row className='main' justify={'center'}>
                                            <aside style={{ width: '90%' }}>
                                                <div>
                                                    <Row justify={'left'}><b><h3><u>รูปโฉนด/หน้า-หลัง</u></h3></b></Row>
                                                    <b> <Form.Item
                                                        style={{ textAlign: 'center' }}
                                                    >
                                                        <Upload
                                                            accept="image/*"
                                                            multiple={true}
                                                            style={{ textAlign: 'center' }}
                                                            listType="picture-card"
                                                            fileList={fileListCustomerDeed}
                                                            onPreview={handlePreview}
                                                            onChange={handleChangeCustomerDeed}
                                                            beforeUpload={() => false}
                                                        // beforeUpload={(file) => {
                                                        //     setFileListCustomerDeed([...fileListCustomerDeed, file])
                                                        //     return false;
                                                        // }}
                                                        >
                                                            {fileListCustomerDeed?.length >= 20 ? null : (
                                                                <div>
                                                                    <PlusOutlined />
                                                                    <div className="ant-upload-text">upload</div>
                                                                </div>
                                                            )}
                                                        </Upload>
                                                        <Modal open={previewVisible} onCancel={handleCancel}>
                                                            <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                        </Modal>
                                                    </Form.Item>
                                                    </b>

                                                </div>
                                                <div>
                                                    <Row justify={'left'}><b><h3><u>ใบแบ่งใบต่อ</u></h3></b></Row>

                                                    <b> <Form.Item
                                                        style={{ textAlign: 'center' }}
                                                    >
                                                        <Upload
                                                            accept="image/*"
                                                            multiple={true}
                                                            style={{ textAlign: 'center' }}
                                                            listType="picture-card"
                                                            fileList={fileListCustomerLeafDivider}
                                                            onPreview={handlePreview}
                                                            onChange={handleChangeCustomerLeafDivider}
                                                            beforeUpload={() => false}
                                                        >
                                                            {fileListCustomerLeafDivider?.length >= 20 ? null : (
                                                                <div>
                                                                    <PlusOutlined />
                                                                    <div className="ant-upload-text">upload</div>
                                                                </div>
                                                            )}
                                                        </Upload>
                                                        <Modal open={previewVisible} onCancel={handleCancel}>
                                                            <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                        </Modal>
                                                    </Form.Item>
                                                    </b>

                                                </div>
                                                <div>
                                                    <Row justify={'left'}><b><h3><u>ระวาง</u></h3></b></Row>

                                                    <b> <Form.Item
                                                        style={{ textAlign: 'center' }}
                                                    >
                                                        <Upload
                                                            accept="image/*"
                                                            multiple={true}
                                                            style={{ textAlign: 'center' }}
                                                            listType="picture-card"
                                                            fileList={fileListCustomerBurden}
                                                            onPreview={handlePreview}
                                                            onChange={handleChangeCustomerBurden}
                                                            beforeUpload={() => false}
                                                        >
                                                            {fileListCustomerBurden?.length >= 20 ? null : (
                                                                <div>
                                                                    <PlusOutlined />
                                                                    <div className="ant-upload-text">upload</div>
                                                                </div>
                                                            )}
                                                        </Upload>
                                                        <Modal open={previewVisible} onCancel={handleCancel}>
                                                            <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                        </Modal>
                                                    </Form.Item>
                                                    </b>
                                                </div>
                                            </aside>
                                        </Row>
                                        <Divider style={{ margin: 5 }} />
                                        <Row justify={'left'}><b><h3><u>รูปที่ดิน/สิ่งปลูกสร้าง-ที่ดินปัจจุบัน (อัพโหลดไฟล์ไม่เกิน 3MB)</u></h3></b></Row>
                                        <Row justify={'center'}>
                                            <aside gutter={24} justify={'center'}>
                                                <div className='gutter-row' span={12}>
                                                    <b> <Form.Item
                                                        style={{ textAlign: 'center', width: '300px' }}
                                                    >
                                                        <Upload
                                                            accept="image/*"
                                                            multiple={true}
                                                            style={{ textAlign: 'center' }}
                                                            listType="picture-card"
                                                            fileList={fileListCustomerLand}
                                                            onPreview={handlePreview}
                                                            onChange={handleChangeCustomerLand}
                                                            beforeUpload={() => false}
                                                        // beforeUpload={(file) => {
                                                        //     setFileListCustomerLand([...fileListCustomerLand, file])
                                                        //     return false;
                                                        // }}
                                                        >
                                                            {fileListCustomerLand?.length >= 20 ? null : (
                                                                <div>
                                                                    <PlusOutlined />
                                                                    <div className="ant-upload-text">upload</div>
                                                                </div>
                                                            )}
                                                        </Upload>
                                                        <Modal open={previewVisible} onCancel={handleCancel}>
                                                            <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                        </Modal>
                                                    </Form.Item>
                                                    </b>
                                                </div>

                                            </aside>
                                        </Row>
                                        <Divider style={{ margin: 5 }} />
                                    </>
                                    : null
                                }
                            </Col>
                            {/* </Card> */}
                            <Row justify={'center'} >
                                <Space>
                                    <Button
                                        type="text"
                                        style={{ background: "#e0e0e0" }}
                                    >
                                        หน้าหลัก
                                    </Button>
                                    {selectProduct?.productTypeId === 1 ?
                                        <>
                                            <Button
                                                type='primary'
                                                htmlType='submit'
                                            >
                                                ต่อไป
                                            </Button>
                                        </>
                                        : null
                                    }
                                    {/* {selectProduct?.productTypeId === 2 && fileListCustomerBurden?.length > 0 ? */}
                                    {selectProduct?.productTypeId === 2 && fileListCustomerBurden?.length > 0 && fileListCustomerDeed?.length > 0 ?
                                        <>
                                            <Button
                                                type='primary'
                                                htmlType='submit'
                                            >
                                                ต่อไป
                                            </Button>
                                        </>
                                        : null
                                    }
                                    {/* {selectProduct?.productTypeId === 3 && fileListCustomerBurden?.length > 0 ? */}
                                    {selectProduct?.productTypeId === 3 && fileListCustomerBurden?.length > 0 && fileListCustomerDeed?.length > 0 ?
                                        <>
                                            <Button
                                                type='primary'
                                                htmlType='submit'
                                            >
                                                ต่อไป
                                            </Button>
                                        </>
                                        : null
                                    }

                                </Space>
                            </Row>
                        </Form>
                    </Row>
                </Spin>
            </Card>
        </Row >
    )
}

export default UploadImages
