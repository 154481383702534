import React, { useState, useEffect } from 'react'
import { Row, Col, Space, Card, Divider, Image, Form, Tag, Button } from 'antd'
// import axios from 'axios'
import dayjs from 'dayjs';
import { getImagess } from '../../file_mid/all_api';

export default function View_imagePn() {
    const view = localStorage.getItem('viewPN')
    const token = localStorage.getItem('token');
    const getData = JSON.parse(view)
    // const [loading, setLoading] = useState(false)
    const [imageBlobzz, setImageBlobzz] = useState([])
    // const [showNoteAD, setShowNoteAD] = useState([])

    useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {
        // setLoading(true)
        setImageBlobzz(getData?.img)
        // setLoading(false)
        // setShowNoteAD(getData?.notes)
    }

    console.log("getData", getData)
    // console.log("view", view)

    const currencyFormatOne = (amount) => {
        return Number(amount)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }

    const Onclose = () => {
        localStorage.removeItem('viewPN')
        window.close()
    }

    window.addEventListener('beforeunload', function () {
        localStorage.removeItem('viewPN');
      });

    const renderNoteAD = ({ item, index }) => {
        return (
            <>
                <Row gutter={32} justify={'center'}>
                    <Col className='gutter-row' span={24}>
                        <>
                            <Card
                                key={index}
                                style={{ width: '270px', textAlign: 'left' }}>
                                <>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>สาเหตุ : </span>
                                        <b>{item?.cause}</b>
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>ผู้สร้างหมายเหตุ : </span>
                                        <b>{item?.noteBy}</b>
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>สถานะที่ทำการหมายเหตุ : </span>
                                        <b>{item?.status}</b>
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>เมื่อวันที่ : </span>
                                        <b>{dayjs(item?.noteDate).format("DD-MM-YYYY")}</b>
                                    </div>
                                </>
                            </Card>
                        </>
                    </Col>
                </Row>
            </>
        )
    }

    return (
        <>
            <Card
                bordered={false}
                style={{
                    width: 'auto',
                }}
            >
                <Row style={{ textAlign: 'center' }} >
                    <Col span={24} style={{ fontSize: '30px' }} ><b><u>ข้อมูลที่เคยเสนอมาแล้ว</u></b></Col>
                </Row>
                <Row justify={'center'}>
                    {/* <Divider style={{ margin: 5 }} /> */}
                    <Form
                        labelCol={{
                            span: 12,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                        style={{
                            width: '90%',
                        }}
                        // form={form}
                        // onFinish={handleSubmit}
                        autoComplete="off"
                        initialValues={{ remember: true }}
                    >
                        <div className='main'>
                            {/* <Card style={{ width: '100%' }}> */}
                            <Row justify={'center'}>
                                <Col span={24} style={{ textAlign: 'right' }}>
                                    <div style={{ margin: 0 }}>
                                        ชื่อการตลาดที่เสนอ : <Tag color="cyan">{getData?.landLoanDetails?.proposalBy}</Tag>
                                    </div>
                                    <div style={{ margin: 0 }}>
                                    </div>
                                </Col>
                                <Col span={24} style={{ textAlign: 'right' }}>
                                    <div style={{ margin: 0 }}>
                                        สถานะ : <Tag color="cyan">{getData?.landLoanDetails?.approvalStatus}</Tag>
                                    </div>
                                    <div style={{ margin: 0 }}>
                                    </div>
                                </Col>
                            </Row>
                            <Divider />
                            <>
                                <Row justify={'left'}><b><u> ข้อมูลที่เคยเสนอมาแล้ว</u></b></Row>
                                <Row gutter={32} justify={'center'}>
                                    <aside style={{ width: '90%' }}>

                                        <div
                                            style={{ marginBottom: 0 }}
                                        >
                                            <span>กลุ่มสินค้า : </span>{<b>{getData?.landLoanDetails?.productType}</b>}
                                        </div>
                                        <div
                                            style={{ marginBottom: 0 }}
                                        >
                                            <span>ประเภทเอกสาร : </span>{<b>{getData?.productTypeLand === 1 ? "นส.3" : "นส.4"}</b>}
                                        </div>
                                        <div
                                            style={{ marginBottom: 0 }}
                                        >
                                            <span>เลขโฉนดที่ดิน : </span>{<b>{getData?.numberLand}</b>}
                                        </div>
                                        <div
                                            style={{ marginBottom: 0 }}
                                        >
                                            <span>เลขที่ดินหรือระวาง : </span>{<b>{getData?.numberLandlawang}</b>}
                                        </div>
                                        <div
                                            style={{ marginBottom: 0 }}
                                        >
                                            <span>จังหวัด : </span>{<b>{getData?.provinces}</b>}
                                        </div>
                                        <div
                                            style={{ marginBottom: 0 }}
                                        >
                                            <span>อำเภอ : </span>{<b>{getData?.district}</b>}
                                        </div>
                                        <div
                                            style={{ marginBottom: 0 }}
                                        >
                                            <span>พื้นที่ไร่ : </span>{<b>{getData?.rai}</b>}
                                            <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                                ไร่
                                            </span>
                                        </div>
                                        <div
                                            style={{ marginBottom: 0 }}
                                        >
                                            <span>พื้นที่งาน : </span>{<b>{getData?.workArea}</b>}
                                            <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                                งาน
                                            </span>
                                        </div>
                                        <div
                                            style={{ marginBottom: 0 }}
                                        >
                                            <span>พื้นที่ตารางวา : </span>{<b>{currencyFormatOne(getData?.squareWaArea)}</b>}
                                            <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                                บาท
                                            </span>
                                        </div>
                                        <div
                                            style={{ marginBottom: 0 }}
                                        >
                                            <span>ราคาประเมินที่ดิน : </span> {<b>{getData?.landPrice}</b>}
                                            <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                                บาท / ตารางวา
                                            </span>
                                        </div>
                                        <div
                                            style={{ marginBottom: 0 }}
                                        >
                                            <span>ราคารวม : </span> {<b>{getData?.resultLandPrice}</b>}
                                            <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                                บาท
                                            </span>
                                        </div>
                                        <div
                                            style={{ marginBottom: 0 }}
                                        >
                                            <span>เสนอมาเมื่อวันที่ : </span>{<b>{dayjs(getData?.landInput).format("DD-MM-YYYY")}</b>}
                                        </div>
                                    </aside>
                                </Row>
                                <Divider />
                                <Row justify={'left'}><b><u> การเคาะราคา</u></b></Row>
                                <Row gutter={32} justify={'center'}>
                                    {
                                        getData?.approval?.id !== 0 ?
                                            <>
                                                <aside style={{ width: '90%' }}>
                                                    <div style={{ marginBottom: 0 }}>
                                                        <span>หมายเหตุ : </span>{<b>{getData?.approval?.note}</b>}
                                                    </div>
                                                    <div style={{ marginBottom: 0 }} >
                                                        <span>เมื่อวันที่ : </span>{<b>{dayjs(getData?.approval?.inputPriceDate).format('DD-MM-YYYY')}</b>}
                                                    </div>
                                                    <div style={{ marginBottom: 0 }}>
                                                        {/* <span>ประเภทเอกสาร : </span>{<b>{dataLand?.productTypeLand}</b>} */}
                                                        <span>ราคาต่ำสุด-สูงสุด : </span>{<b>{getData?.approval?.minPrice} - {getData?.approval?.maxPrice}</b>}
                                                    </div>
                                                    <div style={{ marginBottom: 0 }}>
                                                        <span>เคาะราคาโดย : </span>{<b>{getData?.approval?.proposalPriceBy}</b>}
                                                    </div>
                                                </aside>
                                            </>
                                            :
                                            <>
                                                <div style={{ marginBottom: 0 }}>
                                                    <span style={{ color: "red" }}>*ยังไม่เคาะราคา!</span>
                                                </div>
                                            </>
                                    }
                                </Row>
                            </>
                            {/* {showNoteAD?.length > 0 ?
                                <>
                                    <Divider />
                                    <Row justify={'left'}>
                                        <b><u>หมายเหตุ</u></b>
                                    </Row>
                                    <Row gutter={32} justify={'center'}>
                                        {
                                            showNoteAD?.map((item, index) => {
                                                return renderNoteAD({ item, index })
                                            })
                                        }

                                    </Row>
                                </>
                                : null
                            } */}
                        </div>
                        <Divider />
                        <Col span={24}>
                            <div
                                style={{ marginBottom: 0 }}
                            >
                                <>
                                    <Row justify={'left'}><b><u>รูปโฉนด/หน้า-หลัง</u></b></Row>
                                    <Space>
                                        <Row gutter={32} justify={'center'}>
                                            {imageBlobzz?.map((e, index) => {
                                                if (e.type === 3) {
                                                    return (
                                                        <>
                                                            <Image width={"70%"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />

                                                        </>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </Row>
                                    </Space>
                                    <Divider />
                                    <Row justify={'left'}><b><u>ใบแบ่งใบต่อ</u></b></Row>
                                    <Space>
                                        <Row gutter={32} justify={'center'}>
                                            {imageBlobzz?.map((e, index) => {
                                                if (e.type === 10) {
                                                    return (
                                                        <>
                                                            <Image width={"70%"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />

                                                        </>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </Row>
                                    </Space>
                                    <Divider />
                                    <Row justify={'left'}><b><u>ระวาง</u></b></Row>
                                    <Space>
                                        <Row gutter={32} justify={'center'}>
                                            {imageBlobzz?.map((e, index) => {
                                                if (e.type === 11) {
                                                    return (
                                                        <>
                                                            <Image width={"70%"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />

                                                        </>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </Row>
                                    </Space>
                                    <Divider />
                                    <Row justify={'left'}><b><u>รูปที่ดิน/สิ่งปลูกสร้าง-ที่ดินปัจจุบัน</u></b></Row>
                                    <Space>
                                        <Row gutter={32} justify={'center'}>
                                            {imageBlobzz?.map((e, index) => {
                                                if (e.type === 1) {
                                                    return (
                                                        <>
                                                            <Image width={"70%"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />

                                                        </>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </Row>
                                    </Space>
                                    <Divider />
                                </>
                            </div>
                        </Col>

                    </Form>
                </Row>
            </Card >
        </>
    )
}
