import React, { useState, useEffect } from "react";
import { Card, Col, Divider, Radio, Input, Row, Space, Spin, Table, notification, Select } from 'antd'
import axios from 'axios';
// import dayjs from "dayjs";
import { FindDataNotiHDPAYMENT } from "../file_mid/all_api";

export default function Main() {
    const token = localStorage.getItem('token');
    const idSep = localStorage.getItem('idSep');
    const branch = localStorage.getItem('branch');
    const [loading, setLoading] = useState(false);
    const [axiosData, setAxiosData] = useState([]);
    const [arrayTable, setArrayTable] = useState([]);
    const [dataAll, setDataAll] = useState();
    const [api, contextHolder] = notification.useNotification();
    const [dataPost, setDataPost] = useState({ keyCont: "ทั้งหมด" })

    useEffect(() => {
        loadData()
    }, [])
    const errorConnt = (placement) => {
        api.error({
            message: "ไม่พบข้อมูล",
            // description:
            //     'กรุณาเพิ่มข้อมูลให้ถูกต้อง',
            // placement,
        });
    };

    const loadData = async () => {
        let mainData
        const tk = JSON.parse(token)
        const sep = JSON.parse(idSep)
        if (tk !== "") {
            setLoading(true)
            mainData = { sep: sep, branch: branch, flagNoti: false }
            await axios.post(FindDataNotiHDPAYMENT, mainData)
                .then(async (res) => {
                    console.log("loadData", res.data)
                    if (res.data) {
                        setDataAll(res.data)
                        if (res.data.modalHDPAYMENT) {
                            setArrayTable(res.data.modalHDPAYMENT)
                            setAxiosData(res.data.modalHDPAYMENT)
                        }
                    }
                    setLoading(false)
                }).catch((err) => {
                    console.log(err)
                    setLoading(false)
                    errorConnt("top")
                })
        } else {
            errorConnt("top")
        }
    }

    const search = (data) => {
        console.log("dataPost.keyCont", dataPost.keyCont)
        if (dataPost?.keyCont === "ทั้งหมด") {
            console.log("all")
            const greaterThanTen = axiosData.filter(
                (item) =>
                    item.CONTNO.toLowerCase().includes(data.target.value)
            );
            setArrayTable(greaterThanTen)
        } else if (dataPost?.keyCont === "vsfhp") {
            console.log("2")
            const greaterThanTen = axiosData.filter(
                (item) =>
                    item.CONTNO.toLowerCase().includes(data.target.value)
                    && item.DATATYPE === "vsfhp"
            );
            setArrayTable(greaterThanTen)
        } else if (dataPost?.keyCont === "lsfhp") {
            console.log("1")
            const greaterThanTen = axiosData.filter(
                (item) =>
                    item.CONTNO.toLowerCase().includes(data.target.value)
                    && item.DATATYPE === "lsfhp"
            );
            setArrayTable(greaterThanTen)
        } else if (dataPost?.keyCont === "sfhp") {
            console.log("8")
            const greaterThanTen = axiosData.filter(
                (item) =>
                    item.CONTNO.toLowerCase().includes(data.target.value)
                    && item.DATATYPE === "sfhp"
            );
            // console.log("greaterThanTen", greaterThanTen)
            setArrayTable(greaterThanTen)
        } else if (dataPost?.keyCont === "psfhp") {
            console.log("3 o")
            const greaterThanTen = axiosData.filter(
                (item) =>
                    item.CONTNO.toLowerCase().includes(data.target.value)
                    && item.DATATYPE === "psfhp"
            );
            setArrayTable(greaterThanTen)
        } else if (dataPost?.keyCont === "rpsl") {
            console.log("3 n")
            const greaterThanTen = axiosData.filter(
                (item) =>
                    item.CONTNO.toLowerCase().includes(data.target.value)
                    && item.DATATYPE === "rpsl"
            );
            setArrayTable(greaterThanTen)
        } else {
            console.log("ไม่เข้า")
        }

    };
    const onChangeKeyWord = (value) => {
        console.log("value", value)
        // setQuery("")
        if (value === "ทั้งหมด") {
            loadData()
        } else {
            const greaterThanTen = axiosData.filter(
                (item) =>
                    item.DATATYPE === value
            );
            setArrayTable(greaterThanTen)
        }
        setDataPost({ ...dataPost, keyCont: value })
    
    }

    const currencyFormatOne = (amount) => {
        if (amount) {
            return Number(amount)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, '$&,')
        } else {
            return "-"
        }
    }

    const columns = [
        {
            title: "ลำดับ",
            dataIndex: "index",
            key: 'index',
            align: 'center',
            //   fixed: true,
            width: "10%",
            render: (text, object, index) => index + 1
        },
        {
            title: "สาขา",
            dataIndex: "LOCAT",
            key: 'LOCAT',
            align: 'center',
            width: "30%",
            render: (text, record) => (
                <>{record.LOCAT}</>
            ),
        },
        // {
        //     title: "CH",
        //     dataIndex: "DATATYPE",
        //     key: 'DATATYPE',
        //     align: 'center',
        //     width: "30%",
        //     render: (text, record) => (
        //         <>{record.DATATYPE}</>
        //     ),
        // },
        {
            title: "เลขที่สัญญา",
            dataIndex: "CONTNO",
            key: 'CONTNO',
            align: 'center',
            width: "30%",
            render: (text, record) => (
                <>{record.CONTNO}</>
            ),
        },
        {
            title: "ยอดเงินในระบบ",
            dataIndex: "TOTAMT",
            key: 'TOTAMT',
            align: 'center',
            width: "30%",
            render: (text, record) => (
                <>{currencyFormatOne(record.TOTAMT)}</>
            ),
        },
        {
            title: "ชำระขั้นต่ำ",
            dataIndex: "PAYLIMITAMT",
            key: 'PAYLIMITAMT',
            align: 'center',
            width: "30%",
            render: (text, record) => (
                <>{currencyFormatOne(record.PAYLIMITAMT)}</>
            ),
        },
    ];
    return (
        <div>
            <Card bordered={false} style={{ width: '100%' }}>
                <Row justify={'center'}>
                    <Col>
                        <Spin spinning={loading} size='large' tip=" Loading... ">
                            <div className='text-center'>
                                <h2>ตรวจสอบข้อมูลบัญชี</h2>
                            </div>
                            <Divider></Divider>
                            <Row gutter={32} justify={'center'} style={{ margin: 10 }}>
                                {branch === "MIT" || branch === "UD" || branch === "LEX" || branch === "S4" ?
                                    <>
                                        <Space>
                                            <>{"เลือกบัญชี : "}</>
                                            <Select
                                                // defaultValue={"รอธุรการรับ"}
                                                value={dataPost.keyCont}
                                                style={{ width: 200, height: '40px' }}
                                                onChange={(value) => onChangeKeyWord(value)}
                                                options={[
                                                    {
                                                        label: 'ทั้งหมด', value: 'ทั้งหมด',
                                                    },
                                                    {
                                                        label: 'บัญชี 1', value: 'lsfhp',
                                                    },
                                                    {
                                                        label: 'บัญชี 2', value: 'vsfhp',
                                                    },
                                                    {
                                                        label: 'บัญชี 8', value: 'sfhp',
                                                    },
                                                    {
                                                        label: 'บัญชี 3 (เก่า)', value: 'psfhp',
                                                    },
                                                    {
                                                        label: 'บัญชี 3 (ใหม่)', value: 'rpsl',
                                                    },
                                                ]}
                                            />
                                        </Space>
                                        {/* </Row> */}
                                        <Divider></Divider>
                                    </>
                                    : null}
                                {/* <Row gutter={32} justify={'center'} style={{ margin: 10 }}> */}
                                {/* <Col> */}
                                {/* <Space> */}
                                {branch === "MIT" || branch === "UD" || branch === "LEX" || branch === "S4" ?
                                    <>
                                        <Radio.Button
                                            disabled
                                            style={{
                                                width: '180px',
                                                border: '2px solid',
                                                backgroundColor: '#e6f4ff',
                                                color: '#1677ff'
                                            }}>{"บัญชี 1 : "} {dataAll?.count1}</Radio.Button>
                                        <Radio.Button
                                            disabled
                                            style={{
                                                width: '180px',
                                                border: '2px solid',
                                                backgroundColor: '#e6f4ff',
                                                color: '#1677ff'
                                            }}>{"บัญชี 2 : "} {dataAll?.count2}</Radio.Button>
                                        <Radio.Button
                                            disabled
                                            style={{
                                                width: '180px',
                                                border: '2px solid',
                                                backgroundColor: '#e6f4ff',
                                                color: '#1677ff'
                                            }}>{"บัญชี 8 : "} {dataAll?.count8}</Radio.Button>
                                        <Radio.Button
                                            disabled
                                            style={{
                                                width: '180px',
                                                border: '2px solid',
                                                backgroundColor: '#e6f4ff',
                                                color: '#1677ff'
                                            }}>{"บัญชี 3 (เก่า) : "} {dataAll?.count3old}</Radio.Button>
                                    </>
                                    : null}
                                <Radio.Button
                                    disabled
                                    style={{
                                        width: '180px',
                                        border: '2px solid',
                                        backgroundColor: '#e6f4ff',
                                        color: '#1677ff'
                                    }}>{"บัญชี 3 (ใหม่) : "} {dataAll?.count3new}</Radio.Button>
                                {/* </Space> */}
                                {/* </Col> */}
                            </Row>
                            <Divider></Divider>
                            <Row gutter={32} style={{ margin: 10 }}>
                                <Col className='gutter-row' span={24} style={{ textAlign: 'right' }}>
                                    <Input.Search
                                        style={{ width: '250px' }}
                                        placeholder="ค้นหาสัญญา...."
                                        onChange={search}
                                        name="search"
                                        id="search"
                                        size="large"
                                        loading={loading}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={32}>
                                <Col span={24}>
                                    <Table
                                        // pagination={false}
                                        rowKey={(record) => record.uid}
                                        scroll={{
                                            x: 1500,
                                            y: 400,
                                        }}
                                        dataSource={arrayTable}
                                        columns={columns}
                                    >
                                    </Table>
                                    <Divider />
                                </Col>
                            </Row>
                            <Col span={24} style={{ textAlign: 'center' }}>
                                <Space>
                                    {/* <Button href="/offercase" type="primary" ><PlusCircleFilled />เพิ่ม</Button> */}
                                    {/* <Button href="/" type="primary"><HomeFilled />หน้าหลัก</Button> */}
                                </Space>
                            </Col>
                        </Spin>
                    </Col>
                </Row>
            </Card >
            {contextHolder}
        </div >
    )
}
