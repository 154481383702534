import React, { useState } from "react";
import { Modal, Row, Col, Divider, Space, Button, Card, Image } from 'antd';
import axios from "axios";
import { useEffect } from "react";
import { getImagess, newnote } from "../../file_mid/all_api";


const ModalNote = ({ open, close, dataFromTable  }) => {
    const [dataNote, setDataNote] = useState([]);
    const token = localStorage.getItem('token');

    // console.log('dataNote', dataNote)
    console.log('dataFromTable', dataFromTable)


    useEffect(() => {

            loadData()
        
    }, [])

    const loadData = async () => {
        // await axios.post(`https://pg-api-i67f6gaaqa-as.a.run.app/api/load-data-noteforpg`, carid)
            await axios.post(`${newnote}`, { carLandId: dataFromTable.carLandId, typeLoan: dataFromTable.typeLoan })
            .then((res) => {
                setDataNote(res.data)
                // console.log('res.data', res.data)
            })
            .catch((err) => {
                console.log("err", err)
            })
    }

    const handleCancel = () => {
        close(false);
    }

    const renderDataNote = ({ item }) => {
        return (
            <>
                <Row gutter={32} justify={'center'}>
                    <Col className='gutter-row' span={24}>
                        <Card style={{ width: '270px', textAlign: 'left' }}>
                            <div style={{ marginBottom: 0 }}>
                                <span>สาเหตุ : </span>
                                <b>{item.cause}</b>
                            </div>
                            <div style={{ marginBottom: 0 }}>
                                <span>หมายเหตุ : </span>
                                <b>{item.note}</b>
                            </div>
                            <div style={{ marginBottom: 0 }}>
                                <span>ผู้สร้างหมายเหตุ : </span>
                                <b>{item.noteBy}</b>
                            </div>
                            <div style={{ marginBottom: 0 }}>
                                <span>สถานะที่ทำการหมายเหตุ : </span>
                                <b>{item.status}</b>
                            </div>
                            <div>
                                <Image.PreviewGroup>
                                <span>รูปภาพ : </span>
                                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                                    {item.image.length > 0 ? (
                                        item.image.map((e, index) => (
                                            <Image
                                                key={index}
                                                src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                alt="รูปภาพ"
                                                width={80}
                                                height={80}
                                                style={{ objectFit: 'cover', borderRadius: '4px' }}
                                            />
                                        ))
                                    ) : (
                                        <span>ไม่มีรูปภาพ</span>
                                    )}
                                </div>
                                </Image.PreviewGroup>
                            </div>
                            {/* <div >
                                <span>รูปภาพ : </span>
                                {item?.pgLandImage?.map((e, index) => {
                                    if (e.type === 15) {
                                        return (
                                            <Row gutter={32} justify={'center'}>
                                                <Col span={24} style={{ textAlign: 'center' }}> 
                                                    <Image
                                                        style={{ margin: 3, width: '80px', height: '80px', objectFit: 'cover', borderRadius: '4px' }}
                                                        width={'100px'}
                                                        key={index}
                                                        src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                        alt={`รูปภาพ ${index}`
                                                        } />
                                                </Col>
                                            </Row>
                                        );
                                    }
                                    return null;
                                })}
                            </div> */}
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }

    return (
        <>
            <Modal
                open={open}
                onCancel={handleCancel}
                title={<div style={{ marginBottom: '30px', textAlign: 'center', fontSize: '20px' }}>หมายเหตุ</div>}
                // style={{width:1000, height:100}}
                width={1200}
                height={1000}
                footer={null}

            >
                <Row justify={'left'}><Col span={24}><b><u>หมายเหตุ</u></b></Col></Row>
                <Row gutter={32} justify={'center'}>
                    {
                        dataNote?.length >= 1 ?
                            dataNote?.map((item, index) => {
                                return renderDataNote({ item, index });
                            })
                            : <>* ยังไม่เพิ่มหมายเหตุ!</>
                    }
                </Row>
                <Col span={24}>
                    <Row justify={"center"}>
                        <Space>
                            <Button
                                key="save"
                                type='submit'
                                onClick={handleCancel}
                                style={{
                                    color: 'white',
                                    backgroundColor: 'red',
                                    justifyContent: 'end',
                                    padding: '10px',
                                    margin: '10px'
                                }}
                            >
                                ปิด
                            </Button>
                        </Space>
                    </Row>
                </Col>

            </Modal>
        </>

    )
}

export default ModalNote;