const DataColums = [
  {
    title: "เลขสัญญา",
    dataIndex: "CONTNO",
    key: "CONTNO",
    onCell: () => ({
      "data-label": "เลขสัญญา",
    }),
    sorter: (a, b) => a.CONTNO.localeCompare(b.CONTNO),
  },
  {
    title: "วันที่ส่งเคส",
    dataIndex: "sendPgDate",
    key: "sendPgDate",
    onCell: () => ({
      "data-label": "วันที่ส่งเคส",
    }),
  },
  {
    title: "เลือก",
  },
];
export default DataColums;
