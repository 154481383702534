import React, { useState, useEffect } from 'react'
import { Modal, Form, Button, Input, Card, Space, Select, notification, Row, Col, Checkbox, Divider } from 'antd'
import axios from 'axios';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import TextArea from 'antd/es/input/TextArea';
import { Installment } from '../../offer_case/ploan/Installment';
import Currency from 'currency.js';
import { HirePurchase } from '../../offer_case/hire_purchase/HirePurchase';
import { addcausename, approvepgcon, approvepgnew, rejectpg, showcausename, unapprovepgnew, updateApproves } from '../../file_mid/all_api';

function Edit_Accept({ open, close, dataFromTable, dataCarLand, dataCarLands, setGetNotification, sendback, setCheckApprove, guarantorData, newDataLand }) {

    const guarantor = useSelector(state => state.guarantor)

    const user = localStorage.getItem('username');
    const [numForCheck, setNumForCheck] = useState({});
    const [addNote, setAddNote] = useState({ name: '' });
    const [options, setOptions] = useState([]);

    const [cause, setCause] = useState({});
    const [dis, setDis] = useState(false);
    const [formAdd] = Form.useForm()
    const [checkCause, setCheckCause] = useState(0);
    const [api, contextHolder] = notification.useNotification();
    const [appStatus, setAppStatusappStatus] = useState(dataFromTable?.approvalStatus);

    const [statusAccept, setstatusAccept] = useState({});

    //ส่งค่าไปในตาราง p-loan
    // const [installmentAmountt, setinstallmentAmountt] = useState({})

    const [checkType, setCheckType] = useState()
    const [typeCar, setTypeCar] = useState()
    const [checkDis, setCheckDis] = useState(true)

    const [checkCar23, setCheckCar23] = useState(false)

    const [openTable, setOpenTable] = useState(false); //ตารางค่างวด p-loan
    const [openTableHP, setOpenTableHP] = useState(false); //ตารางค่างวด เช่าซื้อ

    const [money] = useState({}) //loan  
    const branch = localStorage.getItem('branch');
    // const [dataPost2, setDataPost2] = useState({ productTypeCar: 1 })  //car
    const [preData, setpreData] = useState()  //car
    const [checkApprovalConditions] = useState(0)
    const [valueApCon, setValueApCon] = useState(guarantorData);
    const [mixLand, setMixLand] = useState();

    const [form] = Form.useForm()

    useEffect(() => {

        setAppStatusappStatus(dataFromTable?.approvalStatus)
        // if (dataFromTable.car === "car") {

        if (appStatus === "อนุมัติแบบมีเงื่อนไข" || appStatus === "อนุมัติ" || appStatus === "ลูกค้าคอนเฟิร์ม" || appStatus === "รอตรวจสอบเอกสาร" || appStatus === "รอทำสัญญา") {
            if (dataCarLands?.cars?.apCarLoanDetails?.loanId !== 0) {
                setpreData({
                    ...dataCarLands?.cars?.apCarLoanDetails,
                    apProductType: dataCarLands?.cars?.apCarLoanDetails?.apProductType,
                    apProductLoanType: dataCarLands?.cars?.apCarLoanDetails.apProductLoanType,
                    apLoanAmount: dataCarLands?.cars?.apCarLoanDetails?.apLoanAmount,
                    apDownPayment: dataCarLands?.cars?.apCarLoanDetails?.apDownPayment,
                    apInterest: dataCarLands?.cars?.apCarLoanDetails?.apInterest,
                    apInterestRate: dataCarLands?.cars?.apCarLoanDetails?.apInterestRate,
                    apLoanTerm: dataCarLands?.cars?.apCarLoanDetails?.apLoanTerm,
                    apMonthlyPayment: dataCarLands?.cars?.apCarLoanDetails?.apMonthlyPayment,
                    apInstallmentWithInterest: ~~dataCarLands?.cars?.apCarLoanDetails?.apMonthlyPayment * ~~dataCarLands?.cars?.apCarLoanDetails?.apLoanTerm
                })
            } else {
                setpreData({
                    ...dataCarLands?.cars?.carLoanDetails,
                    apProductType: dataCarLands?.cars?.carLoanDetails?.productType,
                    apProductLoanType: dataCarLands?.cars?.carLoanDetails.productLoanType,
                    apLoanAmount: dataCarLands?.cars?.carLoanDetails?.loanAmount,
                    apInterest: dataCarLands?.cars?.carLoanDetails?.interest,
                    apInterestRate: dataCarLands?.cars?.carLoanDetails?.interestRate,
                    apLoanTerm: dataCarLands?.cars?.carLoanDetails?.loanAmount,
                    apMonthlyPayment: dataCarLands?.cars?.carLoanDetails?.monthlyPayment,
                    apInstallmentWithInterest: ~~dataCarLands?.cars?.carLoanDetails?.monthlyPayment * ~~dataCarLands?.cars?.carLoanDetails?.loanAmount
                })
            }

            // setinstallmentAmountt({ installmentAmountt: dataCarLands?.cars?.apCarLoanDetails?.loanTerm })
            setCheckType(dataCarLands?.cars?.apCarLoanDetails?.apProductType)
            setTypeCar(dataCarLands?.cars?.productTypeCar)
            if (dataCarLands?.cars?.apCarLoanDetails?.apProductType === "p-loan") {
                setCheckDis(true)
            } else if (dataCarLands?.cars?.apCarLoanDetails?.apProductType === "เช่าซื้อ") {
                if (dataCarLands?.cars?.productTypeCar === 1) {
                    setCheckDis(true)
                } else {
                    setCheckCar23(true)
                    setCheckDis(false)
                }
            } else if (dataCarLands?.cars?.apCarLoanDetails?.apProductType === "เช่าซื้อ1") {
                setCheckDis(false)
            }

            form.setFieldsValue({
                cause: "แก้ไขยอดอนุมัติ",
                apProductType: dataCarLands?.cars?.apCarLoanDetails?.apProductType,
                apLoanTerm: dataCarLands?.cars?.apCarLoanDetails?.apLoanTerm,
                apLoanAmount: dataCarLands?.cars?.apCarLoanDetails?.apLoanAmount,
                apInterestRate: dataCarLands?.cars?.apCarLoanDetails?.apInterestRate,
                apMonthlyPayment: dataCarLands?.cars?.apCarLoanDetails?.apMonthlyPayment,
                apInterest: dataCarLands?.cars?.apCarLoanDetails?.apInterest,
                apInstallmentWithInterest: ~~dataCarLands?.cars?.apCarLoanDetails.apLoanTerm * ~~dataCarLands?.cars?.apCarLoanDetails?.apMonthlyPayment
            })
        }

        if (dataFromTable.car === "land") {
            if (appStatus === "อนุมัติแบบมีเงื่อนไข" || appStatus === "อนุมัติ" || appStatus === "ลูกค้าคอนเฟิร์ม" || appStatus === "รอตรวจสอบเอกสาร" || appStatus === "รอทำสัญญา") {
                if (dataCarLands.length > 1) {
                    makeMixLand()
                }
                if (dataCarLands?.lands?.apLandLoanDetails?.loanId !== 0) {
                    setpreData({
                        ...dataCarLands?.lands?.apLandLoanDetails,
                        apInterestRate: dataCarLands?.lands?.apLandLoanDetails?.apInterestRate,
                        apLoanAmount: dataCarLands?.lands?.apLandLoanDetails?.apLoanAmount,
                        apLoanTerm: dataCarLands?.lands?.apLandLoanDetails?.apLoanTerm
                    })
                    form.setFieldsValue(
                        {
                            cause: "แก้ไขยอดอนุมัติ",
                            apLoanTerm: dataCarLands?.lands?.apLandLoanDetails?.apLoanTerm,
                            apLoanAmount: dataCarLands?.lands?.apLandLoanDetails?.apLoanAmount,
                            apMonthlyPayment: dataCarLands?.lands?.apLandLoanDetails?.apMonthlyPayment,
                            apInterest: dataCarLands?.lands?.apLandLoanDetails?.apInterest,
                            apInstallmentWithInterest: ~~dataCarLands?.lands?.apLandLoanDetails.apLoanTerm * ~~dataCarLands?.lands?.apLandLoanDetails?.apMonthlyPayment
                        }
                    )

                } else {
                    setpreData({
                        ...dataCarLands?.lands?.landLoanDetails,
                        apProductType: dataCarLands?.lands?.landLoanDetails?.productType,
                        apProductLoanType: dataCarLands?.lands?.landLoanDetails.productLoanLandType,
                        apLoanAmount: dataCarLands?.lands?.landLoanDetails?.approvedLoanAmount,
                        apInterest: dataCarLands?.lands?.landLoanDetails?.interestLand,
                        apInterestRate: dataCarLands?.lands?.landLoanDetails?.interestRateLand,
                        apLoanTerm: dataCarLands?.lands?.landLoanDetails?.approvedLoanTerm,
                        apMonthlyPayment: dataCarLands?.lands?.landLoanDetails?.monthlyPaymentLand,
                        apInstallmentWithInterest: ~~dataCarLands?.lands?.landLoanDetails?.monthlyPaymentLand * ~~dataCarLands?.lands?.landLoanDetails?.loanLandTerm
                    })
                    form.setFieldsValue(
                        {
                            cause: "แก้ไขยอดอนุมัติ",
                            apLoanTerm: dataCarLands?.lands?.landLoanDetails?.approvedLoanTerm,
                            apLoanAmount: dataCarLands?.lands?.landLoanDetails?.approvedLoanAmount,
                            apMonthlyPayment: dataCarLands?.lands?.landLoanDetails?.monthlyPaymentLand,
                            apInterest: dataCarLands?.lands?.landLoanDetails?.interestLand,
                            apInstallmentWithInterest: ~~dataCarLands?.lands?.landLoanDetails.approvedLoanTerm * ~~dataCarLands?.lands?.landLoanDetails?.monthlyPaymentLand
                        }
                    )
                }


            }

        }
    }, [dataCarLands])

    useEffect(() => {
        form.setFieldsValue(
            {
                mixLand: mixLand,
            }
        )
    }, [mixLand])

    useEffect(() => {
        loadCauseName();
    }, [numForCheck])

    const currencyFormatOne = (amount) => {
        if (amount) {
            return Number(amount)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, '$&,')
        }
    }

    useEffect(() => {
        if (dataFromTable.car === "car") {
            if (checkType === "เช่าซื้อ1") {
                // console.log("เช่าซื้อ1")
                car_Calculate()
            } else if (dataCarLands?.cars.productTypeCar !== 1 && checkType === "เช่าซื้อ") {
                // console.log("เช่าซื้อ รถใหญ่")
                car_Calculate()
            }
        } else if (dataFromTable.car === "land") {
            if (appStatus === "อนุมัติ" || appStatus === "อนุมัติแบบมีเงื่อนไข" || appStatus === "ลูกค้าคอนเฟิร์ม" || appStatus === "รอตรวจสอบเอกสาร" || appStatus === "รอทำสัญญา") {
                land_Calculate()
            }
            // console.log("land")
        }
    }, [preData?.apLoanAmount, preData?.apLoanTerm, preData?.apInterestRate, checkType])

    const handleMoney = (values) => {
        // console.log("values1", values)
        setstatusAccept({
            ...statusAccept,
            apInterestRate: values?.interestRate,
            apLoanAmount: values?.loanAmountt,
            apLoanTerm: values?.installmentAmountt,
            apInterest: values?.interestt,
            apMonthlyPayment: values?.monthlyPayment,
            apInstallmentWithInterest: values?.installmentWithInterestt,
        })
        form.setFieldsValue(
            {
                apLoanAmount: values?.loanAmountt,
                apInterestRate: values?.interestRate,
                apLoanTerm: values?.installmentAmountt,
                apInterest: currencyFormatOne(values?.interestt),
                apMonthlyPayment: currencyFormatOne(values?.monthlyPayment),
                apInstallmentWithInterest: currencyFormatOne(values?.installmentWithInterestt),
            }
        )
    }

    const handleMoneyHP = (values) => {
        // console.log("values2", values)

        setstatusAccept({
            ...statusAccept,
            apLoanAmount: values?.loanAmountt,
            apInterestRate: values?.interestRate,
            apLoanTerm: values?.installmentAmountt,
            apInterest: values?.interestt,
            apMonthlyPayment: values?.monthlyPayment,
            apInstallmentWithInterest: values?.installmentWithInterestt,
        })
        form.setFieldsValue(
            {
                apLoanAmount: currencyFormatOne(values?.loanAmountt),
                apInterestRate: values?.interestRate,
                apLoanTerm: values?.installmentAmountt,
                apInterest: currencyFormatOne(values?.interestt),
                apMonthlyPayment: currencyFormatOne(values?.monthlyPayment),
                apInstallmentWithInterest: currencyFormatOne(values?.installmentWithInterestt),
            }
        )
    }

    const EdiitSuccess = (placement) => {
        api.success({
            message: "แก้ไขยอดสำเร็จ",
            // description:
            //     'หมายเหตุที่ถูกเพิ่ม สามารถเลือกได้ในช่อง หมายเหตุ',
            placement,
        });
    };




    const handleCancel = () => {
        close(false);
    };

    const handleChange = (value) => {
        setCause(`${value}`)
    }

    const changeLoanTerm = (value) => {
        setpreData({ ...preData, apLoanTerm: value })
    }

    const changeInterestRate = (value) => {
        console.log("apInterestRate", value)
        setpreData({ ...preData, apInterestRate: value })
    }

    const ErrorSuccess = (placement) => {
        api.error({
            message: "แก้ไขยอดไม่สำเร็จ",
            // description:
            //     'หมายเหตุที่ถูกเพิ่ม สามารถเลือกได้ในช่อง หมายเหตุ',
            placement,
        });
    };

    const getTable = () => {
        setOpenTable(true)
    }
    const getTableHP = () => {
        setOpenTableHP(true)
    }

    const handleOk = async () => {
        setDis(true)
        if (appStatus === "อนุมัติแบบมีเงื่อนไข" || appStatus === "อนุมัติ" || appStatus === "ลูกค้าคอนเฟิร์ม" || appStatus === "รอตรวจสอบเอกสาร" || appStatus === "รอทำสัญญา") {
            var EditApproveLoan = { cause, ...statusAccept, noteBy: user, rejectStatus: false, guarantors: valueApCon, noteDate: dayjs().format('YYYY-MM-DDTHH:mm:ssZ'), checkNote: "0" }
            // console.log("EditApproveLoan", EditApproveLoan)
            var DataUpdate = {
                loanId: dataFromTable.car === "car" ? dataCarLands?.cars?.apCarLoanDetails?.loanId : dataCarLands?.lands?.apLandLoanDetails.loanId,
                typeLoan: dataFromTable.car,
                loanDetail:
                {
                    // ApprovalStatus: EditApproveLoan?.ApprovalStatus,
                    // ApprovedBy: EditApproveLoan?.ApprovedBy,
                    // apApprovalDate: EditApproveLoan?.apApprovalDate,
                    // apApprovalStatus: EditApproveLoan?.apApprovalStatus,
                    // ApprovedBy: EditApproveLoan?.apApprovedBy,
                    // apBranch: EditApproveLoan?.apBranch,
                    // apCheckGua: EditApproveLoan?.apCheckGua,
                    downPayment: EditApproveLoan?.apDownPayment ? EditApproveLoan?.apDownPayment : null,
                    installmentWithInterest: EditApproveLoan?.apInstallmentWithInterest,
                    interest: EditApproveLoan?.apInterest ? parseInt(EditApproveLoan?.apInterest) : null,
                    interestRate: EditApproveLoan?.apInterestRate ? parseFloat(EditApproveLoan?.apInterestRate) : parseFloat(dataFromTable?.car === "land" ? dataCarLand?.landLoanDetails?.interestRateLand : dataCarLand?.carLoanDetails?.interestRate),
                    loanAmount: EditApproveLoan?.apLoanAmount,
                    loanTerm: EditApproveLoan?.apLoanTerm,
                    monthlyPayment: EditApproveLoan?.apMonthlyPayment,
                    // apProductLoanType: EditApproveLoan?.apProductLoanType,
                    productType: EditApproveLoan?.apProductType ? EditApproveLoan?.apProductType : null,
                    // apProposalBy: EditApproveLoan?.apProposalBy,
                    // apReviewedBy: EditApproveLoan?.apReviewedBy,
                    // appStatus: EditApproveLoan?.appStatus,
                    // approvalConditions: EditApproveLoan?.approvalConditions,
                    // carId: EditApproveLoan?.carId,
                    // customerId: EditApproveLoan?.customerId,

                },
                note: {
                    noteBy: EditApproveLoan?.noteBy,
                    noteDate: EditApproveLoan?.noteDate,
                    status: EditApproveLoan?.status ? EditApproveLoan?.status : null,
                    rejectStatus: EditApproveLoan?.rejectStatus,
                    cause: "แก้ไขยอดอนุมัติ",
                    note: EditApproveLoan?.note ? EditApproveLoan?.note : null,

                }
            }
            // console.log("DataUpdate", DataUpdate)
            updateApprove(DataUpdate)
        }

    };

    const updateApprove = async (value) => {
        console.log("updateApprove", value)
        await axios.post(updateApproves, value)
            .then(res => {
                // console.log("res.data", res)
                EdiitSuccess("top")
                sendback(dayjs())
                setTimeout(() => {
                    close(false)
                }, 2000);
            }).catch((err) =>
                ErrorSuccess("top")
            )
    };


    const loadCauseName = async () => {
        await axios.get(showcausename)
            .then(res => {
                if (res.status === 200) {
                    setOptions(res.data)
                }
            }).catch((err) => console.log(err))
    };

    const handleAddCause = async () => {
        console.log("addNote", addNote)
        // if (addNote.name !== '') {
        //     await axios.post(addcausename, addNote)
        //         .then(res => {
        //             if (res.status === 200) {
        //                 setNumForCheck(dayjs())
        //                 formAdd.resetFields();
        //                 setCheckCause(0);
        //                 addSuccess("top")
        //                 setDis(false);
        //             }
        //         }).catch((err) => console.log(err))
        // }
    };

    const BTNAddCause = () => {
        if (checkCause === 0) {
            setCheckCause(1);
            setDis(true);
        } else {
            setCheckCause(0);
            setDis(false);
        }
    };

    const car_Calculate = () => {
        //แก้ใหม่
        if (dataCarLands?.cars?.apCarLoanDetails?.loanId !== 0) {
            var interestRateCar = parseFloat(preData.apInterestRate) / 100 // อัตราดอก / 100
            var rateCar = ((parseFloat(preData.apLoanAmount) * Currency(interestRateCar, { precision: 5 }).value) * preData.apLoanTerm) // (ยอดจัด * อัตราดอกที่หาร100แล้ว ,precision: 3 คือทศนิยม 3 )*งวด
            var loanTermCar = (rateCar + parseFloat(preData.apLoanAmount)) / parseInt(preData.apLoanTerm) // (ดอกเบี้ยทั้งหมด + ยอดจัด)/ งวด
            var newLoanTermCar = Math.ceil(Currency((loanTermCar * 0.07) + loanTermCar), { precision: 5 })// งวดละใหม่ = ( งวดละ * 0.07 ) + งวดละ
            var resultRateCar = Math.ceil(Currency(newLoanTermCar, { precision: 5 }) * parseInt(preData.apLoanTerm)) - parseFloat(preData.apLoanAmount) // งวดละใหม่ * งวด - ยอดจัด
            var newResultPriceCar = Math.ceil(Currency(newLoanTermCar) * parseInt(preData.apLoanTerm), { precision: 5 }) // งวดละใหม่ * งวด

        } else {
            var interestRateCar = parseFloat(dataCarLands?.cars?.carLoanDetails?.interestRate) / 100 // อัตราดอก / 100
            var rateCar = ((parseFloat(preData.apLoanAmount) * Currency(interestRateCar, { precision: 5 }).value) * preData.apLoanTerm) // (ยอดจัด * อัตราดอกที่หาร100แล้ว ,precision: 3 คือทศนิยม 3 )*งวด
            var loanTermCar = (rateCar + parseFloat(preData.apLoanAmount)) / parseInt(preData.apLoanTerm) // (ดอกเบี้ยทั้งหมด + ยอดจัด)/ งวด
            var newLoanTermCar = Math.ceil(Currency((loanTermCar * 0.07) + loanTermCar), { precision: 5 })// งวดละใหม่ = ( งวดละ * 0.07 ) + งวดละ
            var resultRateCar = Math.ceil(Currency(newLoanTermCar, { precision: 5 }) * parseInt(preData.apLoanTerm)) - parseFloat(preData.apLoanAmount) // งวดละใหม่ * งวด - ยอดจัด
            var newResultPriceCar = Math.ceil(Currency(newLoanTermCar) * parseInt(preData.apLoanTerm), { precision: 5 }) // งวดละใหม่ * งวด

        }
        // console.log("interestRateCar", interestRateCar)
        // console.log("rateCar", rateCar)
        // console.log("loanTermCar", loanTermCar)
        // console.log("newLoanTermCar", newLoanTermCar)
        // console.log("resultRateCar", resultRateCar)
        // console.log("newResultPriceCar", newResultPriceCar)


        setstatusAccept({
            ...statusAccept,
            apInterestRate: preData.apInterestRate,
            apMonthlyPayment: newLoanTermCar,
            apInterest: resultRateCar,
            apInstallmentWithInterest: newResultPriceCar,
            apLoanAmount: preData.apLoanAmount,
            apLoanTerm: preData.apLoanTerm,
        })

        form.setFieldsValue(
            {
                apMonthlyPayment: newLoanTermCar,
                apInterest: resultRateCar,
                apInstallmentWithInterest: newResultPriceCar
            }
        )
    }

    const land_Calculate = () => {
        if (dataCarLands?.lands?.apLandLoanDetails?.loanId !== 0) {
            // console.log("3")
            // var interestRateLand = parseFloat(shootdata.lands.landLoanDetails.interestRateLand) / 100 // อัตราดอก / 100
            var interestRateLand = parseFloat(preData?.apInterestRate) / 100 // อัตราดอก / 100
            var rate = (parseFloat(preData?.apLoanAmount) * Currency(interestRateLand, { precision: 3 }).value) * parseInt(preData?.apLoanTerm) // (ยอดจัด * อัตราดอกที่หาร100แล้ว ,precision: 3 คือทศนิยม 3 )*งวด
            var loanTerm = Math.ceil(Currency((rate + parseFloat(preData?.apLoanAmount)) / parseInt(preData?.apLoanTerm), { precision: 2 }).value) // (ดอกเบี้ยทั้งหมด + ยอดจัด)/ งวด
            var resultRate = (loanTerm * parseInt(preData?.apLoanTerm)) - parseFloat(preData?.apLoanAmount)
            var resultPrice = loanTerm * parseInt(preData?.apLoanTerm)
        } else {
            // console.log("4")
            var interestRateLand = parseFloat(dataCarLands.lands.landLoanDetails.interestRateLand) / 100 // อัตราดอก / 100
            // var interestRateLand = parseFloat(dataCarLands.lands.apLandLoanDetails.apInterestRate) / 100 // อัตราดอก / 100
            var rate = (parseFloat(preData?.apLoanAmount) * Currency(interestRateLand, { precision: 3 }).value) * parseInt(preData?.apLoanTerm) // (ยอดจัด * อัตราดอกที่หาร100แล้ว ,precision: 3 คือทศนิยม 3 )*งวด
            var loanTerm = Math.ceil(Currency((rate + parseFloat(preData?.apLoanAmount)) / parseInt(preData?.apLoanTerm), { precision: 2 }).value) // (ดอกเบี้ยทั้งหมด + ยอดจัด)/ งวด
            var resultRate = (loanTerm * parseInt(preData?.apLoanTerm)) - parseFloat(preData?.apLoanAmount)
            var resultPrice = loanTerm * parseInt(preData?.apLoanTerm)
        }

        form.setFieldsValue(
            {
                apMonthlyPayment: loanTerm,
                apInterest: resultRate,
                apInstallmentWithInterest: resultPrice
            }
        )


        if (appStatus === "อนุมัติ" && checkApprovalConditions === 0) {
            setstatusAccept({
                ...statusAccept,
                apMonthlyPayment: loanTerm,
                apInterest: resultRate,
                apInstallmentWithInterest: resultPrice,
                apLoanAmount: preData?.apLoanAmount,
                apLoanTerm: preData?.apLoanTerm,

                status: appStatus,
                landId: dataCarLands?.lands?.landId,
                loanId: dataCarLands?.lands?.landLoanDetails?.landLoanId,
                customerId: dataCarLands?.customerId,
                ApprovalStatus: appStatus,
                appStatus: appStatus,
                ApprovedBy: user,

                apProductType: dataCarLands?.lands?.landLoanDetails?.productType,
                apProductLoanType: dataCarLands?.lands?.landLoanDetails?.productLoanLandType,
                // apLoanAmount: dataCarLands?.lands?.landLoanDetails?.loanAmountLand,
                apDownPayment: dataCarLands?.lands?.landLoanDetails?.downPayment,
                // apInterest: dataCarLands?.lands?.landLoanDetails?.interestLand,
                apInterestRate: parseFloat(dataCarLands?.lands?.landLoanDetails?.interestRateLand),
                // apLoanTerm: dataCarLands?.lands?.landLoanDetails?.loanLandTerm,
                // apMonthlyPayment: dataCarLands?.lands?.landLoanDetails?.monthlyPayment,
                apProposalBy: dataCarLands?.lands?.landLoanDetails?.proposalBy,
                apReviewedBy: dataCarLands?.lands?.landLoanDetails?.reviewedBy,
                apApprovalStatus: appStatus,
                apApprovedBy: user,
                apApprovalDate: dayjs(),

                apCheckGua: dataCarLands?.lands?.landLoanDetails?.checkGua,
                apBranch: branch,
                approvalConditions: false,
            })
        } else {
            setstatusAccept({
                ...statusAccept,
                apMonthlyPayment: loanTerm,
                apInterest: resultRate,
                apInstallmentWithInterest: resultPrice,
                apLoanAmount: preData?.apLoanAmount,
                apLoanTerm: preData?.apLoanTerm,

                status: appStatus,
                landId: dataCarLands?.lands?.landId,
                loanId: dataCarLands?.lands?.landLoanDetails?.landLoanId,
                customerId: dataCarLands?.customerId,
                ApprovalStatus: appStatus,
                appStatus: appStatus,
                ApprovedBy: user,
            })
        }


    }

    /////////////// เลือก p-loan หรือ เช่าซื้อ ///////////////////
    const handleChangePLorCH = (value) => {
        if (dataCarLands?.cars?.productTypeCar === 1) {
            // console.log("รถเล็ก")
            if (value === "p-loan") {
                // console.log("p-loan")
                setCheckType(value)
                setCheckDis(true)
                setstatusAccept({
                    ...statusAccept,
                    apProductType: "p-loan",
                })
            } else if (value === "เช่าซื้อ") {
                // console.log("เช่าซื้อ")
                setCheckDis(true)
                setstatusAccept({
                    ...statusAccept,
                    apProductType: "เช่าซื้อ",
                })
                setCheckType(value)
            } else if (value === "เช่าซื้อ1") {
                // console.log("เช่าซื้อ1")
                setCheckDis(false)
                setstatusAccept({
                    ...statusAccept,
                    apProductType: "เช่าซื้อ1",
                })
                setCheckType(value)
            }
            form.setFieldsValue(
                {
                    apLoanAmount: "",
                    apLoanTerm: "",
                    apInterestRate: ""
                }
            )
            setCheckCar23(false)
        } else {
            // console.log("รถใหญ่")
            if (value === "p-loan") {
                setCheckType(value)
                setCheckDis(true)
                setstatusAccept({
                    ...statusAccept,
                    apProductType: "p-loan",
                })
                setCheckCar23(false)
                form.setFieldsValue(
                    {
                        apInterestRate: dataCarLands?.cars?.carLoanDetails?.interestRate
                    }
                )
            } else if (value === "เช่าซื้อ") {
                setCheckDis(false)
                setstatusAccept({
                    ...statusAccept,
                    apProductType: "เช่าซื้อ",
                })
                setCheckType(value)
                setCheckCar23(true)
                form.setFieldsValue(
                    {
                        apInterestRate: dataCarLands?.cars?.carLoanDetails?.interestRate
                    }
                )
            }
            form.setFieldsValue(
                {
                    apLoanAmount: "",
                    apLoanTerm: "",
                }
            )
        }
    }

    const onChange = (v, i) => {
        const updatedGuarantorData = valueApCon?.map((item, index) => {
            if (index === i) {
                return { ...item, checkApCon: v.target.checked };
            } else {
                return { ...item };
            }
        });
        setValueApCon(updatedGuarantorData);
    };

    const makeMixLand = () => {
        var mixnumber = [];
        newDataLand?.forEach((item, index) => {
            mixnumber.push(item.numberLand);
        });
        const mixString = mixnumber.join(",");
        setMixLand(mixString);
    };

    const renderGuarantorNewImg = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
        return (
            <>
                <Card
                    title={"ลำดับที่ " + (item.garno)}
                    style={{ textAlign: "center" }}
                    key={index}
                >
                    <Row style={{ margin: '3px' }} justify={'center'} >
                        <Col span={24} style={{ textAlign: "left" }}>
                            <div>
                                <span>ชื่อ-สกุล :</span>  <b>{item?.firstname} {item.lastname}</b>
                            </div>
                            <div>
                                <span>เบอร์โทรติดต่อ :</span> <b>{item?.phones[0]?.telp}</b>
                            </div>
                            <div>
                                <span>ความสัมพันธ์ :</span> <b>{item?.description}</b>
                            </div>
                            <Divider />
                            <div style={{ textAlign: 'center' }}>
                                <Checkbox onChange={(value) => onChange(value, index)} style={{ color: "red" }}>ไม่ผ่าน(ลบ)</Checkbox>
                            </div>
                        </Col>
                    </Row>
                </Card >

            </>
        )
    }

    return (
        <div>
            <Modal title="แก้ไขยอดออนุมัติ" open={open} onCancel={handleCancel} footer={[null]} width={800}>
                <Card>
                    <Form
                        onFinish={handleOk}
                        form={form}
                        labelCol={{
                            span: 6,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}>
                        <Row>
                            <Col span={12}>
                                <Form.Item style={{ textAlign: 'center' }}>
                                    สถานะ : <b style={{ color: 'green', fontSize: '20px' }}><u> {appStatus}</u></b>
                                </Form.Item>
                            </Col>
                            {
                                appStatus === "อนุมัติแบบมีเงื่อนไข" ?
                                    <>
                                        <Row gutter={32}  >
                                            {
                                                guarantorData?.map((item, index) => {
                                                    return renderGuarantorNewImg({ item, index, key: `{item.identificationId} - ${index}` })
                                                })
                                            }
                                        </Row>
                                        <Divider />
                                    </>
                                    : null
                            }
                            <Col span={22}>
                                {
                                    mixLand !== undefined ?
                                        <>
                                            <Form.Item label="จดรวมโฉนด" name="mixLand">
                                                <Input disabled name="mixLand" rows={4} style={{ color: 'blue' }} />
                                            </Form.Item>
                                        </> : null
                                }
                                <Form.Item
                                    label="สาเหตุ"
                                    name="cause"

                                    rules={[
                                        {
                                            required: true,
                                            message: 'กรุณากรอกสาเหตุ'
                                        },]}>
                                    <Select
                                        mode="multiple"
                                        style={{
                                            width: '100%',
                                        }}
                                        size="large"
                                        disabled
                                        placeholder="สาเหตุ"
                                        onChange={handleChange}
                                        options={options}
                                    />
                                </Form.Item>
                                {
                                    checkCause === 0 ?
                                        null
                                        :
                                        <div>
                                            <Form
                                                form={formAdd}
                                                labelCol={{
                                                    span: 6,
                                                }}
                                                wrapperCol={{
                                                    span: 16,
                                                }}>
                                                <Form.Item label='เพิ่มสาเหตุ' name='name'>
                                                    <Input type='text' size="small" placeholder='กรอกสาเหตุที่ต้องการเพิ่ม' style={{ color: 'black' }}
                                                        onChange={(e) => setAddNote({ ...addNote, name: e.target.value })}
                                                    />
                                                </Form.Item>
                                            </Form>
                                        </div>

                                }
                                <Form.Item
                                    label="หมายเหตุ"
                                    name="note"
                                >
                                    <TextArea rows={4} onChange={(e) => setstatusAccept({ ...statusAccept, note: e.target.value })} />
                                </Form.Item>
                                {
                                    appStatus === "อนุมัติ" || appStatus === "อนุมัติแบบมีเงื่อนไข" || appStatus === "ลูกค้าคอนเฟิร์ม" || appStatus === "รอตรวจสอบเอกสาร" || appStatus === "รอทำสัญญา" ?
                                        <>
                                            {
                                                dataFromTable?.car === "car" ?
                                                    <>
                                                        {/* <p>รถ</p> */}
                                                        {/* handleCalculateHIRE() */}
                                                        {
                                                            typeCar === 1 ?
                                                                <>
                                                                    <Form.Item label='ประเภทค่างวด' name='apProductType'>
                                                                        <Select
                                                                            placeholder={'เลือก'}
                                                                            onChange={(value) => { handleChangePLorCH(value) }}
                                                                            options={[
                                                                                {
                                                                                    label: 'P-LOAN',
                                                                                    value: 'p-loan',
                                                                                },
                                                                                {
                                                                                    label: 'เช่าซื้อ',
                                                                                    value: 'เช่าซื้อ',
                                                                                },
                                                                                {
                                                                                    label: 'เช่าซื้อ (0.84-0.75)',
                                                                                    value: 'เช่าซื้อ1',
                                                                                }
                                                                            ]}
                                                                        />
                                                                    </Form.Item>
                                                                </>
                                                                :
                                                                <>
                                                                    <Form.Item label='ประเภทค่างวด' name='apProductType'>
                                                                        <Select
                                                                            placeholder={'เลือก'}
                                                                            onChange={(value) => { handleChangePLorCH(value) }}
                                                                            options={[
                                                                                {
                                                                                    label: 'P-LOAN',
                                                                                    value: 'p-loan',
                                                                                },
                                                                                {
                                                                                    label: 'เช่าซื้อ',
                                                                                    value: 'เช่าซื้อ',
                                                                                },
                                                                            ]}
                                                                        />
                                                                    </Form.Item>
                                                                </>
                                                        }
                                                        {
                                                            checkType === "p-loan" ?
                                                                <>
                                                                    <Form.Item label='ตารางค่างวด'>
                                                                        <Button type="primary" onClick={getTable}>
                                                                            ตารางค่างวด P-LOAN
                                                                        </Button>
                                                                    </Form.Item>
                                                                    {
                                                                        openTable ?
                                                                            <>
                                                                                {dataFromTable.car === "car" ?
                                                                                    <>
                                                                                        <Installment open={openTable} close={setOpenTable} money={handleMoney} type={money} cp={~~dataCarLands?.cars?.apCarLoanDetails?.apLoanAmount} cp2={~~dataCarLands?.cars?.apCarLoanDetails?.apLoanTerm} checkGARFE={2} checkPG={1} />

                                                                                    </>
                                                                                    :
                                                                                    null
                                                                                }
                                                                            </>
                                                                            : null
                                                                    }
                                                                </>
                                                                : checkType === "เช่าซื้อ" ?
                                                                    <>
                                                                        {
                                                                            dataCarLands?.cars?.productTypeCar === 1 ?
                                                                                <>
                                                                                    <Form.Item label='ตารางค่างวด'>
                                                                                        <Button type="primary" onClick={getTableHP}>
                                                                                            ตารางค่างวด เช่าซื้อ
                                                                                        </Button>
                                                                                    </Form.Item>
                                                                                    {
                                                                                        openTableHP ?
                                                                                            <HirePurchase open={openTableHP} close={setOpenTableHP} money={handleMoneyHP} type={money} cp={~~dataCarLands?.cars?.apCarLoanDetails?.apLoanAmount} cp2={~~dataCarLands?.cars?.apCarLoanDetails?.apLoanTerm} checkGARFE={2} checkPG={1} />
                                                                                            : null
                                                                                    }
                                                                                </>
                                                                                : null
                                                                        }
                                                                    </> : null
                                                        }

                                                        <Form.Item label='ยอดที่อนุมัติ' name='apLoanAmount'
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !'
                                                                },]}>
                                                            <Input disabled={checkDis} style={{ color: 'black' }} suffix="บาท"
                                                                onChange={(e) => setpreData({ ...preData, apLoanAmount: parseInt(e.target.value) })} />
                                                        </Form.Item>
                                                        <Form.Item label='จำนวนงวด' name='apLoanTerm'
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !'
                                                                },]}>
                                                            <Select
                                                                disabled={checkDis}
                                                                placeholder="เลือกจำนวนงวด"
                                                                onChange={changeLoanTerm}
                                                                options={[
                                                                    {
                                                                        value: 12,
                                                                        label: 12,
                                                                    },
                                                                    {
                                                                        value: 18,
                                                                        label: 18,
                                                                    },
                                                                    {
                                                                        value: 24,
                                                                        label: 24,
                                                                    },
                                                                    {
                                                                        value: 30,
                                                                        label: 30,
                                                                    },
                                                                    {
                                                                        value: 36,
                                                                        label: 36,
                                                                    },
                                                                    {
                                                                        value: 42,
                                                                        label: 42,
                                                                    },
                                                                    {
                                                                        value: 48,
                                                                        label: 48,
                                                                    },
                                                                    {
                                                                        value: 54,
                                                                        label: 54,
                                                                    },
                                                                    {
                                                                        value: 60,
                                                                        label: 60,
                                                                    },
                                                                    {
                                                                        value: 66,
                                                                        label: 66,
                                                                    },
                                                                    {
                                                                        value: 72,
                                                                        label: 72,
                                                                    },
                                                                    {
                                                                        value: 78,
                                                                        label: 78,
                                                                    },
                                                                    {
                                                                        value: 84,
                                                                        label: 84,
                                                                    },
                                                                    {
                                                                        value: 90,
                                                                        label: 90,
                                                                    },
                                                                    {
                                                                        value: 96,
                                                                        label: 96,
                                                                    },
                                                                    {
                                                                        value: 102,
                                                                        label: 102,
                                                                    },
                                                                    {
                                                                        value: 108,
                                                                        label: 108,
                                                                    },
                                                                    {
                                                                        value: 114,
                                                                        label: 114,
                                                                    },
                                                                    {
                                                                        value: 120,
                                                                        label: 120,
                                                                    },
                                                                ]}
                                                            />
                                                        </Form.Item>
                                                        {
                                                            checkCar23 === true ?
                                                                <Form.Item label='อัตราดอกเบี้ย' name='apInterestRate'
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please input !'
                                                                        },]}>

                                                                    <Input disabled={checkDis} style={{ color: 'black' }} suffix="% +VAT"
                                                                        onChange={(e) => setpreData({ ...preData, apInterestRate: parseFloat(e.target.value) })} />
                                                                </Form.Item>
                                                                :
                                                                <Form.Item label='อัตราดอกเบี้ย' name='apInterestRate'
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please input !'
                                                                        },]}>
                                                                    <Select
                                                                        disabled={checkDis}
                                                                        placeholder="เลือกอัตราดอกเบี้ย"
                                                                        onChange={changeInterestRate}
                                                                        options={[
                                                                            {
                                                                                label: 0.84,
                                                                                value: 0.84,

                                                                            },
                                                                            {
                                                                                label: 0.75,
                                                                                value: 0.75,
                                                                            }
                                                                        ]}
                                                                    />
                                                                </Form.Item>

                                                        }
                                                        <Form.Item label='งวดละ' name='apMonthlyPayment'>
                                                            <Input disabled style={{ color: 'black' }} suffix="บาท"
                                                                onChange={(e) => setstatusAccept({ ...statusAccept, apMonthlyPayment: e.target.value })} />
                                                        </Form.Item>
                                                        <Form.Item label='ดอกเบี้ย' name='apInterest'>
                                                            <Input disabled style={{ color: 'black' }} suffix="บาท"
                                                                onChange={(e) => setstatusAccept({ ...statusAccept, apInterest: e.target.value })} />
                                                        </Form.Item>
                                                        <Form.Item label='รวมราคา' name='apInstallmentWithInterest'>
                                                            <Input disabled style={{ color: 'black' }} suffix="บาท"
                                                                onChange={(e) => setstatusAccept({ ...statusAccept, apInstallmentWithInterest: e.target.value })} />
                                                        </Form.Item>
                                                    </>
                                                    :
                                                    <>
                                                        {/* <p>ที่ดิน</p> */}
                                                        {/* // land_Calculate() */}
                                                        <Form.Item label='ยอดที่อนุมัติ' name='apLoanAmount'
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !'
                                                                },]}>
                                                            <Input
                                                                type='number' suffix="บาท"
                                                                onChange={(e) => setpreData({ ...preData, apLoanAmount: parseInt(e.target.value) })} />
                                                        </Form.Item>
                                                        <Form.Item label='จำนวนงวด' name='apLoanTerm'
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !'
                                                                },]}>
                                                            <Input type='number' suffix="งวด"
                                                                onChange={(e) => setpreData({ ...preData, apLoanTerm: parseInt(e.target.value) })} />
                                                        </Form.Item>
                                                        <Form.Item label='งวดละ' name='apMonthlyPayment'>
                                                            <Input type='number' suffix="บาท" disabled style={{ color: 'black' }}
                                                                onChange={(e) => setpreData({ ...preData, apMonthlyPayment: parseInt(e.target.value) })} />
                                                        </Form.Item>
                                                        <Form.Item label='ดอกเบี้ย' name='apInterest'>
                                                            <Input type='number' suffix="บาท" disabled style={{ color: 'black' }}
                                                                onChange={(e) => setpreData({ ...preData, apInterest: parseInt(e.target.value) })} />
                                                        </Form.Item>
                                                        <Form.Item label='รวมราคา' name='apInstallmentWithInterest'>
                                                            <Input disabled style={{ color: 'black' }} suffix="บาท"
                                                                onChange={(e) => setpreData({ ...preData, apInstallmentWithInterest: parseInt(e.target.value) })} />
                                                        </Form.Item>
                                                    </>
                                            }
                                        </>
                                        : null
                                }
                            </Col>
                            <Col span={2}>
                                {/* {
                                    checkCause === 0 ?
                                        <Form.Item>
                                            <Button type="primary" onClick={BTNAddCause}>
                                                +
                                            </Button>
                                        </Form.Item>
                                        :
                                        <Form.Item>
                                            <Button type="primary" onClick={BTNAddCause}>
                                                -
                                            </Button>
                                        </Form.Item>
                                } */}
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} >
                                <div style={{ textAlign: 'center' }}>
                                    <Space>
                                        {
                                            checkCause === 1 ?
                                                <Button type='primary' onClick={handleAddCause}>เพิ่มหมายเหตุ</Button>
                                                : null
                                        }
                                        <Button key="back" type='primary' danger onClick={handleCancel}>ปิด</Button>
                                        <Button type='primary' htmlType='submit' disabled={dis} style={{ backgroundColor: "Green" }}>ยืนยัน</Button>
                                    </Space>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </Modal>
            {contextHolder}
        </div >
    )
};

export default Edit_Accept