import React, { useState, useEffect } from 'react'
import { Modal, Form, Button, Input, Card, Space, Select, Row, Col, notification, message, Divider, InputNumber } from 'antd'
import axios from 'axios';
import dayjs from 'dayjs';
import { approvalEditPriceNew, findlandpn, editApprovePriceRe, shownotepn, loadlandrepn } from '../../file_mid/all_api';
import { css } from 'styled-components';

export default function EditPrice({ open, close, dataFromTable }) {

    const us = localStorage.getItem('username')
    const currentTime = dayjs();
    const formattedTime = currentTime.format('YYYY-MM-DDTHH:mm:ssZ');

    const [dataModalEdit, setDataModalEdit] = useState(dataFromTable);
    const [newPrice, setNewPrice] = useState({});
    const [options, setOptions] = useState([]);
    const [multiData, setMultiData] = useState([]);
    const [dataSend, setDataSend] = useState([]);

    const [multiMode, setMultiMode] = useState(false);
    const [dataLandRe, setDataLandRe] = useState([]);
    const [api, contextHolder] = notification.useNotification();
    const [form] = Form.useForm()
    const [formAdd] = Form.useForm()

    // console.log("dataModalEdit", dataModalEdit)

    useEffect(() => {
        loadDataNew()
        loadNoteName();
    }, [])

    useEffect(() => {
        pickData()

    }, [multiData])

    const pickData = () => {
        const newData = []
        const oldData = []
        multiData.map((data) => {
            // console.log("data", data)
            var NewApproval = {
                customerId: data?.land?.approvalLandPrice?.customerId,
                landId: data?.land?.approvalLandPrice?.landId,
                landLoanId: data?.land?.approvalLandPrice?.landLoanId,
                approvalDate: data?.land?.approvalLandPrice?.approvalDate,
                minPrice: parseInt(data?.land?.approvalLandPrice?.minPrice) ? parseInt(data?.land?.approvalLandPrice?.minPrice) : parseInt(data?.approval?.minPrice),
                maxPrice: parseInt(data?.land?.approvalLandPrice?.maxPrice) ? parseInt(data?.land?.approvalLandPrice?.maxPrice) : parseInt(data?.approval?.maxPrice),
                priceStatus: true,
                inputPriceDate: data?.land?.approvalLandPrice?.inputPriceDate,
                proposalPriceBy: data?.land?.approvalLandPrice?.proposalPriceBy,
                note: data?.land?.approvalLandPrice?.note ? data?.land?.approvalLandPrice?.note : data?.approval?.note,
                // mixNote: data?.approval?.mixNote,
                rejectDate: data?.land?.approvalLandPrice?.rejectDate,
                rejectStatus: data?.land?.approvalLandPrice?.rejectStatus
                // rejectDate: data?.land?.approvalLandPrice?.rejectDate,
                // rejectStatus: data?.land?.approvalLandPrice?.rejectStatus
            }
            var oldApproval = {
                customerId: data?.land?.approvalLandPrice?.customerId,
                landId: data?.land?.approvalLandPrice?.landId,
                landLoanId: data?.land?.approvalLandPrice?.landLoanId,
                oldMinPrice: parseInt(data?.land?.approvalLandPrice?.minPrice),
                oldMaxPrice: parseInt(data?.land?.approvalLandPrice?.maxPrice)
            }

            newData.push(NewApproval)
            oldData.push(oldApproval)

        })
        setDataSend({
            newPrice: newData,
            oldPrice: oldData
        })
        multiData.map((e, index) => {
            // console.log("e", e)
            form.setFieldsValue({
                [`minPrice${index}`]: e?.land?.approvalLandPrice?.minPrice,
                [`maxPrice${index}`]: e?.land?.approvalLandPrice?.maxPrice,
                [`note${index}`]: e?.land?.approvalLandPrice?.note,
            })
        })
    }

    const loadNoteName = async () => {
        await axios.get(shownotepn)
            .then(res => {
                if (res.status === 200) {
                    setOptions(res.data)
                }
            }).catch((err) => console.log(err))
    };

    const loadDataNew = async () => {
        await axios.post(loadlandrepn, { customerId: dataFromTable.customerId, reLoanId: dataFromTable.reLoanId, landId: dataFromTable.landId })
            .then(res => {
                const landRe = [{ ...res.data }]
                setDataLandRe(landRe)
                setMultiData(landRe)

            }).catch((err) => {
                console.log("err", err)
            })
    }

    const handleCancel = () => {
        close(false)
    }


    const handleOk = async (datatolandre) => {
        // console.log("datatolandre", datatolandre)
        // var checkNumber = 0
        // if (datatolandre.minPrice > datatolandre.maxPrice) {
        //     checkNumber = 1
        //     console.log('11')
        // }
        // if (checkNumber === 1) {
        //     alert("ยอดสูงสุดน้อยกว่ายอดต่ำสุด กรุณาตรวจสอบ")
        // } else {
        // console.log('else')
        await axios.post(editApprovePriceRe, datatolandre)
            .then(res => {
                if (res.status === 200) {
                    message.success('บันทึกสำเร็จ');
                    close(false);
                } else if (res.status === 204) {
                }
            }).catch(err => {
                console.log(err)
            })
        // }
    }

    const submitConfirm = () => {
        let approvePriceReNew;
        approvePriceReNew = { ...dataSend.newPrice[0] }
        var datatolandre = {
            approvalLandPriceId: dataModalEdit.approvalLandPriceId,
            customerId: approvePriceReNew?.customerId,
            reLoanId: dataModalEdit?.reLoanId,
            landId: approvePriceReNew?.landId,
            minPrice: approvePriceReNew?.minPrice,
            maxPrice: approvePriceReNew?.maxPrice,
            priceStatus: approvePriceReNew?.priceStatus,
            inputPriceDate: approvePriceReNew?.inputPriceDate,
            proposalPriceBy: approvePriceReNew?.proposalPriceBy,
            note: approvePriceReNew?.note,
            oldMinPrice: parseFloat(dataModalEdit?.minPrice),
            oldMaxPrice: parseFloat(dataModalEdit?.maxPrice),
        }
        var checkNumber = 0
        if (datatolandre.minPrice > datatolandre.maxPrice) {
            checkNumber = 1
            console.log('11')
        }
        if (checkNumber === 1) {
            alert("ยอดสูงสุดน้อยกว่ายอดต่ำสุด กรุณาตรวจสอบ")
        } else {
            Modal.confirm({
                title: 'บันทึกข้อมูล',
                content: 'คุณต้องการบันทึกข้อมูลใช่หรือไม่ ?',
                onOk() {
                    handleOk(datatolandre)
                },
                onCancel() {
                    message.error('ยกเลิก');
                },
            });
        }
    };

    const handleMaxPrice = (value, index) => {
        // console.log("valuue155", value)
        setDataSend(prevData => ({
            ...prevData,
            newPrice: prevData.newPrice.map((item, i) =>
                i === index ? { ...item, maxPrice: parseFloat(value) } : item
            )
        }));
    }

    const handleMinPrice = (value, index) => {
        // console.log("valuue165", value)

        setDataSend(prevData => ({
            ...prevData,
            newPrice: prevData.newPrice.map((item, i) =>
                i === index ? { ...item, minPrice: parseFloat(value) } : item
            )
        }));
    }

    const handleNoteMultimode = (value, index) => {
        setDataSend(prevData => ({
            ...prevData,
            newPrice: prevData.newPrice.map((item, i) =>
                i === index ? { ...item, note: `${value}` } : item
            )
        }));
    }

    const renderMultiLand = ({ item, index }) => {
        // console.log("item", item)
        return (
            <>
                <Divider orientation='left'><b>เลขโฉนดที่ดิน ({item?.land?.numberLand})</b></Divider>
                <Col className='gutter-row' span={24} style={{ textAlign: 'center' }}>
                    <Form.Item label="หมายเหตุ" name={`note${index}`}>
                        <Select
                            mode="multiple"
                            style={{
                                width: '100%',
                            }}
                            size="large"
                            placeholder="เลือกหมายเหตุ"
                            onChange={(e) => handleNoteMultimode(e, index)}
                            options={options}
                        />
                    </Form.Item>
                </Col>
                <Col className='gutter-row' span={12} style={{ textAlign: 'center' }}>
                    <Form.Item label='ยอดขั้นต่ำ' name={`minPrice${index}`}
                        rules={[
                            {
                                required: true,
                                message: 'กรุณากรอกยอดที่อนุมัติขั้นต่ำ'
                            },
                        ]}>
                        <InputNumber
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            size="large"
                            style={{ width: '100%', color: 'black' }}
                            onChange={(value) => handleMinPrice(value, index)}
                        />
                    </Form.Item>
                </Col>
                <Col className='gutter-row' span={12} style={{ textAlign: 'center' }}>
                    <Form.Item label='ยอดสูงสุด' name={`maxPrice${index}`}
                        rules={[
                            {
                                required: true,
                                message: 'กรุณากรอกยอดที่อนุมัติสูงสุด'
                            },
                        ]}>
                        <InputNumber
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            size="large"
                            style={{ width: '100%', color: 'black' }}
                            onChange={(value) => handleMaxPrice(value, index)}
                        />
                    </Form.Item>
                </Col>
            </>
        )
    }

    return (
        <div>
            <Modal title="แก้ไขยอด" open={open} onCancel={handleCancel} footer={[null]} width={800}>
                <Card>
                    <Form
                        onFinish={submitConfirm}
                        form={form}
                        labelCol={{
                            span: 6,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}>
                        <Row>
                            <Col span={24}>
                                <Row>
                                    {
                                        dataLandRe?.map((item, index) => {
                                            return renderMultiLand({ item, index })
                                        })
                                    }
                                </Row>
                            </Col>
                        </Row>
                        <Divider />
                        <Row>
                            <Col span={24} >
                                <div style={{ textAlign: 'center' }}>
                                    <Space>
                                        <Button type='primary' key="back" danger onClick={handleCancel}>ปิด</Button>
                                        <Button type='primary' htmlType='submit' style={{ backgroundColor: "green" }}>บันทึก</Button>
                                    </Space>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </Modal>
            {contextHolder}
        </div>
    )
}
