import { createSlice } from "@reduxjs/toolkit";

const initialState = {

}

const oldLoanLandDataSlice = createSlice ({
    name: "oldLoanLand",
    initialState,
    reducers: {
        
        addOldLoanLand: (state, action) => {
            state.data = action.payload
            // console.log(current(state))
        },
        
    }
    
})

export const { addOldLoanLand } = oldLoanLandDataSlice.actions
export default oldLoanLandDataSlice.reducer