  const datalocat = [
    {
      key: 3,
      value: "ทั้งหมด",
      label: "ทั้งหมด",
    },
    {
      key: 1,
      value: "ALLONE",
      label: "ONEทั้งหมด",
    },
    {
      key: 2,
      value: "ALLKSM",
      label: "KSMทั้งหมด",
    },
  ];

export default datalocat;