//function dotaColums() {
  const dotaColums = [
    {
      title: "การตลาด",
      dataIndex: "userMarketing",
      key: "userMarketing",
      sorter: (a, b) => a.userMarketing.localeCompare(b.userMarketing),
    // หรือ sorter: (a, b) => a.userMarketing - b.userMarketing ถ้า userMarketing เป็นตัวเลข
    },
    {
      title: "เคสทั้งหมด",
      dataIndex: "caseAll",
      key: "caseAll",
      sorter: (a, b) => a.caseAll - b.caseAll,
    },
    {
      title: "เคสได้ยอด",
      dataIndex: "caseSuccess",
      key: "caseSuccess",
      sorter: (a, b) => a.caseSuccess - b.caseSuccess,
    },
    {
      title: "รอดำเนินการ",
      dataIndex: "caseWait",
      key: "caseWait",
      sorter: (a, b) => a.caseWait - b.caseWait,
    },
    {
      title: "เคสไม่ได้ยอด",
      dataIndex: "caseFail",
      key: "caseFail",
      sorter: (a, b) => a.caseFail - b.caseFail,
    },
  ];
//  return columns;
//}
export default dotaColums;
