import { createSlice } from "@reduxjs/toolkit";

const initialState = {

}

const occupationDataSlice = createSlice ({
    name: "occupation",
    initialState,
    reducers: {
        
        addOccupation: (state, action) => {
            state.data = action.payload
        },
        
    }
    
})

export const { addOccupation } = occupationDataSlice.actions
export default occupationDataSlice.reducer