import React, { useState, useEffect } from 'react'
import { Form, Modal, Row, Col, Tag, Select, Card, Button, Divider, Spin, InputNumber } from 'antd'
import dayjs from 'dayjs';
import axios from "axios";

export default function DetailAD({ open, dataFromTable, close }) {
    const { confirm } = Modal;
    const token = localStorage.getItem('token');
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm()
    const [dataModalEdit, setDataModalEdit] = useState(dataFromTable);


    useEffect(() => {
        // loadData()
        form.setFieldsValue(
            {
                ...dataFromTable,
            }
          )
    }, [])

    // const loadData = async () => {
    //     const dataAD = {username: dataFromTable?.username}
    //     // console.log("dataAD", dataAD)
    //     setLoading(true)     
    //         await axios.post(`http://localhost:8070/group/detailAD`, dataAD)
    //             .then(res => {
    //                 if (res.status === 200) {
    //                     console.log("AD",res.data)
    //                     setDataModalEdit({...res.data, 
    //                         name: dataFromTable?.username,
    //                         nickname: dataFromTable?.nickname
    //                     })
    //                     form.setFieldsValue(
    //                         {
    //                           ...res.data,
    //                           ...dataFromTable
    //                         }
    //                       )
    //                     setLoading(false)
    //                 }
    //             }).catch((err) => {
    //                 console.log("err", err)
    //             })
    //     setLoading(false)
    // }


    const handleOk = async () => {
        console.log('ok',dataModalEdit)
        // setLoading(true)     
        //     await axios.post(`http://localhost:8070/group/add`, dataModalEdit)
        //         .then(res => {
        //             if (res.data) {
        //                 console.log("OK",res.data)
        //                 setLoading(false)
        //             }
        //         }).catch((err) => {
        //             console.log("err", err)
        //         })
        // setLoading(false)
    };

    const handleCancel = () => {
        close(false);
    };

    const handleChangeType = (value) => {
        //console.log("value-case", value)
        setDataModalEdit({ ...dataModalEdit, team: value })
    }

    return (
        <div>
            <Modal title="ตรวจสอบข้อมูล" open={open} onOk={handleOk} onCancel={handleCancel} width={800} footer={[null]} >
                <Card>
                    <Spin spinning={loading} size='large' tip=" Loading... ">
                        {/* <Row justify={'center'}>
                        <Col className='gutter-row' span={24} style={{ fontSize: '25px', textAlign: 'center' }}>
                            <b>รายละเอียด</b>
                        </Col>
                    </Row> */}
                        <Divider style={{ margin: 5 }} />
                        <Form
                            labelCol={{
                                span: 6,
                            }}
                            wrapperCol={{
                                span: 16,
                            }}
                            form={form}
                            onFinish={handleOk}
                            autoComplete="off"
                            initialValues={{ remember: true }}
                        >
                            <Row>
                                <Col span={24}>
                                    <Form.Item>
                                        <b>รหัสธุรการ : <Tag color={"green"}>{dataFromTable?.username}</Tag></b>
                                        <b>ชื่อเล่น : <Tag color={"green"}>{dataFromTable?.nickname}</Tag></b>
                                    </Form.Item>
                                </Col>
                                <Col span={22}>
                                    {/* <Form.Item label='ทีม' name='team'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'กรุณาเลือกทีม !',
                                            },]}>
                                        <Select
                                            placeholder={'เลือก'}
                                            onChange={handleChangeType}
                                            options={[
                                                {
                                                    label: 'A',
                                                    value: 'A',
                                                },
                                                {
                                                    label: 'B',
                                                    value: 'B',
                                                },
                                                {
                                                    label: 'C',
                                                    value: 'C',
                                                },
                                            ]}
                                        />
                                    </Form.Item> */}
                                </Col>
                            </Row>
                            <Row gutter={32}>
                                <Col className='gutter-row' span={12} style={{ textAlign: 'center' }}>
                                    <Form.Item
                                        label='ยอดขั้นต่ำ'
                                        name='minBalance'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'กรุณากรอกยอดที่อนุมัติขั้นต่ำ'
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                            size="large"
                                            style={{ width: '100%', color: 'black' }}
                                            onChange={(value) => setDataModalEdit({ ...dataModalEdit, minBalance: parseFloat(value) })}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col className='gutter-row' span={12} style={{ textAlign: 'center' }}>
                                    <Form.Item
                                        label='ยอดสูงสุด'
                                        name='maxBalance'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'กรุณากรอกยอดที่อนุมัติสูงสุด'
                                            },
                                        ]}
                                    >

                                        <InputNumber
                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                            size="large"
                                            style={{ width: '100%', color: 'black' }}
                                            onChange={(value) => setDataModalEdit({ ...dataModalEdit, maxBalance: parseFloat(value) })}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Divider />
                            <Col className='gutter-row' span={24} style={{ textAlign: 'right' }}>
                                <Button key="back" type='primary' danger onClick={handleCancel}>
                                    ปิด
                                </Button>
                                <Button
                                    htmlType="submit"
                                    style={{ margin: '5px', background: '#597ef7', color: '#ffffff' }}
                                >
                                    บันทึก
                                </Button>
                            </Col>
                        </Form>
                    </Spin>
                </Card>
            </Modal>
        </div >
    )
}
