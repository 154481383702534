import React, { useEffect, useState } from 'react';
import { Button, Table, Row, Col, Card, Space, message, Modal, Spin } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import ModalCreateRole from '../modals/ModalCreateRole';
import ModalDetailRight from '../modals/ModalDetailRight';
import ModalEditRole from '../modals/ModalEditRole';
import axios from 'axios';


const { confirm } = Modal;

const CreateRights = () => {
    const pageSize = 10;
    const [currentPage, setCurrentPage] = useState(1);

    const [modalCreateRole, setModalCreateRole] = useState(false);
    const [modalDetailRight, setModalDetailRight] = useState(false);
    const [modalEditRole, setModalEditRole] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [loading, setLoading] = useState(false);
    const [role, setRole] = useState([]);

    // console.log('role',role)

    useEffect(() => {
        if (!modalCreateRole && !modalDetailRight && !modalEditRole) {
            fetchData();
        }
    }, [modalCreateRole, modalDetailRight, modalEditRole]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.get('https://api-mongodb-i67f6gaaqa-as.a.run.app/roles');
            setRole(response.data);
            setLoading(false);
            // console.log('response', response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    const handleDelete = async (roleId) => {
        try {
            const response = await axios.delete(`https://api-mongodb-i67f6gaaqa-as.a.run.app/delete-role/${roleId}`);
            if (response.status === 200) {
                message.success('ลบข้อมูลสำเร็จ');
                fetchData();
            } else {
                message.error('เกิดข้อผิดพลาดในการลบข้อมูล');
            }
        } catch (error) {
            console.error('Error deleting data:', error);
            if (error.response && error.response.status === 400) {
                message.error('ไม่สามารถลบข้อมูลได้');
            }
        }
    };

    const showDeleteConfirm = (record) => {
        confirm({
            title: 'คุณแน่ใจหรือไม่ที่จะลบข้อมูลนี้?',
            content: 'ข้อมูลจะถูกลบอย่างถาวรและไม่สามารถกู้คืนได้',
            okText: 'ใช่',
            icon: <ExclamationCircleOutlined />,
            okType: 'danger',
            cancelText: 'ยกเลิก',
            onOk() {
                handleDelete(record.role_id);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const columns = [
        {
            title: 'ลำดับที่',
            dataIndex: 'index',
            key: 'index',
            width: '10%',
            align: 'center',
            render: (text, record, index) => (currentPage - 1) * pageSize + index + 1,
        },
        // {
        //     title: 'Role ID',
        //     dataIndex: 'role_id',
        //     key: 'role_id',
        //     width: '10%',
        // },
        {
            title: 'ชื่อสิทธิ์',
            dataIndex: 'role_name',
            key: 'role_name',
            align: 'center',
        },
        {
            title: 'รายละเอียด',
            key: 'action',
            align: 'center',
            width: '30%',
            render: (text, record) => (
                <Space size="middle">
                    <Button
                        icon={<SearchOutlined />}
                        onClick={() => onDetailClick(record)}
                        style={{ width: '50px' }}
                    />
                </Space>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            width: '30%',
            align: 'center',
            render: (text, record) => (
                <Space size="middle">
                    <Button
                        onClick={() => onEditClick(record)}
                        style={{
                            color: 'white',
                            backgroundColor: 'orange',
                            justifyContent: 'end',
                            padding: '10px',
                            margin: '10px'
                        }}
                    >
                        แก้ไข
                    </Button>
                    <Button
                        onClick={() => showDeleteConfirm(record)}
                        style={{
                            color: 'white',
                            backgroundColor: 'red',
                            justifyContent: 'end',
                            padding: '10px',
                            margin: '10px'
                        }}
                    >
                        ลบ
                    </Button>
                </Space>
            ),
        },
    ];

    const onCustom = () => {
        setModalCreateRole(true);
    };

    const onDetailClick = (record) => {
        setSelectedRecord(record);
        setModalDetailRight(true);
    };

    const onEditClick = (record) => {
        setSelectedRecord(record);
        setModalEditRole(true);
    };

    const onClose = () => {
        setModalDetailRight(false);
        setModalCreateRole(false);
        setModalEditRole(false);
        setSelectedRecord(null);
    };

    const handleTableChange = (pagination) => {
        setCurrentPage(pagination.current);
    };

    return (
        <>
            <Card bordered={false}>
                <Spin spinning={loading} size='large' tip=' loading... '>
                    <Row justify={'center'}>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <h1>ตารางสิทธิ์ทั้งหมด</h1>
                        </Col>
                        <Col span={22}>
                            <Row justify={'end'}>
                                <Button
                                    onClick={onCustom}
                                    className='button-create'
                                    style={{ margin: 20 }}
                                >
                                    สร้างสิทธิ์ใหม่
                                </Button>
                            </Row>
                        </Col>
                    </Row>
                    <Row justify={"center"}>
                        <Col span={22}>
                            <Table
                                columns={columns}
                                dataSource={role}
                                onChange={handleTableChange}
                                rowKey="role_id"
                                pagination={{ pageSize }}
                            />
                        </Col>
                    </Row>
                    {modalCreateRole && (
                        <ModalCreateRole
                            open={modalCreateRole}
                            close={onClose}
                            roles={role}
                        />
                    )}
                    {modalDetailRight && selectedRecord && (
                        <ModalDetailRight
                            open={modalDetailRight}
                            close={onClose}
                            record={selectedRecord}
                        />
                    )}
                    {modalEditRole && selectedRecord && (
                        <ModalEditRole
                            open={modalEditRole}
                            close={onClose}
                            record={selectedRecord}
                            roles={role}
                        />
                    )}
                </Spin >
            </Card>
        </>
    );
};

export default CreateRights;
