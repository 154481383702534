import React, { useEffect, useState } from 'react'
import { Button, Form, Card, Col, Row, Space, Divider, Spin, Modal, Image, Checkbox } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import FileSaver from 'file-saver';
import JSZip from 'jszip';
import { setTypeImg } from '../../../file_mid/type_img';
import { deleteAllCar, deleteAllLand, storeDBLine, getImagess, downloadImagess, deleteNewUpCar, deleteNewUpLand } from '../../../file_mid/all_api';

export default function Product2({ getData, DeleteError, deleteData, checkType, checkSendNEW, newDataLand, sendB }) {
  const un = localStorage.getItem('username');
  const token = localStorage.getItem('token');
  const view = localStorage.getItem('viewAD')
  const detail = JSON.parse(view)
  const [dataModalEdit, setDataModalEdit] = useState(getData);
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [cb, setCb] = useState(false)
  const [checkedImages, setCheckedImages] = useState([]);
  const [adData, setAdData] = useState([])
  const [checkedState, setCheckedState] = useState({});

  const { confirm } = Modal;

  useEffect(() => {
    console.log('img')
    setDataModalEdit(getData)
    onChangeCheckboxAll(true)
  }, [getData, newDataLand])

  window.addEventListener('beforeunload', function () {
    localStorage.removeItem('viewAD');
  });

  // const onChangeBack = () => {
  //   changepage(page - 1)
  // }
  // const handleChangepage = () => {
  //   changepage(page + 1)
  // };
  const onChangeCheckbox = (es, e) => {
    // console.log("es", es.target.checked)
    // console.log("e", e)
    const newCheckedState = { ...checkedState };
    newCheckedState[e.id] = es.target.checked;
    setCheckedState(newCheckedState);
    if (es.target.checked === true) {
      adData.push(e)
    } else {
      for (const removedFile of adData.filter(file => es.target.checked === false)) {
        if (adData.some(img => img.idImage === removedFile.idImage)) {
          const updatedAllImg = adData.filter(img => !(img.idImage === removedFile.idImage));
          setAdData(updatedAllImg);
        }
      }
    }
  }
  const showConfirmAll = () => {
    confirm({
      title: 'คุณต้องการที่จะลบรูปภาพใช่หรือไม่...?',
      onOk() {
        allDeleteImg()
      },
      onCancel() {
      },
    });
  };
  const allDeleteImg = async () => {
    console.log("adData", adData)
    setLoading(true)
    if (adData?.length > 0) {
      if (checkType.car === "car") {
        const test2 = adData
        await axios.delete(deleteAllCar, { data: test2, })
          .then((res) => {
            if (res.status === 200) {
              console.log("car", res.data)
              setCheckedState({});
              setAdData([])
              sendB(dayjs())
              deleteData("top")
              setLoading(false)
            }
          }).catch((err) => DeleteError("top"))
      } else {
        const test2 = adData
        await axios.delete(deleteAllLand, { data: test2, })
          .then((res) => {
            if (res.status === 200) {
              console.log("land", res.data)
              setCheckedState({});
              setAdData([])
              sendB(dayjs())
              deleteData("top")
              setLoading(false)
            }
          }).catch((err) => DeleteError("top"))
      }
    } else {
      alert('กรุณาเลือกรูปภาพก่อน !!!');
    }
    setLoading(false)
  }

  const showConfirm = (e, index) => {
    confirm({
      title: 'คุณต้องการที่จะลบรูปภาพนี้ใช่หรือไม่...?',
      onOk() {
        if (checkType.car === "car") {
          handleDeleteCar(e, index)
        } else {
          handleDeleteLand(e, index)
        }
      },
      onCancel() {
      },
    });
  };

  const downloadImages = async () => {
    setLoading(true)
    const names = detail.firstName + detail.lastName
    if (checkedImages.length > 0) {
      // console.log("checkedImages",checkedImages)
      try {
        const zip = new JSZip();
        for (const image of checkedImages) {
          const folderName = setTypeImg(image);
          const folder = zip.folder(folderName); // สร้างโฟลเดอร์ใหม่ใน ZIP
          const response = await fetch(`${downloadImagess}/${image.pathImage}?token=${token}`);
          const blob = await response.blob();
          folder.file(`${image.idImage}.jpg`, blob);
        }
        // สร้างไฟล์ ZIP
        const zipBlob = await zip.generateAsync({ type: 'blob' });

        // บันทึกไฟล์ ZIP และดาวน์โหลด
        FileSaver.saveAs(zipBlob, `${names} ใบประเมิน,รูปรถ,สัญญาไฟแนนซ์เดิม,ใบเสร็จไฟแนนซ์เดิม.zip`);
      } catch (error) {
        console.error('Error downloading images:', error);
      }
    } else {
      alert('ไม่พบรูปภาพ');
    }
    setLoading(false)
  };

  const onChangeCheckboxAll = (isChecked) => {
    setLoading(true)
    if (detail?.car === "car") {
      if (isChecked) {
        setCb(true)
        if (getData?.length > 0) {
          var PreAaproveSplits = []
          var resImg = []
          getData?.map((item, index) => {
            PreAaproveSplits = item.pathImage.split("/")
            if (PreAaproveSplits[3] !== "13") {
              if (item.type === 1) {
                resImg.push(item)
              }
            }
          })
          setCheckedImages(resImg);
        } else {
          setCb(false)
          setCheckedImages([])
        }
      } else {
        setCb(false)
        setCheckedImages([])
      }
    } else {
      const newDataLandImgAll = []
      if (newDataLand?.length > 0) {
        newDataLand?.map((item, index) => {
          newDataLandImgAll.push(...item.img)
        })
      }
      if (isChecked) {
        setCb(true)
        if (newDataLandImgAll?.length > 0) {
          var PreAaproveSplits = []
          var resImg = []
          newDataLandImgAll?.map((item, index) => {
            PreAaproveSplits = item.pathImage.split("/")
            if (PreAaproveSplits[3] !== "13") {
              if (item.type === 1) {
                resImg.push(item)
              }
            }
          })
          setCheckedImages(resImg);
        } else {
          setCb(false)
          setCheckedImages([])
        }
      } else {
        setCb(false)
        setCheckedImages([])
      }
    }
    setLoading(false)
  };

  const handleDeleteCar = async (e, index) => {
    const id = e.idImage
    const path = e.pathImage
    setLoading(true)
    await axios.delete(`${deleteNewUpCar}/${id}/${path}`)
      .then(async res => {
        if (res.data) {

          const updatedImageBlobzz = [...dataModalEdit];
          updatedImageBlobzz.splice(index, 1);
          setDataModalEdit(updatedImageBlobzz);
          // deleteData("top")
          // setLoading(false)
          await handleNoti(path)
        }
      })
      .catch((err) =>
        DeleteError("top")
        // console.log(err)
      )
    setLoading(false)
  }

  const handleDeleteLand = async (e, index) => {
    const id = e.idImage
    const path = e.pathImage
    setLoading(true)
    await axios.delete(`${deleteNewUpLand}/${id}/${path}`)
      .then(async res => {
        if (res.data) {
          // console.log("WP", res.data)
          const updatedImageBlobzz = [...dataModalEdit];
          updatedImageBlobzz.splice(index, 1);
          setDataModalEdit(updatedImageBlobzz);
          // deleteData("top")
          // setLoading(false)
          await handleNoti(path)
        }
      })
      .catch((err) =>
        DeleteError("top")
        // console.log(err)
      )
    setLoading(false)
  }

  const handleNoti = async (path) => {
    const ez = { nameInput: un, path: path }
    await axios.post(storeDBLine, ez)
      .then(res => {
        if (res.data) {
          console.log("OK")
          deleteData("top")
          sendB(dayjs())
          setLoading(false)
        }
      })
      .catch((err) =>
        DeleteError("top")
        // console.log(err)
      )
    setLoading(false)
  }

  const renderLandBuildings = ({ item, index }) => {
    return (
      <>
        <Row justify={'left'}><b><u>รูปที่ดิน/สิ่งปลูกสร้าง-ที่ดินปัจจุบัน ({item.numberLand})</u></b></Row>
        <Space direction='vertical'>
          {item?.img?.map((e, index) => {
            var PreAaproveSplit = []
            PreAaproveSplit = e.pathImage.split("/")
            if (PreAaproveSplit[3] !== "13") {
              if (e.type === 1) {
                return (
                  <Space direction='vertical'>
                    <Checkbox disabled={checkSendNEW} checked={checkedState[e.id]} style={{ margin: 10 }} key={index} onChange={(es) => onChangeCheckbox(es, e)}>
                      <Image width={750} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                      <p><Button disabled={checkSendNEW} onClick={() => showConfirm(e, index)} type='primary' danger>ลบรูปภาพ</Button></p>
                    </Checkbox>
                  </Space>
                );
              }
            }
            return null;
          })}
        </Space>
        <Divider />
      </>
    )
  }

  return (
    <div>
      <Card style={{ padding: 20 }} className="modal-img">
        <Spin spinning={loading} size='large' tip=" Loading... ">
          <div style={{ textAlign: 'center' }}>
            <Row style={{ textAlign: 'center' }} >
              <Col span={24} style={{ fontSize: '30px' }} >ใบประเมิน / รูปรถ / สัญญาไฟแนนซ์เดิม / ใบเสร็จไฟแนนซ์เดิม</Col>
            </Row>
            <Row style={{ textAlign: 'right' }} >
              <Col span={24} style={{ fontSize: '30px' }} >
                <Space>
                  {/* <Checkbox defaultChecked={true} disabled style={{ margin: 10 }} onChange={(es) => onChangeCheckboxAll(es.target.checked)}>
                    เลือกรูปหน้าคู่มือ/โฉนด
                  </Checkbox> */}
                  <Button disabled={checkSendNEW} type='primary' danger onClick={showConfirmAll}>ลบรูปภาพที่เลือก</Button>
                  <Button style={{ background: '#bfbfbf', color: '#000000', }} onClick={downloadImages}>ดาวน์โหลดรูปภาพ</Button>
                </Space>
              </Col>
            </Row>
            <Divider></Divider>
            <Form
              form={form}
            >
              <Row>
                <Col span={24}>
                  <Image.PreviewGroup>
                    {
                      detail?.car === "car" ?
                        <>
                          {dataModalEdit?.map((e, index) => {
                            var PreAaproveSplit = []
                            PreAaproveSplit = e.pathImage.split("/")
                            if (e && e.pathImage) {
                              if (e.type === 1) {
                                if (PreAaproveSplit[3] !== "13") {
                                  return (
                                    <div key={index}>
                                      <Row justify={'center'} style={{ fontSize: '20px' }}>
                                        <b><u></u></b>
                                      </Row>
                                      <Row justify={'center'}>
                                        <Space direction='vertical'>
                                          <Checkbox disabled={checkSendNEW} checked={checkedState[e.id]} style={{ margin: 10 }} key={index} onChange={(es) => onChangeCheckbox(es, e)}>
                                            <Image
                                              width={750}
                                              src={`${getImagess}/${e.pathImage}?token=${token}`}
                                              alt={`รูปภาพ ${index}`} />
                                            <p><Button disabled={checkSendNEW} onClick={() => showConfirm(e, index)} type='primary' danger>ลบรูปภาพ</Button></p>
                                          </Checkbox>
                                        </Space>
                                      </Row>
                                    </div>
                                  )
                                }
                              }
                            } else {
                              return null
                            }
                          })}
                        </>
                        :
                        <>
                          {
                            newDataLand?.map((item, index) => {
                              return renderLandBuildings({ item, index, key: `{item.identification} - ${index}` })
                            })
                          }
                        </>
                    }
                  </Image.PreviewGroup>
                </Col>
              </Row>
              <Divider></Divider>
              {/* <div style={{ textAlign: "center" }} >
                <Space>
                  <Button type="primary" style={{ background: '#bfbfbf', color: '#000000', }} onClick={onChangeBack}>ย้อนกลับ</Button>
                  <Button type="primary" onClick={handleChangepage}>
                    ต่อไป
                  </Button>
                </Space>
              </div> */}
            </Form>
          </div>
        </Spin>
      </Card>
    </div>
  )
}
