import { createSlice } from "@reduxjs/toolkit";

const initialState = {

}

const addressDataSlice = createSlice ({
    name: "address",
    initialState,
    reducers: {
        
        addAddress: (state, action) => {
            state.data = action.payload
            // console.log(current(state))
        },
        
    }
    
})

export const { addAddress } = addressDataSlice.actions
export default addressDataSlice.reducer