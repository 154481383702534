import './Index.css';
import { Modal, Row, Select, Col, Form, Button, Space, notification, Typography, Spin } from "antd";
import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from 'react-router-dom';
import { valueOrDefault } from 'chart.js/helpers';
import { updaterigth } from '../../file_mid/all_api';

const { Option } = Select;
const { Text } = Typography;

const SetPermissions = ({ open, close, member, updateMember }) => {
    const { id } = useParams();
    const [formData, setFormData] = useState({ ...member })
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [role, setRole] = useState([]);
    const [api, contextHolder] = notification.useNotification();


    useEffect(() => {
        getRole();
    }, []);

    const openNotification = (placement) => {
        api.info({
            message: 'บันทึกสำเร็จ',
            description: 'แก้ไขสิทธิ์ผู้ใช้สำเร็จ.',
            placement,
        });
    };

    const upDateRight = async () => {
        await axios.put(`${updaterigth}/${member.id}`, formData)
            .then(response => {
                // updateMember(response.data);
                // console.log('response.data', response.data)
                if (response.data === 'ok') {
                    openNotification('top');
                    setTimeout(() => {
                        close(false);
                    }, 2000)
                }
            })
            .catch(error => {
                console.error('Error updating user:', error);
            });
    }

    const getRole = async () => {
        setLoading(true);

        await axios.get('https://api-mongodb-i67f6gaaqa-as.a.run.app/roles')
            .then(res => {
                setRole(res.data);
                setLoading(false);
            })
            // console.log('response', response.data)
            .catch(error => {
                console.error('Error fetching users:', error);
                setLoading(false);
            })
    };

    const handleOk = async () => {
        setConfirmLoading(true);
        await upDateRight();
        setConfirmLoading(false);
        // close(false);
    }

    const handleChange = (value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            role_id: value
        }))
    }

    const handleCancel = () => {
        close(false);
    }

    return (
        <>
            {contextHolder}

            <Modal
                open={open}
                title="กำหนดสิทธิ์"
                onCancel={handleCancel}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                footer={null}
                width={600}

            >
                <Spin spinning={loading} size='large' tip=' loading... '>
                    <Row>
                        <Col span={24}>
                            <Row justify={'center'}>
                                <Form.Item label="Username" name="username" style={{ textAlign: 'center' }}>
                                    <Text>{formData.username}</Text>
                                    {/* <Text>{`${formData.name} ${formData.lastname}`}</Text> */}
                                </Form.Item>
                            </Row>
                            <Row justify={'center'}>
                                <Form.Item label="ชื่อ-นามสกุล" name="name" style={{ textAlign: 'center' }}>
                                    {/* <Text>{formData.username}</Text> */}
                                    <Text>{`${formData.name} ${formData.lastname}`}</Text>
                                </Form.Item>
                            </Row>
                            <Row justify={"center"}>
                                <Col span={18}>
                                    <Form.Item label="เลือกสิทธิ์">
                                        <Select
                                            placeholder="เลือกสิทธิ์"
                                            value={formData.role_id}
                                            name="role_id"
                                            className="input"
                                            onChange={handleChange}
                                            style={{ width: '100%' }}
                                        >
                                            {role.map(key => (
                                                <Option value={key.role_id}>{key.role_name}</Option>
                                            ))}

                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24}>
                            <Row justify={"end"}>
                                <Space>
                                    <Button
                                        key="cancel"
                                        className='button-cancel'
                                        onClick={handleCancel}
                                    >
                                        ยกเลิก
                                    </Button>
                                    <Button
                                        key="save"
                                        className='button-save'
                                        type='submit'
                                        onClick={handleOk}
                                    >
                                        บันทึก
                                    </Button>
                                </Space>
                            </Row>
                        </Col>
                    </Row>
                </Spin>
            </Modal>
        </>
    )
}
export default SetPermissions;