  // const dotaCase = [
  //   {
  //     value: "รับจำนองที่ดิน",
  //     label: "รับจำนองที่ดิน",
  //   },
  //   {
  //     value: "รับจำนองที่ดินพร้อมสิ่งปลูกสร้าง",
  //     label: "รับจำนองที่ดินพร้อมสิ่งปลูกสร้าง",
  //   },
  //   {
  //     value: "ที่ดินว่างเปล่า",
  //     label: "ที่ดินว่างเปล่า",
  //   },
  //   {
  //     value: "รับจำนองที่ดินพร้อมสิ่งปลูกสร้างที่มีอยู่ หรือที่จะเกิดขึ้นในอนาคต",
  //     label: "รับจำนองที่ดินพร้อมสิ่งปลูกสร้างที่มีอยู่ หรือที่จะเกิดขึ้นในอนาคต",
  //   },
  // ];
  const dotaCase = [ // กาแฟ แก้ไข 16/07/24
    {
      value: "รับจำนองที่ดิน",
      label: "รับจำนองที่ดิน",
    },
    {
      value: "รับจำนองที่ดินพร้อมสิ่งปลูกสร้าง",
      label: "รับจำนองที่ดินพร้อมสิ่งปลูกสร้าง",
    },
    // {
    //   value: "ที่ดินว่างเปล่า",
    //   label: "ที่ดินว่างเปล่า",
    // },
    {
      value: "รับจำนองที่ดินไม่มีสิ่งปลูกสร้าง",
      label: "รับจำนองที่ดินไม่มีสิ่งปลูกสร้าง",
    },
    // {
    //   value: "รับจำนองที่ดินพร้อมสิ่งปลูกสร้างที่มีอยู่ หรือที่จะเกิดขึ้นในอานคต",
    //   label: "รับจำนองที่ดินพร้อมสิ่งปลูกสร้างที่มีอยู่ หรือที่จะเกิดขึ้นในอานคต",
    // },
  ];
export default dotaCase;