import React, { useState, useEffect } from 'react'
import { Modal, Row, Spin, Radio, Col } from 'antd'
import axios from 'axios';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { addCar } from '../../../redux/Car';
import EditLand from "./edit_land/EditLand";
import Conclusion from "./edit_land/Conclusion";
import { alldataland1, loadDataReLand } from '../../file_mid/all_api';
import UploadImgResend from './edit_land/UploadImgResend';

export default function ModalEdit({ open, close, data }) {
    const { confirm } = Modal
    const dispatch = useDispatch()
    const [count, setCount] = useState(0)
    const [loading, setLoading] = useState(false)
    const [dataCont, setDataCont] = useState()
    const [dataRe, setDataRe] = useState()
    const [noteLandRe, setNoteLandRe] = useState([]) // เก็บโน๊ต
    const [sendbackRE, setSendbackRE] = useState(dayjs())

    useEffect(() => {
        loadDataReLands()
        loadDataContnoLand()
    }, [sendbackRE])


    const loadDataReLands = async () => {
        if (data?.reLoanId && data?.customerId && data?.landId) {
            var mainData = { "reLoanId": data?.reLoanId, "customerId": data?.customerId, "landId": data?.landId }
            setLoading(true)
            await axios.post(loadDataReLand, mainData)
                .then(async (res) => {
                    // console.log("loadDataContno", res.data)
                    setDataRe(res.data)
                    setNoteLandRe(res.data?.land?.landLoanDetailsRe.notesLandRe)
                    setLoading(false)
                }).catch((err) => {
                    console.log(err)
                    alert("ไม่พบข้อมูล !!!")
                    setLoading(false)
                })
        } else {
            alert("การโหลดข้อมูลมีปัญหา กรุณาปิดแล้วเปิดใหม่ครับ/ค่ะ")
        }
    }

    const loadDataContnoLand = async () => {
        setLoading(true)
        await axios.post(alldataland1, { CONTNO: data.ISSUNO })
            .then(async res => {
                if (res.data) {
                    console.log("loadDataContnoLand", res.data.Data1[0])
                    setDataCont(res.data?.Data1)
                    setLoading(false)
                }
            }).catch((err) => {
                console.log("err", err)
                setLoading(false)
            })
    }


    const showConfirmCancel = () => {
        confirm({
            title: 'คุณต้องการยกเลิก...?',
            onOk() {
                localStorage.removeItem('addLand');
                dispatch(addCar());
                close(false);
            },
            onCancel() {
            },
        });
    };

    const Onclose = () => {
        localStorage.removeItem('addLand');
        dispatch(addCar());
        close(false);
    };

    return (
        <div>
            <Modal title="แก้ไขข้อมูล" open={open} width={1100} footer={null} onCancel={showConfirmCancel}>
                <Spin tip="Loading..." spinning={loading} >
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <Radio.Group value={count} onChange={(e) => setCount(e.target.value)}>
                            <Radio.Button value={1}>เพิ่มรูปที่ดิน</Radio.Button>
                            <Radio.Button value={2}>รายละเอียดสินค้า</Radio.Button>
                            <Radio.Button value={3}>สรุปรายละเอียด</Radio.Button>
                        </Radio.Group>
                    </Col>
                    <Row>
                        <Col span={24}>
                            {count === 1 && (<UploadImgResend close={showConfirmCancel} changepage={setCount} page={count} data={data} dataRe={dataRe} dataCont={dataCont} sendback={setSendbackRE} />)}
                            {count === 2 && (<EditLand close={showConfirmCancel} changepage={setCount} page={count} data={data} dataRe={dataRe} dataCont={dataCont} sendback={setSendbackRE} />)}
                            {count === 3 && (<Conclusion close={showConfirmCancel} closeAll={Onclose} changepage={setCount} page={count} dataRe={dataRe} noteLandRes={noteLandRe} />)}
                        </Col>
                    </Row>
                </Spin>
            </Modal>
        </div >
    )
}
