import React, { useState, useEffect } from 'react'
import { Button, Form, Input, Select, Divider, Row, Col, Modal, Checkbox, Space, Spin } from 'antd';
import { Option } from 'antd/es/mentions';
import axios from 'axios';
import { getocc, newgetsection, newgetsub, newupdatecustomerocc } from '../../../../file_mid/all_api';

export default function EditOcc({ open, close, dataindex, shootdata, fucnEdit }) {
    const [loading, setLoading] = useState(false)
    const [occupation, setOccupation] = useState([]) // getdatabase occ
    const [sub, setSub] = useState([]) // getdatabase sub
    const [section, setSection] = useState([]) // getdatabase section

    const [form] = Form.useForm();
    const [getData, setGetData] = useState({ ...shootdata.dataedit });
    const [getIndex, setGetIndex] = useState(dataindex);
    const [count, setCount] = useState({ ...shootdata.dataedit })

    // console.log("getss", count)


    useEffect(() => {
        setGetData({ ...shootdata.dataedit })
        setGetIndex(dataindex)
        form.setFieldsValue(
            {
                ...getData,
                // ...getData.income,
                // ...getData.income.expenses
            }
        )
        loadOccupation()
    }, [shootdata])

    useEffect(() => {
        if (getData) {
            // loadSubOccupation(count.occupationalGroup)
            // loadOccupationSection(count.governmentPrivate)
            bpSubOccupation(count?.occupationalGroup)
            bpOccupationSection(count?.governmentPrivate)
        }
    }, [])

    // console.log("getData", getData)

    const success = () => {
        Modal.success({
            title: 'บันทึกสำเร็จ',
            content: 'แก้ไขข้อมูลเรียบร้อย',
        })
    }
    const error = () => {
        Modal.error({
            title: 'บันทึกไม่สำเร็จ',
            content: 'กรุณาตรวจสอบข้อมูลอีกครั้ง',
        })
    }

    //elements
    const handleCancel = () => { //กดปิด Modal
        close(false);
    };
    //console.log("getData", getData)
    //handleSubmit
    const handleSubmit = async () => {
        setLoading(true)
        const result = {
            customer: { customerId: getData?.customerId },
            ojs: {
                occupationJobPositionId: getData?.occupationJobPositionId,
                occupationalGroup: getData?.occupationalGroup,
                governmentPrivate: getData?.governmentPrivate,
                jobPosition: getData?.jobPosition,
                companyName: getData?.companyName,
                occupationDescription: getData?.occupationDescription,
                mainOccupation: getData?.mainOccupation,
                idPljob: getData?.idPljob,
                idPloccupation: getData?.idPloccupation,
                idArgroup: getData?.idArgroup
            },
            income: {
                //incomesMainId: getData?.income?.incomesMainId,
                incomesMainId: getData?.incomesMainId,
                incomeMonth: getData?.incomeMonth,
                incomeMainDescription: getData?.incomeMainDescription
            },
            expenses: {
                //expensesId: getData?.income?.expenses?.expensesId,
                expensesId: getData?.expensesId,
                expensesMonth: getData?.expensesMonth,
                expensesMonthDescription: getData?.expensesMonthDescription
            }
        }
        //console.log("result: ", result);

        await axios.post(newupdatecustomerocc, result)
            .then((res) => {
                //console.log("rr", res.data)
                setLoading(false)
                fucnEdit({ getData, getIndex })
                success()
                close(false);
            })
            .catch((err) => error())
        setLoading(false)
        // close(false);
    }
    // Data Select Form
    const loadOccupation = async () => {
        setLoading(true)
        await axios.get(getocc)
            .then((res) => {
                setOccupation(res.data)
                setLoading(false)
            })
            .catch((err) => console.log(err))
        setLoading(false)
    }

    const loadSubOccupation = async (value) => {
        //console.log("sub = ", value)
        if (Number.isInteger(value)) {
            // ทำอะไรกับค่าจำนวนเต็ม
            const idOccupation = { idOccupation: value }
            setLoading(true)
            await axios.post(newgetsub, idOccupation)
                .then((res) => {
                    setSub(res.data)
                    setLoading(false)
                    form.setFieldsValue(
                        {
                            governmentPrivate: "",
                            jobPosition: "",
                            companyName: "",
                        }
                    )
                })
                .catch((err) => console.log(err))
            setLoading(false)
        } else {
            // ทำอะไรกับค่าอื่น ๆ
            const desc = { desc: value } // ชื่อไทย
            setLoading(true)
            await axios.post(newgetsub, desc)
                .then((res) => {
                    setSub(res.data)
                    setLoading(false)
                    form.setFieldsValue(
                        {
                            governmentPrivate: "",
                            jobPosition: "",
                            companyName: "",
                        }
                    )
                })
                .catch((err) => console.log(err))
            setLoading(false)
        }
    }

    const loadOccupationSection = async (value) => {
        //console.log("section = ", value)
        if (Number.isInteger(value)) {
            const idSub = { idSub: value }
            setLoading(true)
            await axios.post(newgetsection, idSub)
                .then((res) => {
                    setSection(res.data)
                    setLoading(false)
                    form.setFieldsValue(
                        {            
                            jobPosition: "",
                            companyName: "",
                        }
                    )
                })
                .catch((err) => console.log(err))
            setLoading(false)
        } else {
            // ทำอะไรกับค่าอื่น ๆ
            const desc = { descSub: value } // ชื่อไทย
            setLoading(true)
            await axios.post(newgetsection, desc)
                .then((res) => {
                    setSection(res.data)
                    setLoading(false)
                    form.setFieldsValue(
                        {
                            jobPosition: "",
                            companyName: "",
                        }
                    )
                })
                .catch((err) => console.log(err))
            setLoading(false)
        }
    }
    /// bp
    const bpSubOccupation = async (value) => {
        //console.log("sub = ", value)
        if (Number.isInteger(value)) {
            // ทำอะไรกับค่าจำนวนเต็ม
            const idOccupation = { idOccupation: value }
            setLoading(true)
            await axios.post(newgetsub, idOccupation)
                .then((res) => {
                    setSub(res.data)
                    setLoading(false)
                })
                .catch((err) => console.log(err))
            setLoading(false)
        } else {
            // ทำอะไรกับค่าอื่น ๆ
            const desc = { desc: value } // ชื่อไทย
            setLoading(true)
            await axios.post(newgetsub, desc)
                .then((res) => {
                    setSub(res.data)
                    setLoading(false)
                })
                .catch((err) => console.log(err))
            setLoading(false)
        }
    }

    const bpOccupationSection = async (value) => {
        //console.log("section = ", value)
        if (Number.isInteger(value)) {
            const idSub = { idSub: value }
            setLoading(true)
            await axios.post(newgetsection, idSub)
                .then((res) => {
                    setSection(res.data)
                    setLoading(false)
                })
                .catch((err) => console.log(err))
            setLoading(false)
        } else {
            // ทำอะไรกับค่าอื่น ๆ
            const desc = { descSub: value } // ชื่อไทย
            setLoading(true)
            await axios.post(newgetsection, desc)
                .then((res) => {
                    setSection(res.data)
                    setLoading(false)
                })
                .catch((err) => console.log(err))
            setLoading(false)
        }
    }

    const getOccupation = (value) => {
        //console.log("value", {occupation: occupation.find((e) => e.idOccupation === value).desc})
        //setGetData({ ...getData, occupationalGroup: occupation.find((e) => e.idOccupation === value).desc })
        setGetData({
            ...getData,
            governmentPrivate: "",
            jobPosition: "",
            companyName: "",
            occupationalGroup: value,
            //occupationalGroupTH: occupation.find((e) => e.idOccupation === value).desc
        })
        loadSubOccupation(value)
    }

    const getSub = (value) => {
        //console.log("value", value)
        //setGetData({ ...getData, governmentPrivate: sub.find((e) => e.idSub === value).descSub })
        setGetData({
            ...getData,
            jobPosition: 32,
            idPloccupation: 12,
            idArgroup: 11,
            companyName: "",
            governmentPrivate: value,
            //governmentPrivateTH: sub.find((e) => e.idSub === value).descSub,
            idPljob: sub.find((e) => e.idSub === value).idPljob
        })
        loadOccupationSection(value)
    }

    const getSection = (value) => {
        //console.log("value", value)
        //setGetData({ ...getData, jobPosition: section.find((e) => e.idSection === value).descSection })
        setGetData({
            ...getData,
            jobPosition: value,
            //jobPositionTH: section.find((e) => e.idSection === value).descSection,
            idPloccupation: section.find((e) => e.idSection === value).idPloccupation,
            idArgroup: section.find((e) => e.idSection === value).idArgroup
        })
    }

    return (
        <>
            {/* Modal แก้ไข้ */}
            <Modal
                open={open}
                onCancel={handleCancel}
                // style={{ background: '#2f54eb' }}
                centered
                width={1200}
                footer={[null]}>
                <Spin spinning={loading} size='large' tip=" Loading... ">
                    <Form
                        form={form}
                        name='editdata'
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                        style={{
                            maxWidth: '100%',
                        }}>
                        <Row>
                            <Col span={24} style={{ fontSize: '30px', textAlign: 'center' }} ><u>แก้ไขอาชีพและรายได้</u></Col>
                        </Row>
                        <Divider />
                        <Row gutter={24}>
                            <Col className="gutter-row" span={12}>
                                <Form.Item label='กลุ่มอาชีพ' name='occupationalGroup'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <Select
                                        placeholder={'เลือก'}
                                        onChange={getOccupation}
                                    >
                                        {occupation?.map((item) => {
                                            return (
                                                <Select.Option key={item.idOccupation} value={item.idOccupation}>
                                                    {item.desc}
                                                </Select.Option>
                                            )
                                        })}

                                    </Select>
                                </Form.Item>

                                <Form.Item label='เลือกอาชีพ' name='governmentPrivate'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <Select
                                        placeholder={'เลือก'}
                                        onChange={getSub}
                                    >
                                        {sub?.map((item) => {
                                            return (
                                                <Select.Option key={item.idSub} value={item.idSub}>
                                                    {item.descSub}
                                                </Select.Option>
                                            )
                                        })}

                                    </Select>
                                </Form.Item>

                                <Form.Item label='สาขาอาชีพ' name='jobPosition'>
                                    <Select
                                        placeholder={'เลือก'}
                                        onChange={getSection}
                                    >
                                        {section?.map((item) => {
                                            return (
                                                <Select.Option key={item.idSection} value={item.idSection}>
                                                    {item.descSection}
                                                </Select.Option>
                                            )
                                        })}

                                    </Select>
                                </Form.Item>

                                <Form.Item label='ชื่อบริษัท สถานที่ทำงาน' name='companyName'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <Input type='text'
                                        onChange={(e) => setGetData({ ...getData, companyName: e.target.value })}></Input>
                                </Form.Item>

                                <Form.Item label='หมายเหตุ' name='occupationDescription' >
                                    <Input type='text'
                                        onChange={(e) => setGetData({ ...getData, occupationDescription: e.target.value })}></Input>
                                </Form.Item>

                                {/* <Form.Item label='เป็นอาชีพหลักหรือไม่' name='mainOccupation' >
                                    <Checkbox defaultChecked={true} onChange={(e) => setGetData({ ...getData, mainOccupation: e.target.checked })}>ใช่</Checkbox>
                                </Form.Item> */}
                                {/* <Divider /> */}
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Form.Item label='รายได้ต่อเดือน' name='incomeMonth'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <Input type='text' suffix='บาท'
                                        onChange={(e) => setGetData({ ...getData, incomeMonth: parseInt(e.target.value) })}></Input>
                                </Form.Item>

                                {/* <Form.Item label='อื่นๆ' name='note' >
                                    <Input type='text' suffix='บาท'
                                        onChange={(e) => setGetData({ ...getData, note: parseInt(e.target.value) })}></Input>
                                </Form.Item> */}

                                <Form.Item label='หมายเหตุ' name='incomeMainDescription' >
                                    <Input type='text'
                                        onChange={(e) => setGetData({ ...getData, incomeMainDescription: e.target.value })}></Input>
                                </Form.Item>

                                {/* <Form.Item label='รายได้รวม / เดือน' name='totalIncomeMonth' >
                                        {currencyFormatOne(testM)} บาท
                                    </Form.Item>

                                    <Form.Item label='รายได้รวม / ปี' name='totalIncomeYear' >
                                        {currencyFormatOne(testY)} บาท
                                    </Form.Item> */}

                                <Form.Item label='ค่าใช้จ่ายต่อ / เดือน' name='expensesMonth'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <Input type='text' suffix='บาท'
                                        onChange={(e) => setGetData({ ...getData, expensesMonth: parseInt(e.target.value) })}></Input>
                                </Form.Item>

                                <Form.Item label='หมายเหตุ' name='expensesMonthDescription' >
                                    <Input type='text'
                                        onChange={(e) => setGetData({ ...getData, expensesMonthDescription: e.target.value })}></Input>
                                </Form.Item>

                                {/* <Form.Item label='หักค่าใช้จ่าย / เดือน' name='summaryOfExpenses' >
                                        {currencyFormatOne(testResult)} บาท
                                    </Form.Item> */}
                                
                            </Col>
                        </Row>
                        <Divider />
                        <Row>
                            <Col span={24} style={{ textAlign: 'center' }}>
                                <Space>
                                    <Button type="primary" onClick={handleCancel} style={{ width: 70, background: "red" }}>ยกเลิก</Button>
                                    <Button type="primary" htmlType="submit" style={{ width: 70, background: "green" }} onClick={handleSubmit} >บันทึก</Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal >
        </>
    )
}
