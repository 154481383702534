import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

const exportExcel = async ({
  dataApiCheckLocatCar,
  dataApiCheckLocatLand,
}) => {
  const workbook = new ExcelJS.Workbook();
  // สร้างแผ่นงานแรก
  const sheet1 = workbook.addWorksheet("สรุปยอดรวมรถตามสาขา");
  sheet1.columns = [
    { header: "ลำดับ", key: "key", width: 10 },
    { header: "สาขา", key: "locat", width: 20 },
    { header: "เคสผ่าน", key: "caseSuccess", width: 10 },
    { header: "เคสไม่จบ", key: "caseFail", width: 10 },
    { header: "REJECT/ไม่ผ่าน", key: "caseReject", width: 15 },
    { header: "รวมส่งมาเคาะ/ สาขา", key: "caseAll", width: 20 },
    { header: "PRE", key: "casePre", width: 10 },
    { header: "คิดเป็น %", key: "casePercent", width: 10 },
  ];
  // เพิ่มลำดับให้กับข้อมูล dataApiCheckLocatCar
  const dataWithKey1 = dataApiCheckLocatCar.map((item, index) => ({
    key: index + 1, // เริ่มนับจาก 1
    ...item,
  }));
  // เพิ่มข้อมูลลงใน sheet
  sheet1.addRows(dataWithKey1);
  // คำนวณผลรวม
  const totalCaseSuccess1 = dataApiCheckLocatCar.reduce((sum, row) => sum + row.caseSuccess, 0);
  const totalCaseFail1 = dataApiCheckLocatCar.reduce((sum, row) => sum + row.caseFail, 0);
  const totalCaseReject1 = dataApiCheckLocatCar.reduce((sum, row) => sum + row.caseReject, 0);
  const totalCaseAll1 = dataApiCheckLocatCar.reduce((sum, row) => sum + row.caseAll, 0);
  const totalCasePre1 = dataApiCheckLocatCar.reduce((sum, row) => sum + row.casePre, 0);
  // เพิ่มแถวผลรวม
  sheet1.addRow({
    key: "รวม",
    locat: "",  // ถ้าไม่ต้องการใส่อะไรในช่องนี้
    caseSuccess: totalCaseSuccess1,
    caseFail: totalCaseFail1,
    caseReject: totalCaseReject1,
    caseAll: totalCaseAll1,
    casePre: totalCasePre1,
  });
  // สร้างแผ่นงานที่สอง
  const sheet2 = workbook.addWorksheet("สรุปยอดรวมที่ดินตามสาขา");
  sheet2.columns = [
    { header: "ลำดับ", key: "key", width: 10 },
    { header: "สาขา", key: "locat", width: 20 },
    { header: "เคสผ่าน", key: "caseSuccess", width: 10 },
    { header: "เคสไม่จบ", key: "caseFail", width: 10 },
    { header: "REJECT/ไม่ผ่าน", key: "caseReject", width: 15 },
    { header: "รวมส่งมาเคาะ/ สาขา", key: "caseAll", width: 20 },
    { header: "PRE", key: "casePre", width: 10 },
    { header: "คิดเป็น %", key: "casePercent", width: 10 },
  ];
  // เพิ่มลำดับให้กับข้อมูล dataApiCheckLocatCar
  const dataWithKey2 = dataApiCheckLocatLand.map((item, index) => ({
    key: index + 1, // เริ่มนับจาก 1
    ...item,
  }));
  // เพิ่มข้อมูลลงใน sheet
  sheet2.addRows(dataWithKey2);
  // คำนวณผลรวม
  const totalCaseSuccess2 = dataApiCheckLocatLand.reduce((sum, row) => sum + row.caseSuccess, 0);
  const totalCaseFail2 = dataApiCheckLocatLand.reduce((sum, row) => sum + row.caseFail, 0);
  const totalCaseReject2 = dataApiCheckLocatLand.reduce((sum, row) => sum + row.caseReject, 0);
  const totalCaseAll2 = dataApiCheckLocatLand.reduce((sum, row) => sum + row.caseAll, 0);
  const totalCasePre2 = dataApiCheckLocatLand.reduce((sum, row) => sum + row.casePre, 0);
  // เพิ่มแถวผลรวม
  sheet2.addRow({
    key: "รวม",
    locat: "",  // ถ้าไม่ต้องการใส่อะไรในช่องนี้
    caseSuccess: totalCaseSuccess2,
    caseFail: totalCaseFail2,
    caseReject: totalCaseReject2,
    caseAll: totalCaseAll2,
     casePre: totalCasePre2,
  });
  
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  saveAs(blob, "รายงานผลการวิเคราะห์เคส.xlsx");
};

export default exportExcel;
