import React, { useEffect, useState } from 'react'
import { Modal, Col, Row, Space, notification, Steps } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addCustomer } from '../../../../redux/Customer';
import GAddress from './GAddress';
import GPhone from './GPhone';
import GCustomer from './GCustomer';
import GDetail from './GDetail';

export default function ModalAddGuarantor({ open, close, fucnAdd, getCarId, sendcarId }) {

    const { customers } = useSelector((state) => ({ ...state }))
    const [cusIdMain] = useState(customers.data); //มาจากรีดัค

    const [api, contextHolder] = notification.useNotification();
    const [count, setCount] = useState(1)
    const dispatch = useDispatch()
    const [current, setCurrent] = useState(0);
    const { confirm } = Modal


    const next = () => {
        setCurrent(current + 1);
    };
    const prev = () => {
        setCurrent(current - 1);
    };
    const steps = [
        {
            title: 'ข้อมูลส่วนตัว',
        },
        {
            title: 'รายละเอียดที่อยู่',
        },
        {
            title: 'ช่องทางการติดต่อ',
        },
        {
            title: 'สรุปรายละเอียด/บันทึกข้อมูล',
        },
    ];

    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
    }));

    useEffect(() => {
        loadData()

    }, [])

    const loadData = () => {
        // close(false);
    };

    const Onclose = () => {
        close(false);
        dispatch(addCustomer(cusIdMain))
    };

    const InsertSuccess = (placement) => {
        api.success({
            message: <b>เพิ่มข้อมูลสำเร็จ</b>,
            placement,
        });
    }
    const EditSuccess = (placement) => {
        api.success({
            message: <b>แก้ไขข้อมูลสำเร็จ</b>,
            placement,
        });
    }
    const DeleteSuccess = (placement) => {
        api.success({
            message: <b>ลบข้อมูลสำเร็จ</b>,
            placement,
        });
    }
    const InsertError = (placement) => {
        api.error({
            message: <b>เพิ่มข้อมูลไม่สำเร็จ</b>,
            placement,
        });
    }
    const CheckAddressER = (placement) => {
        api.error({
            message: <b>เพิ่มข้อมูลไม่สำเร็จ</b>,
            description: 'กรุณาเพิ่มที่อยู่ อย่างน้อย 1 ที่',
            placement,
        });
    }
    const CheckPhoneER = (placement) => {
        api.error({
            message: <b>เพิ่มข้อมูลไม่สำเร็จ</b>,
            description: 'กรุณาเพิ่มเบอร์โทรศัพท์ อย่างน้อย 1 เบอร์',
            placement,
        });
    }
    const CheckGuER = (placement) => {
        api.error({
            message: <b>เพิ่มข้อมูลไม่สำเร็จ</b>,
            description: 'พบข้อมูลซ้ำกันระหว่างผู้กู้และผู้ค้ำ กรุณาเปลี่ยนผู้ค้ำ',
            placement,
        });
    }

    const showConfirm = () => {
        confirm({
            title: 'คุณต้องการที่ปิดหน้าต่างข้อมูลผู้กู้หรือไม่...?',
            content: 'หากกดปิดหน้าต่างผู้กู้ข้อมูลจะไม่ถูกบันทึก กด OK เพื่อยืนยัน',
            onOk() {
                Onclose();
            },
            onCancel() {
            },
        });
    };

    return (
        <>
            <Modal title="เพิ่มข้อมูลผู้ค้ำ" open={open} onCancel={showConfirm} width={1200} footer={null} >
                <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                    <Row>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            {/* <Radio.Group value={count} onChange={(e) => setCount(e.target.value)}>
                                <Radio.Button value={1} >ข้อมูลส่วนตัว</Radio.Button>
                                <Radio.Button value={2} >รายละเอียดที่อยู่</Radio.Button>
                                <Radio.Button value={3} >ช่องทางการติดต่อ</Radio.Button>
                                <Radio.Button value={4} >สรุปรายละเอียด/บันทึกข้อมูล</Radio.Button>
                            </Radio.Group> */}
                            <Steps current={current} items={items} />
                        </Col>
                    </Row>
                    {count === 1 && (<GCustomer close={showConfirm} count1={count} setCount1={setCount} n1={InsertSuccess} n2={InsertError} next={next} prev={prev} chg={CheckGuER} cusIdMain={cusIdMain} sendcarId={sendcarId} />)}
                    {count === 2 && (<GAddress close={showConfirm} count={count} setCount={setCount} n1={InsertSuccess} e1={EditSuccess} d1={DeleteSuccess} cha={CheckAddressER} next={next} prev={prev} />)}
                    {count === 3 && (<GPhone close={showConfirm} count={count} setCount={setCount} n1={InsertSuccess} e1={EditSuccess} d1={DeleteSuccess} chp={CheckPhoneER} next={next} prev={prev} />)}
                    {count === 4 && (<GDetail close={showConfirm} saveclose={Onclose} count={count} setCount={setCount} n1={InsertSuccess} next={next} prev={prev} fucnAdd={fucnAdd} cusIdMain={cusIdMain} sendcarId={sendcarId} />)}
                </Space>
                {contextHolder}
            </Modal>
        </>
    )
};
