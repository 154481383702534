import React, { useEffect, useState } from 'react'
import { Form, Button, Modal, Card, Row, Col, Divider } from 'antd'
import dayjs from 'dayjs'
import axios from 'axios';
import { addCar } from '../../../redux/Car'
import { useDispatch, useSelector } from 'react-redux';
import { model1 } from '../../file_mid/all_api';

export default function RateCarModal({ open, close, priceData, cars1, close2, countoffer }) {
    const dispatch = useDispatch()
    const { cars } = useSelector((state) => ({ ...state })) //จาก redux
    const [car, setCar] = useState(cars1)
    // const [car, setCar] = useState(cars)
    const [status, setStatus] = useState(cars)
    const [price, setPrice] = useState(priceData)
    const [data, setData] = useState({})
    const newData = { ...data[0] }
    // console.log("Redux", cars)
    // console.log("Rate", countoffer)

    useEffect(() => {
        loadData(car)
        setCar(cars1)
        // setCar(cars)
        setPrice(priceData)
        setStatus({ productTypeCar: cars1.type_id, carBrand: cars1.brand })
        // setStatus({ productTypeCar: cars.type_id, carBrand: cars.brand })
    }, [])

    const loadData = async (value) => {
        await axios.get(model1 + value.model_te_id)
            .then((res) => {
                setData(res.data)
            })
            .catch((err) => console.log(err))

    }

    const handleCancel = () => {
        close(false)
    }
    const handleCancel2 = () => {
        close2(false)
    }


    const currencyFormat = (amount) => {
        return Number(amount)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }
    const enternumber = (event, k, v) => {
        const carPrice = v * 1000
        const carYear = parseInt(k.replace('p', ''))
        //console.log("เงิน",carPrice)
        //console.log("ปี",carYear)
        if (cars?.data) {
            var result = {
                ...car,
                carBaab: car.models,
                ...status, carPrice, carYear, carModel: newData.model,
                productTypeId: 1,
                carPriceStatus: true,
                carInput: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"),
                carDateStatus: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"),
                carProvince: cars.data.vehicle_license_province,    //จังหวัด
                carPlateNumber: cars.data.vehicle_license_number,   //เลขทะเบียน
                carChassisNumber: cars.data.carChassisNumber,       //เลขคัสซี
                carEngineNumber: cars.data.carEngineNumber,         //เลขเคคื่อง
                carColor: cars.data.carColor,                       //สี
            }
        } else {
            var result = {
                ...car,
                carBaab: car.models,
                ...status, carPrice, carYear, carModel: newData.model,
                productTypeId: 1,
                carPriceStatus: true,
                carInput: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"),
                carDateStatus: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"),
                carProvince: null,    //จังหวัด
                carPlateNumber: null,   //เลขทะเบียน
                carChassisNumber: null,       //เลขคัสซี
                carEngineNumber: null,         //เลขเคคื่อง
                carColor: null,                       //สี
            }
        }

        // const arr1 = delete result.active_status
        // const arr2 = delete result.brand
        // const arr3 = delete result.brand_id
        // const arr4 = delete result.brand_th
        // const arr5 = delete result.cc
        // const arr6 = delete result.idbrand
        // const arr7 = delete result.idmodel
        // const arr8 = delete result.model_te_id
        // const arr9 = delete result.models
        // const arr10 = delete result.note
        // const arr11 = delete result.type_id
        // console.log("CAR2", result)
        dispatch(addCar(result))
        if (countoffer === 1) {
            localStorage.setItem('addCar', JSON.stringify(result))
        }
        close(false)
    }
    return (
        <Row justify={'center'}>
            <Card style={{ width: '100%' }}>
                <Modal
                    open={open}
                    width={700}
                    onCancel={handleCancel2}
                    footer={[
                        <Button onClick={handleCancel2}
                            key="close">ยกเลิก</Button>
                    ]}
                >
                    <Form id="myForm"
                        name="basic"
                        // labelCol={{
                        //     span: 5,
                        // }}
                        wrapperCol={{
                            span: 24,
                        }}
                        style={{
                            maxWidth: 700,
                        }}
                        initialValues={{
                            remember: true,
                        }}

                    >
                        <Row gutter={32} justify={'center'}>
                            <Col className='gutter-row'>
                                <b><u><h3>ข้อมูล</h3></u></b>
                            </Col>
                        </Row>
                        <Row gutter={32}>
                            <Col className='gutter-row' span={24}>
                                <Form.Item label='รหัส' name="model_id" style={{ margin: 0 }}>
                                    <b>{price.model_id}</b>
                                </Form.Item>

                                <Form.Item label='แบบ' name="models" style={{ margin: 0 }}>
                                    <b>{car.models}</b>
                                </Form.Item>

                                {car.note ?
                                    <Form.Item label='หมายเหตุ' style={{ color: 'red', margin: 0 }} name="note" >
                                        <b>{car.note}</b>
                                    </Form.Item> : null
                                }

                                <Form.Item label='อัพเดดตล่าสุด เมื่อ' style={{ marginBottom: 0, marginTop: 15 }}>
                                    <b>{dayjs(price.timeprice).format('DD-MM-YYYY')}</b>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Card style={{ width: '100%' }}>
                            <Row gutter={32} style={{ marginBottom: 0 }}>
                                <Col className='gutter-row' span={8} style={{ textAlign: 'center' }}>
                                    <u><h4> ปี ค.ศ. : พ.ศ.</h4></u>
                                </Col>
                                <Col className='gutter-row' span={10} style={{ textAlign: 'center' }}>
                                    <u><h4> ราคา (บาท)</h4></u>
                                </Col>
                                <Col className='gutter-row' span={6}>

                                </Col>
                            </Row>
                            <Row gutter={32}>
                                <Col span={24} className='gutter-row' >
                                    {Object.entries(price).map(([k, v]) => {
                                        if (k.charAt(0) === 'p' && v) {
                                            const year = k.replace('p', '')
                                            return (
                                                <>
                                                    <Divider style={{ margin: 0 }} />
                                                    <Row gutter={32} style={{ margin: 5 }}>
                                                        <Col className='gutter-row' span={8} style={{ textAlign: 'center' }}>
                                                            ปี : <b>{year} : {~~year + 543}</b>
                                                        </Col>
                                                        <Col className='gutter-row' span={10} style={{ textAlign: 'center' }}>
                                                            ราคา :  <b>{currencyFormat(v * 1000)}</b>  บาท

                                                        </Col>
                                                        <Col className='gutter-row' span={6} style={{ textAlign: 'center' }}>
                                                            <Button onClick={(event) => { enternumber(event, k, v) }}>เลือก</Button>
                                                        </Col>
                                                    </Row>
                                                </>
                                            )
                                        }
                                    })}
                                </Col>
                            </Row>
                        </Card>
                    </Form>
                </Modal>
            </Card>
        </Row>
    )
}
