import React, { useState, useEffect } from 'react'
import { Form, Input, Row, Col, Button, Select, Divider, Space, Radio, DatePicker, Spin, Card } from 'antd'
import axios from 'axios'
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { addCustomer } from '../../../redux/Customer';
import { editLand } from '../../file_mid/all_api';

export default function EditCustomer({ onCancel, changepage, notification, notification2 }) {

    const dispatch = useDispatch()
    const customers = useSelector(state => state.customers)
    const [dataModalEdit, setDataModalEdit] = useState({})
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false)
    const [sell, setSell] = useState(true)
    const [holds, setHolds] = useState();
    const [typeCompany, setTypeCompany] = useState('หจก.');
    const [typeCompanyed, setTypeCompanyed] = useState('หจก.');

    useEffect(() => {
        if (customers.data) {
            setDataModalEdit(customers.data)
            setHolds(customers.data?.nationalId)
            form.setFieldsValue(
                {
                    ...customers.data,
                    birthdate: dayjs(customers?.data?.birthdate),
                    issudt: dayjs(customers?.data?.issudt),
                    expdt: dayjs(customers?.data?.expdt),
                }
            )
            if (customers.data.productLoanLandType === "Pre-Aaprove" && customers.data.loanAmounttLand === 0) {
                setSell(false)
            }
        }
    }, [])

    const onChangeGo = () => {
        changepage('2')
    }
    const handleCancel = () => {
        onCancel(false);
    }

    const handleSubmit = async () => {
        dispatch(addCustomer(dataModalEdit))
        await axios.put(editLand, dataModalEdit)
            .then(res => {
                if (res.status === 200) {
                    notification('top')
                    setTimeout(() => {
                        changepage('2')
                    }, 1000)
                } else {
                    notification2('top')
                }
            }).catch((err) => console.log(err))
    };

    const handleProduct = (value) => {
        setHolds(value)
        setDataModalEdit({ ...dataModalEdit, nationalId: value })
    }
    const handletypeCompany = (value) => {
        setTypeCompany(value)
        setDataModalEdit({ ...dataModalEdit, snam: value })
    }
    const handletypeCompanyed = (value) => {
        setTypeCompanyed(value)
        setDataModalEdit({ ...dataModalEdit, lastname: value })
    }
    const inputTaxId = (e) => {
        //setStep(1)
        setDataModalEdit({ ...dataModalEdit, identificationId: e.target.value })
        // isThaiNationalID(e.target.value)
    }

    return (
        <div>
            <Row justify={'center'}>
                <Card style={{ width: '900px' }}>
                    <Spin spinning={loading} size='large' tip=" Loading... ">
                        <Divider orientation="center" plain>
                            <h3>แก้ไขข้อมูลส่วนตัว</h3>
                        </Divider>
                        <Form
                            form={form}
                            labelCol={{
                                span: 12,
                            }}
                            wrapperCol={{
                                span: 24,
                            }}
                            onFinish={handleSubmit}>
                            <Row gutter={32} justify={'center'}>
                                <Col span={24}>
                                    <Row justify={'center'}>

                                        <Form.Item label='ประเภท' name='nationalId'
                                            style={{ textAlign: 'center', width: '300px' }}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input !',
                                                },]}>
                                            <Select
                                                disabled
                                                placeholder={'เลือก'}
                                                style={{ width: '250px', height: '40px', }}
                                                defaultValue={'บุคคลธรรมดา'}
                                                name='legal'
                                                onChange={(value) => handleProduct(value)}
                                                options={[
                                                    {
                                                        label: 'บุคคลธรรมดา',
                                                        value: 'บุคคลธรรมดา',
                                                    },
                                                    {
                                                        label: 'นิติบุคคล',
                                                        value: 'นิติบุคคล',
                                                    },
                                                ]}
                                            />
                                        </Form.Item>

                                    </Row>
                                    {holds === "นิติบุคคล" ?
                                        <>
                                            <Row className='main2' justify={'center'}>
                                                <Col span={24}>
                                                    <aside style={{ width: '85%' }}>
                                                        <div>
                                                            <Form.Item label='ประเภทห้าง' name='snam'
                                                                style={{ textAlign: 'center', width: '300px' }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !',
                                                                    },]}>
                                                                <Select
                                                                    placeholder={'เลือก'}
                                                                    // style={{ width: '250px', height: '40px', }}
                                                                    style={{ width: '250px', height: '40px', marginRight: '5px', marginLeft: '5px', color: 'black' }}
                                                                    name='snam'
                                                                    initialvalues={1}
                                                                    onChange={(value) => handletypeCompany(value)}
                                                                    options={[
                                                                        {
                                                                            label: 'หจก.',
                                                                            value: 'หจก',
                                                                        },
                                                                        {
                                                                            label: 'บริษัท',
                                                                            value: 'บริษัท',
                                                                        },
                                                                    ]}
                                                                />
                                                            </Form.Item>
                                                        </div>

                                                        <div>
                                                            <Form.Item
                                                                label='เลขประจำตัวผู้เสียภาษี'
                                                                // name='taxID'
                                                                name='identificationId'
                                                                // style={{ width: '300px' }}
                                                                // style={{ textAlign: 'left', width: '300px' }}
                                                                style={{ textAlign: 'center', width: '300px' }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !',
                                                                    },]}>

                                                                <Input disabled type='text' maxLength={13}
                                                                    name='identificationId'
                                                                    // disabled={CheckIdCard ? false : true}
                                                                    style={{ width: '250px', marginRight: '5px', marginLeft: '5px', color: 'black' }}
                                                                    //  onChange={(e) => setDataPost({ ...dataPost, taxID: e.target.value }, isThaiNationalID(e.target.value))}></Input>
                                                                    onChange={(e) => inputTaxId(e)}></Input>
                                                            </Form.Item>

                                                        </div>

                                                        {/* {typeCompany } */}
                                                        <div>
                                                            <Form.Item label='ชื่อบริษัท' name='firstname'
                                                                style={{ textAlign: 'center', width: '300px' }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !',
                                                                    },]}
                                                            >
                                                                <Input type='text'
                                                                    style={{ width: '250px', color: 'black' }}
                                                                    onChange={(e) => setDataModalEdit({ ...dataModalEdit, firstname: e.target.value })}>

                                                                </Input>

                                                            </Form.Item>
                                                        </div>
                                                        <div>
                                                            <Form.Item label='' name='lastname'
                                                                // style={{ textAlign: 'left', width: '250px' }}
                                                                style={{ textAlign: 'center', width: '300px' }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !',
                                                                    },]}
                                                            >
                                                                <Select
                                                                    placeholder={'เลือก'}
                                                                    style={{ textAlign: 'left', height: '40px', width: '250px' }}
                                                                    name='lastname'
                                                                    initialvalues={'จำกัด'}
                                                                    onChange={(value) => handletypeCompanyed(value)}
                                                                    options={[
                                                                        {
                                                                            label: 'มหาชน',
                                                                            value: 'มหาชน',
                                                                        },
                                                                        {
                                                                            label: 'จำกัด',
                                                                            value: 'จำกัด',
                                                                        },
                                                                    ]}
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                        <div>
                                                            <Form.Item label='วันจดทะเบียน' name='birthdate'
                                                                style={{ textAlign: 'center', width: '300px' }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !',
                                                                    },]}>
                                                                <DatePicker format={'YYYY-MM-DD'}
                                                                    style={{ height: '40px', width: '250px', color: 'black' }}
                                                                    onChange={(e) => {
                                                                        if (e) {
                                                                            setDataModalEdit({ ...dataModalEdit, birthdate: e.format('YYYY-MM-DD') })
                                                                        }
                                                                    }} />
                                                            </Form.Item>
                                                        </div>
                                                    </aside>
                                                </Col>
                                            </Row>
                                        </>
                                        :
                                        <>
                                            <Row className='main2' justify={'center'}>
                                                <Col span={24} style={{ textAlign: 'center', marginLeft: '15px' }}>
                                                    <aside style={{ width: '85%' }}>
                                                        <Form.Item label='เลขประจำตัวประชาชน' name='identificationId' style={{ width: '300px' }}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !',
                                                                },]}>
                                                            <Input type='text' disabled style={{
                                                                width: '250px',
                                                                color: 'black'
                                                            }} onChange={e => setDataModalEdit({ ...dataModalEdit, identificationId: e.target.value })} />
                                                        </Form.Item>
                                                        <Form.Item label='คำนำหน้าชื่อ' name='snam' style={{ width: '300px' }}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !',
                                                                },]}>
                                                            <Radio.Group disabled={!sell} style={{ width: '300px' }}
                                                                onChange={(e) => setDataModalEdit({ ...dataModalEdit, snam: e.target.value })} >
                                                                <Radio value="นาย"> นาย </Radio>
                                                                <Radio value="นาง"> นาง </Radio>
                                                                <Radio value="นางสาว"> นางสาว </Radio>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                        <Form.Item label='ชื่อ' name='firstname' style={{ width: '300px' }}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !',
                                                                },]}>
                                                            <Input type='text' disabled={!sell} style={{
                                                                width: '250px',
                                                            }}
                                                                onChange={e => setDataModalEdit({ ...dataModalEdit, firstname: e.target.value })} />
                                                        </Form.Item>
                                                        <Form.Item label='นามสกุล' name='lastname' style={{ width: '300px' }}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !',
                                                                },]}>
                                                            <Input disabled={!sell} style={{
                                                                width: '250px',
                                                            }}
                                                                type='text' onChange={e => setDataModalEdit({ ...dataModalEdit, lastname: e.target.value })} />
                                                        </Form.Item>
                                                        <Form.Item label='ชื่อเล่น' name='nickname' style={{ width: '300px' }}>
                                                            <Input disabled={!sell} style={{
                                                                width: '250px',
                                                                height: '40px'
                                                            }}
                                                                type='text' onChange={e => setDataModalEdit({ ...dataModalEdit, nickname: e.target.value })} />
                                                        </Form.Item>
                                                        <Form.Item label='เกิดวันที่' name='birthdate' style={{ width: '300px' }}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !',
                                                                },]}>
                                                            <DatePicker
                                                                disabled={!sell}
                                                                format={'YYYY-MM-DD'}
                                                                style={{
                                                                    width: '250px',
                                                                    height: '40px'
                                                                }}
                                                                name="birthdate"
                                                                onChange={(e) => {
                                                                    if (e) {
                                                                        setDataModalEdit({ ...dataModalEdit, birthdate: e.format('YYYY-MM-DD') })
                                                                    }
                                                                }} />
                                                        </Form.Item>
                                                        <Form.Item label='เพศ' name='gender' style={{ width: '300px' }}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !',
                                                                },]}>
                                                            <Select
                                                                disabled={!sell}
                                                                style={{
                                                                    width: '250px',
                                                                    height: '40px'
                                                                }}
                                                                onChange={(value) => setDataModalEdit({ ...dataModalEdit, gender: value })}
                                                                options={[
                                                                    {
                                                                        label: 'ชาย',
                                                                        value: 'M',
                                                                    },
                                                                    {
                                                                        label: 'หญิง',
                                                                        value: 'F',
                                                                    },
                                                                ]}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item label='เจ้าพนักงานออกบัตร' name='issuby' style={{ width: '300px' }}
                                                        // rules={[
                                                        //     {
                                                        //         required: true,
                                                        //         message: 'Please input !',
                                                        //     },]}
                                                        >
                                                            <Input disabled={!sell} style={{
                                                                width: '250px',
                                                                height: '40px'
                                                            }}
                                                                type='text' onChange={e => setDataModalEdit({ ...dataModalEdit, issuby: e.target.value })} />
                                                        </Form.Item>
                                                        <Form.Item label='วันออกบัตร' name='issudt' style={{ width: '300px' }}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !',
                                                                },]}>
                                                            <DatePicker
                                                                disabled={!sell}
                                                                format={'YYYY-MM-DD'}
                                                                style={{
                                                                    width: '250px',
                                                                    height: '40px'
                                                                }}
                                                                name='issudt'
                                                                onChange={(e) => {
                                                                    if (e) {
                                                                        setDataModalEdit({ ...dataModalEdit, issudt: e.format('YYYY-MM-DD') })
                                                                    }
                                                                }} />
                                                        </Form.Item>
                                                        <Form.Item label='วันบัตรหมดอายุ' name='expdt' style={{ width: '300px' }}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !',
                                                                },]}>
                                                            <DatePicker
                                                                disabled={!sell}
                                                                format={'YYYY-MM-DD'}
                                                                style={{
                                                                    width: '250px',
                                                                    height: '40px'
                                                                }}
                                                                name='expdt'
                                                                onChange={(e) => {
                                                                    if (e) {
                                                                        setDataModalEdit({ ...dataModalEdit, expdt: e.format('YYYY-MM-DD') })
                                                                    }
                                                                }} />
                                                        </Form.Item>
                                                    </aside>
                                                </Col>
                                            </Row>
                                        </>
                                    }
                                </Col>
                            </Row>
                            <Col span={24} style={{ textAlign: 'center' }}>
                                <Space>
                                    <Button type="primary" onClick={handleCancel} style={{ background: "red" }}>ยกเลิก</Button>
                                    <Button type="primary" htmlType="submit" style={{ background: "green" }}>บันทึก</Button>
                                    <Button type="primary" onClick={onChangeGo}>ต่อไป</Button>
                                </Space>
                            </Col>
                        </Form>
                    </Spin>
                </Card>
            </Row>
        </div >
    )
};
