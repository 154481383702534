import React, { useState } from 'react'
import { Button, Form, Input, Select, Divider, Row, Col, Modal, Radio, Space } from 'antd';

function AddPhoneED({ open, close, fucnAdd, cusId, setCountSave }) {

  const [form] = Form.useForm();
  const [dataPhone, setDataPhone] = useState({ customerId: cusId, phoneId: 0 });
  // const [dataPhone, setDataPhone] = useState({ customerId: cusId,  });

  const handleCancel = () => {
    close(false);
  };


  const handleSubmit = () => {
    // console.log('Submit', dataPhone)
    setCountSave(true)
    fucnAdd({ dataPhone })
    close(false);
  }

  return (
    <>
      {/* Modal เพิ่มข้อมูล */}
      <Modal
        open={open}
        centered
        onCancel={handleCancel}
        width={600}
        footer={[null]}

      >
        <Form
          form={form}
          name='fucnAdd'
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 24,
          }}
          style={{
            maxWidth: '100%',
          }}
          onFinish={handleSubmit}>
          <Row>
            <Col span={24} style={{ fontSize: '30px', textAlign: 'center' }} ><u>เพิ่มช่องทางการติดต่อ</u></Col>
          </Row>
          <Divider />
          <Row gutter={{ sx: 8, sm: 16, md: 24, lg: 32 }} >
            <Col className='gutter-row' span={24}>
              <Form.Item label='ชนิด' name='phoneType'
                rules={[
                  {
                    required: true,
                    message: 'Please input !',
                  },]}>
                <Select
                  placeholder={'เลือก'}
                  onChange={(value) => setDataPhone({ ...dataPhone, phoneType: value })}
                  options={[
                    {
                      label: 'เบอร์มือถือ',
                      value: 'เบอร์มือถือ',
                    },
                    {
                      label: 'เบอร์ที่ทำงาน',
                      value: 'เบอร์ที่ทำงาน',
                    },
                    {
                      label: 'เบอร์บ้าน',
                      value: 'เบอร์บ้าน',
                    }
                  ]}

                />
              </Form.Item>
              <Form.Item label="เบอร์" name='telp' rules={[
                {
                  required: true,
                  message: 'Please input !',
                },]} >
                <Input onChange={(e) => setDataPhone({ ...dataPhone, telp: e.target.value })} />
              </Form.Item>
              <Form.Item label="ส่ง sms" name="sendSms" rules={[
                {
                  required: true,
                  message: 'Please input !',
                },]}>
                <Radio.Group onChange={(e) => setDataPhone({ ...dataPhone, sendSms: e.target.value })} >
                  <Radio value="1"> ส่ง </Radio>
                  <Radio value="0"> ไม่ส่ง </Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item label="หมายเหตุ" name='note' >
                <Input onChange={(e) => setDataPhone({ ...dataPhone, note: e.target.value })} />
              </Form.Item>

              <Divider />
            </Col>
          </Row>

          <Row>
            <Col span={24} style={{ textAlign: 'center' }}>
              <Space>
                <Button type="primary" onClick={handleCancel} style={{ background: "red" }}>ยกเลิก</Button>
                <Button type="primary" htmlType="submit" style={{ background: "green" }}  >บันทึก</Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
};
export default AddPhoneED
