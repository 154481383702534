import { EditOutlined, SearchOutlined, SettingOutlined } from '@ant-design/icons'
import { Button, Card, Col, Divider, Form, Input, Row, Space, Spin, Table, Tag, Flex, Radio, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import dayjs from "dayjs";
import EditCont from './modal/EditCont'
import { checkcontnopgre, loadSendPG } from '../file_mid/all_api'

export default function MainCheckInfoPGRECont() {

  const token = localStorage.getItem('token')
  // const [api, contextHolder] = notification.useNotification();
  const [dataSend, setDataSend] = useState();
  const [dataTable, setDataTable] = useState([]);
  const [dataTableAun, setDataTableAun] = useState([]);
  const [dataCount, setDataCount] = useState([]);
  const [dataTableAunSearch, setDataTableAunSearch] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [recordData, setRecordData] = useState();
  const [selectedValue, setSelectedValue] = useState("1");
  const [count, setCount] = useState(); // state สำหรับเก็บข้อมูลจาก loadDataReAun()
  const [searchValue, setSearchValue] = useState('');
  const [selectValue, setSelectValue] = useState('ทั้งหมด');

  const opAun =
    [
      {
        label: 'ทั้งหมด', value: 'ทั้งหมด',
      },
      {
        label: 'เคสที่เปิดแล้ว', value: 'เคสที่เปิดแล้ว',
      },
      {
        label: 'เคสที่ยังไม่ได้เปิด', value: 'เคสที่ยังไม่ได้เปิด',
      },
    ]

  // console.log("dataTableAun", dataTableAun)
  // console.log("selectValue", selectValue)

  useEffect(() => {
    setLoading(true)
    axios.get(loadSendPG)
      .then((res) => {
        if (res.status === 200) {
          // console.log("res.data111", res.data)
          setDataTableAun(res.data.data)
          var dataCountsOpen = []
          var dataCountsNoOpen = []
          res?.data?.data?.map((item, index) => {
            // console.log("item", item)
            if (item?.checkChange === true || item?.checkPub === true) {
              dataCountsOpen.push(item)
            } else {
              dataCountsNoOpen.push(item)
            }
          })
          // นับจำนวนของ dataCountsOpen และ dataCountsNoOpen
          var countOpen = dataCountsOpen.length;
          var countNoOpen = dataCountsNoOpen.length;

          // console.log("จำนวนของ dataCountsOpen:", countOpen);
          // console.log("จำนวนของ dataCountsNoOpen:", countNoOpen);

          if (selectValue === "เคสที่เปิดแล้ว") {
            setDataTableAun(dataCountsOpen)
            setCount(countOpen)
          } else if (selectValue === "เคสที่ยังไม่ได้เปิด") {
            setCount(countNoOpen)
            setDataTableAun(dataCountsNoOpen)
          } else {
            setCount(res.data.count)
            setDataTableAun(res.data.data)
          }
          // console.log("dataCdataCountsOpenunts", dataCountsOpen)
          // console.log("dataCountsNoOpen", dataCountsNoOpen)
          setDataTableAunSearch(res.data.data)
          setLoading(false)
        }
      })
      .catch((err) => {
        console.log("err", err)
        setLoading(false)
      })

  }, [selectedValue, searchValue, selectValue])

  const onChange = (e) => {
    setSelectedValue(e.target.value);
  };

  const handleSubmit = async () => {
    const tk = JSON.parse(token)
    const headers = {
      "Authorization": `Bearer ${tk}`,
      "Menu": JSON.stringify("5")
    }
    setLoading(true)
    await axios.post(checkcontnopgre, dataSend, { headers: headers })
      .then((res) => {
        if (res.status === 200) {
          // console.log("res.data", res.data)
          setDataTable(res.data)
          setLoading(false)
        }
      })
      .catch((err) => {
        console.log("err", err)
        setLoading(false)
      })
  }
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setSearchValue(inputValue);
  };
  const handleSearch = (e) => {
    // console.log("eeeee", e)
    const filteredData = dataTableAunSearch.filter(item =>
      item.CONTNO.includes(searchValue)
    );
    // console.log("filteredData", filteredData);
    setDataTableAun(filteredData)
  };

  const handleSelectChange = (e) => {
    console.log("ee", e)
    setSelectValue(e)
  }

  const columns = [
    {
      fixed: true,
      title: "ลำดับ",
      dataIndex: "index",
      key: 'index',
      width: "20%",
      align: 'center',
      render: (text, object, index) => index + 1
    },
    {
      title: "เลขสัญญา",
      dataIndex: "CONTNO",
      align: 'center',
      width: "20%",
    },
    {
      title: "ชื่อ-นามสกุล",
      dataIndex: "",
      width: "20%",
      align: 'center',
      render: (text, record) => (
        <>
          <div>{record.NAME1} {record.NAME2}</div>
        </>
      )
    },
    {
      title: "การตลาดที่เสนอ",
      dataIndex: "SALCOD",
      width: "20%",
      align: 'center',
      render: (text, record) => (
        <>
          <Tag color="geekblue">{record.SALCOD}</Tag>
        </>
      ),
    },
    {
      title: "Action",
      align: 'center',
      width: "20%",
      render: (record) => (
        <Space size="middle">
          <Button type="primary" icon={<SettingOutlined />} style={{ backgroundColor: "#faad14", color: "black" }} onClick={() => {
            setRecordData(record)
            setIsModalOpen(true)
          }}>กำหนดสัญญา</Button>
        </Space>
      ),
    },
  ];
  const columns1 = [
    {
      fixed: true,
      title: "ลำดับ",
      dataIndex: "index",
      key: 'index',
      width: "20%",
      align: 'center',
      render: (text, object, index) => index + 1
    },
    {
      title: "เลขสัญญา",
      dataIndex: "CONTNO",
      align: 'center',
      width: "20%",
    },
    {
      title: "วันที่เสนอ",
      dataIndex: "",
      width: "20%",
      align: 'center',
      render: (text, record) => (
        <>
          <div>{dayjs(record.offerDate).format("DD-MM-YYYY")} </div>
          {/* {dayjs(record.SDATE).format("DD-MM-YYYY")} */}
        </>
      )
    },
    {
      title: "วันหมดอายุ",
      dataIndex: "",
      width: "20%",
      align: 'center',
      render: (text, record) => (
        <>
          <div>

            {/* {record.newOfferDate} */}
            {dayjs(record.newOfferDate).format("DD-MM-YYYY")}
          </div>
        </>
      )
    },
    {
      title: "การตลาดที่เสนอ",
      dataIndex: "SALCOD",
      width: "20%",
      align: 'center',
      render: (text, record) => (
         <>
          <Tag color="geekblue">{record.proposalBy}</Tag>
        </>
      ),
    },
    {
      title: "เร่งรัดที่เสนอ",
      dataIndex: "SALCOD",
      width: "20%",
      align: 'center',
      render: (text, record) => (
        <>
          <Tag color="geekblue">{record.offerBy}</Tag>
        </>
      ),
    },
    {
      title: "Action",
      align: 'center',
      width: "20%",
      render: (record) => (
        <Space size="middle">
          <Button type="primary" icon={<SettingOutlined />} style={{ backgroundColor: "#faad14", color: "black" }} onClick={() => {
            setRecordData(record)
            setIsModalOpen(true)
          }}>กำหนดสัญญา</Button>
        </Space>
      ),
    },
  ];

  return (
    <Card style={{ height: '100%' }}>
      <Row>
        <Col span={24}>
          <div className='text-center'>
            <h2>กำหนดสัญญา รี/ปรับ/เปลี่ยน</h2>
          </div>
        </Col>
        <Divider />

        {/* <Space> */}

        <Col span={10} orientation="center">
          <Row justify={'center'}>
            <Flex vertical gap="middle">
              <Radio.Group onChange={onChange} value={selectedValue} buttonStyle="solid">
                <Radio.Button value="1"><b>ปกติ</b></Radio.Button>
                <Radio.Button value="2"><b>เคสเร่งรัดภาคสนาม ({count})</b></Radio.Button>
              </Radio.Group>
            </Flex>
          </Row>
        </Col>
        <Col span={14}>
          {/* <Spin spinning={loading} size='large' tip="Loading..."> */}
          <Row justify={'left'}>
            {selectedValue === '1' ?
              <>
                <Form.Item label="กรอกเลขสัญญา">
                  <Input onChange={(e) => setDataSend({ ...dataSend, CONTNO: e.target.value })}></Input>
                </Form.Item>
                <Form.Item label="">
                  <Button type='primary'
                    onClick={handleSubmit}
                    icon={<SearchOutlined />}>ค้นหา</Button>
                </Form.Item>
              </>
              :
              <>
                <Col className='gutter-row' span={12} style={{ textAlign: 'right' }}>
                  <span><b>เลือกเคส : </b></span>
                  <Select
                    defaultValue={selectValue + '(' + (count) + ')'}
                    style={{ width: '250px', height: '40px' }}
                    onChange={(value) => handleSelectChange(value)}
                    options={opAun}
                  />
                </Col>
                <Col className='gutter-row' span={12} style={{ textAlign: 'right' }}>
                  <span><b>กรอกเลขสัญญา : </b></span>
                  <Input
                    style={{ width: '250px' }}
                    placeholder="ค้นหา...."
                    value={searchValue}
                    onChange={handleInputChange}
                  // onKeyPress={handleKeyPress}
                  />
                  <Button style={{ marginLeft: '15px' }}
                    type="primary"
                    onClick={(e) => handleSearch(e)}
                  >ค้นหา</Button>
                </Col>
              </>
            }
          </Row>
          {/* </Spin> */}
        </Col>

        {/* </Space> */}
        <Table
          // rowKey={(record) => record.uid}
          dataSource={selectedValue === '1' ? dataTable : dataTableAun}
          columns={selectedValue === '1' ? columns : columns1}
          scroll={{
            x: 1500,
            y: 400,
          }}
          style={{ padding: 20 }}
        >
        </Table>
      </Row >
      {
        isModalOpen &&
        <EditCont open={isModalOpen} close={setIsModalOpen} selectedValue={selectedValue} recordData={recordData} />
      }
    </Card >
  )
}
