import React, { useState, useEffect } from 'react'
import { Modal, Form, Button, Card, Divider, Tag, Space, Row, Image, notification, Col } from 'antd'
import axios from 'axios';
import TextArea from 'antd/es/input/TextArea';
import { addmemo, findcarpg, getImagess } from '../../file_mid/all_api';

function ModalEditMemo({ open, dataFromTable, close }) {
    const [loading, setLoading] = useState(false);
    const token = localStorage.getItem('token');
    const [form] = Form.useForm();

    const [dataModalEdit, setDataModalEdit] = useState(dataFromTable);
    const [imageBlobzz, setImageBlobzz] = useState([]);
    const [api, contextHolder] = notification.useNotification();

    useEffect(() => {
        setDataModalEdit(dataFromTable)
        loadData();
        form.setFieldsValue({
            carMemo: dataFromTable.carMemo
        })
    }, [dataFromTable])

    const handleCancel = () => {
        close(false);
    };

    const currencyFormatOne = (amount) => {
        return Number(amount)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }

    const loadData = async () => {
        await axios.post(findcarpg, { carId: dataFromTable.carId, customerId: dataFromTable.customerId })
            .then(res => {
                if (res.status === 200) {
                    setImageBlobzz(res.data.cars.img)
                }
            }).catch((err) => {
                console.log("err", err)
            })
    }

    const succSend = (placement) => {
        api.success({
            message: <b>แก้ไข Memo สำเร็จ</b>,
            placement,
        });
    }

    const errSend = (placement) => {
        api.error({
            message: <b>แก้ไข Memo ไม่สำเร็จ</b>,
            placement,
        });
    }

    const handleOk = async () => {
        setLoading(true);
        await axios.post(addmemo, { carId: dataFromTable.carId, car: dataFromTable.car, carMemo: dataModalEdit.carMemo })
            .then(res => {
                if (res.status === 200) {
                    succSend("top")
                    setTimeout(() => {
                        close(false);
                    }, 1500)
                } else {
                    errSend("top")
                    setLoading(true);
                }
            }).catch((err) => {
                console.log(err)
                errSend("top")
            })
    };

    return (
        <div>
            <Modal title="แก้ไข Memo" open={open} onCancel={handleCancel} width={1200}
                footer={[null]}>
                <Card>
                    <Divider orientation="left"><b>รายละเอียดรถ</b></Divider>
                    <Form
                        name="basic"
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}
                        form={form}
                        onFinish={handleOk}>
                        <Row>
                            <Col span={8}>
                                <Form.Item label="ชื่อลูกค้า" style={{ margin: 0 }}>
                                    {dataModalEdit.snam}{dataModalEdit.firstName} {dataModalEdit.lastName}
                                </Form.Item>
                                <Form.Item label="เลขทะเบียนรถ" style={{ margin: 0 }}>
                                    {dataModalEdit.carPlateNumber}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="รายละเอียด" style={{ margin: 0 }}>
                                    {dataModalEdit.carBrand} {dataModalEdit.carModel} {dataModalEdit.carYear}
                                </Form.Item>
                                <Form.Item label="ยอดขอกู้" style={{ margin: 0 }}>
                                    {currencyFormatOne(dataModalEdit.loanAmount)}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="เลขทะเบียนรถ" style={{ margin: 0 }}>
                                    {dataModalEdit.carPlateNumber}
                                </Form.Item>
                                <Form.Item label="เรท" style={{ margin: 0 }}>
                                    {currencyFormatOne(dataModalEdit.carPrice)}
                                </Form.Item>
                                <Form.Item label="สถานะ">
                                    <Tag color="gold">{dataModalEdit.approvalStatus}</Tag>
                                    {
                                        dataModalEdit.productLoanType === "ย้ายไฟแนนซ์" && (
                                            <Tag color="green">{dataModalEdit.productLoanType}</Tag>
                                        )
                                    }
                                    {
                                        dataModalEdit.productLoanType === "ถือเล่มมา" && (
                                            <Tag color="geekblue">{dataModalEdit.productLoanType}</Tag>
                                        )
                                    }
                                    {
                                        (dataModalEdit.productLoanType === "ถือโฉนด" || dataModalEdit.productLoanType === "ถือโฉนด(ที่ดิน)") && (
                                            <Tag color="geekblue">{dataModalEdit.productLoanType}</Tag>
                                        )
                                    }
                                    {
                                        dataModalEdit.productLoanType === "ย้ายไฟแนนซ์" && (
                                            <Tag color="geekblue">{dataModalEdit.productLoanType}</Tag>
                                        )
                                    }
                                    {
                                        dataModalEdit.productLoanType === "ปรับโครงสร้าง" && (
                                            <Tag color="geekblue">{dataModalEdit.productLoanType}</Tag>
                                        )
                                    }
                                    {
                                        dataModalEdit.productLoanType === "ซื้อ-ขาย" && (
                                            <Tag color="geekblue">{dataModalEdit.productLoanType}</Tag>
                                        )
                                    }
                                    {
                                        dataModalEdit.productLoanType === "ที่ดิน+บ้าน" && (
                                            <Tag color="geekblue">{dataModalEdit.productLoanType}</Tag>
                                        )
                                    }
                                    {
                                        dataModalEdit.productLoanType === "ฝากโฉนด" && (
                                            <Tag color="geekblue">{dataModalEdit.productLoanType}</Tag>
                                        )
                                    }
                                    {
                                        dataModalEdit.productLoanType === "Pre-Aaprove" && (
                                            <Tag color="geekblue">{dataModalEdit.productLoanType}</Tag>
                                        )
                                    }
                                    {
                                        dataModalEdit.productLoanType === "Pre-Aaprove-ถือเล่มมา" && (
                                            <Tag color="geekblue">{dataModalEdit.productLoanType}</Tag>
                                        )
                                    }
                                    {
                                        dataModalEdit.productLoanType === "Pre-Aaprove-ซื้อ-ขาย" && (
                                            <Tag color="geekblue">{dataModalEdit.productLoanType}</Tag>
                                        )
                                    }
                                    {
                                        dataModalEdit.productLoanType === "Pre-Aaprove-ย้ายไฟแนนซ์" && (
                                            <Tag color="geekblue">{dataModalEdit.productLoanType}</Tag>
                                        )
                                    }
                                    {
                                        dataModalEdit.productLoanType === "Pre-Aaprove-ฝากโฉนด" && (
                                            <Tag color="geekblue">{dataModalEdit.productLoanType}</Tag>
                                        )
                                    }
                                    {
                                        dataModalEdit.productLoanType === "Pre-Aaprove-รีโอน" && (
                                            <Tag color="geekblue">{dataModalEdit.productLoanType}</Tag>
                                        )
                                    }
                                    {
                                        dataModalEdit.productLoanType === "เปลี่ยนสัญญา" && (
                                            <Tag color="geekblue">{dataModalEdit.productLoanType}</Tag>
                                        )
                                    }
                                    {
                                        dataModalEdit.productLoanType === "Pre-Aaprove-เปลี่ยนสัญญา" && (
                                            <Tag color="geekblue">{dataModalEdit.productLoanType}</Tag>
                                        )
                                    }
                                    {
                                        dataModalEdit.productLoanType === "รี+เปลี่ยนสัญญา" && (
                                            <Tag color="geekblue">{dataModalEdit.productLoanType}</Tag>
                                        )
                                    }
                                    {
                                        dataModalEdit.productLoanType === "ปรับ+เปลี่ยนสัญญา" && (
                                            <Tag color="geekblue">{dataModalEdit.productLoanType}</Tag>
                                        )
                                    }
                                    {
                                        dataModalEdit.productLoanType === "Pre-Aaprove-รี-Ploan" && (
                                            <Tag color="geekblue">{dataModalEdit.productLoanType}</Tag>
                                        )
                                    }
                                    {
                                        dataModalEdit.productLoanType === "รี-3" && (
                                            <Tag color="geekblue">{dataModalEdit.productLoanType}</Tag>
                                        )
                                    }
                                    {
                                        dataModalEdit.productLoanType === "Pre-Aaprove-ปรับโครงสร้าง" && (
                                            <Tag color="geekblue">{dataModalEdit.productLoanType}</Tag>
                                        )
                                    }
                                    {
                                        dataModalEdit.productLoanType === "Pre-Aaprove-รี" && (
                                            <Tag color="geekblue">{dataModalEdit.productLoanType}</Tag>
                                        )
                                    }
                                    {
                                        dataModalEdit.productLoanType === "Pre-Aaprove-รี+เปลี่ยนสัญญา" && (
                                            <Tag color="geekblue">{dataModalEdit.productLoanType}</Tag>
                                        )
                                    }
                                    {
                                        dataModalEdit.productLoanType === "Pre-Aaprove-Ploan'" && (
                                            <Tag color="geekblue">{dataModalEdit.productLoanType}</Tag>
                                        )
                                    }
                                    {
                                        dataModalEdit.productLoanType === "Pre-Aaprove-เปลี่ยนสัญญา" && (
                                            <Tag color="geekblue">{dataModalEdit.productLoanType}</Tag>
                                        )
                                    }
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider orientation="left"><b>Memo</b></Divider>
                        <Row>
                            <Col span={20}>
                                <Form.Item label="Memo" name="carMemo"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !'
                                        },]}>
                                    <TextArea name='carMemo' onChange={(e) => setDataModalEdit({ ...dataModalEdit, carMemo: e.target.value })} rows={5} ></TextArea>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                            </Col>
                        </Row>

                        <Divider orientation="left"><b>รูปรถ</b></Divider>
                        <Row gutter={32} justify={'center'}>
                            <Space direction="vertical">
                                <Row gutter={32} justify={'center'}>
                                    {imageBlobzz?.map((e, index) => {
                                        if (e.type === 1) {
                                            return (
                                                <>
                                                    <Image width={200} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                </>
                                            );
                                        }
                                        return null;
                                    })}
                                </Row>
                            </Space>
                        </Row>

                        <Row>
                            <Divider />
                            <Col span={24} style={{ textAlign: "center" }}>
                                <Space>
                                    <Button type='primary' htmlType='submit' disabled={loading} style={{ backgroundColor: "Green" }}>ยืนยัน</Button>
                                    <Button key="back" type='primary' danger onClick={handleCancel}>ปิด</Button>
                                </Space>
                            </Col>
                        </Row>

                    </Form>
                </Card>
            </Modal>
            {contextHolder}
        </div>
    )
}

export default ModalEditMemo