import React, { useState } from "react";
import dotaLocat from "./dotaLocat";
import { Select } from "antd";

function InputSelect({ onChange }) { // ตั้งชื่อให้เป็น InputSelect และเริ่มต้นด้วยตัวใหญ่
  const [locatA, setLocatA] = useState([]);

  //F-local-1
  const onChangeLocal = (value) => {
    //console.log(`selected ${value}`);
    setLocatA(value);
    onChange(value); // Call the onChange function passed as pro
  };
  //console.log(locatA);
  //F-local-2
  const onSearchLocal = (value) => {
    console.log("search:", value);
  };
  //F-local-3
  const filterOptionLocal = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <Select
      style={{ width: "110px", margin: "0 4px 4px" }}
      showSearch
      placeholder="เลือกสาขา"
      optionFilterProp="children"
      onChange={(value) => {
        onChangeLocal(value);
      }}
      onSearch={onSearchLocal}
      filterOption={filterOptionLocal}
      options={dotaLocat}
      value={locatA}
    />
  );
}
export default InputSelect;
