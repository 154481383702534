import React, { useState, useEffect } from 'react';
import { Form, Input, Row, Col, Button, Select, Divider, Space, Radio, DatePicker, Card, Spin, notification, Upload, Modal } from 'antd';
import '../../offer_case/css/Media.css';
import Compressor from 'compressorjs';
import axios from 'axios';
import dayjs from 'dayjs';
import { PlusOutlined } from '@ant-design/icons';
import { newUpimg, newUpimgland } from '../../file_mid/all_api';

export default function EditOtherDoc({ getData, close, sendback, dt }) {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const [api, contextHolder] = notification.useNotification();
    const [dataModalEdit, setDataModalEdit] = useState()
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [fileListOther, setFileListOther] = useState([]);
    const [fileListOther1, setFileListOther1] = useState([]);

    useEffect(() => {
        setDataModalEdit(getData)
    }, [getData])

    const success = () => {
        Modal.success({
            content: 'บันทึกสำเร็จ',
        })
    }
    const uploadImage = async (resultImg) => {
        if (dt?.car === "car") {
            var inputTest = { mainImg: { carId: getData.cars.carId, carInput: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"), img: resultImg } }
            console.log("inputTest-car", inputTest)
            const headers = { 'Content-Type': 'application/json' }
            await axios.post(newUpimg, inputTest, { headers: headers })
                .then(res => {
                    if (res.data) {
                        success()
                        console.log("OK car")
                        setFileListOther([])
                        setFileListOther1([])
                    }
                })
                .catch((err) => console.error('เกิดข้อผิดพลาดในการอัปโหลดรูปภาพ:', err))
        } else {
            var inputTest = { mainImg: { landId: getData.lands.landId, landInput: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"), img: resultImg } }
            console.log("inputTest-land", inputTest)
            const headers = { 'Content-Type': 'application/json' }
            await axios.post(newUpimgland, inputTest, { headers: headers })
                .then(res => {
                    if (res.data) {
                        success()
                        console.log("OK land")
                        setFileListOther([])
                        setFileListOther1([])
                    }
                })
                .catch((err) => console.error('เกิดข้อผิดพลาดในการอัปโหลดรูปภาพ:', err))
        }
    }


    const handleSubmit = async () => {
        setLoading(true);
        var resultImg = []
        if (fileListOther1?.imageList) {
            resultImg.push(...fileListOther1?.imageList)
        }
        console.log("resultImg", resultImg)
        if (resultImg?.length > 0) {
            await uploadImage(resultImg)
        }

        setLoading(false);
    };

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
    };
    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };
    const handleCancel = () => {
        close();
    }
    const handleCancel1 = () => {
        setPreviewVisible(false);
    }
    const handleChangeOther = async ({ fileList }) => {
        setLoading(true)
        setFileListOther(fileList);
        var imageList = []
        for (const file of fileList) {
            const compressedFile = await new Promise((resolve) => {
                new Compressor(file.originFileObj, {
                    quality: 0.3, // เช่น, 60%
                    success: (result) => {
                        resolve(result);
                    },
                });
            });
            var image = {}
            var filename = file.name
            var image64 = await getBase64(compressedFile)
            image.filename = filename
            image.image64 = image64
            image.type = 12
            imageList.push(image)
        }
        setFileListOther1({ ...fileListOther1, imageList })
        setLoading(false)
    };
    return (
        <div>
            <Card style={{ padding: 20 }} className="modal-img">
                <div style={{ textAlign: 'center' }}>
                    <Row style={{ textAlign: 'center' }} >
                        <Col span={24} style={{ fontSize: '30px' }} >อัพโหลดเอกสารเพิ่มเติม</Col>
                    </Row>
                    <Divider />
                    <Spin spinning={loading} size='large' tip=" Loading... ">
                        <Form
                            labelCol={{
                                span: 12,
                            }}
                            wrapperCol={{
                                span: 24,
                            }}
                            form={form}
                            onFinish={handleSubmit} >
                            <Row justify={'center'} style={{ margin: '5px' }} >
                                <Col span={20} style={{ textAlign: 'center' }}>
                                    <Row justify={'center'} style={{ margin: '5px' }} >รูปเอกสารเพิ่มเติม</Row>
                                    <Row justify={'center'}>
                                        <Col span={24}>
                                            <aside gutter={24} justify={'center'}>
                                                <div className='gutter-row' span={12}>
                                                    <Row justify={'center'}><b><h3><u>ไฟล์ภาพ</u></h3></b></Row>
                                                    <Row justify={'center'}>
                                                        <b> <Form.Item
                                                            style={{ textAlign: 'center' }}
                                                        >
                                                            <Upload
                                                                accept="image/*"
                                                                multiple={true}
                                                                style={{ textAlign: 'center' }}
                                                                listType="picture-card"
                                                                fileList={fileListOther}
                                                                onPreview={handlePreview}
                                                                onChange={handleChangeOther}
                                                                beforeUpload={() => false}
                                                            >
                                                                {fileListOther?.length >= 20 ? null : (
                                                                    <div>
                                                                        <PlusOutlined />
                                                                        <div className="ant-upload-text">upload</div>
                                                                    </div>
                                                                )}
                                                            </Upload>
                                                            <Modal open={previewVisible} onCancel={handleCancel1}>
                                                                <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                            </Modal>
                                                        </Form.Item>
                                                        </b>
                                                    </Row>
                                                </div>
                                            </aside>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col span={24} style={{ textAlign: 'center' }}>
                                            <Space>
                                                <Button type="primary" style={{ background: "red" }} onClick={handleCancel}>
                                                    ยกเลิก
                                                </Button>
                                                <Button type="primary" htmlType="submit" style={{ background: "green" }} >
                                                    บันทึก
                                                </Button>
                                            </Space>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    </Spin>
                </div >
            </Card >
            {contextHolder}
        </div >
    )
}
