import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Space, Button, Form, Typography, Input, Table, Spin } from "antd";
import axios from "axios";
import './../App.css';
import { getuser } from "../../file_mid/all_api";

const ModalDetailRight = ({ open, close, record }) => {
    const pageSize = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState({ ...record });
    const [description, setDescription] = useState(record.description);
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState("");

    // console.log("record", record);

    useEffect(() => {
        getUser();
    }, []);

    useEffect(() => {
        if (users.length > 0) {
            const filtered = users.filter(user => user.role_id === record.role_id);
            setFilteredUsers(filtered);
        }
    }, [users, record.role_id]);

    const getUser = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${getuser}`);
            if (response.data === null) {
                setUsers([]);
            } else {
                setUsers(response.data);
            }
            setLoading(false);
            // console.log('user', response.data);
        } catch (error) {
            console.error('Error fetching users:', error);
            setLoading(false);
        }
    };

    const handleOk = () => {
        close();
    };

    const handleCancel = () => {
        setFormData({ description: record.description });
        close();
    };

    const handleChange = (e) => {
        setFormData({ ...formData, description: e.target.value });
    };

    const handleTableChange = (pagination) => {
        setCurrentPage(pagination.current);
    };

    const handleSearch = (e) => {
        setSearchText(e.target.value);
        const filtered = users.filter(user =>
            user.role_id === record.role_id &&
            (user.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
                user.lastname.toLowerCase().includes(e.target.value.toLowerCase()) ||
                user.username.toLowerCase().includes(e.target.value.toLowerCase()))
        );
        setFilteredUsers(filtered);
    };

    const columns = [
        {
            title: 'ลำดับที่',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => (currentPage - 1) * pageSize + index + 1,
        },
        {
            title: 'ชื่อ-นามสกุล',
            dataIndex: 'fullname',
            key: 'fullname',
            render: (text, filteredUsers) => `${filteredUsers.name} ${filteredUsers.lastname}`
        },
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
        },
    ];

    return (
        <Modal
            title="รายละเอียดสิทธิ์"
            open={open}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
            width={1000}
        >
            <Spin spinning={loading} size='large' tip=' loading... '>
                <Row>
                    <Col span={24}>
                        <Form.Item label='ชื่อสิทธิ์'>
                            <Typography.Text>{formData.role_name}</Typography.Text>
                        </Form.Item>
                        <Form.Item label="รายละเอียดสิทธิ์" style={{ textAlign: 'center' }}>
                            {isEditing ? (
                                <Input.TextArea
                                    value={formData.description}
                                    onChange={handleChange}
                                />
                            ) : (
                                <div>
                                    <Row>
                                        <Col span={24}>
                                            <Row justify={'start'}>
                                                <Typography.Text>{description}</Typography.Text>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            )}
                        </Form.Item>
                        {/* <Row justify={'end'}>
                        {isEditing ? (
                            <>
                                <Button onClick={handleCancelEdit} style={{ margin: '10px' }}>ยกเลิก</Button>
                                <Button onClick={handleSave} type="primary" style={{ marginRight: 10, margin: '10px' }}>
                                    บันทึก
                                </Button>
                            </>
                        ) : (
                            <Button
                                onClick={handleEdit}
                                type="primary"
                                style={{ margin: '10px' }}
                            >
                                แก้ไข
                            </Button>
                        )}
                    </Row> */}
                    </Col>
                    <Col span={24}>
                        <Row justify="end" style={{ marginBottom: 16 }}>
                            <Col>
                                <Input
                                    placeholder="ค้นหาพนักงาน"
                                    value={searchText}
                                    onChange={handleSearch}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="รายชื่อพนักงาน" >
                            <Row>
                                <Col span={24}>
                                    <Table
                                        columns={columns}
                                        dataSource={filteredUsers}
                                        onChange={handleTableChange}
                                        pagination={{
                                            pageSize: 10,
                                            showSizeChanger: false,
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Row justify={"end"}>
                            <Space>
                                <Button
                                    key="save"
                                    type='submit'
                                    onClick={handleOk}
                                    style={{
                                        color: 'white',
                                        backgroundColor: 'red',
                                        justifyContent: 'end',
                                        padding: '10px',
                                        margin: '10px'
                                    }}
                                >
                                    ปิด
                                </Button>
                            </Space>
                        </Row>
                    </Col>
                </Row>
            </Spin>
        </Modal>
    );
}

export default ModalDetailRight;
