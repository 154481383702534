import React, { useEffect, useState } from 'react'
import { Button, Form, Card, Col, Row, Space, Divider, Spin, Image, Modal, Checkbox } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import FileSaver from 'file-saver';
import JSZip from 'jszip';
import { setTypeImg } from '../../../file_mid/type_img';
import { deleteAllCar, deleteAllLand, storeDBLine, getImagessPre, downloadImagessPre, deletePreCar, deletePreLand } from '../../../file_mid/all_api';

export default function ProductNew({ getData, DeleteError, deleteData, checkType, newDataLand, sendB }) {
  const un = localStorage.getItem('username');
  const token = localStorage.getItem('token');
  const view = localStorage.getItem('viewAD')
  const detail = JSON.parse(view)
  const [dataModalEdit, setDataModalEdit] = useState(getData);
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const { confirm } = Modal;
  const [checkedImages, setCheckedImages] = useState([]);
  // const [cb, setCb] = useState(false)
  const [adData, setAdData] = useState([])
  const [checkedState, setCheckedState] = useState({});
  // const [newDataLandImgAll, setNewDataLandImgAll] = useState([]);
  // console.log("detail", detail)
  // console.log("dataModalEdit", dataModalEdit)
  // console.log("newDataLand", newDataLand)
  useEffect(() => {
    // setCb(false)
    console.log('img')
    setDataModalEdit(getData)
    onChangeCheckboxAll(true)
  }, [getData, newDataLand])

  window.addEventListener('beforeunload', function () {
    localStorage.removeItem('viewAD');
  });

  const onChangeCheckbox = (es, e) => {
    // console.log("es", es.target.checked)
    // console.log("e", e)
    const newCheckedState = { ...checkedState };
    newCheckedState[e.id] = es.target.checked;
    setCheckedState(newCheckedState);
    if (es.target.checked === true) {
      adData.push(e)
    } else {
      for (const removedFile of adData.filter(file => es.target.checked === false)) {
        if (adData.some(img => img.idImage === removedFile.idImage)) {
          const updatedAllImg = adData.filter(img => !(img.idImage === removedFile.idImage));
          setAdData(updatedAllImg);
        }
      }
    }
  }
  const showConfirmAll = () => {
    confirm({
      title: 'คุณต้องการที่จะลบรูปภาพใช่หรือไม่...?',
      onOk() {
        allDeleteImg()
      },
      onCancel() {
      },
    });
  };
  const allDeleteImg = async () => {
    console.log("adData", adData)
    setLoading(true)
    if (adData?.length > 0) {
      if (checkType.car === "car") {
        const test2 = adData
        await axios.delete(deleteAllCar, { data: test2, })
          .then((res) => {
            if (res.status === 200) {
              console.log("car", res.data)
              setCheckedState({});
              setAdData([])
              sendB(dayjs())
              deleteData("top")
              setLoading(false)
            }
          }).catch((err) => DeleteError("top"))
      } else {
        const test2 = adData
        await axios.delete(deleteAllLand, { data: test2, })
          .then((res) => {
            if (res.status === 200) {
              console.log("land", res.data)
              setCheckedState({});
              setAdData([])
              sendB(dayjs())
              deleteData("top")
              setLoading(false)
            }
          }).catch((err) => DeleteError("top"))
      }
    } else {
      alert('กรุณาเลือกรูปภาพก่อน !!!');
    }
    setLoading(false)
  }

  const showConfirm = (e, index) => {
    // console.log(e,index)
    confirm({
      title: 'คุณต้องการที่จะลบรูปภาพนี้ใช่หรือไม่...?',
      onOk() {
        // message.success('ลบรูปภาพสำเร็จ');
        if (checkType.car === "car") {
          handleDeleteCar(e, index)
        } else {
          handleDeleteLand(e, index)
        }
      },
      onCancel() {
        // message.error('ยกเลิกการลบรูปภาพ');
      },
    });
  };
  const handleDeleteCar = async (e, index) => {
    const id = e.idImage
    const path = e.pathImage
    setLoading(true)
    await axios.delete(`${deletePreCar}/${id}/${path}`)
      .then(async res => {
        if (res.data) {
          console.log("car")
          const updatedImageBlobzz = [...dataModalEdit];
          updatedImageBlobzz.splice(index, 1);
          setDataModalEdit(updatedImageBlobzz);
          await handleNoti(path)
        }
      })
      .catch((err) =>
        DeleteError("top")
      )
    setLoading(false)
  }
  const handleDeleteLand = async (e, index) => {
    const id = e.idImage
    const path = e.pathImage
    setLoading(true)
    await axios.delete(`${deletePreLand}/${id}/${path}`)
      .then(async res => {
        if (res.data) {
          console.log("land")
          const updatedImageBlobzz = [...dataModalEdit];
          updatedImageBlobzz.splice(index, 1);
          setDataModalEdit(updatedImageBlobzz);
          await handleNoti(path)
        }
      })
      .catch((err) =>
        DeleteError("top")
      )
    setLoading(false)
  }
  const handleNoti = async (path) => {
    const ez = { nameInput: un, path: path }
    await axios.post(storeDBLine, ez)
      .then(res => {
        if (res.data) {
          console.log("OK")
          sendB(dayjs())
          deleteData("top")
          setLoading(false)
        }
      })
      .catch((err) =>
        DeleteError("top")
      )
    setLoading(false)
  }

  // const onChangeCheckbox = (isChecked, image) => {
  //   // console.log(isChecked, image)
  //   if (isChecked) {
  //     setCheckedImages([...checkedImages, image]);
  //   } else {
  //     setCheckedImages(checkedImages?.filter(img => img.idImage !== image.idImage));
  //   }
  // };

  const onChangeCheckboxAll = (isChecked) => {
    setLoading(true)
    if (detail?.car === "car") {
      if (isChecked) {
        // setCb(true)
        if (getData?.length > 0) {
          console.log("1")
          var PreAaproveSplits = []
          var resImg = []
          getData?.map((item, index) => {
            PreAaproveSplits = item.pathImage.split("/")
            if (PreAaproveSplits[3] === "13") {
              if (item.type === 2) {
                resImg.push(item)
              }
            }
            return null;
          })
          setCheckedImages(resImg);
        } else {
          // setCb(false)
          setCheckedImages([])
        }
      } else {
        // setCb(false)
        setCheckedImages([])
      }
    } else {
      const newDataLandImgAll = []
      if (newDataLand?.length > 0) {
        newDataLand?.map((item, index) => {
          newDataLandImgAll.push(...item.img)
          return null;
        })
      }
      if (isChecked) {
        // setCb(true)
        if (newDataLandImgAll?.length > 0) {
          console.log("2")
          var PreAaproveSplits = []
          var resImg = []
          newDataLandImgAll?.map((item, index) => {
            PreAaproveSplits = item.pathImage.split("/")
            if (PreAaproveSplits[3] === "13") {
              if (item.type === 3 || item.type === 10 || item.type === 11) {
                resImg.push(item)
              }
            }
            return null;
          })
          // console.log("resImg", resImg)
          setCheckedImages(resImg);
        } else {
          // setCb(false)
          setCheckedImages([])
        }
      } else {
        console.log("3")
        // setCb(false)
        setCheckedImages([])
      }
    }
    setLoading(false)
  };

  // const downloadImages2 = () => {
  //   if (checkedImages.length > 0) {
  //     checkedImages.forEach(async image => {
  //       // console.log("downloadImages", image)
  //       try {
  //         // Fetch the image data
  //         const response = await fetch(`${getImagessPre}/${image.pathImage}?token=${token}`);
  //         const blob = await response.blob();

  //         // // Create a URL for the blob
  //         // const url = URL.createObjectURL(blob);

  //         // // Create a link element
  //         // const downloadLink = document.createElement('a');
  //         // downloadLink.href = url;
  //         // downloadLink.download = image.idImage; // Set the desired file name

  //         // // Append the link to the document body
  //         // document.body.appendChild(downloadLink);

  //         // // Programmatically trigger a click event on the link to initiate download
  //         // downloadLink.click();
  //         // FileSaver.saveAs(blob, image.idImage);
  //         // // Remove the link from the document body
  //         // document.body.removeChild(downloadLink);

  //         // // Revoke the URL to free up memory
  //         // URL.revokeObjectURL(url);
  //       } catch (error) {
  //         console.error('Error downloading image:', error);
  //       }
  //     });
  //   } else {
  //     alert('กรุณาเลือกรูปภาพ')
  //   }
  // };

  const downloadImages = async () => {
    setLoading(true)
    const names = detail.firstName + detail.lastName
    if (checkedImages.length > 0) {
      // console.log("checkedImages",checkedImages)
      try {
        const zip = new JSZip();
        for (const image of checkedImages) {
          const folderName = setTypeImg(image);
          const folder = zip.folder(folderName); // สร้างโฟลเดอร์ใหม่ใน ZIP
          const response = await fetch(`${downloadImagessPre}/${image.pathImage}?token=${token}`);
          const blob = await response.blob();
          folder.file(`${image.idImage}.jpg`, blob);
        }
        // สร้างไฟล์ ZIP
        const zipBlob = await zip.generateAsync({ type: 'blob' });

        // บันทึกไฟล์ ZIP และดาวน์โหลด
        FileSaver.saveAs(zipBlob, `${names} หน้าคู่มือ,โฉนด (ใหม่).zip`);
      } catch (error) {
        console.error('Error downloading images:', error);
      }
    } else {
      alert('ไม่พบรูปภาพ');
    }
    setLoading(false)
  };

  // const folder = zip.folder('images'); // สร้างโฟลเดอร์ที่ชื่อว่า
  // // ดึงข้อมูลของรูปภาพทั้งหมด
  // const promises = checkedImages.map(async (image) => {
  //   const response = await fetch(`${getImagessPre}/${image.pathImage}?token=${token}`);
  //   const blob = await response.blob();
  //   return { name: image.idImage, blob };
  // });

  // // รอจนกว่าข้อมูลของรูปภาพทั้งหมดจะถูกดาวน์โหลดเสร็จ
  // const imagesData = await Promise.all(promises);
  // // เพิ่มไฟล์รูปภาพลงใน ZIP โดยใช้ชื่อเดียวกับไฟล์
  // imagesData.forEach(({ name, blob }) => {
  //   folder.file(`${name}.jpg`, blob);
  // });

  // const downloadImages3 = () => {
  //   if (checkedImages.length > 0) {
  //     checkedImages.forEach(async image => {
  //       try {
  //         // Fetch the image data
  //         const response = await fetch(`${getImagessPre}/${image.pathImage}?token=${token}`);
  //         const blob = await response.blob();

  //         // Create a link element
  //         const downloadLink = document.createElement('a');

  //         // Set the href and download attributes
  //         downloadLink.href = window.URL.createObjectURL(blob);
  //         downloadLink.download = image.idImage;

  //         // Append the link to the document body
  //         document.body.appendChild(downloadLink);

  //         // Programmatically trigger a click event on the link to initiate download
  //         downloadLink.click();

  //         // Remove the link from the document body
  //         document.body.removeChild(downloadLink);
  //       } catch (error) {
  //         console.error('Error downloading image:', error);
  //       }
  //     });
  //   } else {
  //     alert('กรุณาเลือกรูปภาพ')
  //   }
  // };

  const renderTitleDeed = ({ item, index }) => {
    return (
      <>
        <Row justify={'left'}><b><u>รูปโฉนด/หน้า-หลัง ({item.numberLand})</u></b></Row>
        <Space direction='vertical'>
          {/* <Row gutter={32} justify={'center'}> */}
          {item?.img?.map((e, index) => {
            var PreAaproveSplit = []
            PreAaproveSplit = e.pathImage.split("/")
            if (PreAaproveSplit[3] === "13") {
              if (e.type === 3) {
                return (
                  <Row justify={'center'}>
                    <Space direction='vertical'>
                      <Checkbox checked={checkedState[e.id]} style={{ margin: 10 }} key={index} onChange={(es) => onChangeCheckbox(es, e)}>
                        <Image width={750} key={index} src={`${getImagessPre}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                        <p><Button onClick={() => showConfirm(e, index)} type='primary' danger>ลบรูปภาพ</Button></p>
                      </Checkbox>
                    </Space>
                  </Row>
                );
              }
            }
            return null;
          })}
          {/* </Row> */}
        </Space>
        <Divider />
      </>
    )
  }
  const renderDivision = ({ item, index }) => {
    return (
      <>
        <Row justify={'left'}><b><u>ใบแบ่งใบต่อ ({item.numberLand})</u></b></Row>
        <Space direction='vertical'>
          {/* <Row gutter={32} justify={'center'}> */}
          {item?.img?.map((e, index) => {
            var PreAaproveSplit = []
            PreAaproveSplit = e.pathImage.split("/")
            if (PreAaproveSplit[3] === "13") {
              if (e.type === 10) {
                return (
                  <Space direction='vertical'>
                    <Checkbox checked={checkedState[e.id]} style={{ margin: 10 }} key={index} onChange={(es) => onChangeCheckbox(es, e)}>
                      <Image width={750} key={index} src={`${getImagessPre}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                      <p><Button onClick={() => showConfirm(e, index)} type='primary' danger>ลบรูปภาพ</Button></p>
                    </Checkbox>
                  </Space>
                );
              }
            }
            return null;
          })}
          {/* </Row> */}
        </Space>
        <Divider />
      </>
    )
  }
  const renderLawang = ({ item, index }) => {
    return (
      <>
        <Row justify={'left'}><b><u>ระวาง ({item.numberLand})</u></b></Row>
        <Space direction='vertical'>
          {/* <Row gutter={32} justify={'center'}> */}
          {item?.img?.map((e, index) => {
            var PreAaproveSplit = []
            PreAaproveSplit = e.pathImage.split("/")
            if (PreAaproveSplit[3] === "13") {
              if (e.type === 11) {
                return (
                  <Space direction='vertical'>
                    <Checkbox checked={checkedState[e.id]} style={{ margin: 10 }} key={index} onChange={(es) => onChangeCheckbox(es, e)}>
                      <Image width={750} key={index} src={`${getImagessPre}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                      <p><Button onClick={() => showConfirm(e, index)} type='primary' danger>ลบรูปภาพ</Button></p>
                    </Checkbox>
                  </Space>
                );
              }
            }
            return null;
          })}
          {/* </Row> */}
        </Space>
        <Divider />
      </>
    )
  }

  // console.log("newDataLand", newDataLand)

  return (
    <div>
      <Card style={{ padding: 20 }} className="modal-img">
        <Spin spinning={loading} size='large' tip=" Loading... ">
          <div style={{ textAlign: 'center' }}>
            <Row style={{ textAlign: 'center' }} >
              <Col span={24} style={{ fontSize: '30px' }} >หน้าคู่มือ/โฉนด (ใหม่)</Col>
            </Row>
            <Row style={{ textAlign: 'right' }} >
              <Col span={24} style={{ fontSize: '30px' }} >
                <Space>
                  {/* <Checkbox defaultChecked={true} disabled style={{ margin: 10 }} onChange={(es) => onChangeCheckboxAll(es.target.checked)}>
                    เลือกรูปหน้าคู่มือ/โฉนดทั้งหมด (ใหม่)
                  </Checkbox> */}
                  <Button type='primary' danger onClick={showConfirmAll}>ลบรูปภาพที่เลือก</Button>
                  <Button style={{ background: '#bfbfbf', color: '#000000', }} onClick={downloadImages}>ดาวน์โหลดรูปภาพ</Button>
                </Space>
              </Col>
            </Row>
            <Divider></Divider>
            <Form
              labelCol={{
                span: 12,
              }}
              wrapperCol={{
                span: 12,
              }}
              form={form}
            >
              <Row>
                <Col span={24}>
                  <Row gutter={32} justify={'center'}>
                    <Space direction="vertical" >
                      <Image.PreviewGroup>
                        {
                          detail?.car === "car" ?
                            <>
                              {dataModalEdit?.map((e, index) => {
                                var PreAaproveSplit = []
                                PreAaproveSplit = e.pathImage.split("/")
                                if (PreAaproveSplit[3] === "13") {
                                  if (e.type === 2) { // คู่มือ
                                    return (
                                      <Row justify={'center'}>
                                        <Space direction='vertical'>
                                          <Checkbox checked={checkedState[e.id]} style={{ margin: 10 }} key={index} onChange={(es) => onChangeCheckbox(es, e)}>
                                            <Image width={750} key={index} src={`${getImagessPre}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                            <p><Button onClick={() => showConfirm(e, index)} type='primary' danger>ลบรูปภาพ</Button></p>
                                          </Checkbox>
                                        </Space>
                                      </Row>
                                    );
                                  }
                                  return null;
                                }
                                return null;
                              }
                              )}
                            </>
                            :
                            <>
                              {
                                newDataLand?.map((item, index) => {
                                  return renderTitleDeed({ item, index, key: `{item.identification} - ${index}` })
                                })
                              }
                              {
                                newDataLand?.map((item, index) => {
                                  return renderDivision({ item, index, key: `{item.identification} - ${index}` })
                                })
                              }
                              {
                                newDataLand?.map((item, index) => {
                                  return renderLawang({ item, index, key: `{item.identification} - ${index}` })
                                })
                              }
                            </>
                        }
                      </Image.PreviewGroup>
                    </Space>
                  </Row>
                </Col>
              </Row>
              <Divider />
              <div style={{ textAlign: "center" }} >
                <Space>
                  {/* <Button type="primary" style={{ background: '#bfbfbf', color: '#000000', }} onClick={onChangeBack}>ย้อนกลับ</Button>
                  <Button type="primary" onClick={handleChangepage}>
                    ต่อไป
                  </Button> */}
                  {/* <Button style={{ background: '#bfbfbf', color: '#000000', }} onClick={downloadImages}>ดาวน์โหลดรูปภาพ</Button> */}
                </Space>
              </div>
            </Form>
            {/* </Spin> */}
          </div>
        </Spin>
      </Card >
    </div >
  )
}
