import React, { useState, useEffect } from 'react'
import { Modal, Form, Button, Card, Divider, Tag, Space, Row, Col, notification, Image } from 'antd'
import axios from 'axios';
import dayjs from 'dayjs';
import NoteRe from './NoteRe';
import { getImagess, loadLandPGRE, loadCarPGRE, acceptpgre, getcontnoJojonoi } from '../../file_mid/all_api';
import ModalInstallment from './ModalInstallment';
import NoteRe2 from '../../approval_rate_car/modals/NoteRe';

function ModalAcceptRe({ open, dataFromTable, close }) {
    const token = localStorage.getItem('token');
    const [api, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(false);
    const [isModalNote, setIsModalNote] = useState(false);
    const user = localStorage.getItem('username');
    const [allData, setAllData] = useState();
    const [newdata, setNewData] = useState();
    const [imageBlobzz, setImageBlobzz] = useState([]);
    const [isModalTable, setIsModalTable] = useState(false)
    const [isModalCancelCaseRe, setIsModalCancelCaseRe] = useState(false);
    const [preTypeNote, setPreTypeNote] = useState();

    const [moonnee, setMoonnee] = useState()
    const [hug, setHug] = useState()
    const [kong, setKong] = useState()
    const [jaimoney, setJaimoney] = useState()
    const [yodjut, setYodjut] = useState()
    const [dateceo, setDateceo] = useState()
    const [exp, setExp] = useState()
    const [ngod, setNgod] = useState()
    const [pon, setPon] = useState()

    // console.log("allData", allData)
    // console.log("newdata", newdata)

    useEffect(() => {
        console.log("1")
        loadData()
    }, [])

    const loadData = async () => {
        setLoading(true)
        if (dataFromTable?.typeLoan === "car") {
            // await axios.post("http://localhost:8080/api/loadCarPGRE", { carId: dataFromTable.carId, customerId: dataFromTable.customerId })
            await axios.post(loadCarPGRE, { carId: dataFromTable.carId, customerId: dataFromTable.customerId })
                // await axios.post(findcarmk, { carId: dataFromTable.carId, customerId: dataFromTable.customerId })
                .then(async (res) => {
                    if (res.data) {
                        console.log("loadData car", res.data)
                        setAllData(res.data)
                        setImageBlobzz(res.data.cars?.img)
                        loadDataContno(res.data.cars.ISSUNO)
                        calYellowCard(res.data?.cars?.carsApproveCeo)
                    }
                }).catch((err) => {
                    console.log("err ", err)
                })
        } else {
            // await axios.post("http://localhost:8080/api/loadLandPGRE", { landId: dataFromTable.carId, customerId: dataFromTable.customerId })
            await axios.post(loadLandPGRE, { landId: dataFromTable.carId, customerId: dataFromTable.customerId })
                // await axios.post(findcarmk, { carId: dataFromTable.carId, customerId: dataFromTable.customerId })
                .then(async (res) => {
                    if (res.data) {
                        // console.log("loadData land", res.data)
                        setAllData(res.data)
                        setImageBlobzz(res.data.land?.img)
                        // loadDataContnoLand(res.data.lands.ISSUNO)
                    }
                }).catch((err) => {
                    console.log("err ", err)
                })
        }
        setLoading(false)
    }

    const calYellowCard = (value) => {
        // console.log("calYellowCard", value)
        // มูลหนี้ balanc
        // หักส่วนลด (balanc-(approvedLoanAmount+(newDamt+followPay)))
        // คงเหลือ มูลหนี้ - หักส่วนลด
        // จ่ายเงิน (newDamt+followPay)
        // ยอดจัด approvedLoanAmount
        // วันที่ approvalDate
        // ใบนี้หมดอายุ ณ วันที่ newOfferDate
        // จำนวนงวด pgLoanTerm
        // ผ่อน installment x pgLoanTerm งวด (รวมภาษีมูลค่าเพิ่ม) 
        // วันที่ dayjs(dataCarAun?.approvalDate).format("DD-MM-YYYY")

        setMoonnee(currencyFormatOne(~~value?.balanc))
        setHug(currencyFormatOne(~~value?.balanc - (~~value?.approvedLoanAmount + (~~value?.newDamt + ~~value?.followPay))))
        setKong(currencyFormatOne(~~value?.balanc - (~~value?.balanc - (~~value?.approvedLoanAmount + (~~value?.newDamt + ~~value?.followPay)))))
        setJaimoney(currencyFormatOne(~~value?.newDamt + ~~value?.followPay))
        setYodjut(currencyFormatOne(~~value?.approvedLoanAmount))
        setDateceo(dayjs(value?.approvalDate).format("DD-MM-YYYY"))
        setExp(dayjs(value?.newOfferDate).format("DD-MM-YYYY"))
        setNgod(~~value?.pgLoanTerm)
        setPon(currencyFormatOne(~~value?.installment) + " x " + ~~value?.pgLoanTerm)
    }

    const loadDataContno = async (ISSUNO) => {
        console.log("ISSUNO", ISSUNO)
        setLoading(true)
        await axios.get(`${getcontnoJojonoi}/${ISSUNO}`)
            .then(async res => {
                if (res.data) {
                    console.log("Contno42", res.data)
                    await setNewData(res.data)
                    setLoading(false)
                }
            }).catch((err) => {
                console.log("err", err)
            })
        setLoading(false)
    }

    // const loadDataContnoLand = async () => {
    //     setLoading(true)
    //     await axios.post(alldataland1, { CONTNO: data?.CONTNO })
    //         .then(async res => {
    //             if (res.data) {
    //                 console.log("loadDataContnoLand", res.data)
    //                 await setNewData(res.data)
    //                 setLoading(false)
    //             }
    //         }).catch((err) => {
    //             console.log("err", err)
    //         })
    //     setLoading(false)
    // }

    // console.log("allData", allData)

    const handleCancel = () => {
        close(false);
    };

    const currencyFormatOne = (amount) => {
        if (amount) {
            return Number(amount)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, '$&,')
        } else {
            return "-"
        }
    }

    const handleNotAccept = () => {
        setPreTypeNote("ปฏิเสธคำร้อง")
        setIsModalNote(true)
    };

    // const successAccept = (placement) => {
    //     api.success({
    //         message: `Success`,
    //         // description:
    //         //     'สถานะเปลี่ยนเป็น รออนุมัติ',
    //         placement,
    //     });
    // };
    // const errorAccept = (placement) => {
    //     api.error({
    //         message: `Error`,
    //         // description:
    //         //     'เนื่องจากเคสนี้ถูกรับงานไปแล้ว',
    //         placement,
    //     });
    // };

    const handleOk = async (value) => {
        let sendData
        // setLoading(true)
        if (value === "yes") {
            if (dataFromTable.typeLoan === "car") {
                sendData = {
                    reLoanId: dataFromTable.reLoanId,
                    acceptByPg: user,
                    acceptDatePg: dayjs(),
                    approvalStatus: "รับคำร้อง",
                    ISSUNO: dataFromTable.ISSUNO,
                    username: dataFromTable.proposalBy,
                    productLoanType: allData?.cars?.carLoanDetailsRe?.productLoanType
                }
            } else {
                sendData = {
                    reLoanId: dataFromTable.reLoanId,
                    acceptByPg: user,
                    acceptDatePg: dayjs(),
                    approvalStatus: "รับคำร้อง",
                    ISSUNO: dataFromTable.ISSUNO,
                    username: dataFromTable.proposalBy,
                    productLoanType: allData?.land?.landLoanDetailsRe?.productLoanLandType
                }
            }
        }
        setPreTypeNote("รับคำร้อง")
        setIsModalNote(true)
        // else {
        //     sendData = {
        //         reLoanId: dataFromTable.reLoanId,
        //         acceptByPg: user,
        //         acceptDatePg: dayjs(),
        //         approvalStatus: "ปฏิเสธคำร้อง"
        //     }
        // }
        // console.log("sendData", { ...dataFromTable, ...sendData })

        // await axios.put(acceptpgre, { ...dataFromTable, ...sendData })
        //     .then(res => {
        //         if (res.status === 200) {
        //             successAccept("top")
        //             setTimeout(() => {
        //                 close(false);
        //             }, 2000)
        //         }
        //     }).catch((err) => {
        //         errorAccept("top")
        //         console.log(err)
        //     })
    };

    const handleOpenTable = () => {
        setIsModalTable(true)
    }

    const handleCancelCase = () => {
        setIsModalCancelCaseRe(true)
    }

    return (
        <div>
            <Modal title="กรุณายืนยันข้อมูล" open={open} onCancel={handleCancel} width={850} footer={[null]}>
                <Card>
                    {/* <Divider orientation="left"><b>รายละเอียด</b></Divider> */}
                    <Form
                        name="basic"
                        labelCol={{
                            span: 12,
                        }}
                        wrapperCol={{
                            span: 12,
                        }}>

                        {
                            dataFromTable.typeLoan === "car" ?
                                <>
                                    <Row>
                                        <Col span={16}></Col>
                                        <Col span={8} >
                                            <Form.Item label="ประเภทขอกู้" style={{ margin: 0 }}>
                                                <Tag color="green">{allData?.cars?.carLoanDetailsRe?.productLoanType}</Tag>
                                            </Form.Item>
                                            <Form.Item label="เลขสัญญา" style={{ margin: 0 }}>
                                                <Tag color="geekblue">{allData?.cars?.ISSUNO}</Tag>
                                            </Form.Item>
                                        </Col>
                                        <Divider orientation="left"><b>ข้อมูลลูกค้า</b></Divider>
                                        <Col span={12}>
                                            <Form.Item label="เลขบัตรประชาชน" style={{ margin: 0 }}>
                                                {allData?.identificationId}
                                            </Form.Item>
                                            <Form.Item label="ชื่อลูกค้า" style={{ margin: 0 }}>
                                                {dataFromTable.snam}{dataFromTable.firstName} {dataFromTable.lastName}
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label="เกรด" style={{ margin: 0 }}>
                                                <Tag color={"green"}>{allData?.cars?.carLoanDetailsRe?.GRDCOD}</Tag>
                                            </Form.Item>
                                        </Col>

                                        <Divider orientation="left"><b>รายละเอียดสินค้า</b></Divider>
                                        <Row className='center'>
                                            <Divider orientation="center"><b>เรทรถเดิม</b></Divider>
                                            <Col span={12}>
                                                <Form.Item label="รายละเอียด" style={{ margin: 0 }}>
                                                    {newdata?.custmast[0]?.TYPE ? newdata?.custmast[0]?.TYPE + ' ' : null}
                                                    {newdata?.custmast[0]?.MODEL ? newdata?.custmast[0]?.MODEL + ' ' : null}
                                                    {newdata?.custmast[0]?.BAAB ? newdata?.custmast[0]?.BAAB + ' ' : null}
                                                    {newdata?.custmast[0]?.MANUYR ? newdata?.custmast[0]?.MANUYR + ' ' : null}
                                                </Form.Item>
                                                <Form.Item label="สีรถ" style={{ margin: 0 }}>
                                                    {newdata?.custmast[0]?.COLOR ? newdata?.custmast[0]?.COLOR : null}
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item label="เลขทะเบียนรถ" style={{ margin: 0 }}>
                                                    {newdata?.custmast[0]?.REGNO ? newdata?.custmast[0]?.REGNO + ' ' : null}
                                                    {newdata?.custmast[0]?.DORECV ? newdata?.custmast[0]?.DORECV : null}
                                                </Form.Item>
                                                {/* <Form.Item label="เรทรถ" style={{ margin: 0 }}>
                                                    <b>{currencyFormatOne(allData?.cars?.carPrice)}</b> บาท
                                                </Form.Item> */}
                                            </Col>
                                            <Divider orientation="center"><b>เรทรถที่การตลาดเลือก</b></Divider>
                                            <Col span={12}>
                                                <Form.Item label="รายละเอียด" style={{ margin: 0 }}>
                                                    {allData?.cars?.carBrand} {allData?.cars?.carModel} {allData?.cars?.carYear}
                                                </Form.Item>
                                                <Form.Item label="สีรถ" style={{ margin: 0 }}>
                                                    {allData?.cars?.carColor}
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item label="เลขทะเบียนรถ" style={{ margin: 0 }}>
                                                    {allData?.cars?.carPlateNumber} {allData?.cars?.carProvince}
                                                </Form.Item>
                                                <Form.Item label="เรทรถ" style={{ margin: 0 }}>
                                                    <b>{currencyFormatOne(allData?.cars?.carPrice)}</b> บาท
                                                </Form.Item>
                                            </Col>

                                        </Row>


                                        <Divider orientation="left"><b>รายละเอียดสัญญาเก่า</b></Divider>
                                        {
                                            allData?.cars?.carsApproveCeo?.id > 0 ?
                                                <>
                                                    <Card style={{ width: '1000px', backgroundColor: "lightyellow" }}>
                                                        <Row justify={'left'}><b><u style={{ backgroundColor: '#f39c12' }}>เงื่อนไขการปรับโครงสร้าง ({allData?.cars?.carsApproveCeo?.CONTNO})</u></b></Row>
                                                        <Col span={24} style={{ textAlign: 'right' }}>
                                                            <Image
                                                                width={100}
                                                                src={`${process.env.PUBLIC_URL}/logo.jpg`}
                                                                alt="My Image"
                                                            />
                                                        </Col>
                                                        <Row>
                                                            <Col span={12}>
                                                                <Form.Item style={{ margin: 0 }} label='วันที่'>
                                                                    <b>{dateceo}</b>
                                                                </Form.Item>
                                                                <Form.Item style={{ margin: 0 }} label='มูลหนี้เช่าซื้อคงเหลือรวม'>
                                                                    <b>{moonnee}</b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </Form.Item>
                                                                <Form.Item style={{ margin: 0 }} label='หักส่วนลด'>
                                                                    <b>{hug}</b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </Form.Item>
                                                                <Form.Item style={{ margin: 0 }} label='ผ่อน'>
                                                                    <b>{pon}</b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        งวด
                                                                        (รวมภาษีมูลค่าเพิ่ม)
                                                                    </span>
                                                                </Form.Item>
                                                                <Form.Item label="ตารางการผ่อน" style={{ margin: 0 }}>
                                                                    <Button type='primary' style={{ backgroundColor: "#FEA13F" }} onClick={handleOpenTable} >ตารางการผ่อน</Button>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item style={{ margin: 0 }} label='คงเหลือ'>
                                                                    <b>{kong}</b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </Form.Item>
                                                                <Form.Item style={{ margin: 0 }} label='จ่ายเงิน'>
                                                                    <b>{jaimoney}</b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </Form.Item>
                                                                <Form.Item style={{ margin: 0 }} label='จำนวนงวด'>
                                                                    <b>{ngod}</b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </Form.Item>
                                                                <Form.Item style={{ margin: 0 }} label='ยอดจัด'>
                                                                    <b style={{ fontSize: '20px' }}><u>{yodjut}</u></b>
                                                                    {/* <b>{currencyFormatOne(allData?.cars?.carsApproveCeo?.approvedLoanAmount)}</b> */}
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                </>
                                                :
                                                <>
                                                    <Col span={12}>
                                                        <Form.Item label="ยอดจัดครั้งที่แล้ว" style={{ margin: 0 }}>
                                                            {currencyFormatOne(allData?.cars?.carLoanDetailsRe?.tcshprc)} บาท
                                                        </Form.Item>
                                                        <Form.Item label="ราคาเช่าซื้อครั้งที่แล้ว" style={{ margin: 0 }}>
                                                            {currencyFormatOne(allData?.cars?.carLoanDetailsRe?.balanc)} บาท
                                                        </Form.Item>
                                                        <Form.Item label="ยอดที่ชำระมาแล้วทั้งหมด" style={{ margin: 0 }}>
                                                            {currencyFormatOne(allData?.cars?.carLoanDetailsRe?.totalpayment)} บาท
                                                        </Form.Item>
                                                        <Form.Item label="ล/น คงเหลือรวม" style={{ margin: 0 }}>
                                                            {currencyFormatOne(allData?.cars?.carLoanDetailsRe?.neekong)} บาท
                                                        </Form.Item>
                                                        <Form.Item label="ยอดรีไฟแนนซ์เพื่อจัดใหม่" style={{ margin: 0 }}>
                                                            <b>{currencyFormatOne(allData?.cars?.carLoanDetailsRe?.re1)}</b> บาท
                                                        </Form.Item>
                                                        <Form.Item label="ใบนี้หมดอายุ ณ วันที่" style={{ margin: 0 }}>
                                                            <b style={{ color: "red" }}>{dayjs(allData?.cars?.carLoanDetailsRe?.newOfferDate).format("DD-MM-YYYY")}</b>
                                                        </Form.Item>
                                                        <Form.Item label="ตารางการผ่อน" style={{ margin: 0 }}>
                                                            <Button type='primary' style={{ backgroundColor: "#FEA13F" }} onClick={handleOpenTable} >ตารางการผ่อน</Button>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Form.Item label="เบี้ยปรับ" style={{ margin: 0 }}>
                                                            {currencyFormatOne(allData?.cars?.carLoanDetailsRe?.beeypup)} บาท
                                                        </Form.Item>
                                                        <Form.Item label="ลูกหนี้อื่นๆ" style={{ margin: 0 }}>
                                                            {currencyFormatOne(allData?.cars?.carLoanDetailsRe?.OTHR)} บาท
                                                        </Form.Item>
                                                        <Form.Item label="จำนวนงวดที่ผ่อนมาแล้ว" style={{ margin: 0 }}>
                                                            {currencyFormatOne(allData?.cars?.carLoanDetailsRe?.NOPAY)} งวด
                                                        </Form.Item>
                                                        <Form.Item label="จำนวนงวดทั้งหมด" style={{ margin: 0 }}>
                                                            {currencyFormatOne(allData?.cars?.carLoanDetailsRe?.nopays)} งวด
                                                        </Form.Item>
                                                        <Form.Item label="ตัดสด งวดที่" style={{ margin: 0 }}>
                                                            {currencyFormatOne(allData?.cars?.carLoanDetailsRe?.reqNo)}
                                                        </Form.Item>
                                                        <Divider style={{ margin: 3 }} />
                                                        {
                                                            allData?.cars?.carLoanDetailsRe?.productLoanType === "Pre-Aaprove-เปลี่ยนสัญญา" ||
                                                                allData?.cars?.carLoanDetailsRe?.productLoanType === "Pre-Aaprove-รี+เปลี่ยนสัญญา" ||
                                                                allData?.cars?.carLoanDetailsRe?.productLoanType === "เปลี่ยนสัญญา" ||
                                                                allData?.cars?.carLoanDetailsRe?.productLoanType === "รี+เปลี่ยนสัญญา"
                                                                ?
                                                                <>
                                                                    <div className='center'>
                                                                        <b>
                                                                            <Form.Item label="ค่าเปลี่ยนสัญญา" style={{ margin: 0 }}>
                                                                                {currencyFormatOne(allData?.cars?.carLoanDetailsRe?.priceChange)}  บาท
                                                                            </Form.Item>
                                                                            <Form.Item label="ยอดค้าง + เบี้ยปรับ" style={{ margin: 0 }}>
                                                                                {currencyFormatOne(allData?.cars?.carLoanDetailsRe?.overdue)}  บาท
                                                                            </Form.Item>
                                                                            <Form.Item label="ค่างวดล่วงหน้า 3 หน้า" style={{ margin: 0 }}>
                                                                                {currencyFormatOne(allData?.cars?.carLoanDetailsRe?.preChange)}  บาท
                                                                            </Form.Item>
                                                                            <Form.Item label="ยอดรวม" style={{ margin: 0 }}>
                                                                                <b> {currencyFormatOne(~~allData?.cars?.carLoanDetailsRe?.preChange + ~~allData?.cars?.carLoanDetailsRe?.overdue + ~~allData?.cars?.carLoanDetailsRe?.priceChange
                                                                                )}</b>
                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                    บาท
                                                                                </span>
                                                                            </Form.Item>
                                                                        </b>
                                                                    </div>
                                                                </>
                                                                : null
                                                        }
                                                    </Col>
                                                </>
                                        }
                                        <Image.PreviewGroup>
                                            <Col span={24}>
                                                <Divider orientation="left"><b>เอกสารขอรี/ปรับ/เปลี่ยน</b></Divider>
                                                <Space size={[10, 10]} wrap>
                                                    {imageBlobzz?.map((e, index) => {
                                                        if (e.type === 14) {
                                                            return (
                                                                <>
                                                                    <Image width={50} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                </>
                                                            );
                                                        }
                                                        return null;
                                                    })}
                                                </Space>
                                            </Col>
                                        </Image.PreviewGroup>
                                    </Row>
                                </>
                                :
                                <>
                                    <Row>
                                        <Col span={16}></Col>
                                        <Col span={8} >
                                            <Form.Item label="ประเภทขอกู้" style={{ margin: 0 }}>
                                                <Tag color="green">{allData?.land?.landLoanDetailsRe?.productLoanLandType}</Tag>
                                            </Form.Item>
                                            <Form.Item label="เลขสัญญา" style={{ margin: 0 }}>
                                                <Tag color="geekblue">{allData?.land?.ISSUNO}</Tag>
                                            </Form.Item>
                                        </Col>
                                        <Divider orientation="left"><b>ข้อมูลลูกค้า</b></Divider>
                                        <Col span={12}>
                                            <Form.Item label="เลขบัตรประชาชน" style={{ margin: 0 }}>
                                                {allData?.identificationId}
                                            </Form.Item>
                                            <Form.Item label="ชื่อลูกค้า" style={{ margin: 0 }}>
                                                {dataFromTable.snam}{dataFromTable.firstName} {dataFromTable.lastName}
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label="เกรด" style={{ margin: 0 }}>
                                                <Tag color={"green"}>{allData?.land?.landLoanDetailsRe?.GRDCOD}</Tag>
                                            </Form.Item>
                                        </Col>

                                        <Divider orientation="left"><b>รายละเอียดสินค้า</b></Divider>
                                        {
                                            allData?.land?.productTypeLand === 1 ?
                                                <>
                                                    <Col span={12}>
                                                        <Form.Item label='ประเภทเอกสาร' style={{ margin: 0 }}>
                                                            {allData?.land?.productTypeLand === 1 ? "นส.3" : "นส.4"}
                                                        </Form.Item>
                                                        <Form.Item name='province' label='จังหวัด' style={{ margin: 0 }}>
                                                            {allData?.land?.province}
                                                        </Form.Item>
                                                        <Form.Item name='district' label='อำเภอ' style={{ margin: 0 }}>
                                                            {allData?.land?.district}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Form.Item label='เลขโฉนด' name='numberLand' style={{ margin: 0 }}>
                                                            {allData?.land?.numberLand}
                                                        </Form.Item>
                                                        <Form.Item label='พื้นที่ไร่' name='rai' style={{ margin: 0 }}>
                                                            {allData?.land?.rai}
                                                        </Form.Item>
                                                        <Form.Item label='พื้นที่งาน' name='workArea' style={{ margin: 0 }}>
                                                            {allData?.land?.workArea}
                                                        </Form.Item>
                                                        <Form.Item label='พื้นที่ตารางวา' name='squareWaArea' style={{ margin: 0 }}>
                                                            {allData?.land?.squareWaArea}
                                                        </Form.Item>
                                                    </Col>
                                                </>
                                                :
                                                <>
                                                    <Col span={12}>
                                                        <Form.Item label='ประเภทเอกสาร' style={{ margin: 0 }}>
                                                            {allData?.land?.productTypeLand === 1 ? "นส.3" : "นส.4"}
                                                        </Form.Item>
                                                        <Form.Item name='province' label='จังหวัด' style={{ margin: 0 }}>
                                                            {allData?.land?.province}
                                                        </Form.Item>
                                                        <Form.Item name='district' label='อำเภอ' style={{ margin: 0 }}>
                                                            {allData?.land?.district}
                                                        </Form.Item>
                                                        <Form.Item label='เลขโฉนด' name='numberLand' style={{ margin: 0 }}>
                                                            {allData?.land?.numberLand}
                                                        </Form.Item>
                                                        <Form.Item label='เลขที่ดินหรือระวาง' name='numberLandLawang' style={{ margin: 0 }}>
                                                            {allData?.land?.numberLandLawang}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Form.Item label='พื้นที่ไร่' name='rai' style={{ margin: 0 }}>
                                                            {allData?.land?.rai}
                                                        </Form.Item>
                                                        <Form.Item label='พื้นที่งาน' name='workArea' style={{ margin: 0 }}>
                                                            {allData?.land?.workArea}
                                                        </Form.Item>
                                                        <Form.Item label='พื้นที่ตารางวา' name='squareWaArea' style={{ margin: 0 }}>
                                                            {allData?.land?.squareWaArea}
                                                        </Form.Item>
                                                        <Form.Item label='ราคาประเมิน' name='landPrice' style={{ margin: 0 }}>
                                                            {allData?.land?.landPrice}
                                                        </Form.Item>
                                                        <Form.Item label='ราคารวม' name='resultLandPrice' style={{ margin: 0 }}>
                                                            {allData?.land?.resultLandPrice}
                                                        </Form.Item>
                                                    </Col>
                                                </>
                                        }
                                        <Divider orientation="left"><b>ราคาประเมินที่ดิน</b></Divider>
                                        <Col span={12}>
                                            <Form.Item label="ราคาประเมินที่ดิน" style={{ margin: 0 }}>
                                                <Space>
                                                    <b>{currencyFormatOne(allData?.land?.approvalRe?.minPrice)}</b>
                                                    -
                                                    <b>{currencyFormatOne(allData?.land?.approvalRe?.maxPrice)}</b>
                                                    บาท
                                                </Space>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label="หมายเหตุ" style={{ margin: 0 }}>
                                                <b>{allData?.land?.approvalRe?.note}</b>
                                            </Form.Item>
                                        </Col>
                                        <Divider orientation="left"><b>รายละเอียดสัญญาเก่า</b></Divider>
                                        <Col span={12}>
                                            <Form.Item label="ยอดจัดครั้งที่แล้ว" style={{ margin: 0 }}>
                                                {currencyFormatOne(allData?.land?.landLoanDetailsRe?.tcshprc)} บาท
                                            </Form.Item>
                                            <Form.Item label="ราคาเช่าซื้อครั้งที่แล้ว" style={{ margin: 0 }}>
                                                {currencyFormatOne(allData?.land?.landLoanDetailsRe?.balanc)} บาท
                                            </Form.Item>
                                            <Form.Item label="ยอดที่ชำระมาแล้วทั้งหมด" style={{ margin: 0 }}>
                                                {currencyFormatOne(allData?.land?.landLoanDetailsRe?.totalpayment)} บาท
                                            </Form.Item>
                                            <Form.Item label="ล/น คงเหลือรวม" style={{ margin: 0 }}>
                                                {currencyFormatOne(allData?.land?.landLoanDetailsRe?.neekong)} บาท
                                            </Form.Item>

                                            <Form.Item label="ยอดรีไฟแนนซ์เพื่อจัดใหม่" style={{ margin: 0 }}>
                                                <b>{currencyFormatOne(allData?.land?.landLoanDetailsRe?.re1)}</b> บาท
                                            </Form.Item>
                                            <Form.Item label="ใบนี้หมดอายุ ณ วันที่" style={{ margin: 0 }}>
                                                <b style={{ color: "red" }}>{dayjs(allData?.land?.landLoanDetailsRe?.newOfferDate).format("DD-MM-YYYY")}</b>
                                            </Form.Item>
                                            <Form.Item label="ตารางการผ่อน" style={{ margin: 0 }}>
                                                <Button type='primary' style={{ backgroundColor: "#FEA13F" }} onClick={handleOpenTable} >ตารางการผ่อน</Button>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label="เบี้ยปรับ" style={{ margin: 0 }}>
                                                {currencyFormatOne(allData?.land?.landLoanDetailsRe?.beeypup)} บาท
                                            </Form.Item>
                                            <Form.Item label="ลูกหนี้อื่นๆ" style={{ margin: 0 }}>
                                                {currencyFormatOne(allData?.land?.landLoanDetailsRe?.OTHR)} บาท
                                            </Form.Item>
                                            <Form.Item label="จำนวนงวดที่ผ่อนมาแล้ว" style={{ margin: 0 }}>
                                                {currencyFormatOne(allData?.land?.landLoanDetailsRe?.NOPAY)} งวด
                                            </Form.Item>
                                            <Form.Item label="จำนวนงวดทั้งหมด" style={{ margin: 0 }}>
                                                {currencyFormatOne(allData?.land?.landLoanDetailsRe?.nopays)} งวด
                                            </Form.Item>
                                            <Form.Item label="ตัดสด งวดที่" style={{ margin: 0 }}>
                                                {currencyFormatOne(allData?.land?.landLoanDetailsRe?.reqNo)}
                                            </Form.Item>
                                            <Divider style={{ margin: 3 }} />
                                            {
                                                allData?.land?.landLoanDetailsRe?.productLoanLandType === "Pre-Aaprove-เปลี่ยนสัญญา" ||
                                                    allData?.land?.landLoanDetailsRe?.productLoanLandType === "Pre-Aaprove-รี+เปลี่ยนสัญญา" ||
                                                    allData?.land?.landLoanDetailsRe?.productLoanLandType === "เปลี่ยนสัญญา" ||
                                                    allData?.land?.landLoanDetailsRe?.productLoanLandType === "รี+เปลี่ยนสัญญา"
                                                    ?
                                                    <>
                                                        <div className='center'>
                                                            <Form.Item label="ค่าเปลี่ยนสัญญา" style={{ margin: 0 }}>
                                                                {currencyFormatOne(allData?.land?.landLoanDetailsRe?.priceChange)} บาท
                                                            </Form.Item>
                                                            <Form.Item label="ยอดค้าง + เบี้ยปรับ" style={{ margin: 0 }}>
                                                                {currencyFormatOne(allData?.land?.landLoanDetailsRe?.overdue)} บาท
                                                            </Form.Item>
                                                            <Form.Item label="ค่างวดล่วงหน้า" style={{ margin: 0 }}>
                                                                {currencyFormatOne(allData?.land?.landLoanDetailsRe?.preChange)} บาท
                                                            </Form.Item>
                                                            <Form.Item label="ยอดรวม" style={{ margin: 0 }}>
                                                                <b> {currencyFormatOne(~~allData?.land?.landLoanDetailsRe?.preChange + ~~allData?.land?.landLoanDetailsRe?.overdue + ~~allData?.land?.landLoanDetailsRe?.priceChange
                                                                )}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </Form.Item>

                                                        </div>
                                                    </>
                                                    :
                                                    null
                                            }

                                        </Col>
                                        <Image.PreviewGroup>
                                            <Col span={24}>
                                                <Divider orientation="left"><b>เอกสารขอรี/ปรับ/เปลี่ยน</b></Divider>
                                                {/* <span>รูปที่ดิน : </span> */}
                                                <Space size={[10, 10]} wrap>
                                                    {imageBlobzz?.map((e, index) => {
                                                        if (e.type === 14) {
                                                            return (
                                                                <>
                                                                    <Image width={50} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                </>
                                                            );
                                                        }
                                                        return null;
                                                    })}
                                                </Space>
                                            </Col>
                                        </Image.PreviewGroup>
                                    </Row>
                                </>
                        }
                        <Row>
                            <Divider />
                            <Col span={24} style={{ textAlign: "center" }}>
                                <Space>
                                    <Button type='text' onClick={handleCancel} style={{ backgroundColor: "gray" }}>ปิด</Button>
                                    {
                                        dataFromTable?.typeLoan === "car" ?
                                            <Button type='primary' danger onClick={handleCancelCase}>ตีกลับ</Button>
                                            : null
                                    }
                                    <Button type='primary' onClick={handleNotAccept} disabled={loading} danger >ปฏิเสธคำร้อง</Button>
                                    <Button type='primary' onClick={() => handleOk("yes")} disabled={loading} style={{ backgroundColor: "Green" }}>รับคำร้อง</Button>
                                </Space>
                            </Col>
                        </Row>
                        {
                            isModalNote ?
                                <NoteRe
                                    open={isModalNote}
                                    close={setIsModalNote}

                                    loanId={dataFromTable.reLoanId}
                                    CloseAll={handleCancel}
                                    typeNote={preTypeNote}
                                    // loanRe={loanRe}
                                    // typeData={typeData}
                                    // checkGuas={checkGuas}
                                    // dataCarLand={dataPost}
                                    // contno={dataFromTable.ISSUNO}
                                    // dataInterestRate={interestRate}
                                    // dataField={dataField}
                                    // guarantorData={guarantor1}
                                    allData={allData}
                                    dataFromTable={dataFromTable}
                                />
                                : null
                        }
                        {
                            isModalTable ?
                                <ModalInstallment
                                    open={isModalTable}
                                    close={setIsModalTable}
                                    contno={dataFromTable.ISSUNO}
                                />
                                : null
                        }

                        {
                            isModalCancelCaseRe ?
                                <NoteRe2
                                    open={isModalCancelCaseRe}
                                    close={setIsModalCancelCaseRe}
                                    loanId={dataFromTable.reLoanId}
                                    CloseAll={handleCancel}
                                    typeNote={"คำร้องไม่ถูกต้อง"}
                                    allData={allData}
                                    dataFromTable={dataFromTable}
                                    typeData={"car"}
                                    pg={"pg"}
                                />
                                : null
                        }
                    </Form>
                </Card>
            </Modal>
            {contextHolder}
        </div>
    )
}

export default ModalAcceptRe