import React, { useEffect, useState } from 'react'
import { Button, Input, Form, DatePicker, Card, Col, Row, message, Select, Radio, Spin, Space, Divider } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { addCustomer } from '../../../../../redux/Customer';
import { addAddress } from '../../../../../redux/Address';
import { addPhone } from '../../../../../redux/Phone';
import { checkCCAP, checkduplicate, showrelationship1, testupload } from '../../../../file_mid/all_api';

export default function GCustomer({ close, next, page, setPage, chg, cusId }) {
    const [form] = Form.useForm()
    const branch = localStorage.getItem('branch');
    const dispatch = useDispatch()
    const customers = useSelector(state => state.customers)
    const [dataPost, setDataPost] = useState({ customerId: 0, mainId: cusId, locat: branch })

    const [dataAddress, setDataAddress] = useState() // address
    const [count, setCount] = useState()
    const [dataPhone, setDataPhone] = useState() // phone
    const [, setFileList] = useState() // รูปบัตรประชาชน
    const [loading, setLoading] = useState(false)

    const [value, setValue] = useState([])

    useEffect(() => {
        loadRelationship()
        if (customers.data.customerId !== undefined) {
            setDataPost(customers.data)
            form.setFieldsValue(
                {
                    ...customers.data,
                    birthdate: customers.data.birthdate ? dayjs(customers.data.birthdate) : '',
                    issudt: customers.data.issudt ? dayjs(customers.data.issudt) : '',
                    expdt: customers.data.expdt ? dayjs(customers.data.expdt) : '',
                }
            )
        }
    }, [])

    // console.log("dataPost", dataPost)

    const handleSubmit = async (e) => {
        delete dataPost.address
        delete dataPost.carLoanDetails
        delete dataPost.phones

        // console.log("dataPost", {...dataPost,  locat: branch})

        await axios.post(checkduplicate, { ...dataPost, mainId: cusId, locat: branch })
            .then(res => {
                if (res.data === 'duplicate information') {
                    chg('top')
                } else if (res.data === 'OK') {
                    // console.log("ok")
                    if (dataAddress !== undefined) {
                        dispatch(addAddress(dataAddress))
                    }
                    if (dataPhone !== undefined) {
                        dispatch(addPhone(dataPhone))
                    }
                    dispatch(addCustomer({ ...dataPost, locat: branch }))
                    // setTimeout(() => {
                    onChangeGo()
                    // }, 1000);
                }
            }).catch((err) => {
                console.log("err", err)
            })
    }

    const loadRelationship = async () => {
        await axios.get(showrelationship1)
            .then(res => {
                if (res.status === 200) {
                    setValue(res.data)
                }
            }).catch((err) => console.log(err))
    };

    const onChangeGo = () => {
        setPage(page + 1)
        next()
    }

    const handleCancel = () => {
        close(false)
    }
    ////////////// ข้อมูลยูสในเดต้าเบส ////////////////////////////////////////////////////////////////
    const loadDataOne = async (data) => {
        const one = { identificationId: data.identificationId }
        setLoading(true)
        await axios.post(checkCCAP, one)
            .then((res) => {
                if (res.data) {
                    message.info('มีข้อมูลในฐานข้อมูล')
                    if (window.confirm('คุณต้องการใช้ข้อมูลในฐานข้อมูลหรือไม่ ?')) {
                        // console.log('loadDataOne', res.data)
                        setDataPost(res.data)
                        setDataPhone(res.data.phones)
                        setDataAddress(res.data.address)
                        if (res.data.expdt == null || res.data.issudt == null) {
                            form.setFieldsValue(
                                {
                                    ...res.data,
                                    telp: res?.data?.phones[0]?.telp,
                                    birthdate: dayjs(res?.data?.birthdate),
                                    issudt: '',
                                    expdt: '',
                                }
                            )
                            setLoading(false)
                        }
                        else {
                            form.setFieldsValue(
                                {
                                    ...res.data,
                                    telp: res?.data?.phones[0]?.telp,
                                    birthdate: dayjs(res?.data?.birthdate),
                                    issudt: dayjs(res?.data?.issudt),
                                    expdt: dayjs(res?.data?.expdt),
                                }
                            )
                        }
                    } else {
                        setDataPost({ ...dataPost, ...data })
                        if (data.expdt == null || data.issudt == null) {
                            form.setFieldsValue(
                                {
                                    ...data,
                                    nickname: '',
                                    gender: '',
                                    birthdate: dayjs(data?.birthdate),
                                    issudt: '',
                                    expdt: '',
                                    telp: '',
                                }
                            )
                            setLoading(false)
                        }
                        else {
                            form.setFieldsValue(
                                {
                                    ...data,
                                    nickname: '',
                                    gender: '',
                                    birthdate: dayjs(data?.birthdate),
                                    issudt: dayjs(data?.issudt),
                                    expdt: dayjs(data?.expdt),
                                    telp: '',
                                }
                            )
                        }
                    }
                    setLoading(false)
                } else {
                    // console.log("อิอิ")
                    message.info('ไม่พบข้อมูลในฐานข้อมูล')
                    setDataPost({ ...data })
                    setDataPhone([])
                    if (data.expdt == undefined || data.issudt == undefined) {
                        form.setFieldsValue(
                            {
                                ...data,
                                nickname: '',
                                gender: '',
                                birthdate: dayjs(data?.birthdate),
                                issudt: '',
                                expdt: '',
                                telp: '',
                            }
                        )
                        setLoading(false)
                    } else {
                        form.setFieldsValue(
                            {
                                ...data,
                                nickname: '',
                                gender: '',
                                birthdate: dayjs(data?.birthdate),
                                issudt: '',
                                expdt: '',
                                telp: '',
                            }
                        )
                    }
                    setLoading(false)
                }
            })
            .catch((err) => console.log(err))
        setLoading(false)
    }
    /////////// ดึงข้อมูลจากรูปบัตรประชาชน /////////////////////////////////////////////////////////////////  
    const handleScanIdCard = async (value) => {
        const imgId = { image: value }
        setLoading(true)
        const headers = { 'Content-Type': 'application/json' }
        await axios.post(testupload, imgId, { headers: headers })
            .then(res => {
                if (res.data) {
                    splitAddress(res.data[0])
                    loadDataOne(res.data[0])
                    setLoading(false)
                }
                else {
                    message.info('ไม่สามารถอ่านได้')
                    setDataPost({})
                    setDataAddress({})
                    setDataPhone({})
                    setLoading(false)
                }
            })
            .catch((err) => console.log(err))
        setLoading(false)
    }

    /////////// กดตรวจบัตรประชาชน ///////////////////////////////////////////////////////////////////////
    const handleIdCard = async () => {
        const sumOne = { identificationId: dataPost.identificationId }
        if (dataPost.identificationId !== undefined && dataPost.identificationId.trim() !== "") {
            setLoading(true)
            await axios.post(checkCCAP, sumOne)
                .then((res) => {
                    if (res.data) {
                        message.info('มีข้อมูล')
                        setDataPost(res.data)
                        if (res.data.address) {
                            setDataAddress(res.data.address)
                        }
                        if (res.data.phones) {
                            setDataPhone(res.data.phones)
                        }
                        form.setFieldsValue(
                            {
                                ...res.data,
                                telp: res?.data?.phones[0]?.telp,
                                birthdate: dayjs(res?.data?.birthdate),
                                issudt: dayjs(res?.data?.issudt),
                                expdt: dayjs(res?.data?.expdt),
                            }
                        )
                        setLoading(false)
                    }
                    else {
                        message.info('ไม่พบข้อมูล')
                        setDataPost({
                            ...dataPost,
                            customerId: 0,
                            snam: '',
                            firstname: '',
                            lastname: '',
                            nickname: '',
                            nationalId: '',
                            birthdate: '',
                            gender: '',
                            issuby: '',
                            issudt: '',
                            expdt: ''
                        })
                        form.setFieldsValue(
                            {
                                snam: '',
                                firstname: '',
                                lastname: '',
                                nickname: '',
                                nationalId: '',
                                birthdate: '',
                                gender: '',
                                issuby: '',
                                issudt: '',
                                expdt: '',
                                telp: '',
                            }
                        )
                        setLoading(false)
                    }
                })
                .catch((err) => console.log(err))
            setLoading(false)
        }
        else {
            message.info('กรุณากรอกเลขบัตรประชาชน')
            setDataPost({
                ...dataPost,
                customerId: null,
                snam: '',
                firstname: '',
                lastname: '',
                nickname: '',
                national_id: '',
                birthdate: '',
                gender: '',
                issuby: '',
                issudt: '',
                expdt: ''
            })
            form.setFieldsValue(
                {
                    snam: '',
                    firstname: '',
                    lastname: '',
                    nickname: '',
                    nationalId: '',
                    birthdate: '',
                    gender: '',
                    issuby: '',
                    issudt: '',
                    expdt: '',
                    telp: '',
                }
            )
        }
    }
    function isThaiNationalID(id) {
        if (!/^[0-9]{13}$/g.test(id)) {
            return false
        }
        let i; let sum = 0
        for ((i = 0), (sum = 0); i < 12; i++) {
            sum += Number.parseInt(id.charAt(i)) * (13 - i)

        }
        const checkSum = (11 - sum % 11) % 10
        if (checkSum === Number.parseInt(id.charAt(12))) {
            return true, message.info('เลขบัตรถูกต้อง')
        }
        return false, message.info('เลขบัตรไม่ถูกต้อง')

    }
    const inputIdCard = (e) => {
        setDataPost({ ...dataPost, identificationId: e.target.value })
        isThaiNationalID(e.target.value)
    }
    ////////////// รับไฟล์รูปบัตร /////////////////////////////////
    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg']
        if (file) {
            if (validImageTypes.includes(file.type)) {
                const reader = new FileReader(); // สร้าง FileReader object
                reader.onloadend = () => { // ฟังก์ชันที่ทำงานเมื่ออ่านไฟล์เสร็จสิ้น    
                    const base64Data = reader.result; // รับข้อมูล Base64 จาก FileReader result
                    //console.log('Base64 Data:', base64Data);
                    setFileList({ image: base64Data })
                    handleScanIdCard(base64Data)
                }
                reader.readAsDataURL(file) // อ่านไฟล์และแปลงเป็น Base64    
            }
        }
    }

    //////////////// เก็บแอสเดรส ////////////////////////////////////
    const splitAddress = (value) => {
        let updatedCount = { ...count }
        let part = value.address.split(" ")
        if (part[1].includes("หมู่ที่") === true) {
            updatedCount.houseNumber = part[0] + part[1] + part[2]
        } else {
            updatedCount.houseNumber = part[0]
        }
        for (let i = 0; i <= part.length; i++) {
            if (part[i]?.includes("ซ.") === true) {
                let s = part[i].split("ซ.")
                updatedCount.soi = s[1]
            }
            if (part[i]?.includes("ถ.") === true) {
                let tanon = part[i].split("ถ.")
                updatedCount.road = tanon[1]
            }
            if (part[i]?.includes("ต.") === true) {
                let bn = part[i].split("ต.")
                updatedCount.subdistrict = bn[1]
            }
            if (part[i]?.includes("อ.") === true) {
                let oo = part[i].split("อ.")
                let oop = oo[1].split(",")
                if (oo[1]?.includes(",") === false) {
                    updatedCount.district = oo[1]
                }
                if (oop[1]?.includes("จ.") === true) {
                    updatedCount.district = oop[0]
                    let oops = oop[1].split("จ.")
                    updatedCount.province = oops[1]
                }
            }
            if (part[i]?.includes("จ.") === true) {
                let jh = part[i].split("จ.")
                updatedCount.province = jh[1]
            }
        }
        setDataAddress([{
            ...dataAddress,
            customerId: 0,
            addressId: 0,
            houseNumber: updatedCount.houseNumber,
            houseRegistrationNumber: updatedCount.houseRegistrationNumber,
            village: updatedCount.village,
            zipCode: updatedCount.zipCode,
            soi: updatedCount.soi,
            road: updatedCount.road,
            subdistrict: updatedCount.subdistrict,
            district: updatedCount.district,
            province: updatedCount.province,
            addressStatus: true,
            addressOnIdcard: true,
            workAddress: false,
            otherAddress: false,
            currentAddress: false,
            sendDocuments: false,
            houseRegistration: false,
        }])
    }

    const changeDescription = (value) => {
        setDataPost({ ...dataPost, description: value })
    }

    return (
        <Card >
            <div>
                <div>
                    <Row>
                        <Col span={24} style={{ fontSize: '30px', textAlign: 'center' }} >ข้อมูลส่วนตัว</Col>
                    </Row>
                </div>
                <Divider></Divider>
                <Spin spinning={loading} size='large' tip=" Loading... ">
                    <Form
                        labelCol={{
                            span: 12,
                        }}
                        wrapperCol={{
                            span: 12,
                        }}
                        form={form}
                        onFinish={handleSubmit}
                        autoComplete="off">
                        <Row>
                            <Col span={10}>
                                <Form.Item label='เลขประจำตัวประชาชน' name='identificationId'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <Input type='text' maxLength={13}
                                        onChange={(e) => inputIdCard(e)}></Input>
                                </Form.Item>
                                <Form.Item label='รูปบัตรประชาชน'>
                                    <Input type="file" multiple={true}
                                        onChange={handleFileInputChange} >
                                    </Input>
                                </Form.Item>
                                <Form.Item label="คำนำหน้าชื่อ" name="snam"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <Radio.Group onChange={(e) => setDataPost({ ...dataPost, snam: e.target.value })} >
                                        <Radio value="นาย"> นาย </Radio>
                                        <Radio value="นาง"> นาง </Radio>
                                        <Radio value="นางสาว"> นางสาว </Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item label='ชื่อ' name='firstname'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}
                                >
                                    <Input type='text'
                                        onChange={(e) => setDataPost({ ...dataPost, firstname: e.target.value })}></Input>
                                </Form.Item>
                                <Form.Item label='นามสกุล' name='lastname'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <Input type='text'
                                        onChange={(e) => setDataPost({ ...dataPost, lastname: e.target.value })}></Input>
                                </Form.Item>

                                <Form.Item label='ชื่อเล่น' name='nickname'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <Input type='text'
                                        onChange={(e) => setDataPost({ ...dataPost, nickname: e.target.value })}></Input>
                                </Form.Item>
                            </Col>
                            <Col span={2}>
                                <Form.Item>
                                    <Button type="primary" onClick={(e) => { handleIdCard(e) }}>ตรวจสอบ</Button>
                                </Form.Item>
                            </Col>
                            <Col span={10}>
                                <Form.Item label='เกิดวันที่' name='birthdate'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <DatePicker format={'YYYY-MM-DD'} style={{ width: '100%' }}
                                        onChange={(e) => {
                                            if (e) {
                                                setDataPost({ ...dataPost, birthdate: e.format('YYYY-MM-DD') })
                                            }
                                        }} />
                                </Form.Item>
                                <Form.Item label='เพศ' name='gender'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <Select
                                        placeholder={'เลือก'}
                                        style={{
                                            width: '100%',
                                        }}
                                        onChange={(value) => setDataPost({ ...dataPost, gender: value })}
                                        options={[
                                            {
                                                label: 'ชาย',
                                                value: 'M',
                                            },
                                            {
                                                label: 'หญิง',
                                                value: 'F',
                                            },
                                        ]} />
                                </Form.Item>
                                <Form.Item label='เจ้าพนักงานออกบัตร' name='issuby'
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: 'Please input !',
                                //     },]}
                                >
                                    <Input type='text'
                                        onChange={(e) => setDataPost({ ...dataPost, issuby: e.target.value })}></Input>
                                </Form.Item>
                                <Form.Item label='วันออกบัตร' name='issudt'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <DatePicker format={'YYYY-MM-DD'} style={{ width: '100%' }}
                                        onChange={(e) => {
                                            if (e) {
                                                setDataPost({ ...dataPost, issudt: e.format('YYYY-MM-DD') })
                                            }
                                        }} />
                                </Form.Item>
                                <Form.Item label='วันบัตรหมดอายุ' name='expdt'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <DatePicker format={'YYYY-MM-DD'} style={{ width: '100%' }}
                                        onChange={(e) => {
                                            if (e) {
                                                setDataPost({ ...dataPost, expdt: e.format('YYYY-MM-DD') })
                                            }
                                        }} />
                                </Form.Item>
                                <Form.Item label='ความสัมพันธ์กับผู้กู้' name='description'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !'
                                        },]}>
                                    <Select
                                        placeholder="เลือกความสัมพันธ์"
                                        onChange={changeDescription}
                                        options={value}
                                    />
                                </Form.Item>
                                {/* <Form.Item label='สาขา' name='locat'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <Input type='text'
                                        onChange={(e) => setDataPost({ ...dataPost, locat: e.target.value })}></Input>
                                </Form.Item> */}
                            </Col>
                        </Row>
                        <div style={{ textAlign: "center" }}>
                            <Space>
                                <Button type="primary" style={{ background: "red" }} onClick={handleCancel}>ยกเลิก</Button>
                                <Button type="primary" htmlType="submit">ต่อไป</Button>
                            </Space>
                        </div>
                    </Form>
                </Spin>
            </div>
        </Card>
    )
};
