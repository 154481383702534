import React from 'react';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Divider, Row, Col, Card, Modal, Space, message, Spin } from 'antd';
import ModalEditAddress from './AddressModals/ModalEditAddress';
import ModalAddAddress from './AddressModals/ModalAddAddress';
import dayjs from 'dayjs';

import { useSelector } from 'react-redux';
import { deleteaddress, checkonecusadd } from '../../../file_mid/all_api';

function Address({ close, changepage, notification, notification2, notification3, notification4, notification5, getData, page, sendback }) {

  // const { customers } = useSelector((state) => ({ ...state }))
  const customers = useSelector(state => state.customers)
  const [messageApi, contextHolder] = message.useMessage();

  const [form] = Form.useForm();
  const [dataedit, setDataEdit] = useState([]); //เซตสำหรับแก้ไขข้อมูล 
  const [index1, setIndex] = useState(); //set index send to page edit
  const [dataPost, setDataPost] = useState(customers.data); //มาจากรีดัค
  const [saveAddress, setSaveAddress] = useState([]); // เก็บ address
  const [loading, setLoading] = useState(false); // loading
  const { confirm } = Modal

  //checkOpen Button
  const [modaleditdata, setModalEditData] = useState(false);
  const [modaladddata, setModalAddData] = useState(false);

  useEffect(() => {
    setDataPost(getData.customerId)
    // setDataPost(customers.data)
    loadData()
  }, [modaladddata])

  // console.log("customers.data", customers.data)
  // console.log("getData", getData.customerId)

  const loadData = async (e) => {
    if (dataPost !== undefined) {
      setLoading(true)
      await axios.get(`${checkonecusadd}/${getData.customerId}`)
        .then(res => {
          if (res.data.status.length !== 0) {
            setSaveAddress(res.data.status)
          }
          setLoading(false)
        }).catch((err) => {
          if (err.response.request.status === 422) {
            messageApi.open({
              type: 'error',
              content: 'เกิดข้อผิดพลาด',
            });
          }
          console.log("err", err)
          setLoading(false)
        })
    }
  }

  const deleteData = async (value) => {
    setLoading(true)
    // console.log("TY", value)
    const test2 = value
    await axios.delete(deleteaddress, { data: test2, })
      .then((res) => {
        if (res.status === 200) {
          notification5('top')
          sendback(dayjs())
          setLoading(false)
        }
        else {
          error()
        }
      }).catch((err) => console.log(err))
    setLoading(false)
  }

  const error = () => {
    Modal.error({
      title: 'ไม่สำเร็จ',
      content: 'กรุณาลองใหม่อีกครั้ง',
    })
  }
  // const showConfirm = (value) => {
  const showConfirm = (value, index) => {
    //console.log(value)
    confirm({
      title: 'คุณต้องการที่จะลบข้อมูลหรือไม่...?',
      content: 'กด OK เพื่อยืนยันการลบ',
      onOk() {
        const deletearray = [...saveAddress]
        deletearray.splice(index, 1)
        setSaveAddress(deletearray)
        deleteData(value)
      },
      onCancel() {
        message.error('ยกเลิกการลบข้อมูล')
      },
    });
  };

  const onChangeBack = () => {
    changepage(page - 1)
  }

  const onChangeGo = () => {
    changepage(page + 1)
  }

  const handleCancel = () => {
    close(false)
  }

  //ดึงข้อมูลมากจาก Modal addData
  const fucnAdd = (value) => {
    if (saveAddress.length === 0) {
      setSaveAddress(pre => [...pre, { ...value.address }]);
    } else {
      setSaveAddress(pre => [...pre, { ...value.address }]);
    }
    sendback(dayjs())
  }
  // แก้ไขข้อมูลจากหน้า AddressEditData
  const fucnEdit = ({ getData, getIndex }) => {
    let Theedit = [...saveAddress]
    Theedit[getIndex] = getData
    //ข้อมูลใหม่แทนที่ข้อมูลเดิมในช่อง Input
    setSaveAddress(Theedit);
    sendback(dayjs())
  }

  const renderItem = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์

    // index ตอนนี้เป็นข้อมูลจาก Object
    const handleEdit = (dataedit, index1) => {
      setIndex(index1) //เซตค่า index ให้กับชุด ข้อมูล
      setDataEdit({ dataedit, index1 }) //ส่งข้อมูลด index ไปหน้าแก้ไข
      setModalEditData(true);
    }

    return (
      <Form form={form}>
        <Spin spinning={loading} size='large' tip="Loading...">
          <Row style={{ margin: '3px' }} justify={'center'} >
            <Card
              key={index} // index เป็นตัวกำหนดคีย์
              title={
                (item.addressStatus === 1 || item.addressStatus === true) ? <u>ใช้งานที่อยู่</u> : <u>ไม่ได้ใช้งาน</u>
              }
              style={{ width: '300px', textAlign: 'center' }} >
              <Col span={24} style={{ textAlign: 'left' }}>
                <Form.Item style={{ marginBottom: 0 }} label='เลขทะเบียนบ้าน' >
                  <b>{item.houseRegistrationNumber}</b>
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }} label='บ้านเลขที่'>
                  <b>{item.houseNumber}</b>
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }} label='ซอย'>
                  <b>{item.soi}</b>
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }} label='ถนน'>
                  <b>{item.road}</b>
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }} label='หมู่บ้าน'>
                  <b>{item.village}</b>
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }} label='ตำบล'>
                  <b>{item.subdistrict}</b>
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }} label='อำเภอ'>
                  <b>{item.district}</b>
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }} label='จังหวัด'>
                  <b>{item.province}</b>
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }} label='รหัสไปรษณีย์'>
                  <b>{item.zipCode}</b>
                </Form.Item>
                <Divider></Divider>
                <Form.Item style={{ marginBottom: 0 }} label='เป็นที่อยู่'>
                  <b>
                    {(item.addressOnIdcard === "1" || item.addressOnIdcard === true) && "ตามบัตรประชาชน "}
                    {(item.houseRegistration === "1" || item.houseRegistration === true) && "ตามทะเบียนบ้าน "}
                    {(item.workAddress === "1" || item.workAddress === true) && "ตามสถานที่ทำงาน "}
                    {(item.otherAddress === "1" || item.otherAddress === true) && "อื่นๆ"}
                    {(item.currentAddress === "1" || item.currentAddress === true) && "ที่อยู่ปัจจุบัน"}
                    {(item.sendDocuments === "1" || item.sendDocuments === true) && "ที่อยู่ส่งเอกสาร"}
                    {/* {(item.addressStatus === "0" || item.addressStatus === false) && "ไม่ใช้งานที่อยู่"} */}
                  </b>
                </Form.Item>
                <Divider></Divider>
                <Row justify={'center'} gutter={{ sx: 8, sm: 16, md: 24, lg: 32 }}>
                  <Form.Item>
                    {/* ปุ่มลบข้อมูล */}
                    <Button
                      style={{ margin: '10px', background: '#f5222d', color: 'white', marginBottom: 0 }}
                      icon={<DeleteOutlined />}
                      // onClick={() => deleteData(index)}
                      onClick={() => showConfirm(saveAddress[index], index)}
                    >ลบ</Button>
                  </Form.Item>
                  <Form.Item>
                    {/* ปุ่มแก้ไขข้อมูล */}
                    <Button
                      style={{ margin: '10px', background: '#bfbfbf', color: '#000000', marginBottom: 0 }}
                      type='submit'
                      onClick={() => { handleEdit(saveAddress[index], index); }}
                    >แก้ไข</Button>
                  </Form.Item>
                </Row>
              </Col>
            </Card>
          </Row>
        </Spin>
      </Form>
    )
  }
  return (
    <>
      <Card style={{ color: '#4096ff' }}>
        <Form>
          <Row>
            <Col className='gutter-row' span={24}>
              <Row>
                <Col span={24} style={{ fontSize: '30px', textAlign: 'center' }} >ที่อยู่</Col>
              </Row>
              <Divider />
              <Row gutter={32}>
                <Col span={24} style={{ textAlign: 'right' }}>
                  {/* ปุ่มเพิ่มข้อมูล */}
                  <Button type='primary' onClick={() => { setModalAddData(true); }}> <PlusOutlined />เพิ่มข้อมูลที่อยู่</Button>
                </Col>
              </Row>
              <Row justify={'center'}>
                {
                  saveAddress.map((item, index) => {
                    return renderItem({ item, index, key: `{item.identificationId} - ${index}` })
                  })
                }
              </Row>


            </Col>
          </Row>

          <Divider />

          <div style={{ textAlign: "center" }} >
            <Space>
              <Button type="primary" style={{ background: '#bfbfbf', color: '#000000', }} onClick={onChangeBack}>ย้อนกลับ</Button>
              <Button type="primary" onClick={handleCancel} style={{ background: "red" }}>ยกเลิก</Button>
              <Button type="primary" style={{ color: '#ffffff', }} onClick={onChangeGo}>ต่อไป</Button>
            </Space>
          </div>
        </Form>
      </Card>
      {
        //เพื่มข้อมูลในการ์ด
        modaladddata ?
          <ModalAddAddress
            open={modaladddata}
            close={setModalAddData}
            fucnAdd={fucnAdd}
            CusId={getData.customerId}
            notification3={notification3}
            notification4={notification4}
          />
          : null
      }
      {
        //แก้ไขข้อมูลในการ์ด
        modaleditdata ?
          <ModalEditAddress
            open={modaleditdata}
            close={setModalEditData}
            shootdata={dataedit} // 
            fucnEdit={fucnEdit}
            dataindex={index1}
            notification={notification}
            notification2={notification2}
          />
          : null
      }
      {contextHolder}
    </>
  )
};
export default Address;