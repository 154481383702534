import React, { useState, useEffect } from 'react'
import { Modal, Row, Col, message, Form, Button, Card, Divider, Tag } from 'antd'
import axios from 'axios';
import dayjs from 'dayjs';
import { acceptpn } from '../../file_mid/all_api';

export default function ModalAccept({ open, dataFromTable, close }) {

    const currentTime = dayjs();
    // const delayedTime = currentTime.add(7, 'hour');
    const formattedTime = currentTime.format('YYYY-MM-DDTHH:mm:ssZ');

    const [dataModalEdit, setDataModalEdit] = useState(dataFromTable);
    const [messageApi, contextHolder] = message.useMessage();
    const [statusAccept, setstatusAccept] = useState({
        customerId: '', carId: '', loanId: '', approvalStatus: '', car: 'land'
    });

    // console.log("ez", dataFromTable)

    useEffect(() => {
        setDataModalEdit(dataFromTable)
        pickData();
    }, [dataFromTable])

    const handleCancel = () => {
        close(false);
    };

    const handleOk = async () => {
        await axios.put(acceptpn, { ...dataFromTable, ...statusAccept })
            .then(res => {
                if (res.status === 200) {
                    messageApi.open({
                        type: 'success',
                        content: 'บันทึกสำเร็จ',
                    });
                } else {
                    messageApi.open({
                        type: 'error',
                        content: 'บันทึกไม่สำเร็จ',
                    });
                }
            }).catch((err) => console.log(err))
        close(false);
    };

    const pickData = () => {
        setstatusAccept({
            customerId: dataModalEdit.customerId,
            carId: dataModalEdit.landId,
            loanId: dataModalEdit.landLoanId,
            // phoneId: dataModalEdit.phoneId,
            // set เวลา
            carDateStatus: formattedTime,
            approvalDate: formattedTime,
            approvalStatus: "รอเคาะราคา"
        })
    }

    return (
        <div>
            <Modal title="การรับงาน" open={open} onCancel={handleCancel}
                footer={[
                    <Button type='primary' onClick={handleOk} style={{ backgroundColor: "Green" }}>ยืนยัน</Button>,
                    <Button key="back" type='primary' danger onClick={handleCancel}>
                        ปิด
                    </Button>,

                ]}>
                <Card>
                    <Divider orientation='left'><b>รายละเอียด</b></Divider>
                    <Form
                        name="basic"
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}>
                        {/* <Form.Item label="ชื่อลูกค้า">
                            {dataModalEdit?.firstname + ' ' + dataModalEdit?.lastname}
                        </Form.Item> */}
                        <Form.Item label="เลขที่ดิน" style={{ margin: 0 }}>
                            {dataModalEdit?.numberLand}
                        </Form.Item>
                        <Form.Item label="ระวาง" style={{ margin: 0 }}>
                            {dataModalEdit?.numberLandLawang}
                        </Form.Item>
                        <Form.Item label="อำเภอ" style={{ margin: 0 }}>
                            {dataModalEdit?.district}
                        </Form.Item>
                        <Form.Item label="จังหวัด" style={{ margin: 0 }}>
                            {dataModalEdit?.province}
                        </Form.Item>
                        <Form.Item label="ชื่อการตลาดที่เสนอ" style={{ margin: 0 }}>
                            {dataModalEdit?.proposalBy}
                        </Form.Item>
                        < Form.Item label="สถานะ" style={{ margin: 0 }}>
                            <Tag color="gold">{dataModalEdit.approvalStatus}</Tag>
                        </Form.Item>
                    </Form>
                </Card>
            </Modal>
            {contextHolder}
        </div>
    )
}
