import React, { useEffect, useState } from 'react'
import { Button, Input, Form, DatePicker, Card, Col, Row, message, Select, Radio, Spin, Space, Divider, Modal, Upload } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import '../css/Media.css'
import { PlusOutlined } from '@ant-design/icons';
import { createbroker, checkBroker, uploadJojonoi } from '../../file_mid/all_api';

export default function BrokerNew({ close, open }) {
  const [form] = Form.useForm()
  const [dataPost, setDataPost] = useState()
  const [number, setNumber] = useState(0);
  const [CheckIdCard, setCheckIdCard] = useState(false)
  // const [dataAddress, setDataAddress] = useState() // address
  const [count, setCount] = useState()
  const [dataPhone, setDataPhone] = useState() // phone
  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState(0) // ตรวจสอบการกดตรวจบัตร
  const [fileListCustomer, setFileListCustomer] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  useEffect(() => {
    setCheckIdCard(true)
    setNumber(1)
  }, [])


  const handleCancel = () => {
    close(false)
  }
  const handleSubmit = async (e) => {
    // console.log("dataPost", dataPost)
    // console.log("dataPhone", dataPhone)
    const result = { ...dataPost, phonesBroker: [{ ...dataPhone }] }
    // console.log("result", result)
    setLoading(true)
    await axios.post(createbroker, result)
      .then((res) => {
        if (res.data) {
          console.log("OK", res.data)
          message.info('บันทึกสำเร็จ')
          close(false)
        }
      })
      .catch((err) => console.log(err))
    setLoading(false)

  }

  ////////////// ข้อมูลยูสในเดต้าเบส ////////////////////////////////////////////////////////////////
  const loadDataOne = async (data) => {
    const one = { idCard: data.identificationId}
    // console.log("one",one)
    setLoading(true)
    await axios.post(checkBroker, one)
      .then((res) => {
        console.log("RES-DATA", res.data)
        if (res.data) {
          message.info('มีข้อมูลในฐานข้อมูล')
          if (window.confirm('คุณต้องการใช้ข้อมูลในฐานข้อมูลหรือไม่ ?')) {
            setDataPost(res.data)
            if (res.data.phonesBroker) {
              setDataPhone(res.data.phonesBroker[0])
            }
            // if (res.data.employeeAddress) {
            //   setDataAddress(res.data.employeeAddress[0])
            // }
            if (res.data.expdt == null || res.data.issudt == null) {
              form.setFieldsValue(
                {
                  ...res.data,
                  identificationId: res.data?.idCard,
                  telp: res?.data?.phonesBroker[0]?.telp,
                  birthdate: res?.data?.birthdate ? dayjs(res?.data?.birthdate) : "",
                  issudt: '',
                  expdt: '',
                }
              )
              setLoading(false)
            }
            else {
              form.setFieldsValue(
                {
                  ...res.data,
                  identificationId: res.data?.idCard,
                  telp: res?.data?.phonesBroker[0]?.telp,
                  birthdate: res?.data?.birthdate ? dayjs(res?.data?.birthdate) : "",
                  issudt: res?.data?.issudt ? dayjs(res?.data?.issudt) : "",
                  expdt: res?.data?.expdt ? dayjs(res?.data?.expdt) : "",
                }
              )
            }
          } else {
            setDataPost({ ...dataPost, ...data, idBroker: res.data.idBroker, idCard: data.identificationId })
            if (res.data.phonesBroker) {
              setDataPhone({ ...dataPhone, idBroker: res.data.idBroker, phoneId: res.data.phonesBroker[0].phoneId })
            }
            if (data.expdt == null || data.issudt == null) {
              form.setFieldsValue(
                {
                  ...data,
                  nickname: '',
                  gender: '',
                  birthdate: data?.birthdate ? dayjs(res?.data?.birthdate) : "",
                  issudt: '',
                  expdt: '',
                  telp: '',
                }
              )
              setLoading(false)
            }
            else {
              form.setFieldsValue(
                {
                  ...data,
                  nickname: '',
                  gender: '',
                  birthdate: data?.birthdate ? dayjs(res?.data?.birthdate) : "",
                  issudt: data?.issudt ? dayjs(res?.data?.issudt) : "",
                  expdt: data?.expdt ? dayjs(res?.data?.expdt) : "",
                  telp: '',
                }
              )
            }
          }
          setLoading(false)
        }
        else {
          message.info('ไม่พบข้อมูลในฐานข้อมูล')
          setDataPost({ ...data, idBroker: 0, idCard: data.identificationId })
          setDataPhone()
          if (data.expdt == null || data.issudt == null) {
            form.setFieldsValue(
              {
                ...data,
                nickname: '',
                gender: '',
                birthdate: data?.birthdate ? dayjs(res?.data?.birthdate) : "",
                issudt: '',
                expdt: '',
                telp: '',
              }
            )
            setLoading(false)
          }
          else {
            form.setFieldsValue(
              {
                ...data,
                nickname: '',
                gender: '',
                birthdate: data?.birthdate ? dayjs(res?.data?.birthdate) : "",
                issudt: '',
                expdt: '',
                telp: '',
              }
            )
          }
          setLoading(false)
        }
      })
      .catch((err) => console.log(err))
    setLoading(false)
  }
  /////////// ดึงข้อมูลจากรูปบัตรประชาชน /////////////////////////////////////////////////////////////////  
  const handleScanIdCard = async (value) => {
    const imgId = { image: value }
    setLoading(true)
    const headers = { 'Content-Type': 'application/json' }
    await axios.post(uploadJojonoi, imgId, { headers: headers })
      .then(res => {
        // console.log("img", res.data[0])
        if (res.data) {
          // setDataPost(res.data[0])
          // splitAddress(res.data[0])
          loadDataOne(res.data[0])
          setLoading(false)
        }
        else {
          message.info('ไม่สามารถอ่านได้')
          setDataPost()
          //  setDataAddress()
          setDataPhone()
          setLoading(false)
        }
      })
      .catch((err) => console.log(err))
    setLoading(false)
  }

  /////////// กดตรวจบัตรประชาชน ///////////////////////////////////////////////////////////////////////
  const handleIdCard = async () => {
    if (dataPost?.idCard?.length !== 13 && dataPost?.nationalId === "บุคคลธรรมดา") {
      alert('กรุณาเพิ่มเลขบัตรประชาชนให้ถูกต้อง')
      setStep(0)
    } else {
      setStep(1)
      const sumOne = { idCard: dataPost.idCard }
      if (dataPost.idCard !== undefined && dataPost.idCard.trim() !== "") {
        setLoading(true)
        await axios.post(checkBroker, sumOne)
          .then((res) => {
            console.log("ตรวจบัตร", res.data)
            if (res.data) {
              message.info('มีข้อมูลในฐานข้อมูล')
              setDataPost(res.data)
              if (res.data.phonesBroker) {
                setDataPhone(res.data.phonesBroker[0])
              }
              // if (res.data.employeeAddress) {
              //   setDataAddress(res.data.employeeAddress[0])
              // }
              form.setFieldsValue(
                {
                  ...res.data,
                  telp: res?.data?.phonesBroker[0]?.telp,
                  birthdate: res?.data?.birthdate ? dayjs(res?.data?.birthdate) : "",
                  issudt: res?.data?.issudt ? dayjs(res?.data?.issudt) : "",
                  expdt: res?.data?.expdt ? dayjs(res?.data?.expdt) : "",
                }
              )
              setLoading(false)
            }
            else {
              message.info('ไม่พบข้อมูลในฐานข้อมูล')
              setDataPost({
                ...dataPost,
                idBroker: 0,
                // idCard: identificationId,
              })
              // setDataAddress()
              setDataPhone()
              form.setFieldsValue(
                {
                  telp: '',
                }
              )
              setLoading(false)
            }
          })
          .catch((err) => console.log(err))
        setLoading(false)
      }
      else {
        message.info('กรุณากรอกเลขบัตรประชาชน')
        setDataPost({
          ...dataPost,
          idBroker: 0,
          idCard: '',
          snam: '',
          firstname: '',
          lastname: '',
          nickname: '',
          national_id: '',
          birthdate: '',
          gender: '',
          issuby: '',
          issudt: '',
          expdt: ''
        })
        // setDataAddress()
        setDataPhone()
        form.setFieldsValue(
          {
            snam: '',
            firstname: '',
            lastname: '',
            nickname: '',
            nationalId: '',
            birthdate: '',
            gender: '',
            issuby: '',
            issudt: '',
            expdt: '',
            telp: '',
          }
        )
      }
    }
  }
  function isThaiNationalID(id) {
    if (!/^[0-9]{13}$/g.test(id)) {
      return false
    }
    let i; let sum = 0
    for ((i = 0), (sum = 0); i < 12; i++) {
      sum += Number.parseInt(id.charAt(i)) * (13 - i)

    }
    const checkSum = (11 - sum % 11) % 10
    if (checkSum === Number.parseInt(id.charAt(12))) {
      //console.log(id, 'เลขบัตรถูกต้อง')
      return true, message.info('เลขบัตรถูกต้อง')
    }
    //console.log("เลขบัตรไม่ถูกต้อง", id)
    return false, message.info('เลขบัตรไม่ถูกต้อง')

  }
  ////
  const inputIdCard = (e) => {
    setStep(0)
    setDataPost({ ...dataPost, idCard: e.target.value })
    isThaiNationalID(e.target.value)
  }

  // //////////////// เก็บแอสเดรส ////////////////////////////////////
  // const splitAddress = (value) => {
  //   let updatedCount = { ...count }
  //   //console.log("โหลด",value.address)
  //   let part = value.address.split(" ")
  //   if (part[1].includes("หมู่ที่") === true) {
  //     //setHouseNumber(part[0] + part[1] + part[2])
  //     updatedCount.houseNumber = part[0] + part[1] + part[2]
  //   } else {
  //     //setHouseNumber(part[0])
  //     updatedCount.houseNumber = part[0]
  //   }
  //   //setMoo(part[2])
  //   //setDataAddress({...dataAddress, moo: part[2]})
  //   for (let i = 0; i <= part.length; i++) {
  //     if (part[i]?.includes("ซ.") === true) {
  //       let s = part[i].split("ซ.")
  //       //setSoi(s[1])
  //       updatedCount.soi = s[1]
  //     }
  //     if (part[i]?.includes("ถ.") === true) {
  //       let tanon = part[i].split("ถ.")
  //       //setRoad(tanon[1])
  //       updatedCount.road = tanon[1]
  //     }
  //     if (part[i]?.includes("ต.") === true) {
  //       let bn = part[i].split("ต.")
  //       //setSubdistrict(bn[1])
  //       updatedCount.subdistrict = bn[1]
  //     }
  //     if (part[i]?.includes("อ.") === true) {
  //       let oo = part[i].split("อ.")
  //       let oop = oo[1].split(",")
  //       if (oo[1]?.includes(",") === false) {
  //         //setDistrict(oo[1])
  //         updatedCount.district = oo[1]
  //       }
  //       if (oop[1]?.includes("จ.") === true) {
  //         //setDistrict(oop[0])
  //         updatedCount.district = oop[0]
  //         let oops = oop[1].split("จ.")
  //         //setProvince(oops[1])
  //         updatedCount.province = oops[1]
  //       }
  //     }
  //     if (part[i]?.includes("จ.") === true) {
  //       let jh = part[i].split("จ.")
  //       //setProvince(jh[1])
  //       updatedCount.province = jh[1]
  //     }
  //   }

  //   setDataAddress({
  //     ...dataAddress,
  //     idBroker: 0,
  //     employeeAddressId: 0,
  //     houseNumber: updatedCount.houseNumber,
  //     houseRegistrationNumber: updatedCount.houseRegistrationNumber,
  //     village: updatedCount.village,
  //     zipCode: updatedCount.zipCode,
  //     soi: updatedCount.soi,
  //     road: updatedCount.road,
  //     subdistrict: updatedCount.subdistrict,
  //     district: updatedCount.district,
  //     province: updatedCount.province,
  //     addressStatus: true,
  //     addressOnIdcard: true,
  //     workAddress: false,
  //     otherAddress: false,
  //     currentAddress: false,
  //     sendDocuments: false,
  //     houseRegistration: false,
  //   })
  // }

  ////////////////// Phone /////////////////////////////////
  const handlePhone = (e) => {
    setDataPhone({ ...dataPhone, telp: e.target.value, phoneType: "เบอร์ติดต่อ", sendSms: "1" })
  }

  ////////////////// อ่านรูปบัตรประชาชน //////////////////
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  const handleFileInputChange = async (event) => {
    if (event.length > 0) {
      var file = event[0].originFileObj
      const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg']
      // const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
      if (file) {
        if (validImageTypes.includes(file.type)) {
          const reader = new FileReader(); // สร้าง FileReader object
          reader.onloadend = () => { // ฟังก์ชันที่ทำงานเมื่ออ่านไฟล์เสร็จสิ้น    
            const base64Data = reader.result; // รับข้อมูล Base64 จาก FileReader result
            //console.log('Base64 Data:', base64Data);
            //setFileList({ image: base64Data })
            handleScanIdCard(base64Data)
          }
          reader.readAsDataURL(file) // อ่านไฟล์และแปลงเป็น Base64    
        }
      }
    }
  }

  const handleChangeCustomer = async ({ fileList }) => {
    setFileListCustomer(fileList);
    if (fileList) {
      handleFileInputChange(fileList);
    } else {
      handleFileInputChange([]);
    }
    setLoading(false)
  };
  return (
    <Modal title="เพิ่มข้อมูลผู้แนะนำ" open={open} onCancel={handleCancel} width={1200} footer={null} >
      <Row justify={'center'}>
        <Card style={{ width: '1100px' }}>
          <Col style={{ textAlign: 'center' }} >
            <b><h2>ข้อมูลส่วนตัว</h2></b>
          </Col>
          <Spin spinning={loading} size='large' tip=" Loading... ">
            <Row justify={'center'}>
              <Form
                name="basic"
                labelCol={{
                  span: 12,
                }}
                style={{
                  width: '100%',
                }}
                initialValues={{
                  remember: true,
                }}
                form={form}
                onFinish={handleSubmit}
                autoComplete="off"
              >
                <Divider style={{ margin: 5 }} />
                <Row justify={'center'} gutter={32} style={{ margin: '5px' }} >
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <Row className='main2' justify={'center'} >
                      <aside style={{ width: '90%' }}>
                        <Form.Item label='อ่านข้อมูลจากรูปบัตรประชาชน'
                          style={{ textAlign: 'center' }}
                        >
                          <Upload
                            accept="image/*"
                            maxCount={1}
                            style={{ textAlign: 'center' }}
                            listType="picture-card"
                            fileList={fileListCustomer}
                            onPreview={handlePreview}
                            onChange={handleChangeCustomer}
                            beforeUpload={() => false}
                          //beforeUpload={(file) => beforeUpts(file, 4).then(() => false).catch(() => true)}
                          >
                            {fileListCustomer?.length >= 1 ? null : (
                              <div>
                                <PlusOutlined />
                                <div className="ant-upload-text">upload</div>
                              </div>
                            )}
                          </Upload>
                        </Form.Item>
                        <Form.Item
                          label="คำนำหน้าชื่อ"
                          name="snam"
                          style={{ textAlign: 'center', width: '300px' }}
                          rules={[
                            {
                              required: true,
                              message: 'Please input !',
                            },]}>
                          <Radio.Group onChange={(e) => setDataPost({ ...dataPost, snam: e.target.value })} style={{ width: '250px' }}>
                            <Radio value="นาย" > นาย </Radio>
                            <Radio value="นาง"> นาง </Radio>
                            <Radio value="นางสาว"> นางสาว </Radio>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item
                          label='เลขประจำตัวประชาชน'
                          name='identificationId'
                          style={{ textAlign: 'center', width: '300px' }}
                          rules={[
                            {
                              required: true,
                              message: 'Please input !',
                            },]}>

                          <Input type='text' maxLength={13}
                            disabled={CheckIdCard ? false : true}

                            style={{ width: '250px', marginRight: '5px', marginLeft: '5px', color: 'black' }}
                            onChange={(e) => inputIdCard(e)}></Input>
                        </Form.Item>
                        {number === 1 ?
                          <span
                            style={{ display: 'inline-block' }}
                          >
                            <Form.Item
                              label='' name=''
                              style={{ textAlign: 'left', width: '300px' }}
                            >
                              <Button type="primary" onClick={(e) => { handleIdCard(e) }}>ตรวจสอบเลขประจำตัวประชาชน</Button>
                            </Form.Item>
                          </span>
                          : null}
                        <Form.Item label='ชื่อ' name='firstname'
                          style={{ textAlign: 'center', width: '300px' }}
                          rules={[
                            {
                              required: true,
                              message: 'Please input !',
                            },]}
                        >
                          <Input type='text'
                            style={{ width: '250px', color: 'black' }}
                            onChange={(e) => setDataPost({ ...dataPost, firstname: e.target.value })}></Input>
                        </Form.Item>
                        <Form.Item label='นามสกุล' name='lastname'
                          style={{ textAlign: 'center', width: '300px' }}
                          rules={[
                            {
                              required: true,
                              message: 'Please input !',
                            },]}>
                          <Input type='text'
                            style={{ width: '250px', color: 'black' }}
                            onChange={(e) => setDataPost({ ...dataPost, lastname: e.target.value })}></Input>
                        </Form.Item>
                        <Form.Item label='ชื่อเล่น' name='nickname'
                          style={{ textAlign: 'center', width: '300px' }}
                          rules={[
                            {
                              required: true,
                              message: 'Please input !',
                            },]}
                        >
                          <Input type='text'
                            style={{ width: '250px', color: 'black' }}
                            onChange={(e) => setDataPost({ ...dataPost, nickname: e.target.value })}></Input>
                        </Form.Item>
                        <Form.Item label='เพศ' name='gender'
                          style={{ textAlign: 'center', width: '300px' }}
                          rules={[
                            {
                              required: true,
                              message: 'Please input !',
                            },]}>
                          <Select
                            placeholder={'เลือก'}
                            style={{ width: '250px', color: 'black' }}
                            onChange={(value) => setDataPost({ ...dataPost, gender: value })}
                            options={[
                              {
                                label: 'ชาย',
                                value: 'M',
                              },
                              {
                                label: 'หญิง',
                                value: 'F',
                              },
                            ]}
                          />
                        </Form.Item>
                        <Form.Item label='เกิดวันที่' name='birthdate'
                          style={{ textAlign: 'center', width: '300px' }}
                          rules={[
                            {
                              required: true,
                              message: 'Please input !',
                            },]}>

                          <DatePicker format={'YYYY-MM-DD'}
                            style={{ height: '40px', width: '250px', color: 'black' }}
                            onChange={(e) => {
                              if (e) {
                                setDataPost({ ...dataPost, birthdate: e.format('YYYY-MM-DD') })
                              }
                            }} />
                        </Form.Item>
                        <Form.Item label='เบอร์โทรติดต่อ' name='telp'
                          style={{ textAlign: 'center', width: '300px' }}
                          rules={[
                            {
                              required: true,
                              message: 'Please input !',
                            },]}>
                          <Input type='text'
                            style={{ width: '250px', color: 'black' }}
                            onChange={(e) => { handlePhone(e) }}></Input>
                        </Form.Item>
                        <Form.Item label='เจ้าพนักงานออกบัตร' name='issuby'
                          style={{ textAlign: 'center', width: '300px' }}>
                          <Input type='text'
                            style={{ width: '250px', color: 'black' }}
                            onChange={(e) => setDataPost({ ...dataPost, issuby: e.target.value })}></Input>
                        </Form.Item>
                        <Form.Item label='วันออกบัตร' name='issudt'
                          style={{ textAlign: 'center', width: '300px' }}
                          rules={[
                            {
                              required: true,
                              message: 'Please input !',
                            },]}>
                          <DatePicker format={'YYYY-MM-DD'}
                            style={{ height: '40px', width: '250px', color: 'black' }}
                            onChange={(e) => {
                              if (e) {
                                setDataPost({ ...dataPost, issudt: e.format('YYYY-MM-DD') })
                              }
                            }} />
                        </Form.Item>
                        <Form.Item label='วันบัตรหมดอายุ' name='expdt'
                          style={{ textAlign: 'center', width: '300px' }}
                          rules={[
                            {
                              required: true,
                              message: 'Please input !',
                            },]}>
                          <DatePicker format={'YYYY-MM-DD'}
                            style={{ height: '40px', width: '250px', color: 'black' }}
                            onChange={(e) => {
                              if (e) {
                                setDataPost({ ...dataPost, expdt: e.format('YYYY-MM-DD') })
                              }
                            }} />
                        </Form.Item>
                      </aside>
                    </Row>
                  </Col>
                </Row>
                <Divider style={{ margin: 5 }} />
                <Row justify={'center'} >
                  <Space>
                    <Button type="primary" style={{ background: "red" }} onClick={handleCancel}>ยกเลิก</Button>
                    <Button type="primary" htmlType="submit">save</Button>
                  </Space>
                </Row>
              </Form>
            </Row>
          </Spin>
        </Card>
      </Row>
    </Modal>
  )
}


