import React, { useState, useEffect } from 'react'
import { Form, Modal, Row, Col, Image, Space, Card, Button, Tag, Divider, Upload, message, Spin, notification } from 'antd'
import { SendOutlined, PlusOutlined, PictureOutlined } from '@ant-design/icons'
import dayjs from 'dayjs';
import axios from "axios";
import '../../css/Media.css'
import { irr } from 'node-irr'
import { useSelector } from 'react-redux';
import Currency from 'currency.js';
import { FIFTEENPERCENT, TWENTYFOURPERCENT } from '../../offer_case/ploan/InstallmentTable'
import { HirePurchase } from '../../offer_case/hire_purchase/HirePurchase';
import { Installment } from '../../offer_case/ploan/Installment';
import Compressor from 'compressorjs';
import ModalShowImg from './ModalShowImg';
import AddGua from './Guarantor/AddGua';
import EditGua from './Guarantor/EditGua';
import { useLocation, useNavigate } from 'react-router-dom';
import { checkcarcus, checkguarantors, findcarmk, loadOJS, mutips2pre, resendcarmk, getAllFolderGuaImg, getImagessGua } from '../../file_mid/all_api';

function ModalAddAaprove({ open, dataFromTable, close, SuccSend }) {

    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;
    const [api, contextHolder] = notification.useNotification();
    const guarantor = useSelector(state => state.guarantor)
    const [loading, setLoading] = useState(false)
    const token = localStorage.getItem('token');
    const [imageBlobzz, setImageBlobzz] = useState([]);
    const [dataModalEdit, setDataModalEdit] = useState([]);
    const [form] = Form.useForm()

    const [guarantor1, setGuarantor1] = useState([])
    const [address, setAddress] = useState([])
    const [dataPhoneCus, setDataPhoneCus] = useState([]);
    const [dataEmail, setDataEmail] = useState([]);
    const [dataSocials, setDataSocials] = useState([]);
    const [notes, setNotes] = useState([])
    const [cars, setCars] = useState();
    const [carloanRe, setCarloanRe] = useState();

    const [loanAmountt, setLoanAmountt] = useState();
    const [installmentCount, setInstallmentCount] = useState()
    const [dataSource, setDataSource] = useState([])
    const [dataCareer, setDataCareer] = useState([]);
    const [installmentWithInterestt, setInstallmentWithInterestt] = useState(0.0)
    const [resultIrr, setResultIrr] = useState(0.0)
    const [arr, setArr] = useState()
    const [interestt, setInterestt] = useState(0.0)
    const [getimages, setGetImages] = useState();
    const [checkOpen, setCheckOpen] = useState(false)
    const [checkOpenHIRE, setCheckOpenHIRE] = useState(false)
    // const [checkApprove, setCheckApprove] = useState({})
    const [career, setCareer] = useState([]) // เก็บข้อมูลอาชีพ
    const [oldData, setOldData] = useState();
    const [ap, setAp] = useState();
    const [carLoanDetails, setCarLoanDetails] = useState();

    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');

    //เพิ่มรูป
    const [fileListCustomer, setFileListCustomer] = useState([]);
    const [fileListCustomerImageCareer, setFileListCustomerImageCareer] = useState([]);
    const [fileListCustomerImageCar, setFileListCustomerImageCar] = useState([]);
    const [fileListCustomerDocument, setFileListCustomerDocument] = useState([]);
    const [fileListStatement, setFileListStateMent] = useState([]);
    const [fileListStatementPDF, setFileListStateMentPDF] = useState([]);
    const [fileListOtherMent, setFileListOtherMent] = useState([]);

    /*เก็บรูปทั้งหมด*/
    const [allImg, setAllImg] = useState([]);
    const newFormData = new FormData();
    const [resultImage, setResultImage] = useState([]);
    const [isModalShowImg, setIsModalShowImg] = useState(false);
    const [isModalShowImgPre, setIsModalShowImgPre] = useState(false);
    const [isModalAddGua, setIsModalAddGua] = useState(false);

    const [sendBack, setSendBack] = useState(true)
    // const [sendCaseBTN, setSendCaseBTN] = useState(true)
    // const [checkSendNEW, setCheckSendNEW] = useState(false)
    // const [refreshPage, setRefreshPage] = useState()

    const [index1, setIndex] = useState(); //set index send to page edit
    const [indexEdit, setIndexEdit] = useState(0); //set index send to page edit 
    const [dataedit, setDataEdit] = useState([]); //เซตสำหรับแก้ไขข้อมูล 
    const [isModalsEditGua, setIsModalsEditGua] = useState(false) // เปิด/ปิด modal แก้ไขคนค้ำ
    const [preData] = useState(true)

    const [moonnee, setMoonnee] = useState()
    const [hug, setHug] = useState()
    const [kong, setKong] = useState()
    const [jaimoney, setJaimoney] = useState()
    const [yodjut, setYodjut] = useState()
    const [dateceo, setDateceo] = useState()
    const [exp, setExp] = useState()
    const [ngod, setNgod] = useState()
    const [pon, setPon] = useState()


    const { confirm } = Modal

    var length = notes?.length
    var lastindex = length - 1

    useEffect(() => {
        loadData()
    }, [sendBack])

    useEffect(() => {
        if (guarantor1.length > 0) {
            countType()
        }
        setSendBack(true)
    }, [guarantor1])

    // useEffect(() => {
    //     imageBlobzz?.map((e, index) => {
    //         var PreAaproveSplit = []
    //         PreAaproveSplit = e.pathImage.split("/")
    //         if (PreAaproveSplit[3] === "13") {
    //             setCheckSendNEW(true)
    //         }
    //     })
    // }, [imageBlobzz, refreshPage])


    const confirmClose = () => {
        confirm({
            title: 'คุณต้องการที่ปิดหน้าต่างหรือไม่...?',
            content: 'ต้องการที่จะปิดหน้าต่างโดยยังไม่ส่งเคส กด OK เพื่อยืนยัน',
            onOk() {
                handleCancel();
            },
            onCancel() {
            },
        });
    };

    const SaveSuccess = (placement) => {
        api.success({
            message: `บันทึกสำเร็จ`,
            placement,
        });
    }

    // console.log("imageBlobzz", imageBlobzz)
    // console.log("carloanRe", carloanRe)
    // console.log("cars", cars)
    // console.log("dataFromTable", dataFromTable.approvalStatus)

    const countType = async () => {
        const lastItem = guarantor1[guarantor1?.length - 1];
        const newType = lastItem?.pathImg?.split("/")
        const intTpye = parseInt(newType[4])
        // console.log("intTpye", intTpye)
        // setIndexEdit(intTpye)
        if (isNaN(intTpye)) {
            var newIntType = guarantor1?.length
            console.log("if")
        } else {
            console.log("else")
            var newIntType = intTpye
        }
        setIndexEdit(newIntType)
    }

    const imgSend = (resultImgs) => {
        return new Promise(async (resolve, reject) => {
            try {
                var status;
                newFormData.append('idcus', dataModalEdit?.cars?.carId);
                newFormData.append('tm', dayjs().format("YYYY-MM-DDTHH:mm:ssZ"));

                for (const file of resultImgs) {
                    var image = {}
                    newFormData.append('file', file.imageCompressedFile);
                    newFormData.append("type", file.type);
                    image.type = file.type
                    resultImage.push(image)
                }
                const response = await axios.post(mutips2pre, newFormData);
                if (response.data) {
                    setFileListOtherMent([])
                    setFileListStateMentPDF([])
                    setFileListStateMent([])
                    setFileListCustomerImageCareer([])
                    setFileListCustomerImageCar([])
                    setFileListCustomerDocument([])
                    setFileListCustomer([])
                    setAllImg([])
                    // setRefreshPage(dayjs())
                    console.log("TY Pre", response.data);
                    status = response.status;
                    resolve(status);
                    sendCase()
                }
            } catch (error) {
                error(error);
                reject(error);
            } finally {
                setLoading(false);
                console.log("OK")
                SaveSuccess("top")

            }
        });
    };

    const loadCareer = async () => {
        const id = { customerId: dataFromTable.customerId }
        await axios.post(loadOJS, id)
            .then((res) => {
                // console.log("res", res.data)
                setCareer(res.data)
            })
            .catch((err) => console.log(err))
    }

    const loadGuarantors = async () => {
        var dataloadGua = { typeLoan: "car", loanId: dataFromTable?.loanId }
        await axios.post(checkguarantors, dataloadGua)
            .then(res => {
                if (res.status === 200) {
                    // console.log("คนค้ำ", res.data)
                    const modifiedData = res.data.map((e) => {
                        return { ...e, checkDup: true };
                    })
                    setGuarantor1(modifiedData)
                    // getImg()
                } else if (res.status === 204) {
                    setGuarantor1([])
                    // console.log("ไม่พบข้อมูล")
                }
            }).catch((err) => {
                console.log("err", err)
            })
    }

    // const getImg = async () => {
    //     var path = `one_images/car/${dataFromTable?.carId}/5`
    //     // console.log("dataFromTable", dataFromTable)
    //     // console.log("path", path)
    //     setLoading(true)
    //     await axios.get(`${getAllFolderGuaImg}/${path}?token=${token}`)
    //         .then(res => {
    //             if (res.data) {
    //                 // console.log("NEW IMG", res.data)
    //                 setGetImages(res.data)
    //                 setLoading(false)
    //             } else {
    //                 setGetImages([])
    //             }
    //         })
    //         .catch((err) => {
    //             console.log("err", err)
    //             setLoading(false)
    //         })
    //     setLoading(false)
    // }

    const loadDataCar = async () => {
        await axios.post(checkcarcus, { carPlateNumber: dataFromTable.carPlateNumber, carProvince: dataFromTable.carProvince })
            .then(res => {
                if (res.status === 200) {
                    // console.log("loadDataCar", res.data)
                    setOldData(res.data)
                }
            }).catch((err) => {
                console.log("err", err)
            })
    }

    ///////////////////////////////////อัพโหลดรูปภาพใหม่/////////////////////////////////////////////
    const compressImage = async (ez, types) => {
        var image = {};
        return new Promise(async (resolve) => {
            if (types !== 7) {
                if (ez.size > 4000000) {
                    console.log("4m ไม่ให้ส่ง");
                    resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
                } else {
                    let quality;
                    if (ez.size >= 2500000 && ez.size <= 4000000) {
                        quality = 0.1;
                    } else if (ez.size >= 2000000 && ez.size <= 2499999) {
                        quality = 0.15;
                    } else if (ez.size >= 1500000 && ez.size <= 1999999) {
                        quality = 0.2;
                    } else if (ez.size >= 1000000 && ez.size <= 1499999) {
                        quality = 0.3;
                    } else {
                        quality = 0.5;
                    }

                    try {
                        // ใช้ Promise เพื่อบีบอัดรูปภาพ
                        const compressedFile = await new Promise((resolveCompress) => {
                            new Compressor(ez, {
                                quality: quality,
                                success: (result) => {
                                    resolveCompress(result);
                                },
                            });
                        });

                        const fileOptions = {
                            type: compressedFile.type,
                            lastModified: Date.now(),
                        };
                        const compressedFileAsFile = new File([compressedFile], ez.name, fileOptions);
                        image.imageCompressedFile = compressedFileAsFile;
                        image.type = types
                        allImg.push(image);
                        //setAllImg((prevAllImg) => [...prevAllImg, image]);

                        // ตรงนี้คือการ resolve ไฟล์ที่ถูกบีบอัด
                        resolve(image);
                    } catch (error) {
                        console.error('Image compression error:', error);
                        resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
                    }
                }
            } else {
                // ... (โลจิกสำหรับ type 7)
                image.imageCompressedFile = ez;
                image.type = types
                allImg.push(image);
                //setAllImg((prevAllImg) => [...prevAllImg, image]);
                resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
            }
        });
    };

    const beforeUpts = async (file, ty) => {
        try {
            setLoading(true)
            const compressedFile = await compressImage(file, ty);
            return Promise.resolve(compressedFile);
        } catch (error) {
            console.error('Image compression error:', error);
            message.error('Image compression failed.');
            return Promise.reject(); // เปลี่ยนให้ return Promise.reject() เมื่อไม่สามารถบีบอัดได้
        }
    };

    const handleCustomerImage = async ({ fileList }) => {

        for (const removedFile of fileListCustomer.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 4)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 4));
                setAllImg(updatedAllImg);
            }
        }
        setFileListCustomer(fileList);
        // setCheckImage(fileList);
        //setAllImg({ ...allImg, ts4: fileList });
        setLoading(false)
    };

    const handleCustomerImageCareer = async ({ fileList }) => {

        for (const removedFile of fileListCustomerImageCareer.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 6)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 6));
                setAllImg(updatedAllImg);
            }
        }

        setFileListCustomerImageCareer(fileList);
        // setCheckImage(fileList);
        //setAllImg({ ...allImg, ts6: fileList });
        setLoading(false)
    };
    const handleCustomerImageCar = async ({ fileList }) => {

        for (const removedFile of fileListCustomerImageCar.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 1)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 1));
                setAllImg(updatedAllImg);
            }
        }
        setFileListCustomerImageCar(fileList);
        // setCheckImage(fileList);
        //setAllImg({ ...allImg, ts1: fileList });
        setLoading(false)
    };
    const handleCustomerImageDocument = async ({ fileList }) => {

        for (const removedFile of fileListCustomerDocument.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 2)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 2));
                setAllImg(updatedAllImg);
            }
        }
        setFileListCustomerDocument(fileList);
        // setCheckImage(fileList);
        //setAllImg({ ...allImg, ts2: fileList });
        setLoading(false)
    };
    const handleStatement = async ({ fileList }) => {

        for (const removedFile of fileListStatement.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 8)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 8));
                setAllImg(updatedAllImg);
            }
        }
        setFileListStateMent(fileList);
        // setCheckImage(fileList);
        //setAllImg({ ...allImg, ts8: fileList });
        setLoading(false)
    };
    const handleStatementPDF = async ({ fileList }) => {
        for (const removedFile of fileListStatementPDF.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 7)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 7));
                setAllImg(updatedAllImg);
            }
        }
        setFileListStateMentPDF(fileList);
        // setCheckImage(fileList);
        //setAllImg({ ...allImg, ts7: fileList });
        setLoading(false)
    };
    const handleOtherMent = async ({ fileList }) => {

        for (const removedFile of fileListOtherMent.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 12)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 12));
                setAllImg(updatedAllImg);
            }
        }
        setFileListOtherMent(fileList);
        // setCheckImage(fileList);
        //setAllImg({ ...allImg, ts12: fileList });
        setLoading(false)
    };

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
    };

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const openTable = () => {
        setCheckOpen(true)
    };
    const openTable2 = () => {
        setCheckOpenHIRE(true)
    };

    const handleCancel = () => {
        close(false)
        navigate(`/checkInfoCar`, {
            state: {
                customerId: 0,
                carId: 0,
                firstname: '',
                lastname: '',
                open: false
            }
        })
    }

    // customerId: data?.customerId,
    // carId: data?.carId,
    // firstname: data?.firstname,
    // lastname: data?.lastname,
    // open: true,
    // approvalStatus: 'อนุมัติ'

    const handleCancelPreview = () => {
        setPreviewVisible(false);
    }

    const loadData = async () => {
        setLoading(true)
        await axios.post(findcarmk, { carId: dataFromTable.carId, customerId: dataFromTable.customerId })
            .then(async (res) => {
                if (res.data) {
                    // console.log("loadData", res.data)
                    setCarLoanDetails(res.data.cars.carLoanDetails)
                    setDataModalEdit(res.data)
                    setDataSocials(res.data.socials)
                    setCars(res.data.cars)
                    setImageBlobzz(res.data.cars.img)
                    setDataCareer(res.data.ojs)
                    setAp(res.data.cars.apCarLoanDetails)
                    setAddress(res.data.address)
                    setNotes(res.data.cars.carLoanDetails.notes)
                    setDataPhoneCus(res.data.phones)
                    setDataEmail(res.data.emails)
                    calYellowCard(res.data?.cars?.carsApproveCeo)

                    // setCheckApprove(res.data.cars.carLoanDetails.approvalStatus)
                    if (res.data.cars.apCarLoanDetails.loanId !== 0) {
                        setLoanAmountt(parseInt(res.data.cars.apCarLoanDetails.apLoanAmount))
                        setInstallmentCount(parseInt(res.data.cars.apCarLoanDetails.apLoanTerm))
                    } else {
                        setLoanAmountt(parseInt(res.data.cars.carLoanDetails.approvedLoanAmount))
                        setInstallmentCount(parseInt(res.data.cars.carLoanDetails.approvedLoanTerm))
                    }

                    if (res.data.cars.carMemo) {
                        form.setFieldsValue(
                            {
                                ...res.data.cars
                            }
                        )
                    }
                    await loadGuarantors()
                    await loadCareer()
                    await loadDataCar()
                    await handleCheckForCal(res.data.cars)
                }
            }).catch((err) => {
                console.log("err", err)
            })
        setLoading(false)
    }

    const calYellowCard = (value) => {
        // console.log("calYellowCard", value)
        // มูลหนี้ balanc
        // หักส่วนลด (balanc-(approvedLoanAmount+(newDamt+followPay)))
        // คงเหลือ มูลหนี้ - หักส่วนลด
        // จ่ายเงิน (newDamt+followPay)
        // ยอดจัด approvedLoanAmount
        // วันที่ approvalDate
        // ใบนี้หมดอายุ ณ วันที่ newOfferDate
        // จำนวนงวด pgLoanTerm
        // ผ่อน installment x pgLoanTerm งวด (รวมภาษีมูลค่าเพิ่ม) 
        // วันที่ dayjs(dataCarAun?.approvalDate).format("DD-MM-YYYY")

        setMoonnee(currencyFormatOne(~~value?.balanc))
        setHug(currencyFormatOne(~~value?.balanc - (~~value?.approvedLoanAmount + (~~value?.newDamt + ~~value?.followPay))))
        setKong(currencyFormatOne(~~value?.balanc - (~~value?.balanc - (~~value?.approvedLoanAmount + (~~value?.newDamt + ~~value?.followPay)))))
        setJaimoney(currencyFormatOne(~~value?.newDamt + ~~value?.followPay))
        setYodjut(currencyFormatOne(~~value?.approvedLoanAmount))
        setDateceo(dayjs(value?.approvalDate).format("DD-MM-YYYY"))
        setExp(dayjs(value?.newOfferDate).format("DD-MM-YYYY"))
        setNgod(~~value?.pgLoanTerm)
        setPon(currencyFormatOne(~~value?.installment) + " x " + ~~value?.pgLoanTerm)
    }

    const handleSave = async () => {
        setLoading(true)
        if (allImg?.length > 0) {
            await imgSend(allImg)
        }
    };

    const handleShowImg = async () => {
        setIsModalShowImg(true)
    };

    const handleAddGua = async () => {
        setIsModalAddGua(true)
    };

    const showConfirm = async () => {
        confirm({
            title: 'คุณต้องการที่จะส่งเคสนี้...?',
            onOk() {
                handleSave()
            },
            onCancel() {

            },
        });
    };

    const sendCase = () => {
        var mix = { ...carLoanDetails, guarantors: guarantor?.data, cars: { ...cars, carInput: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"), img: resultImage }, approvalStatus: "รอธุรการรับ" }
        axios.put(resendcarmk, mix)
            .then(res => {
                if (res.status === 200) {
                    SuccSend("top")
                    setTimeout(() => {
                        handleCancel()
                    }, 1000);
                }
            }).catch((err) => console.log(err))
        // showConfirm()
    };

    const handleCheckForCal = async (value) => {

        // console.log("handleCheckForCal", value)
        if (value.carLoanDetails?.productType === "p-loan") {
            console.log("1 = p-loan")
            if (value.apCarLoanDetails.productType === "") {
                // setTexts({ textName: ap?.productType })
            } else {
                // setTexts({ textName: carLoanDetails?.productType })
            }
            await handleCalculate(value.carLoanDetails)
        } else if (value.carLoanDetails?.productType === "เช่าซื้อ") {
            if (cars?.productTypeCar === 1) {
                console.log("2 = รถเล็ก เช่าซื้อ")
                await handleCalculateHIRE(value.carLoanDetails)
            } else {
                console.log("3 = รถใหญ่ เช่าซื้อ")
                await car_Calculate(value.carLoanDetails)
            }
            if (value.apCarLoanDetails.productType === "") {
                // setTexts({ textName: ap?.productType })
            } else {
                // setTexts({ textName: carLoanDetails?.productType })
            }
        } else if (value.carLoanDetails?.productType === "เช่าซื้อ1") {
            console.log("4 = รถเล็ก เช่าซื้อ1")
            if (value.apCarLoanDetails.productType === "") {
                // setTexts({ textName: `เช่าซื้อ ${carLoanDetails?.interestRate}` })
            } else {
                // setTexts({ textName: `เช่าซื้อ ${value.apCarLoanDetails?.apInterest}` })
            }
            await car_Calculate()
        }
    }

    // คำนวน
    const car_Calculate = async (value) => {

        var LA = value.approvedLoanAmount
        var LT = value.approvedLoanTerm
        var IR = value.interestRate

        var interestRateCar = parseFloat(IR) / 100 // อัตราดอก / 100
        var rateCar = ((parseFloat(LA) * Currency(interestRateCar, { precision: 5 }).value) * LT) // (ยอดจัด * อัตราดอกที่หาร100แล้ว ,precision: 3 คือทศนิยม 3 )*งวด
        var loanTermCar = (rateCar + parseFloat(LA)) / parseInt(LT) // (ดอกเบี้ยทั้งหมด + ยอดจัด)/ งวด
        var newLoanTermCar = Math.ceil(Currency((loanTermCar * 0.07) + loanTermCar), { precision: 5 })// งวดละใหม่ = ( งวดละ * 0.07 ) + งวดละ
        var resultRateCar = Math.ceil(Currency(newLoanTermCar, { precision: 5 }) * parseInt(LT)) - parseFloat(LA) // งวดละใหม่ * งวด - ยอดจัด
        var newResultPriceCar = Math.ceil(Currency(newLoanTermCar) * parseInt(LT), { precision: 5 }) // งวดละใหม่ * งวด

        setArr(newLoanTermCar)
        setInterestt(resultRateCar)
        setInstallmentWithInterestt(newResultPriceCar)
    }

    const handleCalculate = async (value) => {
        var LA = parseInt(value.approvedLoanAmount)
        var LT = parseInt(value.approvedLoanTerm)
        setDataSource([])

        if (LA && LT) {

            let loanAmount = 0.0
            let interest = 0.0

            TWENTYFOURPERCENT.map((value) => {
                if (value.installment === ~~LT) {
                    loanAmount = value.interest * 0.7
                }
            })

            FIFTEENPERCENT.map((value) => {
                if (value.installment === ~~LT) {
                    interest = value.interest * 0.3
                }
            })

            let installmentAmount = Math.round((interest + loanAmount) * LA)

            // IRR Calculate
            var array = []
            array.push(-LA)
            for (var i = 0; i < LT; i++) {
                array.push(installmentAmount)
            }

            let IRR = irr(array) * 100

            if (IRR) {
                var TotalPrincipleBalance = LA //ยอดทั้งหมด
                var totalInterest = 0.0 // ดอกเบี้ย
                var result = LA
                for (var j = 1; j <= LT; j++) {     // installmentCount จำนวนงวด
                    let principle = TotalPrincipleBalance     // เงินต้น = ยอดทั้งหมด
                    let no = j   // งวดที่ 
                    let interestAmount = Math.round((TotalPrincipleBalance * IRR) / 100) // จำนวนดอกเบี้ย = 
                    let principleAmount = installmentAmount - interestAmount    //  จำนวนงวด - จำนวนดอกเบี้ย
                    let principleBalance = principle - principleAmount          //  ดอกเบี้ยคงเหลือ = 

                    totalInterest += interestAmount // totalInterest = totalInterest + interestAmount

                    let interestBalance = totalInterest // ดอกเบี้ยคงเหลือ = ยอดรวมดอกเบี้ย

                    setDataSource((pre) => [
                        ...pre,
                        {
                            installmentNo: no,                          // งวดที่ 
                            installmentAmount: installmentAmount,       // จำนวนงวด
                            interestAmount: interestAmount,             // จำนวนดอกเบี้ย
                            principleAmount: principleAmount,           // เงินต้น
                            principleBalance: principleBalance,         // เงินต้นคงเหลือ
                            interestBalance: interestBalance,           // ดอกเบี้ยคงเหลือ
                        },
                    ])
                    // TotalPrincipleBalance = TotalPrincipleBalance - principleAmount, ยอดทั้งหมด
                    TotalPrincipleBalance -= principleAmount
                }
                result = installmentAmount * LT
                var interestNEW = result - LA
                setResultIrr(Currency(IRR, { precision: 5 }).value)
                setArr(installmentAmount)
                setInterestt(interestNEW)
                // setInterestt(totalInterest)
                setInstallmentWithInterestt(result)
            }
        }
    }

    const handleCalculateHIRE = async (value) => {
        setDataSource([])

        var LA = value.approvedLoanAmount
        var LT = value.approvedLoanTerm

        if (LA && LT) {
            const amountWithVat = LA * 1.07
            let installment = 0.0

            FIFTEENPERCENT.map((value) => {
                if (value.installment === ~~LT) {
                    installment = value.interest * amountWithVat
                }
            })

            let installmentAmount = Math.round(installment)

            // IRR Calculate
            var array = []
            array.push(-amountWithVat)
            for (var i = 0; i < LT; i++) {
                array.push(installmentAmount)
            }

            let IRR = irr(array) * 100

            if (IRR) {
                var TotalPrincipleBalance = amountWithVat //ยอดทั้งหมด
                var totalInterest = 0.0 // ดอกเบี้ย
                //var result = ~~LA
                var result = amountWithVat
                for (var j = 1; j <= LT; j++) {     // installmentCount จำนวนงวด
                    let principle = TotalPrincipleBalance     // เงินต้น = ยอดทั้งหมด
                    let no = j   // งวดที่ 
                    let interestAmount = Math.round((TotalPrincipleBalance * IRR) / 100) // จำนวนดอกเบี้ย = 
                    let principleAmount = installmentAmount - interestAmount    //  จำนวนงวด - จำนวนดอกเบี้ย
                    let principleBalance = principle - principleAmount          //  ดอกเบี้ยคงเหลือ = 

                    totalInterest += interestAmount // totalInterest = totalInterest + interestAmount

                    let interestBalance = totalInterest // ดอกเบี้ยคงเหลือ = ยอดรวมดอกเบี้ย

                    setDataSource((pre) => [
                        ...pre,
                        {
                            installmentNo: no,                          // งวดที่ 
                            installmentAmount: installmentAmount,       // จำนวนงวด
                            interestAmount: interestAmount,             // จำนวนดอกเบี้ย
                            principleAmount: principleAmount,           // เงินต้น
                            principleBalance: principleBalance,         // เงินต้นคงเหลือ
                            interestBalance: interestBalance,           // ดอกเบี้ยคงเหลือ
                        },
                    ])
                    // TotalPrincipleBalance = TotalPrincipleBalance - principleAmount, ยอดทั้งหมด
                    TotalPrincipleBalance -= principleAmount
                }
                //result += totalInterest
                result = installmentAmount * LT
                var interestNEW = result - LA
                setResultIrr(Currency(IRR, { precision: 5 }).value)
                setArr(installmentAmount)
                setInterestt(interestNEW)
                // setInterestt(totalInterest)
                setInstallmentWithInterestt(result)
                // console.log("installmentAmount", installmentAmount)
                // console.log("totalInterest", totalInterest)
                console.log("result", result)
            }
        }
    }
    // คำนวน

    const currencyFormatOne = (amount) => {
        if (amount > 0) {
            return Number(amount)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, '$&,')
        } else {
            return "-"
        }

    }

    let color

    if (carLoanDetails?.approvalStatus === "รอธุรการรับ") {
        color = "gold"
    }
    if (carLoanDetails?.approvalStatus === "รอการวิเคราะห์") {
        color = "gold"
    }
    if (carLoanDetails?.approvalStatus === "รอธุรการรับ") {
        color = "gold"
    }
    if (carLoanDetails?.approvalStatus === "รอวิเคราะห์") {
        color = "cyan"
    }
    if (carLoanDetails?.approvalStatus === "ผ่านการวิเคราะห์") {
        color = "geekblue"
    }
    if (carLoanDetails?.approvalStatus === "ไม่ผ่านการวิเคราะห์") {
        color = "red"
    }
    if (carLoanDetails?.approvalStatus === "ผ่านพิจารณา") {
        color = "geekblue"
    }
    if (carLoanDetails?.approvalStatus === "รออนุมัติ") {
        color = "lime"
    }
    if (carLoanDetails?.approvalStatus === "อนุมัติ") {
        color = "green"
    }
    if (carLoanDetails?.approvalStatus === "อนุมัติแบบมีเงื่อนไข") {
        color = "green"
    }
    if (carLoanDetails?.approvalStatus === "โทรติด") {
        color = "magenta"
    }
    if (carLoanDetails?.approvalStatus === "โทรไม่ติด(ติดต่อไม่ได้)") {
        color = "volcano"
    }
    if (carLoanDetails?.approvalStatus === "ไม่อนุมัติ") {
        color = "red"
    }
    if (carLoanDetails?.approvalStatus === "ลูกค้าปฏิเสธ") {
        color = "red"
    }
    if (carLoanDetails?.approvalStatus === "รอทำสัญญา") {
        color = "green"
    }

    const renderGuarantor = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
        return (
            <>
                <div><b><u>ลำดับที่ {index + 1}</u></b></div>
                <aside style={{ width: '90%' }}>
                    <div style={{ marginBottom: 0 }}>
                        <span>ชื่อ-นามสกุล : </span><b>{item?.snam + ' ' + item?.firstname + ' ' + item?.lastname}</b>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>ความสัมพันธ์ : </span> <b>{item?.description}</b>
                    </div>
                    {item?.phones?.length > 0 ?
                        <div style={{ marginBottom: 0 }}>
                            <span>เบอร์โทรติดต่อ : </span> <b>{item?.phones[0]?.telp}</b>
                        </div>
                        :
                        <div style={{ marginBottom: 0 }}>
                            <span>เบอร์โทรติดต่อ : </span> <b>ไม่มี</b>
                        </div>
                    }
                    {item?.address?.length > 0 ?
                        <>
                            <div style={{ marginBottom: 0 }}>
                                <span > ที่อยู่ :  </span>
                                <b>
                                    {
                                        item.address[0]?.houseNumber + ' ' +
                                        "หมู่ " + item.address[0]?.soi + ' ' +
                                        "ถ." + item.address[0]?.road + ' ' +
                                        "ต." + item.address[0]?.subdistrict + ' ' +
                                        "อ." + item.address[0]?.district + ' ' +
                                        "จ." + item.address[0]?.province + ' '
                                        + item.address[0]?.zipCode
                                    }
                                </b>
                            </div>
                        </>
                        : null
                    }
                </aside>
                {guarantor1?.length >= 1 ? <Divider /> : null}
            </>
        )
    }

    const renderGuarantorNewImg = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์

        var itemSplit = []
        if (item?.pathImg) {
            itemSplit = item.pathImg.split("/")
        }


        const handleEdit = (dataedit, index1) => {
            setIndex(index1) //เซตค่า index ให้กับชุด ข้อมูล
            setDataEdit({ dataedit, index1 }) //ส่งข้อมูลด index ไปหน้าแก้ไข
            setIsModalsEditGua(true);
        }

        return (
            <>
                <div><b><u>ลำดับที่ {index + 1}</u></b></div>
                <aside style={{ width: '90%' }}>
                    <div style={{ marginBottom: 0 }}>
                        <span>ชื่อ-นามสกุล : </span><b>{item?.snam + ' ' + item?.firstname + ' ' + item?.lastname}</b>
                    </div>

                    <div style={{ marginBottom: 0 }}>
                        <span>ความสัมพันธ์ : </span> <b>{item?.description}</b>
                    </div>
                    {item?.phones?.length > 0 ?
                        <>
                            <div style={{ marginBottom: 0 }}>
                                <span>เบอร์โทรติดต่อ : </span> <b>{item?.phones[0]?.telp}</b>
                            </div>
                        </>
                        : <div style={{ marginBottom: 0 }}>
                            <span>เบอร์โทรติดต่อ : </span> <b>ไม่มี</b>
                        </div>
                    }

                    {item?.address?.length > 0 ?
                        <>
                            <div style={{ marginBottom: 0 }}>
                                <span > ที่อยู่ :  </span>
                                <b>
                                    {
                                        item.address[0]?.houseNumber + ' ' +
                                        "หมู่ " + item.address[0]?.soi + ' ' +
                                        "ถ." + item.address[0]?.road + ' ' +
                                        "ต." + item.address[0]?.subdistrict + ' ' +
                                        "อ." + item.address[0]?.district + ' ' +
                                        "จ." + item.address[0]?.province + ' '
                                        + item.address[0]?.zipCode
                                    }
                                </b>
                            </div>
                        </>
                        : null
                    }

                </aside>
                {/* <Divider/> */}
                {itemSplit.length > 0 ? (
                    <>
                        <Row justify={"center"}>
                            {imageBlobzz?.map((items, index) => {
                                const segments = items.pathImage.split("/")
                                if (itemSplit[4] === segments[4] && items.type === 5) {
                                    return (
                                        // <Image width={'100px'} key={index} src={`data:${items?.Attr};base64,${items?.data?.toString('base64')}`} alt={`Image ${index + 1}`} />
                                        <Image width={"100px"} key={index} src={`${getImagessGua}/${items.pathImage}?token=${token}`} alt={`รูปภาพ ${index + 1}`} />
                                    )
                                }
                            }
                            )}
                        </Row>
                    </>
                ) : null
                } <Button type="text" style={{ background: "#e0e0e0" }} onClick={() => { handleEdit(guarantor1[index], index); }}>
                    เพิ่มรูปคนค้ำ
                </Button>
                <Divider />
            </>
        )
    }
    const renderItemAddress = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
        return (
            <>
                <div><b><u>ลำดับที่ {index + 1}</u></b></div>
                <aside style={{ width: '90%' }}>
                    <div style={{ marginBottom: 0 }}>
                        <span>เลขทะเบียนบ้าน : </span> <b>{item.houseRegistrationNumber}</b>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>ถนน : </span><b>{item.road}</b>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>ตำบล : </span> <b>{item.subdistrict}</b>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>บ้านเลขที่ : </span> <b>{item.houseNumber}</b>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>หมู่บ้าน : </span> <b>{item.village}</b>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>อำเภอ : </span> <b>{item.district}</b>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>หมู่ / ซอย : </span> <b>{item.soi}</b>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>จังหวัด : </span> <b>{item.province}</b>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>รหัสไปรษณีย์ : </span> <b>{item.zipCode}</b>
                    </div>
                </aside>
                {address.length >= 1 ? <Divider /> : null}
            </>
        )
    }
    const renderItemPhones = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
        return (
            <>
                <div style={{ marginBottom: 0 }} >
                    <span>{item.phoneType ? <>{item.phoneType} : </> : null}</span>
                    <b> {item.telp}</b>
                </div>
            </>
        )
    }
    const renderDataNote = ({ item, index }) => {
        return (
            <>
                <Row gutter={32} justify={'center'}>
                    <Col className='gutter-row' span={24}>
                        <>
                            <Card style={{ width: '270px', textAlign: 'left' }}>
                                <div style={{ marginBottom: 0 }}>
                                    <span>สาเหตุ : </span>
                                    <b>{item.cause}</b>
                                </div>
                                <div style={{ marginBottom: 0 }}>
                                    <span>หมายเหตุ : </span>
                                    <b>{item.note}</b>
                                </div>
                                {/* <div style={{ marginBottom: 0 }}>
                                            <span>ผู้สร้างหมายเหตุ : </span>
                                            <b>{item.noteBy}</b>
                                        </div> */}
                                <div style={{ marginBottom: 0 }}>
                                    <span>สถานะที่ทำการหมายเหตุ : </span>
                                    <b>{item.status}</b>
                                </div>
                            </Card>
                        </>

                    </Col>
                </Row>
            </>
        )
    }

    return (

        <Modal title="ตรวจสอบข้อมูล" open={open} onCancel={confirmClose} width={'1200px'}
            footer={[null]}>
            <Spin spinning={loading} size='large' tip=" Loading... ">
                <Row justify={'center'}>
                    <Col className='gutter-row' span={24} style={{ fontSize: '25px', textAlign: 'center' }}>
                        <b>รายละเอียด 11</b>
                    </Col>
                </Row>

                <Row justify={'center'}>
                    <Divider style={{ margin: 5 }} />
                    <Form
                        labelCol={{
                            span: 12,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                        style={{
                            width: '90%',
                        }}
                        form={form}
                        autoComplete="off"
                        initialValues={{ remember: true }}
                    >
                        <div className='main'>
                            <Card style={{ width: '100%' }}>
                                <Row justify={'center'}>
                                    <Col span={12} style={{ textAlign: 'left' }}>
                                        {
                                            cars?.carLoanDetailsRe ?
                                                <>
                                                    <div style={{ margin: 0 }}>
                                                        เลขสัญญา : <Tag color={color}>{cars?.ISSUNO ? cars?.ISSUNO : null}</Tag>
                                                    </div>

                                                    {cars?.carLoanDetailsRe?.GRDCOD ?
                                                        <div style={{ margin: 0 }}>
                                                            เกรด : {cars?.carLoanDetailsRe?.GRDCOD ? <Tag color={color}> {cars?.carLoanDetailsRe?.GRDCOD} </Tag> : null}
                                                        </div>
                                                        : null
                                                    }
                                                </>
                                                :
                                                null
                                        }
                                    </Col>
                                    <Col span={12} style={{ textAlign: 'right' }}>
                                        <div style={{ margin: 0 }}>
                                            สถานะ : <Tag color={color}>{dataFromTable?.approvalStatus}</Tag>
                                        </div>
                                    </Col>
                                </Row>
                                <Divider />
                                {
                                    dataFromTable.approvalStatus === "อนุมัติแบบมีเงื่อนไข" ?
                                        <>
                                            <Row justify={'left'}><Col span={24} style={{ color: "red" }}><b><u>อนุมัติแบบมีเงื่อนไข!</u></b></Col></Row>
                                            <Card
                                                key={lastindex}
                                                style={{ textAlign: 'left' }}>
                                                <>
                                                    <div style={{ marginBottom: 0 }}>
                                                        <span>สาเหตุ : </span>
                                                        <b>{notes[lastindex]?.cause}</b>
                                                    </div>
                                                    <div style={{ marginBottom: 0 }}>
                                                        <span>หมายเหตุ : </span>
                                                        <b>{notes[lastindex]?.note}</b>
                                                    </div>
                                                    <div style={{ marginBottom: 0 }}>
                                                        <span>ผู้สร้างหมายเหตุ : </span>
                                                        <b>{notes[lastindex]?.noteBy}</b>
                                                    </div>
                                                    <div style={{ marginBottom: 0 }}>
                                                        <span>สถานะที่ทำการหมายเหตุ : </span>
                                                        <b>{notes[lastindex]?.status}</b>
                                                    </div>
                                                </>
                                            </Card>
                                            <Divider />
                                        </>
                                        :
                                        null
                                }
                                {
                                    dataModalEdit?.nationalId === "นิติบุคคล" ?
                                        <>
                                            <Row justify={'left'}><b><u>รายละเอียดผู้ขอกู้</u></b></Row>
                                            <Row gutter={32} justify={'center'}>
                                                <aside style={{ width: '90%' }}>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>ประเภท : </span> <b>{dataModalEdit?.nationalId}</b>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>เลขประจำตัวผู้เสียภาษี : </span> <b>{dataModalEdit.identificationId}</b>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>
                                                            {dataModalEdit?.snam} : </span> <b>{dataModalEdit.firstname + ' ' + dataModalEdit?.lastname}</b>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>วันจดทะเบียน : </span> <b> {dataModalEdit?.birthdate}</b>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <b>
                                                            {
                                                                dataModalEdit?.phones?.map((item, index) => {
                                                                    return renderItemPhones({ item, index, key: `{ item.identification } - ${index}` })
                                                                })
                                                            }
                                                        </b>
                                                    </div>
                                                </aside>
                                            </Row>
                                        </>
                                        :
                                        <>
                                            <Row justify={'left'}><b><u>รายละเอียดผู้ขอกู้</u></b></Row>
                                            <Row gutter={32} justify={'center'}>
                                                <aside style={{ width: '90%' }}>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>ชื่อ - นามสกุล : </span>
                                                        <b>{dataModalEdit?.snam + '   ' + dataModalEdit?.firstname + '   ' + dataModalEdit?.lastname}</b>
                                                    </div>

                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>ชื่อเล่น : </span>
                                                        <b>{dataModalEdit?.nickname}</b>
                                                    </div>

                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>เลขบัตรประชาชน : </span>
                                                        <b>{dataModalEdit?.identificationId}</b>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <b>

                                                            {
                                                                dataPhoneCus?.map((item, index) => {
                                                                    return renderItemPhones({ item, index, key: `{item.identification} - ${index}` })
                                                                })
                                                            }
                                                        </b>
                                                    </div>
                                                    <div style={{ margin: 0, }}>
                                                        <span>เพศ : </span>
                                                        <b>{dataModalEdit?.gender}</b>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>{dataEmail === "" ? <> </> : <>{dataEmail?.emailData}</>}</span>
                                                        <b>{dataEmail?.emailData}</b>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>{dataSocials?.socialType}  </span>
                                                        <b>{dataSocials?.socialData}</b>
                                                    </div>
                                                </aside>
                                            </Row>
                                        </>
                                }
                                <Divider />
                                {
                                    address?.length >= 1 ?
                                        <>
                                            <Row justify={'left'}>
                                                <b><u>รายละเอียดที่อยู่</u></b>
                                            </Row>
                                            <Row gutter={32} justify={'center'}>
                                                {
                                                    address?.map((item, index) => {
                                                        return renderItemAddress({ item, index })
                                                    })
                                                }
                                            </Row>
                                        </>
                                        : null
                                }
                                {
                                    carLoanDetails?.productLoanType === "ย้ายไฟแนนซ์" ||
                                        carLoanDetails?.productLoanType === 'Pre-Aaprove-ย้ายไฟแนนซ์' ?
                                        <>
                                            <Row justify={'left'}><b><u>รายละเอียดยอดกู้ {carLoanDetails?.productLoanType}</u></b></Row>
                                            <Row gutter={32} justify={'center'}>
                                                <aside style={{ width: '90%' }}>
                                                    <div style={{ marginBottom: 0, }} >
                                                        <span>ยอดกู้ : </span>
                                                        <b>{currencyFormatOne(carLoanDetails?.loanAmount)}</b>
                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                            บาท
                                                        </span>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>จำนวนงวด : </span>
                                                        <b>{carLoanDetails?.loanTerm}</b>
                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                            งวด
                                                        </span>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>ดอกเบี้ย : </span>
                                                        <b>{currencyFormatOne(carLoanDetails?.interest)}</b>
                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                            บาท
                                                        </span>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>จำนวนผ่อนต่อเดือน : </span>
                                                        <b>{currencyFormatOne(carLoanDetails?.monthlyPayment)}</b>
                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                            บาท
                                                        </span>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>ผ่อนรวมดอกเบี้ย : </span>
                                                        <b>{currencyFormatOne(~~carLoanDetails?.monthlyPayment * ~~carLoanDetails?.loanTerm)}</b>
                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                            บาท
                                                        </span>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>ผู้เสนอเคส : </span>
                                                        <b>{carLoanDetails?.proposalBy}</b>
                                                    </div>
                                                    <b>
                                                        <div style={{ margin: 0, }}>
                                                            <span>เรทรถ : </span>
                                                            <b style={{ fontSize: '20px' }}><u>{currencyFormatOne(cars?.carPrice)}</u></b>
                                                            <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                                                บาท
                                                            </span>
                                                        </div>
                                                    </b>
                                                </aside>
                                            </Row>
                                            <Divider />
                                            <Row justify={'left'}><b><u>รายละเอียดย้ายไฟแนนซ์เดิม</u></b></Row>
                                            <Row gutter={32} justify={'center'}>
                                                <aside style={{ width: '90%' }}>
                                                    <div style={{ marginBottom: 0, textAlign: 'left' }}>
                                                        <span>จากไฟแนนซ์ : </span>
                                                        <b>{cars?.carOldLoanDetails?.issuno}</b>
                                                    </div>
                                                    <div style={{ marginBottom: 0, textAlign: 'left' }} >
                                                        <span>ยอดปิดไฟแนนซ์ : </span>
                                                        <b>{currencyFormatOne(cars?.carOldLoanDetails?.oldLoanAmount)}</b>
                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                            บาท
                                                        </span>
                                                    </div>
                                                    <div style={{ marginBottom: 0, textAlign: 'left' }}>
                                                        <span>ค่างวด / เดือน : </span>
                                                        <b>{currencyFormatOne(cars?.carOldLoanDetails?.oldMonthlyPayment)}</b>
                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                            บาท
                                                        </span>
                                                    </div>
                                                    <div style={{ marginBottom: 0, textAlign: 'left' }}>
                                                        <span>จำนวนงวดที่ผ่อน : </span>
                                                        <b>{cars?.carOldLoanDetails?.oldLoanTerm}</b>
                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                            งวด
                                                        </span>
                                                    </div>
                                                </aside>
                                            </Row>
                                        </>
                                        :
                                        <>
                                            <Row justify={'left'}><b><u>รายละเอียด({carLoanDetails?.productLoanType})</u></b></Row>
                                            {
                                                carLoanDetails?.productType === 'เช่าซื้อ' ?
                                                    <>
                                                        <Row gutter={32} justify={'center'}>
                                                            <aside style={{ width: '90%' }}>
                                                                <div style={{ marginBottom: 0 }} >
                                                                    <span>ยอดกู้เช่าซื้อ : </span>
                                                                    <b>{currencyFormatOne(carLoanDetails?.loanAmount)}</b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </div>
                                                                <div style={{ marginBottom: 0 }} >
                                                                    <span>จำนวนงวดเช่าซื้อ : </span>
                                                                    <b>{carLoanDetails?.loanTerm}</b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        งวด
                                                                    </span>
                                                                </div>
                                                                <div style={{ marginBottom: 0 }}>
                                                                    <span>ดอกเบี้ยเช่าซื้อ : </span>
                                                                    <b>{currencyFormatOne(carLoanDetails?.interest)}</b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </div>
                                                                <div style={{ marginBottom: 0 }}>
                                                                    <span>จำนวนผ่อนต่อเดือนเช่าซื้อ : </span>
                                                                    <b>{currencyFormatOne(carLoanDetails?.monthlyPayment)}</b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </div>
                                                                <div style={{ marginBottom: 0 }}>
                                                                    <span>ผ่อนรวมดอกเบี้ยเช่าซื้อ : </span>
                                                                    <b>{currencyFormatOne(~~carLoanDetails?.monthlyPayment * ~~carLoanDetails?.loanTerm)}</b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </div>
                                                                <div style={{ marginBottom: 0 }}>
                                                                    <span>ผู้เสนอเคสเช่าซื้อ : </span>
                                                                    <b>{carLoanDetails?.proposalBy}</b>
                                                                </div>
                                                                <b> <div style={{ margin: 0 }}>
                                                                    <span>เรทรถ : </span>
                                                                    <b style={{ fontSize: '20px' }}><u>{currencyFormatOne(cars?.carPrice)}</u></b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                                                        บาท
                                                                    </span>
                                                                </div>
                                                                </b>
                                                            </aside>
                                                        </Row>
                                                    </>
                                                    : carLoanDetails?.productType === 'เช่าซื้อ1' ?
                                                        <>
                                                            <Row gutter={32} justify={'center'}>
                                                                <aside style={{ width: '90%' }}>
                                                                    <div style={{ marginBottom: 0, }}>
                                                                        <span>ยอดกู้เช่าซื้อ : </span>
                                                                        <b>{currencyFormatOne(carLoanDetails?.loanAmount)}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </div>
                                                                    <div style={{ marginBottom: 0, }}>
                                                                        <span>จำนวนงวดเช่าซื้อ : </span>
                                                                        <b>{carLoanDetails?.loanTerm}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            งวด
                                                                        </span>
                                                                    </div>
                                                                    <div style={{ marginBottom: 0, }}>
                                                                        <span>ดอกเบี้ยเช่าซื้อ : </span>
                                                                        <b>{currencyFormatOne(carLoanDetails?.interest)}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </div>
                                                                    <div style={{ marginBottom: 0, }}>
                                                                        <span>จำนวนผ่อนต่อเดือนเช่าซื้อ : </span>
                                                                        <b>{currencyFormatOne(carLoanDetails?.monthlyPayment)}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </div>
                                                                    <div style={{ marginBottom: 0, }}>
                                                                        <span>ผ่อนรวมดอกเบี้ยเช่าซื้อ : </span>
                                                                        <b>{currencyFormatOne(~~carLoanDetails?.monthlyPayment * ~~carLoanDetails?.loanTerm)}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </div>
                                                                    <div style={{ marginBottom: 0, }}>
                                                                        <span>ผู้เสนอเคสเช่าซื้อ : </span>
                                                                        <b>{carLoanDetails?.proposalBy}</b>
                                                                    </div>
                                                                    <b>
                                                                        <div style={{ margin: 0, }}>
                                                                            <span>เรทรถ : </span>
                                                                            <b style={{ fontSize: '20px' }}><u>{currencyFormatOne(cars?.carPrice)}</u></b>
                                                                            <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                                                                บาท
                                                                            </span>
                                                                        </div>
                                                                    </b>
                                                                </aside>
                                                            </Row>
                                                            <Divider />
                                                        </>
                                                        :
                                                        <>
                                                            {/* p - loan */}
                                                            <Row gutter={32} justify={'center'}>
                                                                <aside style={{ width: '90%' }}>
                                                                    <div style={{ marginBottom: 0, }}>
                                                                        <span>ยอดกู้ p-loan : </span>
                                                                        <b>{currencyFormatOne(carLoanDetails?.loanAmount)}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </div>
                                                                    <div style={{ marginBottom: 0, }}>
                                                                        <span>จำนวนงวด p-loan : </span>
                                                                        <b>{carLoanDetails?.loanTerm}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            งวด
                                                                        </span>
                                                                    </div>
                                                                    <div style={{ marginBottom: 0, }}>
                                                                        <span>ดอกเบี้ย p-loan : </span>
                                                                        <b>{currencyFormatOne(carLoanDetails?.interest)}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </div>
                                                                    <div style={{ marginBottom: 0, }}>
                                                                        <span>จำนวนผ่อน/เดือน p-loan : </span>
                                                                        <b>{currencyFormatOne(carLoanDetails?.monthlyPayment)}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </div>
                                                                    <div style={{ marginBottom: 0, }} >
                                                                        <span>ผ่อนรวมดอกเบี้ย p-loan : </span>
                                                                        <b>{currencyFormatOne(~~carLoanDetails?.monthlyPayment * ~~carLoanDetails?.loanTerm)}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </div>
                                                                    <div style={{ marginBottom: 0, }}>
                                                                        <span>ผู้เสนอเคส p-loan : </span>
                                                                        <b>{carLoanDetails?.proposalBy}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </div>
                                                                    <b>
                                                                        <div style={{ margin: 0, }}>
                                                                            <span>เรทรถ : </span>
                                                                            <b style={{ fontSize: '20px' }}><u>{currencyFormatOne(cars?.carPrice)}</u></b>
                                                                            <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                                                                บาท
                                                                            </span>
                                                                        </div>
                                                                    </b>
                                                                </aside>
                                                            </Row>
                                                        </>
                                            }
                                        </>
                                }
                                <Divider />
                                {
                                    carLoanDetails?.approvalStatus === 'อนุมัติ' &&
                                        cars?.carLoanDetailsRe?.carId !== 0 ?
                                        <>
                                            {
                                                cars?.carsApproveCeo?.id > 0 ?
                                                    <>
                                                        <Card style={{ backgroundColor: "lightyellow" }}>
                                                            <Row justify={'left'}><b><u style={{ backgroundColor: '#f39c12' }}>เงื่อนไขการปรับโครงสร้าง</u></b></Row>
                                                            <Col span={24} style={{ textAlign: 'center' }}>
                                                                <Image
                                                                    width={100}
                                                                    src={`${process.env.PUBLIC_URL}/logo.jpg`}
                                                                    alt="My Image"
                                                                />
                                                            </Col>
                                                            <br />
                                                            <aside style={{ width: '90%' }}>
                                                                <div style={{ marginBottom: 0, }}>
                                                                    <span>วันที่ : </span>
                                                                    <b><u>{dateceo}</u></b>
                                                                </div>
                                                                <div style={{ marginBottom: 0, }}>
                                                                    <span>มูลหนี้เช่าซื้อคงเหลือรวม : </span>
                                                                    <b><u>{moonnee}</u></b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </div>
                                                                <div style={{ marginBottom: 0, }}>
                                                                    <span>หักส่วนลด : </span>
                                                                    <b><u>{hug}</u></b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </div>
                                                                <div style={{ marginBottom: 0, }}>
                                                                    <span>คงเหลือ : </span>
                                                                    <b><u>{kong}</u></b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </div>
                                                                <div style={{ marginBottom: 0, }}>
                                                                    <span>จ่ายเงิน : </span>
                                                                    <b><u>{jaimoney}</u></b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </div>
                                                                <div style={{ marginBottom: 0, }}>
                                                                    <span>จำนวนงวด : </span>
                                                                    <b><u>{ngod}</u></b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        งวด
                                                                    </span>
                                                                </div>
                                                                <div style={{ marginBottom: 0, }}>
                                                                    <span>ผ่อน : </span>
                                                                    <b>{pon}</b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        งวด
                                                                        (รวมภาษีมูลค่าเพิ่ม)
                                                                    </span>
                                                                </div>
                                                                <div style={{ marginBottom: 0, }}>
                                                                    <span>ยอดจัด : </span>
                                                                    <b style={{ color: 'red', fontSize: '18px' }}><u>{yodjut}</u></b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </div>
                                                            </aside>
                                                        </Card>
                                                    </>
                                                    :
                                                    <>
                                                        <Divider orientation={'left'}><b><u>รายละเอียดยอดกู้</u></b></Divider>
                                                        <aside style={{ width: '100%' }}>
                                                            <div label='ค่างวด' name='DAMT' style={{ marginBottom: 3 }}>
                                                                <span>ค่างวด : </span><b>
                                                                    {cars?.carLoanDetailsRe?.DAMT ? currencyFormatOne(cars?.carLoanDetailsRe?.DAMT) + " บาท" : "0"}
                                                                </b>
                                                            </div>
                                                            <div label='ยอดจัดครั้งที่แล้ว' name='tcshprc' style={{ marginBottom: 3 }}>
                                                                <span>ยอดจัดครั้งที่แล้ว : </span><b>{cars?.carLoanDetailsRe?.tcshprc ? currencyFormatOne(cars?.carLoanDetailsRe?.tcshprc) + " บาท" : 0}</b>
                                                            </div>
                                                            <div label='ราคาเช่าซื้อครั้งที่แล้ว' name='balanc' style={{ marginBottom: 3 }}>
                                                                <span>ราคาเช่าซื้อครั้งที่แล้ว : </span><b>{cars?.carLoanDetailsRe?.balanc ? currencyFormatOne(cars?.carLoanDetailsRe?.balanc) + " บาท" : 0}</b>
                                                            </div>
                                                            <div label='ยอดที่ชำระมาแล้วทั้งหมด' name='totalpayment' style={{ marginBottom: 3 }}>
                                                                <span>ยอดที่ชำระมาแล้วทั้งหมด : </span><b>{cars?.carLoanDetailsRe?.totalpayment ? currencyFormatOne(cars?.carLoanDetailsRe?.totalpayment) + " บาท" : 0}</b>
                                                            </div>
                                                            <div label='ล/น คงเหลือรวม' name='neekong' style={{ marginBottom: 3 }}>
                                                                <span>ล/น คงเหลือรวม : </span><b>{cars?.carLoanDetailsRe?.neekong ? currencyFormatOne(cars?.carLoanDetailsRe?.neekong) + " บาท" : 0}</b>
                                                            </div>
                                                            <div label='เบี้ยปรับ' name='beeypup' style={{ marginBottom: 3 }}>
                                                                <span>เบี้ยปรับ : </span><b>{cars?.carLoanDetailsRe?.beeypup ? currencyFormatOne(cars?.carLoanDetailsRe?.beeypup) + " บาท" : 0}</b>
                                                            </div>
                                                            <div label='ลูกหนี้อื่นๆ' name='OTHR' style={{ marginBottom: 3 }}>
                                                                <span>ลูกหนี้อื่นๆ : </span><b>{cars?.carLoanDetailsRe?.OTHR ? currencyFormatOne(cars?.carLoanDetailsRe?.OTHR) + " บาท" : 0}</b>
                                                            </div>
                                                            <div label='จำนวนงวดที่ผ่อนมาแล้ว' name='NOPAY' style={{ marginBottom: 3 }}>
                                                                <span>จำนวนงวดที่ผ่อนมาแล้ว : </span><b>{cars?.carLoanDetailsRe?.NOPAY ? currencyFormatOne(cars?.carLoanDetailsRe?.NOPAY) + " งวด" : 0}</b>
                                                            </div>
                                                            <div label='จำนวนงวดทั้งหมด' name='nopay' style={{ marginBottom: 3 }}>
                                                                <span>จำนวนงวดทั้งหมด : </span><b>{cars?.carLoanDetailsRe?.nopays ? currencyFormatOne(cars?.carLoanDetailsRe?.nopays) + " งวด" : 0}</b>
                                                            </div>
                                                            <div label='ตัดสด งวดที่' name='reqNo' style={{ marginBottom: 3 }}>
                                                                <span>ตัดสด งวดที่ : </span><b>{cars?.carLoanDetailsRe?.reqNo ? currencyFormatOne(cars?.carLoanDetailsRe?.reqNo) : 0}</b>
                                                            </div>
                                                            <div style={{ marginBottom: 0, background: '#1677ff' }}>
                                                                <span>จำนวนงวดที่ค้าง : </span>
                                                                <b>{~~cars?.carLoanDetailsRe?.nopays - ~~cars?.carLoanDetailsRe?.NOPAY} งวด</b>
                                                            </div>
                                                            <div style={{ marginBottom: 0, }}>
                                                                <span>ยอดรีไฟแนนซ์เพื่อจัดใหม่ : </span>
                                                                <b>{currencyFormatOne(cars?.carLoanDetailsRe?.re1)} บาท</b>
                                                            </div>
                                                        </aside>
                                                        <Divider orientation={'left'}><b><u>รายละเอียดจัดใหม่</u></b></Divider>
                                                        <aside style={{ width: '100%' }}>
                                                            <b>
                                                                <div style={{ margin: 0, }}>
                                                                    <span>ยอดรีไฟแนนซ์เพื่อจัดใหม่ : </span>
                                                                    <b style={{ color: 'red', fontSize: '18px' }}><u>{currencyFormatOne(cars?.carLoanDetailsRe?.re1)}</u></b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </div>
                                                            </b>
                                                            <b>
                                                                <div style={{ margin: 0, }}>
                                                                    <span>ยอดจัดใหม่ : </span>
                                                                    <b style={{ color: 'red', fontSize: '18px' }}><u>{currencyFormatOne(cars?.carLoanDetailsRe?.approvedLoanAmount)}</u></b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </div>
                                                            </b>
                                                            <b>
                                                                <div style={{ margin: 0, }}>
                                                                    <span>จำนวนงวด : </span>
                                                                    <b style={{ color: 'red', fontSize: '18px' }}><u>{currencyFormatOne(cars?.carLoanDetailsRe?.approvedLoanTerm)}</u></b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        งวด
                                                                    </span>
                                                                </div>
                                                            </b>
                                                        </aside>
                                                    </>
                                            }
                                        </>
                                        : null
                                }
                                {
                                    carLoanDetails?.approvalStatus === 'อนุมัติ' ||
                                        carLoanDetails?.approvalStatus === 'รอทำสัญญา' ||
                                        carLoanDetails?.approvalStatus === 'ลูกค้าปฏิเสธ' ||
                                        carLoanDetails?.approvalStatus === 'รอตรวจสอบเอกสาร' ||
                                        carLoanDetails?.approvalStatus === 'ลูกค้าคอนเฟิร์ม' ?
                                        <>
                                            <Divider />
                                            <Row justify={'left'}><b><u>อนุมัติ {ap?.apProductType !== "" ? ap?.apProductType : carLoanDetails?.productType} {
                                                ap?.apProductType === "เช่าซื้อ1" || carLoanDetails?.productType === "เช่าซื้อ1" ?
                                                    <>
                                                        {ap?.apInterestRate !== "" ? <>({ap?.apInterestRate})</> : <>({carLoanDetails?.interestRate})</>}
                                                    </>
                                                    : null
                                            }</u></b>
                                            </Row>
                                            <Row gutter={32} justify={'center'} style={{ backgroundColor: 'lightgreen' }}>
                                                <aside style={{ width: '90%' }}>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>ยอดกู้ : </span>
                                                        {
                                                            ap?.apLoanAmount !== "0" ?
                                                                <b>{currencyFormatOne(ap?.apLoanAmount)}</b>
                                                                :
                                                                <b>{currencyFormatOne(carLoanDetails?.approvedLoanAmount)}</b>
                                                        }
                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                            บาท
                                                        </span>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>จำนวนงวด : </span>
                                                        {
                                                            ap?.apLoanTerm !== "0" ?
                                                                <b>{currencyFormatOne(ap?.apLoanTerm)}</b>
                                                                :
                                                                <b>{currencyFormatOne(carLoanDetails?.approvedLoanTerm)}</b>
                                                        }
                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                            งวด
                                                        </span>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>ดอกเบี้ย : </span>
                                                        {
                                                            ap?.apInterest !== "0" ?
                                                                <b>{currencyFormatOne(ap?.apInterest)}</b>
                                                                :
                                                                <b>{interestt}</b>
                                                        }
                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                            บาท
                                                        </span>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }} >
                                                        <span>จำนวนผ่อนต่อเดือน : </span>
                                                        {
                                                            ap?.apMonthlyPayment !== "0" ?
                                                                <b>{currencyFormatOne(ap?.apMonthlyPayment)}</b>
                                                                :
                                                                <b>{arr}</b>
                                                        }
                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                            บาท
                                                        </span>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }} >
                                                        <span>ผ่อนรวมดอกเบี้ย : </span>
                                                        {
                                                            ap?.apInstallmentWithInterest !== "0" ?
                                                                <b>{currencyFormatOne(ap?.apInstallmentWithInterest)}</b>
                                                                :
                                                                <b>{installmentWithInterestt}</b>
                                                        }
                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                            บาท
                                                        </span>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>ผู้อนุมัติเคส : </span>
                                                        {
                                                            ap?.apApprovedBy !== "" ?
                                                                <b>{ap?.apApprovedBy}</b>
                                                                :
                                                                <b>{carLoanDetails.approvedBy}</b>
                                                        }

                                                    </div>
                                                    <div>
                                                        {/* ตาราง */}
                                                        {
                                                            ap.loanId !== 0 ?
                                                                <>
                                                                    {
                                                                        ap.apProductType === "p-loan" ?
                                                                            <>
                                                                                <Col span={24}>
                                                                                    <div style={{ textAlign: "right" }}>
                                                                                        <Button onClick={openTable}>ตารางค่างวด P-Loan</Button>
                                                                                    </div>
                                                                                </Col>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {
                                                                                    ap.apInterestRate === "0.75" || ap.apInterestRate === "0.84" ?
                                                                                        null
                                                                                        :
                                                                                        <>
                                                                                            {
                                                                                                cars.productTypeCar === 1 ?
                                                                                                    <>
                                                                                                        <Col span={24}>
                                                                                                            <div style={{ textAlign: "right" }}>
                                                                                                                <Button onClick={openTable2}>ตารางค่างวด เช่าซื้อ</Button>
                                                                                                            </div>
                                                                                                        </Col>
                                                                                                    </>
                                                                                                    : null
                                                                                            }
                                                                                        </>
                                                                                }
                                                                            </>
                                                                    }
                                                                </>
                                                                :
                                                                <>
                                                                    {
                                                                        carLoanDetails.productType === "p-loan" ?
                                                                            <>
                                                                                <Col span={24}>
                                                                                    <div style={{ textAlign: "right" }}>
                                                                                        <Button onClick={openTable}>ตารางค่างวด P-Loan</Button>
                                                                                    </div>
                                                                                </Col>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {
                                                                                    carLoanDetails.interestRate === "0.75" || carLoanDetails.interestRate === "0.84" ?
                                                                                        null
                                                                                        :
                                                                                        <>
                                                                                            {
                                                                                                cars.productTypeCar === 1 ?
                                                                                                    <>
                                                                                                        <Col span={24}>
                                                                                                            <div style={{ textAlign: "right" }}>
                                                                                                                <Button onClick={openTable2}>ตารางค่างวด เช่าซื้อ</Button>
                                                                                                            </div>
                                                                                                        </Col>
                                                                                                    </>
                                                                                                    : null
                                                                                            }
                                                                                        </>
                                                                                }
                                                                            </>
                                                                    }
                                                                </>
                                                        }
                                                    </div>
                                                </aside>
                                            </Row>
                                        </>
                                        : null
                                }
                                <Divider />
                                <Row justify={'left'}><b><u>รายละเอียดรถ</u></b></Row>
                                <Row gutter={32} justify={'center'}>
                                    <aside style={{ width: '90%' }}>
                                        <div style={{ marginBottom: 0, }}>
                                            <span>ยี่ห้อ : </span>
                                            <b>{cars?.carBrand}</b>
                                        </div>
                                        <div style={{ marginBottom: 0, }}>
                                            <span>เลขทะเบียน : </span>
                                            <b>{cars?.carPlateNumber}</b>
                                        </div>
                                        <div style={{ marginBottom: 0, }}>
                                            <span>เลขเครื่อง : </span>
                                            <b>{cars?.carEngineNumber}</b>
                                        </div>
                                        <div style={{ marginBottom: 0, }}>
                                            <span>รุ่นสินค้า : </span>
                                            <b>{cars?.carModel}</b>
                                        </div>
                                        <div style={{ marginBottom: 0, }}>
                                            <span>เลขคัสซี : </span>
                                            <b>{cars?.carChassisNumber}</b>
                                        </div>
                                        <div style={{ marginBottom: 0, }}>
                                            <span>สี : </span>
                                            <b>{cars?.carColor}</b>
                                        </div>
                                        <div style={{ marginBottom: 0, }}>
                                            <span>แบบรถ : </span>
                                            <b>{cars?.carBaab}</b>
                                        </div>
                                        <div style={{ marginBottom: 0, }}>
                                            <span>ปีรถ : </span>
                                            <b>{cars?.carYear}</b>
                                        </div>
                                    </aside>
                                </Row>
                                <Divider />
                                <Row justify={'left'}>
                                    <b><u>อาชีพ - รายได้</u></b>
                                </Row>
                                {
                                    career !== undefined || career?.length > 0 ?
                                        <>
                                            {
                                                career?.map((e, index) => {
                                                    return (
                                                        <Row gutter={32} justify={'center'}>
                                                            <aside style={{ width: '90%' }}>
                                                                <div style={{ marginBottom: 0, }}>
                                                                    <span>กลุ่มอาชีพ : </span>
                                                                    <b>{e?.desc}</b>
                                                                </div>
                                                                <div style={{ marginBottom: 0, }}>
                                                                    <span>ชื่อบริษัท(สถานที่ทำงาน) : </span>
                                                                    <b>{e?.companyName}</b>
                                                                </div>
                                                                <div style={{ marginBottom: 0, }}>
                                                                    <span>อาชีพ  : </span>
                                                                    <b>{e?.descSub}</b>
                                                                </div>
                                                                <div style={{ marginBottom: 0, }}>
                                                                    <span>รายได้ / เดือน : </span>
                                                                    <b>{currencyFormatOne(e?.incomeMonth)}</b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </div>
                                                                <div style={{ marginBottom: 0, }}>
                                                                    <span>สาขาอาชีพ : </span>
                                                                    <b>{e?.descSection}</b>
                                                                </div>

                                                                <div style={{ marginBottom: 0, }}>
                                                                    <span>
                                                                        รายจ่าย / เดือน : </span>
                                                                    <b>{currencyFormatOne(e?.expensesMonth)}</b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </div>
                                                            </aside>
                                                            <Divider />
                                                        </Row>
                                                    )
                                                })}
                                        </>
                                        :
                                        <Row gutter={32} justify={'center'}>
                                            <div style={{ marginBottom: 0, }}>
                                                <span>ไม่พบอาชีพ </span>
                                            </div>
                                            <Divider />
                                        </Row>

                                }
                                <Row justify={'left'}>
                                    <Col span={12}>
                                        <b><u>รายละเอียดคนค้ำ</u></b>
                                    </Col>
                                    <Col span={12} style={{ textAlign: 'right' }}>
                                        <Button type='primary' icon={<PlusOutlined />} onClick={handleAddGua}>เพิ่มคนค้ำ</Button>
                                    </Col>
                                </Row>
                                {
                                    guarantor1?.map((item, index) => {
                                        return renderGuarantorNewImg({ item, index })
                                    })
                                }
                                <Divider />
                                <Row justify={'left'}><Col span={24}><b><u>หมายเหตุ</u></b></Col></Row>
                                <Row gutter={32} justify={'center'}>
                                    {notes.length >= 1 ?
                                        notes.map((item, index) => {
                                            return renderDataNote({ item, index });
                                        })
                                        : <>* ยังไม่เพิ่มหมายเหตุ!</>
                                    }
                                </Row>
                                <Divider />
                                {
                                    dataModalEdit?.carLoanDetails?.approvalDescription !== "" && dataModalEdit?.carLoanDetails?.approvalDescription !== null && dataModalEdit?.carLoanDetails?.approvalDescription !== undefined ?
                                        <>
                                            <Row justify={'left'}><Col span={24}><b><u>หมายเหตุเงื่อนไขการอนุมัติ</u></b></Col></Row>
                                            <Row gutter={32} justify={'center'}>
                                                <div style={{ marginBottom: 0, }}>
                                                    <span>เงื่อนไขการอนุมัติ : </span>
                                                    <b>{dataModalEdit?.carLoanDetails?.approvalDescription}</b>
                                                </div>
                                            </Row>
                                            <Divider />
                                        </>
                                        : null
                                }
                                <Row gutter={32} justify={'center'}>
                                    <Col span={24} style={{ textAlign: 'right' }}>
                                        <Space direction="vertical">
                                            <Button type='primary' icon={<PictureOutlined />} style={{ backgroundColor: "#3FB8FE" }} onClick={handleShowImg}>
                                                รูปตอนเสนอ
                                            </Button>
                                            {/* {
                                                checkSendNEW ?
                                                    <>
                                                        <Button type='primary' icon={<PictureOutlined />} style={{ backgroundColor: "#FEA13F" }} onClick={handleShowImgPre}>
                                                            รูปตอนเสนอ (ใหม่)
                                                        </Button>
                                                    </>
                                                    : null
                                            } */}
                                        </Space>
                                    </Col>
                                </Row>
                                <Divider />
                                <Row justify={'left'}><b><u>รูปบัตรประชาชนผู้ทำสัญญา</u></b></Row>
                                <Row gutter={32} justify={'center'}>
                                    <Space direction="vertical" >
                                        {
                                            <Col className='gutter-row' span={24}>
                                                <b>
                                                    <Form.Item label='เพิ่มรูป' name='imageIDGuCar'>
                                                        <Upload
                                                            name='imageIDGuCar'
                                                            multiple={true}
                                                            listType="picture-card"
                                                            fileList={fileListCustomer}
                                                            onPreview={handlePreview}
                                                            onChange={handleCustomerImage}
                                                            beforeUpload={(file) => beforeUpts(file, 4).then(() => false).catch(() => true)}
                                                        >
                                                            <div>
                                                                <PlusOutlined />
                                                                <div className="ant-upload-text">upload</div>
                                                            </div>
                                                        </Upload>
                                                        <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                                            <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                        </Modal>
                                                    </Form.Item>
                                                </b>
                                            </Col>
                                        }
                                    </Space>
                                </Row>
                                <Divider />
                                <Row justify={'left'}><b><u>หน้าคู่มือ</u></b></Row>
                                <Row gutter={32} justify={'center'}>
                                    <Space direction="vertical">
                                        <div className='gutter-row' span={12}>
                                            <b>
                                                <Form.Item label='เพิ่มรูป' name='imageIDGuCar'>
                                                    <Upload
                                                        name='imageIDGuCar'
                                                        multiple={true}
                                                        listType="picture-card"
                                                        fileList={fileListCustomerDocument}
                                                        onPreview={handlePreview}
                                                        onChange={handleCustomerImageDocument}
                                                        beforeUpload={(file) => beforeUpts(file, 2).then(() => false).catch(() => true)}
                                                    >
                                                        <div>
                                                            <PlusOutlined />
                                                            <div className="ant-upload-text">upload</div>
                                                        </div>
                                                    </Upload>
                                                    <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                                        <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                    </Modal>
                                                </Form.Item>
                                            </b>
                                        </div>
                                    </Space>
                                </Row>
                                <Divider />
                                <Row justify={'left'}><b><u>ใบประเมิน / รูปรถ / สัญญาไฟแนนซ์เดิม / ใบเสร็จไฟแนนซ์เดิม</u></b></Row>
                                <Row gutter={32} justify={'center'}>
                                    <Space direction="vertical">
                                        <div className='gutter-row' span={12}>
                                            <b>
                                                <Form.Item label='เพิ่มรูป' name='imageIDGuCar'>
                                                    <Upload
                                                        name='imageIDGuCar'
                                                        multiple={true}
                                                        listType="picture-card"
                                                        fileList={fileListCustomerImageCar}
                                                        onPreview={handlePreview}
                                                        onChange={handleCustomerImageCar}
                                                        beforeUpload={(file) => beforeUpts(file, 1).then(() => false).catch(() => true)}
                                                    >
                                                        <div>
                                                            <PlusOutlined />
                                                            <div className="ant-upload-text">upload</div>
                                                        </div>
                                                    </Upload>
                                                    <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                                        <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                    </Modal>
                                                </Form.Item>
                                            </b>
                                        </div>
                                    </Space>
                                </Row>
                                <Divider />
                                <Row justify={'left'}><b><u>ประวัติผู้กู้ / ทะเบียนบ้าน / อาชีพ</u></b></Row>
                                <Row gutter={32} justify={'center'}>
                                    <Space direction="vertical" >
                                        <div className='gutter-row' span={12}>
                                            <b> <Form.Item label='เพิ่มรูป' name='imageIDGuCar'>
                                                <Upload
                                                    name='imageIDGuCar'
                                                    multiple={true}
                                                    listType="picture-card"
                                                    fileList={fileListCustomerImageCareer}
                                                    onPreview={handlePreview}
                                                    onChange={handleCustomerImageCareer}
                                                    beforeUpload={(file) => beforeUpts(file, 6).then(() => false).catch(() => true)}
                                                >
                                                    <div>
                                                        <PlusOutlined />
                                                        <div className="ant-upload-text">upload</div>
                                                    </div>
                                                </Upload>
                                                <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                                    <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                </Modal>
                                            </Form.Item>
                                            </b>
                                        </div>
                                    </Space>
                                </Row>
                                <Divider />
                                <Row justify={'left'}><b><u>รูปสเตทเมนต์ผู้กู้</u></b></Row>
                                <Row gutter={32} justify={'center'}>
                                    <Space direction="vertical" >
                                        <div className='gutter-row' span={12}>
                                            <b>
                                                <Form.Item label='เพิ่มรูป' name='imageIDGuCar'>
                                                    <Upload
                                                        name='imageIDGuCar'
                                                        multiple={true}
                                                        listType="picture-card"
                                                        fileList={fileListStatement}
                                                        onPreview={handlePreview}
                                                        onChange={handleStatement}
                                                        beforeUpload={(file) => beforeUpts(file, 8).then(() => false).catch(() => true)}
                                                    >
                                                        <div>
                                                            <PlusOutlined />
                                                            <div className="ant-upload-text">upload</div>
                                                        </div>
                                                    </Upload>
                                                    <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                                        <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                    </Modal>
                                                </Form.Item>
                                            </b>
                                        </div>
                                    </Space>
                                </Row>
                                <Divider />
                                <Row justify={'left'}><b><u>รูปสเตทเมนต์ผู้กู้ PDF</u></b></Row>
                                <Row gutter={32} justify={'center'}>
                                    <Space direction="vertical" >
                                        <div className='gutter-row' span={12}>
                                            <b>
                                                <Form.Item label='เพิ่มรูป' name='imageIDGuCar'>
                                                    <Upload
                                                        name='imageIDGuCar'
                                                        multiple={true}
                                                        listType="picture-card"
                                                        fileList={fileListStatementPDF}
                                                        onPreview={handlePreview}
                                                        onChange={handleStatementPDF}
                                                        beforeUpload={(file) => beforeUpts(file, 7).then(() => false).catch(() => true)}
                                                    >
                                                        <div>
                                                            <PlusOutlined />
                                                            <div className="ant-upload-text">upload</div>
                                                        </div>
                                                    </Upload>
                                                    <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                                        <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                    </Modal>
                                                </Form.Item>
                                            </b>
                                        </div>
                                    </Space>
                                </Row>
                                <Divider />
                                <Row justify={'left'}><b><u>เอกสารเพิ่มเติม</u></b></Row>
                                <Row gutter={32} justify={'center'}>
                                    <Space direction="vertical" >
                                        <div className='gutter-row' span={12}>
                                            <b>
                                                <Form.Item label='เพิ่มรูป' name='imageIDGuCar'>
                                                    <Upload
                                                        name='imageIDGuCar'
                                                        multiple={true}
                                                        listType="picture-card"
                                                        fileList={fileListOtherMent}
                                                        onPreview={handlePreview}
                                                        onChange={handleOtherMent}
                                                        beforeUpload={(file) => beforeUpts(file, 12).then(() => false).catch(() => true)}
                                                    >
                                                        <div>
                                                            <PlusOutlined />
                                                            <div className="ant-upload-text">upload</div>
                                                        </div>
                                                    </Upload>
                                                    <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                                        <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                    </Modal>
                                                </Form.Item>
                                            </b>
                                        </div>
                                    </Space>
                                </Row>
                            </Card>
                        </div>
                    </Form>
                </Row>
                <Divider />
                <Row justify={'center'}>
                    <Space>
                        <Button key="back" type='primary' danger onClick={confirmClose}>
                            ปิด
                        </Button>
                        {
                            allImg.length > 0 ?
                                <Button type='primary' icon={<SendOutlined />} onClick={showConfirm}>
                                    บันทึกและส่งเคส
                                </Button>
                                :
                                <Button type='primary' disabled icon={<SendOutlined />}>
                                    บันทึกและส่งเคส
                                </Button>
                        }
                        {/* <Button type='primary' disabled={sendCaseBTN} onClick={sendCase} icon={<SendOutlined />}>
                            ส่งเคส
                        </Button> */}
                    </Space>
                </Row >
                {contextHolder}
            </Spin>

            {
                checkOpen ?
                    <Installment
                        open={checkOpen}
                        close={setCheckOpen}
                        cp={loanAmountt}
                        cp2={installmentCount}
                        checkGARFE={1}
                    />
                    : null
            }
            {
                checkOpenHIRE ?
                    <HirePurchase
                        open={checkOpenHIRE}
                        close={setCheckOpenHIRE}
                        cp={loanAmountt}
                        cp2={installmentCount}
                        checkGARFE={1}
                    />
                    : null
            }
            {
                isModalShowImg ?
                    <ModalShowImg open={isModalShowImg} close={setIsModalShowImg} dataFromTable={dataFromTable} oldData={oldData} imageBlobzz={imageBlobzz} />
                    : null
            }

            {/* {
                isModalShowImgPre ?
                    <ModalShowImgPre open={isModalShowImgPre} close={setIsModalShowImgPre} dataFromTable={dataFromTable} oldData={oldData} imageBlobzz={imageBlobzz} />
                    : null
            } */}

            {
                isModalAddGua ?
                    <AddGua open={isModalAddGua} close={setIsModalAddGua} indexedit={indexEdit} getData={dataModalEdit} pregarno={guarantor1.length} oldGua={guarantor1} sb={setSendBack} />
                    : null
            }
            {
                isModalsEditGua ?
                    <EditGua
                        open={isModalsEditGua}
                        close={setIsModalsEditGua}
                        sb={setSendBack}
                        dataGuarantor={guarantor1}
                        shootdata={dataedit}
                        dataindex={index1}
                        setSendback={setSendBack}
                        indexedit={indexEdit}
                        preData={preData}
                    />
                    :
                    null
            }
        </Modal >

    )
};

export default ModalAddAaprove