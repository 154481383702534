import React, { useEffect, useState } from 'react'
import { Button, Input, Form, DatePicker, Card, Col, Row, Select, Radio, Space, Divider } from 'antd';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { addCustomer } from '../../../../redux/Customer';

function GECustomer({ getData, dataGua, next, chg, close, cusIdMain }) {

    const { customers } = useSelector((state) => ({ ...state }))

    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [dataPost, setDataPost] = useState({ ...getData, ...dataGua, customerId2: getData.customerId, customerId: cusIdMain })
    const [dataCus] = useState(customers.data)

    useEffect(() => {
        if (customers.data.customerId2 !== undefined) {
            setDataPost(dataCus)
            form.setFieldsValue(
                {
                    ...customers.data,
                    birthdate: dayjs(customers.data?.birthdate),
                    issudt: dayjs(customers.data?.issudt),
                    expdt: dayjs(customers.data?.expdt),
                }
            )
        } else {
            setDataPost({ ...getData, ...dataGua, customerId2: getData.customerId, customerId: cusIdMain })
            form.setFieldsValue(
                {
                    ...getData,
                    ...dataGua,
                    birthdate: dayjs(getData?.birthdate),
                    issudt: dayjs(getData?.issudt),
                    expdt: dayjs(getData?.expdt),
                }
            )
        }
    }, [getData])

    const handleSubmit = async (e) => {
        delete dataPost.dataAddress
        delete dataPost.dataPhones

        // console.log("customer", dataPost)
        dispatch(addCustomer(dataPost))
        onChangeGo()
    }

    const onChangeGo = () => {
        next()
    }

    const handleCancel = () => {
        close()
    }

    return (
        <Card >
            <div>
                <div>
                    <Row>
                        <Col span={24} style={{ fontSize: '30px', textAlign: 'center' }} >ข้อมูลส่วนตัว</Col>
                    </Row>
                </div>
                <Divider></Divider>
                <Form
                    labelCol={{
                        span: 12,
                    }}
                    wrapperCol={{
                        span: 12,
                    }}
                    form={form}
                    onFinish={handleSubmit}
                    autoComplete="off">
                    <Row>
                        <Col span={10}>
                            <Form.Item label='เลขประจำตัวประชาชน' name='identificationId'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input !',
                                    },]}>
                                <Input type='text' maxLength={13} disabled
                                // onChange={(e) => inputIdCard(e)}
                                >
                                </Input>
                            </Form.Item>
                            <Form.Item label='รูปบัตรประชาชน'>
                                <Input type="file" multiple={true} disabled
                                // onChange={handleFileInputChange} 
                                >
                                </Input>
                            </Form.Item>
                            <Form.Item label="คำนำหน้าชื่อ" name="snam"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input !',
                                    },]}>
                                <Radio.Group onChange={(e) => setDataPost({ ...dataPost, snam: e.target.value })} >
                                    <Radio value="นาย"> นาย </Radio>
                                    <Radio value="นาง"> นาง </Radio>
                                    <Radio value="นางสาว"> นางสาว </Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item label='ชื่อ' name='firstname'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input !',
                                    },]}
                            >
                                <Input type='text'
                                    onChange={(e) => setDataPost({ ...dataPost, firstname: e.target.value })}></Input>
                            </Form.Item>
                            <Form.Item label='นามสกุล' name='lastname'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input !',
                                    },]}>
                                <Input type='text'
                                    onChange={(e) => setDataPost({ ...dataPost, lastname: e.target.value })}></Input>
                            </Form.Item>

                            <Form.Item label='ชื่อเล่น' name='nickname'>
                                <Input type='text'
                                    onChange={(e) => setDataPost({ ...dataPost, nickname: e.target.value })}></Input>
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item>
                                <Button type="primary" disabled
                                // onClick={(e) => { handleIdCard(e) }}
                                >ตรวจสอบ</Button>
                            </Form.Item>
                        </Col>
                        <Col span={10}>
                            {/* <Form.Item label='ประเทศ' name='nationalId'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <Input type='text'
                                        onChange={(e) => setDataPost({ ...dataPost, nationalId: e.target.value })}></Input>
                                </Form.Item> */}
                            <Form.Item label='เกิดวันที่' name='birthdate'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input !',
                                    },]}>
                                <DatePicker format={'YYYY-MM-DD'} style={{ width: '100%' }}
                                    onChange={(e) => {
                                        if (e) {
                                            setDataPost({ ...dataPost, birthdate: e.format('YYYY-MM-DD') })
                                        }
                                    }} />
                            </Form.Item>
                            <Form.Item label='เพศ' name='gender'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input !',
                                    },]}>
                                <Select
                                    placeholder={'เลือก'}
                                    style={{
                                        width: '100%',
                                    }}
                                    onChange={(value) => setDataPost({ ...dataPost, gender: value })}
                                    options={[
                                        {
                                            label: 'ชาย',
                                            value: 'M',
                                        },
                                        {
                                            label: 'หญิง',
                                            value: 'F',
                                        },
                                    ]} />
                            </Form.Item>
                            <Form.Item label='สถานที่ออกบัตรประชาชน' name='issuby'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input !',
                                    },]}>
                                <Input type='text'
                                    onChange={(e) => setDataPost({ ...dataPost, issuby: e.target.value })}></Input>
                            </Form.Item>
                            <Form.Item label='วันออกบัตร' name='issudt'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input !',
                                    },]}>
                                <DatePicker format={'YYYY-MM-DD'} style={{ width: '100%' }}
                                    onChange={(e) => {
                                        if (e) {
                                            setDataPost({ ...dataPost, issudt: e.format('YYYY-MM-DD') })
                                        }
                                    }} />
                            </Form.Item>
                            <Form.Item label='วันบัตรหมดอายุ' name='expdt'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input !',
                                    },]}>
                                <DatePicker format={'YYYY-MM-DD'} style={{ width: '100%' }}
                                    onChange={(e) => {
                                        if (e) {
                                            setDataPost({ ...dataPost, expdt: e.format('YYYY-MM-DD') })
                                        }
                                    }} />
                            </Form.Item>
                            <Form.Item label='ความสัมพันกับผู้กู้' name='description'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input !',
                                    },]}>
                                <Input type='text'
                                    onChange={(e) => setDataPost({ ...dataPost, description: e.target.value })}></Input>
                            </Form.Item>
                            <Form.Item label='สาขา' name='locat'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input !',
                                    },]}>
                                <Input type='text'
                                    onChange={(e) => setDataPost({ ...dataPost, locat: e.target.value })}></Input>
                            </Form.Item>
                        </Col>
                    </Row>
                    <div style={{ textAlign: "center" }}>
                        <Space>
                            <Button type="primary" style={{ background: "red" }} onClick={handleCancel}>ยกเลิก</Button>
                            <Button type="primary" htmlType="submit" style={{ background: "green" }}>บันทึก</Button>
                            <Button type="primary" onClick={onChangeGo}>ต่อไป</Button>
                        </Space>
                    </div>
                </Form>
            </div>
        </Card>
    )
};

export default GECustomer