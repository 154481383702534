import { Col, Divider, Modal, Row, Steps } from 'antd'
import React, { useState } from 'react'
import Page1 from './Note/Page1'
import Page2 from './Note/Page2'

function ModalMainNote({ open, close, closeall, shootdata, appStatus, setGetNotification, sendback, setCheckApprove, guarantorData, newDataLand }) {

    const [pages, setPages] = useState(1)
    const [saveData, setSaveData] = useState()

    console.log("saveData", saveData)

    const handleClose = () => {
        close(false)
    }

    const steps = [
        {
            title: 'ยอดอนุมัติ/หมายเหตุ',
        },
        {
            title: 'หมายเหตุถึงธุรการ',
        },
    ];

    const stepsUnApprove = [
        {
            title: 'หมายเหตุ',
        },
        {
            title: 'หมายเหตุถึงธุรการ',
        },
    ];

    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
    }));

    return (
        <Modal title={"Note"} open={open} onClose={handleClose} footer={[null]} closable={false} width={800}>
            <Row>
                <Col span={4}></Col>
                <Col span={16}>
                    {
                        appStatus === 'อนุมัติ' || appStatus === 'อนุมัติแบบมีเงื่อนไข' ?
                            <Steps current={pages - 1} items={items} />
                            :
                            <Steps current={pages - 1} items={stepsUnApprove} />
                    }
                </Col>
                <Col span={4}></Col>
            </Row>
            <Divider />
            {pages === 1 && (<Page1
                pages={pages}
                setPages={setPages}
                close={handleClose}
                closeall={closeall}
                setCheckApprove={setCheckApprove}
                shootdata={shootdata}
                appStatus={appStatus}
                guarantorData={guarantorData}
                sendback={sendback}
                newDataLand={newDataLand}
                saveData={saveData}
                setSaveData={setSaveData} />)}
            {pages === 2 && (<Page2
                pages={pages}
                close={handleClose}
                closeall={closeall}
                setPages={setPages}
                appStatus={appStatus}
                saveData={saveData}
                setSaveData={setSaveData} />)}
        </Modal>

    )
}

export default ModalMainNote