import React, { useState, useEffect } from "react";
import { SearchOutlined } from '@ant-design/icons';
import { DatePicker, Radio, Row, Button, Card, Space, Divider } from 'antd';
import dayjs from 'dayjs';
import axios from 'axios';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import { getall } from "../file_mid/all_api";


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


export default function Main_Boss_Report() {
  const token = localStorage.getItem('token')
  const tk = JSON.parse(token)
  //  dayjs.locale('th'); // กำหนด locale ให้กับ dayjs
  const [timePeriod, setTimePeriod] = useState('year');
  const [selectedYear1, setSelectedYear1] = useState(dayjs());
  const [selectedYear2, setSelectedYear2] = useState(dayjs());
  const [selectedM1, setSelectedM1] = useState(dayjs());
  const [selectedM2, setSelectedM2] = useState(dayjs());
  const [labels, setlabel] = useState([]);
  const [name1, setName] = useState([]);
  const [numdata, setNumdata] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const options = {
    responsive: true,
    plugins: {
      datalabels: {
        display: true,
        color: "black",
        align: "end",
        anchor: "end",
        font: { size: "14" }
      },
      legend: {
        position: 'top',
      },
      title: {
        display: false,
        text: 'Chart.js Bar Chart',
      },

    },
  };


  const name = name1
  const num_data = numdata
  const uniqueColors = [
    'rgba(255, 99, 132, 0.5)',
    'rgba(53, 152, 235, 1.5)',
    'rgba(75, 192, 192, 0.5)',
    'rgba(255, 206, 86, 0.5)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 159, 64, 0.5)',
    'rgba(54, 162, 235, 0.5)',
    'rgba(75, 100, 192, 1)',
    'rgba(255, 208, 86, 0.5)',
    'rgba(153, 102, 255, 0.5)',
    'rgba(255, 200, 64, 2.5)',
    'rgba(155, 75, 192, 0.5)',
    'rgba(215, 86, 65, 0.5)',
    'rgba(109, 255, 102, 0.5)',
    'rgba(235, 82, 67, 0.5)',
    // เพิ่มสีเพิ่มเติมตามที่ต้องการ
  ];

  const datasets = name.map((item, index) => {
    const backgroundColor = uniqueColors[index % uniqueColors.length];

    return {
      label: `${item.name}`,
      data: labels?.map((month) => {
        const filteredData = num_data?.find((entry) => entry.name === item.name && entry.month === month);
        return filteredData ? filteredData.value : 0;
      }),
      backgroundColor,
    };
  });

  const data = {
    labels,
    datasets,
  };



  const handleTimePeriodChange = (e) => {
    setTimePeriod(e.target.value);
  };
  const handleYearChange1 = (date) => {
    setSelectedYear1(date);

    // setCurrentYear(date.year());
  };
  const handleYearChange2 = (date) => {
    setSelectedYear2(date);

    // setCurrentYear(date.year());
  };
  const handlemChange1 = (date) => {
    setSelectedM1(date)
    // console.log(dayjs(date).format('MMYY'))
    // setCurrentYear(date.year());
  };
  const handlemChange2 = (date) => {
    setSelectedM2(date)
    //   const formattedDate = date.format(`YYYY-MM-${date.daysInMonth()}`);
    // console.log(formattedDate)


    // setCurrentYear(date.year());
  };

  const handle_button_Change = () => {

    if (timePeriod === 'year') {
      let startDate = dayjs(selectedYear1);
      const endDate = dayjs(selectedYear2);
      const diffInMonths = endDate.diff(startDate, 'year');
      //console.log(diffInMonths,startDate,endDate)
      const label1 = [];
      for (let i = 0; i <= diffInMonths; i++) {
        // const a = startDate
        label1.push(startDate.format('YYYY'));
        startDate = startDate.add(1, 'year')
        // console.log(startDate.format('YYYY'))
      }
      setlabel(label1)
      fetch_Data(timePeriod, dayjs(selectedYear1).format('YYYY'), dayjs(selectedYear2).format('YYYY'))
      // console.log("year",dayjs(selectedYear1).format('YYYY'),dayjs(selectedYear2).format('YYYY'))
    } else {
      let startDate = dayjs(selectedM1);
      const endDate = dayjs(selectedM2);
      const diffInMonths = endDate.diff(startDate, 'month');
      const label1 = [];
      for (let i = 0; i <= diffInMonths; i++) {
        // const a = startDate
        label1.push(startDate.format('MMMYY'));
        startDate = startDate.add(1, 'month')
        // console.log(startDate.format('MMMYY'))
      }
      setlabel(label1)
      fetch_Data(timePeriod, dayjs(selectedM1).format('YYYY-MM-01'), dayjs(selectedM2).format('YYYY-MM-31'))
      //  console.log("mont",dayjs(selectedM1).format('MMYY'),dayjs(selectedM2).format('MMYY'))
    }
  };

  const fetch_Data = async (Period, Year1, Year2) => {
    const headers = {
      "Authorization": `Bearer ${tk}`,
      "Menu": JSON.stringify("8")
    }
    const payload = { timeperiod: Period, startdate: Year1, enddate: Year2 };
    console.log("payload", payload)
    setIsLoading(true);
    await axios.post(getall, payload, { headers: headers })
      //await axios.post(`http://localhost:8080/boss/getall`, payload, { headers: headers })
      .then(res => {
        console.log("aa", res.data)
        if (res.status === 200) {
          setName(res.data.name)
          setNumdata(res.data.chart)
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }


      })
      .catch(e => {
        console.log(e)
        setNumdata(null)
        // setAlertMenu(true)
        // setTimeout(function () {
        //   setAlertMenu(false)
        // }, 3000)
      })
  }

  return (
    <Card style={{ padding: 20 }}>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 0,
        marginBottom: 30,
      }}>
        <div className='text-center'>
          <h2>ตรวจสอบข้อมูล(บอส)</h2>
        </div>
        <Radio.Group
          onChange={handleTimePeriodChange}
          value={timePeriod}
          style={{ marginBottom: 16 }}
        >
          <Radio.Button value="year">Yearly</Radio.Button>
          <Radio.Button value="month">Monthly</Radio.Button>
        </Radio.Group>

        {timePeriod === 'year' ? (
          // แสดงเลือกปี
          <Row>
            <Space>
              <DatePicker
                value={selectedYear1}
                onChange={handleYearChange1}
                picker={'year'}
              />
              <DatePicker
                value={selectedYear2}
                onChange={handleYearChange2}
                picker={'year'}
              />
            </Space>
          </Row>
        ) : (
          // แสดงเลือกเดือน
          <>
            <DatePicker
              value={selectedM1}
              onChange={handlemChange1}
              picker={'month'}
            />
            <DatePicker
              value={selectedM2}
              onChange={handlemChange2}
              picker={'month'}
            />
          </>
        )}
        <Button type="primary"
          icon={<SearchOutlined />}
          disabled={isLoading}
          onClick={handle_button_Change}>
          ค้นหา
        </Button>

        <Divider />
        <Bar plugins={[ChartDataLabels]} options={options} data={data} />

      </div>
    </Card>
  )
}
