import React, { useState, useEffect } from 'react'
import { Form, Modal, Row, Spin, Card, Button, Divider, DatePicker, Typography, Col } from 'antd'
import dayjs from "dayjs";
import axios from "axios";
import { addTime } from '../../file_mid/all_api';

export default function ModalInfo({ open, dataFromTable, close }) {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false);
    const [addData, setAddData] = useState();

    useEffect(() => {
        setAddData(dataFromTable)
        form.setFieldsValue(
            {
                stopDay: dataFromTable.stopDay ? dayjs(dataFromTable.stopDay) : "",
                onDay: dataFromTable.onDay ? dayjs(dataFromTable.onDay) : "",
            }
        )
    }, [])

    const handleOk = async () => {
        setLoading(true)
        console.log('ok', addData)
        await axios.put(addTime, addData)
            .then(res => {
                if (res) {
                    console.log("scc", res)
                    close(false);
                }
            }).catch((err) => console.log(err))
        setLoading(false)
    };
    const handleCancel = () => {
        // dispatch(addCustomer())
        // dispatch(addCar())
        // dispatch(addAddress())
        // dispatch(addPhone())
        // dispatch(addGuarantor())
        close(false);
    };
    return (
        <Modal title="ลงวันเวลา" open={open} onOk={handleOk} width={820} onCancel={handleCancel}
            footer={[
                <Row justify={'center'}>
                    <Button key="back" type='primary' danger onClick={handleCancel}>
                        ปิด
                    </Button>
                    <Button type="text" onClick={handleOk} style={{ background: '#389e0d', color: '#ffffff' }} >
                        บันทึก
                    </Button>
                </Row>
            ]} >
            <Spin spinning={loading} size='large' tip=" Loading... ">
                {/* <div className='text-center'>
                    <h2>รายละเอียด</h2>
                </div> */}
                <Card>
                    <Form
                        labelCol={{
                            span: 12,
                        }}
                        wrapperCol={{
                            span: 18,
                        }}
                        form={form}
                        //onFinish={handleSubmit}
                        autoComplete="off"
                        initialValues={{ remember: true }}
                    >
                        <div className='text-center' style={{ margin: 0 }}>
                            <h2><u>รายละเอียด</u></h2>
                        </div>
                        <Divider />
                        <Row gutter={32} justify={'center'}>
                            {/* <aside style={{ width: '90%', fontSize: '16px' }}> */}
                            <Col span={9}>
                                <div name='team' style={{ marginBottom: 3, fontSize: '16px' }}>
                                    <span>ทีม : </span> <b>{dataFromTable?.team}</b>
                                </div>
                                <div name='name' style={{ marginBottom: 3, fontSize: '16px' }}>
                                    <span>รหัสธุรการ : </span> <b>{dataFromTable?.name}</b>
                                </div>
                                <div name='nickname' style={{ marginBottom: 3, fontSize: '16px' }}>
                                    <span>ชื่อธุรการ : </span> <b>{dataFromTable?.nickname}</b>
                                </div>
                                <div name='leaveStatus' style={{ marginBottom: 3, fontSize: '16px' }}>
                                    {
                                        dataFromTable?.leaveStatus === "no leave" ?
                                            <>
                                                <span>สถานะตอนนี้ : </span> <b>{"รับงาน"}</b>
                                            </> :
                                            <>
                                                <span>สถานะตอนนี้ : </span> <b>{"หยุดรับงาน"}</b>
                                            </>
                                    }
                                </div>
                            </Col>
                            <Col span={9}>
                                <Row justify={'center'}>
                                    <b>
                                        <Form.Item label='วันที่หยุดรับงาน' name='stopDay'
                                            style={{ textAlign: 'left', }}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input !',
                                                },]}>
                                            <DatePicker format={'YYYY-MM-DD'}
                                                style={{ height: '40px', color: 'black' }}
                                                onChange={(e) => {
                                                    if (e) {
                                                        setAddData({ ...addData, stopDay: e.format('YYYY-MM-DD') })
                                                    }
                                                }} />
                                        </Form.Item>
                                        <Form.Item label='วันที่เริ่มรับงาน' name='onDay'
                                            style={{ textAlign: 'left', }}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input !',
                                                },]}>
                                            <DatePicker format={'YYYY-MM-DD'}
                                                style={{ height: '40px', color: 'black' }}
                                                onChange={(e) => {
                                                    if (e) {
                                                        setAddData({ ...addData, onDay: e.format('YYYY-MM-DD') })
                                                    }
                                                }} />
                                        </Form.Item>
                                    </b>
                                </Row>
                            </Col>

                            {/* </aside> */}

                        </Row>
                        <Divider />
                        <Typography style={{ color: 'blue', fontSize: '18px', textAlign: 'center' }}>
                            <u>
                                * หมายเหตุ: ระบบจะเปลี่ยนสถานะอัตโนมัติตามวันที่เริ่มรับงาน เวลา 14.00 น. * <p />
                                ถ้าจะหยุดรับงาน : หลังจาก  เวลา 14.00 น. จะไม่สามารถหยุดรับงานได้
                            </u>
                        </Typography>
                        <p />
                        <Typography style={{ color: 'red', fontSize: '20px', textAlign: 'center' }}>
                            <p /><u>** ตัวอย่าง: **</u>
                        </Typography>

                        <Typography style={{ color: 'red', fontSize: '18px', textAlign: 'center' }}>
                            <p />ถ้าจะหยุดวันที่ 2024-01-25 จะต้องลงวันหยุดภายในวันที่ 2024-01-24 ก่อนเวลา 14.00 น. <p />
                            ระบบจะเปลี่ยนสถานะเป็น "หยุดรับงาน" ตั้งแต่ 2024-01-24 เวลา 14.00 น.
                        </Typography>
                    </Form>
                </Card>
            </Spin>
        </Modal >
    )
}
