import React, { useEffect } from "react";
// import { Col, Row, Steps } from "antd";
// import { useState } from "react";
import Address from "../add_customer/contents/Address";
import Description from "../add_customer/contents/Drescription";
import CareerIncome from "../add_customer/contents/CareerIncome";
import PersonalInfPost from "./postData/PersonalInfPost";
import { Route, Routes } from "react-router-dom";
import Contract from "../add_customer/contents/Contract";
import Guarantor from './contents/Guarantor';
import Product from './contents/Product'
import { useDispatch } from 'react-redux';


import { addCustomer } from '../../redux/Customer'
import { addAddress } from '../../redux/Address'
import { addPhone } from '../../redux/Phone'
import { addCar } from '../../redux/Car'
import { addLoan } from '../../redux/Loan'
import { addOldLoan } from '../../redux/OldLoan'
import { addImage } from '../../redux/Image'
import { addCareerIncome } from '../../redux/CareerIncome';
import { addGuarantor } from '../../redux/Guarantor';
import { addSocial } from '../../redux/Social';
import { addEmail } from '../../redux/Email';
import { addUser } from '../../redux/User';

const Main = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(addCustomer())
    dispatch(addAddress())
    dispatch(addPhone())
    dispatch(addCar())
    dispatch(addLoan())
    dispatch(addOldLoan())
    dispatch(addImage())
    dispatch(addCareerIncome())
    dispatch(addGuarantor())
    dispatch(addSocial())
    dispatch(addEmail())
    dispatch(addUser())
  }, [])

  return (
    <Routes>
      <Route path="/" element={<PersonalInfPost />} />
      <Route path="/address" element={<Address />} />
      <Route path="/contract" element={<Contract />} />
      <Route path="/careers" element={<CareerIncome />} />
      <Route path="/products" element={<Product />} />
      <Route path="/guarantor" element={<Guarantor />} />
      <Route path="/descriptions" element={<Description />} />
    </Routes>
  )
}

export default Main;
