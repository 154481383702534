import React, { useState, useEffect } from "react";
import { Table, Button, Input, Modal, Space, Typography, Form, Card, Row, Col, Spin, message } from 'antd';
import axios from "axios";

import EditRoleUser from "./EditRoleUser";
import ModalResetPass from "./ModalResetPass";
import { deleteuser, table } from "../file_mid/all_api";

//const { Title } = Typography;

export default function TableUser() {
    const { confirm } = Modal;
    const [userData, setUserData] = useState([]) // API
    const [editData, setEditData] = useState({})
    const [resetData, setResetData] = useState({})
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalReset, setIsModalReset] = useState(false);
    const [arrTable, setArrTable] = useState() // ค้นหา
    const [sortedInfo, setSortedInfo] = useState({}); // เรียง
    const [filteredInfo, setFilteredInfo] = useState({});
    const [loading, setLoading] = useState(false)
    const token = localStorage.getItem('token')
    const menu = localStorage.getItem('menu')
    const tk = JSON.parse(token)

    const handleChange = (pagination, filters, sorter) => {
        //console.log('Various parameters', pagination, filters, sorter);
        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };

    useEffect(() => {
        loadData()
    }, [isModalOpen])
    const success = () => {
        Modal.success({
            title: 'สำเร็จ',
            content: 'ลบข้อมูลสำเร็จ',
        })
    }
    const errors = () => {
        Modal.error({
            title: 'ไม่สำเร็จ',
            content: 'กรุณาลองใหม่อีกครั้ง',
        })
    }

    const showConfirm = (value) => {
        confirm({
            title: 'คุณต้องการที่จะลบข้อมูลหรือไม่...?',
            content: 'กด OK เพื่อยืนยันการลบ',
            onOk() {
                handleDelete(value)
            },
            onCancel() {
                message.error('ยกเลิกการลบข้อมูล');
            },
        });
    };

    const loadData = async (e) => {
        setLoading(true)
        const headers = {
            "Authorization": `Bearer ${tk}`,
            "Menu": JSON.stringify("16")
        }
        await axios.get(table, { headers: headers })
            .then((res) => {
                setUserData(res.data)
                setArrTable(res.data)
                setLoading(false)
            })
            .catch((err) => errors())
        setLoading(false)
    }
    const searchData = (e) => {
        const arr = userData.filter((item) =>
            item.username.toLowerCase().includes(e.target.value) ||
            item.roleName.toLowerCase().includes(e.target.value)
        )
        setArrTable(arr)
    }
    const handleEdit = (value) => {
        //console.log("value", value)
        setEditData(value)
        setIsModalOpen(true)
    }
    const handleReset = (value) => {
        console.log("value", value)
        setResetData(value)
        setIsModalReset(true)
    }
    const cancel = (e) => {
        //console.log(e);
    };
    ///////////////////////////////////////////////////////////////////////////
    function removeObj(id) {
        return userData.filter((obj) => obj.id !== id)
    }
    const handleDelete = async (value) => {
        //console.log("value", value)
        setLoading(true)
        const headers = {
            "Authorization": `Bearer ${tk}`,
            "Menu": JSON.stringify("16")
        }
        const test = value
        const newValue = removeObj(value.id)
        await axios.delete(deleteuser, { headers: headers, data: test, })
            .then((res) => {
                if (res.status === 200) {
                    setUserData(newValue)
                    setArrTable(newValue)
                    message.success('ลบข้อมูลแล้ว');
                    setLoading(false)
                }
            })
            .catch((err) => errors())
        setLoading(false)
    }
    //////////////////////////////////////////////////////////////////////////
    const columns = [
        {
            title: "ลำดับ",
            dataIndex: "index",
            key: 'index',
            with: 60,
            render: (text, object, index) => index + 1
        },
        {
            title: 'ชื่อ',
            dataIndex: 'username',
        },
        {
            title: 'ชื่อสิทธิ์',
            dataIndex: 'roleName',
        },
        {
            title: 'ResetPassword',
            render: (text, record) => (
                <><Button onClick={() => { handleReset(record) }}>reset</Button></>
            )
        },
        {
            title: 'Edit & Delete',
            //key: 'edit',
            render: (_, record) => (
                <Space size="middle">
                    <Button onClick={() => { handleEdit(record) }}>Edit</Button>
                    {/* // <Link to={'/Edit/' + record.customerId }>
            //   Edit
            // </Link>
             */}
                    {/* <Popconfirm
                        title="Warning !!!"
                        description="Are you sure to delete !!!"
                        onConfirm={() => handleDelete(record)}
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                    > */}
                    <Button onClick={() => { showConfirm(record) }}>Delete</Button>
                    {/* </Popconfirm> */}
                </Space>
            ),
        },
    ];

    return (
        <>
            <Card
                bordered={false}
            >
                <Spin spinning={loading} size='large' tip=" Loading... ">
                    <Row justify={'center'}>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <b style={{ color: '#002766', fontSize: '30px' }}>
                                <u>ตารางแก้ไขสิทธิ์</u>
                            </b>
                        </Col>
                    </Row>
                    <Row justify={'center'}>
                        <Row justify={'center'}>
                            <Form.Item style={{ width: 500 }}>
                                <b style={{ display: 'flex', textAlign: 'center', color: '#002766', fontSize: '18px' }}>
                                    <span style={{ width: '150px', lineHeight: '38px' }}>
                                        ชื่อผู้ใช้งาน:
                                    </span>
                                    <Input.Search placeholder="ค้นหา....." onChange={searchData} />
                                </b>
                            </Form.Item>
                        </Row>
                    </Row>
                    <Row justify={'center'}>
                        <Col span={24}>
                            <Table style={{ padding: 20 }} dataSource={arrTable} columns={columns} onChange={handleChange} />
                        </Col>

                    </Row>
                    {
                        isModalOpen ?
                            <EditRoleUser open={isModalOpen} close={setIsModalOpen} oData={editData} />
                            : null
                    }

                    {
                        isModalReset ?
                            <ModalResetPass open={isModalReset} close={setIsModalReset} rData={resetData} />
                            : null
                    }
                </Spin>
            </Card>
        </>
    )
}
