import React, { useState, useEffect } from "react";
import { UpOutlined, DownOutlined, EditOutlined, StopOutlined } from '@ant-design/icons';
import { Button, Col, Select, Row, DatePicker, Space, Card, notification, Statistic, Spin, Divider, Typography, Form } from 'antd';
import dayjs from "dayjs";
import axios from "axios";

import DetailAD from "./modals/DetailAD";
const { Text } = Typography;
const { Countdown } = Statistic;
const { Option } = Select;
export default function Main() {
  const token = localStorage.getItem('token')
  const branch = localStorage.getItem('branch');
  //const dispatch = useDispatch()
  //const user = localStorage.getItem('username');
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [arrayTable, setArrayTable] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false)
  const [openDetail, setOpenDetail] = useState(false)
  const [dataDetail, setDataDetail] = useState()
  const [checkEdit, setCheckEdit] = useState(false)
  const [selectTeam, setSelectTeam] = useState()
  const [dataFile, setDataFile] = useState([]);
  const [sendBack, setSendBack] = useState()
  // const { RangePicker } = DatePicker;
  // const [dDay, setDDay] = useState()

  useEffect(() => {
    loadData();
  }, [openDetail, sendBack])


  // const onChange = (e1, e2) => {
  //   console.log('e1: ', e1)
  //   console.log('e2: ', e2)
  //   setDDay(e2)
  // };

  // console.log('dDay: ', dDay)

  const apiPg = "https://pg-api-nrbozk5hnq-as.a.run.app"

  const loadData = async () => {
    setLoading(true)
    const tk = JSON.parse(token)
    const headers = {
      "Authorization": `Bearer ${tk}`,
      "Menu": JSON.stringify("5")
    }
    await axios.get(`${apiPg}/adGroup/findAD`, { headers: headers })
      .then(async (res) => {
        if (res.data !== null) {
          console.log("res.data", res.data)
          setArrayTable(res.data)
        } else {
          setArrayTable([])
        }
        setLoading(false)
      }).catch((err) => console.log(err))
    setLoading(false)
  }

  // const convertPdfToImages = async (file) => {
  //   const images = [];
  //   const data = await readFileData(file);
  //   const pdf = await PDFJS.getDocument(data).promise;
  //   const canvas = document.createElement("canvas");
  //   for (let i = 0; i < pdf.numPages; i++) {
  //     const page = await pdf.getPage(i + 1);
  //     const viewport = page.getViewport({ scale: 1 });
  //     const context = canvas.getContext("2d");
  //     canvas.height = viewport.height;
  //     canvas.width = viewport.width;
  //     await page.render({ canvasContext: context, viewport: viewport }).promise;
  //     images.append(canvas.toDataURL());
  //   }
  //   canvas.remove();
  //   return images;
  // }

  const currencyFormatOne = (amount) => {
    return Number(amount)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
  }

  const handleEditDetail = async (value, index) => {
    console.log(value, index)
    setOpenDetail(true)
    setDataDetail(value)
  }

  const handleEdit = async () => {

  }

  const handleOpenEdit = () => {
    setCheckEdit(true)
  }
  const handleCloseEdit = () => {
    setSendBack(dayjs())
    handleProduct("A")
    form.setFieldsValue({ teams: "A", })
    setCheckEdit(false)
  }
  const handleSwap = (index1, index2) => {
    // console.log("index1",index1)
    // console.log("index2",index2)
    const newData = [...dataFile];
    [newData[index1], newData[index2]] = [newData[index2], newData[index1]];
    console.log("newData", newData)
    setDataFile(newData);
  };

  const handleProduct = (value) => {
    setSelectTeam(value)
    let filteredData;
    if (value === "A") {
      filteredData = arrayTable.filter(
        (item) =>
          item.team === "A",
      );
    } else if (value === "B") {
      filteredData = arrayTable.filter(
        (item) =>
          item.team === "B",
      );
    } else if (value === "C") {
      filteredData = arrayTable.filter(
        (item) =>
          item.team === "C",
      );
    } else {
      filteredData = arrayTable.filter(
        (item) =>
          item.team === "",
      );
    }
    setDataFile(filteredData)
    console.log("filteredData", filteredData)
  }

  const showAD = ({ item, index }) => {
    // console.log("item", item)
    // const handleEdit = (dataedit, index1) => {
    //   //console.log("GG", dataedit, index1)
    //   setIndex(index1) //เซตค่า index ให้กับชุด ข้อมูล
    //   setDataEdit({ dataedit, index1 }) //ส่งข้อมูลด index ไปหน้าแก้ไข
    //   setIsModalsEditGua(true);
    // }
    return (
      <Form
        name="basic"
        labelCol={{
          span: 12,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          remember: true,
        }}
        form={form1}
        // onFinish={handleSubmit}
        autoComplete="off"
      >
        <Card style={{ width: '100%' }}>
          <Row justify={'center'}>
            <Col span={18}>
              <Row justify={'center'}><b><u>ลำดับการรับงาน {item?.num}</u></b></Row>
              <Row>
                <Col span={12} >
                  <Form.Item style={{ margin: 3 }} label='ทีม' >
                    <b>{item?.team}</b>
                  </Form.Item>
                  <Form.Item style={{ margin: 3 }} label='ชื่อเล่น' >
                    <b>{item?.nickname}</b>
                  </Form.Item>
                  <Form.Item style={{ margin: 3 }} label='รหัส' >
                    <b>{item?.username}</b>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item style={{ margin: 3 }} label='ยอดที่รับได้' >
                    <b>{currencyFormatOne(item?.minBalance)} - {currencyFormatOne(item?.maxBalance)}</b>
                  </Form.Item>
                </Col>
              </Row>

              <Divider style={{ margin: 5 }} />
              <Row justify={'center'}>
                <Col span={24} style={{ textAlign: 'center' }}>
                  <Space>
                    <Button type="text" style={{ background: "#e0e0e0" }}
                      // disabled={checkEditGarno}
                      onClick={() => { handleEditDetail(dataFile[index], index); }}
                    >
                      แก้ไข
                    </Button>
                  </Space>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Form>
    )
  }

  const showADEdit = ({ item, index }) => {
    // console.log("item", item)
    return (
      <Form
        name="basic"
        labelCol={{
          span: 12,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          remember: true,
        }}
        form={form1}
        // onFinish={handleSubmit}
        autoComplete="off"
      >
        <Card style={{ width: '100%' }}>
          <Row justify={'center'}>
            <Col span={18}>
              <Row justify={'center'}><b><u>ลำดับการรับงาน {item?.num}</u></b></Row>
              <Row>
                <Col span={12} >
                  <Form.Item style={{ margin: 3 }} label='ทีม' >
                    <b>{item?.team}</b>
                  </Form.Item>
                  <Form.Item style={{ margin: 3 }} label='ชื่อเล่น' >
                    <b>{item?.nickname}</b>
                  </Form.Item>
                  <Form.Item style={{ margin: 3 }} label='รหัส' >
                    <b>{item?.username}</b>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item style={{ margin: 3 }} label='ยอดที่รับได้' >
                    <b>{currencyFormatOne(item?.minBalance)} - {currencyFormatOne(item?.maxBalance)}</b>
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Divider style={{ margin: 5 }} />
            <Col span={6} style={{ textAlign: 'right' }}>
              {
                checkEdit === true ?
                  <>
                    <Row justify={'center'}>
                      <Button icon={<UpOutlined />} onClick={() => handleSwap(index, index - 1)} disabled={index === 0}>
                        เลื่อนขึ้น
                      </Button>
                    </Row>
                    <Row justify={'center'}>
                      <Button icon={<DownOutlined />} onClick={() => handleSwap(index, index + 1)} disabled={index === dataFile.length - 1}>
                        เลื่อนลง
                      </Button>
                    </Row>
                  </>
                  : null
              }
            </Col>
          </Row>
        </Card>
      </Form>
    )
  }

  return (
    <>
      <Card
        bordered={false}
        style={{
          width: 'auto',
        }}
      >
        <Spin spinning={loading} size='large' tip=" Loading... ">
          <Form
            labelCol={{
              span: 12,
            }}
            wrapperCol={{
              span: 24,
            }}
            // style={{
            //   width: '100%',
            // }}
            form={form}
            // onFinish={handleSubmit}
            autoComplete="off"
            initialValues={{ remember: true }}
          >
            {/* <>
              <RangePicker format="YYYY-MM-DD" onChange={(e1,e2) => { onChange(e1,e2) }} />
            </> */}


            <Row justify={'center'}>
              <b>
                <Form.Item label='เลือกทีม' name='teams'
                  style={{ textAlign: 'center', width: '300px' }}
                >
                  <Select placeholder={'เลือก'} disabled={checkEdit}
                    style={{ width: '250px' }}
                    onChange={(value) => handleProduct(value)}
                  >
                    <Option value={"A"}>A</Option>
                    <Option value={"B"}>B</Option>
                    <Option value={"C"}>C</Option>
                    <Option value={""}>ยังไม่มีทีม</Option>
                  </Select>
                </Form.Item>
              </b>
            </Row>
            <Divider style={{ margin: 5 }} />
            {
              checkEdit === false ?
                <>
                  {dataFile?.length > 0 ?
                    <>
                      <Card>
                        <Row justify={'left'}><b><h3><u>ทีม: {selectTeam}</u></h3></b></Row>
                        <Col span={24}>
                          {dataFile?.map((item, index) => {
                            return showAD({ item, index, key: `{item.id} - ${index}` });
                          })}
                        </Col>
                        <Divider style={{ margin: 5 }} />
                        {
                          checkEdit === false ?
                            <>
                              <Row>
                                <Col span={24} style={{ textAlign: 'right' }}>
                                  <Button type='primary' style={{ backgroundColor: "#faad14", color: "black" }}
                                    onClick={handleOpenEdit} icon={<EditOutlined />}>แก้ลำดับ</Button>
                                </Col>
                              </Row>
                            </>
                            : null
                        }
                      </Card>
                      <Divider style={{ margin: 5 }} />
                    </>
                    : null
                  }
                </>
                :
                <>
                  {dataFile?.length > 0 ?
                    <>
                      <Card>
                        <Row justify={'left'}><b><h3><u>ทีม: A</u></h3></b></Row>
                        <Col span={24}>
                          {dataFile?.map((item, index) => {
                            return showADEdit({ item, index, key: `{item.id} - ${index}` });
                          })}
                        </Col>
                        <Divider style={{ margin: 5 }} />
                        {
                          checkEdit === true ?
                            <>
                              <Row>
                                <Col span={24} style={{ textAlign: 'right' }}>
                                  <Space>
                                    <Button type='primary' style={{ backgroundColor: "green" }} onClick={() => handleEdit(true)}>บันทึกลำดับ</Button>
                                    <Button type='primary' danger onClick={handleCloseEdit} icon={<StopOutlined />}>ยกเลิกแก้ลำดับ</Button>
                                  </Space>
                                </Col>
                              </Row>
                            </>
                            : null
                        }

                      </Card>
                      <Divider style={{ margin: 5 }} />
                    </>
                    :
                    null
                  }
                </>
            }
          </Form>
        </Spin>
      </Card >
      {
        openDetail ?
          <DetailAD
            open={openDetail}
            close={setOpenDetail}
            dataFromTable={dataDetail}
          />
          : null
      }
      {contextHolder}
    </>
  )
}
