import { createSlice } from "@reduxjs/toolkit";


const initialState = {

}

const careerIncomeDataSlice = createSlice({
    name: "guarantor",
    initialState,
    reducers: {

        addCareerIncome: (state, action) => {
            state.data = action.payload
        },

    }

})

export const { addCareerIncome } = careerIncomeDataSlice.actions
export default careerIncomeDataSlice.reducer