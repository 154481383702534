import React, { useEffect, useState } from 'react'
import { Button, Input, Form, DatePicker, Card, Col, Row, message, Select, Radio, Spin, Space, Divider } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { addGuarantor } from '../../../../redux/Guarantor';
import { checkCCAP, testupload } from '../../../file_mid/all_api';

export default function GCustomer({ close, n1, n2, fucnAdd, next, count1, setCount1, chg, cusIdMain, sendcarId }) {

    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const { customers } = useSelector((state) => ({ ...state }))
    const [dataPost, setDataPost] = useState({ customerId2: 0 })


    const [dataCusid] = useState(customers.data.customerId)

    // { customerId: customers.data }
    /////////////////////////////////////////////////////////////////////////////////////
    const [dataAddress, setDataAddress] = useState([]) // address
    const [dataPhones, setDataPhone] = useState([]) // phone

    const [count] = useState()
    /////////////////////////////////////////////////////////////////////////////////////////
    const [fileList, setFileList] = useState() // รูปบัตรประชาชน
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (customers.data.customerId !== undefined) {
            // setDataPost(customers.data)
            // form.setFieldsValue(
            //     {
            //         ...customers.data,
            //         birthdate: dayjs(customers.data?.birthdate),
            //         issudt: dayjs(customers.data?.issudt),
            //         expdt: dayjs(customers.data?.expdt),
            //     }
            // )
        }
    }, [])

    const handleSubmit = async (e) => {
        // delete dataPost.address
        // delete dataPost.carLoanDetails
        // delete dataPost.phones
        console.log('cusIdMain', cusIdMain);
        await axios.post('https://garfe-i67f6gaaqa-as.a.run.app/api/checkguerror', { ...dataPost, customerId: dataCusid })
            .then(res => {
                if (res.data === 'duplicate information') {
                    chg('top')
                } else if (res.data === 'OK') {
                    console.log("dataPost", dataPost)
                    dispatch(addGuarantor({ ...dataPost, customerId: dataCusid, dataPhones: [], dataAddress: [] }))
                    onChangeGo()
                }
            }).catch((err) => {
                console.log("err", err)
            })
    }

    const onChangeGo = () => {
        setCount1(count1 + 1)
        next()
    }

    const handleCancel = () => {
        close(false)
    }
    ////////////// ข้อมูลยูสในเดต้าเบส ////////////////////////////////////////////////////////////////
    const loadDataOne = async (data) => {
        //console.log("DATA", data)
        const one = { identificationId: data.identificationId }
        setLoading(true)
        await axios.post(checkCCAP, one)
            .then((res) => {
                //console.log("RES-DATA", res.data)
                if (res.data) {
                    message.info('มีข้อมูลในฐานข้อมูล')
                    if (window.confirm('คุณต้องการใช้ข้อมูลในฐานข้อมูลหรือไม่ ?')) {
                        // Save it!
                        // console.log('ok')
                        setDataPost({ ...res.data, customerId: customers.data, customerId2: res.data.customerId })
                        setDataPhone(res.data.phones)
                        setDataAddress(res.data.address)
                        // console.log("อ่านบัตร",res.data.address)
                        // console.log("อ่านบัตร",res.data.phones)
                        if (res.data.expdt == null || res.data.issudt == null) {
                            form.setFieldsValue(
                                {
                                    ...res.data,
                                    telp: res?.data?.phones[0]?.telp,
                                    birthdate: dayjs(res?.data?.birthdate),
                                    issudt: '',
                                    expdt: '',
                                }
                            )
                            setLoading(false)
                        }
                        else {
                            form.setFieldsValue(
                                {
                                    ...res.data,
                                    telp: res?.data?.phones[0]?.telp,
                                    birthdate: dayjs(res?.data?.birthdate),
                                    issudt: dayjs(res?.data?.issudt),
                                    expdt: dayjs(res?.data?.expdt),
                                }
                            )
                        }
                    } else {
                        // Do nothing!
                        // console.log('no');
                        setDataPost({ ...dataPost, ...data, customerId: customers.data, customerId2: res.data.customerId })
                        if (data.expdt == null || data.issudt == null) {
                            form.setFieldsValue(
                                {
                                    ...data,
                                    nickname: '',
                                    gender: '',
                                    birthdate: dayjs(data?.birthdate),
                                    issudt: '',
                                    expdt: '',
                                    telp: '',
                                }
                            )
                            setLoading(false)
                        }
                        else {
                            form.setFieldsValue(
                                {
                                    ...data,
                                    nickname: '',
                                    gender: '',
                                    birthdate: dayjs(data?.birthdate),
                                    issudt: dayjs(data?.issudt),
                                    expdt: dayjs(data?.expdt),
                                    telp: '',
                                }
                            )
                        }
                    }
                    setLoading(false)
                } else {
                    //alert('ไม่พบข้อมูลในฐานข้อมูล')
                    message.info('ไม่พบข้อมูลในฐานข้อมูล')
                    // console.log('data', data)
                    setDataPost({ ...data, customerId: customers.data, customerId2: 0 })
                    //setDataAddress({ ...dataAddress, customerId: 0, addressId: 0 })
                    setDataPhone({})
                    if (data.expdt === undefined || data.issudt === undefined) {
                        // console.log("if")
                        form.setFieldsValue(
                            {
                                ...data,
                                nickname: '',
                                gender: '',
                                birthdate: dayjs(data?.birthdate),
                                issudt: '',
                                expdt: '',
                                telp: '',
                            }
                        )
                        setLoading(false)
                    } else {
                        // console.log("else")
                        form.setFieldsValue(
                            {
                                ...data,
                                nickname: '',
                                gender: '',
                                birthdate: dayjs(data?.birthdate),
                                issudt: '',
                                expdt: '',
                                telp: '',
                            }
                        )
                    }
                    setLoading(false)
                }
            })
            .catch((err) => console.log(err))
        setLoading(false)
    }
    /////////// ดึงข้อมูลจากรูปบัตรประชาชน /////////////////////////////////////////////////////////////////  
    const handleScanIdCard = async (value) => {
        const imgId = { image: value }
        setLoading(true)
        const headers = { 'Content-Type': 'application/json' }
        await axios.post(testupload, imgId, { headers: headers })
            .then(res => {
                // console.log("img", res.data)
                if (res.data) {
                    // setDataPost({ ...res.data[0], customerId: customers.data, customerId2: res.data.customerId })
                    // setDataPost(res.data[0])
                    splitAddress(res.data[0])
                    loadDataOne(res.data[0])
                    setLoading(false)
                    // form.setFieldsValue(
                    //     {
                    //         ...res.data[0],
                    //         nickname: '',
                    //         gender: '',
                    //         birthdate: dayjs(res.data[0]?.birthdate),
                    //         issudt: dayjs(res.data[0]?.issudt),
                    //         expdt: dayjs(res.data[0]?.expdt),
                    //         telp: '',
                    //     }
                    // )
                }
                else {
                    message.info('ไม่สามารถอ่านได้')
                    setDataPost({})
                    setDataAddress({})
                    setDataPhone({})
                    setLoading(false)
                }
            })
            .catch((err) => console.log(err))
        setLoading(false)
    }

    /////////// กดตรวจบัตรประชาชน ///////////////////////////////////////////////////////////////////////
    const handleIdCard = async () => {
        const sumOne = { identificationId: dataPost.identificationId }
        if (dataPost.identificationId !== undefined && dataPost.identificationId.trim() !== "") {
            setLoading(true)
            await axios.post(checkCCAP, sumOne)
                .then((res) => {
                    // console.log("ตรวจบัตร", res.data)
                    if (res.data) {
                        message.info('มีข้อมูล')
                        setDataPost({ ...res.data, customerId: customers.data, customerId2: res.data.customerId })
                        if (res.data.address) {
                            setDataAddress(res.data.address)
                            // console.log("กดตรวจ", res.data.address)
                        }
                        if (res.data.phones) {
                            setDataPhone(res.data.phones)
                            // console.log("กดตรวจ", res.data.phones)
                        }
                        form.setFieldsValue(
                            {
                                ...res.data,
                                telp: res?.data?.phones[0]?.telp,
                                birthdate: dayjs(res?.data?.birthdate),
                                issudt: dayjs(res?.data?.issudt),
                                expdt: dayjs(res?.data?.expdt),
                            }
                        )
                        setLoading(false)
                    }
                    else {
                        message.info('ไม่พบข้อมูล')
                        setDataPost({
                            ...dataPost,
                            customerId: null,
                            snam: '',
                            firstname: '',
                            lastname: '',
                            nickname: '',
                            nationalId: '',
                            birthdate: '',
                            gender: '',
                            issuby: '',
                            issudt: '',
                            expdt: ''
                        })
                        setDataAddress({})
                        setDataPhone({})
                        form.setFieldsValue(
                            {
                                snam: '',
                                firstname: '',
                                lastname: '',
                                nickname: '',
                                nationalId: '',
                                birthdate: '',
                                gender: '',
                                issuby: '',
                                issudt: '',
                                expdt: '',
                                telp: '',
                            }
                        )
                        setLoading(false)
                    }
                })
                .catch((err) => console.log(err))
            setLoading(false)
        }
        else {
            message.info('กรุณากรอกเลขบัตรประชาชน')
            setDataPost({
                ...dataPost,
                customerId: null,
                snam: '',
                firstname: '',
                lastname: '',
                nickname: '',
                national_id: '',
                birthdate: '',
                gender: '',
                issuby: '',
                issudt: '',
                expdt: ''
            })
            setDataAddress({})
            setDataPhone({})
            form.setFieldsValue(
                {
                    snam: '',
                    firstname: '',
                    lastname: '',
                    nickname: '',
                    nationalId: '',
                    birthdate: '',
                    gender: '',
                    issuby: '',
                    issudt: '',
                    expdt: '',
                    telp: '',
                }
            )
        }
    }
    function isThaiNationalID(id) {
        if (!/^[0-9]{13}$/g.test(id)) {
            return false
        }
        let i; let sum = 0
        for ((i = 0), (sum = 0); i < 12; i++) {
            sum += Number.parseInt(id.charAt(i)) * (13 - i)

        }
        const checkSum = (11 - sum % 11) % 10
        if (checkSum === Number.parseInt(id.charAt(12))) {
            //console.log(id, 'เลขบัตรถูกต้อง')
            return true, message.info('เลขบัตรถูกต้อง')
        }
        //console.log("เลขบัตรไม่ถูกต้อง", id)
        return false, message.info('เลขบัตรไม่ถูกต้อง')

    }
    ////
    const inputIdCard = (e) => {
        setDataPost({ ...dataPost, identificationId: e.target.value })
        isThaiNationalID(e.target.value)
    }
    ////////////// รับไฟล์รูปบัตร /////////////////////////////////
    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg']
        if (file) {
            if (validImageTypes.includes(file.type)) {
                const reader = new FileReader(); // สร้าง FileReader object
                reader.onloadend = () => { // ฟังก์ชันที่ทำงานเมื่ออ่านไฟล์เสร็จสิ้น    
                    const base64Data = reader.result; // รับข้อมูล Base64 จาก FileReader result
                    //console.log('Base64 Data:', base64Data);
                    setFileList({ image: base64Data })
                    handleScanIdCard(base64Data)
                }
                reader.readAsDataURL(file) // อ่านไฟล์และแปลงเป็น Base64    
            }
        }
    }

    //////////////// เก็บแอสเดรส ////////////////////////////////////
    const splitAddress = (value) => {
        let updatedCount = { ...count }
        //console.log("โหลด",value.address)
        let part = value.address.split(" ")
        if (part[1].includes("หมู่ที่") === true) {
            //setHouseNumber(part[0] + part[1] + part[2])
            updatedCount.houseNumber = part[0] + part[1] + part[2]
        } else {
            //setHouseNumber(part[0])
            updatedCount.houseNumber = part[0]
        }
        //setMoo(part[2])
        //setDataAddress({...dataAddress, moo: part[2]})
        for (let i = 0; i <= part.length; i++) {
            if (part[i]?.includes("ซ.") === true) {
                let s = part[i].split("ซ.")
                //setSoi(s[1])
                updatedCount.soi = s[1]
            }
            if (part[i]?.includes("ถ.") === true) {
                let tanon = part[i].split("ถ.")
                //setRoad(tanon[1])
                updatedCount.road = tanon[1]
            }
            if (part[i]?.includes("ต.") === true) {
                let bn = part[i].split("ต.")
                //setSubdistrict(bn[1])
                updatedCount.subdistrict = bn[1]
            }
            if (part[i]?.includes("อ.") === true) {
                let oo = part[i].split("อ.")
                let oop = oo[1].split(",")
                if (oo[1]?.includes(",") === false) {
                    //setDistrict(oo[1])
                    updatedCount.district = oo[1]
                }
                if (oop[1]?.includes("จ.") === true) {
                    //setDistrict(oop[0])
                    updatedCount.district = oop[0]
                    let oops = oop[1].split("จ.")
                    //setProvince(oops[1])
                    updatedCount.province = oops[1]
                }
            }
            if (part[i]?.includes("จ.") === true) {
                let jh = part[i].split("จ.")
                //setProvince(jh[1])
                updatedCount.province = jh[1]
            }
        }
        setDataAddress({
            ...dataAddress,
            customerId: 0,
            addressId: 0,
            houseNumber: updatedCount.houseNumber,
            houseRegistrationNumber: updatedCount.houseRegistrationNumber,
            village: updatedCount.village,
            zipCode: updatedCount.zipCode,
            soi: updatedCount.soi,
            road: updatedCount.road,
            subdistrict: updatedCount.subdistrict,
            district: updatedCount.district,
            province: updatedCount.province,
            addressStatus: true,
            addressOnIdcard: true,
            workAddress: false,
            otherAddress: false,
            currentAddress: false,
            sendDocuments: false,
            houseRegistration: false,
        })
    }
    return (
        <Card >
            <div>
                <div>
                    <Row>
                        <Col span={24} style={{ fontSize: '30px', textAlign: 'center' }} >ข้อมูลส่วนตัว</Col>
                    </Row>
                </div>
                <Divider></Divider>
                <Spin spinning={loading} size='large' tip=" Loading... ">
                    <Form
                        labelCol={{
                            span: 12,
                        }}
                        wrapperCol={{
                            span: 12,
                        }}
                        form={form}
                        onFinish={handleSubmit}
                        autoComplete="off">
                        <Row>
                            <Col span={10}>
                                <Form.Item label='เลขประจำตัวประชาชน' name='identificationId'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <Input type='text' maxLength={13}
                                        onChange={(e) => inputIdCard(e)}></Input>
                                </Form.Item>
                                <Form.Item label='รูปบัตรประชาชน'>
                                    <Input type="file" multiple={true}
                                        onChange={handleFileInputChange} >
                                    </Input>
                                </Form.Item>
                                <Form.Item label="คำนำหน้าชื่อ" name="snam"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <Radio.Group onChange={(e) => setDataPost({ ...dataPost, snam: e.target.value })} >
                                        <Radio value="นาย"> นาย </Radio>
                                        <Radio value="นาง"> นาง </Radio>
                                        <Radio value="นางสาว"> นางสาว </Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item label='ชื่อ' name='firstname'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}
                                >
                                    <Input type='text'
                                        onChange={(e) => setDataPost({ ...dataPost, firstname: e.target.value })}></Input>
                                </Form.Item>
                                <Form.Item label='นามสกุล' name='lastname'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <Input type='text'
                                        onChange={(e) => setDataPost({ ...dataPost, lastname: e.target.value })}></Input>
                                </Form.Item>

                                <Form.Item label='ชื่อเล่น' name='nickname'>
                                    <Input type='text'
                                        onChange={(e) => setDataPost({ ...dataPost, nickname: e.target.value })}></Input>
                                </Form.Item>
                            </Col>
                            <Col span={2}>
                                <Form.Item>
                                    <Button type="primary" onClick={(e) => { handleIdCard(e) }}>ตรวจสอบ</Button>
                                </Form.Item>
                            </Col>
                            <Col span={10}>
                                {/* <Form.Item label='ประเทศ' name='nationalId'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <Input type='text'
                                        onChange={(e) => setDataPost({ ...dataPost, nationalId: e.target.value })}></Input>
                                </Form.Item> */}
                                <Form.Item label='เกิดวันที่' name='birthdate'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <DatePicker format={'YYYY-MM-DD'} style={{ width: '100%' }}
                                        onChange={(e) => {
                                            if (e) {
                                                setDataPost({ ...dataPost, birthdate: e.format('YYYY-MM-DD') })
                                            }
                                        }} />
                                </Form.Item>
                                <Form.Item label='เพศ' name='gender'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <Select
                                        placeholder={'เลือก'}
                                        style={{
                                            width: '100%',
                                        }}
                                        onChange={(value) => setDataPost({ ...dataPost, gender: value })}
                                        options={[
                                            {
                                                label: 'ชาย',
                                                value: 'M',
                                            },
                                            {
                                                label: 'หญิง',
                                                value: 'F',
                                            },
                                        ]} />
                                </Form.Item>
                                <Form.Item label='สถานที่ออกบัตรประชาชน' name='issuby'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <Input type='text'
                                        onChange={(e) => setDataPost({ ...dataPost, issuby: e.target.value })}></Input>
                                </Form.Item>
                                <Form.Item label='วันออกบัตร' name='issudt'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <DatePicker format={'YYYY-MM-DD'} style={{ width: '100%' }}
                                        onChange={(e) => {
                                            if (e) {
                                                setDataPost({ ...dataPost, issudt: e.format('YYYY-MM-DD') })
                                            }
                                        }} />
                                </Form.Item>
                                <Form.Item label='วันบัตรหมดอายุ' name='expdt'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <DatePicker format={'YYYY-MM-DD'} style={{ width: '100%' }}
                                        onChange={(e) => {
                                            if (e) {
                                                setDataPost({ ...dataPost, expdt: e.format('YYYY-MM-DD') })
                                            }
                                        }} />
                                </Form.Item>
                                <Form.Item label='ความสัมพันกับผู้กู้' name='description'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <Input type='text'
                                        onChange={(e) => setDataPost({ ...dataPost, description: e.target.value })}></Input>
                                </Form.Item>
                                <Form.Item label='สาขา' name='locat'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <Input type='text'
                                        onChange={(e) => setDataPost({ ...dataPost, locat: e.target.value })}></Input>
                                </Form.Item>
                            </Col>
                        </Row>
                        <div style={{ textAlign: "center" }}>
                            <Space>
                                <Button type="primary" style={{ background: "red" }} onClick={handleCancel}>ยกเลิก</Button>
                                <Button type="primary" htmlType="submit" >ต่อไป</Button>
                            </Space>
                        </div>
                    </Form>
                </Spin>
            </div>
        </Card>
    )
};
