import React, { useState, useEffect } from 'react'
import { Button, Form, Input, Select, Divider, Row, Col, Modal, Checkbox } from 'antd';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { getocc, getsection, getsub, occupationIncom } from '../../file_mid/all_api';

function ModalAddCareerIncome({ open, close, fucnAdd, n1 }) {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [occupation, setOccupation] = useState([]) // getdatabase occ
    const [sub, setSub] = useState([]) // getdatabase sub
    const [section, setSection] = useState([]) // getdatabase section
    const [dataPost, setDataPost] = useState({ mainOccupation: true }) //เก็บอาชีพ
    const [money, setMoney] = useState({}) //เก็บรายได้
    const [expenses, setExpenses] = useState({}) //เก็บรายจ่าย

    const { customers } = useSelector((state) => ({ ...state }))
    const [address, setAddress] = useState({
    });

    useEffect(() => {
        if (customers.data.customerId === undefined) {
            setAddress({
            })
        }

        loadOccupation()
    }, [])

    const success = () => {
        Modal.success({
            content: 'บันทึกสำเร็จ',
        })
    }
    const error = () => {
        Modal.error({
            title: 'บันทึกไม่สำเร็จ',
            content: 'กรุณาตรวจสอบข้อมูลอีกครั้ง',
        })
    }

    //checkBox status ของที่อยู่
    const handleCancel = () => {
        close(false);
    };

    //ปุ่ม Submit in Modal Add Data Address
    const handleSubmit = async () => {
        const req = { customer: customers.data, ojs: dataPost, income: money, expenses: expenses }
        console.log("รวม ", req)
        setLoading(true)
        await axios.post(occupationIncom, req)
            .then(res => {
                if (res.status === 200) {
                    fucnAdd({ req })
                    success()
                    close(false);
                }
            })
            .catch((err) => {
                if (err.response.request.status === 400) {
                    error()
                }
                else {
                    error()
                }
                setLoading(false)
            })
        setLoading(false)
        // n1('top')
        //fucnAdd({ req })
        //close(false);
    }
    // data input Form 
    const handleInputDataAddress = (e) => {
        const { name, value } = e.target;
        setAddress({
            ...address, [name]: value,
        }) // เป็นการกระจายข้อมูลเข้าไปตามชื่อ  name
    }
    // Data Select Form
    const loadOccupation = async () => {
        setLoading(true)
        await axios.get(getocc)
            .then((res) => {
                setOccupation(res.data)
                setLoading(false)
            })
            .catch((err) => console.log(err))
        setLoading(false)
    }

    const loadSubOccupation = async (value) => {
        //console.log("sub",value)
        const idOccupation = { idOccupation: value }
        setLoading(true)
        await axios.post(getsub, idOccupation)
            .then((res) => {
                setSub(res.data)
                setLoading(false)
                form.setFieldsValue(
                    {
                        governmentPrivate: "",
                        jobPosition: "",
                        companyName: "",
                    }
                )
            })
            .catch((err) => console.log(err))
        setLoading(false)
    }

    const loadOccupationSection = async (value) => {
        //console.log("section",value)
        const idSub = { idSub: value }
        setLoading(true)
        await axios.post(getsection, idSub)
            .then((res) => {
                setSection(res.data)
                setLoading(false)
                form.setFieldsValue(
                    {
                        jobPosition: "",
                        companyName: "",
                    }
                )
            })
            .catch((err) => console.log(err))
        setLoading(false)
    }

    const getOccupation = (value) => {
        //console.log("value", {occupation: occupation.find((e) => e.idOccupation === value).desc})
        setDataPost({ ...dataPost, occupationalGroup: occupation.find((e) => e.idOccupation === value).desc })
        loadSubOccupation(value)
    }

    const getSub = (value) => {
        //console.log("value", value)
        setDataPost({ ...dataPost, governmentPrivate: sub.find((e) => e.idSub === value).descSub })
        loadOccupationSection(value)
    }

    const getSection = (value) => {
        //console.log("value", value)
        setDataPost({ ...dataPost, jobPosition: section.find((e) => e.idSection === value).descSection })
    }
    return (
        <>
            {/* Modal เพิ่มข้อมูล */}
            <Modal
                open={open}
                centered
                onCancel={handleCancel}
                width={900}
                footer={[null]}
            >
                <Form
                    form={form}
                    name='add'
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    style={{
                        maxWidth: 900,
                    }}
                    autoComplete="off"
                    initialValues={{ remember: true }}
                >
                    <Row gutter={32}>
                        <Col className='gutter-row' span={24} style={{ fontSize: '30px', textAlign: 'center' }} ><u>เพิ่มอาชีพ</u></Col>
                    </Row>
                    <Divider />
                    <Row gutter={32}>
                        <Col className="gutter-row" span={12}>
                            <Form.Item label='กลุ่มอาชีพ' name='occupationalGroup'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input !',
                                    },]}>
                                <Select
                                    placeholder={'เลือก'}
                                    onChange={getOccupation}
                                >
                                    {occupation?.map((item) => {
                                        return (
                                            <Select.Option key={item.idOccupation} value={item.idOccupation}>
                                                {item.desc}
                                            </Select.Option>
                                        )
                                    })}

                                </Select>
                            </Form.Item>

                            <Form.Item label='เลือกอาชีพ' name='governmentPrivate'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input !',
                                    },]}>
                                <Select
                                    placeholder={'เลือก'}
                                    onChange={getSub}
                                >
                                    {sub?.map((item) => {
                                        return (
                                            <Select.Option key={item.idSub} value={item.idSub}>
                                                {item.descSub}
                                            </Select.Option>
                                        )
                                    })}

                                </Select>
                            </Form.Item>

                            <Form.Item label='สาขาอาชีพ' name='jobPosition'>
                                <Select
                                    placeholder={'เลือก'}
                                    onChange={getSection}
                                >
                                    {section?.map((item) => {
                                        return (
                                            <Select.Option key={item.idSection} value={item.idSection}>
                                                {item.descSection}
                                            </Select.Option>
                                        )
                                    })}

                                </Select>
                            </Form.Item>

                            <Form.Item label='ชื่อสถานที่ทำงาน' name='companyName'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input !',
                                    },]}>
                                <Input type='text'
                                    onChange={(e) => setDataPost({ ...dataPost, companyName: e.target.value })}></Input>
                            </Form.Item>

                            <Form.Item label='หมายเหตุ' name='occupationDescription' >
                                <Input type='text'
                                    onChange={(e) => setDataPost({ ...dataPost, occupationDescription: e.target.value })}></Input>
                            </Form.Item>

                            <Form.Item label='เป็นอาชีพหลักหรือไม่' name='mainOccupation' >
                                <Checkbox defaultChecked={true} onChange={(e) => setDataPost({ ...dataPost, mainOccupation: e.target.checked })}>ใช่</Checkbox>
                            </Form.Item>

                        </Col>
                        <Col className="gutter-row" span={12}>
                            <Form.Item label='รายได้ต่อเดือน' name='incomeMonth'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input !',
                                    },]}>
                                <Input type='text' suffix='บาท'
                                    onChange={(e) => setMoney({ ...money, incomeMonth: parseInt(e.target.value) })}></Input>
                            </Form.Item>

                            <Form.Item label='อื่นๆ' name='note' >
                                <Input type='text' suffix='บาท'
                                    onChange={(e) => setMoney({ ...money, note: parseInt(e.target.value) })}></Input>
                            </Form.Item>

                            <Form.Item label='หมายเหตุ' name='incomeMainDescription' >
                                <Input type='text'
                                    onChange={(e) => setMoney({ ...money, incomeMainDescription: e.target.value })}></Input>
                            </Form.Item>

                            {/* <Form.Item label='รายได้รวม / เดือน' name='totalIncomeMonth' >
                                        {currencyFormatOne(testM)} บาท
                                    </Form.Item>

                                    <Form.Item label='รายได้รวม / ปี' name='totalIncomeYear' >
                                        {currencyFormatOne(testY)} บาท
                                    </Form.Item> */}

                            <Form.Item label='ค่าใช้จ่ายต่อ / เดือน' name='expensesMonth'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input !',
                                    },]}>
                                <Input type='text' suffix='บาท'
                                    onChange={(e) => setExpenses({ ...expenses, expensesMonth: parseInt(e.target.value) })}></Input>
                            </Form.Item>

                            <Form.Item label='หมายเหตุ' name='expensesMonthDescription' >
                                <Input type='text'
                                    onChange={(e) => setExpenses({ ...expenses, expensesMonthDescription: e.target.value })}></Input>
                            </Form.Item>

                            {/* <Form.Item label='หักค่าใช้จ่าย / เดือน' name='summaryOfExpenses' >
                                        {currencyFormatOne(testResult)} บาท
                                    </Form.Item> */}
                        </Col>
                    </Row>
                    <Row gutter={32}>
                        <Col span={12} style={{ textAlign: 'right' }}>
                            <Button
                                type="primary"
                                style={{ background: 'red', color: '#ffffff', }}
                                onClick={handleCancel}>
                                ยกเลิก
                            </Button>
                        </Col>
                        <Col span={12} style={{ textAlign: 'left' }}>
                            <Button type="primary"
                                onClick={handleSubmit}
                            >
                                บันทึก
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    )
};

export default ModalAddCareerIncome
