import React from 'react'
import { Routes, Route } from "react-router-dom"
import TabelLoanLocat from "./report_mg/tabelLoanLocat";

export default function Main_Report() {
  return (
    <Routes>
      <Route path="/" element={<TabelLoanLocat />} /> 
    </Routes>
  )
}

// รายงาน 1