import React, { useState, useEffect } from 'react'
import { Modal, Form, Button, Input, Card, Space, Select, notification, Row, Col, Checkbox, Divider } from 'antd'
import axios from 'axios';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import TextArea from 'antd/es/input/TextArea';
import { Installment } from '../../../offer_case/ploan/Installment';
import Currency from 'currency.js';
import { HirePurchase } from '../../../offer_case/hire_purchase/HirePurchase';
import { addcausename, approvepgcon, approvepgnew, rejectpg, showcausename, unapprovepgnew } from '../../../file_mid/all_api';

function Note({ open, close, shootdata, appStatus, setGetNotification, sendback, setCheckApprove, guarantorData, newDataLand }) {
    const guarantor = useSelector(state => state.guarantor)
    const user = localStorage.getItem('username');
    const [numForCheck, setNumForCheck] = useState({});
    const [addNote, setAddNote] = useState({ name: '' });
    const [options, setOptions] = useState([]);

    const [cause, setCause] = useState({});
    const [dis, setDis] = useState(false);
    const [formAdd] = Form.useForm()
    const [checkCause, setCheckCause] = useState(0);
    const [api, contextHolder] = notification.useNotification();

    const [statusAccept, setstatusAccept] = useState({});

    //ส่งค่าไปในตาราง p-loan
    // const [installmentAmountt, setinstallmentAmountt] = useState({})

    const [checkType, setCheckType] = useState()
    const [typeCar, setTypeCar] = useState()
    const [checkDis, setCheckDis] = useState(true)

    const [checkCar23, setCheckCar23] = useState(false)

    const [openTable, setOpenTable] = useState(false); //ตารางค่างวด p-loan
    const [openTableHP, setOpenTableHP] = useState(false); //ตารางค่างวด เช่าซื้อ

    const [money] = useState({}) //loan
    const branch = localStorage.getItem('branch');
    // const [dataPost2, setDataPost2] = useState({ productTypeCar: 1 })  //car
    const [preData, setpreData] = useState()  //car
    const [checkApprovalConditions] = useState(0)
    const [valueApCon, setValueApCon] = useState(guarantorData);
    const [mixLand, setMixLand] = useState();

    const [form] = Form.useForm()

    useEffect(() => {
        if (guarantor.data.typeLoan === "car") {
            if (appStatus === "อนุมัติ") {
                setpreData({
                    ...shootdata.cars?.carLoanDetails,
                    apProductType: shootdata?.cars.carLoanDetails?.productType,
                    apProductLoanType: shootdata?.cars.carLoanDetails.productLoanType,
                    apLoanAmount: shootdata?.cars?.carLoanDetails?.loanAmount,
                    apDownPayment: shootdata?.cars?.carLoanDetails?.downPayment,
                    apInterest: shootdata?.cars?.carLoanDetails?.interest,
                    apInterestRate: shootdata?.cars?.carLoanDetails?.interestRate,
                    apLoanTerm: shootdata?.cars?.carLoanDetails?.loanTerm,
                    apMonthlyPayment: shootdata?.cars?.carLoanDetails?.monthlyPayment,
                    apInstallmentWithInterest: ~~shootdata?.cars?.carLoanDetails?.monthlyPayment * ~~shootdata?.cars?.carLoanDetails?.loanTerm
                })
                // setinstallmentAmountt({ installmentAmountt: shootdata?.cars?.carLoanDetails?.loanTerm })
                setCheckType(shootdata?.cars?.carLoanDetails?.productType)
                setTypeCar(shootdata?.cars?.productTypeCar)
                if (shootdata?.cars?.carLoanDetails?.productType === "p-loan") {
                    setCheckDis(true)
                } else if (shootdata?.cars?.carLoanDetails?.productType === "เช่าซื้อ") {
                    if (shootdata?.cars?.productTypeCar === 1) {
                        setCheckDis(true)
                    } else {
                        setCheckCar23(true)
                        setCheckDis(false)
                    }
                } else if (shootdata?.cars?.carLoanDetails?.productType === "เช่าซื้อ1") {
                    setCheckDis(false)
                }
                setstatusAccept({

                    status: "อนุมัติ",
                    carId: shootdata?.cars?.carId,
                    loanId: shootdata?.cars?.carLoanDetails?.loanId,
                    customerId: shootdata?.customerId,
                    ApprovalStatus: "อนุมัติ",
                    appStatus: "อนุมัติ",
                    ApprovedBy: user,

                    apProductType: shootdata?.cars.carLoanDetails?.productType,
                    apProductLoanType: shootdata?.cars.carLoanDetails.productLoanType,
                    apLoanAmount: shootdata?.cars?.carLoanDetails?.loanAmount,
                    apDownPayment: shootdata?.cars?.carLoanDetails?.downPayment,
                    apInterest: shootdata?.cars?.carLoanDetails?.interest,
                    apInterestRate: shootdata?.cars?.carLoanDetails?.interestRate,
                    apLoanTerm: shootdata?.cars?.carLoanDetails?.loanTerm,
                    apMonthlyPayment: shootdata?.cars?.carLoanDetails?.monthlyPayment,
                    apInstallmentWithInterest: ~~shootdata?.cars?.carLoanDetails?.monthlyPayment * ~~shootdata?.cars?.carLoanDetails?.loanTerm,
                    apProposalBy: shootdata?.cars?.carLoanDetails?.proposalBy,
                    apReviewedBy: shootdata?.cars?.carLoanDetails?.reviewedBy,
                    apApprovalStatus: "อนุมัติ",
                    apApprovedBy: user,
                    apApprovalDate: dayjs(),
                    apCheckGua: shootdata?.cars?.carLoanDetails?.checkGua,
                    apBranch: branch,

                    approvalConditions: false,
                })
                form.setFieldsValue({

                    apProductType: shootdata?.cars?.carLoanDetails?.productType,
                    apLoanTerm: shootdata?.cars?.carLoanDetails?.loanTerm,
                    apLoanAmount: shootdata?.cars?.carLoanDetails?.loanAmount,
                    apInterestRate: shootdata?.cars?.carLoanDetails?.interestRate,
                    apMonthlyPayment: shootdata?.cars?.carLoanDetails?.monthlyPayment,
                    apInterest: shootdata?.cars?.carLoanDetails?.interest,
                    apInstallmentWithInterest: ~~shootdata?.cars?.carLoanDetails.loanTerm * ~~shootdata?.cars?.carLoanDetails?.monthlyPayment
                })
            } else if (appStatus === "อนุมัติแบบมีเงื่อนไข") {
                setCause("อนุมัติแบบมีเงื่อนไข")
                setpreData({
                    ...shootdata.cars?.carLoanDetails,
                    apProductType: shootdata?.cars.carLoanDetails?.productType,
                    apProductLoanType: shootdata?.cars.carLoanDetails.productLoanType,
                    apLoanAmount: shootdata?.cars?.carLoanDetails?.loanAmount,
                    apDownPayment: shootdata?.cars?.carLoanDetails?.downPayment,
                    apInterest: shootdata?.cars?.carLoanDetails?.interest,
                    apInterestRate: shootdata?.cars?.carLoanDetails?.interestRate,
                    apLoanTerm: shootdata?.cars?.carLoanDetails?.loanTerm,
                    apMonthlyPayment: shootdata?.cars?.carLoanDetails?.monthlyPayment,
                    apInstallmentWithInterest: ~~shootdata?.cars?.carLoanDetails?.monthlyPayment * ~~shootdata?.cars?.carLoanDetails?.loanTerm
                })
                // setinstallmentAmountt({ installmentAmountt: shootdata?.cars?.carLoanDetails?.loanTerm })
                setCheckType(shootdata?.cars?.carLoanDetails?.productType)
                setTypeCar(shootdata?.cars?.productTypeCar)
                if (shootdata?.cars?.carLoanDetails?.productType === "p-loan") {
                    setCheckDis(true)
                } else if (shootdata?.cars?.carLoanDetails?.productType === "เช่าซื้อ") {
                    if (shootdata?.cars?.productTypeCar === 1) {
                        setCheckDis(true)
                    } else {
                        setCheckCar23(true)
                        setCheckDis(false)
                    }
                } else if (shootdata?.cars?.carLoanDetails?.productType === "เช่าซื้อ1") {
                    setCheckDis(false)
                }
                setstatusAccept({

                    status: "อนุมัติแบบมีเงื่อนไข",
                    carId: shootdata?.cars?.carId,
                    loanId: shootdata?.cars?.carLoanDetails?.loanId,
                    customerId: shootdata?.customerId,
                    ApprovalStatus: "อนุมัติแบบมีเงื่อนไข",
                    appStatus: "อนุมัติแบบมีเงื่อนไข",
                    ApprovedBy: user,

                    apProductType: shootdata?.cars.carLoanDetails?.productType,
                    apProductLoanType: shootdata?.cars.carLoanDetails.productLoanType,
                    apLoanAmount: shootdata?.cars?.carLoanDetails?.loanAmount,
                    apDownPayment: shootdata?.cars?.carLoanDetails?.downPayment,
                    apInterest: shootdata?.cars?.carLoanDetails?.interest,
                    apInterestRate: shootdata?.cars?.carLoanDetails?.interestRate,
                    apLoanTerm: shootdata?.cars?.carLoanDetails?.loanTerm,
                    apMonthlyPayment: shootdata?.cars?.carLoanDetails?.monthlyPayment,
                    apInstallmentWithInterest: ~~shootdata?.cars?.carLoanDetails?.monthlyPayment * ~~shootdata?.cars?.carLoanDetails?.loanTerm,
                    apProposalBy: shootdata?.cars?.carLoanDetails?.proposalBy,
                    apReviewedBy: shootdata?.cars?.carLoanDetails?.reviewedBy,
                    apApprovalStatus: "อนุมัติแบบมีเงื่อนไข",
                    apApprovedBy: user,
                    apApprovalDate: dayjs(),
                    apCheckGua: shootdata?.cars?.carLoanDetails?.checkGua,
                    apBranch: branch,

                    approvalConditions: true,
                })
                form.setFieldsValue({
                    cause: "อนุมัติแบบมีเงื่อนไข",
                    apProductType: shootdata?.cars?.carLoanDetails?.productType,
                    apLoanTerm: shootdata?.cars?.carLoanDetails?.loanTerm,
                    apLoanAmount: shootdata?.cars?.carLoanDetails?.loanAmount,
                    apInterestRate: shootdata?.cars?.carLoanDetails?.interestRate,
                    apMonthlyPayment: shootdata?.cars?.carLoanDetails?.monthlyPayment,
                    apInterest: shootdata?.cars?.carLoanDetails?.interest,
                    apInstallmentWithInterest: ~~shootdata?.cars?.carLoanDetails.loanTerm * ~~shootdata?.cars?.carLoanDetails?.monthlyPayment
                })
            } else if (appStatus === "ไม่อนุมัติ") {
                setstatusAccept({
                    loanId: shootdata?.cars?.carLoanDetails?.loanId,
                    approvalStatus: "ไม่อนุมัติ",
                    status: "ไม่อนุมัติ",
                    approvedBy: user,
                    approvalDate: dayjs(),
                })
            } else if (appStatus === "Reject") {
                setstatusAccept({
                    loanId: shootdata?.cars?.carLoanDetails?.loanId,
                    approvalStatus: "Reject",
                    approvedBy: user,
                    status: "Reject",
                    rejectStatus: true,
                    rejectDate: dayjs()
                })
            }
        } else if (guarantor.data.typeLoan === "land") {
            if (appStatus === "อนุมัติ") {
                if (newDataLand.length > 1) {
                    makeMixLand()
                }
                setpreData({
                    ...shootdata?.lands?.landLoanDetails,
                    apLoanAmount: shootdata?.lands?.landLoanDetails?.loanAmountLand,
                    apLoanTerm: shootdata?.lands?.landLoanDetails?.loanLandTerm
                })
                setstatusAccept({
                    ...statusAccept,
                    status: "อนุมัติ",
                    landId: shootdata?.lands?.landId,
                    loanId: shootdata?.lands?.landLoanDetails?.landLoanId,
                    customerId: shootdata?.customerId,
                    ApprovalStatus: "อนุมัติ",
                    appStatus: "อนุมัติ",
                    ApprovedBy: user,

                    apProductType: shootdata?.lands?.landLoanDetails?.productType,
                    apProductLoanType: shootdata?.lands?.landLoanDetails?.productLoanLandType,
                    apLoanAmount: shootdata?.lands?.landLoanDetails?.loanAmountLand,
                    apDownPayment: shootdata?.lands?.landLoanDetails?.downPayment,
                    apInterest: shootdata?.lands?.landLoanDetails?.interestLand,
                    apInterestRate: parseFloat(shootdata?.lands?.landLoanDetails?.interestRateLand),
                    apLoanTerm: shootdata?.lands?.landLoanDetails?.loanLandTerm,
                    apMonthlyPayment: shootdata?.lands?.landLoanDetails?.monthlyPayment,
                    apProposalBy: shootdata?.lands?.landLoanDetails?.proposalBy,
                    apReviewedBy: shootdata?.lands?.landLoanDetails?.reviewedBy,
                    apApprovalStatus: "อนุมัติ",
                    apApprovedBy: user,
                    apApprovalDate: dayjs(),
                    apInstallmentWithInterest: ~~shootdata?.lands?.landLoanDetails?.monthlyPayment * ~~shootdata?.lands?.landLoanDetails?.loanTerm,
                    apCheckGua: shootdata?.lands?.landLoanDetails?.checkGua,
                    apBranch: branch,

                    approvalConditions: false,
                })
            } else if (appStatus === "อนุมัติแบบมีเงื่อนไข") {
                if (newDataLand.length > 1) {
                    makeMixLand()
                }
                setCause("อนุมัติแบบมีเงื่อนไข")
                setpreData({
                    ...shootdata?.lands?.landLoanDetails,
                    apLoanAmount: shootdata?.lands?.landLoanDetails?.loanAmountLand,
                    apLoanTerm: shootdata?.lands?.landLoanDetails?.loanLandTerm
                })
                setstatusAccept({
                    ...statusAccept,
                    status: "อนุมัติแบบมีเงื่อนไข",
                    landId: shootdata?.lands?.landId,
                    loanId: shootdata?.lands?.landLoanDetails?.landLoanId,
                    customerId: shootdata?.customerId,
                    ApprovalStatus: "อนุมัติแบบมีเงื่อนไข",
                    appStatus: "อนุมัติแบบมีเงื่อนไข",
                    ApprovedBy: user,

                    apProductType: shootdata?.lands?.landLoanDetails?.productType,
                    apProductLoanType: shootdata?.lands?.landLoanDetails?.productLoanLandType,
                    apLoanAmount: shootdata?.lands?.landLoanDetails?.loanAmountLand,
                    apDownPayment: shootdata?.lands?.landLoanDetails?.downPayment,
                    apInterest: shootdata?.lands?.landLoanDetails?.interestLand,
                    apInterestRate: parseFloat(shootdata?.lands?.landLoanDetails?.interestRateLand),
                    apLoanTerm: shootdata?.lands?.landLoanDetails?.loanLandTerm,
                    apMonthlyPayment: shootdata?.lands?.landLoanDetails?.monthlyPayment,
                    apProposalBy: shootdata?.lands?.landLoanDetails?.proposalBy,
                    apReviewedBy: shootdata?.lands?.landLoanDetails?.reviewedBy,
                    apApprovalStatus: "อนุมัติแบบมีเงื่อนไข",
                    apApprovedBy: user,
                    apApprovalDate: dayjs(),
                    apInstallmentWithInterest: ~~shootdata?.lands?.landLoanDetails?.monthlyPayment * ~~shootdata?.lands?.landLoanDetails?.loanTerm,
                    apCheckGua: shootdata?.lands?.landLoanDetails?.checkGua,
                    apBranch: branch,

                    approvalConditions: true,
                })
                form.setFieldsValue({
                    cause: "อนุมัติแบบมีเงื่อนไข",

                })
            } else if (appStatus === "ไม่อนุมัติ") {
                setstatusAccept({
                    loanId: shootdata?.lands?.landLoanDetails?.landLoanId,
                    approvalStatus: "ไม่อนุมัติ",
                    status: "ไม่อนุมัติ",
                    approvedBy: user,
                    approvalDate: dayjs(),
                })
            } else if (appStatus === "Reject") {
                setstatusAccept({
                    loanId: shootdata?.lands?.landLoanDetails?.landLoanId,
                    approvalStatus: "Reject",
                    status: "Reject",
                    rejectStatus: true,
                    rejectDate: dayjs(),
                    // approvedBy: user

                })
            }
            form.setFieldsValue(
                {
                    apLoanTerm: shootdata?.lands?.landLoanDetails?.loanLandTerm,
                    apLoanAmount: shootdata?.lands?.landLoanDetails?.loanAmountLand,
                    apMonthlyPayment: shootdata?.lands?.landLoanDetails?.monthlyPaymentLand,
                    apInterest: shootdata?.lands?.landLoanDetails?.interestLand,
                    apInstallmentWithInterest: ~~shootdata?.lands?.landLoanDetails.loanLandTerm * ~~shootdata?.lands?.landLoanDetails?.monthlyPaymentLand
                }
            )
        }
    }, [shootdata])

    useEffect(() => {
        form.setFieldsValue(
            {
                mixLand: mixLand,
            }
        )
    }, [mixLand])

    useEffect(() => {
        loadCauseName();
    }, [numForCheck])

    const currencyFormatOne = (amount) => {
        if (amount) {
            return Number(amount)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, '$&,')
        }
    }

    useEffect(() => {
        if (guarantor?.data?.typeLoan === "car") {
            if (checkType === "เช่าซื้อ1") {
                // console.log("เช่าซื้อ1")
                car_Calculate()
            } else if (shootdata?.cars.productTypeCar !== 1 && checkType === "เช่าซื้อ") {
                // console.log("เช่าซื้อ รถใหญ่")
                car_Calculate()
            }
        } else if (guarantor?.data?.typeLoan === "land") {
            if (appStatus === "อนุมัติ" || appStatus === "อนุมัติแบบมีเงื่อนไข") {
                land_Calculate()
            }
            // console.log("land")
        }
    }, [preData?.apLoanAmount, preData?.apLoanTerm, preData?.apInterestRate, checkType])

    const handleMoney = (values) => {
        //console.log("values",values)
        setstatusAccept({
            ...statusAccept,
            apInterestRate: values?.interestRate,
            apLoanAmount: values?.loanAmountt,
            apLoanTerm: values?.installmentAmountt,
            apInterest: values?.interestt,
            apMonthlyPayment: values?.monthlyPayment,
            apInstallmentWithInterest: values?.installmentWithInterestt,
        })
        form.setFieldsValue(
            {
                apLoanAmount: values?.loanAmountt,
                apInterestRate: values?.interestRate,
                apLoanTerm: values?.installmentAmountt,
                apInterest: currencyFormatOne(values?.interestt),
                apMonthlyPayment: currencyFormatOne(values?.monthlyPayment),
                apInstallmentWithInterest: currencyFormatOne(values?.installmentWithInterestt),
            }
        )
    }

    const handleMoneyHP = (values) => {
        setstatusAccept({
            ...statusAccept,
            apLoanAmount: values?.loanAmountt,
            apInterestRate: values?.interestRate,
            apLoanTerm: values?.installmentAmountt,
            apInterest: values?.interestt,
            apMonthlyPayment: values?.monthlyPayment,
            apInstallmentWithInterest: values?.installmentWithInterestt,
        })
        form.setFieldsValue(
            {
                apLoanAmount: currencyFormatOne(values?.loanAmountt),
                apInterestRate: values?.interestRate,
                apLoanTerm: values?.installmentAmountt,
                apInterest: currencyFormatOne(values?.interestt),
                apMonthlyPayment: currencyFormatOne(values?.monthlyPayment),
                apInstallmentWithInterest: currencyFormatOne(values?.installmentWithInterestt),
            }
        )
    }

    // const onChangePreaaprove = (e) => {
    //     setCheckApprovalConditions(1)
    //     if (e.target.checked === true) {
    //         setstatusAccept({ ...statusAccept, approvalConditions: e.target.checked })
    //     } else {
    //         setstatusAccept({ ...statusAccept, approvalConditions: e.target.checked, approvalDescription: "" })
    //         form.setFieldsValue({ approvalDescription: "" })
    //     }
    // };


    const handleCancel = () => {
        close(false);
    };

    const handleChange = (value) => {
        setCause(`${value}`)
    }

    const changeLoanTerm = (value) => {
        setpreData({ ...preData, apLoanTerm: value })
    }

    const changeInterestRate = (value) => {
        console.log("apInterestRate", value)
        setpreData({ ...preData, apInterestRate: value })
    }

    const addSuccess = (placement) => {
        api.success({
            message: "เพิ่มหมายเหตุแล้ว",
            description:
                'หมายเหตุที่ถูกเพิ่ม สามารถเลือกได้ในช่อง หมายเหตุ',
            placement,
        });
    };

    const getTable = () => {
        setOpenTable(true)
    }
    const getTableHP = () => {
        setOpenTableHP(true)
    }

    const handleOk = async () => {
        setDis(true)
        if (appStatus === "Reject") {
            var rejectData = { ...guarantor.data, cause, ...statusAccept, noteBy: user, rejectStatus: true, noteDate: dayjs().format('YYYY-MM-DDTHH:mm:ssZ') }
            sendReject(rejectData)
        } else if (appStatus === "อนุมัติ") {
            var NoteData = { ...guarantor.data, cause, ...statusAccept, noteBy: user, rejectStatus: false, noteDate: dayjs().format('YYYY-MM-DDTHH:mm:ssZ'), checkNote: "0" }
            // console.log("NoteData", NoteData)
            sendNote(NoteData)
        } else if (appStatus === "อนุมัติแบบมีเงื่อนไข") {
            var ApprovedConditions = { ...guarantor.data, cause, ...statusAccept, noteBy: user, rejectStatus: false, guarantors: valueApCon, noteDate: dayjs().format('YYYY-MM-DDTHH:mm:ssZ'), checkNote: "0" }
            // console.log("ApprovedConditions", ApprovedConditions)
            sendApprovedConditions(ApprovedConditions)
        } else if (appStatus === "ไม่อนุมัติ") {
            var UnApprovedData = { ...guarantor.data, cause, ...statusAccept, noteBy: user, rejectStatus: false, noteDate: dayjs().format('YYYY-MM-DDTHH:mm:ssZ'), checkNote: "0" }
            // console.log("UnApprovedData", UnApprovedData)
            sendUnApproved(UnApprovedData)
        }
    };

    const sendReject = async (value) => {
        await axios.post(rejectpg, value)
            .then(res => {
                if (res.status === 200) {
                    if (value.approvalStatus === "Reject") {
                        setGetNotification(3)
                    }
                    sendback(dayjs())
                    setTimeout(() => {
                        close(false)
                    }, 1000);
                }
            }).catch((err) => console.log(err))
    };

    const sendNote = async (value) => {
        await axios.put(approvepgnew, value)
            .then(res => {
                if (res.status === 200) {
                    setGetNotification(1)
                    setCheckApprove(1)
                    sendback(dayjs())
                    setTimeout(() => {
                        close(false);
                    }, 1000);
                }
            }).catch((err) => console.log(err))
    };

    const sendApprovedConditions = async (value) => {
        await axios.put(approvepgcon, value)
            .then(res => {
                if (res.status === 200) {
                    setGetNotification(1)
                    setCheckApprove(1)
                    sendback(dayjs())
                    setTimeout(() => {
                        close(false);
                    }, 1000);
                }
            }).catch((err) => console.log(err))
    };
    const sendUnApproved = async (value) => {
        await axios.put(unapprovepgnew, value)
            .then(res => {
                if (res.status === 200) {
                    setGetNotification(2)
                    sendback(dayjs())
                    setTimeout(() => {
                        close(false);
                    }, 1000);
                }
            }).catch((err) => console.log(err))
    };

    const loadCauseName = async () => {
        await axios.get(showcausename)
            .then(res => {
                if (res.status === 200) {
                    setOptions(res.data)
                }
            }).catch((err) => console.log(err))
    };

    const handleAddCause = async () => {
        if (addNote.name !== '') {
            await axios.post(addcausename, addNote)
                .then(res => {
                    if (res.status === 200) {
                        setNumForCheck(dayjs())
                        formAdd.resetFields();
                        setCheckCause(0);
                        addSuccess("top")
                        setDis(false);
                    }
                }).catch((err) => console.log(err))
        }
    };

    const BTNAddCause = () => {
        if (checkCause === 0) {
            setCheckCause(1);
            setDis(true);
        } else {
            setCheckCause(0);
            setDis(false);
        }
    };

    const car_Calculate = () => {
        //แก้ใหม่
        var interestRateCar = parseFloat(preData.apInterestRate) / 100 // อัตราดอก / 100
        var rateCar = ((parseFloat(preData.apLoanAmount) * Currency(interestRateCar, { precision: 5 }).value) * preData.apLoanTerm) // (ยอดจัด * อัตราดอกที่หาร100แล้ว ,precision: 3 คือทศนิยม 3 )*งวด
        var loanTermCar = (rateCar + parseFloat(preData.apLoanAmount)) / parseInt(preData.apLoanTerm) // (ดอกเบี้ยทั้งหมด + ยอดจัด)/ งวด
        var newLoanTermCar = Math.ceil(Currency((loanTermCar * 0.07) + loanTermCar), { precision: 5 })// งวดละใหม่ = ( งวดละ * 0.07 ) + งวดละ
        var resultRateCar = Math.ceil(Currency(newLoanTermCar, { precision: 5 }) * parseInt(preData.apLoanTerm)) - parseFloat(preData.apLoanAmount) // งวดละใหม่ * งวด - ยอดจัด
        var newResultPriceCar = Math.ceil(Currency(newLoanTermCar) * parseInt(preData.apLoanTerm), { precision: 5 }) // งวดละใหม่ * งวด

        setstatusAccept({
            ...statusAccept,
            apInterestRate: preData.apInterestRate,
            apMonthlyPayment: newLoanTermCar,
            apInterest: resultRateCar,
            apInstallmentWithInterest: newResultPriceCar,
            apLoanAmount: preData.apLoanAmount,
            apLoanTerm: preData.apLoanTerm,
        })

        form.setFieldsValue(
            {
                apMonthlyPayment: newLoanTermCar,
                apInterest: resultRateCar,
                apInstallmentWithInterest: newResultPriceCar
            }
        )
    }

    const land_Calculate = () => {
        var interestRateLand = parseFloat(shootdata.lands.landLoanDetails.interestRateLand) / 100 // อัตราดอก / 100
        var rate = (parseFloat(preData?.apLoanAmount) * Currency(interestRateLand, { precision: 3 }).value) * parseInt(preData?.apLoanTerm) // (ยอดจัด * อัตราดอกที่หาร100แล้ว ,precision: 3 คือทศนิยม 3 )*งวด
        var loanTerm = Math.ceil(Currency((rate + parseFloat(preData?.apLoanAmount)) / parseInt(preData?.apLoanTerm), { precision: 2 }).value) // (ดอกเบี้ยทั้งหมด + ยอดจัด)/ งวด
        var resultRate = (loanTerm * parseInt(preData?.apLoanTerm)) - parseFloat(preData?.apLoanAmount)
        var resultPrice = loanTerm * parseInt(preData?.apLoanTerm)

        form.setFieldsValue(
            {
                apMonthlyPayment: loanTerm,
                apInterest: resultRate,
                apInstallmentWithInterest: resultPrice
            }
        )

        if (appStatus === "อนุมัติ" && checkApprovalConditions === 0) {
            setstatusAccept({
                ...statusAccept,
                apMonthlyPayment: loanTerm,
                apInterest: resultRate,
                apInstallmentWithInterest: resultPrice,
                apLoanAmount: preData?.apLoanAmount,
                apLoanTerm: preData?.apLoanTerm,

                status: appStatus,
                landId: shootdata?.lands?.landId,
                loanId: shootdata?.lands?.landLoanDetails?.landLoanId,
                customerId: shootdata?.customerId,
                ApprovalStatus: appStatus,
                appStatus: appStatus,
                ApprovedBy: user,

                apProductType: shootdata?.lands?.landLoanDetails?.productType,
                apProductLoanType: shootdata?.lands?.landLoanDetails?.productLoanLandType,
                // apLoanAmount: shootdata?.lands?.landLoanDetails?.loanAmountLand,
                apDownPayment: shootdata?.lands?.landLoanDetails?.downPayment,
                // apInterest: shootdata?.lands?.landLoanDetails?.interestLand,
                apInterestRate: parseFloat(shootdata?.lands?.landLoanDetails?.interestRateLand),
                // apLoanTerm: shootdata?.lands?.landLoanDetails?.loanLandTerm,
                // apMonthlyPayment: shootdata?.lands?.landLoanDetails?.monthlyPayment,
                apProposalBy: shootdata?.lands?.landLoanDetails?.proposalBy,
                apReviewedBy: shootdata?.lands?.landLoanDetails?.reviewedBy,
                apApprovalStatus: appStatus,
                apApprovedBy: user,
                apApprovalDate: dayjs(),

                apCheckGua: shootdata?.lands?.landLoanDetails?.checkGua,
                apBranch: branch,
                approvalConditions: false,
            })
        } else {
            setstatusAccept({
                ...statusAccept,
                apMonthlyPayment: loanTerm,
                apInterest: resultRate,
                apInstallmentWithInterest: resultPrice,
                apLoanAmount: preData?.apLoanAmount,
                apLoanTerm: preData?.apLoanTerm,

                status: appStatus,
                landId: shootdata?.lands?.landId,
                loanId: shootdata?.lands?.landLoanDetails?.landLoanId,
                customerId: shootdata?.customerId,
                ApprovalStatus: appStatus,
                appStatus: appStatus,
                ApprovedBy: user,
            })
        }


    }

    /////////////// เลือก p-loan หรือ เช่าซื้อ ///////////////////
    const handleChangePLorCH = (value) => {
        if (shootdata?.cars?.productTypeCar === 1) {
            // console.log("รถเล็ก")
            if (value === "p-loan") {
                // console.log("p-loan")
                setCheckType(value)
                setCheckDis(true)
                setstatusAccept({
                    ...statusAccept,
                    apProductType: "p-loan",
                })
            } else if (value === "เช่าซื้อ") {
                // console.log("เช่าซื้อ")
                setCheckDis(true)
                setstatusAccept({
                    ...statusAccept,
                    apProductType: "เช่าซื้อ",
                })
                setCheckType(value)
            } else if (value === "เช่าซื้อ1") {
                // console.log("เช่าซื้อ1")
                setCheckDis(false)
                setstatusAccept({
                    ...statusAccept,
                    apProductType: "เช่าซื้อ1",
                })
                setCheckType(value)
            }
            form.setFieldsValue(
                {
                    apLoanAmount: "",
                    apLoanTerm: "",
                    apInterestRate: ""
                }
            )
            setCheckCar23(false)
        } else {
            // console.log("รถใหญ่")
            if (value === "p-loan") {
                setCheckType(value)
                setCheckDis(true)
                setstatusAccept({
                    ...statusAccept,
                    apProductType: "p-loan",
                })
                setCheckCar23(false)
                form.setFieldsValue(
                    {
                        apInterestRate: shootdata?.cars?.carLoanDetails?.interestRate
                    }
                )
            } else if (value === "เช่าซื้อ") {
                setCheckDis(false)
                setstatusAccept({
                    ...statusAccept,
                    apProductType: "เช่าซื้อ",
                })
                setCheckType(value)
                setCheckCar23(true)
                form.setFieldsValue(
                    {
                        apInterestRate: shootdata?.cars?.carLoanDetails?.interestRate
                    }
                )
            }
            form.setFieldsValue(
                {
                    apLoanAmount: "",
                    apLoanTerm: "",
                }
            )
        }
    }

    const onChange = (v, i) => {
        const updatedGuarantorData = valueApCon?.map((item, index) => {
            if (index === i) {
                return { ...item, checkApCon: v.target.checked };
            } else {
                return { ...item };
            }
        });
        setValueApCon(updatedGuarantorData);
    };

    const makeMixLand = () => {
        var mixnumber = [];
        newDataLand?.forEach((item, index) => {
            mixnumber.push(item.numberLand);
        });
        const mixString = mixnumber.join(",");
        setMixLand(mixString);
    };

    const renderGuarantorNewImg = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
        return (
            <>
                <Card
                    title={"ลำดับที่ " + (item.garno)}
                    style={{ textAlign: "center" }}
                    key={index}
                >
                    <Row style={{ margin: '3px' }} justify={'center'} >
                        <Col span={24} style={{ textAlign: "left" }}>
                            <div>
                                <span>ชื่อ-สกุล :</span>  <b>{item?.firstname} {item.lastname}</b>
                            </div>
                            <div>
                                <span>เบอร์โทรติดต่อ :</span> <b>{item?.phones[0]?.telp}</b>
                            </div>
                            <div>
                                <span>ความสัมพันธ์ :</span> <b>{item?.description}</b>
                            </div>
                            <Divider />
                            <div style={{ textAlign: 'center' }}>
                                <Checkbox onChange={(value) => onChange(value, index)} style={{ color: "red" }}>ไม่ผ่าน(ลบ)</Checkbox>
                            </div>
                        </Col>
                    </Row>
                </Card >

            </>
        )
    }

    return (
        <div>
            <Modal title="กรุณายืนยันข้อมูล" open={open} onCancel={handleCancel} footer={[null]} width={800}>
                <Card>
                    <Form
                        onFinish={handleOk}
                        form={form}
                        labelCol={{
                            span: 6,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}>
                        <Row>
                            <Col span={12}>
                                <Form.Item style={{ textAlign: 'center' }}>
                                    สถานะ : <b style={{ color: 'green', fontSize: '20px' }}><u> {appStatus}</u></b>
                                </Form.Item>
                            </Col>
                            {
                                appStatus === "อนุมัติแบบมีเงื่อนไข" ?
                                    <>
                                        <Row gutter={32}  >
                                            {
                                                guarantorData?.map((item, index) => {
                                                    return renderGuarantorNewImg({ item, index, key: `{item.identificationId} - ${index}` })
                                                })
                                            }
                                        </Row>
                                        <Divider />
                                    </>
                                    : null
                            }
                            <Col span={22}>
                                {
                                    mixLand !== undefined ?
                                        <>
                                            <Form.Item label="จดรวมโฉนด" name="mixLand">
                                                <Input disabled name="mixLand" rows={4} style={{ color: 'blue' }} />
                                            </Form.Item>
                                        </> : null
                                }
                                <Form.Item
                                    label="สาเหตุ"
                                    name="cause"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'กรุณากรอกสาเหตุ'
                                        },]}>
                                    <Select
                                        mode="multiple"
                                        style={{
                                            width: '100%',
                                        }}
                                        size="large"
                                        placeholder="สาเหตุ"
                                        onChange={handleChange}
                                        options={options}
                                    />
                                </Form.Item>
                                {
                                    checkCause === 0 ?
                                        null
                                        :
                                        <div>
                                            <Form
                                                form={formAdd}
                                                labelCol={{
                                                    span: 6,
                                                }}
                                                wrapperCol={{
                                                    span: 16,
                                                }}>
                                                <Form.Item label='เพิ่มสาเหตุ' name='name'>
                                                    <Input type='text' size="small" placeholder='กรอกสาเหตุที่ต้องการเพิ่ม' style={{ color: 'black' }}
                                                        onChange={(e) => setAddNote({ ...addNote, name: e.target.value })}
                                                    />
                                                </Form.Item>
                                            </Form>
                                        </div>

                                }
                                <Form.Item
                                    label="หมายเหตุ"
                                    name="note"
                                >
                                    <TextArea rows={4} onChange={(e) => setstatusAccept({ ...statusAccept, note: e.target.value })} />
                                </Form.Item>
                                {
                                    appStatus === "อนุมัติ" || appStatus === "อนุมัติแบบมีเงื่อนไข" ?
                                        <>
                                            {
                                                guarantor.data.typeLoan === "car" ?
                                                    <>
                                                        {/* <p>รถ</p> */}
                                                        {/* handleCalculateHIRE() */}
                                                        {
                                                            typeCar === 1 ?
                                                                <>
                                                                    <Form.Item label='ประเภทค่างวด' name='apProductType'>
                                                                        <Select
                                                                            placeholder={'เลือก'}
                                                                            onChange={(value) => { handleChangePLorCH(value) }}
                                                                            options={[
                                                                                {
                                                                                    label: 'P-LOAN',
                                                                                    value: 'p-loan',
                                                                                },
                                                                                {
                                                                                    label: 'เช่าซื้อ',
                                                                                    value: 'เช่าซื้อ',
                                                                                },
                                                                                {
                                                                                    label: 'เช่าซื้อ (0.84-0.75)',
                                                                                    value: 'เช่าซื้อ1',
                                                                                }
                                                                            ]}
                                                                        />
                                                                    </Form.Item>
                                                                </>
                                                                :
                                                                <>
                                                                    <Form.Item label='ประเภทค่างวด' name='apProductType'>
                                                                        <Select
                                                                            placeholder={'เลือก'}
                                                                            onChange={(value) => { handleChangePLorCH(value) }}
                                                                            options={[
                                                                                {
                                                                                    label: 'P-LOAN',
                                                                                    value: 'p-loan',
                                                                                },
                                                                                {
                                                                                    label: 'เช่าซื้อ',
                                                                                    value: 'เช่าซื้อ',
                                                                                },
                                                                            ]}
                                                                        />
                                                                    </Form.Item>
                                                                </>
                                                        }
                                                        {
                                                            statusAccept.apProductType === "p-loan" ?
                                                                <>
                                                                    <Form.Item label='ตารางค่างวด'>
                                                                        <Button type="primary" onClick={getTable}>
                                                                            ตารางค่างวด P-LOAN
                                                                        </Button>
                                                                    </Form.Item>
                                                                    {
                                                                        openTable ?
                                                                            <Installment open={openTable} close={setOpenTable} money={handleMoney} type={money} cp={~~statusAccept.apLoanAmount} cp2={~~statusAccept.apLoanTerm} checkGARFE={2} checkPG={1} />
                                                                            : null
                                                                    }
                                                                </>
                                                                : statusAccept.apProductType === "เช่าซื้อ" ?
                                                                    <>
                                                                        {
                                                                            shootdata?.cars?.productTypeCar === 1 ?
                                                                                <>
                                                                                    <Form.Item label='ตารางค่างวด'>
                                                                                        <Button type="primary" onClick={getTableHP}>
                                                                                            ตารางค่างวด เช่าซื้อ
                                                                                        </Button>
                                                                                    </Form.Item>
                                                                                    {
                                                                                        openTableHP ?
                                                                                            <HirePurchase open={openTableHP} close={setOpenTableHP} money={handleMoneyHP} type={money} cp={~~statusAccept.apLoanAmount} cp2={~~statusAccept.apLoanTerm} checkGARFE={2} checkPG={1} />
                                                                                            : null
                                                                                    }
                                                                                </>
                                                                                : null
                                                                        }
                                                                    </> : null
                                                        }

                                                        <Form.Item label='ยอดที่อนุมัติ' name='apLoanAmount'
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !'
                                                                },]}>
                                                            <Input disabled={checkDis} style={{ color: 'black' }} suffix="บาท"
                                                                onChange={(e) => setpreData({ ...preData, apLoanAmount: parseInt(e.target.value) })} />
                                                        </Form.Item>
                                                        <Form.Item label='จำนวนงวด' name='apLoanTerm'
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !'
                                                                },]}>
                                                            <Select
                                                                disabled={checkDis}
                                                                placeholder="เลือกจำนวนงวด"
                                                                onChange={changeLoanTerm}
                                                                options={[
                                                                    {
                                                                        value: 12,
                                                                        label: 12,
                                                                    },
                                                                    {
                                                                        value: 18,
                                                                        label: 18,
                                                                    },
                                                                    {
                                                                        value: 24,
                                                                        label: 24,
                                                                    },
                                                                    {
                                                                        value: 30,
                                                                        label: 30,
                                                                    },
                                                                    {
                                                                        value: 36,
                                                                        label: 36,
                                                                    },
                                                                    {
                                                                        value: 42,
                                                                        label: 42,
                                                                    },
                                                                    {
                                                                        value: 48,
                                                                        label: 48,
                                                                    },
                                                                    {
                                                                        value: 54,
                                                                        label: 54,
                                                                    },
                                                                    {
                                                                        value: 60,
                                                                        label: 60,
                                                                    },
                                                                    {
                                                                        value: 66,
                                                                        label: 66,
                                                                    },
                                                                    {
                                                                        value: 72,
                                                                        label: 72,
                                                                    },
                                                                    {
                                                                        value: 78,
                                                                        label: 78,
                                                                    },
                                                                    {
                                                                        value: 84,
                                                                        label: 84,
                                                                    },
                                                                    {
                                                                        value: 90,
                                                                        label: 90,
                                                                    },
                                                                    {
                                                                        value: 96,
                                                                        label: 96,
                                                                    },
                                                                    {
                                                                        value: 102,
                                                                        label: 102,
                                                                    },
                                                                    {
                                                                        value: 108,
                                                                        label: 108,
                                                                    },
                                                                    {
                                                                        value: 114,
                                                                        label: 114,
                                                                    },
                                                                    {
                                                                        value: 120,
                                                                        label: 120,
                                                                    },
                                                                ]}
                                                            />
                                                        </Form.Item>
                                                        {
                                                            checkCar23 === true ?
                                                                <Form.Item label='อัตราดอกเบี้ย' name='apInterestRate'
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please input !'
                                                                        },]}>

                                                                    <Input disabled={checkDis} style={{ color: 'black' }} suffix="% +VAT"
                                                                        onChange={(e) => setpreData({ ...preData, apInterestRate: parseFloat(e.target.value) })} />
                                                                </Form.Item>
                                                                :
                                                                <Form.Item label='อัตราดอกเบี้ย' name='apInterestRate'
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please input !'
                                                                        },]}>
                                                                    <Select
                                                                        disabled={checkDis}
                                                                        placeholder="เลือกอัตราดอกเบี้ย"
                                                                        onChange={changeInterestRate}
                                                                        options={[
                                                                            {
                                                                                label: 0.84,
                                                                                value: 0.84,

                                                                            },
                                                                            {
                                                                                label: 0.75,
                                                                                value: 0.75,
                                                                            }
                                                                        ]}
                                                                    />
                                                                </Form.Item>

                                                        }
                                                        <Form.Item label='งวดละ' name='apMonthlyPayment'>
                                                            <Input disabled style={{ color: 'black' }} suffix="บาท"
                                                                onChange={(e) => setstatusAccept({ ...statusAccept, apMonthlyPayment: e.target.value })} />
                                                        </Form.Item>
                                                        <Form.Item label='ดอกเบี้ย' name='apInterest'>
                                                            <Input disabled style={{ color: 'black' }} suffix="บาท"
                                                                onChange={(e) => setstatusAccept({ ...statusAccept, apInterest: e.target.value })} />
                                                        </Form.Item>
                                                        <Form.Item label='รวมราคา' name='apInstallmentWithInterest'>
                                                            <Input disabled style={{ color: 'black' }} suffix="บาท"
                                                                onChange={(e) => setstatusAccept({ ...statusAccept, apInstallmentWithInterest: e.target.value })} />
                                                        </Form.Item>
                                                    </>
                                                    :
                                                    <>
                                                        {/* <p>ที่ดิน</p> */}
                                                        {/* // land_Calculate() */}
                                                        <Form.Item label='ยอดที่อนุมัติ' name='apLoanAmount'
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !'
                                                                },]}>
                                                            <Input
                                                                type='number' suffix="บาท"
                                                                onChange={(e) => setpreData({ ...preData, apLoanAmount: parseInt(e.target.value) })} />
                                                        </Form.Item>
                                                        <Form.Item label='จำนวนงวด' name='apLoanTerm'
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !'
                                                                },]}>
                                                            <Input type='number' suffix="งวด"
                                                                onChange={(e) => setpreData({ ...preData, apLoanTerm: parseInt(e.target.value) })} />
                                                        </Form.Item>
                                                        <Form.Item label='งวดละ' name='apMonthlyPayment'>
                                                            <Input type='number' suffix="บาท" disabled style={{ color: 'black' }}
                                                                onChange={(e) => setpreData({ ...preData, apMonthlyPayment: parseInt(e.target.value) })} />
                                                        </Form.Item>
                                                        <Form.Item label='ดอกเบี้ย' name='apInterest'>
                                                            <Input type='number' suffix="บาท" disabled style={{ color: 'black' }}
                                                                onChange={(e) => setpreData({ ...preData, apInterest: parseInt(e.target.value) })} />
                                                        </Form.Item>
                                                        <Form.Item label='รวมราคา' name='apInstallmentWithInterest'>
                                                            <Input disabled style={{ color: 'black' }} suffix="บาท"
                                                                onChange={(e) => setpreData({ ...preData, apInstallmentWithInterest: parseInt(e.target.value) })} />
                                                        </Form.Item>
                                                    </>
                                            }
                                        </>
                                        : null
                                }
                            </Col>
                            <Col span={2}>
                                {
                                    checkCause === 0 ?
                                        <Form.Item>
                                            <Button type="primary" onClick={BTNAddCause}>
                                                +
                                            </Button>
                                        </Form.Item>
                                        :
                                        <Form.Item>
                                            <Button type="primary" onClick={BTNAddCause}>
                                                -
                                            </Button>
                                        </Form.Item>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} >
                                <div style={{ textAlign: 'center' }}>
                                    <Space>
                                        {
                                            checkCause === 1 ?
                                                <Button type='primary' onClick={handleAddCause}>เพิ่มหมายเหตุ</Button>
                                                : null
                                        }
                                        <Button key="back" type='primary' danger onClick={handleCancel}>ปิด</Button>
                                        {/* <Button type='primary' htmlType='submit' disabled={dis} style={{ backgroundColor: "Green" }}>ยืนยัน</Button> */}
                                        {/* <Button type='primary' onClick={} style={{ backgroundColor: "Green" }}>ต่อไป</Button> */}
                                    </Space>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </Modal>
            {contextHolder}
            {/* {
                openTableInstallment ?
                    <Installment
                        open={openTableInstallment}
                        close={setOpenTableInstallment}
                        cp={statusAccept.apLoanAmount}
                        cp2={installmentAmountt}
                        checkGARFE={1} />
                    : null
            }
            {
                openTableHirePurchase ?
                    <HirePurchase
                        open={openTableHirePurchase}
                        close={setOpenTableHirePurchase}
                        cp={statusAccept.apLoanAmount}
                        cp2={installmentAmountt}
                        checkGARFE={1}
                    />
                    : null
            } */}
        </div >
    )
};

export default Note