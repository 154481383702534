import React, { useState, useEffect } from "react";
import { Button, Row, Col, Form, Card, Input, InputNumber } from "antd";
import axios from "axios";
import moment from "moment";
import DataTebelColums from "./dataTebelColums";
function MainLandSetRemove() {
  //รับค่า API
  const [dataAPI, setDataAPI] = useState([]);
  //รับค่าข้อมูลที่ค้นหา
  const [dataSearch, setDataSearch] = useState([]);
  //ช่องรับข้อมูล
  const onChangeInputText = (event) => {
    const valueInputText = event.target.value;
    //setInputText(value);
    const filteredSearch = dataAPI.filter(
      (item) =>
        item.numberLand.includes(valueInputText) ||
        item.firstname.includes(valueInputText) ||
        item.lastname.includes(valueInputText)
    );
    //console.log("test2-filteredSearch", filteredSearch);
    setDataSearch(filteredSearch);
  };
  //ช่องรับข้อมูลตัวเลข1
  const [inputNumber, setInputNumber] = useState([]);
  const onChangeInputNumber = (value) => {
    setInputNumber(value);
  };
  //ช่องรับข้อมูลตัวเลข2
  const [inputNumber2, setInputNumber2] = useState([]);
  const onChangeInputNumber2 = (value) => {
    setInputNumber2(value);
  };



  // ส่งข้อมูลไป tabel
  const [dataTabel, setDataTabel] = useState([]);
  //form-Failed
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  //form-Success
  const onFinish = (values) => {
    //console.log("Success:", values);
    //ประธีป เย็นเสนาะ 32832 //สมพงษ์ กำไรเงิน 	22775
    //console.log("test3-show", dataSearch);
    setDataTabel(dataSearch);
    //customerId  //numberLand
  };

  //useEffect โหลดข้อมูลครั้งแรก
  useEffect(() => {
    const fetchData = async () => {
      const dateStart = "2024-04-02";
      const dateEnd = moment().format("YYYY-MM-DD");
      await axios
        .post(
          "https://sendcase-mk-d2flonv3.an.gateway.dev/api-checkinfo/getlandpn",
          {
            days1: dateStart,
            days2: dateEnd,
            approvalStatus: "ทั้งหมด",
          }
        )
        .then((res) => {
          //console.log("res11", res.data);
          const filteredApprovalStatus = res.data.filter(
            (item) =>
              // item.approvalStatus.includes("รอทำสัญญา")
              item.approvalStatus === "อนุมัติ" ||
              item.approvalStatus === "ลูกค้าคอนเฟิร์ม" ||
              item.approvalStatus === "รอตรวจสอบเอกสาร" ||
              item.approvalStatus === "รอทำสัญญา" ||
              item.approvalStatus === "อนุมัติแบบมีเงื่อนไข"
          );
          //console.log("test1-filteredApprovalStatus", filteredApprovalStatus);
          setDataAPI(filteredApprovalStatus);
        })
        .catch((err) => console.log(err.message));
    };
    fetchData();
  }, []);
  return (
    <>
      <h1 style={{ textAlign: "center" }}>ชุดไถ่ถอนที่ดิน</h1>
      <Form
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Card
          bordered={false}
          style={{
            width: 1000,
            //textAlign: "center",
            //border: "3px  solid green",
          }}
        >
          <Row>
            <Col
              span={21}
              style={{
                display: "flex",
                height: "100%", // ให้ Col ใช้ความสูงทั้งหมด
              }}
            >
              <Form.Item
                label="กรอกข้อมูล 1 อย่างจาก (เลขที่โฉนด, ชื่อ, นามสกุล)"
                name="inputText"
                rules={[
                  {
                    required: true,
                    message: "กรอกข้อมูลก่อน!",
                  },
                ]}
              >
                <Input
                  placeholder="กรอกข้อมูล"
                  style={{ width: "400px",height: "32px"  }}
                  onChange={onChangeInputText}
                />
              </Form.Item>
              <Form.Item
                label="กรอกหน้าสำรวจ"
                name="inputNumber"
                rules={[
                  {
                    required: true,
                    message: "กรอกหน้าสำรวจ!",
                  },
                ]}
              >
                <InputNumber
                  placeholder="กรอกหน้าสำรวจ"
                  style={{ width: "200px" }}
                  onChange={onChangeInputNumber}
                />
              </Form.Item>
              <Form.Item
                label="กรอกเลขที่ดิน"
                name="inputNumber2"
                rules={[
                  {
                    required: true,
                    message: "กรอกเลขที่ดินก่อน!",
                  },
                ]}
              >
                <InputNumber
                  placeholder="กรอกเลขที่ดิน"
                  style={{ width: "200px" }}
                  onChange={onChangeInputNumber2}
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label=" ">
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ margin: "0 2px 4px" }}
                >
                  ค้นหา
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
      <DataTebelColums dataTabel={dataTabel} inputNumber={inputNumber} inputNumber2={inputNumber2} />
    </>
  );
}
export default MainLandSetRemove;
