import { PhoneOutlined } from '@ant-design/icons'
import { Button, Card, Col, Divider, Form, Row, Space, Tag } from 'antd'
import React, { useEffect, useState } from 'react'




// ทีมพี่อั๋น
// เอกลักษณ์ 	คำจันทร์	ลักกี้	แผนกเร่งรัดหนี้สิน 0633132147
// ศุภชัย    	สาครวงษ์สุริย	อั๋น	แผนกเร่งรัดหนี้สิน 0864559343
// คมกฤษ  	โพนธาตุ	ต้าร์	แผนกเร่งรัดหนี้สิน 0956857681
// สุริยา  	น้อยสุข	อั่ม	แผนกเร่งรัดหนี้สิน 0942799014
// อภิรัตน์  	โคตรแสนดี	พี 0612965899
// กัมปนาท	โฆสิต	แชมป์ 0952236707


function Tel({ allData, data, onCancel, aun }) {
    const [cusData, setCusData] = useState()
    const [phones, setPhones] = useState([]);

    const Aun = [
        {
            key: 1,
            name: "เอกลักษณ์",
            lastname: "คำจันทร์",
            nickame: "ลักกี้",
            tell: "0633132147",
            major: "แผนกเร่งรัดหนี้สิน",
        },
        {
            key: 2,
            name: "ศุภชัย",
            lastname: "สาครวงษ์สุริย",
            nickame: "อั๋น",
            tell: "0864559343",
            major: "แผนกเร่งรัดหนี้สิน",
        },
        {
            key: 3,
            name: "คมกฤษ",
            lastname: "โพนธาตุ",
            nickame: "ต้าร์",
            tell: "0956857681",
            major: "แผนกเร่งรัดหนี้สิน",
        },
        {
            key: 4,
            name: "สุริยา",
            lastname: "น้อยสุข",
            nickame: "อั่ม",
            tell: "0942799014",
            major: "แผนกเร่งรัดหนี้สิน",
        },
        {
            key: 5,
            name: "อภิรัตน์",
            lastname: "โคตรแสนดี",
            nickame: "พี",
            tell: "0612965899",
            major: "แผนกเร่งรัดหนี้สิน",
        },
        {
            key: 6,
            name: "กัมปนาท",
            lastname: "โฆสิต",
            nickame: "แชมป์",
            tell: "0952236707",
            major: "แผนกเร่งรัดหนี้สิน",
        },
    ]

    useEffect(() => {
        if (data.PRODUCTS === "car") {
            // setTelData("083-678-4303/333-333-3333".replace(/[^\d]/g, ''))
            // setTelData(allData?.custmast[0].TELP.replace(/[^\d]/g, ''))
            setCusData(allData?.custmast[0])
            if (phones.length === 0) {
                if (allData?.custmast[0].TELP) {
                    // console.log(allData?.custmast[0].TELP)
                    extractPhones(allData?.custmast[0].TELP)
                    // extractPhones("098-139-796 043242302//083-678-4303/043242302-0212")
                }
            }
            // if (realTel?.length === 0) {
            //     cutPhoneNumbers(allData?.custmast[0].TELP.replace(/[^\d]/g, '')) 
            // }
        } else {
            if (allData) {
                // setTelData("083-678-4303/333-333-3333".replace(/[^\d]/g, ''))
                // setTelData(allData[0]?.TELP.replace(/[^\d]/g, ''))
                setCusData(allData[0])
                if (phones.length === 0) {
                    if (allData[0]?.TELP) {
                        // console.log(allData[0]?.TELP)
                        extractPhones(allData[0]?.TELP)
                        // extractPhones("098-139-796 043242302//083-678-4303/043242302-0212")
                    }
                }
                // if (realTel?.length === 0) {
                //     cutPhoneNumbers(allData[0]?.TELP.replace(/[^\d]/g, ''))
                // }
            }
        }
    }, [allData])

    const extractPhones = (e) => {
        const phonePattern = /0\d{1,2}-?\d{3}-?\d{3,4}/g;
        const foundPhones = e.match(phonePattern) || [];
        const cleanedPhones = foundPhones.map(phone => phone.replace(/-/g, ''));
        setPhones(cleanedPhones);
    };

    const handleCall = (item) => {
        // console.log("index", index)
        // console.log("item", `tel:+66${item}`)
        // window.location.href = 'tel:+66836784303';
        window.location.href = `tel:+66${item}`;
    };

    // const cutPhoneNumbers = (input) => {
    //     // console.log("input", input)
    //     // ลูปเพื่อประมวลผล input ทีละ 20 ตัวอักษร
    //     for (let i = 0; i < input?.length; i += 20) {
    //         // ตัดสตริง input ตั้งแต่ตำแหน่ง i ถึง i + 20 (รวม 20 ตัวอักษร)
    //         let segment = input?.substring(i, i + 20);

    //         // ตัดสตริง segment ตั้งแต่ตำแหน่ง 0 ถึง 10 (เอา 10 ตัวอักษรแรก)
    //         let firstTen = segment?.substring(0, 10);

    //         // ตัดสตริง segment ตั้งแต่ตำแหน่ง 10 ถึง 20 (เอา 10 ตัวอักษรถัดไป)
    //         // let lastTen = segment.substring(10, 20);

    //         let lastTen = segment?.length > 10 ? segment?.substring(10, 20) : segment?.substring(10);
    //         realTel.push(firstTen);
    //         if (lastTen?.length > 0) {
    //             realTel.push(lastTen);
    //         }
    //     }
    // };

    const handleClose = () => {
        onCancel(false);
    }

    const renderTel = ({ item, index }) => {

        return (
            <>
                <div style={{ marginBottom: 3 }}>
                    <Form.Item>
                        <Space>
                            <span>เบอร์ที่ {index + 1} : </span> <b>{item}</b>
                            <Button
                                type='primary'
                                style={{ backgroundColor: "green" }}
                                icon={<PhoneOutlined />}
                                onClick={() => handleCall(item)}
                            >โทรออก</Button>
                        </Space>
                    </Form.Item>
                </div>
            </>
        )
    }
    const Person = ({ name, lastname, nickame, tell, major }) => (
        <>
            <aside style={{ width: '90%' }}>
                <b>
                    <div>ชื่อ: {name}</div>
                    <div>นามสกุล: {lastname}</div>
                    <div>ชื่อเล่น: {nickame}</div>
                    <div>แผนก: {major}</div>
                    <div>เบอร์โทร: {tell}</div>
                    <div>
                        <Button
                            type='primary'
                            style={{ backgroundColor: "green" }}
                            icon={<PhoneOutlined />}
                            onClick={() => handleCall(tell)}
                        >
                            โทรออก
                        </Button>
                    </div>

                </b>
            </aside>

        </>
    );

    // Parent component to render the list of people
    const PeopleList = ({ people }) => (
        <>
            {people.map(person => (
                <>
                    <Row justify={'center'}>
                        <Card style={{ width: '100%', margin: 0, textAlign: 'left' }}>
                            <Person key={person.key} {...person} />
                            <Divider style={{ margin: 5 }} />
                        </Card>

                    </Row>
                </>
            ))}
        </>
    );

    return (
        <Card style={{ width: "100%" }}>
            <Col justify={'center'}>
                <Row justify={'center'}>
                    <Col span={24} style={{ textAlign: 'left' }}>
                        <b>
                            <div style={{ marginBottom: 0 }}>
                                <span>เลขสัญญา : </span>{<b style={{ fontSize: '20px', lineHeight: '32px' }}>{data?.CONTNO}</b>}
                            </div>
                        </b>
                        <b>
                            <div style={{ marginBottom: 0 }}>
                                <span>เกรด : </span>{<b style={{ fontSize: '20px', lineHeight: '32px' }}>{data?.GRDCOD}</b>}
                            </div>
                        </b>
                    </Col>
                </Row>
            </Col>
            <Col justify={'center'}>
                <Divider orientation={'left'}><b><u>การตลาด</u></b></Divider>
                <aside style={{ width: '90%' }}>
                    <div style={{ margin: 0 }}>
                        ผู้เสนอ : <Tag color="geekblue">{data?.SALCOD}</Tag>
                    </div>
                </aside>
            </Col>
            <Col justify={'center'}>
                <Divider orientation={'left'}><b><u>รายละเอียด</u></b></Divider>
                <aside style={{ width: '90%' }}>
                    <div style={{ marginBottom: 3 }}>
                        <span>เลขบัตรประชาชน : </span> <b>{cusData?.CUSCOD}</b>
                    </div>
                    <div style={{ marginBottom: 3 }}>
                        <span>ชื่อ-สกุล : </span> <b>{cusData?.SNAM}{cusData?.NAME1} {cusData?.NAME2}</b>
                    </div>
                    <div style={{ marginBottom: 3 }}>
                        <span>เบอร์ : </span> <b>{cusData?.TELP}</b>
                    </div>
                </aside>
            </Col>
            <Col justify={'center'}>
                <Divider orientation={'left'}><b><u>Tel.</u></b></Divider>
                <aside style={{ width: '90%' }}>
                    {aun ?
                        <>
                            <PeopleList people={Aun} />
                        </>
                        :
                        <>
                            {
                                phones.map((item, index) => {
                                    return renderTel({ item, index })
                                })
                            }
                        </>
                    }

                </aside>
            </Col>
            <Divider />
            <Row justify={'center'}>
                <Button type='primary' danger onClick={handleClose}>ปิด</Button>
            </Row>
        </Card>
    )
}

export default Tel