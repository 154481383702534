import React, { useState, createRef, useEffect } from "react";
import { Button, Form, Input, Radio, Modal, Typography } from "antd";
import axios from "axios";
import { createFileName } from "use-react-screenshot";
import * as htmlToImage from "html-to-image";

export default function QRCodeWithContractNo({ open, close, dataQr }) {
  const [urlLink, setUrlLink] = useState();
  const [details, setDetails] = useState();
  const [ref2, setRef2] = useState("");
  const [contractNo, setContractNo] = useState("");
  const [payment, setPayment] = useState(0);
  const [isModalQrCode, setModalQrCode] = useState(null);
  const ref = createRef(null);

  const takeScreenShot = async (node) => {
    const dataURI = await htmlToImage.toJpeg(node);
    return dataURI;
  };

  const download = (image, { name = "qr code", extension = "jpg" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name, ` เลขที่สัญญา ${dataQr}`);
    a.click();
  };

  const getImage = () => {
    console.log(ref.current);
    takeScreenShot(ref.current).then(download);
  };

  const QRCODE_URL =
    "https://asia-east2-fir-express-c1aec.cloudfunctions.net/aa/loans";

  const onFinish = (values) => {
    console.log("FINISH", values);
    let amount;
    if (!values.amount) {
      amount = 0;
    } else {
      amount = values.amount;
      setPayment(amount);
    }
    const url =
      QRCODE_URL +
      "/qr/" +
      values.paymentWay +
      "/" +
      "00" +
      "/" +
      "006" +
      "/" +
      ref2 +
      "/" +
      amount * 100;

    console.log("Success:", values);
    setUrlLink(url);
    console.log(url);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    setModalQrCode(true);

    if (isModalQrCode === true) {
      setUrlLink("");
      searchContractNo(dataQr);
      console.log("isModalQrCode1");
    }
  }, [isModalQrCode]);

  const currencyFormat = (amount) => {
    return Number(amount)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };

  const searchContractNo = async (value) => {
    console.log("QR", value);
    await axios
      .get(
        "https://asia-east2-fir-express-c1aec.cloudfunctions.net/aa/conref2/" +
          value
      )
      .then((res) => {
        if (res.data) {
          const result = res.data.reduce((acc, item) => {
            if (item.CONTNO === dataQr) {
              acc = { ...item };
            }
            return acc;
          });
          setDetails(result);
          setRef2(result?.CONTNO_);
          setContractNo(dataQr);
        }
      })
      .catch((err) => alert(err));
  };

  const handleClose = () => {
    setModalQrCode(false);
    close(false);
  };

  return (
    <Modal
      title="สร้าง qr code สำหรับชำระเงิน"
      open={open}
      onCancel={handleClose}
      width={850}
      footer={[
        <Button key="cancel" onClick={handleClose}>
          ปิด
        </Button>,
      ]}
    >
      {details ? (
        <>
          <Form
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            style={{
              maxWidth: 600,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              style={{ fontWeight: "bold" }}
              label="ช่องทางชำระ"
              name="paymentWay"
              rules={[
                {
                  required: true,
                  message: "กรูณาใส่่เลือกช่องทางชำระ",
                },
              ]}
            >
              <Radio.Group>
                <Radio value={"0405559001925"}>ชำระผ่านธนาคาร</Radio>
                <Radio value={"0405552000435"}>
                  ชำระผ่านเค้าน์เตอร์เซอร์วิส
                </Radio>
              </Radio.Group>
            </Form.Item>
            <b>เลขที่สัญญา</b>
            <Typography>{contractNo}</Typography>
            <b>รายละเอียด</b>
            {details ? (
              <>
                <Typography>ชื่อ : {details?.NAME || ""}</Typography>
                <Typography>ทะเบียน/โฉนด : {details?.REGNO || ""}</Typography>
                <Typography>ยี่ห้อ/ประเภท : {details?.TYPE || ""}</Typography>
                <Typography>รุ่น/โมเดล : {details?.MODEL || ""}</Typography>
                <Typography>สี : {details?.COLOR || ""}</Typography>
                <Typography>
                  เลขเครื่อง/เลขที่ : {details?.STRNO || ""}
                </Typography>
              </>
            ) : null}
            <b>ref2</b>
            <Typography>{ref2}</Typography>
            <Form.Item label="จำนวนเงิน" name="amount">
              <Input placeholder="หากไม่ใส่ ลูกค้าจะกำหนดยอดเองได้" />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Button type="primary" htmlType="submit">
                Generate QR Code
              </Button>
            </Form.Item>
          </Form>
          <div
            style={{
              width: "100%",
              justifyContent: "center",
              display: "flex",
            }}
          >
            {urlLink ? (
              <div style={{ width: 350 }}>
                <div
                  ref={ref}
                  style={{
                    padding: 24,
                    backgroundColor: "white",
                    flexDirection: "column",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography>เลขที่สัญญา</Typography>
                    <Typography style={{ fontWeight: "bold" }}>
                      {details?.CONTNO}
                    </Typography>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography>ชื่อ</Typography>
                    <Typography style={{ fontWeight: "bold" }}>
                      {details?.NAME}
                    </Typography>
                  </div>

                  <div
                    style={{
                      width: "100%",
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography>ทะเบียน/โฉนด</Typography>
                    <Typography style={{ fontWeight: "bold" }}>
                      {details?.REGNO}
                    </Typography>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography>ประเภท/ยี่ห้อ</Typography>
                    <Typography style={{ fontWeight: "bold" }}>
                      {details?.TYPE}
                    </Typography>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography>โมเดล/รุ่น</Typography>
                    <Typography style={{ fontWeight: "bold" }}>
                      {details?.MODEL}
                    </Typography>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography>แบบ</Typography>
                    <Typography style={{ fontWeight: "bold" }}>
                      {details?.BAAB}
                    </Typography>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography>สี</Typography>
                    <Typography style={{ fontWeight: "bold" }}>
                      {details?.COLOR}
                    </Typography>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography>เลขที่/เลขเครื่อง</Typography>
                    <Typography style={{ fontWeight: "bold" }}>
                      {details?.STRNO}
                    </Typography>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography>หมายเลขอ้างอิง ref2</Typography>
                    <Typography style={{ fontWeight: "bold" }}>
                      {details?.CONTNO_}
                    </Typography>
                  </div>

                  <img
                    alt=""
                    style={{ justifySelf: "center", display: "flex" }}
                    width={300}
                    src={urlLink}
                    crossOrigin="anonymous"
                  />

                  <Typography style={{ fontWeight: "bold" }}>
                    ยอดชำระ : {currencyFormat(payment)} บาท
                  </Typography>

                  {/* <Typography style={{ fontStyle: "italic" }}>
                        วันที่พิมพ์ {dateNow()}
                      </Typography> */}
                  <br />
                  <Typography style={{ color: "red", fontWeight: "bold" }}>
                    กรุณาตรวจสอบข้อมูลของท่านก่อนชำระงวด
                  </Typography>
                </div>

                <div
                  style={{
                    marginTop: 24,
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <Button
                    type="primary"
                    onClick={() => {
                      getImage();
                    }}
                  >
                    ดาวน์โหลด QR CODE
                  </Button>
                </div>
              </div>
            ) : null}
          </div>
        </>
      ) : (
        "ไม่พบข้อมูล"
      )}
    </Modal>
  );
}
