import React from "react";
import { Divider, Card, Col, Row, Form } from "antd";
import { UserOutlined } from "@ant-design/icons";



export default function MainManagerEmployee() {
    return (
        <>
            <Card>
                <Divider orientation={'center'} style={{ fontSize: '25px' }}><b><u>จัดการพนักงาน</u></b></Divider>
                <Form>
                    <div>
                        <Col span={24}>
                            <div style={{ textAlign: 'center', }}>
                                <UserOutlined style={{ fontSize: '300px' }} />
                            </div>
                            <div style={{ textAlign: 'center', }}>
                                <b style={{ fontSize: '150px' }}>Wait Continue</b>
                            </div>
                        </Col>
                    </div>
                </Form>
            </Card>
        </>
    )

}
