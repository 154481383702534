import React, { useState, useEffect } from 'react'
import { Button, Form, Input, Select, Divider, Row, Col, Modal, Radio, Space, Spin } from 'antd';
import axios from 'axios';
import { editphone } from '../../../../file_mid/all_api';

function ModalEditPhone({ open, close, dataindex, shootdata, fucnEdit, notification, notification2 }) {

    const [form] = Form.useForm();
    const [getData, setGetData] = useState({ ...shootdata.dataedit });
    const [getIndex, setGetIndex] = useState(dataindex);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setGetData({ ...shootdata.dataedit })
        setGetIndex(dataindex)
        form.setFieldsValue(
            {
                ...getData
            }
        )
    }, [shootdata])

    //elements
    const handleCancel = () => { //กดปิด Modal
        close(false);
    };

    const handleSubmit = async () => {
        console.log("Edit555: ", getData)
        setLoading(true)
        await axios.put(editphone, getData)
            .then(res => {
                if (res.status === 200) {
                    notification('top')
                    setLoading(false)
                }
            }).catch((err) => {
                if (err.response.request.status === 400) {
                    notification2('top')
                    setLoading(false)
                }
                console.log("err", err)
            })

        fucnEdit({ getData, getIndex })
        setLoading(false)
        close(false);
    }

    return (
        <>
            <Modal
                open={open}
                onCancel={handleCancel}
                // style={{ background: '#2f54eb' }}
                centered
                width={600}
                footer={[null]} >
                <Spin spinning={loading} size='large' tip=" Loading... ">
                    <Form
                        form={form}
                        name='editdata'
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                        style={{
                            maxWidth: '100%',
                        }}
                        onFinish={handleSubmit}>
                        <Row>
                            <Col span={24} style={{ fontSize: '30px', textAlign: 'center' }} ><u>แก้ไขเบอร์</u></Col>
                        </Row>
                        <Divider />
                        <Row>
                            <Col className='gutter-row' span={24}>
                                <Form.Item label='ชนิด' name='phoneType' rules={[
                                    {
                                        required: true,
                                        message: 'Please input !',
                                    },]}>
                                    <Select
                                        placeholder={'เลือก'}
                                        onChange={(value) => setGetData({ ...getData, phoneType: value })}
                                        options={[
                                            {
                                                label: 'เบอร์มือถือ',
                                                value: 'เบอร์มือถือ',
                                            },
                                            {
                                                label: 'เบอร์ที่ทำงาน',
                                                value: 'เบอร์ที่ทำงาน',
                                            },
                                            {
                                                label: 'เบอร์บ้าน',
                                                value: 'เบอร์บ้าน',
                                            }
                                        ]}
                                    />
                                </Form.Item>
                                <Form.Item label="เบอร์" name='telp'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <Input onChange={(e) => setGetData({ ...getData, telp: e.target.value })} />
                                </Form.Item>
                                <Form.Item label="ส่ง sms" name="sendSms"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <Radio.Group onChange={(e) => setGetData({ ...getData, sendSms: e.target.value })} >
                                        <Radio value="1"> ส่ง </Radio>
                                        <Radio value="0"> ไม่ส่ง </Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item label="หมายเหตุ" name='note' >
                                    <Input onChange={(e) => setGetData({ ...getData, note: e.target.value })} />
                                </Form.Item>
                                <Divider />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} style={{ textAlign: 'center' }}>
                                <Space>
                                    <Button type="primary" onClick={handleCancel} style={{ background: "red" }}>ยกเลิก</Button>
                                    <Button type="primary" htmlType="submit" style={{ background: "green" }} >บันทึก</Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        </>
    )
};

export default ModalEditPhone
