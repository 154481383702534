import React, { useState, useEffect } from 'react'
import { Form, Modal, Row, Col, Image, Space, Card, Button, Tag, Divider, Upload, message, notification, Spin, Checkbox } from 'antd'
import { SendOutlined, PlusOutlined } from '@ant-design/icons'
import dayjs from 'dayjs';
import axios from "axios";
import Currency from 'currency.js';
import { useSelector, useDispatch } from 'react-redux';
import { addGuarantor } from '../../../redux/Guarantor';
import { addImage } from '../../../redux/Image';
// import { FIFTEENPERCENT, TWENTYFOURPERCENT } from './../../offer_case/ploan/InstallmentTable'
import ModalCusConfirm from './ModalCusConfirm';
import Compressor from 'compressorjs';
import TextArea from 'antd/es/input/TextArea';
import {
  checkgu, checklandcus, customerconfirmland, findlandmk, findlandpn, loadOJS, mutips4, resendlandmk1,
  getImagess, getImagessPre, getAllFolderGuaImg, getImagessGua
} from '../../file_mid/all_api';

export default function ModalInfoLand({ open, dataFromTable, close }) {

  // console.log("dataFromTable", dataFromTable)

  const guarantor = useSelector(state => state.guarantor)
  const dispatch = useDispatch()
  const token = localStorage.getItem('token');
  const [imageBlobzz, setImageBlobzz] = useState([]);
  const [dataApproval, setDataApproval] = useState();
  const [dataLand, setDataLand] = useState(dataFromTable);
  const [dataLand1, setDataLand1] = useState();
  const [dataCareer, setDataCareer] = useState([]);
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const { confirm } = Modal;
  const [guarantor1, setGuarantor1] = useState([])
  const [address, setAddresss] = useState([])
  const [dataPhoneCus, setDataPhoneCus] = useState([]);
  const [dataPostGuarantor, setDataPostGuarantor] = useState([]) // customerGuarantor
  const [dataEmail, setDataEmail] = useState([]);
  const [dataSocials, setDataSocials] = useState([]);
  const [reSendData, setReSendData] = useState({});
  // const des = JSON.parse(dataFromTable.des)
  const [des, setDes] = useState([]);
  const [noteRe, setNoteRe] = useState([]);
  const [land, setLand] = useState();
  const [number, setNumber] = useState(0);
  const [checkImage, setCheckImage] = useState();
  const [api, contextHolder] = notification.useNotification();

  // คำนวนที่ดิน
  const [loanTermDATA, setloanTermDATA] = useState({})
  const [resultRateDATA, setresultRateDATA] = useState({})
  const [resultPriceDATA, setresultPriceDATA] = useState({})

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  //เพิ่มรูป
  const [fileListCustomer, setFileListCustomer] = useState([]);
  const [fileListCustomerImageCareer, setFileListCustomerImageCareer] = useState([]);
  const [fileListStatement, setFileListStateMent] = useState([]);
  const [fileListStatementPDF, setFileListStateMentPDF] = useState([]);
  // const [fileListOther, setFileListOther] = useState([]);
  const [fileListOtherMent, setFileListOtherMent] = useState([]);
  const [fileListLand, setFileListLand] = useState([]);
  const [fileListDeep, setFileListDeep] = useState([]);
  const [fileListCustomerLeafDivider, setFileListCustomerLeafDivider] = useState([]);
  const [fileListCustomerBurden, setFileListCustomerBurden] = useState([]);
  // const [fileListGuarantor, setFileListGuarantor] = useState([]);
  // const [fileListGuarantor1, setFileListGuarantor1] = useState([]);

  const [checkApprove, setCheckApprove] = useState({})

  const [career, setCareer] = useState([]) // เก็บข้อมูลอาชีพ
  const [oldData, setOldData] = useState()
  const [modalConfirm, setModalConfirm] = useState() // เปิด modal ของผู้กู้ยอมรับ

  /*เก็บรูปทั้งหมด*/
  const [allImg, setAllImg] = useState([]);
  const newFormData = new FormData();
  const [resultImage, setResultImage] = useState([]);

  const [getimages, setGetImages] = useState();
  const [newDataLand, setNewDataLand] = useState([]);

  const [checkSendNEW, setCheckSendNEW] = useState(false);
  const [isu, setIsu] = useState();
  const [loanRe, setLoanRe] = useState();

  // console.log("fileListStatementPDF", fileListStatementPDF)
  // console.log("all", allImg)

  useEffect(() => {
    loadData()
    loadMixData()
    form.setFieldsValue({ storyLand: dataFromTable.storyLand ? dataFromTable.storyLand : '', })
  }, [])

  useEffect(() => {
    newDataLand.map((e, index) => {
      form.setFieldsValue({
        [`mixNote${index}`]: e?.approval?.mixNote,
        [`storyLand${index}`]: e?.storyLand,

      })
    })
  }, [newDataLand])

  useEffect(() => {
    imageBlobzz?.map((e, index) => {
      var PreAaproveSplit = []
      PreAaproveSplit = e.pathImage.split("/")
      if (PreAaproveSplit[3] === "13") {
        setCheckSendNEW(true)
      }
    })
  }, [imageBlobzz])

  const loadMixData = async () => {
    await axios.post(findlandpn, { landLoanId: dataFromTable.landLoanId })
      .then(res => {
        if (res.status === 200) {
          setNewDataLand(res.data)
        }
      }).catch((err) => {
        console.log("err", err)
      })
  }

  const land_Calculate = async (dataLand11) => {
    // console.log("dataLand11",dataLand11)
    var interestRateLand = parseFloat(dataLand11?.interestRateLand) / 100 // อัตราดอก / 100
    var rate = (parseFloat(dataLand11?.approvedLoanAmount) * Currency(interestRateLand, { precision: 3 }).value) * parseInt(dataLand11?.approvedLoanTerm) // (ยอดจัด * อัตราดอกที่หาร100แล้ว ,precision: 3 คือทศนิยม 3 )*งวด
    var loanTerm = Math.ceil(Currency((rate + parseFloat(dataLand11?.approvedLoanAmount)) / parseInt(dataLand11?.approvedLoanTerm), { precision: 2 }).value) // (ดอกเบี้ยทั้งหมด + ยอดจัด)/ งวด
    var resultRate = (loanTerm * parseInt(dataLand11?.approvedLoanTerm)) - parseFloat(dataLand11?.approvedLoanAmount)
    var resultPrice = loanTerm * parseInt(dataLand11?.approvedLoanTerm)

    setloanTermDATA(loanTerm)
    setresultRateDATA(resultRate)
    setresultPriceDATA(resultPrice)
  }

  const imgSend = (resultImgs) => {
    return new Promise(async (resolve, reject) => {
      try {
        var status;
        // newFormData.append('idcus', reSendData?.lands?.landId);
        //newFormData.append('productTypeId', "1");
        newFormData.append('tm', dayjs().format("YYYY-MM-DDTHH:mm:ssZ"));

        for (const file of resultImgs) {
          var image = {}
          newFormData.append('file', file.imageCompressedFile);
          newFormData.append("type", file.type);
          newFormData.append("idcus", file.imgid);
          image.type = file.type
          resultImage.push(image)
        }
        //const response = await axios.post('http://localhost:8060/Apimg/mutips-3', newFormData);
        const response = await axios.post(mutips4, newFormData);
        if (response.data) {
          //success(randomTenDigitNumber);
          console.log("TY", response.data);
          //dispatch(addSocial(response.data))
          status = response.status;
          resolve(status);
        }
      } catch (error) {
        error(error);
        reject(error);
      } finally {
        //setLoading(false);
        console.log("OK")
      }
    });
  };

  const loadCareer = async () => {
    const id = { customerId: dataFromTable.customerId }
    await axios.post(loadOJS, id)
      .then((res) => {
        setCareer(res.data)
      })
      .catch((err) => console.log(err))
  }

  const addImg = async (resultImg) => {
    //console.log("resultImg =", resultImg)
    if (allImg?.ts1?.length > 0) {
      await logFormData(allImg.ts1, 1, resultImg)
    }
    if (allImg?.ts2?.length > 0) {
      await logFormData(allImg.ts2, 2, resultImg)
    }
    if (allImg?.ts3?.length > 0) {
      await logFormData(allImg.ts3, 3, resultImg)
    }
    if (allImg?.ts4?.length > 0) {
      await logFormData(allImg.ts4, 4, resultImg)
    }
    if (allImg?.ts5?.length > 0) {
      await logFormData(allImg.ts5, 5, resultImg)
    }
    if (allImg?.ts6?.length > 0) {
      await logFormData(allImg.ts6, 6, resultImg)
    }
    if (allImg?.ts7?.length > 0) {
      await logFormData(allImg.ts7, 7, resultImg)
    }
    if (allImg?.ts8?.length > 0) {
      await logFormData(allImg.ts8, 8, resultImg)
    }
    if (allImg?.ts9?.length > 0) {
      await logFormData(allImg.ts9, 9, resultImg)
    }
    if (allImg?.ts10?.length > 0) {
      await logFormData(allImg.ts10, 10, resultImg)
    }
    if (allImg?.ts11?.length > 0) {
      await logFormData(allImg.ts11, 11, resultImg)
    }
    if (allImg?.ts12?.length > 0) {
      await logFormData(allImg.ts12, 12, resultImg)
    }
  }

  const logFormData = async (ts, type, resultImg) => {
    //  var imageListImage = []
    for (const ez of ts) {
      var image = {}
      if (type !== 7) {
        if (ez.size > 4000000) {
          console.log("4m ไม่ให้ส่ง");
        } else {
          let quality;
          if (ez.size >= 2500000 && ez.size <= 4000000) {
            quality = 0.1;
          } else if (ez.size >= 2000000 && ez.size <= 2499999) {
            quality = 0.15;
          } else if (ez.size >= 1500000 && ez.size <= 1999999) {
            quality = 0.2;
          } else if (ez.size >= 1000000 && ez.size <= 1499999) {
            quality = 0.3;
          } else {
            quality = 0.5;
          }
          const compressedFile = await new Promise((resolve) => {
            new Compressor(ez.originFileObj, {
              quality: quality,
              success: (result) => {
                resolve(result);
              },
            });
          });
          var filename = ez.name
          var image64 = await getBase64(compressedFile)
          image.filename = filename
          image.image64 = image64
          image.type = type
          resultImg.push(image)
        }
      }
      else {
        var filename = ez.name
        var image64 = await getBase64(ez.originFileObj)
        image.filename = filename
        image.image64 = image64
        image.type = type
        resultImg.push(image)
      }
    }
    return resultImg
  };

  const loadGuarantors = async () => { //คนค้ำ
    var dataloadGua = { typeLoan: "land", loanId: dataFromTable?.landLoanId }
    await axios.post(checkgu, dataloadGua)
      .then(res => {
        if (res.status === 200) {
          // console.log("คนค้ำ", res.data)
          setGuarantor1(res.data)
          // getImg()
        } else if (res.status === 204) {
          // console.log("ไม่พบข้อมูล")
        }
      }).catch((err) => {
        console.log("err", err)
      })
  }

  // const getImg = async () => {
  //   var path = `one_images/land/${dataFromTable?.landId}/5`
  //   setLoading(true)
  //   await axios.get(`${getAllFolderGuaImg}/${path}?token=${token}`)
  //     .then(res => {
  //       if (res.data) {
  //         // console.log("NEW IMG", res.data) 
  //         setGetImages(res.data)
  //         setLoading(false)
  //       } else {
  //         setGetImages([])
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("err", err)
  //       setLoading(false)
  //     })
  //   setLoading(false)
  // }

  const loadData = async () => {
    setLoading(true)
    await axios.post(findlandmk, { carId: dataFromTable.landId, customerId: dataFromTable.customerId })
      .then(async (res) => {
        if (res.data) {
          console.log("LandLand", res.data)
          setDes(res.data.lands.landLoanDetails.notes)
          if (res.data?.lands?.landLoanDetailsRe?.notes?.length > 0) {
            setNoteRe(res.data.lands.landLoanDetailsRe.notes)
          }
          if (res.data?.lands?.ISSUNO !== "" || res.data?.lands?.ISSUNO !== null) {
            setIsu(res.data?.lands?.ISSUNO)
          }
          setLoanRe(res.data.lands.landLoanDetailsRe)
          setDataLand1(res.data.lands.landLoanDetails)
          setReSendData(res.data)
          setDataCareer(res.data.ojs)
          setAddresss(res.data.address)
          setDataPhoneCus(res.data.phones)
          setDataEmail(res.data.emails)
          setDataSocials(res.data.socials)
          setImageBlobzz(res.data.lands.img)
          setLand(res.data.lands)
          setCheckApprove(res.data.lands.landLoanDetails.approvalStatus)
          setDataApproval(res.data.lands.apLandLoanDetails)
          await land_Calculate(res.data.lands.landLoanDetails)
          await loadGuarantors()
          await loadDataLand()
          await loadCareer()
        }
      }).catch((err) => {
        console.log("err", err)
      })
    setLoading(false)
  }

  const loadDataLand = async () => {
    await axios.post(checklandcus, { numberLand: dataFromTable.numberLand, provinces: dataFromTable.province, district: dataFromTable.district })
      .then(res => {
        if (res.status === 200) {
          setOldData(res.data)
        }
      }).catch((err) => {
        console.log("err", err)
      })
  }

  const ConfirmOK = () => {
    setModalConfirm(true)
  };


  const showConfirmOK = () => {
    confirm({
      title: 'ผู้กู้ยอมรับยอดที่อนุมัติ...?',
      onOk() {
        var checkOK = true
        customerConfirm(checkOK)
        setTimeout(() => {

        }, 1500)
      },
      onCancel() {
      },
    });
  };

  const showConfirmCancel = () => {
    confirm({
      title: 'ผู้กู้ปฏิเสธยอดที่อนุมัติ...?',
      onOk() {
        var checkOK = false
        customerConfirm(checkOK)

      },
      onCancel() {
      },
    });
  };

  const customerConfirm = async (checkOK) => {
    setLoading(true)
    if (checkOK === true) {
      var data = { landLoanId: reSendData?.lands?.landLoanDetails?.landLoanId, approvalStatus: "ลูกค้าคอนเฟิร์ม", cusIsAccepted: true, cusIsAcceptedDate: dayjs() }
    } else {
      var data = { landLoanId: reSendData?.lands?.landLoanDetails?.landLoanId, approvalStatus: "ลูกค้าปฏิเสธ", cusIsAccepted: false, cusIsAcceptedDate: dayjs() }
    }
    delete data.rejectDate
    await axios.put(customerconfirmland, data)
      .then(res => {
        if (res.status === 200) {
          setLoading(false)
          if (data.approvalStatus === "ลูกค้าคอนเฟิร์ม") {
            message.success('ผู้กู้ยอมรับยอดที่อนุมัติ')
          } else {
            message.error('ผู้กู้ปฏิเสธยอดที่อนุมัติ')
          }
          setTimeout(() => {
            handleCancel();
          }, 2000)

        }
      }).catch((err) => {
        console.log("err", err)
      })
  };

  const handleSave = async () => {
    setLoading(true)
    console.log("allImg", allImg)
    if (allImg?.length > 0) {
      await imgSend(allImg)
    }
    setLoading(false)
    message.success('บันทึกสำเร็จ')
    setNumber(1)
  };

  const sendNew = (placement) => {
    api.success({
      message: "ส่งเคสสำเร็จ",
      description:
        'ส่งเคสไปยังผู้ที่เกี่ยวข้องแล้ว',
      placement,
    });
  };


  const showConfirm = async () => {
    if (dataFromTable?.approvalStatus === "ไม่อนุมัติ") {
      console.log("ไม่อนุมัติ")
      var mix = { ...reSendData.lands.landLoanDetails, guarantors: guarantor?.data, land: { ...reSendData.lands, img: resultImage, carInput: dayjs().format("YYYY-MM-DD") }, approvalStatus: "รอธุรการรับ" }
    } else {
      var mix = { ...reSendData.lands.landLoanDetails, guarantors: guarantor?.data, land: { ...reSendData.lands, img: resultImage, carInput: dayjs().format("YYYY-MM-DD") }, approvalStatus: "รอพี่หนุ่มรับ" }
      console.log("รอพี่หนุ่มรับ")
    }
    //console.log("mix", mix)
    confirm({
      title: 'คุณต้องการที่จะส่งเคสนี้อีกครั้ง...?',
      onOk() {
        axios.put(resendlandmk1, mix)
          .then(res => {
            if (res.status === 200) {
              sendNew("top")
              setTimeout(() => {
                close(false)
              }, 1500);
            }
          }).catch((err) => console.log(err))
      },
      onCancel() {
        dispatch(addImage())
      },
    });
  };

  ///////////////////////////////////อัพโหลดรูปภาพใหม่/////////////////////////////////////////////
  const compressImage = async (ez, types, landId) => {
    var image = {};
    return new Promise(async (resolve) => {
      if (types !== 7) {
        if (ez.size > 4000000) {
          console.log("4m ไม่ให้ส่ง");
          resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
        } else {
          let quality;
          if (ez.size >= 2500000 && ez.size <= 4000000) {
            quality = 0.1;
          } else if (ez.size >= 2000000 && ez.size <= 2499999) {
            quality = 0.15;
          } else if (ez.size >= 1500000 && ez.size <= 1999999) {
            quality = 0.2;
          } else if (ez.size >= 1000000 && ez.size <= 1499999) {
            quality = 0.3;
          } else {
            quality = 0.5;
          }

          try {
            // ใช้ Promise เพื่อบีบอัดรูปภาพ
            const compressedFile = await new Promise((resolveCompress) => {
              new Compressor(ez, {
                quality: quality,
                success: (result) => {
                  resolveCompress(result);
                },
              });
            });

            const fileOptions = {
              type: compressedFile.type,
              lastModified: Date.now(),
            };
            const compressedFileAsFile = new File([compressedFile], ez.name, fileOptions);
            image.imageCompressedFile = compressedFileAsFile;
            image.type = types
            image.imgid = landId
            allImg.push(image);
            //setAllImg((prevAllImg) => [...prevAllImg, image]);

            // ตรงนี้คือการ resolve ไฟล์ที่ถูกบีบอัด
            resolve(image);
          } catch (error) {
            console.error('Image compression error:', error);
            resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
          }
        }
      } else {
        // ... (โลจิกสำหรับ type 7)
        image.imageCompressedFile = ez;
        image.type = types
        image.imgid = landId
        allImg.push(image);
        //setAllImg((prevAllImg) => [...prevAllImg, image]);
        resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
      }
    });
  };
  const beforeUpts = async (file, ty, landId) => {
    try {
      setLoading(true)
      const compressedFile = await compressImage(file, ty, landId);
      return Promise.resolve(compressedFile);
    } catch (error) {
      console.error('Image compression error:', error);
      message.error('Image compression failed.');
      return Promise.reject(); // เปลี่ยนให้ return Promise.reject() เมื่อไม่สามารถบีบอัดได้
    }
  };

  const handleCustomerImage = async ({ fileList }) => {

    for (const removedFile of fileListCustomer.filter(file => file.status === 'removed')) {
      if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 4)) {
        const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 4));
        setAllImg(updatedAllImg);
      }
    }
    setFileListCustomer(fileList);
    setCheckImage(fileList);
    setLoading(false)
  };
  const handleCustomerImageCareer = async ({ fileList }) => {

    for (const removedFile of fileListCustomerImageCareer.filter(file => file.status === 'removed')) {
      if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 6)) {
        const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 6));
        setAllImg(updatedAllImg);
      }
    }
    setFileListCustomerImageCareer(fileList);
    setCheckImage(fileList);
    setLoading(false)
  };
  const handleLand = async ({ fileList }) => {

    for (const removedFile of fileListLand.filter(file => file.status === 'removed')) {
      if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 1)) {
        const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 1));
        setAllImg(updatedAllImg);
      }
    }
    setFileListLand(fileList);
    setCheckImage(fileList);
    setLoading(false)
  };

  const handleDeep = async ({ fileList }) => {

    for (const removedFile of fileListDeep.filter(file => file.status === 'removed')) {
      if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 3)) {
        const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 3));
        setAllImg(updatedAllImg);
      }
    }
    setFileListDeep(fileList);
    setCheckImage(fileList);
    setLoading(false)
  };

  const handleChangeCustomerLeafDivider = async ({ fileList }) => {

    for (const removedFile of fileListCustomerLeafDivider.filter(file => file.status === 'removed')) {
      if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 10)) {
        const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 10));
        setAllImg(updatedAllImg);
      }
    }
    setFileListCustomerLeafDivider(fileList);
    setCheckImage(fileList);
    setLoading(false)
  };

  const handleChangeCustomerBurden = async ({ fileList }) => {

    for (const removedFile of fileListCustomerBurden.filter(file => file.status === 'removed')) {
      if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 11)) {
        const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 11));
        setAllImg(updatedAllImg);
      }
    }
    setFileListCustomerBurden(fileList)
    setCheckImage(fileList);
    setLoading(false)
  };

  const handleStatement = async ({ fileList }) => {

    for (const removedFile of fileListStatement.filter(file => file.status === 'removed')) {
      if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 8)) {
        const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 8));
        setAllImg(updatedAllImg);
      }
    }
    setFileListStateMent(fileList);
    setCheckImage(fileList);
    setLoading(false)
  };

  const handleStatementPDF = async ({ fileList }) => {

    for (const removedFile of fileListStatementPDF.filter(file => file.status === 'removed')) {
      if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 7)) {
        const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 7));
        setAllImg(updatedAllImg);
      }
    }
    setFileListStateMentPDF(fileList);
    setCheckImage(fileList);
    setLoading(false)
  };

  // const handleOther = async ({ fileList }) => {

  //   for (const removedFile of fileListOther.filter(file => file.status === 'removed')) {
  //     if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 9)) {
  //       const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 9));
  //       setAllImg(updatedAllImg);
  //     }
  //   }
  //   setFileListOther(fileList);
  //   setCheckImage(fileList);
  //   setLoading(false)
  // };

  const handleOtherMent = async ({ fileList }) => {

    for (const removedFile of fileListOtherMent.filter(file => file.status === 'removed')) {
      if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 12)) {
        const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 12));
        setAllImg(updatedAllImg);
      }
    }
    setFileListOtherMent(fileList);
    setCheckImage(fileList);
    setLoading(false)
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleCancel = () => {
    close(false)
  }
  const handleCancelPreview = () => {
    setPreviewVisible(false);
  }

  const handleOk = async () => {
    console.log('ok', dataLand)
    close(false);
  };

  const currencyFormatOne = (amount) => {
    return Number(amount)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
  }

  let color

  if (dataLand?.approvalStatus === "รอธุรการรับ") {
    color = "gold"
  }
  if (dataLand?.approvalStatus === "รอการวิเคราะห์") {
    color = "gold"
  }
  if (dataLand?.approvalStatus === "รอวิเคราะห์") {
    color = "cyan"
    // color = "gold"
  }
  if (dataLand?.approvalStatus === "รอเคาะราคา") {
    color = "gold"
    // color = "gold"
  }
  if (dataLand?.approvalStatus === "ผ่านการวิเคราะห์") {
    color = "geekblue"
    // color = "orange"
  }
  if (dataLand?.approvalStatus === "เคาะราคาแล้ว") {
    color = "green"
    // color = "orange"
  }
  if (dataLand?.approvalStatus === "ไม่ผ่านการวิเคราะห์") {
    color = "red"
    // color = "cyan"
  }
  if (dataLand?.approvalStatus === "ผ่านพิจารณา") {
    color = "geekblue"
  }
  if (dataLand?.approvalStatus === "รออนุมัติ") {
    color = "lime"
  }
  if (dataLand?.approvalStatus === "อนุมัติ") {
    color = "green"
  }
  if (dataLand?.approvalStatus === "อนุมัติแบบมีเงื่อนไข") {
    color = "green"
  }
  if (dataLand?.approvalStatus === "โทรติด") {
    color = "magenta"
  }
  if (dataLand?.approvalStatus === "โทรไม่ติด(ติดต่อไม่ได้)") {
    color = "volcano"
  }
  if (dataLand?.approvalStatus === "ไม่อนุมัติ") {
    color = "red"
  }
  if (dataLand?.approvalStatus === "ลูกค้าปฏิเสธ") {
    color = "red"
  }
  if (dataLand?.approvalStatus === "รอทำสัญญา") {
    color = "green"
  }
  if (dataLand?.approvalStatus === "รออนุมัติราคารถ") {
    color = "orange"
  }
  if (dataLand?.approvalStatus === "รอพิจารณา") {
    color = "cyan"
  }

  const renderGuarantor = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
    return (
      <>
        <div><b><u>ลำดับที่ {index + 1}</u></b></div>
        <aside style={{ width: '90%' }}>
          <div style={{ marginBottom: 0 }}>
            <span>ชื่อ-นามสกุล : </span><b>{item?.snam + ' ' + item?.firstname + ' ' + item?.lastname}</b>
          </div>

          <div style={{ marginBottom: 0 }}>
            <span>ความสัมพันธ์ : </span> <b>{item?.description}</b>
          </div>
          {item?.phones?.length > 0 ?
            <>
              <div style={{ marginBottom: 0 }}>
                <span>เบอร์โทรติดต่อ : </span> <b>{item?.phones[0]?.telp}</b>
              </div>
            </>
            : <div style={{ marginBottom: 0 }}>
              <span>เบอร์โทรติดต่อ : </span> <b>ไม่มี</b>
            </div>
          }

          {item?.address?.length > 0 ?
            <>
              <div style={{ marginBottom: 0 }}>
                <span > ที่อยู่ :  </span>
                <b>
                  {
                    item.address[0]?.houseNumber + ' ' +
                    "หมู่ " + item.address[0]?.soi + ' ' +
                    "ถ." + item.address[0]?.road + ' ' +
                    "ต." + item.address[0]?.subdistrict + ' ' +
                    "อ." + item.address[0]?.district + ' ' +
                    "จ." + item.address[0]?.province + ' '
                    + item.address[0]?.zipCode
                  }
                </b>
              </div>
            </>
            : null
          }
        </aside>
        {guarantor1.length > 0 ? <Divider /> : null}
      </>
    )
  }
  const renderGuarantorNewImg = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
    // console.log("Item", item, index)
    var itemSplit = []
    if (item?.pathImg) {
      itemSplit = item.pathImg.split("/")
    }
    return (
      <>
        <div><b><u>ลำดับที่ {index + 1}</u></b></div>
        <aside style={{ width: '90%' }}>
          <div style={{ marginBottom: 0 }}>
            <span>ชื่อ-นามสกุล : </span><b>{item?.snam + ' ' + item?.firstname + ' ' + item?.lastname}</b>
          </div>

          <div style={{ marginBottom: 0 }}>
            <span>ความสัมพันธ์ : </span> <b>{item?.description}</b>
          </div>
          {item?.phones?.length > 0 ?
            <>
              <div style={{ marginBottom: 0 }}>
                <span>เบอร์โทรติดต่อ : </span> <b>{item?.phones[0]?.telp}</b>
              </div>
            </>
            : <div style={{ marginBottom: 0 }}>
              <span>เบอร์โทรติดต่อ : </span> <b>ไม่มี</b>
            </div>
          }

          {item?.address?.length > 0 ?
            <>
              <div style={{ marginBottom: 0 }}>
                <span > ที่อยู่ :  </span>
                <b>
                  {
                    item.address[0]?.houseNumber + ' ' +
                    "หมู่ " + item.address[0]?.soi + ' ' +
                    "ถ." + item.address[0]?.road + ' ' +
                    "ต." + item.address[0]?.subdistrict + ' ' +
                    "อ." + item.address[0]?.district + ' ' +
                    "จ." + item.address[0]?.province + ' '
                    + item.address[0]?.zipCode
                  }
                </b>
              </div>
            </>
            : null
          }

        </aside>
        {/* <Divider/> */}
        {itemSplit.length > 0 ? (
          <>
            <Row justify={"center"}>
              {imageBlobzz?.map((items, index) => {
                const segments = items.pathImage.split("/")
                if (itemSplit[4] === segments[4] && items.type === 5) {
                  return (
                    // <Image width={'100px'} key={index} src={`data:${items?.Attr};base64,${items?.data?.toString('base64')}`} alt={`Image ${index + 1}`} />
                    <Image width={"100px"} key={index} src={`${getImagessGua}/${items.pathImage}?token=${token}`} alt={`รูปภาพ ${index + 1}`} />
                  )
                }
              }
              )}
            </Row>
          </>
        ) : null
        }
        <Divider style={{ margin: 5 }} />
      </>
    )
  }
  const renderItemAddress = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
    return (
      <>
        <div><b><u>ลำดับที่ {index + 1}</u></b></div>
        <aside style={{ width: '90%' }}>
          <div style={{ marginBottom: 0 }}>
            <span>เลขทะเบียนบ้าน : </span> <b>{item.houseRegistrationNumber}</b>
          </div>
          <div
            style={{ marginBottom: 0 }}>
            <span>ถนน : </span><b>{item.road}</b>
          </div>
          <div style={{ marginBottom: 0 }}>
            <span>ตำบล : </span> <b>{item.subdistrict}</b>
          </div>
          <div style={{ marginBottom: 0 }}>
            <span>บ้านเลขที่ : </span> <b>{item.houseNumber}</b>
          </div>
          <div style={{ marginBottom: 0 }}>
            <span>หมู่บ้าน : </span> <b>{item.village}</b>
          </div>
          <div style={{ marginBottom: 0 }}>
            <span>อำเภอ : </span> <b>{item.district}</b>
          </div>
          <div style={{ marginBottom: 0 }}>
            <span>หมู่ / ซอย : </span> <b>{item.soi}</b>
          </div>
          <div
            style={{ marginBottom: 0 }}>
            <span>จังหวัด : </span> <b>{item.province}</b>
          </div>
          <div style={{ marginBottom: 0 }}>
            <span>รหัสไปรษณีย์ : </span> <b>{item.zipCode}</b>
          </div>

        </aside>
        {address.length >= 1 ? <Divider /> : null}
      </>
    )
  }
  const renderItemPhones = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
    return (
      <>
        <div
          style={{ marginBottom: 0 }}
        >
          <span>{item.phoneType ? <>{item.phoneType} : </> : null}</span>
          <b> {item.telp}</b>
        </div>
      </>
    )
  }
  const renderDataNote = ({ item, index }) => {
    return (
      <>
        <Row gutter={32} justify={'center'}>
          <Col className='gutter-row' span={24}>
            <>
              <Card
                key={index}
                style={{ width: '270px', textAlign: 'left' }}>
                <>
                  <div style={{ marginBottom: 0 }}>
                    <span>สาเหตุ : </span>
                    <b>{item.cause}</b>
                  </div>
                  <div style={{ marginBottom: 0 }}>
                    <span>หมายเหตุ : </span>
                    <b>{item.note}</b>
                  </div>
                  {/* <div style={{ marginBottom: 0 }}>
                      <span>ผู้สร้างหมายเหตุ : </span>
                      <b>{item.noteBy}</b>
                    </div> */}
                  <div style={{ marginBottom: 0 }}>
                    <span>สถานะที่ทำการหมายเหตุ : </span>
                    <b>{item.status}</b>
                  </div>
                </>
              </Card>
            </>
          </Col>
        </Row>
      </>
    )
  }

  const renderLandData = ({ item, index }) => {
    return (
      <>
        <Divider orientation={'left'}><b><u>รายละเอียดที่ดิน ({item?.numberLand}) {newDataLand.length > 1 ? <>{item.landMain ? <b style={{ color: "red" }} >(ที่ดินหลัก)</b> : null}</> : null}</u></b></Divider>
        <aside style={{ width: '90%' }}>
          <div style={{ marginBottom: 0 }}>
            <span>กลุ่มสินค้า : </span>{<b>{item?.productType}</b>}
          </div>
          <div style={{ marginBottom: 0 }}>
            <span>ประเภทเอกสาร : </span>{<b>{item?.productTypeLand === 1 ? "นส.3" : "นส.4"}</b>}
          </div>
          <div style={{ marginBottom: 0 }}>
            <span>เลขโฉนดที่ดิน : </span>{<b>{item?.numberLand}</b>}
          </div>
          <div style={{ marginBottom: 0 }}>
            <span>เลขที่ดินหรือระวาง : </span>{<b>{item?.numberLandLawang}</b>}
          </div>
          <div style={{ marginBottom: 0 }}>
            <span>จังหวัด : </span>{<b>{item?.province}</b>}
          </div>
          <div style={{ marginBottom: 0 }}>
            <span>อำเภอ : </span>{<b>{item?.district}</b>}
          </div>
          <div style={{ marginBottom: 0 }}>
            <span>พื้นที่ไร่ : </span>{<b>{item?.rai}</b>}
            <span style={{ display: 'inline-block', marginLeft: '30px' }}>
              ไร่
            </span>
          </div>
          <div style={{ marginBottom: 0 }}>
            <span>พื้นที่งาน : </span>{<b>{item?.workArea}</b>}
            <span style={{ display: 'inline-block', marginLeft: '30px' }}>
              งาน
            </span>
          </div>
          <div style={{ marginBottom: 0 }}>
            <span>พื้นที่ตารางวา : </span>{<b>{currencyFormatOne(item?.squareWaArea)}</b>}
            <span style={{ display: 'inline-block', marginLeft: '30px' }}>
              บาท
            </span>
          </div>
          <div style={{ marginBottom: 0 }}>
            <span>ราคาประเมินที่ดิน : </span> {<b>{item?.landPrice}</b>}
            <span style={{ display: 'inline-block', marginLeft: '30px' }}>
              บาท / ตารางวา
            </span>
          </div>
          <div style={{ marginBottom: 0 }}>
            <span>ราคารวม : </span> {<b>{currencyFormatOne(item?.resultLandPrice)}</b>}
            <span style={{ display: 'inline-block', marginLeft: '30px' }}>
              บาท
            </span>
          </div>
        </aside>
        <Divider orientation={'left'}><b><u>สตอรี่รายละเอียดที่ดิน ({item?.numberLand})</u></b></Divider>
        <Form.Item name={`storyLand${index}`} label="">
          <TextArea style={{ color: 'blue' }} rows={5} disabled />
        </Form.Item>
      </>
    )

  }
  const renderCoordinates = ({ item, index }) => { //render Land Array
    return (
      <>
        <div>
          <span>รายละเอียดพิกัด ({item?.numberLand}) : </span>
          <a href={`https://www.google.com/maps?q=${item.parcellat},${item.parcellon}`} target="_blank">
            {`${item.parcellat},${item.parcellon}`}
          </a>
        </div>
      </>
    )
  }
  const renderApproval = ({ item, index }) => {
    return (
      <>
        <aside style={{ width: '90%' }}>
          <div style={{ marginBottom: 0 }}>
            <span>ยอดที่อนุมัติ ({item?.numberLand}) : </span>{<b>{currencyFormatOne(item?.approval?.minPrice)} - {currencyFormatOne(item?.approval?.maxPrice)}</b>}
            <span style={{ display: 'inline-block', marginLeft: '30px' }}>
              บาท
            </span>
          </div>
          <div style={{ marginBottom: 0 }}>
            <span>หมายเหตุ : </span>{<b>{item?.approval?.note ? item?.approval?.note : "-"}</b>}
          </div>
        </aside>
      </>
    )
  }
  const renderMixNote = ({ item, index }) => {
    return (
      <>
        {
          index === 0 ?
            null :
            <>
              <Row justify={'left'}><b><u>หมายเหตุจดรวมโฉนด</u></b></Row>
              <Row gutter={32} justify={'center'}>
                <Col className='gutter-row' span={24}>
                  <Form.Item style={{ marginBottom: 0, textAlign: 'center' }} label='' name={`mixNote${index}`}>
                    <TextArea rows={5} disabled name={`mixNote${index}`} style={{ color: "blue" }}></TextArea>
                  </Form.Item>
                </Col>
              </Row>
              <Divider />
            </>
        }
      </>
    )
  }

  const renderLandBuildings = ({ item, index }) => { //render Land Array
    // console.log(item)
    return (
      <>
        <Row justify={'left'}><b><u>รูปที่ดิน/สิ่งปลูกสร้าง-ที่ดินปัจจุบัน ({item.numberLand})</u></b></Row>
        <Row gutter={32} justify={'center'}>
          <Space direction='vertical'>
            <Row justify={'center'}>
              {item?.img?.map((e, index) => {
                var PreAaproveSplit = []
                PreAaproveSplit = e.pathImage.split("/")
                if (PreAaproveSplit[3] !== "13") {
                  if (e.type === 1) {
                    return (
                      <div style={{ margin: 3 }}>
                        <Image width={"100px"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                      </div>
                    );
                  }
                }
                return null;
              })}
            </Row>
            {
              dataFromTable?.approvalStatus === "ขอเอกสารเพิ่ม" || dataFromTable?.approvalStatus === "ไม่อนุมัติ" ?
                <>
                  <div className='gutter-row' span={12}>
                    <b> <Form.Item label='เพิ่มรูป' name='imageIDGuCar'
                    >
                      <Upload
                        name='imageIDGuCar'
                        multiple={true}
                        listType="picture-card"
                        // fileList={fileListLand}
                        onPreview={handlePreview}
                        onChange={handleLand}
                        beforeUpload={(file) => beforeUpts(file, 1, item.landId).then(() => false).catch(() => true)}
                      >
                        {fileListLand?.length >= 10 ? null : (
                          <div>
                            <PlusOutlined />
                            <div className="ant-upload-text">upload</div>
                          </div>
                        )}
                      </Upload>
                      <Modal open={previewVisible} onCancel={handleCancelPreview}>
                        <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                      </Modal>
                    </Form.Item>
                    </b>
                  </div>
                </>
                : null
            }
          </Space>
        </Row>
        <Divider />
        {
          checkSendNEW ?
            <>
              <Row justify={'left'}><b><u>รูปที่ดิน/สิ่งปลูกสร้าง-ที่ดินปัจจุบัน ({item.numberLand})</u> (ใหม่)</b></Row>
              <Row gutter={32} justify={'center'}>
                <Space>
                  <Row justify={'center'}>
                    {item?.img?.map((e, index) => {
                      var PreAaproveSplit = []
                      PreAaproveSplit = e.pathImage.split("/")
                      if (PreAaproveSplit[3] === "13") {
                        if (e.type === 1) {
                          return (
                            <div style={{ margin: 3 }}>
                              <Image width={"100px"} key={index} src={`${getImagessPre}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                            </div>
                          );
                        }
                      }
                      return null;
                    })}
                  </Row>
                </Space>
              </Row>
              <Divider />
            </>
            : null
        }
      </>
    )
  }
  const renderTitleDeed = ({ item, index }) => { //render Land Array
    return (
      <>
        <Row justify={'left'}><b><u>รูปโฉนด/หน้า-หลัง ({item.numberLand})</u></b></Row>
        <Row gutter={32} justify={'center'}>
          <Space direction='vertical'>
            <Row justify={'center'}>
              {item?.img?.map((e, index) => {
                var PreAaproveSplit = []
                PreAaproveSplit = e.pathImage.split("/")
                if (PreAaproveSplit[3] !== "13") {
                  if (e.type === 3) {
                    return (
                      <Row gutter={32} justify={'center'}>
                        <Col span={24} style={{ textAlign: 'center' }}>
                          <Image style={{ margin: 3 }} width={'100px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                        </Col>
                      </Row>
                    );
                  }
                }
                return null;
              })}
            </Row>
            {
              dataFromTable?.approvalStatus === "ขอเอกสารเพิ่ม" || dataFromTable?.approvalStatus === "ไม่อนุมัติ" ?
                <>
                  <div className='gutter-row' span={12}>
                    <b> <Form.Item label='เพิ่มรูป' name='imageIDGuCar'
                    >
                      <Upload
                        name='imageIDGuCar'
                        multiple={true}
                        listType="picture-card"
                        fileList={fileListDeep}
                        onPreview={handlePreview}
                        onChange={handleDeep}
                        beforeUpload={(file) => beforeUpts(file, 3, item.landId).then(() => false).catch(() => true)}
                      >
                        {fileListDeep?.length >= 10 ? null : (
                          <div>
                            <PlusOutlined />
                            <div className="ant-upload-text">upload</div>
                          </div>
                        )}
                      </Upload>
                      <Modal open={previewVisible} onCancel={handleCancelPreview}>
                        <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                      </Modal>
                    </Form.Item>
                    </b>
                  </div>
                </>
                : null
            }
          </Space>
        </Row>
        <Divider />
        {
          checkSendNEW ?
            <>
              <Row justify={'left'}><b><u>รูปโฉนด/หน้า-หลัง ({item.numberLand})</u> (ใหม่)</b></Row>
              <Row gutter={32} justify={'center'}>
                <Space>
                  <Row justify={'center'}>
                    {item?.img?.map((e, index) => {
                      var PreAaproveSplit = []
                      PreAaproveSplit = e.pathImage.split("/")
                      if (PreAaproveSplit[3] === "13") {
                        if (e.type === 3) {
                          return (
                            <Row gutter={32} justify={'center'}>
                              <Col span={24} style={{ textAlign: 'center' }}>
                                <Image style={{ margin: 3 }} width={'100px'} key={index} src={`${getImagessPre}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                              </Col>
                            </Row>
                          );
                        }
                      }
                      return null;
                    })}
                  </Row>
                </Space>
              </Row>
              <Divider />
            </>
            : null
        }
      </>
    )
  }
  const renderDivision = ({ item, index }) => { //render Land Array
    return (
      <>
        <Row justify={'left'}><b><u>ใบแบ่งใบต่อ ({item.numberLand})</u></b></Row>
        <Row gutter={32} justify={'center'}>
          <Space direction="vertical">
            <Row justify={'center'}>
              {item?.img?.map((e, index) => {
                var PreAaproveSplit = []
                PreAaproveSplit = e.pathImage.split("/")
                if (PreAaproveSplit[3] !== "13") {
                  if (e.type === 10) {
                    return (
                      <>
                        <Row gutter={32} justify={'center'}>
                          <Col span={24} style={{ textAlign: 'center' }}>
                            <Image style={{ margin: 3 }} width={'100px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                          </Col>
                        </Row>
                      </>
                    );
                  }
                }
                return null;
              })}
            </Row>
            {
              dataFromTable?.approvalStatus === "ขอเอกสารเพิ่ม" || dataFromTable?.approvalStatus === "ไม่อนุมัติ" ?
                <>
                  <div className='gutter-row' span={12}>
                    <b> <Form.Item label='เพิ่มรูป' name='imageIDGuCar'
                    >
                      <Upload
                        name='imageIDGuCar'
                        multiple={true}
                        listType="picture-card"
                        // fileList={fileListCustomerLeafDivider}
                        onPreview={handlePreview}
                        onChange={handleChangeCustomerLeafDivider}
                        // beforeUpload={() => false}
                        beforeUpload={(file) => beforeUpts(file, 10, item.landId).then(() => false).catch(() => true)}
                      >
                        <div>
                          <PlusOutlined />
                          <div className="ant-upload-text">upload</div>
                        </div>
                      </Upload>
                      <Modal open={previewVisible} onCancel={handleCancelPreview}>
                        <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                      </Modal>
                    </Form.Item>
                    </b>
                  </div>
                </>
                : null
            }
          </Space>
        </Row>
        <Divider />
        {
          checkSendNEW ?
            <>
              <Row justify={'left'}><b><u>ใบแบ่งใบต่อ ({item.numberLand})</u> (ใหม่)</b></Row>
              <Row gutter={32} justify={'center'}>
                <Space>
                  <Row justify={'center'}>
                    {item?.img?.map((e, index) => {
                      var PreAaproveSplit = []
                      PreAaproveSplit = e.pathImage.split("/")
                      if (PreAaproveSplit[3] === "13") {
                        if (e.type === 10) {
                          return (
                            <>
                              <Row gutter={32} justify={'center'}>
                                <Col span={24} style={{ textAlign: 'center' }}>
                                  <Image style={{ margin: 3 }} width={'100px'} key={index} src={`${getImagessPre}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                </Col>
                              </Row>
                            </>
                          );
                        }
                      }
                      return null;
                    })}
                  </Row>
                </Space>
              </Row>
              <Divider />
            </>
            : null
        }
      </>
    )
  }
  const renderLawang = ({ item, index }) => { //render Land Array
    return (
      <>
        <Row justify={'left'}><b><u>ระวาง ({item.numberLand})</u></b></Row>
        <Row gutter={32} justify={'center'}>
          <Space direction="vertical">
            <Row justify={'center'}>
              {item?.img?.map((e, index) => {
                var PreAaproveSplit = []
                PreAaproveSplit = e.pathImage.split("/")
                if (PreAaproveSplit[3] !== "13") {
                  if (e.type === 11) {
                    return (
                      <>
                        <Row gutter={32} justify={'center'}>
                          <Col span={24} style={{ textAlign: 'center' }}>
                            <Image style={{ margin: 3 }} width={'100px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                          </Col>
                        </Row>
                      </>
                    );
                  }
                }
                return null;
              })}
            </Row>
            {
              dataFromTable?.approvalStatus === "ขอเอกสารเพิ่ม" || dataFromTable?.approvalStatus === "ไม่อนุมัติ" ?
                <>
                  <div className='gutter-row' span={12}>
                    <b>
                      <Form.Item label='เพิ่มรูป' name='imageIDGuCar'>
                        <Upload
                          name='imageIDGuCar'
                          multiple={true}
                          listType="picture-card"
                          // fileList={fileListCustomerBurden}
                          onPreview={handlePreview}
                          onChange={handleChangeCustomerBurden}
                          beforeUpload={(file) => beforeUpts(file, 11, item.landId).then(() => false).catch(() => true)}
                        >
                          <div>
                            <PlusOutlined />
                            <div className="ant-upload-text">upload</div>
                          </div>
                        </Upload>
                        <Modal open={previewVisible} onCancel={handleCancelPreview}>
                          <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                        </Modal>
                      </Form.Item>
                    </b>
                  </div>
                </>
                : null
            }
          </Space>
        </Row>
        <Divider />
        {
          checkSendNEW ?
            <>
              <Row justify={'left'}><b><u>ระวาง ({item.numberLand})</u> (ใหม่)</b></Row>
              <Row gutter={32} justify={'center'}>
                <Space>
                  <Row justify={'center'}>
                    {item?.img?.map((e, index) => {
                      var PreAaproveSplit = []
                      PreAaproveSplit = e.pathImage.split("/")
                      if (PreAaproveSplit[3] === "13") {
                        if (e.type === 11) {
                          return (
                            <>
                              <Row gutter={32} justify={'center'}>
                                <Col span={24} style={{ textAlign: 'center' }}>
                                  <Image style={{ margin: 3 }} width={'100px'} key={index} src={`${getImagessPre}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                </Col>
                              </Row>
                            </>
                          );
                        }
                      }
                      return null;
                    })}
                  </Row>
                  {dataFromTable?.approvalStatus === "ขอเอกสารเพิ่ม" ?
                    <>
                      <div className='gutter-row' span={12}>
                        <b> <Form.Item label='เพิ่มรูป' name='imageIDGuCar'
                        >
                          <Upload
                            name='imageIDGuCar'
                            multiple={true}
                            listType="picture-card"
                            fileList={fileListCustomerBurden}
                            onPreview={handlePreview}
                            onChange={handleChangeCustomerBurden}
                            beforeUpload={() => false}
                          >
                            {/* {fileListCustomerBurden?.length >= 10 ? null : ( */}
                            <div>
                              <PlusOutlined />
                              <div className="ant-upload-text">upload</div>
                            </div>
                            {/* )} */}
                          </Upload>
                          <Modal open={previewVisible} onCancel={handleCancelPreview}>
                            <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                          </Modal>
                        </Form.Item>
                        </b>
                      </div>
                    </>
                    : null
                  }
                </Space>
              </Row>
              <Divider />
            </>
            : null
        }
      </>
    )
  }

  console.log("land", land)
  console.log("reSendData", reSendData)

  return (
    <Modal open={open} onOk={handleOk} onCancel={handleCancel} width={'1100px'}
      footer={[
        <Row justify={'center'}>
          <Space>
            <Button key="back" type='primary' danger onClick={handleCancel}>
              ปิด
            </Button>
            {
              checkApprove === "อนุมัติ" ?
                <>
                  {
                    oldData !== null ?
                      <>
                        <Button type='primary' onClick={ConfirmOK}>
                          ที่ดินนี้เคยเสนอแล้ว
                        </Button>
                      </>
                      :
                      <>
                        <Button
                          type='primary'
                          danger
                          onClick={showConfirmCancel}
                        >
                          ผู้กู้ไม่ยอมรับ
                        </Button>
                        <Button
                          type='primary'
                          style={{ backgroundColor: 'green' }}
                          onClick={showConfirmOK}
                        >
                          ผู้กู้ยอมรับ
                        </Button>
                      </>
                  }
                </>
                : null
            }
            {
              dataFromTable?.approvalStatus === "ขอเอกสารเพิ่ม" || dataFromTable?.approvalStatus === "ไม่อนุมัติ" ?
                <>
                  {
                    // checkImage?.length >= 1 ?
                    // allImg?.length >= 1 && loading === false ?
                    loading === false ?
                      <>
                        <Button type='primary' onClick={handleSave}>
                          บันทึกข้อมูล
                        </Button>
                      </> :
                      <>
                        <Button type='primary' disabled
                          style={{ color: 'success' }}
                        >
                          บันทึกข้อมูล
                        </Button>
                        {number === 1 ? <>
                          <Button type='primary' icon={<SendOutlined />} onClick={showConfirm}>
                            ส่งเคสอีกครั้ง
                          </Button>
                        </> : null}
                      </>
                  }

                  {number === 1 ? <>
                    <Button type='primary' icon={<SendOutlined />} onClick={showConfirm}>
                      ส่งเคสอีกครั้ง
                    </Button>
                  </> : null}
                </>
                : null
            }
          </Space>
        </Row>
      ]} >
      <Spin spinning={loading} size='large' tip=" Loading... ">
        <Row justify={'center'}>
          <Col className='gutter-row' span={24} style={{ fontSize: '25px', textAlign: 'center' }}>
            <b>รายละเอียด</b>
          </Col>
        </Row>
        <Row justify={'center'}>
          <Divider style={{ margin: 5 }} />
          <Form
            labelCol={{
              span: 12,
            }}
            wrapperCol={{
              span: 24,
            }}
            style={{
              width: '90%',
            }}
            form={form}
            autoComplete="off"
            initialValues={{ remember: true }}
          >
            <div className='main'>
              <Card style={{ width: '100%' }}>
                <Row justify={'center'}>
                  <Col span={12} style={{ textAlign: 'left' }}>
                    {reSendData?.lands?.landLoanDetailsRe?.productLoanLandType === "Pre-Aaprove-เปลี่ยนสัญญา" ?
                      <>
                        <div style={{ margin: 0 }}>
                          เลขสัญญา : <Tag color={color}>{reSendData?.lands?.ISSUNO}</Tag>
                        </div>
                        <div style={{ margin: 0 }}>
                          เกรด  : <Tag color={color}>{reSendData?.lands?.landLoanDetailsRe?.GRDCOD}</Tag>
                        </div>

                      </>
                      :
                      null
                    }

                  </Col>
                  <Col span={12} style={{ textAlign: 'right' }}>
                    <div style={{ margin: 0 }}>
                      สถานะ : <Tag color={color}>{dataLand?.approvalStatus}</Tag>
                    </div>
                    <div style={{ margin: 0 }}>
                      {/* วันที่ถูกเปลี่ยนสถานะ : {dayjs(dataLand?.LandDateStatus).format("DD-MM-YYYY")} */}
                    </div>
                  </Col>
                </Row>
                <Divider />

                {dataLand?.nationalId === "นิติบุคคล" ?
                  <>
                    <Row justify={'left'}><b><u>รายละเอียดผู้ขอกู้</u></b></Row>
                    <Row gutter={32} justify={'center'}>
                      <aside style={{ width: '90%' }}>
                        <div style={{ marginBottom: 0, }}>
                          <span>ประเภท : </span> <b>{dataLand?.nationalId}</b>
                        </div>
                        <div style={{ marginBottom: 0, }}>
                          <span>เลขประจำตัวผู้เสียภาษี : </span> <b>{dataLand.identificationId}</b>
                        </div>
                        <div style={{ marginBottom: 0, }}>
                          <span>
                            {dataLand?.snam} : </span> <b>{dataLand.firstname + ' ' + dataLand?.lastname}</b>
                        </div>
                        <div style={{ marginBottom: 0, }}>
                          <span>วันจดทะเบียน : </span> <b> {dataLand?.birthdate}</b>
                        </div>
                        <div style={{ marginBottom: 0, }}>
                          <b>
                            {
                              dataLand?.phones?.map((item, index) => {
                                return renderItemPhones({ item, index, key: `{ item.identification } - ${index}` })
                              })
                            }
                          </b>
                        </div>
                      </aside>
                    </Row>
                  </>
                  :
                  <>
                    <Row justify={'left'}><b><u>รายละเอียดผู้ขอกู้</u></b></Row>
                    <Row gutter={32} justify={'center'}>
                      <aside style={{ width: '90%' }}>
                        <div style={{ marginBottom: 0, }}>
                          <span>ชื่อ - นามสกุล : </span>
                          <b>{dataLand?.snam + '   ' + dataLand?.firstname + '   ' + dataLand?.lastname}</b>
                        </div>

                        <div style={{ marginBottom: 0, }}>
                          <span>ชื่อเล่น : </span>
                          <b>{dataLand?.nickname}</b>
                        </div>

                        <div style={{ marginBottom: 0, }}>
                          <span>เลขบัตรประชาชน : </span>
                          <b>{dataLand?.identificationId}</b>
                        </div>
                        <div style={{ marginBottom: 0, }}>
                          <b>
                            {
                              dataPhoneCus?.map((item, index) => {
                                return renderItemPhones({ item, index })
                              })
                            }
                          </b>
                        </div>
                        <div style={{ margin: 0, }}>
                          <span>เพศ : </span>
                          <b>{dataLand?.gender}</b>
                        </div>
                        <div style={{ marginBottom: 0, }}>
                          <span>{dataEmail === "" ? <> </> : <>{dataEmail?.emailData}</>}</span>
                          <b>{dataEmail?.emailData}</b>
                        </div>
                        <div style={{ marginBottom: 0, }}>
                          <span>{dataSocials?.socialType}  </span>
                          <b>{dataSocials?.socialData}</b>
                        </div>
                      </aside>
                    </Row>
                  </>
                }
                <Divider />
                {address?.length >= 1 ?
                  <>
                    <Row justify={'left'}>
                      <b><u>รายละเอียดที่อยู่</u></b>
                    </Row>
                    <Row gutter={32} justify={'center'}>
                      {
                        address?.map((item, index) => {
                          return renderItemAddress({ item, index })
                        })
                      }

                    </Row>
                  </>
                  : null
                }
                <Row justify={'left'}>
                  <b><u>อาชีพ - รายได้</u></b>
                </Row>
                {
                  career !== undefined || career?.length > 0 ?
                    <>
                      {career?.map((e, index) => {
                        return (
                          <Row gutter={32} justify={'center'}>
                            <aside style={{ width: '90%' }}>
                              <div style={{ marginBottom: 0, }}>
                                <span>กลุ่มอาชีพ : </span>
                                <b>{e?.desc}</b>
                              </div>
                              <div style={{ marginBottom: 0, }}>
                                <span>ชื่อบริษัท(สถานที่ทำงาน) : </span>
                                <b>{e?.companyName}</b>
                              </div>
                              <div style={{ marginBottom: 0, }}>
                                <span>อาชีพ  : </span>
                                <b>{e?.descSub}</b>
                              </div>
                              <div style={{ marginBottom: 0, }}>
                                <span>รายได้ / เดือน : </span>
                                <b>{currencyFormatOne(e?.incomeMonth)}</b>
                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                  บาท
                                </span>
                              </div>
                              <div style={{ marginBottom: 0, }}>
                                <span>สาขาอาชีพ : </span>
                                <b>{e?.descSection}</b>
                              </div>

                              <div style={{ marginBottom: 0, }}>
                                <span>
                                  รายจ่าย / เดือน : </span>
                                <b>{currencyFormatOne(e?.expensesMonth)}</b>
                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                  บาท
                                </span>
                              </div>
                            </aside>
                          </Row>
                        )
                      })}
                    </>
                    :
                    <Row gutter={32} justify={'center'}>
                      <div style={{ marginBottom: 0, }}>
                        <span>ไม่พบอาชีพ </span>
                      </div>
                    </Row>
                }
                <Divider />
                {dataFromTable?.productLoanLandType === 'ย้ายไฟแนนซ์' || dataFromTable?.productLoanLandType === 'Pre-Aaprove-ย้ายไฟแนนซ์' ?
                  <>
                    <Row justify={'left'}><b><u>รายละเอียดย้ายไฟแนนซ์เดิม</u></b></Row>
                    <Row gutter={32} justify={'center'}>
                      <aside style={{ width: '90%' }}>
                        <div
                          style={{ marginBottom: 0, textAlign: 'left' }}
                        // label='จากไฟแนนซ์'
                        >
                          <span>จากไฟแนนซ์ : </span>
                          <b>{land?.landOldLoanDetails?.issuno}</b>
                        </div>
                        <div style={{ marginBottom: 0 }}>
                          <span>ยอดปิดไฟแนนซ์ : </span>
                          <b>{currencyFormatOne(land?.landOldLoanDetails?.oldLandLoanAmount)}</b>
                          <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                            บาท
                          </span>
                        </div>
                        <div style={{ marginBottom: 0 }}>
                          <span>ค่างวด / เดือน : </span>
                          <b>{currencyFormatOne(land?.landOldLoanDetails?.oldLandMonthlyPayment)}</b>
                          <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                            บาท
                          </span>
                        </div>

                        <div style={{ marginBottom: 0 }}>
                          <span>จำนวนงวดที่ผ่อน : </span>
                          <b>{land?.landOldLoanDetails?.oldLandLoanTerm}</b>
                          <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                            งวด
                          </span>
                        </div>
                      </aside>
                    </Row>
                    <Divider />
                  </>
                  : null
                }

                <>
                  <Row justify={'left'}><b><u>รายละเอียด {dataFromTable?.productLoanLandType}</u></b></Row>
                  <Row gutter={32} justify={'center'}>
                    <aside style={{ width: '90%' }}>

                      <div style={{ marginBottom: 0, }}>
                        <span>ยอดจัด : </span>
                        <b>{currencyFormatOne(dataLand?.loanAmounttLand)}</b>
                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                          บาท
                        </span>
                      </div>
                      <div style={{ marginBottom: 0, }}>
                        <span>จำนวนงวด : </span>
                        <b>{dataLand?.loanLandTerm}</b>
                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                          งวด
                        </span>
                      </div>
                      <div style={{ marginBottom: 0, }}>
                        <span>งวดละ : </span>
                        <b>{currencyFormatOne(dataLand?.monthlyPaymentLand)}</b>
                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                          บาท
                        </span>
                      </div>
                      <div style={{ marginBottom: 0, }}>
                        <span>ดอกเบี้ย : </span>
                        <b>{currencyFormatOne(dataLand?.interesttLand)}</b>
                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                          บาท
                        </span>
                      </div>
                      <div style={{ marginBottom: 0, }}>
                        <span>ราคารวม : </span>
                        <b>{currencyFormatOne(~~dataLand?.loanAmounttLand + ~~dataLand?.interesttLand)}</b>
                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                          บาท
                        </span>
                      </div>
                      <div style={{ marginBottom: 0, }}>
                        <span>ผู้เสนอเคสเช่าซื้อ : </span>
                        <b>{dataLand?.proposalBy}</b>
                      </div>
                    </aside>
                  </Row>
                  <Divider />
                </>
                {
                  reSendData?.lands?.landLoanDetailsRe?.productLoanLandType === "Pre-Aaprove-เปลี่ยนสัญญา" ||
                    reSendData?.lands?.landLoanDetailsRe?.productLoanLandType === "เปลี่ยนสัญญา" ?
                    <>
                      <aside style={{ width: '100%', background: '#061178', padding: '8px', color: 'white' }} className='center'>
                        <div name='priceChange' style={{ marginBottom: 3 }}>
                          <span>ค่าเปลี่ยนสัญญา : </span> <b> {currencyFormatOne(reSendData?.lands?.landLoanDetailsRe?.priceChange)}</b> บาท
                        </div>
                        <div name='overdue' style={{ marginBottom: 3 }}>
                          <span>ยอดค้าง + เบี้ยปรับ : </span> <b> {currencyFormatOne(reSendData?.lands?.landLoanDetailsRe?.overdue)}</b>  บาท
                        </div>
                        <div name='preChange' style={{ marginBottom: 3 }}>
                          <span>ค่างวดล่วงหน้า : </span> <b> {currencyFormatOne(reSendData?.lands?.landLoanDetailsRe?.preChange)}</b>  บาท
                        </div><br />
                        <div>
                          <span>ยอดรวม : </span> <b> {currencyFormatOne(~~reSendData?.lands?.landLoanDetailsRe?.preChange + ~~reSendData?.lands?.landLoanDetailsRe?.overdue + ~~reSendData?.lands?.landLoanDetailsRe?.priceChange
                          )}</b>
                          <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                            บาท
                          </span>
                        </div>
                      </aside>
                      <Divider style={{ margin: 3 }} />
                    </>
                    :
                    null
                }
                {
                  land?.ISSUNO !== null ?
                    // reSendData?.lands?.ISSUNO !== null
                    <>
                      <Row justify={'left'}><b><u>รายละเอียดสัญญา ({isu})</u></b></Row>
                      <Row gutter={32} justify={'center'}>
                        <aside style={{ width: '90%' }}>
                          <div style={{ marginBottom: 0, }}>
                            <span>ค่างวด : </span>
                            <b>{currencyFormatOne(loanRe?.DAMT)} บาท</b>
                          </div>
                          <div style={{ marginBottom: 0, }}>
                            <span>ยอดจัดครั้งที่แล้ว : </span>
                            <b>{currencyFormatOne(loanRe?.tcshprc)} บาท</b>
                          </div>
                          <div style={{ marginBottom: 0, }}>
                            <span>ราคาเช่าซื้อครั้งที่แล้ว : </span>
                            <b>{currencyFormatOne(loanRe?.balanc)} บาท</b>
                          </div>
                          <div style={{ marginBottom: 0, }}>
                            <span>ยอดที่ชำระมาแล้วทั้งหมด : </span>
                            <b>{currencyFormatOne(loanRe?.totalpayment)} บาท</b>
                          </div>
                          <div style={{ marginBottom: 0, }}>
                            <span>ล/น คงเหลือรวม : </span>
                            <b>{currencyFormatOne(loanRe?.neekong)} บาท</b>
                          </div>
                          <div style={{ marginBottom: 0, }}>
                            <span>เบี้ยปรับ : </span>
                            <b>{currencyFormatOne(loanRe?.beeypup)} บาท</b>
                          </div>
                          <div style={{ marginBottom: 0, }}>
                            <span>ลูกหนี้อื่นๆ : </span>
                            <b>{currencyFormatOne(loanRe?.OTHR)} บาท</b>
                          </div>
                          <div style={{ marginBottom: 0, }}>
                            <span>จำนวนงวดที่ผ่อนมาแล้ว : </span>
                            <b>{currencyFormatOne(loanRe?.NOPAY)} งวด</b>
                          </div>
                          <div style={{ marginBottom: 0, }}>
                            <span>จำนวนงวดทั้งหมด : </span>
                            <b>{currencyFormatOne(loanRe?.nopays)} งวด</b>
                          </div>
                          <div style={{ marginBottom: 0, }}>
                            <span>ตัดสด งวดที่ : </span>
                            <b>{currencyFormatOne(loanRe?.reqNo)}</b>
                          </div>
                          <div style={{ marginBottom: 0, }}>
                            <span>ยอดรีไฟแนนซ์เพื่อจัดใหม่ : </span>
                            <b>{currencyFormatOne(loanRe?.re1)} บาท</b>
                          </div>
                        </aside>
                      </Row>
                      <Divider />
                    </>
                    : null
                }
                <>
                  <Col gutter={32} justify={'center'}>
                    {
                      newDataLand?.map((item, index) => {
                        return renderLandData({ item, index })
                      })
                    }
                  </Col>
                  <Divider />
                  {
                    dataLand?.parcellat ?
                      <>
                        <Row justify={'left'}><b><u>พิกัด</u></b></Row>
                        {
                          newDataLand?.map((item, index) => {
                            return renderCoordinates({ item, index })
                          })
                        }
                        <Divider />
                      </>
                      : null
                  }
                </>


                <Row justify={'left'}><b><u>ราคาประเมินที่ดิน</u></b></Row>
                <Row gutter={32} justify={'center'}>
                  {
                    newDataLand?.map((item, index) => {
                      return renderApproval({ item, index })
                    })
                  }
                </Row>
                <Divider />
                {
                  land?.approval?.mixNote === "" ||
                    land?.approval?.mixNote === undefined ||
                    land?.approval?.mixNote === null ?
                    null
                    :
                    <>
                      {
                        newDataLand?.map((item, index) => {
                          return renderMixNote({ item, index, key: `{item.identificationId} - ${index}` })
                        })
                      }
                    </>
                }
                {
                  dataApproval?.loanId > 0 || dataLand1?.approvedLoanAmount > 0 ?
                    <>
                      <Row justify={'left'}><b><u>ยอดที่อนุมัติ</u></b></Row>
                      <Row gutter={32} justify={'center'} style={{ backgroundColor: "lightgreen" }}>
                        <aside style={{ width: '90%' }}>
                          <div style={{ marginBottom: 0, }}>
                            <span>ยอดจัด : </span>
                            {
                              dataApproval?.apLoanAmount !== "0" ?
                                <b>{currencyFormatOne(dataApproval?.apLoanAmount)}</b>
                                :
                                <b>{currencyFormatOne(dataLand1?.approvedLoanAmount)}</b>
                            }
                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                              บาท
                            </span>
                          </div>
                          <div style={{ marginBottom: 0, }}>
                            <span>จำนวนงวด : </span>
                            {
                              dataApproval?.apLoanTerm !== "0" ?
                                <b>{dataApproval?.apLoanTerm}</b>
                                :
                                <b>{dataLand1?.approvedLoanTerm}</b>
                            }
                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                              งวด
                            </span>
                          </div>
                          <div style={{ marginBottom: 0, }}>
                            <span>งวดละ : </span>
                            {
                              dataApproval?.apMonthlyPayment !== "0" ?
                                <b>{currencyFormatOne(dataApproval?.apMonthlyPayment)}</b>
                                :
                                <b>{currencyFormatOne(loanTermDATA)}</b>
                            }
                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                              บาท
                            </span>
                          </div>
                          <div style={{ marginBottom: 0, }}>
                            <span>ดอกเบี้ย : </span>
                            {
                              dataApproval?.apInterest !== "0" ?
                                <b>{currencyFormatOne(dataApproval?.apInterest)}</b>
                                :
                                <b>{currencyFormatOne(resultRateDATA)}</b>
                            }
                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                              บาท
                            </span>
                          </div>
                          <div style={{ marginBottom: 0, }}>
                            <span>ราคารวม : </span>
                            {
                              dataApproval?.apInstallmentWithInterest !== "0" ?
                                <b>{currencyFormatOne(dataApproval?.apInstallmentWithInterest)}</b>
                                :
                                <b>{currencyFormatOne(resultPriceDATA)}</b>
                            }
                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                              บาท
                            </span>
                          </div>
                          <div style={{ marginBottom: 0, }}>
                            <span>ผู้อนุมัติเคส : </span>
                            <b>{dataLand1?.approvedBy}</b>
                          </div>
                        </aside>
                      </Row>
                      <Divider />
                    </>
                    : null
                }

                <Row justify={'left'}><Col span={24}><b><u>การค้ำโอน</u></b></Col></Row>
                <Row justify={'center'}>
                  <Checkbox
                    style={{ fontSize: '20px' }}
                    checked={dataLand1?.checkGua === "ค้ำ-ไม่โอน" ? true : false}
                  >ค้ำ-ไม่โอน
                  </Checkbox>
                  <Checkbox
                    style={{ fontSize: '20px' }}
                    checked={dataLand1?.checkGua === "ค้ำ-โอน" ? true : false}
                  >ค้ำ-โอน
                  </Checkbox>
                  <Checkbox
                    style={{ fontSize: '20px' }}
                    checked={dataLand1?.checkGua === "ไม่ค้ำ-ไม่โอน" ? true : false}
                  >ไม่ค้ำ-ไม่โอน
                  </Checkbox>
                  <Checkbox
                    style={{ fontSize: '20px' }}
                    checked={dataLand1?.checkGua === "ไม่ค้ำ-โอน" ? true : false}
                  >ไม่ค้ำ-โอน
                  </Checkbox>
                </Row>
                <Divider />
                {guarantor1?.length >= 1 ?
                  <>
                    <Row justify={'left'}>
                      <b><u>รายละเอียดคนค้ำ</u></b>
                    </Row>
                    {
                      guarantor1?.map((item, index) => {
                        return renderGuarantorNewImg({ item, index })
                      })
                    }
                  </>
                  : null
                }
                <Row justify={'left'}><Col span={24}><b><u>หมายเหตุ</u></b></Col></Row>
                <Row gutter={32} justify={'center'}>
                  {des?.length >= 1 ?
                    des?.map((item, index) => {
                      if (item.note_by !== null) {
                        return renderDataNote({ item, index });
                      } else {
                        return <>* ยังไม่เพิ่มหมายเหตุ!</>
                      }
                    })
                    : <>* ยังไม่เพิ่มหมายเหตุ!</>
                  }
                </Row>
                <Divider />
                {noteRe?.length >= 1 ?
                  <>
                    <Row justify={'left'}><Col span={24}><b><u>หมายเหตุ รี/ปรับ</u></b></Col></Row>
                    <Row gutter={32} justify={'center'}>
                      {
                        noteRe?.map((item, index) => {
                          if (item.note_by !== null) {
                            return renderDataNote({ item, index });
                          } else {
                            return <>* ยังไม่เพิ่มหมายเหตุ!</>
                          }
                        })
                      }
                    </Row>
                    <Divider />
                  </>
                  : null
                }
                {
                  reSendData?.lands?.landLoanDetails?.approvalDescription !== "" && reSendData?.lands?.landLoanDetails?.approvalDescription !== null && reSendData?.lands?.landLoanDetails?.approvalDescription !== undefined ?
                    <>
                      <Row justify={'left'}><Col span={24}><b><u>หมายเหตุเงื่อนไขการอนุมัติ</u></b></Col></Row>
                      <Row gutter={32} justify={'center'}>
                        <div style={{ marginBottom: 0, }}>
                          <span>เงื่อนไขการอนุมัติ : </span>
                          <b>{reSendData?.lands.landLoanDetails?.approvalDescription}</b>
                        </div>
                      </Row>
                      <Divider />
                    </>
                    : null
                }
                <Row justify={'left'}><b><u>รูปบัตรประชาชนผู้ขอกู้</u></b></Row>
                <Row gutter={32} justify={'center'}>
                  <Space direction="vertical" >
                    <Row justify={'center'}>
                      {imageBlobzz?.map((e, index) => {
                        var PreAaproveSplit = []
                        PreAaproveSplit = e.pathImage.split("/")
                        if (PreAaproveSplit[3] !== "13") {
                          if (e.type === 4) {
                            return (
                              <>
                                <Row gutter={32} justify={'center'}>
                                  <Col span={24} style={{ textAlign: 'center' }}>
                                    <Image width={'200px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                  </Col>
                                </Row>
                              </>
                            );
                          }
                        }
                        return null;
                      })}
                    </Row>
                    {dataFromTable?.approvalStatus === "ขอเอกสารเพิ่ม" || dataFromTable?.approvalStatus === "ไม่อนุมัติ" ?
                      //{dataFromTable?.approvalStatus === "อนุมัติ" || reSendData?.landLoanDetails?.approvalConditions === true ?
                      <>
                        <div className='gutter-row' span={12}>
                          <b> <Form.Item label='เพิ่มรูป' name='imageIDGuCar'
                          >
                            <Upload
                              name='imageIDGuCar'
                              multiple={true}
                              listType="picture-card"
                              fileList={fileListCustomer}
                              onPreview={handlePreview}
                              onChange={handleCustomerImage}
                              //beforeUpload={() => false}
                              beforeUpload={(file) => beforeUpts(file, 4, dataFromTable.landId).then(() => false).catch(() => true)}
                            >
                              {fileListCustomer?.length >= 5 ? null : (
                                <div>
                                  <PlusOutlined />
                                  <div className="ant-upload-text">upload</div>
                                </div>
                              )}
                            </Upload>
                            <Modal open={previewVisible} onCancel={handleCancelPreview}>
                              <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                            </Modal>
                          </Form.Item>
                          </b>
                        </div>
                      </>
                      :
                      null
                    }
                  </Space>
                </Row>
                <Divider />
                {
                  checkSendNEW ?
                    <>
                      <Row justify={'left'}><b><u>รูปบัตรประชาชนผู้ขอกู้</u> (ใหม่)</b></Row>
                      <Row gutter={32} justify={'center'}>
                        <Space direction="vertical" >
                          <Row justify={'center'}>
                            {imageBlobzz?.map((e, index) => {
                              var PreAaproveSplit = []
                              PreAaproveSplit = e.pathImage.split("/")
                              if (PreAaproveSplit[3] === "13") {
                                if (e.type === 4) {
                                  return (
                                    <>
                                      <Row gutter={32} justify={'center'}>
                                        <Col span={24} style={{ textAlign: 'center' }}>
                                          <Image width={'200px'} key={index} src={`${getImagessPre}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                        </Col>
                                      </Row>
                                    </>
                                  );
                                }
                              }
                              return null;
                            })}
                          </Row>
                        </Space>
                      </Row>
                      <Divider />
                    </>
                    : null
                }
                {
                  newDataLand?.map((item, index) => {
                    return renderLandBuildings({ item, index })
                  })
                }
                {
                  newDataLand?.map((item, index) => {
                    return renderTitleDeed({ item, index })
                  })
                }
                {
                  newDataLand?.map((item, index) => {
                    return renderDivision({ item, index })
                  })
                }
                {
                  newDataLand?.map((item, index) => {
                    return renderLawang({ item, index })
                  })
                }
                <Row justify={'left'}><b><u>ประวัติคนกู้ / ทะเบียนบ้าน / อาชีพ</u></b></Row>
                <Row gutter={32} justify={'center'}>
                  <Space direction="vertical" >
                    <Row justify={'center'}>
                      {imageBlobzz?.map((e, index) => {
                        var PreAaproveSplit = []
                        PreAaproveSplit = e.pathImage.split("/")
                        if (PreAaproveSplit[3] !== "13") {
                          if (e.type === 6) {
                            return (
                              <>
                                <Row gutter={32} justify={'center'}>
                                  <Col span={24} style={{ textAlign: 'center' }}>
                                    <Image width={'200px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                  </Col>

                                </Row>
                              </>

                            );
                          }
                        }
                        return null;
                      })}
                    </Row>
                    {
                      dataFromTable?.approvalStatus === "ขอเอกสารเพิ่ม" || dataFromTable?.approvalStatus === "ไม่อนุมัติ" ?
                        <>
                          <div className='gutter-row' span={12}>
                            <b> <Form.Item label='เพิ่มรูป' name='imageIDGuCar'
                            >

                              <Upload
                                name='imageIDGuCar'
                                multiple={true}
                                listType="picture-card"
                                fileList={fileListCustomerImageCareer}
                                onPreview={handlePreview}
                                onChange={handleCustomerImageCareer}
                                beforeUpload={(file) => beforeUpts(file, 6, dataFromTable.landId).then(() => false).catch(() => true)}
                              >
                                {fileListCustomerImageCareer?.length >= 20 ? null : (
                                  <div>
                                    <PlusOutlined />
                                    <div className="ant-upload-text">upload</div>
                                  </div>
                                )}
                              </Upload>
                              <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                              </Modal>
                            </Form.Item>
                            </b>
                          </div>
                        </>
                        : null
                    }
                  </Space>
                </Row>
                <Divider />
                {
                  checkSendNEW ?
                    <>
                      <Row justify={'left'}><b><u>ประวัติคนกู้ / ทะเบียนบ้าน / อาชีพ</u> (ใหม่)</b></Row>
                      <Row gutter={32} justify={'center'}>
                        <Space direction="vertical" >
                          <Row justify={'center'}>
                            {imageBlobzz?.map((e, index) => {
                              var PreAaproveSplit = []
                              PreAaproveSplit = e.pathImage.split("/")
                              if (PreAaproveSplit[3] === "13") {
                                if (e.type === 6) {
                                  return (
                                    <>
                                      <Row gutter={32} justify={'center'}>
                                        <Col span={24} style={{ textAlign: 'center' }}>
                                          <Image width={'200px'} key={index} src={`${getImagessPre}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                        </Col>

                                      </Row>
                                    </>

                                  );
                                }
                              }
                              return null;
                            })}
                          </Row>
                        </Space>
                      </Row>
                      <Divider />
                    </>
                    : null
                }
                <Row justify={'left'}><b><u>รูปสเตทเมนต์ผู้กู้</u></b></Row>
                <Row gutter={32} justify={'center'}>
                  <Space direction="vertical" >
                    <Row justify={'center'}>
                      {imageBlobzz?.map((e, index) => {
                        var PreAaproveSplit = []
                        PreAaproveSplit = e.pathImage.split("/")
                        if (PreAaproveSplit[3] !== "13") {
                          if (e.type === 8) {
                            return (
                              <>
                                <Row gutter={32} justify={'center'}>
                                  <Col span={24} style={{ textAlign: 'center' }}>
                                    <Image width={'200px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                  </Col>

                                </Row>
                              </>

                            );
                          }
                        }
                        return null;
                      })}
                    </Row>
                    {
                      dataFromTable?.approvalStatus === "ขอเอกสารเพิ่ม" || dataFromTable?.approvalStatus === "ไม่อนุมัติ" ?
                        <>
                          <div className='gutter-row' span={12}>
                            <b>
                              <Form.Item label='เพิ่มรูป' name='imageIDGuCar'>
                                <Upload
                                  name='imageIDGuCar'
                                  multiple={true}
                                  listType="picture-card"
                                  fileList={fileListStatement}
                                  onPreview={handlePreview}
                                  onChange={handleStatement}
                                  beforeUpload={(file) => beforeUpts(file, 8, dataFromTable.landId).then(() => false).catch(() => true)}
                                >
                                  {fileListStatement?.length >= 15 ? null : (
                                    <div>
                                      <PlusOutlined />
                                      <div className="ant-upload-text">upload</div>
                                    </div>
                                  )}
                                </Upload>
                                <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                  <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                </Modal>
                              </Form.Item>
                            </b>
                          </div>
                        </>
                        : null
                    }
                  </Space>
                </Row>
                <Divider />
                {
                  checkSendNEW ?
                    <>
                      <Row justify={'left'}><b><u>รูปสเตทเมนต์ผู้กู้</u> (ใหม่)</b></Row>
                      <Row gutter={32} justify={'center'}>
                        <Space direction="vertical" >
                          <Row justify={'center'}>
                            {imageBlobzz?.map((e, index) => {
                              var PreAaproveSplit = []
                              PreAaproveSplit = e.pathImage.split("/")
                              if (PreAaproveSplit[3] === "13") {
                                if (e.type === 8) {
                                  return (
                                    <>
                                      <Row gutter={32} justify={'center'}>
                                        <Col span={24} style={{ textAlign: 'center' }}>
                                          <Image width={'200px'} key={index} src={`${getImagessPre}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                        </Col>
                                      </Row>
                                    </>
                                  );
                                }
                              }
                              return null;
                            })}
                          </Row>
                        </Space>
                      </Row>
                      <Divider />
                    </>
                    : null
                }
                <Row justify={'left'}><b><u>รูปสเตทเมนต์ผู้กู้ PDF</u></b></Row>
                <Row gutter={32} justify={'center'}>
                  <Space direction="vertical" >
                    <Row justify={'center'}>
                      {imageBlobzz?.map((e, index) => {
                        var PreAaproveSplit = []
                        PreAaproveSplit = e.pathImage.split("/")
                        if (PreAaproveSplit[3] !== "13") {
                          if (e.type === 7) {
                            return (
                              <>
                                <Row gutter={32} justify={'center'}>
                                  <Col span={24} style={{ textAlign: 'center' }}>
                                    <Space direction='vertical'>
                                      <object data={`${getImagess}/${e.pathImage}?token=${token}`} type="application/pdf" width="350px" height="450px">
                                        <p>Alternative text - include a link <a href={`${getImagess}/${e.pathImage}?token=${token}`}>to the PDF!</a></p>
                                      </object>
                                      <p></p>
                                    </Space>
                                  </Col>
                                </Row>
                              </>
                            )
                          }
                        }
                        return null;
                      })}
                    </Row>
                    {
                      dataFromTable?.approvalStatus === "ขอเอกสารเพิ่ม" || dataFromTable?.approvalStatus === "ไม่อนุมัติ" ?
                        <>
                          <div className='gutter-row' span={12}>
                            <b>
                              <Form.Item label='เพิ่มรูป' name='imageIDGuCar'>
                                <Upload
                                  accept="application/pdf"
                                  name='imageIDGuCar'
                                  multiple={true}
                                  listType="picture-card"
                                  fileList={fileListStatementPDF}
                                  onPreview={handlePreview}
                                  onChange={handleStatementPDF}
                                  beforeUpload={(file) => beforeUpts(file, 7, dataFromTable.landId).then(() => false).catch(() => true)}
                                >
                                  {fileListStatementPDF?.length >= 7 ? null : (
                                    <div>
                                      <PlusOutlined />
                                      <div className="ant-upload-text">upload</div>
                                    </div>
                                  )}
                                </Upload>
                                <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                  <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                </Modal>
                              </Form.Item>
                            </b>
                          </div>
                        </>
                        : null
                    }
                  </Space>
                </Row>
                <Divider />
                {
                  checkSendNEW ?
                    <>
                      <Row justify={'left'}><b><u>รูปสเตทเมนต์ผู้กู้ PDF</u> (ใหม่)</b></Row>
                      <Row gutter={32} justify={'center'}>
                        <Space direction="vertical" >
                          <Row justify={'center'}>
                            {imageBlobzz?.map((e, index) => {
                              var PreAaproveSplit = []
                              PreAaproveSplit = e.pathImage.split("/")
                              if (PreAaproveSplit[3] === "13") {
                                if (e.type === 7) {
                                  return (
                                    <>
                                      <Row gutter={32} justify={'center'}>
                                        <Col span={24} style={{ textAlign: 'center' }}>
                                          <Space direction='vertical'>
                                            <object data={`${getImagessPre}/${e.pathImage}?token=${token}`} type="application/pdf" width="350px" height="450px">
                                              <p>Alternative text - include a link <a href={`${getImagessPre}/${e.pathImage}?token=${token}`}>to the PDF!</a></p>
                                            </object>
                                            <p></p>
                                          </Space>
                                        </Col>
                                      </Row>
                                    </>
                                  )
                                }
                              }
                              return null;
                            })}
                          </Row>
                        </Space>
                      </Row>
                      <Divider />
                    </>
                    : null
                }

                <Row justify={'left'}><b><u>เอกสารเพิ่มเติม</u></b></Row>
                <Row gutter={32} justify={'center'}>
                  <Space direction="vertical" >
                    <Row justify={'center'}>
                      {imageBlobzz?.map((e, index) => {
                        var PreAaproveSplit = []
                        PreAaproveSplit = e.pathImage.split("/")
                        if (PreAaproveSplit[3] !== "13") {
                          if (e.type === 12) {
                            return (
                              <>
                                <Row gutter={32} justify={'center'}>
                                  <Col span={24} style={{ textAlign: 'center' }}>
                                    <Image width={'200px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                  </Col>

                                </Row>
                              </>

                            );
                          }
                        }
                        return null;
                      })}
                    </Row>
                    {
                      dataFromTable?.approvalStatus === "ขอเอกสารเพิ่ม" || dataFromTable?.approvalStatus === "ไม่อนุมัติ" ?
                        <>
                          <div className='gutter-row' span={12}>
                            <b>
                              <Form.Item label='เพิ่มรูป' name='imageIDGuCar'>
                                <Upload
                                  name='imageIDGuCar'
                                  multiple={true}
                                  listType="picture-card"
                                  fileList={fileListOtherMent}
                                  onPreview={handlePreview}
                                  onChange={handleOtherMent}
                                  beforeUpload={(file) => beforeUpts(file, 12, dataFromTable.landId).then(() => false).catch(() => true)}
                                >
                                  {fileListOtherMent?.length >= 20 ? null : (
                                    <div>
                                      <PlusOutlined />
                                      <div className="ant-upload-text">upload</div>
                                    </div>
                                  )}
                                </Upload>
                                <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                  <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                </Modal>
                              </Form.Item>
                            </b>
                          </div>
                        </>
                        : null
                    }
                  </Space>
                </Row>
                {
                  checkSendNEW ?
                    <>
                      <Divider />
                      <Row justify={'left'}><b><u>เอกสารเพิ่มเติม</u></b></Row>
                      <Row gutter={32} justify={'center'}>
                        <Space direction="vertical" >
                          <Row justify={'center'}>
                            {imageBlobzz?.map((e, index) => {
                              var PreAaproveSplit = []
                              PreAaproveSplit = e.pathImage.split("/")
                              if (PreAaproveSplit[3] === "13") {
                                if (e.type === 12) {
                                  return (
                                    <>
                                      <Row gutter={32} justify={'center'}>
                                        <Col span={24} style={{ textAlign: 'center' }}>
                                          <Image width={'200px'} key={index} src={`${getImagessPre}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                        </Col>

                                      </Row>
                                    </>

                                  );
                                }
                              }
                              return null;
                            })}
                          </Row>
                        </Space>
                      </Row>
                    </>
                    : null
                }
                <Divider />
                <Row justify={'left'}><b><u>เอกสารขอรี/ปรับ/เปลี่ยน</u></b></Row>
                <Row gutter={32} justify={'center'}>
                  <Space direction="vertical" >
                    <Row justify={'center'}>
                      {imageBlobzz?.map((e, index) => {
                        var PreAaproveSplit = []
                        PreAaproveSplit = e.pathImage.split("/")
                        if (PreAaproveSplit[3] !== "13") {
                          if (e.type === 14) {
                            return (
                              <Row gutter={32} justify={'center'}>
                                <Col span={24} style={{ textAlign: 'center' }}>
                                  <Image width={'100px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                </Col>
                              </Row>
                            );
                          }
                        }
                        return null;
                      })}
                    </Row>
                  </Space>
                </Row>
              </Card>
              {contextHolder}
            </div>
          </Form>
        </Row>
      </Spin>
      {
        modalConfirm ?
          <ModalCusConfirm open={modalConfirm} close={setModalConfirm} closeAll={close} dataFromTable={dataFromTable} oldData={oldData} />
          : null
      }
    </Modal >
  )
}; 
