import React, { useState, useEffect } from "react";
import { PlusCircleFilled, HomeFilled, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Table, Spin, Tag, Space, Form, Card, Radio, Modal, Statistic, notification, Typography, DatePicker, Select } from 'antd';
import { useDispatch } from 'react-redux';
import axios from "axios";
import dayjs from "dayjs";
import '../css/Media.css'
import { columns } from './columns/columns';
import { checkcountcar, findcase1 } from "../file_mid/all_api";
const { Countdown } = Statistic;

export default function Main() {
  const [isLoading, setIsLoading] = useState(false);
  const currentDate = new Date(); // สร้างอ็อบเจ็กต์ Date สำหรับวันที่ปัจจุบัน
  const year = currentDate.getFullYear(); // ดึงปีปัจจุบัน
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // ดึงเดือนและจัดรูปแบบให้มี 2 หลัก
  const day = String(currentDate.getDate()).padStart(2, '0'); // ดึงวันและจัดรูปแบบให้มี 2 หลัก
  const formattedDate = `${year}-${month}-${day}`;
  const currentDateTime = dayjs()

  const user = localStorage.getItem('username');
  const [dataPost, setDataPost] = useState({ sec: 0, days1: formattedDate, days2: formattedDate })
  const [isModalEditer, setIsModalEditer] = useState(false);
  const [namead, setNamead] = useState([]);
  const [select_op, setSelect_op] = useState([]);
  const [selectedValue, setSelectedValue] = useState();
  const [arrayTable, setArrayTable] = useState();
  const [data, setData] = useState([]);
  const [valueselect, setValueselect] = useState("ทั้งหมด");
  const [valueselect1, setValueselect1] = useState("ทั้งหมด");
  const [defu, setDefu] = useState("ทั้งหมด");
  const [cData, setCData] = useState([]);
  const [keyWord, setKeyWord] = useState("ทั้งหมด");
  // const { confirm } = Modal;
  const [loading, setLoading] = useState(false)
  const token = localStorage.getItem('token')

  useEffect(() => {
    loadData()
    loadCountData()
  }, [keyWord, isModalEditer])

  // console.log("loading", loading)
  //console.log("data", data)
  // console.log("axiosData", axiosData)
  // console.log("keyWord", keyWord)

  const loadCountData = async () => {
    setLoading(true)
    const tk = JSON.parse(token)
    const headers = {
      "Authorization": `Bearer ${tk}`,
      "Menu": JSON.stringify("3")
    }
    var mainData = { proposalBy: user, approvalStatus: keyWord }
    await axios.post(checkcountcar, mainData, { headers: headers })
      .then((res) => {
        console.log("res.data", res.data)
        const initialValue = 0;
        const sumWithInitial = res.data.reduce(
          (accumulator, currentValue) => accumulator + currentValue.A,
          initialValue
        );
        console.log("sumWithInitial", sumWithInitial)
        res.data.push({ ApprovalStatus: 'ทั้งหมด', A: sumWithInitial })
        setCData(res.data)
        setLoading(false)
      }).catch((err) => console.log(err))
    setLoading(false)
  }

  const loadData = async () => {
    setLoading(true)
    setDefu({ ...namead, label: 'ทั้งหมด' })
    const tk = JSON.parse(token)
    const headers = {
      "Authorization": `Bearer ${tk}`,
      "Menu": JSON.stringify("3")
    }
    var mainData = {
      "startdate": dataPost.days1,
      "enddate": dataPost.days2,
      "status": "2023-01-12",
      "ad": "2023-01-12"
    }
    await axios.post(findcase1, mainData, { headers: headers })
      .then((res) => {
        console.log("Res", res.data)
        setArrayTable(res.data)
        // setAxiosData(res.data)
        setData(res.data)
        const resultArray = [{ label: 'ทั้งหมด', value: 'ทั้งหมด' }];
        const resultArray1 = [{ label1: 'ทั้งหมด', label: `${'ทั้งหมด'} (${res.data.length || 0})`, value: 'ทั้งหมด' }];
        setSelectedValue({ label1: 'ทั้งหมด', label: `${'ทั้งหมด'} (${res.data.length || 0})`, value: 'ทั้งหมด' })
        // นับจำนวนของแต่ละสถานะ
        const countByApprovalStatus = {};

        res.data.forEach(item => {
          if (!countByApprovalStatus[item.approvalStatus]) {
            countByApprovalStatus[item.approvalStatus] = 1;
          } else {
            countByApprovalStatus[item.approvalStatus]++;
          }
        });

        console.log("countByApprovalStatus", countByApprovalStatus);


        res.data.forEach(item => {
          // ถ้า item.reviewedBy หรือ item.approvalStatus ไม่ได้ถูกเพิ่มลงใน resultArray แล้ว
          if (!resultArray.some(resultItem => resultItem.label === item.reviewedBy)) {
            resultArray.push({ label: item.reviewedBy, value: item.reviewedBy });
          }

          if (!resultArray1.some(resultItem => resultItem.label1 === item.approvalStatus)) {
            resultArray1.push({ label1: item.approvalStatus, label: `${item.approvalStatus} (${countByApprovalStatus[item.approvalStatus]})`, value: item.approvalStatus });
            console.log("aa", resultArray1)
          }
        });
        // console.log("resultArray", resultArray); //รหัสพนักงาน
        // console.log("resultArray1", resultArray1); //สถานะ

        setNamead(resultArray)
        setSelect_op(resultArray1)
        setLoading(false)
      }).catch((err) => console.log(err))
    setLoading(false)
  }

  // const search = (data) => {
  //   const greaterThanTen = axiosData.filter(
  //     (item) =>
  //       item.firstName.toLowerCase().includes(data.target.value) ||
  //       item.carPlateNumber.toLowerCase().includes(data.target.value)
  //   );
  //   setArrayTable(greaterThanTen)
  // };



  // const countMap = {};
  // data.forEach((item) => {
  //   const reviewedBy = item.reviewedBy;
  //   // ทำการนับจำนวนและบวกเพิ่มเรื่อย ๆ
  //   countMap[reviewedBy] = (countMap[reviewedBy] || 0) + 1;
  // });
  // console.log("countMap", countMap);

  // const totalReviews = Object.values(countMap).reduce((acc, count) => acc + count, 0);
  // console.log("Total Reviews:", totalReviews);


  const nameAd = namead //รหัสธุรการ
  console.log("nameAd", nameAd)
  const backc = {
    "น้ำ(อารยา)": '#e6f4ff',
    "น้ำ 3": '#fffbe6',
    "เหมย": '#fffbe6',
    "หยก": '#fff7e6',
    "ออรี่": '#e6fffb',
    "ชมพู่": '#e6fffb',
    "เอิร์น": '#f0f5ff',
  }
  const textc = {
    "MIT00034": '#1677ff',
    "MIT00026": '#e39f23',
    "MIT00092": '#e39f23',
    "MIT00075": '#d86d38',
    "MIT00109": '#3fbccf',
    "MIT00115": '#384ecb',
    "MIT00117": '#afc222',

  }


  const currencyFormatOne = (amount) => {
    return Number(amount)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
  }

  const handleChange1 = (value) => { // รหัสพนักงาน
    //valueselect1 คือ รหัสพนักงาน
    //valueselect คือ สถานะ
    // console.log("value", value)
    // console.log("valueselect", valueselect)
    // console.log("valueselect1", valueselect1)
    // console.log("nameAd", nameAd)
    // console.log("data", data)
    // if () { 

    // }

    setValueselect1(value)
    let filteredData;

    if (value === 'ทั้งหมด' & valueselect === 'ทั้งหมด') {
      filteredData = data

    } else if (value === 'ทั้งหมด') { //ใช้งานได้
      filteredData = data

      // } else if (value === 'ทั้งหมด' & valueselect === valueselect) { //ทดสอบอยู่
      //   filteredData = data.filter(
      //     (item) =>
      //       setValueselect(item.approvalStatus)
      //   );

    } else if (value === value) {
      filteredData = data.filter(
        (item) =>
          item.reviewedBy === value,
      );

    } else if (valueselect === "ทั้งหมด") {
      filteredData = data.filter(
        (item) =>
          item.reviewedBy === value
      );
    }
    else {
      filteredData = data.filter(
        (item) =>
          item.reviewedBy === value &&
          item.approvalStatus === valueselect

      );

    }
    setArrayTable(filteredData)
    // setArrayTable(filteredData)
    const countByApprovalStatus = {};
    const resultArray = [{ label: 'ทั้งหมด', value: 'ทั้งหมด' }];
    const resultArray1 = [{ label1: 'ทั้งหมด', label: `${'ทั้งหมด'} (${filteredData.length || 0})`, value: 'ทั้งหมด' }];
    setSelectedValue({ label1: 'ทั้งหมด', label: `${'ทั้งหมด'} (${filteredData.length || 0})`, value: 'ทั้งหมด' })
    filteredData.forEach(item => {
      if (!countByApprovalStatus[item.approvalStatus]) {
        countByApprovalStatus[item.approvalStatus] = 1;
      } else {
        countByApprovalStatus[item.approvalStatus]++;
      }
    });
    data.forEach(item => {
      if (!resultArray.some(resultItem => resultItem.label === item.reviewedBy)) {
        resultArray.push({ label: item.reviewedBy, value: item.reviewedBy });
        console.log("!!", resultArray)
      }
    });

    filteredData.forEach(item => {
      if (!resultArray1.some(resultItem => resultItem.label1 === item.approvalStatus)) {
        resultArray1.push({ label1: item.approvalStatus, label: `${item.approvalStatus} (${countByApprovalStatus[item.approvalStatus] || 0})`, value: item.approvalStatus });
      }
    });

    setNamead(resultArray)
    setSelect_op(resultArray1)

  };

  const handleChange = (value) => { //สถานะ
    //valueselect1 คือ รหัสพนักงาน
    //valueselect คือ สถานะ
    setValueselect(value)
    setSelectedValue(value)
    let filteredData;

    if (value === 'ทั้งหมด' && valueselect1 === 'ทั้งหมด') {
      filteredData = data
    } else if (valueselect1 === 'ทั้งหมด') {
      filteredData = data.filter(
        (item) =>
          item.approvalStatus === value

      );
    } else if (valueselect1 === 'ทั้งหมด' && value === valueselect) {
      filteredData = data

    } else {
      filteredData = data.filter(
        (item) =>
          // item.reviewedBy === valueselect1 && item.approvalStatus === value
          item.reviewedBy === valueselect1 &&
          item.approvalStatus === value

      );
    }
    // console.log("sdf", filteredData, valueselect1, valueselect, value, 3);
    setArrayTable(filteredData)

  };

  const handle_button_Change = async () => {
    await loadData()
    setValueselect1("ทั้งหมด")
  };

  return <>
    <Card>
      <Row gutter={32} style={{ textAlign: 'center' }}>
        <Col>
          <Spin spinning={loading} size='large' tip=" Loading... ">
            <div className='text-center'>
              <h2>รายงาน</h2>
            </div>
            <Row gutter={24} justify={'center'} style={{ margin: 10 }}>
              <Col className='gutter-row' span={24} style={{ textAlign: 'center' }}>
                <Space>
                  <>{"เลือกวันที่ต้องการแสดงเคส :"}</>
                  <DatePicker format={'YYYY-MM-DD'}
                    defaultValue={currentDateTime}
                    style={{ height: '40px' }}
                    onChange={(e) => {
                      if (e) {
                        setDataPost({ ...dataPost, days1: e.format('YYYY-MM-DD') })
                      }
                    }} />
                  <>{"ถึง"}</>
                  <DatePicker format={'YYYY-MM-DD'}
                    defaultValue={currentDateTime}
                    style={{ height: '40px' }}
                    onChange={(e) => {
                      if (e) {
                        setDataPost({ ...dataPost, days2: e.format('YYYY-MM-DD') })
                      }
                    }} />
                  <Button type="primary"
                    icon={<SearchOutlined />}
                    disabled={isLoading}
                    onClick={handle_button_Change}>
                    ค้นหา
                  </Button>
                  {/* <span style={{ display: 'inline-block' }}>
                      <Button type="primary" onClick={(e) => { handleYG(e) }}>ตรวจสอบ</Button>
                    </span> */}
                </Space>
              </Col>
            </Row>

            {/* <Row gutter={32} justify={'center'}>
              <Radio.Group value={keyWord} onChange={(e) => setKeyWord(e.target.value)} >
                {
                  nameAd.sort((a, b) => {
                    const order = [
                      "MIT00075",
                      "น้ำ3",
                      "เหมย",
                      "หยก",
                      "ออรี่",
                      "ชมพู่",
                      "เอิร์น",
                    ];

                    return order.indexOf(a.ApprovalStatus) - order.indexOf(b.ApprovalStatus);
                  })
                    .map((item) => (
                      <Radio.Button
                        style={{
                          width: '160px',
                          border: '2px solid',
                          color: textc[item.ApprovalStatus]
                        }}
                        value={item.name}>
                        {item.name}
                        <b>{item.totalCount}</b>
                      </Radio.Button>
                    ))}

              </Radio.Group>
            </Row> */}

            <Row gutter={32} justify={'center'} style={{ marginTop: '15px' }}>
              <Col className="gutter-row" style={{ textAlign: 'right' }} span={12}>
                <Form.Item
                  style={{ marginBottom: 0, textAlign: 'right' }}
                >
                  <span>เลือกธุรการ : </span>
                  <Select
                    // defaultValue={{
                    //   label: 'ทั้งหมด',
                    //   value: 'ทั้งหมด',
                    // }}
                    value={valueselect1}
                    // value={defu}
                    onChange={handleChange1}
                    style={{ width: '160px' }}
                    options={namead}

                  >

                  </Select>
                </Form.Item>

              </Col>
              <Col className="gutter-row" style={{ textAlign: 'left' }} span={12}>
                <Form.Item
                  style={{ marginBottom: 0, textAlign: 'left' }}
                >
                  <span>เลือกสถานะ : </span>
                  <Select
                    value={selectedValue}
                    onChange={handleChange}
                    style={{ width: '220px' }}
                    options={select_op}
                  >

                  </Select>
                </Form.Item>
              </Col>

            </Row>
            {/* <Row gutter={32} style={{ margin: 10 }}>
              <Col className='gutter-row' span={24} style={{ textAlign: 'right' }}>
                <Input.Search
                  style={{ width: '250px' }}
                  placeholder="ค้นหา...."
                  onChange={search}
                  name="search"
                  id="search"

                />
              </Col>
            </Row> */}


            <Row gutter={32} style={{ marginTop: '15px' }}>
              <Col span={24}>

                <Table
                  rowKey={(record) => record.uid}
                  scroll={{
                    x: 1500,
                    y: 400,
                  }}
                  dataSource={arrayTable}
                  columns={columns}
                >
                </Table>
              </Col>
            </Row>



            <Col span={24} style={{ textAlign: 'center' }}>
              <Space>

                <Button href="/" type="primary"><HomeFilled />หน้าหลัก</Button>
              </Space>
            </Col>
          </Spin>
        </Col>
      </Row>
    </Card >

  </>;
}

