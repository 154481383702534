  //ที่รถ
  const arrCase = [];
  // arrCase[0] = "รออนุมัติราคารถ"                 //รอ
  // arrCase[1] = "รอธุรการรับ"                    //รอ
  // arrCase[2] = "รอวิเคราะห์"                    //รอ
  // arrCase[3] = "ผ่านการวิเคราะห์"                //รอ
  // arrCase[4] = "ไม่ผ่านการวิเคราะห์"              //รอ
  // arrCase[5] = "รออนุมัติ"                      //รอ
  // arrCase[6] = "อนุมัติ"                        //รอ
  // arrCase[7] = "ไม่อนุมัติ"                      //รอ
  // arrCase[8] = "ลูกค้าคอนเฟิร์ม"                 //รอ
  // arrCase[9] = "รอตรวจสอบเอกสาร"             //รอ
  // arrCase[10] = "ลูกค้าปฏิเสธ"                //ไม่ได้ยอด
  // arrCase[11] = "Reject"                  //ไม่ได้ยอด
  // arrCase[12] = "รอทำสัญญา"               //ได้ยอด
  arrCase[0] = "รอทำสัญญา"                    //เคสผ่าน

  arrCase[1] = "อนุมัติ"                        //เคสไม่จบ
  arrCase[2] = "อนุมัติแบบมีเงื่อนไข"              //เคสไม่จบ
  
  arrCase[3] = "Reject"                       //Reject

  // arrCase[4] = "Pre-ยื่นคำร้อง"                     //Pre
  // arrCase[5] = "Pre-คำร้องไม่ถูกต้อง"                //Pre
  // arrCase[6] = "Pre-รับคำร้อง"                     //Pre
  // arrCase[7] = "Pre-ปฏิเสธคำร้อง"                  //Pre
  // arrCase[8] = "Pre-ระงับเคส"                     //Pre
  // arrCase[9] = "Pre-เสนอเคสรีแล้ว"                 //Pre

  arrCase[4] = "Pre-Aaprove-รีโอน"                     //Pre
  arrCase[5] = "Pre-Aaprove-รี-Ploan"                //Pre
  arrCase[6] = "Pre-Aaprove-รี"                     //Pre
  arrCase[7] = "Pre-Aaprove-ปรับโครงสร้าง"                  //Pre
  arrCase[8] = "Pre-Aaprove-รี+เปลี่ยนสัญญา"                     //Pre
  arrCase[9] = "Pre-Aaprove-เปลี่ยนสัญญา"                 //Pre

  arrCase[10] = "Pre-Aaprove-ถือเล่มมา"                 //Pre
  arrCase[11] = "Pre-Aaprove-ซื้อ-ขาย"                //Pre
  arrCase[12] = "Pre-Aaprove-ย้ายไฟแนนซ์"                 //Pre
  arrCase[13] = "Pre-Aaprove-ถือโฉนด(ที่ดิน)"                //Pre
  arrCase[14] = "Pre-Aaprove-Ploan"                 //Pre
  arrCase[15] = "Pre-Aaprove-ฝากโฉนด"                //Pre

export default arrCase;
