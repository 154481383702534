import React from 'react'
import { Routes, Route } from "react-router-dom"
import TabelLandRegistrationSet from "./modal/tabelLandRegistrationSet";

export default function MainFormLand() {
    return (
        <Routes>
            <Route path="/" element={<TabelLandRegistrationSet />} />
        </Routes>
    )
}
