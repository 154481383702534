//from ใช้งาน-V1
import React, { useEffect, useState } from "react";
import { Button, Card, Col, DatePicker, Divider, Form, Input, InputNumber, Row, Select, message, Modal } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import "./cssResponsive.css";
import { Option } from 'antd/es/mentions';
import { getdistrict, getsubdistrict, getzipcodes, loadProvinces } from "../../file_mid/all_api";


function FormDataMarkings() {
  //ปีขณะนี้
  const yearNow = dayjs().format("YYYY");
  const inputMarketing = localStorage.getItem("username");
  const [inputNameMK, setInputNameMK] = useState([]);
  const onChangeNameMK = (value) => { setInputNameMKF(value); };
  const [inputNameMKF, setInputNameMKF] = useState([]);
  const onChangeNameMKF = (value) => { setInputNameMK(value); };
  const [inputNameMKM, setInputNameMKM] = useState([]);
  const onChangeNameMKM = (value) => { setInputNameMKM(value); };
  const [inputProvince, setInputProvince] = useState(null);   //เลือกจังหวัด
  const [selectedDate, setSelectedDate] = useState(new Date());  //วันที่ เพิ่ม-แก้ไข
  const [dataInputProvince, setDataInputProvince] = useState(null);   //เลือกอำเภอ
  const [inputDistricts, setInputDistricts] = useState(null);
  const [dataInputDistricts, setDataInputDistricts] = useState([]);  //เลือกตำบล
  const [inputSubdistrict, setInputSubdistrict] = useState();
  // const [alertDisplayed, setAlertDisplayed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  // const [provinces, setProvinces] = useState([]);
  // const [district, setDistricts] = useState([]);
  // const [subdistricts, setSubdistricts] = useState([]);
  // const [zipcode, setZipCodes] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { confirm } = Modal

  useEffect(() => {
    // axios.get(loadProvinces)
    //   .then((res) => {
    //     setProvinces(res.data)
    //   }).catch((err) => {
    //     console.error(err);
    //   })
    async function fetchData() {
      try {
        const info = await axios.get(`https://shark-app-j9jc9.ondigitalocean.app/toro/marketings/${inputMarketing}`)
        console.log("res.data", info)
        // setAlertDisplayed(true);

        if (info.data) {
          const values = info.data[0];
          setData(values);
          const date = dayjs(values.bDayMK, "YYYY-MM-DD");
          form.setFieldsValue({
            nameMK: values.nameMK,
            nameMK1: values.nameMK1,
            nameMK2: values.nameMK2,
            noIDMK: values.noIDMK,
            bDayMK: date,
            ethnicity: values.ethnicity,
            nationality: values.nationality,
            addr: values.addr,
            village: values.village,
            soi: values.soi,
            road: values.road,
            tumB: values.tumB,
            district: values.district,
            province: values.province,
            telp: values.telp,
            nameMKF: values.nameMKF,
            nameMKF1: values.nameMKF1,
            nameMKF2: values.nameMKF2,
            nameMKM: values.nameMKM,
            nameMKM1: values.nameMKM1,
            nameMKM2: values.nameMKM2,
          });

        }

      } catch (error) {
        console.error("Error fetching data:", error)
      } finally {
        setIsLoading(false)
      }
    }
    // ตรวจสอบว่าข้อมูลยังไม่ได้ถูกโหลด
    if (isLoading) {
      fetchData();
    }
  }, []); // Empty array ensures useEffect runs only once

  useEffect(() => {
    if (data === null || data === undefined || data === '') {
      showModal()
    }
  }, [data])

  // console.log("provinces", provinces)
  // console.log("district", district)
  // console.log("subdistricts", subdistricts)
  // console.log("zipcode", zipcode)

  // console.log("data", data)
  // console.log("inputMarketing", inputMarketing)

  const showModal = () => {
    if (inputMarketing !== undefined || inputMarketing === null) {
      confirm({
        title: 'คุณยังไม่มีข้อมูล..?',
        content: 'กรุณากรอกข้อมูลส่วนตัวด้านล่าง...',
        onOk() {
        },
        onCancel() {
        },
      });
    }
  }

  const changeDateThai = (date, dateString) => {
    setSelectedDate(dateString);
    console.log("date111", dateString);
  };

  const onClickProvince = () => {
    axios
      .get(loadProvinces)
      .then((response) => {
        console.log("response", response.data)
        const formattedProvinces = response.data.map((response) => ({
          value: response.provinceName,
          label: response.provinceName,
          key: response.provinceId,
        }));
        setInputProvince(formattedProvinces);
        // รีเซ็ตค่า inputDistricts เมื่อมีการคลิกที่ปุ่ม onClickProvince อีกครั้ง

        setDataInputDistricts(null);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const onChangeProvince = (value, inputProvince) => {
    setDataInputProvince(value);
    //console.log("setDataInputProvince",inputProvince.valueProvinceId);
    //const provinceId = parseInt(inputProvince);
    const provinceId = inputProvince.key;
    axios
      .get(
        getdistrict + provinceId
      )
      .then((response) => {
        const formattedDistrictId = response.data.map((response) => ({
          value: response.districtName,
          label: response.districtName,
          key: response.districtId,
        }));
        setInputDistricts(formattedDistrictId);
        //console.log("formattedDistrictId", formattedDistrictId);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const onChangeDistricts = (value, inputDistricts) => {
    setDataInputDistricts(value);
    const districtsID = inputDistricts.key;
    axios.get(getsubdistrict + districtsID)
      .then((response) => {
        const formattedSubdistrict = response.data.map((response) => ({

          value: response.subdistrictName,
          label: response.subdistrictName,
          key: response.subdistrictId,
        }));
        console.log("formattedSubdistrict", formattedSubdistrict)
        setInputSubdistrict(formattedSubdistrict);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const [dataInputSubdistrict, setDataInputSubdistrict] = useState([]);

  const onChangeSubdistrict = (value) => {
    console.log("value", value)
    setDataInputSubdistrict(value);
  };

  //บันทึก Error
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  //เช็ค เลขบัตรต้องมีความยาว 13 ตัว
  const validateID = (value) => {
    if (value && value.toString().length !== 13) {
      return Promise.reject(new Error("เลขบัตรต้องมีความยาว 13 ตัว"));
    } else {
      return Promise.resolve();
    }
  };
  //เช็ค โทรศัพท์ มีความยาว 9-10 ตัว
  const validateTelpID = (value) => {
    if (value && value.toString().length < 9) {
      return Promise.reject(new Error("เลขโทรศัพท์มีความยาว 9 ตัว!"));
    } else {
      return Promise.resolve();
    }
  };
  //บันทึก Success
  const onFinish = async (values) => {
    let checkMK;
    //http://localhost:5000/read/single/${idmk}
    //getById: https://shark-app-j9jc9.ondigitalocean.app/toro/marketings/:id
    await axios
      .get(
        `https://shark-app-j9jc9.ondigitalocean.app/toro/marketings/${inputMarketing}`
      )
      .then((res) => {
        console.log("res.data", res.data)
        checkMK = res.data;
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      }); //เพิ่ม
    if (checkMK.length === 0) {
      //http://localhost:5000/create"
      //post : https://shark-app-j9jc9.ondigitalocean.app/toro/marketings
      await axios
        .post("https://shark-app-j9jc9.ondigitalocean.app/toro/marketings", {
          idmk: inputMarketing,
          nameMK: values.nameMK,
          nameMK1: values.nameMK1,
          nameMK2: values.nameMK2,
          noIDMK: values.noIDMK,
          bDayMK: selectedDate,
          ethnicity: values.ethnicity,
          nationality: values.nationality,
          addr: values.addr,
          village: values.village,
          soi: values.soi,
          road: values.road,
          tumB: values.tumB,
          district: values.district,
          province: values.province,
          telp: values.telp,
          nameMKF: values.nameMKF,
          nameMKF1: values.nameMKF1,
          nameMKF2: values.nameMKF2,
          nameMKM: values.nameMKM,
          nameMKM1: values.nameMKM1,
          nameMKM2: values.nameMKM2,
        })
        .then(function (response) {
          console.log(response);
          alert("เพิ่มข้อมูลการตลาด " + inputMarketing + " สำเร็จ");
          window.location.reload();
        })
        .catch(function (error) {
          console.log(error);
        });
      //แก้ไข
    } else {
      //http://localhost:5000/update/${idmk}
      //updateById: https://shark-app-j9jc9.ondigitalocean.app/toro/marketings/:id
      await axios
        .put(
          `https://shark-app-j9jc9.ondigitalocean.app/toro/marketings/${inputMarketing}`,
          {
            nameMK: values.nameMK,
            nameMK1: values.nameMK1,
            nameMK2: values.nameMK2,
            noIDMK: values.noIDMK,
            bDayMK: selectedDate,
            ethnicity: values.ethnicity,
            nationality: values.nationality,
            addr: values.addr,
            village: values.village,
            soi: values.soi,
            road: values.road,
            tumB: values.tumB,
            district: values.district,
            province: values.province,
            telp: values.telp,
            nameMKF: values.nameMKF,
            nameMKF1: values.nameMKF1,
            nameMKF2: values.nameMKF2,
            nameMKM: values.nameMKM,
            nameMKM1: values.nameMKM1,
            nameMKM2: values.nameMKM2,
          }
        )
        .then(function (response) {
          console.log(response);
          alert("แก้ไขข้อมูลการตลาด " + inputMarketing + " สำเร็จ");
          window.location.reload();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };
  //ค้นหาการตลาด
  const [form] = Form.useForm();
  const searchMarking = async () => {
    //http://localhost:5000/read/single/${inputMarketing}
    //getById: https://shark-app-j9jc9.ondigitalocean.app/toro/marketings/:id
    await axios.get(`https://shark-app-j9jc9.ondigitalocean.app/toro/marketings/${inputMarketing}`)
      .then((res) => {
        console.log("res.data", res.data)
        const values = res.data[0];
        if (values === undefined) {
          alert("ไม่พบข้อมูล " + inputMarketing + "กรุณากรอกข้อมูลส่วนตัว");
          return;
        }
        //const date = moment(values.bDayMK, moment.ISO_8601, true);
        //const date = moment(values.bDayMK, 'YYYY-MM-DD');
        const date = dayjs(values.bDayMK, "YYYY-MM-DD");
        form.setFieldsValue({
          nameMK: values.nameMK,
          nameMK1: values.nameMK1,
          nameMK2: values.nameMK2,
          noIDMK: values.noIDMK,
          bDayMK: date,
          ethnicity: values.ethnicity,
          nationality: values.nationality,
          addr: values.addr,
          village: values.village,
          soi: values.soi,
          road: values.road,
          tumB: values.tumB,
          district: values.district,
          province: values.province,
          telp: values.telp,
          nameMKF: values.nameMKF,
          nameMKF1: values.nameMKF1,
          nameMKF2: values.nameMKF2,
          nameMKM: values.nameMKM,
          nameMKM1: values.nameMKM1,
          nameMKM2: values.nameMKM2,
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  return (
    <>
      {
        isLoading ?
          <p>กำลังโหลดข้อมูล...</p>
          :
          <>
            <Form
              layout="vertical"
              className="formScreen"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}

              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              form={form}
            >
              <div className="main" justify={"center"}>
                <Card style={{ width: '100%' }}>
                  <Divider orientation="center" style={{ fontSize: '25px' }}>
                    <b>ข้อมูลการตลาด-มอบอำนาจ</b>
                  </Divider>
                  <aside style={{ width: '95%' }}>
                    <div style={{ padding: 3 }}>
                      <Form.Item
                        label="คำนำหน้า"
                        name="nameMK"
                        rules={[
                          {
                            required: true,
                            message: "เลือก คำนำหน้า!",
                          },
                        ]}
                      >
                        <Select
                          style={{ height: "40px" }}
                          onChange={(value) => {
                            onChangeNameMK(value);
                          }}
                          placeholder="เลือกคำนำหน้า"
                          options={[
                            {
                              value: 'นาย',
                              label: 'นาย',
                            },
                            {
                              value: 'นาง',
                              label: 'นาง',
                            },
                            {
                              value: 'นางสาว',
                              label: 'นางสาว',
                            },
                          ]}
                          value={inputNameMK}
                        />
                      </Form.Item>
                    </div>
                    <div style={{ padding: 3 }}>
                      <Form.Item
                        className="formScreen"
                        label="ชื่อ"
                        name="nameMK1"
                        rules={[
                          {
                            required: true,
                            message: "กรอก ชื่อการตลาด!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                    <div style={{ padding: 3 }}>
                      <Form.Item
                        label="นามสกุล"
                        name="nameMK2"
                        rules={[
                          {
                            required: true,
                            message: "กรอก นามสกุลการตลาด!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                    <div style={{ padding: 3 }}>
                      <Form.Item
                        label="เลขบัตร"
                        name="noIDMK"
                        rules={[
                          {
                            required: true,
                            message: "กรอก เลขบัตร13!",
                            validator: (_, value) => validateID(value),
                          },
                        ]}
                      >
                        <InputNumber
                          maxLength={13}
                          style={{
                            width: "100%",
                            height: "40px"
                          }}
                        />
                      </Form.Item>
                    </div>
                    <div style={{ padding: 3 }}>
                      <Form.Item
                        label="ว/ด/ป เกิดปี ค.ศ."
                        name="bDayMK"
                        rules={[
                          {
                            required: true,
                            message: "เลือก วันเดือนปีเกิด!",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (value && (new Date().getFullYear() - value.year() >= 20)) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('อายุคุณ '+(yearNow-value.year())+' ปี ต้องมีอายุมากกว่า 20 ปี'));
                            },
                          }),
                        ]}
                      >
                        <DatePicker
                          //selected={selectedDate}
                          onChange={changeDateThai}
                          //showTimeSelect
                          //timeFormat="HH:mm"
                          //timeIntervals={15}
                          //dateFormat= "YYYY-MM-DD"
                          //timeCaption="time"
                          //locale="th"
                          style={{ height: "40px", width: '100%' }}
                        />
                      </Form.Item>
                    </div>
                    <div style={{ padding: 3 }}>
                      <Form.Item
                        label="เชื้อชาติ"
                        name="ethnicity"
                        rules={[
                          {
                            required: true,
                            message: "กรอก เชื้อชาติ!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                    <div style={{ padding: 3 }}>
                      <Form.Item
                        label="สัญชาติ"
                        name="nationality"
                        rules={[
                          {
                            required: true,
                            message: "กรอก สัญชาติ!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                    <div style={{ padding: 3 }}>
                      <Form.Item
                        label="บ้านเลขที่"
                        name="addr"
                        rules={[
                          {
                            required: true,
                            message: "กรอก บ้านเลขที่!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                    <div style={{ padding: 3 }}>
                      <Form.Item
                        label="หมู่ที่"
                        name="village"
                        rules={[
                          {
                            required: true,
                            message: "กรอก หมู่ที่!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                    <div style={{ padding: 3 }}>
                      <Form.Item
                        label="ตรอก/ซอย"
                        name="soi"
                        rules={[
                          {
                            required: true,
                            message: "กรอก ตรอก/ซอย!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                    <div style={{ padding: 3 }}>
                      <Form.Item
                        label="ถนน"
                        name="road"
                        rules={[
                          {
                            required: true,
                            message: "กรอก ถนน!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                    <div style={{ padding: 3 }}>
                      <Form.Item
                        label="จังหวัด"
                        name="province"
                        rules={[
                          {
                            required: true,
                            message: "เลือก จังหวัด!",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          style={{ height: "40px" }}
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                              .toLowerCase()
                              .localeCompare((optionB?.label ?? "").toLowerCase())
                          }
                          options={inputProvince}
                          onClick={onClickProvince}
                          value={dataInputProvince}
                          onChange={onChangeProvince}
                          placeholder="เลือก จังหวัด!"
                        />
                      </Form.Item>
                    </div>
                    {/* <div style={{ padding: 3 }}>
                      <Form.Item
                        label="จังหวัด222"
                        name="province"
                        rules={[
                          {
                            required: true,
                            message: "เลือก จังหวัด!",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          loading={loading}
                          name='province'
                          placeholder="จังหวัด"
                          onChange={handleProvinceChange}
                        >
                          {provinces.map((pro, index) => (
                            <Option key={pro.provinceId} value={pro.provinceName}>
                              {pro.provinceName}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div> */}
                    <div style={{ padding: 3 }}>
                      <Form.Item
                        label="อำเภอ"
                        name="district"
                        rules={[
                          {
                            required: true,
                            message: "เลือก อำเภอ!",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          style={{ height: "40px" }}
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                              .toLowerCase()
                              .localeCompare((optionB?.label ?? "").toLowerCase())
                          }
                          value={dataInputDistricts}
                          onChange={onChangeDistricts}
                          placeholder="เลือก อำเภอ!"
                          options={inputDistricts}
                        />
                      </Form.Item>
                    </div>
                    {/* <div style={{ padding: 3 }}>
                      <Form.Item
                        label="อำเภอ2222"
                        name="district"
                        rules={[
                          {
                            required: true,
                            message: "เลือก อำเภอ!",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          loading={loading}

                          name='district'
                          placeholder="อำเภอ"
                          onChange={handleDistrictChange}
                        >
                          {district.map((dis, index) => (
                            <Option key={dis.districtId} value={dis.districtName}>
                              {dis.districtName}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div> */}
                    <div style={{ padding: 3 }}>
                      <Form.Item
                        label="ตำบล"
                        name="tumB"
                        rules={[
                          {
                            required: true,
                            message: "เลือก ตำบล!",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          style={{ height: "40px" }}
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                              .toLowerCase()
                              .localeCompare((optionB?.label ?? "").toLowerCase())
                          }
                          value={dataInputSubdistrict}
                          onChange={onChangeSubdistrict}
                          placeholder="เลือก อำเภอ!"
                          options={inputSubdistrict}
                        />
                      </Form.Item>
                    </div>
                    {/* <div style={{ padding: 3 }}>
                      <Form.Item
                        label="ตำบล22222"
                        name="tumB"
                        rules={[
                          {
                            required: true,
                            message: "เลือก ตำบล!",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          loading={loading}
                          name='subdistrict'
                          placeholder='ตำบล'
                          onChange={handleSubdistrictChange}
                        >
                          {subdistricts.map((subdis, index) => (
                            <Option key={subdis.subdistrictId} value={subdis.subdistrictName}>
                              {subdis.subdistrictName}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div> */}
                    <div style={{ padding: 3 }}>
                      <Form.Item
                        label="โทรศัพท์"
                        name="telp"
                        rules={[
                          {
                            required: true,
                            message: "กรอก โทรศัพท์ 9 ตัวขึ้นไป!",
                            validator: (_, value) => validateTelpID(value),
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>


                  </aside>
                  <Divider />
                  <aside style={{ width: '95%' }}>
                    <div style={{ padding: 3 }}>
                      <Form.Item
                        label="คำนำหน้า"
                        name="nameMKF"
                        rules={[
                          {
                            required: true,
                            message: "เลือก คำนำหน้า!",
                          },
                        ]}
                      >
                        <Select
                          style={{ height: "40px" }}
                          onChange={(value) => {
                            onChangeNameMKF(value);
                          }}
                          placeholder="เลือกคำนำหน้า"
                          options={[
                            {
                              value: 'นาย',
                              label: 'นาย',
                            },
                            {
                              value: 'นาง',
                              label: 'นาง',
                            },
                            {
                              value: 'นางสาว',
                              label: 'นางสาว',
                            },
                          ]}
                          value={inputNameMKF}
                        />
                      </Form.Item>
                    </div>
                    <div style={{ padding: 3 }}>
                      <Form.Item
                        label="ชื่อบิดา"
                        name="nameMKF1"
                        rules={[
                          {
                            required: true,
                            message: "กรอก ชื่อบิดา!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                    <div style={{ padding: 3 }}>
                      <Form.Item
                        label="นามสกุลบิดา"
                        name="nameMKF2"
                        rules={[
                          {
                            required: true,
                            message: "กรอก นามสกุลบิดา!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                    <div style={{ padding: 3 }}>
                      <Form.Item
                        label="คำนำหน้า"
                        name="nameMKM"
                        rules={[
                          {
                            required: true,
                            message: "เลือก คำนำหน้า!",
                          },
                        ]}
                      >
                        <Select
                          style={{ height: "40px" }}
                          onChange={(value) => {
                            onChangeNameMKM(value);
                          }}
                          placeholder="เลือกคำนำหน้า"
                          options={[
                            {
                              value: 'นาย',
                              label: 'นาย',
                            },
                            {
                              value: 'นาง',
                              label: 'นาง',
                            },
                            {
                              value: 'นางสาว',
                              label: 'นางสาว',
                            },
                          ]}
                          value={inputNameMKM}
                        />
                      </Form.Item>
                    </div>
                    <div style={{ padding: 3 }}>
                      <Form.Item
                        label="ชื่อมารดา"
                        name="nameMKM1"
                        rules={[
                          {
                            required: true,
                            message: "กรอก ชื่อมารดา!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                    <div style={{ padding: 3 }}>
                      <Form.Item
                        label="นามสกุลมารดา"
                        name="nameMKM2"
                        rules={[
                          {
                            required: true,
                            message: "กรอก นามสกุลมารดา!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                  </aside>
                  <Divider />
                  <Row justify={'center'}>
                    {/* <Button
                style={{
                  margin: "5px",
                  backgroundColor: "green",
                  color: "white",
                }}
                onClick={searchMarking}
              >
                ค้นหาข้อมูล
              </Button> */}
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ margin: "5px" }}
                    >
                      บันทึก
                    </Button>
                    <Button htmlType="reset" style={{ margin: "5px" }}>
                      ยกเลิก
                    </Button>
                  </Row>
                </Card>
              </div>

            </Form>
          </>
      }


    </>
  );
}
export default FormDataMarkings;
