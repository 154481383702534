import React, { useState, useEffect } from 'react';
import { Form, Input, Row, Col, Button, Select, Divider, Space, Radio, DatePicker, Card, Spin, notification, Upload, Modal } from 'antd';
import '../../offer_case/css/Media.css';
import Compressor from 'compressorjs';
import axios from 'axios';
import dayjs from 'dayjs';
import { PlusOutlined } from '@ant-design/icons';
import { newUpimg, newUpimgland } from '../../file_mid/all_api';

function EditCustomerDoc({ getData, close, sendback, dt }) {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [dataModalEdit, setDataModalEdit] = useState(getData)
    const [api, contextHolder] = notification.useNotification();
    const [fileListCustomer, setFileListCustomer] = useState([]);
    const [fileListCustomer1, setFileListCustomer1] = useState([]);
    const [fileListCustomerCareer, setFileListCustomerCareer] = useState([]);
    const [fileListCustomerCareer1, setFileListCustomerCareer1] = useState([]);
    const [fileListCustomerStatementImage, setFileListCustomerStatementImage] = useState([]);
    const [fileListCustomerStatementPDF, setFileListCustomerStatementPDF] = useState([]);
    const [fileListCustomerStatementImage1, setFileListCustomerStatementImage1] = useState([]);
    const [fileListCustomerStatementPDF1, setFileListCustomerStatementPDF1] = useState([]);

    //console.log("dt", dt.car)
    console.log("getData", getData)

    useEffect(() => {
        setDataModalEdit(getData)
        if (getData !== "") {
            form.setFieldsValue(
                {
                    ...getData,
                    birthDate: dayjs(getData?.birthDate),
                    issudt: dayjs(getData?.issudt),
                    expdt: dayjs(getData?.expdt),
                }
            )
        }
    }, [getData])


    const handleCancel = () => {
        close();
    }

    // const SuccSend = (placement) => {
    //     api.success({
    //         message: <b>แก้ไขข้อมูลสำเร็จ</b>,
    //         placement,
    //     });
    // }

    const uploadImage = async (resultImg) => {
        if (dt?.car === "car") {
            var inputTest = { mainImg: { carId: getData.cars.carId, carInput: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"), img: resultImg } }
            console.log("inputTest-car", inputTest)
            const headers = { 'Content-Type': 'application/json' }
            await axios.post(newUpimg, inputTest, { headers: headers })
                .then(res => {
                    if (res.data) {
                        console.log("OK car")
                        setFileListCustomer([])
                        setFileListCustomer1([])
                        setFileListCustomerCareer([])
                        setFileListCustomerCareer1([])
                        setFileListCustomerStatementImage([])
                        setFileListCustomerStatementPDF([])
                        setFileListCustomerStatementImage1([])
                        setFileListCustomerStatementPDF1([])
                    }
                })
                .catch((err) => console.error('เกิดข้อผิดพลาดในการอัปโหลดรูปภาพ:', err))
        } else {
            var inputTest = { mainImg: { landId: getData.lands.landId, landInput: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"), img: resultImg } }
            console.log("inputTest-land", inputTest)
            const headers = { 'Content-Type': 'application/json' }
            await axios.post(newUpimgland, inputTest, { headers: headers })
                .then(res => {
                    if (res.data) {
                        console.log("OK land")
                        setFileListCustomer([])
                        setFileListCustomer1([])
                        setFileListCustomerCareer([])
                        setFileListCustomerCareer1([])
                        setFileListCustomerStatementImage([])
                        setFileListCustomerStatementPDF([])
                        setFileListCustomerStatementImage1([])
                        setFileListCustomerStatementPDF1([])
                    }
                })
                .catch((err) => console.error('เกิดข้อผิดพลาดในการอัปโหลดรูปภาพ:', err))
        }
    }

    const handleSubmit = async () => {
        setLoading(true);
        var resultImg = []
        var data = { ...dataModalEdit, updatedAt: dayjs() }
        // console.log("DATA", data)
        if (fileListCustomer1?.imageListCustomer) {
            resultImg.push(...fileListCustomer1?.imageListCustomer)
        }
        if (fileListCustomerStatementImage1?.imageListCustomerStatementImage) {
            resultImg.push(...fileListCustomerStatementImage1?.imageListCustomerStatementImage)
        }
        if (fileListCustomerStatementPDF1?.imageListCustomerStatementPDF) {
            resultImg.push(...fileListCustomerStatementPDF1?.imageListCustomerStatementPDF)
        }
        if (fileListCustomerCareer1?.imageListCustomerCareer) {
            resultImg.push(...fileListCustomerCareer1?.imageListCustomerCareer)
        }
        if (resultImg?.length > 0) {
            await uploadImage(resultImg)
        }

        // await axios.post('http://localhost:8080/api/editcustomer', data)
        //     .then(async (res) => {
        //         if (res.status === 200) {
        //             sendback(dayjs())
        //             SuccSend("top")
        //             setLoading(false);
        //         }
        //     }).catch((err) => console.log(err))
        setLoading(false);
    };

    const handleLastName = (value) => {
        setDataModalEdit({ ...dataModalEdit, lastname: value })
    }

    const handleProduct = (value) => {
        setDataModalEdit({ ...dataModalEdit, nationalId: value })
        if (value === "บุคคลธรรมดา") {
            form.setFieldsValue({
                ...getData,
                birthDate: dayjs(getData?.birthDate),
                issudt: dayjs(getData?.issudt),
                expdt: dayjs(getData?.expdt),
            })
        } else {
            form.setFieldsValue({
                snam: "",
                lastname: "",
            })
        }

    }
    const handletypeCompany = (value) => {
        setDataModalEdit({ ...dataModalEdit, snam: value })
    }
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
    };
    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const handleChangeCustomer = async ({ fileList }) => { // 4
        setLoading(true)
        setFileListCustomer(fileList);
        var imageListCustomer = []
        for (const file of fileList) {
            const compressedFile = await new Promise((resolve) => {
                new Compressor(file.originFileObj, {
                    quality: 0.3, // เช่น, 60%
                    success: (result) => {
                        resolve(result);
                    },
                });
            });
            var image = {}
            var filename = file.name
            var image64 = await getBase64((compressedFile))
            image.filename = filename
            image.image64 = image64
            image.type = 4
            imageListCustomer.push(image)
        }
        setFileListCustomer1({ ...fileListCustomer1, imageListCustomer });
        setLoading(false)
    };

    const handleChangeCustomerCareer = async ({ fileList }) => { // 6
        setLoading(true)
        setFileListCustomerCareer(fileList);
        var imageListCustomerCareer = []
        for (const file of fileList) {
            const compressedFile = await new Promise((resolve) => {
                new Compressor(file.originFileObj, {
                    quality: 0.3, // เช่น, 60%
                    success: (result) => {
                        resolve(result);
                    },
                });
            });
            var image = {}
            var filename = file.name
            var image64 = await getBase64(compressedFile)
            image.filename = filename
            image.image64 = image64
            image.type = 6
            imageListCustomerCareer.push(image)
        }
        setFileListCustomerCareer1({ ...fileListCustomerCareer1, imageListCustomerCareer });
        setLoading(false)
    };

    const handleChangeCustomerStatementImage = async ({ fileList }) => { // 8
        setLoading(true)
        setFileListCustomerStatementImage(fileList);
        var imageListCustomerStatementImage = []
        for (const file of fileList) {
            const compressedFile = await new Promise((resolve) => {
                new Compressor(file.originFileObj, {
                    quality: 0.3, // เช่น, 60%
                    success: (result) => {
                        resolve(result);
                    },
                });
            });
            var image = {}
            var filename = file.name
            var image64 = await getBase64(compressedFile)
            //var image64 = file.originFileObj
            image.filename = filename
            image.image64 = image64
            image.type = 8
            imageListCustomerStatementImage.push(image)
        }
        setFileListCustomerStatementImage1({ ...fileListCustomerStatementImage1, imageListCustomerStatementImage });
        setLoading(false)
    };

    const handleChangeCustomerStatementPDF = async ({ fileList }) => { // 7
        setLoading(true)
        setFileListCustomerStatementPDF(fileList);
        var imageListCustomerStatementPDF = []
        for (const file of fileList) {
            var image = {}
            var filename = file.name
            var image64 = await getBase64(file.originFileObj)
            image.filename = filename
            image.image64 = image64
            image.type = 7
            imageListCustomerStatementPDF.push(image)
        }
        setFileListCustomerStatementPDF1({ ...fileListCustomerStatementPDF1, imageListCustomerStatementPDF });
        setLoading(false)
    };
    
    const handleCancel1 = () => {
        setPreviewVisible(false);
    }
    return (
        <div>
            <Card style={{ padding: 20 }} className="modal-img">
                <div style={{ textAlign: 'center' }}>
                    <Row style={{ textAlign: 'center' }} >
                        <Col span={24} style={{ fontSize: '30px' }} >ข้อมูลส่วนตัว</Col>
                    </Row>
                    <Divider />
                    <Spin spinning={loading} size='large' tip=" Loading... ">
                        <Form
                            labelCol={{
                                span: 12,
                            }}
                            wrapperCol={{
                                span: 24,
                            }}
                            form={form}
                            onFinish={handleSubmit} >
                            <Row justify={'center'}>
                                <Col span={24}>
                                    <Row justify={'center'}>
                                        <Form.Item label='ประเภท' name='nationalId'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input !',
                                                },]}>
                                            <Select
                                                placeholder={'เลือก'}
                                                style={{ width: '200px', height: '40px', }}
                                                defaultValue={'บุคคลธรรมดา'}
                                                name='nationalId'
                                                onChange={(value) => handleProduct(value)}
                                                options={[
                                                    {
                                                        label: 'บุคคลธรรมดา',
                                                        value: 'บุคคลธรรมดา',
                                                    },
                                                    {
                                                        label: 'นิติบุคคล',
                                                        value: 'นิติบุคคล',
                                                    },
                                                ]}
                                            />
                                        </Form.Item>
                                    </Row>
                                    {
                                        dataModalEdit?.nationalId === "นิติบุคคล" ?
                                            <>
                                                <Row className='main2' justify={'center'}>
                                                    <aside style={{ width: '75%' }}>
                                                        <div>
                                                            <Form.Item label='ประเภทห้าง' name='snam'
                                                                style={{ textAlign: 'center', width: '300px' }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !',
                                                                    },]}>
                                                                <Select
                                                                    placeholder={'เลือก'}
                                                                    style={{ width: '250px', height: '40px', }}
                                                                    name='snam'
                                                                    initialvalues={1}
                                                                    onChange={(value) => handletypeCompany(value)}
                                                                    options={[
                                                                        {
                                                                            label: 'หจก.',
                                                                            value: 'หจก',
                                                                        },
                                                                        {
                                                                            label: 'บริษัท',
                                                                            value: 'บริษัท',
                                                                        },
                                                                    ]}
                                                                />


                                                            </Form.Item>
                                                        </div>
                                                        <div>
                                                            <Form.Item
                                                                label='เลขประจำตัวผู้เสียภาษี'
                                                                // name='taxID'
                                                                name='identificationId'
                                                                style={{ width: '300px' }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !',
                                                                    },]}>

                                                                <Input type='text' maxLength={13}
                                                                    name='identificationId'
                                                                    // disabled={CheckIdCard ? false : true}
                                                                    style={{ width: '200px', marginRight: '5px', marginLeft: '5px', color: 'black' }}
                                                                    onChange={(e) => setDataModalEdit({ ...dataModalEdit, identificationId: e.target.value })}
                                                                />

                                                            </Form.Item>

                                                        </div>

                                                        <div>
                                                            <Form.Item label='ชื่อบริษัท' name='firstname'
                                                                style={{ textAlign: 'center', width: '300px' }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !',
                                                                    },]}
                                                            >
                                                                <Input type='text'
                                                                    style={{ width: '250px', color: 'black' }}
                                                                    onChange={(e) => setDataModalEdit({ ...dataModalEdit, firstname: e.target.value })}>

                                                                </Input>

                                                            </Form.Item>
                                                        </div>
                                                        <div>
                                                            <Form.Item label='' name='lastname'
                                                                style={{ textAlign: 'left', width: '300px' }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !',
                                                                    },]}
                                                            >
                                                                <Select
                                                                    placeholder={'เลือก'}
                                                                    style={{ textAlign: 'left', height: '40px', width: '100px' }}
                                                                    name='lastname'
                                                                    initialvalues={'จำกัด'}
                                                                    onChange={(value) => handleLastName(value)}
                                                                    options={[
                                                                        {
                                                                            label: 'มหาชน',
                                                                            value: 'มหาชน',
                                                                        },
                                                                        {
                                                                            label: 'จำกัด',
                                                                            value: 'จำกัด',
                                                                        },
                                                                    ]}
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                        <div>
                                                            <Form.Item label='วันจดทะเบียน' name='birthDate'
                                                                style={{ textAlign: 'center', width: '300px' }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !',
                                                                    },]}>
                                                                <DatePicker format={'YYYY-MM-DD'}
                                                                    style={{ height: '40px', width: '250px', color: 'black' }}
                                                                    onChange={(e) => {
                                                                        if (e) {
                                                                            setDataModalEdit({ ...dataModalEdit, birthDate: e.format('YYYY-MM-DD') })
                                                                        }
                                                                    }} />
                                                            </Form.Item>
                                                        </div>


                                                    </aside>
                                                </Row>
                                            </>
                                            :
                                            <>
                                                <Row className='main2' justify={'center'}>
                                                    <aside style={{ width: '75%' }}>
                                                        <div>
                                                            <Form.Item label='เลขประจำตัวประชาชน' name='identificationId'
                                                                style={{ textAlign: 'center', width: '320px' }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !',
                                                                    },]}>
                                                                <Input name='identificationId' type='text' maxLength={13}
                                                                    style={{ width: '200px' }}
                                                                    // defaultValue={dataModalEdit.identificationId}
                                                                    onChange={(e) => setDataModalEdit({ ...dataModalEdit, identificationId: e.target.value })} />
                                                            </Form.Item>
                                                        </div>
                                                        <div>
                                                            <Form.Item label="คำนำหน้าชื่อ" name="snam"
                                                                style={{ textAlign: 'center', width: '320px' }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !',
                                                                    },]}>
                                                                <Radio.Group
                                                                    style={{ width: '250px' }}
                                                                    // defaultValue={dataModalEdit.snam}
                                                                    onChange={(e) => setDataModalEdit({ ...dataModalEdit, snam: e.target.value })} >
                                                                    <Radio value="นาย"> นาย </Radio>
                                                                    <Radio value="นาง"> นาง </Radio>
                                                                    <Radio value="นางสาว"> นางสาว </Radio>
                                                                </Radio.Group>
                                                            </Form.Item>
                                                        </div>
                                                        <div>
                                                            <Form.Item label='ชื่อ' name='firstname'
                                                                style={{ textAlign: 'center', width: '320px' }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !',
                                                                    },]}>
                                                                <Input type='text' name='firstname' style={{ width: '200px' }}
                                                                    // defaultValue={dataModalEdit.firstName}
                                                                    onChange={(e) => setDataModalEdit({ ...dataModalEdit, firstName: e.target.value })}></Input>
                                                            </Form.Item>
                                                        </div>
                                                        <div>


                                                            <Form.Item label='นามสกุล' name='lastname'
                                                                style={{ textAlign: 'center', width: '320px' }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !',
                                                                    },]} >
                                                                <Input type='text' style={{ width: '200px' }}
                                                                    onChange={(e) => setDataModalEdit({ ...dataModalEdit, lastName: e.target.value })}></Input>
                                                            </Form.Item>
                                                        </div>
                                                        <div>
                                                            <Form.Item label='ชื่อเล่น' name='nickname'
                                                                style={{ textAlign: 'center', width: '320px' }}>
                                                                <Input type='text' style={{ width: '200px' }}
                                                                    onChange={(e) => setDataModalEdit({ ...dataModalEdit, nickname: e.target.value })}></Input>
                                                            </Form.Item>
                                                        </div>
                                                        <div>
                                                            <Form.Item label='เกิดวันที่' name='birthDate'
                                                                style={{ textAlign: 'center', width: '320px' }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !',
                                                                    },]} >
                                                                <DatePicker
                                                                    style={{ width: '200px' }}
                                                                    format={'YYYY-MM-DD'}
                                                                    name='birthDate'
                                                                    onChange={(e) => {
                                                                        if (e) {
                                                                            setDataModalEdit({ ...dataModalEdit, birthDate: e.format('YYYY-MM-DD') })
                                                                        }
                                                                    }} />
                                                                {/* <Yearpick
                                            picker="year"
                                            // name="birthDate"
                                            onChange={(e, string) => {
                                                console.log('e', e.$y)
                                            }} /> */}
                                                            </Form.Item>
                                                        </div>
                                                        <div>
                                                            <Form.Item label='เพศ' name='gender'
                                                                style={{ textAlign: 'center', width: '320px' }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !',
                                                                    },]} >
                                                                <Select
                                                                    placeholder={'เลือก'}
                                                                    style={{ width: '200px' }}
                                                                    // defaultValue={dataModalEdit.gender}
                                                                    onChange={(value) => setDataModalEdit({ ...dataModalEdit, gender: value })}
                                                                    options={[
                                                                        {
                                                                            label: 'ชาย',
                                                                            value: 'M',
                                                                        },
                                                                        {
                                                                            label: 'หญิง',
                                                                            value: 'F',
                                                                        },
                                                                    ]} />
                                                            </Form.Item>
                                                        </div>
                                                        <div>
                                                            <Form.Item label='เจ้าพนักงานออกบัตร' name='issuby'
                                                                style={{ textAlign: 'center', width: '320px' }}
                                                            // rules={[
                                                            //     {
                                                            //         required: true,
                                                            //         message: 'Please input !',
                                                            //     },]}
                                                            >
                                                                <Input type='text' style={{ width: '200px' }}
                                                                    // defaultValue={dataModalEdit.issuby}
                                                                    onChange={(e) => setDataModalEdit({ ...dataModalEdit, issuby: e.target.value })}></Input>
                                                            </Form.Item>
                                                        </div>
                                                        <div>
                                                            <Form.Item label='วันออกบัตร' name='issudt'
                                                                style={{ textAlign: 'center', width: '320px' }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !',
                                                                    },]} >
                                                                <DatePicker
                                                                    style={{ width: '200px' }}
                                                                    format={'YYYY-MM-DD'}
                                                                    name='issudt'
                                                                    // defaultValue={convertDate(dataModalEdit.issudt)}
                                                                    onChange={(e) => {
                                                                        if (e) {
                                                                            setDataModalEdit({ ...dataModalEdit, issudt: e.format('YYYY-MM-DD') })
                                                                        }
                                                                    }} />
                                                            </Form.Item>
                                                        </div>
                                                        <div>
                                                            <Form.Item label='วันบัตรหมดอายุ' name='expdt'
                                                                style={{ textAlign: 'center', width: '320px' }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !',
                                                                    },]} >
                                                                <DatePicker
                                                                    style={{ width: '200px' }}
                                                                    format={'YYYY-MM-DD'}
                                                                    // defaultValue={convertDate(dataModalEdit.expdt)}
                                                                    onChange={(e) => {
                                                                        if (e) {
                                                                            setDataModalEdit({ ...dataModalEdit, expdt: e.format('YYYY-MM-DD') })
                                                                        }
                                                                    }} />
                                                            </Form.Item>
                                                        </div>
                                                    </aside>
                                                </Row>
                                            </>
                                    }
                                </Col>
                            </Row>
                            <Divider></Divider>
                            <Row className='main' justify={'center'}>
                                <aside style={{ width: '90%' }}>
                                    <div>
                                        <Row justify={'left'}><b><h3><u>รูปบัตรประชาชน</u></h3></b></Row>
                                        <b> <Form.Item
                                            style={{ textAlign: 'center', width: '300px' }}
                                        >
                                            <Upload
                                                accept="image/*"
                                                multiple={true}
                                                style={{ textAlign: 'center' }}
                                                listType="picture-card"
                                                fileList={fileListCustomer}
                                                onPreview={handlePreview}
                                                onChange={handleChangeCustomer}
                                                beforeUpload={() => false}
                                            >
                                                {fileListCustomer?.length >= 20 ? null : (
                                                    <div>
                                                        <PlusOutlined />
                                                        <div className="ant-upload-text">upload</div>
                                                    </div>
                                                )}
                                            </Upload>
                                            <Modal open={previewVisible} onCancel={handleCancel1}>
                                                <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                            </Modal>
                                        </Form.Item>
                                        </b>
                                    </div>
                                    <div>
                                        <Row justify={'left'}><b><h3><u>ประวัติคนกู้ / ทะเบียนบ้าน / อาชีพ</u></h3></b></Row>
                                        <b> <Form.Item
                                            style={{ textAlign: 'center' }}
                                        >
                                            <Upload
                                                accept="image/*"
                                                multiple={true}
                                                style={{ textAlign: 'center' }}
                                                listType="picture-card"
                                                fileList={fileListCustomerCareer}
                                                onPreview={handlePreview}
                                                onChange={handleChangeCustomerCareer}
                                                beforeUpload={() => false}
                                            >
                                                {fileListCustomerCareer?.length >= 20 ? null : (
                                                    <div>
                                                        <PlusOutlined />
                                                        <div className="ant-upload-text">upload</div>
                                                    </div>
                                                )}
                                            </Upload>
                                            <Modal open={previewVisible} onCancel={handleCancel1}>
                                                <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                            </Modal>
                                        </Form.Item>
                                        </b>
                                    </div>
                                </aside>
                            </Row>
                            <Divider></Divider>
                            <Row justify={'left'}><b><h3><u>สเตทเมนต์ผู้กู้</u></h3></b></Row>
                            <Row className='main' justify={'center'}>
                                <aside style={{ width: '90%' }}>
                                    <div>
                                        <Row justify={'left'}><b><h3><u>ไฟล์ภาพ</u></h3></b></Row>
                                        <b> <Form.Item
                                            style={{ textAlign: 'center', width: '300px' }}
                                        >
                                            <Upload
                                                accept="image/*"
                                                multiple={true}
                                                style={{ textAlign: 'center' }}
                                                listType="picture-card"
                                                fileList={fileListCustomerStatementImage}
                                                onPreview={handlePreview}
                                                onChange={handleChangeCustomerStatementImage}
                                                beforeUpload={() => false}
                                            >
                                                {fileListCustomerStatementImage?.length >= 20 ? null : (
                                                    <div>
                                                        <PlusOutlined />
                                                        <div className="ant-upload-text">upload</div>
                                                    </div>
                                                )}
                                            </Upload>
                                            <Modal open={previewVisible} onCancel={handleCancel1}>
                                                <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                            </Modal>
                                        </Form.Item>
                                        </b>
                                    </div>
                                    <div>
                                        <Row justify={'left'}><b><h3><u>ไฟล์ PDF</u></h3></b></Row>
                                        <b> <Form.Item
                                            style={{ textAlign: 'center' }}
                                        >
                                            <Upload
                                                accept="application/pdf"
                                                multiple={true}
                                                style={{ textAlign: 'center' }}
                                                listType="picture-card"
                                                fileList={fileListCustomerStatementPDF}
                                                onPreview={handlePreview}
                                                onChange={handleChangeCustomerStatementPDF}
                                                beforeUpload={() => false}
                                            >
                                                {fileListCustomerStatementPDF?.length >= 10 ? null : (
                                                    <div>
                                                        <PlusOutlined />
                                                        <div className="ant-upload-text">upload</div>
                                                    </div>
                                                )}
                                            </Upload>
                                            <Modal open={previewVisible} onCancel={handleCancel1}>
                                                <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                            </Modal>
                                        </Form.Item>
                                        </b>
                                    </div>
                                </aside>
                            </Row>
                            <Divider></Divider>
                            <div style={{ textAlign: "center" }} >
                                <Space>
                                    <Button type="primary" style={{ background: "red" }} onClick={handleCancel}>
                                        ยกเลิก
                                    </Button>
                                    <Button type="primary" htmlType="submit" style={{ background: "green" }} >
                                        บันทึก
                                    </Button>
                                </Space>
                            </div>
                        </Form>
                    </Spin>
                </div >
            </Card >
            {contextHolder}
        </div >
    )
};

export default EditCustomerDoc