import React, { useEffect, useState } from 'react'
import { Button, notification, Form, Card, Input, Col, Row, Spin, Space, Divider, Select, Table } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import { SearchOutlined, HomeFilled } from '@ant-design/icons';
import ModalEditMK from './ModalChange/ModalEditMK';
import { loadContnoMK } from "../file_mid/all_api";

export default function MainChange() {
    const [form] = Form.useForm()
    const token = localStorage.getItem('token');
    const branch = localStorage.getItem('branch');
    const [api, contextHolder] = notification.useNotification();
    const [axiosData, setAxiosData] = useState([]);
    const [arrayTable, setArrayTable] = useState([]);
    const [loading, setLoading] = useState(false)
    const [dataCont, setDataCont] = useState()
    const [modalCont, setModalCont] = useState(false)

    useEffect(() => {
        console.log("api manager + search-contno-db2")
        loadData()
    }, [modalCont])

    const loadData = async () => {
        if (branch === "MIT" || branch === "UD" || branch === "LEX" || branch === "S4") {
            setLoading(true)
            const tk = JSON.parse(token)
            const headers = {
                "Authorization": `Bearer ${tk}`,
                "Menu": JSON.stringify("5")
            }
            await axios.get(loadContnoMK, { headers: headers })
                // await axios.get("http://localhost:8080/midle/cont-mk", { headers: headers })
                .then(async (res) => {
                    console.log("res.data Conn", res.data)
                    if (res.data) {
                        setArrayTable(res.data)
                        setAxiosData(res.data)

                        // sortedData = res.data.sort((a, b) => new Date(b.inputDate) - new Date(a.inputDate));
                    } else {
                        setArrayTable([])
                        setAxiosData([])
                    }
                }).catch((err) => {
                    console.log(err)
                    alert("ไม่พบข้อมูล !!!")
                })
            setLoading(false)
        } else {
            alert("คุณไม่มีสิทธิ์ใช้งานเมนูนี้ !")
        }
    }

    const onSearch = (data) => {
        if (data && axiosData) {
            const greaterThanTen = axiosData.filter(
                (item) =>
                    // item.snam.toLowerCase().includes(data.target.value) ||
                    // item.firstName.toLowerCase().includes(data.target.value) ||
                    item.CONTNO.toLowerCase().includes(data.target.value)
            );
            setArrayTable(greaterThanTen)
        }
    };

    const columns = [
        {
            title: "ลำดับ",
            dataIndex: "index",
            key: 'index',
            align: 'center',
            //   fixed: true,
            width: "10%",
            render: (text, object, index) => index + 1
        },
        {
            title: "วันที่",
            dataIndex: "SDATE",
            width: "10%",
            align: 'center',
            render: (text, record) => (
                <Space size="middle">
                    {
                        record.SDATE !== "" ?
                            <Space>
                                <>{dayjs(record.SDATE).format("DD-MM-YYYY")}</>
                            </Space>
                            : null
                    }
                </Space>
            ),
            defaultSortOrder: 'descend',
        },
        {
            title: "สัญญา",
            dataIndex: "CONTNO",
            key: 'CONTNO',
            align: 'center',
            width: "30%",
            render: (text, record) => (
                <>
                    <div>เลขสัญญา: {record.CONTNO}</div>
                    <div>การตลาด(เก่า): <Space style={{ color: "red" }}><b>{record.oldMK}</b></Space></div>
                    <div>การตลาด(ใหม่): <Space style={{ color: "red" }}><b>{record.newMK}</b></Space></div>
                </>
            ),
        },
        {
            title: "รายละเอียดการเปลี่ยนการตลาด",
            dataIndex: "inputDate",
            align: 'center',
            width: "20%",
            // sorter: (a, b) => new Date(a.inputDate) - new Date(b.inputDate),
            render: (text, record) => (
                <>
                    {
                        record.inputDate !== "" ?
                            <>
                                <div>วันที่กดเปลี่ยน : <Space style={{ color: "red" }}><b>{dayjs(record.inputDate).format("DD-MM-YYYY")}</b></Space></div>
                                <div>ผู้ที่กดเปลี่ยน : <Space style={{ color: "red" }}><b>{record.inputBy}</b></Space></div>
                            </>
                            : null
                    }
                </>
            ),
        },
        // {
        //     title: "ยอดที่ชำระมาแล้วทั้งหมด",
        //     dataIndex: "SMPAY",
        //     align: 'center',
        //     width: "15%",
        //     render: (text, record) => (
        //         <Space size="middle">
        //             {
        //                 <Space>
        //                     <>{currencyFormatOne(record.SMPAY)}</>
        //                 </Space>
        //             }
        //         </Space>
        //     ),
        // },
        {
            title: "Action",
            key: 'Action',
            align: 'center',
            width: "30%",
            render: (text, record) => {
                return (
                    <>
                        <Space>
                            <Button
                                type="primary"
                                style={{ backgroundColor: "green", width: "50px" }}
                                icon={<SearchOutlined />}
                                onClick={() => {
                                    setModalCont(true)
                                    setDataCont(record)
                                }}
                            >
                            </Button>
                        </Space>
                    </>
                );
            },
        },
    ];

    return (
        <>
            <Card>
                <Row style={{ textAlign: 'center' }}>
                    <Col>
                        <Spin spinning={loading} size='large' tip=" Loading... ">
                            <div className='text-center'>
                                <h2>ตรวจสอบข้อมูลสัญญา(คนออก) ที่เลือกการตลาดไปแล้ว</h2>
                            </div>
                            <Divider></Divider>
                            <Row gutter={32} style={{ margin: 10 }}>
                                <Col className='gutter-row' span={24} style={{ textAlign: 'right' }}>
                                    <Input.Search
                                        style={{ width: '250px' }}
                                        placeholder="ค้นหา...."
                                        onChange={onSearch}
                                        name="search"
                                        id="search"
                                    />
                                </Col>
                            </Row>

                            <Row gutter={32} style={{ margin: 10 }}>
                                <Col span={24}>
                                    <Table
                                        // pagination={false}
                                        rowKey={(record) => record.uid}
                                        scroll={{
                                            x: 1500,
                                            y: 400,
                                        }}
                                        dataSource={arrayTable}
                                        columns={columns}
                                    >
                                    </Table>
                                    <Divider />
                                </Col>
                            </Row>

                            <Col span={24} style={{ textAlign: 'center' }}>
                                <Space>
                                    <Button href="/" type="primary"><HomeFilled />หน้าหลัก</Button>
                                </Space>
                            </Col>
                        </Spin>
                    </Col>
                </Row>
            </Card >
            {
                modalCont ?
                    <ModalEditMK open={modalCont} close={setModalCont} data={dataCont} />
                    : null
            }
            {contextHolder}
        </>
    )
}
