import { Modal, Button, Row, Card, Col } from 'antd'
import React from 'react'
import dayjs from 'dayjs';


function ModalMoreNoteRe({ open, close, datanote }) {

    const handleClose = () => {
        close(false)
    }

    console.log("open", open)
    console.log("datanote", datanote)

    const renderNote = ({ item, index }) => {
        return (
            <>
                <Row gutter={32} justify={'center'}>
                    <Col className='gutter-row' span={24}>
                        <>
                            <Card key={index} style={{ width: '270px', textAlign: 'left' }}>
                                <>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>สาเหตุ : </span>
                                        <b>{item?.cause}</b>
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>สถานะที่ทำการหมายเหตุ : </span>
                                        <b>{item?.status}</b>
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>ผู้สร้างหมายเหตุ : </span>
                                        <b>{item?.noteBy}</b>
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>เมื่อวันที่ : </span>
                                        <b>{dayjs(item?.noteDate).format("DD-MM-YYYY")}</b>
                                    </div>
                                </>
                            </Card>
                        </>
                    </Col>
                </Row>
            </>
        )
    }

    return (
        <Modal open={open} footer={[null]}>
            <Row justify={'left'}>
                <b><u>หมายเหตุ</u></b>
            </Row>
            <Row gutter={32} justify={'center'}>
                {
                    datanote?.map((item, index) => {
                        return renderNote({ item, index })
                    })
                }
            </Row>
            {/* <Row> */}
                {/* <Col> */}
                    <div style={{ textAlign: 'right' }}>
                        <Button onClick={handleClose}>ปิด</Button>
                    </div>
                {/* </Col> */}
            {/* </Row> */}
        </Modal>
    )
}

export default ModalMoreNoteRe