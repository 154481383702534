import React, { useState, useEffect } from 'react'
import { Button, Form, Input, Select, Divider, Row, Col, Modal, Checkbox, Space } from 'antd';
import axios from 'axios';
import { Option } from 'antd/es/mentions';
import { useDispatch, useSelector } from 'react-redux';
import { getdistrict, getsubdistrict, getzipcodes, loadProvinces } from '../../../../../file_mid/all_api';

function GEModalAddAddress({ open, close, fucnAdd, n1, n2, getData, cusIdMain, guaId }) {

    const { customers } = useSelector((state) => ({ ...state }))
    const [formeditdata] = Form.useForm();
    const [editaddress, setEditAddress] = useState({
        customerId: customers.data.customerId2,
        addressId: 0,
        houseRegistrationNumber: '',
        houseNumber: '',
        soi: '',
        road: '',
        village: '',
        subdistrct: '',
        district: '',
        province: '',
        addressOnIdcard: false,
        houseRegistration: false,
        workAddress: false,
        otherAddress: false,
        currentAddress: false,
        sendDocuments: false,
        addressStatus: true,
    });

    const [provinces, setProvinces] = useState([]);
    const [district, setDistricts] = useState([]);
    const [subdistricts, setSubdistricts] = useState([]);
    const [zipcode, setZipCodes] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (customers.data.customerId === undefined) {
            setEditAddress({
                customerId: guaId,
                addressId: 0,
                houseRegistrationNumber: '',
                houseNumber: '',
                soi: '',
                road: '',
                village: '',
                subdistrct: '',
                district: '',
                province: '',
                addressOnIdcard: false,
                houseRegistration: false,
                workAddress: false,
                otherAddress: false,
                currentAddress: false,
                sendDocuments: false,
                addressStatus: true,
            })
        }
        axios.get(loadProvinces)
            .then((res) => {
                setProvinces(res.data)
            }).catch((err) => {
                console.error(err);
            })
    }, [])
    const handleProvinceChange = (value, key) => {
        setLoading(true)
        formeditdata.setFieldsValue({ //set form values
            district: '',
            subdistrict: '',
            zipCode: '',
        })
        setEditAddress({ ...editaddress, province: value });
        axios.get(getdistrict + key.key)
            .then(response => {
                setLoading(false)
                setDistricts(response.data);
            }).catch(error => {
                console.error(error);
            });
    };

    const handleDistrictChange = (value, key) => {
        setLoading(true)
        formeditdata.setFieldsValue({ //set form values
            subdistrict: '',
            zipCode: '',
        })
        setEditAddress({ ...editaddress, district: value });
        axios.get(getsubdistrict + key.key)
            .then(response => {
                setLoading(false)
                setSubdistricts(response.data);
            }).catch(error => {
                console.error(error);
            });
    };
    const handleSubdistrictChange = (value, key) => {
        setLoading(true)
        formeditdata.setFieldsValue({ //set form values
            zipCode: '',
        })
        setEditAddress({ ...editaddress, subdistrict: value });
        axios.get(getzipcodes + key.key)
            .then(response => {
                setLoading(false)
                setZipCodes(response.data);
            }).catch(error => {
                console.error(error);
            });
    };

    const handleZipcodeChange = (value, key) => {
        setEditAddress({ ...editaddress, zipCode: value });
    };


    //checkBox status ของที่อยู่
    const handleCancel = () => {
        close(false);
    };

    //ปุ่ม Submit in Modal Add Data Address
    const handleSubmit = async () => {
        console.log('add', editaddress)
        // n1('top')
        fucnAdd({ editaddress })
        close(false);
    }
    // data input Form 
    const handleInputDataAddress = (e) => {
        const { name, value } = e.target;
        setEditAddress({
            ...editaddress, [name]: value,
        }) // เป็นการกระจายข้อมูลเข้าไปตามชื่อ  name
    }

    return (
        <>
            {/* Modal เพิ่มข้อมูล */}
            <Modal
                open={open}
                centered
                onCancel={handleCancel}
                width={600}
                footer={[null]}
            >
                <Form
                    form={formeditdata}
                    name='add'
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    style={{
                        maxWidth: '100%',
                    }}
                    onFinish={handleSubmit}
                >
                    <Row>
                        <Col span={24} style={{ fontSize: '30px', textAlign: 'center' }} ><u>เพิ่มที่อยู่</u></Col>
                    </Row>
                    <Divider />
                    <Row gutter={{ sx: 8, sm: 16, md: 24, lg: 32 }} >
                        <Col className='gutter-row' span={24}>
                            <Form.Item name='houseRegistrationNumber' label="เลขทะเบียนบ้าน"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input !',
                                    },]} >
                                {/* input Form เลขบัตรประจำตัวประชาชน*/}
                                <Input name='houseRegistrationNumber' placeholder='เลขทะเบียนบ้าน' onChange={handleInputDataAddress} />
                            </Form.Item>
                            <Form.Item name='houseNumber' label='บ้านเลขที่'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input !',
                                    },]}>
                                {/* input Form ชื่อ - นามสกุล*/}
                                <Input name='houseNumber' placeholder='บ้านเลขที่' onChange={handleInputDataAddress} />
                            </Form.Item>
                            <Form.Item name='soi' label='ซอย'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input !',
                                    },]}>
                                {/* input Form บ้านเลขที่  */}
                                <Input name='soi' placeholder='ซอย' onChange={handleInputDataAddress} />
                            </Form.Item>
                            <Form.Item name='road' label='ถนน'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input !',
                                    },]}>
                                <Input name='road' placeholder='ถนน' onChange={handleInputDataAddress} />
                            </Form.Item>
                            <Form.Item name='village' label='หมู่บ้าน'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input !',
                                    },]}>
                                <Input name='village' placeholder="หมู่บ้าน" onChange={handleInputDataAddress} />
                            </Form.Item>
                            <Form.Item
                                name='province'
                                label='จังหวัด'
                            >
                                <Select
                                    showSearch
                                    loading={loading}
                                    name='province'
                                    placeholder="จังหวัด"
                                    onChange={handleProvinceChange}
                                >
                                    {provinces.map((pro, index) => (
                                        <Option key={pro.provinceId} value={pro.provinceName}>
                                            {pro.provinceName}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name='district'
                                label='อำเภอ'
                            >
                                <Select
                                    showSearch
                                    loading={loading}
                                    name='district'
                                    placeholder="อำเภอ"
                                    onChange={handleDistrictChange}
                                >
                                    {district.map((dis, index) => (
                                        <Option key={dis.districtId} value={dis.districtName}>
                                            {dis.districtName}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name='subdistrict'
                                label='ตำบล'
                            >
                                <Select
                                    showSearch
                                    loading={loading}
                                    name='subdistrict'
                                    placeholder='ตำบล'
                                    onChange={handleSubdistrictChange}
                                >
                                    {subdistricts.map((subdis, index) => (
                                        <Option key={subdis.subdistrictId} value={subdis.subdistrictName}>
                                            {subdis.subdistrictName}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name='zipCode'
                                label='รหัสไปรษณีย์'
                            >
                                <Select
                                    name='zipCode'
                                    placeholder="รหัสไปรษณีย์"
                                    onChange={handleZipcodeChange}
                                >
                                    {zipcode.map((zip, index) => (
                                        <Option key={zip.zipcodeId} value={zip.zipCode}>
                                            {zip.zipCode}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Divider><b>เป็นที่อยู่</b></Divider>
                            <Row gutter={32} justify={'center'}>
                                <Checkbox
                                    key={0}
                                    checked={editaddress.addressOnIdcard}
                                    name='addressOnIdcard'
                                    defaultValue={editaddress.addressOnIdcard}
                                    // label='ตามบัตรประชาชน'
                                    onChange={(e) => {
                                        setEditAddress({ ...editaddress, addressOnIdcard: e.target.checked });

                                    }}
                                >"ตามบัตรประชาชน"</Checkbox>
                                <Checkbox
                                    key={1}
                                    checked={editaddress.houseRegistration}
                                    name='houseRegistration'
                                    defaultValue={editaddress.houseRegistration}
                                    // label='ตามทะเบียนบ้าน'
                                    onChange={(e) => {
                                        setEditAddress({ ...editaddress, houseRegistration: e.target.checked });
                                    }}
                                >"ตามทะเบียนบ้าน"</Checkbox>
                                <Checkbox
                                    key={2}
                                    checked={editaddress.workAddress}
                                    name='workAddress'
                                    defaultValue={editaddress.workAddress}
                                    // label='ตามสถานที่ทำงาน'
                                    onChange={(e) => {
                                        setEditAddress({ ...editaddress, workAddress: e.target.checked });
                                    }}
                                >"ตามสถานที่ทำงาน"</Checkbox>
                                <Checkbox
                                    key={3}
                                    checked={editaddress.otherAddress}
                                    name='otherAddress'
                                    defaultValue={editaddress.otherAddress}
                                    // label='อื่นๆ'
                                    onChange={(e) => {
                                        setEditAddress({ ...editaddress, otherAddress: e.target.checked });
                                    }}
                                >"อื่นๆ"</Checkbox>
                                <Checkbox
                                    key={4}
                                    checked={editaddress.currentAddress}
                                    name='currentAddress'
                                    defaultValue={editaddress.currentAddress}
                                    // label='อื่นๆ'
                                    onChange={(e) => {
                                        setEditAddress({ ...editaddress, currentAddress: e.target.checked });
                                    }}
                                >"ที่อยู่ปัจจุบัน"</Checkbox>
                                <Checkbox
                                    key={5}
                                    checked={editaddress.sendDocuments}
                                    name='sendDocuments'
                                    defaultValue={editaddress.sendDocuments}
                                    // label='อื่นๆ'
                                    onChange={(e) => {
                                        setEditAddress({ ...editaddress, sendDocuments: e.target.checked });
                                    }}
                                >"ที่อยู่ส่งเอกสาร"</Checkbox>
                                <Checkbox
                                    key={6}
                                    checked={editaddress.addressStatus}
                                    name='addressStatus'
                                    defaultValue={editaddress.addressStatus}
                                    // label='อื่นๆ'
                                    onChange={(e) => {
                                        setEditAddress({ ...editaddress, addressStatus: e.target.checked });
                                    }}
                                >"ที่อยู่ที่ใช้งานหรือไม่"</Checkbox>
                            </Row>
                            <Divider />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <Space>
                                <Button type="primary" onClick={handleCancel} style={{ background: "red" }}>ยกเลิก</Button>
                                <Button type="primary" htmlType="submit" style={{ background: "green" }} >บันทึก</Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    )
};

export default GEModalAddAddress
