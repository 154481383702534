import React, { useEffect, useState } from 'react'
import { Modal, Col, Row, Space, notification, Steps } from 'antd';
import axios from 'axios';

import { useDispatch, useSelector } from 'react-redux';
import { addCustomer } from '../../../../redux/Customer';
import GECustomer from './GECustomer';
import GEAddress from './GEAddress';
import GEPhone from './GEPhone';
import GEDetail from './GEDetail';

export default function ModalEditGuarantor({ open, close, dataindex, shootdata, fucnEdit, getCarId }) {

    const { customers } = useSelector((state) => ({ ...state }))
    const [cusIdMain, setcusIdMain] = useState(customers.data); //มาจากรีดัค
    const [guaId, setguaId] = useState(shootdata.dataedit.customerId); //เก็บค่า id จากการ edit

    const [getIden, setGetIden] = useState(shootdata.dataedit.identificationId)
    const [dataGua, setDataGua] = useState(shootdata.dataedit)
    const [getData, setGetData] = useState({});
    const [loading, setLoading] = useState(false)
    const [dataAddress, setDataAddress] = useState([]);
    const [dataPhone, setDataPhone] = useState([]);

    const [api, contextHolder] = notification.useNotification();
    const dispatch = useDispatch()
    const [current, setCurrent] = useState(0);
    const { confirm } = Modal

    console.log("shootdata", shootdata)
    useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {
        await axios.post(`https://garfe-i67f6gaaqa-as.a.run.app/api/getguarantorsbyiden/${getIden}`)
            .then((res) => {
                if (res.status === 200) {
                    // console.log("res.data",res.data)
                    setGetData(res.data)
                    setDataAddress(res.data.dataAddress)
                    setDataPhone(res.data.dataPhones)
                    setLoading(false)
                }
                else {
                }
            }).catch((err) => console.log(err))
        setLoading(false)
    };


    const next = () => {
        setCurrent(current + 1);
    };
    const prev = () => {
        setCurrent(current - 1);
    };
    const steps = [
        {
            title: 'ข้อมูลส่วนตัว',
        },
        {
            title: 'รายละเอียดที่อยู่',
        },
        {
            title: 'ช่องทางการติดต่อ',
        },
        {
            title: 'สรุปรายละเอียด/บันทึกข้อมูล',
        },
    ];

    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
    }));

    const InsertSuccess = (placement) => {
        api.success({
            message: <b>เพิ่มข้อมูลสำเร็จ</b>,
            placement,
        });
    }
    const EditSuccess = (placement) => {
        api.success({
            message: <b>แก้ไขข้อมูลสำเร็จ</b>,
            placement,
        });
    }
    const DeleteSuccess = (placement) => {
        api.success({
            message: <b>ลบข้อมูลสำเร็จ</b>,
            placement,
        });
    }
    const CheckAddressER = (placement) => {
        api.error({
            message: <b>เพิ่มข้อมูลไม่สำเร็จ</b>,
            description: 'กรุณาเพิ่มที่อยู่ อย่างน้อย 1 ที่',
            placement,
        });
    }
    const CheckPhoneER = (placement) => {
        api.error({
            message: <b>เพิ่มข้อมูลไม่สำเร็จ</b>,
            description: 'กรุณาเพิ่มเบอร์โทรศัพท์ อย่างน้อย 1 เบอร์',
            placement,
        });
    }
    const CheckGuER = (placement) => {
        api.error({
            message: <b>เพิ่มข้อมูลไม่สำเร็จ</b>,
            description: 'พบข้อมูลซ้ำกันระหว่างผู้กู้และผู้ค้ำ กรุณาเปลี่ยนผู้ค้ำ',
            placement,
        });
    }

    const showConfirm = () => {
        confirm({
            title: 'คุณต้องการที่ปิดหน้าต่างข้อมูลผู้กู้หรือไม่...?',
            content: 'หากกดปิดหน้าต่างผู้กู้ข้อมูลจะไม่ถูกบันทึก กด OK เพื่อยืนยัน',
            onOk() {
                Onclose();
            },
            onCancel() {
            },
        });
    };

    const onChange = (value) => {
        setCurrent(value);
    };

    const Onclose = () => {
        close(false);
        dispatch(addCustomer(cusIdMain))
    };

    return (
        <Modal title="แก้ไขข้อมูล" open={open} onCancel={showConfirm} width={1200} footer={null} >
            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                <Row>
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <Steps
                            current={current}
                            onChange={onChange}
                            items={[
                                {
                                    title: 'ข้อมูลส่วนตัว',
                                },
                                {
                                    title: 'รายละเอียดที่อยู่',
                                },
                                {
                                    title: 'ช่องทางการติดต่อ',
                                },
                                {
                                    title: 'สรุปรายละเอียด/บันทึกข้อมูล',
                                },
                            ]}
                        />
                    </Col>
                </Row>
                {current === 0 && (<GECustomer close={showConfirm} getData={getData} dataGua={dataGua} next={next} chg={CheckGuER} cusIdMain={cusIdMain} />)}
                {current === 1 && (<GEAddress close={showConfirm} getData={dataAddress} n1={InsertSuccess} e1={EditSuccess} d1={DeleteSuccess} next={next} prev={prev} cusIdMain={cusIdMain} guaId={guaId} />)}
                {current === 2 && (<GEPhone close={showConfirm} getData={dataPhone} n1={InsertSuccess} e1={EditSuccess} d1={DeleteSuccess} next={next} prev={prev} chp={CheckPhoneER} cusIdMain={cusIdMain} guaId={guaId} />)}
                {current === 3 && (<GEDetail close={showConfirm} getData={getData} dataGua={dataGua} n1={InsertSuccess} e1={EditSuccess} d1={DeleteSuccess} prev={prev} fucnEdit={fucnEdit} saveclose={Onclose} dataindex={dataindex} cha={CheckAddressER} chp={CheckPhoneER} cusIdMain={cusIdMain} />)}
            </Space>
            {contextHolder}
        </Modal>
    )
};
