import React, { useState, useEffect } from 'react'
import { Button, Form, Input, Select, Divider, Row, Col, Modal, Checkbox, Space, Spin } from 'antd';
import axios from 'axios';
import { Option } from 'antd/es/mentions';
// import dayjs from 'dayjs';
import { addaddress, getdistrict, getsubdistrict, getzipcodes, loadProvinces } from '../../../../file_mid/all_api';

function ModalAddAddress({ open, close, fucnAdd, CusId, notification3, notification4 }) {

    const [form] = Form.useForm();
    // const [messageApi, contextHolder] = message.useMessage();
    const [addressadd, setAddressAdd] = useState({
        customerId: CusId,
        houseRegistrationNumber: '',
        houseNumber: '',
        soi: '',
        road: '',
        village: '',
        subdistrct: '',
        district: '',
        province: '',
        addressOnIdcard: false,
        houseRegistration: false,
        workAddress: false,
        otherAddress: false,
        currentAddress: false,
        sendDocuments: false,
        addressStatus: false,
    });

    const [provinces, setProvinces] = useState([]);
    const [district, setDistricts] = useState([]);
    const [subdistricts, setSubdistricts] = useState([]);
    const [zipcode, setZipCodes] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        axios.get(loadProvinces)
            .then((res) => {
                setProvinces(res.data)
            }).catch((err) => {
                console.error(err);
            })
    }, [])

    const handleProvinceChange = (value, key) => {
        // console.log(key, value)
        setLoading(true)
        form.setFieldsValue({ //set form values
            district: '',
            subdistrict: '',
            zipCode: '',
        })
        setAddressAdd({ ...addressadd, province: value });
        axios.get(getdistrict + key.key)
            .then(response => {
                setLoading(false)
                setDistricts(response.data);
            }).catch(error => {
                console.error(error);
            });
    };

    const handleDistrictChange = (value, key) => {
        // console.log(key, value)
        setLoading(true)
        form.setFieldsValue({ //set form values
            subdistrict: '',
            zipCode: '',
        })
        setAddressAdd({ ...addressadd, district: value });
        axios.get(getsubdistrict + key.key)
            .then(response => {
                setLoading(false)
                setSubdistricts(response.data);
            }).catch(error => {
                console.error(error);
            });
    };
    const handleSubdistrictChange = (value, key) => {
        // console.log(key, value)
        setLoading(true)
        form.setFieldsValue({ //set form values
            zipCode: '',
        })
        setAddressAdd({ ...addressadd, subdistrict: value });
        axios.get(getzipcodes + key.key)
            .then(response => {
                setLoading(false)
                setZipCodes(response.data);
            }).catch(error => {
                console.error(error);
            });
    };

    const handleZipcodeChange = (value, key) => {
        // console.log(key, value)
        setAddressAdd({ ...addressadd, zipCode: value });
    };

    //checkBox status ของที่อยู่
    const handleCancel = () => {
        close(false);
    };

    //ปุ่ม Submit in Modal Add Data Address
    const handleSubmit = async () => {
        // console.log('add', addressadd)
        // ต่อไปบันทึกเข้า Database
        setLoading(true)
        await axios.post(addaddress, addressadd)
            .then(res => {
                if (res.status === 200) {
                    fucnAdd({ addressadd })
                    notification3('top')
                    setLoading(false)
                    close(false);
                }
            }).catch((err) => {
                console.log("err", err)
                if (err.response.request.status === 400) {
                    // notification4('top')
                    setLoading(false)
                    // messageApi.open({
                    //     type: 'error',
                    //     content: 'บันทึกไม่สำเร็จ',
                    // });
                }
                console.log("err", err)
            })

        // setTimeout(() => {
        //     setuseDisabled(false)
        // }, 1000);

        setLoading(false)
    }
    // data input Form 
    const handleInputDataAddress = (e) => {
        const { name, value } = e.target;
        setAddressAdd({
            ...addressadd, [name]: value,
        }) // เป็นการกระจายข้อมูลเข้าไปตามชื่อ  name
    }

    return (
        <>
            {/* Modal เพิ่มข้อมูล */}
            <Modal
                open={open}
                centered
                onCancel={handleCancel}
                width={600}
                footer={[null]}
            >
                <Spin spinning={loading} size='large' tip=" Loading... ">
                    <Form
                        form={form}
                        name='fucnAdd'
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                        style={{
                            maxWidth: '100%',
                        }}
                        onFinish={handleSubmit}>
                        <Row>
                            <Col span={24} style={{ fontSize: '30px', textAlign: 'center' }} ><u>เพิ่มที่อยู่</u></Col>
                        </Row>
                        <Divider />
                        <Row gutter={{ sx: 8, sm: 16, md: 24, lg: 32 }} >
                            <Col className='gutter-row' span={24}>
                                <Form.Item name='houseRegistrationNumber' label="เลขทะเบียนบ้าน"
                                >
                                    {/* input Form เลขบัตรประจำตัวประชาชน*/}
                                    <Input name='houseRegistrationNumber' placeholder='เลขทะเบียนบ้าน' onChange={handleInputDataAddress} />
                                </Form.Item >
                                <Form.Item name='houseNumber' label='บ้านเลขที่'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    {/* input Form ชื่อ - นามสกุล*/}
                                    <Input name='houseNumber' placeholder='บ้านเลขที่' onChange={handleInputDataAddress} />
                                </Form.Item>
                                <Form.Item name='soi' label='ซอย'
                                >
                                    {/* input Form บ้านเลขที่  */}
                                    <Input name='soi' placeholder='ซอย' onChange={handleInputDataAddress} />
                                </Form.Item>
                                <Form.Item name='road' label='ถนน'
                                >
                                    <Input name='road' placeholder='ถนน' onChange={handleInputDataAddress} />
                                </Form.Item>
                                <Form.Item name='village' label='หมู่บ้าน'
                                >
                                    <Input name='village' placeholder="หมู่บ้าน" onChange={handleInputDataAddress} />
                                </Form.Item>

                                <Form.Item
                                    name='province'
                                    label='จังหวัด'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}
                                >
                                    <Select
                                        showSearch
                                        loading={loading}
                                        name='province'
                                        placeholder="จังหวัด"
                                        onChange={handleProvinceChange}
                                    >
                                        {provinces.map((pro, index) => (
                                            <Option key={pro.provinceId} value={pro.provinceName}>
                                                {pro.provinceName}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name='district'
                                    label='อำเภอ'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}
                                >
                                    <Select
                                        showSearch
                                        loading={loading}

                                        name='district'
                                        placeholder="อำเภอ"
                                        onChange={handleDistrictChange}
                                    >
                                        {district.map((dis, index) => (
                                            <Option key={dis.districtId} value={dis.districtName}>
                                                {dis.districtName}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name='subdistrict'
                                    label='ตำบล'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}
                                >
                                    <Select
                                        showSearch
                                        loading={loading}
                                        name='subdistrict'
                                        placeholder='ตำบล'
                                        onChange={handleSubdistrictChange}
                                    >
                                        {subdistricts.map((subdis, index) => (
                                            <Option key={subdis.subdistrictId} value={subdis.subdistrictName}>
                                                {subdis.subdistrictName}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>


                                <Form.Item
                                    name='zipCode'
                                    label='รหัสไปรษณีย์'
                                >
                                    <Select
                                        name='zipCode'
                                        placeholder="รหัสไปรษณีย์"
                                        onChange={handleZipcodeChange}
                                    // value={databyid.Subdistrct}
                                    >
                                        {zipcode.map((zip, index) => (
                                            <Option key={zip.zipcodeId} value={zip.zipCode}>
                                                {zip.zipCode}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>

                                <Divider><b>เป็นที่อยู่</b></Divider>
                                <Row gutter={32} justify={'center'}>
                                    <Checkbox
                                        key={0}
                                        checked={addressadd.addressOnIdcard}
                                        name='addressOnIdcard'
                                        defaultValue={addressadd.addressOnIdcard}
                                        // label='ตามบัตรประชาชน'
                                        onChange={(e) => {
                                            setAddressAdd({ ...addressadd, addressOnIdcard: e.target.checked });

                                        }}
                                    >"ตามบัตรประชาชน"</Checkbox>
                                    <Checkbox
                                        key={1}
                                        checked={addressadd.houseRegistration}
                                        name='houseRegistration'
                                        defaultValue={addressadd.houseRegistration}
                                        // label='ตามทะเบียนบ้าน'
                                        onChange={(e) => {
                                            setAddressAdd({ ...addressadd, houseRegistration: e.target.checked });
                                        }}
                                    >"ตามทะเบียนบ้าน"</Checkbox>
                                    <Checkbox
                                        key={2}
                                        checked={addressadd.workAddress}
                                        name='workAddress'
                                        defaultValue={addressadd.workAddress}
                                        // label='ตามสถานที่ทำงาน'
                                        onChange={(e) => {
                                            setAddressAdd({ ...addressadd, workAddress: e.target.checked });
                                        }} setAddressAdd
                                    >"ตามสถานที่ทำงาน"</Checkbox>
                                    <Checkbox
                                        key={3}
                                        checked={addressadd.otherAddress}
                                        name='otherAddress'
                                        defaultValue={addressadd.otherAddress}
                                        // label='อื่นๆ'
                                        onChange={(e) => {
                                            setAddressAdd({ ...addressadd, otherAddress: e.target.checked });
                                        }}
                                    >"อื่นๆ"</Checkbox>
                                    <Checkbox
                                        key={4}
                                        checked={addressadd.currentAddress}
                                        name='currentAddress'
                                        defaultValue={addressadd.currentAddress}
                                        // label='อื่นๆ'
                                        onChange={(e) => {
                                            setAddressAdd({ ...addressadd, currentAddress: e.target.checked });
                                        }}
                                    >"ที่อยู่ปัจจุบัน"</Checkbox>
                                    <Checkbox
                                        key={5}
                                        checked={addressadd.sendDocuments}
                                        name='sendDocuments'
                                        defaultValue={addressadd.sendDocuments}
                                        // label='อื่นๆ'
                                        onChange={(e) => {
                                            setAddressAdd({ ...addressadd, sendDocuments: e.target.checked });
                                        }}
                                    >"ที่อยู่ส่งเอกสาร"</Checkbox>
                                    <Checkbox
                                        key={6}
                                        checked={addressadd.addressStatus}
                                        name='addressStatus'
                                        defaultValue={addressadd.addressStatus}
                                        // label='อื่นๆ'
                                        onChange={(e) => {
                                            setAddressAdd({ ...addressadd, addressStatus: e.target.checked });
                                        }}
                                    >"ที่อยู่ที่ใช้งานหรือไม่"</Checkbox>
                                </Row>
                                <Divider />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} style={{ textAlign: 'center' }}>
                                <Space>
                                    <Button type="primary" onClick={handleCancel} style={{ background: "red" }}>ยกเลิก</Button>
                                    <Button type="primary" htmlType="submit" style={{ background: "green" }}>บันทึก</Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
                {/* {contextHolder} */}
            </Modal>
        </>
    )
};

export default ModalAddAddress
