import { Button, notification, Divider, Form, Modal, Space, Spin } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import React, { useState } from 'react'
import axios from 'axios';
import dayjs from 'dayjs';
import { insertcase } from '../../../file_mid/all_api';
import { ExclamationCircleFilled } from '@ant-design/icons';

function NoteToCEO({ open, close, closeAll, getData, onChangeStatus, status }) {

    const user = localStorage.getItem('username');
    const [api, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(false)
    const [dataNote, setDataNote] = useState({
        noteBy: user,
        status: "15",
        noteDate: dayjs(),
    })
    const { confirm } = Modal;

    const handleClose = () => {
        close(false)
    }
    
    const handleSubmit = async () => {
        setLoading(true);
        const urlRequestApproval = insertcase;
        await axios
            .post(urlRequestApproval, {
                CONTNO: getData?.CONTNO,
                offerBy: user,
                proposalBy: getData?.SALCOD, //แก้กลับเป็น getData.SALCOD
                reviewedBy: getData?.CHECKER, //แก้กลับเป็น getData.CHECKER
                notesCarsApprove: dataNote
            })
            .then(async (resReq) => {
                if (resReq.data) {
                    onChangeStatus({ ...getData, ApprovalStatus: "2" }, status);
                    if (resReq.status === 200) {
                        successSend("top")
                        setTimeout(() => {
                            closeAll()
                        }, 2000)
                    }
                }
            })
            .catch((err) => {
                errorSend("top")
                console.log(err)
                setLoading(false);
            });
    }

    const showConfirm = () => {
        confirm({
            title: "คำร้องขออนุมัติ",
            icon: <ExclamationCircleFilled />,
            content: "คุณต้องการส่งคำร้องขออนุมัติรีไฟแนนซ์ใช่หรือไม่",
            okText: "ยืนยัน",
            cancelText: "ยกเลิก",
            onOk() {
                handleSubmit()
            },
            onCancel() {
                // console.log("Cancel");
            },
        });
    };

    const handleChangeNote = (e) => {
        setDataNote({ ...dataNote, note: e })
    }

    const successSend = (placement) => {
        api.success({
            message: <b>ส่งเคส {getData?.CONTNO} สำเร็จ</b>,
            placement,
        });
    }

    const errorSend = (placement) => {
        api.error({
            message: <b>ส่งเคส {getData?.CONTNO} ไม่สำเร็จ</b>,
            placement,
        });
    }

    return (
        <Modal open={open} footer={[null]} closable={false} title="ส่งอนุมัติรีไฟแนนซ์">
            <Spin spinning={loading} size='large' tip=" Loading... ">
                <Form onFinish={showConfirm}>
                    <Form.Item label="หมายเหตุถึง CEO" name="NoteToCEO"
                        rules={[{
                            required: true,
                            message: 'กรุณากรอกหมายเหตุถึง CEO',
                        }]}>
                        <TextArea onChange={(e) => { handleChangeNote(e.target.value) }} rows={4} />
                    </Form.Item>
                    <Divider />
                    <div style={{ textAlign: 'center' }}>
                        <Space>
                            <Button type='primary' danger onClick={handleClose} >ยกเลิก</Button>
                            <Button type='primary' htmlType='submit' style={{ backgroundColor: 'green' }}>ยืนยัน</Button>
                        </Space>
                    </div>
                </Form>
            </Spin>
            {contextHolder}
        </Modal>
    )
}

export default NoteToCEO