import React, { useEffect, useState } from 'react'
import { Form, Button, Checkbox, Modal, Card, Row, Col, Input, Select, Table, Spin } from 'antd';
import axios from 'axios'
import { editpermisuser, showallrole, updatepermisuser } from '../file_mid/all_api';

export default function EditRoleUser({ open, close, oData }) {
    const [form] = Form.useForm();
    const [result, setResult] = useState(oData);
    const [dataRole, setDataRole] = useState();
    const [count, setCount] = useState(false);
    const [dataR, setDataR] = useState();
    const [selectedRole, setSelectedRole] = useState(null);
    const [loading, setLoading] = useState(false)
    const token = localStorage.getItem('token')
    const menu = localStorage.getItem('menu')
    const tk = JSON.parse(token)

    useEffect(() => {
        setResult(oData)
        loadData()
    }, [oData])

    const loadData = async (e) => {
        setLoading(true)
        const headers = {
            "Authorization": `Bearer ${tk}`,
            "Menu": JSON.stringify("16")
        }
        await axios.get(showallrole, { headers: headers })
            .then((res) => {
                setDataRole(res.data)
                setLoading(false)
            })
            .catch((err) => console.log(err))
            setLoading(false)
    }

    const success = () => {
        Modal.success({
            content: 'บันทึกสำเร็จ',
        })
    }
    const error = () => {
        Modal.error({
            title: 'บันทึกไม่สำเร็จ',
            content: 'กรุณาลองใหม่อีกครั้ง',
        })
    }

    const onChangeRole = (e) => {
        setResult({ ...result, roleName: e })
        //console.log("*result*", e)
    }

    const onFinish = async () => {
        setLoading(true)
        delete result.roleId
        delete result.permission
        const permis = Object.assign({}, dataSource.filter(item => item.menuId))
        const newData = {};
        const headers = {
            "Authorization": `Bearer ${tk}`,
            "Menu": JSON.stringify("16")
        }
        for (const key in permis) {
            const item = permis[key];
            const menuName = item.key;
            const permissions = {
                read: item.read,
                write: item.write,
                delete: item.delete,
            };
            newData[menuName] = permissions;
        }
        const selectedData1 = { ...dataR, permission: newData }
        if (count === false) {
            const update = { user: result, role: { roleName: result.roleName } }
            console.log("count", count)
            //console.log("result", result)
            //console.log("update", update)
            await axios.post(editpermisuser, update, { headers: headers })
                .then(res => {
                    if (res.status === 200) {
                        success()
                        setLoading(false)
                    }
                    else {
                        error()
                        setLoading(false)
                    }
                })
                .catch((err) => error())
                setLoading(false)
            close(false)
        }
        else {
            console.log("count", count)
            //console.log("สิทธิ์", selectedData1)
            //console.log("result", result)
            const upsert = { user: result, role: { roleName: selectedData1.name, permission: selectedData1.permission } }
            //console.log("upsert", upsert)
            await axios.post(updatepermisuser, upsert, { headers: headers })
                .then(res => {
                    if (res.status === 200) {
                        success()
                        setLoading(false)
                    }
                    else {
                        error()
                        setLoading(false)
                    }
                })
                .catch((err) => error())
                setLoading(false)
            close(false)
        }
        setLoading(false)
    };
    const handleCancel = () => {
        close(false)
    };
    const onCheckbox = (e) => {
        setCount(e.target.checked)
        setSelectedRole(null)
        setResult({ ...result, roleName: '' })
        form.setFieldsValue(
            {
                roleName: '',
            }
        )
    }

    const data = [
        {
            key: 1,
            menu: 'home',
            menuId: true, 
            read: true,
            //read: false,
            write: false,
            delete: false,
        },
        {
            key: 2,
            menu: 'เสนอเคส',
            read: false,
            write: false,
            delete: false,
        },
        {
            key: 3,
            menu: 'ตรวจสอบข้อมูล',
            read: false,
            write: false,
            delete: false,
        },
        {
            key: 4,
            menu: 'ตรวจสอบข้อมูลธุรการ',
            read: false,
            write: false,
            delete: false,
        },
        {
            key: 5,
            menu: 'ตรวจสอบข้อมูลพื่กั้ง',
            read: false,
            write: false,
            delete: false,
        },
        {
            key: 6,
            menu: 'ตรวจสอบข้อมูลพื่หนุ่ม',
            read: false,
            write: false,
            delete: false,
        },
        {
            key: 7,
            menu: 'ตรวจสอบข้อมูลผู้จัดการ',
            read: false,
            write: false,
            delete: false,
        },
        {
            key: 8,
            menu: 'ตรวจสอบข้อมูลบอส',
            read: false,
            write: false,
            delete: false,
        },
        {
            key: 9,
            menu: 'บันทึกประวัติลูกค้า',
            read: false,
            write: false,
            delete: false,
        },
        {
            key: 10,
            menu: 'บันทึกทรัพย์สิน',
            read: false,
            write: false,
            delete: false,
        },
        {
            key: 11,
            menu: 'บันทึกสัญญาเงินกู้',
            read: false,
            write: false,
            delete: false,
        },
        {
            key: 12,
            menu: 'สอบถามข้อมูล Blacklist',
            read: false,
            write: false,
            delete: false,
        },
        {
            key: 13,
            menu: 'สอบถามข้อมูลประวัติลูกค้า',
            read: false,
            write: false,
            delete: false,
        },
        {
            key: 14,
            menu: 'สอบถามข้อมูลลูกหนี้เงินกู้',
            read: false,
            write: false,
            delete: false,
        },
        {
            key: 15,
            menu: 'บันทึกชำระประจำวัน',
            read: false,
            write: false,
            delete: false,
        },
        {
            key: 16,
            menu: 'พิมพ์ใบเสร็จรับเงิน',
            read: false,
            write: false,
            delete: false,
        },
        {
            key: 17,
            menu: 'สมัครสมาชิก',
            read: false,
            write: false,
            delete: false,
        },
        {
            key: 18,
            menu: 'กำหนดสิทธิ์',
            read: false,
            write: false,
            delete: false,
        },
        {
            key: 19,
            menu: 'แก้ไขสิทธิ์',
            read: false,
            write: false,
            delete: false,
        },
        {
            key: 20,
            menu: 'memo',
            read: false,
            write: false,
            delete: false,
        },
        {
            key: 21,
            menu: 'ตรวจสอบเอกสาร',
            read: false,
            write: false,
            delete: false,
        },
        {
            key: 22,
            menu: 'อนุมัติเรทรถ',
            read: false,
            write: false,
            delete: false,
        },
    ];
    const [dataSource, setDataSource] = useState(data);
    const handleCheckboxChange = (e, key, type) => {
        //console.log("e", e.target.checked, "key", key, "type", type)
        const updatedDataSource = dataSource.map((item) => {
            if (item.key === key) {
                if (type === "menuId" && !e.target.checked) {
                    return {
                        ...item,
                        menuId: e.target.checked,
                        //menuId: key,
                        read: false,
                        write: false,
                        delete: false,
                    };
                }
                return {
                    ...item,
                    menuId: key,
                    [type]: e.target.checked,
                };
            }
            return item;
        })
        //console.log("update", updatedDataSource)
        setDataSource(updatedDataSource)
    }

    const columns = [
        {
            title: "ลำดับ",
            dataIndex: "index",
            key: 'index',
            width: 80,
            align: 'center',
            render: (text, object, index) => index + 1
        },
        {
            title: 'เลือกเมนู',
            dataIndex: 'menuId',
            key: 'menuId',
            width: 80,
            align: 'center',
            render: (text, record) => (
                <Checkbox
                    checked={record.menuId}
                    onChange={(e) => handleCheckboxChange(e, record.key, 'menuId')}
                />
            ),
        },
        {
            title: 'Menu',
            dataIndex: 'menu',
            key: 'menu',
        },
        {
            title: 'read',
            dataIndex: 'read',
            key: 'read',
            render: (text, record) => (
                <Checkbox
                    disabled={record.menuId ? false : true}
                    checked={record.read}
                    onChange={(e) => handleCheckboxChange(e, record.key, 'read')}
                />
            ),
        },
        {
            title: 'write',
            dataIndex: 'write',
            key: 'write',
            render: (text, record) => (
                <Checkbox
                    disabled={record.menuId ? false : true}
                    checked={record.write}
                    onChange={(e) => handleCheckboxChange(e, record.key, 'write')}
                />
            ),
        },
        {
            title: 'delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Checkbox
                    disabled={record.menuId ? false : true}
                    checked={record.delete}
                    onChange={(e) => handleCheckboxChange(e, record.key, 'delete')}
                />
            ),
        },
    ];
    return (
        <Card style={{ padding: 20 }}>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: 0,
                marginBottom: 30,
            }}>
                <Modal title="แก้ไขข้อมูล" open={open} width={900} onCancel={handleCancel}
                    footer={[
                        <Button form="myForm" key="submit" htmlType="submit">
                            ยืนยัน
                        </Button>,
                        <Button onClick={handleCancel} key="close">
                            ยกเลิก
                        </Button>
                    ]}
                >
                    <Spin spinning={loading} size='large' tip=" Loading... ">
                        <Form id="myForm"
                            name="basic"
                            labelCol={{
                                span: 8,
                            }}
                            wrapperCol={{
                                span: 12,
                            }}
                            initialValues={{
                                remember: true,
                            }}
                            form={form}
                            onFinish={onFinish}
                            autoComplete="off"
                        >
                            <Row>
                                <Col span={24}>
                                    <Form.Item label="ชื่อ" name="username">
                                        {result.username}
                                    </Form.Item>

                                    {/* <Form.Item label="สิทธิ์เดิม" name="roleName">
                                    {result.roleName}
                                </Form.Item> */}

                                    <Form.Item label='เลือกสิทธิ์' name='roleName'>
                                        <Select
                                            allowClear
                                            disabled={count ? true : false}
                                            onChange={onChangeRole}
                                            optionFilterProp="children"
                                            placeholder="Search to Select"
                                            filterOption={(inputValue, option) =>
                                                option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                                            }
                                            value={selectedRole}
                                        >
                                            {dataRole?.map((e) => {
                                                return (
                                                    <Select.Option key={e.roleId} value={e.roleName}>
                                                        {e.roleName}
                                                    </Select.Option>
                                                )
                                            })}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item label="เลือก" name="checkRole">
                                        <Checkbox onChange={onCheckbox}>กำหนดเอง</Checkbox>
                                    </Form.Item>

                                    {count === true && (
                                        <Form.Item label='ชื่อสิทธิ์' name='name'>
                                            <Input type='text' onChange={(e) => setDataR({ ...dataR, name: e.target.value })}></Input>
                                        </Form.Item>
                                    )}

                                    {count === true && (
                                        <Table dataSource={dataSource} columns={columns} />
                                    )}
                                </Col>
                            </Row>
                        </Form>
                    </Spin>
                </Modal>
            </div>
        </Card>
    )
}
