import React, { useEffect, useState } from 'react'
import { Button, Input, Form, DatePicker, Card, Col, Row, Select, Radio, Space, Divider, Upload, Modal, Image, message, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { addCustomer } from '../../../../redux/Customer';
import { addImage } from '../../../../redux/Image';
import Compressor from 'compressorjs'
import { showrelationship, storeDBLine, uploadImgGuaTable, getFolderGuaImg, deleteImgGua } from '../../../file_mid/all_api';

function EditCustomerGua({ next, close, theData, setSendback, indexedit }) {
    const un = localStorage.getItem('username');
    const token = localStorage.getItem('token');
    const guarantors = useSelector(state => state.guarantor)
    const [fileListGuarantors, setFileListGuarantors] = useState([]);
    const [fileListGuarantors1, setFileListGuarantors1] = useState([]);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [loading, setLoading] = useState(false);
    const customers = useSelector(state => state.customers)
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [dataPost, setDataPost] = useState({})
    const [value, setValue] = useState([])
    const [getimgs, setGetImgs] = useState([]);
    const [sendbacks, setSendbacks] = useState()
    const { confirm } = Modal

    useEffect(() => {
        loadRelationship()
        // console.log("1",customers.data)
        // console.log("2",theData)
        if (customers?.data?.customerId !== undefined) {
            // console.log("1")
            setDataPost(customers?.data)
            form.setFieldsValue(
                {
                    ...customers?.data,
                    birthdate: customers.data.birthDate ? dayjs(customers?.data?.birthDate) : "",
                    issudt: customers.data.issudt ? dayjs(customers?.data?.issudt) : "",
                    expdt: customers.data.expdt ? dayjs(customers?.data?.expdt) : "",
                }
            )
        } else {
            // console.log("2")
            setDataPost(theData)
            form.setFieldsValue(
                {
                    ...theData,
                    birthdate: theData.birthDate ? dayjs(theData?.birthDate) : "",
                    issudt: theData.issudt ? dayjs(theData?.issudt) : "",
                    expdt: theData.expdt ? dayjs(theData?.expdt) : "",

                }
            )
        }
        if (theData?.pathImg) {
            getImg()
        }

    }, [theData, sendbacks])

    const getImg = async () => {
        var path = theData?.pathImg
        setLoading(true)
        axios.get(`${getFolderGuaImg}/${path}?token=${token}`)
            .then(res => {
                if (res.data) {
                    console.log("res.dataGE", res.data)
                    setGetImgs(res.data)
                    dispatch(addImage(res.data))
                    setFileListGuarantors([])
                    setFileListGuarantors1([])
                    setLoading(false)
                }
            })
            .catch((err) => {
                console.log("err", err)
                setLoading(false)
            })
        setLoading(false)
    }

    const loadRelationship = async () => {
        await axios.get(showrelationship)
            .then(res => {
                if (res.status === 200) {
                    setValue(res.data)
                }
            }).catch((err) => console.log(err))
    };


    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
    };
    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };
    const handleCancel1 = () => {
        setPreviewVisible(false);
    }

    const handleChangeGuarantors1 = async ({ fileList }) => {
        var itemSplit = []
        setLoading(true)
        // console.log("theData.pathImg", theData)
        if (theData.pathImg === "") {
            itemSplit[4] = indexedit + 1
            // itemSplit[4] = theData.garno
        } else {
            itemSplit = theData.pathImg.split("/")
        }
        // if (theData.pathImg) {
        //     itemSplit = theData.pathImg.split("/")
        // }
        setFileListGuarantors(fileList);
        var imageListGuarantors = []
        var i = 0
        for (const file of fileList) {
            const compressedFile = await new Promise((resolve) => {
                new Compressor(file.originFileObj, {
                    quality: 0.1, // เช่น, 60%
                    success: (result) => {
                        resolve(result);
                    },
                });
            });
            var image = {}
            var filename = file.name
            var image64 = await getBase64(compressedFile)
            image.filename = filename
            image.image64 = image64
            image.type = `5.${itemSplit[4]}`
            imageListGuarantors.push(image)
            i++
        }
        setFileListGuarantors1({ ...fileListGuarantors1, imageListGuarantors });
        setLoading(false)
    };

    // console.log("fileListGuarantors1", fileListGuarantors1)

    const handleNoti = async (path) => {
        const ez = {nameInput: un, path: path}
        await axios.post(storeDBLine, ez)
          .then(res => {
            if (res.data) {
              console.log("OK")
              setLoading(false)
            }
          })
          .catch((err) =>
            console.log(err)
          )
        setLoading(false)
      }

    const showConfirmdel = async (e) => {
        const id = e.idImage
        const path = e.pathImage
        console.log("id", id)
        console.log("path", path)
        confirm({
            title: 'คุณต้องการที่จะลบรูปภาพนี้ใช่หรือไม่...?',
            onOk() {
                // setSendback(55)
                axios.delete(`${deleteImgGua}/${e.pathImage}`)
                    .then(async res => {
                        console.log("res delete", res)
                        await handleNoti(path)
                        setSendback(dayjs())
                        setSendbacks(dayjs())
                    })
                    .catch((err) =>
                        console.log(err)

                    )

            },
            onCancel() {
                message.error('ยกเลิกการลบรูปภาพ');
            },
        });

    };

    const handleSubmit = async (e) => {
        console.log("dataPost", dataPost)
        const imageListGuarantorPush1 = [];
        if (fileListGuarantors1?.imageListGuarantors) {
            imageListGuarantorPush1.push(...fileListGuarantors1?.imageListGuarantors)
        }
        if (dataPost.typeLoan === "car") {
            var id = 1
        } else {
            var id = 2
        }
        const InputImage = {
            mainImg: { carId: dataPost?.carlandId.toString(), carInput: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"), productTypeId: id, img: imageListGuarantorPush1 }
        }
        await axi(InputImage)
        // delete dataPost.address
        // delete dataPost.phones
        // dispatch(addCustomer(dataPost))
        // onChangeGo()
    }

    console.log("dataPost", dataPost)
    // var makeNewPath = `one_images/${theData.typeLoan}/${theData.carlandId}/5/${theData.garno}`

    const axi = async (InputImage) => {
        setLoading(true)
        await axios.post(uploadImgGuaTable, InputImage)
            .then(response => {
                if (response) {
                    console.log("response", response)
                    if (dataPost.pathImg === "") {
                        dispatch(addCustomer({ ...dataPost, pathImg: `one_images/${dataPost.typeLoan}/${dataPost.carlandId}/5/${indexedit + 1}` }))
                    } else {
                        dispatch(addCustomer(dataPost))
                    }

                    setLoading(false)
                    setSendbacks(dayjs())
                    message.success('บันทึกสำเร็จ')
                }
            }).catch((err) => {
                console.log("err", err)
                setLoading(false)
            })
    }

    const onChangeGo = () => {
        next()
    }

    const handleCancel = () => {
        close()
    }

    const changeDescription = (value) => {
        setDataPost({ ...dataPost, description: value })
    }

    return (
        <Card >
            <Spin spinning={loading} size='large' tip=" Loading... ">
                <div>
                    <div>
                        <Row>
                            <Col span={24} style={{ fontSize: '30px', textAlign: 'center' }}>ข้อมูลส่วนตัว</Col>
                        </Row>
                    </div>
                    <Divider></Divider>
                    <Form
                        labelCol={{
                            span: 12,
                        }}
                        wrapperCol={{
                            span: 12,
                        }}
                        form={form}
                        onFinish={handleSubmit}
                        autoComplete="off">
                        <Row>
                            <Col span={10}>
                                <Form.Item label='เลขประจำตัวประชาชน' name='identificationId'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <Input type='text' maxLength={13} disabled
                                    // onChange={(e) => inputIdCard(e)}
                                    >
                                    </Input>
                                </Form.Item>
                                <Form.Item label='รูปบัตรประชาชน'>
                                    <Input type="file" multiple={true} disabled
                                    // onChange={handleFileInputChange} 
                                    >
                                    </Input>
                                </Form.Item>
                                <Form.Item label="คำนำหน้าชื่อ" name="snam"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <Radio.Group onChange={(e) => setDataPost({ ...dataPost, snam: e.target.value })} >
                                        <Radio value="นาย"> นาย </Radio>
                                        <Radio value="นาง"> นาง </Radio>
                                        <Radio value="นางสาว"> นางสาว </Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item label='ชื่อ' name='firstname'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}
                                >
                                    <Input type='text'
                                        onChange={(e) => setDataPost({ ...dataPost, firstname: e.target.value })}></Input>
                                </Form.Item>
                                <Form.Item label='นามสกุล' name='lastname'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <Input type='text'
                                        onChange={(e) => setDataPost({ ...dataPost, lastname: e.target.value })}></Input>
                                </Form.Item>

                                <Form.Item label='ชื่อเล่น' name='nickname'>
                                    <Input type='text'
                                        onChange={(e) => setDataPost({ ...dataPost, nickname: e.target.value })}></Input>
                                </Form.Item>
                            </Col>
                            <Col span={2}>
                                <Form.Item>
                                    <Button type="primary" disabled
                                    // onClick={(e) => { handleIdCard(e) }}
                                    >ตรวจสอบ</Button>
                                </Form.Item>
                            </Col>
                            <Col span={10}>
                                {/* <Form.Item label='ประเทศ' name='nationalId'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <Input type='text'
                                        onChange={(e) => setDataPost({ ...dataPost, nationalId: e.target.value })}></Input>
                                </Form.Item> */}
                                <Form.Item label='เกิดวันที่' name='birthdate'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <DatePicker format={'YYYY-MM-DD'} style={{ width: '100%' }}
                                        onChange={(e) => {
                                            if (e) {
                                                setDataPost({ ...dataPost, birthdate: e.format('YYYY-MM-DD') })
                                            }
                                        }} />
                                </Form.Item>
                                <Form.Item label='เพศ' name='gender'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <Select
                                        placeholder={'เลือก'}
                                        style={{
                                            width: '100%',
                                        }}
                                        onChange={(value) => setDataPost({ ...dataPost, gender: value })}
                                        options={[
                                            {
                                                label: 'ชาย',
                                                value: 'M',
                                            },
                                            {
                                                label: 'หญิง',
                                                value: 'F',
                                            },
                                        ]} />
                                </Form.Item>
                                <Form.Item label='เจ้าพนักงานออกบัตร' name='issuby'
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: 'Please input !',
                                //     },]}
                                >
                                    <Input type='text'
                                        onChange={(e) => setDataPost({ ...dataPost, issuby: e.target.value })}></Input>
                                </Form.Item>
                                <Form.Item label='วันออกบัตร' name='issudt'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <DatePicker format={'YYYY-MM-DD'} style={{ width: '100%' }}
                                        onChange={(e) => {
                                            if (e) {
                                                setDataPost({ ...dataPost, issudt: e.format('YYYY-MM-DD') })
                                            }
                                        }} />
                                </Form.Item>
                                <Form.Item label='วันบัตรหมดอายุ' name='expdt'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <DatePicker format={'YYYY-MM-DD'} style={{ width: '100%' }}
                                        onChange={(e) => {
                                            if (e) {
                                                setDataPost({ ...dataPost, expdt: e.format('YYYY-MM-DD') })
                                            }
                                        }} />
                                </Form.Item>
                                {/* <Form.Item label='ความสัมพันกับผู้กู้' name='description'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input !',
                                    },]}>
                                <Input type='text'
                                    onChange={(e) => setDataPost({ ...dataPost, description: e.target.value })}></Input>
                            </Form.Item> */}
                                <Form.Item label='ความสัมพันธ์กับผู้กู้' name='description'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !'
                                        },]}>
                                    <Select
                                        placeholder="เลือกความสัมพันธ์"
                                        onChange={changeDescription}
                                        options={value}
                                    />
                                </Form.Item>

                            </Col>
                            <Divider />
                            <div>
                                <Row justify={'left'} > <span><b>เพิ่มเอกสารผู้ค้ำ :</b></span></Row>
                                <Row justify={'center'}>
                                    <Form.Item
                                        label=''
                                        name='imgGuarantor'
                                        style={{ textAlign: 'center' }}
                                    >
                                        <Upload
                                            multiple={true}
                                            listType="picture-card"
                                            fileList={fileListGuarantors}
                                            onPreview={handlePreview}
                                            onChange={handleChangeGuarantors1}
                                            // onChange={handleChangeGuarantor}
                                            beforeUpload={() => false}
                                        >
                                            {fileListGuarantors?.length >= 10 ? null : (
                                                <div>
                                                    <PlusOutlined />
                                                    <div className="ant-upload-text">upload</div>
                                                </div>
                                            )}
                                        </Upload>
                                        <Modal open={previewVisible} onCancel={handleCancel1}>
                                            <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                        </Modal>
                                    </Form.Item>
                                </Row>
                            </div>
                            <Divider />
                            <div>
                                <Row style={{ marginLeft: 5 }}><span><b>รูปเอกสารผู้ค้ำ :</b></span></Row>
                                <Row justify={'center'}>
                                    {getimgs?.map((items, index) => {
                                        return (
                                            <Row gutter={32} >
                                                <Col span={24} >
                                                    <Image width={'100px'} key={index} src={`data:${items?.Attr};base64,${items?.data?.toString('base64')}`} alt={`Image ${index + 1}`} />
                                                    <p><Button onClick={() => showConfirmdel(items, index)} type='primary' danger>ลบรูปภาพ</Button></p>
                                                </Col>
                                            </Row>
                                        )
                                        // }

                                    })}
                                </Row>

                                <Divider />
                            </div>
                        </Row>
                        <div style={{ textAlign: "center" }}>
                            <Space>
                                <Button type="primary" style={{ background: "red" }} onClick={handleCancel}>ยกเลิก</Button>
                                <Button type="primary" htmlType="submit" style={{ background: "green" }}>บันทึก</Button>
                                <Button type="primary" onClick={onChangeGo}>ต่อไป</Button>
                            </Space>
                        </div>
                    </Form>
                </div>
            </Spin>
        </Card>
    )
};


export default EditCustomerGua