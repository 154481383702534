import { Button, Card, Col, Row, Space, Spin, Input } from 'antd'
import TextArea from 'antd/es/input/TextArea';
import Modal from 'antd/es/modal/Modal'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';




const SendtoPage = ({ open, close, data }) => {
    const { confirm } = Modal
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false)
    const { TextArea } = Input;


    console.log("data",data)

    const showConfirmCancel = () => {
        confirm({
            title: 'คุณต้องการยกเลิก...?',
            onOk() {
                Onclose();
            },
            onCancel() {
            },
        });
    };
    const sendtopage = () => {
        // Navigate to the desired page, passing the contract number as a parameter
        navigate(`/checkInfoCar`, {
            state: {
                customerId: data?.customerId,
                carId: data?.carId,
                firstname: data?.firstname,
                lastname: data?.lastname,
                open: true,
                approvalStatus: 'อนุมัติ'
            }
        })

    };

    const Onclose = () => {
        close(false);

    };



    return (
        <>
            <Modal title="เสนอเคสต่อ" width={'90%'} style={{ textAlign: 'center' }} open={open} footer={null} onCancel={showConfirmCancel}>
                <Spin tip="Loading..." spinning={loading} >
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <Card>
                            <Row justify={'center'} style={{ marginBottom: 15, background: '#e0e0e0' }} className='center'>
                                <Col span={24}>
                                    <b>
                                        หากต้องการเสนอเคสต่อ  สามารถกดปุ่มบันทึกเพื่อเสนอเคสต่อได้เลย หรือเข้าไปที่เมนู 'รถ'
                                    </b>
                                </Col>
                            </Row>
                            <Row style={{ textAlign: 'left', fontSize: '14px' }}>
                                <b>
                                    <div>
                                        เลขที่สัญญา : {data?.ISSUNO}
                                    </div>
                                    <div>
                                        ชื่อ - นามสกุล : {data?.snam + " " + data?.firstname + " " + data?.lastname}
                                    </div>
                                    <div>
                                        สถานะ : {data?.approvalStatus}
                                    </div>
                                </b>
                            </Row>
                        </Card>
                        <Row justify={'center'} >
                            <Space>
                                <Button type="primary" style={{ background: "red" }} onClick={showConfirmCancel}>
                                    ยกเลิก
                                </Button>
                                <Button type="primary" onClick={sendtopage} style={{ background: "green" }} >
                                    บันทึก
                                </Button>
                            </Space>
                        </Row>
                    </Col>

                </Spin>
            </Modal>
        </>
    )
}

export default SendtoPage