import React, { useState, useEffect } from "react";
import {
  Col,
  Input,
  Row,
  Table,
  Spin,
  Space,
  Card,
  Divider,
  Select,
  message,
  Progress,
  Tag,
} from "antd";
import { InfoCircleFilled, InfoCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import "../css/MobileActive.css";
import "../css/buttonNickie.css";
import { acceptceo, loadMainBoss } from "../file_mid/all_api";
import Approval from "./modal/Approval";
import moment from "moment";

const Main_Boss = () => {
  const user = localStorage.getItem("username");
  const [arrayTable, setArrayTable] = useState();
  const [totalPage, setTotalPage] = useState();
  const [isModalApproval, setIsModalApproval] = useState(false);
  const [dataArr, setDataArr] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  const [countStatus, setCountStatus] = useState(2);
  const [dataSearch, setDataSearch] = useState([]);
  const [dataSend, setDataSend] = useState({});
  const { Option } = Select;
  const [selectedValue, setSelectedValue] = useState(0);
  const [dataContno, setDataContno] = useState();
  const [statusApproval, setStatusApproval] = useState({
    waitAccept: 0,
    approval: 0,
    approved: 0,
    percentWaitAccept: 0,
    percentApproval: 0,
    percentApproved: 0,
  });

  useEffect(() => {
    if (selectedValue === 0) {
      loadData();
      console.log("Main_Boss1");
    }
  }, [selectedValue]);

  const search = (event) => {
    console.log("Main_Boss2");
    onSearch(event.target.value);
  };

  const onSearch = (value) => {
    if (selectedValue === 0) {
      let result = dataArr.filter((item) => item.CONTNO.includes(value));
      // console.log("result", result);
      setArrayTable(result);
    } else {
      let result = dataSearch.filter((item) => item.CONTNO.includes(value));
      // console.log("result", result);
      setArrayTable(result);
    }
  };

  const handleSelectedStatus = (value) => {
    if (value === 6) {
      let statusAll = dataArr.filter((item) => item.approvalStatus === 6 || item.approvalStatus === 12 );
      setArrayTable(statusAll);
      setDataSearch(statusAll);
    } else {
      let statusAll = dataArr.filter((item) => item.approvalStatus === value);
      setArrayTable(statusAll);
      setDataSearch(statusAll);
    }
  };

  const onSelect = (value) => {
    // console.log("v", value)
    setSelectedValue(value);
    if (value !== 0) {
      handleSelectedStatus(value);
    } else {
      loadData();
      console.log("Main_Boss3");
    }
  };

  const handleChangeStatus = (data, status) => {
    // console.error("TEST", data);
    if (status !== 0) {
      const result = dataArr.map((item) => {
        if (item.CONTNO === data.CONTNO) {
          return { ...data };
        } else {
          return { ...item };
        }
      });
      setDataArr(result);
      handleStatusApproval(result);
      const arr = result.filter((item) => item.approvalStatus === status);
      setArrayTable(arr);
    } else {
      loadData();
      console.log("handleChangeStatus loadData");
    }
  };

  const loadData = async (data) => {
    setLoading(true);
    const tk = JSON.parse(token);
    const url = loadMainBoss;
    const statusParams = { approvalStatus: countStatus };
    const headers = {
      Authorization: `Bearer ${tk}`,
      Menu: JSON.stringify("8"),
    };

    await axios
      .post(url, { statusParams }, { headers: headers })
      .then(async (res) => {
        if (res.data) {
          console.log(res.data.data);
          let result = res.data.data.filter(
            (item) =>
              item.approvalStatus === 6 ||
              item.approvalStatus === 5 ||
              item.approvalStatus === 2 ||
              item.approvalStatus === 12
          );
          // console.log("result", result);
          setArrayTable(result);
          defaultStatusApproval(result);
          setDataArr(result);
          setTotalPage(result.length);
        }
      })
      .catch((err) => console.log(err));

    setLoading(false);
  };

  const sendStatus = async (data, contno) => {
    setLoading(true);
    const tk = JSON.parse(token);
    const url = acceptceo;
    const headers = {
      Authorization: `Bearer ${tk}`,
      Menu: JSON.stringify("8"),
    };

    await axios
      .post(
        url,
        {
          //แก้กลับไป
          id: data.id,
          acceptBy: user,
          CONTNO: data.CONTNO,
          offerBy: data.offerBy,
          proposalBy: data.proposalBy,
        },
        { headers: headers }
      )
      .then(async (res) => {
        handleChangeStatus({ ...data, approvalStatus: 5 }, selectedValue);
        message.success(`รับงานสำเร็จ  เลขที่สัญญาที่: ${contno}  `);
        setTimeout(() => { }, 2000);
      })
      .catch((err) => console.log(err));

    setLoading(false);
  };

  const defaultStatusApproval = (data) => {
    if (data) {
      let countWaitAccept = 0;
      let countApproval = 0;
      let countApproved = 0;

      data.map((item) => {
        if (item.approvalStatus === 2) {
          countWaitAccept++;
        } else if (item.approvalStatus === 5) {
          countApproval++;
        } else if (item.approvalStatus === 6) {
          countApproved++;
        } else if (item.approvalStatus === 12) {
          countApproved++;
        }
      });

      let waitAcceptItems = data.filter((item) => item.approvalStatus === 2);
      let totalItemswaitAccept = data.length;
      let resultWaitAccept =
        (waitAcceptItems.length / totalItemswaitAccept) * 100;

      if (resultWaitAccept === 0) {
        resultWaitAccept = 100;
      } else if (resultWaitAccept === 100) {
        resultWaitAccept = 0;
      }
      let approvalItems = data.filter((item) => item.approvalStatus === 5);
      let totalItemsapproval = data.length;
      let resultApproval = (approvalItems.length / totalItemsapproval) * 100;
      if (resultApproval === 0) {
        resultApproval = 100;
      } else if (resultApproval === 100) {
        resultApproval = 0;
      }

      let approvedItems = data.filter(
        (item) => item.approvalStatus === 6 || item.approvalStatus === 12
      );
      console.log(approvedItems);
      let totalItemsapproved = data.length;
      let resultApproved = (approvedItems.length / totalItemsapproved) * 100;
      if (resultApproved === 0) {
        resultApproved = 100;
      } else if (resultApproved === 100) {
        resultApproved = 0;
      }

      setStatusApproval({
        waitAccept: countWaitAccept,
        approval: countApproval,
        approved: countApproved,
        percentWaitAccept: resultWaitAccept.toFixed(2),
        percentApproval: resultApproval.toFixed(2),
        percentApproved: resultApproved.toFixed(2),
      });
    }
  };

  const handleStatusApproval = (data) => {
    if (data) {
      let countWaitAccept = 0;
      let countApproval = 0;
      let countApproved = 0;
      data.map((item) => {
        if (item.approvalStatus === 2) {
          countWaitAccept++;
        } else if (item.approvalStatus === 5) {
          countApproval++;
        } else if (item.approvalStatus === 6) {
          countApproved++;
        } else if (item.approvalStatus === 12) {
          countApproved++;
        }
      });

      let waitAcceptItems = data.filter((item) => item.approvalStatus === 2);
      let totalItemswaitAccept = data.length;
      let resultWaitAccept =
        (waitAcceptItems.length / totalItemswaitAccept) * 100;
      if (resultWaitAccept === 0) {
        resultWaitAccept = 100;
      } else if (resultWaitAccept === 100) {
        resultWaitAccept = 0;
      }

      let approvalItems = data.filter((item) => item.approvalStatus === 5);
      let totalItemsapproval = data.length;
      let resultApproval = (approvalItems.length / totalItemsapproval) * 100;
      if (resultApproval === 0) {
        resultApproval = 100;
      } else if (resultApproval === 100) {
        resultApproval = 0;
      }

      let approvedItems = data.filter((item) => item.approvalStatus === 6 || item.approvalStatus === 12);
      console.log(approvedItems);
      let totalItemsapproved = data.length;
      let resultApproved = (approvedItems.length / totalItemsapproved) * 100;
      if (resultApproved === 0) {
        resultApproved = 100;
      } else if (resultApproved === 100) {
        resultApproved = 0;
      }

      setStatusApproval({
        ...statusApproval,
        waitAccept: countWaitAccept,
        approval: countApproval,
        approved: countApproved,
        percentWaitAccept: resultWaitAccept.toFixed(2),
        percentApproval: resultApproval.toFixed(2),
        percentApproved: resultApproved.toFixed(2),
      });
      console.log("setStatusApproval2", statusApproval);
    }
  };

  const columns = [
    {
      title: "ลำดับ",
      dataIndex: "id",
      key: "id",
      align: "center",
      width: "5%",
      render: (text, record, index) => index + 1,
      sorter: (a, b) => a.id - b.id,
    },

    {
      title: "เลขที่สัญญา",
      dataIndex: "CONTNO",
      key: "CONTNO",
      align: "center",
      width: "15%",
      render: (text, record) => {
        return <>{record.CONTNO ? record.CONTNO : "-"}</>;
      },
    },
    {
      title: "วันที่เสนอเคส",
      dataIndex: "offerDate",
      key: "offerDate",
      align: "center",
      width: "10%",
      render: (text, record) => {
        return (
          <>
            <>
              วันที่{" "}
              {moment(record.offerDate).add(543, "year").format("DD/MM/YYYY ")}
            </>
            <br />
            <>เวลา {moment(record.offerDate).format("HH:mm:ss ")}</>
          </>
        );
      },
      sorter: (a, b) => moment(a.offerDate).unix() - moment(b.offerDate).unix(),
    },
    {
      title: "ผู้รับผิดชอบ",
      dataIndex: "offerBy",
      key: "offerBy",
      align: "center",
      width: "30%",
      render: (text, record) => (
        <>
          <b>ผู้เสนอเคส :</b> {record.offerBy ? record.offerBy : "-"}
          <br />
          <b>การตลาด :</b> {record.proposalBy ? record.proposalBy : "-"}
          <br />
          <b>ธุรการ :</b> {record.reviewedBy ? record.reviewedBy : "-"}
          <br />
          <b>ผู้อนุมัติ :</b> {record.approvedBy ? record.approvedBy : "-"}
        </>
      ),
    },
    {
      title: "วันที่อนุมัติ",
      dataIndex: "approvalDate",
      key: "approvalDate",
      align: "center",
      width: "10%",
      render: (text, record) => (
        <div>
          วันที่{" "}
          {record.approvalDate
            ? moment(record.approvalDate).add(543, "year").format("DD/MM/YYYY ")
            : "-"}
          <br />
          เวลา{" "}
          {record.approvalDate
            ? moment(record.approvalDate).format("HH:mm:ss")
            : "-"}
        </div>
      ),
      sorter: (a, b) =>
        moment(a.approvalDate).unix() - moment(b.approvalDate).unix(),
    },

    {
      title: "สถานะสัญญา/การจัดการ",
      dataIndex: "ApprovalStatus",
      key: "ApprovalStatus",
      align: "center",
      width: "30%",
      // sorter: {
      //   compare: (a, b) => a.ApprovalStatus.length - b.ApprovalStatus.length,
      //   multiple: 5,
      // },
      render: (text, record) => (
        <>
          {record.approvalStatus === 6 || record.approvalStatus === 12 ? (
            <button
              className="myButtonGreen "
              onClick={() => {
                setDataSend(record);
                setDataContno(record.CONTNO);
                setIsModalApproval(true);
                console.log("อนุมัติแล้ว", record);
              }}
            >
              อนุมัติแล้ว
            </button>
          ) : record.approvalStatus === 5 ? (
            <button
              className="myButtonYellow "
              onClick={() => {
                setDataSend(record);
                setDataContno(record.CONTNO);
                setIsModalApproval(true);
                console.log("รออนุมัติ", record);
              }}
            >
              รออนุมัติ
            </button>
          ) : record.approvalStatus === 2 ? (
            <button
              className="myButtonOriginal "
              onClick={() => {
                sendStatus(record, record.CONTNO);
                console.log("รับงาน", record);
              }}
            >
              รอรับงาน
            </button>
          ) : null}
        </>
      ),
    },
  ];

  return (
    <>
      <Card>
        <Row gutter={32} style={{ textAlign: "center" }}>
          <Col>
            <Spin spinning={loading} size="large" tip=" Loading... ">
              <Row gutter={32} style={{ textAlign: "center" }}>
                <Col>
                  <div className="text-center">
                    <h2>ข้อมูลการขออนุมัติ</h2>
                  </div>
                </Col>
              </Row>
              <Row gutter={24} style={{ margin: 10 }}>
                <Col>
                  <Tag
                    style={{ backgroundColor: "#f5f5f5", margin: "5px" }}
                    onClick={() => {
                      handleSelectedStatus(2);
                      setSelectedValue(2);
                    }}
                  >
                    <b>{"รอรับงาน "}</b>
                    {statusApproval.waitAccept
                      ? statusApproval.waitAccept
                      : "0"}{" "}
                    <b>เคส</b>
                  </Tag>
                  <Progress
                    title="รอรับงาน"
                    percent={statusApproval.percentWaitAccept}
                    size="small"
                  />
                </Col>
                <Col>
                  <Tag
                    style={{ backgroundColor: "yellow", marginTop: "5px" }}
                    onClick={() => {
                      handleSelectedStatus(5);
                      setSelectedValue(5);
                    }}
                  >
                    <b>{"รออนุมัติ "}</b>{" "}
                    {statusApproval.approval ? statusApproval.approval : "0"}{" "}
                    <b>เคส</b>
                  </Tag>
                  <Progress
                    title="รออนุมัติ"
                    percent={statusApproval.percentApproval}
                    size="small"
                  />
                </Col>
                <Col>
                  <Tag
                    style={{ backgroundColor: "#72f70d", margin: "5px" }}
                    onClick={() => {
                      handleSelectedStatus(6);
                      setSelectedValue(6);
                    }}
                  >
                    <b>{"อนุมัติแล้ว"}</b>{" "}
                    {statusApproval.approved ? statusApproval.approved : "0"}{" "}
                    <b>เคส</b>
                  </Tag>
                  <Progress
                    title="อนุมัติแล้ว"
                    percent={statusApproval.percentApproved}
                    size="small"
                  />
                </Col>
                <Col>
                  <Tag
                    style={{
                      backgroundColor: "#18ffff",
                      margin: "5px",
                      fontWeight: "bold",
                    }}
                    onClick={() => {
                      loadData();
                      setSelectedValue(0);
                      console.log("Main_Boss4");
                    }}
                  >
                    {`เคสทั้งหมด ${totalPage} เคส`}
                  </Tag>
                </Col>
                <Col
                  className="gutter-row"
                  span={24}
                  style={{ textAlign: "right" }}
                >
                  <Space wrap style={{ marginRight: "5px" }}>
                    <Select
                      defaultValue="ทั้งหมด"
                      style={{
                        width: 120,
                      }}
                      value={selectedValue}
                      onChange={onSelect}
                      options={[
                        {
                          value: 0,
                          label: "ทั้งหมด",
                        },
                        {
                          value: 6,
                          label: "อนุมัติแล้ว",
                        },
                        {
                          value: 5,
                          label: "รออนุมัติ",
                        },
                        {
                          value: 2,
                          label: "รอรับงาน",
                        },
                      ]}
                    />
                  </Space>
                  <Input.Search
                    style={{ width: "250px" }}
                    placeholder="ค้นหา...."
                    onChange={search}
                    name="search"
                    id="search"
                  />
                </Col>
              </Row>
              <Row gutter={32}>
                <Col span={24}>
                  <Table
                    columns={columns}
                    Key={(record) => record.uid}
                    scroll={{
                      x: 1500,
                      y: 400,
                    }}
                    dataSource={arrayTable}
                  ></Table>
                  <Divider />
                  <div style={{ textAlign: "center" }}>
                    <p>
                      <InfoCircleOutlined
                        title="สีบอกไม่ผ่านการอนุมัติ"
                        style={{ marginRight: "3px" }}
                      />{" "}
                      รอรับงาน{" "}
                      <InfoCircleFilled
                        title="สีบอกรออนุมัติ"
                        style={{
                          color: "yellow",
                          marginRight: "3px",
                          marginLeft: "3px",
                        }}
                      />{" "}
                      รออนุมัติ{" "}
                      <InfoCircleFilled
                        title="อนุมัติ"
                        style={{
                          color: "#64FF00",
                          marginRight: "3px",
                          marginLeft: "3px",
                        }}
                      />{" "}
                      อนุมัติแล้ว{" "}
                    </p>
                    <b>จำนวนทั้งหมด {totalPage + " เคส"}</b>
                  </div>
                  <Row justify={"center"}></Row>
                  <Divider />
                </Col>
              </Row>
            </Spin>
          </Col>
        </Row>
      </Card>
      {isModalApproval ? (
        <Approval
          open={isModalApproval}
          close={setIsModalApproval}
          data={dataSend}
          contno={dataContno}
          onChangeStatus={handleChangeStatus}
          status={selectedValue}
        />
      ) : null}
    </>
  );
};

export default Main_Boss;
