import React from "react";
import ExcelJS from "exceljs";
import { Button } from "antd";

class ExportExcel extends React.Component {
  generateExcel = () => {
    const { columns, dataSource } = this.props;
    // สร้าง workbook
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("My Sheet");

    // เพิ่มข้อมูลคอลัมน์ลงใน worksheet
    worksheet.columns = columns.map((column) => ({
      header: column.title, // ใช้ชื่อคอลัมน์ columns
      key: column.dataIndex, // ใช้ dataIndex เป็น key ของคอลัมน์
      width: column.width || 10, // กำหนดความกว้างของคอลัมน์ (หากไม่ระบุให้ใช้ค่าเริ่มต้นเป็น 10)
    }));

    // เพิ่มข้อมูลลงใน worksheet
    dataSource.forEach((data) => {
      worksheet.addRow(data);
    });

    // สร้างไฟล์ Excel
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "รายงานสรุปเคสขอยอดที่ดินตามพนักงาน.xlsx";
      a.click();
    });
  };

  render() {
    return (
      <Button
        type="primary"
        danger
        //onClick={handleExport}
        onClick={this.generateExcel}
        style={{
          backgroundColor: "green",
          borderColor: "green",
          margin: "0 2px 4px",
        }}
      >
        Excel
      </Button>
    );
  }
}
export default ExportExcel;
