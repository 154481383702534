import React, { useState, useEffect } from 'react'
import { Modal, Form, Button, Card, Divider, Tag, Spin, Row, Col } from 'antd'
import axios from 'axios';
import dayjs from 'dayjs';
import { accept, checkcarad } from '../../file_mid/all_api';

function ModalAccept({ open, dataFromTable, close, Success, RateCar, RateLand }) {
    const [loading, setLoading] = useState(false);
    const [dataModalEdit, setDataModalEdit] = useState(dataFromTable);
    const [statusAccept, setstatusAccept] = useState({
        customerId: '', carId: '', loanId: '', approvalStatus: '',
    });
    const [checkOld, setCheckOld] = useState(false);
    const [oldData, setOldData] = useState();
    const [oldNote, setOldNote] = useState();
    const [getSize, setGetSize] = useState(500);

    useEffect(() => {
        setDataModalEdit(dataFromTable)
        if (dataFromTable.car === "car") {
            loadData()
        }
        pickData();
    }, [dataFromTable])

    const handleCancel = () => {
        close(false);
    };

    // console.log("dataFromTable",dataFromTable)

    const currencyFormatOne = (amount) => {
        return Number(amount)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }

    const loadData = async () => {
        // setLoading(true)
        await axios.post(checkcarad, { carPlateNumber: dataFromTable.carPlateNumber, carProvince: dataFromTable.carProvince })
            .then(res => {
                if (res.status === 200) {
                    if (res.data !== null) {
                        setCheckOld(true)
                        setGetSize(1000)
                        setOldData(res.data)
                        // console.log("loadData", res.data)
                        var indexnote = 0
                        if (res.data.notes.length > 0) {
                            indexnote = res.data.notes.length - 1
                            setOldNote(res.data.notes[indexnote])
                        }
                    }
                }
            }).catch((err) => console.log(err))
    };

    const handleOk = async () => {
        // console.log("statusAccept", statusAccept)
        setLoading(true)
        await axios.put(accept, statusAccept)
            .then(res => {
                if (res.status === 200) {
                    if (dataFromTable.carPriceStatus === false) {
                        if (dataFromTable.car === "car") {
                            RateCar("top")
                        } else {
                            RateLand("top")
                        }
                        // setLoading(false)
                    } else {
                        Success("top")
                        // setLoading(false)
                    }
                    setTimeout(() => {
                        close(false);
                    }, 1000);
                }
            }).catch((err) => console.log(err))
        // setLoading(false)
    };

    const pickData = () => {
        if (dataFromTable.car === "car") {
            if (dataFromTable.carPriceStatus === false) {
                setstatusAccept({
                    customerId: dataFromTable.customerId,
                    carId: dataFromTable.carId,
                    loanId: dataFromTable.loanId,
                    car: dataFromTable.car,
                    approvalStatus: "รออนุมัติราคารถ",

                })
            } else {
                setstatusAccept({
                    customerId: dataFromTable.customerId,
                    carId: dataFromTable.carId,
                    loanId: dataFromTable.loanId,
                    car: dataFromTable.car,
                    // set เวลา
                    acceptDate: dayjs(),
                    approvalStatus: "รอวิเคราะห์"
                })
            }
        } else if (dataFromTable.car === "land") {
            setstatusAccept({
                customerId: dataFromTable.customerId,
                carId: dataFromTable.carId,
                loanId: dataFromTable.loanId,
                car: dataFromTable.car,
                // carDateStatus: formattedTime,

                // set เวลา
                acceptDate: dayjs(),
                approvalStatus: "รอวิเคราะห์"
            })
        }
    }

    return (
        <div>
            <Modal title="การรับงาน" open={open} onCancel={handleCancel} width={getSize}
                footer={[

                    <Button type='primary' onClick={handleOk} disabled={loading} style={{ backgroundColor: "green" }}>ยืนยัน</Button>,
                    <Button type='primary' danger onClick={handleCancel}>ปิด</Button>
                ]}>
                <Card>
                    {
                        dataModalEdit?.car === "car" ?
                            <Divider orientation="left"><b>รายละเอียดรถ</b></Divider>
                            :
                            <Divider orientation="left"><b>รายละเอียดที่ดิน</b></Divider>
                    }
                    <Form
                        name="basic"
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}>
                        {checkOld === true ?
                            <>
                                <Row>
                                    <Col span={12}>
                                        <Form.Item label="ชื่อลูกค้า" style={{ marginBottom: 0 }}>
                                            {dataModalEdit?.snam}{dataModalEdit?.firstName} {dataModalEdit?.lastName}
                                        </Form.Item>

                                        <Form.Item label="เลขทะเบียนรถ" style={{ marginBottom: 0 }}>
                                            {dataModalEdit?.carPlateNumber} {dataModalEdit?.carProvince}
                                        </Form.Item>

                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="รายละเอียด" style={{ marginBottom: 0 }}>
                                            {dataModalEdit?.carBrand} {dataModalEdit?.carModel} {dataModalEdit?.carYear}
                                        </Form.Item>
                                        <Form.Item label="เรท" style={{ marginBottom: 0 }}>
                                            {currencyFormatOne(dataModalEdit?.carPrice)}
                                        </Form.Item>
                                        <Form.Item label="สถานะ" style={{ marginBottom: 0 }}>
                                            <Tag color="gold">{dataModalEdit?.approvalStatus}</Tag>
                                            <Tag color="green">{dataModalEdit?.productLoanType}</Tag>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                            :
                            <>
                                <Form.Item label="ชื่อลูกค้า" style={{ marginBottom: 0 }}>
                                    {dataModalEdit?.snam}{dataModalEdit?.firstName} {dataModalEdit?.lastName}
                                </Form.Item>
                                {dataModalEdit?.car === "car" ? (
                                    <>
                                        <Form.Item label="รายละเอียด" style={{ marginBottom: 0 }}>
                                            {dataModalEdit?.carBrand} {dataModalEdit?.carModel} {dataModalEdit?.carYear}
                                        </Form.Item>
                                        <Form.Item label="เลขทะเบียนรถ" style={{ marginBottom: 0 }}>
                                            {dataModalEdit?.carPlateNumber} {dataModalEdit?.carProvince}
                                        </Form.Item>
                                        <Form.Item label="เรท" style={{ marginBottom: 0 }}>
                                            {currencyFormatOne(dataModalEdit?.carPrice)}
                                        </Form.Item>
                                    </>
                                ) : (
                                    <>
                                        <Form.Item label="รายละเอียด" style={{ marginBottom: 0 }}>
                                            {dataModalEdit?.carBrand} {dataModalEdit?.carModel} เลขโฉนด {dataModalEdit?.carPlateNumber}
                                        </Form.Item>
                                        <Form.Item label="พื้นที่ตารางวา" style={{ marginBottom: 0 }}>
                                            {parseFloat(dataModalEdit?.carPrice)}
                                        </Form.Item>
                                        <Form.Item label="ราคาที่ดิน/ตารางวา" style={{ marginBottom: 0 }}>
                                            {parseFloat(dataModalEdit?.carProvince)}
                                        </Form.Item>
                                    </>
                                )}
                                <Form.Item label="สถานะ" style={{ marginBottom: 0 }}>
                                    <Tag color="gold">{dataModalEdit?.approvalStatus}</Tag>
                                    <Tag color="green">{dataModalEdit?.productLoanType}</Tag>
                                </Form.Item>
                            </>
                        }
                        {checkOld === true ?
                            <>
                                <Row>
                                    <Divider orientation="left"><b style={{ color: "red" }}>*พบข้อมูลที่เคสเสนอมาแล้ว!</b></Divider>
                                    <Col span={12}>
                                        <Form.Item label="ชื่อลูกค้า" style={{ marginBottom: 0 }}>
                                            {oldData?.customerAd.snam}{oldData?.customerAd.firstname} {oldData?.customerAd.lastname}
                                        </Form.Item>
                                        <Form.Item label="รายละเอียด" style={{ marginBottom: 0 }}>
                                            {oldData?.carBrand} {oldData?.carModel} {oldData?.carYear}
                                        </Form.Item>
                                        <Form.Item label="เลขทะเบียนรถ" style={{ marginBottom: 0 }}>
                                            {oldData?.carPlateNumber} {oldData?.carProvince}
                                        </Form.Item>
                                        <Form.Item label="เรท" style={{ marginBottom: 0 }}>
                                            {currencyFormatOne(oldData?.carPrice)}
                                        </Form.Item>
                                        <Form.Item label="สถานะ" style={{ marginBottom: 0 }}>
                                            <Tag color="gold">{oldData?.carLoanDetails.approvalStatus}</Tag>
                                            <Tag color="green">{oldData?.carLoanDetails.productLoanType}</Tag>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="ผู้อนุมัติ" style={{ marginBottom: 0 }}>
                                            {
                                                oldData?.carLoanDetails.approvedBy !== null ?
                                                    <>
                                                        {oldData?.carLoanDetails.approvedBy}
                                                    </>
                                                    : <p>-</p>
                                            }
                                        </Form.Item>
                                        <Form.Item label="ยอดที่อนุมัติ" style={{ marginBottom: 0 }}>
                                            {
                                                oldData?.carLoanDetails.approvedLoanAmount !== null ?
                                                    <>
                                                        {oldData?.carLoanDetails.approvedLoanAmount}
                                                    </>
                                                    : <p>-</p>
                                            }
                                        </Form.Item>
                                        <Form.Item label="งวดที่อนุมัติ" style={{ marginBottom: 0 }}>
                                            {
                                                oldData?.carLoanDetails.approvedLoanTerm !== null ?
                                                    <>
                                                        {oldData?.carLoanDetails.approvedLoanTerm}
                                                    </>
                                                    : <p>-</p>
                                            }
                                        </Form.Item>

                                        <Form.Item label="ผู้เสนอเคส" style={{ marginBottom: 0 }}>
                                            {oldData?.carLoanDetails.proposalBy}
                                        </Form.Item>
                                        <Form.Item label="สาขา" style={{ marginBottom: 0 }}>
                                            {oldData?.carLoanDetails.branch}
                                        </Form.Item>
                                        <Form.Item label="วันที่เสนอ" style={{ marginBottom: 0 }}>
                                            {dayjs(oldData?.carInput).format("DD/MM/YYYY")}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Divider orientation="left"><b style={{ color: "red" }}>*หมายเหตุ!</b></Divider>
                                <Row>
                                    <Col span={12}>
                                        <Form.Item label="สาเหตุ" style={{ marginBottom: 0 }}>
                                            {oldNote?.cause}
                                        </Form.Item>
                                        <Form.Item label="ผู้สร้างหมายเหตุ" style={{ marginBottom: 0 }}>
                                            {oldNote?.noteBy}
                                        </Form.Item>

                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="หมายเหตุ" style={{ marginBottom: 0 }}>
                                            {oldNote?.note}
                                        </Form.Item>

                                        <Form.Item label="วันที่หมายเหตุ" style={{ marginBottom: 0 }}>
                                            {dayjs(oldNote?.noteDate).format("DD/MM/YYYY")}
                                        </Form.Item>
                                        <Form.Item label="สถานะ" style={{ marginBottom: 0 }}>
                                            <Tag color="gold">{oldNote?.status}</Tag>
                                        </Form.Item>
                                    </Col>
                                </Row>

                            </>
                            : null
                        }
                    </Form>
                </Card>
            </Modal>
        </div >
    )
}

export default ModalAccept