import React, { useState, useEffect } from 'react';
import { Button, Form, Modal, Divider, Row, Col, Card, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import PostsPhone from '../postData/PostsPhone'
import PostsSocial from '../postData/PostsSocial'
import PostsEmail from '../postData/PostsEmail'
import EditPhone from '../editData/EditPhone';
import EditSocial from '../editData/EditSocial';
import EditEmail from '../editData/EditEmail';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { deleteemail, deletephone, deletesocial } from '../../file_mid/all_api';

function ContractData() {
    const navigate = useNavigate()
    //useForm
    const [formphone] = Form.useForm();
    const [formemail] = Form.useForm();
    const [formsocial] = Form.useForm();

    //loading
    const [loading, setLoading] = useState(false)
    const { confirm } = Modal
    //useSelector
    const { customers } = useSelector((state) => ({ ...state }))
    const id = customers.data.customerId

    //stateData
    const [data, setData] = useState(customers.data);
    const [index1, setIndex] = useState(); //set index send to page edit

    //statesPhones
    const [phone, setPhone] = useState() //เก็บ phone
    const [phonedataedit, setPhoneEdit] = useState([]);
    const [checkphone, setCheckPhone] = useState(); // check 0

    //stateEmails
    const [email, setEmail] = useState()
    const [emaidataledit, setEmailEdit] = useState([]);
    const [checkemail, setCheckEmail] = useState(); // check 0

    //stateSocials
    const [social, setSocial] = useState()
    const [socialdataedit, setSocialEdit] = useState([]);
    const [checksocial, setCheckSocial] = useState(); // check 0

    //Modal
    const [modaladdphone, setModalAddPhone] = useState(false);
    const [modaladdemail, setModalAddEmail] = useState(false);
    const [modaladdsocial, setModalAddSocial] = useState(false);

    const [modaleditphone, setModalEditPhone] = useState(false);
    const [modaleditemail, setModalEditEmail] = useState(false);
    const [modaleditsocial, setModalEditSocial] = useState(false);

    useEffect(() => {
        setData(customers.data)
        loadphoneId()
        loademailId()
        loadsocialId()
    }, [modaladdphone, modaladdemail, modaladdsocial])

    //elements
    const loadphoneId = async (e) => {
        console.log(id)
        setLoading(true)
        await axios.get(`https://eua-i67f6gaaqa-as.a.run.app/Api/morephone/${id}`)
            .then((res) => {
                console.log("as", res.data);
                if (res.data.status.length !== 0) {
                    setPhone(res.data.status);
                    setCheckPhone("1")
                    setLoading(false)
                } else if (res.data.status.length === 0) {
                    setCheckPhone("0")
                    setPhone([{ customerId: data }]);
                    setLoading(false)
                }
            })
            .catch((err) => console.log("err", err))
        setLoading(false)
    }
    const loademailId = async (e) => {
        setLoading(true)
        await axios.get(`https://eua-i67f6gaaqa-as.a.run.app/Api/moreemail/${id}`)
            .then((res) => {
                console.log("as", res.data);
                if (res.data.status.length !== 0) {
                    setEmail(res.data.status);
                    setCheckEmail("1")
                    setLoading(false)
                } else if (res.data.status.length === 0) {
                    setCheckEmail("0")
                    setEmail([{ customerId: data }]);
                    setLoading(false)
                }
            })
            .catch((err) => console.log("err", err))
        setLoading(false)
    }
    const loadsocialId = async (e) => {
        setLoading(true)
        await axios.get(`https://eua-i67f6gaaqa-as.a.run.app/Api/moresocial/${id}`)
            .then((res) => {
                console.log("as", res.data);
                if (res.data.status.length !== 0) {
                    setSocial(res.data.status);
                    setCheckSocial("1")
                    setLoading(false)
                } else if (res.data.status.length === 0) {
                    setCheckSocial("0")
                    setSocial([{ customerId: data }]);
                    setLoading(false)
                }
            })
            .catch((err) => console.log("err", err))
        setLoading(false)
    }


    //buuton Previos
    const handlePrevios = (e) => {
        navigate('/addcustomer/address')
    }
    //buuton Next
    const handleNext = (e) => {
        navigate('/addcustomer/careers')
    }
    //buuton Next
    const onChangeGo = () => {
        if (checkphone === "0" || checkphone === undefined) {
            alert('ต้องมีเบอร์โทรศัพท์อย่างน้อย 1 เบอร์')
        } else {
            navigate('/addcustomer/careers')
        }
    }
    //ADDDATA
    const addphone = (value) => {
        if (checkphone === "0") {
            const AddData = value.phoneadd    //ประกาศตัวแปรเพื่อเก็บค่าที่เพิ่มเข้ามาไปเซตให้ dataArray
            setPhone([AddData]);
        }
        else if (checkphone === "1") {
            const AddData2 = value.phoneadd   //ประกาศตัวแปรเพื่อเก็บค่าที่เพิ่มเข้ามาไปเซตให้ dataArray
            setPhone(pre => [...pre, { ...AddData2 }]);//เก็บค่า state เดิมไว้ พร้อมส่ง type ไปด้วย เพื่อบอกค่าที่ส่ง
        }
    }
    const addemail = (value) => {
        if (checkemail === "0") {
            const AddData = value.emailadd    //ประกาศตัวแปรเพื่อเก็บค่าที่เพิ่มเข้ามาไปเซตให้ dataArray
            setEmail([AddData]);
        }
        else if (checkemail === "1") {
            const AddData2 = value.emailadd   //ประกาศตัวแปรเพื่อเก็บค่าที่เพิ่มเข้ามาไปเซตให้ dataArray
            setEmail(pre => [...pre, { ...AddData2 }]);//เก็บค่า state เดิมไว้ พร้อมส่ง type ไปด้วย เพื่อบอกค่าที่ส่ง
        }
    }
    const addsocial = (value) => {
        if (checksocial === "0") {
            const AddData = value.socialadd    //ประกาศตัวแปรเพื่อเก็บค่าที่เพิ่มเข้ามาไปเซตให้ dataArray
            setSocial([AddData]);
        }
        else if (checksocial === "1") {
            const AddData2 = value.socialadd   //ประกาศตัวแปรเพื่อเก็บค่าที่เพิ่มเข้ามาไปเซตให้ dataArray
            setSocial(pre => [...pre, { ...AddData2 }]);//เก็บค่า state เดิมไว้ พร้อมส่ง type ไปด้วย เพื่อบอกค่าที่ส่ง
        }
    }
    //EDITPHONE
    const editphone = ({ editphone, editindex }) => {
        let Edit = [...phone]
        Edit[editindex] = editphone
        //ข้อมูลใหม่แทนที่ข้อมูลเดิมในช่อง Input
        setPhone(Edit);
    }
    const editsocial = ({ editsocial, editindex }) => {
        let Edit = [...social]
        Edit[editindex] = editsocial
        //ข้อมูลใหม่แทนที่ข้อมูลเดิมในช่อง Input
        setSocial(Edit);
    }
    const editemail = ({ editemail, editindex }) => {
        let Edit = [...email]
        Edit[editindex] = editemail
        //ข้อมูลใหม่แทนที่ข้อมูลเดิมในช่อง Input
        setEmail(Edit);
    }
    //DELETEDATA
    const deleteDataPhone = async (value) => {
        setLoading(true)
        console.log("TY", value)
        const test2 = value
        await axios.delete(deletephone, { data: test2, })
            .then((res) => {
                if (res.status === 200) {
                    message.success('ลบข้อมูลแล้ว');
                    setLoading(false)
                }
                else {
                    error()
                }
            }).catch((err) => console.log(err))
        setLoading(false)
    }
    const deleteDataSocial = async (value) => {
        setLoading(true)
        console.log("TY", value)
        const test2 = value
        await axios.delete(deletesocial, { data: test2, })
            .then((res) => {
                if (res.status === 200) {
                    message.success('ลบข้อมูลแล้ว');
                    setLoading(false)
                }
                else {
                    error()
                }
            }).catch((err) => console.log(err))
        setLoading(false)
    }
    const deleteDataEmail = async (value) => {
        setLoading(true)
        console.log("TY", value)
        const test2 = value
        await axios.delete(deleteemail, { data: test2, })
            .then((res) => {
                if (res.status === 200) {
                    message.success('ลบข้อมูลแล้ว');
                    setLoading(false)
                }
                else {
                    error()
                }
            }).catch((err) => console.log(err))
        setLoading(false)
    }

    const error = () => {
        Modal.error({
            title: 'ไม่สำเร็จ',
            content: 'กรุณาลองใหม่อีกครั้ง',
        })
    }

    const showConfirmDeletePhone = (value, index) => {
        confirm({
            title: 'คุณต้องการที่จะลบข้อมูลหรือไม่...?',
            content: 'กด OK เพื่อยืนยันการลบ',
            onOk() {
                const deletearray = [...phone]
                deletearray.splice(index, 1)
                setPhone(deletearray)
                deleteDataPhone(value)
            },
            onCancel() {
                message.error('ยกเลิกการลบข้อมูล')
            },
        });
    };
    const showConfirmDeleteSocial = (value, index) => {
        confirm({
            title: 'คุณต้องการที่จะลบข้อมูลหรือไม่...?',
            content: 'กด OK เพื่อยืนยันการลบ',
            onOk() {
                const deletearray = [...social]
                deletearray.splice(index, 1)
                setSocial(deletearray)
                deleteDataSocial(value)
            },
            onCancel() {
                message.error('ยกเลิกการลบข้อมูล')
            },
        });
    };
    const showConfirmDeleteEmail = (value, index) => {
        confirm({
            title: 'คุณต้องการที่จะลบข้อมูลหรือไม่...?',
            content: 'กด OK เพื่อยืนยันการลบ',
            onOk() {
                const deletearray = [...email]
                deletearray.splice(index, 1)
                setEmail(deletearray)
                deleteDataEmail(value)
            },
            onCancel() {
                message.error('ยกเลิกการลบข้อมูล')
            },
        });
    };

    //CardPhone
    const CardPhone = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
        // console.log('ss', item)
        const handlePhoneEdit = (phonedataedit, index1) => {
            console.log("11", phonedataedit, index1)
            setIndex(index1) //เซตค่า index ให้กับชุด ข้อมูล
            setPhoneEdit({ phonedataedit, index1 }) //ส่งข้อมูลด index ไปหน้าแก้ไข
            setModalEditPhone(true);
        }
        return (
            <Row gutter={32} justify={'center'}>
                <Col span={24} className='gutter-row' style={{ margin: 5 }}>
                    <Form form={formphone}>
                        <Card
                            key={index} // index เป็นตัวกำหนดคีย์
                            title={
                                <u>{item.phoneType}</u>
                            }
                            style={{ width: '100%', textAlign: 'center', }}
                        >
                            <Col className='gutter-row' span={24}>

                                <Form.Item
                                    style={{ marginBottom: 0 }}
                                    label='หมายเลข'
                                >
                                    <b>{item.telp}</b>
                                </Form.Item>
                                <Form.Item
                                    style={{ marginBottom: 0 }}
                                    label='sendSms'
                                >
                                    <b>
                                        {(item.sendSms === "1" || item.sendSms === true) && "ส่ง"}
                                        {(item.sendSms === "0" || item.sendSms === false) && "ไม่ส่ง"}
                                    </b>
                                </Form.Item>
                                <Form.Item
                                    style={{ marginBottom: 0 }}
                                    label='หมายเหตุ'
                                >
                                    <b>
                                        {/* {item.sendSms} */}
                                    </b>
                                </Form.Item>

                                <Divider></Divider>
                                <Row
                                    gutter={32}
                                >
                                    <Col className='gutter-row' span={12}>
                                        <Form.Item>
                                            <Button
                                                style={{ margin: '10px', background: '#f5222d', color: 'white', marginBottom: 0 }}
                                                // icon={<DeleteOutlined />}
                                                onClick={() => { showConfirmDeletePhone(phone[index], index); }}
                                            >
                                                ลบ
                                            </Button>
                                        </Form.Item>
                                    </Col>

                                    <Col className='gutter-row' span={12}>
                                        <Form.Item>
                                            <Button
                                                style={{ margin: '10px', background: '#bfbfbf', color: '#000000', marginBottom: 0 }}
                                                type='submit'
                                                onClick={() => { handlePhoneEdit(phone[index], index); }}
                                            >
                                                แก้ไข
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Card>
                    </Form>
                </Col>
            </Row>
        )
    }
    //CardEmail
    const CardEmail = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
        console.log('ss', item)
        // index ตอนนี้เป็นข้อมูลจาก Object
        const handleEmailEdit = (emaidataledit, index1) => {
            setIndex(index1) //เซตค่า index ให้กับชุด ข้อมูล
            setEmailEdit({ emaidataledit, index1 }) //ส่งข้อมูลด index ไปหน้าแก้ไข
            setModalEditEmail(true);
        }
        return (
            <Row gutter={32} justify={'center'}>
                <Col span={24} className='gutter-row' style={{ margin: 5 }}>
                    <Form form={formemail}>
                        <Card
                            key={index} // index เป็นตัวกำหนดคีย์
                            title={
                                <u>Email</u>
                            }
                            style={{ width: '100%', textAlign: 'center' }}
                        >
                            <Col className='gutter-row' span={24}>

                                <Form.Item
                                    style={{ marginBottom: 0 }}
                                    label='Email'
                                >
                                    <b>{item.emailData}</b>
                                </Form.Item>
                                <Form.Item
                                    style={{ marginBottom: 0 }}
                                    label='sendGetNews'
                                >
                                    <b>
                                        {(item.sendGetNews === "1" || item.sendGetNews === true) && "ส่ง"}
                                        {(item.sendGetNews === "0" || item.sendGetNews === false) && "ไม่ส่ง"}
                                    </b>
                                </Form.Item>

                                <Divider></Divider>
                                <Row
                                    justify={'center'}
                                    gutter={32}
                                >
                                    <Col className='gutter-row' span={12}>
                                        <Form.Item>
                                            <Button
                                                style={{ margin: '10px', background: '#f5222d', color: 'white', marginBottom: 0 }}
                                                // icon={<DeleteOutlined />}
                                                onClick={() => { showConfirmDeleteEmail(email[index], index); }}
                                            >
                                                ลบ
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                    <Col className='gutter-row' span={12}>
                                        <Form.Item>
                                            <Button
                                                style={{ margin: '10px', background: '#bfbfbf', color: '#000000', marginBottom: 0 }}
                                                type='submit'
                                                onClick={() => { handleEmailEdit(email[index], index); }}
                                            >
                                                แก้ไข
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Card>
                    </Form>
                </Col>
            </Row>
        )
    }
    //CardSocial
    const CardSocial = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
        console.log('ss', item)
        // index ตอนนี้เป็นข้อมูลจาก Object
        const handleSocialEdit = (socialdataedit, index1) => {
            setIndex(index1) //เซตค่า index ให้กับชุด ข้อมูล
            setSocialEdit({ socialdataedit, index1 }) //ส่งข้อมูลด index ไปหน้าแก้ไข
            setModalEditSocial(true);
        }
        return (
            <Row gutter={32} justify={'center'}>
                <Col span={24} className='gutter-row' style={{ margin: 5 }}>
                    <Form form={formsocial}>
                        <Card
                            key={index} // index เป็นตัวกำหนดคีย์
                            title={
                                <u>{item.socialType}</u>
                            }
                            style={{ width: '100%', textAlign: 'center' }}
                        >
                            <Col className='gutter-row' span={24}>

                                <Form.Item
                                    style={{ marginBottom: 0 }}
                                    label='Data'
                                >
                                    <b>{item.socialData}</b>
                                </Form.Item>
                                <Form.Item
                                    style={{ marginBottom: 0 }}
                                    label='sendGetNews'
                                >
                                    <b>
                                        {(item.sendGetNews === "1" || item.sendGetNews === true) && "รับ"}
                                        {(item.sendGetNews === "0" || item.sendGetNews === false) && "ไม่รับ"}
                                    </b>
                                </Form.Item>

                                <Divider></Divider>
                                <Row
                                    justify={'center'}
                                    gutter={32}
                                >
                                    <Col className='gutter-row' span={12}>
                                        <Form.Item>
                                            <Button
                                                style={{ margin: '10px', background: '#f5222d', color: 'white', marginBottom: 0 }}
                                                // icon={<DeleteOutlined />}
                                                onClick={() => { showConfirmDeleteSocial(social[index], index); }}
                                            >
                                                ลบ
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                    <Col className='gutter-row' span={12}>
                                        <Form.Item>
                                            <Button
                                                style={{ margin: '10px', background: '#bfbfbf', color: '#000000', marginBottom: 0 }}
                                                type='submit'
                                                onClick={() => { handleSocialEdit(social[index], index); }}
                                            >
                                                แก้ไข
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Card>
                    </Form>
                </Col>
            </Row>
        )
    }

    return (
        <>
            <Row justify={'center'}>
                <Card style={{ width: '100%' }}>
                    <Row justify={'center'}>
                        <Col className='gutter-row' span={24} style={{ fontSize: '25px', textAlign: 'center' }}>
                            <b>ช่องทางการติดต่อ</b>
                        </Col>
                    </Row>
                    <Form
                        // form={formphone}
                        name="basic"
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                        style={{
                            maxWidth: '100%',
                        }}
                        autoComplete="off"
                        initialValues={{
                            remember: true,
                        }}
                    >
                        <Divider style={{ margin: 5 }} />
                        <Row gutter={32}>
                            <Col className='gutter-row' span={24} style={{ textAlign: 'center' }}>
                                <h3><b><u>เบอร์โทรศัพท์</u></b></h3>
                                <Form.Item style={{ textAlign: 'right', marginRight: '30px' }}>
                                    <Button
                                        type="primary"
                                        onClick={() => { setModalAddPhone(true); }}
                                    >
                                        เพิ่มเบอร์โทร
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify={'center'} gutter={32}>
                            {
                                phone === undefined ?
                                    setTimeout(() => {
                                    }, 1000)
                                    : phone !== "" ?

                                        phone.map((item, index) => {
                                            if (checkphone !== "0") {
                                                return CardPhone({ item, index })
                                                setCheckPhone("1")
                                            }
                                        })
                                        : null
                            }

                        </Row>
                    </Form>
                    <Divider />
                    <Form
                        // form={formphone}
                        name="basic"
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                        style={{
                            maxWidth: '100%',
                        }}
                        autoComplete="off"
                        initialValues={{
                            remember: true,
                        }}
                    >
                        <Row gutter={32}>
                            <Col className='gutter-row' span={24} style={{ textAlign: 'center' }}>
                                <h3><b><u>Email</u></b></h3>
                                <Form.Item style={{ textAlign: 'right', marginRight: '30px' }}>
                                    <Button
                                        type="primary"
                                        onClick={() => { setModalAddEmail(true); }}
                                    >
                                        เพิ่ม Email
                                    </Button>
                                </Form.Item>
                            </Col>

                        </Row>
                        <Row justify={'center'}>
                            {
                                email === undefined ?
                                    setTimeout(() => {
                                    }, 2000)
                                    : email !== "" ?

                                        email.map((item, index) => {
                                            if (checkemail !== "0") {
                                                return CardEmail({ item, index })
                                                setCheckEmail("1")
                                            }
                                        })
                                        : null
                            }
                        </Row>

                    </Form>
                    <Divider />
                    <Form
                        name="basic"
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                        style={{
                            maxWidth: '100%',
                        }}
                        autoComplete="off"
                        initialValues={{
                            remember: true,
                        }}
                    >
                        <Row gutter={32}>
                            <Col className='gutter-row' span={24} style={{ textAlign: 'center' }}>
                                <h3><b><u>Socials</u></b></h3>
                                <Form.Item style={{ textAlign: 'right', marginRight: '30px' }}>
                                    <Button
                                        type="primary"
                                        onClick={() => { setModalAddSocial(true); }}
                                    >
                                        เพิ่ม Socials
                                    </Button>
                                </Form.Item>
                            </Col>

                        </Row>
                        <Row justify={'center'}>
                            {
                                social === undefined ?
                                    setTimeout(() => {
                                    }, 2000)
                                    : social !== "" ?

                                        social.map((item, index) => {
                                            if (checksocial !== "0") {
                                                return CardSocial({ item, index })
                                                setCheckSocial("1")
                                            }
                                        })
                                        : null
                            }
                        </Row>
                    </Form>
                    <Divider style={{ margin: 5 }} />
                    <Row gutter={32}>
                        <Col className='gutter-row' span={12} style={{ textAlign: 'left' }}>
                            <Button
                                style={{ margin: '3px', marginBottom: 0, background: '#bfbfbf', color: '#000000', }}
                                type='primary'
                                onClick={handlePrevios}
                            >
                                ย้อนกลับ
                            </Button>
                        </Col>
                        <Col className='gutter-row' span={12} style={{ textAlign: 'right' }}>
                            <Button
                                style={{ margin: '3px', marginBottom: 0 }}
                                type='primary'
                                onClick={onChangeGo}
                            >
                                ต่อไป
                            </Button>
                        </Col>
                    </Row>
                </Card>
            </Row>
            {
                modaladdphone ?
                    <PostsPhone
                        open={modaladdphone}
                        close={setModalAddPhone}
                        phone={phone}
                        funcphone={addphone} // ดึงข้อมูลจากมาจากหน้า PostsPhone
                        checkphone1={checkphone} // โยนค่าไปหน้า PostsPhone
                        setcheckphone={setCheckPhone}
                    />
                    : null
            }
            {
                modaladdsocial ?
                    <PostsSocial
                        open={modaladdsocial}
                        close={setModalAddSocial}
                        social={social}
                        funcsocial={addsocial} // ดึงข้อมูลจากมาจากหน้า PostsSocial
                        checksocial1={checksocial} // โยนค่าไปหน้า PostsSocial
                        setchecksocial={setCheckSocial}// โยนค่าไปหน้า PostsSocial
                    />
                    : null
            }
            {
                modaladdemail ?
                    <PostsEmail
                        open={modaladdemail}
                        close={setModalAddEmail}
                        email={email}
                        funcemail={addemail} // ดึงข้อมูลจากมาจากหน้า PostsEmail
                        checkemail1={checkemail} // โยนค่าไปหน้า PostsEmail
                        setcheckemail={setCheckEmail}
                    />
                    : null
            }

            { //แก้ไขข้อมูลในการ์ด
                modaleditphone ?
                    <EditPhone
                        open={modaleditphone} // เซตการเปิด Modal จากหน้า PhoneEdit
                        close={setModalEditPhone} // เซตการปิด Modal จากหน้า PhoneEdit
                        phoneedit={phonedataedit} // ส่งค่าไปยังหน้า EditPhone เพื่อแสดงข้อมูลจากการ์ด
                        editphonedata={editphone}
                        dataindex={index1} //ส่ง index  ไปหน้า PhoneEdit
                    />
                    : null
            }
            {
                modaleditsocial ?
                    <EditSocial
                        open={modaleditsocial} // เซตการเปิด Modal จากหน้า SocialEdit
                        close={setModalEditSocial} // เซตการปิด Modal จากหน้า SocialEdit
                        socialedit={socialdataedit} // ส่งค่าไปยังหน้า EditSocial เพื่อแสดงข้อมูลจากการ์ด
                        editsocialdata={editsocial}
                        dataindex={index1} //ส่ง index  ไปหน้า SocialEdit
                    />
                    : null
            }
            {
                modaleditemail ?
                    <EditEmail
                        open={modaleditemail} // เซตการเปิด Modal จากหน้า EmailEdit
                        close={setModalEditEmail} // เซตการปิด Modal จากหน้า EmailEdit
                        emailedit={emaidataledit} // ส่งค่าไปยังหน้า EditEmail เพื่อแสดงข้อมูลจากการ์ด
                        editemaildata={editemail}
                        dataindex={index1} //ส่ง index  ไปหน้า SocialEdit
                    />
                    : null
            }
        </>

    )
};
export default ContractData
