import React, { useState, useEffect } from 'react'
import { Button, Form, Input, Divider, Row, Col, Modal, Radio, message } from 'antd';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { updateemail2 } from '../../file_mid/all_api';

function EditEmail({ open, close, emailedit, editemaildata, dataindex }) {
    //dispatch
    const dispatch = useDispatch();
    //useForm
    const [formeditemail] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    //state
    const [editemail, setEditEmail] = useState({ ...emailedit.emaidataledit });
    const [editindex, setEditIndex] = useState(dataindex);

    //useEffect เพื่อ refresh data
    useEffect(() => {
        setEditEmail({ ...emailedit.emaidataledit })
        setEditIndex(dataindex)
        formeditemail.setFieldsValue(
            {
                ...editemail
            }
        )
    }, [emailedit]);

    //elements
    const handleCancel = () => { //กดปิด Modal
        close(false);
    };
    //handleSubmit
    const handleSubmit = async () => {
        await axios.put(updateemail2, editemail)
            .then((res) => {
                if (res.status === 200) {
                    messageApi.open({
                        type: 'success',
                        content: 'แก้ไขสำเร็จ',
                    });
                } else {
                    messageApi.open({
                        type: 'error',
                        content: 'แก้ไขไม่สำเร็จ',
                    });
                }
            })
            .catch((err) => {
                if (err.response.request.status === 400) {
                    messageApi.open({
                        type: 'error',
                        content: 'บันทึกไม่สำเร็จ',
                    });
                }
                console.log("err", err)
            })
        editemaildata({ editemail, editindex }) // ส่งข้อมูลไปหน้า Address
        close(false);
    }

    return (
        <>
            {/* Modal แก้ไข้ */}
            <Modal
                open={open}
                onCancel={handleCancel}
                style={{ background: '#2f54eb' }}
                centered
                width={600}
                footer={
                    [
                        <Row
                            justify={'center'}
                        >
                            <Button key="back"
                                onClick={handleCancel}
                            >
                                ยกเลิก
                            </Button>
                            <Button
                                type="primary"
                                onClick={handleSubmit}
                            >
                                บันทึก
                            </Button>
                        </Row>
                    ]}
            >
                <Form
                    form={formeditemail}
                    name='editemail'
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    style={{
                        maxWidth: '100%',
                    }}
                >
                    <Row
                        justify={'center'}
                    >
                        <h3 style={{ marginBottom: 0 }}>
                            <u>แก้ไขอีเมล</u>
                        </h3>
                    </Row>

                    <Row
                        gutter={{ sx: 8, sm: 16, md: 24, lg: 32 }}
                    >
                        <Col className='gutter-row' span={24}>
                            <Form.Item label='E-Mail' name='emailData' rules={[
                                {
                                    required: true,
                                    message: 'Please input !',
                                },]}>
                                <Input onChange={(e) => setEditEmail({ ...editemail, emailData: e.target.value })} />
                            </Form.Item>
                            <Form.Item label='รับข่าวสาร' name='sendGetNews' rules={[
                                {
                                    required: true,
                                    message: 'Please input !',
                                },]}>
                                <Radio.Group onChange={(e) => setEditEmail({ ...editemail, sendGetNews: e.target.value })} >
                                    <Radio value="1"> รับ </Radio>
                                    <Radio value="0"> ไม่รับ </Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item label="หมายเหตุ" name='note'>
                                <Input onChange={(e) => setEditEmail({ ...editemail, note: e.target.value })} />
                            </Form.Item>
                            <Divider />
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    )

};

export default EditEmail
