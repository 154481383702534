import React, { useState, useEffect } from "react";
import { Button, Col, Input, Row, Table, Spin, Space, Card, Divider, Select, Tag, message, } from "antd";
import { FileSearchOutlined, ProfileOutlined, CheckOutlined, SearchOutlined, } from "@ant-design/icons";
import axios from "axios";
import ModalInstallment from "../../check_info_PG_RE/modals/ModalInstallment";
import BillExpedite from "./modals/BillExpedite";
import "../../css/MobileActive.css";
import WaitApproval from "./modals/WaitApproval";
import "../../css/buttonNickie.css";
import "../../css/Media.css";
import { loadMainBoss, mainexpeditethedebt, searchmainexpeditethedebt, } from "../../file_mid/all_api";

const Cars = () => {
  // const user = localStorage.getItem("username");
  const idPosition = localStorage.getItem("idSep");
  const [arrayTable, setArrayTable] = useState(); // ข้อมูลใน Table
  const [totalPage, setTotalPage] = useState();
  const [query, setQuery] = useState("");
  const [isModalInstallment, setIsModalInstallment] = useState(false);
  const [isModalInfoCar, setIsModalInfoCar] = useState(false);
  const [isModalRefinance, setIsModalRefinance] = useState(false);
  const [dataInstallment, setDataInstallment] = useState(null);
  const [dataInfoCar, setDataInfoCar] = useState(null);
  const [dataRefinance, setInfoRefinance] = useState(null);
  const [dataArr, setDataArr] = useState([]);
  const [dataSend, setDataSend] = useState({});
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  const [dataApproval, setDataApproval] = useState([]);
  const [selectedValue, setSelectedValue] = useState("reset");
  const [dataWait, setDataWait] = useState();
  const [dataSearch, setDataSearch] = useState();
  const [dataValue, setDataValue] = useState();
  const [queryContno, setQueryContno] = useState({ CONTNO: "" });
  const [bunsheClose, setBunsheClose] = useState([]);
  const [statusApproval, setStatusApproval] = useState({
    request: 0,
    waitAccept: 0,
    approval: 0,
    approved: 0,
    success: 0,
  });

  useEffect(() => {
    if (idPosition === "1" || idPosition === "5") {
      if (query !== "") {
        search();
      } else {
        loadData();
        setStatusApproval(dataArr);
      }
    }
  }, [dataValue]);

  const search = (event) => {
    onSearch(event.target.value);
  };

  const onSearch = (value) => {
    if (selectedValue === "reset") {
      let result = dataArr.filter(
        (item) =>
          item.CONTNO.includes(value) ||
          item.CNAME.includes(value) ||
          item.CUSCOD.includes(value) ||
          item.REGNO.includes(value) ||
          item.TELP.includes(value)
      );
      setArrayTable(result);
    } else if (selectedValue === "close") {
      let result = bunsheClose.filter(
        (item) =>
          item.CONTNO.includes(value)
      );
      setArrayTable(result);
    } else {
      let result = dataSearch.filter((item) => item.CONTNO.includes(value));
      setArrayTable(result);
    }
  };

  const onQuery = () => {
    if (queryContno) {
      queryData(queryContno);
    }
  };

  const handleSelectedStatus = (value) => {
    if (value === "close") {
      setArrayTable(bunsheClose);
      setDataSearch(bunsheClose);;
    } else {
      let statusAll = dataArr.filter((item) => item.ApprovalStatus === value);
      setArrayTable(statusAll);
      setDataSearch(statusAll);
    }
  };

  const onSelect = (value) => {
    setSelectedValue(value);
    if (idPosition === "1" || idPosition === "5") {
      if (value !== "reset") {
        handleSelectedStatus(value);
      } else {
        loadData();
      }
    }
  };

  const loadData = async () => {
    setLoading(true);
    const tk = JSON.parse(token);
    const url = mainexpeditethedebt;
    const headers = {
      Authorization: `Bearer ${tk}`,
      Menu: JSON.stringify("25"),
    };
    //main
    await axios
      .get(url, { headers: headers })
      .then(async (res) => {
        let i = 1;
        if (res.data) {
          const newData = res.data.map((item) => ({
            ...item,
            key: i++,
          }));
          setArrayTable(newData);
          setDataArr(newData);
          setTotalPage(res.data.length);
          defaultStatusApproval(res.data);
          loadCheckClose(newData)
        } else {
          setArrayTable([]);
        }
      })
      .catch((err) => console.log(err));
    setLoading(false);
  };

  const loadCheckClose = async (e) => {
    setLoading(true);
    const tk = JSON.parse(token);
    const urlStatus = loadMainBoss;
    const headers = {
      Authorization: `Bearer ${tk}`,
      Menu: JSON.stringify("25"),
    };
    const statusBody = { approvalStatus: 0 };
    await axios.post(urlStatus, { statusBody }, { headers: headers })
      .then(async (res) => {
        if (res.status === 200) {
          handleCheckClose(e, res.data.data)
        }
      })
      .catch((err) => console.log(err));
    setLoading(false);
  };

  const handleCheckClose = async (stepOne, stepTwo) => {
    const unmatchedInStepTwo = stepTwo.filter(itemTwo =>
      !stepOne.some(itemOne =>
        itemOne.CONTNO === itemTwo.CONTNO
      )
    );
    let i = 1;
    const dataClose = unmatchedInStepTwo.map((item) => ({
      ...item,
      key: i++,
    }));
    setBunsheClose(dataClose)
  };

  const queryData = async () => {
    setLoading(true);
    const tk = JSON.parse(token);
    const urlQueryData = searchmainexpeditethedebt;
    const headers = {
      Authorization: `Bearer ${tk}`,
      Menu: JSON.stringify("25"),
    };
    await axios.post(urlQueryData, { CONTNO: queryContno }, { headers })
      .then(async (resQuery) => {
        if (resQuery.data) {
          setArrayTable(resQuery.data);
          setTotalPage(resQuery.data.length);
          // console.log(resQuery.data);
        } else {
          setArrayTable([]);
          setTotalPage([]);
          message.error("ไม่มีเลขที่สัญญาที่ค้นหา");
        }
      })
      .catch((err) => console.log(err));
    setLoading(false);
  };

  const columns = [
    {
      title: "ลำดับ",
      dataIndex: "key",
      key: "key",
      align: "center",
      width: "10%",
      render: (text, object, key) => key + 1,
      sorter: {
        compare: (a, b) => a.key - b.key,
        multiple: 5,
      },
    },
    {
      title: "เลขที่สัญญา",
      dataIndex: "CONTNO",
      key: "CONTNO",
      align: "center",
      width: "40%",
    },
    {
      title: "สถานะสัญญา",
      dataIndex: "ApprovalStatus",
      key: "ApprovalStatus",
      align: "center",
      width: "30%",
      // sorter: {
      //   compare: (a, b) => a.ApprovalStatus.length - b.ApprovalStatus.length,
      //   multiple: 5,
      // },
      render: (text, record) => (
        <>
          {
            selectedValue !== "close" ?
              <>
                {idPosition === "1" || idPosition === "5" ? (
                  <>
                    {
                      record.ApprovalStatus === "" ? (
                        <Tag >
                          <b>ยังไม่ได้ส่งขอ</b>
                        </Tag>
                      ) : record.ApprovalStatus === "12" ? (
                        <Tag style={{ backgroundColor: "#72f70d" }}>
                          <b>สำเร็จ</b>
                        </Tag>
                      ) : record.ApprovalStatus === "5" ? (
                        <Tag style={{ backgroundColor: "yellow" }}>
                          <b>รอCEOอนุมัติ</b>
                        </Tag>
                      ) : record.ApprovalStatus === "2" ? (
                        <Tag style={{ backgroundColor: "orange" }}>
                          <b>รอนำเสนอCEO</b>
                        </Tag>
                      ) : record.ApprovalStatus === "6" ? (
                        <Tag style={{ backgroundColor: "#dcedc8" }}>
                          <b>รอส่งเคสอนุมัติ</b>
                        </Tag>
                      ) : null}
                  </>
                ) : (
                  "-"
                )}
              </>
              :
              <>
                <Tag style={{ backgroundColor: "pink" }}>
                  <b>สัญญาที่ปิดแล้ว</b>
                </Tag>
              </>
          }

        </>
      ),
    },
  ];

  const handleChangeStatus = (data, status) => {
    setDataValue(status)
    // console.log("TEST", data, status);
    const result = dataArr.map((item) => {
      if (item.CONTNO === data.CONTNO) {
        return { ...data };
      } else {
        return { ...item };
      }
    });
    setDataArr(result);
    handleStatusApproval(result);
    // console.log("1", result);
    if (status === "reset") {
      setArrayTable(result);
    } else {
      const arr = result.filter((item) =>
        item.ApprovalStatus === status
      );
      setArrayTable(arr);
    }
  };

  const defaultStatusApproval = (data) => {
    if (data) {
      let countRequest = 0;
      let countWaitAccept = 0;
      let countApproval = 0;
      let countApproved = 0;
      let countSuccess = 0;
      data.map((item) => {
        if (item.ApprovalStatus === "") {
          countRequest++;
        } else if (item.ApprovalStatus === "2") {
          countWaitAccept++;
        } else if (item.ApprovalStatus === "5") {
          countApproval++;
        } else if (item.ApprovalStatus === "6") {
          countApproved++;
        } else if (item.ApprovalStatus === "12") {
          countSuccess++;
        }
      });
      setStatusApproval({
        request: countRequest,
        waitAccept: countWaitAccept,
        approval: countApproval,
        approved: countApproved,
        success: countSuccess,
      });
    }
  };

  const handleStatusApproval = (data) => {
    if (data) {
      let countRequest = 0;
      let countWaitAccept = 0;
      let countApproval = 0;
      let countApproved = 0;
      let countSuccess = 0;

      data.forEach((item) => {
        if (item.ApprovalStatus === "") {
          countRequest++;
        } else if (item.ApprovalStatus === "2") {
          countWaitAccept++;
        } else if (item.ApprovalStatus === "5") {
          countApproval++;
        } else if (item.ApprovalStatus === "6") {
          countApproved++;
        } else if (item.ApprovalStatus === "12") {
          countSuccess++;
        }
      });
      setStatusApproval({
        request: countRequest,
        waitAccept: countWaitAccept,
        approval: countApproval,
        approved: countApproved,
        success: countSuccess,
      });
    }
  };

  console.log("arrayTable", arrayTable)

  return (
    <>
      <Card>
        <Row gutter={32} style={{ textAlign: "center" }}>
          <Col>
            <Spin spinning={loading} size="large" tip=" Loading... ">
              <Col>
                <div className="text-center">
                  <h2>ตรวจสอบข้อมูลตามงวด</h2>
                </div>
              </Col>
              <Row gutter={32} className="main" justify={'center'}>
                {idPosition === "1" || idPosition === "5" ? (
                  <Col span={18} >
                    <aside>
                      <div style={{ textAlign: 'center' }}>
                        <Tag
                          style={{ backgroundColor: "#f5f5f5", marginTop: "5px" }}
                          onClick={() => {
                            handleSelectedStatus("");
                            setSelectedValue("");
                          }}
                        >
                          <b>{"ยังไม่ได้ส่งขอ "}</b>{" "}
                          {statusApproval.request ? statusApproval.request : "0"}{" "}
                          <b>เคส</b>
                        </Tag>
                      </div>
                      <div>
                        <Tag
                          style={{ backgroundColor: "orange", margin: "5px" }}
                          onClick={() => {
                            handleSelectedStatus("2");
                            setSelectedValue("2");
                          }}
                        >
                          <b>{"รอCEOรับงาน "}</b>
                          {statusApproval.waitAccept
                            ? statusApproval.waitAccept
                            : "0"}{" "}
                          <b>เคส</b>
                        </Tag>
                      </div>
                      <div>
                        <Tag
                          style={{ backgroundColor: "yellow", marginTop: "5px" }}
                          onClick={() => {
                            handleSelectedStatus("5");
                            setSelectedValue("5");
                          }}
                        >
                          <b>{"รอCEOอนุมัติ "}</b>{" "}
                          {statusApproval.approval ?
                            statusApproval.approval
                            : "0"}{" "}
                          <b>เคส</b>
                        </Tag>
                      </div>
                      <div>
                        <Tag
                          style={{ backgroundColor: "#dcedc8", margin: "5px" }}
                          onClick={() => {
                            handleSelectedStatus("6");
                            setSelectedValue("6");
                          }}
                        >
                          <b>{"รอส่งเคสอนุมัติ "}</b>{" "}
                          {statusApproval.approved
                            ? statusApproval.approved
                            : "0"}{" "}
                          <b>เคส</b>
                        </Tag>
                      </div>
                      <div>
                        <Tag
                          style={{ backgroundColor: "#72f70d", margin: "5px" }}
                          onClick={() => {
                            handleSelectedStatus("12");
                            setSelectedValue("12");
                          }}
                        >
                          <b>{"สำเร็จ "}</b>{" "}
                          {statusApproval.success ? statusApproval.success : "0"}{" "}
                          <b>เคส</b>
                        </Tag>


                      </div>
                      <div>
                        <Tag
                          style={{ backgroundColor: "pink", margin: "5px" }}
                          onClick={() => {
                            handleSelectedStatus("close");
                            setSelectedValue("close");
                          }}
                        >
                          <b>{"สัญญาที่ปิดแล้ว "}</b>{" "}
                          {bunsheClose ? bunsheClose.length : "0"}{" "}
                          <b>เคส</b>
                        </Tag>
                      </div>
                    </aside>
                  </Col>
                ) : null}
              </Row>
              <Row justify={'center'}>
                <Col span={24}>
                  <div>
                    <b
                      onClick={() => {
                        onSelect("reset");
                        setSelectedValue("reset");
                      }}
                    >
                      จำนวนทั้งหมด {totalPage ? totalPage : "0"} {" เคส"}
                    </b>
                  </div>
                </Col>
              </Row>
              <Row style={{ marginLeft: "5px" }} justify={'center'}>
                <Col
                  span={24}
                >
                  {idPosition === "1" || idPosition === "5" ? (
                    <>
                      <Space
                        wrap
                        style={{ marginTop: "5px", marginRight: "5px" }}
                      >
                        <Select
                          defaultValue="ทั้งหมด"
                          style={{
                            width: 150,
                            height: '40px'
                          }}
                          value={selectedValue}
                          onChange={onSelect}
                          options={[
                            {
                              value: "reset",
                              label: "ทั้งหมด",
                            },
                            {
                              value: "",
                              label: "เคสที่ยังไม่ส่ง",
                            },
                            {
                              value: "2",
                              label: "รอนำเสนอCEO",
                            },
                            {
                              value: "5",
                              label: "รอCEOอนุมัติ",
                            },

                            {
                              value: "6",
                              label: "รอส่งเคสอนุมัติ",
                            },
                            {
                              value: "12",
                              label: "สำเร็จ",
                            },
                            {
                              value: "close",
                              label: "สัญญาที่ปิดแล้ว",
                            },
                          ]}
                        />
                        <Input.Search
                          style={{ width: "300px", marginTop: "5px" }}
                          placeholder="ค้นหาเลขที่สัญญา"
                          onChange={search}
                          name="search"
                          id="search"
                        />
                        {/* <Button
                          // onChange={search}
                          onClick={search}
                          name="search"
                          type="primary"
                          id="search"
                        >
                          ค้นหา
                        </Button> */}
                      </Space>

                    </>
                  ) : (
                    <>
                      <Input
                        style={{ width: "60%" }}
                        placeholder="ค้นหาเลขที่สัญญา"
                        onChange={(e) => setQueryContno(e.target.value)}
                        name="search"
                        id="search"
                      />
                      <Button style={{ marginRight: "5px" }} onClick={onQuery}>
                        <SearchOutlined />
                      </Button>
                    </>
                  )}
                </Col>
              </Row>
              <Row gutter={32}>
                <Col span={24}>
                  {
                    selectedValue !== "close" ?
                      <>
                        <Table
                          style={{ marginTop: "5px" }}
                          scroll={{
                            y: 400,
                          }}
                          expandable={{
                            expandedRowRender: (record) => {
                              if (record.CONTNO)
                                return (
                                  <div>
                                    <Space size="small">
                                      <Button
                                        title="ติดตามค่างวด"
                                        onClick={() => {
                                          setDataInfoCar(record.CONTNO);
                                          // console.log("dataInfoCar", dataInfoCar);
                                          // console.log("record.CONTNO", record.CONTNO);
                                          setIsModalInfoCar(true);
                                          setDataSend(record);
                                        }}
                                      >
                                        <FileSearchOutlined />
                                      </Button>
                                      <Button
                                        title="ตารางการผ่อน"
                                        onClick={() => {
                                          setDataInstallment(record.CONTNO);
                                          setIsModalInstallment(true);
                                          // console.log("ตารางการผ่อน", record.CONTNO);
                                        }}
                                      >
                                        <ProfileOutlined />
                                      </Button>
                                      {record.ApprovalStatus !== "" &&
                                        (idPosition === "1" || idPosition === "5") ? (
                                        <Button
                                          title="ส่งอนุมัติรีไฟแนนซ์"
                                          onClick={() => {
                                            setInfoRefinance(record.CONTNO);
                                            setDataWait(record.idCarsApproveCeo);
                                            setIsModalRefinance(true);
                                            setDataApproval(record.MEMO1);
                                            setDataSend(record);
                                            // console.log("ส่งอนุมัติรีไฟแนนซ์");
                                          }}
                                        >
                                          <CheckOutlined />
                                        </Button>
                                      ) : null}
                                    </Space>
                                  </div>
                                );
                            },
                          }}
                          dataSource={arrayTable}
                          columns={columns}
                        ></Table>
                      </>
                      :
                      <>
                        <Table
                          style={{ marginTop: "5px" }}
                          scroll={{
                            y: 400,
                          }}
                          expandable={{
                            expandedRowRender: (record) => {
                              if (record.CONTNO)
                                return (
                                  <div>
                                    <Space size="small">
                                      {record.ApprovalStatus !== "" &&
                                        (idPosition === "1" || idPosition === "5") ? (
                                        <Button
                                          title="ส่งอนุมัติรีไฟแนนซ์"
                                          onClick={() => {
                                            setInfoRefinance(record.CONTNO);
                                            setDataWait(record.id);
                                            setIsModalRefinance(true);
                                            setDataApproval(record.MEMO1);
                                            setDataSend(record);
                                            // console.log("ส่งอนุมัติรีไฟแนนซ์");
                                          }}
                                        >
                                          <CheckOutlined />
                                        </Button>
                                      ) : null}
                                    </Space>
                                  </div>
                                );
                            },
                          }}
                          dataSource={arrayTable}
                          columns={columns}
                        ></Table>
                      </>
                  }
                  <Divider />
                  <Row justify={"center"}></Row>
                  <Divider />
                </Col>
              </Row>
            </Spin>
          </Col>
        </Row>
      </Card>
      {isModalInfoCar ?
        <BillExpedite
          open={isModalInfoCar}
          close={setIsModalInfoCar}
          continoBill={dataInfoCar}
          data={dataSend}
          onChangeStatus={handleChangeStatus}
          status={selectedValue}
        />
        : null}
      {isModalInstallment ?
        <ModalInstallment
          open={isModalInstallment}
          close={setIsModalInstallment}
          contno={dataInstallment}
        />
        : null}
      {isModalRefinance ?
        <WaitApproval
          open={isModalRefinance}
          close={setIsModalRefinance}
          continoRe={dataRefinance}
          id={dataWait}
          memo={dataApproval}
          data={dataSend}
          onChangeStatus={handleChangeStatus}
          status={selectedValue}
        />
        : null}
    </>
  );
};

export default Cars;
