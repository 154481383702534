import React, { useState, useEffect } from 'react'
import { Form, Input, Row, Col, Button, Select, Divider, Space, Radio, DatePicker, Spin, Card, message, Modal } from 'antd'
import axios from 'axios'
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { addCustomer } from '../../../redux/Customer';
import { addCareerIncome } from '../../../redux/CareerIncome';
import { addPhone } from '../../../redux/Phone';
import { addAddress } from '../../../redux/Address';
import '../../css/Media.css';
import { checkNewCCAP } from '../../file_mid/all_api';

export default function SendEditCustomer({ onCancel, changepage }) {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const dispatch = useDispatch()
    const customers = useSelector(state => state.customers)
    const Addresss = useSelector(state => state.Addresss)
    const Phones = useSelector(state => state.Phones)
    const [dataModalEdit, setDataModalEdit] = useState({ nationalId: "บุคคลธรรมดา" })
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false)
    const [sell, setSell] = useState(true)
    const [number, setNumber] = useState(0);
    const [step, setStep] = useState(0)
    const [CheckIdCard, setCheckIdCard] = useState(false)
    const [dataPhone, setDataPhone] = useState({})
    const [dataAddress, setDataAddress] = useState({})
    const { confirm } = Modal
    const [holds, setHolds] = useState(customers?.data?.nationalId);
    const [typeCompany, setTypeCompany] = useState('หจก.');
    const [typeCompanyed, setTypeCompanyed] = useState('หจก.');

    useEffect(() => {
        if (customers.data) {
            if (!customers.data.identificationId) {
                setCheckIdCard(true)
                setNumber(1)
                form.setFieldsValue(
                    {
                        nationalId: "บุคคลธรรมดา"
                    }
                )
            } else {
                setStep(1)
                setCheckIdCard(false)
                form.setFieldsValue(
                    {
                        nationalId: "บุคคลธรรมดา"
                    }
                )
            }
            setDataModalEdit({ ...dataModalEdit, ...customers.data })
            setDataAddress(Addresss.data)
            setDataPhone(Phones.data)
            form.setFieldsValue(
                {
                    ...customers.data, ...Phones.data,
                    birthdate: customers.data.birthdate ? dayjs(customers.data.birthdate) : '',
                    issudt: customers.data.issudt ? dayjs(customers.data.issudt) : '',
                    expdt: customers.data.expdt ? dayjs(customers.data.expdt) : '',
                }
            )
        }
    }, [])

    const onChangeBack = () => {
        dispatch(addCareerIncome())
        changepage('1')
    }
    const onChangeGo = () => {
        changepage('2')
    }
    const showConfirmCancel = () => {
        confirm({
            title: 'คุณต้องการยกเลิกการเสนอเคส...?',
            onOk() {
                onCancel(false);
            },
            onCancel() {
                // message.success('ยกเลิก')
            },
        });
    };

    const handleSubmit = async () => {
        setLoading(true)
        //console.log("dataModalEdit", dataModalEdit)
        if (step > 0) {
            delete dataModalEdit.address
            dispatch(addCustomer(dataModalEdit))
            dispatch(addPhone(dataPhone));
            changepage('3')
        } else {
            if (dataModalEdit?.nationalId === "นิติบุคคล") {
                alert("ตรวจสอบเลขประจำตัวผู้เสียภาษี")
            } else {
                alert("กรุณากดตรวจเลขบัตร")
            }
        }
        setLoading(false)
    };
    function isThaiNationalID(id) {
        if (!/^[0-9]{13}$/g.test(id)) {
            return false
        }
        let i; let sum = 0
        for ((i = 0), (sum = 0); i < 12; i++) {
            sum += Number.parseInt(id.charAt(i)) * (13 - i)

        }
        const checkSum = (11 - sum % 11) % 10
        if (checkSum === Number.parseInt(id.charAt(12))) {
            //console.log(id, 'เลขบัตรถูกต้อง')
            return true, message.info('เลขบัตรถูกต้อง')
        }
        //console.log("เลขบัตรไม่ถูกต้อง", id)
        return false, message.info('เลขบัตรไม่ถูกต้อง')

    }
    const inputIdCard = (e) => {
        setStep(0)
        setDataModalEdit({ ...dataModalEdit, identificationId: e.target.value })
        isThaiNationalID(e.target.value)
    }
    const handleIdCard = async () => {
        if (dataModalEdit?.identificationId.length !== 13 && dataModalEdit?.nationalId === "บุคคลธรรมดา") {
            alert('กรุณาเพิ่มเลขบัตรประชาชนให้ถูกต้อง')
            setStep(0)
        } else {
            setStep(1)
            const sumOne = { identificationId: dataModalEdit.identificationId }
            //console.log("id",dataPost.identificationId)
            //console.log("sumOne", sumOne)
            if (dataModalEdit.identificationId !== undefined && dataModalEdit.identificationId.trim() !== "") {
                setLoading(true)
                await axios.post(checkNewCCAP, sumOne)
                    .then((res) => {
                        console.log("ตรวจบัตร", res.data)
                        if (res.data) {
                            message.info('มีข้อมูลในฐานข้อมูล')
                            setDataModalEdit({
                                ...dataModalEdit,
                                customerId: res?.data?.customerId,
                                identificationId: res?.data?.identificationId,
                                snam: res?.data?.snam,
                                firstname: res?.data?.firstname,
                                lastname: res?.data?.lastname,
                                nickname: res?.data?.nickname,
                                gender: res?.data?.gender,
                                birthdate: res?.data?.birthdate,
                                issuby: res?.data?.issuby,
                                issudt: res?.data?.issudt,
                                expdt: res?.data?.expdt,
                                CreatedAt: res?.data?.CreatedAt,
                                UpdatedAt: res?.data?.UpdatedAt,
                                nationalId: res?.data?.nationalId ? res?.data?.nationalId : 'บุคคลธรรมดา',
                            })
                            setDataPhone(res.data.phones[0])
                            setDataAddress(res.data.address[0])
                            if (res.data?.ojs[0]) {
                                dispatch(addCareerIncome(res.data.ojs[0]))
                            }
                            dispatch(addAddress(res.data.address[0] ? res.data.address[0] : {}))
                            form.setFieldsValue(
                                {
                                    ...res.data,
                                    nationalId: res?.data?.nationalId ? res?.data?.nationalId : 'บุคคลธรรมดา',
                                    telp: res?.data?.phones[0]?.telp,
                                    birthdate: dayjs(res?.data?.birthdate),
                                    issudt: dayjs(res?.data?.issudt),
                                    expdt: dayjs(res?.data?.expdt),
                                }
                            )
                            setLoading(false)
                        }
                        else {
                            message.info('ไม่พบข้อมูลในฐานข้อมูล')
                            setDataModalEdit({ ...dataModalEdit, customerId: 0, })
                            setDataAddress()
                            setDataPhone()
                            dispatch(addCareerIncome())
                            dispatch(addAddress({}))
                            form.setFieldsValue(
                                {
                                    //nationalId: 'บุคคลธรรมดา',
                                    telp: '',
                                }
                            )
                            setLoading(false)
                        }
                    })
                    .catch((err) => console.log(err))
                setLoading(false)
            }
            else {
                message.info('กรุณากรอกเลขบัตรประชาชน')
                //   setDataPost({
                //     ...dataPost,
                //     customerId: 0,
                //     snam: '',
                //     firstname: '',
                //     lastname: '',
                //     nickname: '',
                //     nationalId: '',
                //     birthdate: '',
                //     gender: '',
                //     issuby: '',
                //     issudt: '',
                //     expdt: ''
                //   })
                //   setDataAddress()
                //   setDataPhone()
                form.setFieldsValue(
                    {
                        snam: '',
                        firstname: '',
                        lastname: '',
                        nickname: '',
                        nationalId: '',
                        birthdate: '',
                        gender: '',
                        issuby: '',
                        issudt: '',
                        expdt: '',
                        telp: '',
                    }
                )
            }
        }
    }
    ////////////////// Phone /////////////////////////////////
    const handlePhone = (e) => {
        setDataPhone({ ...dataPhone, telp: e.target.value, phoneType: "เบอร์ติดต่อ", sendSms: "1" })
    }

    //////////////// นิติบุคคล /////////////////
    const inputTaxId = (e) => {
        setStep(0)
        setDataModalEdit({ ...dataModalEdit, identificationId: e.target.value })
        // isThaiNationalID(e.target.value)
    }

    const handleProduct = (value) => {
        setHolds(value)
        if (value === 'นิติบุคคล') {
            setDataPhone()
            setDataAddress()
            dispatch(addCareerIncome())
            dispatch(addAddress({}))
            setDataModalEdit({
                //...dataModalEdit,
                nationalId: value,
                identificationId: '',
                snam: '',
                firstname: '',
                lastname: '',
                nickname: '',
                birthdate: '',
                gender: '',
                issuby: '',
                issudt: '',
                expdt: '',
            })
            form.setFieldsValue(
                {
                    identificationId: '',
                    snam: '',
                    firstname: '',
                    lastname: '',
                    nickname: '',
                    birthdate: '',
                    gender: '',
                    issuby: '',
                    issudt: '',
                    expdt: '',
                    telp: '',
                }
            )
        } else {
            setCheckIdCard(true)
            setNumber(1)
            setDataPhone()
            setDataAddress()
            dispatch(addCareerIncome())
            dispatch(addAddress({}))
            setDataModalEdit({
                //...dataModalEdit,
                nationalId: value,
                identificationId: '',
                snam: '',
                firstname: '',
                lastname: '',
                nickname: '',
                birthdate: '',
                gender: '',
                issuby: '',
                issudt: '',
                expdt: '',
            })
            form.setFieldsValue(
                {
                    identificationId: '',
                    snam: '',
                    firstname: '',
                    lastname: '',
                    nickname: '',
                    birthdate: '',
                    gender: '',
                    issuby: '',
                    issudt: '',
                    expdt: '',
                    telp: '',
                }
            )
        }
    }
    const handletypeCompany = (value) => {
        setTypeCompany(value)
        setDataModalEdit({ ...dataModalEdit, snam: value })
    }
    const handletypeCompanyed = (value) => {
        setTypeCompanyed(value)
        setDataModalEdit({ ...dataModalEdit, lastname: value })
    }
    return (
        <Row justify={'center'}>
            <Card style={{ width: '1100px' }}>
                <Col style={{ textAlign: 'center' }} >
                    <b><h2>ข้อมูลส่วนตัว</h2></b>
                </Col>
                <Divider style={{ margin: 10 }} />
                <Spin spinning={loading} size='large' tip=" Loading... ">
                    <Form
                        name="basic"
                        labelCol={{
                            span: 12,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}

                        initialValues={{
                            remember: true,
                        }}
                        form={form}
                        onFinish={handleSubmit}
                        autoComplete="off"
                    >
                        <Row gutter={32} justify={'center'}>
                            <Col span={24}>
                                <Row justify={'center'}>
                                    <Form.Item label='ประเภท' name='nationalId'
                                        style={{ textAlign: 'center', width: '200px' }}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input !',
                                            },]}>
                                        <Select
                                            placeholder={'เลือก'}
                                            style={{ width: '250px', height: '40px', }}
                                            defaultValue={'บุคคลธรรมดา'}
                                            name='nationalId'
                                            onChange={(value) => handleProduct(value)}
                                            options={[
                                                {
                                                    label: 'บุคคลธรรมดา',
                                                    value: 'บุคคลธรรมดา',
                                                },
                                                {
                                                    label: 'นิติบุคคล',
                                                    value: 'นิติบุคคล',
                                                },
                                            ]}
                                        />
                                    </Form.Item>
                                </Row>
                                {holds === "นิติบุคคล" ?
                                    <>
                                        <Row className='main2' justify={'center'}>
                                            <aside style={{ width: '80%' }}>
                                                <div>
                                                    <Form.Item label='ประเภทห้าง' name='snam'
                                                        style={{ textAlign: 'left', width: '300px' }}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !',
                                                            },]}>
                                                        <Select
                                                            placeholder={'เลือก'}
                                                            style={{ width: '250px', height: '40px', }}
                                                            name='snam'
                                                            //initialvalues={1}
                                                            onChange={(value) => handletypeCompany(value)}
                                                            options={[
                                                                {
                                                                    label: 'หจก.',
                                                                    value: 'หจก',
                                                                },
                                                                {
                                                                    label: 'บริษัท',
                                                                    value: 'บริษัท',
                                                                },
                                                            ]}
                                                        />
                                                    </Form.Item>
                                                </div>
                                                <div>
                                                    <Form.Item
                                                        label='เลขประจำตัวผู้เสียภาษี'
                                                        name='identificationId'
                                                        style={{ textAlign: 'left', width: '300px' }}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !',
                                                            },]}>

                                                        <Input type='text'
                                                            // maxLength={13}
                                                            name='identificationId'
                                                            // disabled={CheckIdCard ? false : true}
                                                            style={{ width: '250px', color: 'black' }}
                                                            //  onChange={(e) => setDataPost({ ...dataPost, taxID: e.target.value }, isThaiNationalID(e.target.value))}></Input>
                                                            onChange={(e) => inputTaxId(e)}></Input>
                                                    </Form.Item>
                                                </div>
                                                <div>
                                                    {number === 1 ?
                                                        <span
                                                            style={{ display: 'inline-block' }}
                                                        >
                                                            <Form.Item>
                                                                <Button type="primary" onClick={(e) => { handleIdCard(e) }}>ตรวจสอบเลขประจำตัวผู้เสียภาษี</Button>
                                                            </Form.Item>
                                                        </span> : null}
                                                </div>

                                                <div>
                                                    <Form.Item label='ชื่อบริษัท' name='firstname'
                                                        style={{ textAlign: 'left', width: '300px' }}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !',
                                                            },]}
                                                    >
                                                        <Input type='text'
                                                            style={{ width: '250px', color: 'black' }}
                                                            onChange={(e) => setDataModalEdit({ ...dataModalEdit, firstname: e.target.value })}>

                                                        </Input>

                                                    </Form.Item>
                                                </div>
                                                <div>
                                                    <Form.Item label='' name='lastname'
                                                        style={{ textAlign: 'left', width: '300px' }}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !',
                                                            },]}
                                                    >
                                                        <Select
                                                            placeholder={'เลือก'}
                                                            style={{ textAlign: 'left', height: '40px', width: '250px' }}
                                                            name='lastname'
                                                            initialvalues={'จำกัด'}
                                                            onChange={(value) => handletypeCompanyed(value)}
                                                            options={[
                                                                {
                                                                    label: 'มหาชน',
                                                                    value: 'มหาชน',
                                                                },
                                                                {
                                                                    label: 'จำกัด',
                                                                    value: 'จำกัด',
                                                                },
                                                            ]}
                                                        />
                                                    </Form.Item>
                                                </div>
                                                <div>
                                                    <Form.Item label='วันจดทะเบียน' name='birthdate'
                                                        style={{ textAlign: 'left', width: '300px' }}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !',
                                                            },]}>
                                                        <DatePicker format={'YYYY-MM-DD'}
                                                            style={{ height: '40px', width: '250px', color: 'black' }}
                                                            onChange={(e) => {
                                                                if (e) {
                                                                    setDataModalEdit({ ...dataModalEdit, birthdate: e.format('YYYY-MM-DD') })
                                                                }
                                                            }} />
                                                    </Form.Item>
                                                </div>
                                                <div>
                                                    <Form.Item label='เบอร์โทรติดต่อ' name='telp'
                                                        style={{ textAlign: 'left', width: '300px' }}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input !',
                                                            },]}>
                                                        <Input type='text'
                                                            style={{ width: '250px', color: 'black' }}
                                                            onChange={(e) => { handlePhone(e) }}></Input>
                                                    </Form.Item>
                                                </div>
                                            </aside>
                                        </Row>
                                    </>
                                    :
                                    <>
                                        <Row className='main2' justify={'center'}>
                                            <Col span={24} style={{ textAlign: 'center', marginLeft: '15px' }}>
                                                <aside style={{ width: '85%' }}>
                                                    <div>
                                                        <Form.Item
                                                            label='เลขประจำตัวประชาชน'
                                                            name='identificationId'
                                                            style={{ textAlign: 'center', width: '300px' }}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !',
                                                                },]}>

                                                            <Input type='text' maxLength={13}
                                                                disabled={CheckIdCard ? false : true}
                                                                name='identificationId'
                                                                style={{ textAlign: 'left', width: '250px', color: 'black' }}
                                                                //  onChange={(e) => setDataPost({ ...dataPost, identificationId: e.target.value }, isThaiNationalID(e.target.value))}></Input>
                                                                onChange={(e) => inputIdCard(e)}></Input>
                                                        </Form.Item>

                                                    </div>
                                                    <div>
                                                        {number === 1 ?
                                                            <span style={{ display: 'inline-block' }}>
                                                                <Form.Item style={{ textAlign: 'left', width: '100px' }}>
                                                                    <Button type="primary" onClick={(e) => { handleIdCard(e) }}>ตรวจสอบ</Button>
                                                                </Form.Item>
                                                            </span>
                                                            :
                                                            null
                                                        }
                                                    </div>

                                                    {/* {screenWidth > 1170 ?
                                                        <>
                                                            <div>
                                                                <Form.Item
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please input !',
                                                                        },]}
                                                                >
                                                                </Form.Item>
                                                            </div>
                                                        </>
                                                        : null
                                                    } */}
                                                    <div>
                                                        <Form.Item
                                                            label="คำนำหน้าชื่อ"
                                                            style={{ textAlign: 'center', width: '300px' }}
                                                            name="snam"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !',
                                                                },]}>
                                                            <Radio.Group onChange={(e) => setDataModalEdit({ ...dataModalEdit, snam: e.target.value })} style={{ width: '300px' }}>
                                                                <Radio value="นาย" > นาย </Radio>
                                                                <Radio value="นาง"> นาง </Radio>
                                                                <Radio value="นางสาว"> นางสาว </Radio>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </div>
                                                    <div>
                                                        <Form.Item label='ชื่อ' name='firstname'
                                                            style={{ textAlign: 'center', width: '300px' }}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !',
                                                                },]}
                                                        >
                                                            <Input type='text'
                                                                style={{ width: '250px', color: 'black' }}
                                                                onChange={(e) => setDataModalEdit({ ...dataModalEdit, firstname: e.target.value })}></Input>
                                                        </Form.Item>
                                                    </div>
                                                    <div>
                                                        <Form.Item label='นามสกุล' name='lastname'
                                                            style={{ textAlign: 'center', width: '300px' }}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !',
                                                                },]}>
                                                            <Input type='text'
                                                                style={{ width: '250px', color: 'black' }}
                                                                onChange={(e) => setDataModalEdit({ ...dataModalEdit, lastname: e.target.value })}></Input>
                                                        </Form.Item>
                                                    </div>
                                                    <div>
                                                        <Form.Item label='ชื่อเล่น' name='nickname'
                                                            style={{ textAlign: 'center', width: '300px' }}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !',
                                                                },]}
                                                        >
                                                            <Input type='text'
                                                                style={{ width: '250px', color: 'black' }}
                                                                onChange={(e) => setDataModalEdit({ ...dataModalEdit, nickname: e.target.value })}></Input>
                                                        </Form.Item>
                                                    </div>
                                                    <div>
                                                        <Form.Item label='เพศ' name='gender'
                                                            style={{ textAlign: 'center', width: '300px' }}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !',
                                                                },]}>
                                                            <Select
                                                                placeholder={'เลือก'}
                                                                style={{ width: '250px', color: 'black', height: '40px' }}
                                                                onChange={(value) => setDataModalEdit({ ...dataModalEdit, gender: value })}
                                                                options={[
                                                                    {
                                                                        label: 'ชาย',
                                                                        value: 'M',
                                                                    },
                                                                    {
                                                                        label: 'หญิง',
                                                                        value: 'F',
                                                                    },
                                                                ]}
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                    <div>
                                                        <Form.Item label='เกิดวันที่' name='birthdate'
                                                            style={{ textAlign: 'center', width: '300px' }}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !',
                                                                },]}>
                                                            <DatePicker format={'YYYY-MM-DD'}
                                                                style={{ height: '40px', width: '250px' }}
                                                                onChange={(e) => {
                                                                    if (e) {
                                                                        setDataModalEdit({ ...dataModalEdit, birthdate: e.format('YYYY-MM-DD') })
                                                                    }
                                                                }} />
                                                        </Form.Item>
                                                    </div>
                                                    <div>
                                                        <Form.Item label='เบอร์โทรติดต่อ' name='telp'
                                                            style={{ textAlign: 'center', width: '300px' }}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !',
                                                                },]}>
                                                            <Input type='text'
                                                                style={{ width: '250px', color: 'black' }}
                                                                //onChange={(e) => setDataPhone({ ...dataPhone, telp: e.target.value })}></Input>
                                                                onChange={(e) => { handlePhone(e) }}></Input>
                                                        </Form.Item>
                                                    </div>
                                                    <div>
                                                        <Form.Item label='เจ้าพนักงานออกบัตร' name='issuby'
                                                            style={{ textAlign: 'center', width: '300px' }}
                                                        // rules={[
                                                        //     {
                                                        //         required: true,
                                                        //         message: 'Please input !',
                                                        //     },]}
                                                        >
                                                            <Input type='text'
                                                                style={{ width: '250px', color: 'black' }}
                                                                onChange={(e) => setDataModalEdit({ ...dataModalEdit, issuby: e.target.value })}></Input>
                                                        </Form.Item>
                                                    </div>
                                                    <div>
                                                        <Form.Item label='วันออกบัตร' name='issudt'
                                                            style={{ textAlign: 'center', width: '300px' }}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !',
                                                                },]}>
                                                            <DatePicker format={'YYYY-MM-DD'}
                                                                style={{ height: '40px', width: '250px' }}
                                                                onChange={(e) => {
                                                                    if (e) {
                                                                        setDataModalEdit({ ...dataModalEdit, issudt: e.format('YYYY-MM-DD') })
                                                                    }
                                                                }} />
                                                        </Form.Item>
                                                    </div>
                                                    <div>
                                                        <Form.Item label='วันบัตรหมดอายุ' name='expdt'
                                                            style={{ textAlign: 'center', width: '300px' }}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input !',
                                                                },]}>
                                                            <DatePicker format={'YYYY-MM-DD'}
                                                                style={{ height: '40px', width: '250px' }}
                                                                onChange={(e) => {
                                                                    if (e) {
                                                                        setDataModalEdit({ ...dataModalEdit, expdt: e.format('YYYY-MM-DD') })
                                                                    }
                                                                }} />
                                                        </Form.Item>
                                                    </div>
                                                </aside>
                                            </Col>
                                        </Row>
                                    </>
                                }


                            </Col>
                        </Row>

                        <Divider style={{ margin: 5 }} />
                        <Row justify={'center'} >
                            <Space>
                                {/* <Link to="/"> */}
                                <Button type="primary" onClick={onChangeBack} style={{ background: "#bfbfbf" }}>ย้อนกลับ</Button>
                                {/* </Link> */}
                                <Button type="primary" style={{ background: "red" }} onClick={showConfirmCancel}>
                                    ยกเลิก
                                </Button>
                                <Button type="primary" htmlType="submit" style={{ background: "green" }} >
                                    ต่อไป
                                </Button>
                            </Space>
                        </Row>

                    </Form>
                </Spin>
            </Card>
        </Row>
    )
}
