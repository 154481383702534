import React, { useState, useEffect } from 'react'
import { Button, Form, Input, Select, Divider, Row, Col, Modal, Radio, Space, message } from 'antd';
import axios from 'axios';

function EditP({ open, close, dataindex, shootdata, fucnEdit }) {

    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const [getData, setGetData] = useState({ ...shootdata.dataedit });
    const [getIndex, setGetIndex] = useState(dataindex);

    useEffect(() => {
        setGetData({ ...shootdata.dataedit })
        setGetIndex(dataindex)
        form.setFieldsValue(
            {
                ...getData
            }
        )
    }, [shootdata])

    //elements
    const handleCancel = () => { //กดปิด Modal
        close(false);
    };
    //handleSubmit
    const handleSubmit = async () => {
        // console.log("Edit555: ", getData)
        fucnEdit({ getData, getIndex })
        close(false);
    }


    return (
        <>
            {/* Modal แก้ไข้ */}
            <Modal
                open={open}
                onCancel={handleCancel}
                centered
                width={600}
                footer={[null]}
            >
                <Form
                    form={form}
                    name='editdata'
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    style={{
                        maxWidth: '100%',
                    }}
                    onFinish={handleSubmit}>
                    <Row>
                        <Col span={24} style={{ fontSize: '30px', textAlign: 'center' }} ><u>แก้ไขเบอร์</u></Col>
                    </Row>
                    <Divider />
                    <Row>
                        <Col className='gutter-row' span={24}>
                            <Form.Item label='ชนิด' name='phoneType' rules={[
                                {
                                    required: true,
                                    message: 'Please input !',
                                },]}>
                                <Select
                                    placeholder={'เลือก'}
                                    onChange={(value) => setGetData({ ...getData, phoneType: value })}
                                    options={[
                                        {
                                            label: 'เบอร์มือถือ',
                                            value: 'เบอร์มือถือ',
                                        },
                                        {
                                            label: 'เบอร์ที่ทำงาน',
                                            value: 'เบอร์ที่ทำงาน',
                                        },
                                        {
                                            label: 'เบอร์บ้าน',
                                            value: 'เบอร์บ้าน',
                                        }
                                    ]}
                                />
                            </Form.Item>
                            <Form.Item label="เบอร์" name='telp' rules={[
                                {
                                    required: true,
                                    message: 'Please input !',
                                },]}>
                                <Input onChange={(e) => setGetData({ ...getData, telp: e.target.value })} />
                            </Form.Item>
                            <Form.Item label="ส่ง sms" name="sendSms" rules={[
                                {
                                    required: true,
                                    message: 'Please input !',
                                },]}>
                                <Radio.Group onChange={(e) => setGetData({ ...getData, sendSms: e.target.value })} >
                                    <Radio value="1"> ส่ง </Radio>
                                    <Radio value="0"> ไม่ส่ง </Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item label="หมายเหตุ" name='note' >
                                <Input onChange={(e) => setGetData({ ...getData, note: e.target.value })} />
                            </Form.Item>

                            <Divider />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <Space>
                                <Button type="primary" onClick={handleCancel} style={{ background: "red" }}>ยกเลิก</Button>
                                <Button type="primary" htmlType="submit" style={{ background: "green" }} >บันทึก</Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
                {contextHolder}
            </Modal>
        </>
    )

};

export default EditP
