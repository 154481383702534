import React, { useState } from 'react'
import { Modal, Col, Row, Space, Steps } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addCustomer } from '../../../../../redux/Customer';
import { addCar } from '../../../../../redux/Car';
import { addAddress } from '../../../../../redux/Address';
import { addPhone } from '../../../../../redux/Phone';
import { addOccupation } from '../../../../../redux/Occupation';
// import { addGuarantor } from '../../redux/Guarantor';

import GAddress from './GAddress';
import GPhone from './GPhone';
import GCustomer from './GCustomer';
import GDetail from './GDetail';
import GCareer from './GCareer';
import AddGuarantoesNewAd from '../AddGuarantoesNewAd';

export default function ModalAddGuarantor({ open, close, indexedit, pregarno, dataloadGua, setSendback, getData, oData }) {
    const customers = useSelector(state => state.customers)
    const cars = useSelector(state => state.cars)
    const [saveCusIdMain] = useState(customers.data);
    const [saveCarId] = useState(cars.data);
    // const [saveGuaranter] = useState(guarantor.data);

    const [count, setCount] = useState(1)
    const dispatch = useDispatch()
    const [current, setCurrent] = useState(0);
    const { confirm } = Modal

    const [dataCustomer, setDataCustomer] = useState();
    const [dataAddress, setDataAddress] = useState();
    const [dataPhone, setDataPhone] = useState();
    const [dataCareer, setDataCareer] = useState();

    // console.log("dataCustomer",dataCustomer)
    // console.log("dataAddress",dataAddress)
    // console.log("dataPhone",dataPhone)
    // console.log("เลขพาท + 1", indexedit + 1)


    const next = () => {
        setCurrent(current + 1);
        setCount(count + 1);
    };
    const prev = () => {
        setCurrent(current - 1);
        setCount(count - 1);
    };
    const steps = [
        {
            title: 'ข้อมูลส่วนตัว',
        },
        {
            title: 'รายละเอียดที่อยู่',
        },
        {
            title: 'ช่องทางการติดต่อ',
        },
        {
            title: 'อาชีพ - รายได้',
        },
        {
            title: 'สรุปรายละเอียด/บันทึกข้อมูล',
        },
    ];

    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
    }));

    const Onclose = () => {
        close(false);
        dispatch(addCustomer(saveCusIdMain))
        dispatch(addCar(saveCarId))
        dispatch(addAddress())
        dispatch(addPhone())
        dispatch(addOccupation())
    };

    const showConfirm = () => {
        confirm({
            title: 'คุณต้องการที่ปิดหน้าต่างข้อมูลผู้กู้หรือไม่...?',
            content: 'หากกดปิดหน้าต่างผู้กู้ข้อมูลจะไม่ถูกบันทึก กด OK เพื่อยืนยัน',
            onOk() {
                Onclose();
            },
            onCancel() {
            },
        });
    };

    return (
        <>
            <Modal title="เพิ่มข้อมูลผู้ค้ำ" open={open} onCancel={showConfirm} width={1200} footer={null} >
                <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                    <Row>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <Steps current={current} items={items} />
                        </Col>
                    </Row>
                    {count === 1 && (<AddGuarantoesNewAd
                        close={showConfirm}
                        next={next}
                        cusId={saveCusIdMain}
                        dataCustomer={dataCustomer}
                        setDataCustomer={setDataCustomer}
                        setDataAddress={setDataAddress}
                        indexedit={indexedit}
                        setDataPhone={setDataPhone}
                        oData={oData}
                        getData={getData} />)}
                    {/* {count === 1 && (<GCustomer close={showConfirm} next={next} chg={CheckGuER} cusId={saveCusIdMain} />)} */}
                    {count === 2 && (<GAddress close={showConfirm} next={next} prev={prev} dataAddress={dataAddress} setDataAddress={setDataAddress} dataCustomer={dataCustomer} />)}
                    {count === 3 && (<GPhone close={showConfirm} next={next} prev={prev} dataPhone={dataPhone} setDataPhone={setDataPhone} dataCustomer={dataCustomer} />)}
                    {count === 4 && (<GCareer close={showConfirm} next={next} prev={prev} dataCareer={dataCareer} setDataCareer={setDataCareer} dataCustomer={dataCustomer} />)}
                    {count === 5 && (<GDetail close={showConfirm} saveclose={Onclose} prev={prev} carId={saveCarId} dataloadGua={dataloadGua} dataPhone={dataPhone} dataAddress={dataAddress} dataCustomer={dataCustomer} dataCareer={dataCareer} pregarno={pregarno + 1} indexedit={indexedit} setSendback={setSendback} />)}
                </Space>
            </Modal>
        </>
    )
};