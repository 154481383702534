import React, { useState, useEffect } from 'react'
import { Button, Form, Input, Select, Divider, Row, Col, Modal, Card, Space, Spin } from 'antd';
import axios from 'axios';
import { Option } from 'antd/es/mentions';
import { getocc, newgetsection, newgetsub } from '../../../../../../file_mid/all_api';

export default function AddCareer({ open, close, fucnAdd, dataCustomer }) {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm();
  const [occupation, setOccupation] = useState([]) // getdatabase occ
  const [sub, setSub] = useState([]) // getdatabase sub
  const [section, setSection] = useState([]) // getdatabase section
  const [career, setCareer] = useState({
    customerId: 0,
    desc: "",
    descSection: "",
    descSub: "",
    companyName: "",
    expensesMonth: 0,
    expensesMonthDescription: "",
    governmentPrivate: 0,
    idArgroup: 0,
    idPljob: 0,
    idPloccupation: 0,
    incomeMainDescription: "",
    incomeMonth: 0,
    incomesMainId: 0,
    jobPosition: 0,
    mainOccupation: true,
    occupationDescription: "",
    occupationJobPositionId: 0,
    occupationalGroup: 0,
  });

  useEffect(() => {
    loadOccupation()
    if (dataCustomer[0]?.customerId > 0) {
      setCareer({
        customerId: dataCustomer[0]?.customerId,
        desc: "",
        descSection: "",
        descSub: "",
        companyName: "",
        expensesMonth: 0,
        expensesMonthDescription: "",
        governmentPrivate: 0,
        idArgroup: 0,
        idPljob: 0,
        idPloccupation: 0,
        incomeMainDescription: "",
        incomeMonth: 0,
        incomesMainId: 0,
        jobPosition: 0,
        mainOccupation: true,
        occupationDescription: "",
        occupationJobPositionId: 0,
        occupationalGroup: 0,
      })
    }
  }, [])

  // Data Select Form
  const loadOccupation = async () => {
    setLoading(true)
    await axios.get(getocc)
      .then((res) => {
        setOccupation(res.data)
        setLoading(false)
      })
      .catch((err) => console.log(err))
    setLoading(false)
  }

  const loadSubOccupation = async (value) => {
    const idOccupation = { idOccupation: value }
    setLoading(true)
    await axios.post(newgetsub, idOccupation)
      .then((res) => {
        //console.log("sub", res.data)
        setSub(res.data)
        setLoading(false)
        form.setFieldsValue(
          {
            governmentPrivate: "",
            jobPosition: "",
            companyName: "",
          }
        )
      })
      .catch((err) => console.log(err))
    setLoading(false)
  }

  const loadOccupationSection = async (value) => {
    const idSub = { idSub: value }
    setLoading(true)
    await axios.post(newgetsection, idSub)
      .then((res) => {
        //console.log("section", res.data)
        setSection(res.data)
        setLoading(false)
        form.setFieldsValue(
          {
            jobPosition: "",
            companyName: "",
          }
        )
      })
      .catch((err) => console.log(err))
    setLoading(false)
  }

  const getOccupation = (value) => {
    setCareer({
      ...career,
      governmentPrivate: "",
      governmentPrivateTH: "",
      jobPosition: "",
      companyName: "",
      occupationalGroup: value,
      desc: occupation.find((e) => e.idOccupation === value).desc
    })
    loadSubOccupation(value)
  }

  const getSub = (value) => {
    setCareer({
      ...career,
      jobPosition: 32,
      jobPositionTH: "",
      idPloccupation: 12,
      idArgroup: 11,
      companyName: "",
      governmentPrivate: value,
      descSub: sub.find((e) => e.idSub === value).descSub,
      idPljob: sub.find((e) => e.idSub === value).idPljob
    })
    loadOccupationSection(value)
  }

  const getSection = (value) => {
    setCareer({
      ...career,
      jobPosition: value,
      descSection: section.find((e) => e.idSection === value).descSection,
      idPloccupation: section.find((e) => e.idSection === value).idPloccupation,
      idArgroup: section.find((e) => e.idSection === value).idArgroup
    })
  }

  const handleCancel = () => {
    close(false);
  };

  const handleSubmit = async () => {
    setLoading(true)
    // console.log('add', address)
    fucnAdd({ career })
    setLoading(false)
    close(false);
  }

  return (
    <>
      <Modal
        open={open}
        centered
        onCancel={handleCancel}
        width={1100}
        footer={[null]}
      >
        <Row justify={'center'}>
          <Card style={{ width: '1100px' }}>
            <Spin spinning={loading} size='large' tip=" Loading... ">
              <Form
                form={form}
                name='add'
                labelCol={{
                  span: 10,
                }}
                wrapperCol={{
                  span: 24,
                }}
                style={{
                  maxWidth: '100%',
                }}
                autoComplete="off"
                initialValues={{ remember: true }}
                onFinish={handleSubmit}

              >
                <Row gutter={32}>
                  <Col className='gutter-row' span={24} style={{ fontSize: '30px', textAlign: 'center' }} ><u>เพิ่มอาชีพ</u></Col>
                </Row>
                <Divider style={{ margin: 5 }} />
                <Row className='main2' justify={'center'}>
                  <Card style={{ width: '100%' }} >
                    <aside style={{ width: '90%', textAlign: 'center' }}>
                      <div>
                        <Form.Item label='กลุ่มอาชีพ' name='occupationalGroup' style={{ textAlign: 'left' }}
                          rules={[
                            {
                              required: true,
                              message: 'Please input !',
                            },]}>
                          <Select
                            placeholder={'เลือก'}
                            style={{ height: '40px', width: '250px' }}
                            onChange={getOccupation}
                          >
                            {occupation?.map((item) => {
                              return (
                                <Select.Option key={item.idOccupation} value={item.idOccupation}>
                                  {item.desc}
                                </Select.Option>
                              )
                            })}

                          </Select>
                        </Form.Item>

                        <Form.Item label='เลือกอาชีพ' name='governmentPrivate' style={{ textAlign: 'left' }}
                          rules={[
                            {
                              required: true,
                              message: 'Please input !',
                            },]}>
                          <Select
                            placeholder={'เลือก'}
                            style={{ height: '40px', width: '250px' }}
                            onChange={getSub}
                          >
                            {sub?.map((item) => {
                              return (
                                <Select.Option key={item.idSub} value={item.idSub}>
                                  {item.descSub}
                                </Select.Option>
                              )
                            })}

                          </Select>
                        </Form.Item>

                        <Form.Item label='สาขาอาชีพ' name='jobPosition' style={{ textAlign: 'left' }}>
                          <Select
                            placeholder={'เลือก'}
                            style={{ height: '40px', width: '250px' }}
                            onChange={getSection}
                          >
                            {section?.map((item) => {
                              return (
                                <Select.Option key={item.idSection} value={item.idSection}>
                                  {item.descSection}
                                </Select.Option>
                              )
                            })}

                          </Select>
                        </Form.Item>

                        <Form.Item label='ชื่อบริษัท สถานที่ทำงาน' name='companyName'
                          style={{ textAlign: 'left' }}
                          rules={[
                            {
                              required: true,
                              message: 'Please input !',
                            },]}>
                          <Input type='text'
                            style={{ height: '40px', width: '250px' }}
                            onChange={(e) => setCareer({ ...career, companyName: e.target.value })}></Input>
                        </Form.Item>

                        <Form.Item label='หมายเหตุ' name='occupationDescription' style={{ textAlign: 'left' }}>
                          <Input type='text'
                            style={{ height: '40px', width: '250px' }}
                            onChange={(e) => setCareer({ ...career, occupationDescription: e.target.value })}></Input>
                        </Form.Item>

                      </div>

                      <div>
                        <Form.Item label='รายได้ต่อเดือน' name='incomeMonth'
                          rules={[
                            {
                              required: true,
                              message: 'Please input !',
                            },]}>
                          <Input type='text' suffix='บาท'
                            style={{ color: 'black', height: '50px', width: '250px' }}
                            onChange={(e) => setCareer({ ...career, incomeMonth: parseInt(e.target.value) })}></Input>
                        </Form.Item>

                        <Form.Item label='หมายเหตุ' name='incomeMainDescription' >
                          <Input type='text'
                            style={{ color: 'black', height: '50px', width: '250px' }}
                            onChange={(e) => setCareer({ ...career, incomeMainDescription: e.target.value })}></Input>
                        </Form.Item>

                        <Form.Item label='ค่าใช้จ่ายต่อ / เดือน' name='expensesMonth'
                          rules={[
                            {
                              required: true,
                              message: 'Please input !',
                            },]}>
                          <Input type='text' suffix='บาท'
                            style={{ color: 'black', height: '50px', width: '250px' }}
                            onChange={(e) => setCareer({ ...career, expensesMonth: parseInt(e.target.value) })}></Input>
                        </Form.Item>

                        <Form.Item label='หมายเหตุ' name='expensesMonthDescription' >
                          <Input type='text'
                            style={{ color: 'black', height: '50px', width: '250px' }}
                            onChange={(e) => setCareer({ ...career, expensesMonthDescription: e.target.value })}></Input>
                        </Form.Item>

                      </div>
                    </aside>
                  </Card>
                </Row>
                <Divider style={{ margin: 5 }} />
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <Space>
                      <Button type="primary" onClick={handleCancel} style={{ background: "red" }}> ยกเลิก</Button>
                      <Button type="primary" htmlType="submit" style={{ background: "green" }}>บันทึก</Button>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Spin>
          </Card>
        </Row>
      </Modal>
    </>
  )
}
