import React from 'react'
import { Routes, Route } from "react-router-dom"
import TabelLoanCarLocat from "./report_pn3/tabelLoanCarLocat";

export default function Main_Report3() {
    return (
        <Routes>
            <Route path="/" element={<TabelLoanCarLocat />} />
        </Routes>
    )
}
