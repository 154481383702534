import React from 'react'
import { Routes, Route } from "react-router-dom"
import TabelLoanMarketing from "./report_pn2/tabelLoanMarketing";

export default function Main_report2() {
    return (
        <Routes>
            <Route path="/" element={<TabelLoanMarketing />} />
        </Routes>
    )
}

