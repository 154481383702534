import React, { useEffect, useState } from 'react'
import { Button, Input, Form, DatePicker, Card, Col, Row, message, Select, Radio, Spin, Space, Divider, Modal } from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import '../css/Media.css'
//import { useForm } from 'antd/es/form/Form';
import { useDispatch, useSelector } from 'react-redux';
import { addCustomer } from '../../../redux/Customer';
import { addAddress } from '../../../redux/Address';
import { addPhone } from '../../../redux/Phone';
import { addCar } from '../../../redux/Car';
import { addLoan } from '../../../redux/Loan';
import { addOldLoan } from '../../../redux/OldLoan';
import { addImage } from '../../../redux/Image';
import { addLand } from '../../../redux/Land';
import { addLoanLand } from '../../../redux/LoanLand';
import { addCareerIncome } from '../../../redux/CareerIncome';
import { addGuarantor } from '../../../redux/Guarantor';
import { addOccupation } from '../../../redux/Occupation';
import { checkCCAP, checkNewCCAP, testupload } from '../../file_mid/all_api';
const { Option } = Select;

export default function AddCustomer() {
  const currentDate = dayjs();
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const imgs = useSelector(state => state.imgs)
  const [checkPreImg, setCheckPreImg] = useState(false)

  const customers = useSelector(state => state.customers)
  const Addresss = useSelector(state => state.Addresss)
  const Phones = useSelector(state => state.Phones)

  const [number, setNumber] = useState(0);
  const [CheckIdCard, setCheckIdCard] = useState(false)
  const [dataPost, setDataPost] = useState({}) // customer
  /////////////////////////////////////////////////////////////////////////////////////
  const [dataAddress, setDataAddress] = useState({}) // address
  const [count, setCount] = useState()
  /////////////////////////////////////////////////////////////////////////////////////////
  const [dataPhone, setDataPhone] = useState({}) // phone
  ///////////////////////////////////////////////////////////////////////////////
  const [fileList, setFileList] = useState() // รูปบัตรประชาชน
  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState(0) // ตรวจสอบการกดตรวจบัตร

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [selectProduct, setSelectProduct] = useState()
  const [selectTypeCar, setSelectTypeCar] = useState()
  const [typeCompany, setTypeCompany] = useState('หจก.');
  const [typeCompanyed, setTypeCompanyed] = useState('หจก.');
  const [imgPrePath, setImgPrePath] = useState()

  const addCustomers = localStorage.getItem('addCustomer')
  const addPhones = localStorage.getItem('addPhone')
  const addAddresss = localStorage.getItem('addAddress')
  const dataAddCustomer = JSON.parse(addCustomers)
  const dataAddPhone = JSON.parse(addPhones)
  const dataAddAddress = JSON.parse(addAddresss)
  const [holds, setHolds] = useState(dataAddCustomer?.nationalId);

  useEffect(() => {
    // console.log("viewss",dataAddCustomer)
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [])

  useEffect(() => {
    if (dataAddCustomer) {
      if (dataAddCustomer?.preImg) {
        //setCheckPreImg(true)
        setImgPrePath({ preImg: dataAddCustomer?.preImg })
      }
      if (!dataAddCustomer.identificationId) {
        setHolds("บุคคลธรรมดา")
        setCheckIdCard(true)
        setNumber(1)
        form.setFieldsValue(
          {
            nationalId: "บุคคลธรรมดา",
          }
        )
      } else {
        setHolds(dataAddCustomer?.nationalId)
        setStep(1)
        setCheckIdCard(false)
        form.setFieldsValue(
          {
            nationalId: dataAddCustomer?.nationalId,
          }
        )
      }
      setSelectProduct({ ...selectProduct, productTypeId: dataAddCustomer?.productTypeId })
      setSelectTypeCar({ ...selectTypeCar, productTypeCar: dataAddCustomer?.productTypeCar })
      setDataPost({ ...dataAddCustomer, nationalId: dataAddCustomer.nationalId ? dataAddCustomer.nationalId : "บุคคลธรรมดา" })
      setDataAddress(dataAddAddress)
      setDataPhone(dataAddPhone)
      if (dataAddCustomer.expdt == null || dataAddCustomer.issudt == null || dataAddCustomer.birthdate == null) {
        form.setFieldsValue(
          {
            ...dataAddCustomer, ...dataAddAddress, ...dataAddPhone,
            birthdate: dataAddCustomer.birthdate ? dayjs(dataAddCustomer.birthdate) : '',
            issudt: dataAddCustomer.issudt ? dayjs(dataAddCustomer.issudt) : '',
            expdt: dataAddCustomer.expdt ? dayjs(dataAddCustomer.expdt) : '',
          }
        )
      } else {
        form.setFieldsValue(
          {
            ...dataAddCustomer, ...dataAddAddress, ...dataAddPhone,
            birthdate: dayjs(dataAddCustomer?.birthdate),
            issudt: dayjs(dataAddCustomer?.issudt),
            expdt: dayjs(dataAddCustomer?.expdt),
          }
        )
      }
    } else {
      //console.log("2")
      form.setFieldsValue(
        {
          identificationId: "",
          // taxid: "",
          // companyname: "",
          snam: "",
          firstname: "",
          lastname: "",
          nickname: "",
          birthdate: "",
          gender: "",
          telp: "",
          issuby: "",
          issudt: "",
          expdt: ""
        }
      )
    }

  }, [])

  // const success = () => {
  //   Modal.success({
  //     content: 'บันทึกสำเร็จ',
  //   })
  // }
  // const error = () => {
  //   Modal.error({
  //     title: 'บันทึกไม่สำเร็จ',
  //     content: 'กรุณาลองใหม่อีกครั้ง',
  //   })
  // }

  const handleProduct = (value) => {
    setHolds(value)
    setDataPost({ ...dataPost, nationalId: value })
    if (value === "นิติบุคคล") {
      setCheckIdCard(true)
      setNumber(1)
    }
  }
  const handletypeCompany = (value) => {
    setTypeCompany(value)
    setDataPost({ ...dataPost, snam: value })
  }
  const handletypeCompanyed = (value) => {
    setTypeCompanyed(value)
    setDataPost({ ...dataPost, lastname: value })
  }

  const error = () => {
    Modal.error({
      title: 'บันทึกไม่สำเร็จ',
      content: 'กรุณาลองใหม่อีกครั้ง',
    })
  }
  const unauthorized = () => {
    Modal.error({
      title: 'unauthorized',
      content: 'คุณไม่มีสิทธิ์ใช้งานเมนูนี้',
    })
  }
  const handleSubmit = async (e) => {
    setLoading(true)
    // console.log("customer", dataPost)
    // const totalChunks = 4; // จำนวนของกลุ่มที่คุณต้องการ
    // const chunkSize = Math.ceil(imgs?.data?.length / totalChunks);
    // const chunks = [];
    // for (let i = 0; i < imgs?.data?.length; i += chunkSize) {
    //   chunks.push(imgs.data.slice(i, i + chunkSize));
    // }
    // const result = { ...dataPost, img: chunks[0] }
    // console.log("resultCus", result)
    const expDate = dayjs(dataPost.expdt);
    if (step === 1) {
      if (holds === "นิติบุคคล") {
        delete dataPost.address
        delete dataPost.phones
        dispatch(addCustomer({ ...dataPost, ...selectProduct, ...selectTypeCar, ...imgPrePath }))
        localStorage.setItem('addCustomer', JSON.stringify({ ...dataPost, ...selectProduct, ...selectTypeCar, ...imgPrePath }))
        if (dataAddress) {
          dispatch(addAddress(dataAddress))
          localStorage.setItem('addAddress', JSON.stringify(dataAddress))
        }
        if (dataPhone) {
          dispatch(addPhone(dataPhone))
          localStorage.setItem('addPhone', JSON.stringify(dataPhone))
        }
        // if (checkPreImg === true) {
        //   setLoading(false)
        //   navigate('/offercase/addguarantor')
        // }
        // if (checkPreImg === false) {
        //   await axios.post('https://offercase-car-i67f6gaaqa-as.a.run.app/Api/create-pre-part', result)
        //   //await axios.post('http://localhost:8080/Api/create-pre-part', result)
        //     .then(res => {
        //       delete res.data.img
        //       if (res.status === 200) {
        //         console.log("res =", res.data)
        //         dispatch(addCustomer({ ...res.data, ...selectProduct, ...selectTypeCar }))
        //         setLoading(false)
        //         navigate('/offercase/addguarantor')
        //       }
        //     })
        //     .catch((err) => {
        //       if (err.response.request.status === 401) {
        //         unauthorized()
        //       }
        //       else {
        //         error()
        //       }
        //       console.log("err", err)
        //       setLoading(false)
        //     })
        // }
        setLoading(false)
        navigate('/offercase/addguarantor')
      } else {
        if (expDate.isAfter(currentDate, 'day') || expDate.isSame(currentDate, 'day')) {
          delete dataPost.address
          // delete dataPost.carLoanDetails
          delete dataPost.phones
          // delete dataPost.ojs
          // console.log("customer", dataPost)
          // console.log("address", dataAddress)
          // console.log("phone", dataPhone)
          //dispatch(addCustomer(dataPost))
          dispatch(addCustomer({ ...dataPost, ...selectProduct, ...selectTypeCar, ...imgPrePath }))
          localStorage.setItem('addCustomer', JSON.stringify({ ...dataPost, ...selectProduct, ...selectTypeCar, ...imgPrePath }))
          if (dataAddress) {
            dispatch(addAddress(dataAddress))
            localStorage.setItem('addAddress', JSON.stringify(dataAddress))
          }
          if (dataPhone) {
            dispatch(addPhone(dataPhone))
            localStorage.setItem('addPhone', JSON.stringify(dataPhone))
          }
          // if (checkPreImg === true) {
          //   setLoading(false)
          //   navigate('/offercase/addguarantor')
          // }
          // if (checkPreImg === false) {
          //   await axios.post('https://offercase-car-i67f6gaaqa-as.a.run.app/Api/create-pre-part', result)
          //   //await axios.post('http://localhost:8080/Api/create-pre-part', result)
          //     .then(res => {
          //       delete res.data.img
          //       if (res.status === 200) {
          //         console.log("res =", res.data)
          //         dispatch(addCustomer({ ...res.data, ...selectProduct, ...selectTypeCar }))
          setLoading(false)
          navigate('/offercase/addguarantor')
          //       }
          //     })
          //     .catch((err) => {
          //       if (err.response.request.status === 401) {
          //         unauthorized()
          //       }
          //       else {
          //         error()
          //       }
          //       console.log("err", err)
          //       setLoading(false)
          //     })
          // }
        } else {
          alert("บัตรประชาชนหมดอายุ")
        }
      }

    } else {
      if (dataPost?.nationalId === "นิติบุคคล") {
        alert("ตรวจสอบเลขประจำตัวผู้เสียภาษี")
      } else {
        alert("กรุณากดตรวจเลขบัตร")
      }
    }
    // const list = JSON.parse(menu)
    // const tk = JSON.parse(token)
    // console.log("menu", "1")
    // console.log("Token", tk)
    // const headers = { 
    //   "Authorization": `Bearer ${tk}`, 
    //   "Menu": JSON.stringify("3")
    // }
    // await axios.post('http://localhost:8080/auth/createcus', dataPost, { headers: headers })
    //   .then(res => {
    //     if (res.status === 200) {
    //       success()
    //     }
    //   })
    //   .catch((err) => error())
    //form.resetFields()

    setLoading(false)
    // }
  }
  ////////////// ข้อมูลยูสในเดต้าเบส ////////////////////////////////////////////////////////////////
  const loadDataOne = async (data) => {
    //console.log("DATA", data)
    const one = { identificationId: data.identificationId }
    setLoading(true)
    await axios.post(checkCCAP, one)
      .then((res) => {
        //console.log("RES-DATA", res.data)
        if (res.data) {
          //alert('มีข้อมูลในฐานข้อมูล')
          message.info('มีข้อมูลในฐานข้อมูล')
          //setIsModalOpen(true)
          if (window.confirm('คุณต้องการใช้ข้อมูลในฐานข้อมูลหรือไม่ ?')) {
            //splitAddress(res.data)          
            // Save it!
            setDataPost(res.data)
            setDataPhone(res.data.phones[0])
            setDataAddress(res.data.address[0])
            if (res.data.expdt == null || res.data.issudt == null) {
              form.setFieldsValue(
                {
                  ...res.data,
                  telp: res?.data?.phones[0]?.telp,
                  birthdate: dayjs(res?.data?.birthdate),
                  issudt: '',
                  expdt: '',
                }
              )
              setLoading(false)
            }
            else {
              form.setFieldsValue(
                {
                  ...res.data,
                  telp: res?.data?.phones[0]?.telp,
                  birthdate: dayjs(res?.data?.birthdate),
                  issudt: dayjs(res?.data?.issudt),
                  expdt: dayjs(res?.data?.expdt),
                }
              )
            }
          } else {
            // Do nothing!
            //console.log('cancel');
            setDataPost({ ...dataPost, ...data, customerId: res.data.customerId })
            if (res.data.phones) {
              setDataPhone({ ...dataPhone, customerId: res.data.customerId, phoneId: res.data.phones[0].phoneId })
            }
            // setDataAddress({ ...dataAddress, customerId: res.data.customerId, addressId: res.data.address[0].addressId })
            //splitAddress(data)
            if (data.expdt == null || data.issudt == null) {
              form.setFieldsValue(
                {
                  ...data,
                  nickname: '',
                  gender: '',
                  birthdate: dayjs(data?.birthdate),
                  issudt: '',
                  expdt: '',
                  telp: '',
                }
              )
              setLoading(false)
            }
            else {
              form.setFieldsValue(
                {
                  ...data,
                  nickname: '',
                  gender: '',
                  birthdate: dayjs(data?.birthdate),
                  issudt: dayjs(data?.issudt),
                  expdt: dayjs(data?.expdt),
                  telp: '',
                }
              )
            }
          }
          setLoading(false)
        }
        else {
          //alert('ไม่พบข้อมูลในฐานข้อมูล')
          message.info('ไม่พบข้อมูลในฐานข้อมูล')
          setDataPost({ ...data, customerId: 0 })
          // setDataAddress({ ...dataAddress, customerId: 0, addressId: 0 })
          setDataPhone({})
          if (data.expdt == null || data.issudt == null) {
            form.setFieldsValue(
              {
                ...data,
                nickname: '',
                gender: '',
                birthdate: dayjs(data?.birthdate) || "",
                issudt: '',
                expdt: '',
                telp: '',
              }
            )
            setLoading(false)
          }
          else {
            form.setFieldsValue(
              {
                ...data,
                nickname: '',
                gender: '',
                birthdate: dayjs(data?.birthdate) || "",
                issudt: '',
                expdt: '',
                telp: '',
              }
            )
          }
          setLoading(false)
        }
      })
      .catch((err) => console.log(err))
    setLoading(false)
  }
  /////////// ดึงข้อมูลจากรูปบัตรประชาชน /////////////////////////////////////////////////////////////////  
  const handleScanIdCard = async (value) => {
    const imgId = { image: value }
    setLoading(true)
    const headers = { 'Content-Type': 'application/json' }
    await axios.post(testupload, imgId, { headers: headers })
      .then(res => {
        //console.log("img", res.data)
        if (res.data) {
          //setDataPost(res.data[0])
          splitAddress(res.data[0])
          loadDataOne(res.data[0])
          setLoading(false)
        }
        else {
          message.info('ไม่สามารถอ่านได้')
          setDataPost({})
          setDataAddress({})
          setDataPhone({})
          setLoading(false)
        }
      })
      .catch((err) => console.log(err))
    setLoading(false)
  }

  /////////// กดตรวจบัตรประชาชน ///////////////////////////////////////////////////////////////////////
  const handleIdCard = async () => {
    if (dataPost?.identificationId?.length !== 13 && dataPost?.nationalId === "บุคคลธรรมดา") {
      alert('กรุณาเพิ่มเลขบัตรประชาชนให้ถูกต้อง')
      setStep(0)
    } else {
      setStep(1)
      const sumOne = { identificationId: dataPost.identificationId }
      //console.log("id",dataPost.identificationId)
      //console.log("sumOne", sumOne)
      if (dataPost.identificationId !== undefined && dataPost.identificationId.trim() !== "") {
        setLoading(true)
        await axios.post(checkNewCCAP, sumOne)
          .then((res) => {
            //console.log("ตรวจบัตร", res.data)
            if (res.data) {
              message.info('มีข้อมูลในฐานข้อมูล')
              setDataPost(res.data)
              setDataPhone(res.data.phones[0])
              setDataAddress(res.data.address[0])
              if (res.data?.ojs[0]) {
                dispatch(addCareerIncome(res.data.ojs[0]))
                localStorage.setItem('addCareerIncome', JSON.stringify(res.data.ojs[0]))
              }
              form.setFieldsValue(
                {
                  ...res.data,
                  telp: res?.data?.phones[0]?.telp,
                  birthdate: dayjs(res?.data?.birthdate),
                  issudt: dayjs(res?.data?.issudt),
                  expdt: dayjs(res?.data?.expdt),
                }
              )
              setLoading(false)
            }
            else {
              message.info('ไม่พบข้อมูลในฐานข้อมูล')
              setDataPost({
                ...dataPost,
                customerId: 0,
                // snam: '',
                // firstname: '',
                // lastname: '',
                // nickname: '',
                // national_id: '',
                // birthdate: '',
                // gender: '',
                // issuby: '',
                // issudt: '',
                // expdt: ''
              })
              setDataAddress()
              setDataPhone()
              dispatch(addCareerIncome())
              localStorage.removeItem('addCareerIncome');
              form.setFieldsValue(
                {
                  // snam: '',
                  // firstname: '',
                  // lastname: '',
                  // nickname: '',
                  // nationalId: '',
                  // birthdate: '',
                  // gender: '',
                  // issuby: '',
                  // issudt: '',
                  // expdt: '',
                  telp: '',
                }
              )
              setLoading(false)
            }
          })
          .catch((err) => console.log(err))
        setLoading(false)
      }
      else {
        message.info('กรุณากรอกเลขบัตรประชาชน')
        setDataPost({
          ...dataPost,
          customerId: 0,
          snam: '',
          firstname: '',
          lastname: '',
          nickname: '',
          national_id: '',
          birthdate: '',
          gender: '',
          issuby: '',
          issudt: '',
          expdt: ''
        })
        setDataAddress()
        setDataPhone()
        form.setFieldsValue(
          {
            snam: '',
            firstname: '',
            lastname: '',
            nickname: '',
            nationalId: '',
            birthdate: '',
            gender: '',
            issuby: '',
            issudt: '',
            expdt: '',
            telp: '',
          }
        )
      }
    }
  }
  function isThaiNationalID(id) {
    if (!/^[0-9]{13}$/g.test(id)) {
      return false
    }
    let i; let sum = 0
    for ((i = 0), (sum = 0); i < 12; i++) {
      sum += Number.parseInt(id.charAt(i)) * (13 - i)

    }
    const checkSum = (11 - sum % 11) % 10
    if (checkSum === Number.parseInt(id.charAt(12))) {
      //console.log(id, 'เลขบัตรถูกต้อง')
      return true, message.info('เลขบัตรถูกต้อง')
    }
    //console.log("เลขบัตรไม่ถูกต้อง", id)
    return false, message.info('เลขบัตรไม่ถูกต้อง')

  }
  ////
  const inputIdCard = (e) => {
    setStep(0)
    setDataPost({ ...dataPost, identificationId: e.target.value })
    isThaiNationalID(e.target.value)
  }
  const inputTaxId = (e) => {
    setStep(0)
    setDataPost({ ...dataPost, identificationId: e.target.value })
    // isThaiNationalID(e.target.value)
  }
  ////////////// รับไฟล์รูปบัตร /////////////////////////////////
  const handleFileInputChange = (event) => {
    setStep(1)
    const file = event.target.files[0];
    console.log("file", file)
    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg']
    if (file) {
      if (validImageTypes.includes(file.type)) {
        const reader = new FileReader(); // สร้าง FileReader object
        reader.onloadend = () => { // ฟังก์ชันที่ทำงานเมื่ออ่านไฟล์เสร็จสิ้น    
          const base64Data = reader.result; // รับข้อมูล Base64 จาก FileReader result
          //console.log('Base64 Data:', base64Data);
          setFileList({ image: base64Data })
          handleScanIdCard(base64Data)
        }
        reader.readAsDataURL(file) // อ่านไฟล์และแปลงเป็น Base64    
      }
    }
  }

  //////////////// เก็บแอสเดรส ////////////////////////////////////
  const splitAddress = (value) => {
    let updatedCount = { ...count }
    //console.log("โหลด",value.address)
    let part = value.address.split(" ")
    if (part[1].includes("หมู่ที่") === true) {
      //setHouseNumber(part[0] + part[1] + part[2])
      updatedCount.houseNumber = part[0] + part[1] + part[2]
    } else {
      //setHouseNumber(part[0])
      updatedCount.houseNumber = part[0]
    }
    //setMoo(part[2])
    //setDataAddress({...dataAddress, moo: part[2]})
    for (let i = 0; i <= part.length; i++) {
      if (part[i]?.includes("ซ.") === true) {
        let s = part[i].split("ซ.")
        //setSoi(s[1])
        updatedCount.soi = s[1]
      }
      if (part[i]?.includes("ถ.") === true) {
        let tanon = part[i].split("ถ.")
        //setRoad(tanon[1])
        updatedCount.road = tanon[1]
      }
      if (part[i]?.includes("ต.") === true) {
        let bn = part[i].split("ต.")
        //setSubdistrict(bn[1])
        updatedCount.subdistrict = bn[1]
      }
      if (part[i]?.includes("อ.") === true) {
        let oo = part[i].split("อ.")
        let oop = oo[1].split(",")
        if (oo[1]?.includes(",") === false) {
          //setDistrict(oo[1])
          updatedCount.district = oo[1]
        }
        if (oop[1]?.includes("จ.") === true) {
          //setDistrict(oop[0])
          updatedCount.district = oop[0]
          let oops = oop[1].split("จ.")
          //setProvince(oops[1])
          updatedCount.province = oops[1]
        }
      }
      if (part[i]?.includes("จ.") === true) {
        let jh = part[i].split("จ.")
        //setProvince(jh[1])
        updatedCount.province = jh[1]
      }
    }

    setDataAddress({
      ...dataAddress,
      customerId: 0,
      addressId: 0,
      houseNumber: updatedCount.houseNumber,
      houseRegistrationNumber: updatedCount.houseRegistrationNumber,
      village: updatedCount.village,
      zipCode: updatedCount.zipCode,
      soi: updatedCount.soi,
      road: updatedCount.road,
      subdistrict: updatedCount.subdistrict,
      district: updatedCount.district,
      province: updatedCount.province,
      addressStatus: true,
      addressOnIdcard: true,
      workAddress: false,
      otherAddress: false,
      currentAddress: false,
      sendDocuments: false,
      houseRegistration: false,
    })
  }

  ////////////////// Phone /////////////////////////////////
  const handlePhone = (e) => {
    setDataPhone({ ...dataPhone, telp: e.target.value, phoneType: "เบอร์ติดต่อ", sendSms: "1" })
  }
  /////////////////////////////////////////////////////////
  const handleGoHome = () => {
    // dispatch(addCustomer())
    // dispatch(addAddress())
    // dispatch(addPhone())
    // dispatch(addGuarantor())
    // dispatch(addCareerIncome());
    // dispatch(addCar())
    // dispatch(addLoan())
    // dispatch(addOldLoan())
    // dispatch(addImage())
    // dispatch(addLand())
    // dispatch(addLoanLand())
    // dispatch(addOccupation())
    navigate('/offercase')
  }




  return (
    <Row justify={'center'}>
      <Card style={{ width: '1100px' }}>

        <Col style={{ textAlign: 'center' }} >
          <b><h2>ข้อมูลส่วนตัว</h2></b>
        </Col>
        <Spin spinning={loading} size='large' tip=" Loading... ">
          <Row justify={'center'}>
            <Form
              name="basic"
              labelCol={{
                span: 12,
              }}
              // wrapperCol={{
              //   span: 16,
              // }}
              style={{
                width: '100%',
              }}
              initialValues={{
                remember: true,
              }}
              form={form}
              onFinish={handleSubmit}
              autoComplete="off"
            >
              {
                dataAddCustomer?.productTypeId === 1 || dataAddCustomer?.productTypeId === 2 ?
                  <>
                    <Divider style={{ margin: 5 }} />
                    {/* <Card> */}
                    <Row justify={'center'} gutter={32} style={{ margin: '5px' }} >
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <Row className='main2' justify={'center'}>
                          <aside style={{ width: '70%' }}>
                            <Row justify={'center'} >
                              <Form.Item label='ประเภท' name='nationalId'
                                style={{ textAlign: 'left', width: '500px', marginLeft: '10px' }}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please input !',
                                  },]}>
                                <Select
                                  placeholder={'เลือก'}
                                  style={{ width: '250px', height: '40px' }}
                                  // defaultValue={'บุคคลธรรมดา'}
                                  name='nationalId'
                                  onChange={(value) => handleProduct(value)}
                                  options={[
                                    {
                                      label: 'บุคคลธรรมดา',
                                      value: 'บุคคลธรรมดา',
                                    },
                                    {
                                      label: 'นิติบุคคล',
                                      value: 'นิติบุคคล',
                                    },
                                  ]}
                                />
                              </Form.Item>
                            </Row>
                          </aside>
                        </Row>
                        {holds === "นิติบุคคล" ?
                          <Row className='main2' justify={'center'}>
                            <aside style={{ width: '70%' }}>
                              <div>
                                <Form.Item label='ประเภทห้าง' name='snam'
                                  style={{ textAlign: 'left', width: '300px' }}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please input !',
                                    },]}>
                                  <Select
                                    placeholder={'เลือก'}
                                    style={{ width: '250px', height: '40px', }}
                                    name='snam'
                                    initialvalues={1}
                                    onChange={(value) => handletypeCompany(value)}
                                    options={[
                                      {
                                        label: 'หจก.',
                                        value: 'หจก',
                                      },
                                      {
                                        label: 'บริษัท',
                                        value: 'บริษัท',
                                      },
                                    ]}
                                  />


                                </Form.Item>
                              </div>
                              <div>
                                <Form.Item
                                  label='เลขประจำตัวผู้เสียภาษี'
                                  // name='taxID'
                                  name='identificationId'
                                  style={{ textAlign: 'left', width: '300px' }}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please input !',
                                    },]}>

                                  <Input type='text'
                                    //maxLength={13}
                                    name='identificationId'
                                    disabled={CheckIdCard ? false : true}
                                    style={{ width: '250px', marginRight: '5px', marginLeft: '5px', color: 'black' }}
                                    //  onChange={(e) => setDataPost({ ...dataPost, taxID: e.target.value }, isThaiNationalID(e.target.value))}></Input>
                                    onChange={(e) => inputTaxId(e)}></Input>
                                </Form.Item>
                              </div>
                              <div>
                                {number === 1 ?
                                  <span
                                    style={{ display: 'inline-block' }}
                                  >
                                    <Form.Item>
                                      <Button type="primary" onClick={(e) => { handleIdCard(e) }}>ตรวจสอบเลขประจำตัวผู้เสียภาษี</Button>
                                    </Form.Item>
                                  </span> : null}
                              </div>

                              <div>
                                <Form.Item label='ชื่อบริษัท' name='firstname'
                                  style={{ textAlign: 'left', width: '300px' }}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please input !',
                                    },]}
                                >
                                  <Input type='text'
                                    style={{ width: '250px', color: 'black' }}
                                    onChange={(e) => setDataPost({ ...dataPost, firstname: e.target.value })}>

                                  </Input>

                                </Form.Item>
                              </div>
                              <div>
                                <Form.Item label='' name='lastname'
                                  style={{ textAlign: 'left', width: '300px' }}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please input !',
                                    },]}
                                >
                                  <Select
                                    placeholder={'เลือก'}
                                    style={{ textAlign: 'left', height: '40px', width: '250px' }}
                                    name='lastname'
                                    initialvalues={'จำกัด'}
                                    onChange={(value) => handletypeCompanyed(value)}
                                    options={[
                                      {
                                        label: 'มหาชน',
                                        value: 'มหาชน',
                                      },
                                      {
                                        label: 'จำกัด',
                                        value: 'จำกัด',
                                      },
                                    ]}
                                  />
                                </Form.Item>
                              </div>
                              <div>
                                <Form.Item label='วันจดทะเบียน' name='birthdate'
                                  style={{ textAlign: 'left', width: '300px' }}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please input !',
                                    },]}>
                                  <DatePicker format={'YYYY-MM-DD'}
                                    style={{ height: '40px', width: '250px', color: 'black' }}
                                    onChange={(e) => {
                                      if (e) {
                                        setDataPost({ ...dataPost, birthdate: e.format('YYYY-MM-DD') })
                                      }
                                    }} />
                                </Form.Item>
                              </div>
                              <div>
                                <Form.Item label='เบอร์โทรติดต่อ' name='telp'
                                  style={{ textAlign: 'left', width: '300px' }}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please input !',
                                    },]}>
                                  <Input type='text'
                                    style={{ width: '250px', color: 'black' }}
                                    //onChange={(e) => setDataPhone({ ...dataPhone, telp: e.target.value })}></Input>
                                    onChange={(e) => { handlePhone(e) }}></Input>
                                </Form.Item>
                              </div>
                            </aside>
                          </Row>
                          :
                          <Row className='main2' justify={'center'} >
                            <aside style={{ width: '75%' }}>

                              <div>
                                <Form.Item
                                  label="คำนำหน้าชื่อ"
                                  name="snam"
                                  style={{ textAlign: 'center', width: '300px' }}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please input !',
                                    },]}>
                                  <Radio.Group onChange={(e) => setDataPost({ ...dataPost, snam: e.target.value })} style={{ width: '250px' }}>
                                    <Radio value="นาย" > นาย </Radio>
                                    <Radio value="นาง"> นาง </Radio>
                                    <Radio value="นางสาว"> นางสาว </Radio>
                                  </Radio.Group>
                                </Form.Item>
                              </div>
                              <div style={{ textAlign: 'center' }}>
                                <Form.Item
                                  label='เลขประจำตัวประชาชน'
                                  name='identificationId'
                                  style={{ textAlign: 'center', width: '300px' }}
                                  // style={{ display: 'inline-block' }}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please input !',
                                    },]}>

                                  <Input type='text' maxLength={13}
                                    name='identificationId'
                                    disabled={CheckIdCard ? false : true}

                                    style={{ width: '250px', marginRight: '5px', marginLeft: '5px', color: 'black' }}
                                    //  onChange={(e) => setDataPost({ ...dataPost, identificationId: e.target.value }, isThaiNationalID(e.target.value))}></Input>
                                    onChange={(e) => inputIdCard(e)}></Input>
                                </Form.Item>
                                {number === 1 ?
                                  <span
                                    style={{ display: 'inline-block' }}
                                  >
                                    <Form.Item
                                      label='' name=''
                                      style={{ textAlign: 'left', width: '300px' }}
                                    >
                                      <Button type="primary" onClick={(e) => { handleIdCard(e) }}>ตรวจสอบเลขประจำตัวประชาชน</Button>
                                    </Form.Item>
                                  </span>
                                  : null}
                              </div>

                              {/* {screenWidth > 1170 ?
                          <>
                            <div>
                              <Form.Item
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please input !',
                                  },]}
                              >
                              </Form.Item>
                            </div>


                          </>
                          : null
                        } */}

                              <div>
                                <Form.Item label='ชื่อ' name='firstname'
                                  style={{ textAlign: 'center', width: '300px' }}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please input !',
                                    },]}
                                >
                                  <Input type='text'
                                    style={{ width: '250px', color: 'black' }}
                                    onChange={(e) => setDataPost({ ...dataPost, firstname: e.target.value })}></Input>
                                </Form.Item>
                              </div>


                              <div>
                                <Form.Item label='นามสกุล' name='lastname'
                                  style={{ textAlign: 'center', width: '300px' }}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please input !',
                                    },]}>
                                  <Input type='text'
                                    style={{ width: '250px', color: 'black' }}
                                    onChange={(e) => setDataPost({ ...dataPost, lastname: e.target.value })}></Input>
                                </Form.Item>
                              </div>

                              <div>
                                <Form.Item label='ชื่อเล่น' name='nickname'
                                  style={{ textAlign: 'center', width: '300px' }}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please input !',
                                    },]}
                                >
                                  <Input type='text'
                                    style={{ width: '250px', color: 'black' }}
                                    onChange={(e) => setDataPost({ ...dataPost, nickname: e.target.value })}></Input>
                                </Form.Item>
                              </div>

                              <div>
                                <Form.Item label='เพศ' name='gender'
                                  style={{ textAlign: 'center', width: '300px' }}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please input !',
                                    },]}>
                                  <Select
                                    placeholder={'เลือก'}
                                    style={{ width: '250px', color: 'black' }}
                                    onChange={(value) => setDataPost({ ...dataPost, gender: value })}
                                    options={[
                                      {
                                        label: 'ชาย',
                                        value: 'M',
                                      },
                                      {
                                        label: 'หญิง',
                                        value: 'F',
                                      },
                                    ]}
                                  />
                                </Form.Item>
                              </div>

                              <div>
                                <Form.Item label='เกิดวันที่' name='birthdate'
                                  style={{ textAlign: 'center', width: '300px' }}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please input !',
                                    },]}>

                                  <DatePicker format={'YYYY-MM-DD'}
                                    style={{ height: '40px', width: '250px', color: 'black' }}
                                    onChange={(e) => {
                                      if (e) {
                                        setDataPost({ ...dataPost, birthdate: e.format('YYYY-MM-DD') })
                                      }
                                    }} />

                                </Form.Item>
                              </div>
                              <div>
                                <Form.Item label='เบอร์โทรติดต่อ' name='telp'
                                  style={{ textAlign: 'center', width: '300px' }}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please input !',
                                    },]}>
                                  <Input type='text'
                                    style={{ width: '250px', color: 'black' }}
                                    //onChange={(e) => setDataPhone({ ...dataPhone, telp: e.target.value })}></Input>
                                    onChange={(e) => { handlePhone(e) }}></Input>
                                </Form.Item>
                              </div>

                              <div>
                                <Form.Item label='เจ้าพนักงานออกบัตร' name='issuby'
                                  style={{ textAlign: 'center', width: '300px' }}
                                // rules={[
                                //   {
                                //     required: true,
                                //     message: 'Please input !',
                                //   },]}
                                >
                                  <Input type='text'
                                    style={{ width: '250px', color: 'black' }}
                                    onChange={(e) => setDataPost({ ...dataPost, issuby: e.target.value })}></Input>
                                </Form.Item>
                              </div>

                              <div>
                                <Form.Item label='วันออกบัตร' name='issudt'
                                  style={{ textAlign: 'center', width: '300px' }}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please input !',
                                    },]}>
                                  <DatePicker format={'YYYY-MM-DD'}
                                    style={{ height: '40px', width: '250px', color: 'black' }}
                                    onChange={(e) => {
                                      if (e) {
                                        setDataPost({ ...dataPost, issudt: e.format('YYYY-MM-DD') })
                                      }
                                    }} />
                                </Form.Item>
                              </div>
                              <div>
                                <Form.Item label='วันบัตรหมดอายุ' name='expdt'
                                  style={{ textAlign: 'center', width: '300px' }}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please input !',
                                    },]}>
                                  <DatePicker format={'YYYY-MM-DD'}
                                    style={{ height: '40px', width: '250px', color: 'black' }}
                                    onChange={(e) => {
                                      if (e) {
                                        setDataPost({ ...dataPost, expdt: e.format('YYYY-MM-DD') })
                                      }
                                    }} />
                                </Form.Item>
                              </div>
                            </aside>
                          </Row>
                        }

                      </Col>
                    </Row>
                  </>
                  :
                  null
              }

              <Divider style={{ margin: 5 }} />
              <Row justify={'center'} >
                <Space>
                  {/* <Link to="/"> */}
                  <Button type="text" style={{ background: "#e0e0e0" }} onClick={(e) => { handleGoHome(e) }}>
                    ย้อนกลับ
                  </Button>
                  {/* </Link> */}
                  {
                    dataAddCustomer?.productTypeId === 1 || dataAddCustomer?.productTypeId === 2 ?
                      <Button type="primary" htmlType="submit"  >
                        ต่อไป
                      </Button>
                      :
                      null
                  }
                </Space>
              </Row>
            </Form>
          </Row>
        </Spin>
      </Card>
    </Row>
  )
}
