import React, { useState, useEffect } from 'react'
import { Modal, Form, Button, Divider, Row, Col, Space, notification, Select, Spin } from 'antd'
import axios from 'axios';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { checksynccar, confirmcarcontno, confirmlandcontno, findcarcd, findlandcd, newmix } from '../../file_mid/all_api';

function ModalAddContractNumber({ open, close, dataModalCars, dataModalLand, closeall, dataFromTable }) {
    const [loading, setLoading] = useState(false);
    const { confirm } = Modal;
    const [api, contextHolder] = notification.useNotification();
    const guarantor = useSelector(state => state.guarantor)
    const [data, setData] = useState();
    // const [duplicateData, setDuplicateData] = useState();
    const [checkSubmit, setCheckSubmit] = useState(false);
    const [syncData, setSyncData] = useState();
    const [disSyncBTN, setdisSyncBTN] = useState(false);
    const [carChassisCUT, setCarChassisCUT] = useState();
    const [carEngineCUT, setCarEngineCUT] = useState();
    const [dis1] = useState(true);
    const [dis2] = useState(true);
    const [dis3, setDis3] = useState(true);
    const [dis8] = useState(true);
    const [disSubmit, setDisSubmit] = useState(false);
    const [check1238, setCheck1238] = useState();

    const user = localStorage.getItem('username');

    useEffect(() => {
        if (guarantor.data.typeLoan === "car") {
            setData({ carContno: null })
            loadDataCar({ carId: dataFromTable.carId, customerId: dataFromTable.customerId })
        } else {
            setData({ landContno: null })
            loadDataLand({ carId: dataFromTable.carId, customerId: dataFromTable.customerId })
        }
    }, [])

    useEffect(() => {
        if (dataFromTable.car === "car") {
            if (syncData?.cars?.carOldLoanDetails?.carId === 0) {
                delete syncData?.cars?.carOldLoanDetails
            }
            // var carChassis = "123456789123456789123456789";
            var carChassis = syncData?.cars?.carChassisNumber;
            var carEngine = syncData?.cars?.carEngineNumber;
            var carChassisCUT = carChassis?.slice(0, 19);
            var carEngineCUT = carEngine?.slice(0, 19);
            setCarChassisCUT(carChassisCUT)
            setCarEngineCUT(carEngineCUT)

            delete syncData?.cars?.img
            delete syncData?.cars?.carLoanDetails?.notes
            delete syncData?.cars?.carLoanDetails?.checkbox
        } else {
            if (syncData?.lands?.landOldLoanDetails?.landId === 0) {
                delete syncData?.lands?.landrOldLoanDetails
            }
            delete syncData?.lands?.img
            delete syncData?.lands?.landLoanDetails?.notes
            delete syncData?.lands?.landLoanDetails?.checkbox
            // ยังไม่ได้ตัดเลขที่ดินนะ
        }
        // console.log("syncData", syncData)
    }, [syncData])

    const loadDataCar = async (value) => {
        setLoading(true)
        await axios.post(findcarcd, value)
            .then(res => {
                if (res.status === 200) {
                    setSyncData(res.data)
                    setLoading(false)
                }
            }).catch((err) => {
                console.log("err", err)
            })
    }
    const loadDataLand = async (value) => {
        setLoading(true)
        await axios.post(findlandcd, value)
            .then(res => {
                if (res.status === 200) {
                    setSyncData(res.data)
                    setLoading(false)
                }
            }).catch((err) => {
                console.log("err", err)
            })
    }

    const handleCancel = () => {
        close(false);
    };

    const handleSelectSync = (e) => {
        setCheck1238(e)
    };

    const showConfirmOK = () => {
        confirm({
            title: 'ส่งเอกสารไปยังผู้ที่เกี่ยวข้อง...?',
            onOk() {
                customerConfirm()
            },
            onCancel() {
            },
        });
    };

    const successAddContno = (placement) => {
        api.success({
            message: <b>บันทึกสำเร็จ</b>,
            description:
                'ส่งข้อมูลไปยังผู้เกี่ยวข้องแล้ว',
            placement,
        });
    }
    const successSync = (placement) => {
        api.success({
            message: <b>Sync Success</b>,
            //   description:
            //     'ไม่สามารถเพิ่มข้อมูลได้ เนื่องจากเลขสัญญาซ้ำกับที่มีอยู่แล้ว',
            placement,
        });
    }
    const errorSync = (placement) => {
        api.error({
            message: <b>Sync Error</b>,
            description:
                'เชื่อมข้อมูลไม่สำเร็จ กรุณาติดต่อ IT',
            placement,
        });
    }

    const errorSyncDup = (placement) => {
        api.error({
            message: <b>Sync Error</b>,
            description:
                'พบประวัติการเชื่อมต่อข้อมูลแล้ว ไม่สามารถซิงค์ได้ กรุณาติดต่อ IT',
            placement,
        });
    }

    const errorLoadData = (placement) => {
        api.error({
            message: <b>โหลดข้อมูลไม่สำเร็จ</b>,
            description:
                'กรุณา "ปิด" หน้าส่งเอกสารไปตรวจสอบ แล้วเข้าใหม่',
            placement,
        });
    }

    const errorAddContno = (placement) => {
        api.error({
            message: <b>ไม่สามารถเพิ่มข้อมูลได้</b>,
            description:
                'ไม่สามารถเพิ่มข้อมูลได้ เนื่องจากเลขสัญญาซ้ำกับที่มีอยู่แล้ว',
            placement,
        });
    }

    const customerConfirm = async () => {
        setLoading(true)
        if (guarantor.data.typeLoan === "car") {
            var value = { ...data, carInputContno: dayjs(), loanId: dataModalCars?.carLoanDetails?.loanId, approvalStatus: "รอตรวจสอบเอกสาร" }
            // console.log("value car", value)
            await axios.put(confirmcarcontno, value)
                .then(res => {
                    console.log(res.status)
                    if (res.status === 201) {
                        setSyncData({ ...syncData, cars: { ...syncData?.cars, carChassisNumber: carChassisCUT, carEngineNumber: carEngineCUT, carContno: data?.carContno } })
                        successAddContno("top")
                        if (dataModalCars?.carLoanDetails?.productType === 'p-loan') {
                            setCheckSubmit(true)
                            setDis3(false)
                            setDisSubmit(true)
                            setLoading(false)
                            // } else if (dataModalCars?.carLoanDetails?.productType === 'เช่าซื้อ' || dataModalCars?.carLoanDetails?.productType === 'เช่าซื้อ1') {
                            // setCheckSubmit(true)
                            //     setDis2(false)
                            //     setDis8(false)
                        } else {
                            setTimeout(() => {
                                closeall(false);
                            }, 2000)
                        }
                    } else if (res.status === 200) {
                        // setDuplicateData(res.data)
                        errorAddContno("top")
                        setLoading(false)
                    }
                }).catch((err) => {
                    console.log("err", err)
                })
        } else {
            var value = { ...data, landInputContno: dayjs(), landLoanId: dataModalLand?.landLoanDetails?.landLoanId, approvalStatus: "รอตรวจสอบเอกสาร" }
            await axios.put(confirmlandcontno, value)
                .then(res => {
                    // console.log(res.status)
                    if (res.status === 201) {
                        successAddContno("top")
                        // setCheckSubmit(true)
                        // setDis1(false)
                        // setLoading(false)
                        setTimeout(() => {
                            closeall(false);
                        }, 2000)
                    } else if (res.status === 200) {
                        // setDuplicateData(res.data)
                        errorAddContno("top")
                        setLoading(false)
                    }
                }).catch((err) => {
                    console.log("err", err)
                })
        }
    };

    const CheckSyncData = async () => {
        setdisSyncBTN(true)
        await axios.post(checksynccar, { loanId: dataModalCars?.carLoanDetails?.loanId, apSyncBy: user, apSyncDate: dayjs() })
            .then(res => {
                if (res.status === 200) {
                    errorSyncDup("top")
                    console.log("เคยแล้ว")
                } else if (201) {
                    if (check1238 === 1) {
                        handleSync1()
                    } else if (check1238 === 2) {
                        handleSync2()
                    } else if (check1238 === 3) {
                        handleSync3()
                    } else if (check1238 === 8) {
                        handleSync8()
                    }
                }
            }).catch((err) => {
                console.log("err", err)
            })
    }

    const CheckLoadData = async () => { // ตรวจสอบว่ามีข้อมูลใน ap หรือยังจะได้เอาไป Sync
        if (guarantor.data.typeLoan === "car") {
            // car
            if (syncData.cars.apCarLoanDetails.loanId !== 0) {
                showConfirmOK()
            } else {
                errorLoadData("top")
            }
        } else {
            // land
            if (syncData.lands.apLandLoanDetails.loanId !== 0) {
                showConfirmOK()
            } else {
                errorLoadData("top")
            }
        }
    }

    const handleSync1 = async () => {

    }

    const handleSync2 = async () => {

    }

    const handleSync3 = async () => {
        await axios.post(newmix, syncData)
            .then(res => {
                if (res.status === 200) {
                    // console.log("sync แล้ว")
                    successSync("top")
                    setTimeout(() => {
                        closeall(false);
                    }, 2000)
                } else if (res.status === 201) {
                    errorSync("top")
                    setTimeout(() => {
                        closeall(false);
                    }, 3000)
                }
            }).catch((err) => {
                console.log("err", err)
            })
    }

    const handleSync8 = async () => {

    }

    return (
        <div>
            <Modal title="ส่งเอกสารไปตรวจสอบ" open={open} Cancel={handleCancel} width={500} footer={[null]}>
                <Spin spinning={loading} tip="Loading...">
                    <Form
                        name="basic"
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 12,
                        }}
                        onFinish={CheckSyncData}>
                        <Row>
                            <Col span={24}>
                                {
                                    checkSubmit ?
                                        <>
                                            <Form.Item label="เชื่อมข้อมูลลงบัญชี" name="landContno" style={{ marginBottom: 0 }}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'กรุณาเลือกการเชื่อมข้อมูล',
                                                    },
                                                ]}>
                                                <Select
                                                    onChange={(e) => handleSelectSync(e)}
                                                    options={[
                                                        {
                                                            value: 1,
                                                            label: 'บัญชี 1',
                                                            disabled: dis1,
                                                        },
                                                        {
                                                            value: 2,
                                                            label: 'บัญชี 2',
                                                            disabled: dis2,
                                                        },
                                                        {
                                                            value: 3,
                                                            label: 'บัญชี 3',
                                                            disabled: dis3,
                                                        },
                                                        {
                                                            value: 8,
                                                            label: 'บัญชี 8',
                                                            disabled: dis8,
                                                        },
                                                    ]}></Select>
                                            </Form.Item>
                                        </>
                                        :
                                        null
                                }
                                <Divider />
                                {/* {
                                duplicateData !== undefined ?
                                    <>
                                        <Card>
                                            {
                                                duplicateData?.carId !== undefined ?
                                                    <>
                                                        <Row justify={'center'}><Col span={24} style={{ color: "red" }}><b><u>พบเลขสัญญาซ้ำ(รถ)</u></b></Col></Row>
                                                        <Divider />
                                                        <Row>
                                                            <Col span={24}>
                                                                <div>
                                                                    <span>หมายเลขสัญญา : </span>{duplicateData?.carContno}
                                                                </div>
                                                                <div>
                                                                    <span>หมายเลขทะเบียน : </span>{duplicateData?.carPlateNumber}
                                                                </div>
                                                                <div>
                                                                    <span>จังหวัด : </span>{duplicateData?.carProvince}
                                                                </div>
                                                                <div>
                                                                    <span>วันที่บันทึกสัญญา : </span>{dayjs(duplicateData?.carInputContno).format("DD/MM/YYYY")}
                                                                </div>
                                                                <div>
                                                                    <span>เจ้าของเคส(ธุรการ) : </span>{duplicateData?.carLoanDetails?.reviewedBy}
                                                                </div>
                                                                <div>
                                                                    <span>เจ้าของเคส(การตลาด) : </span>{duplicateData?.carLoanDetails?.proposalBy}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                    :
                                                    <>
                                                        <Row justify={'left'}><Col span={24} style={{ color: "red" }}><b><u>พบเลขสัญญาซ้ำ(ที่ดิน)</u></b></Col></Row>
                                                        <Divider />
                                                        <Row>
                                                            <Col>
                                                                <div>
                                                                    <span>หมายเลขสัญญา : </span>{duplicateData?.landContno}
                                                                </div>
                                                                <div>
                                                                    <span>หมายเลขทะเบียน : </span>{duplicateData?.landPlateNumber}
                                                                </div>
                                                                <div>
                                                                    <span>จังหวัด : </span>{duplicateData?.landProvince}
                                                                </div>
                                                                <div>
                                                                    <span>วันที่บันทึกสัญญา : </span>{dayjs(duplicateData?.landInputContno).format("DD/MM/YYYY")}
                                                                </div>
                                                                <div>
                                                                    <span>เจ้าของเคส(ธุรการ) : </span>{duplicateData?.landLoanDetails?.reviewedBy}
                                                                </div>
                                                                <div>
                                                                    <span>เจ้าของเคส(การตลาด) : </span>{duplicateData?.landLoanDetails?.proposalBy}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </>
                                            }
                                        </Card>
                                        <Divider />
                                    </>
                                    :
                                    null
                            } */}
                                <Col style={{ textAlign: 'center' }}>
                                    <Space>
                                        {
                                            guarantor?.data?.typeLoan === "car" ?
                                                <>
                                                    {
                                                        checkSubmit ?
                                                            <>
                                                                {
                                                                    dataModalCars?.carLoanDetails?.productType === 'p-loan' ?
                                                                        <Button type='primary' htmlType='submit' disabled={disSyncBTN} style={{ backgroundColor: "green" }}>syncData</Button> : null
                                                                }
                                                            </>
                                                            : null

                                                    }
                                                </>
                                                : null
                                        }
                                        <Button type='primary' onClick={CheckLoadData} disabled={disSubmit} style={{ backgroundColor: "green" }}>ยืนยัน</Button>
                                        <Button type='primary' danger onClick={handleCancel}>ปิด</Button>
                                    </Space>
                                </Col>
                            </Col>
                        </Row>
                    </Form>
                    {contextHolder}
                </Spin>
            </Modal>
        </div >
    )
}

export default ModalAddContractNumber