import { Modal, Form, Button, Row, Spin, Input, Divider, Col, Space, Upload, Card, message, Radio, Select, DatePicker, notification, Image } from 'antd'
import React, { useState, useEffect } from 'react'
import Compressor from 'compressorjs';
import { PlusOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import axios from 'axios';
import dayjs from 'dayjs';
import { checkCCAP, checkNewCCAP, showrelationship, testupload, uploadImgGuaTable } from '../../../file_mid/all_api';

function AddCustomerGua({ close, indexedit, dataCustomer, setDataCustomer, next, setDataAddress, setDataPhone, getData, oData }) {
    var countGu = 0
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm()
    const [form1] = Form.useForm()
    const guarantor = useSelector(state => state.guarantor)
    const { confirm } = Modal
    const [api, contextHolder] = notification.useNotification();
    const [guarantors, setGuarantors] = useState();
    const [images, setImage] = useState([]);
    const [images1, setImage1] = useState();
    const [count, setCount] = useState()

    const [dataPostGuarantor, setDataPostGuarantor] = useState([]) // customerGuarantor

    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');

    const [fileListGuarantor, setFileListGuarantor] = useState([]);
    const [fileListGuarantor1, setFileListGuarantor1] = useState([]);

    const [fileListGuarantors, setFileListGuarantors] = useState([]);
    const [fileListGuarantors1, setFileListGuarantors1] = useState([]);

    const [oldImgs, setOldImgs] = useState([]);
    const [value, setValue] = useState([])
    // const [CheckIdCard, setCheckIdCard] = useState(false)

    useEffect(() => {
        console.log("oData", oData)
        loadRelationship()
        if (dataCustomer) {
            setGuarantors(dataCustomer)
            setOldImgs(dataCustomer[0].imgs)
            form.setFieldsValue({
                ...dataCustomer[0],
                birthdate: dataCustomer[0]?.birthdate ? dayjs(dataCustomer[0].birthdate) : '',
                issudt: dataCustomer[0]?.issudt ? dayjs(dataCustomer[0].issudt) : '',
                expdt: dataCustomer[0]?.expdt ? dayjs(dataCustomer[0].expdt) : '',
            })
        } else {
            if (images1?.length === 0) {
                form.setFieldsValue({
                    ...images1[0],
                    ...images1[0]?.phones[0],
                    birthdate: images1[0]?.birthdate ? dayjs(images1[0].birthdate) : '',
                    expdt: images1[0]?.expdt ? dayjs(images1[0].expdt) : '',
                    issudt: images1[0]?.issudt ? dayjs(images1[0].issudt) : '',
                })
            } else if (images1?.length >= 1) {
                form.setFieldsValue({
                    ...images1[0],
                    ...images1[0]?.phones,
                    birthdate: images1[0]?.birthdate ? dayjs(images1[0].birthdate) : '',
                    expdt: images1[0]?.expdt ? dayjs(images1[0].expdt) : '',
                    issudt: images1[0]?.issudt ? dayjs(images1[0].issudt) : '',
                })
            }
        }
    }, [images1])

    const CheckGuER = (placement) => {
        api.error({
            message: <b>ไม่สามารถเพิ่มข้อมูลได้</b>,
            description: 'พบข้อมูลซ้ำกันระหว่างผู้กู้และผู้ค้ำ กรุณาเปลี่ยนผู้ค้ำ',
            placement,
        });
    }

    const CheckGuERS = (placement) => {
        api.error({
            message: <b>ไม่สามารถเพิ่มข้อมูลได้</b>,
            description: 'พบข้อมูลซ้ำกันระหว่างผู้ค้ำและผู้ค้ำ กรุณาเปลี่ยนผู้ค้ำ',
            placement,
        });
    }

    const ErrorCustomer = (placement) => {
        api.error({
            message: "กรุณาตรวจสอบวันเกิด/วันออกบัตร/วันบัตรหมดอายุของผู้ขอกู้",
            description:
                'กรุณาเพิ่มข้อมูลให้ถูกต้อง',
            placement,
        });
    };

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
    };
    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };
    const handleCancel1 = () => {
        setPreviewVisible(false);
    }

    const handleCancel = () => {
        close(false);
    };

    //////////// โชว์รูปภาพ ////////////////
    function createImageUrl(imageData) {
        return imageData.image64;
    }

    /////////// ดึงข้อมูลจากรูปบัตรประชาชนคนค้ำ /////////////////////////////////////////////////////////////////  
    const handleScanIdCardGuarantor = async (value) => {
        if (guarantors?.length > 0) {
            var dataListGuarantor = [...guarantors]
        } else {
            var dataListGuarantor = []
        }
        for (let i = 0; i < value.length; i++) {
            var imgId = { image: value[i].image64 }
            const headers = { 'Content-Type': 'application/json' }
            await axios.post(testupload, imgId, { headers: headers })
                .then(async (res) => {
                    if (res.data) {
                        if (oData !== undefined) {
                            oData?.map((e) => {
                                if (e.identificationId === res.data[0]?.identificationId) {
                                    countGu = 1
                                    setFileListGuarantor()
                                    setFileListGuarantor1()
                                    console.log("บัตรคนค้ำซ้ำกัน")
                                    CheckGuERS('top')
                                }
                            })
                        }
                        if (countGu === 0) {
                            const b = await loadDataOneGuarantor(res.data[0])
                            dataListGuarantor.push({ ...b[0], imgs: value[i] })
                            // console.log("b", b)
                        }
                    }
                    else {
                        message.info('ไม่สามารถอ่านได้')
                    }
                })
                .catch((err) => console.log(err))
        }
        if (countGu === 0) {
            setGuarantors({ ...guarantors, dataListGuarantor })
            showConfirm({ dataListGuarantor })
        }
    }

    //////////////// เก็บแอสเดรส ////////////////////////////////////
    const splitAddress = async (value) => {
        var dataA = []
        let updatedCount = { ...count }
        let part = value.address.split(" ")
        if (part[1].includes("หมู่ที่") === true) {
            updatedCount.houseNumber = part[0] + part[1] + part[2]
        } else {
            updatedCount.houseNumber = part[0]
        }
        for (let i = 0; i <= part.length; i++) {
            if (part[i]?.includes("ซ.") === true) {
                let s = part[i].split("ซ.")
                updatedCount.soi = s[1]
            }
            if (part[i]?.includes("ถ.") === true) {
                let tanon = part[i].split("ถ.")
                updatedCount.road = tanon[1]
            }
            if (part[i]?.includes("ต.") === true) {
                let bn = part[i].split("ต.")
                updatedCount.subdistrict = bn[1]
            }
            if (part[i]?.includes("อ.") === true) {
                let oo = part[i].split("อ.")
                let oop = oo[1].split(",")
                if (oo[1]?.includes(",") === false) {
                    updatedCount.district = oo[1]
                }
                if (oop[1]?.includes("จ.") === true) {
                    updatedCount.district = oop[0]
                    let oops = oop[1].split("จ.")
                    updatedCount.province = oops[1]
                }
            }
            if (part[i]?.includes("จ.") === true) {
                let jh = part[i].split("จ.")
                updatedCount.province = jh[1]
            }
        }
        dataA.push({
            customerId: 0,
            addressId: 0,
            houseNumber: updatedCount.houseNumber,
            houseRegistrationNumber: updatedCount.houseRegistrationNumber,
            village: updatedCount.village,
            zipCode: updatedCount.zipCode,
            soi: updatedCount.soi,
            road: updatedCount.road,
            subdistrict: updatedCount.subdistrict,
            district: updatedCount.district,
            province: updatedCount.province,
            addressStatus: true,
            addressOnIdcard: true,
            workAddress: false,
            otherAddress: false,
            currentAddress: false,
            sendDocuments: false,
            houseRegistration: false,
        })
        return dataA
    }

    ////////////// ข้อมูลยูสในเดต้าเบสคนค้ำ ////////////////////////////////////////////////////////////////
    const loadDataOneGuarantor = async (data) => {
        //setLoading(true)
        var dataListGuarantor = []
        if (!data?.identificationId) {
            // setCheckIdCard(true)
            var unDataGua = {}
            // countGu = 1
            setFileListGuarantor()
            setFileListGuarantor1()
            console.log("ไม่มีเลขบัตรคนค้ำ")
            alert('ไม่สามารถอ่านบัตรคนค้ำประกันได้')
            unDataGua.identificationId = ""
            unDataGua.customerId = 0
            unDataGua.firstname = data?.firstname
            unDataGua.lastname = data?.lastname
            unDataGua.snam = data?.snam
            unDataGua.nickname = ""
            unDataGua.phones = undefined
            unDataGua.gender = data?.gender
            unDataGua.birthdate = data?.birthdate
            unDataGua.expdt = data?.expdt
            unDataGua.issuby = data?.issuby
            unDataGua.checkDup = false
            dataListGuarantor.push(unDataGua)
            setDataPostGuarantor({ ...dataPostGuarantor, ...unDataGua })
            setDataAddress()
            setDataPhone()
            form.setFieldsValue({ nickname: "", telp: "" })
        }
        if (data?.identificationId === getData.identificationId) {
            countGu = 1
            setFileListGuarantor()
            setFileListGuarantor1()
            // console.log("ไม่มีเลขบัตรคนค้ำ")
            CheckGuER('top')
        }
        // if (data)
        else {
            var one = { identificationId: data.identificationId }
            //setLoading(true)
            await axios.post(checkCCAP, one)
                .then(async (res) => {
                    if (res.data) {
                        //alert('มีข้อมูลในฐานข้อมูล')
                        message.info('คนค้ำมีข้อมูลในฐานข้อมูล')
                        // delete res.data.address
                        console.log("มี", res.data)
                        delete res.data.carLoanDetails
                        delete res.data.emails
                        delete res.data.ojs
                        delete res.data.socials
                        dataListGuarantor.push({ ...res.data, checkDup: true })
                        setDataPostGuarantor({ ...dataPostGuarantor, ...data, customerId: 0, address: res.data.address })
                        // setCheckIdCard(false)
                        // setLoading(false)
                    }
                    else {
                        const a = await splitAddress(data)
                        console.log("A", a)
                        console.log("ไม่มี", data)
                        message.info('ไม่พบข้อมูลในฐานข้อมูล')
                        dataListGuarantor.push({ ...data, address: a, checkDup: false })
                        setDataPostGuarantor({ ...dataPostGuarantor, ...data, customerId: 0, address: a })
                        // setCheckIdCard(true)
                        // setLoading(false)
                    }
                })
                .catch((err) => console.log(err))
            // setLoading(false)
        }
        // showConfirm({ dataListGuarantor })
        return dataListGuarantor
    }

    const showConfirm = ({ dataListGuarantor }) => {
        var note = dataListGuarantor?.length;
        var lastIndex = note - 1;
        var imageGuarantors1 = [...dataListGuarantor];
        console.log("imageGuarantors1BeforeMAP", imageGuarantors1)
        setImage1(imageGuarantors1)
        guarantors?.map((img, index) => {
            imageGuarantors1.push({ ...img.imgs })
            setImage(imageGuarantors1)
        })

        setLoading(true)
        confirm({
            title: 'เพิ่มคนค้ำ?',
            content: dataListGuarantor[lastIndex]?.snam + ' ' + dataListGuarantor[lastIndex]?.firstname + ' ' + dataListGuarantor[lastIndex]?.lastname,
            onOk() {
                console.log("dataListGuarantor", dataListGuarantor)
                setGuarantors(dataListGuarantor)
                setDataAddress(dataListGuarantor[0]?.address)
                if (dataListGuarantor[0]?.phones) {
                    setDataPhone(dataListGuarantor[0]?.phones)
                }
                setLoading(false)
                setTimeout(() => {
                    setFileListGuarantor([])
                },)
            },
            onCancel() {
                message.error('ยกเลิก')
                setGuarantors(guarantors)
                setFileListGuarantor1({})
                setTimeout(() => {
                    setFileListGuarantor([])
                },)
                setLoading(false)
            },
        });
    }

    //////////////////// อ่านบัตรคนค้ำ /////////////////////////
    const handleChangeGuarantor1 = async ({ fileList }) => {
        setLoading(true)
        console.log("fileList", fileList)
        setFileListGuarantor(fileList);
        var imageListGuarantor = []
        var i = 0
        for (const file of fileList) {
            const compressedFile = await new Promise((resolve) => {
                new Compressor(file.originFileObj, {
                    quality: 0.2, // เช่น, 60%
                    success: (result) => {
                        resolve(result);
                    },
                });
            });
            var image = {}
            var filename = file.name
            var image64 = await getBase64(compressedFile)
            // var image64 = file.originFileObj(compressedFile)
            image.filename = filename
            image.image64 = image64
            image.type = `5.${indexedit + 1}`
            imageListGuarantor.push(image)
            i++
        }
        await handleScanIdCardGuarantor(imageListGuarantor)
        setFileListGuarantor1({ ...fileListGuarantor1, imageListGuarantor });
        setLoading(false)
    };

    //////////////////// รูปเอกสาร ///////////////////////
    const handleChangeGuarantors1 = async ({ fileList }) => {
        setFileListGuarantors(fileList);
        setLoading(true)
        var imageListGuarantors = []
        var i = 0
        for (const file of fileList) {
            const compressedFile = await new Promise((resolve) => {
                new Compressor(file.originFileObj, {
                    quality: 0.1, // เช่น, 60%
                    success: (result) => {
                        resolve(result);
                    },
                });
            });
            var image = {}
            var filename = file.name
            var image64 = await getBase64(compressedFile)
            //var image64 = file.originFileObj
            image.filename = filename
            image.image64 = image64
            image.type = `5.${indexedit + 1}`
            imageListGuarantors.push(image)
            i++
        }
        setFileListGuarantors1({ ...fileListGuarantors1, imageListGuarantors });
        setLoading(false)
    };

    const types = async (InputImage) => {
        console.log("InputImage", InputImage)
        setLoading(true)
        await axios.post(uploadImgGuaTable, InputImage)
            .then(response => {
                if (response) {
                    console.log("response", response)
                    setLoading(false)
                    close(false)
                }
            }).catch((err) => {
                console.log("err", err)
                setLoading(false)
            })
    }

    // const DataImagesGu = async ({ data }) => {
    //     console.log("data", data)

    //     let AddData2

    //     if (dataPostCustomer?.typeLoan === "car") {
    //         let path = `one_images/car/${dataPostCustomer?.carId}/5/${indexedit + 1}`
    //         console.log("path1", path)
    //         AddData2 = [{ ...data[0], pathImg: path }];

    //         const InputImage = {
    //             mainImg: { carId: `${dataPostCustomer?.carId}`, carInput: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"), productTypeId: 1, img: data[0].imgs }
    //         }
    //         await types(InputImage)
    //         console.log("InputImage-Car", InputImage)
    //     } else {
    //         let path = `one_images/land/${dataPostCustomer?.carId}/5/${indexedit + 1}`
    //         console.log("path2", path)
    //         AddData2 = [{ ...data[0], pathImg: path }];//เก็บค่า state เดิมไว้ พร้อมส่ง type ไปด้วย เพื่อบอกค่าที่ส่ง
    //         const InputImage = {
    //             mainImg: { carId: `${dataPostCustomer?.carId}`, carInput: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"), productTypeId: 2, img: data[0].imgs }
    //         }
    //         console.log("InputImage-Land", InputImage)
    //         await types(InputImage)
    //     }
    //     // โยนค่าข้าม Component
    //     console.log("AddData2", AddData2)
    //     setLoading(false)
    // }

    const ERsnam = (placement) => {
        api.error({
            message: <b>กรุณาเพิ่มคำนำหน้าชื่อ</b>,
            placement,
        });
    }
    const ERid = (placement) => {
        api.error({
            message: <b>กรุณาเพิ่มเลขประจำตัวประชาชน</b>,
            placement,
        });
    }
    const ERid13 = (placement) => {
        api.error({
            message: <b>กรุณากรอกเลขประจำตัวประชาชนให้ครบ 13 หลัก</b>,
            placement,
        });
    }

    const ERfirstname = (placement) => {
        api.error({
            message: <b>กรุณาเพิ่มชื่อ</b>,
            placement,
        });
    }
    const ERlastname = (placement) => {
        api.error({
            message: <b>กรุณาเพิ่มนามสกุล</b>,
            placement,
        });
    }
    const ERnickname = (placement) => {
        api.error({
            message: <b>กรุณาเพิ่มชื่อเล่น</b>,
            placement,
        });
    }
    const ERgender = (placement) => {
        api.error({
            message: <b>กรุณาเพิ่มเพศ</b>,
            placement,
        });
    }
    const ERbirthdate = (placement) => {
        api.error({
            message: <b>กรุณาเพิ่มวันเกิด</b>,
            placement,
        });
    }
    const ERissudt = (placement) => {
        api.error({
            message: <b>กรุณาเพิ่มวันที่ออกบัตร</b>,
            placement,
        });
    }
    const ERexpdt = (placement) => {
        api.error({
            message: <b>กรุณาเพิ่มวันบัตรหมดอายุ</b>,
            placement,
        });
    }
    const ERdescription = (placement) => {
        api.error({
            message: <b>กรุณาเพิ่มความสัมพันธ์</b>,
            placement,
        });
    }

    const Success = (placement) => {
        api.success({
            message: <b>บันทึกสำเร็จ</b>,
            placement,
        });
    }


    // ส่งค่าไปหน้าหลักของคนค้ำ
    const handleSubmit = () => {
        // console.log('dataCustomer', dataCustomer)
        // console.log('guarantors', guarantors.length)
        if (dataCustomer) {
            if (guarantors) {
                if (!guarantors[0]?.identificationId) {
                    ERid('top')
                } else {
                    if (guarantors[0]?.identificationId.length !== 13) {
                        ERid13('top')
                    }
                }
                if (!guarantors[0]?.snam) {
                    ERsnam('top')
                }
                if (!guarantors[0]?.firstname) {
                    ERfirstname('top')
                }
                if (!guarantors[0]?.lastname) {
                    ERlastname('top')
                }
                if (!guarantors[0]?.nickname) {
                    ERnickname('top')
                }
                if (!guarantors[0]?.gender) {
                    ERgender('top')
                }
                if (!guarantors[0]?.birthdate) {
                    ERbirthdate('top')
                }
                if (!guarantors[0]?.issudt) {
                    ERissudt('top')
                }
                if (!guarantors[0]?.expdt) {
                    ERexpdt('top')
                }
                if (!guarantors[0]?.description) {
                    ERdescription('top')
                }
                if (guarantors[0]?.birthdate === "undefined" || guarantors[0]?.issudt === "undefined" || guarantors[0]?.expdt === "undefined" ||
                    guarantors[0]?.birthdate === "null" || guarantors[0]?.issudt === "null" || guarantors[0]?.expdt === "null" ||
                    guarantors[0]?.birthdate === "Invalid Date" || guarantors[0]?.issudt === "Invalid Date" || guarantors[0]?.expdt === "Invalid Date") {
                    ErrorCustomer("top")
                }
                if (guarantors[0]?.snam &&
                    guarantors[0]?.identificationId &&
                    guarantors[0]?.identificationId.length === 13 &&
                    guarantors[0]?.firstname &&
                    guarantors[0]?.lastname &&
                    guarantors[0]?.nickname &&
                    guarantors[0]?.gender &&
                    guarantors[0]?.birthdate &&
                    guarantors[0]?.issudt &&
                    guarantors[0]?.expdt &&
                    guarantors[0]?.description &&
                    guarantors[0]?.birthdate !== "undefined" && guarantors[0]?.issudt !== "undefined" && guarantors[0]?.expdt !== "undefined" &&
                    guarantors[0]?.birthdate !== "null" && guarantors[0]?.issudt !== "null" && guarantors[0]?.expdt !== "null" &&
                    guarantors[0]?.birthdate !== "Invalid Date" && guarantors[0]?.issudt !== "Invalid Date" && guarantors[0]?.expdt !== "Invalid Date") {

                    const imageListGuarantorPush = []
                    // รูปเอกสาร
                    if (fileListGuarantors1?.imageListGuarantors) {
                        imageListGuarantorPush.push(...fileListGuarantors1?.imageListGuarantors)
                    }

                    // รูปเก่า
                    if (oldImgs) {
                        imageListGuarantorPush.push(...oldImgs)
                    }

                    var data = []
                    if (guarantors?.length === 0) {
                        data.push({ ...guarantors[0] })
                        // console.log("กรณียังไม่มีคนค้ำ")
                    }
                    if (guarantors?.length > 0) {
                        data.push({ ...guarantors[0], imgs: imageListGuarantorPush }) //ต่อ array
                        // console.log("กรณีมีคนค้ำแล้ว",)
                    }
                    if (data) {
                        console.log("ok", data)
                        handleSaveValue(data)
                        // DataImagesGu({ data })
                    }
                }
            }
        } else {
            if (guarantors) {
                if (!guarantors[0]?.identificationId) {
                    ERid('top')
                } else {
                    if (guarantors[0]?.identificationId.length !== 13) {
                        ERid13('top')
                    }
                }
                if (!guarantors[0]?.snam) {
                    ERsnam('top')
                }
                if (!guarantors[0]?.firstname) {
                    ERfirstname('top')
                }
                if (!guarantors[0]?.lastname) {
                    ERlastname('top')
                }
                if (!guarantors[0]?.nickname) {
                    ERnickname('top')
                }
                if (!guarantors[0]?.gender) {
                    ERgender('top')
                }
                if (!guarantors[0]?.birthdate) {
                    ERbirthdate('top')
                }
                if (!guarantors[0]?.issudt) {
                    ERissudt('top')
                }
                if (!guarantors[0]?.expdt) {
                    ERexpdt('top')
                }
                if (!guarantors[0]?.description) {
                    ERdescription('top')
                }
                if (guarantors[0]?.birthdate === "undefined" || guarantors[0]?.issudt === "undefined" || guarantors[0]?.expdt === "undefined" ||
                    guarantors[0]?.birthdate === "null" || guarantors[0]?.issudt === "null" || guarantors[0]?.expdt === "null" ||
                    guarantors[0]?.birthdate === "Invalid Date" || guarantors[0]?.issudt === "Invalid Date" || guarantors[0]?.expdt === "Invalid Date") {
                    ErrorCustomer("top")
                }
                if (guarantors[0]?.snam &&
                    guarantors[0]?.identificationId &&
                    guarantors[0]?.identificationId.length === 13 &&
                    guarantors[0]?.firstname &&
                    guarantors[0]?.lastname &&
                    guarantors[0]?.nickname &&
                    guarantors[0]?.gender &&
                    guarantors[0]?.birthdate &&
                    guarantors[0]?.issudt &&
                    guarantors[0]?.expdt &&
                    guarantors[0]?.description &&
                    guarantors[0]?.birthdate !== "undefined" && guarantors[0]?.issudt !== "undefined" && guarantors[0]?.expdt !== "undefined" &&
                    guarantors[0]?.birthdate !== "null" && guarantors[0]?.issudt !== "null" && guarantors[0]?.expdt !== "null" &&
                    guarantors[0]?.birthdate !== "Invalid Date" && guarantors[0]?.issudt !== "Invalid Date" && guarantors[0]?.expdt !== "Invalid Date") {
                    // console.log("OK")

                    const imageListGuarantorPush = []
                    if (fileListGuarantor1?.imageListGuarantor) {
                        imageListGuarantorPush.push(...fileListGuarantor1?.imageListGuarantor)
                    }
                    if (fileListGuarantors1?.imageListGuarantors) {
                        imageListGuarantorPush.push(...fileListGuarantors1?.imageListGuarantors)
                    }
                    var data = []
                    if (guarantors?.length === 0) {
                        data.push({ ...guarantors[0] })
                        // console.log("กรณียังไม่มีคนค้ำ")
                    }
                    if (guarantors?.length > 0) {
                        data.push({ ...guarantors[0], imgs: imageListGuarantorPush }) //ต่อ array
                        // console.log("กรณีมีคนค้ำแล้ว",)
                    }
                    console.log('guarantors', guarantors)
                    // console.log("data", data)
                    if (data) {
                        console.log("ok", data)
                        handleSaveValue(data)
                        // DataImagesGu({ data })
                    }
                }
            }
        }

    }
    // }

    const handleSaveValue = (value) => {
        // console.log("handleSaveValue", value)
        delete value[0]?.address
        delete value[0]?.phones
        setDataCustomer(value)
        Success('top')
        setTimeout(() => {
            next()
        }, 1500)
    }
    const changeDescription = (value) => {
        setGuarantors([{ ...guarantors[0], description: value }])
    }

    const loadRelationship = async () => {
        await axios.get(showrelationship)
            .then(res => {
                if (res.status === 200) {
                    setValue(res.data)
                }
            }).catch((err) => console.log(err))
    };

    const handleIdCard = async (value) => {
        // console.log("value", value)
        const sumOne = { identificationId: value }
        setLoading(true)
        if (oData !== undefined) {
            oData?.map((e) => {
                if (e.identificationId === sumOne?.identificationId) {
                    countGu = 1
                    console.log("บัตรคนค้ำซ้ำกัน")
                    setGuarantors([{ ...guarantors[0], identificationId: "", phones: undefined }])
                    form.setFieldsValue({ identificationId: "", telp: "" })
                    CheckGuERS('top')
                }
            })
        }
        if (countGu === 0) {
            console.log("ตอนกรอกเองไม่ซ้ำ")
            if (sumOne?.identificationId === getData.identificationId) {
                CheckGuER('top')
                setGuarantors([{ ...guarantors[0], identificationId: "", phones: undefined }])
                form.setFieldsValue({ identificationId: "", telp: "" })
            } else {
                await axios.post(checkNewCCAP, sumOne)
                    .then((res) => {
                        console.log("res", res.data)
                        if (res.data) {
                            // console.log("res.data",res.data)
                            delete res.data.emails
                            delete res.data.ojs
                            delete res.data.socials
                            message.info('มีข้อมูลในฐานข้อมูล')
                            // setCheckIdCard(false)
                            setDataPostGuarantor({ ...dataPostGuarantor, ...res.data })
                            if (res.data.address) {
                                setDataAddress(res.data.address)
                            }
                            if (res.data.phones) {
                                setDataPhone(res.data.phones)
                            }
                            // setGuarantors([res.data])
                            setImage1([{
                                ...images1[0], ...res.data,
                            }])
                            setGuarantors([{
                                ...guarantors[0], ...res.data, checkDup: true
                            }])
                        }
                        else {
                            message.info('ไม่พบข้อมูลในฐานข้อมูล1')
                            // setCheckIdCard(true)
                            // setDataPostGuarantor({ ...dataPostGuarantor })
                            setGuarantors([{ ...guarantors[0], identificationId: value, customerId: 0, phones: undefined, checkDup: false }])
                            // setDataAddress()
                            // setDataPhone()
                            //form.setFieldsValue({ telp: "" })
                        }
                    })
                    .catch((err) => console.log(err))
            }
        }
        setLoading(false)
    }

    const handleIdentificationId = async (e) => {
        if (e.target.value.length === 13) {
            await handleIdCard(e.target.value)
        } else {
            setGuarantors([{ ...guarantors[0], identificationId: e.target.value }])
        }
    }

    const handlePhone = (e) => {
        const updatedGuarantors = [...images1];

        if (images1[0]?.phones !== undefined) {
            updatedGuarantors[0].phones[0].telp = e.target.value;
            setImage1(updatedGuarantors);
        }
        else {
            setImage1([{ ...images1[0], phones: [{ telp: e.target.value, phoneType: "เบอร์ติดต่อ", sendSms: "1" }] }])
        }

    }

    const handleNickname = (e) => {
        setImage1([{ ...images1[0], nickname: e.target.value }])
    }

    const genGuarantors = ({ item, index }) => {

        return (
            <Form
                name="basic"
                labelCol={{
                    span: 12,
                }}
                wrapperCol={{
                    span: 24,
                }}
                style={{
                    maxWidth: '100%',
                }}
                initialValues={{
                    remember: true,
                }}
                form={form}
                onFinish={handleSubmit}
                autoComplete="off"
            >
                <Card style={{ width: '100%' }}>
                    {/* <Row justify={'center'}><b><u>คนค้ำที่ {index + 1}</u></b></Row> */}
                    <Form
                        labelCol={{
                            span: 12,
                        }}
                        wrapperCol={{
                            span: 12,
                        }}
                        form={form}
                        autoComplete="off">
                        <Row>
                            <Col span={12}>
                                <Form.Item label='เลขประจำตัวประชาชน' name='identificationId'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <Input type='text' maxLength={13} onChange={(e) => { handleIdentificationId(e) }} ></Input>
                                </Form.Item>
                                <Form.Item label="คำนำหน้าชื่อ" name="snam"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <Radio.Group onChange={(e) => setGuarantors([{ ...guarantors[0], snam: e.target.value }])}>
                                        <Radio value="นาย"> นาย </Radio>
                                        <Radio value="นาง"> นาง </Radio>
                                        <Radio value="นางสาว"> นางสาว </Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item label='ชื่อ' name='firstname'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}
                                >
                                    <Input type='text'
                                        onChange={(e) => setGuarantors([{ ...guarantors[0], firstname: e.target.value }])}></Input>
                                </Form.Item>
                                <Form.Item label='นามสกุล' name='lastname'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <Input type='text'
                                        onChange={(e) => setGuarantors([{ ...guarantors[0], lastname: e.target.value }])}></Input>
                                </Form.Item>

                                <Form.Item label='ชื่อเล่น' name='nickname'>
                                    <Input type='text'
                                        onChange={(e) => setGuarantors([{ ...guarantors[0], nickname: e.target.value }])}></Input>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label='เพศ' name='gender'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <Select
                                        placeholder={'เลือก'}
                                        style={{
                                            width: '100%',
                                        }}
                                        onChange={(value) => setGuarantors([{ ...guarantors[0], gender: value }])}
                                        options={[
                                            {
                                                label: 'ชาย',
                                                value: 'M',
                                            },
                                            {
                                                label: 'หญิง',
                                                value: 'F',
                                            },
                                        ]} />
                                </Form.Item>
                                <Form.Item label='เกิดวันที่' name='birthdate'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <DatePicker format={'YYYY-MM-DD'} style={{ width: '100%' }}
                                        onChange={(e) => {
                                            if (e) {
                                                setGuarantors([{ ...guarantors[0], birthdate: e.format('YYYY-MM-DD') }])
                                            } else {
                                                setGuarantors([{ ...guarantors[0], birthdate: null }])
                                            }
                                        }} />
                                </Form.Item>

                                <Form.Item label='เจ้าพนักงานออกบัตร' name='issuby'>
                                    <Input type='text'
                                        onChange={(e) => setGuarantors([{ ...guarantors[0], issuby: e.target.value }])}>
                                    </Input>
                                </Form.Item>
                                <Form.Item label='วันออกบัตร' name='issudt'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <DatePicker format={'YYYY-MM-DD'} style={{ width: '100%' }}
                                        onChange={(e) => {
                                            if (e) {
                                                setGuarantors([{ ...guarantors[0], issudt: e.format('YYYY-MM-DD') }])
                                            } else {
                                                setGuarantors([{ ...guarantors[0], issudt: null }])
                                            }
                                        }} />
                                </Form.Item>
                                <Form.Item label='วันบัตรหมดอายุ' name='expdt'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <DatePicker format={'YYYY-MM-DD'} style={{ width: '100%' }}
                                        onChange={(e) => {
                                            if (e) {
                                                setGuarantors([{ ...guarantors[0], expdt: e.format('YYYY-MM-DD') }])
                                            } else {
                                                setGuarantors([{ ...guarantors[0], expdt: null }])
                                            }
                                        }} />
                                </Form.Item>
                                <Form.Item label='ความสัมพันธ์กับผู้กู้' name='description'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !'
                                        },]}>
                                    <Select
                                        placeholder="เลือกความสัมพันธ์"
                                        onChange={changeDescription}
                                        options={value}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <div style={{ textAlign: "center" }}>
                            <Space>
                                {/* <Button type="primary" style={{ background: "red" }} onClick={handleCancel}>ยกเลิก</Button>
                                <Button type="primary" htmlType="submit">ต่อไป</Button> */}
                            </Space>
                        </div>
                    </Form>
                </Card>
            </Form>
        )
    }



    return (
        <>
            <Spin spinning={loading} size='large' tip=" Loading... ">
                <Form
                    name="basic"
                    labelCol={{
                        span: 12,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    style={{
                        width: '100%',
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    form={form1}
                    //onFinish={handleSubmit}
                    autoComplete="off"
                >
                    <Row justify={'center'}><b><u>เพิ่มข้อมูลคนค้ำ</u></b></Row>
                    <Divider style={{ margin: 5 }} />
                    <Row justify={'center'} className='main2'>
                        <Col span={22}>
                            <aside>
                                <b>
                                    <div>
                                        {guarantors?.length > 0 ?
                                            <>
                                                {guarantors?.map((item, index) => {
                                                    return genGuarantors({ item, index, key: `{item.guarantors} - ${index}` });
                                                })}
                                            </>
                                            :
                                            null
                                        }
                                        <Divider />
                                        {
                                            guarantors?.length >= 1 ?
                                                <>
                                                    {fileListGuarantor1?.imageListGuarantor?.map((item, index) => {
                                                        // console.log("item", item)
                                                        return (
                                                            <>
                                                                <Row justify={'left'} > <span><b>รูปบัตรประชาชนผู้ขอกู้ :</b></span></Row>
                                                                <Row justify={'center'}>
                                                                    <Col span={24} style={{ textAlign: 'center' }}>
                                                                        {/*กรณีโชว์รูปโดยที่ยังไม่เข้าฐานข้อมูล */}
                                                                        <img width={'300px'} key={index} style={{ margin: 5 }} src={createImageUrl(item)} alt={item.filename} />
                                                                        {/*กรณีโชว์รูปที่เข้าฐานข้อมูลแล้ว */}
                                                                    </Col>
                                                                </Row>
                                                            </>
                                                        )
                                                    })}

                                                </>

                                                :
                                                <Form.Item
                                                    label={<u>รูปบัตรประชาชนคนค้ำ ( 1 รูป)</u>}
                                                    name='imgGuarantor'
                                                // style={{ textAlign: 'center' }}
                                                >
                                                    <Upload
                                                        // multiple={true}
                                                        style={{ textAlign: 'center' }}
                                                        listType="picture-card"
                                                        fileList={fileListGuarantor}
                                                        onPreview={handlePreview}
                                                        onChange={handleChangeGuarantor1}
                                                        // onChange={handleChangeGuarantor}
                                                        beforeUpload={() => false}
                                                    >
                                                        {fileListGuarantor?.length >= 1 ? null : (
                                                            <div>
                                                                <PlusOutlined />
                                                                <div className="ant-upload-text">upload</div>
                                                            </div>
                                                        )}
                                                    </Upload>
                                                    <Modal open={previewVisible} onCancel={handleCancel1}>
                                                        <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                    </Modal>
                                                </Form.Item>
                                        }


                                    </div>
                                    <Divider />
                                    <div>
                                        <Row justify={'left'} > <span><b>รูปเอกสารผู้ค้ำ :</b></span></Row>
                                        <Row justify={'center'}>
                                            <Form.Item
                                                label=''
                                                name='imgGuarantor'
                                                style={{ textAlign: 'center' }}
                                            >
                                                <Upload
                                                    multiple={true}
                                                    listType="picture-card"
                                                    fileList={fileListGuarantors}
                                                    onPreview={handlePreview}
                                                    onChange={handleChangeGuarantors1}
                                                    // onChange={handleChangeGuarantor}
                                                    beforeUpload={() => false}
                                                >
                                                    {fileListGuarantors?.length >= 10 ? null : (
                                                        <div>
                                                            <PlusOutlined />
                                                            <div className="ant-upload-text">upload</div>
                                                        </div>
                                                    )}
                                                </Upload>
                                                <Modal open={previewVisible} onCancel={handleCancel1}>
                                                    <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                </Modal>
                                            </Form.Item>
                                        </Row>
                                    </div>
                                    <div>
                                        {
                                            dataCustomer !== undefined ?
                                                <>
                                                    <Row style={{ marginLeft: 5 }}><span><b>รูปเอกสารผู้ค้ำ :</b></span></Row>
                                                    <Row justify={'center'}>


                                                        {dataCustomer[0]?.imgs?.map((imageData, index) => (
                                                            <>
                                                                <div>
                                                                    <Image width={'100px'} style={{ margin: 5 }} src={createImageUrl(imageData)} alt={imageData?.filename} />
                                                                </div>
                                                            </>
                                                        ))}
                                                    </Row>
                                                </>
                                                :
                                                null
                                        }

                                    </div>
                                </b >
                            </aside>
                        </Col>
                    </Row>
                    <Divider />
                    <Row>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <Space>
                                <Button type="primary" onClick={handleCancel} style={{ background: "red" }}>ยกเลิก</Button>
                                <Button type="primary" onClick={handleSubmit} style={{ background: "green" }} >ต่อไป</Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </Spin>
            {contextHolder}
        </>

    )

}

export default AddCustomerGua