import React from 'react';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { Button, Form, Divider, Row, Col, Card, Space, notification } from 'antd';
import dayjs from 'dayjs';
import { addguarantors, uploadImgGuaTable } from '../../../file_mid/all_api';

export default function AddDetailGua({ close, prev, saveclose, dataPhone, dataAddress, dataCustomer, indexedit, setSendback, dataFromTable }) {

    const [checkButton, setCheckButton] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const branch = localStorage.getItem('branch')
    const [dataMix, setDataMix] = useState({})
    const [form] = Form.useForm();

    console.log("dataFromTable", dataFromTable)

    useEffect(() => {
        var GuaPath = `one_images/${dataFromTable.car}/${dataFromTable.carId}/5/${indexedit + 1}`
        setDataMix({ ...dataCustomer[0], address: dataAddress, phones: dataPhone, carLandId: dataFromTable.carId, locat: branch, typeLoan: dataFromTable.car, loanId: dataFromTable.loanId, pathImg: GuaPath })
    }, [])

    console.log("dataMix", dataMix)

    const handleSubmit = async () => {
        // setCheckButton(true)
        delete dataMix.socials
        delete dataMix.emails
        // delete dataMix.identificationId
        console.log("dataMix", dataMix)

        if (dataMix.identificationId) {
            await axios.post(addguarantors, dataMix)
                .then(res => {
                    if (res.status === 200) {
                        DataImagesGu(dataCustomer[0])
                    }
                }).catch((err) => {
                    console.log("err", err)
                })
        } else {
            ERid("top")
        }

    }

    const ERid = (placement) => {
        api.error({
            message: <b>บันทึกไม่สำเร็จ</b>,
            description: 'ไม่พบเลขประจำตัวประชาชน กรุณากลับไปยังหน้า ข้อมูลส่วนตัว เพื่อแก้ข้อมูล',
            placement,
        });
    }

    const DataImagesGu = async (data) => {
        // console.log("data", data)

        let AddData2

        if (dataFromTable?.car === "car") {
            let path = `one_images/car/${dataFromTable.carId}/5/${indexedit + 1}`
            console.log("path1", path)
            AddData2 = [{ ...data, pathImg: path }];

            const InputImage = {
                mainImg: { carId: `${dataFromTable.carId}`, carInput: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"), productTypeId: 1, img: data.imgs }
            }
            await types(InputImage)
            console.log("InputImage-Car", InputImage)
        } else {
            let path = `one_images/land/${dataFromTable.carId}/5/${indexedit + 1}`
            console.log("path2", path)
            AddData2 = [{ ...data, pathImg: path }];//เก็บค่า state เดิมไว้ พร้อมส่ง type ไปด้วย เพื่อบอกค่าที่ส่ง
            const InputImage = {
                mainImg: { carId: `${dataFromTable.carId}`, carInput: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"), productTypeId: 2, img: data.imgs }
            }
            console.log("InputImage-Land", InputImage)
            await types(InputImage)
        }
    }

    const types = async (InputImage) => {
        console.log("InputImage", InputImage)
        await axios.post(uploadImgGuaTable, InputImage)
            .then(response => {
                if (response) {
                    setSendback(dayjs())
                    Success("top")
                    setTimeout(() => {
                        saveclose()
                    }, 1500)
                }
            }).catch((err) => {
                console.log("err", err)

            })
    }

    const Success = (placement) => {
        api.success({
            message: <b>บันทึกสำเร็จ</b>,
            placement,
        });
    }

    const handleCancel = () => {
        close(false)
    }

    const onChangeBack = () => {
        prev()
    }

    const renderAddress = ({ item, index }) => {
        return (
            <Form form={form}>
                <Row style={{ margin: '3px' }} justify={'center'} >
                    <Card
                        key={index} // index เป็นตัวกำหนดคีย์
                        title={
                            (item?.addressStatus === true || item?.AddressStatus === 1 || item?.AddressStatus === true) ? <u>ใช้งานที่อยู่</u> : <u>ไม่ได้ใช้งาน</u>
                        }
                        style={{ width: '300px', textAlign: 'center' }} >
                        <Col span={24} style={{ textAlign: 'left' }}>
                            <Form.Item style={{ marginBottom: 0 }} label='เลขทะเบียนบ้าน' >
                                <b>{item?.houseRegistrationNumber}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='บ้านเลขที่'>
                                <b>{item?.houseNumber}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='ซอย'>
                                <b>{item?.soi}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='ถนน'>
                                <b>{item?.road}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='หมู่บ้าน'>
                                <b>{item?.village}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='ตำบล'>
                                <b>{item?.subdistrict}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='อำเภอ'>
                                <b>{item?.district}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='จังหวัด'>
                                <b>{item?.province}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='รหัสไปรษณีย์'>
                                <b>{item?.zipCode}</b>
                            </Form.Item>
                            <Divider></Divider>
                            <Form.Item style={{ marginBottom: 0 }} label='เป็นที่อยู่'>
                                <b>
                                    {(item?.addressOnIdcard === true || item?.AddressOnIdcard === 1 || item?.AddressOnIdcard === true) && "ตามบัตรประชาชน "}
                                    {(item?.houseRegistration === true || item?.HouseRegistration === 1 || item?.HouseRegistration === true) && "ตามทะเบียนบ้าน "}
                                    {(item?.workAddress === true || item?.WorkAddress === 1 || item?.WorkAddress === true) && "ตามสถานที่ทำงาน "}
                                    {(item?.otherAddress === true || item?.OtherAddress === 1 || item?.OtherAddress === true) && "อื่นๆ "}
                                    {(item?.currentAddress === true || item?.CurrentAddress === 1 || item?.CurrentAddress === true) && "ที่อยู่ปัจจุบัน "}
                                    {(item?.sendDocuments === true || item?.SendDocuments === 1 || item?.SendDocuments === true) && "ที่อยู่ส่งเอกสาร "}
                                </b>
                            </Form.Item>
                        </Col>
                    </Card>
                </Row>
            </Form>
        )
    }
    const renderPhone = ({ item, index }) => {

        return (
            <Form form={form}>
                <Row style={{ margin: '3px' }} justify={'center'} >
                    <Card
                        key={index} // index เป็นตัวกำหนดคีย์
                        style={{ textAlign: 'center' }}
                        title={<u>{item?.phoneType}</u>}
                    >
                        <Col span={24} style={{ textAlign: 'left' }}>
                            <Form.Item style={{ marginBottom: 0 }} label='เบอร์'>
                                <b>{item?.telp}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='sendSms'>
                                {
                                    item?.sendSms === "0" && (<b>ไม่ส่ง</b>)
                                }
                                {
                                    item?.sendSms === "1" && (<b>ส่ง</b>)
                                }
                            </Form.Item>
                        </Col>
                    </Card>
                </Row>
            </Form>
        )
    }
    return (
        <>
            <Card>
                <Row>
                    <Col span={24} style={{ fontSize: '30px', textAlign: 'center' }} >รายละเอียด</Col>
                    <Divider />
                </Row>
                <Form >
                    <Row>
                        <Col span={24} style={{ fontSize: '25px' }} >ข้อมูลส่วนตัว</Col>
                        <Col span={4}></Col>
                        <Col span={6}>
                            <Form.Item label="เลขบัตรประชาชน" style={{ margin: 0 }}>
                                <b>{dataCustomer[0].identificationId}</b>
                            </Form.Item>
                            <Form.Item label="ชื่อ-สกุล" style={{ margin: 0 }}>
                                <b>{dataCustomer[0].snam}{dataCustomer[0].firstname} {dataCustomer[0].lastname}</b>
                            </Form.Item>
                            <Form.Item label="เกิดวันที่" style={{ margin: 0 }}>
                                <b>{dataCustomer[0].birthdate}</b>
                            </Form.Item>

                            <Form.Item label="ความสัมพันธ์กับผู้กู้" style={{ margin: 0 }}>
                                <b>{dataCustomer[0].description}</b>
                            </Form.Item>
                        </Col>
                        <Col span={7}>
                            <Form.Item label="ชื่อเล่น" style={{ margin: 0 }}>
                                <b>{dataCustomer[0].nickname}</b>
                            </Form.Item>
                            <Form.Item label="สถานที่ออกบัตร" style={{ margin: 0 }}>
                                <b>{dataCustomer[0].issuby}</b>
                            </Form.Item>
                            <Form.Item label="สาขา" style={{ margin: 0 }}>
                                <b>{dataMix?.locat}</b>
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item label="เพศ" style={{ margin: 0 }}>
                                <b>{dataCustomer[0].gender}</b>
                            </Form.Item>
                            <Form.Item label="วันที่บัตรหมดอายุ" style={{ margin: 0 }}>
                                <b>{dataCustomer[0].expdt}</b>
                            </Form.Item>
                            <Form.Item label="วันที่ออกบัตร" style={{ margin: 0 }}>
                                <b>{dataCustomer[0].issudt}</b>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider />
                    <Row>
                        <Col span={24}>
                            <Row>
                                <Col span={24} style={{ fontSize: '25px' }} >รายละเอียดที่อยู่</Col>
                            </Row>
                            <Row justify={'center'}>
                                {
                                    dataAddress.map((item, index) => {
                                        return renderAddress({ item, index, key: `{item.identificationId} - ${index}` })
                                    })
                                }
                            </Row>
                        </Col>
                    </Row>
                    <Divider />
                    <Row>
                        <Col span={24}>
                            <Row>
                                <Col span={24} style={{ fontSize: '25px' }} >ช่องทางการติดต่อ</Col>
                            </Row>
                            <Row justify={'center'}>
                                {
                                    dataPhone.map((item, index) => {
                                        return renderPhone({ item, index, key: `{item.identificationId} - ${index}` })
                                    })
                                }
                            </Row>
                        </Col>
                    </Row>
                    <Divider />
                    <Row>
                        <Col className='gutter-row' span={24}>
                        </Col>
                    </Row>
                    <div style={{ textAlign: "center" }} >
                        <Space>
                            <Button type="primary" onClick={onChangeBack} style={{ background: '#bfbfbf', color: '#000000', }}>ย้อนกลับ</Button>
                            <Button type="primary" onClick={handleCancel} style={{ background: "red" }}>ยกเลิก</Button>
                            <Button type="primary" htmlType="submit" style={{ background: "green" }} disabled={checkButton} onClick={handleSubmit}>บันทึก</Button>
                        </Space>
                    </div>
                </Form>
                {contextHolder}
            </Card>
        </>
    )

};
