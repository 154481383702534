import React, { useState } from 'react'
import { Button, Form, Input, Select, Row, Col, Modal, message, Radio } from 'antd';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { insertphone } from '../../file_mid/all_api';



function PostsPhone({ open, close, checkphone1, setcheckphone, funcphone, phone }) {
    //useSelector
    const [messageApi, contextHolder] = message.useMessage();
    const { customers } = useSelector(state => (state))
    const [idPhone, setIdPhone] = useState({ customerId: customers.data.customerId })
    //useForm
    const [formphone] = Form.useForm();
    //states
    const [phoneadd, setPhoneAdd] = useState({});

    //cancle ปิด modal
    const handleCancel = () => {
        close(false);
    };

    //ปุ่ม Submit in Modal Add Data Contract
    const handleSubmit = async () => {
        const dataphoneadd = { customer: idPhone, phones: [{ ...phoneadd }] }
        await axios.post(insertphone, dataphoneadd)
            .then((res) => {
                if (res) {
                    messageApi.open({
                        type: 'success',
                        content: 'บันทึกสำเร็จ',
                    });
                } else {
                    messageApi.open({
                        type: 'error',
                        content: 'บันทึกไม่สำเร็จ',
                    });
                }
            })
            .catch((err) => {
                if (err.response.request.status === 400) {
                    messageApi.open({
                        type: 'error',
                        content: 'บันทึกไม่สำเร็จ',
                    });
                }
                console.log("err", err)
            })
        // ...pre เป็นการดึงข้อมูลก่อนหน้าที่อยู่ใน store และ ...addressadd โยนค่าที่กรอกใหม่จาก Input Form เข้าไปใน setDataArray 
        //จะได้ข้อมูลชุดใหม่ขึ้นมาแสดงตามที่กรอกใหม่

        if (checkphone1 === "0") {
            setPhoneAdd(pre => [...pre, { ...phoneadd }]);
            funcphone({ phoneadd })
            setcheckphone("1")
            close(false)
        } else {
            setPhoneAdd(pre => [...pre, { ...phoneadd }]);
            funcphone({ phoneadd })
            setcheckphone("1")
            close(false)
        }
    }

    return (
        <>
            {/* Modal เพิ่มข้อมูล */}
            <Modal
                open={open}
                style={{ background: '#2f54eb' }}
                centered
                onCancel={handleCancel}
                width={500}
                footer={[
                    <Row
                        justify={'center'}
                    >
                        <Button key="back" onClick={handleCancel}>
                            ยกเลิก
                        </Button>
                        <Button
                            type="primary"
                            onClick={handleSubmit}
                        >
                            บันทึก
                        </Button>
                    </Row>
                ]}
            >
                <Form
                    form={formphone}
                    name='adddata'
                    labelCol={{ span: 8, }}
                    wrapperCol={{ span: 24, }}
                    style={{ maxWidth: '400px', }}
                >
                    <Row
                        justify={'center'}
                    >
                        <h3 style={{ margin: 10 }}>
                            <u>เพิ่มเบอร์โทรศัพท์</u>
                        </h3>
                    </Row>
                    <Row
                        gutter={32}
                    >
                        <Col span={24}>
                            <Form.Item label='ชนิด' name='phoneType'
                                rules={[{
                                    required: true,
                                    message: 'Please input !',
                                },]}
                            >
                                <Select
                                    placeholder={'เลือก'}
                                    onChange={(value) => setPhoneAdd({ ...phoneadd, phoneType: value })}
                                    options={[
                                        {
                                            label: 'เบอร์มือถือ',
                                            value: 'เบอร์มือถือ',
                                        },
                                        {
                                            label: 'เบอร์ที่ทำงาน',
                                            value: 'เบอร์ที่ทำงาน',
                                        },
                                        {
                                            label: 'เบอร์บ้าน',
                                            value: 'เบอร์บ้าน',
                                        }
                                    ]}
                                />
                            </Form.Item>
                            <Form.Item label="เบอร์" name='telp' >
                                {phoneadd.phoneType === 'เบอร์มือถือ' ? (<Input maxLength={10} onChange={(e) => setPhoneAdd({ ...phoneadd, telp: e.target.value })} />) : null}
                                {phoneadd.phoneType === 'เบอร์ที่ทำงาน' ? (<Input maxLength={9} onChange={(e) => setPhoneAdd({ ...phoneadd, telp: e.target.value })} />) : null}
                                {phoneadd.phoneType === 'เบอร์บ้าน' ? (<Input maxLength={9} onChange={(e) => setPhoneAdd({ ...phoneadd, telp: e.target.value })} />) : null}
                            </Form.Item>
                            <Form.Item label="ส่ง sms" name="sendSms">
                                <Radio.Group onChange={(e) => setPhoneAdd({ ...phoneadd, sendSms: e.target.value })} >
                                    <Radio value="1"> ส่ง </Radio>
                                    <Radio value="0"> ไม่ส่ง </Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item label="หมายเหตุ" name='note' >
                                <Input onChange={(e) => setPhoneAdd({ ...phoneadd, note: e.target.value })} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            {contextHolder}
        </>
    )
};

export default PostsPhone
