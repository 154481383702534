import React, { useEffect, useState } from 'react'
import { Button, Card, Checkbox, Col, Divider, Form, Space } from 'antd'

function CheckGuaLand({ page, changepage, close }) {

  const [checkGuaData, setCheckGuaData] = useState();
  const checkGuas = localStorage.getItem('addCheckGua')
  const dataCheckGuas = JSON.parse(checkGuas)

  useEffect(() => {
    setCheckGuaData(dataCheckGuas)
  }, [checkGuas])


  const handleGo = () => {
    if (checkGuaData === "ค้ำ-ไม่โอน" || checkGuaData === "ค้ำ-โอน") {
      changepage(page + 1)
    } else {
      changepage(page + 2)
    }
  }

  const handleBack = () => {
    changepage(page - 1)
  }
  const handleClose = () => {
    close()
  }

  // console.log("checkGuaData", checkGuaData)

  const handleSubmit = () => {
    if (checkGuaData) {
      if (checkGuaData === "ไม่ค้ำ-ไม่โอน" || checkGuaData === "ไม่ค้ำ-โอน") {
        localStorage.removeItem('addGuarantor');
      }
      localStorage.setItem('addCheckGua', JSON.stringify(checkGuaData))
      handleGo()
    } else {
      alert("กรุณาเลือกการค้ำโอน")
    }
  }
  const onChangeCheckGua = (e) => {
    setCheckGuaData(e)
  }


  return (
    <Card>
      <Col style={{ textAlign: 'center' }} >
        <b><h2>เลือกการค้ำโอน</h2></b>
      </Col>
      <Divider />
      <Form onFinish={handleSubmit}>
        <Col span={24} style={{ textAlign: 'center' }}>
          <Checkbox
            key={"0"}
            value={1}
            style={{ fontSize: '20px' }}
            checked={checkGuaData === "ค้ำ-ไม่โอน"}
            isPacked={true}
            onChange={() => onChangeCheckGua("ค้ำ-ไม่โอน")}
          >
            ค้ำ-ไม่โอน
          </Checkbox>
          <Checkbox
            key={"1"}
            value={2}
            style={{ fontSize: '20px' }}
            checked={checkGuaData === "ค้ำ-โอน"}
            isPacked={true}
            onChange={() => onChangeCheckGua("ค้ำ-โอน")}
          >
            ค้ำ-โอน
          </Checkbox>
          <Checkbox
            key={"2"}
            value={3}
            style={{ fontSize: '20px' }}
            checked={checkGuaData === "ไม่ค้ำ-ไม่โอน"}
            isPacked={true}
            onChange={() => onChangeCheckGua("ไม่ค้ำ-ไม่โอน")}
          >
            ไม่ค้ำ-ไม่โอน
          </Checkbox>
          <Checkbox
            key={"3"}
            value={4}
            style={{ fontSize: '20px' }}
            checked={checkGuaData === "ไม่ค้ำ-โอน"}
            isPacked={true}
            onChange={() => onChangeCheckGua("ไม่ค้ำ-โอน")}
          >
            ไม่ค้ำ-โอน
          </Checkbox>
        </Col>
        <Divider />
        <Col span={24} style={{ textAlign: 'center' }}>
          <Space>
            <Button type='primary' onClick={handleBack} >ย้อนกลับ</Button>
            <Button type='primary' danger onClick={handleClose} >ยกเลิก</Button>
            <Button type='primary' htmlType='submit' style={{ backgroundColor: "green" }} >ต่อไป</Button>
          </Space>
        </Col>
      </Form>
    </Card>
  )
}

export default CheckGuaLand