import React, { useState, useEffect } from 'react'
import { Modal, Row, Col, Radio, notification } from 'antd'
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { addCustomer } from '../../../redux/Customer';
import { addCar } from '../../../redux/Car';

import EditCustomer from './EditCustomer';
import EditCarLone from './EditCarLone';
import { findcarall } from '../../file_mid/all_api';

function ModalEditer({ open, close }) {

    const customers = useSelector(state => state.customers)
    const cars = useSelector(state => state.cars)
    const [dataMix, setDataMix] = useState({ carId: cars.data, customerId: customers.data }); //มาจากรีดัค
    const [api, contextHolder] = notification.useNotification();
    const [dataModalEdit, setDataModalEdit] = useState();
    const [countReset, setCountReset] = useState()
    const [count, setCount] = useState(1)

    const dispatch = useDispatch()

    useEffect(() => {
        setDataMix({ carId: cars.data, customerId: customers.data })
        loadData()
    }, [open, countReset])

    const handleCancel = () => {
        close(false);
        dispatch(addCustomer())
        dispatch(addCar())
    };

    const NotiSuccess = (placement) => {
        api.success({
            message: <b>แก้ไขข้อมูลแล้ว</b>,
            //   description:
            //     'สถานะถูกเปลี่ยนจาก รอธุการรับ เป็น รอพิจารณาแล้ว',
            placement,
        });

    }
    const NotiError = (placement) => {
        api.error({
            message: <b>แก้ไขข้อมูลส่วนไม่สำเร็จ</b>,
            placement,
        });
    }
    const NotiErrorIMG = (placement) => {
        api.warning({
            message: <b>ไม่พบรูปภาพ</b>,
            placement,
        });
    }
    const DeleteImgSucc = (placement) => {
        api.success({
            message: <b>ลบรูปภาพแล้ว</b>,
            placement,
        });
    }
    const DeleteImgError = (placement) => {
        api.error({
            message: <b>ลบรูปภาพไม่สำเร็จ</b>,
            placement,
        });
    }

    const loadData = async () => {
        await axios.post(findcarall, dataMix)
            .then(res => {
                if (res.status === 200) {
                    // console.log("loadData", res.data)
                    setDataModalEdit(res.data)
                }
            }).catch((err) => {
                console.log("err", err)
            })
    }

    return (
        <div>
            <Modal open={open} onCancel={handleCancel} width={1000} footer={null}>
                <Row>
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <Radio.Group value={count} onChange={(e) => setCount(e.target.value)} block>
                            <Radio.Button value={1}>ข้อมูลส่วนตัว</Radio.Button>
                            <Radio.Button value={2}>รายละเอียด</Radio.Button>
                        </Radio.Group>
                    </Col>
                </Row>
                {count === 1 && (<EditCustomer getData={dataModalEdit} sendback={setCountReset} onCancel={handleCancel} changepage={setCount} notification={NotiSuccess} notification2={NotiError} />)}
                {count === 2 && (<EditCarLone getData={dataModalEdit} onCancel={handleCancel} changepage={setCount} notification={NotiSuccess} notification2={NotiError} NotiErrorIMG={NotiErrorIMG} DeleteImgSucc={DeleteImgSucc} DeleteImgError={DeleteImgError} />)}
            </Modal>
            {contextHolder}
        </div>
    )
};

export default ModalEditer