import { Button, Col, Modal, Row } from 'antd';
import React, { useEffect, useState, useRef } from 'react'
import { Carousel } from 'react-responsive-carousel';
import { UndoOutlined, RedoOutlined } from "@ant-design/icons";
import { getImagess } from '../file_mid/all_api';

function ModalShowImages({ open, close, carimage1 }) {


    console.log("carimag1", carimage1)
    const token = localStorage.getItem('token');
    const [currentIndex, setCurrentIndex] = useState(0);
    const [rotateDegree, setRotateDegree] = useState(0);
    const handleRotate = (degrees) => {
        setRotateDegree(prevDegree => (prevDegree + degrees) % 360); // ป้องกันองศาเกิน 360
    };
    const carouselRef = useRef(null);
    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'ArrowRight') {
                setCurrentIndex((prevItem) => (prevItem + 1));
            } else if (event.key === 'ArrowLeft') {
                setCurrentIndex((prevItem) => (prevItem - 1));
            }
        };

        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    const handleCancel = () => {
        close(false);
    };

    return (
        <Modal
            title={"รูปภาพ"}
            open={open}
            onCancel={handleCancel}
            width={1000}
            footer={null}
        >
            <Row justify="center">
                <Button onClick={() => handleRotate(-90)}> <b style={{ fontSize: '18px' }}><UndoOutlined />หมุนซ้าย </b></Button>
                <Button onClick={() => handleRotate(90)}><b style={{ fontSize: '18px' }}>หมุนขวา <RedoOutlined /></b></Button>
            </Row>
            <Row jutify={'center'}>
                <Col span={24}>
                    <Carousel
                        showArrows={true}
                        infiniteLoop
                        dynamicHeight
                        width={'100%'}
                        textAlign={'cneter'}
                        ref={carouselRef}
                        selectedItem={currentIndex}
                        onChange={setCurrentIndex}
                    >
                        {carimage1?.map((e, index) => {
                            return (
                                <div key={index}>
                                    <img
                                        style={{ width: '100%', transform: `rotate(${rotateDegree}deg)`, }}
                                        src={`${getImagess}/${e.pathImage}?token=${token}`}
                                        alt={`รูปภาพ ${index}`}
                                    />

                                </div>
                            )
                        })}
                    </Carousel>

                </Col>
            </Row>

        </Modal >
    )
}
export default ModalShowImages