import React, { useEffect, useState } from 'react'
import { Button, Input, Form, DatePicker, Card, Col, Row, Select, Radio, Space, Divider } from 'antd';
import dayjs from 'dayjs';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { addCustomer } from '../../../../../redux/Customer';
import { showrelationship1 } from '../../../../file_mid/all_api';
// import { guarantor } from '../../../../../redux/Guarantor';

function GECustomer({ next, close, theData }) {

    const customers = useSelector(state => state.customers)

    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [dataPost, setDataPost] = useState({})

    const [value, setValue] = useState([])

    useEffect(() => {
        loadRelationship()
        if (customers.data.customerId !== undefined) {
            setDataPost(customers.data)
            form.setFieldsValue(
                {
                    ...customers.data,
                    birthdate: customers.data.birthdate ? dayjs(customers.data.birthdate) : '',
                    issudt: customers.data.issudt ? dayjs(customers.data.issudt) : '',
                    expdt: customers.data.expdt ? dayjs(customers.data.expdt) : '',
                }
            )
        } else {
            setDataPost(theData)
            form.setFieldsValue(
                {
                    ...theData,
                    // birthdate: dayjs(theData?.birthdate),
                    // issudt: dayjs(theData?.issudt),
                    // expdt: dayjs(theData?.expdt),
                    birthdate: theData.birthdate ? dayjs(theData.birthdate) : '',
                    issudt: theData.issudt ? dayjs(theData.issudt) : '',
                    expdt: theData.expdt ? dayjs(theData.expdt) : '',
                }
            )
        }
    }, [theData])

    const loadRelationship = async () => {
        await axios.get(showrelationship1)
            .then(res => {
                if (res.status === 200) {
                    setValue(res.data)
                }
            }).catch((err) => console.log(err))
    };

    const handleSubmit = async (e) => {
        //delete dataPost.dataAddress
        //delete dataPost.dataPhones
        dispatch(addCustomer(dataPost))
        onChangeGo()
    }

    const onChangeGo = () => {
        next()
    }

    const handleCancel = () => {
        close()
    }

    const changeDescription = (value) => {
        setDataPost({ ...dataPost, description: value })
    }

    return (
        <Card >
            <div>
                <div>
                    <Row>
                        <Col span={24} style={{ fontSize: '30px', textAlign: 'center' }}>ข้อมูลส่วนตัว</Col>
                    </Row>
                </div>
                <Divider></Divider>
                <Form
                    labelCol={{
                        span: 12,
                    }}
                    wrapperCol={{
                        span: 12,
                    }}
                    form={form}
                    onFinish={handleSubmit}
                    autoComplete="off">
                    <Row>
                        <Col span={10}>
                            <Form.Item label='เลขประจำตัวประชาชน' name='identificationId'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input !',
                                    },]}>
                                <Input type='text' maxLength={13} disabled
                                // onChange={(e) => inputIdCard(e)}
                                >
                                </Input>
                            </Form.Item>
                            <Form.Item label='รูปบัตรประชาชน'>
                                <Input type="file" multiple={true} disabled
                                // onChange={handleFileInputChange} 
                                >
                                </Input>
                            </Form.Item>
                            <Form.Item label="คำนำหน้าชื่อ" name="snam"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input !',
                                    },]}>
                                <Radio.Group onChange={(e) => setDataPost({ ...dataPost, snam: e.target.value })} >
                                    <Radio value="นาย"> นาย </Radio>
                                    <Radio value="นาง"> นาง </Radio>
                                    <Radio value="นางสาว"> นางสาว </Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item label='ชื่อ' name='firstname'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input !',
                                    },]}
                            >
                                <Input type='text'
                                    onChange={(e) => setDataPost({ ...dataPost, firstname: e.target.value })}></Input>
                            </Form.Item>
                            <Form.Item label='นามสกุล' name='lastname'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input !',
                                    },]}>
                                <Input type='text'
                                    onChange={(e) => setDataPost({ ...dataPost, lastname: e.target.value })}></Input>
                            </Form.Item>

                            <Form.Item label='ชื่อเล่น' name='nickname'>
                                <Input type='text'
                                    onChange={(e) => setDataPost({ ...dataPost, nickname: e.target.value })}></Input>
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item>
                                <Button type="primary" disabled
                                // onClick={(e) => { handleIdCard(e) }}
                                >ตรวจสอบ</Button>
                            </Form.Item>
                        </Col>
                        <Col span={10}>
                            {/* <Form.Item label='ประเทศ' name='nationalId'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <Input type='text'
                                        onChange={(e) => setDataPost({ ...dataPost, nationalId: e.target.value })}></Input>
                                </Form.Item> */}
                            <Form.Item label='เกิดวันที่' name='birthdate'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input !',
                                    },
                                ]}>
                                <DatePicker format={'YYYY-MM-DD'} style={{ width: '100%' }}
                                    onChange={(e) => {
                                        if (e) {
                                            setDataPost({ ...dataPost, birthdate: e.format('YYYY-MM-DD') })
                                        }
                                    }} />
                            </Form.Item>
                            <Form.Item label='เพศ' name='gender'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input !',
                                    },
                                ]}>
                                <Select
                                    placeholder={'เลือก'}
                                    style={{
                                        width: '100%',
                                    }}
                                    onChange={(value) => setDataPost({ ...dataPost, gender: value })}
                                    options={[
                                        {
                                            label: 'ชาย',
                                            value: 'M',
                                        },
                                        {
                                            label: 'หญิง',
                                            value: 'F',
                                        },
                                    ]} />
                            </Form.Item>
                            <Form.Item label='เจ้าพนักงานออกบัตร' name='issuby'
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'Please input !',
                            //     },]}
                            >
                                <Input type='text'
                                    onChange={(e) => setDataPost({ ...dataPost, issuby: e.target.value })}></Input>
                            </Form.Item>
                            <Form.Item label='วันออกบัตร' name='issudt'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input !',
                                    },
                                ]}>
                                <DatePicker format={'YYYY-MM-DD'} style={{ width: '100%' }}
                                    onChange={(e) => {
                                        if (e) {
                                            setDataPost({ ...dataPost, issudt: e.format('YYYY-MM-DD') })
                                        }
                                    }} />
                            </Form.Item>
                            <Form.Item label='วันบัตรหมดอายุ' name='expdt'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input !',
                                    },
                                ]}>
                                <DatePicker format={'YYYY-MM-DD'} style={{ width: '100%' }}
                                    onChange={(e) => {
                                        if (e) {
                                            setDataPost({ ...dataPost, expdt: e.format('YYYY-MM-DD') })
                                        }
                                    }} />
                            </Form.Item>
                            <Form.Item label='ความสัมพันธ์กับผู้กู้' name='description'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input !'
                                    },]}>
                                <Select
                                    placeholder="เลือกความสัมพันธ์"
                                    onChange={changeDescription}
                                    options={value}
                                />
                            </Form.Item>
                            {/* <Form.Item label='สาขา' name='locat'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input !',
                                    },
                                ]}>
                                <Input type='text'
                                    onChange={(e) => setDataPost({ ...dataPost, locat: e.target.value })}></Input>
                            </Form.Item> */}
                        </Col>
                    </Row>
                    <div style={{ textAlign: "center" }}>
                        <Space>
                            <Button type="primary" style={{ background: "red" }} onClick={handleCancel}>ยกเลิก</Button>
                            <Button type="primary" htmlType="submit" style={{ background: "green" }}>บันทึก</Button>
                            <Button type="primary" onClick={onChangeGo}>ต่อไป</Button>
                        </Space>
                    </div>
                </Form>
            </div>
        </Card>
    )
};


export default GECustomer