import React, { useEffect, useState } from 'react'
import { Button, notification, Form, Card, Input, Col, Row, Spin, Space, Divider, Select, Pagination, DatePicker, Table } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import { SearchOutlined, HomeFilled } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import ModalContno from './ModalContno';
const { Option } = Select;
const { Search } = Input;

export default function Main() {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const token = localStorage.getItem('token');
  const branch = localStorage.getItem('branch');
  const [api, contextHolder] = notification.useNotification();
  const [axiosData, setAxiosData] = useState([]);
  const [arrayTable, setArrayTable] = useState();
  const showDay1 = dayjs("2015-01-01")
  const [dataPost, setDataPost] = useState({ day1: "2015-01-01", day2: "", isu: 0, searchType: "", contno: "" })
  const [loading, setLoading] = useState(false)
  const [tot, setTot] = useState();
  let numberCount = 0
  const [dataCont, setDataCont] = useState()
  const [modalCont, setModalCont] = useState(false)
  // const { Countdown } = Statistic;

  useEffect(() => {
    console.log("api manager + search-contno-db2")
    // loadData()
  }, [])

  const loadCountData = async (inClause) => {
    const tk = JSON.parse(token)
    const headers = {
      "Authorization": `Bearer ${tk}`,
      "Menu": JSON.stringify("3")
    }
    var mainData = { ...dataPost, SALCOD: inClause }
    // await axios.post(checkcountmkcar, mainData, { headers: headers })
    await axios.post("https://api-search-contno-db2-i67f6gaaqa-as.a.run.app/api-db2/countContnoBoss", mainData)
      .then((res) => {
        console.log("cc", res.data)
        if (res.data) {
          setTot(res.data[0]?.TOTAL_COUNT)
        }
      }).catch((err) => console.log(err))

  }
  // console.log("tot", tot)
  const loadData = async (e, pageSize) => {
    console.log(dataPost, numberCount)
    if (dataPost?.day2 !== "" && dataPost?.isu > 0 && numberCount === 1) {
      const date1 = new Date(dataPost.day1);
      const date2 = new Date(dataPost.day2);
      if (date2 > date1) {
        setLoading(true)
        const tk = JSON.parse(token)
        const headers = {
          "Authorization": `Bearer ${tk}`,
          "Menu": JSON.stringify("5")
        }
        if (e && pageSize) {
          var mixData = { ...dataPost, contno: "", searchType: "", numberPage: e, pageSize: pageSize }
        } else {
          var mixData = { ...dataPost, contno: "", searchType: "" }
        }
        console.log("mixData", mixData)
        await axios.post("https://api-manager-i67f6gaaqa-as.a.run.app/midle/cont-boss", mixData, { headers: headers })
          // await axios.post("http://localhost:8080/midle/cont-boss", mixData, { headers: headers })
          .then(async (res) => {
            console.log("res.data Conn", res.data)
            if (res.data?.findARMAST) {
              await loadCountData(res.data.inClause)
              setArrayTable(res.data.findARMAST)
              setAxiosData(res.data.findARMAST)
              form.setFieldsValue({
                search: "",
              })
            } else {
              setArrayTable([])
              setAxiosData([])
              form.setFieldsValue({
                search: "",
              })
            }
          }).catch((err) => {
            console.log(err)
            alert("ไม่พบข้อมูล !!!")
          })
        setLoading(false)
      } else {
        console.log("รูปแบบวันที่ไม่ถูกต้อง")
      }
    } else {
      // alert("กรุณาเลือกวันที่ให้ถูกต้อง !!!")
      console.log("ss")
    }
  }

  const handleYG = async () => {
    if (branch === "MIT" || branch === "UD" || branch === "LEX" || branch === "S4") {
      numberCount = 1
      await loadData()
    } else {
      alert("คุณไม่มีสิทธิ์ใช้งานเมนูนี้ !")
    }
  }

  const onChangePagination = async (e, pageSize) => {
    console.log(e, pageSize)
    numberCount = 1
    setDataPost({ ...dataPost, numberPage: e, pageSize: pageSize })
    await loadData(e, pageSize)
  }
  const handleProduct = (value) => {
    console.log("value: " + value)
    setDataPost({ ...dataPost, isu: value, numberPage: 1, pageSize: 10 })
    numberCount = 0
    setArrayTable([])
    setAxiosData([])
    setTot()
  }
  const handleDate = (e, ty) => {
    if (ty === 1) {
      if (e) {
        numberCount = 0
        setArrayTable([])
        setAxiosData([])
        setTot()
        setDataPost({ ...dataPost, day1: e.format('YYYY-MM-DD') })
      }
    } else {
      if (e) {
        numberCount = 0
        setArrayTable([])
        setAxiosData([])
        setTot()
        setDataPost({ ...dataPost, day2: e.format('YYYY-MM-DD') })
      }
    }
  }

  const onSearch = async (value, _e, info) => {
    if (branch === "MIT" || branch === "UD" || branch === "LEX" || branch === "S4") {
      if (value) {
        setLoading(true)
        setTimeout(async () => {
          console.log("zxc");
          console.log(info?.source, value);
          const tk = JSON.parse(token)
          const headers = {
            "Authorization": `Bearer ${tk}`,
            "Menu": JSON.stringify("5")
          }
          var mixData = { ...dataPost, contno: value, searchType: "search" }
          console.log("mixData", mixData)
          await axios.post("https://api-manager-i67f6gaaqa-as.a.run.app/midle/cont-boss", mixData, { headers: headers })
            // await axios.post("http://localhost:8080/midle/cont-boss", mixData, { headers: headers })
            .then(async (res) => {
              console.log("res.data Conn onSearch", res.data)
              if (res.data?.findARMAST) {
                setDataPost({ day1: "2015-01-01", day2: "", isu: 0, searchType: "", contno: "" })
                setArrayTable(res.data.findARMAST)
                setAxiosData(res.data.findARMAST)
                setTot()

              } else {
                setDataPost({ day1: "2015-01-01", day2: "", isu: 0, searchType: "", contno: "" })
                setArrayTable([])
                setAxiosData([])
                setTot()
              }
              form.setFieldsValue({
                day1: dayjs("2015-01-01"),
                day2: "",
                isu: "",
              })
            }).catch((err) => {
              console.log(err)
              alert("ไม่พบข้อมูล !!!")
            })
          setLoading(false)
        }, 3000)
      } else {
        alert("กรุณากรอกเลขสัญญา !!!")
      }
    } else {
      alert("คุณไม่มีสิทธิ์ใช้งานเมนูนี้ !")
    }
  }
  const currencyFormatOne = (amount) => {
    return Number(amount)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
  }
  const columns = [
    {
      title: "ลำดับ",
      dataIndex: "index",
      key: 'index',
      align: 'center',
      //   fixed: true,
      width: "10%",
      render: (text, object, index) => index + 1
    },
    {
      title: "วันที่",
      dataIndex: "SDATE",
      width: "10%",
      align: 'center',
      render: (text, record) => (
        <Space size="middle">
          {
            record.SDATE !== "" ?
              <Space>
                <>{dayjs(record.SDATE).format("DD-MM-YYYY")}</>
              </Space>
              : null
          }
        </Space>
      ),
      defaultSortOrder: 'descend',
    },
    {
      title: "สัญญา",
      dataIndex: "CONTNO",
      key: 'CONTNO',
      align: 'center',
      width: "30%",
      render: (text, record) => (
        <>
          <div>เลขสัญญา: {record.CONTNO}</div>
          <div>การตลาด: <Space style={{ color: "red" }}><b>{record.SALCOD}</b></Space></div>
        </>
      ),
    },
    {
      title: "ราคาเช่าซื้อครั้งที่แล้ว",
      dataIndex: "BALANC",
      align: 'center',
      width: "15%",
      render: (text, record) => (
        <Space size="middle">
          {
            <Space>
              <>{currencyFormatOne(record.BALANC)}</>
            </Space>
          }
        </Space>
      ),
    },
    {
      title: "ยอดที่ชำระมาแล้วทั้งหมด",
      dataIndex: "SMPAY",
      align: 'center',
      width: "15%",
      render: (text, record) => (
        <Space size="middle">
          {
            <Space>
              <>{currencyFormatOne(record.SMPAY)}</>
            </Space>
          }
        </Space>
      ),
    },
    {
      title: "Action",
      key: 'Action',
      align: 'center',
      width: "30%",
      render: (text, record) => {
        return (
          <>
            <Space>
              <Button
                type="primary"
                style={{ backgroundColor: "green", width: "50px" }}
                icon={<SearchOutlined />}
                onClick={() => {
                  setModalCont(true)
                  setDataCont(record)
                }}
              >
              </Button>
            </Space>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Card>
        <Row style={{ textAlign: 'center' }}>
          <Col>
            <Spin spinning={loading} size='large' tip=" Loading... ">
              <div className='text-center'>
                <h2>ตรวจสอบข้อมูลสัญญา(คนออก)</h2>
              </div>
              <Divider></Divider>
              <Form
                labelCol={{
                  span: 12,
                }}
                wrapperCol={{
                  span: 24,
                }}
                style={{
                  maxWidth: '100%',
                }}
                form={form}
                autoComplete="off"
                initialValues={{ remember: true }}
              >
                <Row gutter={32} style={{ margin: 10 }}>
                  <Space>
                    <Form.Item label='วันที่' name='day1'>
                      <DatePicker format={'YYYY-MM-DD'}
                        defaultValue={showDay1}
                        style={{ textAlign: 'center', width: '150px' }}
                        // style={{ height: '40px', margin: '10px' }}
                        onChange={(e) => { handleDate(e, 1) }} />
                    </Form.Item>

                    <Form.Item label='ถึง' name='day2'>
                      <DatePicker format={'YYYY-MM-DD'}
                        defaultValue={dataPost.day2}
                        style={{ textAlign: 'center', width: '150px' }}
                        onChange={(e) => { handleDate(e, 2) }} />
                    </Form.Item>

                    <Form.Item label='สัญญา' name='isu'>
                      <Select placeholder={'เลือก'}
                        style={{ textAlign: 'center', width: '180px' }}
                        onChange={(value) => handleProduct(value)}
                      >
                        <Option value={1}>สัญญา 1</Option>
                        <Option value={2}>สัญญา 2</Option>
                        <Option value={8}>สัญญา 8</Option>
                      </Select>
                    </Form.Item>
                  </Space>
                  <Col>
                    <Button style={{ marginLeft: '100px', width: '100px' }} type="primary" onClick={(e) => { handleYG(e) }}>ค้นหา</Button>
                  </Col>
                </Row>
                <Divider></Divider>
                {/* <Row gutter={32} style={{ margin: 10 }}>
                <Col className='gutter-row' span={24} style={{ textAlign: 'right' }}>
                  <Search
                    style={{ width: '250px' }}
                    disabled={sButton}
                    placeholder="ค้นหา...."
                    allowClear
                    enterButton="Search"
                    size="large"
                    onSearch={onSearch}
                  />
                </Col>
              </Row> */}

                <Row gutter={32} style={{ margin: 10 }}>
                  <Col className='gutter-row' span={24} style={{ textAlign: 'right' }}>
                    <Form.Item label='' name='search'>
                      <Input.Search
                        style={{ width: '250px' }}
                        placeholder="ค้นหา...."
                        onSearch={onSearch}
                        name="search"
                        id="search"
                        size="large"
                        loading={loading}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>

              <Row gutter={32} style={{ margin: 10 }}>
                <Col span={24}>
                  <Table
                    pagination={false}
                    rowKey={(record) => record.uid}
                    scroll={{
                      x: 1500,
                      y: 400,
                    }}
                    dataSource={arrayTable}
                    columns={columns}
                  >
                  </Table>
                  <Divider />
                  <Pagination onChange={onChangePagination} onShowSizeChange={onChangePagination} defaultPageSize={10} defaultCurrent={1} total={tot} />
                  <Divider />
                </Col>
              </Row>

              <Col span={24} style={{ textAlign: 'center' }}>
                <Space>
                  {/* <Button href="/offercase" type="primary" ><PlusCircleFilled />เพิ่ม</Button> */}
                  <Button href="/" type="primary"><HomeFilled />หน้าหลัก</Button>
                </Space>
              </Col>
            </Spin>
          </Col>
        </Row>
      </Card >
      {
        modalCont ?
          <ModalContno open={modalCont} close={setModalCont} data={dataCont} />
          : null
      }
      {contextHolder}
    </>
  )
}
