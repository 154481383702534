import React, { useState, useEffect } from 'react'
import { Button, Modal, Card, Typography, Table, Form, Input, Row, Col, Select } from 'antd';
import { irr } from 'node-irr'
import Currency from 'currency.js';

import { FIFTEENPERCENT, TWENTYFOURPERCENT } from '../ploan/InstallmentTable'
import { nopay } from '../../file_mid/all_options';

export const HirePurchase = ({ open, close, money, cp, cp2, cp3, checkGARFE, type, checkPG }) => {

  const [odata, setOdata] = useState()
  const [form] = Form.useForm()
  const { Title } = Typography;
  const [checkAmountt, setCheckAmountt] = useState(cp) // ใช้เรทรถ
  const [loanAmountt, setLoanAmountt] = useState(cp)
  //const [installmentCount, setInstallmentCount] = useState(12)
  const [installmentCount, setInstallmentCount] = useState({})
  const [dataSource, setDataSource] = useState([])
  const [interestt, setInterestt] = useState(0.0)
  const [installmentWithInterestt, setInstallmentWithInterestt] = useState(0.0)
  const [arr, setArr] = useState()
  const [resultIrr, setResultIrr] = useState(0.0)
  const [filteredOptions, setFilteredOptions] = useState([]); //หางวดตามช่วงของเฮียอนุมัติ

  const [checkBTN, setcheckBTN] = useState(false)

  // console.log("จำนวนงวด",installmentCount)
  // console.log("เงิน",amount)
  // console.log("ดอก",interest)
  // console.log("จ่ายต่อเดือน",arr)
  // console.log("dataSource", dataSource)
  // console.log("cp", cp)
  // console.log("cp2", cp2)
  // console.log("cp3", cp3)
  // console.log("checkGARFE", checkGARFE)
  // console.log("checkPG", checkPG)
  // console.log("type", type)
  // console.log("nopay", nopay)

  useEffect(() => {
    setOdata()
    handleCalculate()
  }, [installmentCount, loanAmountt])

  useEffect(() => {
    if (cp2 && cp3) {
      const filtered = nopay.filter(
        (option) =>
          option.value >= cp2 &&
          option.value <= cp3
      );
      // console.log("filtered", filtered)
      setFilteredOptions(filtered);
    }

  }, [cp2, cp3]);

  useEffect(() => {
    if (checkGARFE === 1) {
      setLoanAmountt(cp)
      setInstallmentCount({ installmentAmountt: cp2 })
      setcheckBTN(true)

      form.setFieldsValue(
        {
          installmentCount: cp2,
          loanAmountt: cp
        }
      )
    }
    if (checkGARFE === 2) {
      setLoanAmountt(cp)
      setInstallmentCount({ installmentAmountt: cp2 })
      form.setFieldsValue(
        {
          installmentCount: cp2,
          loanAmountt: cp
        }
      )
    }
    if (checkGARFE === 3) {
      setLoanAmountt(cp)
      setInstallmentCount({ installmentAmountt: cp2 })

      form.setFieldsValue(
        {
          installmentCount: cp2,
          loanAmountt: cp
        }
      )
    } else {
      setLoanAmountt(cp)
      form.setFieldsValue(
        {
          // installmentCount: cp2,
          loanAmountt: cp
        }
      )
    }
    if (checkGARFE === 4) {
      // cp  approvedLoanAmount
      // cp2  approvedLoanTerm
      // cp3  approvedLoanTermTo
      setcheckBTN(true)
      setLoanAmountt(cp)
      setInstallmentCount({ installmentAmountt: "" })

      form.setFieldsValue(
        {
          installmentCount: "",
          loanAmountt: cp
        }
      )
    } else {
      setLoanAmountt(cp)
      form.setFieldsValue(
        {
          // installmentCount: cp2,
          loanAmountt: cp
        }
      )
    }
  }, [])

  //เลือกงวดตามช่วงที่เฮียอนุมัติ
  const handleSelectChange = (e) => {
    console.log("ee", e)
    setInstallmentCount({ installmentAmountt: e })
  };

  const handleCancel = () => {
    close(false)
  };

  const onFinish = () => {
    if (checkAmountt > 0) {
      if (checkPG === 1) {
        if (type?.productLoanType === 'ย้ายไฟแนนซ์') {
          const dataM = { ...installmentCount, loanAmountt, interestt, installmentWithInterestt, ...odata, monthlyPayment: arr, interestRate: resultIrr }
          //console.log("result", dataM)
          money(dataM)
          close(false)
        }
        else if (loanAmountt <= checkAmountt && type?.productLoanType !== 'ย้ายไฟแนนซ์') {
          const dataM = { ...installmentCount, loanAmountt, interestt, installmentWithInterestt, ...odata, monthlyPayment: arr, interestRate: resultIrr }
          //console.log("result", dataM)
          money(dataM)
          close(false)
        } else {
          const dataM = { ...installmentCount, loanAmountt, interestt, installmentWithInterestt, ...odata, monthlyPayment: arr, interestRate: resultIrr }
          //console.log("result", dataM)
          money(dataM)
          close(false)
        }
      } else {
        if (type?.productLoanType === 'ย้ายไฟแนนซ์') {
          const dataM = { ...installmentCount, loanAmountt, interestt, installmentWithInterestt, ...odata, monthlyPayment: arr, interestRate: resultIrr }
          //console.log("result", dataM)
          money(dataM)
          close(false)
        }
        else if (loanAmountt <= checkAmountt && type?.productLoanType !== 'ย้ายไฟแนนซ์') {
          const dataM = { ...installmentCount, loanAmountt, interestt, installmentWithInterestt, ...odata, monthlyPayment: arr, interestRate: resultIrr }
          //console.log("result", dataM)
          money(dataM)
          close(false)
        } else {
          alert("ไม่สามารถใส่วงเงินกู้เกินเรทรถได้")
        }
      }
    } else {
      const dataM = { ...installmentCount, loanAmountt, interestt, installmentWithInterestt, ...odata, monthlyPayment: arr, interestRate: resultIrr }
      //console.log("result", dataM)
      money(dataM)
      close(false)
    }

  };


  const installmentCountList = [
    12, 18, 24, 30, 36, 42, 48, 54, 60, 66, 72, 78, 84, 90, 96, 102, 108, 114, 120,
  ]

  // const handleChangeCount = (event) => {
  //   setInstallmentCount(event.target.value)
  // }

  const handleChangeAmount = (event) => {
    //console.log("AA",event.target.value)
    const pAmount = parseInt(event.target.value)
    setLoanAmountt(pAmount)
  }

  const handleCalculate = () => {
    setDataSource([])

    // if (checkGARFE === 1) {
    //   setLoanAmountt(cp)
    //   setInstallmentCount(cp2)
    //   setcheckBTN(true)

    //   form.setFieldsValue(
    //     {
    //       installmentCount: cp2.installmentAmountt
    //     }
    //   )
    // }

    if (loanAmountt && installmentCount.installmentAmountt) {
      const amountWithVat = loanAmountt * 1.07
      let installment = 0.0

      FIFTEENPERCENT.map((value) => {
        if (value.installment === ~~installmentCount.installmentAmountt) {
          installment = value.interest * amountWithVat
        }
      })

      let installmentAmount = Math.round(installment)

      // IRR Calculate
      var array = []
      array.push(-amountWithVat)
      for (var i = 0; i < installmentCount.installmentAmountt; i++) {
        array.push(installmentAmount)
      }

      let IRR = irr(array) * 100

      if (IRR) {
        var TotalPrincipleBalance = amountWithVat //ยอดทั้งหมด
        var totalInterest = 0.0 // ดอกเบี้ย
        //var result = ~~loanAmountt
        var result = amountWithVat
        for (var j = 1; j <= installmentCount.installmentAmountt; j++) {     // installmentCount จำนวนงวด
          let principle = TotalPrincipleBalance     // เงินต้น = ยอดทั้งหมด
          let no = j   // งวดที่ 
          let interestAmount = Math.round((TotalPrincipleBalance * IRR) / 100) // จำนวนดอกเบี้ย = 
          let principleAmount = installmentAmount - interestAmount    //  จำนวนงวด - จำนวนดอกเบี้ย
          let principleBalance = principle - principleAmount          //  ดอกเบี้ยคงเหลือ = 

          totalInterest += interestAmount // totalInterest = totalInterest + interestAmount

          let interestBalance = totalInterest // ดอกเบี้ยคงเหลือ = ยอดรวมดอกเบี้ย

          setDataSource((pre) => [
            ...pre,
            {
              installmentNo: no,                          // งวดที่ 
              installmentAmount: installmentAmount,       // จำนวนงวด
              interestAmount: interestAmount,             // จำนวนดอกเบี้ย
              principleAmount: principleAmount,           // เงินต้น
              principleBalance: principleBalance,         // เงินต้นคงเหลือ
              interestBalance: interestBalance,           // ดอกเบี้ยคงเหลือ
            },
          ])
          // TotalPrincipleBalance = TotalPrincipleBalance - principleAmount, ยอดทั้งหมด
          TotalPrincipleBalance -= principleAmount
        }
        //result += totalInterest
        result = installmentAmount * installmentCount.installmentAmountt
        var interestNEW = result - loanAmountt
        setResultIrr(Currency(IRR, { precision: 5 }).value)
        setArr(installmentAmount)
        setInterestt(interestNEW)
        // setInterestt(totalInterest)
        setInstallmentWithInterestt(result)
      }
    }
  }

  const currencyFormat = (loanAmountt) => {
    return Number(loanAmountt)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
  }

  const column = [
    {
      key: '1',
      title: 'งวดที่',
      dataIndex: 'installmentNo',
    },
    {
      key: '2',
      title: 'ค่างวด',
      dataIndex: 'installmentAmount',
      render: (record) => {
        return currencyFormat(record)
      },
    },
    {
      key: '3',
      title: 'ดอกเบี้ย',
      dataIndex: 'interestAmount',
      render: (record) => {
        return currencyFormat(record)
      },
    },
    {
      key: '4',
      title: 'เงินต้น',
      dataIndex: 'principleAmount',
      render: (record) => {
        return currencyFormat(record)
      },
    },
    {
      key: '5',
      title: 'เงินต้นคงเหลือ',
      dataIndex: 'principleBalance',
      render: (record) => {
        return currencyFormat(record)
      },
    },
    {
      key: '6',
      title: 'ดอกเบี้ยคงเหลือ',
      render: (record) => {
        // let sum = 0.0
        // dataSource.forEach((element) => {
        //   sum += element.interestAmount
        // })

        return currencyFormat(interestt - record.interestBalance)
      },
    },
  ]

  return (
    <Card>
      <Modal
        open={open}
        width={900}
        closable={false}
        footer={[
          <>
            {checkGARFE === 4 ?
              <>
                <Button
                  form="myForm"
                  key="submit"
                  htmlType="submit"
                // disabled={checkBTN}
                >
                  ยืนยัน
                </Button>
              </>
              :
              <>
                <Button
                  form="myForm"
                  key="submit"
                  htmlType="submit"
                  disabled={checkBTN}>
                  ยืนยัน
                </Button>,
              </>
            }


            <Button onClick={handleCancel} key="close">
              ยกเลิก
            </Button>
          </>

        ]}
      >
        <Form
          id="myForm"
          name="basic"
          // labelCol={{
          //   span: 12,
          // }}
          wrapperCol={{
            span: 18,
          }}
          style={{
            maxWidth: '100%',
            margin: 0
          }}
          initialValues={{
            remember: true,
          }}
          form={form}
          onFinish={onFinish}
        //autoComplete="off"
        >
          <Row gutter={32} justify={'center'}>
            <Col className='gutter-row'>
              <b><h3>หาค่างวด เช่าซื้อ ( เฉพาะ รถเก๋ง รถกระบะ และรถจักรยานยนต์ )</h3></b>
            </Col>
          </Row>
          <Row gutter={32}>
            <Col className='gutter-row' span={12}>
              <h2> <Form.Item
                label='วงเงินกู้' name="loanAmountt"
                rules={[
                  {
                    required: true,
                    message: 'Please input !',
                  },]}
              >
                <Input
                  placeholder={'กรอกยอด'}
                  disabled={checkBTN}
                  defaultValue={loanAmountt}
                  onChange={handleChangeAmount}
                  onKeyDown={(e) => (e.key === 'Enter' ? handleCalculate() : null)}
                />
              </Form.Item>
              </h2>
            </Col>
            <Col className='gutter-row' span={12}>
              <h2>
                <Form.Item
                  label='จำนวนงวด'
                  name='installmentCount'
                  rules={[
                    {
                      required: true,
                      message: 'Please input !',
                    },]}
                >
                  {cp3 ?
                    <>
                      <Select
                        placeholder={'เลือกจำนวนงวด' + cp2 + ' - ' + cp3}
                        style={{ height: '40px', color: 'black' }}
                        onChange={(value) => handleSelectChange(value)}
                        options={filteredOptions}
                      />
                    </>
                    :
                    <>
                      <Select
                        style={{
                          width: '100%',
                        }}
                        //value={installmentCount}
                        placeholder={'เลือก'}
                        disabled={checkBTN}
                        //onChange={handleChangeCount}
                        onChange={(value) => setInstallmentCount({ ...installmentCount, installmentAmountt: value })}
                        label="จำนวนงวด"
                        options={[
                          { label: '12', value: 12 }, { label: 18, value: 18 }, { label: '24', value: 24 },
                          { label: '30', value: 30 }, { label: 36, value: 36 }, { label: '42', value: 42 },
                          { label: '48', value: 48 }, { label: 54, value: 54 }, { label: '60', value: 60 },
                          { label: '66', value: 66 }, { label: 72, value: 72 }, { label: '78', value: 78 },
                          { label: '84', value: 84 }, { label: 90, value: 90 }, { label: '96', value: 96 },
                          { label: '102', value: 102 }, { label: 108, value: 108 }, { label: '114', value: 114 },
                          { label: '120', value: 120 }
                        ]}
                      >
                        {/* {installmentCountList.map((e) => (
                      <MenuItem key={e} value={e}>
                        {e}
                      </MenuItem>
                    ))} */}
                      </Select>
                    </>

                  }

                </Form.Item>
              </h2>
            </Col>
          </Row>
        </Form>
        <Row gutter={32} style={{ marginBottom: 0 }}>
          <Col className='gutter-row' span={12} style={{ margin: 0 }}>
            <Typography
              variant="subtitle1"
              component="h5"
            >
              {/* <b> ยอดกู้ : {currencyFormat(loanAmountt)}</b> */}
              <b> ยอดกู้ : {isNaN(loanAmountt) ? "-" : currencyFormat(loanAmountt)} </b>
            </Typography>
          </Col>
          <Col className='gutter-row' span={12}>
            <Typography
              variant="subtitle1"
              component="h5"
            >
              <b> ดอกเบี้ย : {currencyFormat(interestt)}</b>
            </Typography>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col className='gutter-row' span={24}>
            <Table
              style={{ textAlign: 'center' }}
              dataSource={dataSource}
              columns={column}
              scroll={{
                x: 700,
              }}

            />
          </Col>
        </Row>
      </Modal>
    </Card>
  )
}
