import React, { useState, useEffect } from 'react'
import { Modal, Form, Button, Input, Card, Space, Select, Row, Col, notification, message, Divider, InputNumber } from 'antd'
import axios from 'axios';
import dayjs from 'dayjs';
import { approvalEditPriceNew, findlandpn, shownotepn } from '../../file_mid/all_api';

export default function EditPrice({ open, close, dataFromTable }) {

    const us = localStorage.getItem('username')
    const currentTime = dayjs();
    const formattedTime = currentTime.format('YYYY-MM-DDTHH:mm:ssZ');

    const [dataModalEdit, setDataModalEdit] = useState(dataFromTable);
    const [newPrice, setNewPrice] = useState({});
    const [options, setOptions] = useState([]);
    const [multiData, setMultiData] = useState([]);
    const [dataSend, setDataSend] = useState([]);

    const [multiMode, setMultiMode] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [form] = Form.useForm()
    const [formAdd] = Form.useForm()

    useEffect(() => {
        loadDataNew()
        loadNoteName();
    }, [])

    useEffect(() => {
        pickData()

    }, [multiData])

    const pickData = () => {
        const newData = []
        const oldData = []
        multiData.map((data) => {
            var NewApproval = {
                customerId: data?.approval?.customerId,
                landId: data?.approval?.landId,
                landLoanId: data?.approval?.landLoanId,
                approvalDate: dayjs(),
                minPrice: parseInt(data?.approval?.minPrice),
                maxPrice: parseInt(data?.approval?.maxPrice),
                priceStatus: data?.approval?.priceStatus,
                inputPriceDate: dayjs(),
                proposalPriceBy: data?.approval?.proposalPriceBy,
                note: data?.approval?.note,
                mixNote: data?.approval?.mixNote,
                rejectDate: data?.approval?.rejectDate,
                rejectStatus: data?.approval?.rejectStatus
            }
            var oldApproval = {
                customerId: data?.approval?.customerId,
                landId: data?.approval?.landId,
                landLoanId: data?.approval?.landLoanId,
                oldMinPrice: parseInt(data?.approval?.minPrice),
                oldMaxPrice: parseInt(data?.approval?.maxPrice)
            }

            newData.push(NewApproval)
            oldData.push(oldApproval)

        })
        setDataSend({
            newPrice: newData,
            oldPrice: oldData
        })
        multiData.map((e, index) => {
            form.setFieldsValue({
                [`minPrice${index}`]: e?.approval?.minPrice,
                [`maxPrice${index}`]: e?.approval?.maxPrice,
                [`note${index}`]: e?.approval?.note,
            })
        })
    }

    console.log("dataSend", dataSend)

    const loadNoteName = async () => {
        await axios.get(shownotepn)
            .then(res => {
                if (res.status === 200) {
                    setOptions(res.data)
                }
            }).catch((err) => console.log(err))
    };

    const loadDataNew = async () => {
        await axios.post(findlandpn, { landLoanId: dataFromTable.landLoanId })
            .then(res => {
                if (res.status === 200) {
                    setMultiData(res.data)
                }
            }).catch((err) => {
                console.log("err", err)
            })
    }

    const handleCancel = () => {
        close(false)
    }

    const handleOk = async () => {
        var checkNumber = 0
        dataSend.newPrice.map((e) => {
            if (e.minPrice >= e.maxPrice) {
                checkNumber = 1
            }
        })
        if (checkNumber === 1) {
            alert("กรุณาตรวจสอบยอดที่อนุมัติอีกครั้ง")
        } else {
            console.log("dataSend", dataSend)
            await axios.post(approvalEditPriceNew, dataSend)
                .then(res => {
                    if (res.status === 200) {
                        message.success('บันทึกสำเร็จ');
                        close(false);
                    } else if (res.status === 204) {
                        // NotiErrorIMG("top")
                    }
                }).catch(err => {
                    console.log(err)
                })
        }
    }

    const submitConfirm = () => {
        Modal.confirm({
            title: 'บันทึกข้อมูล',
            onOk() {
                handleOk()
            },
            onCancel() {
                message.error('ยกเลิก');
            },
        });
    };

    const handleMaxPrice = (value, index) => {
        setDataSend(prevData => ({
            ...prevData,
            newPrice: prevData.newPrice.map((item, i) =>
                i === index ? { ...item, maxPrice: parseFloat(value) } : item
            )
        }));
    }

    const handleMinPrice = (value, index) => {
        setDataSend(prevData => ({
            ...prevData,
            newPrice: prevData.newPrice.map((item, i) =>
                i === index ? { ...item, minPrice: parseFloat(value) } : item
            )
        }));
    }

    const handleNoteMultimode = (value, index) => {
        setDataSend(prevData => ({
            ...prevData,
            newPrice: prevData.newPrice.map((item, i) =>
                i === index ? { ...item, note: `${value}` } : item
            )
        }));
    }

    const renderMultiLand = ({ item, index }) => {

        return (
            <>
                <Divider orientation='left'>เลขโฉนดที่ดิน ({item.numberLand})</Divider>
                <Col className='gutter-row' span={24} style={{ textAlign: 'center' }}>
                    <Form.Item label="หมายเหตุ" name={`note${index}`}>
                        <Select
                            mode="multiple"
                            style={{
                                width: '100%',
                            }}
                            size="large"
                            placeholder="เลือกหมายเหตุ"
                            onChange={(e) => handleNoteMultimode(e, index)}
                            options={options}
                        />
                    </Form.Item>
                </Col>
                <Col className='gutter-row' span={12} style={{ textAlign: 'center' }}>
                    <Form.Item label='ยอดขั้นต่ำ' name={`minPrice${index}`}
                        rules={[
                            {
                                required: true,
                                message: 'กรุณากรอกยอดที่อนุมัติขั้นต่ำ'
                            },
                        ]}>
                        <InputNumber
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            size="large"
                            style={{ width: '100%', color: 'black' }}
                            onChange={(value) => handleMinPrice(value, index)}
                        />
                    </Form.Item>
                </Col>
                <Col className='gutter-row' span={12} style={{ textAlign: 'center' }}>
                    <Form.Item label='ยอดสูงสุด' name={`maxPrice${index}`}
                        rules={[
                            {
                                required: true,
                                message: 'กรุณากรอกยอดที่อนุมัติสูงสุด'
                            },
                        ]}>
                        <InputNumber
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            size="large"
                            style={{ width: '100%', color: 'black' }}
                            onChange={(value) => handleMaxPrice(value, index)}
                        />
                    </Form.Item>
                </Col>
                <Divider />
            </>
        )
    }

    return (
        <div>
            <Modal title="แก้ไขยอด" open={open} onCancel={handleCancel} footer={[null]} width={800}>
                <Card>
                    <Form
                        onFinish={submitConfirm}
                        form={form}
                        labelCol={{
                            span: 6,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}>
                        <Row>
                            <Col span={24}>
                                <Row>
                                    {
                                        multiData?.map((item, index) => {
                                            return renderMultiLand({ item, index })
                                        })
                                    }
                                </Row>
                            </Col>
                        </Row>
                        <Divider />
                        <Row>
                            <Col span={24} >
                                <div style={{ textAlign: 'center' }}>
                                    <Space>
                                        <Button type='primary' key="back" danger onClick={handleCancel}>ปิด</Button>
                                        <Button type='primary' htmlType='submit' style={{ backgroundColor: "green" }}>บันทึก</Button>
                                    </Space>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </Modal>
            {contextHolder}
        </div>
    )
}
