import React, { useState, useEffect } from 'react'
import { Form, Card, Col, Row, Button, Divider, Spin, Modal, message } from 'antd';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';


import AddOcc from '../postData/ModalAddCareerIncome'
import EditOcc from '../editData/ModalEditCareerIncome'
import { addCareerIncome } from '../../../redux/CareerIncome'
import { deletecustomerocc, getcustomerocc } from '../../file_mid/all_api';


function CareerIncome({ page, close, datashow, changepage }) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { confirm } = Modal
    const { customers } = useSelector((state) => ({ ...state }))
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [occupation, setOccupation] = useState([]) // getdatabase occ
    const [sub, setSub] = useState([]) // getdatabase sub
    const [section, setSection] = useState([]) // getdatabase section
    const [cus, setCus] = useState({}) // customerId
    const [dataPost, setDataPost] = useState({ mainOccupation: true }) //เก็บอาชีพ
    const [money, setMoney] = useState({}) //เก็บรายได้
    const [expenses, setExpenses] = useState({}) //เก็บรายจ่าย
    const [getData, setGetData] = useState([]) //ดึงข้อมูลอาชีพ+รายได้ที่มีมา
    const [testM, setTestM] = useState(0.0)
    const [testY, setTestY] = useState(0.0)
    const [testResult, setTestResult] = useState(0.0)
    const [dataCareerIncome, setCareerIncome] = useState([])
    const [dataedit, setDataEdit] = useState([]); //เซตสำหรับแก้ไขข้อมูล 
    const [index1, setIndex] = useState(); //set index send to page edit

    const [modalAddOcc, setModalAddOcc] = useState(false)
    const [modaleditdata, setModalEditData] = useState(false)


    useEffect(() => {
        setCus({ customerId: customers.data })
        loadData()
    }, [])

    //buuton Previos
    const handlePrevios = (e) => {
        navigate('/addcustomer/contract')
    }
    //buuton Next
    const handleNext = (e) => {
        dispatch(addCareerIncome({ ...dataCareerIncome }))
        navigate('/addcustomer/products')
    }
    const handleCancel = () => {
        close(false)
    }
    const error = () => {
        Modal.error({
            title: 'บันทึกไม่สำเร็จ',
            content: 'กรุณาตรวจสอบข้อมูลอีกครั้ง',
        })
    }
    const successDelete = () => {
        Modal.success({
            title: 'สำเร็จ',
            content: 'ลบข้อมูลสำเร็จ',
        })
    }
    const errorDelete = () => {
        Modal.error({
            title: 'ไม่สำเร็จ',
            content: 'ลบข้อมูลไม่สำเร็จ กรุณาตรวจสอบข้อมูลอีกครั้ง',
        })
    }


    const fucnAdd = (value) => {
        console.log("value", value)
        setCareerIncome(pre => [...pre, { ...value.req.ojs, ...value.req.income, ...value.req.expenses }]);

    }
    const fucnEdit = ({ getData, getIndex }) => {
        console.log("getdata", getData)
        console.log("getIndex", getIndex)
        let Theedit = [...dataCareerIncome]
        Theedit[getIndex] = getData
        //ข้อมูลใหม่แทนที่ข้อมูลเดิมในช่อง Input
        setCareerIncome(Theedit);
    }

    const loadData = async () => {
        const req = { customerId: customers.data.customerId }
        console.log("req", req)
        setLoading(true)
        await axios.post(getcustomerocc, req)
            .then((res) => {
                console.log("res =", res.data)
                if (res.data.length !== 0) {
                    setCareerIncome(res.data)
                    form.setFieldsValue(
                        {
                            occupationalGroup: res?.data?.occupationalGroup,
                            governmentPrivate: res?.data?.governmentPrivate,
                            jobPosition: res?.data?.jobPosition,
                            companyName: res?.data?.companyName,
                            occupationDescription: res?.data?.occupationDescription,
                            mainOccupation: res?.data?.mainOccupation,
                            incomeMonth: res?.data?.incomeMonth,
                            incomeMainDescription: res?.data?.incomeMainDescription,
                            expensesMonth: res?.data?.expensesMonth,
                            expensesMonthDescription: res?.data?.expensesMonthDescription
                        }
                    )
                    setLoading(false)
                }
            })
            .catch((err) => console.log(err))
        setLoading(false)
    }

    const deleteData = async (value) => {

        console.log("TY", value)
        const test2 = value
        setLoading(true)
        await axios.delete(deletecustomerocc, { data: test2, })
            .then((res) => {
                if (res.status === 200) {
                    //notification5('top')
                    setLoading(false)
                    successDelete()
                }
                else {
                    errorDelete()
                }
            }).catch((err) => errorDelete())
        setLoading(false)
    }

    const showConfirm = (value, index) => {
        console.log("value =", value)
        console.log("index =", index)
        confirm({
            title: 'คุณต้องการที่จะลบข้อมูลหรือไม่...?',
            content: 'กด OK เพื่อยืนยันการลบ',
            onOk() {
                const deletearray = [...dataCareerIncome]
                deletearray.splice(index, 1)
                setCareerIncome(deletearray)
                deleteData(value)
            },
            onCancel() {
                message.error('ยกเลิกการลบข้อมูล')
                // console.log('Cancel');
            },
        });
    };

    const renderItem = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
        // index ตอนนี้เป็นข้อมูลจาก Object
        const handleEdit = (dataedit, index1) => {
            setIndex(index1) //เซตค่า index ให้กับชุด ข้อมูล
            setDataEdit({ dataedit, index1 }) //ส่งข้อมูลด index ไปหน้าแก้ไข
            setModalEditData(true);
        }

        return (
            <>
                <Row justify={'center'} gutter={32}>
                    <Col span={24} className='gutter-row' style={{ margin: 5 }}>
                        <Form
                            name='basic'
                            labelCol={{
                                span: 12,
                            }}
                            wrapperCol={{
                                span: 24,
                            }}
                            style={{
                                maxWidth: '100%',
                            }}
                            autoComplete="off"
                            initialValues={{ remember: true }}
                            form={form}
                        >
                            <Card
                                key={index} // index เป็นตัวกำหนดคีย์
                                title={item.occupationalGroup}
                                style={{ width: '350px', textAlign: 'center' }}
                            >
                                <Row gutter={32} justify={'center'}>
                                    <Col className='gutter-row' span={24} style={{ textAlign: 'left' }} >
                                        <Form.Item style={{ marginBottom: 0 }} label='กลุ่มอาชีพ' >
                                            <b>{item.occupationalGroup}</b>
                                        </Form.Item>
                                        <Form.Item style={{ marginBottom: 0 }} label='เลือกอาชีพ'>
                                            <b>{item.governmentPrivate}</b>
                                        </Form.Item>
                                        <Form.Item style={{ marginBottom: 0 }} label='สาขาอาชีพ'>
                                            <b>{item.jobPosition}</b>
                                        </Form.Item>
                                        <Form.Item style={{ marginBottom: 0 }} label='ชื่อสถานที่ทำงาน'>
                                            <b>{item.companyName}</b>
                                        </Form.Item>
                                        <Form.Item style={{ marginBottom: 0 }} label='หมายเหตุอาชีพ'>
                                            <b>{item.occupationDescription}</b>
                                        </Form.Item>
                                        <Form.Item style={{ marginBottom: 0 }} label='รายได้ต่อเดือน'>
                                            <b>{item.incomeMonth}</b>
                                        </Form.Item>
                                        <Form.Item style={{ marginBottom: 0 }} label='อื่นๆ'>
                                            <b>{item.note}</b>
                                        </Form.Item>
                                        <Form.Item style={{ marginBottom: 0 }} label='หมายเหตุรายได้'>
                                            <b>{item.incomeMainDescription}</b>
                                        </Form.Item>
                                        <Form.Item style={{ marginBottom: 0 }} label='ค่าใช้จ่ายต่อ / เดือน'>
                                            <b>{item.expensesMonth}</b>
                                        </Form.Item>
                                        <Form.Item style={{ marginBottom: 0 }} label='หมายเหตุรายจ่าย'>
                                            <b>{item.expensesMonthDescription}</b>
                                        </Form.Item>
                                    </Col>
                                    <Divider></Divider>

                                    <Row justify={'center'} gutter={32}>
                                        <Col className='gutter-row' span={12} style={{ textAlign: 'right' }}>

                                            <Button
                                                style={{ margin: '10px', background: '#f5222d', color: 'white', marginBottom: 0 }}
                                                icon={<DeleteOutlined />}
                                                onClick={() => showConfirm(dataCareerIncome[index], index)}
                                            >ลบ</Button>
                                        </Col>
                                        <Col className='gutter-row' span={12} style={{ textAlign: 'left' }}>
                                            <Button
                                                style={{ margin: '10px', background: '#bfbfbf', color: '#000000', marginBottom: 0 }}
                                                type='submit'
                                                onClick={() => { handleEdit(dataCareerIncome[index], index); }}
                                            >แก้ไข</Button>
                                        </Col>
                                    </Row>
                                </Row>
                            </Card>

                        </Form>
                    </Col>
                </Row>
            </>
        )
    }

    return (
        <>
            <Row justify={'center'}>
                <Card style={{ width: '100%' }}>
                    <Spin spinning={loading} size='large' tip="Loading...">
                        <Row justify={'center'}>
                            <Col className='gutter-row' span={24} style={{ fontSize: '25px', textAlign: 'center' }}>
                                <b>รายละเอียดอาชีพและรายได้</b>
                            </Col>
                        </Row>
                        <Form
                            labelCol={{
                                span: 8,
                            }}
                            wrapperCol={{
                                span: 16,
                            }}
                            style={{
                                maxWidth: '100%',
                            }}
                            autoComplete="off"
                            initialValues={{ remember: true }}
                            form={form}
                        >
                            <Row gutter={32}>
                                <Col className='gutter-row' span={24}>
                                    <Divider />
                                    <Row gutter={32}>
                                        <Col span={24} style={{ textAlign: 'right' }}>
                                            {/* ปุ่มเพิ่มข้อมูล */}
                                            <Button type='primary'
                                                onClick={() => { setModalAddOcc(true); }}
                                            >
                                                เพิ่มข้อมูลที่อยู่</Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row gutter={32} justify={'center'}>
                                {
                                    dataCareerIncome.map((item, index) => {
                                        return renderItem({ item, index })
                                    })
                                }
                            </Row>
                            <Divider style={{ margin: 5 }} />
                            <Row gutter={32} justify={'center'}>
                                <Col span={12} className='gutter-row' style={{ textAlign: 'left' }}>
                                    <Button
                                        type="primary"
                                        style={{ margin: '3px', marginBottom: 0, background: '#bfbfbf', color: '#000000', }}
                                        onClick={handlePrevios}>
                                        กลับ
                                    </Button>
                                </Col>
                                <Col span={12} className='gutter-row' style={{ textAlign: 'right' }}>
                                    <Button
                                        type="primary"
                                        style={{ margin: '3px', marginBottom: 0 }}
                                        onClick={handleNext}>
                                        ต่อไป
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Spin>
                </Card>
                {
                    //เพื่มข้อมูลในการ์ด
                    modalAddOcc ?
                        <AddOcc open={modalAddOcc} close={setModalAddOcc} fucnAdd={fucnAdd} />
                        : null
                }
                {
                    //แก้ไขข้อมูลในการ์ด
                    modaleditdata ?
                        <EditOcc
                            open={modaleditdata} // เซตการเปิด Modal จากหน้า AddressEditData
                            close={setModalEditData} // เซตการปิด Modal จากหน้า AddressEditData
                            shootdata={dataedit} // 
                            fucnEdit={fucnEdit}
                            dataindex={index1}
                        />
                        : null
                }
            </Row>
        </>
    )
};
export default CareerIncome
