import React, { useState, useEffect } from "react";
import axios from "axios";
import { Row, Col, Button, Typography, Card, Form, Input, Select, Modal } from "antd";
import { useDispatch } from "react-redux";
import { addRegister } from "../redux/User";
import { Link, useNavigate } from 'react-router-dom';
import { GetBranch, registers } from "./file_mid/all_api";

const { Title } = Typography;

export default function SignUp() {
  const { Option } = Select;
  const token = localStorage.getItem('token')
  const menu = localStorage.getItem('menu')
  const tk = JSON.parse(token)
  const { Text } = Typography;
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [register, setRegister] = useState({})
  const [getBranch, setGetBranch] = useState([])

  useEffect(() => {
    loadGetBranch()
  }, [])

  const loadGetBranch = async () => {
    setLoading(true)
    await axios.get(GetBranch)
      .then((res) => {
        setGetBranch(res.data)
        setLoading(false)
      }).catch((err) => {
        console.error(err);
      })
    setLoading(false)
  }
  const success = () => {
    Modal.success({
      title: 'Success',
      content: 'สำเร็จ',
    })
  }
  const error = () => {
    Modal.error({
      title: 'Error',
      content: "ไม่สำเร็จ กรุณาตรวจสอบ username และ password",
    })
  }

  const onFinish = async () => {
    //console.log("register", register)
    setLoading(true)
    const headers = {
      "Authorization": `Bearer ${tk}`,
      "Menu": JSON.stringify("14")
    }
    await axios.post(registers, register, { headers: headers })
      .then((res) => {
        if (res.data.status == "success") {
          success()
          navigate('/')
          //console.log("DATA",res.data)
          //dispatch(addRegister(res.data))
          setLoading(false)
        }
        else {
          error()
          console.log("error", "ไม่สำเร็จ กรุณาตรวจสอบ username และ password")
        }
      })
      .catch((err) => error())
    setLoading(false)
  };

  const handleBranch = async (value, key) => {
    //console.log("value",value)
    setRegister({ ...register, branch: value })
  }


  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };
  return (
    <>
      <Card style={{ width: '100%' }}>
        <Form
          labelCol={{
            span: 12,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Row justify={'center'}>
            <Col style={{ width: '100%' }}>
              <div className='main' style={{ padding: '15px' }}>

                <div>
                  <Form.Item style={{ textAlign: "center" }}>
                    <b style={{ textAlign: 'center', color: '#002766', fontSize: '30px' }}>
                      <u>สมัครสมาชิก</u>
                    </b>
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    name="username"
                    rules={[
                      { required: true, message: "Please input your username!" },
                    ]}
                  >
                    <Input placeholder="Username" onChange={(e) => { setRegister({ ...register, username: e.target.value }) }} />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item

                    name="password"
                    rules={[
                      { required: true, message: "Please input your password!" },
                    ]}
                  >
                    <Input.Password placeholder="Passwoed" onChange={(e) => { setRegister({ ...register, password: e.target.value }) }} />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    name="branch"
                    rules={[
                      { required: true, message: "Please input!" },
                    ]}
                  >
                    <Select
                      placeholder={"เลือกสาขา"}
                      onChange={handleBranch}
                    >
                      {getBranch?.map((bh, index) => (
                        <Option key={bh.idBranch} value={bh.branch}>
                          {bh.branch}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div>
                  <Form.Item>
                    <Button style={{ width: "100%" }} type="primary" htmlType="submit">
                      <b style={{ textAlign: 'center', color: '#ffffff', fontSize: '18px' }}>
                        สมัคร
                      </b>
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </Card >

    </>
  );
}

