import React, { useEffect, useState } from 'react'
import { Modal, Col, Row, Space, notification, Steps, theme, Image, Button, Spin, message, Upload, Form, Divider } from 'antd';
// import axios from 'axios';
// import dayjs from 'dayjs';

import { useDispatch, useSelector } from 'react-redux';
import { addCustomer } from '../../../../redux/Customer';
import { addPhone } from '../../../../redux/Phone';
import { addAddress } from '../../../../redux/Address';
import { addCar } from '../../../../redux/Car';
import { addImage } from '../../../../redux/Image';



import EditCustomerGua from './EditCustomerGua';
import EditAddressGua from './EditAddressGua';
import EditPhoneGua from './EditPhoneGua';
import EditDetailGua from './EditDetailGua';

export default function ModalEditGuarantor({ open, close, dataindex, shootdata, infor, getimages, setSendback , indexedit}) {
    // const customers = useSelector(state => state.customers)
    // const cars = useSelector(state => state.cars)

    const [loading, setLoading] = useState(false);

    const [theData, setTheData] = useState(shootdata.dataedit);
    const [index, setIndex] = useState(shootdata.index1);
    // const [saveCusIdMain] = useState(customers.data);
    // const [saveCarId] = useState(cars.data);

    const [showImage, setShowImage] = useState([]);
    const [foldernum, setFoldernum] = useState();

    const [api, contextHolder] = notification.useNotification();
    const dispatch = useDispatch()
    const [current, setCurrent] = useState(0);
    const { confirm } = Modal

    useEffect(() => {
        setData()
        mapImg()
    }, [])

    // console.log("indexedit", indexedit)

    const mapImg = () => {
        {
            const folder = []
            const split = theData?.pathImg.split('/')[4];
            setFoldernum(split)
            getimages?.map((item, index) => {
                const segments = item.pathImage.split("/")[4]
                // console.log("segments", segments)
                var image = {}
                if (item?.pathImage.split('/')[4] === split) {
                    image.attr = item.attr
                    image.data = item.data
                    image.idImage = item.idImage
                    image.pathImage = item.pathImage
                    folder.push(image)
                }
            })
            // console.log("folder", folder)
            setShowImage(folder)
        }
    }

    const setData = async () => {
        dispatch(addCustomer(theData))
        dispatch(addAddress(theData.address))
        dispatch(addPhone(theData.phones))
    };

    const next = () => {
        setCurrent(current + 1);
    };
    const prev = () => {
        setCurrent(current - 1);
    };


    const InsertSuccess = (placement) => {
        api.success({
            message: <b>เพิ่มข้อมูลสำเร็จ</b>,
            placement,
        });
    }
    const EditSuccess = (placement) => {
        api.success({
            message: <b>แก้ไขข้อมูลสำเร็จ</b>,
            placement,
        });
    }
    const DeleteSuccess = (placement) => {
        api.success({
            message: <b>ลบข้อมูลสำเร็จ</b>,
            placement,
        });
    }

    const CheckAddressER = (placement) => {
        api.error({
            message: <b>เพิ่มข้อมูลไม่สำเร็จ</b>,
            description: 'กรุณาเพิ่มที่อยู่ อย่างน้อย 1 ที่',
            placement,
        });
    }
    const CheckPhoneER = (placement) => {
        api.error({
            message: <b>เพิ่มข้อมูลไม่สำเร็จ</b>,
            description: 'กรุณาเพิ่มเบอร์โทรศัพท์ อย่างน้อย 1 เบอร์',
            placement,
        });
    }

    const CheckDesER = (placement) => {
        api.error({
            message: <b>เพิ่มข้อมูลไม่สำเร็จ</b>,
            description: 'กรุณาเพิ่มความสัมพันธ์กับผู้กู้',
            placement,
        });
    }

    const showConfirm = () => {
        confirm({
            title: 'คุณต้องการที่ปิดหน้าต่างข้อมูลผู้กู้หรือไม่...?',
            content: 'หากกดปิดหน้าต่างผู้กู้ข้อมูลจะไม่ถูกบันทึก กด OK เพื่อยืนยัน',
            onOk() {
                Onclose();
            },
            onCancel() {
            },
        });
    };

    const onChange = (value) => {
        console.log('onChange:', value);
        setCurrent(value);
    };

    const Onclose = () => {
        close(false);
        dispatch(addCustomer())
        dispatch(addCar())
        dispatch(addAddress())
        dispatch(addPhone())
        dispatch(addImage())
    };

    // console.log("current", current)

    return (
        <Modal title="แก้ไขข้อมูล" open={open} onCancel={showConfirm} width={1200} footer={null} >
            <Spin spinning={loading} size='large' tip=" Loading... ">
                <Space direction="vertical" size="middle" style={{ display: 'flex' }}>

                    <Row>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <Steps
                                current={current}
                                onChange={onChange}
                                items={[
                                    {
                                        title: 'ข้อมูลส่วนตัว',
                                    },
                                    {
                                        title: 'รายละเอียดที่อยู่',
                                    },
                                    {
                                        title: 'ช่องทางการติดต่อ',
                                    },
                                    {
                                        title: 'สรุปรายละเอียด/บันทึกข้อมูล',
                                    },
                                ]}
                            />
                        </Col>
                    </Row>
                    {current === 0 && (<EditCustomerGua close={showConfirm} theData={theData} infor={infor} index={index} next={next} showImage={showImage} getimages={getimages} setSendback={setSendback} indexedit={indexedit} />)}
                    {current === 1 && (<EditAddressGua close={showConfirm} theData={theData} infor={infor} index={index} n1={InsertSuccess} e1={EditSuccess} d1={DeleteSuccess} next={next} prev={prev} cha={CheckAddressER} />)}
                    {current === 2 && (<EditPhoneGua close={showConfirm} theData={theData} infor={infor} index={index} n1={InsertSuccess} e1={EditSuccess} d1={DeleteSuccess} next={next} prev={prev} chp={CheckPhoneER} />)}
                    {current === 3 && (<EditDetailGua close={showConfirm} theData={theData} getimages={getimages} shootdata={shootdata} infor={infor} index={index} n1={InsertSuccess} e1={EditSuccess} d1={DeleteSuccess} prev={prev} saveclose={Onclose} cha={CheckAddressER} chp={CheckPhoneER} chd={CheckDesER} />)}
                </Space>
                {contextHolder}
            </Spin>
        </Modal >

    )
};
