import React, { useState, useEffect } from 'react'
import { Form, Modal, Row, Spin, Space, Button, Divider, Col, Card, Tag, Select } from 'antd'
import axios from 'axios';
// import dayjs from "dayjs";
import { useNavigate } from 'react-router-dom';
import { alldataland1, getcontnoJojonoi, loadMKActive, confirmMKContLeaving } from '../../file_mid/all_api';

export default function ModalEditMK({ open, close, data }) {
    // const { confirm } = Modal
    const navigate = useNavigate()
    const [form] = Form.useForm();
    const token = localStorage.getItem('token');
    const username = localStorage.getItem('username');
    const [loading, setLoading] = useState(false)
    const [allData, setAllData] = useState()
    const [dataMk, setDataMk] = useState([])
    const [dataPost, setDataPost] = useState()

    useEffect(() => {
        console.log("ModalEditMK", data)
        if (data.PRODUCTS === "car") {
            loadDataContno()
        } else {
            loadDataContnoLand()
        }
    }, [])

    const loadDataContno = async () => {
        setLoading(true)
        await axios.get(`${getcontnoJojonoi}/${data?.CONTNO}`)
            .then(async res => {
                if (res.data) {
                    // console.log("loadDataContno", res.data)
                    await loadDataMK()
                    setAllData(res.data)
                    setLoading(false)
                }
            }).catch((err) => {
                console.log("err", err)
            })
        setLoading(false)
    }

    const loadDataContnoLand = async () => {
        setLoading(true)
        await axios.post(alldataland1, { CONTNO: data.CONTNO })
            .then(async res => {
                if (res.data) {
                    // console.log("loadDataContnoLand", res.data)
                    await loadDataMK()
                    setAllData(res.data)
                    setLoading(false)
                }
            }).catch((err) => {
                console.log("err", err)
            })
        setLoading(false)
    }

    const loadDataMK = async () => {
        await axios.get(loadMKActive)
            // await axios.get("http://localhost:8080/group/selectMkActive")
            .then(res => {
                if (res.data) {
                    console.log("data mk", res.data)
                    setDataMk(res.data)
                }
            }).catch((err) => {
                console.log("err", err)
            })
    }

    const success = () => {
        setLoading(false)
        close(false);
        Modal.success({
            content: 'บันทึกรายการสำเร็จ',
            path: navigate('/checkInfoPGRECont')
        })
    }

    const onModelChange = (value) => {
        console.log("value", value)
        setDataPost({ ...dataPost, newMK: value })
    }

    const handleSubmit = async () => {
        setLoading(true)
        if (dataPost?.newMK) {
            var mixData = {
                DATA_TYPE: data.DATA_TYPE,
                inputBy: username,
                oldMK: data.SALCOD,
                newMK: dataPost?.newMK,
                contno: data.CONTNO
            }
            console.log("mixData", mixData)
            await axios.post(confirmMKContLeaving, mixData)
                // await axios.post('http://localhost:8070/api-db2/selectContnoMK', mixData)
                .then((res) => {
                    console.log("OK", res)
                    success()
                })
                .catch((err) => console.log(err))
        } else {
            console.log("กรุณาเลือกการตลาด")
        }
        setLoading(false)
    };

    const currencyFormatOne = (amount) => {
        if (amount) {
            return Number(amount)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, '$&,')
        } else {
            return "..."
        }
    }

    const Onclose = () => {
        close(false);

    };

    return (
        <div>
            <Modal open={open} onCancel={Onclose} width={1100} footer={null} >
                <Spin tip="Loading..." spinning={loading} >
                    <Divider orientation={'center'} style={{ fontSize: '25px' }}><b><u>รายละเอียด</u></b></Divider>
                    <Row justify={'center'}>
                        <Form
                            labelCol={{
                                span: 12,
                            }}
                            wrapperCol={{
                                span: 24,
                            }}
                            style={{
                                width: '90%',
                            }}
                            form={form}
                            onFinish={handleSubmit}
                            autoComplete="off"
                            initialValues={{ remember: true }}
                        >
                            <div className='main'>
                                <Card style={{ width: '100%' }}>
                                    <Row justify={'center'}>
                                        <Col span={12} style={{ textAlign: 'left' }}>
                                            <b>
                                                <div style={{ marginBottom: 0 }}>
                                                    <span>เลขสัญญา : </span>{<b style={{ fontSize: '20px', lineHeight: '32px' }}>{data?.CONTNO}</b>}
                                                </div>
                                            </b>
                                            <b>
                                                <div style={{ marginBottom: 0 }}>
                                                    <span>เกรด : </span>{<b style={{ fontSize: '20px', lineHeight: '32px' }}>{data?.GRDCOD}</b>}
                                                </div>
                                            </b>
                                        </Col>
                                        <Col span={12} style={{ textAlign: 'right' }}>
                                            <div style={{ margin: 0 }}>
                                                ชื่อการตลาด : <Tag color="geekblue">{data?.SALCOD}</Tag>
                                            </div>
                                        </Col>
                                    </Row>
                                    {
                                        data?.PRODUCTS === "car" ?
                                            <>
                                                <Divider orientation={'left'}><b><u>รายละเอียดผู้ขอกู้</u></b></Divider>
                                                <Row gutter={32} justify={'center'}>
                                                    <aside style={{ width: '90%' }}>
                                                        <div style={{ marginBottom: 0, }}>
                                                            <span>ชื่อ - นามสกุล : </span>
                                                            <b>{allData?.custmast[0]?.SNAM + '   ' + allData?.custmast[0]?.NAME1 + '   ' + allData?.custmast[0]?.NAME2}</b>
                                                        </div>
                                                        <div style={{ marginBottom: 0, }}>
                                                            <span>เบอร์โทรติดต่อ : </span>
                                                            <b>{allData?.custmast[0]?.TELP}</b>
                                                        </div>
                                                    </aside>
                                                </Row>

                                                <Divider orientation={'left'}><b><u>รายละเอียดสินค้า</u></b></Divider>
                                                <Row gutter={32} justify={'center'}>
                                                    <aside style={{ width: '90%' }}>
                                                        <div style={{ marginBottom: 0, }}>
                                                            <span>ยี่ห้อ : </span>
                                                            <b>{allData?.custmast[0]?.TYPE}</b>
                                                        </div>
                                                        <div style={{ marginBottom: 0, }}>
                                                            <span>รุ่น : </span>
                                                            <b>{allData?.custmast[0]?.MODEL}</b>
                                                        </div>
                                                        <div style={{ marginBottom: 0, }}>
                                                            <span>แบบ : </span>
                                                            <b>{allData?.custmast[0]?.BAAB}</b>
                                                        </div>
                                                        <div style={{ marginBottom: 0, }}>
                                                            <span>เลขทะเบียน : </span>
                                                            <b>{allData?.custmast[0]?.REGNO} {allData?.custmast[0]?.DORECV}</b>
                                                        </div>
                                                        <div style={{ marginBottom: 0, }}>
                                                            <span>สี : </span>
                                                            <b>{allData?.custmast[0]?.COLOR}</b>
                                                        </div>
                                                        <div style={{ marginBottom: 0, }}>
                                                            <span>เลขเครื่อง : </span>
                                                            <b>{allData?.custmast[0]?.STRNO}</b>
                                                        </div>
                                                        <div style={{ marginBottom: 0, }}>
                                                            <span>เลขตัวถัง : </span>
                                                            <b>{allData?.custmast[0]?.ENGNO}</b>
                                                        </div>
                                                        <div style={{ marginBottom: 0, }}>
                                                            <span>ปีรถ : </span>
                                                            <b>{allData?.custmast[0]?.MANUYR}</b>
                                                        </div>
                                                    </aside>
                                                </Row>

                                                <Divider orientation={'left'}><b><u>รายละเอียดยอดจัด</u></b></Divider>
                                                <Row gutter={32} justify={'center'}>
                                                    <aside style={{ width: '90%' }}>
                                                        <div style={{ marginBottom: 0, }}>
                                                            <span>ค่างวด : </span>
                                                            <b>{currencyFormatOne(allData?.bu1[0]?.DAMT)} บาท</b>
                                                        </div>
                                                        <div style={{ marginBottom: 0, }}>
                                                            <span>ราคาเช่าซื้อครั้งที่แล้ว : </span>
                                                            <b>{currencyFormatOne(allData?.bu1[0]?.balanc)} บาท</b>
                                                        </div>
                                                        <div style={{ marginBottom: 0, }}>
                                                            <span>จำนวนงวดทั้งหมด : </span>
                                                            <b>{currencyFormatOne(allData?.bu1[0]?.nopay)} งวด</b>
                                                        </div>
                                                        <div style={{ marginBottom: 0, }}>
                                                            <span>ยอดที่ชำระมาแล้วทั้งหมด : </span>
                                                            <b>{currencyFormatOne(data?.SMPAY)} บาท</b>
                                                        </div>
                                                    </aside>
                                                </Row>
                                            </>
                                            :
                                            <>
                                                <Divider orientation={'left'}><b><u>รายละเอียดผู้ขอกู้</u></b></Divider>
                                                <Row gutter={32} justify={'center'}>
                                                    <aside style={{ width: '90%' }}>
                                                        <div style={{ marginBottom: 0, }}>
                                                            <span>ชื่อ - นามสกุล : </span>
                                                            <b>{allData?.Data1[0]?.SNAM + '   ' + allData?.Data1[0]?.NAME1 + '   ' + allData?.Data1[0]?.NAME2}</b>
                                                        </div>
                                                        <div style={{ marginBottom: 0, }}>
                                                            <span>เบอร์โทรติดต่อ : </span>
                                                            <b>{allData?.Data1[0]?.TELP}</b>
                                                        </div>
                                                    </aside>
                                                </Row>

                                                <Divider orientation={'left'}><b><u>รายละเอียดสินค้า</u></b></Divider>
                                                <Row gutter={32} justify={'center'}>
                                                    <aside style={{ width: '90%' }}>
                                                        <div style={{ marginBottom: 0, }}>
                                                            <span>ประเภทเอกสาร : </span>
                                                            <b>{allData?.Data1[0]?.productTypeLand === "โฉนด" ? "นส.4 จ." : "นส.3 ก."}</b>
                                                        </div>
                                                        <div style={{ marginBottom: 0, }}>
                                                            <span>จังหวัด : </span>
                                                            <b>{allData?.Data1[0]?.provinces}</b>
                                                        </div>
                                                        <div style={{ marginBottom: 0, }}>
                                                            <span>อำเภอ : </span>
                                                            <b>{allData?.Data1[0]?.district}</b>
                                                        </div>
                                                        <div style={{ marginBottom: 0, }}>
                                                            <span>เลขโฉนดที่ดิน : </span>
                                                            <b>{allData?.Data1[0]?.numberLand}</b>
                                                        </div>
                                                        <div style={{ marginBottom: 0, }}>
                                                            <span>ระวาง : </span>
                                                            <b>{allData?.Data1[0]?.numberLandlawang}</b>
                                                        </div>
                                                    </aside>
                                                </Row>

                                                <Divider orientation={'left'}><b><u>รายละเอียดยอดจัด</u></b></Divider>
                                                <Row gutter={32} justify={'center'}>
                                                    <aside style={{ width: '90%' }}>
                                                        <div style={{ marginBottom: 0, }}>
                                                            <span>ค่างวด : </span>
                                                            <b>{currencyFormatOne(allData?.Data2?.DAMT)} บาท</b>
                                                        </div>
                                                        <div style={{ marginBottom: 0, }}>
                                                            <span>ราคาเช่าซื้อครั้งที่แล้ว : </span>
                                                            <b>{currencyFormatOne(allData?.Data2?.balanc)} บาท</b>
                                                        </div>
                                                        <div style={{ marginBottom: 0, }}>
                                                            <span>จำนวนงวดทั้งหมด : </span>
                                                            <b>{currencyFormatOne(allData?.Data2?.nopays)} งวด</b>
                                                        </div>
                                                        <div style={{ marginBottom: 0, }}>
                                                            <span>ยอดที่ชำระมาแล้วทั้งหมด : </span>
                                                            <b>{currencyFormatOne(data?.SMPAY)} บาท</b>
                                                        </div>
                                                    </aside>
                                                </Row>
                                            </>
                                    }
                                    <Divider />
                                    <Card style={{ width: '100%' }}>
                                        <Row justify={'center'}>
                                            <Divider orientation={'left'}><b><u>เลือกการตลาด</u></b></Divider>
                                            <Select
                                                showSearch
                                                style={{ width: '400px', height: '40px' }}
                                                onChange={onModelChange}
                                                placeholder="Search to Select"
                                                optionFilterProp="children"
                                            // filterOption={
                                            //     (inputValue, option) =>
                                            //         option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                                            // }
                                            >
                                                {dataMk?.map((e) => {
                                                    return (
                                                        <Select.Option style={{ width: '400px' }} key={e.id} value={e.username}>
                                                            {e.username} {e.name} {e.lastname} {e.nickname} {e.branch}
                                                        </Select.Option>
                                                    )
                                                })}
                                            </Select>
                                        </Row>
                                    </Card>
                                    <Divider />
                                    <Row justify={'center'}>
                                        <Space>
                                            <Button type="primary" style={{ background: "red" }} onClick={Onclose}>
                                                ออก
                                            </Button>
                                            {
                                                loading === false ?
                                                    <>
                                                        <Button type="text" htmlType="submit" style={{ background: '#389e0d', color: '#ffffff' }} >
                                                            บันทึก
                                                        </Button>
                                                    </>
                                                    :
                                                    <>
                                                        <Button type='primary' htmlType="submit" disabled
                                                            style={{ color: 'success' }}
                                                        >
                                                            บันทึก
                                                        </Button>
                                                    </>
                                            }
                                        </Space>
                                    </Row>
                                </Card>
                            </div>
                        </Form>
                    </Row>
                </Spin>
            </Modal>
        </div >
    )
}
