import { createSlice } from "@reduxjs/toolkit";

const initialState = {

}

const phoneDataSlice = createSlice ({
    name: "phone",
    initialState,
    reducers: {
        
        addPhone: (state, action) => {
            state.data = action.payload
            // console.log(current(state))
        },
        
    }
    
})

export const { addPhone } = phoneDataSlice.actions
export default phoneDataSlice.reducer