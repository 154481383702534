let branch = localStorage.getItem('branch')
// let branchs = JSON.parse(branch)

// console.log("locatA22",locatA)
let dotaLocat = [
  {
    value: branch,
    label: branch,
  },
];



export default dotaLocat;