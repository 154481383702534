import React, { useState, useEffect } from 'react'
import { Form, Modal, Row, Spin, Space, Button, Divider, Col, Card, Tag, message, Image, Checkbox, notification } from 'antd'
import axios from 'axios';
import dayjs from "dayjs";
import { getAllFolderGuaImg, getImagess, loadDataReLand, confirmreland } from '../../file_mid/all_api';
import TextArea from 'antd/es/input/TextArea';

function DetailLand({ page, changepage, close, data, allData, closeAll }) {
  const token = localStorage.getItem('token');
  const branch = localStorage.getItem('branch');
  const { confirm } = Modal
  const [form] = Form.useForm();
  const addLands = localStorage.getItem('addLand')
  const addOccupation = localStorage.getItem('addOccupation')
  const addAddress = localStorage.getItem('addAddress')
  const addLandLoanDetailsRes = localStorage.getItem('addLandLoanDetailsRe')
  const addCustomers = localStorage.getItem('addCustomer')
  const addCustomer2 = localStorage.getItem('addCustomer2')
  const addGuarantors = localStorage.getItem('addGuarantor')
  const addLoans = localStorage.getItem('addLoan')
  const addPhones = localStorage.getItem('addPhone')
  const addPhones2 = localStorage.getItem('addPhone2')
  const addCheckGuas = localStorage.getItem('addCheckGua')
  const dataAddLands = JSON.parse(addLands)
  const dataAddLandLoanDetailsRes = JSON.parse(addLandLoanDetailsRes)
  const dataAddAddress = JSON.parse(addAddress)
  const dataAddCustomers = JSON.parse(addCustomers)
  const dataAddCustomers2 = JSON.parse(addCustomer2)
  const dataOccupation = JSON.parse(addOccupation)
  const dataAddGuarantor = JSON.parse(addGuarantors)
  const dataAddLoans = JSON.parse(addLoans)
  const dataAddPhones = JSON.parse(addPhones)
  const dataAddPhones2 = JSON.parse(addPhones2)
  const dataAddCheckGuas = JSON.parse(addCheckGuas)
  const [getimages, setGetImages] = useState([]);
  const [loading, setLoading] = useState(false)
  const [showImg, setShowImg] = useState([]) // โชว์รูป
  const [DATAGUA, setDATAGUA] = useState([])
  const [DATAPHONE, setDATAPHONE] = useState([])
  const [DATAADDRESS, setDATAADDRESS] = useState([])
  const [DATACUSTOMER, setDATACUSTOMER] = useState()
  const [guarantors, setGuarantors] = useState([]);
  const [dataPost, setDataPost] = useState({
    offercaseNote: ""
  })

  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    loadDataContno()
    if (dataAddGuarantor?.length > 0) {
      getImg()
      setGuarantors(dataAddGuarantor)
      setDATAGUA(dataAddGuarantor)
    } else {
      setDATAGUA([])
    }
    if (dataAddPhones2?.length > 0) {
      setDATAPHONE(dataAddPhones2)
    } else {
      setDATAPHONE(dataAddPhones)
    }
    if (dataAddCustomers2) {
      setDATACUSTOMER(dataAddCustomers2)
    } else {
      setDATACUSTOMER(dataAddCustomers)
    }
    if (dataAddAddress?.length > 0) {
      setDATAADDRESS(dataAddAddress)
    } else {
      setDATAADDRESS([])
    }
  }, [])

  const errorSubmit = (placement) => {
    api.error({
      message: `ไม่สามารถส่งเคสได้`,
      description:
        'กรุณาตรวจสอบ ยอดจัดใหม่/จำนวนงวด',
      placement,
    });
  };

  const errorSubmitSend = (placement) => {
    api.error({
      message: `บันทึกไม่สำเร็จ`,
      placement,
    });
  };

  const getImg = async () => {
    const path = `one_images/land/${data.landId}/5`
    setLoading(true)
    await axios.get(`${getAllFolderGuaImg}/${path}?token=${token}`)
      .then(res => {
        if (res.data) {
          setGetImages(res.data)
          setLoading(false)
        }
      })
      .catch((err) => {
        console.log("err", err)
        setLoading(false)
      })
    setLoading(false)
  }

  const loadDataContno = async () => {
    if (data?.reLoanId && data?.customerId && data?.landId) {
      var mainData = { "reLoanId": data?.reLoanId, "customerId": data?.customerId, "landId": data?.landId }
      setLoading(true)
      await axios.post(loadDataReLand, mainData)
        .then(async (res) => {
          // console.log("load", res.data)
          setShowImg(res.data?.land?.img)
          setLoading(false)
        }).catch((err) => {
          console.log(err)
          alert("ไม่พบข้อมูล !!!")
        })
      setLoading(false)
    } else {
      // alert("การโหลดข้อมูลมีปัญหา กรุณาปิดแล้วเปิดใหม่ครับ/ค่ะ")
    }
  }

  const handleSubmit = async () => {
    setLoading(true)
    if (dataAddLoans.interestLand > 0 && dataAddLoans.interestRateLand > 0 && dataAddLoans.loanAmountLand > 0 && dataAddLoans.loanLandTerm > 0) {
      var data = {
        ...DATACUSTOMER,
        ...dataPost,
        landLoanDetailsRe: {
          reLoanId: dataAddLandLoanDetailsRes.reLoanId,
          landId: dataAddLands.landId,
          customerId: dataAddLandLoanDetailsRes.customerId
        },
        phones: DATAPHONE,
        address: DATAADDRESS,
        landLoanDetails: [{
          ...dataAddLoans,
          branch: branch,
          proposalBy: dataAddLandLoanDetailsRes.proposalBy,
          productLoanLandType: dataAddLandLoanDetailsRes.productLoanLandType,
          checkGua: dataAddCheckGuas,
          productType: "ที่ดิน",
          approvalStatus: "รอธุรการรับ",
          land: [{
            landId: dataAddLands.landId,
            landLoanId: 0,
            customerId: dataAddLands.customerId,
            landId: dataAddLands.landId,
            ISSUNO: dataAddLands.ISSUNO,
            landInput: dataAddLands.landInput,
            productTypeLand: 2,
            approvalRe: {
              id: dataAddLands.approvalRe.id
            }
          }]
        }],
        ...dataOccupation,
        guarantors: DATAGUA
      }
    } else {
      errorSubmit("top")
    }
    delete data.land
    // console.log("data", data)
    await axios.post(confirmreland, data)
      .then(res => {
        if (res.data) {
          message.success('ส่งยอดจัดใหม่เรียบร้อย')
          closeAll();
          setLoading(false)
        }
      }).catch((err) => {
        console.log("err", err)
        errorSubmitSend("top")
        setLoading(false)
      })
  };


  const currencyFormatOne = (amount) => {
    if (amount) {
      return Number(amount)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    } else {
      return "..."
    }
  }

  const showConfirmOKRe = () => {
    confirm({
      title: 'ผู้กู้ยอมรับยอดที่อนุมัติ...?',
      onOk() {
        let checkOK = true
        let app = "ลูกค้าคอนเฟิร์ม"
        handleSubmit(checkOK, app)

      },
      onCancel() {
      },
    });
  };

  const genGuarantors = ({ item, index }) => {

    var itemSplit = []
    if (item?.pathImg) {
      itemSplit = item.pathImg.split("/")
    }

    return (
      <Form
        name="basic"
        labelCol={{
          span: 12,
        }}
        // wrapperCol={{
        //     span: 24,
        // }}
        style={{
          maxWidth: '100%',
        }}
        initialValues={{
          remember: true,
        }}
        autoComplete="off"
      >
        <Card style={{ width: '100%' }}                >
          <Row justify={'center'}><b><u>คนค้ำที่ {index + 1}</u></b></Row>
          <span>เลขบัตรประชาชน : </span>
          <b>{item?.identificationId}</b>
          <div style={{ textAlign: 'left' }}>
            <span>ชื่อ : </span>
            <b>{item?.firstname}</b>
          </div>
          <div style={{ textAlign: 'left' }}>
            <span>นามสกุล : </span>
            <b>{item?.lastname}</b>
          </div>
          <div style={{ textAlign: 'left' }}>
            <span>ชื่อเล่น : </span>
            <b>{item?.nickname}</b>
          </div>
          <div style={{ textAlign: 'left' }}>
            {item?.phones?.length >= 1 ?
              <>
                <span>เบอร์โทร : </span>
                <b>{item?.phones[0].telp}</b>
              </>
              :
              <>
                <span>เบอร์โทร : </span>
                <b>{item?.phones}</b>
              </>
            }
          </div>
          <div style={{ textAlign: 'left' }}>
            <span>เพศ : </span>
            <b>{item?.gender}</b>
          </div>
          <Divider style={{ margin: 5 }} />
          <Row gutter={32} justify={'center'}>
            <span>รูปภาพ : </span>
            <Col span={24} style={{ textAlign: 'center' }}>
              {
                itemSplit.length > 0 ? (
                  <>
                    {getimages?.map((items, index) => {
                      const segments = items.pathImage.split("/")
                      if (itemSplit[4] === segments[4]) {
                        return (
                          // <img width={'300px'} key={index} style={{ margin: 5 }} src={createImageUrl(items.image64)} alt={items.filename} />
                          <Image width={'50px'} key={index} src={`data:${items?.Attr};base64,${items?.data?.toString('base64')}`} alt={`Image ${index + 1}`} />
                        )
                      }

                    })}
                  </>
                ) : null
              }
            </Col>
          </Row>
          <Divider style={{ margin: 5 }} />
        </Card>
      </Form>
    )
  }

  const handleBack = () => {
    changepage(page - 1)
  }
  const handleClose = () => {
    close()
  }

  return (
    <Row justify={'center'}>
      <Card style={{ width: '100%' }}>
        <Divider orientation={'center'} style={{ fontSize: '25px' }}><b><u>รายละเอียด</u></b></Divider>
        <Spin tip="Loading..." spinning={loading} >
          <Form
            name="basic"
            labelCol={{
              span: 12,
            }}
            wrapperCol={{
              span: 24,
            }}

            initialValues={{
              remember: true,
            }}
            form={form}
            onFinish={showConfirmOKRe}
            autoComplete="off"
          >
            <Space
              direction="vertical"
              size="middle"
              style={{
                display: 'flex',
              }}>
              {/* <aside> */}
              <Row>
                <Col span={12} style={{ textAlign: 'left' }}>
                  {
                    allData?.land?.landLoanDetailsRe?.productLoanType === "Pre-Aaprove-เปลี่ยนสัญญา" ||
                      allData?.land?.landLoanDetailsRe?.productLoanType === "Pre-Aaprove-ปรับโครงสร้าง" ||
                      allData?.land?.landLoanDetailsRe?.productLoanType === "Pre-Aaprove-รี" ||
                      allData?.land?.landLoanDetailsRe?.productLoanType === "Pre-Aaprove-รี+เปลี่ยนสัญญา" ||
                      allData?.land?.landLoanDetailsRe?.productLoanType === "รี+เปลี่ยนสัญญา" ||
                      allData?.land?.landLoanDetailsRe?.productLoanType === "เปลี่ยนสัญญา" ||
                      allData?.land?.landLoanDetailsRe?.productLoanType === "ปรับโครงสร้าง" ||
                      allData?.land?.landLoanDetailsRe?.productLoanType === "รี"
                      ?
                      <>
                        <div style={{ margin: 0 }}>
                          {allData?.land?.ISSUNO ?
                            <>
                              เลขสัญญา : <Tag color="geekblue">{allData?.land?.ISSUNO ? allData?.land?.ISSUNO : null}</Tag>
                            </>
                            :
                            null
                          }
                        </div>
                        <div style={{ margin: 0 }}>
                          {allData?.land?.landLoanDetailsRe?.GRDCOD ?
                            <>
                              เกรด : <Tag color="geekblue">{allData?.land?.landLoanDetailsRe?.GRDCOD !== '' ? allData?.land?.landLoanDetailsRe?.GRDCOD : null}</Tag>
                            </>
                            :
                            null
                          }
                        </div>
                      </>
                      :
                      null
                  }
                </Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                  <div style={{ margin: 0 }}>
                    สถานะ : <Tag color="geekblue">{allData?.land?.landLoanDetailsRe?.approvalStatus}</Tag>
                  </div>
                  <div style={{ margin: 0 }}>
                    ประเภทขอกู้ : <Tag color="green">{allData?.land?.landLoanDetailsRe?.productLoanLandType}</Tag>
                  </div>
                </Col>
              </Row>
              {/* </aside> */}
              <Row className='main' justify={'center'}>
                <Col span={24}>
                  <Divider orientation={'left'}><b><u>รายละเอียด</u></b></Divider>
                  <aside style={{ width: '100%' }}>
                    <b>
                      <div style={{ margin: 0, }}>
                        <span>ตัดสด ณ วันที่ : </span>
                        <b style={{ fontSize: '18px' }}>
                          <u>
                            {dayjs(dataAddLandLoanDetailsRes?.ftime).format("DD-MM-YYYY")}
                          </u>
                        </b>
                      </div>
                    </b>
                    <div label='เลขที่สัญญา' name='ISSUNO' style={{ marginBottom: 3 }}>
                      <span>เลขที่สัญญา : </span> <b>{allData?.land?.ISSUNO}</b>
                    </div>
                    <div label='ชื่อ-สกุล' name='snam' style={{ marginBottom: 3 }}>
                      <span>ชื่อ-สกุล : </span> <b>{DATACUSTOMER?.firstname + ' ' + DATACUSTOMER?.lastname}</b>
                    </div>
                    {
                      DATAPHONE?.length > 0 ?
                        <div label='เบอร์' name='telp' style={{ marginBottom: 3 }}>
                          <span>เบอร์ : </span> <b>{DATAPHONE[0]?.telp}</b>
                        </div>
                        : null
                    }
                    <div label='เกรด' name='GRDCOD' style={{ marginBottom: 3 }}>
                      <span>เกรด : </span> <b> {dataAddLandLoanDetailsRes?.GRDCOD}</b>
                    </div>
                  </aside>
                  <Divider orientation={'left'}><b><u>อาชึพ</u></b></Divider>
                  <aside style={{ width: '100%' }}>

                    <div style={{ margin: 0, }}>
                      <span>กลุ่มอาชีพ : </span>
                      <b>{dataOccupation?.ojs?.occupationalGroupTH}</b>
                    </div>
                    <div style={{ margin: 0, }}>
                      <span>อาชีพ : </span>
                      <b>{dataOccupation?.ojs?.governmentPrivateTH}</b>
                    </div>
                    <div style={{ margin: 0, }}>
                      <span>สาขาอาชีพ : </span>
                      <b>{dataOccupation?.ojs?.jobPositionTH}</b>
                    </div>
                    <div style={{ margin: 0, }}>
                      <span>ชื่อบริษัท(สถานที่ทำงาน) : </span>
                      <b>{dataOccupation?.ojs?.companyName}</b>
                    </div>
                    <div style={{ margin: 0, }}>
                      <span>รายได้ / เดือน : </span>
                      <b>{dataOccupation?.income?.incomeMonth}</b>
                      <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                        บาท
                      </span>
                    </div>
                    <div style={{ margin: 0, }}>
                      <span>รายจ่าย / เดือน : </span>
                      <b>{dataOccupation?.expenses?.expensesMonth}</b>
                      <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                        บาท
                      </span>
                    </div>
                  </aside>
                  {
                    allData?.land?.landLoanDetailsRe?.productLoanType === "Pre-Aaprove-เปลี่ยนสัญญา" ||
                      allData?.land?.landLoanDetailsRe?.productLoanType === "Pre-Aaprove-รี+เปลี่ยนสัญญา" ||
                      allData?.land?.landLoanDetailsRe?.productLoanType === "รี+เปลี่ยนสัญญา" ||
                      allData?.land?.landLoanDetailsRe?.productLoanType === "เปลี่ยนสัญญา"
                      ?
                      <>
                        <Divider style={{ margin: 3 }} />

                        <Divider orientation={'left'}><b><u>ค่าใช้จ่ายที่ลูกค้าต้องจ่าย</u></b></Divider>
                        <aside style={{ width: '100%', background: '#061178', padding: '8px', color: 'white' }} >

                          <div name='priceChange' style={{ marginBottom: 3 }}>
                            <span><b>ค่าเปลี่ยนสัญญา</b> : </span> <b> {currencyFormatOne(allData?.land?.landLoanDetailsRe?.priceChange)}</b>
                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                              บาท
                            </span>
                          </div>
                          <div name='overdue' style={{ marginBottom: 3 }}>
                            <span><b>ยอดค้าง + เบี้ยปรับ</b> : </span> <b> {currencyFormatOne(allData?.land?.landLoanDetailsRe?.overdue)}</b>
                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                              บาท
                            </span>
                          </div>
                          <div name='preChange' style={{ marginBottom: 3 }}>
                            <span><b>ค่างวดล่วงหน้า</b> : </span> <b> {currencyFormatOne(allData?.land?.landLoanDetailsRe?.preChange)}</b>
                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                              บาท
                            </span>
                          </div><br />
                          <div>
                            <span>ยอดรวม : </span> <b> {currencyFormatOne(~~allData?.land?.landLoanDetailsRe?.preChange + ~~allData?.land?.landLoanDetailsRe?.overdue + ~~allData?.land?.landLoanDetailsRe?.priceChange
                            )}</b>
                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                              บาท
                            </span>
                          </div>

                        </aside>
                        <Divider style={{ margin: 3 }} />
                      </>
                      :
                      null
                  }

                  <Divider orientation={'left'}><b><u>รายละเอียดสินค้า</u></b></Divider>
                  <aside style={{ width: '100%' }}>
                    <div style={{ marginBottom: 3 }}>
                      <span>ประเภทเอกสาร : </span><b>{dataAddLands?.productTypeLand === 1 ? "นส.3 ก." : "นส.4 จ."}</b>
                    </div>
                    <div style={{ marginBottom: 3 }}>
                      <span>จังหวัด : </span><b>{dataAddLands?.province}</b>
                    </div>
                    <div style={{ marginBottom: 3 }}>
                      <span>อำเภอ : </span><b>{dataAddLands?.district}</b>
                    </div>
                    <div style={{ marginBottom: 3 }}>
                      <span>เลขโฉนดที่ดิน : </span><b>{dataAddLands?.numberLand}</b>
                    </div>
                    <div style={{ marginBottom: 3 }}>
                      <span>ระวาง : </span><b>{dataAddLands?.numberLandLawang}</b>
                    </div>
                  </aside>
                  <Divider orientation={'left'}><b><u>รายละเอียดยอดกู้</u></b></Divider>
                  <aside style={{ width: '100%' }}>
                    <div label='ค่างวด' name='DAMT' style={{ marginBottom: 3 }}>
                      <span>ค่างวด : </span><b>
                        {dataAddLandLoanDetailsRes?.DAMT ? currencyFormatOne(dataAddLandLoanDetailsRes?.DAMT) + " บาท" : "0"}
                      </b>
                    </div>
                    <div label='ยอดจัดครั้งที่แล้ว' name='tcshprc' style={{ marginBottom: 3 }}>
                      <span>ยอดจัดครั้งที่แล้ว : </span><b>{dataAddLandLoanDetailsRes?.tcshprc ? currencyFormatOne(dataAddLandLoanDetailsRes?.tcshprc) + " บาท" : 0}</b>
                    </div>
                    <div label='ราคาเช่าซื้อครั้งที่แล้ว' name='balanc' style={{ marginBottom: 3 }}>
                      <span>ราคาเช่าซื้อครั้งที่แล้ว : </span><b>{dataAddLandLoanDetailsRes?.balanc ? currencyFormatOne(dataAddLandLoanDetailsRes?.balanc) + " บาท" : 0}</b>
                    </div>
                    <div label='ยอดที่ชำระมาแล้วทั้งหมด' name='totalpayment' style={{ marginBottom: 3 }}>
                      <span>ยอดที่ชำระมาแล้วทั้งหมด : </span><b>{dataAddLandLoanDetailsRes?.totalpayment ? currencyFormatOne(dataAddLandLoanDetailsRes?.totalpayment) + " บาท" : 0}</b>
                    </div>
                    <div label='ล/น คงเหลือรวม' name='neekong' style={{ marginBottom: 3 }}>
                      <span>ล/น คงเหลือรวม : </span><b>{dataAddLandLoanDetailsRes?.neekong ? currencyFormatOne(dataAddLandLoanDetailsRes?.neekong) + " บาท" : 0}</b>
                    </div>
                    <div label='เบี้ยปรับ' name='beeypup' style={{ marginBottom: 3 }}>
                      <span>เบี้ยปรับ : </span><b>{dataAddLandLoanDetailsRes?.beeypup ? currencyFormatOne(dataAddLandLoanDetailsRes?.beeypup) + " บาท" : 0}</b>
                    </div>
                    <div label='ลูกหนี้อื่นๆ' name='OTHR' style={{ marginBottom: 3 }}>
                      <span>ลูกหนี้อื่นๆ : </span><b>{dataAddLandLoanDetailsRes?.OTHR ? currencyFormatOne(dataAddLandLoanDetailsRes?.OTHR) + " บาท" : 0}</b>
                    </div>
                    <div label='จำนวนงวดที่ผ่อนมาแล้ว' name='NOPAY' style={{ marginBottom: 3 }}>
                      <span>จำนวนงวดที่ผ่อนมาแล้ว : </span><b>{dataAddLandLoanDetailsRes?.NOPAY ? currencyFormatOne(dataAddLandLoanDetailsRes?.NOPAY) + " งวด" : 0}</b>
                    </div>
                    <div label='จำนวนงวดทั้งหมด' name='nopay' style={{ marginBottom: 3 }}>
                      <span>จำนวนงวดทั้งหมด : </span><b>{dataAddLandLoanDetailsRes?.nopays ? currencyFormatOne(dataAddLandLoanDetailsRes?.nopays) + " งวด" : 0}</b>
                    </div>
                    <div label='ตัดสด งวดที่' name='reqNo' style={{ marginBottom: 3 }}>
                      <span>ตัดสด งวดที่ : </span><b>{dataAddLandLoanDetailsRes?.reqNo ? currencyFormatOne(dataAddLandLoanDetailsRes?.reqNo) : 0}</b>
                    </div>
                  </aside>
                  <Divider orientation={'left'}><b><u>เคาะราคาที่ดิน</u></b></Divider>
                  <aside style={{ width: '100%' }}>
                    <b>
                      <div style={{ margin: 0, }}>
                        <span>ราคาต่ำสุด : </span>
                        <b style={{ fontSize: '18px' }}><u>{currencyFormatOne(dataAddLands?.approvalRe?.minPrice)}</u></b>
                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                          บาท
                        </span>
                      </div>
                    </b>
                    <b>
                      <div style={{ margin: 0, }}>
                        <span>ราคาสูงสุด : </span>
                        <b style={{ fontSize: '18px' }}><u>{currencyFormatOne(dataAddLands?.approvalRe?.maxPrice)}</u></b>
                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                          บาท
                        </span>
                      </div>
                    </b>
                    <b>
                      <div style={{ margin: 0, }}>
                        <span>หมายเหตุ : </span>
                        <b style={{ fontSize: '18px' }}><u>{dataAddLands?.approvalRe?.note}</u></b>
                      </div>
                    </b>
                  </aside>
                  <Divider orientation={'left'}><b><u>รายละเอียดจัดใหม่</u></b></Divider>
                  <aside style={{ width: '100%' }}>
                    <b>
                      <div style={{ margin: 0, }}>
                        <span>ยอดรีไฟแนนซ์เพื่อจัดใหม่ : </span>
                        <b style={{ color: 'red', fontSize: '18px' }}><u>{currencyFormatOne(dataAddLandLoanDetailsRes?.re1)}</u></b>
                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                          บาท
                        </span>
                      </div>
                    </b>
                    <b>
                      <div style={{ margin: 0, }}>
                        <span>ยอดจัดใหม่ : </span>
                        <b style={{ color: 'red', fontSize: '18px' }}><u>{currencyFormatOne(dataAddLoans?.loanAmountLand)}</u></b>
                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                          บาท
                        </span>
                      </div>
                    </b>
                    <b>
                      <div style={{ margin: 0, }}>
                        <span>จำนวนงวด : </span>
                        <b style={{ color: 'red', fontSize: '18px' }}><u>{(dataAddLoans?.loanLandTerm)}</u></b>
                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                          งวด
                        </span>
                      </div>
                    </b>
                  </aside>
                  <Divider orientation={'left'}><b><u>การค้ำโอน</u></b></Divider>
                  <Row justify={'center'}>
                    <Checkbox
                      style={{ fontSize: '20px' }}
                      checked={dataAddCheckGuas === "ค้ำ-ไม่โอน" ? true : false}
                    >ค้ำ-ไม่โอน
                    </Checkbox>
                    <Checkbox
                      style={{ fontSize: '20px' }}
                      checked={dataAddCheckGuas === "ค้ำ-โอน" ? true : false}
                    >ค้ำ-โอน
                    </Checkbox>
                    <Checkbox
                      style={{ fontSize: '20px' }}
                      checked={dataAddCheckGuas === "ไม่ค้ำ-ไม่โอน" ? true : false}
                    >ไม่ค้ำ-ไม่โอน
                    </Checkbox>
                    <Checkbox
                      style={{ fontSize: '20px' }}
                      checked={dataAddCheckGuas === "ไม่ค้ำ-โอน" ? true : false}
                    >ไม่ค้ำ-โอน
                    </Checkbox>
                  </Row>
                  <Divider orientation={'left'}></Divider>
                  <aside style={{ width: '100%' }}>
                    <b>
                      <div style={{ margin: 0, }}>
                        <span>ใบนี้หมดอายุ ณ วันที่ : </span>
                        <b style={{ color: 'red', fontSize: '20px' }}><u>{dayjs(dataAddLandLoanDetailsRes?.newOfferDate).format("DD-MM-YYYY")}</u></b>
                      </div>
                    </b>
                  </aside>
                </Col>
              </Row>
              <Divider orientation={'left'}></Divider>
              <Row justify={'center'}>
                <Col span={22}>
                  <Form.Item name="offercaseNote" label="">
                    <span><b style={{ fontSize: '25' }}><u>หมายเหตุ</u></b></span> <p />
                    <span><u style={{ fontSize: '20', color: 'red' }}>กรณีที่เอกสารส่วนไหนไม่ครบหรือไม่มี ให้พิมพ์หมายเหตุตรงนี้</u></span>
                    <TextArea
                      name='offercaseNote'
                      style={{ color: 'blue' }}
                      rows={8}
                      onChange={(e) => setDataPost({ offercaseNote: e.target.value })}
                    >
                    </TextArea>
                  </Form.Item>
                </Col>
              </Row>
              {
                guarantors?.length > 0 ?
                  <>
                    <Divider orientation={'left'}></Divider>
                    {guarantors?.map((item, index) => {
                      return genGuarantors({ item, index, key: `{item.identificationId} - ${index}` });
                    })}
                  </>
                  :
                  null
              }
              <Divider />
              <Row justify={'left'}><b><u>รูปประกอบการขออนุมัติ</u></b></Row>
              <Row gutter={32} justify={'center'}>
                {/* <Space direction="vertical" > */}
                {showImg?.map((imageData, index) => {
                  if (imageData?.type === 14) {
                    return (
                      <>
                        <Row gutter={32} justify={'center'}>
                          <Col span={24} style={{ textAlign: 'center' }}>
                            <Image width={'100px'} key={index} src={`${getImagess}/${imageData.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                          </Col>
                        </Row>
                      </>
                    );
                  }
                  return null;
                })}
                {/* </Space> */}
              </Row>
              <Divider />
              <Row justify={'left'}><b><u>รูปบัตรประชาชนผู้ทำสัญญา</u></b></Row>
              <Row gutter={32} justify={'center'}>
                {/* <Space direction="vertical" > */}
                {showImg?.map((imageData, index) => {
                  if (imageData?.type === 4) {
                    return (
                      <>
                        <Row gutter={32} justify={'center'}>
                          <Col span={24} style={{ textAlign: 'center' }}>
                            <Image width={'100px'} key={index} src={`${getImagess}/${imageData.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                          </Col>
                        </Row>
                      </>
                    );
                  }
                  return null;
                })}
                {/* </Space> */}
              </Row>
              <Divider />
              <Row justify={'left'}><b><u>รูปโฉนด/หน้า-หลัง</u></b></Row>
              <Row gutter={32} justify={'center'}>
                {/* <Space direction="vertical" > */}
                {showImg?.map((imageData, index) => {
                  if (imageData?.type === 3) {
                    return (
                      <>
                        <Row gutter={32} justify={'center'}>
                          <Col span={24} style={{ textAlign: 'center' }}>
                            <Image width={'100px'} key={index} src={`${getImagess}/${imageData.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                          </Col>
                        </Row>
                      </>
                    );
                  }
                  return null;
                })}
                {/* </Space> */}
              </Row>
              <Divider />
              <Row justify={'left'}><b><u>ใบแบ่งใบต่อ</u></b></Row>
              <Row gutter={32} justify={'center'}>
                {/* <Space direction="vertical" > */}
                {showImg?.map((imageData, index) => {
                  if (imageData?.type === 10) {
                    return (
                      <>
                        <Row gutter={32} justify={'center'}>
                          <Col span={24} style={{ textAlign: 'center' }}>
                            <Image width={'100px'} key={index} src={`${getImagess}/${imageData.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                          </Col>
                        </Row>
                      </>
                    );
                  }
                  return null;
                })}
                {/* </Space> */}
              </Row>
              <Divider />
              <Row justify={'left'}><b><u>ระวาง</u></b></Row>
              <Row gutter={32} justify={'center'}>
                {/* <Space direction="vertical" > */}
                {showImg?.map((imageData, index) => {
                  if (imageData?.type === 11) {
                    return (
                      <>
                        <Row gutter={32} justify={'center'}>
                          <Col span={24} style={{ textAlign: 'center' }}>
                            <Image width={'100px'} key={index} src={`${getImagess}/${imageData.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                          </Col>
                        </Row>
                      </>
                    );
                  }
                  return null;
                })}
                {/* </Space> */}
              </Row>
              <Divider />
              <Row justify={'left'}><b><u>ใบประเมิน/รูปที่ดิน/สิ่งปลูกสร้าง-ที่ดินปัจจุบัน</u></b></Row>
              <Row gutter={32} justify={'center'}>
                {/* <Space direction="vertical" > */}
                {showImg?.map((imageData, index) => {
                  if (imageData?.type === 1) {
                    return (
                      <>
                        <Row gutter={32} justify={'center'}>
                          <Col span={24} style={{ textAlign: 'center' }}>
                            <Image width={'100px'} key={index} src={`${getImagess}/${imageData.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                          </Col>
                        </Row>
                      </>
                    );
                  }
                  return null;
                })}
                {/* </Space> */}
              </Row>
              <Divider />
              <Row justify={'left'}><b><u>ประวัติผู้กู้ / ทะเบียนบ้าน / อาชีพ</u></b></Row>
              <Row gutter={32} justify={'center'}>
                {/* <Space direction="vertical" > */}
                {showImg?.map((imageData, index) => {
                  if (imageData?.type === 6) {
                    return (
                      <>
                        <Row gutter={32} justify={'center'}>
                          <Col span={24} style={{ textAlign: 'center' }}>
                            <Image width={'100px'} key={index} src={`${getImagess}/${imageData.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                          </Col>
                        </Row>
                      </>
                    );
                  }
                  return null;
                })}
                {/* </Space> */}
              </Row>
              <Divider />
              <Row justify={'left'}><b><u>รูปสเตทเมนต์ผู้กู้</u></b></Row>
              <Row gutter={32} justify={'center'}>
                {/* <Space direction="vertical" > */}
                {showImg?.map((imageData, index) => {
                  if (imageData?.type === 8) {
                    return (
                      <>
                        <Row gutter={32} justify={'center'}>
                          <Col span={24} style={{ textAlign: 'center' }}>
                            <Image width={'100px'} key={index} src={`${getImagess}/${imageData.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                          </Col>
                        </Row>
                      </>
                    );
                  }
                  return null;
                })}
                {/* </Space> */}
              </Row>
              <Divider />
              <Row justify={'left'}><b><u>รูปสเตทเมนต์ผู้กู้ PDF</u></b></Row>
              <Row gutter={32} justify={'center'}>
                {/* <Space direction="vertical" > */}
                {showImg?.map((imageData, index) => {
                  if (imageData.type === 7) {
                    return (
                      <Row gutter={32} justify={'center'}>
                        <Col span={24} style={{ textAlign: 'center' }}>
                          {/* <Space direction='vertical'> */}
                          <object data={`${getImagess}/${imageData.pathImage}?token=${token}`} type="application/pdf" width="350px" height="450px">
                            <p>Alternative text - include a link <a href={`${getImagess}/${imageData.pathImage}?token=${token}`}>to the PDF!</a></p>
                          </object>
                          <p></p>
                          {/* </Space> */}
                        </Col>
                      </Row>
                    )
                  }
                  return null;
                })}
                {/* </Space> */}
              </Row>
              <Divider />
              <Row justify={'left'}><b><u>เอกสารเพิ่มเติม</u></b></Row>
              <Row gutter={32} justify={'center'}>
                {/* <Space direction="vertical" > */}
                {showImg?.map((imageData, index) => {
                  if (imageData?.type === 12) {
                    return (
                      <>
                        <Row gutter={32} justify={'center'}>
                          <Col span={24} style={{ textAlign: 'center' }}>
                            <Image width={'100px'} key={index} src={`${getImagess}/${imageData.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                          </Col>
                        </Row>
                      </>
                    );
                  }
                  return null;
                })}
                {/* </Space> */}
              </Row>
              <Divider />
              <Row justify={'center'}>
                <Space>
                  <Button type='primary' onClick={handleBack} >ย้อนกลับ</Button>
                  <Button type='primary' danger onClick={handleClose} >ยกเลิก</Button>
                  <Button type='primary' htmlType='submit' disabled={loading} style={{ backgroundColor: "green" }} >คอนเฟิร์มยอดจัดใหม่</Button>
                </Space>
              </Row>
            </Space>
          </Form>
        </Spin>
      </Card>
      {contextHolder}
    </Row >
  )
}

export default DetailLand