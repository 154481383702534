import React, { useState, useEffect } from 'react'
import { Form, Modal, Row, Spin, Space, Button, Divider, Col, Card, Input, Select, notification } from 'antd'
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import dayjs from 'dayjs';
import TableRateCar from '../../../offer_case/rate_car/TableRateCar';
import { dataModel, getprice, ratecarsmodel, editcasere } from '../../../file_mid/all_api';
import { timeout } from 'async';
export default function EditCar({ close, page, changepage, data, dataCont, dataRe, sendback }) {
    const { confirm } = Modal
    const { Option } = Select;
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [api, contextHolder] = notification.useNotification();
    // const cars = useSelector(state => state.cars)
    const [loading, setLoading] = useState(false);
    const currentTime = dayjs();
    const formattedTime = currentTime.format('YYYY-MM-DDTHH:mm:ssZ');
    let countoffer = 1;
    const [dataPost2, setDataPost2] = useState({ productTypeCar: 1, carBrand: "" })  //car
    const [resultData, setResultData] = useState([]) // API
    const [typeData, setTypeData] = useState([]) // API
    const [brandData, setBrandData] = useState([]) // API
    const [modelData, setModelData] = useState([]) // API
    const [yearPrice, setYearPrice] = useState([])
    const [typeSelected, setTypeSelected] = useState(null)
    const [openTableCar, setOpenTableCar] = useState(false); //ตารางเรทรถ
    const [disBTN, setDisBTN] = useState(false); //ตารางเรทรถ
    const addCars = localStorage.getItem('addCar')
    const addCars2 = localStorage.getItem('addCar2')
    const dataAddCars = JSON.parse(addCars)
    const dataAddCars2 = JSON.parse(addCars2)

    // console.log("dataAddCars", dataAddCars)

    useEffect(() => {
        if (addCars2) {

            if (addCars) {
                // console.log("1")
                setTypeData(dataAddCars.productTypeCar)
                setTypeSelected(dataAddCars.productTypeCar)
                loadDataBrand(dataAddCars.productTypeCar)
                setDataPost2(dataAddCars)
                form.setFieldsValue(
                    {
                        ...dataAddCars,
                        carPrice: currencyFormatOne(dataAddCars?.carPrice),
                    }
                )
            } else {
                if (addCars2) {
                    // console.log("3")
                    setTypeData(dataAddCars2?.productTypeCar)
                    setTypeSelected(dataAddCars2?.productTypeCar)
                    loadDataBrand(dataAddCars2?.productTypeCar)
                    setDataPost2(dataAddCars2)
                    form.setFieldsValue(
                        {
                            ...dataAddCars2,
                            carPrice: currencyFormatOne(dataAddCars2?.carPrice),
                        }
                    )
                }
            }

            // if (dataRe) {
            //     // console.log("3")
            //     setTypeData(dataRe?.cars?.productTypeCar)
            //     setTypeSelected(dataRe?.cars?.productTypeCar)
            //     loadDataBrand(dataRe?.cars?.productTypeCar)
            //     setDataPost2(dataRe?.cars)
            //     form.setFieldsValue(
            //         {
            //             ...dataRe?.cars,
            //             carPrice: currencyFormatOne(dataRe?.cars?.carPrice),
            //         }
            //     )
            // } else {
            //     // console.log("4")
            //     // loadDataBrand(dataPost2?.productTypeCar)
            //     // setTypeSelected(1)
            //     // setTypeData(1)
            //     // form.setFieldsValue({ productTypeCar: dataPost2?.productTypeCar })
            // }
        } else {
            if (addCars) {
                // console.log("1")
                setTypeData(dataAddCars.productTypeCar)
                setTypeSelected(dataAddCars.productTypeCar)
                loadDataBrand(dataAddCars.productTypeCar)
                setDataPost2(dataAddCars)
                form.setFieldsValue(
                    {
                        ...dataAddCars,
                        carPrice: currencyFormatOne(dataAddCars?.carPrice),
                    }
                )
            }
            // else {
            //     // console.log("2")

            // }
        }
    }, [addCars])

    const sendSuccess = (placement) => {
        api.success({
            message: <b>บันทึกสำเร็จ</b>,
            //   description:
            //     'สถานะถูกเปลี่ยนจาก รอธุการรับ เป็น รอพิจารณาแล้ว',
            placement,
        });
    }
    const sendError = (placement) => {
        api.error({
            message: <b>บันทึกไม่สำเร็จ</b>,
            //   description:
            //     'สถานะถูกเปลี่ยนจาก รอธุการรับ เป็น รอพิจารณาแล้ว',
            placement,
        });
    }

    const handleSubmit = async () => {
        setLoading(true)
        let result = {
            carId: data?.carId,
            carBaab: dataPost2?.carBaab,
            carBrand: dataPost2?.carBrand,
            carModel: dataPost2?.carModel,
            carPrice: dataPost2?.carPrice,
            // carProvince: dataPost2?.carProvince,
            productTypeCar: dataPost2?.productTypeCar,
            carPriceStatus: dataPost2?.carPriceStatus,
            carInput: formattedTime,
            carDateStatus: formattedTime
        }
        // console.log("result", result)
        await axios.put(editcasere, result)
            .then((res) => {
                if (res.status === 200) {
                    sendback(dayjs())
                    sendSuccess('top')
                    localStorage.setItem('addCar2', JSON.stringify(dataPost2))
                    localStorage.setItem('addCar', JSON.stringify(dataPost2))
                    setTimeout(() => {
                        handleGo()
                    }, 1000)
                    setLoading(false)
                }
                // console.log("handleSubmit", res.data)
            }).catch((err) => {
                console.log(err)
                sendError('top')
                setLoading(false)
            })
    };

    const handleGo = () => {
        changepage(page + 1)
    }
    const handleClose = () => {
        close()
    }
    const currencyFormatOne = (amount) => {
        if (amount) {
            return Number(amount)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, '$&,')
        }
    }
    const loadDataBrand = async (value) => { //รถยนต์ รถเครื่องจักร รถบรรทุก
        setLoading(true)
        await axios.get(`https://shark-app-j9jc9.ondigitalocean.app/ratecars/brand/${value}`)
            .then((res) => {
                // console.log("loadDataBrand", res.data)
                setBrandData(res.data)
                setLoading(false)
            })
            .catch((err) => console.log(err))
        setLoading(false)
    }
    const loadDataModel = async (value) => {
        setLoading(true)
        await axios.get(dataModel + value)
            .then((res) => {
                setModelData(res.data)
                form.setFieldsValue(
                    {
                        carModel: "",
                        carBaab: "",
                        carYear: "",
                        carPrice: 0
                    }
                )
                setLoading(false)
            })
            .catch((err) => console.log(err))
        setLoading(false)
    }
    const getResult = async (value) => {
        setLoading(true)
        const result = await axios.get(ratecarsmodel,
            { params: { typeId: typeSelected, modelId: value }, })
        setResultData(result.data)
        setLoading(false)
    }
    const onBrandChange = (value) => {
        setDataPost2({ ...dataPost2, carBrand: brandData.find((a) => a.idbrand === value).brand })
        loadDataModel(value)
        setDisBTN(false)
    }
    const onModelChange = (value) => {
        setDataPost2({ ...dataPost2, carModel: modelData.find((a) => a.idmodel_te === value).model })
        getResult(value)
    }
    const onDetailChange = (value) => {
        setDataPost2({ ...dataPost2, carBaab: resultData.find((a) => a.idmodel === value).models })
        getPrice(typeData, value)
    }
    const getPrice = async (typeId, carId) => {
        setLoading(true)
        await axios.get(getprice, { params: { typeId, carId }, })
            .then((res) => {
                if (res.data) {
                    setYearPrice(res.data)
                    setLoading(false)
                }
            })
            .catch((err) => alert('ไม่พบข้อมูล ราคารถ'))
        setLoading(false)
    }
    const onResult = (k, v) => {
        const pYear = parseInt(v.key)
        if (k) {
            setDataPost2({ ...dataPost2, carPriceStatus: true, carPrice: k, carYear: pYear, carInput: formattedTime, carDateStatus: formattedTime })
            parseInt(dataPost2.carYear)
            form.setFieldsValue({
                carPrice: currencyFormatOne(v.value)
            })
        }
    }

    const onTypeChange = (value) => {
        // console.log("ชนิด =", value)
        setDataPost2({ ...dataPost2, productTypeCar: value, carYear: 0, carPrice: 0, carPriceStatus: false, carInput: formattedTime, carDateStatus: formattedTime })
        form.setFieldsValue({
            carBrand: "",
            carModel: "",
            carBaab: "",
            carYear: "",
            carPrice: 0
        })
        setTypeData(value)
        setTypeSelected(value)
        loadDataBrand(value)
    }
    const getTableCar = () => {
        setOpenTableCar(true)
    }

    const handleRate0 = async () => {
        const filteredBrandData = brandData?.filter(item =>
            item.brand?.toLowerCase().includes(dataCont?.custmast[0]?.TYPE.toLowerCase()) ||
            item.brand_th?.toLowerCase().includes(dataCont?.custmast[0]?.TYPE.toLowerCase())
        ).map(item => (item))
        let brandDataNew
        if (filteredBrandData.length !== 0) {
            brandDataNew = filteredBrandData[0].brand
        } else {
            brandDataNew = dataCont?.custmast[0]?.TYPE
        }
        confirm({
            title: 'คุณต้องการที่จะเลือกรถเรท 0 หรือไม่...?',
            content: 'กรณีที่รถไม่มีในเรท กด OK เพื่อยืนยันการใช้รถเรท 0',
            onOk() {
                setDataPost2({
                    ...dataPost2,
                    carBrand: brandDataNew,
                    carModel: dataCont?.custmast[0]?.MODEL,
                    carBaab: dataCont?.custmast[0]?.BAAB,
                    carYear: ~~dataCont?.custmast[0]?.MANUYR - 543,
                    carPrice: 0,
                    carPriceStatus: false
                })
                setDisBTN(true)
                form.setFieldsValue({
                    carBrand: brandDataNew,
                    carModel: dataCont?.custmast[0]?.MODEL,
                    carBaab: dataCont?.custmast[0]?.BAAB,
                    carYear: ~~dataCont?.custmast[0]?.MANUYR - 543,
                    carPrice: 0
                })
            },
            onCancel() {

            },
        });
    };

    return (
        <Card style={{ width: "100%" }}>
            <Divider orientation={'center'} style={{ fontSize: '25px' }}><b><u>ข้อมูลรถ</u></b></Divider>
            <Spin spinning={loading} size='large' tip=" Loading... ">
                <Form
                    name="basic"
                    labelCol={{
                        span: 2,
                    }}
                    // wrapperCol={{
                    //     span: 24,
                    // }}
                    initialValues={{
                        remember: true,
                    }}
                    form={form}
                    onFinish={handleSubmit}
                    autoComplete="off"
                >
                    <Row justify={"center"}>
                        {
                            dataCont ?
                                <>
                                    <Divider orientation={'left'}><b><u>สินค้า</u></b></Divider>
                                    <aside style={{ width: '90%' }}>
                                        <div label='ยี่ห้อ' name='TYPE' style={{ marginBottom: 3 }}>
                                            <span>ยี่ห้อ : </span><b>{dataCont?.custmast[0]?.TYPE}</b>
                                        </div>
                                        <div label='รุ่น' name='MODEL' style={{ marginBottom: 3 }}>
                                            <span>รุ่น : </span><b>{dataCont?.custmast[0]?.MODEL}</b>
                                        </div>
                                        <div label='แบบ' name='MODEL' style={{ marginBottom: 3 }}>
                                            <span>แบบ : </span><b>{dataCont?.custmast[0]?.BAAB}</b>
                                        </div>
                                        <div label='ปี' name='MANUYR' style={{ marginBottom: 3 }}>
                                            <span>ปี : </span><b>{dataCont?.custmast[0]?.MANUYR}</b>
                                        </div>
                                    </aside>
                                </>
                                : null
                        }
                        <Divider orientation={'left'}><b><u>รายละเอียด</u></b></Divider>
                        <aside style={{ width: '90%' }}>
                            <Form.Item label='ชนิดรถ' name='productTypeCar'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input !'
                                    },]}>
                                <Select
                                    placeholder={'เลือก'}
                                    style={{ color: 'black', height: '40px' }}
                                    allowClear
                                    onChange={onTypeChange}>
                                    <Option value={1}>รถยนต์</Option>
                                    <Option value={2}>รถเครื่องจักการเกษตร</Option>
                                    <Option value={3}>รถบรรทุก</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item label='เรทรถ'>
                                <Space>
                                    <Button type='primary' onClick={getTableCar}>ดู</Button>
                                    <Button type='primary' style={{ backgroundColor: "#F7A419" }} onClick={handleRate0}>รถไม่มีในเรท</Button>
                                </Space>
                                {
                                    openTableCar ?
                                        <TableRateCar
                                            open={openTableCar}
                                            close={setOpenTableCar}
                                            countoffer={countoffer}
                                            productTypeCar={dataPost2?.productTypeCar}
                                        />
                                        : null
                                }
                            </Form.Item>
                            <Form.Item label='ยี่ห้อ'
                                name='carBrand'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input !'
                                    },]}>
                                <Select
                                    showSearch
                                    style={{ height: '40px' }}
                                    onChange={onBrandChange}
                                    optionFilterProp="children"
                                    placeholder="Search to Select"
                                    filterOption={(inputValue, option) =>
                                        option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                                    }
                                >
                                    {
                                        brandData?.map((e) => {
                                            return (
                                                <Select.Option key={e.idbrand} value={e.idbrand}>
                                                    {e.brand} ({e.brand_th})
                                                </Select.Option>
                                            )
                                        })}
                                </Select>
                            </Form.Item>
                            <Form.Item label='รุ่นรถ' name='carModel'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input !'
                                    },]}>
                                <Select
                                    showSearch
                                    disabled={disBTN}
                                    style={{ height: '40px' }}
                                    onChange={onModelChange}
                                    placeholder="Search to Select"
                                    optionFilterProp="children"
                                    filterOption={(inputValue, option) =>
                                        option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                                    }
                                >
                                    {modelData?.map((e) => {
                                        return (
                                            <Select.Option key={e.idmodel_te} value={e.idmodel_te}>
                                                {e.model} {e.model_te ? e.model_te : null}
                                            </Select.Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item label='แบบรถ' name='carBaab'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input !'
                                    },]}>
                                <Select
                                    showSearch
                                    style={{ height: '40px' }}
                                    disabled={disBTN}
                                    onChange={(value) => { onDetailChange(value) }}
                                    placeholder="Search to Select"
                                >
                                    {resultData?.map((e) => {
                                        return (
                                            <Select.Option key={e.idmodel} value={e.idmodel}>
                                                {e.models} {e.idmodel ? e.idmodel : null}
                                            </Select.Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item label='ปีรถ' name='carYear'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input !'
                                    },]}>
                                <Select
                                    disabled={disBTN}
                                    style={{ height: '40px' }}
                                    onChange={(k, v) => (onResult(k, v))}
                                >
                                    {Object.entries(yearPrice).map(([k, v]) => {
                                        if (k.charAt(0) === 'p' && v) {
                                            const year = k.replace('p', '')
                                            return (
                                                <Select.Option key={year} value={v * 1000}>
                                                    {year}
                                                </Select.Option>
                                            )
                                        }
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item label='ราคา' name='carPrice'>
                                <Input disabled defaultValue={0} style={{ color: 'black', height: '40px' }}
                                    onChange={(e) => setDataPost2({ ...dataPost2, carPrice: e.value })} />
                            </Form.Item>
                        </aside>
                        <Divider style={{ margin: 5 }} />
                        <Row justify={'center'} >
                            <Space>
                                <Button type="primary" style={{ background: "red" }} onClick={handleClose}>
                                    ยกเลิก
                                </Button>
                                <Button type="primary" htmlType="submit" style={{ background: "green" }} >
                                    บันทึก
                                </Button>
                            </Space>
                        </Row>
                    </Row>
                </Form>
            </Spin>
            {contextHolder}
        </Card >
        // {/* </Row > */}
    )
}

