import React, { useEffect, useState, useRef } from 'react'
import { Button, Input, Form, DatePicker, Card, Modal, Col, Row, message, Select, Radio, Spin, Divider } from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { addCustomer } from '../../../redux/Customer'
import { addAddress } from '../../../redux/Address'
import { addPhone } from '../../../redux/Phone'
import { addCar } from '../../../redux/Car'
import { addLoan } from '../../../redux/Loan'
import { addOldLoan } from '../../../redux/OldLoan'
import { addImage } from '../../../redux/Image'
import { addGuarantor } from '../../../redux/Guarantor';
import { addCareerIncome } from '../../../redux/CareerIncome';
import { checkCCAP2, createaddresstocustomer, testupload, upsert } from '../../file_mid/all_api';
// import { addSocial } from '../../../redux/Social';
// import { addEmail } from '../../../redux/Email';
// import { addUser } from '../../../redux/User';
// import { addLand } from '../../../redux/Land';
// import { addLoanLand } from '../../../redux/LoanLand';

export default function AddCustomer() {
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const inputRef = useRef(null);
    const [messageApi, contextHolder] = message.useMessage();
    //useSelectors
    const { customers } = useSelector(state => (state))
    const { Addresss } = useSelector(state => (state))
    const { Phones } = useSelector(state => (state))

    //state address
    const [dataAddress, setDataAddress] = useState({})
    const [dataPost, setDataPost] = useState({
        identificationId: '',
        snam: '',
        firstname: '',
        lastname: '',
        nickname: '',
        nationalId: '',
        birthdate: '',
        gender: '',
        issuby: '',
        issudt: '',
        expdt: '',
    }) // customer
    const [count] = useState(false)
    const [dataPhone, setDataPhone] = useState({}) // phone
    const [fileList, setFileList] = useState() // รูปบัตรประชาชน
    const [loading, setLoading] = useState(false) //loading

    //useEffect
    useEffect(() => {
        if (customers.data) {
            setDataPost(customers.data)
            setDataAddress(Addresss.data)
            setDataPhone(Phones.data)
            form.setFieldsValue(
                {
                    ...customers.data, ...Addresss.data, ...Phones.data,
                    birthdate: dayjs(customers.data?.birthdate),
                    issudt: dayjs(customers.data?.issudt),
                    expdt: dayjs(customers.data?.expdt),
                }
            )
        }

    }, [])

    const getdatanot = () => {
        Modal.success({
            content: 'ยังไม่มีข้อมูลในระบบ ?',
        })
    }
    const error = () => {
        Modal.error({
            title: 'กรุณากรอกข้อมูล!!',
            // content: 'กรุณาข้อมูล',
        })
    }

    const handleReset = () => {
        dispatch(addCustomer())
        dispatch(addAddress())
        dispatch(addPhone())
        dispatch(addCar())
        dispatch(addLoan())
        dispatch(addOldLoan())
        dispatch(addImage())
        dispatch(addGuarantor())
        dispatch(addCareerIncome())
        form.setFieldsValue(
            {
                identificationId: '',
                snam: '',
                firstname: '',
                lastname: '',
                nickname: '',
                nationalId: '',
                birthdate: '',
                gender: '',
                issuby: '',
                issudt: '',
                expdt: '',
                image: inputRef.current.value = null,
            }
        )
    }
    console.log("dataAddress", dataAddress)
    //Button Submit
    const handleSubmit = async (e) => {
        // delete sumData.customer.address
        // delete dataPost.address
        //เป็นแบบเพิ่มและแก้ไขในปุ่มเดียว โดยเช็คเงื่อนไขจากหลังบ้าน
        // await axios.post('https://eua-i67f6gaaqa-as.a.run.app/Api/create', dataPost) //อัพเดตข้อมูลลงฐานข้อมูล กรณีมีข้อมูล (เช็คตามเลข CustomerId)
        if (sumData.customer.customerId === 0 || sumData.customer.customerId === undefined) {
            await axios.post(createaddresstocustomer, sumData)
                .then(res => {
                    if (res.status === 200) {
                        console.log("เพิ่มข้อมูลสำเร็จ")
                        if (window.confirm("บันทึกข้อมูลใช่หรือไม่")) {
                            setDataPost(res.data.customer)
                            setDataAddress(res.data.address)
                            dispatch(addAddress(res.data.address))
                            dispatch(addCustomer(res.data.customer))
                            navigate('/addcustomer/address')  //ลิ้งค์ไปหน้าที่อยู่
                        } else {
                            // setDataPost({})
                            // setDataAddress({})
                            // dispatch(addAddress({}))
                            // dispatch(addCustomer({}))
                        }
                    }
                })
                .catch((err) => console.log(err))
        } else {
            console.log("upsert");
            await axios.post(upsert, dataPost)
                .then(res => {
                    if (res.status) {
                        if (window.confirm("ต้องการอัพเดตข้อมูลใช่หรือไม่")) {
                            console.log("เพิ่มข้อมูลสำเร็จ")
                            setDataPost(res.data)
                            setDataAddress(res.data.address)
                            dispatch(addAddress(res.data.address))
                            dispatch(addCustomer(res.data))
                            navigate('/addcustomer/address')  //ลิ้งค์ไปหน้าที่อยู่
                        } else {
                            // setDataPost({})
                            // setDataAddress({})
                            // dispatch(addAddress({}))
                            // dispatch(addCustomer({}))
                        }
                    }
                }).catch((err) => {
                    if (err.response.request.status === 400) {
                        error()
                    }
                    console.log("err", err)
                })
        }
    }
    const customer = dataPost // เก็บข้อมูลที่มี customerId ไป set in redux
    //Buuton Next
    const handleNext = (e) => {
        if (dataPost.identificationId === undefined || dataPost.identificationId === "") {
            error()
        } else {
            dispatch(addCustomer(dataPost))
            dispatch(addCustomer(customer))
            dispatch(addAddress(dataAddress))
            dispatch(addPhone(dataPhone))
            navigate('/addcustomer/address')
        }
    }
    // ข้อมูลยูสในเดต้าเบส //
    const loadDataOne = async (data) => {
        const one = { identificationId: data.identificationId }
        setLoading(true)
        //ตัวโหลดข้อมูล
        await axios.post(checkCCAP2, one)
            .then((res) => {
                if (res.data) {
                    message.info('มีข้อมูลในฐานข้อมูล')
                    if (window.confirm('คุณต้องการอัพเดตข้อมูลหรือไม่ ?')) {
                        //ถ้ามีข้อมูในฐานข้อมูลแล้ว จะดึงข้อมูลใน Database มาโชว์
                        setDataPost(res.data)
                        setDataPhone(res.data.phones)
                        setDataAddress(res.data.address) // ถ้าใส่ [0] จะไม่สามารถแมพที่อยู่ได้
                        if (res.data.expdt === null || res.data.issudt === null) {
                            form.setFieldsValue(
                                {
                                    ...res.data,
                                    telp: res?.data?.phones[0]?.telp,
                                    birthdate: dayjs(res?.data?.birthdate),
                                    issudt: '',
                                    expdt: '',
                                }
                            )
                            setLoading(false)
                        }
                        else {
                            form.setFieldsValue(
                                {
                                    ...res.data,
                                    telp: res?.data?.phones[0]?.telp,
                                    birthdate: dayjs(res?.data?.birthdate),
                                    issudt: dayjs(res?.data?.issudt),
                                    expdt: dayjs(res?.data?.expdt),
                                }
                            )
                        }
                    } else {
                        // กรณีไม่มีข้อมูลในฐานข้อมูล
                        setDataPost({ ...dataPost, ...data, customerId: data.customerId })
                        if (res.data.phones) {
                            setDataPhone({ ...dataPhone, customerId: res.data.customerId, phoneId: res.data.phones[0].phoneId })
                        }
                        // setDataAddress(data.address[0])
                        //ถ้าไม่มีข้อมูในฐานข้อมูล จะดึงข้อมูลในบัตรมาโชว์
                        if (data.expdt === null || data.issudt === null) {
                            form.setFieldsValue(
                                {
                                    ...data,
                                    nickname: '',
                                    gender: '',
                                    birthdate: dayjs(data?.birthdate),
                                    issudt: '',
                                    expdt: '',
                                    telp: '',
                                }
                            )
                            setLoading(false)
                        }
                        else {
                            form.setFieldsValue(
                                {
                                    ...data,
                                    nickname: '',
                                    gender: '',
                                    birthdate: dayjs(data?.birthdate),
                                    issudt: dayjs(data?.issudt),
                                    expdt: dayjs(data?.expdt),
                                    telp: '',
                                }
                            )
                        }
                    }
                    setLoading(false)
                }
                else {
                    //alert('ไม่พบข้อมูลในฐานข้อมูล')
                    message.info('ไม่พบข้อมูลในฐานข้อมูล')
                    setDataPost({ ...data, customerId: 0 })
                    // setDataAddress('')
                    setDataPhone('')
                    if (data.expdt === null || data.issudt === null) {
                        form.setFieldsValue(
                            {
                                ...data,
                                nickname: '',
                                gender: '',
                                birthdate: dayjs(data?.birthdate),
                                issudt: '',
                                expdt: '',
                                telp: '',
                            }
                        )
                        setLoading(false)
                    }
                    else {
                        form.setFieldsValue(
                            {
                                ...data,
                                nickname: '',
                                gender: '',
                                birthdate: dayjs(data?.birthdate),
                                issudt: '',
                                expdt: '',
                                telp: '',
                            }
                        )
                    }
                    setLoading(false)
                }
            })
            .catch((err) => console.log(err))
        setLoading(false)
    }
    //ดึงข้อมูลจากรูปบัตรประชาชน
    const handleScanIdCard = async (value) => {
        const imgId = { image: value }
        setLoading(true)
        const headers = { 'Content-Type': 'application/json' }
        await axios.post(testupload, imgId, { headers: headers })
            .then(res => {
                console.log("ดึงข้อมูลตามบัตร", res.data)
                if (res.data) {
                    console.log("res.data", res.data)
                    setDataPost(res.data[0])
                    splitAddress(res.data[0])
                    loadDataOne(res.data[0])
                    setLoading(false)
                }
                else {
                    message.info('ไม่สามารถอ่านได้')
                    setDataPost({})
                    setDataAddress({})
                    setDataPhone({})
                    setLoading(false)
                }
            })
            .catch((err) => console.log(err))
        setLoading(false)
    }
    //กดตรวจบัตรประชาชน 
    const mapAddressStatus = (data) => {
        const newData = data.map((element, index) => (
            {
                ...element,
                addressStatus: element.addressStatus === 1 ? true : false,
                addressOnIdcard: element.addressOnIdcard === 1 ? true : false,
                houseRegistration: element.houseRegistration === 1 ? true : false,
                workAddress: element.workAddress === 1 ? true : false,
                otherAddress: element.otherAddress === 1 ? true : false,
                currentAddress: element.currentAddress === 1 ? true : false,
                sendDocuments: element.sendDocuments === 1 ? true : false,
            }
        ))
        setDataAddress(newData)
    }
    const inputIdCard = (e) => {
        setDataPost({ ...dataPost, identificationId: e.target.value })
        isThaiNationalID(e.target.value)
    }
    const handleIdCard = async () => {
        const checkcard = { identificationId: dataPost.identificationId }
        setLoading(true)
        if (dataPost.identificationId !== undefined && dataPost.identificationId.trim() !== "") {
            await axios.post(checkCCAP2, checkcard)
                .then((res) => {
                    if (res.data) {
                        message.info('มีข้อมูล')
                        setDataPost(res.data)
                        setDataPhone(res.data.phones)// ถ้าใส่ [0] จะไม่สามารถ map ได้ถ้ากดตรวจสอบบัตร
                        mapAddressStatus(res.data.address)
                        form.setFieldsValue(
                            {
                                ...res.data,
                                telp: res?.data?.phones[0]?.telp,
                                birthdate: dayjs(res?.data?.birthdate),
                                issudt: dayjs(res?.data?.issudt),
                                expdt: dayjs(res?.data?.expdt),
                            }
                        )
                        setLoading(false)
                    }
                    else {
                        message.info('ไม่พบข้อมูล')
                        form.setFieldsValue(
                            {
                                snam: '',
                                firstname: '',
                                lastname: '',
                                nickname: '',
                                nationalId: '',
                                birthdate: '',
                                gender: '',
                                address: '',
                                issuby: '',
                                issudt: '',
                                expdt: '',
                                telp: '',
                                email: '',
                                social: '',
                            }
                        )
                        setLoading(false)
                        setDataPost({ ...dataPost, snam: '', firstname: '', lastname: '', nickname: '', national_id: '', birthdate: '', gender: '', address: '', issuby: '', issudt: '', expdt: '', })
                        setDataAddress('')
                        setDataPhone('')
                        getdatanot()
                    }
                })
                .catch((err) => console.log(err))
        } else {
            message.info('กรุณากรอกเลขบัตรประชาชน')
            setLoading(false)
            setDataPost({
                ...dataPost,
                customerId: null,
                snam: '',
                firstname: '',
                lastname: '',
                nickname: '',
                national_id: '',
                birthdate: '',
                gender: '',
                issuby: '',
                issudt: '',
                expdt: ''
            })
            setDataAddress({})
            setDataPhone({})
            form.setFieldsValue(
                {
                    snam: '',
                    firstname: '',
                    lastname: '',
                    nickname: '',
                    nationalId: '',
                    birthdate: '',
                    gender: '',
                    issuby: '',
                    issudt: '',
                    expdt: '',
                    telp: '',
                    image: '',
                }
            )
        }
    }
    //check identifivations Correct?
    function isThaiNationalID(id) {
        if (!/^[0-9]{13}$/g.test(id)) {
            return false
        }
        let i; let sum = 0
        for ((i = 0), (sum = 0); i < 12; i++) {
            sum += Number.parseInt(id.charAt(i)) * (13 - i)

        }
        const checkSum = (11 - sum % 11) % 10
        if (checkSum === Number.parseInt(id.charAt(12))) {
            // console.log(id, 'เลขบัตรถูกต้อง')
            return true, message.info('เลขบัตรถูกต้อง')
        }
        // console.log("เลขบัตรไม่ถูกต้อง", id)
        return false, message.info('เลขบัตรไม่ถูกต้อง')

    }
    //กดเลือกรูปบัตรประชาชนเพื่อเช็คข้อมูล
    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg']
        if (file) {
            if (validImageTypes.includes(file.type)) {
                const reader = new FileReader(); // สร้าง FileReader object
                reader.onloadend = () => { // ฟังก์ชันที่ทำงานเมื่ออ่านไฟล์เสร็จสิ้น    
                    const base64Data = reader.result; // รับข้อมูล Base64 จาก FileReader result
                    //console.log('Base64 Data:', base64Data);
                    setFileList({ image: base64Data })
                    handleScanIdCard(base64Data)
                }
                reader.readAsDataURL(file) // อ่านไฟล์และแปลงเป็น Base64    
            }
        }
    }

    //ส่งค่าแอดเดรสเก็บในตัวแปรใหม่
    const splitAddress = (value) => {
        let updatedCount = { ...count }
        //console.log("โหลด",value.address)
        const part = value.address.split(" ")
        if (part[1].includes("หมู่ที่") === true) {
            //setHouseNumber(part[0] + part[1] + part[2])
            updatedCount.houseNumber = part[0] + part[1] + part[2]
            console.log("moo", updatedCount.houseNumber)

        } else {
            //setHouseNumber(part[0])
            updatedCount.houseNumber = part[0]
            console.log("houseNumber", updatedCount.houseNumber)

        }
        //setMoo(part[2])
        //setDataAddress({...dataAddress, moo: part[2]})
        for (let i = 0; i <= part.length; i++) {
            if (part[i]?.includes("ซ.") === true) {
                const s = part[i].split("ซ.")
                //setSoi(s[1])
                updatedCount.soi = s[1]
                console.log("soi", updatedCount.soi)
            }
            if (part[i]?.includes("ถ.") === true) {
                const tanon = part[i].split("ถ.")
                //setRoad(tanon[1])
                updatedCount.road = tanon[1]
                console.log("road", updatedCount.road)

            }
            if (part[i]?.includes("ต.") === true) {
                const bn = part[i].split("ต.")
                //setSubdistrict(bn[1])
                updatedCount.subdistrict = bn[1]
                console.log("subdistrict", updatedCount.subdistrict)

            }
            if (part[i]?.includes("อ.") === true) {
                const oo = part[i].split("อ.")
                const oop = oo[1].split(",")
                if (oo[1]?.includes(",") === false) {
                    //setDistrict(oo[1])
                    updatedCount.district = oo[1]
                    console.log("district", updatedCount.district)
                }
                if (oop[1]?.includes("จ.") === true) {
                    //setDistrict(oop[0])
                    updatedCount.district = oop[0]
                    const oops = oop[1].split("จ.")
                    //setProvince(oops[1])
                    updatedCount.province = oops[1]
                    console.log("province", updatedCount.province)
                }
            }
            if (part[i]?.includes("จ.") === true) {
                const jh = part[i].split("จ.")
                //setProvince(jh[1])
                updatedCount.province = jh[1]
                console.log("province", updatedCount.province)
            }
        }
        setDataAddress({
            ...dataAddress,
            houseNumber: updatedCount.houseNumber,
            soi: updatedCount.soi,
            road: updatedCount.road,
            subdistrict: updatedCount.subdistrict,
            district: updatedCount.district,
            province: updatedCount.province,
            addressStatus: true,
            addressOnIdcard: true,
            workAddress: false,
            otherAddress: false,
            currentAddress: false,
            sendDocuments: false,
            houseRegistration: false,
        })
    }
    // รวมก้อนข้อมูล
    const sumData = { customer: { ...dataPost }, address: [{ ...dataAddress }] }

    //Phone
    return (
        <>

            <Row justify={'center'}>
                <Card style={{ width: '100%' }}>
                    <Row justify={'center'}>
                        <Row justify={'center'}>
                            <Col className='gutter-row' span={24} style={{ fontSize: '25px', textAlign: 'center' }}>
                                <b>ข้อมูลส่วนตัว</b>
                            </Col>
                        </Row>
                        <Divider style={{ margin: 10 }} />
                        <Spin spinning={loading} size='large' tip="Loading...">
                            <Card style={{ width: '900px' }}>
                                <Row justify={'center'}>
                                    <Form
                                        name="basic"
                                        labelCol={{
                                            span: 8,
                                        }}
                                        wrapperCol={{
                                            span: 16,
                                        }}
                                        style={{
                                            maxWidth: 700,
                                        }}
                                        initialValues={{
                                            remember: true,
                                        }}
                                        form={form}
                                        onFinish={handleSubmit}
                                        autoComplete="off"
                                    >
                                        <Row gutter={32} justify={'center'}>
                                            <Col className='gutter-row' span={24} style={{ textAlign: 'center' }}>
                                                <Form.Item label='เลขประจำตัวประชาชน' name='identificationId'
                                                    style={{ display: 'inline-block' }}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !',
                                                        },]}>

                                                    <Input type='text'
                                                        name='identificationId'
                                                        maxLength={13}
                                                        style={{ display: 'inline-block', width: '250px', marginRight: '5px', marginLeft: '5px' }}
                                                        onChange={(e) => inputIdCard(e)}></Input>
                                                </Form.Item>
                                                <span style={{ display: 'inline-block' }}>
                                                    <Form.Item >
                                                        <Button type="primary" onClick={(e) => { handleIdCard(e) }}>ตรวจสอบ</Button>
                                                    </Form.Item>
                                                </span>
                                            </Col>
                                        </Row>

                                        <Form.Item label='รูปบัตรประชาชน' name="image">
                                            <Input type="file" name="image" ref={inputRef} multiple={true}
                                                style={{ width: '250px', margin: 0 }}
                                                onChange={handleFileInputChange} >
                                            </Input>
                                        </Form.Item>

                                        <Form.Item label="คำนำหน้าชื่อ" name="snam"
                                            rules={[{
                                                required: true, message: 'Please input !',
                                            },]}>
                                            <Radio.Group onChange={(e) => setDataPost({ ...dataPost, snam: e.target.value })} >
                                                <Radio value="นาย"> นาย </Radio>
                                                <Radio value="นาง"> นาง </Radio>
                                                <Radio value="นางสาว"> นางสาว </Radio>
                                            </Radio.Group>
                                        </Form.Item>

                                        <Row gutter={32}>
                                            <Col className='gutter-row' span={12}>
                                                <Form.Item label='ชื่อ' name='firstname'
                                                    rules={[{
                                                        required: true, message: 'Please input !',
                                                    },]}
                                                >
                                                    <Input type='text'
                                                        onChange={(e) => setDataPost({ ...dataPost, firstname: e.target.value })}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col className='gutter-row' span={12}>
                                                <Form.Item label='นามสกุล' name='lastname'
                                                    rules={[{
                                                        required: true, message: 'Please input !',
                                                    },]}>
                                                    <Input type='text'
                                                        onChange={(e) => setDataPost({ ...dataPost, lastname: e.target.value })}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={32}>
                                            <Col className='gutter-row' span={12}>
                                                <Form.Item label='ชื่อเล่น' name='nickname'
                                                // rules={[{
                                                //     required: true, message: 'Please input !',
                                                // },]}
                                                >
                                                    <Input type='text'
                                                        onChange={(e) => setDataPost({ ...dataPost, nickname: e.target.value })}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col className='gutter-row' span={12}>
                                                <Form.Item label='เพศ' name='gender'
                                                    rules={[{
                                                        required: true, message: 'Please input !',
                                                    },]}
                                                >
                                                    <Select
                                                        placeholder={'เลือก'}
                                                        style={{ width: '165px', height: '40px' }}
                                                        onChange={(value) => setDataPost({ ...dataPost, gender: value })}
                                                        options={[
                                                            {
                                                                label: 'ชาย',
                                                                value: 'M',
                                                            },
                                                            {
                                                                label: 'หญิง',
                                                                value: 'F',
                                                            },
                                                        ]}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={32}>
                                            <Col className='gutter-row' span={24}>
                                                <Form.Item label='เกิดวันที่' name='birthdate'
                                                    style={{ lineHeight: '32px' }}
                                                    rules={[{
                                                        required: true, message: 'Please input !',
                                                    },]}
                                                >
                                                    <DatePicker
                                                        format={'YYYY-MM-DD'}
                                                        style={{ width: '165px', height: '40px' }}
                                                        onChange={(e) => {
                                                            if (e) {
                                                                setDataPost({ ...dataPost, birthdate: e.format('YYYY-MM-DD') })
                                                            }
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        {/* <Row justify={'center'}>
                                            <Col className='gutter-row' span={19}>
                                                <Form.Item
                                                    label='ที่อยู่'
                                                    name='address'
                                                >
                                                    <TextArea
                                                        rows={4}
                                                        type='text'
                                                        disabled
                                                        onChange={(e) => setDataAddress({ ...dataAddress, address: e.target.value })} />
                                                </Form.Item>
                                            </Col>
                                            <Col className='gutter-row' span={5}>
                                            </Col>
                                        </Row> */}
                                        {/* <Row justify={'center'}>
                                            <Col className='gutter-row' span={24}>
                                                <Form.Item label='เบอร์โทรติดต่อ' name='telp'
                                                    rules={[{
                                                        required: true, message: 'Please input !',
                                                    },]}
                                                >
                                                    <Input type='text'
                                                        style={{ width: '200px' }}
                                                        // onChange={(e) => { handlePhone(e) }}
                                                        onChange={(e) => setDataPost({ ...dataPost, telp: e.target.value })}
                                                    >

                                                    </Input>
                                                </Form.Item>
                                            </Col>
                                        </Row> */}
                                        <Row gutter={32}>
                                            <Col className='gutter-row' span={24}>
                                                <Form.Item label='สถานที่ออกบัตรประชาชน' name='issuby'
                                                    rules={[{
                                                        required: true, message: 'Please input !',
                                                    },]}>
                                                    <Input type='text'
                                                        style={{ width: '200px' }}
                                                        onChange={(e) => setDataPost({ ...dataPost, issuby: e.target.value })}></Input>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={32}>
                                            <Col className='gutter-row' span={12}>
                                                <Form.Item label='วันออกบัตร' name='issudt'
                                                    rules={[{
                                                        required: true, message: 'Please input !',
                                                    },]}>
                                                    <DatePicker format={'YYYY-MM-DD'}
                                                        style={{ height: '40px' }}
                                                        onChange={(e) => {
                                                            if (e) {
                                                                setDataPost({ ...dataPost, issudt: e.format('YYYY-MM-DD') })
                                                            }
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col className='gutter-row' span={12}>
                                                <Form.Item label='วันบัตรหมดอายุ' name='expdt'
                                                    rules={[{
                                                        required: true, message: 'Please input !',
                                                    },]}>
                                                    <DatePicker format={'YYYY-MM-DD'}
                                                        style={{ height: '40px' }}
                                                        onChange={(e) => {
                                                            if (e) {
                                                                setDataPost({ ...dataPost, expdt: e.format('YYYY-MM-DD') })
                                                            }
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={32} justify={'center'}>
                                            <Button
                                                style={{ width: '100px', textAlign: 'center', background: '#bfbfbf', color: '#000000', marginRight: '5px' }}
                                                onClick={handleReset}
                                            >
                                                รีเซ็ตค่า
                                            </Button>
                                            <Button
                                                style={{ width: '100px', textAlign: 'center', marginLeft: '5px' }}
                                                type="primary"
                                                htmlType='submit'
                                                onClick={handleSubmit}
                                            >
                                                บันทึก
                                            </Button>
                                        </Row>

                                    </Form>
                                </Row>
                            </Card>
                        </Spin>
                        <Divider />

                    </Row>
                    <Row gutter={32}>
                        <Col className='gutter-row' span={24} style={{ textAlign: 'right' }} >
                            <Button
                                style={{ marginBottom: 0 }}
                                type='primary'
                                onClick={handleNext}
                            >
                                ต่อไป
                            </Button>
                        </Col>
                    </Row>
                </Card>
            </Row>
            {contextHolder}
        </>
    )
};
