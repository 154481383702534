import React, { useState, useEffect } from "react";
import { Button, Card, Col, Divider, Form, Input, Row, Space, Spin, Table, notification, InputNumber, Tag, DatePicker, Checkbox } from 'antd'
import axios from 'axios';
import dayjs from "dayjs";
import ExcelJS from 'exceljs';
import { DataExcelPay } from './excel/data_excel';
import { mainDataExcel, headersThai, headersColumsName } from './excel/cl';

export default function Main() {
  // const token = localStorage.getItem('token')
  const currentDateTime = dayjs()
  const [form] = Form.useForm();
  const [checkGuas, setCheckGuas] = useState("")
  const [checkDue, setCheckDue] = useState(false)
  const [checkCt, setCheckCt] = useState(true)
  const [api, contextHolder] = notification.useNotification();
  const [dataSend, setDataSend] = useState({
    CONTNO: "",
    // CONTNO: "3-0001310",
    money: 0,
    sale: 0,
    date: currentDateTime,
    payType: "จ่ายเข้าตารางดิว",
  });
  const [dataTable, setDataTable] = useState([]);
  const [dataPay, setDataPay] = useState([]);
  const [calData, setCalData] = useState({
    ALLPAYAMT: 0,
    ALLPAYFOLLOW: 0,
    ALLPAYINTEFF: 0,
    ALLPAYTON: 0,
    ALLCAL: 0,
    text: "",
  });
  const [sum, setSum] = useState({
    sumAmt: 0,
    sumFollow: 0,
    sumAll: 0,
  });
  const [loading, setLoading] = useState(false);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [recordData, setRecordData] = useState();
  // useEffect(() => {
  //   const delayDebounceFn = setTimeout(() => {
  //     searchLoadData(dataSend.money)
  //   }, 1500)
  //   return () => clearTimeout(delayDebounceFn)
  // }, [dataSend.money])

  // const searchLoadData = async (data) => {
  //   console.log("searchLoadData data", data)
  //   if (data > 0) {
  //     console.log("OK searchLoadData data")
  //     await pay(data)
  //   }
  // }

  // const pay = async (data) => {
  //   if (dataSend.CONTNO != "" && data > 0) {
  //     let mixData = { CONTNO: dataSend.CONTNO, money: data }
  //     // const tk = JSON.parse(token)
  //     // const headers = {
  //     //   "Authorization": `Bearer ${tk}`,
  //     //   "Menu": JSON.stringify("5")
  //     // }
  //     setLoading(true)
  //     // await axios.post(checkcontnopgre, dataSend, { headers: headers })
  //     await axios.post("http://localhost:8070/api-db2/payamt", mixData)
  //       .then((res) => {
  //         if (res.status === 200) {
  //           console.log("res.data payamt", res.data)
  //           setDataTable(res.data)
  //         } else if (res.status === 202) {
  //           console.log("ไม่ได้จ่ายล่วงหน้า คิดดอกเป็นรายวัน", res.data)
  //         } else if (res.status === 203) {
  //           console.log("จำนวนเงินที่จ่ายเข้ามา มากกว่าเงินต้นคงเหลือ")
  //           msLimit("top")
  //         } else if (res.status === 204) {
  //           console.log("กรอกยอดเงินไม่ถูกต้อง")
  //           msNil("top")
  //         } else {
  //           msNil("top")
  //         }
  //         setLoading(false)
  //       })
  //       .catch((err) => {
  //         console.log("err", err)
  //         msError("top")
  //         setLoading(false)
  //       })
  //   } else {
  //     msNil("top")
  //   }
  // }
  // const funcContno = (value) => {
  //   if (value <= calData?.ALLPAYINTEFF) {
  //     setDataSend({ ...dataSend, sale: parseFloat(value) })
  //   } else {
  //     setDataSend({ ...dataSend, sale: 0 })
  //     form.setFieldsValue({ sale: "", });
  //     msSale("top")
  //   }
  // }
  const funcSale = (value) => {
    console.log("vv", value)
    console.log("calData?.ALLPAYINTEFF", calData?.ALLPAYINTEFF)
    if (value <= calData?.ALLPAYINTEFF) {
      setDataSend({ ...dataSend, sale: parseFloat(value) })
    } else {
      setDataSend({ ...dataSend, sale: 0 })
      form.setFieldsValue({ sale: "", });
      msSale("top")
    }
  }
  const onChangecheckGuas = (value) => {
    // console.log(value)
    setCheckGuas(value);
    setDataSend({ ...dataSend, payType: value })
  };

  const calPay = async (value, note) => {
    console.log("dataPay", dataPay) // ค่าแรก
    console.log("value", value) // ค่าที่กดคำนวณ
    console.log("note", note)
    console.log("value?.Dok", value?.Dok)
    console.log("dataSend.sale", dataSend.sale) // ส่วนลด
    var AMT = 0
    var FOLLOW = 0
    var INTEFF = 0
    var TON = 0
    var all = 0
    if (note === "") {
      if (dataPay?.ALLPAYAMT && dataPay?.ALLPAYFOLLOW && dataPay?.ALLPAYINTEFF && dataPay?.ALLPAYTON) {
        AMT = value.ALLPAYAMT - dataPay.ALLPAYAMT
        FOLLOW = value.ALLPAYFOLLOW - dataPay.ALLPAYFOLLOW
        INTEFF = value.ALLPAYINTEFF - dataPay.ALLPAYINTEFF
        TON = (value.ALLPAYTON - dataPay.ALLPAYTON)
      } else {
        AMT = value.ALLPAYAMT
        FOLLOW = value.ALLPAYFOLLOW
        INTEFF = value.ALLPAYINTEFF
        TON = value.ALLPAYTON
      }
      // console.log("AMT", AMT)
      // console.log("FOLLOW", FOLLOW)
      // console.log("INTEFF", INTEFF)
      // console.log("TON", TON)
      if (value?.PaySm > 0) {
        if (value?.Dok > 0) {
          all = (value.ALLPAYFOLLOW - dataPay.ALLPAYFOLLOW) + (value.ALLPAYINTEFF - dataPay.ALLPAYINTEFF) + (value.ALLPAYTON - dataPay.ALLPAYTON) + (value?.PaySm + value?.Dok)
          console.log("value.ALLPAYFOLLOW", value.ALLPAYFOLLOW)
          console.log("dataPay.ALLPAYFOLLOW", dataPay.ALLPAYFOLLOW)
          console.log("value.ALLPAYINTEFF", value.ALLPAYINTEFF)
          console.log("dataPay.ALLPAYINTEFF", dataPay.ALLPAYINTEFF)
          console.log("value.ALLPAYTON", value.ALLPAYTON)
          console.log("dataPay.ALLPAYTON", dataPay.ALLPAYTON)
          console.log("value?.PaySm", value?.PaySm)
          console.log("value?.Dok", value?.Dok)
        } else {
          all = (value.ALLPAYFOLLOW - dataPay.ALLPAYFOLLOW) + (value.ALLPAYINTEFF - dataPay.ALLPAYINTEFF) + (value.ALLPAYTON - dataPay.ALLPAYTON) + value?.PaySm
          console.log("value.ALLPAYFOLLOW", value.ALLPAYFOLLOW)
          console.log("dataPay.ALLPAYFOLLOW", dataPay.ALLPAYFOLLOW)
          console.log("value.ALLPAYINTEFF", value.ALLPAYINTEFF)
          console.log("dataPay.ALLPAYINTEFF", dataPay.ALLPAYINTEFF)
          console.log("value.ALLPAYTON", value.ALLPAYTON)
          console.log("dataPay.ALLPAYTON", dataPay.ALLPAYTON)
          console.log("value?.PaySm", value?.PaySm)
        }
        setCalData({
          ALLPAYAMT: AMT,
          ALLPAYFOLLOW: FOLLOW,
          ALLPAYINTEFF: INTEFF,
          ALLPAYTON: TON,
          ALLCAL: all,
          PaySm: value?.PaySm,
          Dok: value?.Dok,
          ccb: value?.ccb,
          text: "",
        })
      } else {
        if (dataPay.ALLPAYFOLLOW && dataPay.ALLPAYINTEFF && dataPay.ALLPAYTON) {
          all = (value.ALLPAYFOLLOW - dataPay.ALLPAYFOLLOW) + (value.ALLPAYINTEFF - dataPay.ALLPAYINTEFF) + (value.ALLPAYTON - dataPay.ALLPAYTON)
        } else {
          all = value.ALLPAYFOLLOW + value.ALLPAYINTEFF + value.ALLPAYTON
        }
        setCalData({
          ALLPAYAMT: AMT,
          ALLPAYFOLLOW: FOLLOW,
          ALLPAYINTEFF: INTEFF,
          ALLPAYTON: TON,
          ALLCAL: all,
          ccb: value?.ccb,
          text: "",
        })
      }
    } else {
      if (value?.NewTonKong < 0) { // เงินที่จ่ายเข้ามาต้องไม่เกินต้นคงเหลือใหม่
        setCalData({
          ALLPAYAMT: 0,
          ALLPAYFOLLOW: 0,
          ALLPAYINTEFF: 0,
          ALLPAYTON: 0,
          ALLCAL: value.ALLPAYTON + value.NewTonKong,
          text: "เงินที่จ่ายเข้ามาเกินเงินต้นคงเหลือ"
        })
      } else {
        console.log(value.ALLPAYTON, value.ALLPAYINTEFF)
        setCalData({
          ALLPAYAMT: 0,
          ALLPAYFOLLOW: 0,
          ALLPAYINTEFF: value.ALLPAYINTEFF,
          ALLPAYTON: value.ALLPAYTON,
          ALLCAL: value.ALLPAYTON + value.ALLPAYINTEFF,
          text: "ไม่ตัดเข้าตารางดิว"
        })
      }
    }
  }
  // console.log(calData)

  const handleSubmitPay = async () => {
    if (dataSend.CONTNO !== "" && dataSend.money > 0) {
      if (dataSend?.sale > 0) {
        var mixData = { CONTNO: dataSend.CONTNO, money: dataSend.money, sale: dataSend.sale, date: dataSend.date, payType: dataSend.payType }
      } else {
        var mixData = { CONTNO: dataSend.CONTNO, money: dataSend.money, sale: 0, date: dataSend.date, payType: dataSend.payType }
      }
      console.log("mixData", mixData)
      // const tk = JSON.parse(token)
      // const headers = {
      //   "Authorization": `Bearer ${tk}`,
      //   "Menu": JSON.stringify("5")
      // }
      setLoading(true)
      // await axios.post(checkcontnopgre, dataSend, { headers: headers })
      // await axios.post("http://localhost:8070/api-db2/payamt", mixData)
      await axios.post("https://api-search-contno-db2-i67f6gaaqa-as.a.run.app/api-db2/payamt", mixData)
        .then(async (res) => {
          if (res.status === 200) {
            console.log("res.data payamt", res.data)
            await calPay(res.data, "")
            setDataTable(res.data.result)
          } else if (res.status === 202) {
            console.log("ไม่ได้จ่ายล่วงหน้า คิดดอกเป็นรายวัน", res.data)
            await calPay(res.data, "ไม่ได้จ่ายล่วงหน้า คิดดอกเป็นรายวัน")
            setDataTable([])
            // setDataPay([])
          } else if (res.status === 203) {
            console.log("จำนวนเงินที่จ่ายเข้ามา มากกว่าเงินต้นคงเหลือ", res.data)
            setCalData({ ALLPAYAMT: 0, ALLPAYFOLLOW: 0, ALLPAYINTEFF: 0, ALLPAYTON: 0, ALLCAL: res.data.ccb, text: "จำนวนเงินที่จ่ายเข้ามา มากกว่าเงินต้นคงเหลือ" })
            setDataTable([])
            // setDataPay([])
            msLimit("top")
          } else if (res.status === 204) {
            console.log("กรอกยอดเงินไม่ถูกต้อง")
            setCalData({ ALLPAYAMT: 0, ALLPAYFOLLOW: 0, ALLPAYINTEFF: 0, ALLPAYTON: 0, ALLCAL: 0, text: "กรอกยอดเงินไม่ถูกต้อง" })
            setDataTable([])
            // setDataPay([])
            msNil("top")
          } else if (res.status === 205) {
            console.log("เงินน้อยกว่าดอกเบี้ยสร้างดิว")
            msCreateDue("top")
          } else {
            setCalData({ ALLPAYAMT: 0, ALLPAYFOLLOW: 0, ALLPAYINTEFF: 0, ALLPAYTON: 0, ALLCAL: 0, text: "" })
            setDataTable([])
            // setDataPay([])
            msNil("top")
          }
          setLoading(false)
        })
        .catch((err) => {
          console.log("err", err)
          setCalData({ ALLPAYAMT: 0, ALLPAYFOLLOW: 0, ALLPAYINTEFF: 0, ALLPAYTON: 0, ALLCAL: 0, text: "" })
          setDataTable([])
          // setDataPay([])
          msError("top")
          setLoading(false)
        })
    } else {
      msNil("top")
    }
  }

  const handleSubmit = async () => {
    if (dataSend?.CONTNO !== "") {
      setCheckCt(false)
      let sumAmt = 0;
      let sumFollow = 0;
      let sumAll = 0;
      // const tk = JSON.parse(token)
      // const headers = {
      //   "Authorization": `Bearer ${tk}`,
      //   "Menu": JSON.stringify("5")
      // }
      setLoading(true)
      // await axios.post(checkcontnopgre, dataSend, { headers: headers })
      // await axios.post("http://localhost:8070/api-db2/load-payamt", dataSend)
      await axios.post("https://api-search-contno-db2-i67f6gaaqa-as.a.run.app/api-db2/load-payamt", dataSend)
        .then(async (res) => {
          if (res.status === 200) {
            onChangecheckGuas("จ่ายเข้าตารางดิว")
            console.log("res.data load-payamt", res.data)
            setDataPay(res.data)
            setDataSend({ ...dataSend, money: 0, sale: 0 })
            if (res.data?.result) {
              setCheckDue(true)
              setDataTable(res.data.result)
              await res.data.result.forEach((item) => {
                sumAmt += item.DUEAMT - item.PAYAMT;
                sumFollow += item.FOLLOWAMT - item.PAYFOLLOW;
              });
              sumAll = sumAmt + sumFollow
              setSum({ sumAmt: sumAmt, sumFollow: sumFollow, sumAll: sumAll })
              console.log("sumAmt", sumAmt)
              console.log("sumFollow", sumFollow)
            } else {
              setCheckDue(false)
              setDataTable([])
            }
            setCalData({ ALLPAYAMT: 0, ALLPAYFOLLOW: 0, ALLPAYINTEFF: 0, ALLPAYTON: 0, ALLCAL: 0, text: "" })
            form.setFieldsValue({
              money: "",
              sale: "",
            });
          } else {
            setCalData({ ALLPAYAMT: 0, ALLPAYFOLLOW: 0, ALLPAYINTEFF: 0, ALLPAYTON: 0, ALLCAL: 0, text: "" })
            setDataTable([])
            setDataPay([])
            setSum({ sumAmt: 0, sumFollow: 0, sumAll: 0 })
            msDue("top")
          }
          setLoading(false)
        })
        .catch((err) => {
          console.log("err", err)
          setCalData({ ALLPAYAMT: 0, ALLPAYFOLLOW: 0, ALLPAYINTEFF: 0, ALLPAYTON: 0, ALLCAL: 0, text: "" })
          setDataTable([])
          setDataPay([])
          msError("top")
          setLoading(false)
        })
    } else {

    }
  }

  const currencyFormatOne = (amount) => {
    if (amount) {
      return Number(amount)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    } else {
      return "-"
    }
  }

  const insert = async () => {
    if (dataTable.length > 0) {
      console.log("dataTable", dataTable)
      // const tk = JSON.parse(token)
      // const headers = {
      //   "Authorization": `Bearer ${tk}`,
      //   "Menu": JSON.stringify("5")
      // }
      setLoading(true)
      // await axios.post(checkcontnopgre, dataSend, { headers: headers })
      await axios.post("http://localhost:8060/pay/data-due", dataTable)
        .then(async (res) => {
          if (res.status === 200) {
            console.log("ok", res.data)
          }
          setLoading(false)
        })
        .catch((err) => {
          console.log("err", err)
          msError("top")
          setLoading(false)
        })
    } else {
      msError("top")
    }
  }

  // const msSuccess = (placement) => {
  //   api.success({
  //     message: "อนุมัติ",
  //     // description:
  //     //     'หมายเหตุที่ถูกเพิ่ม สามารถเลือกได้ในช่อง หมายเหตุ',
  //     placement,
  //   });
  // };
  const msSale = (placement) => {
    api.error({
      message: "คำเตือน !!!",
      description:
        'ไม่สามารถใส่ส่วนลดเกินจำนวนดอกเบี้ยได้',
      placement,
    });
  };
  const msError = (placement) => {
    api.error({
      message: "error",
      description:
        'กรุณาติดต่อไอที',
      placement,
    });
  };
  const msDue = (placement) => {
    api.error({
      message: "error",
      description:
        'ไม่พบข้อมูลตารางดิว',
      placement,
    });
  };
  const msNil = (placement) => {
    api.error({
      message: "error",
      description:
        'กรุณากรอกข้อมูลให้ถูกต้อง *ยอดที่จ่ายต้องไม่เป็น 0 หรือมีค่าติดลบ',
      placement,
    });
  };
  const msLimit = (placement) => {
    api.warning({
      message: "คำเตือน",
      description:
        'จำนวนเงินที่จ่ายเข้ามา มากกว่าเงินต้นคงเหลือ',
      placement,
    });
  };
  const msCreateDue = (placement) => {
    api.warning({
      message: "คำเตือนควรสร้างดิว",
      description:
        'จำนวนเงินที่จ่ายเข้ามา น้อยกว่าดอกเบี้ย',
      placement,
    });
  };

  const exportToExcel = async () => {
    if (dataTable?.length > 0) {
      var allData = []
      allData = await DataExcelPay(allData, dataTable);
      const workbook = new ExcelJS.Workbook();

      // สร้างชีทและเพิ่มข้อมูลลงในแต่ละชีท
      const addSheet = (sheetName, dataSource, headers_colums_name, headers_thai) => {

        const worksheet = workbook.addWorksheet(sheetName);
        // กำหนดความกว้างของแต่ละคอลัมน์
        worksheet.columns = mainDataExcel
        worksheet.addRow(headers_thai);
        dataSource.forEach((row) => {
          const rowData = headers_colums_name.map((column) => row[column]);
          worksheet.addRow(rowData);
        });
      };

      // หัวข้อใน Excel

      const headers_thai = headersThai
      const headers_colums_name = headersColumsName
      // // นำหัวข้อลงในแถวแรกของ Excel
      // // เพิ่มข้อมูลลงในแต่ละชีท 
      addSheet(`รายงานรับเงินสัญญา ${dataSend.CONTNO}`, allData, headers_colums_name, headers_thai);
      // addSheet(`รายงานรับเงินสัญญา ${"3-0001310"}`, allData, headers_colums_name, headers_thai);
      // console.log("addSheet")

      // บันทึกไฟล์ Excel
      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);

        // สร้างลิงก์สำหรับดาวน์โหลดไฟล์ Excel
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `dataPay ${dataSend.CONTNO}.xlsx`);
        // link.setAttribute('download', `dataPay ${"3-0001310"}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    } else {
      console.log("ไม่มีข้อมูล")
    }
  }


  const columns = [
    // {
    //   title: "ลำดับ",
    //   dataIndex: "index",
    //   key: 'index',
    //   width: "auto",
    //   align: 'center',
    //   render: (text, object, index) => index + 1
    // },
    {
      title: "เลขสัญญา",
      dataIndex: "CONTNO",
      key: 'index',
      align: 'center',
      width: "auto",
    },
    {
      title: "งวดที่",
      dataIndex: "NOPAY",
      key: 'index',
      align: 'center',
      width: "auto",
    },
    {
      title: "เงินต้นคงเหลือ",
      dataIndex: "TONBALANCE",
      key: 'index',
      align: 'center',
      width: "auto",
      render: (text, record) => (
        <Space size="middle">
          <>{currencyFormatOne(record.TONBALANCE)}</>
        </Space>
      ),
    },
    {
      title: "จำนวนวัน",
      dataIndex: "DELAYDAY",
      key: 'index',
      align: 'center',
      width: "auto",
    },
    {
      title: "วันกำหนดชำระ",
      dataIndex: "DUEDATE",
      key: 'index',
      align: 'center',
      width: "auto",
      render: (text, record) => (
        <>{dayjs(record.DUEDATE).format("DD-MM-YYYY")}</>
      ),
    },
    {
      title: "ค่างวด",
      dataIndex: "DUEAMT",
      key: 'index',
      align: 'center',
      width: "auto",
      render: (text, record) => (
        <Space size="middle">
          <>{currencyFormatOne(record.DUEAMT)}</>
        </Space>
      ),
    },
    {
      title: "ดอกเบี้ย",
      dataIndex: "DUEINTEFF",
      key: 'index',
      align: 'center',
      width: "auto",
      render: (text, record) => (
        <Space size="middle">
          <>{currencyFormatOne(record.DUEINTEFF)}</>
        </Space>
      ),
    },
    {
      title: "เงินต้น",
      dataIndex: "DUETONEFF",
      key: 'index',
      align: 'center',
      width: "auto",
      render: (text, record) => (
        <Space size="middle">
          <>{currencyFormatOne(record.DUETONEFF)}</>
        </Space>
      ),
    },
    {
      title: "ค่าทวงถาม",
      dataIndex: "FOLLOWAMT",
      key: 'index',
      align: 'center',
      width: "auto",
    },
    {
      title: "วันที่ชำระ",
      dataIndex: "PAYDATE",
      key: 'index',
      align: 'center',
      width: "auto",
      render: (text, record) => (
        <Space size="middle">
          {
            record.PAYDATE !== "" ?
              <>{dayjs(record.PAYDATE).format("DD-MM-YYYY")}</>
              : "-"
          }
        </Space>
      ),
    },
    {
      title: "ชำระค่างวด",
      dataIndex: "PAYAMT",
      key: 'index',
      align: 'center',
      width: "auto",
      render: (text, record) => (
        <Space size="middle">
          <>{currencyFormatOne(record.PAYAMT)}</>
        </Space>
      ),
    },
    {
      title: "ชำระดอกเบี้ย",
      dataIndex: "PAYINTEFF",
      key: 'index',
      align: 'center',
      width: "auto",
      render: (text, record) => (
        <Space size="middle">
          <>{currencyFormatOne(record.PAYINTEFF)}</>
        </Space>
      ),
    },
    {
      title: "ชำระเงินต้น",
      dataIndex: "PAYTON",
      key: 'index',
      align: 'center',
      width: "auto",
      render: (text, record) => (
        <Space size="middle">
          <>{currencyFormatOne(record.PAYTON)}</>
        </Space>
      ),
    },
    {
      title: "ชำระค่าทวงถาม",
      dataIndex: "PAYFOLLOW",
      key: 'index',
      align: 'center',
      width: "auto",
      render: (text, record) => (
        <Space size="middle">
          <>{currencyFormatOne(record.PAYFOLLOW)}</>
        </Space>
      ),
    },
  ];

  return (
    <Card style={{ height: '100%' }}>
      <Form
        form={form}
      >
        <Row style={{ textAlign: 'center' }}>
          <Col span={24}>
            <Spin spinning={loading} size='large' tip="Loading...">

              <Col span={24}>
                <div className='text-center'>
                  <h2>รับเงิน test</h2>
                </div>
                <div className='text-right'>
                  <Space>
                    <Button onClick={exportToExcel}>Export to Excel</Button>
                    <Button disabled type='primary' onClick={insert} >Insert-mysql</Button>
                  </Space>
                </div>
              </Col>

              {/* <Col span={24} style={{ textAlign: 'right' }}>

              </Col> */}
              <Divider />
              <Row gutter={16}>
                <Col span={8}>
                  <Card title="รายละเอียดสัญญา" bordered={false}>
                    <Form.Item label="วันที่ชำระ">
                      <DatePicker format={'YYYY-MM-DD'}
                        defaultValue={currentDateTime}
                        style={{ height: '30px', width: '100%' }}
                        onChange={(e) => {
                          if (e) {
                            setDataSend({ ...dataSend, date: e.format("YYYY-MM-DDTHH:mm:ssZ") })
                          }
                        }} />
                    </Form.Item>
                    <Space>
                      <Form.Item label="เลขสัญญา">
                        {/* <Input disabled value={"3-0001310"} onChange={(e) => setDataSend({ ...dataSend, CONTNO: e.target.value })}></Input> */}
                        <Input onChange={(e) => setDataSend({ ...dataSend, CONTNO: e.target.value })}></Input>
                      </Form.Item>
                      <Form.Item label="">
                        <Button type='primary' onClick={handleSubmit} >ตกลง</Button>
                      </Form.Item>
                    </Space>
                    <Divider />
                    {
                      calData?.ccb > 0 ?
                        <b> <div
                          style={{ margin: 0, }}
                        >
                          <span>ยอดปิด : </span>
                          <b style={{ fontSize: '20px', color: 'red' }}><u>{currencyFormatOne(calData?.ccb)}</u></b>
                          <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                            บาท
                          </span>
                        </div>
                          <Divider />
                        </b>
                        : dataPay?.ccb > 0 ?
                          <b> <div
                            style={{ margin: 0, }}
                          >
                            <span>ยอดปิด : </span>
                            <b style={{ fontSize: '20px', color: 'red' }}><u>{currencyFormatOne(dataPay?.ccb)}</u></b>
                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                              บาท
                            </span>
                          </div>
                            <Divider />
                          </b>
                          : null
                    }
                    <Col span={16}>
                      <Form.Item label="ค้างค่างวด" style={{ margin: 0 }}>
                        <b>{currencyFormatOne(sum?.sumAmt)}</b>
                        <span style={{ marginLeft: '10px' }}>บาท</span>
                      </Form.Item>
                      <Form.Item label="ค้างค่าทวงถาม" style={{ margin: 0 }}>
                        <b>{currencyFormatOne(sum?.sumFollow)}</b>
                        <span style={{ marginLeft: '10px' }}>บาท</span>
                      </Form.Item>
                      <Form.Item label="รวม" style={{ margin: 0 }}>
                        <b>{currencyFormatOne(sum?.sumAll)}</b>
                        <span style={{ marginLeft: '10px' }}>บาท</span>
                      </Form.Item>
                    </Col>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card title="รับเงิน" bordered={false}>
                    <Checkbox
                      style={{ fontSize: '16px' }}
                      checked={checkGuas === "จ่ายเข้าตารางดิว"}
                      isPacked={true}
                      onChange={() => onChangecheckGuas("จ่ายเข้าตารางดิว")}
                    >จ่ายเข้าตารางดิว
                    </Checkbox>
                    <Checkbox
                      style={{ fontSize: '16px' }}
                      checked={checkGuas === "จ่ายแบบไม่เข้าตารางดิว"}
                      isPacked={true}
                      disabled={checkDue}
                      onChange={() => onChangecheckGuas("จ่ายแบบไม่เข้าตารางดิว")}
                    >จ่ายแบบไม่เข้าตารางดิว
                    </Checkbox>
                    <Divider />
                    {
                      checkGuas !== "" ?
                        <>
                          <Form.Item label="จำนวนเงิน" name="money">
                            <InputNumber
                              disabled={checkCt}
                              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                              size="large"
                              style={{ width: '100%', color: 'black' }}
                              onChange={(value) => setDataSend({ ...dataSend, money: parseFloat(value) })}
                            />
                          </Form.Item>
                          <Form.Item label="ใส่ส่วนลด" name="sale">
                            <InputNumber
                              disabled={checkCt}
                              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                              size="large"
                              style={{ width: '100%', color: 'black' }}
                              onChange={(value) => funcSale(value)}
                            />
                          </Form.Item>
                          <Form.Item label="">
                            <Button type='primary' onClick={handleSubmitPay} >คำนวณ</Button>
                          </Form.Item>
                        </>
                        : null
                    }
                  </Card>
                </Col>
                <Col span={8}>
                  <Card title="รายละเอียดที่คำนวณได้" bordered={false}>
                    {
                      calData.text === "" ?
                        <Col span={16}>
                          <Form.Item label="ชำระค่างวด" style={{ margin: 0 }}>
                            <b>{currencyFormatOne(calData.ALLPAYAMT)}</b>
                            <span style={{ marginLeft: '10px' }}>บาท</span>
                          </Form.Item>
                          <Form.Item label="ชำระดอกเบี้ย" style={{ margin: 0 }}>
                            <b>{currencyFormatOne(calData.ALLPAYINTEFF)}</b>
                            <span style={{ marginLeft: '10px' }}>บาท</span>
                          </Form.Item>
                          <Form.Item label="ชำระเงินต้น" style={{ margin: 0 }}>
                            <b>{currencyFormatOne(calData.ALLPAYTON)}</b>
                            <span style={{ marginLeft: '10px' }}>บาท</span>
                          </Form.Item>
                          <Form.Item label="ชำระค่าทวงถาม" style={{ margin: 0 }}>
                            <b>{currencyFormatOne(calData.ALLPAYFOLLOW)}</b>
                            <span style={{ marginLeft: '10px' }}>บาท</span>
                          </Form.Item>
                          {
                            calData?.Dok > 0 ?
                              <Form.Item label="ดอกเบี้ยรายวัน" style={{ margin: 0 }}>
                                <b>{currencyFormatOne(calData.Dok)}</b>
                                <span style={{ marginLeft: '10px' }}>บาท</span>
                              </Form.Item>
                              : null
                          }
                          {
                            calData?.PaySm > 0 ?
                              <Form.Item label="ตัดเข้าเงินต้นคงเหลือ" style={{ margin: 0 }}>
                                <b>{currencyFormatOne(calData.PaySm)}</b>
                                <span style={{ marginLeft: '10px' }}>บาท</span>
                              </Form.Item>
                              : null
                          }
                          {
                            dataSend?.sale > 0 ?
                              <Form.Item label="ส่วนลด" style={{ margin: 0 }}>
                                <b>{currencyFormatOne(dataSend?.sale)}</b>
                                <span style={{ marginLeft: '10px' }}>บาท</span>
                              </Form.Item>
                              : null
                          }
                          <Form.Item label="รวมยอดชำระ" style={{ margin: 0 }}>
                            <b>{currencyFormatOne(calData.ALLCAL)}</b>
                            <span style={{ marginLeft: '10px' }}>บาท</span>
                          </Form.Item>
                        </Col>
                        : calData.text === "เงินที่จ่ายเข้ามาเกินเงินต้นคงเหลือ" || calData.text === "จำนวนเงินที่จ่ายเข้ามา มากกว่าเงินต้นคงเหลือ" ?
                          <Col span={16}>
                            <Form.Item label="Note" style={{ margin: 0 }}>
                              <Tag color="geekblue">{calData.text}</Tag>
                            </Form.Item>
                            <Form.Item label="ยอดที่รับได้ไม่เกิน" style={{ margin: 0 }}>
                              <b>{currencyFormatOne(calData.ALLCAL)}</b>
                              <span style={{ marginLeft: '10px' }}>บาท</span>
                            </Form.Item>
                          </Col>
                          : calData.text === "ไม่ตัดเข้าตารางดิว" ?
                            <Col span={16}>
                              <Form.Item label="Note" style={{ margin: 0 }}>
                                <Tag color="geekblue">{calData.text}</Tag>
                              </Form.Item>
                              <Form.Item label="ชำระค่างวด" style={{ margin: 0 }}>
                                <b>{currencyFormatOne(calData.ALLPAYAMT)}</b>
                                <span style={{ marginLeft: '10px' }}>บาท</span>
                              </Form.Item>
                              <Form.Item label="ชำระดอกเบี้ย" style={{ margin: 0 }}>
                                <b>{currencyFormatOne(calData.ALLPAYINTEFF)}</b>
                                <span style={{ marginLeft: '10px' }}>บาท</span>
                              </Form.Item>
                              <Form.Item label="ชำระเงินต้น" style={{ margin: 0 }}>
                                <b>{currencyFormatOne(calData.ALLPAYTON)}</b>
                                <span style={{ marginLeft: '10px' }}>บาท</span>
                              </Form.Item>
                              <Form.Item label="ชำระค่าทวงถาม" style={{ margin: 0 }}>
                                <b>{currencyFormatOne(calData.ALLPAYFOLLOW)}</b>
                                <span style={{ marginLeft: '10px' }}>บาท</span>
                              </Form.Item>
                              <Form.Item label="รวมยอดชำระ" style={{ margin: 0 }}>
                                <b>{currencyFormatOne(calData.ALLCAL)}</b>
                                <span style={{ marginLeft: '10px' }}>บาท</span>
                              </Form.Item>
                            </Col>
                            : <Col span={16}>
                              <Form.Item label="Note" style={{ margin: 0 }}>
                                <Tag color="geekblue">{calData.text}</Tag>
                              </Form.Item>
                            </Col>
                    }
                  </Card>
                </Col>
              </Row>
              <Divider />
              <Table
                rowKey={(record) => record.uid}
                dataSource={dataTable}
                columns={columns}
                scroll={{
                  x: 1500,
                  y: 400,
                }}
                style={{ padding: 20 }}
              >
              </Table>
            </Spin>
          </Col>
        </Row>
      </Form>
      {contextHolder}
    </Card>
  )
}
