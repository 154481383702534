import React from 'react'
import { Routes, Route } from "react-router-dom"
import TabelLoanMarketing from "./report_mg2/tabelLoanMarketing";

export default function Main_Report2() {
  return (
    <Routes>
    <Route path="/" element={<TabelLoanMarketing />} />
</Routes>
  )
}


// รายงาน 2