import { Form, Card, Col, Row, Spin, InputNumber, Divider, Select, Input, Button, Space } from 'antd';
import React, { useState, useEffect } from 'react'
import '../css/Media.css'
import axios from 'axios';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import BrokerNew from './BrokerNew';
import { customerbroker } from '../../file_mid/all_api';

export default function Broker() {
    const navigate = useNavigate()
    const addBrokers = localStorage.getItem('addBroker')
    const dataAddBrokers = JSON.parse(addBrokers)
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const [dataPost, setDataPost] = useState(dataAddBrokers)
    const [customerBroker, setCustomerBroker] = useState([]);
    const [modalBrokerNew, setModalBrokerNew] = useState(false)

    useEffect(() => {
        loadCustomerBroker()
        if (dataPost) {
            form.setFieldsValue(
                {
                    ...dataPost, 
                    idBroker: dataPost?.idCustomerBroker,
                }
            )
        }
    }, [modalBrokerNew])

    const loadCustomerBroker = async () => {
        setLoading(true)
        await axios.get(customerbroker)
            .then(response => {
                console.log("response.data", response.data)
                setCustomerBroker(response.data);
            }).catch(error => {
                console.error(error);
            });
        setLoading(false)
    }

    const onBroker = async (value) => {
        // console.log("idCard",value)
        setDataPost({ ...dataPost, idCustomerBroker: value }) // เลขบัตรคนแนะนำ
    }

    const handleSubmit = async () => {
        console.log("handleSubmit", dataPost)
        if (dataPost?.amount > 0 || dataPost?.approvalService > 0) {
            localStorage.setItem('addBroker', JSON.stringify(dataPost))
            navigate('/offercase/addCareerincome')
        } else {
            navigate('/offercase/addCareerincome')
        }
    }
    const handleGoHome = () => {
        navigate('/offercase/addDetail')
    };
    const addBroker = () => {
        setModalBrokerNew(true)
    }
    return (
        <Row justify={'center'}>
            <Card style={{ width: '1100px' }}>
                <Spin spinning={loading} size='large' tip=" Loading... ">
                    <Row style={{ textAlign: 'center', marginTop: '30px' }} >
                        <Col span={24} style={{ fontSize: '30px' }} >รายละเอียดค่าแนะนำ</Col>
                    </Row>
                    <Divider />
                    <Row >
                        <Col span={24} style={{ textAlign: 'right' }}>
                            <Button type="primary" onClick={addBroker}>
                                เพิ่มผู้แนะนำ
                            </Button>
                        </Col>
                    </Row>
                    <Divider />
                    <Form
                        labelCol={{
                            span: 12,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                        style={{
                            maxWidth: '100%',
                        }}
                        form={form}
                        onFinish={handleSubmit}
                        autoComplete="off"
                        initialValues={{ remember: true }}
                    >
                        <Row className='main2' justify={'center'}>
                            <Card style={{ width: '100%' }}>
                                <Col span={22}>
                                    <Row justify={'center'}>
                                        <aside style={{ width: '85%', textAlign: 'center' }}>
                                            <div>
                                                <>
                                                    <Row gutter={32}>
                                                        <Col className='gutter-row' span={16}>
                                                            <Form.Item label='ผู้แนะนำ'
                                                                name='idBroker'
                                                                style={{ lineHeight: '32px', textAlign: 'center', width: '200px' }}>
                                                                <Select
                                                                    showSearch
                                                                    style={{ width: '240px', height: '40px' }}
                                                                    onChange={onBroker}
                                                                    optionFilterProp="children"
                                                                    placeholder="Search to Select"
                                                                    filterOption={(inputValue, option) =>
                                                                        option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                                                                    }
                                                                >
                                                                    {customerBroker?.map((e) => {
                                                                        return (
                                                                            <Select.Option key={e.idBroker} value={e.idBroker}>
                                                                                {e.firstname} {e.lastname}
                                                                            </Select.Option>
                                                                        )
                                                                    })}
                                                                </Select>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>

                                                    <Form.Item label='จำนวนเงิน' name='amount'
                                                        style={{ textAlign: 'center', width: '200px' }}
                                                    >
                                                        <InputNumber
                                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                            size="large"
                                                            style={{ width: '240px', height: '40px', color: 'black' }}
                                                            onChange={(value) => {
                                                                setDataPost({ ...dataPost, amount: parseFloat(value) });
                                                            }}
                                                        />
                                                    </Form.Item>

                                                    <Form.Item label='ค่าอนุมัติ/บริการ' name='approvalService' style={{ textAlign: 'center', width: '200px' }}>
                                                        <InputNumber
                                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                            size="large"
                                                            style={{ width: '240px', height: '40px', color: 'black' }}
                                                            onChange={(value) => {
                                                                setDataPost({ ...dataPost, approvalService: parseFloat(value) });
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </>
                                            </div>
                                            {/* <div>
                                                <>
                                                    <Form.Item label='ค่าจัด-โอน' name='transfer' style={{ textAlign: 'center', width: '200px' }}>
                                                        <InputNumber
                                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                            size="large"
                                                            style={{ width: '240px', height: '40px', color: 'black' }}
                                                            onChange={(value) => {
                                                                setDataPost({ ...dataPost, transfer: parseFloat(value) });
                                                            }}
                                                        />
                                                    </Form.Item>

                                                    <Form.Item label='ค่าน้ำมัน' name='oil' style={{ textAlign: 'center', width: '200px' }}>
                                                        <InputNumber
                                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                            size="large"
                                                            style={{ width: '240px', height: '40px', color: 'black' }}
                                                            onChange={(value) => {
                                                                setDataPost({ ...dataPost, oil: parseFloat(value) });
                                                            }}
                                                        />
                                                    </Form.Item>

                                                    <Form.Item label='ค่าทางด่วนที่ดิน' name='landExpressway' style={{ textAlign: 'center', width: '200px' }}>
                                                        <InputNumber
                                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                            size="large"
                                                            style={{ width: '240px', height: '40px', color: 'black' }}
                                                            onChange={(value) => {
                                                                setDataPost({ ...dataPost, landExpressway: parseFloat(value) });
                                                            }}
                                                        />
                                                    </Form.Item>

                                                    <Form.Item label='ค่าเลี่ยงประกัน' name='guarantee' style={{ textAlign: 'center', width: '200px' }}>
                                                        <InputNumber
                                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                            size="large"
                                                            style={{ width: '240px', height: '40px', color: 'black' }}
                                                            onChange={(value) => {
                                                                setDataPost({ ...dataPost, guarantee: parseFloat(value) });
                                                            }}
                                                        />
                                                    </Form.Item>

                                                    <Form.Item label='ค่าแจ้งย้าย' name='notificationOfMoving' style={{ textAlign: 'center', width: '200px' }}>
                                                        <InputNumber
                                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                            size="large"
                                                            style={{ width: '240px', height: '40px', color: 'black' }}
                                                            onChange={(value) => {
                                                                setDataPost({ ...dataPost, notificationOfMoving: parseFloat(value) });
                                                            }}
                                                        />
                                                    </Form.Item>

                                                    <Form.Item label='ค่าฝากตรวจ' name='inspection' style={{ textAlign: 'center', width: '200px' }}>
                                                        <InputNumber
                                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                            size="large"
                                                            style={{ width: '240px', height: '40px', color: 'black' }}
                                                            onChange={(value) => {
                                                                setDataPost({ ...dataPost, inspection: parseFloat(value) });
                                                            }}
                                                        />
                                                    </Form.Item>

                                                    <Form.Item label='ค่าภาษี+พรบ' name='tax' style={{ textAlign: 'center', width: '200px' }}>
                                                        <InputNumber
                                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                            size="large"
                                                            style={{ width: '240px', height: '40px', color: 'black' }}
                                                            onChange={(value) => {
                                                                setDataPost({ ...dataPost, tax: parseFloat(value) });
                                                            }}
                                                        />
                                                    </Form.Item>

                                                    <Form.Item label='ยอดคงเหลือ' name='balance' style={{ textAlign: 'center', width: '200px' }}>
                                                        <Input disabled style={{ color: 'black', width: '240px', height: '40px' }}
                                                            onChange={(e) => setDataPost({ ...dataPost, balance: e.target.value })} />
                                                    </Form.Item>

                                                </>
                                            </div> */}
                                        </aside>
                                    </Row>
                                </Col>
                            </Card>
                            <Row justify={'center'}>
                                <Space>
                                    <Button
                                        type="text"
                                        style={{ background: '#bfbfbf', color: '#000000', }}
                                        onClick={(e) => { handleGoHome(e) }}
                                    >
                                        ย้อนกลับ
                                    </Button>
                                    <Button type="primary" htmlType="submit">
                                        ต่อไป
                                    </Button>
                                </Space>
                            </Row>
                        </Row>
                    </Form>
                </Spin>
            </Card>
            {
                //เพื่มผู้แนะนำ
                modalBrokerNew ?
                    <BrokerNew
                        open={modalBrokerNew}
                        close={setModalBrokerNew}
                    />
                    : null
            }
        </Row >
    )
}
