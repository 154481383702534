import React, { useState, useEffect } from 'react'
import { Modal, Form, Button, Row, Spin, Input, Divider, Col, Space, DatePicker, Upload, message, Image } from 'antd'
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import Compressor from 'compressorjs';
import { PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import { uploadImgGua, getFolderGuaImg, deleteImgGua } from '../../file_mid/all_api';

const { confirm } = Modal;

function ModalEditGarantor({ open, close, dataindex, shootdata, fucnEdit, dataGuarantor, sb }) {

    const currentDate = dayjs();
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const token = localStorage.getItem('token');
    const [loading, setLoading] = useState(false);
    const customers = useSelector(state => state.customers)
    const addCustomer = localStorage.getItem('addCustomer')
    const dataAddCustomer = JSON.parse(addCustomer)

    const [getData, setGetData] = useState({ ...shootdata.dataedit });
    const [dataPostCustomer, setDataPostCustomer] = useState(dataAddCustomer) // customerGuarantor
    const [getimages, setImages] = useState([]);
    const [phones, setPhones] = useState(getData?.phones);
    const [getIndex, setGetIndex] = useState(dataindex);
    const [fileListGuarantors, setFileListGuarantors] = useState([]);
    const [fileListGuarantors1, setFileListGuarantors1] = useState([]);

    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');


    // const expDate = dayjs(dataPost.expdt);
    // console.log("getData", getData, dataindex)
    // console.log("getIndex", getIndex)
    // console.log("dataGuarantor", dataGuarantor)
    useEffect(() => {
        getImg()
        setGetData(shootdata.dataedit)
        setPhones(getData?.phones)
        setGetIndex(dataindex)
        if (getData) {
            form.setFieldsValue({
                ...getData,
            })
        }
        if (getData?.phones) {
            form.setFieldsValue({
                // ...getData,
                phones: getData?.phones[0]?.telp
            })
        }
    }, [])

    // get image Guarantors
    const getImg = async () => {
        // console.log("imageData", shootdata.dataedit)
        setLoading(true)
        await axios.get(`${getFolderGuaImg}/${shootdata?.dataedit?.pathImg}?token=${token}`)
            .then(res => {
                if (res.data) {
                    // console.log("res.data", res.data)
                    setImages(res.data)
                    setLoading(false)
                }
            })
            .catch((err) => {
                console.log("err", err)
                setLoading(false)
            })
        setLoading(false)
    }

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
    };
    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };
    const handleCancel1 = () => {
        setPreviewVisible(false);
    }
    //////////////////// รูปเอกสาร ///////////////////////
    const handleChangeGuarantors1 = async ({ fileList }) => {
        setLoading(true)
        console.log("fileList", fileList)
        setFileListGuarantors(fileList);
        var imageListGuarantors = []
        var i = 0
        for (const file of fileList) {
            const segments = getData.pathImg.split("/")
            // console.log("segments", segments[4])
            const compressedFile = await new Promise((resolve) => {
                new Compressor(file.originFileObj, {
                    quality: 0.1, // เช่น, 60%
                    success: (result) => {
                        resolve(result);
                    },
                });
            });
            var image = {}
            var filename = file.name
            var image64 = await getBase64(compressedFile)
            //var image64 = file.originFileObj
            image.filename = filename
            image.image64 = image64
            //image.type = `5.${getIndex + 1}`
            image.type = `5.${segments[4]}`
            imageListGuarantors.push(image)
            i++
        }
        setFileListGuarantors1({ ...fileListGuarantors1, imageListGuarantors });
        setLoading(false)
    };

    const handleCancel = () => {
        close(false);
    };

    const axi = async (InputImage) => {
        console.log("InputImage", InputImage)
        //setLoading(true)
        await axios.post(uploadImgGua, InputImage)
            .then(response => {
                if (response) {
                    console.log("response", response)
                    //setLoading(false)
                    //close(false)
                }
            }).catch((err) => {
                console.log("err", err)
                //setLoading(false)
            })
    }

    // const DataImagesGu = ({ images1, imageListGuarantorPush }) => {
    const DataImagesGuEdit = ({ data, getIndex, InputImage }) => {
        console.log("data11", data)
        console.log("getIndex11", getIndex)

        // const InputImage = {
        //     mainImg: { carId: dataPostCustomer.preImg, carInput: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"), productTypeId: dataPostCustomer.productTypeId, img: data[0].imgs }
        // }

        console.log("InputImage", InputImage)
        // axi(InputImage)

        setLoading(true)
        confirm({
            title: 'บันทึกข้อมูลคนค้ำ?',
            onOk() {
                if (InputImage?.mainImg?.img?.length > 0) {
                    console.log("11")
                    axi(InputImage)
                }
                fucnEdit(data, getIndex)
                sb(false)
                // setTimeout(() => {
                //     const deletearray = [...guarantors] // กระจายข้อมูลเข้าไปในตัวแปร deletearray
                //     deletearray.splice(index, 1); // เป็นการลบข้อมูลใน Array ตาม index ที่เลือกตาม Card
                //     dispatch(addGuarantor(deletearray))
                //     setGuarantors(deletearray);  // เซตค่าเข้าไปเก็บใน dataArray
                //     message.success('ลบข้อมูลคนค้ำสำเร็จ')
                // }, 2000)
                setLoading(false)
                setTimeout(() => {
                    close(false);
                }, 1500)
            },
            onCancel() {
                message.error('ยกเลิก')
                setLoading(false)
            },
        });

    }
    const handleSubmit = () => {
        console.log("getDataasds : ", getData, getIndex);
        const imageListGuarantorPushs = [];
        const imageListGuarantorPush = [];
        if (fileListGuarantors1?.imageListGuarantors) {
            imageListGuarantorPush.push(...fileListGuarantors1?.imageListGuarantors)
            imageListGuarantorPushs.push(...fileListGuarantors1?.imageListGuarantors)
        }
        console.log("imageListGuarantorPush", imageListGuarantorPush)
        var data
        // if (getData?.length === 0) {
        //     data = [{ ...getData, imgs: imageListGuarantorPush }]
        //     console.log("กรณียังไม่มีคนค้ำ")
        // } else {
        data = [{ ...getData, imgs: imageListGuarantorPushs }] //ต่อ array
        //     console.log("กรณีมีคนค้ำแล้ว",)
        // }
        // console.log("getData", getData)
        console.log("data", data, getIndex)
        const InputImage = {
            mainImg: { carId: dataPostCustomer.preImg, carInput: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"), productTypeId: dataPostCustomer.productTypeId, img: imageListGuarantorPush }
        }
        DataImagesGuEdit({ data, getIndex, InputImage })
        // close(false);
    }

    // const renderPhonesGuarantor = ({ phone, index }) => {
    //     return (
    //         <Form
    //             name="basic"
    //             labelCol={{
    //                 span: 12,
    //             }}
    //             wrapperCol={{
    //                 span: 24,
    //             }}
    //             style={{
    //                 width: '100%',
    //             }}
    //             initialValues={{
    //                 remember: true,
    //             }}
    //             form={form}
    //             onFinish={handleSubmit}
    //             autoComplete="off"
    //         >
    //             <Form.Item
    //                 label='เบอร์โทร'
    //                 name='phones'
    //             >

    //                 <Input type='text'
    //                     id='phones'
    //                     style={{ color: 'black' }}
    //                     defaultValue={phone?.telp}
    //                     onChange={handleInputDataGuarantor}
    //                 >
    //                 </Input>
    //             </Form.Item>
    //         </Form>
    //     )
    // }

    //////////// โชว์รูปภาพ ////////////////
    function createImageUrl(imageData) {
        // console.log(imageData)
        // const blob = new Blob([imageData.originFileObj], { type: imageData.type });
        // return URL.createObjectURL(blob);
        return imageData.image64;
        // , fileList.pdf;
    }

    const showConfirm = async (e, index) => {
        console.log("e, index", e, index)

        confirm({
            title: 'คุณต้องการที่จะลบรูปภาพนี้ใช่หรือไม่...?',
            onOk() {
                setLoading(true)
                axios.delete(`${deleteImgGua}/${e.pathImage}`)
                    .then(res => {
                        console.log("res delete", res)
                        setTimeout(() => {
                            // console.log("resAddGu =", res.data)
                            const updatedImageBlobzz = [...getimages];
                            updatedImageBlobzz.splice(index, 1);
                            setImages(updatedImageBlobzz);
                            // DeleteImgSucc("top")
                            setLoading(false)
                        }, 2000)
                    })
                    .catch((err) =>
                        console.log(err)
                    )
            },
            onCancel() {
                message.error('ยกเลิกการลบรูปภาพ');
            },
        });
        setLoading(false)
    };

    const handleInputDataGuarantor = (e) => { //กรอกข้อมูล
        if (phones[0]?.customerId) {
            setGetData({
                ...getData,
                phones: [{ telp: e.target.value, phoneType: "เบอร์ติดต่อ", sendSms: "1", customerId: phones[0]?.customerId,phoneId: phones[0]?.phoneId }]
                // ...dataPhone, telp: e.target.value, phoneType: "เบอร์ติดต่อ", sendSms: "1"
                // เซตข้อมูลใหม่เข้าช่อง Input
            }) 
        } else {
            setGetData({
                ...getData,
                phones: [{ telp: e.target.value, phoneType: "เบอร์ติดต่อ", sendSms: "1" }]
                // ...dataPhone, telp: e.target.value, phoneType: "เบอร์ติดต่อ", sendSms: "1"
                // เซตข้อมูลใหม่เข้าช่อง Input
            }) // เป็นการกระจายข้อมูลเข้าไปตามชื่อ name 
        }
    }


    return (
        <Modal open={open} onCancel={handleCancel} footer={[null]}>
            <Spin spinning={loading} size='large' tip=" Loading... ">
                <Form
                    name="basic"
                    labelCol={{
                        span: 12,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    style={{
                        width: '100%',
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    form={form}
                    onFinish={handleSubmit}
                    autoComplete="off"
                >
                    <Row justify={'center'}><b><u>แก้ไขข้อมูลคนค้ำ</u></b></Row>
                    <Divider style={{ margin: 5 }} />
                    <Row justify={'center'} className='main2'>
                        <Form.Item name='identificationId' label='เลขบัตรประชาชน'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input !',
                                },]}
                        >
                            <Input
                                disabled
                                name='identificationId'
                                placeholder="ชื่อ"
                                style={{ color: 'black' }}
                                initialvalues={getData?.identificationId}
                                // onChange={handleInputDataGuarantoridentificationId}
                                onChange={(e) => setGetData({ ...getData, identificationId: e.target.value })}
                            />
                        </Form.Item>
                        <aside>
                            <div>
                                <Form.Item name='firstname' label='ชื่อ'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}
                                >
                                    <Input
                                        name='firstname'
                                        placeholder="ชื่อ"
                                        style={{ color: 'black' }}
                                        initialvalues={getData?.firstname}
                                        // onChange={handleInputDataGuarantorFirstName}
                                        onChange={(e) => setGetData({ ...getData, firstname: e.target.value })}
                                    />
                                </Form.Item>
                                <Form.Item name='lastname' label='นามสกุล'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}
                                >
                                    <Input
                                        name='lastname'
                                        placeholder="นามสกุล"
                                        style={{ color: 'black' }}
                                        initialvalues={getData?.lastname}
                                        // onChange={handleInputDataGuarantorLastName}
                                        onChange={(e) => setGetData({ ...getData, lastname: e.target.value })}

                                    />
                                </Form.Item>
                                {/* <Form.Item name='birthdate' label='วันเกิด'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}
                                >
                                    <Input
                                        name='birthdate'
                                        placeholder="นามสกุล"
                                        style={{ color: 'black' }}
                                        initialvalues={getData?.birthdate}
                                        onChange={(e) => setGetData({ ...getData, birthdate: e.target.value })}

                                    />
                                </Form.Item> */}
                            </div>
                            <div>
                                <Form.Item name='nickname' label='ชื่อเล่น'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}
                                >
                                    <Input
                                        name='nickname'
                                        placeholder="ชื่อเล่น"
                                        style={{ color: 'black' }}
                                        initialvalues={getData?.nickname}
                                        // onChange={handleInputDataGuarantorNickName}
                                        onChange={(e) => setGetData({ ...getData, nickname: e.target.value })}

                                    />
                                </Form.Item>

                                {/* {phones?.length >= 1 ?
                                    <>
                                        {phones?.map((phone, index) => {
                                            return renderPhonesGuarantor({ phone, index })
                                        })}
                                    </>
                                    : */}
                                <Form.Item name='phones' label='เบอร์โทร'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}
                                >
                                    <Input type='text'
                                        id='phones'
                                        name='phones'
                                        style={{ color: 'black' }}
                                        placeholder="เบอร์โทร"
                                        // defaultValue={getData?.phones?.telp}
                                        // onChange={(e) => setGetData({ ...getData, phones: [phones[0]?.telp : e.target.value ]})}
                                        onChange={handleInputDataGuarantor}
                                    //onChange={(e) => setGetData({ ...getData, phones: e.target.value })}

                                    >
                                    </Input>
                                </Form.Item>
                                {/* <Form.Item name='gender' label='เพศ'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}
                                >
                                    <Input type='text'
                                        id='gender'
                                        name='gender'
                                        style={{ color: 'black' }}
                                        placeholder="เบอร์โทร"
                                        // defaultValue={getData?.gender?.telp}
                                        // onChange={(e) => setGetData({ ...getData, gender: [gender[0]?.telp : e.target.value ]})}
                                        // onChange={handleInputDataGuarantor}
                                        onChange={(e) => setGetData({ ...getData, gender: e.target.value })}

                                    >
                                    </Input>
                                </Form.Item> */}
                                <Divider />
                                <Row justify={'left'} > <span><b>รูปเอกสารผู้ค้ำ :</b></span></Row>
                                <Row justify={'center'}>
                                    <Form.Item
                                        label=''
                                        name='imgGuarantor'
                                        style={{ textAlign: 'center' }}
                                    >
                                        <Upload
                                            multiple={true}
                                            accept="image/*"
                                            listType="picture-card"
                                            fileList={fileListGuarantors}
                                            onPreview={handlePreview}
                                            onChange={handleChangeGuarantors1}
                                            // onChange={handleChangeGuarantor}
                                            beforeUpload={() => false}
                                        >
                                            {fileListGuarantors?.length >= 10 ? null : (
                                                <div>
                                                    <PlusOutlined />
                                                    <div className="ant-upload-text">upload</div>
                                                </div>
                                            )}
                                        </Upload>
                                        <Modal open={previewVisible} onCancel={handleCancel1}>
                                            <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                        </Modal>
                                    </Form.Item>
                                </Row>
                                <Divider />
                                <Row justify={'center'}>
                                    <Col span={24}>
                                        <>
                                            <Row justify={'left'} > <span><b>รูปเอกสารผู้ค้ำ :</b></span></Row>
                                            {getimages?.map((item, index) => {
                                                // console.log("item", item)
                                                return (
                                                    <>
                                                        <Row justify={'center'}>
                                                            <Col span={24} style={{ textAlign: 'center' }}>
                                                                {/*กรณีโชว์รูปโดยที่ยังไม่เข้าฐานข้อมูล */}
                                                                {/* <img width={'300px'} key={index} style={{ margin: 5 }} src={createImageUrl(item.image64)} alt={item.filename} /> */}
                                                                {/* ลบทีละ 1 รูป */}
                                                                {/* <p><Button onClick={() => showConfirm(item, index)} type='primary' danger>ลบรูปภาพ</Button></p> */}
                                                                {/* <h1>{item.pathImage}</h1> */}
                                                                {/*กรณีโชว์รูปที่เข้าฐานข้อมูลแล้ว */}
                                                                <Image width={'300px'} key={index} src={`data:${item?.Attr};base64,${item?.data.toString('base64')}`} alt={`Image ${index + 1}`} />
                                                                {/* <p><Button onClick={() => showConfirm(item, index)} type='primary' danger>ลบรูปภาพ</Button></p> */}
                                                            </Col>
                                                        </Row>
                                                    </>
                                                )

                                            })}

                                        </>

                                    </Col>
                                </Row>
                                {/* } */}
                            </div>
                        </aside>
                    </Row>
                    <Divider />
                    <Row>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <Space>
                                <Button type="primary" onClick={handleCancel} style={{ background: "red" }}>ยกเลิก</Button>
                                <Button type="primary" htmlType="submit" style={{ background: "green" }} >บันทึก</Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </Modal >
    )
}

export default ModalEditGarantor