import './Index.css';
import React, { useState } from 'react';
import { Button, Modal, Row, Space } from 'antd';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { confirm } = Modal;

const ModalSwitch = ({ open, close, member, updateMember, check }) => {
    const { id } = useParams();
    const [formData, setFormData] = useState({ ...member });
    const [confirmLoading, setConfirmLoading] = useState(false);

    const upDateSwitch = async () => {
        const id = member.id;
        await axios.put(`http://localhost:8088/update-switch/${id}`, member)
            .then(response => {
                console.log('response.data', response.data)
            })
            .catch(error => {
                console.error('Error updating user:', error);
            });
    }

    const handleOk = async () => {
        setConfirmLoading(true);
        await upDateSwitch(id, formData);
        setConfirmLoading(false);
        close(false);
    }

    const handleCancel = () => {
        close(false);
    }
    
    const showDeleteConfirm = () => {
        confirm({
            title: 'คุณแน่ใจหรือไม่ที่จะลบข้อมูลนี้?',
            icon: <ExclamationCircleOutlined />,
            // content: 'ข้อมูลจะถูกลบอย่างถาวรและไม่สามารถกู้คืนได้',
            okText: 'ใช่',
            okType: 'danger',
            cancelText: 'ยกเลิก',
            onOk() {
                handleOk();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    return (
        <>
            {/* <Modal
                title={member.active_status ? 'คุณต้องการเปิดใช้งานผู้ใช้นี้เหรือไม่' : 'คุณต้องการปิดใช้งานผู้ใช้นี้เหรือไม่'}
                open={open}
                footer={null}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            > */}
                <Row justify="center" align="middle" style={{ height: '100%' }}>
                    <Space>
                        <Button
                            key="cancel"
                            onClick={handleCancel}
                            className='button-cancel1'
                        >
                            ยกเลิก
                        </Button>
                        <Button
                            type="submit"
                            key="save"
                            onClick={showDeleteConfirm}
                            className='button-submit'
                        >
                            ยืนยัน
                        </Button>
                    </Space>
                </Row>
            {/* </Modal> */}
        </>
    )
}

export default ModalSwitch;
