import React, { useEffect, useState } from 'react'
import { Button, notification, Form, Card, Input, Col, Row, Spin, Space, Divider, Select, Pagination, DatePicker, Table } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import { SearchOutlined, HomeFilled } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import ModalContno from './ModalLeaving/ModalContno';
import { loadContMKLeaving } from '../file_mid/all_api'

export default function MainLeaving() {
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const token = localStorage.getItem('token');
    const branch = localStorage.getItem('branch');
    const [api, contextHolder] = notification.useNotification();
    const [axiosData, setAxiosData] = useState([]);
    const [arrayTable, setArrayTable] = useState();
    const [dataPost, setDataPost] = useState({ day1: "2015-01-01", day2: "", isu: 0, searchType: "", contno: "" })
    const [loading, setLoading] = useState(false)
    const [dataCont, setDataCont] = useState()
    const [modalCont, setModalCont] = useState(false)
    // const { Countdown } = Statistic;

    useEffect(() => {
        console.log("api manager + search-contno-db2")
        // loadData()
    }, [])

    const onSearch = async () => {
        if (branch === "MIT" || branch === "UD" || branch === "LEX" || branch === "S4") {
            if (dataPost?.contno) {
                setLoading(true)
                setTimeout(async () => {
                    console.log("zxc");
                    const tk = JSON.parse(token)
                    const headers = {
                        "Authorization": `Bearer ${tk}`,
                        "Menu": JSON.stringify("5")
                    }
                    var mixData = { ...dataPost, contno: dataPost.contno, searchType: "search" }
                    console.log("mixData", mixData)
                    await axios.post(loadContMKLeaving, mixData, { headers: headers })
                        // await axios.post("http://localhost:8080/midle/cont-boss", mixData, { headers: headers })
                        .then(async (res) => {
                            console.log("res.data Conn onSearch", res.data)
                            if (res.data?.findARMAST) {
                                setArrayTable(res.data.findARMAST)
                                setAxiosData(res.data.findARMAST)
                            } else {
                                setArrayTable([])
                                setAxiosData([])
                            }
                        }).catch((err) => {
                            console.log(err)
                            alert("ไม่พบข้อมูล !!!")
                        })
                    setLoading(false)
                }, 3000)
            } else {
                alert("กรุณากรอกเลขสัญญา !!!")
            }
        } else {
            alert("คุณไม่มีสิทธิ์ใช้งานเมนูนี้ !")
        }
    }
    const currencyFormatOne = (amount) => {
        return Number(amount)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }
    const columns = [
        {
            title: "ลำดับ",
            dataIndex: "index",
            key: 'index',
            align: 'center',
            //   fixed: true,
            width: "10%",
            render: (text, object, index) => index + 1
        },
        {
            title: "วันที่",
            dataIndex: "SDATE",
            width: "10%",
            align: 'center',
            render: (text, record) => (
                <Space size="middle">
                    {
                        record.SDATE !== "" ?
                            <Space>
                                <>{dayjs(record.SDATE).format("DD-MM-YYYY")}</>
                            </Space>
                            : null
                    }
                </Space>
            ),
            defaultSortOrder: 'descend',
        },
        {
            title: "สัญญา",
            dataIndex: "CONTNO",
            key: 'CONTNO',
            align: 'center',
            width: "30%",
            render: (text, record) => (
                <>
                    <div>เลขสัญญา: {record.CONTNO}</div>
                    <div>การตลาด: <Space style={{ color: "red" }}><b>{record.SALCOD}</b></Space></div>
                    <div>สาขา: <Space style={{ color: "red" }}><b>{record.LOCAT}</b></Space></div>
                </>
            ),
        },
        {
            title: "ราคาเช่าซื้อครั้งที่แล้ว",
            dataIndex: "BALANC",
            align: 'center',
            width: "15%",
            render: (text, record) => (
                <Space size="middle">
                    {
                        <Space>
                            <>{currencyFormatOne(record.BALANC)}</>
                        </Space>
                    }
                </Space>
            ),
        },
        {
            title: "ยอดที่ชำระมาแล้วทั้งหมด",
            dataIndex: "SMPAY",
            align: 'center',
            width: "15%",
            render: (text, record) => (
                <Space size="middle">
                    {
                        <Space>
                            <>{currencyFormatOne(record.SMPAY)}</>
                        </Space>
                    }
                </Space>
            ),
        },
        {
            title: "Action",
            key: 'Action',
            align: 'center',
            width: "30%",
            render: (text, record) => {
                return (
                    <>
                        <Space>
                            <Button
                                type="primary"
                                style={{ backgroundColor: "green", width: "50px" }}
                                icon={<SearchOutlined />}
                                onClick={() => {
                                    setModalCont(true)
                                    setDataCont(record)
                                }}
                            >
                            </Button>
                        </Space>
                    </>
                );
            },
        },
    ];

    return (
        <>
            <Card style={{ height: '100%' }}>
                <Row>
                    <Col span={24}>
                        <div className='text-center'>
                            <h2>ตรวจสอบข้อมูลสัญญา(คนออก)</h2>
                        </div>
                    </Col>
                    <Divider />
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <Spin spinning={loading} size='large' tip="Loading...">
                            <Space>
                                <Form.Item label="กรอกเลขสัญญา">
                                    <Input onChange={(e) => setDataPost({ ...dataPost, contno: e.target.value })}></Input>
                                </Form.Item>
                                <Form.Item label="">
                                    <Button type='primary' onClick={onSearch} icon={<SearchOutlined />}>ค้นหา</Button>
                                </Form.Item>
                            </Space>
                        </Spin>
                    </Col>
                    <Table
                        // rowKey={(record) => record.uid}
                        dataSource={arrayTable}
                        columns={columns}
                        scroll={{
                            x: 1500,
                            y: 400,
                        }}
                        style={{ padding: 20 }}
                    >
                    </Table>
                </Row>
            </Card>
            {
                modalCont ?
                    <ModalContno open={modalCont} close={setModalCont} data={dataCont} />
                    : null
            }
            {contextHolder}
        </>
    )
}
