
const mainDataExcel = [
    { key: "เลขสัญญา", width: 20, },
    { key: "งวดที่", width: 20 },
    { key: "เงินต้นคงเหลือ", width: 20 },
    { key: "จำนวนวัน", width: 20 },
    { key: "วันกำหนดชำระ", width: 20 },
    { key: "ค่างวด", width: 20 },
    { key: "ดอกเบี้ย", width: 20 },
    { key: "เงินต้น", width: 20 },
    { key: "ค่าทวงถาม", width: 20 },
    { key: "วันที่ชำระ", width: 20 },
    { key: "ชำระค่างวด", width: 20 },
    { key: "ชำระดอกเบี้ย", width: 20 },
    { key: "ชำระเงินต้น", width: 20 },
    { key: "ชำระค่าทวงถาม", width: 20 },
    { key: "เงินต้นคงเหลือ PL", width: 20 },
    { key: "ค่างวด PL", width: 20 },
    { key: "ดอกเบี้ย PL", width: 20 },
    { key: "เงินต้น PL", width: 20 },
    { key: "ชำระค่างวด PL", width: 20 },
    { key: "ชำระดอกเบี้ย PL", width: 20 },
    { key: "ชำระเงินต้น PL", width: 20 },
    { key: "เงินต้นคงเหลือ AS", width: 20 },
    { key: "ค่างวด AS", width: 20 },
    { key: "ดอกเบี้ย AS", width: 20 },
    { key: "เงินต้น AS", width: 20 },
    { key: "ชำระค่างวด AS", width: 20 },
    { key: "ชำระดอกเบี้ย AS", width: 20 },
    { key: "ชำระเงินต้น AS", width: 20 },
];


const headersThai = [
    'เลขสัญญา',
    'งวดที่',
    'เงินต้นคงเหลือ',
    'จำนวนวัน',
    'วันกำหนดชำระ',
    'ค่างวด',
    'ดอกเบี้ย',
    'เงินต้น',
    'ค่าทวงถาม',
    'วันที่ชำระ',
    'ชำระค่างวด',
    'ชำระดอกเบี้ย',
    'ชำระเงินต้น',
    'ชำระค่าทวงถาม',
    'เงินต้นคงเหลือ PL',
    'ค่างวด PL',
    'ดอกเบี้ย PL',
    'เงินต้น PL',
    'ชำระค่างวด PL',
    'ชำระดอกเบี้ย PL',
    'ชำระเงินต้น PL',
    'เงินต้นคงเหลือ AS',
    'ค่างวด AS',
    'ดอกเบี้ย AS',
    'เงินต้น AS',
    'ชำระค่างวด AS',
    'ชำระดอกเบี้ย AS',
    'ชำระเงินต้น AS',
];
const headersColumsName = [
    'CONTNO',
    'NOPAY',
    'TONBALANCE',
    'DELAYDAY',
    'DUEDATE',
    'DUEAMT',
    'DUEINTEFF',
    'DUETONEFF',
    'FOLLOWAMT',
    'PAYDATE',
    'PAYAMT',
    'PAYINTEFF',
    'PAYTON',
    'PAYFOLLOW',
    'PL_TONBALANCE',
    'PL_FUPAY',
    'PL_DUEINTEFF',
    'PL_DUETONEFF',
    'PL_PAYAMT',
    'PL_PAYINTEFF',
    'PL_PAYTON',
    'AS_TONBALANCE',
    'AS_FUPAY',
    'AS_DUEINTEFF',
    'AS_DUETONEFF',
    'AS_PAYAMT',
    'AS_PAYINTEFF',
    'AS_PAYTON',
];



export {
    mainDataExcel, headersThai, headersColumsName
};