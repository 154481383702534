import { Modal, Select, Card, Button, Row, Col, Space, Divider, Form, Input, Image, Checkbox, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { productLoanTypeCarRE, productLoanTypeCarRE1, productLoanTypeCarPAP, productLoanTypeLandRE, productLoanTypeLandPAP } from '../../file_mid/all_options'
import ModalInstallment from './ModalInstallment'
import axios from 'axios'
import dayjs from 'dayjs'
import {
    dataModel, getprice, loadOJS, loadProvinces, ratecarsmodel, checkgu,
    getImagess, findcarpgre, findlandpgre,
    getAllFolderGuaImg, getImagessGua
} from '../../file_mid/all_api'
import TableRateCar from '../../offer_case/rate_car/TableRateCar'
import { useDispatch, useSelector } from 'react-redux';
import { addCar } from '../../../redux/Car';
// import { PrinterFilled } from '@ant-design/icons'
import NoteRe from './NoteRe'
import Currency from 'currency.js';
import { FIFTEENPERCENT, TWENTYFOURPERCENT } from './../../offer_case/ploan/InstallmentTable'
import { irr } from 'node-irr'

function ModalApproveRe({ open, close, dataFromTable }) {
    const token = localStorage.getItem('token');
    const [imageBlobzz, setImageBlobzz] = useState([]);
    const [loading, setLoading] = useState(false)
    // const { confirm } = Modal
    const cars = useSelector(state => state.cars)
    // const [api, contextHolder] = notification.useNotification();
    const [isModalTable, setIsModalTable] = useState(false)
    // const [isModalSendBack, setIsModalSendBack] = useState(false)
    const [isModalNote, setIsModalNote] = useState(false)
    const [typeNote, setTypeNote] = useState()
    const [isModalTableRateCar, setIsModalTableRateCar] = useState(false)
    const [career, setCareer] = useState()
    const [brandData, setBrandData] = useState([])
    const [modelData, setModelData] = useState([])

    const [selectProduct, setSelectProduct] = useState({})
    const [typeSelected, setTypeSelected] = useState(null)
    const [checkBrand] = useState(false)
    const [checkModel] = useState(false)
    const [checkBaab] = useState(false)
    const [yearPrice, setYearPrice] = useState([])
    const [typeData, setTypeData] = useState([])
    const [resultData, setResultData] = useState([])
    const [provincesCar, setProvincesCar] = useState([]);
    const [interestRate, setInterestRate] = useState();
    const [form] = Form.useForm()
    const { Option } = Select;
    const dispatch = useDispatch()
    const [dataPost, setDataPost] = useState();
    const [allData, setAllData] = useState();
    const [ap, setAp] = useState();
    const [loan, setLoan] = useState();
    const [loanRe, setLoanRe] = useState();
    const [address, setAddress] = useState([])

    const [installmentWithInterestt, setInstallmentWithInterestt] = useState()
    const [monthlyPayment1, setMonthlyPayment] = useState()
    const [interest1, setInterest] = useState(0.0)
    const [guarantor1, setGuarantor1] = useState([])
    const [getimages, setGetImages] = useState();
    const [checkGuas, setCheckGuas] = useState()
    const [notes, setNotes] = useState([])
    const [dataField, setDataField] = useState()

    const [checkLoanType, setCheckLoanType] = useState();

    useEffect(() => {
        loadData()
    }, [])
    // useEffect(() => {
    //     if (guarantor1.length > 0) {
    //         if (dataFromTable?.typeLoan === "car") {
    //             getImg(allData?.cars?.carId)
    //         } else {
    //             getImg(allData?.land?.landId)
    //         }
    //     }
    // }, [guarantor1])

    useEffect(() => {
        if (dataFromTable?.typeLoan === "car") {
            if (cars.data?.brand_id !== undefined) {
                setDataPost({ ...cars.data, carYear: cars.data.carYear.toString(), carId: dataPost.carId, productTypeId: 1 })
                setSelectProduct({ ...selectProduct, productTypeId: 1 })
                setTypeData(dataPost?.productTypeCar)
                setTypeSelected(dataPost?.productTypeCar)
                loadDataBrand(dataPost?.productTypeCar)
                form.setFieldsValue({
                    ...dataPost,
                    ...cars.data,
                    carPlateNumber: dataPost.carPlateNumber,
                    carProvince: dataPost.carProvince,
                    productTypeId: 1,
                })
            } else {
                // if (dataPost.carId !== undefined) {
                //     setDataPost({ ...dataPost, productTypeId: 1 })
                //     setSelectProduct({ ...selectProduct, productTypeId: 1 })
                //     setTypeData(dataPost?.productTypeCar)
                //     setTypeSelected(dataPost?.productTypeCar)
                //     loadDataBrand(dataPost?.productTypeCar)
                //     form.setFieldsValue({
                //         ...dataPost,
                //         productTypeId: 1,
                //     })
                // }
            }
        }

    }, [cars.data])

    // const getImg = async (id) => {
    //     if (dataFromTable?.typeLoan === "car") {
    //         var path = `one_images/car/${id}/5`
    //     } else {
    //         var path = `one_images/land/${id}/5`
    //     }
    //     // console.log("path", path)
    //     await axios.get(`${getAllFolderGuaImg}/${path}?token=${token}`)
    //         .then(res => {
    //             if (res.data) {
    //                 // console.log("NEW IMG", res.data)
    //                 setGetImages(res.data)
    //             } else {
    //                 setGetImages([])
    //             }
    //         })
    //         .catch((err) => {
    //             console.log("err", err)
    //         })
    // }


    const handleClose = () => {
        dispatch(addCar())
        close(false)
    }

    const onChangecheckGuas = (value) => {
        setCheckGuas(value);
        setLoanRe({ ...loanRe, checkGua: value })
    };

    const handleSubmitCon = () => {
        setTypeNote("อนุมัติแบบมีเงื่อนไข")
        setDataField({
            monthlyPayment1,
            interest1,
            interestRate,
            installmentWithInterestt,
        })
        setIsModalNote(true)
    }

    const handleSubmit = async () => {
        setTypeNote("อนุมัติ")
        setDataField({
            monthlyPayment1,
            interest1,
            interestRate,
            installmentWithInterestt,
        })
        setIsModalNote(true)
    }

    const handleNotApproved = () => {
        setTypeNote("ไม่อนุมัติ")
        setIsModalNote(true)
    }

    const handleCusUnCon = () => {
        setTypeNote("ลูกค้ายกเลิก")
        setIsModalNote(true)
    }

    const handleSendBack = () => {
        setTypeNote("ตีกลับ")
        setIsModalNote(true)
    }

    const loadData = async () => {
        setLoading(true)
        if (dataFromTable?.typeLoan === "car") {
            await axios.post(findcarpgre, { carId: dataFromTable.carId, customerId: dataFromTable.customerId })
                .then(async (res) => {
                    if (res.data) {
                        console.log("loadDataCar", res.data)
                        setAllData(res.data)
                        setDataPost(res.data.cars)
                        setAp(res.data.cars.apCarLoanDetails)
                        setLoan(res.data.cars.carLoanDetails)
                        setLoanRe(res.data.cars.carLoanDetailsRe)
                        setNotes(res.data.cars.carLoanDetailsRe.notes)
                        setAddress(res.data.address)
                        setCheckGuas(res.data.cars.carLoanDetailsRe.checkGua)
                        setImageBlobzz(res.data.cars.img)
                        // await loadCareer()
                        await handleCheckForCal(res.data.cars)
                        // await loadGuarantors(res.data.cars.carLoanDetailsRe.reLoanId, res.data.cars.carId)

                        setSelectProduct({ ...selectProduct, productTypeId: 1 })
                        setTypeData(res.data.cars?.productTypeCar)
                        setTypeSelected(res.data.cars?.productTypeCar)
                        loadDataBrand(res.data.cars?.productTypeCar)
                        form.setFieldsValue({
                            ...res.data,
                            ...res.data.cars,
                            ...res.data.cars.carLoanDetailsRe,
                            productTypeId: 1,
                        })
                        var ProductLoanTypeSplit = []
                        ProductLoanTypeSplit = res.data.cars.carLoanDetailsRe.productLoanType.split("-")
                        // console.log("ProductLoanTypeSplit", ProductLoanTypeSplit[2])
                        if (ProductLoanTypeSplit[2] === "รี" || ProductLoanTypeSplit[2] === "รีโอน" || ProductLoanTypeSplit[2] === "รี+เปลี่ยนสัญญา") {
                            setCheckLoanType("รี")
                        } else {
                            setCheckLoanType("ปรับ")
                        }
                        // setCheckLoanType
                        // รีโอน
                        // รี
                        // รี+เปลี่ยนสัญญา

                        // เปลี่ยนสัญญา
                        // ปรับโครงสร้าง
                        // getImg(res.data?.cars?.carId)
                        await loadCareer()
                        await loadGuarantors()
                        await fetchAllProvinces()
                    }
                }).catch((err) => {
                    console.log("err ", err)
                })
        } else {
            await axios.post(findlandpgre, { landId: dataFromTable.carId, customerId: dataFromTable.customerId })
                .then(async (res) => {
                    if (res.data) {
                        console.log("loadDataLand", res.data)
                        setInterestRate("1.1")
                        setAllData(res.data)
                        setDataPost(res.data.land)
                        setAp(res.data.land.apCarLoanDetails)
                        setLoan(res.data.land.carLoanDetails)
                        setLoanRe(res.data.land.landLoanDetailsRe)
                        setNotes(res.data.land.landLoanDetailsRe.notes)
                        setAddress(res.data.address)
                        setCheckGuas(res.data.land.landLoanDetailsRe.checkGua)
                        setImageBlobzz(res.data.land.img)
                        // await loadCareer()
                        // await loadGuarantors(res.data.land.landLoanDetailsRe.reLoanId, res.data.land.carId)

                        setSelectProduct({ ...selectProduct, productTypeId: 1 })
                        setTypeData(res.data.land?.productTypeLand)
                        setTypeSelected(res.data.land?.productTypeLand)



                        form.setFieldsValue({
                            ...res.data,
                            ...res.data.land,
                            ...res.data.land.landLoanDetailsRe,
                            productTypeId: 2,
                        })
                        var ProductLoanTypeSplit = []
                        ProductLoanTypeSplit = res.data.land.landLoanDetailsRe.productLoanLandType.split("-")
                        // console.log("ProductLoanTypeSplit", ProductLoanTypeSplit[2])
                        if (ProductLoanTypeSplit[2] === "รี" || ProductLoanTypeSplit[2] === "รีโอน" || ProductLoanTypeSplit[2] === "รี+เปลี่ยนสัญญา") {
                            setCheckLoanType("รี")
                        } else {
                            setCheckLoanType("ปรับ")
                        }
                        // if (PreAaproveSplit[3] === "13") {
                        //     setCheckSendNEW(true)
                        // }
                        // productLoanType
                        // getImg(res.data?.land?.landId)
                        await loadCareer()
                        await loadGuarantors()
                    }
                }).catch((err) => {
                    console.log("err ", err)
                })
        }
        setLoading(false)
    }

    const loadGuarantors = async () => { //คนค้ำ
        if (dataFromTable?.typeLoan === "car") {
            await axios.post(checkgu, { typeLoan: "car_re", loanId: dataFromTable.reLoanId })
                .then(async res => {
                    if (res.status === 200) {
                        // console.log("loadGuarantors", res.data)
                        setGuarantor1(res.data)
                        // await getImg(allData?.cars?.carId)
                    } else if (res.status === 204) {
                        // console.log("ไม่พบข้อมูล")
                    }
                }).catch((err) => {
                    console.log("err", err)
                })
        } else {
            await axios.post(checkgu, { typeLoan: "land_re", loanId: dataFromTable.reLoanId })
                .then(async res => {
                    if (res.status === 200) {
                        // console.log("loadGuarantors", res.data)
                        setGuarantor1(res.data)
                        // await getImg(allData?.land?.landId)
                    } else if (res.status === 204) {
                        // console.log("ไม่พบข้อมูล")
                    }
                }).catch((err) => {
                    console.log("err", err)
                })
        }
    }

    const handleCheckForCal = async (value) => {
        if (value?.carLoanDetailsRe?.productType === "p-loan") {
            console.log("1 = p-loan")
            setInterestRate("1.78")
            await handleCalculate(value?.carLoanDetailsRe)
        } else if (value?.carLoanDetailsRe?.productType === "เช่าซื้อ") {
            if (value?.productTypeCar === 1) {
                console.log("2 = รถเล็ก เช่าซื้อ")
                await handleCalculateHIRE(value?.carLoanDetailsRe)
                setInterestRate("1.25")
            } else {
                console.log("3 = รถใหญ่ เช่าซื้อ")
                await car_Calculate(value?.carLoanDetailsRe)
            }
        } else if (value?.carLoanDetailsRe?.productType === "เช่าซื้อ1") {
            console.log("4 = รถเล็ก เช่าซื้อ1")
            await car_Calculate(value?.carLoanDetailsRe)
        }
    }

    // คำนวน
    const car_Calculate = async (value) => {

        var LA = value.approvedLoanAmount
        var LT = value.approvedLoanTerm
        var IR = interestRate

        var interestRateCar = parseFloat(IR) / 100 // อัตราดอก / 100
        var rateCar = ((parseFloat(LA) * Currency(interestRateCar, { precision: 5 }).value) * LT) // (ยอดจัด * อัตราดอกที่หาร100แล้ว ,precision: 3 คือทศนิยม 3 )*งวด
        var loanTermCar = (rateCar + parseFloat(LA)) / parseInt(LT) // (ดอกเบี้ยทั้งหมด + ยอดจัด)/ งวด
        var newLoanTermCar = Math.ceil(Currency((loanTermCar * 0.07) + loanTermCar), { precision: 5 })// งวดละใหม่ = ( งวดละ * 0.07 ) + งวดละ
        var resultRateCar = Math.ceil(Currency(newLoanTermCar, { precision: 5 }) * parseInt(LT)) - parseFloat(LA) // งวดละใหม่ * งวด - ยอดจัด
        var newResultPriceCar = Math.ceil(Currency(newLoanTermCar) * parseInt(LT), { precision: 5 }) // งวดละใหม่ * งวด

        setMonthlyPayment(newLoanTermCar)
        setInterest(resultRateCar)
        setInstallmentWithInterestt(newResultPriceCar)

        // console.log("newLoanTermCar", newLoanTermCar) // งวดละ
        // console.log("resultRateCar", resultRateCar) // ดอกเบี้ย
        // console.log("newResultPriceCar", newResultPriceCar) // ราคารวม
    }
    const handleCalculate = async (value) => {
        var LA = parseInt(value.approvedLoanAmount)
        var LT = parseInt(value.approvedLoanTerm)

        if (LA && LT) {

            let loanAmount = 0.0
            let interest = 0.0

            TWENTYFOURPERCENT.map((value) => {
                if (value.installment === ~~LT) {
                    loanAmount = value.interest * 0.7
                }
            })

            FIFTEENPERCENT.map((value) => {
                if (value.installment === ~~LT) {
                    interest = value.interest * 0.3
                }
            })

            let installmentAmount = Math.round((interest + loanAmount) * LA)

            // IRR Calculate
            var array = []
            array.push(-LA)
            for (var i = 0; i < LT; i++) {
                array.push(installmentAmount)
            }

            let IRR = irr(array) * 100

            if (IRR) {
                var result = LA

                result = installmentAmount * LT
                var interestNEW = result - LA
                // setResultIrr(Currency(IRR, { precision: 5 }).value)

                setMonthlyPayment(installmentAmount)
                setInterest(interestNEW)
                setInstallmentWithInterestt(result)

                // console.log("installmentAmount", installmentAmount) // งวดละ
                // console.log("interestNEW", interestNEW) // ดอกเบี้ย
                // console.log("result", result) // ราคารวม
            }
        }
    }
    const handleCalculateHIRE = async (value) => {
        var LA = value.approvedLoanAmount
        var LT = value.approvedLoanTerm
        if (LA && LT) {
            const amountWithVat = LA * 1.07
            let installment = 0.0

            FIFTEENPERCENT.map((value) => {
                if (value.installment === ~~LT) {
                    installment = value.interest * amountWithVat
                }
            })

            let installmentAmount = Math.round(installment)
            // IRR Calculate
            var array = []
            array.push(-amountWithVat)
            for (var i = 0; i < LT; i++) {
                array.push(installmentAmount)
            }

            let IRR = irr(array) * 100

            if (IRR) {
                var result = amountWithVat

                //result += totalInterest
                result = installmentAmount * LT
                var interestNEW = result - LA
                // setResultIrr(Currency(IRR, { precision: 5 }).value)

                setMonthlyPayment(installmentAmount)
                setInterest(interestNEW)
                setInstallmentWithInterestt(result)

                // console.log("installmentAmount", installmentAmount) // งวดละ
                // console.log("interestNEW", interestNEW) // ดอกเบี้ย
                // console.log("result", result) // ราคารวม
            }
        }
    }
    // คำนวน

    const handleTableRateCar = () => {
        setIsModalTableRateCar(true)
    }

    //////////////// จังหวัด รถ /////////////////////
    const fetchAllProvinces = async () => {
        await axios.get(loadProvinces)
            .then(response => {
                setProvincesCar(response.data);
            }).catch(error => {
                console.error(error);
            });
    }

    const loadDataBrand = async (value) => { //รถยนต์ รถเครื่องจักร รถบรรทุก
        await axios.get(`https://shark-app-j9jc9.ondigitalocean.app/ratecars/brand/${value}`)
            .then((res) => {
                setBrandData(res.data)
            })
            .catch((err) => console.log(err))
    }

    const loadDataModel = async (value) => {
        await axios.get(dataModel + value)
            .then((res) => {
                setModelData(res.data)
            })
            .catch((err) => console.log(err))
    }
    const getResult = async (value) => {
        const result = await axios.get(ratecarsmodel,
            { params: { typeId: typeSelected, modelId: value }, })
        setResultData(result.data)
    }

    const onResult = (k, v) => {
        const pYear = parseInt(v.key)
        if (k) {
            setDataPost({ ...dataPost, carPriceStatus: true, carPrice: k, carYear: pYear.toString(), carDateStatus: dayjs().format("YYYY-MM-DDTHH:mm:ssZ") })
            parseInt(dataPost.carYear)
            form.setFieldsValue({
                carPrice: v.value
            })
        }
    }

    const onTypeChange = (value) => {
        // setDataPost2({ ...dataPost2, productTypeCar: value, carYear: "0", carPrice: 0, carPriceStatus: false, carInput: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"), carDateStatus: dayjs().format("YYYY-MM-DDTHH:mm:ssZ") })
        // setTypeData(value)
        // setTypeSelected(value)
        // loadDataBrand(value)
        // form.setFieldsValue(
        //     {
        //         carProvince: "",
        //         carPlateNumber: "",
        //         loanAmount: "",
        //         loanTerm: "",
        //         interest: "",
        //         interestRate: "",
        //         monthlyPayment: "",
        //         installmentWithInterest: "",
        //         oldLoanAmount: "",
        //         oldMonthlyPayment: "",
        //         oldLoanTerm: "",
        //         oldKangPayment: "",
        //         oldKangPaymentTerm: ""
        //     }
        // )
    }

    const handleProvinceChangeCar = (value) => {
        setDataPost({ ...dataPost, carProvince: value, });
    };

    const handleChangeProductLoanType = (value) => {
        setDataPost({ ...dataPost, productLoanType: value, });
    }
    const handleOpenTable = () => {
        setIsModalTable(true)
    }

    const loadCareer = async () => {
        const id = { customerId: dataFromTable?.customerId }
        await axios.post(loadOJS, id)
            .then((res) => {
                if (res.data.length > 0) {
                    let lastIndex = res.data.length - 1
                    setCareer(res.data[lastIndex])
                }
            })
            .catch((err) => console.log(err))
    }
    const onBrandChange = (value) => {
        setDataPost({ ...dataPost, carBrand: brandData.find((a) => a.idbrand === value).brand })
        loadDataModel(value)
        form.setFieldsValue({
            carModel: "",
            carBaab: "",
            carYear: "",
            carPrice: "",
        })
    }
    const onModelChange = (value) => {
        setDataPost({ ...dataPost, carModel: modelData.find((a) => a.idmodel_te === value).model })
        getResult(value)
        form.setFieldsValue({
            carBaab: "",
            carYear: "",
            carPrice: "",
        })
    }
    const onDetailChange = (value) => {
        setDataPost({ ...dataPost, carBaab: resultData.find((a) => a.idmodel === value).models })
        getPrice(typeData, value)
        form.setFieldsValue({
            carYear: "",
            carPrice: "",
        })
    }
    const getPrice = async (typeId, carId) => {
        await axios.get(getprice, { params: { typeId, carId }, })
            .then((res) => {
                if (res.data) {
                    setYearPrice(res.data)
                }
            })
            .catch((err) => alert('ไม่พบข้อมูล ราคารถ'))
    }
    const currencyFormatOne = (amount) => {
        if (amount) {
            return Number(amount)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, '$&,')
        } else {
            return 0
        }

    }
    const renderDataNote = ({ item, index }) => {
        return (
            <>
                <div><b><u>หมายเหตุลำดับที่ {index + 1}</u></b></div>
                <div style={{ marginBottom: 0 }}>
                    <span>สาเหตุ : </span>
                    <b>{item.cause}</b>
                </div>
                <div style={{ marginBottom: 0 }}>
                    <span>หมายเหตุ : </span>
                    <b>{item.note}</b>
                </div>
                <div style={{ marginBottom: 0 }}>
                    <span>ผู้สร้างหมายเหตุ : </span>
                    <b>{item.noteBy}</b>
                </div>
                <div style={{ marginBottom: 0 }}>
                    <span>สถานะที่ทำการหมายเหตุ : </span>
                    <b>{item.status}</b>
                </div>
                <Divider />
            </>
        )
    }
    const renderGuarantorNewImg = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
        var itemSplit = []
        if (item?.pathImg) {
            itemSplit = item.pathImg.split("/")
        }
        return (
            <>
                <div><b><u>ลำดับที่ {index + 1}</u></b></div>
                <aside style={{ width: '90%' }}>
                    <div style={{ marginBottom: 0 }}>
                        <span>ชื่อ-นามสกุล : </span><b>{item?.snam + ' ' + item?.firstname + ' ' + item?.lastname}</b>
                    </div>

                    <div style={{ marginBottom: 0 }}>
                        <span>ความสัมพันธ์ : </span> <b>{item?.description}</b>
                    </div>
                    {item?.phones?.length > 0 ?
                        <>
                            <div style={{ marginBottom: 0 }}>
                                <span>เบอร์โทรติดต่อ : </span> <b>{item?.phones[0]?.telp}</b>
                            </div>
                        </>
                        : <div style={{ marginBottom: 0 }}>
                            <span>เบอร์โทรติดต่อ : </span> <b>ไม่มี</b>
                        </div>
                    }

                    {item?.address?.length > 0 ?
                        <>
                            <div style={{ marginBottom: 0 }}>
                                <span > ที่อยู่ :  </span>
                                <b>
                                    {
                                        item.address[0]?.houseNumber + ' ' +
                                        "หมู่ " + item.address[0]?.soi + ' ' +
                                        "ถ." + item.address[0]?.road + ' ' +
                                        "ต." + item.address[0]?.subdistrict + ' ' +
                                        "อ." + item.address[0]?.district + ' ' +
                                        "จ." + item.address[0]?.province + ' '
                                        + item.address[0]?.zipCode
                                    }
                                </b>
                            </div>
                        </>
                        : null
                    }

                </aside>
                {/* <Divider/> */}
                {itemSplit.length > 0 ? (
                    <>
                        <Row justify={"center"}>
                            {imageBlobzz?.map((items, index) => {
                                const segments = items.pathImage.split("/")
                                if (itemSplit[4] === segments[4] && items.type === 5) {
                                    return (
                                        // <Image width={'50px'} key={index} src={`data:${items?.Attr};base64,${items?.data?.toString('base64')}`} alt={`Image ${index + 1}`} />
                                        <Image width={"50px"} key={index} src={`${getImagessGua}/${items.pathImage}?token=${token}`} alt={`รูปภาพ ${index + 1}`} />
                                    )
                                }
                                return null;
                            }
                            )}
                        </Row>
                    </>
                ) : null
                }
                <Divider style={{ margin: 5 }} />
            </>
        )
    }

    const handleProductLand = (value) => {
        if (value === 1) {
            // setdataPost({
            //     ...dataPost,
            //     landTypeId: value,
            //     productTypeLand: value,
            //     numberLand: "-",
            //     rai: 0,
            //     workArea: 0,
            //     squareWaArea: 0,
            //     landPrice: 0,
            //     resultLandPrice: 0,
            // })
            // form.setFieldsValue(
            //     {
            //         numberLand: "",
            //         numberLandlawang: "",
            //         rai: "",
            //         workArea: "",
            //         squareWaArea: "",
            //         landPrice: "",
            //         resultLandPrice: "",
            //         provinces: "",
            //         district: "",
            //     }
            // )
        } else {
            // setdataPost({
            //     ...dataPost,
            //     landTypeId: value,
            //     productTypeLand: value,
            //     numberLand: "-",
            //     rai: 0,
            //     workArea: 0,
            //     squareWaArea: 0,
            //     landPrice: 0,
            //     resultLandPrice: 0,
            // })
            // form.setFieldsValue(
            //     {
            //         numberLand: "",
            //         numberLandlawang: "",
            //         rai: "",
            //         workArea: "",
            //         squareWaArea: "",
            //         landPrice: "",
            //         resultLandPrice: "",
            //         provinces: "",
            //         district: "",
            //     }
            // )
        }
    }

    return (
        <Modal open={open} onCancel={handleClose} footer={[null]} closeIcon={false} width={1000}>
            <Card>
                <Spin spinning={loading} size='large' tip=" Loading... ">
                    <Form.Item>
                        <Button type='primary' style={{ backgroundColor: "#FEA13F" }} onClick={handleOpenTable} >ตารางการผ่อน</Button>
                    </Form.Item>
                    <Card title="ข้อมูลส่วนตัว">
                        <Row justify="center">
                            <Col span={6}>
                                <Form.Item label="ตัดสด ณ วันที่" style={{ margin: 0 }}>
                                    <b>{dayjs(dataFromTable?.ftime).format("DD-MM-YYYY")}</b>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="เลขที่สัญญา" style={{ margin: 0 }}>
                                    <b>{dataFromTable?.ISSUNO}</b>
                                </Form.Item>
                            </Col>
                            <Col span={7}>
                                <Form.Item label="ชื่อ-สกุล" style={{ margin: 0 }}>
                                    <b>{dataFromTable?.snam} {dataFromTable?.firstName} {dataFromTable?.lastName}</b>
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                {
                                    dataFromTable?.typeLoan === "car" ?
                                        <>
                                            <Form.Item label="เกรด" style={{ margin: 0 }}>
                                                <b>{allData?.cars?.carLoanDetailsRe?.GRDCOD}</b>
                                            </Form.Item>
                                        </>
                                        :
                                        <>
                                            <Form.Item label="เกรด" style={{ margin: 0 }}>
                                                <b>{allData?.land?.landLoanDetailsRe?.GRDCOD}</b>
                                            </Form.Item>
                                        </>
                                }
                            </Col>
                        </Row>
                    </Card>
                    <Divider />
                    <Form form={form}>
                        {
                            dataFromTable.typeLoan === "car" ?
                                <Form.Item name="productLoanType" label="ประเภทขอกู้"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !'
                                        },]}>
                                    {
                                        checkLoanType === "รี" ?
                                            <Select
                                                placeholder={'เลือกประเภทขอกู้'}
                                                onChange={(value) => { handleChangeProductLoanType(value) }}
                                                options={productLoanTypeCarRE}
                                            />
                                            :
                                            <Select
                                                placeholder={'เลือกประเภทขอกู้'}
                                                onChange={(value) => { handleChangeProductLoanType(value) }}
                                                options={productLoanTypeCarPAP}
                                            />
                                    }
                                </Form.Item>
                                :
                                <Form.Item name="productLoanLandType" label="ประเภทขอกู้"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !'
                                        },]}>
                                    {
                                        checkLoanType === "รี" ?
                                            <Select
                                                placeholder={'เลือกประเภทขอกู้'}
                                                onChange={(value) => { handleChangeProductLoanType(value) }}
                                                options={productLoanTypeLandRE}
                                            />
                                            :
                                            <Select
                                                placeholder={'เลือกประเภทขอกู้'}
                                                onChange={(value) => { handleChangeProductLoanType(value) }}
                                                options={productLoanTypeLandPAP}
                                            />
                                    }
                                </Form.Item>
                        }
                    </Form>
                    <Divider />
                    <Form
                        labelCol={{
                            span: 12,
                        }}
                        wrapperCol={{
                            span: 12,
                        }}
                        form={form}
                        onFinish={handleSubmit}
                    // onFinish={showConfirmApprove}
                    >
                        <Row>
                            <Col span={11}>
                                <Card title="ยอดปิด" style={{ height: "620px" }}>
                                    <Form.Item label="ยอดจัดครั้งที่แล้ว" style={{ margin: 0 }} >
                                        <b>{currencyFormatOne(loanRe?.tcshprc)}</b>
                                        <span> บาท</span>
                                    </Form.Item>
                                    <Form.Item label="ราคาเช่าซื้อครั้งที่แล้ว" style={{ margin: 0 }} >
                                        <b>{currencyFormatOne(loanRe?.balanc)}</b>
                                        <span> บาท</span>
                                    </Form.Item>
                                    <Form.Item label="ยอดที่ชำระมาแล้วทั้งหมด" style={{ margin: 0 }} >
                                        <b>{currencyFormatOne(loanRe?.totalpayment)}</b>
                                        <span> บาท</span>
                                    </Form.Item>
                                    <Form.Item label="ล/น คงเหลือรวม" style={{ margin: 0 }} >
                                        <b>{currencyFormatOne(loanRe?.neekong)}</b>
                                        <span> บาท</span>
                                    </Form.Item>
                                    <Form.Item label="เบี้ยปรับ" style={{ margin: 0 }} >
                                        <b>{currencyFormatOne(loanRe?.beeypup)}</b>
                                        <span> บาท</span>
                                    </Form.Item>
                                    <Form.Item label="ลูกหนี้อื่นๆ" style={{ margin: 0 }} >
                                        <b>{currencyFormatOne(loanRe?.OTHR)}</b>
                                        <span> บาท</span>
                                    </Form.Item>
                                    <Form.Item label="จำนวนงวดที่ผ่อนมาแล้ว" style={{ margin: 0 }} >
                                        <b>{(loanRe?.NOPAY)}</b>
                                        <span> งวด</span>
                                    </Form.Item>
                                    <Form.Item label="จำนวนงวดทั้งหมด" style={{ margin: 0 }} >
                                        <b>{(loanRe?.nopays)}</b>
                                        <span> งวด</span>
                                    </Form.Item>
                                    <Form.Item label="ตัดสด งวดที่" style={{ margin: 0 }} >
                                        <b>{(loanRe?.reqNo)}</b>
                                    </Form.Item>
                                    <Divider style={{ margin: 0 }} />
                                    <Form.Item label="ยอดรีไฟแนนซ์เพื่อจัดใหม่" style={{ margin: 0 }} >
                                        <b>{currencyFormatOne(loanRe?.re1)}</b>
                                    </Form.Item>
                                    <Form.Item label="ประเภทค่างวด" name="productType" style={{ margin: 0 }} >
                                        <b>{loanRe?.productType}</b>
                                    </Form.Item>
                                    <Form.Item name="approvedLoanAmount" label="ยอดจัดใหม่" style={{ margin: 0 }} >
                                        <b>{currencyFormatOne(loanRe?.approvedLoanAmount)}</b>
                                    </Form.Item>
                                    <Form.Item name="approvedLoanTerm" label="จำนวนงวด" style={{ margin: 0 }} >
                                        <b>{loanRe?.approvedLoanTerm}</b>
                                    </Form.Item>
                                    <Form.Item label='อัตราดอกเบี้ย' name='interestRate' style={{ margin: 0 }}>
                                        <b>{interestRate}</b>
                                    </Form.Item>
                                    <Form.Item label="ใบนี้หมดอายุ ณ วันที่" style={{ margin: 0 }} >
                                        <b style={{ color: "red" }}>{dayjs(loanRe?.newOfferDate).format("DD-MM-YYYY")}</b>
                                    </Form.Item>
                                </Card>
                            </Col>
                            <Col span={1}>
                            </Col>
                            <Col span={12}>
                                {
                                    dataFromTable.typeLoan === "car" ?
                                        <Card title="เรทรถ" style={{ height: "620px" }}>
                                            <Row>
                                                <Col span={24}>
                                                    <Row>
                                                        <Col span={22}>
                                                            <Form.Item name="productTypeId" label="กลุ่มสินค้า" >
                                                                <Select placeholder={'เลือก'}
                                                                    style={{ height: "40px" }}
                                                                    disabled >
                                                                    <Option value={1}>รถ</Option>
                                                                    <Option value={2}>ที่ดิน</Option>
                                                                </Select>
                                                            </Form.Item>
                                                            <Form.Item name="11" label="เรทรถ"  >
                                                                <Button type='primary' onClick={handleTableRateCar}>เรทรถ</Button>
                                                            </Form.Item>
                                                            <Form.Item name="productTypeCar" label="ชนิดรถ" style={{ margin: 0 }}>
                                                                <Select
                                                                    placeholder={'เลือก'}
                                                                    disabled
                                                                    allowClear
                                                                    style={{ height: "40px" }}
                                                                    onChange={onTypeChange}>
                                                                    <Option value={1}>รถยนต์</Option>
                                                                    <Option value={2}>รถเครื่องจักการเกษตร</Option>
                                                                    <Option value={3}>รถบรรทุก</Option>
                                                                </Select>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col span={22}>
                                                            <Form.Item name="carBrand" label="ยี่ห้อ" style={{ margin: 0 }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !'
                                                                    },]}>
                                                                {
                                                                    !checkBrand ?
                                                                        <Select
                                                                            showSearch
                                                                            style={{ height: "40px" }}
                                                                            onChange={onBrandChange}
                                                                            optionFilterProp="children"
                                                                            placeholder="Search to Select"
                                                                            filterOption={(inputValue, option) =>
                                                                                option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                                                                            }
                                                                        >
                                                                            {brandData?.map((e) => {
                                                                                return (
                                                                                    <Select.Option key={e.idbrand} value={e.idbrand}>
                                                                                        {e.brand} ({e.brand_th})
                                                                                    </Select.Option>
                                                                                )
                                                                            })}
                                                                        </Select>
                                                                        :
                                                                        <Input onChange={(e) => setDataPost({ ...dataPost, carBrand: e.target.value })} />
                                                                }
                                                            </Form.Item>
                                                            <Form.Item name="carModel" label="รุ่นรถ" style={{ margin: 0 }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !'
                                                                    },]}>
                                                                {
                                                                    !checkModel ?
                                                                        <Select
                                                                            showSearch
                                                                            style={{ height: "40px" }}
                                                                            onChange={onModelChange}
                                                                            placeholder="Search to Select"
                                                                            optionFilterProp="children"
                                                                            filterOption={(inputValue, option) =>
                                                                                option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                                                                            }
                                                                        >
                                                                            {modelData?.map((e) => {
                                                                                return (
                                                                                    <Select.Option key={e.idmodel_te} value={e.idmodel_te}>
                                                                                        {e.model} {e.model_te ? e.model_te : null}
                                                                                    </Select.Option>
                                                                                )
                                                                            })}
                                                                        </Select>
                                                                        :
                                                                        <Input onChange={(e) => setDataPost({ ...dataPost, carModel: e.target.value })} />
                                                                }
                                                            </Form.Item>
                                                            <Form.Item name="carBaab" label="แบบรถ" style={{ margin: 0 }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !'
                                                                    },]}>
                                                                {
                                                                    !checkBaab ?
                                                                        <Select
                                                                            style={{ height: "40px" }}
                                                                            showSearch
                                                                            onChange={(value) => { onDetailChange(value) }}
                                                                            placeholder="Search to Select"

                                                                        >
                                                                            {resultData?.map((e) => {
                                                                                return (
                                                                                    <Select.Option key={e.idmodel} value={e.idmodel}>
                                                                                        {e.models} {e.idmodel ? e.idmodel : null}
                                                                                    </Select.Option>
                                                                                )
                                                                            })}
                                                                        </Select>
                                                                        :
                                                                        <Input onChange={(e) => setDataPost({ ...dataPost, carBaab: e.target.value })} />
                                                                }
                                                            </Form.Item>
                                                            <Form.Item name="carYear" label="ปีรถ" style={{ margin: 0 }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !'
                                                                    },]}>
                                                                {
                                                                    !checkBaab ?
                                                                        <Select
                                                                            style={{ height: "40px" }}
                                                                            onChange={(k, v) => (onResult(k, v))}
                                                                        >
                                                                            {Object.entries(yearPrice).map(([k, v]) => {
                                                                                if (k.charAt(0) === 'p' && v) {
                                                                                    const year = k.replace('p', '')
                                                                                    return (
                                                                                        <Select.Option key={year} value={v * 1000}>
                                                                                            {year}
                                                                                        </Select.Option>
                                                                                    )
                                                                                } else {
                                                                                    return null
                                                                                }
                                                                            })}
                                                                        </Select>
                                                                        :
                                                                        <Input onChange={(e) => setDataPost({ ...dataPost, carYear: e.target.value })} />
                                                                }
                                                            </Form.Item>
                                                            <Form.Item name="carPrice" label="ราคา" style={{ margin: 0 }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !'
                                                                    },]}>
                                                                {
                                                                    !checkBaab ?
                                                                        <Input disabled onChange={(e) => setDataPost({ ...dataPost, carPrice: e.value })} />
                                                                        :
                                                                        <Input onChange={(e) => setDataPost({ ...dataPost, carPrice: e.value })} />
                                                                }
                                                            </Form.Item>
                                                            <Form.Item name="carPlateNumber" label="เลขทะเบียน" style={{ margin: 0 }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !'
                                                                    },]}
                                                            >
                                                                <Input
                                                                    // style={{ height: "40px" }} 
                                                                    onChange={(e) => setDataPost({ ...dataPost, carPlateNumber: e.target.value })} />
                                                            </Form.Item>
                                                            <Form.Item name="carProvince" label="จังหวัด" style={{ margin: 0 }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !'
                                                                    },]}
                                                            >
                                                                <Select
                                                                    showSearch
                                                                    name='carProvince'
                                                                    style={{ height: "40px" }}
                                                                    placeholder='จังหวัด'
                                                                    onChange={(key, value) => { handleProvinceChangeCar(key, value) }}
                                                                >
                                                                    {provincesCar?.map((pro, index) => (
                                                                        <Option key={pro.provinceId} value={pro.provinceName}>
                                                                            {pro.provinceName}
                                                                        </Option>
                                                                    ))}
                                                                </Select>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col className='gutter-row' span={2} style={{ textAlign: 'center' }}>
                                                            {/* {
                                                    checkBrand === false ?
                                                        <Button onClick={handleAddBrand}>
                                                            +
                                                        </Button>
                                                        :
                                                        <Button onClick={handleAddBrand} style={{ width: '42px' }}>
                                                            -
                                                        </Button>
                                                }
                                                {
                                                    checkModel === false ?
                                                        <Button onClick={handleAddModel}>
                                                            +
                                                        </Button>
                                                        :
                                                        <Button onClick={handleAddModel} style={{ width: '42px' }}>
                                                            -
                                                        </Button>
                                                }
                                                {
                                                    checkBaab === false ?
                                                        <Button onClick={handleAddBaab}>
                                                            +
                                                        </Button>
                                                        :
                                                        <Button onClick={handleAddBaab} style={{ width: '42px' }} >
                                                            -
                                                        </Button>
                                                } */}
                                                        </Col>
                                                    </Row>

                                                </Col>
                                            </Row>
                                        </Card>
                                        :
                                        <Card title="เรทที่ดิน" style={{ height: "620px" }}>
                                            <Row>
                                                <Col span={24}>
                                                    <Row>
                                                        <Col span={22}>
                                                            <Form.Item name="productTypeId" label="กลุ่มสินค้า">
                                                                <Select placeholder={'เลือก'} disabled >
                                                                    <Option value={1}>รถ</Option>
                                                                    <Option value={2}>ที่ดิน</Option>
                                                                </Select>
                                                            </Form.Item>
                                                            <Form.Item label='ประเภทเอกสาร' name='productTypeLand'>
                                                                <Select placeholder={'เลือก'}
                                                                    disabled
                                                                    onChange={(value) => handleProductLand(value)}
                                                                >
                                                                    <Option value={1}>นส.3</Option>
                                                                    <Option value={2}>นส.4</Option>
                                                                </Select>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col span={22}>
                                                            {
                                                                dataPost?.productTypeLand === 1 ?
                                                                    <>
                                                                        <Form.Item name='province' label='จังหวัด' style={{ margin: 0 }}>
                                                                            <Select name='provinces' disabled style={{ height: "40px" }}>
                                                                            </Select>
                                                                        </Form.Item>
                                                                        <Form.Item name='district' label='อำเภอ' style={{ margin: 0 }}>
                                                                            <Select name='district' disabled style={{ height: "40px" }}>
                                                                            </Select>
                                                                        </Form.Item>
                                                                        <Form.Item label='เลขโฉนด' name='numberLand' style={{ margin: 0 }}>
                                                                            <Input type='text' disabled style={{ height: "40px" }} ></Input>
                                                                        </Form.Item>
                                                                        <Form.Item label='พื้นที่ไร่' name='rai' style={{ margin: 0 }}>
                                                                            <Input type='number' suffix="ไร่" disabled  ></Input>
                                                                        </Form.Item>
                                                                        <Form.Item label='พื้นที่งาน' name='workArea' style={{ margin: 0 }}>
                                                                            <Input type='number' suffix="งาน" disabled ></Input>
                                                                        </Form.Item>
                                                                        <Form.Item label='พื้นที่ตารางวา' name='squareWaArea' style={{ margin: 0 }}>
                                                                            <Input type='number' suffix="ตารางวา" disabled ></Input>
                                                                        </Form.Item>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <Form.Item name='province' label='จังหวัด' style={{ margin: 0 }}>
                                                                            <Select disabled name='provinces'>
                                                                            </Select>
                                                                        </Form.Item>
                                                                        <Form.Item name='district' label='อำเภอ' style={{ margin: 0 }}>
                                                                            <Select disabled name='district'
                                                                            >
                                                                            </Select>
                                                                        </Form.Item>
                                                                        <Form.Item label='เลขโฉนด' name='numberLand' style={{ margin: 0 }}>
                                                                            <Input type='text' disabled></Input>
                                                                        </Form.Item>
                                                                        <Form.Item label='เลขที่ดินหรือระวาง' name='numberLandLawang' style={{ margin: 0 }}>
                                                                            <Input type='text' disabled></Input>
                                                                        </Form.Item>
                                                                        <Form.Item label='พื้นที่ไร่' name='rai' style={{ margin: 0 }}>
                                                                            <Input type='text' suffix="ไร่" disabled></Input>
                                                                        </Form.Item>
                                                                        <Form.Item label='พื้นที่งาน' name='workArea' style={{ margin: 0 }}>
                                                                            <Input type='text' suffix="งาน" disabled></Input>
                                                                        </Form.Item>
                                                                        <Form.Item label='พื้นที่ตารางวา' name='squareWaArea' style={{ margin: 0 }}>
                                                                            <Input type='text' suffix="ตารางวา" disabled></Input>
                                                                        </Form.Item>
                                                                        <Form.Item label='ราคาประเมิน' name='landPrice' style={{ margin: 0 }}>
                                                                            <Input type='text' suffix="บาทต่อตารางวา" disabled></Input>
                                                                        </Form.Item>
                                                                        <Form.Item label='ราคารวม' name='resultLandPrice' style={{ margin: 0 }}>
                                                                            <Input type='text' suffix="บาท" disabled></Input>
                                                                        </Form.Item>
                                                                    </>
                                                            }
                                                        </Col>
                                                        <Col className='gutter-row' span={2} style={{ textAlign: 'center' }}>

                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Card>
                                }

                            </Col>
                            {
                                dataFromTable.typeLoan === "land" ?
                                    <>
                                        <Divider />
                                        <Col span={24}>
                                            <Card title="ราคาประเมินที่ดิน">
                                                <Row>
                                                    <Col span={8}>
                                                        <Form.Item label="ราคาต่ำสุด" style={{ margin: 0 }}>
                                                            <b>{currencyFormatOne(allData?.land?.approvalRe?.minPrice)}</b>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={8}>
                                                        <Form.Item label="ราคาสูงสุด" style={{ margin: 0 }}>
                                                            <b>{currencyFormatOne(allData?.land?.approvalRe?.maxPrice)}</b>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={8}>
                                                        <Form.Item label="หมายเหตุ" style={{ margin: 0 }}>
                                                            <b>{allData?.land?.approvalRe?.note}</b>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                    </>
                                    :
                                    null
                            }
                            <Divider />
                            <Col span={24}>
                                <Row justify={'center'} >
                                    <Checkbox
                                        style={{ fontSize: '20px' }}
                                        checked={checkGuas === "ค้ำ-ไม่โอน"}
                                        isPacked={true}
                                        onChange={() => onChangecheckGuas("ค้ำ-ไม่โอน")}
                                    >ค้ำ-ไม่โอน
                                    </Checkbox>
                                    <Checkbox
                                        style={{ fontSize: '20px' }}
                                        checked={checkGuas === "ค้ำ-โอน"}
                                        isPacked={true}
                                        onChange={() => onChangecheckGuas("ค้ำ-โอน")}
                                    >ค้ำ-โอน
                                    </Checkbox>
                                    <Checkbox
                                        style={{ fontSize: '20px' }}
                                        checked={checkGuas === "ไม่ค้ำ-ไม่โอน"}
                                        isPacked={true}
                                        onChange={() => onChangecheckGuas("ไม่ค้ำ-ไม่โอน")}
                                    >ไม่ค้ำ-ไม่โอน
                                    </Checkbox>
                                    <Checkbox
                                        style={{ fontSize: '20px' }}
                                        checked={checkGuas === "ไม่ค้ำ-โอน"}
                                        isPacked={true}
                                        onChange={() => onChangecheckGuas("ไม่ค้ำ-โอน")}
                                    >ไม่ค้ำ-โอน
                                    </Checkbox>
                                </Row>
                                <Divider />
                            </Col>
                            <Divider />
                            <Col span={24}>
                                <Card title="อาชีพ">
                                    {
                                        career ?
                                            <Row>
                                                <Col span={8}>
                                                    <Form.Item label="กลุ่มอาชีพ" style={{ margin: 0 }}>
                                                        <b>{career?.desc}</b>
                                                    </Form.Item>
                                                    <Form.Item label="ชื่อบริษัท สถานที่ทำงาน" style={{ margin: 0 }}>
                                                        <b>{career?.companyName}</b>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={8}>
                                                    <Form.Item label="เลือกอาชีพ" style={{ margin: 0 }}>
                                                        <b>{career?.descSub}</b>
                                                    </Form.Item>
                                                    <Form.Item label="รายได้ต่อเดือน" style={{ margin: 0 }}>
                                                        <b>{currencyFormatOne(career?.incomeMonth)}</b>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={8}>
                                                    <Form.Item label="สาขาอาชีพ" style={{ margin: 0 }}>
                                                        <b>{career?.descSection}</b>
                                                    </Form.Item>
                                                    <Form.Item label="ค่าใช้จ่ายต่อ / เดือน" style={{ margin: 0 }}>
                                                        <b>{currencyFormatOne(career?.expensesMonth)}</b>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            :
                                            <>* ไม่พบข้อมูลอาชีพ!</>
                                    }
                                </Card>
                            </Col>
                            <Divider />
                            <Col span={24}>
                                <Card title="หมายเหตุ">
                                    {/* <Row justify={'left'}><b><u>หมายเหตุ</u></b></Row> */}
                                    {notes?.length >= 1 ?
                                        notes.map((item, index) => {
                                            return renderDataNote({ item, index });
                                        })
                                        : <>* ยังไม่เพิ่มหมายเหตุ!</>
                                    }
                                </Card>
                            </Col>
                            <Divider />
                            <Col span={24}>
                                <Card title="คนค้ำ">
                                    {
                                        guarantor1?.length >= 1 ?
                                            <>
                                                {
                                                    guarantor1?.map((item, index) => {
                                                        return renderGuarantorNewImg({ item, index })
                                                    })
                                                }
                                            </>
                                            : <>* ไม่พบข้อมูลคนค้ำ!</>
                                    }
                                </Card>
                            </Col>
                            {
                                dataFromTable.typeLoan === "car" ?
                                    <>
                                        <Col span={24}>
                                            <Divider orientation="left"><b>รูปภาพ</b></Divider>
                                            <Image.PreviewGroup>
                                                {/* <span>หน้าคู่มือ : </span>
                                            <Space size={[10, 10]} wrap>
                                                {imageBlobzz?.map((e, index) => {
                                                    if (e.type === 2) {
                                                        return (
                                                            <>
                                                                <Image width={50} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                            </>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                            </Space>
                                            <Divider /> */}
                                                {/* <span>รูปรถ : </span> */}
                                                <Space size={[10, 10]} wrap>
                                                    {imageBlobzz?.map((e, index) => {
                                                        if (e.type === 1) {
                                                            return (
                                                                <>
                                                                    <Image width={50} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                </>
                                                            );
                                                        }
                                                        return null;
                                                    })}
                                                </Space>
                                            </Image.PreviewGroup>
                                        </Col>
                                        <Col span={24}>
                                            <Divider orientation="left"><b>เอกสารขอรี/ปรับ/เปลี่ยน</b></Divider>
                                            <Image.PreviewGroup>
                                                <Space size={[10, 10]} wrap>
                                                    {imageBlobzz?.map((e, index) => {
                                                        if (e.type === 14) {
                                                            return (
                                                                <>
                                                                    <Image width={50} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                </>
                                                            );
                                                        }
                                                        return null;
                                                    })}
                                                </Space>
                                            </Image.PreviewGroup>
                                        </Col>
                                    </>
                                    :
                                    <>
                                        <Col span={24}>
                                            <Divider orientation="left"><b>รูปภาพ</b></Divider>
                                            <Image.PreviewGroup>
                                                {/* <span>โฉนด : </span>
                                            <Space size={[10, 10]} wrap>
                                                {imageBlobzz?.map((e, index) => {
                                                    if (e.type === 3) {
                                                        return (
                                                            <>
                                                                <Image width={50} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                            </>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                            </Space>
                                            <Divider />
                                            <span>ใบแบ่งใบต่อ : </span>
                                            <Space size={[10, 10]} wrap>
                                                {imageBlobzz?.map((e, index) => {
                                                    if (e.type === 10) {
                                                        return (
                                                            <>
                                                                <Image width={50} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                            </>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                            </Space>
                                            <Divider />
                                            <span>ระวาง : </span>
                                            <Space size={[10, 10]} wrap>
                                                {imageBlobzz?.map((e, index) => {
                                                    if (e.type === 11) {
                                                        return (
                                                            <>
                                                                <Image width={50} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                            </>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                            </Space>
                                            <Divider /> */}
                                                {/* <span>รูปที่ดิน : </span> */}
                                                <Space size={[10, 10]} wrap>
                                                    {imageBlobzz?.map((e, index) => {
                                                        if (e.type === 1) {
                                                            return (
                                                                <>
                                                                    <Image width={50} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                </>
                                                            );
                                                        }
                                                        return null;
                                                    })}
                                                </Space>
                                            </Image.PreviewGroup>
                                        </Col>
                                        <Col span={24}>
                                            <Divider orientation="left"><b>เอกสารขอรี/ปรับ/เปลี่ยน</b></Divider>
                                            <Image.PreviewGroup>
                                                <Space size={[10, 10]} wrap>
                                                    {imageBlobzz?.map((e, index) => {
                                                        if (e.type === 14) {
                                                            return (
                                                                <>
                                                                    <Image width={50} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                </>
                                                            );
                                                        }
                                                        return null;
                                                    })}
                                                </Space>
                                            </Image.PreviewGroup>
                                        </Col>
                                    </>
                            }
                            <Divider />
                            <Col span={24} style={{ textAlign: 'center' }}>
                                <Space>
                                    <Button type='text' style={{ backgroundColor: "gray" }} onClick={handleClose}>ยกเลิก</Button>
                                    {/* <Button type='primary' style={{ backgroundColor: "blue" }} icon={<PrinterFilled />}>Print</Button> */}
                                    <Button type='primary' onClick={handleCusUnCon} danger>ลูกค้ายกเลิก</Button>
                                    {
                                        dataFromTable?.typeLoan === "car" ?
                                            <Button type='primary' onClick={handleSendBack} style={{ backgroundColor: "#FF9322" }} >ตีกลับ</Button>
                                            : null
                                    }
                                    <Button type='primary' onClick={handleNotApproved} danger>ไม่อนุมัติ</Button>

                                    <Button type='primary' htmlType='submit' style={{ backgroundColor: "green" }} >อนุมัติ</Button>
                                    <Button type='primary' onClick={handleSubmitCon}>อนุมัติแบบมีเงื่อนไข</Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                    {/* {contextHolder} */}
                    {
                        isModalTable ?
                            <ModalInstallment
                                open={isModalTable}
                                close={setIsModalTable}
                                contno={dataFromTable.ISSUNO}
                            />
                            : null
                    }
                    {
                        isModalTableRateCar ?
                            <TableRateCar
                                open={isModalTableRateCar}
                                close={setIsModalTableRateCar}
                                productTypeCar={dataPost?.productTypeCar}
                            />
                            : null
                    }
                    {
                        isModalNote ?
                            <NoteRe
                                open={isModalNote}
                                close={setIsModalNote}
                                loanId={dataFromTable.reLoanId}
                                CloseAll={handleClose}
                                typeNote={typeNote}
                                loanRe={loanRe}
                                typeData={typeData}
                                checkGuas={checkGuas}
                                dataCarLand={dataPost}
                                contno={dataFromTable.ISSUNO}
                                dataInterestRate={interestRate}
                                dataField={dataField}
                                guarantorData={guarantor1}
                                dataFromTable={dataFromTable}
                            // resultAppStatus={resultAppStatus}
                            // resultApp={resultApp}
                            />
                            : null
                    }
                </Spin>
            </Card>
        </Modal >
    )
}

export default ModalApproveRe