import React, { useState, useEffect } from 'react'
import { Form, Modal, Row, Col, Image, Space, Card, Button, Tag, Divider, Upload, message, notification, Spin } from 'antd'
import { SendOutlined, PlusOutlined, PictureOutlined } from '@ant-design/icons'
import dayjs from 'dayjs';
import axios from "axios";
import Currency from 'currency.js';
import { useSelector } from 'react-redux';
// import { addGuarantor } from '../../../redux/Guarantor';
// import { addImage } from '../../../redux/Image';
// import { FIFTEENPERCENT, TWENTYFOURPERCENT } from './../../offer_case/ploan/InstallmentTable'
import ModalCusConfirm from './ModalCusConfirm';
import Compressor from 'compressorjs';
import TextArea from 'antd/es/input/TextArea';
import ModalShowImgL from './ModalShowImg';
import AddGua from './Guarantor/AddGua';
import EditGua from './Guarantor/EditGua';
import { useLocation, useNavigate } from 'react-router-dom';
import { checkguarantors, checklandcus, findlandmk, findlandpn, loadOJS, mutips4pre, resendlandmk1, getAllFolderGuaImg, getImagessGua } from '../../file_mid/all_api';
// import ModalShowImgPreL from './ModalShowImgPreL';

export default function ModalInfoLand({ open, dataFromTable, close }) {
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;
    const [sendBack, setSendBack] = useState(true)
    const guarantor = useSelector(state => state.guarantor)
    const token = localStorage.getItem('token');
    const [imageBlobzz, setImageBlobzz] = useState([]);
    const [dataApproval, setDataApproval] = useState();
    const [dataLand, setDataLand] = useState(dataFromTable);
    const [dataLand1, setDataLand1] = useState();
    const [dataCareer, setDataCareer] = useState([]);
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const { confirm } = Modal;
    const [guarantor1, setGuarantor1] = useState([])
    const [address, setAddresss] = useState([])
    const [dataPhoneCus, setDataPhoneCus] = useState([]);
    const [dataEmail, setDataEmail] = useState([]);
    const [dataSocials, setDataSocials] = useState([]);
    const [reSendData, setReSendData] = useState({});
    // const des = JSON.parse(dataFromTable.des)
    const [des, setDes] = useState([]);
    const [land, setLand] = useState();
    // const [number, setNumber] = useState(0);
    const [checkImage, setCheckImage] = useState();
    const [api, contextHolder] = notification.useNotification();

    // คำนวนที่ดิน
    const [loanTermDATA, setloanTermDATA] = useState({})
    const [resultRateDATA, setresultRateDATA] = useState({})
    const [resultPriceDATA, setresultPriceDATA] = useState({})

    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');

    //เพิ่มรูป
    const [fileListCustomer, setFileListCustomer] = useState([]);
    const [fileListCustomerImageCareer, setFileListCustomerImageCareer] = useState([]);
    const [fileListStatement, setFileListStateMent] = useState([]);
    const [fileListStatementPDF, setFileListStateMentPDF] = useState([]);
    const [fileListOther, setFileListOther] = useState([]);
    const [fileListOtherMent, setFileListOtherMent] = useState([]);
    const [fileListLand, setFileListLand] = useState([]);
    const [fileListDeep, setFileListDeep] = useState([]);
    const [fileListCustomerLeafDivider, setFileListCustomerLeafDivider] = useState([]);
    const [fileListCustomerBurden, setFileListCustomerBurden] = useState([]);
    // const [fileListGuarantor, setFileListGuarantor] = useState([]);
    const [notes, setNotes] = useState([])
    const [checkApprove, setCheckApprove] = useState({})

    const [career, setCareer] = useState([]) // เก็บข้อมูลอาชีพ
    const [oldData, setOldData] = useState()
    const [modalConfirm, setModalConfirm] = useState() // เปิด modal ของผู้กู้ยอมรับ

    /*เก็บรูปทั้งหมด*/
    const [allImg, setAllImg] = useState([]);
    const newFormData = new FormData();
    const [resultImage, setResultImage] = useState([]);

    const [getimages, setGetImages] = useState();
    const [newDataLand, setNewDataLand] = useState([]);
    const [isModalShowImg, setIsModalShowImg] = useState(false);
    // const [isModalShowImgPre, setIsModalShowImgPre] = useState(false);
    const [isModalAddGua, setIsModalAddGua] = useState(false);
    // const [checkSendNEW, setCheckSendNEW] = useState(false);
    // const [refreshPage, setRefreshPage] = useState()
    // const [sendCaseBTN, setSendCaseBTN] = useState(true)

    const [index1, setIndex] = useState(); //set index send to page edit
    const [indexEdit, setIndexEdit] = useState(0); //set index send to page edit 
    const [dataedit, setDataEdit] = useState([]); //เซตสำหรับแก้ไขข้อมูล 
    const [isModalsEditGua, setIsModalsEditGua] = useState(false) // เปิด/ปิด modal แก้ไขคนค้ำ
    const [preData] = useState(true)

    var length = notes?.length
    var lastindex = length - 1

    useEffect(() => {
        loadData()
        loadMixData()
        // getImg()
    }, [sendBack])

    useEffect(() => {
        newDataLand.map((e, index) => {
            form.setFieldsValue({
                [`mixNote${index}`]: e?.approval?.mixNote,
                [`storyLand${index}`]: e?.storyLand,

            })
        })
    }, [newDataLand])

    useEffect(() => {
        if (guarantor1.length > 0) {
            countType()
        }
        setSendBack(true)
    }, [guarantor1])

    // useEffect(() => {
    //     imageBlobzz?.map((e, index) => {
    //         var PreAaproveSplit = []
    //         PreAaproveSplit = e?.pathImage?.split("/")
    //         if (PreAaproveSplit[3] === "13") {
    //             setCheckSendNEW(true)
    //         }
    //     })
    // }, [imageBlobzz, refreshPage])

    // console.log("dataFromTable", dataFromTable)
    // console.log("dataLand", dataLand)
    // console.log("land", land)
    // console.log("newDataLand", newDataLand)

    const countType = async () => {
        const lastItem = guarantor1[guarantor1?.length - 1];
        const newType = lastItem?.pathImg?.split("/")
        const intTpye = parseInt(newType[4])
        if (isNaN(intTpye)) {
            var newIntType = guarantor1?.length
        } else {
            var newIntType = intTpye
        }
        setIndexEdit(newIntType)
    }

    const loadMixData = async () => {
        await axios.post(findlandpn, { landLoanId: dataFromTable.landLoanId })
            .then(res => {
                if (res.status === 200) {
                    setNewDataLand(res.data)
                }
            }).catch((err) => {
                console.log("err", err)
            })
    }

    const land_Calculate = async (dataLand11) => {
        var interestRateLand = parseFloat(dataLand11?.interestRateLand) / 100 // อัตราดอก / 100
        var rate = (parseFloat(dataLand11?.approvedLoanAmount) * Currency(interestRateLand, { precision: 3 }).value) * parseInt(dataLand11?.approvedLoanTerm) // (ยอดจัด * อัตราดอกที่หาร100แล้ว ,precision: 3 คือทศนิยม 3 )*งวด
        var loanTerm = Math.ceil(Currency((rate + parseFloat(dataLand11?.approvedLoanAmount)) / parseInt(dataLand11?.approvedLoanTerm), { precision: 2 }).value) // (ดอกเบี้ยทั้งหมด + ยอดจัด)/ งวด
        var resultRate = (loanTerm * parseInt(dataLand11?.approvedLoanTerm)) - parseFloat(dataLand11?.approvedLoanAmount)
        var resultPrice = loanTerm * parseInt(dataLand11?.approvedLoanTerm)

        setloanTermDATA(loanTerm)
        setresultRateDATA(resultRate)
        setresultPriceDATA(resultPrice)
    }

    const handleShowImg = async () => {
        setIsModalShowImg(true)
    };

    // const handleShowImgPre = async () => {
    //     setIsModalShowImgPre(true)
    // };

    const confirmClose = () => {
        confirm({
            title: 'คุณต้องการที่ปิดหน้าต่างหรือไม่...?',
            content: 'ต้องการที่จะปิดหน้าต่างโดยยังไม่ส่งเคส กด OK เพื่อยืนยัน',
            onOk() {
                handleCancel();
            },
            onCancel() {
            },
        });
    };


    // const handleRemove = () => {
    //     // ลบรูปภาพที่อัพโหลดออกจากช่อง Upload
    //     document.querySelector('.ant-upload-list-item-done').remove();
    // };

    const imgSend = (resultImgs) => {
        return new Promise(async (resolve, reject) => {
            try {
                var status;
                newFormData.append('tm', dayjs().format("YYYY-MM-DDTHH:mm:ssZ"));

                for (const file of resultImgs) {
                    var image = {}
                    newFormData.append('file', file.imageCompressedFile);
                    newFormData.append("type", file.type);
                    newFormData.append('idcus', file?.imgid);
                    image.type = file.type
                    resultImage.push(image)
                }
                const response = await axios.post(mutips4pre, newFormData);
                if (response.data) {
                    setFileListCustomer([])
                    setFileListCustomerImageCareer([])
                    setFileListStateMent([])
                    setFileListStateMentPDF([])
                    setFileListOtherMent([])

                    setFileListLand([])
                    setFileListDeep([])
                    setFileListCustomerLeafDivider([])
                    setFileListCustomerBurden([])
                    setAllImg([])
                    // document.querySelector('.ant-upload-list-item-done').remove();
                    // setRefreshPage(dayjs())
                    // setSendCaseBTN(false)
                    SaveSuccess("top")
                    console.log("TY Pre", response.data);
                    status = response.status;
                    resolve(status);
                    sendCase()
                }
            } catch (error) {
                error(error);
                reject(error);
            } finally {
                setLoading(false);
                console.log("OK")
            }
        });
    };

    const loadCareer = async () => {
        const id = { customerId: dataFromTable.customerId }

        await axios.post(loadOJS, id)
            .then((res) => {
                setCareer(res.data)

            })
            .catch((err) => console.log(err))

    }

    const loadGuarantors = async () => {
        var dataloadGua = { typeLoan: "land", loanId: dataFromTable?.landLoanId }
        await axios.post(checkguarantors, dataloadGua)
            .then(res => {
                if (res.status === 200) {
                    // console.log("คนค้ำ", res.data)
                    const modifiedData = res.data.map((e) => {
                        return { ...e, checkDup: true };
                    })
                    setGuarantor1(modifiedData)
                } else if (res.status === 204) {
                    setGuarantor1([])
                    // console.log("ไม่พบข้อมูล")
                }
            }).catch((err) => {
                console.log("err", err)
            })
    }

    // const getImg = async () => {
    //     var path = `one_images/land/${dataFromTable?.landId}/5`
    //     setLoading(true)
    //     await axios.get(`${getAllFolderGuaImg}/${path}?token=${token}`)
    //         .then(res => {
    //             if (res.data) {
    //                 // console.log("NEW IMG", res.data)
    //                 setGetImages(res.data)
    //                 setLoading(false)
    //             } else {
    //                 setGetImages([])
    //             }
    //         })
    //         .catch((err) => {
    //             console.log("err", err)
    //             setLoading(false)
    //         })
    //     setLoading(false)
    // }

    const handleAddGua = async () => {
        setIsModalAddGua(true)
    };

    const loadData = async () => {
        setLoading(true)
        await axios.post(findlandmk, { carId: dataFromTable.landId, customerId: dataFromTable.customerId })
            .then(async (res) => {
                if (res.data) {
                    // console.log("LandLandasdasdasdasda", res.data)
                    setDes(res.data.lands.landLoanDetails.notes)
                    setNotes(res.data.lands.landLoanDetails.notes)
                    setDataLand1(res.data.lands.landLoanDetails)
                    setReSendData(res.data)
                    setDataCareer(res.data.ojs)
                    setAddresss(res.data.address)
                    setDataPhoneCus(res.data.phones)
                    setDataEmail(res.data.emails)
                    setDataSocials(res.data.socials)
                    setImageBlobzz(res.data.lands.img)
                    setLand(res.data.lands)
                    setCheckApprove(res.data.lands.landLoanDetails.approvalStatus)
                    setDataApproval(res.data.lands.apLandLoanDetails)
                    await land_Calculate(res.data.lands.landLoanDetails)
                    await loadGuarantors()
                    await loadDataLand()
                    await loadCareer()
                }
            }).catch((err) => {
                console.log("err", err)
            })
        setLoading(false)
    }

    const loadDataLand = async () => {
        await axios.post(checklandcus, { numberLand: dataFromTable.numberLand, provinces: dataFromTable.province, district: dataFromTable.district })
            .then(res => {
                if (res.status === 200) {
                    // console.log("loadDataLand", res.data)
                    setOldData(res.data)
                }
            }).catch((err) => {
                console.log("err", err)
            })
    }


    const handleSave = async () => {
        setLoading(true)
        if (allImg?.length > 0) {
            await imgSend(allImg)
        }
    };

    const sendCase = async () => {
        var mix = { ...reSendData.lands.landLoanDetails, guarantors: guarantor?.data, land: { ...reSendData.lands, img: resultImage, carInput: dayjs().format("YYYY-MM-DD") }, approvalStatus: "รอธุรการรับ" }
        await axios.put(resendlandmk1, mix)
            .then(res => {
                if (res.status === 200) {
                    sendNew("top")
                    setTimeout(() => {
                        close(false)
                    }, 1500);
                }
            }).catch((err) => console.log(err))
    };


    const SaveSuccess = (placement) => {
        api.success({
            message: `บันทึกสำเร็จ`,
            placement,
        });
    }

    const showConfirm = async () => {
        confirm({
            title: 'คุณต้องการที่จะส่งเคสนี้...?',
            onOk() {
                handleSave()
            },
            onCancel() {
            },
        });
    };

    const sendNew = (placement) => {
        api.success({
            message: "ส่งเคสสำเร็จ",
            description:
                'ส่งเคสไปยังผู้ที่เกี่ยวข้องแล้ว',
            placement,
        });
    };

    ///////////////////////////////////อัพโหลดรูปภาพใหม่/////////////////////////////////////////////
    const compressImage = async (ez, types, landId) => {
        var image = {};
        return new Promise(async (resolve) => {
            if (types !== 7) {
                if (ez.size > 4000000) {
                    console.log("4m ไม่ให้ส่ง");
                    resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
                } else {
                    let quality;
                    if (ez.size >= 2500000 && ez.size <= 4000000) {
                        quality = 0.1;
                    } else if (ez.size >= 2000000 && ez.size <= 2499999) {
                        quality = 0.15;
                    } else if (ez.size >= 1500000 && ez.size <= 1999999) {
                        quality = 0.2;
                    } else if (ez.size >= 1000000 && ez.size <= 1499999) {
                        quality = 0.3;
                    } else {
                        quality = 0.5;
                    }

                    try {
                        // ใช้ Promise เพื่อบีบอัดรูปภาพ
                        const compressedFile = await new Promise((resolveCompress) => {
                            new Compressor(ez, {
                                quality: quality,
                                success: (result) => {
                                    resolveCompress(result);
                                },
                            });
                        });

                        const fileOptions = {
                            type: compressedFile.type,
                            lastModified: Date.now(),
                        };
                        const compressedFileAsFile = new File([compressedFile], ez.name, fileOptions);
                        image.imageCompressedFile = compressedFileAsFile;
                        image.type = types
                        image.imgid = landId
                        allImg.push(image);
                        //setAllImg((prevAllImg) => [...prevAllImg, image]);

                        // ตรงนี้คือการ resolve ไฟล์ที่ถูกบีบอัด
                        resolve(image);
                    } catch (error) {
                        console.error('Image compression error:', error);
                        resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
                    }
                }
            } else {
                // ... (โลจิกสำหรับ type 7)
                image.imageCompressedFile = ez;
                image.type = types
                image.imgid = landId
                allImg.push(image);
                //setAllImg((prevAllImg) => [...prevAllImg, image]);
                resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
            }
        });
    };
    const beforeUpts = async (file, ty, landId) => {
        try {
            setLoading(true)
            const compressedFile = await compressImage(file, ty, landId);
            return Promise.resolve(compressedFile);
        } catch (error) {
            console.error('Image compression error:', error);
            message.error('Image compression failed.');
            return Promise.reject(); // เปลี่ยนให้ return Promise.reject() เมื่อไม่สามารถบีบอัดได้
        }
    };

    const handleCustomerImage = async ({ fileList }) => {
        // const newFileList = [];

        for (const removedFile of fileListCustomer.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 4)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 4));
                setAllImg(updatedAllImg);
            }
        }

        // fileList.forEach((file) => {
        //     if (!fileListCustomer.some((f) => f.uid === file.uid)) {
        //         newFileList.push(file);
        //     }
        // });

        // fileListCustomer.push(...newFileList)
        setFileListCustomer(fileList);
        setLoading(false)

    };
    const handleCustomerImageCareer = async ({ fileList }) => {
        // const newFileList = [];

        for (const removedFile of fileListCustomerImageCareer.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 6)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 6));
                setAllImg(updatedAllImg);
            }
        }

        // fileList.forEach((file) => {
        //     if (!fileListCustomerImageCareer.some((f) => f.uid === file.uid)) {
        //         newFileList.push(file);
        //     }
        // });

        // fileListCustomerImageCareer.push(...newFileList)
        setFileListCustomerImageCareer(fileList);
        setLoading(false)
    };
    const handleLand = async ({ fileList }) => {

        const newFileList = [];

        for (const removedFile of fileListLand.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 1)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 1));
                setAllImg(updatedAllImg);
            }
        }

        fileList.forEach((file) => {
            if (!fileListLand.some((f) => f.uid === file.uid)) {
                newFileList.push(file);
            }
        });

        fileListLand.push(...newFileList)
        setLoading(false)
    };
    const handleDeep = async ({ fileList }) => {

        const newFileList = [];

        for (const removedFile of fileListDeep.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 3)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 3));
                setAllImg(updatedAllImg);
            }
        }

        fileList.forEach((file) => {
            if (!fileListDeep.some((f) => f.uid === file.uid)) {
                newFileList.push(file);
            }
        });

        fileListDeep.push(...newFileList)
        // setFileListLand(fileList);
        setCheckImage(fileList);
        setLoading(false)
    };


    const handleChangeCustomerLeafDivider = async ({ fileList }) => {

        const newFileList = [];

        for (const removedFile of fileListCustomerLeafDivider.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 10)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 10));
                setAllImg(updatedAllImg);
            }
        }

        fileList.forEach((file) => {
            if (!fileListCustomerLeafDivider.some((f) => f.uid === file.uid)) {
                newFileList.push(file);
            }
        });

        fileListCustomerLeafDivider.push(...newFileList)
        setLoading(false)
    };
    const handleChangeCustomerBurden = async ({ fileList }) => {

        const newFileList = [];

        for (const removedFile of fileListCustomerBurden.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 11)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 11));
                setAllImg(updatedAllImg);
            }
        }

        fileList.forEach((file) => {
            if (!fileListCustomerBurden.some((f) => f.uid === file.uid)) {
                newFileList.push(file);
            }
        });

        fileListCustomerBurden.push(...newFileList)
        setLoading(false)
    };
    const handleStatement = async ({ fileList }) => {

        // const newFileList = [];

        for (const removedFile of fileListStatement.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 8)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 8));
                setAllImg(updatedAllImg);
            }
        }

        // fileList.forEach((file) => {
        //     if (!fileListStatement.some((f) => f.uid === file.uid)) {
        //         newFileList.push(file);
        //     }
        // });

        // fileListStatement.push(...newFileList)
        setFileListStateMent(fileList)
        setLoading(false)
    };
    const handleStatementPDF = async ({ fileList }) => {

        // const newFileList = [];

        for (const removedFile of fileListStatementPDF.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 7)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 7));
                setAllImg(updatedAllImg);
            }
        }

        // fileList.forEach((file) => {
        //     if (!fileListStatementPDF.some((f) => f.uid === file.uid)) {
        //         newFileList.push(file);
        //     }
        // });

        // fileListStatementPDF.push(...newFileList)
        setFileListStateMentPDF(fileList)
        setLoading(false)
    };
    const handleOtherMent = async ({ fileList }) => {

        for (const removedFile of fileListOtherMent.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 12)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 12));
                setAllImg(updatedAllImg);
            }
        }

        setFileListOtherMent(fileList)
        setLoading(false)
    };

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
    };
    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const handleCancel = () => {
        close(false)
        navigate(`/checkInfoLand`, {
            state: {
                customerId: 0,
                landId: 0,
                firstname: '',
                lastname: '',
                open: false
            }
        })
    }
    const handleCancelPreview = () => {
        setPreviewVisible(false);
    }

    const currencyFormatOne = (amount) => {
        return Number(amount)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }

    const renderGuarantorNewImg = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
        var itemSplit = []
        if (item?.pathImg) {
            itemSplit = item.pathImg.split("/")
        }

        const handleEdit = (dataedit, index1) => {
            setIndex(index1) //เซตค่า index ให้กับชุด ข้อมูล
            setDataEdit({ dataedit, index1 }) //ส่งข้อมูลด index ไปหน้าแก้ไข
            setIsModalsEditGua(true);
        }
        return (
            <>
                <div><b><u>ลำดับที่ {index + 1}</u></b></div>
                <aside style={{ width: '90%' }}>
                    <div style={{ marginBottom: 0 }}>
                        <span>ชื่อ-นามสกุล : </span><b>{item?.snam + ' ' + item?.firstname + ' ' + item?.lastname}</b>
                    </div>

                    <div style={{ marginBottom: 0 }}>
                        <span>ความสัมพันธ์ : </span> <b>{item?.description}</b>
                    </div>
                    {item?.phones?.length > 0 ?
                        <>
                            <div style={{ marginBottom: 0 }}>
                                <span>เบอร์โทรติดต่อ : </span> <b>{item?.phones[0]?.telp}</b>
                            </div>
                        </>
                        : <div style={{ marginBottom: 0 }}>
                            <span>เบอร์โทรติดต่อ : </span> <b>ไม่มี</b>
                        </div>
                    }

                    {item?.address?.length > 0 ?
                        <>
                            <div style={{ marginBottom: 0 }}>
                                <span > ที่อยู่ :  </span>
                                <b>
                                    {
                                        item.address[0]?.houseNumber + ' ' +
                                        "หมู่ " + item.address[0]?.soi + ' ' +
                                        "ถ." + item.address[0]?.road + ' ' +
                                        "ต." + item.address[0]?.subdistrict + ' ' +
                                        "อ." + item.address[0]?.district + ' ' +
                                        "จ." + item.address[0]?.province + ' '
                                        + item.address[0]?.zipCode
                                    }
                                </b>
                            </div>
                        </>
                        : null
                    }

                </aside>
                {/* <Divider/> */}
                {itemSplit.length > 0 ? (
                    <>
                        <Image.PreviewGroup>
                            <Row justify={"center"}>
                                {imageBlobzz?.map((items, index) => {
                                    if (items.type === 5) {
                                        const segments = items.pathImage.split("/")
                                        if (itemSplit[4] === segments[4]) {
                                            return (
                                                // <Image width={'100px'} key={index} src={`data:${items?.Attr};base64,${items?.data?.toString('base64')}`} alt={`Image ${index + 1}`} />
                                                <Image width={"50px"} key={index} src={`${getImagessGua}/${items.pathImage}?token=${token}`} alt={`รูปภาพ ${index + 1}`} />
                                            )
                                        }
                                    }
                                    return null;
                                }
                                )}
                            </Row>
                        </Image.PreviewGroup>
                    </>
                ) : null
                }
                {/* <Divider style={{ margin: 5 }} /> */}
                <Button type="text" style={{ background: "#e0e0e0" }} onClick={() => { handleEdit(guarantor1[index], index); }}>
                    เพิ่มรูปคนค้ำ
                </Button>
                <Divider />
            </>
        )
    }
    const renderItemAddress = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
        return (
            <>
                <div><b><u>ลำดับที่ {index + 1}</u></b></div>
                <aside style={{ width: '90%' }}>
                    <div style={{ marginBottom: 0 }}>
                        <span>เลขทะเบียนบ้าน : </span> <b>{item.houseRegistrationNumber}</b>
                    </div>
                    <div
                        style={{ marginBottom: 0 }}>
                        <span>ถนน : </span><b>{item.road}</b>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>ตำบล : </span> <b>{item.subdistrict}</b>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>บ้านเลขที่ : </span> <b>{item.houseNumber}</b>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>หมู่บ้าน : </span> <b>{item.village}</b>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>อำเภอ : </span> <b>{item.district}</b>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>หมู่ / ซอย : </span> <b>{item.soi}</b>
                    </div>
                    <div
                        style={{ marginBottom: 0 }}>
                        <span>จังหวัด : </span> <b>{item.province}</b>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>รหัสไปรษณีย์ : </span> <b>{item.zipCode}</b>
                    </div>

                </aside>
                {address.length >= 1 ? <Divider /> : null}
            </>
        )
    }
    const renderItemPhones = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
        return (
            <>
                <div
                    style={{ marginBottom: 0 }}
                >
                    <span>{item.phoneType ? <>{item.phoneType} : </> : null}</span>
                    <b> {item.telp}</b>
                </div>
            </>
        )
    }
    const renderDataNote = ({ item, index }) => {
        return (
            <>
                <Row gutter={32} justify={'center'}>
                    <Col className='gutter-row' span={24}>
                        {/* {guarantor?.data?.typeLoan === "land" ? */}
                        <>
                            <Card
                                key={index}
                                style={{ width: '270px', textAlign: 'left' }}>
                                <>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>สาเหตุ : </span>
                                        <b>{item.cause}</b>
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>หมายเหตุ : </span>
                                        <b>{item.note}</b>
                                    </div>
                                    {/* <div style={{ marginBottom: 0 }}>
                      <span>ผู้สร้างหมายเหตุ : </span>
                      <b>{item.noteBy}</b>
                    </div> */}
                                    <div style={{ marginBottom: 0 }}>
                                        <span>สถานะที่ทำการหมายเหตุ : </span>
                                        <b>{item.status}</b>
                                    </div>
                                </>
                            </Card>
                        </>
                    </Col>
                </Row>
            </>
        )
    }
    const renderLandData = ({ item, index }) => {
        return (
            <>
                <Divider orientation={'left'}><b><u>รายละเอียดที่ดิน ({item?.numberLand})</u></b></Divider>
                <aside style={{ width: '90%' }}>
                    <div style={{ marginBottom: 0 }}>
                        <span>ประเภทเอกสาร : </span>{<b>{item?.productTypeLand === 1 ? "นส.3" : "นส.4"}</b>}
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>เลขโฉนดที่ดิน : </span>{<b>{item?.numberLand}</b>}
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>เลขที่ดินหรือระวาง : </span>{<b>{item?.numberLandlawang}</b>}
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>จังหวัด : </span>{<b>{item?.provinces}</b>}
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>อำเภอ : </span>{<b>{item?.district}</b>}
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>พื้นที่ไร่ : </span>{<b>{item?.rai}</b>}
                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                            ไร่
                        </span>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>พื้นที่งาน : </span>{<b>{item?.workArea}</b>}
                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                            งาน
                        </span>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>พื้นที่ตารางวา : </span>{<b>{currencyFormatOne(item?.squareWaArea)}</b>}
                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                            บาท
                        </span>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>ราคาประเมินที่ดิน : </span> {<b>{item?.landPrice}</b>}
                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                            บาท / ตารางวา
                        </span>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>ราคารวม : </span> {<b>{currencyFormatOne(item?.resultLandPrice)}</b>}
                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                            บาท
                        </span>
                    </div>
                </aside>
                <Divider orientation={'left'}><b><u>สตอรี่รายละเอียดที่ดิน ({item?.numberLand})</u></b></Divider>
                <Form.Item name={`storyLand${index}`} label="">
                    <TextArea style={{ color: 'blue' }} rows={5} disabled />
                </Form.Item>
            </>
        )

    }
    const renderCoordinates = ({ item, index }) => { //render Land Array
        return (
            <>
                <div>
                    <span>รายละเอียดพิกัด ({item?.numberLand}) : </span>
                    <a href={`https://www.google.com/maps?q=${item.parcellat},${item.parcellon}`} target="_blank">
                        {`${item.parcellat},${item.parcellon}`}
                    </a>
                </div>
            </>
        )
    }
    const renderApproval = ({ item, index }) => {
        return (
            <>
                <aside style={{ width: '90%' }}>
                    <div style={{ marginBottom: 0 }}>
                        <span>ยอดที่อนุมัติ ({item?.numberLand}) : </span>{<b>{currencyFormatOne(item?.approval?.minPrice)} - {currencyFormatOne(item?.approval?.maxPrice)}</b>}
                        <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                            บาท
                        </span>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                        <span>หมายเหตุ : </span>{<b>{item?.approval?.note ? item?.approval?.note : "-"}</b>}
                    </div>
                </aside>
            </>
        )
    }
    const renderMixNote = ({ item, index }) => {
        return (
            <>
                {
                    index === 0 ?
                        null :
                        <>
                            <Row justify={'left'}><b><u>หมายเหตุจดรวมโฉนด</u></b></Row>
                            <Row gutter={32} justify={'center'}>
                                <Col className='gutter-row' span={24}>
                                    <Form.Item style={{ marginBottom: 0, textAlign: 'center' }} label='' name={`mixNote${index}`}>
                                        <TextArea rows={5} disabled name={`mixNote${index}`} style={{ color: "blue" }}></TextArea>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Divider />
                        </>
                }
            </>
        )
    }
    const renderTitleDeed = ({ item, index }) => { //render Land Array
        return (
            <>
                <Row justify={'left'}><b><u>รูปโฉนด/หน้า-หลัง ({item.numberLand})</u></b></Row>
                <Row gutter={32} justify={'center'}>
                    <Space>
                        <div className='gutter-row' span={12}>
                            <b>
                                <Form.Item label='เพิ่มรูป' name='imageIDGuCar' >
                                    <Upload
                                        name='imageIDGuCar'
                                        multiple={true}
                                        listType="picture-card"
                                        // fileList={fileListDeep}
                                        onPreview={handlePreview}
                                        onChange={handleDeep}
                                        beforeUpload={(file) => beforeUpts(file, 3, item.landId).then(() => false).catch(() => true)}
                                    >
                                        <div>
                                            <PlusOutlined />
                                            <div className="ant-upload-text">upload</div>
                                        </div>
                                    </Upload>
                                    <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                        <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                    </Modal>
                                </Form.Item>
                            </b>
                        </div>
                    </Space>
                </Row>
                <Divider />
            </>
        )
    }
    const renderDivision = ({ item, index }) => { //render Land Array
        return (
            <>
                <Row justify={'left'}><b><u>ใบแบ่งใบต่อ ({item.numberLand})</u></b></Row>
                <Row gutter={32} justify={'center'}>
                    <Space>
                        <div className='gutter-row' span={12}>
                            <b>
                                <Form.Item label='เพิ่มรูป' name='imageIDGuCar'>
                                    <Upload
                                        name='imageIDGuCar'
                                        multiple={true}
                                        listType="picture-card"
                                        // fileList={fileListCustomerLeafDivider}
                                        onPreview={handlePreview}
                                        onChange={handleChangeCustomerLeafDivider}
                                        beforeUpload={(file) => beforeUpts(file, 10, item.landId).then(() => false).catch(() => true)}
                                    >
                                        <div>
                                            <PlusOutlined />
                                            <div className="ant-upload-text">upload</div>
                                        </div>
                                    </Upload>
                                    <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                        <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                    </Modal>
                                </Form.Item>
                            </b>
                        </div>
                    </Space>
                </Row>
                <Divider />
            </>
        )
    }
    const renderLawang = ({ item, index }) => { //render Land Array
        return (
            <>
                <Row justify={'left'}><b><u>ระวาง ({item.numberLand})</u></b></Row>
                <Row gutter={32} justify={'center'}>
                    <Space>
                        <div className='gutter-row' span={12}>
                            <b>
                                <Form.Item label='เพิ่มรูป' name='imageIDGuCar'>
                                    <Upload
                                        name='imageIDGuCar'
                                        multiple={true}
                                        listType="picture-card"
                                        // fileList={fileListCustomerBurden}
                                        onPreview={handlePreview}
                                        onChange={handleChangeCustomerBurden}
                                        beforeUpload={(file) => beforeUpts(file, 11, item.landId).then(() => false).catch(() => true)}
                                    >
                                        <div>
                                            <PlusOutlined />
                                            <div className="ant-upload-text">upload</div>
                                        </div>
                                    </Upload>
                                    <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                        <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                    </Modal>
                                </Form.Item>
                            </b>
                        </div>
                    </Space>
                </Row>
                <Divider />
            </>
        )
    }
    const renderLandBuildings = ({ item, index }) => { //render Land Array
        return (
            <>
                <Row justify={'left'}><b><u>รูปที่ดิน/สิ่งปลูกสร้าง-ที่ดินปัจจุบัน ({item.numberLand})</u></b></Row>
                <Row gutter={32} justify={'center'}>
                    <Space>
                        <div className='gutter-row' span={12}>
                            <b>
                                <Form.Item label='เพิ่มรูป' name='imageIDGuCar'>
                                    <Upload
                                        // onRemove={handleRemove}
                                        name='imageIDGuCar'
                                        multiple={true}
                                        listType="picture-card"
                                        // fileList={fileListLand}
                                        onPreview={handlePreview}
                                        onChange={handleLand}
                                        beforeUpload={(file) => beforeUpts(file, 1, item.landId).then(() => false).catch(() => true)}
                                    >
                                        <div>
                                            <PlusOutlined />
                                            <div className="ant-upload-text">upload</div>
                                        </div>
                                    </Upload>
                                    <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                        <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                    </Modal>
                                </Form.Item>
                            </b>
                        </div>

                    </Space>
                </Row>
                <Divider />
            </>
        )
    }

    return (
        <Modal open={open} onCancel={confirmClose} width={'1100px'}
            footer={[null]}>
            <Spin spinning={loading} size='large' tip=" Loading... ">
                <Row justify={'center'}>
                    <Col className='gutter-row' span={24} style={{ fontSize: '25px', textAlign: 'center' }}>
                        <b>รายละเอียด</b>
                    </Col>
                </Row>
                <Row justify={'center'}>
                    <Divider style={{ margin: 5 }} />
                    <Form
                        labelCol={{
                            span: 12,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                        style={{
                            width: '90%',
                        }}
                        form={form}
                        autoComplete="off"
                        initialValues={{ remember: true }}
                    >
                        <div className='main'>
                            <Card style={{ width: '100%' }}>
                                <Row justify={'center'}>
                                    <Col span={12} style={{ textAlign: 'left' }}>
                                        {
                                            land?.landLoanDetailsRe ?
                                                <>
                                                    <div style={{ margin: 0 }}>
                                                        เลขสัญญา : <Tag>{land?.ISSUNO ? land?.ISSUNO : null}</Tag>
                                                    </div>
                                                    {land?.landLoanDetailsRe?.GRDCOD ?
                                                        <div style={{ margin: 0 }}>
                                                            เกรด : {land?.landLoanDetailsRe?.GRDCOD ? <Tag> {land?.landLoanDetailsRe?.GRDCOD} </Tag> : null}
                                                        </div>
                                                        : null
                                                    }
                                                </>
                                                :
                                                null
                                        }
                                    </Col>
                                    <Col span={12} style={{ textAlign: 'right' }}>
                                        <div style={{ margin: 0 }}>
                                            สถานะ : <Tag color={"green"}>{dataLand?.approvalStatus}</Tag>
                                        </div>
                                    </Col>
                                </Row>
                                <Divider />
                                {
                                    dataFromTable.approvalStatus === "อนุมัติแบบมีเงื่อนไข" ?
                                        <>
                                            <Row justify={'left'}><Col span={24} style={{ color: "red" }}><b><u>อนุมัติแบบมีเงื่อนไข!</u></b></Col></Row>
                                            <Card
                                                key={lastindex}
                                                style={{ textAlign: 'left' }}>
                                                <>
                                                    <div style={{ marginBottom: 0 }}>
                                                        <span>สาเหตุ : </span>
                                                        <b>{notes[lastindex]?.cause}</b>
                                                    </div>
                                                    <div style={{ marginBottom: 0 }}>
                                                        <span>หมายเหตุ : </span>
                                                        <b>{notes[lastindex]?.note}</b>
                                                    </div>
                                                    <div style={{ marginBottom: 0 }}>
                                                        <span>ผู้สร้างหมายเหตุ : </span>
                                                        <b>{notes[lastindex]?.noteBy}</b>
                                                    </div>
                                                    <div style={{ marginBottom: 0 }}>
                                                        <span>สถานะที่ทำการหมายเหตุ : </span>
                                                        <b>{notes[lastindex]?.status}</b>
                                                    </div>
                                                </>
                                            </Card>
                                            <Divider />
                                        </>
                                        :
                                        null
                                }
                                {dataLand?.nationalId === "นิติบุคคล" ?
                                    <>
                                        <Row justify={'left'}><b><u>รายละเอียดผู้ขอกู้</u></b></Row>
                                        <Row gutter={32} justify={'center'}>
                                            <aside style={{ width: '90%' }}>
                                                <div style={{ marginBottom: 0, }}>
                                                    <span>ประเภท : </span> <b>{dataLand?.nationalId}</b>
                                                </div>
                                                <div style={{ marginBottom: 0, }}>
                                                    <span>เลขประจำตัวผู้เสียภาษี : </span> <b>{dataLand.identificationId}</b>
                                                </div>
                                                <div style={{ marginBottom: 0, }}>
                                                    <span>
                                                        {dataLand?.snam} : </span> <b>{dataLand.firstname + ' ' + dataLand?.lastname}</b>
                                                </div>
                                                <div style={{ marginBottom: 0, }}>
                                                    <span>วันจดทะเบียน : </span> <b> {dataLand?.birthdate}</b>
                                                </div>
                                                <div style={{ marginBottom: 0, }}>
                                                    <b>
                                                        {
                                                            dataLand?.phones?.map((item, index) => {
                                                                return renderItemPhones({ item, index, key: `{ item.identification } - ${index}` })
                                                            })
                                                        }
                                                    </b>
                                                </div>
                                            </aside>
                                        </Row>
                                    </>
                                    :
                                    <>
                                        <Row justify={'left'}><b><u>รายละเอียดผู้ขอกู้</u></b></Row>
                                        <Row gutter={32} justify={'center'}>
                                            <aside style={{ width: '90%' }}>
                                                <div style={{ marginBottom: 0, }}>
                                                    <span>ชื่อ - นามสกุล : </span>
                                                    <b>{dataLand?.snam + '   ' + dataLand?.firstname + '   ' + dataLand?.lastname}</b>
                                                </div>

                                                <div style={{ marginBottom: 0, }}>
                                                    <span>ชื่อเล่น : </span>
                                                    <b>{dataLand?.nickname}</b>
                                                </div>

                                                <div style={{ marginBottom: 0, }}>
                                                    <span>เลขบัตรประชาชน : </span>
                                                    <b>{dataLand?.identificationId}</b>
                                                </div>
                                                <div style={{ marginBottom: 0, }}>
                                                    <b>
                                                        {
                                                            dataPhoneCus?.map((item, index) => {
                                                                return renderItemPhones({ item, index })
                                                            })
                                                        }
                                                    </b>
                                                </div>
                                                <div style={{ margin: 0, }}>
                                                    <span>เพศ : </span>
                                                    <b>{dataLand?.gender}</b>
                                                </div>
                                                <div style={{ marginBottom: 0, }}>
                                                    <span>{dataEmail === "" ? <> </> : <>{dataEmail?.emailData}</>}</span>
                                                    <b>{dataEmail?.emailData}</b>
                                                </div>
                                                <div style={{ marginBottom: 0, }}>
                                                    <span>{dataSocials?.socialType}  </span>
                                                    <b>{dataSocials?.socialData}</b>
                                                </div>
                                            </aside>
                                        </Row>
                                    </>
                                }
                                {reSendData?.lands?.landLoanDetailsRe?.priceChange ?
                                    <>
                                        {
                                            reSendData?.lands?.landLoanDetailsRe?.productLoanLandType === "Pre-Aaprove-เปลี่ยนสัญญา" ||
                                                reSendData?.lands?.landLoanDetailsRe?.productLoanLandType === "Pre-Aaprove-รี+เปลี่ยนสัญญา" ||
                                                reSendData?.lands?.landLoanDetailsRe?.productLoanLandType === "รี+เปลี่ยนสัญญา" ||
                                                reSendData?.lands?.landLoanDetailsRe?.productLoanLandType === "เปลี่ยนสัญญา" ?
                                                <>
                                                    <Divider style={{ margin: 3 }} />
                                                    <Divider orientation={'left'}><b><u>ค่าใช้จ่ายที่ลูกค้าต้องจ่าย</u></b></Divider>
                                                    <aside style={{ width: '100%', background: '#061178', padding: '8px', color: 'white' }} className='center'>
                                                        <div name='priceChange' style={{ marginBottom: 3 }}>
                                                            <span>ค่าเปลี่ยนสัญญา : </span> <b> {currencyFormatOne(reSendData?.lands?.landLoanDetailsRe?.priceChange)}</b> บาท
                                                        </div>
                                                        <div name='overdue' style={{ marginBottom: 3 }}>
                                                            <span>ยอดค้าง + เบี้ยปรับ : </span> <b> {currencyFormatOne(reSendData?.lands?.landLoanDetailsRe?.overdue)}</b>  บาท
                                                        </div>
                                                        <div name='preChange' style={{ marginBottom: 3 }}>
                                                            <span>ค่างวดล่วงหน้า : </span> <b> {currencyFormatOne(reSendData?.lands?.landLoanDetailsRe?.preChange)}</b>  บาท
                                                        </div><br />
                                                        <div>
                                                            <span>ยอดรวม : </span> <b> {currencyFormatOne(~~reSendData?.lands?.landLoanDetailsRe?.preChange + ~~reSendData?.lands?.landLoanDetailsRe?.overdue + ~~reSendData?.lands?.landLoanDetailsRe?.priceChange
                                                            )}</b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </div>
                                                    </aside>
                                                    <Divider style={{ margin: 3 }} />
                                                </>
                                                :
                                                null
                                        }

                                    </>
                                    :
                                    null
                                }
                                <Divider />
                                {address?.length >= 1 ?
                                    <>
                                        <Row justify={'left'}>
                                            <b><u>รายละเอียดที่อยู่</u></b>
                                        </Row>
                                        <Row gutter={32} justify={'center'}>
                                            {
                                                address?.map((item, index) => {
                                                    return renderItemAddress({ item, index })
                                                })
                                            }

                                        </Row>
                                    </>
                                    : null
                                }
                                {dataFromTable?.productLoanLandType === 'ย้ายไฟแนนซ์' || dataFromTable?.productLoanLandType === 'Pre-Aaprove-ย้ายไฟแนนซ์' ?
                                    <>
                                        <Row justify={'left'}><b><u>รายละเอียดย้ายไฟแนนซ์เดิม</u></b></Row>
                                        <Row gutter={32} justify={'center'}>
                                            <aside style={{ width: '90%' }}>
                                                <div
                                                    style={{ marginBottom: 0, textAlign: 'left' }}
                                                // label='จากไฟแนนซ์'
                                                >
                                                    <span>จากไฟแนนซ์ : </span>
                                                    <b>{land?.landOldLoanDetails?.issuno}</b>
                                                </div>
                                                <div style={{ marginBottom: 0 }}>
                                                    <span>ยอดปิดไฟแนนซ์ : </span>
                                                    <b>{currencyFormatOne(land?.landOldLoanDetails?.oldLandLoanAmount)}</b>
                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                        บาท
                                                    </span>
                                                </div>
                                                <div style={{ marginBottom: 0 }}>
                                                    <span>ค่างวด / เดือน : </span>
                                                    <b>{currencyFormatOne(land?.landOldLoanDetails?.oldLandMonthlyPayment)}</b>
                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                        บาท
                                                    </span>
                                                </div>

                                                <div style={{ marginBottom: 0 }}>
                                                    <span>จำนวนงวดที่ผ่อน : </span>
                                                    <b>{land?.landOldLoanDetails?.oldLandLoanTerm}</b>
                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                        งวด
                                                    </span>
                                                </div>
                                            </aside>
                                        </Row>
                                        <Divider />
                                    </>
                                    : null
                                }
                                <>
                                    <Row justify={'left'}><b><u>รายละเอียด{dataFromTable?.productLoanLandType}</u></b></Row>
                                    <Row gutter={32} justify={'center'}>
                                        <aside style={{ width: '90%' }}>

                                            <div style={{ marginBottom: 0, }}>
                                                <span>ยอดจัด : </span>
                                                <b>{currencyFormatOne(dataLand?.loanAmounttLand)}</b>
                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                    บาท
                                                </span>
                                            </div>
                                            <div style={{ marginBottom: 0, }}>
                                                <span>จำนวนงวด : </span>
                                                <b>{dataLand?.loanLandTerm}</b>
                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                    งวด
                                                </span>
                                            </div>

                                            <div style={{ marginBottom: 0, }}>
                                                <span>งวดละ : </span>
                                                <b>{currencyFormatOne(dataLand?.monthlyPaymentLand)}</b>
                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                    บาท
                                                </span>
                                            </div>
                                            <div style={{ marginBottom: 0, }}>
                                                <span>ดอกเบี้ย : </span>
                                                <b>{currencyFormatOne(dataLand?.interesttLand)}</b>
                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                    บาท
                                                </span>
                                            </div>

                                            <div style={{ marginBottom: 0, }}>
                                                <span>ราคารวม : </span>
                                                <b>{currencyFormatOne(~~dataLand?.loanAmounttLand + ~~dataLand?.interesttLand)}</b>
                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                    บาท
                                                </span>
                                            </div>
                                            <div style={{ marginBottom: 0, }}>
                                                <span>ผู้เสนอเคสเช่าซื้อ : </span>
                                                <b>{dataLand?.proposalBy}</b>
                                            </div>
                                        </aside>
                                    </Row>
                                    <Divider />
                                </>
                                <Col gutter={32} justify={'center'}>
                                    {
                                        newDataLand?.map((item, index) => {
                                            return renderLandData({ item, index })
                                        })
                                    }
                                </Col>
                                <Divider />
                                {
                                    dataLand?.parcellat ?
                                        <>
                                            <Row justify={'left'}><b><u>พิกัด</u></b></Row>
                                            {
                                                newDataLand?.map((item, index) => {
                                                    return renderCoordinates({ item, index })
                                                })
                                            }
                                            <Divider />
                                        </>
                                        : null
                                }
                                <Row justify={'left'}><b><u>ราคาประเมินที่ดิน</u></b></Row>
                                <Row gutter={32} justify={'center'}>
                                    {
                                        newDataLand?.map((item, index) => {
                                            return renderApproval({ item, index })
                                        })
                                    }
                                </Row>
                                <Divider />
                                {
                                    land?.approval?.mixNote === "" ||
                                        land?.approval?.mixNote === undefined ||
                                        land?.approval?.mixNote === null ?
                                        null
                                        :
                                        <>
                                            {
                                                newDataLand?.map((item, index) => {
                                                    return renderMixNote({ item, index, key: `{item.identificationId} - ${index}` })
                                                })
                                            }
                                        </>
                                }
                                {
                                    dataApproval?.loanId > 0 || dataLand1?.approvedLoanAmount > 0 ?
                                        <>
                                            <Row justify={'left'}><b><u>ยอดที่อนุมัติ</u></b></Row>
                                            <Row gutter={32} justify={'center'} style={{ backgroundColor: "lightgreen" }}>
                                                <aside style={{ width: '90%' }}>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>ยอดจัด : </span>
                                                        {
                                                            dataApproval?.apLoanAmount !== "0" ?
                                                                <b>{currencyFormatOne(dataApproval?.apLoanAmount)}</b>
                                                                :
                                                                <b>{currencyFormatOne(dataLand1?.approvedLoanAmount)}</b>
                                                        }
                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                            บาท
                                                        </span>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>จำนวนงวด : </span>
                                                        {
                                                            dataApproval?.apLoanTerm !== "0" ?
                                                                <b>{dataApproval?.apLoanTerm}</b>
                                                                :
                                                                <b>{dataLand1?.approvedLoanTerm}</b>
                                                        }
                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                            งวด
                                                        </span>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>งวดละ : </span>
                                                        {
                                                            dataApproval?.apMonthlyPayment !== "0" ?
                                                                <b>{currencyFormatOne(dataApproval?.apMonthlyPayment)}</b>
                                                                :
                                                                <b>{currencyFormatOne(loanTermDATA)}</b>
                                                        }
                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                            บาท
                                                        </span>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>ดอกเบี้ย : </span>
                                                        {
                                                            dataApproval?.apInterest !== "0" ?
                                                                <b>{currencyFormatOne(dataApproval?.apInterest)}</b>
                                                                :
                                                                <b>{currencyFormatOne(resultRateDATA)}</b>
                                                        }
                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                            บาท
                                                        </span>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>ราคารวม : </span>
                                                        {
                                                            dataApproval?.apInstallmentWithInterest !== "0" ?
                                                                <b>{currencyFormatOne(dataApproval?.apInstallmentWithInterest)}</b>
                                                                :
                                                                <b>{currencyFormatOne(resultPriceDATA)}</b>
                                                        }
                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                            บาท
                                                        </span>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>ผู้อนุมัติเคส : </span>
                                                        <b>{dataLand1?.approvedBy}</b>
                                                    </div>
                                                </aside>
                                            </Row>
                                            <Divider />
                                        </>
                                        : null
                                }
                                {
                                    land?.landLoanDetails?.approvalStatus === 'อนุมัติ' &&
                                        land?.landLoanDetailsRe?.landId !== 0

                                        ?
                                        <>
                                            <Divider orientation={'left'}><b><u>รายละเอียดยอดกู้</u></b></Divider>
                                            <aside style={{ width: '100%' }}>
                                                <div label='ค่างวด' name='DAMT' style={{ marginBottom: 3 }}>
                                                    <span>ค่างวด : </span><b>
                                                        {land?.landLoanDetailsRe?.DAMT ? currencyFormatOne(land?.landLoanDetailsRe?.DAMT) + " บาท" : "0"}
                                                    </b>
                                                </div>
                                                <div label='ยอดจัดครั้งที่แล้ว' name='tcshprc' style={{ marginBottom: 3 }}>
                                                    <span>ยอดจัดครั้งที่แล้ว : </span><b>{land?.landLoanDetailsRe?.tcshprc ? currencyFormatOne(land?.landLoanDetailsRe?.tcshprc) + " บาท" : 0}</b>
                                                </div>
                                                <div label='ราคาเช่าซื้อครั้งที่แล้ว' name='balanc' style={{ marginBottom: 3 }}>
                                                    <span>ราคาเช่าซื้อครั้งที่แล้ว : </span><b>{land?.landLoanDetailsRe?.balanc ? currencyFormatOne(land?.landLoanDetailsRe?.balanc) + " บาท" : 0}</b>
                                                </div>
                                                <div label='ยอดที่ชำระมาแล้วทั้งหมด' name='totalpayment' style={{ marginBottom: 3 }}>
                                                    <span>ยอดที่ชำระมาแล้วทั้งหมด : </span><b>{land?.landLoanDetailsRe?.totalpayment ? currencyFormatOne(land?.landLoanDetailsRe?.totalpayment) + " บาท" : 0}</b>
                                                </div>
                                                <div label='ล/น คงเหลือรวม' name='neekong' style={{ marginBottom: 3 }}>
                                                    <span>ล/น คงเหลือรวม : </span><b>{land?.landLoanDetailsRe?.neekong ? currencyFormatOne(land?.landLoanDetailsRe?.neekong) + " บาท" : 0}</b>
                                                </div>
                                                <div label='เบี้ยปรับ' name='beeypup' style={{ marginBottom: 3 }}>
                                                    <span>เบี้ยปรับ : </span><b>{land?.landLoanDetailsRe?.beeypup ? currencyFormatOne(land?.landLoanDetailsRe?.beeypup) + " บาท" : 0}</b>
                                                </div>
                                                <div label='ลูกหนี้อื่นๆ' name='OTHR' style={{ marginBottom: 3 }}>
                                                    <span>ลูกหนี้อื่นๆ : </span><b>{land?.landLoanDetailsRe?.OTHR ? currencyFormatOne(land?.landLoanDetailsRe?.OTHR) + " บาท" : 0}</b>
                                                </div>
                                                <div label='จำนวนงวดที่ผ่อนมาแล้ว' name='NOPAY' style={{ marginBottom: 3 }}>
                                                    <span>จำนวนงวดที่ผ่อนมาแล้ว : </span><b>{land?.landLoanDetailsRe?.NOPAY ? currencyFormatOne(land?.landLoanDetailsRe?.NOPAY) + " งวด" : 0}</b>
                                                </div>
                                                <div label='จำนวนงวดทั้งหมด' name='nopay' style={{ marginBottom: 3 }}>
                                                    <span>จำนวนงวดทั้งหมด : </span><b>{land?.landLoanDetailsRe?.nopays ? currencyFormatOne(land?.landLoanDetailsRe?.nopays) + " งวด" : 0}</b>
                                                </div>
                                                <div label='ตัดสด งวดที่' name='reqNo' style={{ marginBottom: 3 }}>
                                                    <span>ตัดสด งวดที่ : </span><b>{land?.landLoanDetailsRe?.reqNo ? currencyFormatOne(land?.landLoanDetailsRe?.reqNo) : 0}</b>
                                                </div>
                                                <div style={{ marginBottom: 0, background: '#1677ff' }}>
                                                    <span>จำนวนงวดที่ค้าง : </span>
                                                    <b>{~~land?.landLoanDetailsRe?.nopays - ~~land?.landLoanDetailsRe?.NOPAY} งวด</b>
                                                </div>
                                                <div style={{ marginBottom: 0, }}>
                                                    <span>ยอดรีไฟแนนซ์เพื่อจัดใหม่ : </span>
                                                    <b>{currencyFormatOne(land?.landLoanDetailsRe?.re1)} บาท</b>
                                                </div>
                                            </aside>
                                            <Divider orientation={'left'}><b><u>รายละเอียดจัดใหม่</u></b></Divider>
                                            <aside style={{ width: '100%' }}>
                                                <b>
                                                    <div style={{ margin: 0, }}>
                                                        <span>ยอดรีไฟแนนซ์เพื่อจัดใหม่ : </span>
                                                        <b style={{ color: 'red', fontSize: '18px' }}><u>{currencyFormatOne(land?.landLoanDetailsRe?.re1)}</u></b>
                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                            บาท
                                                        </span>
                                                    </div>
                                                </b>
                                                <b>
                                                    <div style={{ margin: 0, }}>
                                                        <span>ยอดจัดใหม่ : </span>
                                                        <b style={{ color: 'red', fontSize: '18px' }}><u>{currencyFormatOne(land?.landLoanDetailsRe?.approvedLoanAmount)}</u></b>
                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                            บาท
                                                        </span>
                                                    </div>
                                                </b>
                                                <b>
                                                    <div style={{ margin: 0, }}>
                                                        <span>จำนวนงวด : </span>
                                                        <b style={{ color: 'red', fontSize: '18px' }}><u>{currencyFormatOne(land?.landLoanDetailsRe?.approvedLoanTerm)}</u></b>
                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                            งวด
                                                        </span>
                                                    </div>
                                                </b>
                                            </aside>
                                        </>
                                        : null

                                }
                                <Row justify={'left'}>
                                    <Divider orientation={'left'}><b><u>อาชีพ - รายได้</u></b></Divider>
                                </Row>
                                {
                                    career !== undefined || career?.length > 0 ?
                                        <>
                                            {career?.map((e, index) => {
                                                return (
                                                    <Row gutter={32} justify={'center'}>
                                                        <aside style={{ width: '90%' }}>
                                                            <div style={{ marginBottom: 0, }}>
                                                                <span>กลุ่มอาชีพ : </span>
                                                                <b>{e?.desc}</b>
                                                            </div>
                                                            <div style={{ marginBottom: 0, }}>
                                                                <span>ชื่อบริษัท(สถานที่ทำงาน) : </span>
                                                                <b>{e?.companyName}</b>
                                                            </div>
                                                            <div style={{ marginBottom: 0, }}>
                                                                <span>อาชีพ  : </span>
                                                                <b>{e?.descSub}</b>
                                                            </div>
                                                            <div style={{ marginBottom: 0, }}>
                                                                <span>รายได้ / เดือน : </span>
                                                                <b>{currencyFormatOne(e?.incomeMonth)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </div>
                                                            <div style={{ marginBottom: 0, }}>
                                                                <span>สาขาอาชีพ : </span>
                                                                <b>{e?.descSection}</b>
                                                            </div>

                                                            <div style={{ marginBottom: 0, }}>
                                                                <span>
                                                                    รายจ่าย / เดือน : </span>
                                                                <b>{currencyFormatOne(e?.expensesMonth)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </div>
                                                        </aside>
                                                    </Row>
                                                )
                                            })}
                                        </>
                                        :
                                        <Row gutter={32} justify={'center'}>
                                            <div style={{ marginBottom: 0, }}>
                                                <span>ไม่พบอาชีพ </span>
                                            </div>
                                        </Row>
                                }
                                <Divider />
                                <Row justify={'left'}>
                                    <Col span={12} >
                                        <Divider orientation={'left'}><b><u>รายละเอียดคนค้ำ</u></b></Divider>

                                    </Col>
                                    <Col span={12} style={{ textAlign: 'right' }}>
                                        <Button type='primary' icon={<PlusOutlined />} onClick={handleAddGua}>เพิ่มคนค้ำ</Button>
                                    </Col>
                                </Row>
                                {
                                    guarantor1?.map((item, index) => {
                                        return renderGuarantorNewImg({ item, index })
                                    })
                                }
                                <Divider orientation={'left'}><b><u>หมายเหตุ</u></b></Divider>

                                <Row gutter={32} justify={'center'}>
                                    {des?.length >= 1 ?
                                        des?.map((item, index) => {
                                            if (item.note_by !== null) {
                                                return renderDataNote({ item, index });
                                            } else {
                                                return <>* ยังไม่เพิ่มหมายเหตุ!</>
                                            }
                                        })
                                        : <>* ยังไม่เพิ่มหมายเหตุ!</>
                                    }
                                </Row>
                                <Divider />
                                {
                                    reSendData?.lands?.landLoanDetails?.approvalDescription !== "" && reSendData?.lands?.landLoanDetails?.approvalDescription !== null && reSendData?.lands?.landLoanDetails?.approvalDescription !== undefined ?
                                        <>
                                            <Row justify={'left'}><Col span={24}><b><u>หมายเหตุเงื่อนไขการอนุมัติ</u></b></Col></Row>
                                            <Row gutter={32} justify={'center'}>
                                                <div style={{ marginBottom: 0, }}>
                                                    <span>เงื่อนไขการอนุมัติ : </span>
                                                    <b>{reSendData?.lands.landLoanDetails?.approvalDescription}</b>
                                                </div>
                                            </Row>
                                            <Divider />
                                        </>
                                        : null
                                }
                                <Row gutter={32} justify={'center'}>
                                    <Col span={24} style={{ textAlign: 'right' }}>
                                        <Space direction="vertical">
                                            <Button type='primary' icon={<PictureOutlined />} style={{ backgroundColor: "#3FB8FE" }} onClick={handleShowImg}>
                                                รูปตอนเสนอ
                                            </Button>
                                            {/* {
                                                checkSendNEW ?
                                                    <>
                                                        <Button type='primary' icon={<PictureOutlined />} style={{ backgroundColor: "#FEA13F" }} onClick={handleShowImgPre}>
                                                            รูปตอนเสนอ (ใหม่)
                                                        </Button>
                                                    </>
                                                    : null
                                            } */}
                                        </Space>
                                    </Col>
                                </Row>
                                <Divider />
                                <Row justify={'left'}><b><u>รูปบัตรประชาชนผู้ขอกู้</u></b></Row>
                                <Row gutter={32} justify={'center'}>
                                    <Space direction="vertical" >
                                        <div className='gutter-row' span={12}>
                                            <b>
                                                <Form.Item label='เพิ่มรูป' name='imageIDGuCar'>
                                                    <Upload
                                                        name='imageIDGuCar'
                                                        multiple={true}
                                                        listType="picture-card"
                                                        fileList={fileListCustomer}
                                                        onPreview={handlePreview}
                                                        onChange={handleCustomerImage}
                                                        beforeUpload={(file) => beforeUpts(file, 4, dataFromTable.landId).then(() => false).catch(() => true)}
                                                    >
                                                        <div>
                                                            <PlusOutlined />
                                                            <div className="ant-upload-text">upload</div>
                                                        </div>
                                                    </Upload>
                                                    <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                                        <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                    </Modal>
                                                </Form.Item>
                                            </b>
                                        </div>

                                    </Space>
                                </Row>
                                <Divider />
                                {
                                    newDataLand?.map((item, index) => {
                                        return renderLandBuildings({ item, index })
                                    })
                                }
                                {
                                    newDataLand?.map((item, index) => {
                                        return renderTitleDeed({ item, index })
                                    })
                                }
                                {
                                    newDataLand?.map((item, index) => {
                                        return renderDivision({ item, index })
                                    })
                                }
                                {
                                    newDataLand?.map((item, index) => {
                                        return renderLawang({ item, index })
                                    })
                                }
                                <Row justify={'left'}><b><u>ประวัติคนกู้ / ทะเบียนบ้าน / อาชีพ</u></b></Row>
                                <Row gutter={32} justify={'center'}>
                                    <Space direction="vertical" >
                                        <div className='gutter-row' span={12}>
                                            <b>
                                                <Form.Item label='เพิ่มรูป' name='imageIDGuCar'>
                                                    <Upload
                                                        name='imageIDGuCar'
                                                        multiple={true}
                                                        listType="picture-card"
                                                        fileList={fileListCustomerImageCareer}
                                                        onPreview={handlePreview}
                                                        onChange={handleCustomerImageCareer}
                                                        beforeUpload={(file) => beforeUpts(file, 6, dataFromTable.landId).then(() => false).catch(() => true)}
                                                    >
                                                        <div>
                                                            <PlusOutlined />
                                                            <div className="ant-upload-text">upload</div>
                                                        </div>
                                                    </Upload>
                                                    <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                                        <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                    </Modal>
                                                </Form.Item>
                                            </b>
                                        </div>

                                    </Space>
                                </Row>
                                <Divider />
                                <Row justify={'left'}><b><u>รูปสเตทเมนต์ผู้กู้</u></b></Row>
                                <Row gutter={32} justify={'center'}>
                                    <Space direction="vertical" >
                                        <div className='gutter-row' span={12}>
                                            <b>
                                                <Form.Item label='เพิ่มรูป' name='imageIDGuCar'>
                                                    <Upload
                                                        name='imageIDGuCar'
                                                        multiple={true}
                                                        listType="picture-card"
                                                        fileList={fileListStatement}
                                                        onPreview={handlePreview}
                                                        onChange={handleStatement}
                                                        beforeUpload={(file) => beforeUpts(file, 8, dataFromTable.landId).then(() => false).catch(() => true)}
                                                    >
                                                        <div>
                                                            <PlusOutlined />
                                                            <div className="ant-upload-text">upload</div>
                                                        </div>
                                                    </Upload>
                                                    <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                                        <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                    </Modal>
                                                </Form.Item>
                                            </b>
                                        </div>
                                    </Space>
                                </Row>
                                <Divider />
                                <Row justify={'left'}><b><u>รูปสเตทเมนต์ผู้กู้ PDF</u></b></Row>
                                <Row gutter={32} justify={'center'}>
                                    <Space direction="vertical" >
                                        <div className='gutter-row' span={12}>
                                            <b>
                                                <Form.Item label='เพิ่มรูป' name='imageIDGuCar'>
                                                    <Upload
                                                        name='imageIDGuCar'
                                                        multiple={true}
                                                        listType="picture-card"
                                                        fileList={fileListStatementPDF}
                                                        onPreview={handlePreview}
                                                        onChange={handleStatementPDF}
                                                        beforeUpload={(file) => beforeUpts(file, 7, dataFromTable.landId).then(() => false).catch(() => true)}
                                                    >
                                                        <div>
                                                            <PlusOutlined />
                                                            <div className="ant-upload-text">upload</div>
                                                        </div>
                                                    </Upload>
                                                    <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                                        <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                    </Modal>
                                                </Form.Item>
                                            </b>
                                        </div>

                                    </Space>
                                </Row>
                                <Divider />
                                <Row justify={'left'}><b><u>เอกสารเพิ่มเติม</u></b></Row>
                                <Row gutter={32} justify={'center'}>
                                    <Space direction="vertical" >
                                        <div className='gutter-row' span={12}>
                                            <b>
                                                <Form.Item label='เพิ่มรูป' name='imageIDGuCar'>
                                                    <Upload
                                                        name='imageIDGuCar'
                                                        multiple={true}
                                                        listType="picture-card"
                                                        fileList={fileListOtherMent}
                                                        onPreview={handlePreview}
                                                        onChange={handleOtherMent}
                                                        beforeUpload={(file) => beforeUpts(file, 12, dataFromTable.landId).then(() => false).catch(() => true)}
                                                    >
                                                        <div>
                                                            <PlusOutlined />
                                                            <div className="ant-upload-text">upload</div>
                                                        </div>
                                                    </Upload>
                                                    <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                                        <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                                    </Modal>
                                                </Form.Item>
                                            </b>
                                        </div>
                                    </Space>
                                </Row>
                            </Card>
                            {contextHolder}
                        </div>
                    </Form>
                </Row>
                <Divider />
                <Row justify={'center'}>
                    <Space>
                        <Button key="back" type='primary' danger onClick={confirmClose}>
                            ปิด
                        </Button>
                        {
                            allImg.length > 0 ?
                                <>
                                    <Button type='primary' icon={<SendOutlined />} onClick={showConfirm}>
                                        บันทึกและส่งเคส
                                    </Button>
                                </>
                                :
                                <>
                                    <Button type='primary' disabled icon={<SendOutlined />} onClick={showConfirm}>
                                        บันทึกและส่งเคส
                                    </Button>
                                </>
                        }
                        {/* <Button type='primary' disabled={sendCaseBTN} onClick={sendCase} icon={<SendOutlined />}>
                            ส่งเคส
                        </Button> */}
                    </Space>
                </Row >
            </Spin>
            {
                modalConfirm ?
                    <ModalCusConfirm open={modalConfirm} close={setModalConfirm} closeAll={close} dataFromTable={dataFromTable} oldData={oldData} />
                    : null
            }
            {
                isModalShowImg ?
                    <ModalShowImgL
                        open={isModalShowImg}
                        close={setIsModalShowImg}
                        dataFromTable={dataFromTable}
                        oldData={oldData}
                        imageBlobzz={imageBlobzz}
                        // getimages={getimages}
                        newDataLand={newDataLand}
                    />
                    : null
            }
            {
                isModalAddGua ?
                    <AddGua open={isModalAddGua} close={setIsModalAddGua} indexedit={indexEdit} getData={reSendData} pregarno={guarantor1.length} oldGua={guarantor1} sb={setSendBack} />
                    : null
            }
            {/* {
                isModalShowImgPre ?
                    <ModalShowImgPreL
                        open={isModalShowImgPre}
                        close={setIsModalShowImgPre}
                        dataFromTable={dataFromTable}
                        oldData={oldData}
                        imageBlobzz={imageBlobzz}
                        getimages={getimages}
                        newDataLand={newDataLand} />
                    : null
            } */}
            {
                isModalsEditGua ?
                    <EditGua
                        open={isModalsEditGua}
                        close={setIsModalsEditGua}
                        sb={setSendBack}
                        dataGuarantor={guarantor1}
                        shootdata={dataedit}
                        dataindex={index1}
                        setSendback={setSendBack}
                        indexedit={indexEdit}
                        preData={preData}
                    />
                    :
                    null
            }
        </Modal>
    )
}; 
