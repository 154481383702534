import { Modal, Button, Card, Form, Row, Col, Image, Space, Divider } from 'antd'
import React, { useState, useEffect, useRef } from 'react'
import { HeartFilled, PrinterOutlined } from '@ant-design/icons'
import { useReactToPrint } from "react-to-print";
import { Content, Header } from 'antd/es/layout/layout';

function ModalDrescriptionPrint({ open, close }) {
    const conponentPDF = useRef();

    const generatePDF = useReactToPrint({
        content: () => conponentPDF.current,
        documentTitle: "CarRate0",
        // onAfterPrint:()=>alert("Data saved in PDF")
    });

    const handleCancel = () => {
        close(false);
    };

    return (
        <>
            <Modal
                open={open} onCancel={handleCancel} width={795} footer={[
                    <Button onClick={generatePDF}><PrinterOutlined /> Print</Button>,
                    <Button key="back" type='primary' onClick={handleCancel}>
                        ปิด
                    </Button>,
                ]}
            >
                <div ref={conponentPDF}>
                    <Form labelCol={{ span: 12, }}>
                        <Header style={{ background: 'white', textAlign: 'center', margin: 0 }}>
                            <h3>รายละเอียดและผู้ค้ำประกัน</h3>
                        </Header>
                        <Content>
                            <Row>
                                <Col span={18}>
                                    <Form.Item
                                        style={{ marginBottom: 0, marginTop: 0, textAlign: 'right', marginRight: '30px' }}
                                    >
                                        สถานะ : <b style={{ color: 'red' }}>รอพิจารณา</b>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        style={{ marginBottom: 0, marginTop: 0, textAlign: 'right', marginRight: '30px' }}
                                    >
                                        <b><u>ลูกค้าเคยทำสัญญา</u></b>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '15px' }}>
                                <Col span={24} style={{ marginBottom: 0 }}>
                                    <Col span={24} style={{ textAlign: 'center' }}><b><u>รายละเอียดการขอกู้</u></b></Col>
                                    <Row>
                                        <Col span={9}>
                                            <Form.Item
                                                style={{ marginBottom: 0, textAlign: 'center' }}
                                                label='แบบเอกสาร'
                                            >
                                                <b>ถือเล่มมา</b>
                                            </Form.Item>
                                        </Col>
                                        <Col span={3}>
                                            <Form.Item
                                                style={{ marginBottom: 0 }}
                                            >
                                                {/* บาท */}
                                            </Form.Item>
                                        </Col>
                                        <Col span={9}>
                                            <Form.Item
                                                style={{ marginBottom: 0, textAlign: 'center' }}
                                                label='เรทรถ'
                                            >
                                                <b>40,000</b>
                                            </Form.Item>
                                        </Col>
                                        <Col span={3}>
                                            <Form.Item
                                                style={{ marginBottom: 0 }}
                                            >
                                                บาท
                                            </Form.Item>
                                        </Col>
                                        <Col span={9}>
                                            <Form.Item
                                                style={{ marginBottom: 0, textAlign: 'center' }}
                                                label='ชื่อ-นามสกุล'
                                            >
                                                <b>เอื้ออาทร วิบูลกุล</b>
                                            </Form.Item>
                                        </Col>
                                        <Col span={3}>
                                            <Form.Item
                                                style={{ marginBottom: 0 }}
                                            >
                                            </Form.Item>
                                        </Col>
                                        <Col span={9}>
                                            <Form.Item
                                                style={{ marginBottom: 0, textAlign: 'center' }}
                                                label='จำนวผ่อน'
                                            >
                                                <b>39</b>
                                            </Form.Item>
                                        </Col>
                                        <Col span={3}>
                                            <Form.Item
                                                style={{ marginBottom: 0 }}
                                            >
                                                งวด
                                            </Form.Item>
                                        </Col>
                                        <Col span={9}>
                                            <Form.Item
                                                style={{ marginBottom: 0, textAlign: 'center' }}
                                                label='ผู้ตรวจสอบ'
                                            >
                                                <b>สมภพ</b>
                                            </Form.Item>
                                        </Col>
                                        <Col span={3}>
                                            <Form.Item
                                                style={{ marginBottom: 0 }}
                                            >
                                            </Form.Item>
                                        </Col>
                                        <Col span={9}>
                                            <Form.Item
                                                style={{ marginBottom: 0, textAlign: 'center' }}
                                                label='ยอดที่ต้องการ'
                                            >
                                                <b>50,000</b>
                                            </Form.Item>
                                        </Col>
                                        <Col span={3}>
                                            <Form.Item
                                                style={{ marginBottom: 0 }}
                                            >
                                                บาท
                                            </Form.Item>
                                        </Col>
                                        <Col span={9}>
                                            <Form.Item
                                                style={{ marginBottom: 0, textAlign: 'center' }}
                                                label='พนักงานขาย'
                                            >
                                                <b>สมศรี</b>
                                            </Form.Item>
                                        </Col>
                                        <Col span={3}>
                                            <Form.Item
                                                style={{ marginBottom: 0 }}
                                            >
                                            </Form.Item>
                                        </Col>
                                        <Col span={9}>
                                            <Form.Item
                                                style={{ marginBottom: 0, textAlign: 'center' }}
                                                label='ดอกเบี้ย'
                                            >
                                                <b>12 %</b>
                                            </Form.Item>
                                        </Col>
                                        <Col span={3}>
                                            <Form.Item
                                                style={{ marginBottom: 0 }}
                                            >
                                            </Form.Item>
                                        </Col>
                                        <Col span={9}>
                                            <Form.Item
                                                style={{ marginBottom: 0, textAlign: 'center' }}
                                                label='ดอกเบี้ยรวม'
                                            >
                                                <b>20 %</b>
                                            </Form.Item>
                                        </Col>
                                        <Col span={3}>
                                            <Form.Item
                                                style={{ marginBottom: 0 }}
                                            >
                                            </Form.Item>
                                        </Col>
                                        <Col span={9}>
                                            <Form.Item
                                                style={{ marginBottom: 0, textAlign: 'center' }}
                                                label='ผ่อนรวมดอกเบี้ย'
                                            >
                                                <b>75,000</b>
                                            </Form.Item>
                                        </Col>
                                        <Col span={3}>
                                            <Form.Item
                                                style={{ marginBottom: 0 }}
                                            >
                                                บาท
                                            </Form.Item>
                                        </Col>
                                        <Col span={9}>
                                            <Form.Item
                                                style={{ marginBottom: 0, textAlign: 'center' }}
                                            >
                                            </Form.Item>
                                        </Col>
                                        <Col span={3}>
                                            <Form.Item
                                                style={{ marginBottom: 0 }}
                                            >
                                                {/* บาท */}
                                            </Form.Item>
                                        </Col>
                                        <Col span={9}>
                                            <Form.Item
                                                style={{ marginBottom: 0, textAlign: 'center' }}
                                                label='ยอดกู้สุทธิ'
                                            >
                                                <b>75,000</b>
                                            </Form.Item>
                                        </Col>
                                        <Col span={3}>
                                            <Form.Item
                                                style={{ marginBottom: 0 }}
                                            >
                                                บาท
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Divider style={{ marginTop: 0, marginBottom: 0 }} />
                            </Row>
                            <Col span={24}><b><u>รายละเอียดผู้ค้ำประกัน</u></b></Col>
                            <Form
                                labelCol={{
                                    span: 8,
                                }}
                            >
                                <Row>
                                    <Col span={12}>
                                        <Card style={{ marginBottom: 0 }}>
                                            <Form.Item
                                                style={{ marginBottom: 0 }}
                                                label='ลำดับที่'
                                            >
                                                <b>1</b>
                                            </Form.Item>
                                            <Form.Item
                                                style={{ marginBottom: 0 }}
                                                label='ชื่อ-นามสกุล'
                                            >
                                                <b>สมศรี มีสุข</b>
                                            </Form.Item>
                                            <Form.Item
                                                style={{ marginBottom: 0 }}
                                                label='ที่อยู่'
                                            >
                                                <b>1/24 ตำบลในเมือง อำเภอเมืองขอนแก่น จังหวัดขอนแก่น 40000</b>
                                            </Form.Item>

                                            <Form.Item
                                                style={{ marginBottom: 0 }}
                                                label='ความสัมพันธ์'
                                            >
                                                <b>ป้า</b>
                                            </Form.Item>
                                            <Form.Item
                                                style={{ marginBottom: 0 }}
                                                label='เบอร์โทรศัพท์'
                                            >
                                                <b>0987676756</b>
                                            </Form.Item>
                                        </Card>
                                    </Col>
                                    <Col span={12}>
                                        <Card style={{ marginBottom: 0 }}>
                                            <Form.Item
                                                style={{ marginBottom: 0 }}
                                                label='ลำดับที่'
                                            >
                                                <b>2</b>
                                            </Form.Item>
                                            <Form.Item
                                                style={{ marginBottom: 0 }}
                                                label='ชื่อ-นามสกุล'
                                            >
                                                <b>สมศรี มีทุขก์</b>
                                            </Form.Item>
                                            <Form.Item
                                                style={{ marginBottom: 0 }}
                                                label='ที่อยู่'
                                            >
                                                <b>1/24 ตำบลในเมือง อำเภอเมือง จังหวัดอุดรธานี 41230</b>
                                            </Form.Item>

                                            <Form.Item
                                                style={{ marginBottom: 0 }}
                                                label='ความสัมพันธ์'
                                            >
                                                <b>น้า</b>
                                            </Form.Item>
                                            <Form.Item
                                                style={{ marginBottom: 0 }}
                                                label='เบอร์โทรศัพท์'
                                            >
                                                <b>0978575757</b>
                                            </Form.Item>
                                        </Card>
                                    </Col>
                                </Row>
                            </Form>
                            <Divider style={{ marginTop: 0 }} />
                            <Col><b><u>รายละเอียดการขอกู้</u></b></Col>
                            <Row
                                justify={'center'}
                            >
                                <Col span={7}>
                                    <Form.Item
                                        style={{ marginBottom: 0, textAlign: 'center' }}
                                        label='ยอดปิดไฟแนนซ์'
                                    >
                                        <b>50,000</b>
                                    </Form.Item>
                                </Col>
                                <Col span={1}>
                                    <Form.Item
                                        style={{ marginBottom: 0 }}
                                    >
                                        บาท
                                    </Form.Item>
                                </Col>
                                <Col span={7}>
                                    <Form.Item
                                        style={{ marginBottom: 0, textAlign: 'center' }}
                                        label='จำนวนงวดที่ผ่อน'
                                    >
                                        <b>48</b>
                                    </Form.Item>
                                </Col>
                                <Col span={1}>
                                    <Form.Item
                                        style={{ marginBottom: 0 }}
                                    >
                                        งวด
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        style={{ marginBottom: 0, textAlign: 'center' }}
                                        label='ค้างกี่งวด'
                                    >
                                        <b>10</b>
                                    </Form.Item>
                                </Col>
                                <Col span={2}>
                                    <Form.Item
                                        style={{ marginBottom: 0 }}
                                    >
                                        งวด
                                    </Form.Item>
                                </Col>
                                <Col span={7}>
                                    <Form.Item
                                        style={{ marginBottom: 0, textAlign: 'center' }}
                                        label='ค่างวด / เดือน'
                                    >
                                        <b>2,500</b>
                                    </Form.Item>
                                </Col>
                                <Col span={1}>
                                    <Form.Item
                                        style={{ marginBottom: 0 }}
                                    >
                                        บาท
                                    </Form.Item>
                                </Col>
                                <Col span={7}>
                                    <Form.Item
                                        style={{ marginBottom: 0, textAlign: 'center' }}
                                        label='ยอดที่ค้างทั้งหมด'
                                    >
                                        <b>25,000</b>
                                    </Form.Item>
                                </Col>
                                <Col span={1}>
                                    <Form.Item
                                        style={{ marginBottom: 0 }}
                                    >
                                        บาท
                                    </Form.Item>
                                </Col>
                                <Col span={7}>
                                    <Form.Item
                                        style={{ marginBottom: 0, textAlign: 'center' }}
                                    >
                                    </Form.Item>
                                </Col>
                                <Col span={1}>
                                    <Form.Item
                                        style={{ marginBottom: 0 }}
                                    >
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Divider style={{ marginTop: 0 }} />
                            <Col><b><u>รายละเอียดรถ</u></b></Col>
                            <Row>
                                <Col span={8}>
                                    <Form.Item
                                        style={{ marginBottom: 0, textAlign: 'center' }}
                                        label='ยี่ห้อ'
                                    >
                                        <b>TOYOTA</b>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        style={{ marginBottom: 0, textAlign: 'center' }}
                                        label='รุ่นสินค้า'
                                    >
                                        <b>VIOS</b>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        style={{ marginBottom: 0, textAlign: 'center' }}
                                        label='สี'
                                    >
                                        <b>แดง</b>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        style={{ marginBottom: 0, textAlign: 'center' }}
                                        label='เลขตัวถัง'
                                    >
                                        <b>EDS45456R-FG</b>
                                    </Form.Item>
                                </Col>

                                <Col span={8}>
                                    <Form.Item
                                        style={{ marginBottom: 0, textAlign: 'center' }}
                                        label='เลขเครื่อง'
                                    >
                                        <b>EDS45456R-FG</b>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        style={{ marginBottom: 0, textAlign: 'center' }}
                                        label='เลขทะเบียน'
                                    >
                                        <b>กต-4532</b>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        style={{ marginBottom: 0, textAlign: 'center' }}
                                    >
                                    </Form.Item>
                                </Col>

                                <Col span={8}>
                                    <Form.Item
                                        style={{ marginBottom: 0, textAlign: 'center' }}
                                        label='ปีรถ'
                                    >
                                        <b>2020</b>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        style={{ marginBottom: 0, textAlign: 'center' }}
                                        label='จังหวัด'
                                    >
                                        <b>ขอนแก่น</b>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Card>
                                <b><u>รูปรถ</u></b>
                                <Row justify={'center'}>
                                    <Space>
                                        <Space.Compact direction="vertical">
                                            <Image
                                                style={{ marginLeft: 2, marginTop: 5 }}
                                                width={300}
                                                src="https://images.unsplash.com/photo-1552519507-ac11af17dcc8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTh8fGJsdWUlMjBjYXJ8ZW58MHx8MHx8fDA%3D&w=1000&q=80"
                                            />
                                            <Image
                                                style={{ marginLeft: 2, marginTop: 5 }}
                                                width={300}
                                                src="https://images.unsplash.com/photo-1552519507-da3b142c6e3d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fGNhcnN8ZW58MHx8MHx8fDA%3D&w=1000&q=80"
                                            />
                                            <Image
                                                style={{ marginLeft: 2, marginTop: 5 }}
                                                width={300}
                                                src="https://images.unsplash.com/photo-1552519507-ac11af17dcc8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTh8fGJsdWUlMjBjYXJ8ZW58MHx8MHx8fDA%3D&w=1000&q=80"
                                            />
                                            <Image
                                                style={{ marginLeft: 2, marginTop: 5 }}
                                                width={300}
                                                src="https://images.unsplash.com/photo-1552519507-ac11af17dcc8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTh8fGJsdWUlMjBjYXJ8ZW58MHx8MHx8fDA%3D&w=1000&q=80"
                                            />
                                        </Space.Compact>
                                        <Space.Compact direction="vertical">
                                            <Image
                                                style={{ marginLeft: 2, marginTop: 5 }}
                                                width={300}
                                                src="https://images.unsplash.com/photo-1552519507-ac11af17dcc8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTh8fGJsdWUlMjBjYXJ8ZW58MHx8MHx8fDA%3D&w=1000&q=80"
                                            />
                                            <Image
                                                style={{ marginLeft: 2, marginTop: 5 }}
                                                width={300}
                                                src="https://images.unsplash.com/photo-1552519507-da3b142c6e3d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fGNhcnN8ZW58MHx8MHx8fDA%3D&w=1000&q=80"
                                            />
                                            <Image
                                                style={{ marginLeft: 2, marginTop: 5 }}
                                                width={300}
                                                src="https://images.unsplash.com/photo-1552519507-ac11af17dcc8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTh8fGJsdWUlMjBjYXJ8ZW58MHx8MHx8fDA%3D&w=1000&q=80"
                                            />
                                            <Image
                                                style={{ marginLeft: 2, marginTop: 5 }}
                                                width={300}
                                                src="https://images.unsplash.com/photo-1552519507-ac11af17dcc8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTh8fGJsdWUlMjBjYXJ8ZW58MHx8MHx8fDA%3D&w=1000&q=80"
                                            />
                                        </Space.Compact>
                                    </Space>
                                </Row>
                            </Card>
                        </Content>
                    </Form>
                </div>
            </Modal>
        </>
    )
};

export default ModalDrescriptionPrint
