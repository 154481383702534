import React, { useState, useEffect } from 'react'
import { Form, Modal, Row, Col, Space, Card, Button, Tag, Divider, Checkbox, notification } from 'antd'
import dayjs from 'dayjs';
import axios from "axios";
import TextArea from 'antd/es/input/TextArea';
import NotePN from "./NotePN";
import ModalMoreNote from './ModalMoreNote';
import ModalAddLand from './ModalAddLand';
import ModalShowImages from '../../ModalShowImageSlide/ModalShowImages'
import { checklandpntest, findlandpn, getImagess } from '../../file_mid/all_api';

export default function ModalInfoLand({ open, dataFromTable, close }) {

  const token = localStorage.getItem('token');
  // const [imageBlobzz, setImageBlobzz] = useState([]);
  const [dataLand, setDataLand] = useState(dataFromTable);
  const [newdataLand, setNewDataLand] = useState([]);
  const [form] = Form.useForm()

  const [modalnote, setModalNote] = useState(false)
  const [appStatus, setAppStatus] = useState({})
  const [foundInfo, setFoundInfo] = useState(false)
  const [showOldData2, setShowOldData2] = useState([])
  const [isModalShowNote, setisModalShowNote] = useState(false)
  const [isModalAddLand, setIsModalAddLand] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [dataEdit, setDataEdit] = useState()
  const [dataEditIndex, setDataEditIndex] = useState()
  const [checkEdit, setCheckEdit] = useState(false)
  const [test, settest] = useState()
  const [result, setResult] = useState(null);
  const [mainLand, setMainLand] = useState();
  // const [testmain, setTestmain] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [openShowImage, setOpenShowImage] = useState(false);
  // const [currentIndex, setCurrentIndex] = useState(0);
  // แยก type image car
  const [, setCarImage1] = useState([]);
  const [, setCarImage3] = useState([]);
  const [, setCarImage10] = useState([]);
  const [, setCarImage11] = useState([]);

  const [carshowMadol, setCarShowModal] = useState([]); //send to Modal Show Image

  const { confirm } = Modal

  // console.log("carshowMadol", carshowMadol)
  // console.log("newdataLand", newdataLand)
  // console.log("carimage1", carimage1)
  // console.log("carimage3", carimage3)
  // console.log("carimage10", carimage10)
  // console.log("carimage11", carimage11)
  console.log("result", result)

  useEffect(() => {
    loadDataDup()
    loadMixData()
    // loadImage()
    // form.setFieldsValue(
    //   {
    //     storyLand: dataFromTable.storyLand ? dataFromTable.storyLand : '',
    //   }
    // )
  }, [])

  useEffect(() => {
    makeOldMinMax()
    loadImage1()
  }, [showOldData2])



  // console.log("newdataLand", newdataLand)

  useEffect(() => {
    newdataLand.map((e, index) => {
      form.setFieldsValue({
        [`storyLand${index}`]: e?.storyLand,
      })
      return null
    })
  }, [newdataLand])

  const errorCheckMain = (placement) => {
    api.warning({
      message: "กรุณาเลือกที่ดินหลัก",
      // description: 
      // 'กรุณาเลือกที่ดินอื่น',
      placement,
    });
  };

  const makeOldMinMax = async () => {
    for (let i = showOldData2?.length - 1; i >= 0; i--) {
      if (showOldData2[i]?.approval?.id === 0) {
        // หากเจอค่า 0 ให้ทำต่อ
        continue;
      } else {
        // เจอค่าที่ไม่ใช่ 0 ให้เก็บค่านั้นและหยุด
        setResult(showOldData2[i]);
        break;
      }
    }
  }

  const onApprove = async () => {
    if (result !== null) {
      setNewDataLand([
        {
          ...newdataLand[0],
          approval: {
            ...newdataLand[0].approval,
            minPrice: parseInt(result.approval.minPrice),
            maxPrice: parseInt(result.approval.maxPrice),
            note: result.approval.note
          }
        },
        ...newdataLand.slice(1) // คงค่า index อื่นๆ ไว้เหมือนเดิม
      ])
    }

    if (newdataLand.length > 1) {
      var checkMain = true
      newdataLand.map((item, i) => {

        if (item.landMain === false || item.landMain === undefined) {
          checkMain = false
        }
        if (item.landMain === true || item.landMain !== undefined) {
          checkMain = true
        }
      })
      if (checkMain) {
        setAppStatus("อนุมัติ")
        setModalNote(true)
      } else {
        errorCheckMain("top")
      }
    } else {
      setAppStatus("อนุมัติ")
      setModalNote(true)
    }
  }
  const onUnApprove = async () => {
    setAppStatus("ปัดตก")
    setModalNote(true)
  }

  const onRequest = async () => {
    setAppStatus("ขอเอกสารเพิ่ม")
    setModalNote(true)
  }

  const handleviewImg = (index) => {
    localStorage.setItem('viewPN', JSON.stringify(showOldData2[index]))
    window.open('/view-pn', '_blank');
  }

  const loadMixData = async () => {
    await axios.post(findlandpn, { landLoanId: dataFromTable.landLoanId })
      .then(res => {
        if (res.status === 200) {
          setNewDataLand(res.data)
          console.log("res.data", res.data)
          // loadImage1(res.data)
        }
      }).catch((err) => {
        console.log("err", err)
      })
  }


  // const loadImage = async () => {
  //   await axios.post(loadimage, { landId: dataFromTable.landId, loanType: "land" })
  //     .then(res => {
  //       if (res.status === 200) {
  //         setImageBlobzz(res.data)
  //       } else if (res.status === 204) {
  //         // NotiErrorIMG("top")
  //       }
  //     }).catch(err => {
  //       console.log(err)
  //     })
  // }

  const loadImage1 = async () => {
    // console.log("value", value);

    newdataLand?.map((data, i) => {
      console.log("data", data, i);
      // สร้างตัวแปรเพื่อเก็บรูปภาพแต่ละประเภท
      var carimg1 = [];
      var carimg3 = [];
      var carimg10 = [];
      var carimg11 = [];

      data?.img?.map((imgs, j) => {
        console.log("imgs", imgs, j);

        if (imgs?.type === 1) {
          carimg1.push(imgs);
        } else if (imgs?.type === 3) {
          carimg3.push(imgs);
        } else if (imgs?.type === 10) {
          carimg10.push(imgs);
        } else if (imgs?.type === 11) {
          carimg11.push(imgs);
        }
      });

      // เซ็ตค่ารูปภาพให้กับตัวแปร state ที่เก็บรูปภาพแต่ละประเภท
      setCarImage1(carimg1);
      setCarImage3(carimg3);
      setCarImage10(carimg10);
      setCarImage11(carimg11);
    });
  };
  ////////////////////////////////////////////////////////////////
  const openModalImages = (data, index) => {
    console.log("data", data, index);
    let imageToShow = [];
    var carimg1 = [...data];
    var carimg3 = [...data];
    var carimg10 = [...data];
    var carimg11 = [...data];

    if (data[index].type === 3) {
      imageToShow = carimg3; // เลือกรูปภาพจาก carimage1 โดยใช้ index ที่ได้จากการคลิก
      // carimg1.push(data)
      setCarImage1(carimg3);
    } else if (data[index].type === 10) {
      imageToShow = carimg10;  // เลือกรูปภาพจาก carimage3 โดยใช้ index ที่ได้จากการคลิก
      // carimg3.push(data)
      setCarImage3(carimg10);

    } else if (data[index].type === 11) {
      imageToShow = carimg11;  // เลือกรูปภาพจาก carimage10 โดยใช้ index ที่ได้จากการคลิก
      // carimg10.push(data)
      setCarImage10(carimg11);

    } else if (data[index].type === 1) {
      imageToShow = carimg1;  // เลือกรูปภาพจาก carimage11 โดยใช้ index ที่ได้จากการคลิก
      // carimg11.push(data)
      setCarImage11(carimg1);

    }
    console.log("imageToShow", imageToShow)
    setCarShowModal(imageToShow);
    setOpenShowImage(true);
  };




  const loadDataDup = async () => {
    await axios.post(checklandpntest, { numberLand: dataFromTable.numberLand, provinces: dataFromTable.province, district: dataFromTable.district })
      .then(res => {
        if (res.status === 200) {
          var cutData = res.data
          if (res.data.length > 1) {
            setFoundInfo(true)
            cutData.forEach((e, index) => {
              if (e.landLoanDetails.landLoanId === dataFromTable.landLoanId) {
                cutData.splice(index, 1);
              }
            });
            setShowOldData2(cutData)
          }
        }
      }).catch((err) => {
        console.log("err", err)
      })
  }


  const handleMoreNote = (value) => {
    settest(value)
    setisModalShowNote(true)
  };
  const CloseMoreNote = () => {
    setisModalShowNote(false)
  };

  const handleOk = () => {
    close(false);
  };

  const handleCancel = () => {
    close(false);
  };
  const handleCancel2 = (value) => {
    setModalNote(value)
  };
  const handleCloseAllModal = (value) => {
    setModalNote(value)
    close(value)
  }

  const currencyFormatOne = (amount) => {
    return Number(amount)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
  }

  const handleEditLand = async (value, index) => {
    setCheckEdit(true)
    setDataEdit(value)
    setDataEditIndex(index)
    setIsModalAddLand(true)
  }

  const funcEdit = (value, index) => {
    let swapData = [...newdataLand]
    swapData[index] = value
    setNewDataLand(swapData);
  }

  const funcAdd = (value) => {
    setNewDataLand(pre => [...pre, { ...value }]);
  }

  const handleAddLand = async () => {
    setIsModalAddLand(true)
  }

  const handleEditMode = async () => {
    if (editMode) {
      setEditMode(false)
    } else {
      setEditMode(true)
    }
  }

  const handleDeleteLand = (value, index) => {
    confirm({
      title: 'ต้องการที่จะยกเลิกจดรวมข้อมูลหรือไม่...?',
      content: 'กด OK เพื่อยืนยันการยกเลิกจดรวม',
      onOk() {
        const deletearray = [...newdataLand]
        deletearray.splice(index, 1)
        setNewDataLand(deletearray)
      },
      onCancel() {
      },
    });
  };

  const handleSelectMainLand = (value, index) => {
    // console.log("value", value)
    setMainLand(index)
    const updatedLandMain = newdataLand.map((item, index2) => {
      if (index2 === index) {
        return { ...item, landMain: true };
      } else {
        return { ...item, landMain: false };
      }
    });
    setNewDataLand(updatedLandMain);
  };

  let color

  if (dataLand?.approvalStatus === "รอธุรการรับ") {
    color = "gold"
  }
  if (dataLand?.approvalStatus === "รออนุมัติราคาที่ดิน") {
    color = "orange"
  }
  if (dataLand?.approvalStatus === "รอเคาะราคา") {
    color = "gold"
  }
  if (dataLand?.approvalStatus === "เคาะราคาแล้ว") {
    color = "orange"
  }
  if (dataLand?.approvalStatus === "รอวิเคราะห์") {
    color = "cyan"
  }
  if (dataLand?.approvalStatus === "ผ่านการวิเคราะห์") {
    color = "geekblue"
  }
  if (dataLand?.approvalStatus === "รอพิจารณา") {
    color = "cyan"
  }
  if (dataLand?.approvalStatus === "ผ่านพิจารณา") {
    color = "geekblue"
  }
  if (dataLand?.approvalStatus === "ขอเอกสารเพิ่ม") {
    color = "geekblue"
  }
  if (dataLand?.approvalStatus === "รออนุมัติ") {
    color = "lime"
  }
  if (dataLand?.approvalStatus === "อนุมัติ") {
    color = "green"
  }
  if (dataLand?.approvalStatus === "อนุมัติแบบมีเงื่อนไข") {
    color = "green"
  }
  if (dataLand?.approvalStatus === "รอทำสัญญา") {
    color = "green"
  }
  if (dataLand?.approvalStatus === "โทรติด") {
    color = "magenta"
  }
  if (dataLand?.approvalStatus === "โทรไม่ติด(ติดต่อไม่ได้)") {
    color = "volcano"
  }
  if (dataLand?.approvalStatus === "ไม่อนุมัติ") {
    color = "red"
  }
  if (dataLand?.approvalStatus === "ไม่ผ่านการวิเคราะห์") {
    color = "red"
  }
  if (dataLand?.approvalStatus === "ลูกค้าปฏิเสธ") {
    color = "red"
  }
  if (dataLand?.approvalStatus === "ปัดตก") {
    color = "red"
  }

  const renderOldData = ({ item, index }) => {
    var note = item.notes?.length;
    var lastIndex = note - 1;

    let color

    if (item?.landLoanDetails?.approvalStatus === "รอธุรการรับ") {
      color = "gold"
    }
    if (item?.landLoanDetails?.approvalStatus === "รอการวิเคราะห์") {
      color = "gold"
    }
    if (item?.landLoanDetails?.approvalStatus === "ลูกค้าคอนเฟิร์ม") {
      color = "gold"
    }
    if (item?.landLoanDetails?.approvalStatus === "รอวิเคราะห์") {
      color = "cyan"
    }
    if (item?.landLoanDetails?.approvalStatus === "รอเคาะราคา") {
      color = "gold"
    }
    if (item?.landLoanDetails?.approvalStatus === "ผ่านการวิเคราะห์") {
      color = "geekblue"
    }
    if (item?.landLoanDetails?.approvalStatus === "เคาะราคาแล้ว") {
      color = "green"
    }
    if (item?.landLoanDetails?.approvalStatus === "ไม่ผ่านการวิเคราะห์") {
      color = "red"
    }
    if (item?.landLoanDetails?.approvalStatus === "ปัดตก") {
      color = "red"
    }
    if (item?.landLoanDetails?.approvalStatus === "ผ่านพิจารณา") {
      color = "geekblue"
    }
    if (item?.landLoanDetails?.approvalStatus === "รออนุมัติ") {
      color = "lime"
    }
    if (item?.landLoanDetails?.approvalStatus === "อนุมัติ") {
      color = "green"
    }
    if (item?.landLoanDetails?.approvalStatus === "อนุมัติแบบมีเงื่อนไข") {
      color = "green"
    }
    if (item?.landLoanDetails?.approvalStatus === "โทรติด") {
      color = "magenta"
    }
    if (item?.landLoanDetails?.approvalStatus === "โทรไม่ติด(ติดต่อไม่ได้)") {
      color = "volcano"
    }
    if (item?.landLoanDetails?.approvalStatus === "ไม่อนุมัติ") {
      color = "red"
    }
    if (item?.landLoanDetails?.approvalStatus === "ลูกค้าปฏิเสธ") {
      color = "red"
    }
    if (item?.landLoanDetails?.approvalStatus === "รอทำสัญญา") {
      color = "green"
    }
    if (item?.landLoanDetails?.approvalStatus === "รออนุมัติราคารถ") {
      color = "orange"
    }
    if (item?.landLoanDetails?.approvalStatus === "รอพิจารณา") {
      color = "cyan"
    }
    if (item?.landLoanDetails?.approvalStatus === "ขอเอกสารเพิ่ม") {
      color = "gold"
    }
    return (
      <>
        <Row gutter={32} justify={'center'}>
          <Col className='gutter-row' span={24}>
            <>
              <Card key={index} style={{ width: '100%', textAlign: 'left' }}>
                <div style={{ marginBottom: 0, textAlign: 'right' }}>
                  <Tag color={color}>{item.landLoanDetails.approvalStatus}</Tag>
                </div>
                {
                  item.landLoanDetails.landLoanId === dataFromTable.landLoanId ?
                    <>
                      <div style={{ marginBottom: 0, textAlign: 'right' }}>
                        <Tag color="green">เคสปัจจุบัน</Tag>
                      </div>
                    </>
                    :
                    <>
                      <div style={{ marginBottom: 0, textAlign: 'right' }}>
                        <Tag color="red">เคสที่เคยเสนอมาแล้ว</Tag>
                      </div>
                    </>
                }
                <Divider orientation="left">วันที่การเสนอ</Divider>
                <div style={{ marginBottom: 0 }}>
                  <span>เสนอเมื่อ : </span>
                  <b>{dayjs(item.landInput).format("DD/MM/YYYY HH:mm")}</b>
                </div>
                <div style={{ marginBottom: 0 }}>
                  <span>ผู้เสนอเคส : </span>
                  <b>{item.landLoanDetails.proposalBy}</b>
                </div>
                <Divider orientation="left">รายละเอียดที่ดิน</Divider>
                <div style={{ marginBottom: 0 }}>
                  <span>ประเภทที่ดิน : </span>
                  <b>{item.productTypeLand === 1 ? "นส.3 ก." : "นส.4 จ."}</b>
                </div>
                <div style={{ marginBottom: 0 }}>
                  <span>เลขโฉนดที่ดิน : </span>{<b>{item?.numberLand}</b>}
                </div>
                <div style={{ marginBottom: 0 }}>
                  <span>เลขที่ดินหรือระวาง : </span>{<b>{item?.numberLandlawang}</b>}
                </div>
                <div style={{ marginBottom: 0 }}>
                  <span>จังหวัด : </span>{<b>{item?.provinces}</b>}
                </div>
                <div style={{ marginBottom: 0 }}>
                  <span>อำเภอ : </span>{<b>{item?.district}</b>}
                </div>
                <div style={{ marginBottom: 0 }}>
                  <span>พื้นที่ไร่ : </span>{<b>{item?.rai}</b>}
                  <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                    ไร่
                  </span>
                </div>
                <div style={{ marginBottom: 0 }}>
                  <span>พื้นที่งาน : </span>{<b>{item?.workArea}</b>}
                  <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                    งาน
                  </span>
                </div>
                <div style={{ marginBottom: 0 }}>
                  <span>พื้นที่ตารางวา : </span>{<b>{currencyFormatOne(item?.squareWaArea)}</b>}
                  <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                    บาท
                  </span>
                </div>
                <div style={{ marginBottom: 0 }}>
                  <span>ราคาประเมินที่ดิน : </span> {<b>{item?.landPrice}</b>}
                  <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                    บาท / ตารางวา
                  </span>
                </div>
                <div style={{ marginBottom: 0 }}>
                  <span>ราคารวม : </span> {<b>{item?.resultLandPrice}</b>}
                  <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                    บาท
                  </span>
                </div>
                <span>
                  <Button type='primary' onClick={() => handleviewImg(index)} >รูปเอกสาร</Button>
                  {/* <Button type='primary' onClick={handleviewImg} >รูปเอกสาร</Button> */}
                </span>
                <Divider orientation="left">หมายเหตุ</Divider>
                {
                  item?.landLoanDetails.approvalStatus === "ขอเอกสารเพิ่ม" || item?.landLoanDetails.approvalStatus === "ปัดตก" ?
                    <>
                      <div style={{ marginBottom: 0 }}>
                        <span>สาเหตุ : </span>
                        <b>{item?.notes[lastIndex]?.cause}</b>
                      </div>
                      <div style={{ marginBottom: 0 }}>
                        <span>สถานะที่ทำการหมายเหตุ : </span>
                        <b>{item?.notes[lastIndex]?.status}</b>
                      </div>
                      <div style={{ marginBottom: 0 }}>
                        <span>ผู้สร้างหมายเหตุ : </span>
                        <b>{item?.notes[lastIndex]?.noteBy}</b>
                      </div>
                      <div style={{ marginBottom: 0 }}>
                        <span>เมื่อวันที่ : </span>
                        <b>{dayjs(item?.notes[lastIndex]?.noteDate).format("DD-MM-YYYY")}</b>
                      </div>
                      {
                        note > 1 ?
                          <>
                            <Button onClick={() => handleMoreNote(item.notes, index)}>เพิ่มเติม</Button>
                            {/* <Button onClick={((index) => handleMoreNote(index))}>เพิ่มเติม</Button> */}

                          </>
                          : null
                      }
                    </>
                    :
                    <>
                      {
                        item?.approval.id !== 0 ?
                          <>
                            <div style={{ marginBottom: 0 }}>
                              <span>หมายเหตุ : </span>{<b>{item?.approval.note}</b>}
                            </div>
                            <div style={{ marginBottom: 0 }}>
                              <span>ราคาต่ำสุด-สูงสุด : </span>{<b>{item?.approval.minPrice} - {item?.approval.maxPrice}</b>}
                            </div>
                            <div style={{ marginBottom: 0 }}>
                              <span>เคาะราคาโดย : </span>{<b>{item?.approval.proposalPriceBy}</b>}
                            </div>
                            <div style={{ marginBottom: 0 }}>
                              <span>เมื่อวันที่ : </span>{<b>{dayjs(item?.approval.inputPriceDate).format('DD-MM-YYYY')}</b>}
                            </div>
                          </>
                          :
                          <>
                            <div style={{ marginBottom: 0 }}>
                              <span style={{ color: "red" }}>*ยังไม่เคาะราคา!</span>
                            </div>
                          </>
                      }
                    </>
                }
              </Card>

            </>
          </Col>
        </Row>
        {/* <Modal title="Basic Modal" open={isModalShowNote} onCancel={CloseMoreNote} >
          <Row justify={'left'}>
            <b><u>หมายเหตุ</u></b>
          </Row>
          <Row gutter={32} justify={'center'}>
            {
              item?.notes.map((item, index) => {
                return renderNoteAD({ item, index })
              })
            }
          </Row>
        </Modal> */}
      </>
    )

  }

  const renderLandData = ({ item, index }) => {

    return (
      <>
        <Row gutter={32} justify={'center'}>
          <Col className='gutter-row' span={24}>
            <>
              <Card key={index} style={{ width: '100%', textAlign: 'left' }}>
                <Col style={{ marginBottom: 0, textAlign: 'right' }}>
                  <Tag color={color}>{item.landLoanDetails.approvalStatus}</Tag>
                </Col>
                <Divider orientation={'left'}><b><u>รายละเอียดที่ดิน ({item?.numberLand})</u></b></Divider>
                <Row gutter={32} justify={'center'}>
                  <aside style={{ width: '90%' }}>
                    <div style={{ marginBottom: 0 }}>
                      <span>กลุ่มสินค้า : </span>{<b>{item?.landLoanDetails.productType}</b>}
                    </div>
                    <div style={{ marginBottom: 0 }}>
                      <span>ประเภทเอกสาร : </span>{<b>{item?.productTypeLand === 1 ? "นส.3" : "นส.4"}</b>}
                    </div>
                    <div style={{ marginBottom: 0 }}>
                      <span>เลขโฉนดที่ดิน : </span>{<b>{item?.numberLand}</b>}
                    </div>
                    <div style={{ marginBottom: 0 }}>
                      <span>เลขที่ดินหรือระวาง : </span>{<b>{item?.numberLandlawang}</b>}
                    </div>
                    <div style={{ marginBottom: 0 }}>
                      <span>จังหวัด : </span>{<b>{item?.provinces}</b>}
                    </div>
                    <div style={{ marginBottom: 0 }}>
                      <span>อำเภอ : </span>{<b>{item?.district}</b>}
                    </div>
                    <div style={{ marginBottom: 0 }}>
                      <span>พื้นที่ไร่ : </span>{<b>{item?.rai}</b>}
                      <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                        ไร่
                      </span>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                      <span>พื้นที่งาน : </span>{<b>{item?.workArea}</b>}
                      <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                        งาน
                      </span>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                      <span>พื้นที่ตารางวา : </span>{<b>{currencyFormatOne(item?.squareWaArea)}</b>}
                      <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                        บาท
                      </span>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                      <span>ราคาประเมินที่ดิน : </span> {<b>{item?.landPrice}</b>}
                      <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                        บาท / ตารางวา
                      </span>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                      <span>ราคารวม : </span> {<b>{item?.resultLandPrice}</b>}
                      <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                        บาท
                      </span>
                    </div>

                  </aside>
                  {
                    editMode ?
                      <>
                        {
                          index === 0 ?
                            null :
                            <>
                              <Space direction='vertical'>
                                <Button style={{ width: "100px", backgroundColor: "#DCD9D4" }} onClick={() => handleEditLand(newdataLand[index], index)}>เปลี่ยนที่ดิน</Button>
                                <Button style={{ width: "100px" }} type='primary' danger onClick={() => handleDeleteLand(newdataLand[index], index)}>ยกเลิกจดรวม</Button>
                              </Space>
                            </>
                        }
                      </> : null
                  }
                </Row>
                <Divider orientation={'left'}><b><u>สตอรี่รายละเอียดที่ดิน อธิบายข้อมูลของที่ดินแปลงนั้น / ที่นา ที่บ้าน บ้านกี่หลัง ({item?.numberLand})</u></b></Divider>
                <Form.Item name={`storyLand${index}`} label="">
                  <TextArea style={{ color: 'blue' }} rows={5} disabled />
                </Form.Item>
              </Card>
            </>
          </Col>
        </Row>
      </>
    )

  }

  const renderSelectMainLand = ({ item, index }) => { // เลือกที่ดินหลัก
    return (
      <>
        <Checkbox checked={mainLand === index} onChange={() => handleSelectMainLand(item, index)}><b>ที่ดิน {item.numberLand}</b></Checkbox>
      </>
    )
  }

  const renderCoordinates = ({ item, index }) => { // พิกัด
    return (
      <>
        <div>
          <span>รายละเอียดพิกัด ({item?.numberLand}) : </span>
          <a href={`https://www.google.com/maps?q=${item.parcellat},${item.parcellon}`} target="_blank">
            {`${item.parcellat},${item.parcellon}`}
          </a>
        </div>
      </>
    )
  }

  const renderTitleDeed = ({ item, imgs, index }) => {
    // console.log("item766", item, imgs, index);
    var items = [item]

    return (
      <>
        <Row justify={'left'}><b><u>รูปโฉนด/หน้า-หลัง ({item.numberLand})</u></b></Row>
        {items.map((itemData, itemIndex) => (
          <>
            <React.Fragment key={itemIndex}>
              <Row justify={'center'}>
                <Col span={24} style={{ textAlign: 'center' }}>
                  <Space>
                    {itemData?.img?.map((e, imgIndex) => {
                      if (e.type === 3) {
                        return (
                          <div key={imgIndex}>
                            <img
                              onClick={(v) => openModalImages(itemData?.img, imgIndex)}
                              style={{ width: 200 }}
                              src={`${getImagess}/${e.pathImage}?token=${token}`}
                              alt={`รูปภาพ ${imgIndex}`}
                            />
                          </div>
                        );
                      }
                      return null;
                    })}
                  </Space>
                </Col>
              </Row>
              <Divider />
            </React.Fragment>
          </>
        ))}
      </>
    );
  };

  // const renderTitleDeed = ({ item, imgs, index }) => { // render รูปโฉนด/หน้า-หลัง
  //   console.log("item766", item, imgs, index)
  //   return (
  //     <>
  //       <Row justify={'left'}><b><u>รูปโฉนด/หน้า-หลัง ({item.numberLand})</u></b></Row>
  //       <Row justify={'center'} >
  //         <Col span={24} style={{ textAlign: 'center' }}>
  //           <Space>
  //             {carimage3?.map((e, index1) => {
  //               if (e.type === 3 && index1 === index) {
  //                 return (
  //                   <div key={index1}>
  //                     <img
  //                       onClick={(v) => openModalImages(e, index)}
  //                       style={{ width: 200 }}
  //                       src={`${getImagess}/${e.pathImage}?token=${token}`}
  //                       alt={`รูปภาพ ${index}`}
  //                     />
  //                   </div>
  //                 )
  //               }
  //             })}
  //           </Space>
  //         </Col>
  //         {/* {item?.img?.map((e, index) => {
  //             if (e.type === 3) {
  //               return (
  //                 <>
  //                   <Image width={"10%"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
  //                 </>

  //               );
  //             }
  //             return null;
  //           })} */}
  //       </Row>
  //       <Divider />
  //     </>
  //   )
  // }
  const renderDivision = ({ item, imgs, index }) => { // render ใบแบ่งใบต่อ
    var items = [item]
    return (
      <>
        <Row justify={'left'}><b><u>ใบแบ่งใบต่อ ({item.numberLand})</u></b></Row>
        {items.map((itemData, itemIndex) => (
          <>
            <React.Fragment key={itemIndex}>
              <Row justify={'center'}>
                <Col span={24} style={{ textAlign: 'center' }}>
                  <Space>
                    {itemData?.img?.map((e, imgIndex) => {
                      if (e.type === 10) {
                        return (
                          <div key={imgIndex}>
                            <img
                              onClick={(v) => openModalImages(itemData?.img, imgIndex)}
                              style={{ width: 200 }}
                              src={`${getImagess}/${e.pathImage}?token=${token}`}
                              alt={`รูปภาพ ${imgIndex}`}
                            />
                          </div>
                        );
                      }
                      return null;
                    })}
                  </Space>
                </Col>
              </Row>
              <Divider />
            </React.Fragment>
          </>
        ))}
        {/* {item?.img?.map((e, index) => {
              if (e.type === 10) {
                return (
                  <>
                    <Image width={"10%"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                  </>
                );
              }
              return null;
            })} */}
        {/* </Row> */}
        {/* <Divider /> */}
      </>
    )
  }
  const renderLawang = ({ item, imgs, index }) => { // render ระวาง
    // console.log("imgs821", imgs)
    var items = [item]
    return (
      <>
        <Row justify={'left'}><b><u>ระวาง ({item.numberLand})</u></b></Row>
        {items.map((itemData, itemIndex) => (
          <>
            <React.Fragment key={itemIndex}>
              <Row justify={'center'}>
                <Col span={24} style={{ textAlign: 'center' }}>
                  <Space>
                    {itemData?.img?.map((e, imgIndex) => {
                      if (e.type === 11) {
                        return (
                          <div key={imgIndex}>
                            <img
                              onClick={(v) => openModalImages(itemData?.img, imgIndex)}
                              style={{ width: 200 }}
                              src={`${getImagess}/${e.pathImage}?token=${token}`}
                              alt={`รูปภาพ ${imgIndex}`}
                            />
                          </div>
                        );
                      }
                      return null;
                    })}
                  </Space>
                </Col>
              </Row>
              <Divider />
            </React.Fragment>
          </>
        ))}
        {/* {item?.img?.map((e, index) => {
              if (e.type === 11) {
                return (
                  <>
                    <Image width={"10%"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                  </>
                );
              }
              return null;
            })} */}
        {/* </Row> */}
        {/* <Divider /> */}
      </>
    )
  }
  const renderLandBuildings = ({ item, imgs, index }) => { // render รูปที่ดิน/สิ่งปลูกสร้าง-ที่ดินปัจจุบัน
    var items = [item]
    return (
      <>
        <Row justify={'left'}><b><u>รูปที่ดิน/สิ่งปลูกสร้าง-ที่ดินปัจจุบัน ({item.numberLand})</u></b></Row>

        {items.map((itemData, itemIndex) => (
          <>
            <React.Fragment key={itemIndex}>
              <Row justify={'center'}>
                <Col span={24} style={{ textAlign: 'center' }}>
                  <Space>
                    {itemData?.img?.map((e, imgIndex) => {
                      if (e.type === 1) {
                        return (
                          <div key={imgIndex}>
                            <img
                              onClick={(v) => openModalImages(itemData?.img, imgIndex)}
                              style={{ width: 200 }}
                              src={`${getImagess}/${e.pathImage}?token=${token}`}
                              alt={`รูปภาพ ${imgIndex}`}
                            />
                          </div>
                        );
                      }
                      return null;
                    })}
                  </Space>
                </Col>
              </Row>
              <Divider />
            </React.Fragment>
          </>
        ))}
        {/* {item?.img?.map((e, index) => {
              if (e.type === 1) {
                return (
                  <>
                    <Image width={"10%"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                  </>
                );
              }
              return null;
            })} */}
        {/* </Row> */}
        {/* <Divider /> */}

      </>
    )
  }

  return (
    <>
      <Modal open={open} onOk={handleOk} onCancel={handleCancel} width={'90%'}
        footer={[
          <Row justify={'center'}>
            <Button key="back" type='primary' danger onClick={handleCancel}>
              ปิด
            </Button>
          </Row>
        ]} >

        <Row justify={'center'}>
          <Col className='gutter-row' span={24} style={{ fontSize: '25px', textAlign: 'center' }}>
            <b>รายละเอียด</b>
          </Col>
        </Row>
        <Row justify={'center'}>
          <Divider style={{ margin: 5 }} />
          <Form
            labelCol={{
              span: 12,
            }}
            wrapperCol={{
              span: 24,
            }}
            style={{
              width: '90%',
            }}
            form={form}
            // onFinish={handleSubmit}
            autoComplete="off"
            initialValues={{ remember: true }}
          >
            <div className='main'>
              <Card style={{ width: '100%' }}>
                <Row justify={'center'}>
                  <Col span={24} style={{ textAlign: 'right' }}>
                    <div style={{ margin: 0 }}>
                      ชื่อการตลาดที่เสนอ : <Tag color={color}>{dataLand?.proposalBy}</Tag>
                    </div>
                    <div style={{ margin: 0 }}>
                    </div>
                  </Col>
                  <Col span={24} style={{ textAlign: 'right' }}>
                    <div style={{ margin: 0 }}>
                      สถานะ : <Tag color={color}>{dataLand?.approvalStatus}</Tag>
                    </div>
                    <div style={{ margin: 0 }}>
                    </div>
                  </Col>
                </Row>
                <Divider />
                {
                  foundInfo === true ?
                    <>
                      <Row justify={'left'} style={{ color: 'red' }}><b><u> *พบข้อมูลที่เคยเสนอมาแล้ว!</u></b></Row>
                      <Row gutter={32} justify={'center'}>
                        {
                          showOldData2?.map((item, index) => {
                            return renderOldData({ item, index })
                          })
                        }
                      </Row>
                      <Divider />
                    </>
                    :
                    null
                }

                {/* <Row justify={'left'} style={{ color: 'red' }}><b><u> *LAND!</u></b></Row> */}
                <Col gutter={32} justify={'center'}>
                  {
                    newdataLand?.map((item, index) => {
                      return renderLandData({ item, index })
                    })
                  }
                </Col>
                <Divider />


                {/* <Row justify={'left'}><b><u>รายละเอียดที่ดิน888</u></b></Row>
                <Row gutter={32} justify={'center'}>
                  <aside style={{ width: '90%' }}>
                    <div style={{ marginBottom: 0 }}>
                      <span>กลุ่มสินค้า : </span>{<b>{dataLand?.productType}</b>}
                    </div>
                    <div style={{ marginBottom: 0 }}>
                      <span>ประเภทเอกสาร : </span>{<b>{dataLand?.productTypeLand === 1 ? "นส.3" : "นส.4"}</b>}
                    </div>
                    <div style={{ marginBottom: 0 }}>
                      <span>เลขโฉนดที่ดิน : </span>{<b>{dataLand?.numberLand}</b>}
                    </div>
                    <div style={{ marginBottom: 0 }}>
                      <span>เลขที่ดินหรือระวาง : </span>{<b>{dataLand?.numberLandLawang}</b>}
                    </div>
                    <div style={{ marginBottom: 0 }}>
                      <span>จังหวัด : </span>{<b>{dataLand?.province}</b>}
                    </div>
                    <div style={{ marginBottom: 0 }}>
                      <span>อำเภอ : </span>{<b>{dataLand?.district}</b>}
                    </div>
                    <div style={{ marginBottom: 0 }}>
                      <span>พื้นที่ไร่ : </span>{<b>{dataLand?.rai}</b>}
                      <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                        ไร่
                      </span>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                      <span>พื้นที่งาน : </span>{<b>{dataLand?.workArea}</b>}
                      <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                        งาน
                      </span>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                      <span>พื้นที่ตารางวา : </span>{<b>{currencyFormatOne(dataLand?.squareWaArea)}</b>}
                      <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                        บาท
                      </span>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                      <span>ราคาประเมินที่ดิน : </span> {<b>{dataLand?.landPrice}</b>}
                      <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                        บาท / ตารางวา
                      </span>
                    </div>
                    <div style={{ marginBottom: 0 }}>
                      <span>ราคารวม : </span> {<b>{dataLand?.resultLandPrice}</b>}
                      <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                        บาท
                      </span>
                    </div>
                  </aside>
                </Row> */}
                {/* <Divider /> */}
                {
                  newdataLand.length > 1 ?
                    <>
                      <Row justify={'left'}><b><u>เลือกที่ดินหลัก</u></b></Row>
                      <Row gutter={32} justify={'center'}>
                        {
                          newdataLand?.map((item, index) => {
                            return renderSelectMainLand({ item, index })
                          })
                        }
                        {/* <Radio></Radio>
                        <Radio></Radio> */}
                        {/* <aside style={{ width: '90%' }}>
                          <div style={{ marginBottom: 0 }}> */}
                        {/* <span>รายละเอียดพิกัด : </span> */}
                        {/* {
                              newdataLand?.map((item, index) => {
                                return renderCoordinates({ item, index })
                              })
                            } */}
                        {/* <a href={`https://www.google.com/maps?q=${dataLand.parcellat},${dataLand.parcellon}`} target="_blank">
                              {`${dataLand.parcellat},${dataLand.parcellon}`}
                            </a> */}
                        {/* </div>
                        </aside> */}
                      </Row>
                      <Divider />
                    </>
                    : null
                }
                {
                  dataLand?.parcellat ?
                    <>
                      <Row justify={'left'}><b><u>พิกัด</u></b></Row>
                      <Row gutter={32} justify={'center'}>
                        <aside style={{ width: '90%' }}>
                          <div style={{ marginBottom: 0 }} >
                            {/* <span>รายละเอียดพิกัด : </span> */}
                            {
                              newdataLand?.map((item, index) => {
                                return renderCoordinates({ item, index })
                              })
                            }
                            {/* <a href={`https://www.google.com/maps?q=${dataLand.parcellat},${dataLand.parcellon}`} target="_blank">
                              {`${dataLand.parcellat},${dataLand.parcellon}`}
                            </a> */}
                          </div>
                        </aside>
                      </Row>
                    </>
                    : null
                }
                <Divider />

                {
                  newdataLand?.map((item, index) => {
                    console.log("item1105", item)
                    const imgs = item?.img
                    // item?.img?.map((imgs, i) => {
                    //   console.log("imgs", imgs, i)
                    return renderTitleDeed({ item, imgs, index })
                    // })
                  })
                }
                {
                  newdataLand?.map((item, index) => {
                    console.log("item1096", item)
                    const imgs = item?.img
                    return renderDivision({ item, imgs, index })
                  })
                }
                {
                  newdataLand?.map((item, index) => {
                    console.log("item1102", item)
                    const imgs = item?.img
                    return renderLawang({ item, imgs, index })
                  })
                }
                {
                  newdataLand?.map((item, index) => {
                    console.log("item1105", item)
                    const imgs = item?.img
                    return renderLandBuildings({ item, imgs, index })
                  })
                }
                {/* <>
                  <Row justify={'left'}><b><u>รูปโฉนด/หน้า-หลัง</u></b></Row>
                  <Space>
                    <Row gutter={32} justify={'center'}>
                      {imageBlobzz?.map((e, index) => {
                        if (e.type === 3) {
                          return (
                            <>
                              <Image width={"10%"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                            </>
                          );
                        }
                        return null;
                      })}
                    </Row>
                  </Space>
                  <Divider />
                  <Row justify={'left'}><b><u>ใบแบ่งใบต่อ</u></b></Row>
                  <Space>
                    <Row gutter={32} justify={'center'}>
                      {imageBlobzz?.map((e, index) => {
                        if (e.type === 10) {
                          return (
                            <>
                              <Image width={"10%"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />

                            </>
                          );
                        }
                        return null;
                      })}
                    </Row>
                  </Space>
                  <Divider />
                  <Row justify={'left'}><b><u>ระวาง</u></b></Row>
                  <Space>
                    <Row gutter={32} justify={'center'}>
                      {imageBlobzz?.map((e, index) => {
                        if (e.type === 11) {
                          return (
                            <>
                              <Image width={"10%"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />

                            </>
                          );
                        }
                        return null;
                      })}
                    </Row>
                  </Space>
                  <Divider />
                  <Row justify={'left'}><b><u>รูปที่ดิน/สิ่งปลูกสร้าง-ที่ดินปัจจุบัน</u></b></Row>
                  <Space>
                    <Row gutter={32} justify={'center'}>
                      {imageBlobzz?.map((e, index) => {
                        if (e.type === 1) {
                          return (
                            <>
                              <Image width={"10%"} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                            </>
                          );
                        }
                        return null;
                      })}
                    </Row>
                  </Space>
                  <Divider />
                  <Col className='gutter-row' span={24} style={{ textAlign: 'right' }}>
                    <Space>
                      <Button type='primary' style={{ margin: '5px', }} onClick={onRequest} >ขอเอกสารเพิ่ม</Button>
                      <Button type='primary' style={{ backgroundColor: "orange" }} onClick={handleAddLand} >เพิ่มที่ดิน</Button>
                      {
                        editMode ?
                          <Button type='primary' style={{ backgroundColor: "orange" }} onClick={handleEditMode} >ยกเลิกแก้ไข</Button>
                          :
                          <Button type='primary' style={{ backgroundColor: "orange" }} onClick={handleEditMode} >แก้ไข</Button>
                      }

                      <Button style={{ margin: '5px', background: 'red', color: '#ffffff' }} onClick={onUnApprove} >ปัดตก</Button>
                      <Button style={{ margin: '5px', background: '#597ef7', color: '#ffffff' }} onClick={onApprove} >อนุมัติ</Button>
                    </Space>
                  </Col>
                </> */}
                <Divider />
                <Col className='gutter-row' span={24} style={{ textAlign: 'right' }}>
                  <Space>
                    <Button type='primary' style={{ margin: '5px', }} onClick={onRequest} >ขอเอกสารเพิ่ม</Button>
                    <Button type='primary' style={{ backgroundColor: "orange" }} onClick={handleAddLand} >เพิ่มที่ดิน</Button>
                    {
                      editMode ?
                        <Button type='primary' style={{ backgroundColor: "orange" }} onClick={handleEditMode} >ยกเลิกแก้ไข</Button>
                        :
                        <Button type='primary' style={{ backgroundColor: "orange" }} onClick={handleEditMode} >แก้ไข</Button>
                    }

                    <Button style={{ background: 'red', color: '#ffffff' }} onClick={onUnApprove} >ปัดตก</Button>
                    <Button style={{ background: '#597ef7', color: '#ffffff' }} onClick={onApprove} >อนุมัติ</Button>
                  </Space>
                </Col>
              </Card>
            </div>
          </Form>
        </Row>

      </Modal >
      {contextHolder}
      {
        modalnote ?
          <NotePN
            open={modalnote}
            close={handleCloseAllModal}
            close2={handleCancel2}
            shootdata={dataLand}
            appStatus={appStatus}
            newDataPn={setDataLand}
            result={result}
            newdataLand={newdataLand}
            dataFromTable={dataFromTable}
          />
          : null
      }
      {
        isModalShowNote ?
          <ModalMoreNote
            open={isModalShowNote}
            close={setisModalShowNote}
            datanote={test}
          />
          : null
      }
      {
        isModalAddLand ?
          <ModalAddLand
            open={isModalAddLand}
            close={setIsModalAddLand}
            dataFromTable={dataFromTable}
            funcAdd={funcAdd}
            dataEdit={dataEdit}
            dataEditIndex={dataEditIndex}
            checkEdit={checkEdit}
            setCheckEdit={setCheckEdit}
            funcEdit={funcEdit}
            dataForCheckDup={newdataLand}
          />
          : null
      }
      {
        openShowImage ?
          <ModalShowImages
            open={openShowImage}
            close={setOpenShowImage}
            carimage1={carshowMadol}
          />

          : null
      }



    </>
  )
}
