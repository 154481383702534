import React, { useEffect, useState } from 'react'
import { Button, Form, Card, Modal, Col, Row, Space, Divider } from 'antd';
import { useSelector } from 'react-redux';

import axios from 'axios';
import { getguarantorsbyiden } from '../../../../file_mid/all_api';

export default function ViewGuarantor({ open, close, shootdata }) {
    const guarantor = useSelector(state => state.guarantor)
    const [dataGua] = useState(shootdata.dataedit)

    const [getData, setGetData] = useState({});
    const [dataAddress, setDataAddress] = useState([]);
    const [dataPhone, setDataPhone] = useState([]);

    useEffect(() => {
        loadData()
    }, [])

    // console.log("dataGua", dataGua)
    // console.log("guarantor.data", guarantor.data)

    const loadData = async () => {
        await axios.post(getguarantorsbyiden, { ...shootdata.dataedit, ...guarantor.data })
            .then((res) => {
                if (res.status === 200) {
                    console.log("loadData", res.data)
                    setGetData(res.data)
                    setDataAddress(res.data.dataAddress)
                    setDataPhone(res.data.dataPhones)
                    if (res.data.customerId === 0) {
                        setGetData(dataGua)
                        setDataAddress(dataGua.address)
                        setDataPhone(dataGua.phones)
                    }
                }
                else {
                }
            }).catch((err) => console.log(err))
    };

    const handleCancel = () => {
        close(false);
    };

    const renderItem = ({ item, index }) => {

        return (
            <Form >
                <Row style={{ margin: '3px' }} justify={'center'} >
                    <Card
                        key={index} // index เป็นตัวกำหนดคีย์
                        title={
                            (item.addressStatus === true || item.AddressStatus === 1 || item.AddressStatus === true) ? <u>ใช้งานที่อยู่</u> : <u>ไม่ได้ใช้งาน</u>
                        }
                        style={{ width: '300px', textAlign: 'center' }} >
                        <Col span={24} style={{ textAlign: 'left' }}>
                            <Form.Item style={{ marginBottom: 0 }} label='เลขทะเบียนบ้าน' >
                                <b>{item.houseRegistrationNumber}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='บ้านเลขที่'>
                                <b>{item.houseNumber}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='ซอย'>
                                <b>{item.soi}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='ถนน'>
                                <b>{item.road}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='หมู่บ้าน'>
                                <b>{item.village}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='ตำบล'>
                                <b>{item.subdistrict}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='อำเภอ'>
                                <b>{item.district}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='จังหวัด'>
                                <b>{item.province}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='รหัสไปรษณีย์'>
                                <b>{item.zipCode}</b>
                            </Form.Item>
                            <Divider></Divider>
                            <Form.Item style={{ marginBottom: 0 }} label='เป็นที่อยู่'>
                                <b>
                                    {(item.addressOnIdcard === true || item.AddressOnIdcard === 1 || item.AddressOnIdcard === true) && "ตามบัตรประชาชน "}
                                    {(item.houseRegistration === true || item.HouseRegistration === 1 || item.HouseRegistration === true) && "ตามทะเบียนบ้าน "}
                                    {(item.workAddress === true || item.WorkAddress === 1 || item.WorkAddress === true) && "ตามสถานที่ทำงาน "}
                                    {(item.otherAddress === true || item.OtherAddress === 1 || item.OtherAddress === true) && "อื่นๆ "}
                                    {(item.currentAddress === true || item.CurrentAddress === 1 || item.CurrentAddress === true) && "ที่อยู่ปัจจุบัน "}
                                    {(item.sendDocuments === true || item.SendDocuments === 1 || item.SendDocuments === true) && "ที่อยู่ส่งเอกสาร "}
                                </b>
                            </Form.Item>
                        </Col>
                    </Card>
                </Row>
            </Form>
        )
    }
    const renderPhone = ({ item, index }) => {

        return (
            <Form>
                <Row style={{ margin: '3px' }} justify={'center'} >
                    <Card
                        key={index} // index เป็นตัวกำหนดคีย์
                        style={{ textAlign: 'center' }}
                        title={<u>{item.phoneType}</u>}
                    >
                        <Col span={24} style={{ textAlign: 'left' }}>
                            <Form.Item style={{ marginBottom: 0 }} label='เบอร์'>
                                <b>{item.telp}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='sendSms'>
                                {
                                    item.sendSms === "0" && (<b>ไม่ส่ง</b>)
                                }
                                {
                                    item.sendSms === "1" && (<b>ส่ง</b>)
                                }
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label="หมายเหตุ">
                                {item.note}
                            </Form.Item>
                        </Col>
                    </Card>
                </Row>
            </Form >
        )
    }

    return (
        <Modal title="เพิ่มข้อมูล" open={open} onCancel={handleCancel} width={1200} footer={null} >
            <Card>
                <Row>
                    <Col span={24} style={{ fontSize: '30px', textAlign: 'center' }} >รายละเอียด</Col>
                    <Divider />
                </Row>
                <Form >
                    <Row>
                        <Col span={24} style={{ fontSize: '25px' }} >ข้อมูลส่วนตัว</Col>
                        <Col span={4}></Col>
                        <Col span={6}>
                            <Form.Item label="เลขบัตรประชาชน" style={{ margin: 0 }}>
                                <b>{getData.identificationId}</b>
                            </Form.Item>
                            <Form.Item label="ชื่อ-สกุล" style={{ margin: 0 }}>
                                <b>{getData.snam}{getData.firstname} {getData.lastname}</b>
                            </Form.Item>
                            <Form.Item label="เกิดวันที่" style={{ margin: 0 }}>
                                <b>{getData.birthdate}</b>
                            </Form.Item>

                            <Form.Item label="ความสัมพันธ์กับผู้กู้" style={{ margin: 0 }}>
                                <b>{dataGua.description}</b>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="ชื่อเล่น" style={{ margin: 0 }}>
                                <b>{getData.nickname}</b>
                            </Form.Item>
                            <Form.Item label="สถานที่ออกบัตร" style={{ margin: 0 }}>
                                <b>{getData.issuby}</b>
                            </Form.Item>
                            <Form.Item label="สาขา" style={{ margin: 0 }}>
                                <b>{dataGua.locat}</b>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="เพศ" style={{ margin: 0 }}>
                                <b>{getData.gender}</b>
                            </Form.Item>
                            <Form.Item label="วันที่บัตรหมดอายุ" style={{ margin: 0 }}>
                                <b>{getData.expdt}</b>
                            </Form.Item>
                            <Form.Item label="วันที่ออกบัตร" style={{ margin: 0 }}>
                                <b>{getData.issudt}</b>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider />
                    <Row>
                        <Col span={24}>
                            <Row>
                                <Col span={24} style={{ fontSize: '25px' }} >รายละเอียดที่อยู่</Col>
                            </Row>
                            <Row justify={'center'}>
                                {
                                    dataAddress?.map((item, index) => {
                                        return renderItem({ item, index, key: `{item.identificationId} - ${index}` })
                                    })
                                }
                            </Row>
                        </Col>
                    </Row>
                    <Divider />
                    <Row>
                        <Col span={24}>
                            <Row>
                                <Col span={24} style={{ fontSize: '25px' }} >ช่องทางการติดต่อ</Col>
                            </Row>
                            <Row justify={'center'}>
                                {
                                    dataPhone?.map((item, index) => {
                                        return renderPhone({ item, index, key: `{item.identificationId} - ${index}` })
                                    })
                                }
                            </Row>
                        </Col>
                    </Row>
                    <Divider />
                    <Row>
                        <Col className='gutter-row' span={24}>
                        </Col>
                    </Row>
                    <div style={{ textAlign: "center" }} >
                        <Space>
                            {/* <Button type="primary" style={{ width: 70, height: 70 }} onClick={onChangeBack}>BACK</Button> */}
                            <Button type="primary" onClick={handleCancel} style={{ width: 70, height: 70, background: "red" }}> CLOSE</Button>
                            {/* <Button type="primary" htmlType="submit" style={{ width: 70, height: 70, background: "green" }} onClick={handleSubmit}> SAVE</Button> */}
                            {/* <Button type="primary" style={{ width: 70, height: 70 }} onClick={onChangeGo}> NEXT</Button> */}
                        </Space>
                    </div>
                </Form>
            </Card>
        </Modal>
    )
};
