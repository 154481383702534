import { configureStore } from "@reduxjs/toolkit";
import customerDataSlice from "./Customer";
import carDataSlice from "./Car";
import loanDataSlice from "./Loan";
import addressDataSlice from "./Address";
import phoneDataSlice from "./Phone";
import oldLoanDataSlice from "./OldLoan";
import socialDataSlice from "./Social";
import emailDataSlice from "./Email";
import userDataSlice from "./User";
import imageDataSlice from "./Image";
import imageBackupDataSlice from "./ImageBackup";
import guarantorDataSlice from "./Guarantor";
import careerIncomeDataSlice from "./CareerIncome";
import landDataSlice from "./Land";
import loanLandDataSlice from "./LoanLand";
import oldLoanLandDataSlice from "./OldLoanLand";
import occupationDataSlice from "./Occupation";
export default configureStore({
    reducer: {
        customers: customerDataSlice,
        guarantor: guarantorDataSlice,
        careerIncome: careerIncomeDataSlice,
        cars: carDataSlice,
        loans: loanDataSlice,
        Addresss: addressDataSlice,
        Phones: phoneDataSlice,
        Social: socialDataSlice,
        Email: emailDataSlice,
        oldloans: oldLoanDataSlice,
        users: userDataSlice,
        imgs: imageDataSlice,
        imgBackup: imageBackupDataSlice,
        lands: landDataSlice,
        loansLand: loanLandDataSlice,
        oldLoansLand: oldLoanLandDataSlice,
        occupations: occupationDataSlice,
    }
})