import { createSlice } from "@reduxjs/toolkit";

const initialState = {

}

const loanLandDataSlice = createSlice ({
    name: "loanLand",
    initialState,
    reducers: {
        
        addLoanLand: (state, action) => {
            state.data = action.payload
            // console.log(current(state))
        },
        
    }
    
})

export const { addLoanLand } = loanLandDataSlice.actions
export default loanLandDataSlice.reducer