import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Card, Col, Row, Spin, Space, Divider, Image, Modal, message, notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ModalEditGarantor from './ModalEditGarantor';
import AddGuarantoesNew from './AddGuarantoesNew';
import axios from 'axios';

import { addImage } from '../../../redux/Image';
import { addGuarantor } from '../../../redux/Guarantor';
import { checkCCAP, deleteFolderImgGuaLand, storeDBLine, testupload, getAllFolderGuaImg } from '../../file_mid/all_api';

export default function SendGuarantor({ onCancel, changepage }) {
    const [form] = Form.useForm();
    const [form1] = Form.useForm();
    // const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = localStorage.getItem('token');
    const un = localStorage.getItem('username');
    const lands = useSelector(state => state.lands)
    const guarantor = useSelector(state => state.guarantor)
    const [api, contextHolder] = notification.useNotification();
    const imgs = useSelector(state => state.imgs)
    const [loading, setLoading] = useState(false)
    const [guarantors, setGuarantors] = useState([]);
    const [image, setImage] = useState([]);
    const [modaleditGuarantor, setModalEditGuarantor] = useState(false) // customer
    var countGu = 0
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');

    const [fileListGuarantor, setFileListGuarantor] = useState([]);
    const [fileListGuarantor1, setFileListGuarantor1] = useState([]);

    const [dataPostGuarantor, setDataPostGuarantor] = useState([]) // customerGuarantor
    const { confirm } = Modal


    const [land, setLands] = useState({ ...lands?.data })
    const [getimages, setGetImages] = useState([]);
    const [dataedit, setDataEdit] = useState([]); //เซตสำหรับแก้ไขข้อมูล 
    const [index1, setIndex] = useState(); //set index send to page edit
    const [indexedit, setIndexEdit] = useState(0); //set index send to page edit

    const [openAddGuarantor, setOpenAddGuarantor] = useState(false); //
    const [sendB, setSendB] = useState(true)

    console.log("getimages", getimages)

    useEffect(() => {
        getImg()

    }, [dataedit, indexedit, modaleditGuarantor, sendB])

    useEffect(() => {
        console.log("length", guarantor?.data?.length)
        if (guarantor?.data?.length > 0) {
            //setIndexEdit(guarantor.data.length)
            setGuarantors(guarantor.data)
            countType()
        }
    }, [])

    const countType = async () => {
        const lastItem = guarantor.data[guarantor.data.length - 1];
        // console.log("lastItem", lastItem)
        const newType = lastItem.pathImg.split("/")
        const intTpye = parseInt(newType[4])
        setIndexEdit(intTpye)
    }

    const getImg = async () => {
        const path = `one_images/land/${land.landId}/5`
        console.log("path-customer", path)
        setLoading(true)
        await axios.get(`${getAllFolderGuaImg}/${path}?token=${token}`)
            .then(res => {
                if (res.data) {
                    console.log("res.data", res.data)
                    setGetImages(res.data)
                    setLoading(false)
                }
            })
            .catch((err) => {
                console.log("err", err)
                setLoading(false)
            })
        setLoading(false)
    }


    // คนค้ำ
    const handleChangeGuarantor = async ({ fileList }) => {
        setFileListGuarantor(fileList);
        var imageListGuarantor = []
        for (const file of fileList) {
            var image = {}
            var filename = file.name
            var image64 = await getBase64(file.originFileObj)
            image.filename = filename
            image.image64 = image64
            image.type = 5
            imageListGuarantor.push(image)
        }
        setFileListGuarantor1({ ...fileListGuarantor1, imageListGuarantor });

    };
    /////////// ดึงข้อมูลจากรูปบัตรประชาชนคนค้ำ /////////////////////////////////////////////////////////////////  
    const handleScanIdCardGuarantor = async (value) => {
        if (guarantors?.length > 0) {
            var dataListGuarantor = [...guarantors]
        } else {
            var dataListGuarantor = []
        }
        for (let i = 0; i < value.length; i++) {
            var imgId = { image: value[i].image64 }
            setLoading(true)
            const headers = { 'Content-Type': 'application/json' }
            await axios.post(testupload, imgId, { headers: headers })
                .then(async (res) => {
                    delete res.data[0].address
                    if (res.data) {
                        // console.log(res.data[0])
                        const b = await loadDataOneGuarantor(res.data[0])
                        dataListGuarantor.push({ ...b[0], imgs: value[i] })
                        // console.log("BB", dataListGuarantor)
                        setLoading(false)
                    }
                    else {
                        message.info('ไม่สามารถอ่านได้')
                        setLoading(false)
                    }
                })
                .catch((err) => console.log(err))
            setLoading(false)
        }
        // console.log("bb=", dataListGuarantor)
        if (countGu === 0) {
            setGuarantors({ ...guarantors, dataListGuarantor })
            showConfirm({ dataListGuarantor })
        }
    }
    ////////////// ข้อมูลยูสในเดต้าเบสคนค้ำ ////////////////////////////////////////////////////////////////
    const loadDataOneGuarantor = async (data) => {
        setLoading(true)
        var dataListGuarantor = []
        if (!data?.identificationId) {
            countGu = 1
            setFileListGuarantor()
            setFileListGuarantor1()
            console.log("ไม่มีเลขบัตรคนค้ำ")
            alert('ไม่สามารถอ่านบัตรคนค้ำประกันได้')
        }
        else {
            if (data) {
                var one = { identificationId: data.identificationId }
                setLoading(true)
                await axios.post(checkCCAP, one)
                    .then((res) => {
                        if (res.data) {
                            //alert('มีข้อมูลในฐานข้อมูล')
                            message.info('คนค้ำมีข้อมูลในฐานข้อมูล')
                            delete res.data.address
                            delete res.data.carLoanDetails
                            delete res.data.emails
                            delete res.data.ojs
                            delete res.data.socials
                            dataListGuarantor.push(res.data)
                            setDataPostGuarantor({ ...dataPostGuarantor, ...data, customerId: 0 })
                            setLoading(false)
                        }
                        else {
                            // alert('คนค้ำไม่พบข้อมูลในฐานข้อมูล')
                            message.info('ไม่พบข้อมูลในฐานข้อมูล')
                            console.log("RES-DATA NO =", data)
                            dataListGuarantor.push(data)
                            setDataPostGuarantor({ ...dataPostGuarantor, ...data, customerId: 0 })
                            setLoading(false)
                        }
                    })
                    .catch((err) => console.log(err))
                setLoading(false)
            }
            // showConfirm({ dataListGuarantor })
            return dataListGuarantor
        }
    }

    const handleSave = () => {
        if (fileListGuarantor1?.imageListGuarantor?.length > 0) {
            handleScanIdCardGuarantor(fileListGuarantor1.imageListGuarantor)
        }
        var Guarantors = [...image]
        if (fileListGuarantor1?.imageListGuarantor?.length > 0) {
            Guarantors.push(...fileListGuarantor1.imageListGuarantor)
        }
        setFileListGuarantor1({})
        //console.log("Guarantors!!", fileListGuarantor1.imageListGuarantor)
        setImage(Guarantors)
    }

    const showConfirm = ({ dataListGuarantor }) => {
        var note = dataListGuarantor?.length;
        var lastIndex = note - 1;
        // console.log("GUarantors", dataListGuarantor)
        var imageGuarantors1 = [...dataListGuarantor];
        // console.log("imageGuarantors1BeforeMAP", imageGuarantors1)
        guarantors?.map((img, index) => {
            // console.log("imageGuarantors1Before", img)
            imageGuarantors1.push({ ...img.imgs })
            setImage(imageGuarantors1)
            // console.log("imageGuarantors1", imageGuarantors1)
        })
        // dispatch(addImage(imageGuarantors1))

        setLoading(true)
        confirm({
            title: 'เพิ่มคนค้ำ?',
            content: dataListGuarantor[lastIndex]?.snam + ' ' + dataListGuarantor[lastIndex]?.firstname + ' ' + dataListGuarantor[lastIndex]?.lastname,
            onOk() {
                setGuarantors(dataListGuarantor)
                dispatch(addGuarantor(dataListGuarantor))
                setLoading(false)
                setTimeout(() => {
                    setFileListGuarantor([])
                },)
            },
            onCancel() {
                message.error('ยกเลิก')
                setGuarantors(guarantors)
                setFileListGuarantor1({})
                setTimeout(() => {
                    setFileListGuarantor([])
                },)
                setLoading(false)
            },
        });
    }
    const handleSubmit = () => {

        if (guarantors !== undefined) {
            let checkphones = 0
            let checknickname = 0
            guarantors.map((e, index) => {
                // console.log("e", e)
                if (e.nickname === undefined) {
                    checkphones = 1
                    // console.log('No nickname', index + 1)
                    const CheckAddrGua = (placement) => {
                        api.error({
                            message: <b>กรุณาเพิ่มที่อยู่ของผู้ค้ำ</b>,
                            description: `กรุณาเพิ่มที่อยู่ผู้ค้ำ ลำดับที่ ${index + 1}`,
                            placement,
                        });
                    }
                    CheckAddrGua("top")
                }
                if (e.phones === undefined) {
                    checknickname = 1
                    const CheckPhnGua = (placement) => {
                        api.error({
                            message: <b>กรุณาเพิ่มเบอร์โทรศัพท์ของผู้ค้ำ</b>,
                            description: `กรุณาเพิ่มเบอร์โทรศัพท์ของผู้ค้ำ ลำดับที่ ${index + 1}`,
                            placement,
                        });
                    }
                    CheckPhnGua("top")
                    // console.log('No Phones', index + 1)
                }
            })
            if (checkphones !== 1 && checknickname !== 1) {

                dispatch(addGuarantor(guarantors))
                changepage('5')
            }

        } else {
            dispatch(addGuarantor(guarantors))
            changepage('5')
        }
    }
    const onChangeBack = () => {
        dispatch(addGuarantor(guarantors))
        changepage('3')
    }

    const handleOpen = () => {
        setOpenAddGuarantor(true)
    }

    // const getAddData = ({ images1, imageListGuarantorPush, getIndex }) => {
    const getAddData = ({ AddData2, indexedit1 }) => {
        console.log('indexedit1', indexedit1)
        // let count = guarantors?.length
        console.log('AddData2', AddData2)
        setIndexEdit(indexedit1 + 1)

        if (guarantors?.length === 0) {
            const AddDatanew = AddData2    //ประกาศตัวแปรเพื่อเก็บค่าที่เพิ่มเข้ามาไปเซตให้ dataArray
            console.log('1')
            setGuarantors([...AddDatanew]);
        } else if (guarantors?.length >= 1) {
            console.log('2')
            const AddDatanew = AddData2   //ประกาศตัวแปรเพื่อเก็บค่าที่เพิ่มเข้ามาไปเซตให้ dataArray
            setGuarantors([...guarantors, ...AddDatanew]);//เก็บค่า state เดิมไว้ พร้อมส่ง type ไปด้วย เพื่อบอกค่าที่ส่ง
            // setGuarantors(pre => [...pre, ...AddData2]);//เก็บค่า state เดิมไว้ พร้อมส่ง type ไปด้วย เพื่อบอกค่าที่ส่ง
        }
    }


    // แก้ไขข้อมูลจากหน้า AddressEditData
    const fucnEdit = ({ data, getIndex }) => {
        console.log("โยนค่ามาจากหน้าแก้ไขคนค้ำ", data, getIndex)
        let Theedit = [...guarantors]
        Theedit[getIndex] = data[0]
        console.log("Theedit", Theedit)
        //ข้อมูลใหม่แทนที่ข้อมูลเดิมในช่อง Input
        setGuarantors(Theedit);
    }

    // const handlePreview = async (file) => {
    //     if (!file.url && !file.preview) {
    //         file.preview = await getBase64(file.originFileObj);
    //     }
    //     setPreviewImage(file.url || file.preview);
    //     setPreviewVisible(true);
    // };
    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };
    // const handleCancel = () => {
    //     setPreviewVisible(false);
    // }


    const showConfirmCancel = () => {
        confirm({
            title: 'คุณต้องการยกเลิกการเสนอเคส...?',
            onOk() {
                // mixsCancel()
                onCancel(false);
            },
            onCancel() {
                // message.success('ยกเลิก')
            },
        });
    };
    const genGuarantors = ({ item, index }) => {
        // console.log("YY", item, index)
        var itemSplit = []
        if (item?.pathImg) {
            itemSplit = item.pathImg.split("/")
        }
        const handleEdit = (dataedit, index1) => {
            console.log("GG", dataedit, index1)
            setIndex(index1) //เซตค่า index ให้กับชุด ข้อมูล
            setDataEdit({ dataedit, index1 }) //ส่งข้อมูลด index ไปหน้าแก้ไข
            setModalEditGuarantor(true);
        }
        // modal deleteImage
        const showConfirmDelete = async ({ dataedit, index }) => {
            setLoading(true)
            console.log("dataedit", dataedit);
            console.log("index", index);
            console.log("getimagessadsd", getimages);
            // const sums = []
            const folder = []
            const split = dataedit?.pathImg.split('/')[4];
            getimages.map((item, index1) => {
                var image = {}
                // const count = `${index + 1}`
                console.log("split = ", item?.pathImage.split('/')[4], split);
                if (item?.pathImage.split('/')[4] === split) {
                    image.attr = item.attr
                    image.data = item.data
                    image.idImage = item.idImage
                    image.pathImage = item.pathImage
                    folder.push(image)
                }
                // console.log("count = [1]", folder);
            })
            console.log("folder", folder)
            // console.log("dataedit", dataedit)
            del({ dataedit, folder })


            // setLoading(false)
        }


        const handleNoti = async (path) => {
            const ez = {nameInput: un, path: path}
            await axios.post(storeDBLine, ez)
              .then(res => {
                if (res.data) {
                  console.log("OK")
                  setLoading(false)
                }
              })
              .catch((err) =>
                console.log(err)
              )
            setLoading(false)
          }

        const mixs = async ({ mix }) => {
            //console.log("mix", mix)
            await axios.delete(deleteFolderImgGuaLand, { data: mix })
                .then(res => {
                    // if (res.status === 200) {
                    console.log("res.data", res)
                    setLoading(false)
                    setTimeout(() => {
                        const deletearray = [...guarantors] // กระจายข้อมูลเข้าไปในตัวแปร deletearray
                        deletearray.splice(index, 1); // เป็นการลบข้อมูลใน Array ตาม index ที่เลือกตาม Card
                        dispatch(addGuarantor(deletearray))
                        setGuarantors(deletearray);  // เซตค่าเข้าไปเก็บใน dataArray
                        message.success('ลบข้อมูลคนค้ำสำเร็จ')
                    }, 1500)

                })
                .catch((err) => {
                    console.log("err", err)
                    setLoading(false)
                })
        }

        const del = async ({ dataedit, folder }) => {
            // console.log("dataedit 11", dataedit)
            // console.log("sums 11", folder)

            const mix = {
                inPath: dataedit.pathImg, // pathImg:"one_images/land/894/5/2"
                guaImg: folder,
                // guaImg:  // รูปทั้งหมดให้อยู่ในนี้
                //     folder?.map((s, index) => {
                //         // console.log("s", s, index)
                //         return (
                //             {
                //                 attr: s.attr, //attr:"image/jpeg"
                //                 pathImage: s.pathImage, //pathImage:"one_images/land/894/5/2/eebYeBye5rkkXtpTEkm3d51707193267.jpg"
                //                 idImage: s.idImage //idImage:"eebYeBye5rkkXtpTEkm3d5"
                //             }
                //         )
                //     })
            }
            console.log("mix", mix)
            await handleNoti(dataedit.pathImg)
            await mixs({ mix })
        }

        const showConfirm = (dataedit, index) => {
            confirm({
                title: 'คุณต้องการที่จะลบคนค้ำ...?',
                onOk() {
                    handleDelete(dataedit, index)
                },
                onCancel() {
                    // message.success('ยกเลิก')
                },
            });
        };

        const handleDelete = (dataedit, index) => {
            console.log("GsadaG", dataedit, index)
            showConfirmDelete({ dataedit, index })
        }


        return (
            <Form
                name="basic"
                labelCol={{
                    span: 12,
                }}
                wrapperCol={{
                    span: 24,
                }}
                style={{
                    maxWidth: '100%',
                }}
                initialValues={{
                    remember: true,
                }}
                form={form1}
                onFinish={handleSubmit}
                autoComplete="off"
            >
                <Card style={{ width: '100%' }}                >
                    <Row justify={'center'}><b><u>คนค้ำที่ {index + 1}</u></b></Row>
                    <div style={{ textAlign: 'center' }}>
                        <Form.Item style={{ margin: 3 }} label='' >
                            <span>เลขบัตรประชาชน : </span>
                            <b>{item?.identificationId}</b>
                        </Form.Item>
                    </div>
                    <Row justify={'center'} gutter={32} className='main2'>
                        <Col className='gutter-row' span={24}>
                            <aside style={{ width: '100%', }} >
                                <div style={{ textAlign: 'left' }}>
                                    <Form.Item style={{ margin: 3 }} label='' >
                                        <span>ชื่อ : </span>

                                        <b>{item?.firstname}</b>
                                    </Form.Item>
                                </div>
                                <div style={{ textAlign: 'left' }}>
                                    <Form.Item style={{ margin: 3 }} label='' >
                                        <span>นามสกุล : </span>

                                        <b>{item?.lastname}</b>
                                    </Form.Item>
                                </div>
                                <div style={{ textAlign: 'left' }}>
                                    <Form.Item style={{ margin: 3 }} label='' >
                                        <span>ชื่อเล่น : </span>

                                        <b>{item?.nickname}</b>
                                    </Form.Item>
                                </div>
                                <div style={{ textAlign: 'left' }}>
                                    {item?.phones?.length >= 1 ?
                                        <>
                                            <Form.Item
                                                label=''
                                                style={{ margin: 3 }}
                                            >
                                                <span>เบอร์โทร : </span>

                                                <b>{item?.phones[0]?.telp}</b>
                                            </Form.Item>
                                        </>
                                        :
                                        <Form.Item
                                            label=''
                                            style={{ margin: 0 }}
                                        >
                                            <span>เบอร์โทร : </span>

                                            <b>{item?.phones}</b>
                                        </Form.Item>
                                    }
                                </div>
                                {/* <div style={{ textAlign: 'left' }}>
                                    <Form.Item style={{ margin: 3 }} label=''>
                                        <span>วันเกิด : </span>

                                        <b>{item?.birthdate}</b>
                                    </Form.Item>
                                </div>
                                <div style={{ textAlign: 'left' }}>
                                    <Form.Item style={{ margin: 3 }} label='' >
                                        <span>เพศ : </span>

                                        <b>{item?.gender}</b>
                                    </Form.Item>
                                </div> */}
                            </aside>

                        </Col>
                        <Divider style={{ margin: 5 }} />
                    </Row>
                    <Row gutter={32} justify={'center'}>
                        <span>รูปภาพ : </span>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            {/* {item?.imgs?.map((items, index) => {
                                return (
                                    <img width={'300px'} key={index} style={{ margin: 5 }} src={createImageUrl(items.image64)} alt={items.filename} />
                                )
                            })
                            } */}
                            {itemSplit.length > 0 ? (
                                <>
                                    {getimages?.map((items, index) => {
                                        const segments = items.pathImage.split("/")
                                        if (itemSplit[4] === segments[4]) {
                                            return (
                                                <Image width={'300px'} key={index} src={`data:${items?.Attr};base64,${items?.data?.toString('base64')}`} alt={`Image ${index + 1}`} />
                                            )
                                        }

                                    })}
                                </>
                            ) : null
                            }
                        </Col>
                    </Row>
                    <Divider style={{ margin: 5 }} />
                    <Row justify={'center'}>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <Space>
                                <Button type="text"
                                    style={{ background: "red", color: 'white' }}
                                    // onClick={() => { handleDelete(guarantors[index], index); }}
                                    onClick={() => { showConfirm(guarantors[index], index); }}
                                >
                                    ลบ
                                </Button>
                                <Button type="text"
                                    style={{ background: "#e0e0e0" }}
                                    onClick={() => { handleEdit(guarantors[index], index); }}
                                >
                                    แก้ไข
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Card>
            </Form>
        )
    }
    return (
        <>
            <Row justify={'center'}>
                <Card style={{ width: '1100px' }}>

                    <Col style={{ textAlign: 'center' }} >
                        <b><h2>ข้อมูลคนค้ำประกัน</h2></b>
                    </Col>
                    <Spin spinning={loading} size='large' tip=" Loading... ">
                        <Row justify={'center'}>
                            <Form
                                name="basic"
                                labelCol={{
                                    span: 12,
                                }}
                                wrapperCol={{
                                    span: 24,
                                }}
                                style={{
                                    width: '100%',
                                }}
                                initialValues={{
                                    remember: true,
                                }}
                                form={form}
                                onFinish={handleSubmit}
                                autoComplete="off"
                            >
                                <Divider style={{ margin: 5 }} />
                                <Row justify={'center'}>
                                    {guarantors?.length > 0 ?
                                        <>
                                            {guarantors?.map((item, index) => {
                                                return genGuarantors({ item, index, key: `{item.identificationId} - ${index}` });
                                            })}
                                        </>
                                        :
                                        null
                                    }
                                </Row>
                                <Row justify={'center'} style={{ margin: 10 }} align={'middle'}>
                                    <b>
                                        <Form.Item label='' name='imgGuarantor'>
                                            <Button
                                                type='primary'
                                                onClick={handleOpen}
                                            >
                                                เพิ่มคนค้ำ
                                            </Button>
                                        </Form.Item>
                                    </b>
                                </Row>

                                <Row justify={'center'} style={{ margin: 10 }}>
                                    {fileListGuarantor1?.imageListGuarantor?.length >= 1 ?
                                        <>
                                            <Button type="text" style={{ background: 'green', color: 'white' }} onClick={handleSave}>
                                                บันทึก
                                            </Button>
                                        </>
                                        :
                                        <>
                                            {
                                                fileListGuarantor1?.imageListGuarantor?.length === 0 ?
                                                    <>
                                                        <Button type="text" disabled style={{ background: 'green', color: 'white' }} onClick={handleSave}>
                                                            บันทึก
                                                        </Button>
                                                    </>
                                                    : null
                                            }
                                        </>
                                    }
                                </Row>
                                <Row justify={'center'}>
                                    <h3><b style={{ color: 'red' }}>***ตรวจสอบข้อมูลคนค้ำว่าถูกต้อง แล้วกดต่อไป***</b></h3>
                                </Row>
                                <Divider style={{ margin: 5 }} />
                                <Row justify={'center'} >
                                    <Space>
                                        <Button type="primary" style={{ background: "#bfbfbf" }} onClick={(e) => { onChangeBack(e) }}>
                                            ย้อนกลับ
                                        </Button>
                                        {/* {guarantors?.length > 0 ?
                                            <> */}
                                        <Button type="primary" onClick={() => { showConfirmCancel() }}style={{ background: "red" }}>ยกเลิก</Button>
                                        <Button type="primary" htmlType="submit" style={{ background: "green" }}>
                                            ต่อไป
                                        </Button>
                                    </Space>
                                </Row>

                            </Form>
                        </Row>
                    </Spin>
                </Card>
                {contextHolder}
            </Row>
            {
                modaleditGuarantor ?
                    <ModalEditGarantor
                        open={modaleditGuarantor}
                        close={setModalEditGuarantor}
                        dataGuarantor={guarantors}
                        shootdata={dataedit} // 
                        sb={setSendB}
                        fucnEdit={fucnEdit}
                        dataindex={index1}
                    />
                    :
                    null
            }

            {
                openAddGuarantor ?
                    <AddGuarantoesNew
                        open={openAddGuarantor}
                        close={setOpenAddGuarantor}
                        dataGuarantor={guarantors} //ข้อมูลที่มีอยู่โยนไปหน้าเพิ่มคนค้ำ
                        getAddData={getAddData}
                        dataindex={index1}
                        indexedit={indexedit}
                    />
                    :
                    null
            }
        </>
    )

}