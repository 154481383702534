import React, { useRef, useState, useEffect } from "react";
import { Button } from "antd";
import { useReactToPrint } from "react-to-print";
import axios from "axios";
import Page1 from "./page1";
import Page2 from "./page2";
import "./css/pagePicture.css";
//ลายน้ำ=Watermark
//import { Watermark } from "@hirohe/react-watermark";

const DotaColumsPdf = ({ record }) => {
  const dataRecord = record;
  const [marketingData, setMarketingData] = useState([]);
  const [loading, setLoading] = useState(true);
  //console.log("record.proposalBy1234", record.proposalBy);
  let userMK = record.proposalBy;
  const textWatermark = "  ใช้ในการรับจำนองที่ดินโฉนดเลขที่ "+dataRecord.numberLand+" หน้าสำรวจ "+dataRecord.inputDateText4+" เลขที่ดิน "+dataRecord.inputDateText3+" เท่านั้น ";
  // const dataRecordInputCase = recordInputCase;
  const conponentPDF = useRef();
  // Function to print the componentPDF content
  const generatePDF = useReactToPrint({
    content: () => conponentPDF.current,
    documentTitle: "รายงานสรุปเคสขอยอดรถ-ตามสาขา",
    // onAfterPrint: () => alert("Data saved in PDF"),
  });

  const handleGeneratePDF = () => {
    if (marketingData.length === 0) {
      alert(
        "ไม่พบข้อมูล " + userMK + " การตลาดยังไม่ได้กรอกประวัติในหน้าการตั้งค่า"
      );
      //console.log("marketingData22", marketingData);
      return;
    } else {
      //console.log("marketingData33==", marketingData);
      generatePDF();
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await axios.get(
          `https://shark-app-j9jc9.ondigitalocean.app/toro/marketings/${userMK}`
        );
        const values = res.data[0];
        //console.log("values11",values);
        if (values === undefined) {
          console.log("values222", values);
        } else {
          setMarketingData(values);
          console.log("values333", values);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        // Set loading state to false after fetching data
        setLoading(false);
      }
    };

    fetchData();
  }, [userMK]);
  //console.log("marketingData00", marketingData);

  if (loading) {
    return <div tip="Loading" size="large" />;
  }
  return (
    <>
      <div ref={conponentPDF}>{/* Your content to be printed */}</div>
      <Button type="primary" onClick={handleGeneratePDF}>
        เลือก
      </Button>
      <div style={{ display: "none" }}>
        {/* Only render the content to be printed */}
        <div ref={conponentPDF} style={{ width: "100%" }} justify={"center"}>
          {/* Content you want to print */}
          {dataRecord && marketingData.length !== 0 && (
            <div>
              {/* Render Page1, Page2, Page3 here */}
              <Page1
                dataRecord={dataRecord}
                marketingData={marketingData}
                style={{ height: "1123PX", width: "794PX" }}
              ></Page1>
              <Page2
                dataRecord={dataRecord}
                // style={{ height: "1123PX", width: "794PX" }}
              ></Page2>
             <div className="container" >
                <div className="divTextWatermark" >
                  {textWatermark}
                </div>
                <div className="divImgP11" />
              </div>

              <div className="container" >
                <div className="divTextWatermark" >
                  {textWatermark}
                </div>
                <div className="divImgP12" />
              </div>
              <div className="container" >
                <div className="divTextWatermark" >
                  {textWatermark}
                </div>
                <div className="divImgP21" />
              </div>
              <div className="container" >
                <div className="divTextWatermark" >
                  {textWatermark}
                </div>
                <div className="divImgP22" />
              </div>
              <div className="container" >
                <div className="divTextWatermark" >
                  {textWatermark}
                </div>
                <div className="divImgP23" />
              </div>
              <div className="container" >
                <div className="divTextWatermark" >
                  {textWatermark}
                </div>
                <div className="divImgP24" />
              </div>
              <div className="container" >
                <div className="divTextWatermark" >
                  {textWatermark}
                </div>
                <div className="divImgP25" />
              </div>
              <div className="container" >
                <div className="divTextWatermark" >
                  {textWatermark}
                </div>
                <div className="divImgP0" />
              </div>

              
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default DotaColumsPdf;
