import React, { useState, useEffect, useRef } from 'react'
import { Form, Modal, Row, Col, Image, Space, Card, Button, DatePicker, Divider, Spin, Select } from 'antd'
import axios from "axios";
import dayjs from "dayjs";
import { PrinterOutlined } from '@ant-design/icons';
import { useReactToPrint } from "react-to-print";
import './css.css'
import { loadimage, getImagess } from '../../file_mid/all_api';

export default function PrintLand_V2({ open, close, userData }) {
    const token = localStorage.getItem('token');
    const [imageBlobzz, setImageBlobzz] = useState([]);
    const [user, setUser] = useState(userData)
    const [user1, setUser1] = useState()
    const [img, setimg] = useState()
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [dataPost, setDataPost] = useState({ ty: 1 })
    const conponentPDF = useRef();
    const generatePDF = useReactToPrint({
        content: () => conponentPDF.current,
        documentTitle: "CarRate0",
    });

    console.log("a", userData)
    // console.log("user", user)
    useEffect(() => {
        if (userData) {
            loadImage()
        }
        form.setFieldsValue({ ty: dataPost?.ty })
    }, [])

    const loadImage = async () => {
        setLoading(true)
        var imageList = []
        for (const file of userData) {
            await axios.post(loadimage, { landId: file?.landId, loanType: "land" })
                .then(res => {
                    if (res.status === 200) {
                        // console.log("11", res.data)
                        //setImageBlobzz(res.data)
                        imageList.push(...res.data)
                    } else if (res.status === 204) {
                        // NotiErrorIMG("top")
                    }
                }).catch(err => {
                    console.log(err)
                })
        }
        // console.log("imageList",imageList)
        setImageBlobzz(imageList)
        setLoading(false)
    }

    const handleOk = () => {
        close(false);
    };

    const handleCancel = () => {
        close(false);
    };
    return (
        <Modal open={open} onOk={handleOk} onCancel={handleCancel} width={'1000px'}
            footer={[
                <Row justify={'center'}>
                    <Button key="back" type='primary' danger onClick={handleCancel}>
                        ปิด
                    </Button>
                </Row>
            ]} >
            <Row justify={'center'}>
                <Col className='gutter-row' span={24} style={{ fontSize: '25px', textAlign: 'center' }}>
                    <b>ปริ้นท์รูปภาพ</b>
                </Col>
            </Row>
            <Divider style={{ margin: 5 }} />
            <Row justify={'center'}>
                <Spin spinning={loading} size='large' tip=" Loading... ">
                    <Form
                        name="basic"
                        labelCol={{
                            span: 12,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        form={form}
                        autoComplete="off"
                    >
                        <Row gutter={32}>
                            <Col className='gutter-row' span={24} style={{ textAlign: 'center' }}>
                                <Form.Item label='รายละเอียด' name='ty'
                                    style={{ margin: 0, width: '250px' }}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input !',
                                        },]}>
                                    <Select
                                        style={{ width: '250px' }}
                                        placeholder={'เลือก'}
                                        onChange={(value) => setDataPost({ ...dataPost, ty: value })}
                                        options={[
                                            {
                                                label: 'แสดงทั้งหมด',
                                                value: 1,
                                            },
                                            {
                                                label: 'รูปโฉนด/หน้า-หลัง',
                                                value: 2,
                                            },
                                            {
                                                label: 'ใบแบ่งใบต่อ',
                                                value: 3,
                                            },
                                            {
                                                label: 'ระวาง',
                                                value: 4,
                                            },
                                            {
                                                label: 'รูปที่ดิน/สิ่งปลูกสร้าง-ที่ดินปัจจุบัน',
                                                value: 5,
                                            },

                                        ]}
                                    />
                                </Form.Item>
                                <div>
                                    {dataPost && dataPost?.ty == 1 && (
                                        <>
                                            {user && user?.map((e, index) => {
                                                // console.log("e", e)
                                                return (
                                                    <>
                                                        {/* <Space direction='vertical' > */}
                                                        <Row justify={'center'} className="print-content" ref={conponentPDF}>
                                                            <Col className='gutter-row' span={24} style={{ fontSize: '18px', textAlign: 'center' }}>
                                                                <b><u>รายละเอียด</u></b>
                                                            </Col>
                                                            <Col span={8} style={{ textAlign: 'center' }}>
                                                                <b><u>รูปทั้งหมด</u></b>
                                                            </Col>
                                                            <Col span={16} style={{ textAlign: 'left' }}>
                                                                <span>ชื่อการตลาดที่เสนอ : </span><b>{e.proposalBy}</b>
                                                            </Col>
                                                            {imageBlobzz?.map((en, inD) => {
                                                                // console.log("2")
                                                                if (en && en.pathImage) {
                                                                    return (
                                                                        // <div key={index}>
                                                                        <Row justify={'center'} >
                                                                            {/* <Space direction='vertical'> */}
                                                                            <Image
                                                                                style={{ marginTop: '15px' }}
                                                                                width={"620px"}
                                                                                height={"990px"}
                                                                                key={inD}
                                                                                src={`${getImagess}/${en.pathImage}?token=${token}`}
                                                                                alt={`รูปภาพ ${inD}`} />
                                                                            <p></p>
                                                                            {/* </Space> */}
                                                                        </Row>
                                                                        // </div>
                                                                    )
                                                                } else {
                                                                    return null
                                                                }
                                                            }).reverse()}
                                                        </Row>
                                                        {/* </Space> */}
                                                    </>
                                                )
                                            })}
                                        </>
                                    )}
                                    {dataPost && dataPost?.ty == 2 && (
                                        <>
                                            {user && user?.map((e, index) => {
                                                return (
                                                    <>
                                                        {/* <Space direction='vertical' > */}
                                                        <Row justify={'center'}>
                                                            <Col className='gutter-row' span={24} style={{ fontSize: '18px', textAlign: 'center' }}>
                                                                <b><u>รายละเอียด</u></b>
                                                            </Col>
                                                            <Col span={8} style={{ textAlign: 'center' }}>
                                                                <b><u>รูปโฉนด/หน้า-หลัง</u></b>
                                                            </Col>
                                                            <Col span={16} style={{ textAlign: 'left' }}>
                                                                <span>ชื่อการตลาดที่เสนอ : </span><b>{e.proposalBy}</b>
                                                            </Col>
                                                            {imageBlobzz?.map((en, inD) => {
                                                                if (en && en.pathImage) {
                                                                    if (en.type === 3) {
                                                                        return (
                                                                            <div key={index}>
                                                                                <Row justify={'center'}>
                                                                                    {/* <Space direction='vertical'> */}
                                                                                    <Image
                                                                                        style={{ marginTop: '15px' }}
                                                                                        width={"620px"}
                                                                                        height={"990px"}
                                                                                        key={inD}
                                                                                        src={`${getImagess}/${en.pathImage}?token=${token}`}
                                                                                        alt={`รูปภาพ ${inD}`} />
                                                                                    <p></p>
                                                                                    {/* </Space> */}
                                                                                </Row>
                                                                            </div>
                                                                        )
                                                                    }
                                                                } else {
                                                                    return null
                                                                }
                                                            })}

                                                        </Row>
                                                        {/* </Space> */}
                                                    </>
                                                )
                                            })}
                                            <Divider />
                                        </>
                                    )}
                                    {dataPost && dataPost?.ty == 3 && (
                                        <>
                                            {user && user?.map((e, index) => {
                                                return (
                                                    <>
                                                        {/* <Space direction='vertical' > */}
                                                        <Row justify={'center'}>
                                                            <Col className='gutter-row' span={24} style={{ fontSize: '18px', textAlign: 'center' }}>
                                                                <b><u>รายละเอียด</u></b>
                                                            </Col>
                                                            <Col span={8} style={{ textAlign: 'center' }}>
                                                                <b><u>ใบแบ่งใบต่อ</u></b>
                                                            </Col>
                                                            <Col span={16} style={{ textAlign: 'left' }}>
                                                                <span>ชื่อการตลาดที่เสนอ : </span><b>{e.proposalBy}</b>
                                                            </Col>
                                                            {imageBlobzz?.map((en, inD) => {
                                                                if (en && en.pathImage) {
                                                                    if (en.type === 10) {
                                                                        return (
                                                                            <div key={index}>
                                                                                <Row justify={'center'}>
                                                                                    {/* <Space direction='vertical'> */}
                                                                                    <Image
                                                                                        style={{ marginTop: '15px' }}
                                                                                        width={"620px"}
                                                                                        height={"990px"}
                                                                                        key={inD}
                                                                                        src={`${getImagess}/${en.pathImage}?token=${token}`}
                                                                                        alt={`รูปภาพ ${inD}`} />
                                                                                    <p></p>
                                                                                    {/* </Space> */}
                                                                                </Row>
                                                                            </div>
                                                                        )
                                                                    }
                                                                } else {
                                                                    return null
                                                                }
                                                            })}
                                                        </Row>
                                                        {/* </Space> */}
                                                    </>
                                                )
                                            })}
                                            <Divider />
                                        </>
                                    )}
                                    {dataPost && dataPost?.ty == 4 && (
                                        <>
                                            {user && user?.map((e, index) => {
                                                return (
                                                    <>
                                                        {/* <Space direction='vertical' > */}
                                                        <Row justify={'center'}>
                                                            <Col className='gutter-row' span={24} style={{ fontSize: '18px', textAlign: 'center' }}>
                                                                <b><u>รายละเอียด</u></b>
                                                            </Col>
                                                            <Col span={8} style={{ textAlign: 'center' }}>
                                                                <b><u>ระวาง</u></b>
                                                            </Col>
                                                            <Col span={16} style={{ textAlign: 'left' }}>
                                                                <span>ชื่อการตลาดที่เสนอ : </span><b>{e.proposalBy}</b>
                                                            </Col>
                                                            {imageBlobzz?.map((en, inD) => {
                                                                if (en && en.pathImage) {
                                                                    if (en.type === 11) {
                                                                        return (
                                                                            <div key={index}>
                                                                                <Row justify={'center'}>
                                                                                    {/* <Space direction='vertical'> */}
                                                                                    <Image
                                                                                        style={{ marginTop: '15px' }}
                                                                                        width={"620px"}
                                                                                        height={"990px"}
                                                                                        key={inD}
                                                                                        src={`${getImagess}/${en.pathImage}?token=${token}`}
                                                                                        alt={`รูปภาพ ${inD}`} />
                                                                                    <p></p>
                                                                                    {/* </Space> */}
                                                                                </Row>
                                                                            </div>
                                                                        )
                                                                    }
                                                                } else {
                                                                    return null
                                                                }
                                                            })}
                                                        </Row>
                                                        {/* </Space> */}
                                                    </>
                                                )
                                            })}
                                            <Divider />
                                        </>
                                    )}
                                    {dataPost && dataPost?.ty == 5 && (
                                        <>
                                            {user && user?.map((e, index) => {
                                                return (
                                                    <>
                                                        {/* <Space direction='vertical' > */}
                                                        <Row justify={'center'}>
                                                            <Col className='gutter-row' span={24} style={{ fontSize: '18px', textAlign: 'center' }}>
                                                                <b><u>รายละเอียด</u></b>
                                                            </Col>
                                                            <Col span={8} style={{ textAlign: 'center' }}>
                                                                <b><u>รูปที่ดิน/สิ่งปลูกสร้าง-ที่ดินปัจจุบัน</u></b>
                                                            </Col>
                                                            <Col span={16} style={{ textAlign: 'left' }}>
                                                                <span>ชื่อการตลาดที่เสนอ : </span><b>{e.proposalBy}</b>
                                                            </Col>
                                                            {imageBlobzz?.map((en, inD) => {
                                                                if (en && en.pathImage) {
                                                                    if (en.type === 1) {
                                                                        return (
                                                                            <div key={index}>
                                                                                <Row justify={'center'}>
                                                                                    {/* <Space direction='vertical'> */}
                                                                                    <Image
                                                                                        style={{ marginTop: '15px' }}
                                                                                        width={"620px"}
                                                                                        height={"990px"}
                                                                                        key={inD}
                                                                                        src={`${getImagess}/${en.pathImage}?token=${token}`}
                                                                                        alt={`รูปภาพ ${inD}`} />
                                                                                    <p></p>
                                                                                    {/* </Space> */}
                                                                                </Row>
                                                                            </div>
                                                                        )
                                                                    }
                                                                } else {
                                                                    return null
                                                                }
                                                            })}
                                                        </Row>
                                                        {/* </Space> */}
                                                    </>
                                                )
                                            })}
                                            <Divider />
                                        </>
                                    )}
                                </div>
                                <Button
                                    style={{ margin: '5px', background: '#597ef7', color: '#ffffff' }}
                                    // onClick={window.print()}
                                    onClick={() => generatePDF()}
                                    icon={<PrinterOutlined />}
                                >
                                    พิมพ์รูป
                                </Button>

                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Row>
        </Modal>
    )
}


{/* <Row justify={'left'}><b><u>รูปที่ดิน</u></b></Row>
                                        <Space>
                                            <Row gutter={32} justify={'center'}>
                                                {user && user?.map((e, index) => {
                                                    // console.log("ss =",e)
                                                    const tt = JSON.parse(e.images)
                                                    //console.log("mm =",tt)
                                                    const imagess = tt.map((en, inD) => {
                                                        if (en.type === 1) {
                                                            return (
                                                                <Image
                                                                    width={"70%"}
                                                                    key={inD}
                                                                    src={`${getImagess}/${en.pathImage}?token=${token}`}
                                                                    alt={`รูปภาพ ${inD}`}
                                                                />
                                                            )
                                                        }
                                                        return null
                                                    })
                                                    return (
                                                        <div key={index}>
                                                            {e.snam && e.firstname && e.lastname && <h2>{"ชื่อ-สกุล" + ' ' + e.snam + ' ' + e.firstname + ' ' + e.lastname}</h2>}
                                                            {imagess}
                                                            <p></p>
                                                        </div>
                                                    )
                                                })}
                                            </Row>
                                        </Space>
                                        <Divider />
                                        <Row justify={'left'}><b><u>รูปโฉนด</u></b></Row>
                                        <Space>
                                            <Row gutter={32} justify={'center'}>
                                                {user && user?.map((e, index) => {
                                                    const tt = JSON.parse(e.images)
                                                    const imagess = tt.map((en, inD) => {
                                                        if (en.type === 3) {
                                                            return (
                                                                <Image
                                                                    width={"70%"}
                                                                    key={inD}
                                                                    src={`${getImagess}/${en.pathImage}?token=${token}`}
                                                                    alt={`รูปภาพ ${inD}`}
                                                                />
                                                            );
                                                        }
                                                        return null
                                                    })
                                                    return (
                                                        <div key={index}>
                                                            {e.snam && e.firstname && e.lastname && <h2>{"ชื่อ-สกุล" + ' ' + e.snam + ' ' + e.firstname + ' ' + e.lastname}</h2>}
                                                            {imagess}
                                                            <p></p>
                                                        </div>
                                                    );
                                                })}
                                            </Row>
                                        </Space> */}