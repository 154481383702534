//ก่อนแก้ จาก if เป็น switch-case
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Steps, Card } from "antd";
import Dayjs from "dayjs";
import "./css/stepStatus.css";
import { detailfollowexpeditethedebt } from "../../../file_mid/all_api";

const { Step } = Steps;
const DataModal = ({ currentRecord }) => {
  //เก็บข้อมูล API
  const [dataContno, setDataContno] = useState("");
  //status
  let status_001 = "ระงับเคส";
  let status_002 = "ลูกค้าปฏิเสธ";
  let status_003 = "Reject";
  //สถานะต่างๆ
  let abcProcess = "process";
  let aFinish = "finish";
  let bWait = "wait";
  let cError = "error";
  //ตั้งค่า Step เริ่มต้น
  let dataStepS1 = bWait;
  let dataStepS2 = abcProcess;
  let dataStepS3 = abcProcess;
  let dataStepS4 = abcProcess;
  let dataStepS5 = abcProcess;
  //ตั้งค่าข้อความ Step เริ่มต้น
  let descriptionS1 = "รอเปิดปุ่ม";
  let descriptionS2 = "";
  let descriptionS3 = "";
  let descriptionS4 = "";
  let descriptionS5 = "";
  //Note เริ่มต้นทั้งหมด
  let noteBoss = "";
  let noteDeRe = "";
  let noteDe = "";
  let sumNote = "";
  if (dataContno) {
    noteBoss = dataContno.noteCarsApprove || [];
    noteDeRe = dataContno.cars.carLoanDetailsRe.notes || [];
    noteDe = dataContno.cars.carLoanDetails.notes || [];
    //รวม Note
    sumNote = [...noteBoss, ...noteDeRe, ...noteDe];
    const checkS1 = dataContno.checkCont.id;
    const checkS2 = dataContno.cars.carLoanDetailsRe.approvalStatus;
    const checkS3 = dataContno.cars.carLoanDetails.approvalStatus;
    const checkS4 = dataContno.cars.carLoanDetails.productLoanType;
    //รอบ2
    const checkAround2 = dataContno.cars.apCarLoanDetails.loanId;
    console.log("checkAround2", checkAround2);
    //
    if (checkS2 === status_001) {
      dataStepS1 = cError;
      dataStepS2 = cError;
      dataStepS3 = cError;
      dataStepS4 = cError;
      dataStepS5 = cError;
      descriptionS1 = status_001;
      descriptionS2 = status_001;
      descriptionS3 = status_001;
      descriptionS4 = status_001;
      descriptionS5 = status_001;
    } else if (checkS3 === status_002) {
      dataStepS1 = cError;
      dataStepS2 = cError;
      dataStepS3 = cError;
      dataStepS4 = cError;
      dataStepS5 = cError;
      descriptionS1 = status_002;
      descriptionS2 = status_002;
      descriptionS3 = status_002;
      descriptionS4 = status_002;
      descriptionS5 = status_002;
    } else if (checkS3 === status_003) {
      dataStepS1 = cError;
      dataStepS2 = cError;
      dataStepS3 = cError;
      dataStepS4 = cError;
      dataStepS5 = cError;
      descriptionS1 = status_003;
      descriptionS2 = status_003;
      descriptionS3 = status_003;
      descriptionS4 = status_003;
      descriptionS5 = status_003;
    } else {
      //S1
      if (checkS1) {
        dataStepS1 = aFinish;
        descriptionS1 = "รับเคสแล้ว";
      }
      //S2
      if (checkS2) {
        switch (checkS2) {
          case "เสนอเคสรีแล้ว":
            dataStepS2 = aFinish;
            descriptionS2 = checkS2;
            break;
          case "ยื่นคำร้อง":
          case "รับคำร้อง":
            dataStepS2 = bWait;
            descriptionS2 = checkS2;
            break;
          case "ปฏิเสธคำร้อง":
            dataStepS2 = cError;
            descriptionS2 = checkS2;
            break;
          default:
        }
      } else if (!checkS2 && checkS1) {
        dataStepS2 = bWait;
        descriptionS2 = "รอยื่นคำร้อง";
      }
      //S3
      if (checkAround2 && checkS3) {
        switch (checkS3) {
          case "อนุมัติ":
          case "อนุมัติแบบมีเงื่อนไข":
          case "รอธุรการรับ":
          case "รอวิเคราะห์":
          case "ไม่ผ่านการวิเคราะห์":
          case "ลูกค้าคอนเฟิร์ม":
          case "รอตรวจสอบเอกสาร":
          case "รอทำสัญญา":
            dataStepS3 = aFinish;
            descriptionS3 = "ผ่านการวิเคราห์";
            break;
          default:
        }
      } else if (checkS2 && !checkS3) {
        dataStepS3 = bWait;
        descriptionS3 = "รอธุระการดำเนินการ";
      } else if (!checkAround2 && checkS3) {
        if (checkS4.substr(0, 3) === "Pre") {
          switch (checkS3) {
            case "รอธุรการรับ":
            case "รอวิเคราะห์":
            case "ผ่านการวิเคราะห์":
            case "รออนุมัติ":
              dataStepS3 = bWait;
              descriptionS3 = checkS3;
              break;
            case "ไม่ผ่านการวิเคราะห์":
            case "Reject":
            case "ไม่อนุมัติ":
              dataStepS3 = cError;
              descriptionS3 = checkS3;
              break;
            default:
          }
        } else {
          dataStepS3 = aFinish;
          descriptionS3 = checkS3;
        }
      }
      //S4
      if (checkS4 && checkAround2) {
        if (checkS4.substr(0, 3) === "Pre") {
          switch (checkS3) {
            case "อนุมัติ":
            case "อนุมัติแบบมีเงื่อนไข":
              dataStepS4 = bWait;
              descriptionS4 = checkS4;
              break;
            case "รอธุรการรับ":
            case "รอวิเคราะห์":
              dataStepS4 = bWait;
              descriptionS4 = "กำลังดำเนินการ";
              break;
            case "ไม่ผ่านการวิเคราะห์":
              dataStepS4 = cError;
              descriptionS4 = checkS3;
              break;
            default:
          }
        } else {
          //console.log("ไม่มี-pre");
          switch (checkS3) {
            case "ไม่ผ่านการวิเคราะห์":
            case "Reject":
              dataStepS4 = cError;
              descriptionS4 = checkS4;
              break;
            case "อนุมัติแบบมีเงื่อนไข":
            case "อนุมัติ":
            case "ลูกค้าคอนเฟิร์ม":
            case "รอตรวจสอบเอกสาร":
            case "รอทำสัญญา":
              dataStepS4 = aFinish;
              descriptionS4 = "อนุมัติ";
              break;
            default:
              dataStepS4 = bWait;
              descriptionS4 = checkS4;
              break;
          }
        }
      }
      //S5
      if (checkS4) {
        if (checkS4.substr(0, 3) === "Pre") {
          //console.log("ss11");
        } else {
          switch (checkS3) {
            case "รอทำสัญญา":
              dataStepS5 = aFinish;
              descriptionS5 = checkS3;
              break;
            case "ลูกค้าปฏิเสธ":
            case "Reject":
              dataStepS5 = cError;
              descriptionS5 = checkS3;
              break;
            case "อนุมัติ":
            case "ลูกค้าคอนเฟิร์ม":
            case "รอตรวจสอบเอกสาร":
              dataStepS5 = bWait;
              descriptionS5 = "รอตรวจสอบเอกสาร";
              break;
            default:
          }
        }
      }
    }
    console.log("checkCont.id//S1", checkS1);
    console.log("carLoanDetailsRe.approvalStatus//S2", checkS2);
    console.log("carLoanDetails.approvalStatus//S3", checkS3);
    console.log("carLoanDetails.productLoanType//S4", checkS4);
  } else {
  }
  useEffect(() => {
    const fetchData = async () => {
      if (currentRecord) {
        //console.log("dataContno", currentRecord.CONTNO);
        try {
          const res = await axios.post(
            detailfollowexpeditethedebt,
            {
              CONTNO: currentRecord.CONTNO,
              carId: currentRecord.carId,
              loanId: currentRecord.loanId,
              reLoanId: currentRecord.reLoanId,
            }
          );
          //console.log("res22", res.data);
          setDataContno(res.data);
        } catch (err) {
          console.log(err.message);
        }
      }
    };
    fetchData();
  }, [currentRecord]);

  return (
    <div>
      <Steps className="custom-steps" style={{ paddingBottom: "20px" }}>
        <Step title="step1" description={descriptionS1} status={dataStepS1} />
        <Step title="step2" description={descriptionS2} status={dataStepS2} />
        <Step title="step3" description={descriptionS3} status={dataStepS3} />
        <Step title="step4" description={descriptionS4} status={dataStepS4} />
        <Step title="step5" description={descriptionS5} status={dataStepS5} />
      </Steps>
      <center>เลขสัญญา: {currentRecord.CONTNO}</center>
      {/* <p>วันที่ส่งเคส: {currentRecord.sendPgDate}</p> */}
      <div style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
        {sumNote && sumNote.length > 0 ? (
          sumNote
            .sort((a, b) => new Date(b.noteDate) - new Date(a.noteDate)) // เรียงลำดับตาม noteDate จากมากไปหาน้อย
            .map((item, index) => (
              <Card key={index} style={{ width: 300, marginBottom: 10 }}>
                <p style={{ color: "green" }}>หมายเหตุจาก: {item.noteBy}</p>
                <p>สาเหตุ: {item.cause}</p>
                <p>หมายเหตุ: {item.note}</p>
                <p>
                  เวลา: {Dayjs(item.noteDate).format("DD/MM/YYYY HH:mm:ss")}
                </p>
              </Card>
            ))
        ) : (
          <p></p>
        )}
      </div>
    </div>
  );
};

export default DataModal;
