import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    //fileList: []
}

const imageDataSlice = createSlice ({
    name: "images",
    initialState,
    reducers: {
        addImage: (state, action) => {
            //state.fileList = action.payload
            state.data = action.payload
        }
    }
    
})



export const { addImage } = imageDataSlice.actions
export default imageDataSlice.reducer
