import React, { useState, useEffect } from 'react'
import { Button, Form, Input, Select, Divider, Row, Col, Modal, Checkbox } from 'antd';
import { Option } from 'antd/es/mentions';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { getdistrict, getsubdistrict, getzipcodes, loadProvinces, updateaddress,getprovinceall, getdistrictall, getsubdistrictsall } from '../../file_mid/all_api';


function AddressEditData({ open, close, datatoedit, editsensdata, dataindex, idaddress }) {
    //dispatch
    const dispatch = useDispatch();
    //useForm
    const [formeditdata] = Form.useForm();
    //state
    const [editdata, setEditData] = useState(datatoedit.dataedit);
    const [editindex, setEditIndex] = useState(dataindex);
    const [pv] = useState(editdata.province);
    const [dt] = useState(editdata.district);
    const [sdt] = useState(editdata.subdistrict);
    const [provinces, setProvinces] = useState([]);
    const [district, setDistricts] = useState([]);
    const [subdistricts, setSubdistricts] = useState([]);
    const [zipcode, setZipCodes] = useState([]);

    //useEffect เพื่อ refresh data
    useEffect(() => {
        setEditIndex(dataindex)
        setProvinces(provinces)
        // selectdistrict()
        if (editdata !== undefined) {
            formeditdata.setFieldsValue({
                ...editdata
            })
        }
        fetchProvinceByName()
        fetchDistricteByName()
        fetchSubDistricteByName()
        fetchAllProvinces()

    }, []);
    const fetchAllProvinces = async () => {
        await axios.get(loadProvinces)
            .then(response => {
                setProvinces(response.data);
            }).catch(error => {
                console.error(error);
            });
    }
    const fetchProvinceByName = async () => {
        await axios.get(getprovinceall + pv)
            .then(response => {
                fetchDistrictFromProvinceId({ provinceId: response.data.provinceId })
            }).catch(error => {
                console.error(error);
            });
    }

    const fetchDistrictFromProvinceId = async ({ provinceId }) => {
        console.log(provinceId)
        axios.get(getdistrict + provinceId)
            .then(response => {
                console.log("DISTRICT", response.data)
                setDistricts(response.data);
            }).catch(error => {
                console.error(error);
            });
    }
    const fetchDistricteByName = async () => {
        await axios.get(getdistrictall + dt)
            .then(response => {
                fetchSubDistrictFromDistrictId({ districtId: response.data.districtId })
            }).catch(error => {
                console.error(error);
            });
    }
    const fetchSubDistrictFromDistrictId = async ({ districtId }) => {
        console.log(districtId)
        axios.get(getsubdistrict + districtId)
            .then(response => {
                setSubdistricts(response.data);
                console.log("SUBDISTRICT", response.data)
            }).catch(error => {
                console.error(error);
            });
    }
    const fetchSubDistricteByName = async () => {
        await axios.get(getsubdistrictsall + sdt)
            .then(response => {
                fetchZipcodeFromSubdistrictId({ subdistrictId: response.data.subdistrictId })
                console.log("zipcode", response.data)
            }).catch(error => {
                console.error(error);
            });
    }

    const fetchZipcodeFromSubdistrictId = async ({ subdistrictId }) => {
        console.log(subdistrictId)
        await axios.get(getzipcodes + subdistrictId)
            .then(response => {
                console.log("aaa", response.data)
                setZipCodes(response.data);
                // fetchZipcode({ zipcode: response.data.zipcode })
            }).catch(error => {
                console.error(error);
            });
    }


    const handleProvinceChange = (value, element) => {
        formeditdata.setFieldsValue({
            district: '',
            subdistrict: '',
            zipCode: '',
        })
        setEditData({ ...editdata, province: value, });
        axios.get(getdistrict + element.key)
            .then(response => {
                console.log(response.data)
                setDistricts(response.data);
            }).catch(error => {
                console.error(error);
            });
    };

    const handleDistrictChange = (value, key) => {
        formeditdata.setFieldsValue({
            subdistrict: '',
            zipCode: '',
        })
        setEditData({ ...editdata, district: value });
        axios.get(getsubdistrict + key.key)
            .then(response => {
                setSubdistricts(response.data);
                setZipCodes(response.data);
            }).catch(error => {
                console.error(error);
            });
    };
    const handleSubdistrictChange = (value, key) => {
        console.log("asdas", key);
        formeditdata.setFieldsValue({
            zipCode: '',
        })
        setEditData({ ...editdata, subdistrict: value });
        axios.get(getzipcodes + key.key)
            .then(response => {
                console.log("zipcodes", response.data);
                // setZipCodes(response.data);
            }).catch(error => {
                console.error(error);
            });
    };

    const handleZipcodeChange = (value) => {
        setEditData({ ...editdata, zipCode: value });
    };

    //elements
    const handleCancel = () => { //กดปิด Modal
        close(false);
    };

    //handleSubmit
    const handleSubmit = async () => {
        console.log("00", editdata)
        await axios.put(updateaddress, editdata)
            .then((res) => {
                console.log('อัพเดตที่อยู่', res.data)
                setEditData({ ...res.data })
                editsensdata({ editdata, editindex }) // ส่งข้อมูลไปหน้า Address
            })
            .catch((err) => console.log(err))
        close(false);
    }

    // data input Form
    const handleInputDataAddress = (e) => { //กรอกข้อมูล
        const { name, value } = e.target;
        setEditData({
            ...editdata, [name]: value,
        }) // เป็นการกระจายข้อมูลเข้าไปตามชื่อ name 
    }

    return (
        <>
            {/* Modal แก้ไข้ */}
            <Modal
                open={open}
                onCancel={handleCancel}
                style={{ background: '#2f54eb' }}
                centered
                width={600}
                footer={
                    [
                        <Row
                            justify={'center'}
                        >
                            <Button key="back"
                                onClick={handleCancel}
                            >
                                ยกเลิก
                            </Button>
                            <Button
                                type="primary"
                                onClick={handleSubmit}
                            >
                                บันทึก
                            </Button>
                        </Row>
                    ]}
            >
                <Form
                    form={formeditdata}
                    onFinish={handleSubmit}
                    name='editdata'
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    style={{
                        maxWidth: '100%',
                    }}
                >
                    <Row justify={'center'}>
                        <h3 style={{ marginBottom: 0 }}>
                            <u>แก้ไขที่อยู่</u>
                        </h3>
                    </Row>
                    <Row
                        gutter={{ sx: 8, sm: 16, md: 24, lg: 32 }}
                    >
                        <Col className='gutter-row' span={24}>
                            <Form.Item
                                name='houseRegistrationNumber'
                                label="เลขทะเบียนบ้าน"
                            >
                                <Input
                                    name='houseRegistrationNumber'
                                    placeholder='เลขทะเบียนบ้าน'
                                    onChange={handleInputDataAddress}
                                />
                            </Form.Item>
                            <Form.Item
                                name='houseNumber'
                                label='บ้านเลขที่'
                            >
                                <Input
                                    name='houseNumber'
                                    placeholder="บ้านเลขที่"
                                    onChange={handleInputDataAddress}
                                />
                            </Form.Item>
                            <Form.Item
                                name='soi'
                                label='ซอย'
                            >
                                <Input
                                    name='soi'
                                    placeholder='ซอย'
                                    onChange={handleInputDataAddress}
                                />
                            </Form.Item>

                            <Form.Item
                                name='road'
                                label='ถนน'
                            >
                                <Input
                                    name='road'
                                    placeholder='ถนน'
                                    onChange={handleInputDataAddress}
                                />
                            </Form.Item>
                            <Form.Item name='village' label='หมู่บ้าน'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input !',
                                    },]}>
                                <Input
                                    name='village'
                                    placeholder="หมู่บ้าน"
                                    onChange={handleInputDataAddress}
                                />
                            </Form.Item>
                            <Form.Item
                                name='province'
                                label='จังหวัด'
                            >
                                <Select
                                    showSearch
                                    name='province'
                                    placeholder='จังหวัด'
                                    onChange={(key, value) => { handleProvinceChange(key, value) }}
                                >
                                    {provinces.map((pro, index) => (
                                        <Option key={pro.provinceId} value={pro.provinceName}>
                                            {pro.provinceName}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name='district'
                                label='อำเภอ'
                            >
                                <Select
                                    showSearch
                                    name='district'
                                    placeholder="อำเภอ"
                                    onChange={(key, value) => { handleDistrictChange(key, value) }}
                                >
                                    {district.map((dis, index) => (
                                        <Option key={dis.districtId} value={dis.districtName}>
                                            {dis.districtName}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name='subdistrict'
                                label='ตำบล'
                            >
                                <Select
                                    showSearch
                                    name='subdistrict'
                                    placeholder='ตำบล'
                                    onChange={(key, value) => { handleSubdistrictChange(key, value) }}
                                >
                                    {subdistricts.map((subdis, index) => (
                                        <Option key={subdis.subdistrictId} value={subdis.subdistrictName}>
                                            {subdis.subdistrictName}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name='zipCode'
                                label='รหัสไปรษณีย์'
                            >
                                {/* {
                                    !zipcode ?
                                        <> */}
                                <Select
                                    name='zipCode'
                                    placeholder="รหัสไปรษณีย์"
                                    onChange={(key, value) => { handleZipcodeChange(key, value) }}
                                >
                                    {zipcode.map((zip, index) => {

                                        <Option key={zip.zipcodeId} value={zip.zipCode}>
                                            {zip.zipCode}
                                        </Option>
                                    })}

                                </Select>
                                {/* </>
                                        :
                                        <>
                                            <Input
                                                placeholder='กรอกรหัสไปรษณีย์'
                                                name='zipCode'
                                                onChange={handleInputDataAddress}
                                            />
                                        </>
                                } */}
                            </Form.Item>
                            <Divider></Divider>
                            <Row
                                gutter={32}
                                justify={'center'}
                            >
                                <Checkbox
                                    key={0}
                                    checked={editdata.addressStatus === true}
                                    name='addressStatus'
                                    onChange={(e) => {
                                        setEditData({ ...editdata, addressStatus: e.target.checked ? true : false });
                                    }}
                                >
                                    "ใช้งานอยู่หรอไม่"
                                </Checkbox>
                                <Checkbox
                                    key={1}
                                    checked={editdata.addressOnIdcard === true}
                                    name='addressOnIdcard'
                                    onChange={(e) => {
                                        setEditData({ ...editdata, addressOnIdcard: e.target.checked ? true : false });
                                    }}
                                >
                                    "ตามบัตรประชาชน"
                                </Checkbox>
                                <Checkbox
                                    key={2}
                                    checked={editdata.houseRegistration === true}
                                    name='houseRegistration'
                                    onChange={(e) => {
                                        setEditData({ ...editdata, houseRegistration: e.target.checked ? true : false });
                                    }}
                                >
                                    "ตามทะเบียนบ้าน"
                                </Checkbox>
                                <Checkbox
                                    key={3}
                                    checked={editdata.workAddress === true}
                                    name='workAddress'
                                    onChange={(e) => {
                                        setEditData({ ...editdata, workAddress: e.target.checked ? true : false });
                                    }}
                                >
                                    "ตามสถานที่ทำงาน"
                                </Checkbox>
                                <Checkbox
                                    key={4}
                                    checked={editdata.otherAddress === true}
                                    name='otherAddress'
                                    onChange={(e) => {
                                        setEditData({ ...editdata, otherAddress: e.target.checked ? true : false });
                                    }}
                                >
                                    "อื่นๆ"
                                </Checkbox>
                                <Checkbox
                                    key={5}
                                    checked={editdata.currentAddress === true}
                                    name='currentAddress'
                                    onChange={(e) => {
                                        setEditData({ ...editdata, currentAddress: e.target.checked ? true : false });
                                    }}
                                >
                                    "ที่อยู่ปัจจุบัน"
                                </Checkbox>
                                <Checkbox
                                    key={6}
                                    checked={editdata.sendDocuments === true}
                                    name='sendDocuments'
                                    onChange={(e) => {
                                        setEditData({ ...editdata, sendDocuments: e.target.checked ? true : false });
                                    }}
                                >
                                    "ที่อยู่ส่งเอกสาร"
                                </Checkbox>
                            </Row>
                            <Divider></Divider>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    )

};

export default AddressEditData
