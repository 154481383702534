import React, { useState } from 'react'
import { Modal, Col, Row, Space, Steps } from 'antd';
// import { useSelector } from 'react-redux';
import AddAddressGua from './AddAddressGua';
import AddCustomerGua from './AddCustomerGua';
import AddPhoneGua from './AddPhoneGua';
import AddDetailGua from './AddDetailGua';

export default function MainAddGuaDoc({ open, close, getData, indexedit, dataloadGua, saveCarId, setSendback, dataFromTable, sb, guarantors }) {

    // const [api, contextHolder] = notification.useNotification();
    const [count, setCount] = useState(1)
    const [current, setCurrent] = useState(0);
    const { confirm } = Modal

    const [dataCustomer, setDataCustomer] = useState();
    const [dataAddress, setDataAddress] = useState();
    const [dataPhone, setDataPhone] = useState();

    // console.log("dataCustomer", dataCustomer)
    // console.log("dataAddress", dataAddress)
    // console.log("dataPhone", dataPhone)

    const next = () => {
        setCurrent(current + 1);
        setCount(count + 1);
    };
    const prev = () => {
        setCurrent(current - 1);
        setCount(count - 1);
    };
    const steps = [
        {
            title: 'ข้อมูลส่วนตัว',
        },
        {
            title: 'รายละเอียดที่อยู่',
        },
        {
            title: 'ช่องทางการติดต่อ',
        },
        {
            title: 'สรุปรายละเอียด/บันทึกข้อมูล',
        },
    ];

    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
    }));

    const showConfirm = () => {
        confirm({
            title: 'คุณต้องการที่ปิดหน้าต่างข้อมูลผู้กู้หรือไม่...?',
            content: 'หากกดปิดหน้าต่างผู้กู้ข้อมูลจะไม่ถูกบันทึก กด OK เพื่อยืนยัน',
            onOk() {
                Onclose();
            },
            onCancel() {
            },
        });
    };

    const Onclose = () => {
        close(false);
        // dispatch(addCustomer(saveCusIdMain))
        // dispatch(addCar(saveCarId))
        // dispatch(addAddress())
        // dispatch(addPhone())
    };

    return (
        <>
            <Modal title="เพิ่มข้อมูลผู้ค้ำ" open={open} onCancel={showConfirm} width={1200} footer={null} >
                <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                    <Row>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <Steps current={current} items={items} />
                        </Col>
                    </Row>
                    {count === 1 && (<AddCustomerGua
                        close={showConfirm}
                        next={next}
                        getData={getData}
                        indexedit={indexedit}
                        dataCustomer={dataCustomer}
                        setDataCustomer={setDataCustomer}
                        setDataAddress={setDataAddress}
                        oData={guarantors}
                        setDataPhone={setDataPhone} />)}
                    {count === 2 && (<AddAddressGua close={showConfirm} next={next} prev={prev} dataAddress={dataAddress} setDataAddress={setDataAddress} dataCustomer={dataCustomer} />)}
                    {count === 3 && (<AddPhoneGua close={showConfirm} next={next} prev={prev} dataPhone={dataPhone} setDataPhone={setDataPhone} dataCustomer={dataCustomer} />)}
                    {count === 4 && (<AddDetailGua
                        close={showConfirm}
                        saveclose={Onclose}
                        prev={prev}
                        carId={saveCarId}
                        dataloadGua={dataloadGua}
                        dataPhone={dataPhone}
                        dataAddress={dataAddress}
                        dataCustomer={dataCustomer}
                        indexedit={indexedit}
                        setSendback={sb}
                        dataFromTable={dataFromTable} />)}
                </Space>
            </Modal>
        </>
    )
};
