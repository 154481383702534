import * as React from 'react';
import { useState, useEffect } from 'react';
import { Button, Form, Divider, Row, Col, Card, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import AddressEditData from '../editData/AddressEditData';
import ModalAddDataAddress from '../postData/ModalAddDataAddress';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function Address() {

    //dispatch 
    const dispatch = useDispatch(); // เป็นการโยนค่าเข้าไปไว้ใน store ()
    const [messageApi, contextHolder] = message.useMessage();

    //navigate
    const navigate = useNavigate()
    const { customers } = useSelector(state => (state)) // เป็นการเรียกใช้ข้อมูลใน Store โดยเก็บตามชื่อ reducer ที่ตั้ง
    const id = customers.data.customerId

    //useForm
    const [formaddress] = Form.useForm();

    //state
    const [dataedit, setDataEdit] = useState([]); //เซตสำหรับแก้ไขข้อมูล 
    const [index1, setIndex] = useState(); //set index send to page edit
    const [dataPost, setDataPost] = useState(); //มาจากรีดัค
    const [saveAddress, setSaveAddress] = useState(); // เก็บ address
    const [checkaddress, setCheckAddress] = useState(); // check 0

    //checkOpen Button
    const [modaleditdata, setModalEditData] = useState(false);
    const [modaladddata, setModalAddData] = useState(false);
    //useEffect
    useEffect(() => {

        setDataPost(customers.data)
        loadcusId()
    }, [modaladddata, dataPost])

    // //elements
    const loadcusId = async (e) => {
        // console.log("asxfz", dataPost);
        await axios.get(`https://garfe-i67f6gaaqa-as.a.run.app/api/checkonecusadd/${id}`)
            .then((res) => {
                // console.log("as", res.data);
                if (res.data.status.length !== 0) {
                    messageApi.open({
                        type: 'success',
                        content: 'พบที่อยู่',
                    });
                    setSaveAddress(res.data.status);
                    setCheckAddress("1")
                }
                if (res.data.status.length === 0) {
                    messageApi.open({
                        type: 'error',
                        content: 'ไม่พบที่อยู่',
                    });
                    setCheckAddress("0")
                    setSaveAddress([{ customerId: id }]);
                }
            })
            .catch((err) => {
                if (err.response.status === 422) {
                    messageApi.open({
                        type: 'error',
                        content: 'เกิดข้อผิดพลาด',
                    })
                }
                console.log("err", err)
            })
    }
    //buuton Previos
    const handlePrevios = (e) => {
        navigate('/addcustomer')
    }

    const onChangeGo = () => {
        if (checkaddress === "0" || checkaddress === undefined) {
            alert('ต้องมีที่อยู่อย่างน้อย 1 ที่อยู่')
        } else {
            navigate('/addcustomer/contract')
        }
    }

    //ดึงข้อมูลมากจาก Modal addData
    const adddata1 = (value) => {
        console.log('adddata1', value.addressadd)
        if (checkaddress === "0") {
            const AddData = value.addressadd    //ประกาศตัวแปรเพื่อเก็บค่าที่เพิ่มเข้ามาไปเซตให้ dataArray
            setSaveAddress([AddData]);
        } else if (checkaddress === "1") {
            const AddData2 = value.addressadd   //ประกาศตัวแปรเพื่อเก็บค่าที่เพิ่มเข้ามาไปเซตให้ dataArray
            setSaveAddress(pre => [...pre, { ...AddData2 }]);//เก็บค่า state เดิมไว้ พร้อมส่ง type ไปด้วย เพื่อบอกค่าที่ส่ง
        }
    }

    const editnewdata = ({ editdata, editindex }) => {// แก้ไขข้อมูลจากหน้า AddressEditData
        let newArr = [...saveAddress]; // copying the old datas array
        newArr[editindex] = editdata; // replace e.target.value with whatever you want to change it to
        setSaveAddress(newArr);
    }

    const AddressCard = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
        const handleEdit = (dataedit, index1) => {// index ตอนนี้เป็นข้อมูลจาก Object
            setIndex(index1) //เซตค่า index ให้กับชุด ข้อมูล
            setDataEdit({ dataedit, index1, }) //ส่งข้อมูลด index ไปหน้าแก้ไข
            setModalEditData(true);
        }

        //ลบที่อยู่ตาม index
        const handleDelete = async (item, index) => {
            // console.log("ข้อมูล", index, item)
            const deletearray = [...saveAddress] // กระจายข้อมูลเข้าไปในตัวแปร deletearray 
            const id = deletearray[index].addressId
            if (window.confirm('คุณต้องการลบหรือไม่')) {
                await axios.delete(`https://eua-i67f6gaaqa-as.a.run.app/Api/deleteaddress/${id}`, deletearray)
                    .then((res) => {
                        deletearray.splice(index, 1); // เป็นการลบข้อมูลใน Array ตาม index ที่เลือกตาม Card
                        setSaveAddress(deletearray);  // เซตค่าเข้าไปเก็บใน dataArray
                        console.log('ลบที่อยู่สำเร็จ')
                    })
                    .catch((err) => console.log(err))
            } else {
                console.log('ยกเลิกการลบข้อมูล')
            }
        };
        const styletext = {
            textAlign: 'right',
            marginBottom: 0
        };

        return (
            <Row
                gutter={32}
                justify={'center'}
            >
                <Col span={24} className='gutter-row' style={{ margin: 5 }}>
                    <Form form={formaddress}>

                        <Card
                            key={index} // index เป็นตัวกำหนดคีย์
                            title={(item.addressStatus === 1 || item.addressStatus === true) ? <u>ใช้งานอยู่</u> : <u>ไม่ได้ใช้งาน</u>}
                            style={{ width: '330px', textAlign: 'center' }}
                        >
                            <Row gutter={32}>
                                <Col span={10} className='gutter-row' style={{ textAlign: 'right' }}>
                                    <Form.Item style={styletext}>เลขทะเบียนบ้าน :</Form.Item>
                                    <Form.Item style={styletext}>บ้านเลขที่ :</Form.Item>
                                    <Form.Item style={styletext}>ซอย :</Form.Item>
                                    <Form.Item style={styletext}>ถนน :</Form.Item>
                                    <Form.Item style={styletext}>หมู่บ้าน :</Form.Item>
                                    <Form.Item style={styletext}>ตำบล :</Form.Item>
                                    <Form.Item style={styletext}>อำเภอ :</Form.Item>
                                    <Form.Item style={styletext}>จังหวัด :</Form.Item>
                                    <Form.Item style={styletext}>รหัสไปรษณีย์ :</Form.Item>
                                </Col>
                                <Col span={12} className='gutter-row'>
                                    <Form.Item style={{ marginBottom: 0 }}><b>{item.houseRegistrationNumber}</b></Form.Item>
                                    <Form.Item style={{ marginBottom: 0 }}><b>{item.houseNumber}</b></Form.Item>
                                    <Form.Item style={{ marginBottom: 0 }}><b>{item.soi}</b></Form.Item>
                                    <Form.Item style={{ marginBottom: 0 }}><b>{item.road}</b></Form.Item>
                                    <Form.Item style={{ marginBottom: 0 }}><b>{item.village}</b></Form.Item>
                                    <Form.Item style={{ marginBottom: 0 }}><b>{item.subdistrict}</b></Form.Item>
                                    <Form.Item style={{ marginBottom: 0 }}><b>{item.district}</b></Form.Item>
                                    <Form.Item style={{ marginBottom: 0 }}><b>{item.province}</b></Form.Item>
                                    <Form.Item style={{ marginBottom: 0 }}><b>{item.zipCode}</b></Form.Item>

                                </Col>
                            </Row>
                            <Divider style={{ margin: '10px' }} />
                            <Row gutter={32} justify={'center'}>
                                <Col span={24} className='gutter-row'>
                                    <Form.Item
                                        style={{ marginBottom: 0, textAlign: 'center' }}
                                        label='เป็นที่อยู่'
                                    >
                                        <b>
                                            {(item.addressOnIdcard === 1 || item.addressOnIdcard === true) && "ตามบัตรประชาชน "}
                                            {(item.houseRegistration === 1 || item.houseRegistration === true) && "ตามทะเบียนบ้าน "}
                                            {(item.workAddress === 1 || item.workAddress === true) && "ตามสถานที่ทำงาน "}
                                            {(item.otherAddress === 1 || item.otherAddress === true) && "อื่นๆ  "}
                                            {(item.currentAddress === 1 || item.currentAddress === true) && "ที่อยู่ปัจจุบัน  "}
                                            {(item.sendDocuments === 1 || item.sendDocuments === true) && "ที่อยู่ส่งเอกสาร  "}

                                        </b>
                                    </Form.Item>
                                </Col>

                            </Row>

                            <Divider style={{ margin: '5px' }} />
                            <Row
                                justify={'center'}
                                gutter={32}
                            >
                                {/* <Form.Item>
                                <Button
                                    style={{ margin: '10px', background: '#f5222d', color: 'white', marginBottom: 0 }}
                                    icon={<DeleteOutlined />}
                                    onClick={() => { handleDelete(saveAddress[index], index); }}
                                >
                                    ลบ
                                </Button>
                            </Form.Item> */}
                                <Form.Item>
                                    <Button
                                        style={{ margin: '10px', background: '#bfbfbf', color: '#000000', marginBottom: 0 }}
                                        type='submit'
                                        onClick={() => { handleEdit(saveAddress[index], index); }}
                                    >
                                        แก้ไข
                                    </Button>
                                </Form.Item>
                            </Row>
                        </Card>
                    </Form>
                </Col>
            </Row>
        )
    }
    return (
        <>
            <Row justify={'center'}>
                <Card style={{ width: '100%' }}>
                    <Form
                        name='add'
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                        style={{
                            maxWidth: '100%',
                        }}
                        autoComplete="off"
                        initialValues={{ remember: true }}
                    >
                        <Row gutter={32}>
                            <Col className='gutter-row' span={24}>

                                <Row gutter={32}>
                                    <Divider style={{ width: '100%', height: '100%', marginBottom: 0 }}><b><h2>ที่อยู่</h2></b></Divider>
                                    <Col className='gutter-row' span={24}>
                                        <Row justify={'center'}>
                                            {/* ปุ่มเพิ่มข้อมูล */}
                                            <Button
                                                type='primary'
                                                style={{ marginBottom: '10px' }}
                                                onClick={() => { setModalAddData(true); }}>
                                                เพิ่มข้อมูลที่อยู่
                                            </Button>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row justify={'center'}>
                                    {
                                        saveAddress === undefined ?
                                            setTimeout(() => {
                                            }, 1000)
                                            : saveAddress !== "" ?

                                                saveAddress.map((item, index) => {
                                                    if (checkaddress !== "0") {
                                                        return AddressCard({ item, index })
                                                        setCheckAddress("1")
                                                    }
                                                })
                                                : null
                                    }
                                </Row>

                                {/* Modal Add Data and Modal Edit Data */}
                                {
                                    //เพื่มข้อมูลในการ์ด
                                    modaladddata ?
                                        <ModalAddDataAddress
                                            open={modaladddata}
                                            close={setModalAddData}
                                            checkaddress={checkaddress}
                                            dataaddress={saveAddress}
                                            setcheckaddress={setCheckAddress}
                                            adddata={adddata1} // ดึงข้อมูลจากมาจากหน้า ModalAddcusId
                                        />
                                        : null
                                }
                                {
                                    //แก้ไขข้อมูลในการ์ด 
                                    modaleditdata ?
                                        <AddressEditData
                                            open={modaleditdata} // เซตการเปิด Modal จากหน้า AddressEditData
                                            close={setModalEditData} // เซตการปิด Modal จากหน้า AddressEditData
                                            datatoedit={dataedit} // ส่งค่าไปยังหน้า AddressEditData เพื่อแสดงข้อมูลจากการ์ด
                                            dataindex={index1} //ส่ง index  ไปหน้า AddressEditData
                                            // idaddress={id} //ส่ง index  ไปหน้า AddressEditData
                                            editsensdata={editnewdata} // โยนค่าข้ามาจากหน้า  AddressEditData
                                        // check={checked}
                                        />
                                        : null
                                }
                            </Col>
                        </Row>
                    </Form>
                    <Divider />
                    <Row
                        gutter={32}
                        justify={'center'}
                    >
                        <Col className='gutter-row' span={12} style={{ textAlign: 'left' }}>
                            <Button
                                style={{ margin: '3px', marginBottom: 0, background: '#bfbfbf', color: '#000000', }}
                                type='primary'
                                onClick={handlePrevios}
                            >
                                ย้อนกลับ
                            </Button>
                        </Col>
                        <Col className='gutter-row' span={12} style={{ textAlign: 'right' }}>

                            <Button
                                style={{ margin: '3px', marginBottom: 0 }}
                                type='primary'
                                onClick={onChangeGo}
                            >
                                ต่อไป
                            </Button>
                        </Col>
                    </Row>
                </Card>
            </Row>
            {contextHolder}
        </>
    )
};
export default Address;