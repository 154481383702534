//function dotaColums() {
const arrCase = [];
arrCase[0] = "เคาะราคาแล้ว"
arrCase[1] = "รอธุรการรับ"
arrCase[2] = "รอวิเคราะห์"
arrCase[3] = "ผ่านการวิเคราะห์"
arrCase[4] = "ไม่ผ่านการวิเคราะห์"
arrCase[5] = "รออนุมัติ"
arrCase[6] = "อนุมัติ"
arrCase[7] = "ไม่อนุมัติ"
arrCase[8] = "ลูกค้าคอนเฟิร์ม"
arrCase[9] = "ลูกค้าปฏิเสธ"
arrCase[10] = "รอตรวจสอบเอกสาร"
arrCase[11] = "รอทำสัญญา"
arrCase[12] = "Reject"

arrCase[13] = "รอพี่หนุ่มรับ"
arrCase[14] = "รอเคาะราคา"
arrCase[15] = "ขอเอกสารเพิ่ม"

arrCase[16] = "ปัดตก";


export default arrCase;
