import React, { useEffect, useState } from 'react'
import { Modal, Col, Row, Space, notification, Steps, theme } from 'antd';
import axios from 'axios';
// import dayjs from 'dayjs';

import { useDispatch, useSelector } from 'react-redux';
import { addCustomer } from '../../../../../redux/Customer';
import { addCar } from '../../../../../redux/Car';
import { addAddress } from '../../../../../redux/Address';
import { addPhone } from '../../../../../redux/Phone';

import GECustomer from './GECustomer';
import GEAddress from './GEAddress';
import GEPhone from './GEPhone';
import GEDetail from './GEDetail';
import { getguarantorsbyiden } from '../../../../file_mid/all_api';

export default function ModalEditGuarantor({ open, close, dataindex, shootdata, fucnEdit }) {

    const customers = useSelector(state => state.customers)
    const cars = useSelector(state => state.cars)
    const guarantor = useSelector(state => state.guarantor)

    const [theData, setTheData] = useState([]);
    const [saveCusIdMain] = useState(customers.data);
    const [saveCarId] = useState(cars.data);



    const [api, contextHolder] = notification.useNotification();
    const dispatch = useDispatch()
    const { token } = theme.useToken();
    const [current, setCurrent] = useState(0);
    const { confirm } = Modal

    console.log("shootdata", shootdata.dataedit)

    useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {
        await axios.post(getguarantorsbyiden, { ...shootdata.dataedit, ...guarantor.data })
            .then((res) => {
                if (res.status === 200) {
                    console.log("loadData", res.data)
                    setTheData(res.data)
                    if (res.data.customerId === 0) {
                        setTheData(shootdata.dataedit)
                    }
                    // setDataAddress(res.data.dataAddress)
                    // setDataPhone(res.data.dataPhones)
                }
                else {
                }
            }).catch((err) => console.log(err))
    };


    const next = () => {
        setCurrent(current + 1);
    };
    const prev = () => {
        setCurrent(current - 1);
    };
    const steps = [
        {
            title: 'ข้อมูลส่วนตัว',
        },
        {
            title: 'รายละเอียดที่อยู่',
        },
        {
            title: 'ช่องทางการติดต่อ',
        },
        {
            title: 'สรุปรายละเอียด/บันทึกข้อมูล',
        },
    ];

    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
    }));

    const InsertSuccess = (placement) => {
        api.success({
            message: <b>เพิ่มข้อมูลสำเร็จ</b>,
            placement,
        });
    }
    const EditSuccess = (placement) => {
        api.success({
            message: <b>แก้ไขข้อมูลสำเร็จ</b>,
            placement,
        });
    }
    const DeleteSuccess = (placement) => {
        api.success({
            message: <b>ลบข้อมูลสำเร็จ</b>,
            placement,
        });
    }
    // const InsertError = (placement) => {
    //     api.error({
    //         message: <b>เพิ่มข้อมูลไม่สำเร็จ</b>,
    //         placement,
    //     });
    // }
    // const EditError = (placement) => {
    //     api.error({
    //         message: <b>แก้ไขข้อมูลไม่สำเร็จ</b>,
    //         placement,
    //     });
    // }
    // const DeleteError = (placement) => {
    //     api.error({
    //         message: <b>ลบข้อมูลไม่สำเร็จ</b>,
    //         placement,
    //     });
    // }
    const CheckAddressER = (placement) => {
        api.error({
            message: <b>เพิ่มข้อมูลไม่สำเร็จ</b>,
            description: 'กรุณาเพิ่มที่อยู่ อย่างน้อย 1 ที่',
            placement,
        });
    }
    const CheckPhoneER = (placement) => {
        api.error({
            message: <b>เพิ่มข้อมูลไม่สำเร็จ</b>,
            description: 'กรุณาเพิ่มเบอร์โทรศัพท์ อย่างน้อย 1 เบอร์',
            placement,
        });
    }

    const showConfirm = () => {
        confirm({
            title: 'คุณต้องการที่ปิดหน้าต่างข้อมูลผู้กู้หรือไม่...?',
            content: 'หากกดปิดหน้าต่างผู้กู้ข้อมูลจะไม่ถูกบันทึก กด OK เพื่อยืนยัน',
            onOk() {
                Onclose();
            },
            onCancel() {
            },
        });
    };

    const onChange = (value) => {
        // console.log('onChange:', value);
        setCurrent(value);
    };

    const Onclose = () => {
        close(false);
        // console.log("cusIdMain ModalEditGuarantor",cusIdMain)
        dispatch(addCustomer(saveCusIdMain))
        dispatch(addCar(saveCarId))
        dispatch(addAddress())
        dispatch(addPhone())
    };

    return (
        <Modal title="แก้ไขข้อมูล" open={open} onCancel={showConfirm} width={1200} footer={null} >
            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                <Row>
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <Steps
                            current={current}
                            onChange={onChange}
                            items={[
                                {
                                    title: 'ข้อมูลส่วนตัว',
                                },
                                {
                                    title: 'รายละเอียดที่อยู่',
                                },
                                {
                                    title: 'ช่องทางการติดต่อ',
                                },
                                {
                                    title: 'สรุปรายละเอียด/บันทึกข้อมูล',
                                },
                            ]}
                        />
                    </Col>
                </Row>
                {current === 0 && (<GECustomer close={showConfirm} theData={theData} next={next} />)}
                {current === 1 && (<GEAddress close={showConfirm} theData={theData} n1={InsertSuccess} e1={EditSuccess} d1={DeleteSuccess} cha={CheckAddressER} next={next} prev={prev} />)}
                {current === 2 && (<GEPhone close={showConfirm} theData={theData} n1={InsertSuccess} e1={EditSuccess} d1={DeleteSuccess} next={next} prev={prev} chp={CheckPhoneER} />)}
                {current === 3 && (<GEDetail close={showConfirm} theData={theData} n1={InsertSuccess} e1={EditSuccess} d1={DeleteSuccess} prev={prev} fucnEdit={fucnEdit} saveclose={Onclose} dataindex={dataindex} cha={CheckAddressER} chp={CheckPhoneER} />)}
            </Space>
            {contextHolder}
        </Modal>

    )
};
