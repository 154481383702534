import React, { useState, useEffect } from 'react'
import { Button, Form, Input, Select, Divider, Row, Col, Modal, Checkbox, Space, Spin } from 'antd';
import axios from 'axios';

import { Option } from 'antd/es/mentions';
import { useSelector } from 'react-redux';
import { getdistrict, getsubdistrict, getzipcodes, loadProvinces } from '../../../../../../file_mid/all_api';

function AddAddress({ open, close, fucnAdd }) {

    const customers = useSelector(state => state.customers)
    const [form] = Form.useForm();
    const [provinces, setProvinces] = useState([]);
    const [district, setDistricts] = useState([]);
    const [subdistricts, setSubdistricts] = useState([]);
    const [zipcode, setZipCodes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [chechButton, setChechButton] = useState(false);

    const [address, setAddress] = useState({
        customerId: 0,
        addressId: 0,
        houseRegistrationNumber: '',
        houseNumber: '',
        soi: '',
        road: '',
        village: '',
        subdistrct: '',
        district: '',
        province: '',
        addressOnIdcard: false,
        houseRegistration: false,
        workAddress: false,
        otherAddress: false,
        currentAddress: false,
        sendDocuments: false,
        addressStatus: true,
    });

    useEffect(() => {
        if (customers.data.customerId !== undefined) {
            setAddress({
                customerId: customers.data.customerId,
                addressId: 0,
                houseRegistrationNumber: '',
                houseNumber: '',
                soi: '',
                road: '',
                village: '',
                subdistrct: '',
                district: '',
                province: '',
                addressOnIdcard: false,
                houseRegistration: false,
                workAddress: false,
                otherAddress: false,
                currentAddress: false,
                sendDocuments: false,
                addressStatus: true,
            })
        }
        axios.get(loadProvinces)
            .then((res) => {
                setProvinces(res.data)
            }).catch((err) => {
                console.error(err);
            })
    }, [])

    const handleProvinceChange = (value, key) => {
        setLoading(true)
        form.setFieldsValue({ //set form values
            district: '',
            subdistrict: '',
            zipCode: '',
        })
        setAddress({ ...address, province: value });
        axios.get(getdistrict + key.key)
            .then(response => {
                setLoading(false)
                setDistricts(response.data);
            }).catch(error => {
                console.error(error);
            });
    };

    const handleDistrictChange = (value, key) => {
        setLoading(true)
        form.setFieldsValue({ //set form values
            subdistrict: '',
            zipCode: '',
        })
        setAddress({ ...address, district: value });
        axios.get(getsubdistrict + key.key)
            .then(response => {
                setLoading(false)
                setSubdistricts(response.data);
            }).catch(error => {
                console.error(error);
            });
    };
    const handleSubdistrictChange = (value, key) => {
        setLoading(true)
        form.setFieldsValue({ //set form values
            zipCode: '',
        })
        setAddress({ ...address, subdistrict: value });
        axios.get(getzipcodes + key.key)
            .then(response => {
                setLoading(false)
                setZipCodes(response.data);
            }).catch(error => {
                console.error(error);
            });
    };

    const handleZipcodeChange = (value, key) => {
        setAddress({ ...address, zipCode: value });
    };

    //checkBox status ของที่อยู่
    const handleCancel = () => {
        close(false);
    };

    //ปุ่ม Submit in Modal Add Data Address
    const handleSubmit = async () => {
        setChechButton(true)
        // console.log('add', address)
        fucnAdd({ address })
        close(false);
    }
    // data input Form 
    const handleInputDataAddress = (e) => {
        const { name, value } = e.target;
        setAddress({
            ...address, [name]: value,
        }) // เป็นการกระจายข้อมูลเข้าไปตามชื่อ  name
    }

    return (
        <>
            {/* Modal เพิ่มข้อมูล */}
            <Modal
                open={open}
                centered
                onCancel={handleCancel}
                width={600}
                footer={[null]}
            >
                <Form
                    form={form}
                    name='add'
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    style={{
                        maxWidth: '100%',
                    }}
                    onFinish={handleSubmit}>
                    <Row>
                        <Col span={24} style={{ fontSize: '30px', textAlign: 'center' }} ><u>เพิ่มที่อยู่</u></Col>
                    </Row>
                    <Divider />
                    <Row gutter={{ sx: 8, sm: 16, md: 24, lg: 32 }} >
                        <Col className='gutter-row' span={24}>
                            <Form.Item name='houseRegistrationNumber' label="เลขทะเบียนบ้าน"
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'Please input !',
                            //     },]} 
                            >
                                {/* input Form เลขบัตรประจำตัวประชาชน*/}
                                <Input name='houseRegistrationNumber' placeholder='เลขทะเบียนบ้าน' onChange={handleInputDataAddress} />
                            </Form.Item>
                            <Form.Item name='houseNumber' label='บ้านเลขที่'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input !',
                                    },]}>
                                {/* input Form ชื่อ - นามสกุล*/}
                                <Input name='houseNumber' placeholder='บ้านเลขที่' onChange={handleInputDataAddress} />
                            </Form.Item>
                            <Form.Item name='soi' label='ซอย'
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'Please input !',
                            //     },]}
                            >
                                {/* input Form บ้านเลขที่  */}
                                <Input name='soi' placeholder='ซอย' onChange={handleInputDataAddress} />
                            </Form.Item>
                            <Form.Item name='road' label='ถนน'
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'Please input !',
                            //     },]}
                            >
                                <Input name='road' placeholder='ถนน' onChange={handleInputDataAddress} />
                            </Form.Item>
                            <Form.Item name='village' label='หมู่บ้าน'
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'Please input !',
                            //     },]}
                            >
                                <Input name='village' placeholder="หมู่บ้าน" onChange={handleInputDataAddress} />
                            </Form.Item>
                            <Form.Item
                                name='province'
                                label='จังหวัด'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input !',
                                    },]}
                            >
                                <Select
                                    showSearch
                                    loading={loading}
                                    name='province'
                                    placeholder="จังหวัด"
                                    onChange={handleProvinceChange}
                                >
                                    {provinces.map((pro, index) => (
                                        <Option key={pro.provinceId} value={pro.provinceName} >
                                            {pro.provinceName}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name='district'
                                label='อำเภอ'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input !',
                                    },]}
                            >
                                <Select
                                    showSearch
                                    loading={loading}

                                    name='district'
                                    placeholder="อำเภอ"
                                    onChange={handleDistrictChange}
                                >
                                    {district.map((dis, index) => (
                                        <Option key={dis.districtId} value={dis.districtName}>
                                            {dis.districtName}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name='subdistrict'
                                label='ตำบล'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input !',
                                    },]}
                            >
                                <Select
                                    showSearch
                                    loading={loading}
                                    name='subdistrict'
                                    placeholder='ตำบล'
                                    onChange={handleSubdistrictChange}
                                >
                                    {subdistricts.map((subdis, index) => (
                                        <Option key={subdis.subdistrictId} value={subdis.subdistrictName}>
                                            {subdis.subdistrictName}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>


                            <Form.Item
                                name='zipCode'
                                label='รหัสไปรษณีย์'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input !',
                                    },]}
                            >
                                <Select
                                    name='zipCode'
                                    placeholder="รหัสไปรษณีย์"
                                    onChange={handleZipcodeChange}
                                >
                                    {zipcode.map((zip, index) => (
                                        <Option key={zip.zipcodeId} value={zip.zipCode}>
                                            {zip.zipCode}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Divider><b>เป็นที่อยู่</b></Divider>
                            <Row gutter={32} justify={'center'}>
                                <Checkbox
                                    key={0}
                                    checked={address.addressOnIdcard}
                                    name='addressOnIdcard'
                                    defaultValue={address.addressOnIdcard}
                                    // label='ตามบัตรประชาชน'
                                    onChange={(e) => {
                                        setAddress({ ...address, addressOnIdcard: e.target.checked });

                                    }}
                                >"ตามบัตรประชาชน"</Checkbox>
                                <Checkbox
                                    key={1}
                                    checked={address.houseRegistration}
                                    name='houseRegistration'
                                    defaultValue={address.houseRegistration}
                                    // label='ตามทะเบียนบ้าน'
                                    onChange={(e) => {
                                        setAddress({ ...address, houseRegistration: e.target.checked });
                                    }}
                                >"ตามทะเบียนบ้าน"</Checkbox>
                                <Checkbox
                                    key={2}
                                    checked={address.workAddress}
                                    name='workAddress'
                                    defaultValue={address.workAddress}
                                    // label='ตามสถานที่ทำงาน'
                                    onChange={(e) => {
                                        setAddress({ ...address, workAddress: e.target.checked });
                                    }}
                                >"ตามสถานที่ทำงาน"</Checkbox>
                                <Checkbox
                                    key={3}
                                    checked={address.otherAddress}
                                    name='otherAddress'
                                    defaultValue={address.otherAddress}
                                    // label='อื่นๆ'
                                    onChange={(e) => {
                                        setAddress({ ...address, otherAddress: e.target.checked });
                                    }}
                                >"อื่นๆ"</Checkbox>
                                <Checkbox
                                    key={4}
                                    checked={address.currentAddress}
                                    name='currentAddress'
                                    defaultValue={address.currentAddress}
                                    // label='อื่นๆ'
                                    onChange={(e) => {
                                        setAddress({ ...address, currentAddress: e.target.checked });
                                    }}
                                >"ที่อยู่ปัจจุบัน"</Checkbox>
                                <Checkbox
                                    key={5}
                                    checked={address.sendDocuments}
                                    name='sendDocuments'
                                    defaultValue={address.sendDocuments}
                                    // label='อื่นๆ'
                                    onChange={(e) => {
                                        setAddress({ ...address, sendDocuments: e.target.checked });
                                    }}
                                >"ที่อยู่ส่งเอกสาร"</Checkbox>
                                <Checkbox
                                    key={6}
                                    checked={address.addressStatus}
                                    name='addressStatus'
                                    defaultValue={address.addressStatus}
                                    // label='อื่นๆ'
                                    onChange={(e) => {
                                        setAddress({ ...address, addressStatus: e.target.checked });
                                    }}
                                >"ที่อยู่ที่ใช้งานหรือไม่"</Checkbox>
                            </Row>
                            <Divider />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <Space>
                                <Button type="primary" onClick={handleCancel} style={{ background: "red" }}> ยกเลิก</Button>
                                <Spin size="small" spinning={chechButton}>
                                    <Button type="primary" htmlType="submit" style={{ background: "green" }}>บันทึก</Button>
                                </Spin>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    )
};

export default AddAddress
