import React, { useEffect } from 'react'
import drawerHeader from "../../assets/images/drawable-header.png";
import { Row } from 'antd';
export default function Home() {
  useEffect(() => {
    localStorage.removeItem('addCustomer');
    localStorage.removeItem('addAddress');
    localStorage.removeItem('addPhone');
    localStorage.removeItem('addCar');
    localStorage.removeItem('addGuarantor');
    localStorage.removeItem('addCareerIncome');
    localStorage.removeItem('addLoan');
    localStorage.removeItem('addOldLoan');
    localStorage.removeItem('addLand');
    localStorage.removeItem('addLoanLand');
    localStorage.removeItem('addOldLoanLand');
    localStorage.removeItem('addOccupation');
    localStorage.removeItem('addEmail');
    localStorage.removeItem('addSocial');
    localStorage.removeItem('addImage');
    localStorage.removeItem('addBroker');
  }, [])
  return (
    <div>
      <Row justify={'center'} align="middle">
        <img src={drawerHeader} width={'100%'} alt="" />
      </Row>
    </div>
  )
}
