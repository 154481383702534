import React, { useEffect, useState } from 'react';
import { Form, Button, Modal, Card, Row, Col, Input, Spin } from 'antd';
import axios from 'axios';
import { resetpassword } from '../file_mid/all_api';

export default function ModalResetPass({ open, close, rData }) {
    const [form] = Form.useForm();
    const [result, setResult] = useState(rData);
    const [loading, setLoading] = useState(false)
    const [dataPost, setDataPost] = useState({})

    useEffect(() => {
        setResult(rData)
    }, [rData])
    
    const success = () => {
        Modal.success({
            content: 'บันทึกสำเร็จ',
        })
    }
    const error = () => {
        Modal.error({
            title: 'บันทึกไม่สำเร็จ',
            content: 'กรุณาลองใหม่อีกครั้ง',
        })
    }
    const updatePass = async () => {
        // console.log("user ", {username: result.username})
        // console.log("pass ", {password: dataPost.password1})
        const results = { username: result.username, password: dataPost.password1 }
        //console.log(results)
        setLoading(true)
        await axios.put(resetpassword, results)
            .then((res) => {
                if (res.data.status === "success") {
                    success()
                    setLoading(false)
                    close(false)
                }
                else {
                    alert('ข้อมูลไม่ครบถ้วน')
                    setLoading(false)
                }
            })
            .catch((err) => {
                if (err.response.request.status === 400) {
                    error()
                }
                else {
                    error()
                }
                setLoading(false)
            })
        setLoading(false)
    };
    const onFinish = async () => {
        if (dataPost.password1 !== '' && dataPost.password2 !== '') {
            if (dataPost.password1 === dataPost.password2) {
                updatePass()
            } else alert('รหัสผ่านไม่ตรงกัน')
        } else alert('ข้อมูลไม่ครบถ้วน')
    }
    const handleCancel = () => {
        close(false)
    };
    return (
        <Card style={{ padding: 20 }}>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: 0,
                marginBottom: 30,
            }}>
                <Modal title="แก้ไขข้อมูล" open={open} width={900} onCancel={handleCancel}
                    footer={[
                        <Button form="myForm" key="submit" htmlType="submit">
                            ยืนยัน
                        </Button>,
                        <Button onClick={handleCancel} key="close">
                            ยกเลิก
                        </Button>
                    ]}
                >
                    <Spin spinning={loading} size='large' tip=" Loading... ">
                        <Form id="myForm"
                            name="basic"
                            labelCol={{
                                span: 8,
                            }}
                            wrapperCol={{
                                span: 12,
                            }}
                            initialValues={{
                                remember: true,
                            }}
                            form={form}
                            onFinish={onFinish}
                            autoComplete="off"
                        >
                            <Row>
                                <Col span={24}>

                                    <Form.Item label='รหัสผ่านใหม่' name='password1'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your password!',
                                            },]}
                                    >
                                        <Input.Password type='text' placeholder="รหัสผ่านใหม่"
                                            onChange={(e) => setDataPost({ ...dataPost, password1: e.target.value })}></Input.Password>
                                    </Form.Item>

                                    <Form.Item label='ยืนยันรหัสผ่าน' name='password2'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your password!',
                                            },]}
                                    >
                                        <Input.Password type='text' placeholder="ยืนยันรหัสผ่าน"
                                            onChange={(e) => setDataPost({ ...dataPost, password2: e.target.value })}></Input.Password>
                                    </Form.Item>


                                </Col>
                            </Row>
                            <div style={{ textAlign: "center" }}>
                                <Button type="text" htmlType="submit" style={{ background: "#e0e0e0" }} >บันทึก</Button>
                            </div >
                        </Form>
                    </Spin>
                </Modal>
            </div>
        </Card>
    )
}
