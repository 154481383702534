import React, { useState, useEffect } from "react";
import { Button, Form, Select, Row, Col, DatePicker } from "antd";
import axios from "axios";
import dayjs from "dayjs";
//import TestToken from "../AtextToken/testToken";
import DataLocatAll from "./Data/DataLocatsAll";
import DataLocat from "./Data/DataLocats";
import CountCaseByLocat from "./DataCheck/CountCaseByLocat";
import exportExcel from "./Excel/testx1";
import TabMain from "./Tab/tabMain";
const { RangePicker } = DatePicker;
function SearchData() {
  //เลือกวันที่-ถึงวันที่
  const [inputDate, setInputDate] = useState([]);
  const onChangeDate = (date, dateString) => {
    setInputDate(dateString);
    //console.log(dateString);
  };
  //เลือกสาขาหลัก
  const [inputLocatAll, setInputLocatAll] = useState([]);
  const [checkLocat, setcheckLocat] = useState([]);
  const onChangeLocalAll = (value, option) => {
    setInputLocatAll(value);
    const keyA = option.key;
    //console.log("DataLocat", DataLocat);
    if (keyA === 3) {
      setcheckLocat(DataLocat);
    } else if (keyA === 1) {
      const filterLocat = DataLocat.filter((item) => item.checkkey === 1);
      setcheckLocat(filterLocat);
    } else if (keyA === 2) {
      const filterLocat = DataLocat.filter((item) => item.checkkey === 2);
      setcheckLocat(filterLocat);
    }
  };
  //เลือกสาขาย่อ
  const [inputLocat, setInputLocat] = useState([]);
  const onChangeLocal = (value) => {
    //console.log(checkLocatAll, "checkLocatAll");
    setInputLocat(value);
  };
  //เก็บ ข้อมูล Api
  const [dataAPI, setDataAPI] = useState(null);
  //เก็บ ข้อมูล ApiCheckLocatCar
  const [dataApiCheckLocatCar, setApiCheckLocatCar] = useState(null);
  //เก็บ ข้อมูล ApiCheckLocatLand
  const [dataApiCheckLocatLand, setApiCheckLocatLand] = useState(null);
  //form-Error
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  //form-Success
  const onFinish = async (values) => {
    //console.log("Success:", values);
    //console.log("dataAPI", dataAPI);
    const inputYearMonth = dayjs(values.date[0]).format("YYYY/MM");
    const checkLocat = values.inputLocat;
    const checkLocatAll = values.inputLocatAll;
    //รถส่งค่าไปนับสาขา
    const typeCar = "car";
    const resultCar = CountCaseByLocat(
      typeCar,
      inputYearMonth,
      checkLocat,
      checkLocatAll,
      dataAPI
    );
    setApiCheckLocatCar(resultCar);
    //ที่ดินส่งค่าไปนับสาขา
    const typeLand = "land";
    const resultLand = CountCaseByLocat(
      typeLand,
      inputYearMonth,
      checkLocat,
      checkLocatAll,
      dataAPI
    );
    setApiCheckLocatLand(resultLand);
  };
  //ดึงข้อมูลAPI
  useEffect(() => {
    //token
    const token = localStorage.getItem("token");
    const tk = JSON.parse(token);
    //const tk = TestToken;
    const headers = {
      Authorization: `Bearer ${tk}`,
      Menu: JSON.stringify("5"),
    };
    const fetchData = async () => {
      try {
        const res = await axios.post(
          "https://sendcase-mk-d2flonv3.an.gateway.dev/mid/findcase",
          { startdate: inputDate?.[0], enddate: inputDate?.[1] },
          //   { startdate: "2024-01-01", enddate: "2024-01-31" },
          { headers: headers }
        );
        setDataAPI(res.data);
      } catch (err) {
        console.log(err.message);
      }
    };
    fetchData();
  }, [inputDate]);

  return (
    <>
      <h1>รายงานผลการวิเคราะห์เคส</h1>
      <Form
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        {" "}
        <Row>
          <Col span={7}>
            <Form.Item
              label="เลือกวันที่ ถึงวันที่"
              name="date"
              rules={[
                {
                  required: true,
                  message: "เลือกวันที่ ถึงวันที่!",
                },
              ]}
            >
              <RangePicker
                style={{ margin: "0 4px 4px" }}
                onChange={onChangeDate}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="เลือกสาขาหลัก"
              name="inputLocatAll"
              rules={[
                {
                  required: true,
                  message: "เลือกสาขาหลัก!",
                },
              ]}
            >
              <Select
                onChange={(value, option) => {
                  onChangeLocalAll(value, option);
                }}
                placeholder="เลือกสาขาหลัก"
                options={DataLocatAll}
                value={inputLocatAll}
                // style={{ width: "110px", margin: "0 4px 4px" }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="เลือกสาขาย่อ" name="inputLocat">
              <Select
                onChange={(value) => {
                  onChangeLocal(value);
                }}
                placeholder="เลือกสาขาย่อ"
                options={checkLocat}
                value={inputLocat}
                // style={{ width: "110px", margin: "0 4px 4px" }}
              />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label=" ">
              <Button
                type="primary"
                // onClick={postData}
                htmlType="submit"
                style={{ margin: "0 2px 4px" }}
              >
                ค้นหา
              </Button>
              <Button
                type="primary"
                style={{ backgroundColor: "green" }}
                onClick={() =>
                  exportExcel({
                    dataApiCheckLocatCar,
                    dataApiCheckLocatLand,
                  })
                }
              >
                โหลด Excel
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <TabMain
        dataApiCheckLocatCar={dataApiCheckLocatCar}
        dataApiCheckLocatLand={dataApiCheckLocatLand}
      ></TabMain>
    </>
  );
}
export default SearchData;
