import React, { useState, useEffect } from 'react'
import { Modal, Row, Spin, Radio, Col } from 'antd'
import axios from 'axios';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { addCar } from '../../../redux/Car';
import EditCar from "./edit_car/EditCar";
import Conclusion from "./edit_car/Conclusion";
import { loadDataRe, getcontnoJojonoi } from '../../file_mid/all_api';
import UploadImgResend from './edit_car/UploadImgResend';

export default function ModalEdit({ open, close, data }) {
    const { confirm } = Modal
    const dispatch = useDispatch()
    const [count, setCount] = useState(0)
    const [loading, setLoading] = useState(false)
    const [dataCont, setDataCont] = useState()
    const [dataRe, setDataRe] = useState()
    const [noteCarRe, setNoteCarRe] = useState([]) // เก็บโน๊ต
    const [sendbackRE, setSendbackRE] = useState(dayjs())
    const [numberRe, setNumberRe] = useState()

    useEffect(() => {
        loadDataReCar()
        loadDataContnoCar()
    }, [sendbackRE])

    const loadDataReCar = async () => {
        if (data?.reLoanId && data?.customerId && data?.carId) {
            var mainData = { "reLoanId": data?.reLoanId, "customerId": data?.customerId, "carId": data?.carId }
            setLoading(true)
            await axios.post(loadDataRe, mainData)
                .then(async (res) => {
                    // console.log("loadDataContno", res.data)
                    setDataRe(res.data)
                    setNoteCarRe(res.data.cars?.carLoanDetailsRe?.notesCarRe)
                    setLoading(false)
                }).catch((err) => {
                    console.log(err)
                    alert("ไม่พบข้อมูล !!!")
                })
            setLoading(false)
        } else {
            alert("การโหลดข้อมูลมีปัญหา กรุณาปิดแล้วเปิดใหม่ครับ/ค่ะ")
        }
    }


    const loadDataContnoCar = async () => {
        setLoading(true)
        await axios.get(`${getcontnoJojonoi}/${data?.ISSUNO}`)
            .then(res => {
                if (res.data) {
                    // console.log("Contno42", res.data)
                    if (parseFloat(res.data?.re1) < parseFloat(res.data?.re2)) {
                        // console.log("re1 น้อยกว่า re2");
                        setNumberRe(res.data?.re1)
                    } else if (parseFloat(res.data?.re2) < parseFloat(res.data?.re1)) {
                        // console.log("re2 น้อยกว่า re1");
                        setNumberRe(res.data?.re2)
                    } else {
                        // console.log("re1 เท่ากับ re2");
                        setNumberRe(res.data?.re1)
                    }
                    setDataCont(res.data)
                    setLoading(false)
                }
            }).catch((err) => {
                console.log("err", err)
            })
        setLoading(false)
    }

    const showConfirmCancel = () => {
        confirm({
            title: 'คุณต้องการยกเลิก...?',
            onOk() {
                dispatch(addCar());
                localStorage.removeItem('addCar');
                localStorage.removeItem('addCar2');
                Onclose();
            },
            onCancel() {
            },
        });
    };

    const Onclose = () => {
        dispatch(addCar());
        localStorage.removeItem('addCar');
        localStorage.removeItem('addCar2');
        close(false);

    };
    return (
        <div>
            <Modal title="แก้ไขข้อมูล" width={'90%'} open={open} footer={null} onCancel={showConfirmCancel}>
                <Spin tip="Loading..." spinning={loading} >
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <Radio.Group value={count} onChange={(e) => setCount(e.target.value)}>
                            <Radio.Button value={1}>เพิ่มรูปรถ</Radio.Button>โ
                            <Radio.Button value={2}>ข้อมูลรถ</Radio.Button>
                            <Radio.Button value={3}>สรุปรายละเอียด</Radio.Button>
                        </Radio.Group>
                    </Col>

                    <Row>
                        <Col span={24}>
                            {count === 1 && (<UploadImgResend close={showConfirmCancel} changepage={setCount} page={count} data={data} dataRe={dataRe} dataCont={dataCont} sendback={setSendbackRE} />)}
                            {count === 2 && (<EditCar close={showConfirmCancel} changepage={setCount} page={count} data={data} dataRe={dataRe} dataCont={dataCont} sendback={setSendbackRE} />)}
                            {count === 3 && (<Conclusion close={showConfirmCancel} closeAll={Onclose} changepage={setCount} page={count} dataRe={dataRe} noteCarRes={noteCarRe} />)}
                        </Col>
                    </Row>
                </Spin>
            </Modal>
        </div >
    )
}
