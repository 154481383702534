import React, { useState, useEffect } from 'react'
import { Modal, Row, Spin, Space } from 'antd'
import { addCar } from '../../../redux/Car';
import { addImage } from '../../../redux/Image';
import { useDispatch } from 'react-redux';
import { alldataland1, getcontnoJojonoi, loadInfoMk } from '../../file_mid/all_api';
import DetailProduct from "./DetailProduct";
import DetailContno from "./DetailContno";
import DetailProductLand from './DetailProductLand';
import UploadImg from './UploadImg';
import Tel from './Tel';
import axios from 'axios';

export default function Contno({ open, close, data, dataType, againMode, aun }) {
    // const [api, contextHolder] = notification.useNotification();
    const { confirm } = Modal
    const dispatch = useDispatch()
    const [count, setCount] = useState('1')
    // const [resetPage, setResetPage] = useState(dayjs())
    const [loading, setLoading] = useState(false)
    const [allData, setAllData] = useState()
    const [numberRe, setNumberRe] = useState()
    const [dataLand, setDataLand] = useState()
    const [dataOld, setDataOld] = useState()
    const [beeypupLand, setBeeypupLand] = useState()

    useEffect(() => {
        // console.log("data", data)
        // console.log("dataType", dataType)
        if (data.PRODUCTS === "car") {
            loadDataContno()
            // setCount("1")
        } else {
            loadDataContnoLand()
            // setCount("11")
        }
        if (dataType === "โทร") {
            setCount("0")
        }
        if (againMode) {
            loadOldData()
        }
    }, [])
    // console.log("data", data)
    // console.log("count", count)
    // console.log("dataType", dataType)

    const loadDataContno = async () => {
        setLoading(true)
        await axios.get(`${getcontnoJojonoi}/${data?.CONTNO}`)
            .then(res => {
                if (res.data) {
                    // console.log("Contno42", res.data)
                    if (parseFloat(res.data?.re1) < parseFloat(res.data?.re2)) {
                        // console.log("re1 น้อยกว่า re2");
                        setNumberRe(res.data?.re1)
                    } else if (parseFloat(res.data?.re2) < parseFloat(res.data?.re1)) {
                        // console.log("re2 น้อยกว่า re1");
                        setNumberRe(res.data?.re2)
                    } else {
                        // console.log("re1 เท่ากับ re2");
                        setNumberRe(res.data?.re1)
                    }
                    setAllData(res.data)
                    setLoading(false)
                }
            }).catch((err) => {
                console.log("err", err)
            })
        setLoading(false)
    }

    const loadDataContnoLand = async () => {
        setLoading(true)
        await axios.post(alldataland1, { CONTNO: data.CONTNO })
            .then(res => {
                if (res.data) {
                    // console.log("loadDataContnoLand", res.data)
                    setDataLand(res.data?.Data2)
                    setNumberRe(res.data?.Data2?.re1)
                    setAllData(res.data?.Data1)
                    setBeeypupLand(res.data?.beeypup)
                    setLoading(false)
                }
            }).catch((err) => {
                console.log("err", err)
            })
        setLoading(false)
    }

    const loadOldData = async () => {
        setLoading(true)
        await axios.post(loadInfoMk, { CONTNO: data?.CONTNO, PRODUCTS: data?.PRODUCTS })
            .then(res => {
                if (res.data) {
                    setDataOld(res.data)
                    // console.log("loadOldData", res.data)
                    setLoading(false)
                }
            }).catch((err) => {
                console.log("err", err)
                setLoading(false)
            })
    }

    const showConfirmCancel = () => {
        confirm({
            title: 'คุณต้องการยกเลิก...?',
            onOk() {
                localStorage.removeItem('addCar');
                localStorage.removeItem('addLand');
                localStorage.removeItem('addOccupation');
                localStorage.removeItem('addCareerIncome');
                dispatch(addCar());
                dispatch(addImage());
                close(false);
            },
            onCancel() {
            },
        });
    };

    const Onclose = () => {
        localStorage.removeItem('addCar');
        localStorage.removeItem('addLand');
        localStorage.removeItem('addOccupation');
        localStorage.removeItem('addCareerIncome');
        dispatch(addCar());
        dispatch(addImage());
        close(false);

    };

    return (
        <div>
            <Modal open={open} width={1000} footer={null} title="เคส รี/ปรับ/เปลี่ยน" onCancel={showConfirmCancel}>
                <Spin tip="Loading..." spinning={loading} >
                    <Space
                        direction="vertical"
                        size="middle"
                        style={{
                            display: 'flex',
                        }}>
                        <Row justify={'center'}>
                            {count === '0' && (<Tel allData={allData} data={data} aun={aun} onCancel={Onclose} changepage={setCount} dataType={dataType} />)}
                            {count === '1' && (<UploadImg allData={allData} data={data} onCancel={Onclose} changepage={setCount} dataType={dataType} dataOld={dataOld} againMode={againMode} />)}
                            {count === '2' && (<DetailProduct allData={allData} data={data} onCancel={Onclose} changepage={setCount} dataType={dataType} dataOld={dataOld} againMode={againMode} />)}
                            {/* {count === '2' && (<AddOcc onCancel={Onclose} changepage={setCount} />)} */}
                            {count === '4' && (<DetailProductLand allData={allData} onCancel={Onclose} changepage={setCount} dataType={dataType} data={data} dataOld={dataOld} againMode={againMode} />)}
                            {count === '5' && (<DetailContno allData={allData} onCancel={Onclose} changepage={setCount} dataType={dataType} numberRe={numberRe} data={data} dataLand={dataLand} beeypupLand={beeypupLand} dataOld={dataOld} againMode={againMode} />)}
                        </Row>
                    </Space>
                </Spin>
            </Modal>
        </div >
    )
}
