import { Button, Divider, Modal, Table, Col, Space, Card } from "antd";
import React, { useEffect, useState } from "react";
import axios from "axios";
import dayjs from "dayjs";
import { findpayment } from "../../file_mid/all_api";

function ModalInstallment({ open, close, contno }) {
  const [dataInstallment, setDataInstallment] = useState([]);
  // console.log("contno", contno)

  useEffect(() => {
    loadInstallment();
  }, [contno]);

  const handleClose = () => {
    close(false);
  };

  const loadInstallment = async () => {
    await axios
      .post(findpayment, { ISSUNO: contno })
      .then((res) => {
        // console.log("res", res)
        // console.log("res.data", res.data)
        if (res.data === "ไม่มีเลขสัญญา") {
          setDataInstallment([]);
        } else {
          setDataInstallment(res.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const columns = [
    {
      title: "งวดที่",
      dataIndex: "NOPAY",
      width: "20%",
      align: "center",
      // defaultSortOrder: 'ascend',
      sorter: {
        compare: (a, b) => a.NOPAY - b.NOPAY,
        multiple: 1,
      },
    },
    {
      title: "กำหนดชำระ",
      dataIndex: "DDATE",
      width: "20%",
      align: "center",
    },
    {
      title: "ค่างวด",
      dataIndex: "DAMT",
      width: "20%",
      align: "center",
      render: (text, record) => <>{currencyFormatOne(record.DAMT)}</>,
    },
    {
      title: "วันที่ชำระ",
      dataIndex: "DATE1",
      align: "center",
      width: "20%",
      render: (text, record) => (
        <>{record.DATE1 ? dayjs(record.DATE1).format("DD-MM-YYYY") : null}</>
      ),
    },
    {
      title: "ยอดชำระ",
      dataIndex: "PAYMENT",
      width: "20%",
      align: "center",
      render: (text, record) => <>{currencyFormatOne(record.PAYMENT)}</>,
    },
  ];

  const currencyFormatOne = (amount) => {
    if (amount) {
      return Number(amount)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    } else {
      return null;
    }
  };

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      footer={[null]}
      closeIcon={false}
      width={800}
    >
      <Card>
        <Divider orientation="left">
          <b>ตารางการผ่อน</b>
        </Divider>
        <Table
          columns={columns}
          dataSource={dataInstallment}
          scroll={{ x: 850 }}
        />
      </Card>
      <Divider />
      <Col span={24} style={{ textAlign: "right" }}>
        <Space>
          <Button type="primary" danger onClick={handleClose}>
            ปิด
          </Button>
        </Space>
      </Col>
    </Modal>
  );
}

export default ModalInstallment;
