import React, { useState, useEffect } from 'react'
import { Form, Card, Col, Row, Button, Divider, Space, Spin, Modal, message } from 'antd';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { DeleteOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import AddOcc from './Occ/AddOcc'
import EditOcc from './Occ/EditOcc'
import { loadOJS, newdeletecustomerocc } from '../../file_mid/all_api';

export default function EditCareerDoc({ page, close, datashow, changepage, sendback }) {
    const { confirm } = Modal
    // const { customers } = useSelector((state) => ({ ...state }))
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [dataCareer, setDataCareer] = useState([])
    const [dataCareer2, setDataCareer2] = useState([])
    const [dataedit, setDataEdit] = useState([]); //เซตสำหรับแก้ไขข้อมูล 
    const [index1, setIndex] = useState(); //set index send to page edit

    const [modalAddOcc, setModalAddOcc] = useState(false)
    const [modaleditdata, setModalEditData] = useState(false)

    useEffect(() => {
        setDataCareer(datashow)
        loadCareer()
    }, [datashow])

    const loadCareer = async () => {
        const id = { customerId: datashow?.customerId }
        setLoading(true)
        await axios.post(loadOJS, id)
            .then((res) => {
                setDataCareer2(res.data)
                setLoading(false)
            })
            .catch((err) => console.log(err))
        setLoading(false)
    }

    const onChangeBack = () => {
        changepage(page - 1)
    }

    const onChangeGo = () => {
        changepage(page + 1)
    }

    const handleCancel = () => {
        close(false)
    }

    const error = () => {
        Modal.error({
            title: 'บันทึกไม่สำเร็จ',
            content: 'กรุณาตรวจสอบข้อมูลอีกครั้ง',
        })
    }

    const successDelete = () => {
        Modal.success({
            title: 'สำเร็จ',
            content: 'ลบข้อมูลสำเร็จ',
        })
    }
    const errorDelete = () => {
        Modal.error({
            title: 'ไม่สำเร็จ',
            content: 'ลบข้อมูลไม่สำเร็จ กรุณาตรวจสอบข้อมูลอีกครั้ง',
        })
    }

    const fucnEdit = ({ getData, getIndex }) => {
        console.log("fucnEdit", getData)
        let Theedit = [...dataCareer2]
        Theedit[getIndex] = getData
        //ข้อมูลใหม่แทนที่ข้อมูลเดิมในช่อง Input
        setDataCareer(Theedit);
        setDataCareer2(Theedit);
        sendback(dayjs())
    }

    const deleteData = async (value) => {
        setLoading(true)
        const test2 = value
        await axios.delete(newdeletecustomerocc, { data: test2, })
            .then((res) => {
                if (res.status === 200) {
                    sendback(dayjs())
                    setLoading(false)
                    successDelete()
                }
                else {
                    errorDelete()
                }
            }).catch((err) => errorDelete())
        setLoading(false)
    }

    const showConfirm = (value, index) => {
        confirm({
            title: 'คุณต้องการที่จะลบข้อมูลหรือไม่...?',
            content: 'กด OK เพื่อยืนยันการลบ',
            onOk() {
                //const deletearray = [...dataCareer]
                const deletearray = [...dataCareer2]
                deletearray.splice(index, 1)
                setDataCareer(deletearray)
                setDataCareer2(deletearray)
                deleteData(value)
            },
            onCancel() {
                message.error('ยกเลิกการลบข้อมูล')
            },
        });
    };

    const renderItem = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์

        // index ตอนนี้เป็นข้อมูลจาก Object
        const handleEdit = (dataedit, index1) => {
            setIndex(index1) //เซตค่า index ให้กับชุด ข้อมูล
            setDataEdit({ dataedit, index1 }) //ส่งข้อมูลด index ไปหน้าแก้ไข
            setModalEditData(true);
        }

        return (
            <Form form={form}>
                <Row style={{ margin: '3px' }} justify={'center'} >
                    <Card
                        key={index} // index เป็นตัวกำหนดคีย์
                        style={{ width: '300px' }} >
                        <Col span={24}>
                            <Form.Item style={{ marginBottom: 0 }} label='กลุ่มอาชีพ' >
                                <b>{item?.desc}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='เลือกอาชีพ'>
                                <b>{item?.descSub}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='สาขาอาชีพ'>
                                <b>{item?.descSection}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='ชื่อบริษัท สถานที่ทำงาน'>
                                <b>{item?.companyName}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='หมายเหตุอาชีพ'>
                                <b>{item?.occupationDescription}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='รายได้ต่อเดือน'>
                                <b>{item.incomeMonth}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='หมายเหตุรายได้'>
                                <b>{item.incomeMainDescription}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='ค่าใช้จ่ายต่อ / เดือน'>
                                <b>{item?.expensesMonth}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='หมายเหตุรายจ่าย'>
                                <b>{item?.expensesMonthDescription}</b>
                            </Form.Item>
                            <Divider></Divider>
                            <Row justify={'center'} gutter={{ sx: 8, sm: 16, md: 24, lg: 32 }}>
                                <Space>
                                    <Form.Item>
                                        {/* ปุ่มลบข้อมูล */}
                                        <Button
                                            style={{ margin: '10px', background: '#f5222d', color: 'white', marginBottom: 0 }}
                                            icon={<DeleteOutlined />}
                                            onClick={() => showConfirm(dataCareer2[index], index)}
                                        >ลบ</Button>
                                    </Form.Item>
                                    <Form.Item>
                                        {/* ปุ่มแก้ไขข้อมูล */}
                                        <Button
                                            style={{ margin: '10px', background: '#bfbfbf', color: '#000000', marginBottom: 0 }}
                                            type='submit'
                                            onClick={() => { handleEdit(dataCareer2[index], index); }}
                                        >แก้ไข</Button>
                                    </Form.Item>
                                </Space>
                            </Row>
                        </Col>
                    </Card>
                </Row>
            </Form>
        )
    }

    return (
        <div>
            <Card style={{ color: '#4096ff' }}>
                <Form>
                    <Divider />
                    <Form
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 12,
                        }}
                        form={form}
                        // onFinish={handleSubmit}
                        autoComplete="off" >
                        <Spin spinning={loading} size='large' tip="Loading...">
                            <Row>
                                <Col className='gutter-row' span={24}>
                                    <Row>
                                        <Col span={24} style={{ fontSize: '30px', textAlign: 'center' }} >รายละเอียดอาชีพและรายได้</Col>
                                    </Row>
                                    <Divider />
                                    <Row gutter={32}>
                                        <Col span={24} style={{ textAlign: 'right' }}>
                                            {/* ปุ่มเพิ่มข้อมูล */}
                                            <Button type='primary'
                                                onClick={() => { setModalAddOcc(true); }}
                                            >
                                                เพิ่มข้อมูลที่อยู่</Button>
                                        </Col>
                                    </Row>
                                    <Row justify={'center'}>
                                        {
                                            dataCareer2?.map((item, index) => {
                                                return renderItem({ item, index, key: `{item.identificationId} - ${index}` })
                                            })
                                        }
                                    </Row>
                                </Col>
                            </Row>
                        </Spin>
                    </Form>
                    <Divider />

                    <div style={{ textAlign: "center" }} >
                        <Space>
                            <Button type="primary" style={{ background: '#bfbfbf', color: '#000000' }} onClick={onChangeBack}>ย้อนกลับ</Button>
                            <Button type="primary" onClick={handleCancel} style={{ background: "red" }}>ยกเลิก</Button>
                            <Button type="primary" onClick={onChangeGo}>ต่อไป</Button>
                        </Space>
                    </div>
                </Form>
            </Card>
            {
                //เพื่มข้อมูลในการ์ด
                modalAddOcc ?
                    <AddOcc
                        open={modalAddOcc}
                        close={setModalAddOcc}
                        getData={dataCareer}
                        sendback={sendback}
                    />
                    : null
            }
            {
                //แก้ไขข้อมูลในการ์ด
                modaleditdata ?
                    <EditOcc
                        open={modaleditdata} // เซตการเปิด Modal จากหน้า AddressEditData
                        close={setModalEditData} // เซตการปิด Modal จากหน้า AddressEditData
                        shootdata={dataedit} // 
                        fucnEdit={fucnEdit}
                        dataindex={index1}
                    />
                    : null
            }
        </div>

    )
}
