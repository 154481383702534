import { createSlice } from "@reduxjs/toolkit";

const initialState = {

}

const oldLoanDataSlice = createSlice ({
    name: "oldloan",
    initialState,
    reducers: {
        
        addOldLoan: (state, action) => {
            state.data = action.payload
            // console.log(current(state))
        },
        
    }
    
})

export const { addOldLoan } = oldLoanDataSlice.actions
export default oldLoanDataSlice.reducer