//แก้แล้วแต่ input ค่ายังไม่เปลี่ยนใน pdf
import React, { useState, useEffect } from "react";
import { Button, Table, DatePicker, Select, InputNumber, Space, Input } from "antd";
import axios from "axios";
import DotaColums from "./dotaColums";
import DataCase from "./dotaCase";
import { getlandpn1 } from "../../file_mid/all_api";

const { RangePicker } = DatePicker;
function TabelLandRegistrationSet() {
  const [inputDate, setInputDate] = useState([]);
  const [inputCase, setInputCase] = useState([]);
  const [inputDateText, setInputDateText] = useState([]);
  const [inputDateText2, setInputDateText2] = useState([]);
  const [inputDateText3, setInputDateText3] = useState([]);
  const [inputDateText4, setInputDateText4] = useState([]);
  const [inputDateText5, setInputDateText5] = useState([]);
  const [inputDateText6, setInputDateText6] = useState([]);
  const [inputDateText7, setInputDateText7] = useState([]);

  //เซ็ต ค่าวันที่ ถึงวันที่
  const onChangeDate = (dateSelect2) => {
    setInputDate(dateSelect2);
  };
  //case
  const onChangeCase = (value) => {
    //console.log(`selected ${value}`);
    setInputCase(value);
  };
  //time
  const onChangeDatePicker = (date, dateString) => {
    //console.log(date, dateString);
    setInputDateText(dateString);
  };
  const onChangeDatePicker2 = (date, dateString) => {
    //console.log(date, dateString);
    setInputDateText2(dateString);
  };
  //ตัวเลข
  const onChangeDatePicker3 = (dataint) => {
    //console.log("dataint1", dataint);
    setInputDateText3(dataint);
  };
  const onChangeDatePicker4 = (dataint) => {
    //console.log("dataint2", dataint);
    setInputDateText4(dataint);
  };
  const onChangeDatePicker5 = (event) => {
    const value = event.target.value;
    setInputDateText5(value);
  };
  const onChangeDatePicker6 = (event) => {
    const value = event.target.value;
    setInputDateText6(value);
  };
  //ตัวเลข
  const onChangeDatePicker7 = (dataint) => {
    setInputDateText7(dataint);
  };
  //ประกาศตัวแปรรับ tabel
  const [dataTabel, setDataTabel] = useState([]);
  const [checkDataTabel, setcheckDataTabel] = useState([]);
  //Button1
  const onClickA = () => {
    if (inputDate?.length === 0) {
      alert("เลือกวันที่-ถึงวันที่ก่อน");
      return;
    } else if (inputCase?.length === 0) {
      alert("เลือกเรื่องก่อน");
      return;
    } else if (inputDateText2?.length === 0) {
      alert("เลือกวันประชุมก่อน");
      return;
    } else if (inputDateText?.length === 0) {
      alert("เลือกวันหนังสือมอบอำนาจ");
      return;
    } else if (inputDateText3?.length === 0) {
      alert("กรอกเลขที่ดิน");
      return;
    } else if (inputDateText4?.length === 0) {
      alert("กรอกเลขหน้าสำรวจ");
      return;
    } else if (inputDateText7?.length === 0) {
      alert("กรอกเลขการประชุมครั้งที่");
    }
     else {
      setDataTabel(checkDataTabel);
    }
  };

      //useEffect สำหรับเช็คการเปลี่ยนแปลงของ
      useEffect(() => {
        const fetchData = async () => {
          await axios
            .post(
              getlandpn1,
              //"https://api-checkinfoland-i67f6gaaqa-as.a.run.app/info_land/getlandpn",
              {
                days1: inputDate[0],
                days2: inputDate[1],
                approvalStatus: "ทั้งหมด",
              }
            )
            .then((res) => {
              //2024-04-02 วันที่มีเคส
              //console.log("res11",res.data);
              const filteredApprovalStatus = res.data.filter((item) =>
                //item.approvalStatus.includes("รอทำสัญญา")
              item.approvalStatus === "อนุมัติ" ||
              item.approvalStatus === "ลูกค้าคอนเฟิร์ม" ||
              item.approvalStatus === "รอตรวจสอบเอกสาร" ||
              item.approvalStatus === "รอทำสัญญา" ||
              item.approvalStatus === "อนุมัติแบบมีเงื่อนไข" 
              );
              //console.log("res22",filteredApprovalStatus);
              //console.log("Status11",filteredApprovalStatus);
              const newDataFilteredApprovalStatus = filteredApprovalStatus.map(
                (item) => ({
                  ...item,
                  inputCase: inputCase, // สมมติว่า inputCase มาจากตัวแปรใดตัวหนึ่งที่กำหนดไว้นอกฟังก์ชัน onClickA
                  inputDateText: inputDateText,
                  inputDateText2: inputDateText2,
                  inputDateText3: inputDateText3,
                  inputDateText4: inputDateText4,
                  inputDateText5: inputDateText5,
                  inputDateText6: inputDateText6,
                  inputDateText7: inputDateText7,
                })
              );
              setcheckDataTabel(newDataFilteredApprovalStatus);
            })
            .catch((err) => console.log(err.message));
        };
        fetchData();
      }, [
        inputDate,
        inputCase,
        inputDateText,
        inputDateText2,
        inputDateText3,
        inputDateText4,
        inputDateText5,
        inputDateText6,
        inputDateText7,
      ]);
      //console.log("inputDateText4",inputDateText4);
  return (
    <>
      <center>
        <h1>แบบฟร์อมชุดจดที่ดิน</h1>
        <label> วันที่ :</label>
        <label style={{ color: "#0D36EE" }}>{inputDate[0]}</label>
        <label> ถึงวันที่ :</label>
        <label style={{ color: "#0D36EE" }}>{inputDate[1]}</label>
        <br />
        <br />
      </center>
      <RangePicker
        placeholder={["เลือกวันที่", "ถึงวันที่"]}
        style={{ margin: "0 4px 4px" }}
        format="YYYY-MM-DD"
        onChange={(dateSelect, dateSelect2) => {
          onChangeDate(dateSelect2);
        }}
      />
      <Select
        style={{ width: "200px", margin: "0 4px 4px" }}
        showSearch
        placeholder="เลือกเรื่อง"
        optionFilterProp="children"
        onChange={(value) => {
          onChangeCase(value);
        }}
        options={DataCase}
        value={inputCase}
        defaultValue={"รับจำนองที่ดิน"}
      />
      <DatePicker onChange={onChangeDatePicker2} placeholder="วันประชุม" />
      <DatePicker
        onChange={onChangeDatePicker}
        placeholder="วันหนังสือมอบอำนาจ"
      />
      <Space.Compact>
        <InputNumber placeholder="เลขที่ดิน" onChange={onChangeDatePicker3} />
        <InputNumber placeholder="หน้าสำรวจ" onChange={onChangeDatePicker4} />
        <InputNumber placeholder="ครั้งที่" onChange={onChangeDatePicker7} />
        <Input placeholder="ระวาง" style={{ width: "90px",height: "32px" }} onChange={onChangeDatePicker5} />
        <Input  placeholder="ตำบล" style={{ width: "90px",height: "32px" }} onChange={onChangeDatePicker6} />
      </Space.Compact>

      <Button
        type="primary"
        // onClick={postData}
        onClick={onClickA}
        style={{ margin: "0 2px 4px" }}
      >
        ค้นหา
      </Button>
      <Table
        columns={DotaColums}
        dataSource={dataTabel.map((item, index) => ({ ...item, key: index }))}
        style={{ width: "1500px" }}
      />
    </>
  );
}
export default TabelLandRegistrationSet;
