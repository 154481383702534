import { React } from 'react'


const MainexpediteTheDebt = () => {
    return (
        <>
            MainexpediteTheDebt
        </>
    )
}

export default MainexpediteTheDebt