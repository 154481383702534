import React from 'react';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Divider, Row, Col, Card, Modal, Space, message, notification } from 'antd';

import GModalEditPhone from './Modals/Contact/EditPhone';
import AddPhone from './Modals/Contact/AddPhone';
import { deletephone } from '../../../../file_mid/all_api';

export default function GPhone({ close, next, prev, dataPhone, setDataPhone, dataCustomer }) {

    const [form] = Form.useForm();
    const [index1, setIndex] = useState(); //set index send to page edit
    const [dataeditP, setDataEditP] = useState([]); //เซตสำหรับแก้ไขข้อมูล Phone
    const [api, contextHolder] = notification.useNotification();
    const { confirm } = Modal

    // Open Modals
    const [modalAddPhone, setModalAddPhone] = useState(false);
    const [modalEditPhone, setModalEditPhone] = useState(false);

    const [phones, setPhones] = useState([]); // เก็บ Telp

    useEffect(() => {
        if (dataPhone !== undefined) {
            setPhones(dataPhone)
        }
    }, [])

    const DeleteSuccess = (placement) => {
        api.success({
            message: <b>ลบข้อมูลสำเร็จ</b>,
            placement,
        });
    }
    const AddSuccess = (placement) => {
        api.success({
            message: <b>เพิ่มข้อมูลสำเร็จ</b>,
            placement,
        });
    }
    const EditSuccess = (placement) => {
        api.success({
            message: <b>แก้ไขข้อมูลสำเร็จ</b>,
            placement,
        });
    }
    const NotPass = (placement) => {
        api.warning({
            message: <b>กรุณาเพิ่มเบอร์โทรอย่างน้อย 1 เบอร์</b>,
            placement,
        });
    }

    const showConfirm = (value, index) => {
        // console.log("value", value)
        confirm({
            title: 'คุณต้องการที่จะลบข้อมูลหรือไม่...?',
            content: 'กด OK เพื่อยืนยันการลบ',
            onOk() {
                // console.log('OK');
                const deletearray = [...phones]
                deletearray.splice(index, 1)
                setPhones(deletearray)
                if (value.phoneId !== 0) {
                    deleteDataPhone(value)
                } else {
                    DeleteSuccess("top")
                }
            },
            onCancel() {
                message.error('ยกเลิกการลบข้อมูล')
                // console.log('Cancel');
            },
        });
    }

    const deleteDataPhone = async (value) => {
        const test2 = value
        await axios.delete(deletephone, { data: test2, })
            .then((res) => {
                if (res.status === 200) {
                    DeleteSuccess("top")
                }
            }).catch((err) => console.log(err))
    }

    const handleSubmit = () => {
        if (phones.length === 0 || phones === undefined) {
            NotPass("top")
        } else {
            setDataPhone(phones)
            next()
        }
    }

    const handleCancel = () => {
        close(false)
    }

    const onChangeBack = () => {
        if (phones.length === 0 || phones === undefined) {
            prev()
        } else {
            setDataPhone(phones)
            prev()
        }
    }


    const fucnAddPhone = (value) => {
        if (phones.length === 0) {
            setPhones(pre => [...pre, { ...value.dataPhone }]);
        } else {
            setPhones(pre => [...pre, { ...value.dataPhone }]);
        }
        AddSuccess("top")
    }

    // แก้ไขข้อมูลจากหน้า AddressEditData
    const fucnEditPhone = ({ getData, getIndex }) => {
        let Theedit = [...phones]
        Theedit[getIndex] = getData
        //ข้อมูลใหม่แทนที่ข้อมูลเดิมในช่อง Input
        setPhones(Theedit);
        EditSuccess("top")
    }

    const renderPhone = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
        // index ตอนนี้เป็นข้อมูลจาก Object
        const handleEditPhone = (dataedit, index1) => {
            setIndex(index1) //เซตค่า index ให้กับชุด ข้อมูล
            setDataEditP({ dataedit, index1 }) //ส่งข้อมูลด index ไปหน้าแก้ไข
            setModalEditPhone(true);
            //console.log("edit")
        }
        return (
            <Form form={form}>
                <Row style={{ margin: '3px' }} justify={'center'} >
                    <Card
                        key={index} // index เป็นตัวกำหนดคีย์
                        style={{ textAlign: 'center' }}
                        title={<u>{item.phoneType}</u>}
                    >
                        {/* <div > */}
                        <Col span={24} style={{ textAlign: 'left' }}>
                            <Form.Item style={{ marginBottom: 0 }} label='เบอร์'>
                                {item.telp}
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='sendSms'>
                                {
                                    item.sendSms === "0" && ("ไม่ส่ง")
                                }
                                {
                                    item.sendSms === "1" && ("ส่ง")
                                }
                            </Form.Item>
                            {/* <Form.Item style={{ marginBottom: 0 }} label="หมายเหตุ">
                                {item.note}
                            </Form.Item> */}
                            <Divider></Divider>
                            <Row justify={'center'} gutter={32}>
                                <Form.Item>
                                    {/* ปุ่มลบข้อมูล */}
                                    <Button
                                        style={{ margin: '10px', background: '#f5222d', color: 'white', marginBottom: 0 }}
                                        icon={<DeleteOutlined />}
                                        onClick={() => showConfirm(phones[index], index)}
                                    >ลบ</Button>
                                </Form.Item>
                                <Form.Item>
                                    {/* ปุ่มแก้ไขข้อมูล */}
                                    <Button
                                        style={{ margin: '10px', background: '#bfbfbf', color: '#ffffff', marginBottom: 0 }}
                                        type='submit'
                                        onClick={() => { handleEditPhone(phones[index], index) }}
                                    >แก้ไข</Button>
                                </Form.Item>
                            </Row>
                        </Col>
                    </Card>
                </Row>
            </Form>
        )
    }

    return (
        <>
            <Card style={{ color: '#4096ff' }}>
                <Form>
                    <Row>
                        <Col className='gutter-row' span={24}>
                            <Col span={24} style={{ fontSize: '30px', textAlign: 'center' }} >ช่องทางการติดต่อ</Col>
                            <Divider />
                            <Row gutter={32}>
                                <Col span={24} style={{ textAlign: 'right' }}>
                                    {/* ปุ่มเพิ่มข้อมูล */}
                                    <Button type='primary' onClick={() => { setModalAddPhone(true); }}> <PlusOutlined />เบอร์ติดต่อ</Button>
                                </Col>
                            </Row>
                            <Row justify={'center'}>
                                {
                                    phones.map((item, index) => {
                                        return renderPhone({ item, index, key: `{item.identificationId} - ${index}` })
                                    })
                                }
                            </Row>
                        </Col>
                    </Row>
                    <Divider />
                    <div style={{ textAlign: "center" }} >
                        <Space>
                            <Button type="primary" style={{ background: '#bfbfbf', color: '#000000', }} onClick={onChangeBack}>ย้อนกลับ</Button>
                            <Button type="primary" onClick={handleCancel} style={{ background: "red" }}>ยกเลิก</Button>
                            <Button type="primary" htmlType="submit" onClick={handleSubmit} >ต่อไป</Button>
                        </Space>
                    </div>
                </Form>
                {contextHolder}
            </Card>
            {
                //เพื่มข้อมูลในการ์ด
                modalAddPhone ?
                    <AddPhone
                        open={modalAddPhone}
                        close={setModalAddPhone}
                        fucnAdd={fucnAddPhone} // ดึงข้อมูลจากมาจากหน้า ModalAddDataAddress
                        dataCustomer={dataCustomer}
                    />
                    : null
            }
            {
                //แก้ไขข้อมูลในการ์ด
                modalEditPhone ?
                    <GModalEditPhone
                        open={modalEditPhone} // เซตการเปิด Modal จากหน้า AddressEditData
                        close={setModalEditPhone} // เซตการปิด Modal จากหน้า AddressEditData
                        shootdata={dataeditP} // 
                        fucnEdit={fucnEditPhone}
                        dataindex={index1}
                    />
                    : null
            }
        </>
    )

};
