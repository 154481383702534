//function dotaColums() {
//รถ
const arrCase = [];
arrCase[0] = "รออนุมัติราคารถ"                 //รอ
arrCase[1] = "รอธุรการรับ"                    //รอ
arrCase[2] = "รอวิเคราะห์"                    //รอ
arrCase[3] = "ผ่านการวิเคราะห์"                //รอ
arrCase[4] = "ไม่ผ่านการวิเคราะห์"              //รอ
arrCase[5] = "รออนุมัติ"                      //รอ
arrCase[6] = "อนุมัติ"                        //รอ
arrCase[7] = "ไม่อนุมัติ"                      //รอ
arrCase[8] = "ลูกค้าคอนเฟิร์ม"                 //รอ
arrCase[9] = "รอตรวจสอบเอกสาร"             //รอ

arrCase[10] = "ลูกค้าปฏิเสธ"                //ไม่ได้ยอด
arrCase[11] = "Reject"                  //ไม่ได้ยอด

arrCase[12] = "รอทำสัญญา"               //ได้ยอด
export default arrCase;
