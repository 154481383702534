import { createSlice } from "@reduxjs/toolkit";


const initialState = {

}

const guarantorDataSlice = createSlice({
    name: "guarantor",
    initialState,
    reducers: {

        addGuarantor: (state, action) => {
            state.data = action.payload
            // console.log(current(state))
        },

    }

})

export const { addGuarantor } = guarantorDataSlice.actions
export default guarantorDataSlice.reducer