import React, { useEffect, useState } from 'react'
import { Button, Input, Form, DatePicker, Card, Col, Row, Select, Radio, message, Space, Divider, Spin } from 'antd';
import dayjs from 'dayjs';
import axios from 'axios';
import '../../../css/Media.css'
import TextArea from 'antd/es/input/TextArea';
import { useSelector } from 'react-redux';
import { checkCCAP, edit, testupload } from '../../../file_mid/all_api';

function Customer({ getData, close, page, changepage, notification, notification2, sendback }) {

    // console.log('getData', getData)
    const guarantor = useSelector(state => state.guarantor)

    const [dataModalEdit, setDataModalEdit] = useState(getData);
    // const [messageApi, contextHolder] = message.useMessage();
    const [form] = Form.useForm()
    const [dataAddress, setDataAddress] = useState({}) // address
    const [count] = useState(false)
    /////////////////////////////////////////////////////////////////////////////////////////

    // const [idOpen, setIdOpen] = useState(false)
    // const [fileList, setFileList] = useState() // รูปบัตรประชาชน
    const [loading, setLoading] = useState(false)

    // console.log("guarantor", guarantor.data.typeLoan)

    useEffect(() => {
        setDataModalEdit(getData)

        if (getData !== "") {
            form.setFieldsValue(
                {
                    ...getData,
                    birthDate: dayjs(getData?.birthdate),
                    issudt: dayjs(getData?.issudt),
                    expdt: dayjs(getData?.expdt),
                }
            )
        }
        if (guarantor.data.typeLoan === "car") {
            if (getData?.carLoanDetails?.notPass?.loanId !== 0) {
                form.setFieldsValue(
                    {
                        ...getData?.carLoanDetails?.notPass
                    }
                )
            }
            if (getData?.carLoanDetails?.noteResend?.loanId !== 0) {
                form.setFieldsValue(
                    {
                        ...getData?.carLoanDetails?.noteResend
                    }
                )
            }
        } else {
            if (getData?.landLoanDetails?.notPass?.loanId !== 0) {
                form.setFieldsValue(
                    {
                        ...getData?.landLoanDetails?.notPass
                    }
                )
            }
            if (getData?.landLoanDetails?.noteResend?.loanId !== 0) {
                form.setFieldsValue(
                    {
                        ...getData?.landLoanDetails?.noteResend
                    }
                )
            }
        }

    }, [getData])

    const handleCancel = () => {
        close(false);
    };
    const handleChangepage = () => {
        changepage(page + 1)
    };

    const handleSubmit = async (e) => {
        await axios.put(edit, dataModalEdit)
            .then(res => {
                if (res.status === 200) {
                    notification('top')
                    sendback(1)
                    setTimeout(() => {
                        handleChangepage()
                    }, 1000)
                }
            }).catch((err) => {
                if (err.response.request.status === 400) {
                    notification2('top')
                }
                console.log("err", err)
            })
    }
    ////////////// ข้อมูลยูสในเดต้าเบส ////////////////////////////////////////////////////////////////
    const loadDataOne = async (data) => {
        //splitAddress(data)
        const one = { identificationId: data.identificationId }
        setLoading(true)
        await axios.post(checkCCAP, one)
            .then((res) => {
                //console.log("DATA", res.data)
                if (res.data) {
                    //alert('มีข้อมูลในฐานข้อมูล')
                    message.info('มีข้อมูลในฐานข้อมูล')
                    //setIsModalOpen(true)
                    if (window.confirm('คุณต้องการอัพเดตข้อมูลหรือไม่ ?')) {
                        //splitAddress(res.data)          
                        // Save it!
                        setDataModalEdit(res.data)
                        // setDataPhone(res.data.phones[0])

                        setDataAddress(res.data.address[0])
                        if (res.data.expdt == null || res.data.issudt == null) {
                            form.setFieldsValue(
                                {
                                    ...res.data,
                                    firstName: res.data?.firstname,
                                    lastName: res.data?.lastname,
                                    telp: res?.data?.phones[0]?.telp,
                                    birthDate: dayjs(res?.data?.birthdate),
                                    issudt: '',
                                    expdt: '',
                                }
                            )
                            setLoading(false)
                        }
                        else {
                            form.setFieldsValue(
                                {
                                    ...res.data,
                                    firstName: res.data?.firstname,
                                    lastName: res.data?.lastname,
                                    telp: res?.data?.phones[0]?.telp,
                                    birthDate: dayjs(res?.data?.birthdate),
                                    issudt: dayjs(res?.data?.issudt),
                                    expdt: dayjs(res?.data?.expdt),
                                }
                            )
                        }
                    } else {
                        // Do nothing!
                        //console.log('cancel');
                        setDataModalEdit({ ...dataModalEdit, ...data, customerId: res.data.customerId })
                        // setDataPhone(res.data.phones[0])
                        setDataAddress(res.data.address[0])
                        //splitAddress(res.data)
                        if (data.expdt == null || data.issudt == null) {
                            form.setFieldsValue(
                                {
                                    ...data,
                                    firstName: res.data?.firstname,
                                    lastName: res.data?.lastname,
                                    birthDate: dayjs(data?.birthdate),
                                    issudt: '',
                                    expdt: '',
                                }
                            )
                            setLoading(false)
                        }
                        else {
                            form.setFieldsValue(
                                {
                                    ...data,
                                    firstName: res.data?.firstname,
                                    lastName: res.data?.lastname,
                                    birthDate: dayjs(data?.birthdate),
                                    issudt: dayjs(data?.issudt),
                                    expdt: dayjs(data?.expdt),
                                }
                            )
                        }
                    }
                    setLoading(false)
                }
                else {
                    //alert('ไม่พบข้อมูลในฐานข้อมูล')
                    message.info('ไม่พบข้อมูลในฐานข้อมูล')
                    setDataModalEdit(data)
                    //setDataAddress('')
                    //setDataPhone('')
                    if (data.expdt == null || data.issudt == null) {
                        form.setFieldsValue(
                            {
                                ...data,
                                nickname: '',
                                gender: '',
                                birthDate: dayjs(data?.birthdate),
                                issudt: '',
                                expdt: '',
                                telp: '',
                            }
                        )
                        setLoading(false)
                    }
                    else {
                        form.setFieldsValue(
                            {
                                ...data,
                                nickname: '',
                                gender: '',
                                birthDate: dayjs(data?.birthdate),
                                issudt: '',
                                expdt: '',
                                telp: '',
                            }
                        )
                    }
                    setLoading(false)
                }
            })
            .catch((err) => console.log(err))
        setLoading(false)
    }
    /////////// ดึงข้อมูลจากรูปบัตรประชาชน /////////////////////////////////////////////////////////////////  
    const handleScanIdCard = async (value) => {
        const imgId = { image: value }
        setLoading(true)
        const headers = { 'Content-Type': 'application/json' }
        await axios.post(testupload, imgId, { headers: headers })
            .then(res => {
                console.log("img", res.data)
                if (res.data) {
                    setDataModalEdit(res.data[0])
                    splitAddress(res.data[0])
                    loadDataOne(res.data[0])
                    setLoading(false)
                }
                else {
                    message.info('ไม่สามารถอ่านได้')
                    setDataAddress('')
                    // setDataPhone('')
                    setLoading(false)
                }
            })
            .catch((err) => console.log(err))
        setLoading(false)
    }
    ////////////// รับไฟล์รูปบัตร /////////////////////////////////
    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg']
        if (file) {
            if (validImageTypes.includes(file.type)) {
                const reader = new FileReader(); // สร้าง FileReader object
                reader.onloadend = () => { // ฟังก์ชันที่ทำงานเมื่ออ่านไฟล์เสร็จสิ้น    
                    const base64Data = reader.result; // รับข้อมูล Base64 จาก FileReader result
                    //console.log('Base64 Data:', base64Data);
                    // setFileList({ image: base64Data })
                    handleScanIdCard(base64Data)
                }
                reader.readAsDataURL(file) // อ่านไฟล์และแปลงเป็น Base64    
            }
        }

    }
    //////////////// เก็บแอสเดรส ////////////////////////////////////
    const splitAddress = (value) => {
        let updatedCount = { ...count }
        //console.log("โหลด",value.address)
        const part = value.address.split(" ")
        if (part[1].includes("หมู่ที่") === true) {
            //setHouseNumber(part[0] + part[1] + part[2])
            updatedCount.houseNumber = part[0] + part[1] + part[2]
        } else {
            //setHouseNumber(part[0])
            updatedCount.houseNumber = part[0]
        }
        //setMoo(part[2])
        //setDataAddress({...dataAddress, moo: part[2]})
        for (let i = 0; i <= part.length; i++) {
            if (part[i]?.includes("ซ.") === true) {
                const s = part[i].split("ซ.")
                //setSoi(s[1])
                updatedCount.soi = s[1]
            }
            if (part[i]?.includes("ถ.") === true) {
                const tanon = part[i].split("ถ.")
                //setRoad(tanon[1])
                updatedCount.road = tanon[1]
            }
            if (part[i]?.includes("ต.") === true) {
                const bn = part[i].split("ต.")
                //setSubdistrict(bn[1])
                updatedCount.subdistrict = bn[1]
            }
            if (part[i]?.includes("อ.") === true) {
                const oo = part[i].split("อ.")
                const oop = oo[1].split(",")
                if (oo[1]?.includes(",") === false) {
                    //setDistrict(oo[1])
                    updatedCount.district = oo[1]
                }
                if (oop[1]?.includes("จ.") === true) {
                    //setDistrict(oop[0])
                    updatedCount.district = oop[0]
                    const oops = oop[1].split("จ.")
                    //setProvince(oops[1])
                    updatedCount.province = oops[1]
                }
            }
            if (part[i]?.includes("จ.") === true) {
                const jh = part[i].split("จ.")
                //setProvince(jh[1])
                updatedCount.province = jh[1]
            }
        }
        setDataAddress({
            ...dataAddress,
            houseNumber: updatedCount.houseNumber, soi: updatedCount.soi, road: updatedCount.road, subdistrict: updatedCount.subdistrict, district: updatedCount.district, province: updatedCount.province,
        })
    }

    const handleProduct = (value) => {
        setDataModalEdit({ ...dataModalEdit, nationalId: value })
    }
    const handleLastName = (value) => {
        setDataModalEdit({ ...dataModalEdit, lastname: value })
    }
    const handletypeCompany = (value) => {
        // setTypeCompany(value)
        setDataModalEdit({ ...dataModalEdit, snam: value })
    }

    return (
        <div>
            <Card style={{ padding: 20 }} className="modal-img">
                <div style={{ textAlign: 'center' }}>
                    <Row style={{ textAlign: 'center' }} >
                        <Col span={24} style={{ fontSize: '30px' }} >ข้อมูลส่วนตัว</Col>
                    </Row>
                    <Divider></Divider>
                    <Spin spinning={loading} size='large' tip=" Loading... ">
                        <Form
                            labelCol={{
                                span: 12,
                            }}
                            wrapperCol={{
                                span: 24,
                            }}

                            form={form}
                            onFinish={handleSubmit} >
                            <Row justify={'center'}>
                                <Col span={24}>
                                    {
                                        guarantor.data.typeLoan === "car" ?
                                            <Row>
                                                {/* <Row> */}
                                                {
                                                    getData?.carLoanDetails?.notPass?.loanId !== 0 ?
                                                        <>
                                                            {
                                                                getData?.carLoanDetails?.notPass?.npCustomer === false ?
                                                                    <>
                                                                        <Col span={12}>
                                                                            <Form.Item label='หมายเหตุ(ธุรการ)' name='npCustomerNote' style={{ textAlign: 'center' }}>
                                                                                <TextArea rows={5} name='npCustomerNote' disabled />
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </>
                                                                    : null
                                                            }
                                                        </>
                                                        : null
                                                }
                                                {
                                                    getData?.carLoanDetails?.noteResend?.loanId !== 0 ?
                                                        <>
                                                            {
                                                                getData?.carLoanDetails?.noteResend?.customerNote !== null ?
                                                                    <>
                                                                        <Col span={12}>
                                                                            <Form.Item label='หมายเหตุ(การตลาด)' name='customerNote' style={{ textAlign: 'center' }}>
                                                                                <TextArea rows={5} name='customerNote' disabled />
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </>
                                                                    : null
                                                            }
                                                        </>
                                                        : null
                                                }
                                                {/* </Row> */}
                                            </Row>
                                            :
                                            <Row>
                                                {
                                                    getData?.landLoanDetails?.notPass?.loanId !== 0 ?
                                                        <>
                                                            {
                                                                getData?.landLoanDetails?.notPass?.npCustomer === false ?
                                                                    <>
                                                                        <Col span={12}>
                                                                            <Form.Item label='หมายเหตุ(ธุรการ)' name='npCustomerNote' style={{ textAlign: 'center' }}>
                                                                                <TextArea rows={5} name='npCustomerNote' disabled />
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </>
                                                                    : null
                                                            }
                                                        </>
                                                        : null
                                                }
                                                {
                                                    getData?.landLoanDetails?.noteResend?.loanId !== 0 ?
                                                        <>
                                                            {
                                                                getData?.landLoanDetails?.noteResend?.customerNote !== null ?
                                                                    <>
                                                                        <Col span={12}>
                                                                            <Form.Item label='หมายเหตุ(การตลาด)' name='customerNote' style={{ textAlign: 'center' }}>
                                                                                <TextArea rows={5} name='customerNote' disabled />
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </>
                                                                    : null
                                                            }
                                                        </>
                                                        : null
                                                }
                                            </Row>
                                    }
                                </Col>
                                <Divider />
                                <Col span={24}>
                                    <Row justify={'center'}>
                                        <Form.Item label='ประเภท' name='nationalId'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input !',
                                                },]}>
                                            <Select
                                                placeholder={'เลือก'}
                                                style={{ width: '200px', height: '40px', }}
                                                defaultValue={'บุคคลธรรมดา'}
                                                name='legal'
                                                onChange={(value) => handleProduct(value)}
                                                options={[
                                                    {
                                                        label: 'บุคคลธรรมดา',
                                                        value: 'บุคคลธรรมดา',
                                                    },
                                                    {
                                                        label: 'นิติบุคคล',
                                                        value: 'นิติบุคคล',
                                                    },
                                                ]}
                                            />
                                        </Form.Item>
                                    </Row>
                                    {
                                        dataModalEdit?.nationalId === "นิติบุคคล" ?
                                            <>
                                                <Row className='main2' justify={'center'}>
                                                    <aside style={{ width: '75%' }}>
                                                        <div>
                                                            <Form.Item label='ประเภทห้าง' name='snam'
                                                                style={{ textAlign: 'center', width: '300px' }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !',
                                                                    },]}>
                                                                <Select
                                                                    placeholder={'เลือก'}
                                                                    style={{ width: '250px', height: '40px', }}
                                                                    name='snam'
                                                                    initialvalues={1}
                                                                    onChange={(value) => handletypeCompany(value)}
                                                                    options={[
                                                                        {
                                                                            label: 'หจก.',
                                                                            value: 'หจก',
                                                                        },
                                                                        {
                                                                            label: 'บริษัท',
                                                                            value: 'บริษัท',
                                                                        },
                                                                    ]}
                                                                />


                                                            </Form.Item>
                                                        </div>
                                                        <div>
                                                            <Form.Item
                                                                label='เลขประจำตัวผู้เสียภาษี'
                                                                // name='taxID'
                                                                name='identificationId'
                                                                style={{ width: '300px' }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !',
                                                                    },]}>

                                                                <Input type='text' maxLength={13}
                                                                    name='identificationId'
                                                                    // disabled={CheckIdCard ? false : true}
                                                                    style={{ width: '200px', marginRight: '5px', marginLeft: '5px', color: 'black' }}
                                                                    onChange={(e) => setDataModalEdit({ ...dataModalEdit, identificationId: e.target.value })}
                                                                />

                                                            </Form.Item>

                                                        </div>

                                                        <div>
                                                            <Form.Item label='ชื่อบริษัท' name='firstname'
                                                                style={{ textAlign: 'center', width: '300px' }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !',
                                                                    },]}
                                                            >
                                                                <Input type='text'
                                                                    style={{ width: '250px', color: 'black' }}
                                                                    onChange={(e) => setDataModalEdit({ ...dataModalEdit, firstname: e.target.value })}>

                                                                </Input>

                                                            </Form.Item>
                                                        </div>
                                                        <div>
                                                            <Form.Item label='' name='lastname'
                                                                style={{ textAlign: 'left', width: '300px' }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !',
                                                                    },]}
                                                            >
                                                                <Select
                                                                    placeholder={'เลือก'}
                                                                    style={{ textAlign: 'left', height: '40px', width: '100px' }}
                                                                    name='lastname'
                                                                    initialvalues={'จำกัด'}
                                                                    onChange={(value) => handleLastName(value)}
                                                                    options={[
                                                                        {
                                                                            label: 'มหาชน',
                                                                            value: 'มหาชน',
                                                                        },
                                                                        {
                                                                            label: 'จำกัด',
                                                                            value: 'จำกัด',
                                                                        },
                                                                    ]}
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                        <div>
                                                            <Form.Item label='วันจดทะเบียน' name='birthDate'
                                                                style={{ textAlign: 'center', width: '300px' }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !',
                                                                    },]}>
                                                                <DatePicker format={'YYYY-MM-DD'}
                                                                    style={{ height: '40px', width: '250px', color: 'black' }}
                                                                    onChange={(e) => {
                                                                        if (e) {
                                                                            setDataModalEdit({ ...dataModalEdit, birthDate: e.format('YYYY-MM-DD') })
                                                                        }
                                                                    }} />
                                                            </Form.Item>
                                                        </div>


                                                    </aside>
                                                </Row>
                                            </>
                                            :
                                            <>
                                                <Row className='main2' justify={'center'}>
                                                    <aside style={{ width: '75%' }}>
                                                        <div>
                                                            <Form.Item label='เลขประจำตัวประชาชน' name='identificationId'
                                                                style={{ textAlign: 'center', width: '320px' }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !',
                                                                    },]}>
                                                                <Input name='identificationId' type='text' maxLength={13}
                                                                    style={{ width: '200px' }}
                                                                    // defaultValue={dataModalEdit.identificationId}
                                                                    onChange={(e) => setDataModalEdit({ ...dataModalEdit, identificationId: e.target.value })} />
                                                            </Form.Item>
                                                        </div>
                                                        <div>
                                                            <Form.Item label='รูปบัตรประชาชน' style={{ textAlign: 'center', width: '320px' }}>
                                                                <Input type="file" multiple={true}
                                                                    style={{ width: '200px' }}
                                                                    onChange={handleFileInputChange} >
                                                                </Input>
                                                            </Form.Item>
                                                        </div>
                                                        <div>
                                                            <Form.Item label="คำนำหน้าชื่อ" name="snam"
                                                                style={{ textAlign: 'center', width: '320px' }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !',
                                                                    },]}>
                                                                <Radio.Group
                                                                    style={{ width: '250px' }}
                                                                    // defaultValue={dataModalEdit.snam}
                                                                    onChange={(e) => setDataModalEdit({ ...dataModalEdit, snam: e.target.value })} >
                                                                    <Radio value="นาย"> นาย </Radio>
                                                                    <Radio value="นาง"> นาง </Radio>
                                                                    <Radio value="นางสาว"> นางสาว </Radio>
                                                                </Radio.Group>
                                                            </Form.Item>
                                                        </div>
                                                        <div>
                                                            <Form.Item label='ชื่อ' name='firstname'
                                                                style={{ textAlign: 'center', width: '320px' }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !',
                                                                    },]}>
                                                                <Input type='text' name='firstname' style={{ width: '200px' }}
                                                                    // defaultValue={dataModalEdit.firstName}
                                                                    onChange={(e) => setDataModalEdit({ ...dataModalEdit, firstName: e.target.value })}></Input>
                                                            </Form.Item>
                                                        </div>
                                                        <div>


                                                            <Form.Item label='นามสกุล' name='lastname'
                                                                style={{ textAlign: 'center', width: '320px' }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !',
                                                                    },]} >
                                                                <Input type='text' style={{ width: '200px' }}
                                                                    // defaultValue={dataModalEdit.lastName}
                                                                    onChange={(e) => setDataModalEdit({ ...dataModalEdit, lastName: e.target.value })}></Input>
                                                            </Form.Item>
                                                        </div>
                                                        <div>
                                                            <Form.Item label='ชื่อเล่น' name='nickname'
                                                                style={{ textAlign: 'center', width: '320px' }}>
                                                                <Input type='text' style={{ width: '200px' }}
                                                                    // defaultValue={dataModalEdit.nickname}
                                                                    onChange={(e) => setDataModalEdit({ ...dataModalEdit, nickname: e.target.value })}></Input>
                                                            </Form.Item>
                                                        </div>
                                                        <div>
                                                            <Form.Item label='เกิดวันที่' name='birthDate'
                                                                style={{ textAlign: 'center', width: '320px' }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !',
                                                                    },]} >
                                                                <DatePicker
                                                                    style={{ width: '200px' }}
                                                                    format={'YYYY-MM-DD'}
                                                                    name='birthDate'
                                                                    onChange={(e) => {
                                                                        if (e) {
                                                                            setDataModalEdit({ ...dataModalEdit, birthDate: e.format('YYYY-MM-DD') })
                                                                        }
                                                                    }} />
                                                                {/* <Yearpick
                                            picker="year"
                                            // name="birthDate"
                                            onChange={(e, string) => {
                                                console.log('e', e.$y)
                                            }} /> */}
                                                            </Form.Item>
                                                        </div>
                                                        <div>
                                                            <Form.Item label='เพศ' name='gender'
                                                                style={{ textAlign: 'center', width: '320px' }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !',
                                                                    },]} >
                                                                <Select
                                                                    placeholder={'เลือก'}
                                                                    style={{ width: '200px' }}
                                                                    // defaultValue={dataModalEdit.gender}
                                                                    onChange={(value) => setDataModalEdit({ ...dataModalEdit, gender: value })}
                                                                    options={[
                                                                        {
                                                                            label: 'ชาย',
                                                                            value: 'M',
                                                                        },
                                                                        {
                                                                            label: 'หญิง',
                                                                            value: 'F',
                                                                        },
                                                                    ]} />
                                                            </Form.Item>
                                                        </div>
                                                        <div>
                                                            <Form.Item label='เจ้าพนักงานออกบัตร' name='issuby'
                                                                style={{ textAlign: 'center', width: '320px' }}
                                                            // rules={[
                                                            //     {
                                                            //         required: true,
                                                            //         message: 'Please input !',
                                                            //     },]}
                                                            >
                                                                <Input type='text' style={{ width: '200px' }}
                                                                    // defaultValue={dataModalEdit.issuby}
                                                                    onChange={(e) => setDataModalEdit({ ...dataModalEdit, issuby: e.target.value })}></Input>
                                                            </Form.Item>
                                                        </div>
                                                        <div>
                                                            <Form.Item label='วันออกบัตร' name='issudt'
                                                                style={{ textAlign: 'center', width: '320px' }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !',
                                                                    },]} >
                                                                <DatePicker
                                                                    style={{ width: '200px' }}
                                                                    format={'YYYY-MM-DD'}
                                                                    name='issudt'
                                                                    // defaultValue={convertDate(dataModalEdit.issudt)}
                                                                    onChange={(e) => {
                                                                        if (e) {
                                                                            setDataModalEdit({ ...dataModalEdit, issudt: e.format('YYYY-MM-DD') })
                                                                        }
                                                                    }} />
                                                            </Form.Item>
                                                        </div>
                                                        <div>
                                                            <Form.Item label='วันบัตรหมดอายุ' name='expdt'
                                                                style={{ textAlign: 'center', width: '320px' }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please input !',
                                                                    },]} >
                                                                <DatePicker
                                                                    style={{ width: '200px' }}
                                                                    format={'YYYY-MM-DD'}
                                                                    // defaultValue={convertDate(dataModalEdit.expdt)}
                                                                    onChange={(e) => {
                                                                        if (e) {
                                                                            setDataModalEdit({ ...dataModalEdit, expdt: e.format('YYYY-MM-DD') })
                                                                        }
                                                                    }} />
                                                            </Form.Item>
                                                        </div>
                                                    </aside>

                                                </Row>
                                            </>
                                    }
                                </Col>
                            </Row>
                            <Divider></Divider>
                            <div style={{ textAlign: "center" }} >
                                <Space>
                                    <Button type="primary" style={{ background: "red" }} onClick={handleCancel}>
                                        ยกเลิก
                                    </Button>
                                    <Button type="primary" htmlType="submit" style={{ background: "green" }} >
                                        บันทึก
                                    </Button>
                                    <Button type="primary" onClick={handleChangepage}>
                                        ต่อไป
                                    </Button>
                                </Space>
                            </div>
                        </Form>
                    </Spin>
                </div >
            </Card >
            {/* {contextHolder} */}
        </div >
    )
};

export default Customer