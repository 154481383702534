import React, { useState, useEffect } from 'react'
import { Form, Card, Col, Row, Button, Divider, Space, Spin, Modal, message } from 'antd';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { DeleteOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import AddOcc from './Occ/AddOcc'
import EditOcc from './Occ/EditOcc'
import TextArea from 'antd/es/input/TextArea';
import { loadOJS, newdeletecustomerocc } from '../../../file_mid/all_api';

function Career({ page, close, datashow, changepage, sendback, getData }) {

  const { confirm } = Modal
  const guarantor = useSelector(state => state.guarantor)
  const customers = useSelector(state => state.customers)
  // const { customers } = useSelector((state) => ({ ...state }))
  // const { guarantor } = useSelector((state) => ({ ...state }))
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  // const [cus, setCus] = useState({}) // customerId
  // const [dataAddress, setDataAddress] = useState([])
  const [dataAddress2, setDataAddress2] = useState([])
  const [dataedit, setDataEdit] = useState([]); //เซตสำหรับแก้ไขข้อมูล 
  const [index1, setIndex] = useState(); //set index send to page edit

  const [modalAddOcc, setModalAddOcc] = useState(false)
  const [modaleditdata, setModalEditData] = useState(false)

  // console.log("datashow", datashow)

  useEffect(() => {
    // setCus({ customerId: customers.data })
    // setDataAddress(datashow)
    loadCareer()
    if (guarantor.data.typeLoan === "car") {
      if (getData?.carLoanDetails?.notPass?.loanId !== 0) {
        form.setFieldsValue(
          {
            ...getData?.carLoanDetails?.notPass
          }
        )
      }
      if (getData?.carLoanDetails?.noteResend?.loanId !== 0) {
        form.setFieldsValue(
          {
            ...getData?.carLoanDetails?.noteResend
          }
        )
      }
    } else {
      if (getData?.landLoanDetails?.notPass?.loanId !== 0) {
        form.setFieldsValue(
          {
            ...getData?.landLoanDetails?.notPass
          }
        )
      }
      if (getData?.landLoanDetails?.noteResend?.loanId !== 0) {
        form.setFieldsValue(
          {
            ...getData?.landLoanDetails?.noteResend
          }
        )
      }
    }
  }, [datashow])

  const loadCareer = async () => {
    const id = { customerId: customers.data }
    setLoading(true)
    await axios.post(loadOJS, id)
      .then((res) => {
        setDataAddress2(res.data)
        setLoading(false)
      })
      .catch((err) => console.log(err))
    setLoading(false)
  }

  const onChangeBack = () => {
    changepage(page - 1)
  }

  const onChangeGo = () => {
    changepage(page + 1)
  }

  const handleCancel = () => {
    close(false)
  }

  // const error = () => {
  //   Modal.error({
  //     title: 'บันทึกไม่สำเร็จ',
  //     content: 'กรุณาตรวจสอบข้อมูลอีกครั้ง',
  //   })
  // }

  const successDelete = () => {
    Modal.success({
      title: 'สำเร็จ',
      content: 'ลบข้อมูลสำเร็จ',
    })
  }
  const errorDelete = () => {
    Modal.error({
      title: 'ไม่สำเร็จ',
      content: 'ลบข้อมูลไม่สำเร็จ กรุณาตรวจสอบข้อมูลอีกครั้ง',
    })
  }


  const fucnAdd = (value) => {
    // console.log("fucnAdd", value)
    // // setDataAddress(pre => [...pre, { ...value.req.ojs, ...value.req.income, ...value.req.expenses }]);
    // setDataAddress(pre => [...pre, { ...value.req.ojs, ...value.req, expensesMonth: value.req.expenses }]);
    // sendback(dayjs())
  }
  const fucnEdit = ({ getData, getIndex }) => {
    // console.log("fucnEdit", getData)
    //let Theedit = [...dataAddress]
    let Theedit = [...dataAddress2]
    Theedit[getIndex] = getData
    //ข้อมูลใหม่แทนที่ข้อมูลเดิมในช่อง Input
    // setDataAddress(Theedit);
    setDataAddress2(Theedit);
    sendback(dayjs())
  }

  const deleteData = async (value) => {
    setLoading(true)
    const test2 = value
    await axios.delete(newdeletecustomerocc, { data: test2, })
      .then((res) => {
        if (res.status === 200) {
          sendback(dayjs())
          setLoading(false)
          successDelete()
        }
        else {
          errorDelete()
        }
      }).catch((err) => errorDelete())
    setLoading(false)
  }

  const showConfirm = (value, index) => {
    confirm({
      title: 'คุณต้องการที่จะลบข้อมูลหรือไม่...?',
      content: 'กด OK เพื่อยืนยันการลบ',
      onOk() {
        //const deletearray = [...dataAddress]
        const deletearray = [...dataAddress2]
        deletearray.splice(index, 1)
        // setDataAddress(deletearray)
        setDataAddress2(deletearray)
        deleteData(value)
      },
      onCancel() {
        message.error('ยกเลิกการลบข้อมูล')
      },
    });
  };

  const renderItem = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์

    // index ตอนนี้เป็นข้อมูลจาก Object
    const handleEdit = (dataedit, index1) => {
      //console.log("dataedit",dataedit) // ข้อมูล
      //console.log("index1",index1) // ตำแหน่งช่องข้อมูล
      setIndex(index1) //เซตค่า index ให้กับชุด ข้อมูล
      setDataEdit({ dataedit, index1 }) //ส่งข้อมูลด index ไปหน้าแก้ไข
      setModalEditData(true);
    }

    return (
      <Form form={form}>
        <Row style={{ margin: '3px' }} justify={'center'} >
          <Card
            key={index} // index เป็นตัวกำหนดคีย์
            style={{ width: '300px' }} >
            <Col span={24}>
              <Form.Item style={{ marginBottom: 0 }} label='กลุ่มอาชีพ' >
                {/* <b>{item?.occupationalGroup}</b> */}
                <b>{item?.desc}</b>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }} label='เลือกอาชีพ'>
                {/* <b>{item?.governmentPrivate}</b> */}
                <b>{item?.descSub}</b>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }} label='สาขาอาชีพ'>
                {/* <b>{item?.jobPosition}</b> */}
                <b>{item?.descSection}</b>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }} label='ชื่อบริษัท สถานที่ทำงาน'>
                <b>{item?.companyName}</b>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }} label='หมายเหตุอาชีพ'>
                <b>{item?.occupationDescription}</b>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }} label='รายได้ต่อเดือน'>
                {/* <b>{item?.income?.incomeMonth}</b> */}
                <b>{item.incomeMonth}</b>
              </Form.Item>
              {/* <Form.Item style={{ marginBottom: 0 }} label='อื่นๆ'>
                <b>{item.note}</b>
              </Form.Item> */}
              <Form.Item style={{ marginBottom: 0 }} label='หมายเหตุรายได้'>
                {/* <b>{item?.income?.incomeMainDescription}</b> */}
                <b>{item.incomeMainDescription}</b>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }} label='ค่าใช้จ่ายต่อ / เดือน'>
                {/* <b>{item?.income?.expensesMonth}</b> */}
                <b>{item?.expensesMonth}</b>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }} label='หมายเหตุรายจ่าย'>
                {/* <b>{item?.income?.expensesMonthDescription}</b> */}
                <b>{item?.expensesMonthDescription}</b>
              </Form.Item>
              <Divider></Divider>
              <Row justify={'center'} gutter={{ sx: 8, sm: 16, md: 24, lg: 32 }}>
                <Space>
                  <Form.Item>
                    {/* ปุ่มลบข้อมูล */}
                    <Button
                      style={{ margin: '10px', background: '#f5222d', color: 'white', marginBottom: 0 }}
                      icon={<DeleteOutlined />}
                      // onClick={() => deleteData(index)}
                      // onClick={() => showConfirm(dataAddress[index], index)}
                      onClick={() => showConfirm(dataAddress2[index], index)}
                    >ลบ</Button>
                  </Form.Item>
                  <Form.Item>
                    {/* ปุ่มแก้ไขข้อมูล */}
                    <Button
                      style={{ margin: '10px', background: '#bfbfbf', color: '#000000', marginBottom: 0 }}
                      type='submit'
                      // onClick={() => { handleEdit(dataAddress[index], index); }}
                      onClick={() => { handleEdit(dataAddress2[index], index); }}
                    >แก้ไข</Button>
                  </Form.Item>
                </Space>
              </Row>
            </Col>
          </Card>
        </Row>
      </Form>
    )
  }

  return (
    <div>
      <Card style={{ color: '#4096ff' }}>
        <Form>
          <Divider />
          <Form
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 12,
            }}
            form={form}
            // onFinish={handleSubmit}
            autoComplete="off" >
            <Spin spinning={loading} size='large' tip="Loading...">
              <Row>
                <Col className='gutter-row' span={24}>
                  <Row>
                    <Col span={24} style={{ fontSize: '30px', textAlign: 'center' }} >รายละเอียดอาชีพและรายได้</Col>
                  </Row>
                  <Divider />
                  <Col span={24}>
                    {
                      guarantor.data.typeLoan === "car" ?
                        <Row>
                          {
                            getData?.carLoanDetails?.notPass?.loanId !== 0 ?
                              <>
                                {
                                  getData?.carLoanDetails?.notPass?.npCareer === false ?
                                    <>
                                      <Col span={12}>
                                        <Form.Item label='หมายเหตุ(ธุรการ)' name='npCareerNote' style={{ textAlign: 'center' }}>
                                          <TextArea rows={5} name='npCareerNote' disabled />
                                        </Form.Item>
                                      </Col>
                                    </>
                                    : null
                                }

                              </>
                              : null
                          }
                          {
                            getData?.carLoanDetails?.noteResend?.loanId !== 0 ?
                              <>
                                {
                                  getData?.carLoanDetails?.noteResend?.careerNote !== null ?
                                    <>
                                      <Col span={12}>
                                        <Form.Item label='หมายเหตุ(การตลาด)' name='careerNote' style={{ textAlign: 'center' }}>
                                          <TextArea rows={5} name='careerNote' disabled />
                                        </Form.Item>
                                      </Col>
                                    </>
                                    : null
                                }
                              </>
                              : null
                          }
                        </Row>
                        :
                        <Row>
                          {
                            getData?.landLoanDetails?.notPass?.loanId !== 0 ?
                              <>
                                {
                                  getData?.landLoanDetails?.notPass?.npCareer === false ?
                                    <>
                                      <Col span={12}>
                                        <Form.Item label='หมายเหตุ(ธุรการ)' name='npCareerNote' style={{ textAlign: 'center' }}>
                                          <TextArea rows={5} name='npCareerNote' disabled />
                                        </Form.Item>
                                      </Col>
                                    </>
                                    : null
                                }
                              </>
                              : null
                          }
                          {
                            getData?.landLoanDetails?.noteResend?.loanId !== 0 ?
                              <>
                                {
                                  getData?.landLoanDetails?.noteResend?.careerNote !== null ?
                                    <>
                                      <Col span={12}>
                                        <Form.Item label='หมายเหตุ(การตลาด)' name='careerNote' style={{ textAlign: 'center' }}>
                                          <TextArea rows={5} name='careerNote' disabled />
                                        </Form.Item>
                                      </Col>
                                    </>
                                    : null
                                }
                              </>
                              : null
                          }
                        </Row>
                    }
                  </Col>
                  <Divider />
                  <Row gutter={32}>
                    <Col span={24} style={{ textAlign: 'right' }}>
                      {/* ปุ่มเพิ่มข้อมูล */}
                      <Button type='primary'
                        onClick={() => { setModalAddOcc(true); }}
                      >
                        เพิ่มข้อมูลที่อยู่</Button>
                    </Col>
                  </Row>
                  <Row justify={'center'}>
                    {/* {
                      dataAddress?.map((item, index) => {
                        return renderItem({ item, index, key: `{item.identificationId} - ${index}` })
                      })
                    } */}
                    {
                      dataAddress2?.map((item, index) => {
                        return renderItem({ item, index, key: `{item.identificationId} - ${index}` })
                      })
                    }
                  </Row>
                </Col>
              </Row>
            </Spin>
          </Form>
          <Divider />

          <div style={{ textAlign: "center" }} >
            <Space>
              <Button type="primary" style={{ background: '#bfbfbf', color: '#000000' }} onClick={onChangeBack}>ย้อนกลับ</Button>
              <Button type="primary" onClick={handleCancel} style={{ background: "red" }}>ยกเลิก</Button>
              <Button type="primary" onClick={onChangeGo}>ต่อไป</Button>
            </Space>
          </div>
        </Form>
      </Card>
      {
        //เพื่มข้อมูลในการ์ด
        modalAddOcc ?
          <AddOcc open={modalAddOcc} close={setModalAddOcc} fucnAdd={fucnAdd} sendback={sendback} />
          : null
      }
      {
        //แก้ไขข้อมูลในการ์ด
        modaleditdata ?
          <EditOcc
            open={modaleditdata} // เซตการเปิด Modal จากหน้า AddressEditData
            close={setModalEditData} // เซตการปิด Modal จากหน้า AddressEditData
            shootdata={dataedit} // 
            fucnEdit={fucnEdit}
            dataindex={index1}
          />
          : null
      }
    </div>

  )
};

export default Career