import React, { useEffect, useState } from 'react'
import axios from 'axios'

export default function AllCars({note, data}) {

    useEffect(() => {
        getAllCar()
    }, [note])
    const getAllCar = async () => {
        //console.log('8')
        await axios.get(`https://shark-app-j9jc9.ondigitalocean.app/ratecars/${note}`)
            .then((res) => {
                data(res.data) 
            })
            .catch((err) => console.log(err))
           
    }
  return 
}

