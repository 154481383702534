import React, { useState } from 'react'
import { Button, Form, Input, Divider, Row, Radio, Col, Modal, Select, message } from 'antd';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { insertsocail } from '../../file_mid/all_api';


function PostsSocial({ open, close, checksocial1, setchecksocial, funcsocial, social }) {
    const [formsocial] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    //states
    const { customers } = useSelector((state) => ({ ...state }))
    const [idSocial, setIdSocial] = useState({ customerId: customers.data.customerId })
    const [socialadd, setSocialAdd] = useState({});

    //checkBox status ของที่อยู่
    const handleCancel = () => {
        close(false);
    };

    //ปุ่ม Submit in Modal Add Data Address
    const handleSubmit = async () => {
        const datasocialadd = { customer: idSocial, socials: [{ ...socialadd }] }
        console.log("datasocialadd", datasocialadd)
        await axios.post(insertsocail, datasocialadd)
            .then((res) => {
                if (res) {
                    messageApi.open({
                        type: 'success',
                        content: 'บันทึกสำเร็จ',
                    });
                } else {
                    messageApi.open({
                        type: 'error',
                        content: 'บันทึกไม่สำเร็จ',
                    });
                }
            })
            .catch((err) => {
                if (err.response.request.status === 400) {
                    messageApi.open({
                        type: 'error',
                        content: 'บันทึกไม่สำเร็จ',
                    });
                }
                console.log("err", err)
            })

        if (checksocial1 === "0") {
            console.log("checksocial1", checksocial1)
            setchecksocial(pre => [...pre, { ...socialadd }])
            funcsocial({ socialadd })
            setchecksocial("1")
            close(false);
        } else {
            setchecksocial(pre => [...pre, { ...socialadd }]); // ...pre เป็นการดึงข้อมูลก่อนหน้าที่อยู่ใน store และ ...addressadd โยนค่าที่กรอกใหม่จาก Input Form เข้าไปใน setDataArray 
            funcsocial({ socialadd }) //ส่งข้อมูลไปหน้า Address หลัก เพื่อแสดง
            setchecksocial("1")
            close(false);
        }
    }

    return (
        <>
            {/* Modal เพิ่มข้อมูล */}
            <Modal
                open={open}
                style={{ background: '#2f54eb' }}
                centered
                onCancel={handleCancel}
                width={600}
                footer={[
                    <Row
                        justify={'center'}
                    >
                        <Button key="back" onClick={handleCancel}>
                            ยกเลิก
                        </Button>
                        <Button
                            type="primary"
                            onClick={handleSubmit}
                        >
                            บันทึก
                        </Button>
                    </Row>
                ]}
            >
                <Form
                    form={formsocial}
                    name='adddata'
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    style={{
                        maxWidth: '100%',
                    }}
                >
                    <Row
                        justify={'center'}
                    >
                        <h3 style={{ marginBottom: 0 }}>
                            <u>เพิ่ม Socials</u>
                        </h3>
                    </Row>
                    <Row
                        gutter={32}
                        justify={'center'}
                    >
                        <Col className='gutter-row' span={24}>
                            <Form.Item label='ชนิด' name='socialType'
                                rules={[{
                                    required: true,
                                    message: 'Please input !',
                                },]}
                            >
                                <Select
                                    placeholder={'เลือก'}
                                    onChange={(value) => setSocialAdd({ ...socialadd, socialType: value })}
                                    options={[
                                        {
                                            label: 'Facebook',
                                            value: 'Facebook',
                                        },
                                        {
                                            label: 'Instragram',
                                            value: 'Instragram',
                                        },
                                        {
                                            label: 'Twitter',
                                            value: 'Twitter',
                                        },
                                        {
                                            label: 'Line',
                                            value: 'Line',
                                        }
                                    ]}
                                />
                            </Form.Item>
                            <Form.Item label="Socials" name='socialData' rules={[
                                {
                                    required: true,
                                    message: 'Please input !',
                                },]}>
                                <Input onChange={(e) => setSocialAdd({ ...socialadd, socialData: e.target.value })} />
                            </Form.Item>
                            <Form.Item label="รับข่าวสาร" name="sendGetNews" rules={[
                                {
                                    required: true,
                                    message: 'Please input !',
                                },]}>
                                <Radio.Group onChange={(e) => setSocialAdd({ ...socialadd, sendGetNews: e.target.value })} >
                                    <Radio value="1"> รับ </Radio>
                                    <Radio value="0"> ไม่รับ </Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item label="หมายเหตุ" name='note' >
                                <Input onChange={(e) => setSocialAdd({ ...socialadd, note: e.target.value })} />
                            </Form.Item>

                            <Divider />
                        </Col>
                    </Row>
                </Form>
            </Modal>
            {contextHolder}
        </>
    )
};

export default PostsSocial
