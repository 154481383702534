import { Col, Row, Tag, Space } from 'antd';
import dayjs from "dayjs";

const geekblueLoanTypes = ["ผ่านการวิเคราะห์", "ถือเล่มมา", "ถือโฉนด", "ถือโฉนด(ที่ดิน)", "ซื้อ-ขาย", "ปรับโครงสร้าง", "ที่ดิน+บ้าน", "ฝากโฉนด", "Pre-Aaprove-ฝากโฉนด", "Pre-Aaprove-Ploan"]
const magentaLoanTypes = [
    "Pre-Aaprove",
    "Pre-Aaprove-ถือเล่มมา",
    "Pre-Aaprove-ซื้อ-ขาย",
    "Pre-Aaprove-ย้ายไฟแนนซ์",
    "Pre-Aaprove-รีโอน",
    "เปลี่ยนสัญญา",
    "Pre-Aaprove-เปลี่ยนสัญญา",
    "รี+เปลี่ยนสัญญา",
    "ปรับ+เปลี่ยนสัญญา",
    "Pre-Aaprove-รี-Ploan",
    "รี-3",
    "Pre-Aaprove-ปรับโครงสร้าง",
    "Pre-Aaprove-รี",
    "Pre-Aaprove-รี",
    "Pre-Aaprove-เปลี่ยนสัญญา",
    "Pre-Aaprove-รี+เปลี่ยนสัญญา",
    "Pre-Aaprove-Ploan",
    "Pre-Aaprove-เปลี่ยนสัญญา",
    "โทรติด"
];
const greenLoanTypes = ["อนุมัติ", "ย้ายไฟแนนซ์", "ย้ายไฟแนนซ์"]
const redLoanTypes = ["ไม่อนุมัติ", "ลูกค้าปฏิเสธ", "ไม่ผ่านการวิเคราะห์", "Reject"]
const goldLoanTypes = ["รอธุรการรับ", "ลูกค้าคอนเฟิร์ม", "รอทำสัญญา"]
const limeapprovalStatus = ["รอตรวจสอบเอกสาร", "รออนุมัติ"]



const columns = [
    {
        title: <Row justify={"center"}><Col>ลำดับที่</Col></Row>,
        dataIndex: "index",
        key: 'index',
        align: 'center',
        fixed: true,
        width: "3%",
        render: (text, object, index) => <b>{index + 1}</b>
    },
    {
        title: <Row justify={"center"}><Col>วันที่</Col></Row>,
        dataIndex: "carInput",
        key: 'carInput',
        align: 'center',
        fixed: true,
        width: "8%",

        render: (text, record) => (
            <Space size="middle">
                {
                    record.carInput !== "0001-01-01T00:00:00Z" ?
                        <Space>
                            <>{dayjs(record.carInput).format("DD-MM-YYYY HH:mm")}</>
                        </Space>
                        : null
                }
            </Space>
        ),

    },
    {
        title: <Row justify={"center"}><Col>รหัสพนักงาน</Col></Row>,
        dataIndex: "reviewedBy",
        key: 'reviewedBy',
        align: 'center',
        // fixed: true,
        width: "6%",

        render: (text, record) => (
            // console.log("record: " + record.reviewes_by),
            <b>{record.reviewedBy}</b>
        ),
    },
    {
        title: <Row justify={"center"}><Col>ชื่อเล่น</Col></Row>,
        dataIndex: "nickname",
        key: 'nickname',
        align: 'center',
        // fixed: true,
        width: "7%",

        render: (text, record) => (
            // console.log("record: " + record.nickname),
            <> {record.nickname} </>
        ),
    },
    {
        title: <Row justify={"center"}><Col>ทีม</Col></Row>,
        dataIndex: "team",
        key: 'team',
        align: 'center',
        width: "5%",

    },
    // {
    //     title: <Row justify={"center"}><Col>ผู้เสนอ</Col></Row>,
    //     dataIndex: "proposalBy",
    //     key: 'proposalBy',
    //     align: 'center',
    //     width: "8%",

    // },
    {
        title: <Row justify={"center"}><Col>ชื่อเล่นการตลาด</Col></Row>,
        dataIndex: "nameMk",
        key: 'nameMk',
        align: 'center',
        width: "8%",

    },
    {
        title: <Row justify={"center"}><Col>ชื่อ-สกุล(ผู้กู้)</Col></Row>,
        dataIndex: "firstName",
        key: 'firstName',
        align: 'left',
        // fixed: true,
        width: "15%",

        render: (text, record) => (
            <b>{record.snam} {record.firstName} {record.lastName}</b>
        ),
    },
    {
        title: <Row justify={"center"}><Col>เลขทะเบียน / เลขโฉนดที่ดิน</Col></Row>,
        dataIndex: "proposalBy",
        key: 'proposalBy',
        align: 'center',
        width: "10%",
        render: (text, record) => (
            <> {record.carPlateNumber} ( {record.carProvince} )</>
        ),

    },
    {
        title: "สถานะ",
        dataIndex: "approvalStatus",
        align: 'center',
        width: "10%",
        render: (text, record) => {

            let color
            let color2

            if (record?.approvalStatus === "รออนุมัติราคารถ") {
                color = "orange"
            }
            if (record?.approvalStatus === "รอวิเคราะห์") {
                color = "cyan"
            }

            if (record?.approvalStatus === "โทรไม่ติด(ติดต่อไม่ได้)") {
                color = "volcano"
            }
            if (limeapprovalStatus.includes(record?.approvalStatus)) {
                color = "lime"
            }
            if (goldLoanTypes.includes(record?.approvalStatus)) {
                color = "gold";
            }
            if (redLoanTypes.includes(record?.approvalStatus)) {
                color = "red";
            }
            if (greenLoanTypes.includes(record?.approvalStatus)) {
                color = "green";
            }
            if (greenLoanTypes.includes(record?.productLoanType)) {
                color2 = "green";
            }
            if (magentaLoanTypes.includes(record?.approvalStatus)) {
                color = "magenta";
            }
            if (magentaLoanTypes.includes(record?.productLoanType)) {
                color2 = "magenta";
            }
            if (geekblueLoanTypes.includes(record?.approvalStatus)) {
                color = "geekblue";
            }
            if (geekblueLoanTypes.includes(record?.productLoanType)) {
                color2 = "geekblue";
            }


            return (
                <>
                    <Tag color={color}>
                        {record.approvalStatus.toUpperCase()}
                    </Tag>
                    <Space>
                        {

                            record.productLoanType === "ย้ายไฟแนนซ์" ?
                                <Tag color={color2}>
                                    {record.productLoanType}
                                </Tag>
                                : record.productLoanType === "ถือเล่มมา" ?
                                    <Tag color={color2}>
                                        {record.productLoanType}
                                    </Tag>
                                    : record.productLoanType === "ถือโฉนด" ?
                                        <Tag color={color2}>
                                            {record.productLoanType}
                                        </Tag>
                                        : record.productLoanType === "ย้ายไฟแนนซ์" ?
                                            <Tag color={color2}>
                                                {record.productLoanType}
                                            </Tag>
                                            : record.productLoanType === "ปรับโครงสร้าง" ?
                                                <Tag color={color2}>
                                                    {record.productLoanType}
                                                </Tag>
                                                : record.productLoanType === "ซื้อ-ขาย" ?
                                                    <Tag color={color2}>
                                                        {record.productLoanType}
                                                    </Tag>
                                                    : record.productLoanType === "Pre-Aaprove" ?
                                                        <Tag color={color2}>
                                                            {record.productLoanType}
                                                        </Tag>
                                                        : record.productLoanType === "Pre-Aaprove-ถือเล่มมา" ?
                                                            <Tag color={color2}>
                                                                {record.productLoanType}
                                                            </Tag>
                                                            : record.productLoanType === "Pre-Aaprove-ซื้อ-ขาย" ?
                                                                <Tag color={color2}>
                                                                    {record.productLoanType}
                                                                </Tag>
                                                                : record.productLoanType === "Pre-Aaprove-ย้ายไฟแนนซ์" ?
                                                                    <Tag color={color2}>
                                                                        {record.productLoanType}
                                                                    </Tag>
                                                                    : record.productLoanType === "Pre-Aaprove-รีโอน" ?
                                                                        <Tag color={color2}>
                                                                            {record.productLoanType}
                                                                        </Tag>
                                                                        : record.productLoanType === "เปลี่ยนสัญญา" ?
                                                                            <Tag color={color2}>
                                                                                {record.productLoanType}
                                                                            </Tag>
                                                                            : record.productLoanType === "Pre-Aaprove-เปลี่ยนสัญญา" ?
                                                                                <Tag color={color2}>
                                                                                    {record.productLoanType}
                                                                                </Tag>
                                                                                : record.productLoanType === "รี+เปลี่ยนสัญญา" ?
                                                                                    <Tag color={color2}>
                                                                                        {record.productLoanType}
                                                                                    </Tag>
                                                                                    : record.productLoanType === "ปรับ+เปลี่ยนสัญญา" ?
                                                                                        <Tag color={color2}>
                                                                                            {record.productLoanType}
                                                                                        </Tag>
                                                                                        : record.productLoanType === "Pre-Aaprove-รี-Ploan" ?
                                                                                            <Tag color={color2}>
                                                                                                {record.productLoanType}
                                                                                            </Tag>
                                                                                            : record.productLoanType === "รี-3" ?
                                                                                                <Tag color={color2}>
                                                                                                    {record.productLoanType}
                                                                                                </Tag>
                                                                                                : record.productLoanType === "Pre-Aaprove-ปรับโครงสร้าง" ?
                                                                                                    <Tag color={color2}>
                                                                                                        {record.productLoanType}
                                                                                                    </Tag>
                                                                                                    : record.productLoanType === "Pre-Aaprove-รี" ?
                                                                                                        <Tag color={color2}>
                                                                                                            {record.productLoanType}
                                                                                                        </Tag>
                                                                                                        : record.productLoanType === "Pre-Aaprove-Ploan" ?
                                                                                                            <Tag color={color2}>
                                                                                                                {record.productLoanType}
                                                                                                            </Tag>
                                                                                                            : null
                        }
                    </Space>
                    <Space>
                        {
                            record.car === "car" ?
                                <Tag color={"purple"}>
                                    {record.car.toUpperCase()}
                                </Tag>
                                : record.car === "land" ?
                                    <Tag color={"green"}>
                                        {record.car.toUpperCase()}
                                    </Tag>
                                    : null
                        }
                    </Space>
                </>
            );
        },
    }
];


export { columns };