import React, { useState, useEffect } from 'react'
import { Col, Row, Space, Card, Button, Spin, Image, Form, Divider, notification } from 'antd';
import { useSelector } from 'react-redux';
import Compressor from 'compressorjs';
import axios from 'axios';
import dayjs from 'dayjs';
import { editguarantors } from '../../../file_mid/all_api';

export default function EditDetailGua({ close, prev, saveclose, getimages, theData }) {
    const [form] = Form.useForm();
    const [form1] = Form.useForm();
    const [form2] = Form.useForm();

    const customers = useSelector(state => state.customers)
    const Addresss = useSelector(state => state.Addresss)
    const Phones = useSelector(state => state.Phones)
    const imgs = useSelector(state => state.imgs)

    const [dataCustomer, setDataCustomer] = useState({})
    const [dataAddress, setDataAddresss] = useState([])
    const [dataPhones, setDataPhones] = useState([])

    const [loading, setLoading] = useState(false);

    const [showImage, setShowImage] = useState([]);
    // const [foldernum, setFoldernum] = useState();
    const [api, contextHolder] = notification.useNotification();

    const [dataMix, setDataMix] = useState({})

    useEffect(() => {
        setDataCustomer(customers.data)
        setDataAddresss(Addresss.data)
        setDataPhones(Phones.data)
    }, [])

    // console.log("dataCustomer", dataCustomer)
    console.log("dataMix", dataMix)

    useEffect(() => {
        mapImg()
        if (dataCustomer && dataAddress && dataPhones !== undefined) {
            setDataMix({
                ...dataCustomer,
                address: Addresss.data,
                locat: theData.locat,
                garno: theData.garno,
                loanId: theData.loanId,
                typeLoan: theData.typeLoan,
                carLandId: theData.carLandId,
                phones: Phones.data,
                checkDup: true
            })
        }
        if (imgs.data) {
            setShowImage(imgs.data)
        }
    }, [dataCustomer, dataAddress, dataPhones])

    const mapImg = () => {
        {
            const folder = []
            const split = theData?.pathImg.split('/')[4];
            // setFoldernum(split)
            getimages?.map((item, index) => {
                // const segments = item.pathImage.split("/")[4]
                var image = {}
                if (item?.pathImage.split('/')[4] === split) {
                    image.attr = item.attr
                    image.data = item.data
                    image.idImage = item.idImage
                    image.pathImage = item.pathImage
                    folder.push(image)
                }
            })
        }
    }

    const CheckAddressER = (placement) => {
        api.error({
            message: <b>เพิ่มข้อมูลไม่สำเร็จ</b>,
            description: 'กรุณาเพิ่มที่อยู่ อย่างน้อย 1 ที่',
            placement,
        });
    }
    const CheckPhoneER = (placement) => {
        api.error({
            message: <b>เพิ่มข้อมูลไม่สำเร็จ</b>,
            description: 'กรุณาเพิ่มเบอร์โทรศัพท์ อย่างน้อย 1 เบอร์',
            placement,
        });
    }

    const CheckDesER = (placement) => {
        api.error({
            message: <b>เพิ่มข้อมูลไม่สำเร็จ</b>,
            description: 'กรุณาเพิ่มความสัมพันธ์กับผู้กู้',
            placement,
        });
    }

    const handleSubmit = () => {
        console.log("dataMix", dataMix)
        // fucnEdit({ data, index })
        if (dataAddress === undefined) {
            CheckAddressER('top')
        }
        if (dataPhones === undefined) {
            CheckPhoneER('top')
        }
        if (dataAddress && dataPhones) {
            if (dataCustomer.description === "" || dataCustomer.description === undefined) {
                CheckDesER('top')
            } else {
                delete dataMix.status
                sendAxios()
            }
        }
    }
    // // เข้าฐานข้อมูล
    const sendAxios = async () => {
        setLoading(true)
        await axios.post(editguarantors, dataMix)
            .then((res) => {
                console.log("res.data", res.data)
                if (res.status === 200) {
                    const data = res.data
                    console.log("datadata", data)
                    // setDataNew({ data })
                    setLoading(false)
                    saveclose()
                }
            }).catch((err) => {
                console.log(err)
            })

    }

    const handleCancel = () => {
        close(false)
    }

    const onChangeBack = () => {
        prev()
    }

    const renderItem = ({ item, index }) => {

        return (
            <Form
                name="basic"
                labelCol={{
                    span: 12,
                }}
                wrapperCol={{
                    span: 24,
                }}
                style={{
                    width: '100%',
                }}
                initialValues={{
                    remember: true,
                }}
                form={form}
            >
                <Card>
                    <Row justify={'center'}>
                        <Form.Item style={{ marginBottom: 0 }} name='' label=''>
                            <span>เป็นที่อยู่ :</span>
                            <b>
                                {(item.addressOnIdcard === true || item.AddressOnIdcard === 1 || item.AddressOnIdcard === true) && "ตามบัตรประชาชน "}
                                {(item.houseRegistration === true || item.HouseRegistration === 1 || item.HouseRegistration === true) && "ตามทะเบียนบ้าน "}
                                {(item.workAddress === true || item.WorkAddress === 1 || item.WorkAddress === true) && "ตามสถานที่ทำงาน "}
                                {(item.otherAddress === true || item.OtherAddress === 1 || item.OtherAddress === true) && "อื่นๆ "}
                                {(item.currentAddress === true || item.CurrentAddress === 1 || item.CurrentAddress === true) && "ที่อยู่ปัจจุบัน "}
                                {(item.sendDocuments === true || item.SendDocuments === 1 || item.SendDocuments === true) && "ที่อยู่ส่งเอกสาร "}
                            </b>
                            <b>({item.addressStatus === true || item.AddressStatus === 1 || item.AddressStatus === true ? <u>ใช้งานที่อยู่</u> : <u>ไม่ได้ใช้งาน</u>})</b>
                        </Form.Item>
                    </Row>
                    <Divider style={{ margin: 5 }} />
                    <Row justify={'center'} >
                        <Col span={8} style={{ textAlign: 'left' }}>
                            <Form.Item style={{ marginBottom: 0 }} name='' label='เลขทะเบียนบ้าน' >
                                <b>{item.houseRegistrationNumber}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} name='' label='ถนน'>
                                <b>{item.road}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} name='' label='อำเภอ'>
                                <b>{item.district}</b>
                            </Form.Item>


                        </Col>
                        <Col span={8} style={{ textAlign: 'left' }}>
                            <Form.Item style={{ marginBottom: 0 }} name='' label='บ้านเลขที่'>
                                <b>{item.houseNumber}</b>
                            </Form.Item> <Form.Item style={{ marginBottom: 0 }} name='' label='หมู่บ้าน'>
                                <b>{item.village}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} name='' label='จังหวัด'>
                                <b>{item.province}</b>
                            </Form.Item>
                        </Col>
                        <Col span={8} style={{ textAlign: 'left' }}>
                            <Form.Item style={{ marginBottom: 0 }} name='' label='ซอย'>
                                <b>{item.soi}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} name='' label='ตำบล'>
                                <b>{item.subdistrict}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} name='' label='รหัสไปรษณีย์'>
                                <b>{item.zipCode}</b>
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
            </Form>
        )
    }
    const renderPhone = ({ item, index }) => {

        return (
            <Form
                name="basic"
                labelCol={{
                    span: 12,
                }}
                wrapperCol={{
                    span: 24,
                }}
                style={{
                    width: '100%',
                }}
                initialValues={{
                    remember: true,
                }}
                form={form1}
            >
                <Card
                    style={{ width: 500 }}
                >
                    <Row justify={'center'}>
                        <Form.Item style={{ marginBottom: 0 }} name='' label=''>
                            <b>(<u>{item.phoneType}</u>)</b>
                        </Form.Item>
                    </Row>

                    <Row justify={'center'}>
                        <Col span={8} style={{ textAlign: 'left' }}>
                            <Form.Item style={{ marginBottom: 0 }} name='' label='เบอร์'>
                                <b>{item.telp}</b>
                            </Form.Item>
                        </Col>
                        <Col span={8} style={{ textAlign: 'left' }}>
                            <Form.Item style={{ marginBottom: 0 }} name='' label='sendSms'>
                                {
                                    item.sendSms === "0" && (<b>ไม่ส่ง</b>)
                                }
                                {
                                    item.sendSms === "1" && (<b>ส่ง</b>)
                                }
                            </Form.Item>
                        </Col>
                        <Col span={8} style={{ textAlign: 'left' }}>
                            <Form.Item style={{ marginBottom: 0 }} name='' label="หมายเหตุ">
                                {item.note}
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
            </Form>
        )
    }

    return (
        <>
            <Spin spinning={loading} size='large' tip=" Loading... ">
                <Form
                    name="basic"
                    labelCol={{
                        span: 12,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    style={{
                        width: '100%',
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    form={form2}
                >
                    <Card>
                        <Row>
                            <Col span={24} style={{ fontSize: '30px', textAlign: 'center' }} >รายละเอียด</Col>
                        </Row>
                        <Divider style={{ margin: 5 }} />
                        <Row >
                            <Col span={24} style={{ fontSize: '18px' }} >ข้อมูลส่วนตัว</Col>
                            <Col span={8}>
                                <Form.Item label="เลขบัตรประชาชน" name='' style={{ margin: 0 }}>
                                    <b>{dataCustomer.identificationId}</b>
                                </Form.Item>
                                <Form.Item label="ชื่อ-สกุล" name='' style={{ margin: 0 }}>
                                    <b>{dataCustomer.snam}{dataCustomer.firstname} {dataCustomer.lastname}</b>
                                </Form.Item>
                                <Form.Item label="เกิดวันที่" name='' style={{ margin: 0 }}>
                                    <b>{dataCustomer.birthdate}</b>
                                </Form.Item>

                                <Form.Item label="ความสัมพันธ์กับผู้กู้" name='' style={{ margin: 0 }}>
                                    <b>{dataCustomer.description}</b>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="ชื่อเล่น" name='' style={{ margin: 0 }}>
                                    <b>{dataCustomer.nickname}</b>
                                </Form.Item>
                                <Form.Item label="สถานที่ออกบัตร" name='' style={{ margin: 0 }}>
                                    <b>{dataCustomer.issuby}</b>
                                </Form.Item>
                                <Form.Item label="สาขา" name='' style={{ margin: 0 }}>
                                    <b>{dataCustomer.locat}</b>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="เพศ" name='' style={{ margin: 0 }}>
                                    <b>{dataCustomer.gender}</b>
                                </Form.Item>
                                <Form.Item label="วันที่บัตรหมดอายุ" name='' style={{ margin: 0 }}>
                                    <b>{dataCustomer.expdt}</b>
                                </Form.Item>
                                <Form.Item label="วันที่ออกบัตร" name='' style={{ margin: 0 }}>
                                    <b>{dataCustomer.issudt}</b>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider style={{ margin: 5 }} />
                        <Row >
                            <Col span={24}>
                                <Row>
                                    <Col span={24} style={{ fontSize: '18px' }} >รายละเอียดที่อยู่</Col>
                                </Row>
                                <Row justify={'center'}>
                                    {
                                        dataAddress?.map((item, index) => {
                                            return renderItem({ item, index, key: `{item.address} - ${index}` })
                                        })
                                    }
                                </Row>
                            </Col>
                        </Row>
                        <Divider style={{ margin: 5 }} />
                        <Row >
                            <Col span={24}>
                                <Row>
                                    <Col span={24} style={{ fontSize: '18px' }} >ช่องทางการติดต่อ</Col>
                                </Row>
                                <Row justify={'center'}>
                                    {
                                        dataPhones?.map((item, index) => {
                                            return renderPhone({ item, index, key: `{item.phones} - ${index}` })
                                        })
                                    }
                                </Row>
                            </Col>
                        </Row>
                        <Divider />
                        <Row gutter={32} justify={'center'}>
                            <Col span={24} style={{ fontSize: '20px', textAlign: 'center' }}><b><u>รูปเอกสารคนค้ำประกัน</u></b></Col>
                        </Row>
                        <Row justify={'center'}>
                            <Col span={24}>
                                <Row justify={'center'} >
                                    {showImage?.map((items, index) => {
                                        return (
                                            <Row gutter={32} >
                                                <Col span={24} >
                                                    <Image width={'100px'} key={index} src={`data:${items?.Attr};base64,${items?.data?.toString('base64')}`} alt={`Image ${index + 1}`} />
                                                </Col>
                                            </Row>
                                        )
                                        // }

                                    })}
                                </Row>
                            </Col>
                        </Row>
                        <Divider />
                        <Row justify={'center'}>
                            <Space>
                                <Button type="primary" style={{ background: '#bfbfbf', color: '#000000', }} onClick={onChangeBack}>ย้อนกลับ</Button>
                                <Button type="primary" onClick={handleCancel} style={{ background: "red" }}>ยกเลิก</Button>
                                <Button type="primary" tyle={{ background: "green" }} onClick={handleSubmit}>บันทึก</Button>
                            </Space>
                        </Row>
                    </Card>
                    {contextHolder}
                </Form>
            </Spin>
        </>
    )
};
