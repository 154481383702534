import React, { useEffect, useState } from 'react'
import { Form, Card, Col, Row, Space, Divider, Image, Button, Spin, Modal, Checkbox } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import FileSaver from 'file-saver';
import JSZip from 'jszip';
import { setTypeImg } from '../../../file_mid/type_img';
import { deleteAllCar, deleteAllLand, storeDBLine, getImagessPre, downloadImagessPre, deletePreCar, deletePreLand } from '../../../file_mid/all_api';

export default function CustomerNew({ getData, DeleteError, deleteData, checkType, sendB }) {
  const un = localStorage.getItem('username');
  const token = localStorage.getItem('token');
  const view = localStorage.getItem('viewAD')
  const [loading, setLoading] = useState(false)
  const detail = JSON.parse(view)
  const [dataModalEdit, setDataModalEdit] = useState(getData);
  const { confirm } = Modal;
  const [form] = Form.useForm()
  const [checkedImages, setCheckedImages] = useState([]);
  const [adData, setAdData] = useState([])
  const [checkedState, setCheckedState] = useState({});
  const [cb, setCb] = useState(false)

  useEffect(() => {
    console.log("img")
    setDataModalEdit(getData)
    onChangeCheckboxAll(true)
  }, [getData])

  // const handleCancel = () => {
  //   localStorage.removeItem('viewAD')
  //   close(false);
  // };
  window.addEventListener('beforeunload', function () {
    localStorage.removeItem('viewAD');
  });

  const onChangeCheckbox = (es, e) => {
    // console.log("es", es.target.checked)
    // console.log("e", e)
    const newCheckedState = { ...checkedState };
    newCheckedState[e.id] = es.target.checked;
    setCheckedState(newCheckedState);
    if (es.target.checked === true) {
      adData.push(e)
    } else {
      for (const removedFile of adData.filter(file => es.target.checked === false)) {
        if (adData.some(img => img.idImage === removedFile.idImage)) {
          const updatedAllImg = adData.filter(img => !(img.idImage === removedFile.idImage));
          setAdData(updatedAllImg);
        }
      }
    }
  }
  const showConfirmAll = () => {
    confirm({
      title: 'คุณต้องการที่จะลบรูปภาพใช่หรือไม่...?',
      onOk() {
        allDeleteImg()
      },
      onCancel() {
      },
    });
  };
  const allDeleteImg = async () => {
    console.log("adData", adData)
    setLoading(true)
    if (adData?.length > 0) {
      if (checkType.car === "car") {
        const test2 = adData
        await axios.delete(deleteAllCar, { data: test2, })
          .then((res) => {
            if (res.status === 200) {
              console.log("car", res.data)
              setCheckedState({});
              setAdData([])
              sendB(dayjs())
              deleteData("top")
              setLoading(false)
            }
          }).catch((err) => DeleteError("top"))
      } else {
        const test2 = adData
        await axios.delete(deleteAllLand, { data: test2, })
          .then((res) => {
            if (res.status === 200) {
              console.log("land", res.data)
              setCheckedState({});
              setAdData([])
              sendB(dayjs())
              deleteData("top")
              setLoading(false)
            }
          }).catch((err) => DeleteError("top"))
      }
    } else {
      alert('กรุณาเลือกรูปภาพก่อน !!!');
    }
    setLoading(false)
  }

  const onChangeCheckboxAll = (isChecked, updatedImageBlobzz) => {
    setLoading(true)
    if (isChecked && updatedImageBlobzz) {
      console.log("1")
      setCb(true)
      if (updatedImageBlobzz?.length > 0) {
        var PreAaproveSplits = []
        var resImg = []
        updatedImageBlobzz?.map((item, index) => {
          PreAaproveSplits = item.pathImage.split("/")
          if (PreAaproveSplits[3] === "13") {
            if (item.type === 4) {
              resImg.push(item)
            }
          }
        })
        setCheckedImages(resImg);
      } else {
        setCb(false)
        setCheckedImages([])
      }
    } else if (isChecked && !updatedImageBlobzz) {
      console.log("2")
      setCb(true)
      if (getData?.length > 0) {
        var PreAaproveSplits = []
        var resImg = []
        getData?.map((item, index) => {
          PreAaproveSplits = item.pathImage.split("/")
          if (PreAaproveSplits[3] === "13") {
            if (item.type === 4) {
              resImg.push(item)
            }
          }
        })
        setCheckedImages(resImg);
      } else {
        setCb(false)
        setCheckedImages([])
      }
    } else {
      console.log("3")
      setCb(false)
      setCheckedImages([])
    }
    setLoading(false)
  };
  const downloadImages = async () => {
    setLoading(true)
    const names = detail.firstName + detail.lastName
    if (checkedImages.length > 0) {
      // console.log("checkedImages",checkedImages)
      try {
        const zip = new JSZip();
        for (const image of checkedImages) {
          const folderName = setTypeImg(image);
          const folder = zip.folder(folderName); // สร้างโฟลเดอร์ใหม่ใน ZIP
          const response = await fetch(`${downloadImagessPre}/${image.pathImage}?token=${token}`);
          const blob = await response.blob();
          folder.file(`${image.idImage}.jpg`, blob);
        }
        // สร้างไฟล์ ZIP
        const zipBlob = await zip.generateAsync({ type: 'blob' });

        // บันทึกไฟล์ ZIP และดาวน์โหลด
        FileSaver.saveAs(zipBlob, `${names} รูปบัตรประชาชน (ใหม่).zip`);
      } catch (error) {
        console.error('Error downloading images:', error);
      }
    } else {
      alert('ไม่พบรูปภาพ');
    }
    setLoading(false)
  };
  // const handleChangepage = () => {
  //   changepage(page + 1)
  // };

  const showConfirm = (e, index) => {
    // console.log(e,index)
    confirm({
      title: 'คุณต้องการที่จะลบรูปภาพนี้ใช่หรือไม่...?',
      onOk() {
        // message.success('ลบรูปภาพสำเร็จ');
        if (checkType.car === "car") {
          handleDeleteCar(e, index)
        } else {
          handleDeleteLand(e, index)
        }
      },
      onCancel() {
        // message.error('ยกเลิกการลบรูปภาพ');
      },
    });
  };
  const handleDeleteCar = async (e, index) => {
    const id = e.idImage
    const path = e.pathImage
    setLoading(true)
    await axios.delete(`${deletePreCar}/${id}/${path}`)
      .then(async res => {
        if (res.data) {
          console.log("car")
          const updatedImageBlobzz = [...dataModalEdit];
          updatedImageBlobzz.splice(index, 1);
          setDataModalEdit(updatedImageBlobzz);
          await handleNoti(path, updatedImageBlobzz)
        }
      })
      .catch((err) =>
        DeleteError("top")
      )
    setLoading(false)
  }
  const handleDeleteLand = async (e, index) => {
    const id = e.idImage
    const path = e.pathImage
    setLoading(true)
    await axios.delete(`${deletePreLand}/${id}/${path}`)
      .then(async res => {
        if (res.data) {
          console.log("land")
          const updatedImageBlobzz = [...dataModalEdit];
          updatedImageBlobzz.splice(index, 1);
          setDataModalEdit(updatedImageBlobzz);
          await handleNoti(path, updatedImageBlobzz)
        }
      })
      .catch((err) =>
        DeleteError("top")
      )
    setLoading(false)
  }
  const handleNoti = async (path, updatedImageBlobzz) => {
    const ez = { nameInput: un, path: path }
    await axios.post(storeDBLine, ez)
      .then(res => {
        if (res.data) {
          console.log("OK")
          deleteData("top")
          setLoading(false)
        }
      })
      .catch((err) =>
        DeleteError("top")
      )
    setLoading(false)
    onChangeCheckboxAll(true, updatedImageBlobzz)
  }

  return (
    <div>
      <Card style={{ padding: 20 }} className="modal-img">
        <Spin spinning={loading} size='large' tip=" Loading... ">
          <div style={{ textAlign: 'center' }}>
            <Row style={{ textAlign: 'center' }} >
              <Col span={24} style={{ fontSize: '30px' }} >รูปบัตรประชาชน (ใหม่)</Col>
            </Row>
            <Row style={{ textAlign: 'right' }} >
              <Col span={24} style={{ fontSize: '30px' }} >
                <Space>
                  {/* <Checkbox defaultChecked={true} disabled style={{ margin: 10 }} onChange={(es) => onChangeCheckboxAll(es.target.checked)}>
                    เลือกรูปรูปบัตรประชาชน
                  </Checkbox> */}
                  <Button type='primary' danger onClick={showConfirmAll}>ลบรูปภาพที่เลือก</Button>
                  <Button style={{ background: '#bfbfbf', color: '#000000', }} onClick={downloadImages}>ดาวน์โหลดรูปภาพ</Button>
                </Space>
              </Col>
            </Row>
            <Divider />
            <Form
              labelCol={{
                span: 12,
              }}
              wrapperCol={{
                span: 12,
              }}
              form={form}
            >
              <Row>
                <Col span={24}>
                  <Image.PreviewGroup>
                    <Row justify={'center'} style={{ fontSize: '20px' }}>
                      <b><u>ผู้ทำสัญญา</u></b>
                    </Row>
                    {dataModalEdit?.map((e, index) => {
                      var PreAaproveSplit = []
                      PreAaproveSplit = e.pathImage.split("/")
                      if (e && e.pathImage) {
                        if (e.type === 4) {
                          if (PreAaproveSplit[3] === "13") {
                            return (
                              <div key={index}>
                                <Row justify={'center'}>
                                  <Space direction='vertical'>
                                    <Checkbox checked={checkedState[e.id]} style={{ margin: 10 }} key={index} onChange={(es) => onChangeCheckbox(es, e)}>
                                      <Image
                                        width={750}
                                        src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                        alt={`รูปภาพ ${index}`} />
                                      <p><Button onClick={() => showConfirm(e, index)} type='primary' danger>ลบรูปภาพ</Button></p>
                                    </Checkbox>
                                  </Space>
                                </Row>
                                <Divider />
                              </div>
                            )
                          }
                        }
                      } else {
                        return null
                      }
                    })}
                  </Image.PreviewGroup>
                </Col>
              </Row>
              <Divider></Divider>
              {/* <div style={{ textAlign: "center" }} >
                <Space>
                  <Button type="primary" onClick={handleChangepage}>
                    ต่อไป
                  </Button>
                </Space>
              </div> */}
            </Form>
          </div>
        </Spin>
      </Card >
    </div >
  )
}
