import { Button, Card, Col, Divider, Form, Input, Modal, Row, Select, Space, notification, Spin, Checkbox, DatePicker } from 'antd'
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import {
    addcausename, showcausename,
    docnotpasspgre, shownotepnre, docnotpasspgreland
} from '../../file_mid/all_api';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';


export default function NoteRe({ open, close, loanId, typeNote, loanRe, typeData, dataFromTable, allData, pg, CloseAll }) {

    const [money] = useState({}) //loan
    const [monthlyPayment, setMonthlyPayment] = useState()
    const [interest, setInterest] = useState(0.0)
    const [installmentWithInterest, setInstallmentWithInterest] = useState()
    const user = localStorage.getItem('username');
    const [api, contextHolder] = notification.useNotification();
    const [options, setOptions] = useState([]);
    const [checkAddCause, setCheckAddCause] = useState(false);
    const [isModalTableP, setIsModalTableP] = useState(false);
    const [isModalTable, setIsModalTable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [resetPage, setResetPage] = useState();
    const [form] = Form.useForm()
    const [formAddCauseName] = Form.useForm()
    const [interestRate, setInterestRate] = useState();
    const [dataPost, setDataPost] = useState(loanRe);
    const [checkDis, setCheckDis] = useState(true)
    const [addCauseName, setAddCauseName] = useState({ name: '' });

    useEffect(() => {
        console.log("dataFromTable", dataFromTable)
        console.log("typeData", typeData)
        console.log("allData", allData)
        console.log("reLoanId", loanId)
        console.log("pg", pg)
        loadCauseName()
    }, [resetPage])

    const loadCauseName = async () => {
        setLoading(true)
        if (typeData === "car") {
            await axios.get(showcausename)
                .then(res => {
                    if (res.status === 200) {
                        setOptions(res.data)
                        setLoading(false)
                    }
                }).catch((err) => {
                    console.log(err)
                    setLoading(false)
                })
        } else {
            await axios.get(shownotepnre)
                .then(res => {
                    if (res.status) {
                        // console.log("loadNoteNameRe", res.data)
                        setOptions(res.data)
                        setLoading(false)
                    }
                }).catch((err) => console.log(err))
        }
    };

    const errorSendCause = (placement) => {
        api.error({
            message: `กรุณากรอกสาเหตุที่ต้องการเพิ่ม`,
            // description:
            //     'เนื่องจากเคสนี้ถูกรับงานไปแล้ว',
            placement,
        });
    };

    const successSendCause = (placement) => {
        api.success({
            message: `เพิ่มสาเหตุแล้ว`,
            description:
                'เลือกสาเหตุที่เพิ่มได้ในช่องเลือกสาเหตุ',
            placement,
        });
    };

    const successSendNote = (placement) => {
        api.success({
            message: `บันทึกสำเร็จ`,
            description:
                'ตีกลับเคสแล้ว',
            placement,
        });
    };

    const handleClose = () => {
        close(false)
    }

    const successAccept = (placement) => {
        api.success({
            message: `บันทึกสำเร็จ`,
            description:
                'สถานะเปลี่ยนเป็น ปฏิเสธคำร้อง',
            placement,
        });
    };
    const errorAccept = (placement) => {
        api.error({
            message: `บันทึกไม่สำเร็จ`,
            // description:
            //     'เนื่องจากเคสนี้ถูกรับงานไปแล้ว',
            placement,
        });
    };

    const handleSubmit = async () => {
        let sendData
        setLoading(true);
        if (typeData === "car") {
            if (pg === "pg") {
                sendData = {
                    ISSUNO: allData?.cars?.ISSUNO,
                    productLoanType: allData?.cars?.carLoanDetailsRe?.productLoanType,
                    proposalBy: allData?.cars?.carLoanDetailsRe?.proposalBy,
                    username: allData?.cars?.carLoanDetailsRe?.proposalBy,
                    reLoanId: loanId,
                    cause: dataPost.cause,
                    note: dataPost.note,
                    noteBy: user,
                    status: "คำร้องไม่ถูกต้อง",
                    rejectStatus: false,
                    noteDate: dayjs()
                }
            } else {
                sendData = {
                    ISSUNO: allData?.carLoanDetails?.cars?.ISSUNO,
                    productLoanType: allData?.carLoanDetailsRe?.productLoanType,
                    proposalBy: allData?.carLoanDetailsRe?.proposalBy,
                    username: allData?.carLoanDetailsRe?.proposalBy,
                    reLoanId: loanId,
                    cause: dataPost.cause,
                    note: dataPost.note,
                    noteBy: user,
                    status: "คำร้องไม่ถูกต้อง",
                    rejectStatus: false,
                    noteDate: dayjs()
                }
            }
            console.log("sendData", sendData)
            await axios.put(docnotpasspgre, sendData)
                .then(res => {
                    if (res.status === 200) {
                        successSendNote("top")
                        setTimeout(() => {
                            setLoading(false);
                            close(false)
                            CloseAll(false)
                        }, 2000)
                    }
                }).catch((err) => {
                    console.log(err)
                    errorAccept("top")
                    setLoading(false);
                })
        } else {
            sendData = {
                ISSUNO: allData?.land?.ISSUNO,
                productLoanType: allData?.land?.landLoanDetailsRe?.productLoanLandType,
                proposalBy: allData?.land?.landLoanDetailsRe?.proposalBy,
                username: allData?.land?.landLoanDetailsRe?.proposalBy,
                reLoanId: loanId,
                cause: dataPost.cause,
                note: dataPost.note,
                noteBy: user,
                status: "คำร้องไม่ถูกต้อง",
                rejectStatus: false,
                noteDate: dayjs()
            }
            console.log("sendData land", sendData)
            await axios.put(docnotpasspgreland, sendData)
                .then(res => {
                    if (res.status === 200) {
                        successSendNote("top")
                        setTimeout(() => {
                            setLoading(false);
                            close(false)
                            CloseAll(false)
                        }, 2000)
                    }
                }).catch((err) => {
                    console.log(err)
                    errorAccept("top")
                    setLoading(false);
                })
        }
    }

    const handleChangeCause = (value) => {
        setDataPost({ ...dataPost, cause: `${value}` })
        // setSendData({ ...sendData, cause: `${value}` })
    }

    const handleAddCause = async () => {
        if (addCauseName?.name !== '') {
            await axios.post(addcausename, addCauseName)
                .then(res => {
                    if (res.status === 200) {
                        formAddCauseName.resetFields();
                        setResetPage(dayjs())
                        setCheckAddCause(false);
                        successSendCause("top")
                    }
                }).catch((err) => console.log(err))
        } else {
            errorSendCause("top")
        }
    }

    const handleCheckBtnAddCause = () => {
        if (checkAddCause === false) {
            setCheckAddCause(true);
        } else {
            setCheckAddCause(false);
        }
    };

    // console.log("dataPost", dataPost)

    return (
        <Modal open={open} onCancel={handleClose} footer={[null]} title={`หมายเหตุ ${typeNote}`} width={700}>
            <Spin spinning={loading} size='large' tip=" Loading... ">
                <Form
                    labelCol={{
                        span: 7,
                    }}
                    wrapperCol={{
                        span: 15,
                    }}
                    form={form}
                    onFinish={handleSubmit}
                >
                    <Card>
                        <Row>
                            <Col span={22}>
                                <Form.Item
                                    label="สาเหตุ"
                                    name="cause"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'กรุณากรอกสาเหตุ'
                                        },]}>
                                    <Select
                                        mode="multiple"
                                        style={{
                                            width: '100%',
                                        }}
                                        size="large"
                                        placeholder="สาเหตุ"
                                        onChange={handleChangeCause}
                                        options={options}
                                    />
                                </Form.Item>
                                {
                                    checkAddCause === false ?
                                        null
                                        :
                                        <>
                                            <Form
                                                form={formAddCauseName}
                                                labelCol={{
                                                    span: 7,
                                                }}
                                                wrapperCol={{
                                                    span: 15,
                                                }}>
                                                <Form.Item label='เพิ่มสาเหตุ' name='name'>
                                                    <Input type='text' size="small" placeholder='กรอกสาเหตุที่ต้องการเพิ่ม' style={{ color: 'black' }}
                                                        onChange={(e) => setAddCauseName({ ...addCauseName, name: e.target.value })}
                                                    />
                                                </Form.Item>
                                            </Form>
                                        </>

                                }
                                <Form.Item label="รายละเอียด" name="note">
                                    <TextArea rows={5} onChange={(e) => setDataPost({ ...dataPost, note: e.target.value })} />
                                </Form.Item>
                            </Col>
                            {
                                typeData === "car" ?
                                    <Col span={2}>
                                        {
                                            checkAddCause === false ?
                                                <Form.Item>
                                                    <Button type="primary" onClick={handleCheckBtnAddCause}>
                                                        +
                                                    </Button>
                                                </Form.Item>
                                                :
                                                <Form.Item>
                                                    <Button type="primary" onClick={handleCheckBtnAddCause}>
                                                        -
                                                    </Button >
                                                </Form.Item>
                                        }
                                    </Col>
                                    : null
                            }
                        </Row>
                    </Card>
                    <Divider />
                    <Row>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <Space>
                                {
                                    checkAddCause === true ?
                                        <Button type='primary' onClick={handleAddCause}>เพิ่มหมายเหตุ</Button>
                                        : null
                                }
                                <Button type="primary" onClick={handleClose} danger>
                                    ปิด
                                </Button>
                                <Button type="primary" disabled={checkAddCause} htmlType="submit" style={{ backgroundColor: "green" }}>
                                    บันทึก
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </Spin>
            {contextHolder}

        </Modal >

    )
}
