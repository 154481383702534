import React, { useEffect, useState } from 'react'
import { Routes, Route } from "react-router-dom"
import  Test_upload_first  from "./Test_upload_first"

import { useDispatch } from 'react-redux';
import { addCustomer } from '../../redux/Customer';
import { addAddress } from '../../redux/Address';
import { addPhone } from '../../redux/Phone';
import { addCar } from '../../redux/Car';
import { addLoan } from '../../redux/Loan';
import { addOldLoan } from '../../redux/OldLoan';
import { addImage } from '../../redux/Image';
import { addLand } from '../../redux/Land';
import { addLoanLand } from '../../redux/LoanLand';
import { addOldLoanLand } from '../../redux/OldLoanLand';
import { addGuarantor } from '../../redux/Guarantor';
import { addCareerIncome } from '../../redux/CareerIncome';
import { addOccupation } from '../../redux/Occupation';
import { addEmail } from '../../redux/Email';
import { addSocial } from '../../redux/Social';



export default function Main() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(addCustomer())
    dispatch(addAddress())
    dispatch(addPhone())
    dispatch(addCar())
    dispatch(addGuarantor())
    dispatch(addCareerIncome())
    dispatch(addLoan())
    dispatch(addOldLoan())
    dispatch(addImage())
    dispatch(addLand())
    dispatch(addLoanLand())
    dispatch(addOldLoanLand())
    dispatch(addOccupation())
    dispatch(addEmail())
    dispatch(addSocial())
  }, [])

  return (
    <Routes>
      <Route path="/" element={<Test_upload_first />} />
    </Routes>
  )
}

