import { Button, Card, Checkbox, Divider, Form, Modal, Select, Space, notification, Spin, Row, Col, Tag, Image, Input } from 'antd'
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import dayjs from 'dayjs';
import TextArea from 'antd/es/input/TextArea';
import { addcontnopgre, cancelcontnopgre, detailcarchangepg, detaillandchangepg, loadcontnopgre, loadDetailAunSendPG, loadNoteAun } from '../../file_mid/all_api';
import ModalInstallment from '../../check_info_PG_RE/modals/ModalInstallment';
import { nopay } from '../../file_mid/all_options'

function EditCont({ open, close, recordData, selectedValue }) {
    const { confirm } = Modal
    const [form] = Form.useForm();
    const user = localStorage.getItem('username');
    const [api, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(false);
    const [isModalTable, setIsModalTable] = useState(false);
    const [shownote, setShowNote] = useState();
    const [carsApproveve, setCarsApproveve] = useState();
    const [dataDB2, setDataDB2] = useState();
    const [notesCarsApprove, setNotesCarsApprove] = useState();
    const [dataChange, setDataChange] = useState({
        overdue: null,
        preChange: null,
        priceChange: null,
    });
    const [sendData, setSendData] = useState({
        id: 0,
        contno: recordData.CONTNO,
        checkRe: false,
        checkPub: false,
        checkChange: false,
        // status: "",
        mk: selectedValue === '1' ? recordData.SALCOD : recordData?.proposalBy,
        // checkDate: "",
        checkBy: user,
        installment: "",
        pgLoanTerm: ""
    });
    const [outstandingdebt, setOutstandingdebt] = useState();   // outstandingdebt มูลหนี้คงเหลือ
    const [deductdiscount, setDeductdiscount] = useState();     // deductdiscount หักส่วนลด
    const [remaining, setRemaining] = useState();               // remaining  คงเหลือ
    const [pay, setPay] = useState();                           // pay   จ่ายเงิน
    const [Excellent, setExcellent] = useState();               // Excellent   ยอดจัด
    const [relax, setRelax] = useState();                       // relax   ผ่อน
    // const [pgLoanTerm, setPgLoanTerm] = useState(null);         // จำนวนงวด
    // const [filteredOptions, setFilteredOptions] = useState([]); หางวดตามช่วงของเฮียอนุมัติ
    // const [installment, setInstallment] = useState();         // ผ่อน

    // console.log('recordData', recordData)
    // console.log('outstandingdebt', outstandingdebt)
    // console.log('deductdiscount', deductdiscount)
    // console.log('remaining', remaining)
    // console.log('pay', pay)
    // console.log('Excellent', Excellent)
    // console.log('relax', relax)
    // console.log('carsApproveve', carsApproveve)
    // console.log('dataDB2', dataDB2)
    // console.log('pgLoanTerm', pgLoanTerm)
    // console.log('dataChange', dataChange)
    // console.log('sendData', sendData)
    // console.log('installment', sendData?.installment)
    // console.log('filteredOptions', filteredOptions)
    // console.log('nopay', nopay)

    var note = shownote?.length;
    var lastIndex = note - 1;

    // useEffect(() => {
    //     if (recordData?.approvedLoanTerm && recordData?.approvedLoanTermTo) {
    //         const filtered = nopay.filter(
    //             (option) =>
    //                 option.value >= recordData.approvedLoanTerm &&
    //                 option.value <= recordData.approvedLoanTermTo
    //         );
    //         console.log("filtered", filtered)
    //         setFilteredOptions(filtered);
    //     }

    // }, [recordData]);


    useEffect(() => {
        loadDataReAun()
        handleLoadData()
        if (recordData?.id > 0) {
            setOutstandingdebt(recordData?.balanc ? ~~recordData?.balanc : null)
            setDeductdiscount(recordData?.balanc ? (~~recordData?.balanc - ~~recordData?.approvedLoanAmount) + (~~recordData?.newDamt + ~~recordData?.followPay) : null)
            setRemaining(recordData?.balanc ? (~~outstandingdebt - ~~deductdiscount) : null)
            setPay(recordData?.newDamt ? (~~recordData?.newDamt + ~~recordData?.followPay) : null)
            setExcellent(recordData?.approvedLoanAmount ? ~~recordData?.approvedLoanAmount : null)
            {/* 
                มูลหนี้ balance
                หักส่วนลด (balance-approvedLoanAmount)+(newDamt+followPay)
                คงเหลือ หักส่วนลด - มูลหนี้
                จ่ายเงิน (newDamt+followPay)
                ยอดจัด approvedLoanAmount
            */}
        }

    }, [deductdiscount, selectedValue])

    useEffect(() => {
        if (selectedValue === '1') {
            if (recordData.PRODUCTS === "land" && sendData.checkChange) {
                handleLoadChangeLand()
            } else if (recordData.PRODUCTS === "car" && sendData.checkChange) {
                handleLoadChangeCar()
            }
        } else {
            // if (sendData?.pgLoanTerm ? sendData?.pgLoanTerm : null < (recordData?.approvedLoanTerm ?? 0) || sendData?.pgLoanTerm ? sendData?.pgLoanTerm : null > (recordData?.approvedLoanTermTo)) {
            //     form.setFieldsValue({
            //         pgLoanTerm: sendData?.pgLoanTerm
            //     })
            // }
            handleLoadNoteAun()
            handleLoadChangeCar()
        }
        if (carsApproveve?.id > 0) {
            form.setFieldsValue({
                pgLoanTerm: sendData?.pgLoanTerm ? sendData?.pgLoanTerm : carsApproveve?.pgLoanTerm,
                installment: sendData?.installment ? sendData?.installment : carsApproveve?.installment
            })
            setSendData({
                ...sendData,
                pgLoanTerm: sendData?.pgLoanTerm ? sendData?.pgLoanTerm : carsApproveve?.pgLoanTerm,
                installment: sendData?.installment ? sendData?.installment : carsApproveve?.installment
            })
        }
    }, [sendData.checkChange, sendData?.pgLoanTerm, carsApproveve])

    const loadDataReAun = async () => {
        setLoading(true)
        axios.post(loadDetailAunSendPG, { contno: recordData?.CONTNO })
            .then((res) => {
                if (res.status === 200) {
                    // console.log("res.dataNew", res.data)
                    setCarsApproveve(res?.data?.carsApproveve)
                    setDataDB2(res.data?.dataDB2)
                    setNotesCarsApprove(res?.data?.notesCarsApprove)
                    setLoading(false)
                }
            })
            .catch((err) => {
                console.log("err", err)
                setLoading(false)
            })
    }

    // const showConfirmInstallments = () => {
    //     confirm({
    //         title: 'เลือกจำนวนงวดใหม่อีกครั้ง...?',
    //         content: `กรุณาเลือกจำนวนงวดให้อยู่ระหว่าง ${recordData?.approvedLoanTerm} - ${recordData?.approvedLoanTermTo}`,
    //         onOk() {
    //             setPgLoanTerm(0)
    //             form.setFieldsValue({
    //                 pgLoanTerm: " "
    //             });
    //         },
    //         onCancel() {

    //         },
    //     });
    // };
    // console.log("recordData", recordData)

    const handleLoadNoteAun = async () => {
        await axios.post(loadNoteAun, { id: recordData.caId })
            .then((res) => {
                if (res.status === 200) {
                    // console.log("res.dataNote", res.data)
                    if (res.data) {
                        setShowNote(res.data)
                    }
                }
            })
            .catch((err) => {
                console.log("err", err)
            })
    };
    const handleLoadData = async () => {
        await axios.post(loadcontnopgre, { contno: sendData.contno })
            .then((res) => {
                if (res.status === 200) {
                    if (res.data) {
                        setSendData(res.data)
                    }
                }
            })
            .catch((err) => {
                console.log("err", err)
            })
    };
    const handleLoadChangeLand = async () => {
        await axios.post(detaillandchangepg, { CONTNO: recordData.CONTNO })
            .then((res) => {
                if (res.status === 200) {
                    if (res.data) {
                        // console.log("res.dataLAND", res.data)
                        setDataChange(res.data)
                    }
                }
            })
            .catch((err) => {
                console.log("err", err)
            })
    };
    const handleLoadChangeCar = async () => {
        await axios.post(detailcarchangepg, { CONTNO: recordData.CONTNO })
            .then((res) => {
                if (res.status === 200) {
                    if (res.data) {
                        // console.log("res.dataCAR", res.data)
                        setDataChange(res.data)
                    }
                }
            })
            .catch((err) => {
                console.log("err", err)
            })
    };


    const handleSubmit = async () => {
        setLoading(true)
        if (!sendData.checkRe && !sendData.checkPub && !sendData.checkChange) {
            warningSend("top")
            setLoading(false)
        } else {
            // dataChange
            if (selectedValue === '1') {
                var sendData2 = { ...sendData, ...dataChange?.detailChange, type: recordData?.PRODUCTS, checkDate: dayjs() }
                if (!sendData?.checkChange) {
                    delete sendData2?.overdue
                    delete sendData2?.preChange
                    delete sendData2?.priceChange
                }

            } else {
                var sendData2 = {
                    ...sendData, ...dataChange?.detailChange, type: "car", checkDate: dayjs(),
                    // pgLoanTerm: sendData?.pgLoanTerm !== 0 || sendData?.pgLoanTerm !== '' ? sendData?.pgLoanTerm : '' 
                }
                if (!sendData?.checkChange) {
                    delete sendData2?.overdue
                    delete sendData2?.preChange
                    delete sendData2?.priceChange
                }

            }
            console.log("sendData2", dataDB2)
            if (selectedValue === '2' && (!dataDB2?.NAME1)) {
                // if (selectedValue === '2' && (!dataDB2?.SNAM || !dataDB2?.NAME1 || !dataDB2?.NAME2)) {
                warningUndefined("top")
                setLoading(false)
                return
            } else {
                console.log("11")
                setLoading(false)
                await axios.post(addcontnopgre, sendData2)
                    .then((res) => {
                        if (res.status === 200) {
                            // console.log("res.data", res.data)
                            successSend("top")
                            setTimeout(() => {
                                handleClose()
                                setLoading(false)
                            }, 2000)
                        }
                    })
                    .catch((err) => {
                        console.log("err", err)
                        errorSend('top')
                        setLoading(false)
                    })
            }
        }
    };
    const showConfirmSelect = () => {
        confirm({
            title: 'กรุณาเลือกจำนวนงวดในช่วงที่กำหนด...?',
            // content: 'กด OK เพื่อยืนยันการยกเลิก',
            onOk() {
                // handleCancel()
            },
            onCancel() {

            },
        });
    }
    const showConfirmCancel = () => {
        confirm({
            title: 'คุณต้องการที่จะยกเลิกการกำหนดสัญญานี้หรือไม่...?',
            content: 'กด OK เพื่อยืนยันการยกเลิก',
            onOk() {
                handleCancel()
            },
            onCancel() {

            },
        });
    };
    const handleCancel = async () => {
        let sendData2 = { ...sendData, checkDate: dayjs() }
        await axios.delete(cancelcontnopgre, { data: sendData2 })
            .then((res) => {
                if (res.status === 200) {
                    // console.log("res.data", res.data)
                    successSend("top")
                    setTimeout(() => {
                        handleClose()
                        setLoading(false)
                    }, 2000)
                }
            })
            .catch((err) => {
                console.log("err", err)
                errorSend('top')
                setLoading(false)
            })
    };
    const handleClose = () => {
        close(false)
    };
    const handleChangeCheckbox = (e) => {
        if (e === "checkRe") {
            setSendData({
                ...sendData,
                checkRe: true,
                checkPub: false,
                checkChange: false,
            })
        } else if (e === "checkPub") {
            setSendData({
                ...sendData,
                checkRe: false,
                checkPub: true,
                checkChange: false,
            })
        } else if (e === "checkChange") {
            setSendData({
                ...sendData,
                checkRe: false,
                checkPub: false,
                checkChange: true,
            })
        }
    };
    const successSend = (placement) => {
        api.success({
            message: `บันทึกสำเร็จ`,
            placement,
        });
    };

    const errorSend = (placement) => {
        api.error({
            message: `บันทึกไม่สำเร็จ`,
            placement,
        });
    };
    const warningUndefined = (placement) => {
        api.error({
            message: `พบข้อผิดพลาด กรุณาติดต่อ IT`,
            // description:
            //     'กรุณาเลือกกำหนดสัญญาอย่างน้อย 1 อย่าง',
            placement,
        });
    };
    const warningSend = (placement) => {
        api.warning({
            message: `ไม่สามารถบันทึกได้`,
            description:
                'กรุณาเลือกกำหนดสัญญาอย่างน้อย 1 อย่าง',
            placement,
        });
    };
    const handleOpenTable = () => {
        setIsModalTable(true)
    }

    const currencyFormat = (amount) => {
        if (amount) {
            return Number(amount)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, '$&,')
        } else {
            return "-"
        }
    }
    // เลือกงวดตามช่วงที่เฮียอนุมัติ
    // const handleSelectChange = (e) => {
    //     console.log("ee", e)
    //     const isWithinRange = filteredOptions.some(option => option.e === e);

    //     setSendData({
    //         ...sendData,
    //         pgLoanTerm: `${e}`
    //     })
    //     // Additional actions like showConfirmInstallments can be added here

    //     //     showConfirmInstallments()
    //     // alert(`กรุณาเลือกจำนวนงวดให้อยู่ระหว่าง ${recordData?.approvedLoanTerm} - ${recordData?.approvedLoanTermTo}`);
    //     // } else {
    //     //     setPgLoanTerm(`${e}`)
    //     // }
    // };

    return (
        <Modal title="กำหนดสัญญา" open={open} onCancel={handleClose} footer={[null]}>
            <Card>
                <Spin spinning={loading} size='large' tip="Loading...">
                    {selectedValue === '1' ?
                        <>
                            <Row justify={'center'}>
                                <Col span={24} style={{ textAlign: 'right' }}>
                                    <b>
                                        <div style={{ marginBottom: 0 }}>
                                            <span>เลขสัญญา : </span>{<b style={{ fontSize: '20px', lineHeight: '32px' }}>{recordData?.CONTNO}</b>}
                                        </div>
                                    </b>
                                </Col>
                                <Col span={24}>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>ชื่อ-สกุล : </span>{<b>{recordData?.SNAM} {recordData?.NAME1} {recordData?.NAME2}</b>}
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>เกรด : </span>{<b>{recordData?.GRDCOD}</b>}
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>การตลาด : </span>{<b><Tag color='geekblue'>{recordData?.SALCOD}</Tag></b>}
                                    </div>
                                </Col>
                            </Row>
                            {
                                sendData.checkDate ?
                                    <Divider orientation='left'><b>เคยกำหนดสัญญาเมื่อ <u style={{ color: "red" }}>{dayjs(sendData.checkDate).format("DD-MM-YYYY")}</u></b></Divider>
                                    :
                                    <Divider />
                            }
                            <Form
                                form={form}
                                style={{ textAlign: 'center' }}
                                onFinish={handleSubmit}
                                labelCol={{
                                    span: 8,
                                }}
                                wrapperCol={{
                                    span: 16,
                                }}>
                                {/* <Form.Item> */}
                                <Checkbox checked={sendData?.checkRe} onChange={() => handleChangeCheckbox("checkRe")}>รี</Checkbox>
                                <Checkbox checked={sendData?.checkPub} onChange={(e) => handleChangeCheckbox("checkPub")}>ปรับโครงสร้าง</Checkbox>
                                <Checkbox checked={sendData?.checkChange} onChange={(e) => handleChangeCheckbox("checkChange")}>เปลี่ยนสัญญา</Checkbox>
                                {/* </Form.Item> */}
                                {
                                    sendData?.checkChange ?
                                        <>
                                            <Divider orientation='left'><b>ค่าใช้จ่ายที่ลูกค้าต้องจ่าย</b></Divider>
                                            <Col span={24} style={{ textAlign: 'left' }}>
                                                <Form.Item label="ค่าเปลี่ยนสัญญา" style={{ marginBottom: 0 }}>
                                                    {<b>{currencyFormat(dataChange?.detailChange?.priceChange)}</b>}
                                                </Form.Item>
                                                <Form.Item label="ยอดค้าง + เบี้ยปรับ" style={{ marginBottom: 0 }}>
                                                    {<b>{currencyFormat(dataChange?.detailChange?.overdue)}</b>}
                                                </Form.Item>
                                                <Form.Item label="ค่างวดล่วงหน้า 3 งวด" style={{ marginBottom: 0 }}>
                                                    {<b>{currencyFormat(dataChange?.detailChange?.preChange)}</b>}
                                                </Form.Item>
                                                <Form.Item label="รวม" style={{ marginBottom: 0 }}>
                                                    {<b>{currencyFormat(~~dataChange?.detailChange?.priceChange + ~~dataChange?.detailChange?.overdue + ~~dataChange?.detailChange?.preChange)}</b>}
                                                </Form.Item>

                                            </Col>
                                            <Divider />
                                        </>
                                        : <Divider />
                                }
                                <Col span={24} style={{ textAlign: 'left' }}>
                                    <Form.Item label="ตารางการผ่อน" style={{ margin: 0 }}>
                                        <Button type='primary' style={{ backgroundColor: "#FEA13F" }} onClick={handleOpenTable} >ตารางการผ่อน</Button>
                                    </Form.Item>
                                </Col>
                                <Divider />
                                <Space>
                                    {
                                        sendData?.id !== 0 ?
                                            <Button type='primary' style={{ backgroundColor: "#faad14", color: "black" }} onClick={showConfirmCancel}>ยกเลิกกำหนดสัญญา</Button>
                                            : null
                                    }
                                    <Button type='primary' danger onClick={handleClose}>ปิด</Button>
                                    <Button type='primary' htmlType='submit' style={{ backgroundColor: "green" }}>ยืนยัน</Button>
                                </Space>
                            </Form>
                        </>
                        :
                        <>
                            <Row justify={'center'}>
                                <Col span={24} style={{ textAlign: 'right' }}>
                                    <b>
                                        <div style={{ marginBottom: 0 }}>
                                            <span>เลขสัญญา : </span>{<b style={{ fontSize: '20px' }}>{recordData?.CONTNO}</b>}
                                        </div>
                                    </b>
                                </Col>
                                <Col span={24}>
                                    {/* <div style={{ marginBottom: 0 }}>
                                        <span>ชื่อ-สกุล : </span>{<b>{recordData?.SNAM} {recordData?.NAME1} {recordData?.NAME2}</b>}
                                    </div> */}
                                    <div style={{ marginBottom: 0 }}>
                                        <span>เกรด : </span>{<b>{recordData?.GRDCOD}</b>}
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>การตลาด : </span>{<b><Tag color='geekblue'>{recordData?.proposalBy}</Tag></b>}
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>เร่งรัด : </span>{<b><Tag color='geekblue'>{recordData?.offerBy}</Tag></b>}
                                    </div>
                                </Col>

                                <Col span={24} style={{ margin: 5 }} className='center'>
                                    <Card
                                    // style={{ width: '270px', textAlign: 'left' }}
                                    >
                                        <>

                                            <div style={{ marginBottom: 0 }} >
                                                <span>หมายเหตุ : </span>
                                                <b>{shownote?.length > 0 ? shownote[lastIndex]?.note : null}</b>
                                            </div>

                                        </>
                                    </Card>
                                </Col>

                            </Row>
                            {
                                sendData.checkDate ?
                                    <Divider orientation='left'><b>เคยกำหนดสัญญาเมื่อ <u style={{ color: "red" }}>{dayjs(sendData.checkDate).format("DD-MM-YYYY")}</u></b></Divider>
                                    :
                                    <Divider />
                            }
                            <Form
                                form={form}
                                style={{ textAlign: 'center' }}
                                onFinish={handleSubmit}

                                labelCol={{
                                    span: 8,
                                }}
                                wrapperCol={{
                                    span: 16,
                                }}

                            >
                                {/* <Form.Item> */}
                                {/* <Checkbox checked={sendData?.checkRe} onChange={() => handleChangeCheckbox("checkRe")}>รี</Checkbox> */}
                                <Checkbox checked={sendData?.checkPub} onChange={(e) => handleChangeCheckbox("checkPub")}>ปรับโครงสร้าง</Checkbox>
                                <Checkbox checked={sendData?.checkChange} onChange={(e) => handleChangeCheckbox("checkChange")}>เปลี่ยนสัญญา</Checkbox>
                                {/* </Form.Item> */}
                                {
                                    sendData?.checkChange ?
                                        <>
                                            <Divider orientation='left'><b>ค่าใช้จ่ายที่ลูกค้าต้องจ่าย</b></Divider>
                                            <Col span={24} style={{ textAlign: 'left' }}>
                                                <Form.Item label="ค่าเปลี่ยนสัญญา" style={{ marginBottom: 0 }}>
                                                    {<b>{currencyFormat(dataChange?.detailChange?.priceChange)}</b>}
                                                </Form.Item>
                                                <Form.Item label="ยอดค้าง + เบี้ยปรับ" style={{ marginBottom: 0 }}>
                                                    {<b>{currencyFormat(dataChange?.detailChange?.overdue)}</b>}
                                                </Form.Item>
                                                <Form.Item label="ค่างวดล่วงหน้า 3 งวด" style={{ marginBottom: 0 }}>
                                                    {<b>{currencyFormat(dataChange?.detailChange?.preChange)}</b>}
                                                </Form.Item>
                                                <Form.Item label="รวม" style={{ marginBottom: 0 }}>
                                                    {<b>{currencyFormat(~~dataChange?.detailChange?.priceChange + ~~dataChange?.detailChange?.overdue + ~~dataChange?.detailChange?.preChange)}</b>}
                                                </Form.Item>
                                            </Col>
                                            <Divider />
                                        </>
                                        : <Divider />
                                }
                                <Col span={24} style={{ textAlign: 'left' }}>
                                    <Form.Item label="ตารางการผ่อน" style={{ margin: 0 }}>
                                        <Button type='primary' style={{ backgroundColor: "#FEA13F" }} onClick={handleOpenTable} >ตารางการผ่อน</Button>
                                    </Form.Item>
                                </Col>
                                <Col span={24}
                                    style={{
                                        marginTop: 10,
                                        marginBottom: 10,
                                        // background: '#febb0b',
                                        textAlign: 'left'
                                    }}
                                    className='center'>
                                    <Col span={24} style={{ textAlign: 'center' }}>
                                        <Image
                                            width={200}
                                            src={`${process.env.PUBLIC_URL}/logo.jpg`}
                                            alt="My Image"
                                        />
                                    </Col>
                                    <Divider style={{ margin: 8 }} />
                                    <aside>
                                        <div>
                                            <span>วันที่ : <b>{recordData?.approvalDate ? dayjs(recordData?.approvalDate).format("DD-MM-YYYY") : '-'}</b></span>
                                        </div>
                                        <div>
                                            <span>เลขที่สัญญา :<b> {recordData?.CONTNO ? recordData?.CONTNO : null}</b></span>
                                        </div>
                                        <div>
                                            <span> {dataDB2?.PRODUCTS === "car" ? <> รถ :<b> {dataDB2?.BAAB + '  ' + dataDB2?.MODEL + '  ' + dataDB2?.TYPE}</b></> : <></>}</span>
                                        </div>
                                        <div>
                                            <span> {dataDB2?.PRODUCTS === "car" ? <> ทะเบียน : <b> {dataDB2?.REGNO}</b></> : <></>}</span>

                                        </div>
                                        <div>
                                            <span>ชื่อผู้เช่าซื้อ : <b>{dataDB2?.SNAM + ' ' + dataDB2?.NAME1 + ' ' + dataDB2?.NAME2}</b></span>
                                        </div>
                                        <div>
                                            <span>มูลหนี้เช่าซื้อคงเหลือ : <b>{currencyFormat(outstandingdebt)}  บาท</b></span>
                                        </div>
                                        <div>
                                            <span>หักส่วนลด : <b>{currencyFormat(deductdiscount)}  บาท</b></span>
                                        </div>
                                        <div>
                                            <span>คงเหลือ : <b>{currencyFormat(remaining)}  บาท</b></span>
                                        </div>
                                        <div>
                                            <span>จ่ายเงิน : <b>{currencyFormat(pay)} บาท</b></span>
                                        </div>
                                        <div>
                                            <span>ยอดจัด : <b>{currencyFormat(Excellent)}  บาท</b></span>
                                        </div>
                                        <div>
                                            <span>จำนวนงวด : <b>{sendData?.pgLoanTerm}  งวด</b></span>
                                        </div>
                                        <div>
                                            <span>ผ่อน : <b>
                                                {currencyFormat(sendData?.installment) + ' X ' + sendData?.pgLoanTerm + ' งวด ' + '  (รวมภาษีมูลค่าเพิ่ม)  '}
                                            </b>

                                            </span>

                                        </div>
                                        {/* <div>
                                            {carsApproveve?.pgLoanTerm !== 0 || carsApproveve !== "" ?
                                                <>
                                                    <span style={{ color: 'red', textAlign: 'center' }}>กรุณาเลือกจำนวนงวด : <b>{recordData?.approvedLoanTerm + ' - ' + recordData?.approvedLoanTermTo}</b> </span><br />

                                                </>
                                                :
                                                null
                                            }
                                            <span>จำนวนงวด :
                                                <b>
                                                    <Form.Item name="pgLoanTerm"
                                                        rules={[{ required: true, message: 'กรุณาเลือกจำนวนงวด' }]}
                                                    >
                                                        <Select
                                                            placeholder={'เลือกจำนวนงวด' + recordData?.approvedLoanTerm + ' - ' + recordData?.approvedLoanTermTo}
                                                            style={{ width: '200px', height: '40px', color: 'black' }}
                                                            onChange={(value) => handleSelectChange(value)}
                                                            options={filteredOptions}
                                                        />
                                                    </Form.Item>
                                                </b>
                                            </span>
                                        </div>
                                        <div>
                                            <span>ผ่อน : <b>
                                                <Form.Item name="installment"
                                                    rules={[{ required: true, message: 'กรุณาเลือกจำนวนงวด' }]}
                                                >
                                                    <Input
                                                        placeholder={"กรอกค่างวด"}
                                                        style={{ width: '200px', height: '40px' }}
                                                        onChange={(e) => setSendData({ ...sendData, installment: e.target.value })}
                                                    />
                                                </Form.Item>
                                            </b></span>
                                        </div> */}
                                    </aside>

                                </Col>
                                <Divider />
                                <Space>
                                    {
                                        sendData?.id !== 0 ?
                                            <Button type='primary' style={{ backgroundColor: "#faad14", color: "black" }} onClick={showConfirmCancel}>ยกเลิกกำหนดสัญญา</Button>
                                            : null
                                    }
                                    <Button type='primary' danger onClick={handleClose}>ปิด</Button>
                                    <Button type='primary' htmlType='submit' style={{ backgroundColor: "green" }}>ยืนยัน</Button>
                                </Space>
                            </Form>
                        </>

                    }

                </Spin>
            </Card>
            {contextHolder}
            {
                isModalTable ?
                    <ModalInstallment
                        open={isModalTable}
                        close={setIsModalTable}
                        contno={recordData?.CONTNO}
                    />
                    : null
            }
        </Modal >
    )
}

export default EditCont