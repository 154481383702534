//function dotaColums() {
  const dotaColums = [
    {
      title: "สาขา",
      dataIndex: "locat",
      key: "locat",
    },
    {
      title: "เคสทั้งหมด",
      dataIndex: "caseAll",
      key: "caseAll",
    },
    {
      title: "เคสได้ยอด",
      dataIndex: "caseSuccess",
      key: "caseSuccess",
    },
    {
      title: "รอดำเนินการ",
      dataIndex: "caseWait",
      key: "caseWait",
    },
    {
      title: "เคสไม่ได้ยอด",
      dataIndex: "caseFail",
      key: "caseFail",
    },
    {
      title: "เคสซ้ำ",
      dataIndex: "caseDoubel",
      key: "caseDoubel",
    },
  ];
//  return columns;
//}
export default dotaColums;
