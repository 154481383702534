import { createSlice } from "@reduxjs/toolkit";

const initialState = {

}

const userDataSlice = createSlice ({
    name: "user",
    initialState,
    reducers: {
        addUser: (state, action) => {
            state.userData = action.payload
        },
        addToken: (state, action) => {
            state.tokenData = action.payload
        },
        addRegister: (state, action) => {
            state.registerData = action.payload
        }
    }
    
})



export const { addUser } = userDataSlice.actions
export const { addToken } = userDataSlice.actions
export const { addRegister } = userDataSlice.actions
export default userDataSlice.reducer
