import React, { useState } from 'react'
import { Button, Form, Input, Divider, Row, Radio, Col, Modal, message } from 'antd';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { insertemail } from '../../file_mid/all_api';


function PostsEmail({ open, close, checkemail1, setcheckemail, funcemail }) {

    //useSelector
    const [messageApi, contextHolder] = message.useMessage();
    const { customers } = useSelector((state) => ({ ...state }))
    const [idPhone, setIdPhone] = useState({ customerId: customers.data.customerId })

    //useForm
    const [formaemail] = Form.useForm();
    //states
    const [emailadd, setEmailAdd] = useState({});

    //checkBox status ของที่อยู่
    const handleCancel = () => {
        close(false);
    };

    //ปุ่ม Submit in Modal Add Data Address
    const handleSubmit = async () => {
        const dataemailadd = { customer: idPhone, emails: [{ ...emailadd }] }
        await axios.post(insertemail, dataemailadd)
            .then((res) => {
                if (res) {
                    messageApi.open({
                        type: 'success',
                        content: 'บันทึกสำเร็จ',
                    });
                } else {
                    messageApi.open({
                        type: 'error',
                        content: 'บันทึกไม่สำเร็จ',
                    });
                }
            })
            .catch((err) => {
                if (err.response.request.status === 400) {
                    messageApi.open({
                        type: 'error',
                        content: 'บันทึกไม่สำเร็จ',
                    });
                }
                console.log("err", err)
            })
        if (checkemail1 === "0") {
            setEmailAdd(pre => [...pre, { ...emailadd }]);
            funcemail({ emailadd })
            setcheckemail("1")
            close(false)
        } else {
            setEmailAdd(pre => [...pre, { ...emailadd }]);
            funcemail({ emailadd })
            setcheckemail("1")
            close(false)
        }
    }

    return (
        <>
            {/* Modal เพิ่มอีเมล */}
            <Modal
                open={open}
                style={{ background: '#2f54eb' }}
                centered
                onCancel={handleCancel}
                width={600}
                footer={[
                    <Row
                        justify={'center'}
                    >
                        <Button key="back" onClick={handleCancel}>
                            ยกเลิก
                        </Button>
                        <Button
                            type="primary"
                            onClick={handleSubmit}
                        >
                            บันทึก
                        </Button>
                    </Row>
                ]}
            >
                <Form
                    form={formaemail}
                    name='adddata'
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    style={{
                        maxWidth: '100%',
                    }}
                >
                    <Row
                        justify={'center'}
                    >
                        <h3 style={{ marginBottom: 0 }}>
                            <u>เพิ่มอีเมล</u>
                        </h3>
                    </Row>
                    <Row
                        gutter={32}
                    >
                        <Col className='gutter-row' span={24}>
                            <Form.Item label="E-Mail" name='emailData' rules={[
                                {
                                    required: true,
                                    message: 'Please input !',
                                },]}>
                                <Input type="email" onChange={(e) => setEmailAdd({ ...emailadd, emailData: e.target.value })} />
                            </Form.Item>
                            <Form.Item label="รับข่าวสาร" name="sendGetNews" rules={[
                                {
                                    required: true,
                                    message: 'Please input !',
                                },]}>
                                <Radio.Group onChange={(e) => setEmailAdd({ ...emailadd, sendGetNews: e.target.value })} >
                                    <Radio value="1"> รับ </Radio>
                                    <Radio value="0"> ไม่รับ </Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item label="หมายเหตุ" name='note' >
                                <Input onChange={(e) => setEmailAdd({ ...emailadd, note: e.target.value })} />
                            </Form.Item>

                            <Divider />
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    )
};

export default PostsEmail
