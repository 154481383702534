import React, { useEffect, useState } from 'react'
import { Routes, Route } from "react-router-dom"
import MainTabel from "./checkStep/Main";

export default function MainFollow() {
  return (
    <Routes>
    <Route path="/" element={<MainTabel />} />
  </Routes>
  )
}
