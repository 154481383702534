import React, { useState, useEffect } from 'react'
import { Button, Form, Input, Select, Divider, Row, Col, Modal, Space, Card, Spin } from 'antd';
import axios from 'axios';
import '../../offer_case/css/Media.css'
import { getocc, newgetsection, newgetsub } from '../../file_mid/all_api';

export default function AddOcc({ close, changepage, page }) {
  const [form] = Form.useForm()
  const { confirm } = Modal;
  const addCareerIncomes = localStorage.getItem('addCareerIncome')
  const dataAddCareerIncome = JSON.parse(addCareerIncomes)
  const [loading, setLoading] = useState(false)
  const [occupation, setOccupation] = useState([]) // getdatabase occ
  const [sub, setSub] = useState([]) // getdatabase sub
  const [section, setSection] = useState([]) // getdatabase section
  const [countDataPost, setCountDataPost] = useState(0)
  const [dataPost, setDataPost] = useState() //เก็บอาชีพ
  const [money, setMoney] = useState() //เก็บรายได้
  const [expenses, setExpenses] = useState() //เก็บรายจ่าย

  useEffect(() => {
      if (dataAddCareerIncome) {
        setExpenses({ ...dataAddCareerIncome?.income?.expenses })
        setMoney({ ...dataAddCareerIncome?.income })
        setDataPost({ ...dataAddCareerIncome })
        bpOccupation()
        bpSubOccupation(dataAddCareerIncome?.occupationalGroup)
        bpOccupationSection(dataAddCareerIncome?.governmentPrivate)
        form.setFieldsValue(
          {
            ...dataAddCareerIncome,
            ...dataAddCareerIncome?.income,
            ...dataAddCareerIncome?.income?.expenses
          }
        )
      } else {
        loadOccupation()
        setExpenses()
        setMoney()
        setDataPost()
        form.setFieldsValue(
          {
            occupationalGroup: "",
            governmentPrivate: "",
            jobPosition: "",
            companyName: "",
            incomeMonth: '',
            expensesMonth: "",
          }
        )
      }
  }, [])

  const handleGo = () => {
    changepage(page + 1)
  }
  const handleBack = () => {
    changepage(page - 1)
  }
  const handleClose = () => {
    close()
  }

  const handleSubmit = () => {
    setLoading(true)
    if (dataPost) {
      delete dataPost.income
      delete money.expenses
      if (dataAddCareerIncome) {
        if (countDataPost === 0) {
          var show = {
            ...dataPost,
            occupationalGroupTH: occupation?.find((e) => e.idOccupation === dataAddCareerIncome?.occupationalGroup)?.desc,
            governmentPrivateTH: sub?.find((e) => e.idSub === dataAddCareerIncome?.governmentPrivate)?.descSub,
            jobPositionTH: section?.find((e) => e.idSection === dataAddCareerIncome?.jobPosition)?.descSection,
            income: { ...money, incomesMainId: dataAddCareerIncome?.income?.incomesMainId, incomeMainDescription: money.incomeMainDescription ? money.incomeMainDescription : null, expenses: { ...expenses, expensesId: dataAddCareerIncome?.income?.expenses.expensesId, expensesMonthDescription: expenses.expensesMonthDescription ? expenses.expensesMonthDescription : null } }
          }
          var req = {
            ojs: {
              ...dataPost,
              occupationalGroupTH: occupation?.find((e) => e.idOccupation === dataAddCareerIncome?.occupationalGroup)?.desc,
              governmentPrivateTH: sub?.find((e) => e.idSub === dataAddCareerIncome?.governmentPrivate)?.descSub,
              jobPositionTH: section?.find((e) => e.idSection === dataAddCareerIncome?.jobPosition)?.descSection,
            }, income: { ...money, incomesMainId: dataAddCareerIncome?.income?.incomesMainId, incomeMainDescription: money.incomeMainDescription ? money.incomeMainDescription : null }, expenses: { ...expenses, expensesId: dataAddCareerIncome?.income?.expenses.expensesId, expensesMonthDescription: expenses.expensesMonthDescription ? expenses.expensesMonthDescription : null }
          }
        }
        if (countDataPost === 1) {
          var show = {
            ...dataPost,
            income: { ...money, incomesMainId: dataAddCareerIncome?.income?.incomesMainId, incomeMainDescription: money.incomeMainDescription ? money.incomeMainDescription : null, expenses: { ...expenses, expensesId: dataAddCareerIncome?.income?.expenses.expensesId, expensesMonthDescription: expenses.expensesMonthDescription ? expenses.expensesMonthDescription : null } }
          }
          var req = {
            ojs: {
              ...dataPost,
            },
            income: { ...money, incomesMainId: dataAddCareerIncome?.income?.incomesMainId, incomeMainDescription: money.incomeMainDescription ? money.incomeMainDescription : null }, expenses: { ...expenses, expensesId: dataAddCareerIncome?.income?.expenses.expensesId, expensesMonthDescription: expenses.expensesMonthDescription ? expenses.expensesMonthDescription : null }
          }
        }
        localStorage.setItem('addOccupation', JSON.stringify({ ...req }))
        localStorage.setItem('addCareerIncome', JSON.stringify({ ...show }))
        handleGo()
      } else {
        var show = { ...dataPost, mainOccupation: true, income: { ...money, mainOccupation: true, incomeMainDescription: money.incomeMainDescription ? money.incomeMainDescription : null, expenses: { ...expenses, expensesMonthDescription: expenses.expensesMonthDescription ? expenses.expensesMonthDescription : null } } }
        const req = { ojs: { ...dataPost, mainOccupation: true }, income: { ...money, incomeMainDescription: money.incomeMainDescription ? money.incomeMainDescription : null }, expenses: { ...expenses, expensesMonthDescription: expenses.expensesMonthDescription ? expenses.expensesMonthDescription : null } }
        localStorage.setItem('addOccupation', JSON.stringify({ ...req }))
        localStorage.setItem('addCareerIncome', JSON.stringify({ ...show }))
        handleGo()
      }
    } else {
      handleGo()
    }
    setLoading(false)
  }

  const loadOccupation = async () => {
    setLoading(true)
    await axios.get(getocc)
      .then((res) => {
        setOccupation(res.data)
        setLoading(false)
      })
      .catch((err) => console.log(err))
    setLoading(false)
  }

  const loadSubOccupation = async (value) => {
    const idOccupation = { idOccupation: value }
    setLoading(true)
    await axios.post(newgetsub, idOccupation)
      .then((res) => {
        setSub(res.data)
        setLoading(false)
        form.setFieldsValue(
          {
            governmentPrivate: "",
            jobPosition: "",
            companyName: "",
          }
        )
      })
      .catch((err) => console.log(err))
    setLoading(false)
  }

  const loadOccupationSection = async (value) => {
    const idSub = { idSub: value }
    setLoading(true)
    await axios.post(newgetsection, idSub)
      .then((res) => {
        setSection(res.data)
        setLoading(false)
        form.setFieldsValue(
          {
            jobPosition: "",
            companyName: "",
          }
        )
      })
      .catch((err) => console.log(err))
    setLoading(false)
  }
  // back page 
  const bpOccupation = async () => {
    setLoading(true)
    await axios.get(getocc)
      .then((res) => {
        setOccupation(res.data)
        setLoading(false)
      })
      .catch((err) => console.log(err))
    setLoading(false)
  }

  const bpSubOccupation = async (value) => {
    const idOccupation = { idOccupation: value }
    setLoading(true)
    await axios.post(newgetsub, idOccupation)
      .then((res) => {
        setSub(res.data)
        setLoading(false)
      })
      .catch((err) => console.log(err))
    setLoading(false)
  }

  const bpOccupationSection = async (value) => {
    const idSub = { idSub: value }
    setLoading(true)
    await axios.post(newgetsection, idSub)
      .then((res) => {
        setSection(res.data)
        setLoading(false)
      })
      .catch((err) => console.log(err))
    setLoading(false)
  }

  const getOccupation = (value) => {
    setCountDataPost(1)
    setDataPost({
      ...dataPost,
      governmentPrivate: "",
      governmentPrivateTH: "",
      jobPosition: "",
      companyName: "",
      occupationalGroup: value,
      occupationalGroupTH: occupation.find((e) => e.idOccupation === value).desc
    })
    loadSubOccupation(value)
  }

  const getSub = (value) => {
    setCountDataPost(1)
    setDataPost({
      ...dataPost,
      jobPosition: 32,
      jobPositionTH: "",
      idPloccupation: 12,
      idArgroup: 11,
      companyName: "",
      governmentPrivate: value,
      governmentPrivateTH: sub.find((e) => e.idSub === value).descSub,
      idPljob: sub.find((e) => e.idSub === value).idPljob
    })
    loadOccupationSection(value)
  }

  const getSection = (value) => {
    setCountDataPost(1)
    setDataPost({
      ...dataPost,
      jobPosition: value,
      jobPositionTH: section.find((e) => e.idSection === value).descSection,
      idPloccupation: section.find((e) => e.idSection === value).idPloccupation,
      idArgroup: section.find((e) => e.idSection === value).idArgroup
    })
  }

  return (
    <>
      <Row justify={'center'}>
        <Card style={{ width: '100%' }}>
          <Spin spinning={loading} size='large' tip=" Loading... ">
            <Form
              form={form}
              onFinish={handleSubmit}
              name='add'
              labelCol={{
                span: 12,
              }}
              wrapperCol={{
                span: 24,
              }}
            >
              <Divider orientation='center' style={{ fontSize: "25px" }}><b><u>เพิ่มอาชีพ</u></b></Divider>
              <Row className='main' justify={'center'}>
                <Col style={{ padding: '10px' }}>

                  <div>
                    <Form.Item label='กลุ่มอาชีพ' name='occupationalGroup'
                      rules={[
                        {
                          required: true,
                          message: 'Please input !',
                        },]}
                    >
                      <Select
                        placeholder={'เลือก'}
                        onChange={getOccupation}
                      >
                        {occupation?.map((item) => {
                          return (
                            <Select.Option key={item.idOccupation} value={item.idOccupation}>
                              {item.desc}
                            </Select.Option>
                          )
                        })}

                      </Select>
                    </Form.Item>

                    <Form.Item label='เลือกอาชีพ' name='governmentPrivate'
                      rules={[
                        {
                          required: true,
                          message: 'Please input !',
                        },]}
                    >
                      <Select
                        placeholder={'เลือก'}
                        onChange={getSub}
                      >
                        {sub?.map((item) => {
                          return (
                            <Select.Option key={item.idSub} value={item.idSub}>
                              {item.descSub}
                            </Select.Option>
                          )
                        })}

                      </Select>
                    </Form.Item>

                    <Form.Item label='สาขาอาชีพ' name='jobPosition'>
                      <Select
                        placeholder={'เลือก'}
                        onChange={getSection}
                      >
                        {section?.map((item) => {
                          return (
                            <Select.Option key={item.idSection} value={item.idSection}>
                              {item.descSection}
                            </Select.Option>
                          )
                        })}

                      </Select>
                    </Form.Item>

                    <Form.Item label='ชื่อบริษัท สถานที่ทำงาน' name='companyName'
                      rules={[
                        {
                          required: true,
                          message: 'Please input !',
                        },]}
                    >
                      <Input type='text'
                        style={{ color: 'black' }}
                        onChange={(e) => setDataPost({ ...dataPost, companyName: e.target.value })}></Input>
                    </Form.Item>

                    <Form.Item label='หมายเหตุ' name='occupationDescription' >
                      <Input type='text'
                        onChange={(e) => setDataPost({ ...dataPost, occupationDescription: e.target.value })}></Input>
                    </Form.Item>
                  </div>

                  <div>
                    <Form.Item label='รายได้ต่อเดือน' name='incomeMonth'
                      rules={[
                        {
                          required: true,
                          message: 'Please input !',
                        },]}
                    >
                      <Input type='text' suffix='บาท'
                        style={{ color: 'black' }}
                        onChange={(e) => setMoney({ ...money, incomeMonth: parseInt(e.target.value) })}></Input>
                    </Form.Item>

                    <Form.Item label='หมายเหตุ' name='incomeMainDescription' >
                      <Input type='text'
                        onChange={(e) => setMoney({ ...money, incomeMainDescription: e.target.value })}></Input>
                    </Form.Item>

                    <Form.Item label='ค่าใช้จ่ายต่อ / เดือน' name='expensesMonth'
                      rules={[
                        {
                          required: true,
                          message: 'Please input !',
                        },]}
                    >
                      <Input type='text' suffix='บาท'
                        style={{ color: 'black' }}
                        onChange={(e) => setExpenses({ ...expenses, expensesMonth: parseInt(e.target.value) })}></Input>
                    </Form.Item>

                    <Form.Item label='หมายเหตุ' name='expensesMonthDescription' >
                      <Input type='text'
                        style={{ color: 'black' }}
                        onChange={(e) => setExpenses({ ...expenses, expensesMonthDescription: e.target.value })}></Input>
                    </Form.Item>

                  </div>

                </Col>
              </Row>
              <Divider style={{ margin: 5 }} />
              <Row justify={'center'} >
                <Space>
                  <Button type='primary' onClick={handleBack} >ย้อนกลับ</Button>
                  <Button type='primary' danger onClick={handleClose} >ยกเลิก</Button>
                  <Button type='primary' htmlType='submit' style={{ backgroundColor: "green" }} >ต่อไป</Button>
                </Space>
              </Row>
            </Form>
          </Spin>
        </Card>
      </Row>
    </>
  )
}
