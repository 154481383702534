import React, { useState, useEffect } from 'react'
import { Form, Modal, Row, Spin, Space, Button, Divider, Col, Card, Tag, Image, Checkbox } from 'antd'
import axios from 'axios';
import dayjs from "dayjs";
import { loadOJS, loadDataRe, getImagess, checkgu, getAllFolderGuaImg, getImagessGua } from '../../file_mid/all_api';

export default function ResultCase({ open, close, data }) {
    // const { confirm } = Modal
    const [form] = Form.useForm();
    const token = localStorage.getItem('token')
    const [loading, setLoading] = useState(false)
    const [allData, setAllData] = useState()
    const [career, setCareer] = useState([]) // เก็บข้อมูลอาชีพ
    const [noteCarRe, setNoteCarRe] = useState([]) // เก็บโน๊ต
    const [showImg64, setShowImg64] = useState([]) // โชว์รูป
    const [guarantor1, setGuarantor1] = useState([])
    const [getimages, setGetImages] = useState([])

    useEffect(() => {
        loadDataContno()
    }, [])
    console.log("allData", allData)
    const loadCareer = async () => {
        const id = { customerId: data?.customerId }
        await axios.post(loadOJS, id)
            .then((res) => {
                // console.log("loadCareer", res.data)
                setCareer(res.data)
            })
            .catch((err) => console.log(err))
    }

    const loadGuarantors = async (loanId, allId) => { //คนค้ำ
        // console.log(loanId, allId)
        await axios.post(checkgu, { typeLoan: "car_re", loanId: loanId })
            .then(res => {
                if (res.status === 200) {
                    // console.log("loadGuarantors", res.data)
                    setGuarantor1(res.data)
                    // getImg(allId)
                } else if (res.status === 204) {
                    // console.log("ไม่พบข้อมูล")
                }
            }).catch((err) => {
                console.log("err", err)
            })
    }
    // const getImg = async (id) => {
    //     var path = `one_images/car/${id}/5`
    //     await axios.get(`${getAllFolderGuaImg}/${path}?token=${token}`)
    //         .then(res => {
    //             if (res.data) {
    //                 // console.log("NEW IMG", res.data)
    //                 setGetImages(res.data)
    //             } else {
    //                 setGetImages([])
    //             }
    //         })
    //         .catch((err) => {
    //             console.log("err", err)
    //         })
    // }


    const loadDataContno = async () => {
        if (data?.reLoanId && data?.customerId && data?.carId) {
            var mainData = { "reLoanId": data?.reLoanId, "customerId": data?.customerId, "carId": data?.carId }
            setLoading(true)
            await axios.post(loadDataRe, mainData)
                // await axios.post(`http://localhost:8080/api/load-data-re`, mainData)
                .then(async (res) => {
                    console.log("loadDataContno", res.data)
                    setAllData(res.data)
                    if (res.data?.cars?.carLoanDetailsRe?.notesCarRe?.length > 0) {
                        setNoteCarRe(res.data?.cars?.carLoanDetailsRe?.notesCarRe)
                    }
                    // if (res.data?.cars?.carLoanDetailsRe?.GRDCOD !== "A") {
                    //     console.log("!A")
                    setShowImg64(res.data?.cars?.img)
                    await loadCareer()
                    await loadGuarantors(data?.reLoanId, data?.carId)
                    // await loadOccupation()
                    // }
                    setLoading(false)
                }).catch((err) => {
                    console.log(err)
                    alert("ไม่พบข้อมูล !!!")
                })
            setLoading(false)
        } else {
            alert("การโหลดข้อมูลมีปัญหา กรุณาปิดแล้วเปิดใหม่ครับ/ค่ะ")
        }
    }

    const handleSubmit = async (checkOK, app) => {
        setLoading(true)

        setLoading(false)
    };

    const currencyFormatOne = (amount) => {
        if (amount) {
            return Number(amount)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, '$&,')
        } else {
            return "..."
        }
    }
    const showConfirmCancel = () => {
        // confirm({
        //     title: 'คุณต้องการยกเลิก...?',
        //     onOk() {
        close(false);
        //     },
        //     onCancel() {
        //     },
        // });
    };

    // console.log("allData83", allData)
    const renderGuarantor = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
        return (
            <>
                <div><b><u>ลำดับที่ {index + 1}</u></b></div>
                <aside style={{ width: '90%' }}>
                    <div style={{ marginBottom: 0 }}>
                        <span>ชื่อ-นามสกุล : </span><b>{item?.snam + ' ' + item?.firstname + ' ' + item?.lastname}</b>
                    </div>

                    <div style={{ marginBottom: 0 }}>
                        <span>ความสัมพันธ์ : </span> <b>{item?.description}</b>
                    </div>
                    {item?.phones?.length > 0 ?
                        <>
                            <div style={{ marginBottom: 0 }}>
                                <span>เบอร์โทรติดต่อ : </span> <b>{item?.phones[0]?.telp}</b>
                            </div>
                        </>
                        : <div style={{ marginBottom: 0 }}>
                            <span>เบอร์โทรติดต่อ : </span> <b>ไม่มี</b>
                        </div>
                    }

                    {item?.address?.length > 0 ?
                        <>
                            <div style={{ marginBottom: 0 }}>
                                <span > ที่อยู่ :  </span>
                                <b>
                                    {
                                        item.address[0]?.houseNumber + ' ' +
                                        "หมู่ " + item.address[0]?.soi + ' ' +
                                        "ถ." + item.address[0]?.road + ' ' +
                                        "ต." + item.address[0]?.subdistrict + ' ' +
                                        "อ." + item.address[0]?.district + ' ' +
                                        "จ." + item.address[0]?.province + ' '
                                        + item.address[0]?.zipCode
                                    }
                                </b>
                            </div>
                        </>
                        : null
                    }

                </aside>
                <Divider style={{ margin: 5 }} />
            </>
        )
    }
    const renderGuarantorNewImg = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
        // console.log("Item", item, index)
        var itemSplit = []
        if (item?.pathImg) {
            itemSplit = item.pathImg.split("/")
        }
        return (
            <>
                <div><b><u>ลำดับที่ {index + 1}</u></b></div>
                <aside style={{ width: '90%' }}>
                    <div style={{ marginBottom: 0 }}>
                        <span>ชื่อ-นามสกุล : </span><b>{item?.snam + ' ' + item?.firstname + ' ' + item?.lastname}</b>
                    </div>

                    <div style={{ marginBottom: 0 }}>
                        <span>ความสัมพันธ์ : </span> <b>{item?.description}</b>
                    </div>
                    {item?.phones?.length > 0 ?
                        <>
                            <div style={{ marginBottom: 0 }}>
                                <span>เบอร์โทรติดต่อ : </span> <b>{item?.phones[0]?.telp}</b>
                            </div>
                        </>
                        : <div style={{ marginBottom: 0 }}>
                            <span>เบอร์โทรติดต่อ : </span> <b>ไม่มี</b>
                        </div>
                    }

                    {item?.address?.length > 0 ?
                        <>
                            <div style={{ marginBottom: 0 }}>
                                <span > ที่อยู่ :  </span>
                                <b>
                                    {
                                        item.address[0]?.houseNumber + ' ' +
                                        "หมู่ " + item.address[0]?.soi + ' ' +
                                        "ถ." + item.address[0]?.road + ' ' +
                                        "ต." + item.address[0]?.subdistrict + ' ' +
                                        "อ." + item.address[0]?.district + ' ' +
                                        "จ." + item.address[0]?.province + ' '
                                        + item.address[0]?.zipCode
                                    }
                                </b>
                            </div>
                        </>
                        : null
                    }

                </aside>
                {/* <Divider/> */}
                {itemSplit.length > 0 ? (
                    <>
                        <Row justify={"center"}>
                            {showImg64?.map((items, index) => {
                                const segments = items.pathImage.split("/")
                                if (itemSplit[4] === segments[4] && items.type === 5) {
                                    return (
                                        // <Image width={'50px'} key={index} src={`data:${items?.Attr};base64,${items?.data?.toString('base64')}`} alt={`Image ${index + 1}`} />
                                        <Image width={"50px"} key={index} src={`${getImagessGua}/${items.pathImage}?token=${token}`} alt={`รูปภาพ ${index + 1}`} />
                                    )
                                }
                            }
                            )}
                        </Row>
                    </>
                ) : null
                }
                <Divider style={{ margin: 5 }} />
            </>
        )
    }

    return (
        <div>
            <Modal open={open} onCancel={showConfirmCancel} width={1100} footer={null} >
                <Spin tip="Loading..." spinning={loading} >
                    <Divider orientation={'center'} style={{ fontSize: '25px' }}><b><u>รายละเอียดสัญญา</u></b></Divider>
                    <Form
                        name="basic"
                        labelCol={{
                            span: 12,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}

                        initialValues={{
                            remember: true,
                        }}
                        form={form}
                        // onFinish={handleSubmit}
                        autoComplete="off"
                    >
                        <Space
                            direction="vertical"
                            size="middle"
                            style={{
                                display: 'flex',
                            }}>
                            <Row gutter={32} justify={'center'}>
                                <Card style={{ width: '95%' }}>
                                    <Row>
                                        <Col span={12} style={{ textAlign: 'left' }}>
                                            {
                                                allData?.cars?.carLoanDetailsRe?.productLoanType === "Pre-Aaprove-เปลี่ยนสัญญา" ||
                                                    allData?.cars?.carLoanDetailsRe?.productLoanType === "Pre-Aaprove-ปรับโครงสร้าง" ||
                                                    allData?.cars?.carLoanDetailsRe?.productLoanType === "Pre-Aaprove-รี" ||
                                                    allData?.cars?.carLoanDetailsRe?.productLoanType === "Pre-Aaprove-รี+เปลี่ยนสัญญา" ||
                                                    allData?.cars?.carLoanDetailsRe?.productLoanType === "รี+เปลี่ยนสัญญา" ||
                                                    allData?.cars?.carLoanDetailsRe?.productLoanType === "เปลี่ยนสัญญา" ||
                                                    allData?.cars?.carLoanDetailsRe?.productLoanType === "ปรับโครงสร้าง" ||
                                                    allData?.cars?.carLoanDetailsRe?.productLoanType === "รี"
                                                    ?
                                                    <>
                                                        <div style={{ margin: 0 }}>
                                                            เลขสัญญา : <Tag color="geekblue">{allData?.cars?.ISSUNO}</Tag>
                                                        </div>
                                                        <div style={{ margin: 0 }}>
                                                            เกรด : <Tag color="green">{allData?.cars?.carLoanDetailsRe?.GRDCOD}</Tag>
                                                        </div>
                                                    </>
                                                    :
                                                    null
                                            }
                                        </Col>
                                        <Col span={12} style={{ textAlign: 'right' }}>
                                            <div style={{ margin: 0 }}>
                                                สถานะ : <Tag color="geekblue">{allData?.cars?.carLoanDetailsRe?.approvalStatus}</Tag>
                                            </div>
                                            <div style={{ margin: 0 }}>
                                                ประเภทขอกู้ : <Tag color="green">{allData?.cars?.carLoanDetailsRe?.productLoanType}</Tag>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='main' justify={'center'}>
                                        <Col span={24}>
                                            <Divider orientation={'left'}><b><u>รายละเอียด</u></b></Divider>

                                            <aside style={{ width: '100%' }}>
                                                <b>
                                                    <div style={{ margin: 0, }}>
                                                        <span>ตัดสด ณ วันที่ : </span>
                                                        <b style={{ fontSize: '18px' }}>
                                                            <u>
                                                                {dayjs(allData?.cars?.carLoanDetailsRe?.ftime).format("DD-MM-YYYY")}
                                                            </u>
                                                        </b>
                                                    </div>
                                                </b>
                                                <div label='เลขที่สัญญา' name='ISSUNO' style={{ marginBottom: 3 }}>
                                                    <span>เลขที่สัญญา : </span> <b>{allData?.cars?.ISSUNO}</b>
                                                </div>
                                                <div label='ชื่อ-สกุล' name='snam' style={{ marginBottom: 3 }}>
                                                    <span>ชื่อ-สกุล : </span> <b>{allData?.firstname + ' ' + allData?.lastname}</b>
                                                </div>
                                                {
                                                    allData?.phones?.length > 0 ?
                                                        <div label='เบอร์' name='telp' style={{ marginBottom: 3 }}>
                                                            <span>เบอร์ : </span> <b>{allData?.phones[0]?.telp}</b>
                                                        </div>
                                                        : null
                                                }
                                                <div label='เกรด' name='GRDCOD' style={{ marginBottom: 3 }}>
                                                    <span>เกรด : </span> <b> {allData?.cars?.carLoanDetailsRe?.GRDCOD}</b>
                                                </div>


                                            </aside>
                                            {allData?.cars?.carLoanDetailsRe?.priceChange ?
                                                <>
                                                    {
                                                        allData?.cars?.carLoanDetailsRe?.productLoanType === "Pre-Aaprove-เปลี่ยนสัญญา" ||
                                                            allData?.cars?.carLoanDetailsRe?.productLoanType === "เปลี่ยนสัญญา"
                                                            ?
                                                            <>
                                                                <Divider style={{ margin: 3 }} />
                                                                <Divider orientation={'left'}><b><u>ค่าใช้จ่ายที่ลูกค้าต้องจ่าย</u></b></Divider>

                                                                <aside style={{ width: '100%', background: '#061178', padding: '8px', color: 'white' }} className='center'>
                                                                    <div name='priceChange' style={{ marginBottom: 3 }}>
                                                                        <span>ค่าเปลี่ยนสัญญา : </span> <b> {currencyFormatOne(allData?.cars?.carLoanDetailsRe?.priceChange)}</b>
                                                                    </div>
                                                                    <div name='overdue' style={{ marginBottom: 3 }}>
                                                                        <span>ยอดค้าง + เบี้ยปรับ : </span> <b> {currencyFormatOne(allData?.cars?.carLoanDetailsRe?.overdue)}</b>
                                                                    </div>
                                                                    <div name='preChange' style={{ marginBottom: 3 }}>
                                                                        <span>ค่างวดล่วงหน้า : </span> <b> {currencyFormatOne(allData?.cars?.carLoanDetailsRe?.preChange)}</b>
                                                                    </div><br />
                                                                    <div>
                                                                        <span>ยอดรวม : </span> <b> {currencyFormatOne(~~allData?.cars?.carLoanDetailsRe?.preChange + ~~allData?.cars?.carLoanDetailsRe?.overdue + ~~allData?.cars?.carLoanDetailsRe?.priceChange
                                                                        )}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </div>
                                                                </aside>
                                                                <Divider style={{ margin: 3 }} />
                                                            </>
                                                            :
                                                            null
                                                    }
                                                </>
                                                :
                                                null
                                            }

                                            {
                                                career?.length > 0 ?
                                                    <>
                                                        <Divider orientation={'left'}><b><u>อาชีพ</u></b></Divider>
                                                        {career?.map((e, index) => {
                                                            return (
                                                                <aside style={{ width: '100%' }}>
                                                                    <div style={{ margin: 0, }}>
                                                                        <span>กลุ่มอาชีพ : </span>
                                                                        <b>{e?.desc}</b>
                                                                    </div>
                                                                    <div style={{ margin: 0, }}>
                                                                        <span>ชื่อบริษัท(สถานที่ทำงาน) : </span>
                                                                        <b>{e?.companyName}</b>
                                                                    </div>
                                                                    <div style={{ margin: 0, }}>
                                                                        <span>อาชีพ  : </span>
                                                                        <b>{e?.descSub}</b>
                                                                    </div>
                                                                    <div style={{ margin: 0, }}>
                                                                        <span>รายได้ / เดือน : </span>
                                                                        <b>{currencyFormatOne(e?.incomeMonth)}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </div>
                                                                    <div style={{ margin: 0, }}>
                                                                        <span>สาขาอาชีพ : </span>
                                                                        <b>{e?.descSection}</b>
                                                                    </div>
                                                                    <div style={{ margin: 0, }}>
                                                                        <span>
                                                                            รายจ่าย / เดือน : </span>
                                                                        <b>{currencyFormatOne(e?.expensesMonth)}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </div>
                                                                </aside>

                                                            )
                                                        })}
                                                    </>
                                                    : null
                                            }


                                            <Divider orientation={'left'}><b><u>รายละเอียดสินค้า</u></b></Divider>
                                            <aside style={{ width: '100%' }}>
                                                <div label='ชนิดรถ' name='productTypeCar' style={{ marginBottom: 3 }}>
                                                    <span>ชนิดรถ : </span><b>{
                                                        allData?.cars?.productTypeCar === 1 && ("รถยนต์")
                                                    }
                                                        {
                                                            allData?.cars?.productTypeCar === 2 && ("รถเครื่องจักการเกษตร")
                                                        }
                                                        {
                                                            allData?.cars?.productTypeCar === 3 && ("รถบรรทุก")
                                                        }</b>
                                                </div>
                                                <div label='ทะเบียนรถ' name='carPlateNumber' style={{ marginBottom: 3 }}>
                                                    <span>ทะเบียนรถ : </span><b>{allData?.cars?.carPlateNumber}</b>
                                                </div>
                                                <div label='ยี่ห้อ' name='carBrand' style={{ marginBottom: 3 }}>
                                                    <span>ยี่ห้อ : </span><b>{allData?.cars?.carBrand}</b>
                                                </div>
                                                <div label='รุ่น' name='carModel' style={{ marginBottom: 3 }}>
                                                    <span>รุ่น : </span><b>{allData?.cars?.carModel}</b>
                                                </div>
                                                <div label='แบบ' name='carBaab' style={{ marginBottom: 3 }}>
                                                    <span>แบบ : </span><b>{allData?.cars?.carBaab}</b>
                                                </div>
                                                <div label='ปี' name='carYear' style={{ marginBottom: 3 }}>
                                                    <span>ปี : </span><b>{allData?.cars?.carYear}</b>
                                                </div>
                                                <div label='เจ้าหน้าที่ทำเคส' name='proposalBy' style={{ marginBottom: 3 }}>
                                                    <span>เจ้าหน้าที่ทำเคส : </span><b>{allData?.cars?.carLoanDetailsRe?.proposalBy}</b>
                                                </div>
                                                <b>
                                                    <div style={{ margin: 0, }}>
                                                        <span>เรทรถ : </span>
                                                        <b style={{ color: 'red', fontSize: '18px' }}><u>{currencyFormatOne(allData?.cars?.carPrice)}</u></b>
                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                            บาท
                                                        </span>
                                                    </div>
                                                </b>
                                            </aside>

                                            {
                                                allData?.cars?.carLoanDetailsRe?.approvalStatus !== "ยื่นคำร้อง" && allData?.cars?.carLoanDetailsRe?.approvalStatus !== "ปฏิเสธคำร้อง" ?
                                                    <>
                                                        <Divider orientation={'left'}><b><u>รายละเอียดยอดกู้</u></b></Divider>
                                                        <aside style={{ width: '100%' }}>

                                                            <div label='ค่างวด' name='DAMT' style={{ marginBottom: 3 }}>
                                                                <span>ค่างวด : </span><b>{allData?.cars?.carLoanDetailsRe?.DAMT ? currencyFormatOne(allData?.cars?.carLoanDetailsRe?.DAMT) + " บาท" : 0}</b>
                                                            </div>
                                                            <div label='ยอดจัดครั้งที่แล้ว' name='tcshprc' style={{ marginBottom: 3 }}>
                                                                <span>ยอดจัดครั้งที่แล้ว : </span><b>{allData?.cars?.carLoanDetailsRe?.tcshprc ? currencyFormatOne(allData?.cars?.carLoanDetailsRe?.tcshprc) + " บาท" : 0}</b>
                                                            </div>
                                                            <div label='ราคาเช่าซื้อครั้งที่แล้ว' name='balanc' style={{ marginBottom: 3 }}>
                                                                <span>ราคาเช่าซื้อครั้งที่แล้ว : </span><b>{allData?.cars?.carLoanDetailsRe?.balanc ? currencyFormatOne(allData?.cars?.carLoanDetailsRe?.balanc) + " บาท" : 0}</b>
                                                            </div>
                                                            <div label='ยอดที่ชำระมาแล้วทั้งหมด' name='totalpayment' style={{ marginBottom: 3 }}>
                                                                <span>ยอดที่ชำระมาแล้วทั้งหมด : </span><b>{allData?.cars?.carLoanDetailsRe?.totalpayment ? currencyFormatOne(allData?.cars?.carLoanDetailsRe?.totalpayment) + " บาท" : 0}</b>
                                                            </div>
                                                            <div label='ล/น คงเหลือรวม' name='neekong' style={{ marginBottom: 3 }}>
                                                                <span>ล/น คงเหลือรวม : </span><b>{allData?.cars?.carLoanDetailsRe?.neekong ? currencyFormatOne(allData?.cars?.carLoanDetailsRe?.neekong) + " บาท" : 0}</b>
                                                            </div>
                                                            <div label='เบี้ยปรับ' name='beeypup' style={{ marginBottom: 3 }}>
                                                                <span>เบี้ยปรับ : </span><b>{allData?.cars?.carLoanDetailsRe?.beeypup ? currencyFormatOne(allData?.cars?.carLoanDetailsRe?.beeypup) + " บาท" : 0}</b>
                                                            </div>
                                                            <div label='ลูกหนี้อื่นๆ' name='OTHR' style={{ marginBottom: 3 }}>
                                                                <span>ลูกหนี้อื่นๆ : </span><b>{allData?.cars?.carLoanDetailsRe?.OTHR ? currencyFormatOne(allData?.cars?.carLoanDetailsRe?.OTHR) + " บาท" : 0}</b>
                                                            </div>
                                                            <div label='จำนวนงวดที่ผ่อนมาแล้ว' name='NOPAY' style={{ marginBottom: 3 }}>
                                                                <span>จำนวนงวดที่ผ่อนมาแล้ว : </span><b>{allData?.cars?.carLoanDetailsRe?.NOPAY ? currencyFormatOne(allData?.cars?.carLoanDetailsRe?.NOPAY) + " งวด" : 0}</b>
                                                            </div>
                                                            <div label='จำนวนงวดทั้งหมด' name='nopay' style={{ marginBottom: 3 }}>
                                                                <span>จำนวนงวดทั้งหมด : </span><b>{allData?.cars?.carLoanDetailsRe?.nopays ? currencyFormatOne(allData?.cars?.carLoanDetailsRe?.nopays) + " งวด" : 0}</b>
                                                            </div>
                                                            <div label='ตัดสด งวดที่' name='reqNo' style={{ marginBottom: 3 }}>
                                                                <span>ตัดสด งวดที่ : </span><b>{allData?.cars?.carLoanDetailsRe?.reqNo ? currencyFormatOne(allData?.cars?.carLoanDetailsRe?.reqNo) : 0}</b>
                                                            </div>
                                                        </aside>
                                                        <Divider orientation={'left'}><b><u>รายละเอียดจัดใหม่</u></b></Divider>
                                                        <aside style={{ width: '100%' }}>
                                                            <b>
                                                                <div style={{ margin: 0, }}>
                                                                    <span>ยอดรีไฟแนนซ์เพื่อจัดใหม่ : </span>
                                                                    <b style={{ color: 'red', fontSize: '18px' }}><u>{currencyFormatOne(allData?.cars?.carLoanDetailsRe?.re1)}</u></b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </div>
                                                            </b>
                                                            <b>
                                                                <div style={{ margin: 0, }}>
                                                                    <span>ยอดจัดใหม่ : </span>
                                                                    <b style={{ color: 'red', fontSize: '18px' }}><u>{currencyFormatOne(allData?.cars?.carLoanDetailsRe?.approvedLoanAmount)}</u></b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </div>
                                                            </b>
                                                            <b>
                                                                <div style={{ margin: 0, }}>
                                                                    <span>จำนวนงวด : </span>
                                                                    <b style={{ color: 'red', fontSize: '18px' }}><u>{currencyFormatOne(allData?.cars?.carLoanDetailsRe?.approvedLoanTerm)}</u></b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        งวด
                                                                    </span>
                                                                </div>
                                                            </b>
                                                        </aside>
                                                        <Divider orientation={'left'}><b><u>การค้ำโอน</u></b></Divider>
                                                        <Row justify={'center'}>
                                                            <Checkbox
                                                                style={{ fontSize: '20px' }}
                                                                checked={allData?.cars?.carLoanDetailsRe?.checkGua === "ค้ำ-ไม่โอน" ? true : false}
                                                            >ค้ำ-ไม่โอน
                                                            </Checkbox>
                                                            <Checkbox
                                                                style={{ fontSize: '20px' }}
                                                                checked={allData?.cars?.carLoanDetailsRe?.checkGua === "ค้ำ-โอน" ? true : false}
                                                            >ค้ำ-โอน
                                                            </Checkbox>
                                                            <Checkbox
                                                                style={{ fontSize: '20px' }}
                                                                checked={allData?.cars?.carLoanDetailsRe?.checkGua === "ไม่ค้ำ-ไม่โอน" ? true : false}
                                                            >ไม่ค้ำ-ไม่โอน
                                                            </Checkbox>
                                                            <Checkbox
                                                                style={{ fontSize: '20px' }}
                                                                checked={allData?.cars?.carLoanDetailsRe?.checkGua === "ไม่ค้ำ-โอน" ? true : false}
                                                            >ไม่ค้ำ-โอน
                                                            </Checkbox>
                                                        </Row>
                                                        <Divider orientation={'left'}></Divider>
                                                        <aside style={{ width: '100%' }}>
                                                            <b>
                                                                <div style={{ margin: 0, }}>
                                                                    <span>ใบนี้หมดอายุ ณ วันที่ : </span>
                                                                    <b style={{ color: 'red', fontSize: '20px' }}><u>{dayjs(allData?.cars?.carLoanDetailsRe?.newOfferDate).format("DD-MM-YYYY")}</u></b>
                                                                </div>
                                                            </b>
                                                        </aside>
                                                    </>
                                                    : null
                                            }
                                            {
                                                allData?.cars?.carLoanDetailsRe?.approvalStatus === "ไม่อนุมัติ" || allData?.cars?.carLoanDetailsRe?.approvalStatus === "ปฏิเสธคำร้อง" ?
                                                    <>

                                                        <aside style={{ width: '100%' }}>
                                                            <b>
                                                                <div style={{ margin: 0, }}>
                                                                    <span>วันที่นำเคสกลับมารีใหม่ได้ : </span>
                                                                    <b style={{ color: 'red', fontSize: '20px' }}><u>{dayjs(allData?.cars?.carLoanDetailsRe?.newOfferDate).format("DD-MM-YYYY")}</u></b>
                                                                </div>
                                                            </b>
                                                        </aside>
                                                    </>
                                                    : null
                                            }
                                        </Col>
                                    </Row>
                                    <Divider orientation={'left'}></Divider>
                                    {
                                        noteCarRe?.length > 0 ?
                                            <>
                                                <Card>
                                                    <Divider orientation={'left'}>หมายเหตุคำร้อง</Divider>
                                                    <aside style={{ width: '100%' }}>

                                                        {noteCarRe?.map((e, index) => {
                                                            return (
                                                                <>
                                                                    <div label='สาเหตุ' name='cause' style={{ marginBottom: 3 }}>
                                                                        <span>สาเหตุ : </span><b style={{ color: 'red', fontSize: '16px' }}>{e?.cause}</b>
                                                                    </div>
                                                                    <div label='หมายเหตุ' name='note' style={{ marginBottom: 3 }}>
                                                                        <span>หมายเหตุ : </span><b style={{ color: 'red', fontSize: '16px' }}>{e?.note}</b>
                                                                    </div>
                                                                    <div label='สถานะที่ทำหมายเหตุ' name='status' style={{ marginBottom: 3 }}>
                                                                        <span>สถานะที่ทำหมายเหตุ : </span><b style={{ color: 'red', fontSize: '16px' }}>{e?.status}</b>
                                                                    </div>
                                                                    <Divider style={{ margin: 5 }} />
                                                                </>
                                                            )
                                                        })}
                                                    </aside>
                                                </Card>
                                            </>
                                            : null
                                    }

                                    {guarantor1?.length >= 1 ?
                                        <>
                                            {/* {getimages?.length > 0 ?
                                                <> */}
                                            <Row justify={'left'}>
                                                <b><u>รายละเอียดคนค้ำ</u></b>
                                            </Row>
                                            {
                                                guarantor1?.map((item, index) => {
                                                    return renderGuarantorNewImg({ item, index })
                                                })
                                            }
                                            {/* </>
                                                :
                                                <>
                                                    <Row justify={'left'}>
                                                        <b><u>รายละเอียดคนค้ำ</u></b>
                                                    </Row>
                                                    {
                                                        guarantor1?.map((item, index) => {
                                                            return renderGuarantor({ item, index })
                                                        })
                                                    }
                                                </>
                                            } */}
                                        </>
                                        : null
                                    }

                                    <>
                                        <Divider orientation={'left'}><b><u>รูปประกอบการขออนุมัติ</u></b></Divider>
                                        {showImg64?.map((imageData, index) => {
                                            if (imageData?.type === 14) {
                                                return (
                                                    <>
                                                        <Row gutter={32} justify={'center'}>
                                                            <Col span={24} style={{ textAlign: 'center' }}>
                                                                <Image width={'100px'} key={index} src={`${getImagess}/${imageData.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                            </Col>
                                                        </Row>
                                                    </>
                                                );
                                            }
                                            return null;
                                        })}
                                        <Row justify={'left'}><b><u>รูปบัตรประชาชนผู้ทำสัญญา</u></b></Row>
                                        <Row gutter={32} justify={'center'}>
                                            {/* <Space direction="vertical" > */}
                                            {showImg64?.map((imageData, index) => {
                                                if (imageData?.type === 4) {
                                                    return (
                                                        <>
                                                            <Row gutter={32} justify={'center'}>
                                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                                    <Image width={'100px'} key={index} src={`${getImagess}/${imageData.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    );
                                                }
                                                return null;
                                            })}
                                            {/* </Space> */}
                                        </Row>
                                        <Divider />
                                        <Row justify={'left'}><b><u>หน้าคู่มือ</u></b></Row>
                                        <Row gutter={32} justify={'center'}>
                                            {/* <Space direction="vertical" > */}
                                            {showImg64?.map((imageData, index) => {
                                                if (imageData?.type === 2) {
                                                    return (
                                                        <>
                                                            <Row gutter={32} justify={'center'}>
                                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                                    <Image width={'100px'} key={index} src={`${getImagess}/${imageData.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    );
                                                }
                                                return null;
                                            })}
                                            {/* </Space> */}
                                        </Row>
                                        <Divider />
                                        <Row justify={'left'}><b><u>ใบประเมิน / รูปรถ / สัญญาไฟแนนซ์เดิม / ใบเสร็จไฟแนนซ์เดิม</u></b></Row>
                                        <Row gutter={32} justify={'center'}>
                                            {/* <Space direction="vertical" > */}
                                            {showImg64?.map((imageData, index) => {
                                                if (imageData?.type === 1) {
                                                    return (
                                                        <>
                                                            <Row gutter={32} justify={'center'}>
                                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                                    <Image width={'100px'} key={index} src={`${getImagess}/${imageData.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    );
                                                }
                                                return null;
                                            })}
                                            {/* </Space> */}
                                        </Row>
                                        <Divider />
                                        <Row justify={'left'}><b><u>ประวัติผู้กู้ / ทะเบียนบ้าน / อาชีพ</u></b></Row>
                                        <Row gutter={32} justify={'center'}>
                                            {/* <Space direction="vertical" > */}
                                            {showImg64?.map((imageData, index) => {
                                                if (imageData?.type === 6) {
                                                    return (
                                                        <>
                                                            <Row gutter={32} justify={'center'}>
                                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                                    <Image width={'100px'} key={index} src={`${getImagess}/${imageData.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    );
                                                }
                                                return null;
                                            })}
                                            {/* </Space> */}
                                        </Row>
                                        <Divider />
                                        <Row justify={'left'}><b><u>รูปสเตทเมนต์ผู้กู้</u></b></Row>
                                        <Row gutter={32} justify={'center'}>
                                            {/* <Space direction="vertical" > */}
                                            {showImg64?.map((imageData, index) => {
                                                if (imageData?.type === 8) {
                                                    return (
                                                        <>
                                                            <Row gutter={32} justify={'center'}>
                                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                                    <Image width={'100px'} key={index} src={`${getImagess}/${imageData.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    );
                                                }
                                                return null;
                                            })}
                                            {/* </Space> */}
                                        </Row>
                                        <Divider />
                                        <Row justify={'left'}><b><u>รูปสเตทเมนต์ผู้กู้ PDF</u></b></Row>
                                        <Row gutter={32} justify={'center'}>
                                            {/* <Space direction="vertical" > */}
                                            {showImg64?.map((imageData, index) => {
                                                if (imageData.type === 7) {
                                                    return (
                                                        <Row gutter={32} justify={'center'}>
                                                            <Col span={24} style={{ textAlign: 'center' }}>
                                                                {/* <Space direction='vertical'> */}
                                                                <object data={`${getImagess}/${imageData.pathImage}?token=${token}`} type="application/pdf" width="350px" height="450px">
                                                                    <p>Alternative text - include a link <a href={`${getImagess}/${imageData.pathImage}?token=${token}`}>to the PDF!</a></p>
                                                                </object>
                                                                <p></p>
                                                                {/* </Space> */}
                                                            </Col>
                                                        </Row>
                                                    )
                                                }
                                                return null;
                                            })}
                                            {/* </Space> */}
                                        </Row>
                                        <Divider />
                                        <Row justify={'left'}><b><u>เอกสารเพิ่มเติม</u></b></Row>
                                        <Row gutter={32} justify={'center'}>
                                            {/* <Space direction="vertical" > */}
                                            {showImg64?.map((imageData, index) => {
                                                if (imageData?.type === 12) {
                                                    return (
                                                        <>
                                                            <Row gutter={32} justify={'center'}>
                                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                                    <Image width={'100px'} key={index} src={`${getImagess}/${imageData.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    );
                                                }
                                                return null;
                                            })}
                                            {/* </Space> */}
                                        </Row>
                                        <Divider />
                                    </>

                                    <Row justify={'center'} style={{ marginTop: '15px' }}>
                                        <Space>
                                            <Button type="primary" style={{ background: "red" }} onClick={showConfirmCancel}>
                                                ปิด
                                            </Button>
                                        </Space>
                                    </Row>
                                </Card>
                            </Row>
                        </Space>
                    </Form>
                </Spin>
            </Modal>
        </div >
    )
}
