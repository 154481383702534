import React, { useEffect, useState } from 'react'
import { Form, Card, Col, Row, Button, Space, Modal, Spin, Divider, Image, Upload } from 'antd'
import dayjs from 'dayjs'
import { SaveFilled, EditOutlined } from '@ant-design/icons'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios'
import '../../css/Media.css'
import { useDispatch, useSelector } from 'react-redux'
import { addImage } from '../../../redux/Image';
import { addEmail } from '../../../redux/Email';
import { addSocial } from '../../../redux/Social';
import TextArea from 'antd/es/input/TextArea';
import { newallcreate, getImagess, getAllFolderGuaImg } from '../../file_mid/all_api';
//import Compressor from 'compressorjs';

export default function Detail() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  // const { customers, cars, loans, Addresss, Phones, oldloans, imgs, guarantor, careerIncome, occupations, Email } = useSelector((state) => state)
  const customers = useSelector(state => state.customers)
  const cars = useSelector(state => state.cars)
  const loans = useSelector(state => state.loans)
  const Addresss = useSelector(state => state.Addresss)
  const Phones = useSelector(state => state.Phones)
  const oldloans = useSelector(state => state.oldloans)
  const imgs = useSelector(state => state.imgs)
  const guarantor = useSelector(state => state.guarantor)
  const careerIncome = useSelector(state => state.careerIncome)
  const occupations = useSelector(state => state.occupations)
  const Email = useSelector(state => state.Email)
  const Social = useSelector(state => state.Social)

  const addCustomers = localStorage.getItem('addCustomer')
  const addCars = localStorage.getItem('addCar')
  const addLoans = localStorage.getItem('addLoan')
  const addAddresss = localStorage.getItem('addAddress')
  const addPhones = localStorage.getItem('addPhone')
  const addOldLoans = localStorage.getItem('addOldLoan')
  const addGuarantors = localStorage.getItem('addGuarantor')
  const addOccupations = localStorage.getItem('addOccupation')
  const addCareerIncomes = localStorage.getItem('addCareerIncome')
  const addImages = localStorage.getItem('addImage')
  const addBrokers = localStorage.getItem('addBroker')

  const dataAddCustomer = JSON.parse(addCustomers)
  const dataAddCars = JSON.parse(addCars)
  const dataAddLoans = JSON.parse(addLoans)
  const dataAddAddresss = JSON.parse(addAddresss)
  const dataAddPhones = JSON.parse(addPhones)
  const dataAddOldLoans = JSON.parse(addOldLoans)
  const dataAddGuarantors = JSON.parse(addGuarantors)
  const dataAddOccupations = JSON.parse(addOccupations)
  const dataAddCareerIncomes = JSON.parse(addCareerIncomes)
  const dataAddImages = JSON.parse(addImages)
  const dataAddBrokers = JSON.parse(addBrokers)

  // const result1 = { ...customers.data, guarantors: guarantor.data, expenses: occupations.data.expenses, income: occupations.data.income, ojs: occupations.data.ojs, address: [{ ...Addresss.data }], phones: [{ ...Phones.data }], carLoanDetails: [{ ...loans.data, cars: [{ ...cars.data }] }] }
  // const result2 = { ...customers.data, guarantors: guarantor.data, expenses: occupations.data.expenses, income: occupations.data.income, ojs: occupations.data.ojs, address: [{ ...Addresss.data }], phones: [{ ...Phones.data }], carLoanDetails: [{ ...loans.data, cars: [{ ...cars.data, oldLoanDetails: oldloans.data }] }] }
  // const result3 = { ...customers.data, guarantors: guarantor.data, expenses: occupations.data.expenses, income: occupations.data.income, ojs: occupations.data.ojs, phones: [{ ...Phones.data }], carLoanDetails: [{ ...loans.data, cars: [{ ...cars.data }] }] }
  // const result4 = { ...customers.data, guarantors: guarantor.data, expenses: occupations.data.expenses, income: occupations.data.income, ojs: occupations.data.ojs, phones: [{ ...Phones.data }], carLoanDetails: [{ ...loans.data, cars: [{ ...cars.data, oldLoanDetails: oldloans.data }] }] }

  const result1 = { ...dataAddCustomer, guarantors: dataAddGuarantors, expenses: dataAddOccupations?.expenses, income: dataAddOccupations?.income, ojs: dataAddOccupations?.ojs, address: [{ ...dataAddAddresss }], phones: [{ ...dataAddPhones }], carLoanDetails: [{ ...dataAddLoans, cars: [{ ...dataAddCars }] }] }
  const result2 = { ...dataAddCustomer, guarantors: dataAddGuarantors, expenses: dataAddOccupations?.expenses, income: dataAddOccupations?.income, ojs: dataAddOccupations?.ojs, address: [{ ...dataAddAddresss }], phones: [{ ...dataAddPhones }], carLoanDetails: [{ ...dataAddLoans, cars: [{ ...dataAddCars, oldLoanDetails: dataAddOldLoans }] }] }
  const result3 = { ...dataAddCustomer, guarantors: dataAddGuarantors, expenses: dataAddOccupations?.expenses, income: dataAddOccupations?.income, ojs: dataAddOccupations?.ojs, phones: [{ ...dataAddPhones }], carLoanDetails: [{ ...dataAddLoans, cars: [{ ...dataAddCars }] }] }
  const result4 = { ...dataAddCustomer, guarantors: dataAddGuarantors, expenses: dataAddOccupations?.expenses, income: dataAddOccupations?.income, ojs: dataAddOccupations?.ojs, phones: [{ ...dataAddPhones }], carLoanDetails: [{ ...dataAddLoans, cars: [{ ...dataAddCars, oldLoanDetails: dataAddOldLoans }] }] }
  // const show = { ...customers?.data, ...cars?.data, ...loans?.data, ...Addresss?.data, ...Phones?.data, ...oldloans?.data, ...guarantor?.data, ...occupations?.data }

  const show = { ...dataAddCustomer, ...dataAddCars, ...dataAddLoans, ...dataAddAddresss, ...dataAddPhones, ...dataAddOldLoans, ...dataAddGuarantors, ...dataAddOccupations }
  const [form] = Form.useForm()
  const [resultNO, setResultNO] = useState({}) // ข้อมูลที่จะส่งไปหลังบ้าน ไม่มีโอโลน
  const [resultO, setResultO] = useState({}) // ข้อมูลที่จะส่งไปหลังบ้าน มีโอโลน
  const [easy, setEasy] = useState({}) // โชว์ในหน้าเว็บ
  const [OJS, setOjs] = useState(dataAddCareerIncomes) // โชว์ในหน้าเว็บ
  const [fileList, setFileList] = useState([]) // โชว์รูป
  const [guarantors1, setGuarantors1] = useState([]) // โชว์คนค้ำ
  const [guarantors, setGuarantors] = useState(dataAddGuarantors) // โชว์คนค้ำ
  const [image, setImage] = useState(imgs?.data);
  const [imagesomlong, setImageSomlong] = useState(Email?.data);

  const [dataPost, setDataPost] = useState({ offercaseNote: "" })
  const token = localStorage.getItem('token');
  const menu = localStorage.getItem('menu')
  const [getimages, setGetImages] = useState([]);
  //const [fileListCustomer, setFileListCustomer] = useState([]);

  // console.log("dataAddOldLoans", dataAddOldLoans)
  // console.log("dataAddAddresss", dataAddAddresss)
  // console.log("dataAddBrokers", dataAddBrokers)
  useEffect(() => {
    if (Email?.data) {
      setImageSomlong(Email?.data)
    }
    // if (Social?.data) {
    //   setFileListss(Social?.data)
    // }
    // if (imgs?.data) {
    //   var imageGuarantors = [...image];
    //   guarantors?.map((img, index) => {
    //     console.log("img", img)
    //     console.log("img11", img.imgs)
    //     imageGuarantors.push({ ...img.imgs })
    //     setImage(imageGuarantors)
    //   })
    //   dispatch(addImage(imageGuarantors))
    //   console.log("imageGuarantors", imageGuarantors)
    // }
    setEasy(show)
    if (dataAddGuarantors) {
      getImg()
      setGuarantors1(dataAddGuarantors)
    }

    if (dataAddGuarantors === 0 || dataAddGuarantors === '') {
      delete result1.guarantors
      delete result2.guarantors
      delete result3.guarantors
      delete result4.guarantors
    }

    // if (imgs?.data) {
    //   //resultImg()
    //   setFileList(imgs?.data)
    // }
    // if (imgs?.data && Social?.data) {
    //   //resultImg()
    //   setFileList([...imgs?.data, ...Social?.data])
    // }
    if (dataAddImages) {
      setFileList(dataAddImages)
    }

    if (dataAddAddresss !== undefined || dataAddAddresss !== null) {
      setResultNO(result1)
      setResultO(result2)
    }
    else {
      setResultNO(result3)
      setResultO(result4)
    }
  }, [])


  const getImg = async () => {
    const path = `one_images/car/${dataAddCustomer?.preImg}/5`
    // console.log("path-customer", path)
    setLoading(true)
    await axios.get(`${getAllFolderGuaImg}/${path}?token=${token}`)
      .then(res => {
        if (res.data) {
          // console.log("res.data", res.data)
          setGetImages(res.data)
          setLoading(false)
        }
      })
      .catch((err) => {
        console.log("err", err)
        setLoading(false)
      })
    setLoading(false)
  }

  // มีจุดทศนิยม
  const currencyFormatOne = (amount) => {
    return Number(amount)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
  }

  const success = () => {
    Modal.success({
      content: 'บันทึกสำเร็จ',
      path: navigate('/checkInfoCar')
    })
  }
  const error = () => {
    Modal.error({
      title: 'บันทึกไม่สำเร็จ',
      content: 'กรุณาลองใหม่อีกครั้ง',
    })
  }
  const unauthorized = () => {
    Modal.error({
      title: 'unauthorized',
      content: 'คุณไม่มีสิทธิ์ใช้งานเมนูนี้',
    })
  }
  const handleGoBlack = () => {
    navigate('/offercase/addCareerincome')
    //dispatch(addImage(imagesomlong))
  }

  const backInsertcustomer = (e) => {
    Modal.success({
      title: 'ข้อมูลลูกค้าไม่ครบถ้วน',
      // content: `กรุณาตรวจกรอกข้อมูล`,
      onOk: () => {
        navigate('/offercase/addcustomer')
        setLoading(false)
      }
    })
  }
  const backInsertloan = (e) => {
    Modal.success({
      title: 'ข้อมูลขอกู้ไม่ครบถ้วน',
      // content: `กรุณาตรวจกรอกข้อมูล`,
      onOk: () => {
        navigate('/offercase/addDetail')
        setLoading(false)
      }
    })
  }
  const backInsertcar = (e) => {
    Modal.success({
      title: 'ข้อมูลรถไม่ครบถ้วน',
      // content: `กรุณาตรวจกรอกข้อมูล`,
      onOk: () => {
        navigate('/offercase/addDetail')
        setLoading(false)
      }
    })
  }

  const handleSubmit = async (e) => {
    setLoading(true)
    if (dataAddCustomer?.identificationId === "" || dataAddCustomer?.firstname === "" || dataAddCustomer?.lastname === "") {
      backInsertcustomer()
    }
    else if (dataAddLoans?.productLoanType === "" || dataAddLoans?.productType === "" || dataAddLoans?.loanAmountt === "" || dataAddLoans?.loanAmountt === 0) {
      backInsertloan()
    }
    else if (dataAddCars?.carBrand === "" || dataAddCars?.carModel === "" || dataAddCars?.carBaab === "") {
      backInsertcar()
    }
    else if ((dataAddLoans?.productLoanType === "ย้ายไฟแนนซ์" || dataAddLoans?.productLoanType === "Pre-Aaprove-ย้ายไฟแนนซ์") && (dataAddOldLoans?.issuno === "" || dataAddOldLoans?.oldLoanAmount === "")) {
      backInsertloan()
    }
    else {
      dispatch(addEmail())
      dispatch(addSocial())
      if (Object.keys(dataAddAddresss).length === 0) {
        delete resultNO.address
        delete resultO.address
        // delete resultNO.imgs
        // delete resultO.imgs
      }
      if (JSON.stringify(dataAddAddresss) === '{}' || JSON.stringify(resultNO.address[0]) === '{}' || JSON.stringify(resultO.address[0]) === '{}') {
        delete resultNO.address
        delete resultO.address
        // delete resultNO.imgs
        // delete resultO.imgs
      }
      // setTimeout(async () => {
      if (dataAddOldLoans) {
        console.log("รถ ข้อมูลทั้งหมด มีโอโลน")
        const resultOnew = { ...resultO, offercaseNote: dataPost?.offercaseNote.trim(), dataAddBroker: dataAddBrokers }
        // console.log("ข้อมูลทั้งหมด มีโอโลน",resultOnew)
        await axios.post(newallcreate, resultOnew) /*new upload*/
          .then(res => {
            if (res.status === 200) {
              success()
              setLoading(false)
              localStorage.removeItem('addCustomer');
              localStorage.removeItem('addAddress');
              localStorage.removeItem('addPhone');
              localStorage.removeItem('addCar');
              localStorage.removeItem('addGuarantor');
              localStorage.removeItem('addCareerIncome');
              localStorage.removeItem('addLoan');
              localStorage.removeItem('addOldLoan');
              localStorage.removeItem('addOccupation');
              localStorage.removeItem('addEmail');
              localStorage.removeItem('addSocial');
              localStorage.removeItem('addImage');
              localStorage.removeItem('addBroker');
              navigate('/checkInfoCar')
            }
          })
          .catch((err) => {
            if (err.response.request.status === 401) {
              unauthorized()
            }
            else {
              error()
            }
            console.log("err", err)
            setLoading(false)
          })
      }
      else {
        // console.log("รถ ข้อมูลทั้งหมด ไม่มีโอโลน")
        console.log("ข้อมูลทั้งหมด ไม่มีโอโลน")
        const resultNonew = { ...resultNO, offercaseNote: dataPost?.offercaseNote.trim(), dataAddBroker: dataAddBrokers }
        await axios.post(newallcreate, resultNonew) /*new upload*/
          .then(res => {
            if (res.status === 200) {
              success()
              setLoading(false)
              localStorage.removeItem('addCustomer');
              localStorage.removeItem('addAddress');
              localStorage.removeItem('addPhone');
              localStorage.removeItem('addCar');
              localStorage.removeItem('addGuarantor');
              localStorage.removeItem('addCareerIncome');
              localStorage.removeItem('addLoan');
              localStorage.removeItem('addOldLoan');
              localStorage.removeItem('addOccupation');
              localStorage.removeItem('addEmail');
              localStorage.removeItem('addSocial');
              localStorage.removeItem('addImage');
              localStorage.removeItem('addBroker');
              navigate('/checkInfoCar')
            }
          })
          .catch((err) => {
            if (err.response.request.status === 401) {
              unauthorized()
            }
            else {
              error()
            }
            console.log("err", err)
            setLoading(false)
          })
      }
    }
    // }, 2000)
    setLoading(false)
  }

  //// รูปภาพ
  async function convertFileToBlob(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const blob = new Blob([reader.result], { type: file.type });
        resolve(blob);
      };
      reader.onerror = reject;

      reader.readAsArrayBuffer(file.originFileObj);
    });
  }

  //////////// แจกงาน ///////////////
  // const handleCase = async (value) => {
  //   setLoading(true)
  //   const vid = {loanId: value.loanId}
  //   //console.log("v",vid)
  //   await axios.post('http://localhost:8000/ApiCase/addcase', vid)
  //   .then(res => {
  //     if (res.status === 200) {
  //       console.log("success")
  //       setLoading(false)
  //     }
  //     else {
  //       console.log("error")
  //       setLoading(false)
  //     }
  //   })
  //   .catch((err) => console.log(err))
  //   setLoading(false)
  // }
  //   const getBase64 = (file) => {
  //     return new Promise((resolve, reject) => {
  //         const reader = new FileReader();
  //         reader.readAsDataURL(file);
  //         reader.onload = () => resolve(reader.result);
  //         reader.onerror = (error) => reject(error);
  //     });
  // };
  //////////// โชว์รูปภาพ ////////////////
  function createImageUrl(imageData) {
    //console.log(imageData)
    // const blob = new Blob([imageData.originFileObj], { type: imageData.type });
    // return URL.createObjectURL(blob);
    return imageData.image64;
    // , fileList.pdf;
  }
  //////////// โชว์ชื่อไฟล์ PDF ////////////////
  function createPDFUrl(imageData) {
    return imageData.pdf;
  }
  const renderGuarantor = ({ item, index, key }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
    // console.log("AA", item, index)
    var itemSplit = []
    if (item?.pathImg) {
      itemSplit = item.pathImg.split("/")
    }
    // delete item.imgs
    return (
      <>
        <Form
          key={key}
          name='basic'
          labelCol={{
            span: 12,
          }}
          wrapperCol={{
            span: 24,
          }}
          style={{
            maxWidth: '100%',
          }}
          autoComplete="off"
          initialValues={{ remember: true }}
        >
          <Card style={{ width: '300px' }}>
            <b><u>
              <div style={{ marginBottom: 0, textAlign: 'left' }}
              >
                <span>ลำดับที่ : </span>
                {/* {item.garno} */}
                {index + 1}
              </div>
            </u></b>
            <div style={{ marginBottom: 0, textAlign: 'left' }}
            >
              <span>ชื่อ-นามสกุล : </span>
              <b>{item.snam + ' ' + item.firstname + ' ' + item.lastname}</b>
            </div>
            <p />
            <n></n>
            {/* <div
              style={{ marginBottom: 0, textAlign: 'left' }}
            >
              <span>วัน-เดือน-ปีเกิด : </span>
              <b>{item.birthdate}</b>
            </div> */}

            {/* <div
              style={{ marginBottom: 0, textAlign: 'left' }}
            >
              <span>เลขบัตรประชาชน : </span>
              <b>{item.identificationId}</b>
            </div> */}
            <div
              style={{ marginBottom: 0, textAlign: 'left' }}
            >
              <span>ชื่อเล่น : </span>
              <b>{item.nickname}</b>
            </div>

            <div
              style={{ marginBottom: 0, textAlign: 'left' }}
            >
              <span>เบอร์โทร : </span>
              {item?.phones?.length > 0 ?
                <>
                  <b>{item?.phones[0]?.telp}</b>
                </>
                :
                <>
                  <b>{item?.phones?.telp}</b>
                </>
              }
            </div>
            {/* <div
              style={{ marginBottom: 0, textAlign: 'left' }}
            >
              <span>ความสัมพันธ์ : </span>
              <b>{item.description}</b>
            </div> */}
            {itemSplit?.length > 0 ? (
              <>
                <Row justify={"center"}>
                  {getimages?.map((items, index) => {
                    const segments = items.pathImage.split("/")
                    if (itemSplit[4] === segments[4]) {
                      return (
                        <Image width={'100px'} key={index} src={`data:${items?.Attr};base64,${items?.data?.toString('base64')}`} alt={`Image ${index + 1}`} />
                      )
                    }
                  }
                  )}
                </Row>
              </>
            ) : null
            }

          </Card>
        </Form>
      </>
    )
  }

  return (
    <Row justify={'center'} >
      <Card style={{ width: '1100px' }}>
        <Spin spinning={loading} size='large' tip=" Loading... ">
          <div className='text-center'>
            <h2>รายละเอียด</h2>
          </div>
          <Divider />
          <Row justify={'center'}>
            <Form
              labelCol={{
                span: 12,
              }}
              wrapperCol={{
                span: 24,
              }}
              style={{
                width: '100%',
              }}

              form={form}
              onFinish={handleSubmit}
              autoComplete="off"
              initialValues={{ remember: true }}
            >
              <Row justify={'center'}>
                <Card style={{ width: '100%' }}>
                  <Row className='main' justify={'center'}>
                    <Col span={24}>
                      <div>
                        <aside style={{ width: '100%' }}>
                          {easy?.nationalId === "นิติบุคคล" ?
                            <>
                              <div className='text-left' style={{ margin: 0 }}>
                                <h4><u>รายละเอียดผู้กู้</u></h4>
                              </div><p />
                              <n />
                              <div label='ประเภท' name='identificationId' style={{ marginBottom: 3 }}>
                                <span>ประเภท : </span> <b>{easy?.nationalId}</b>
                              </div>
                              <div label='เลขประจำตัวผู้เสียภาษี' name='identificationId' style={{ marginBottom: 3 }}>
                                <span>เลขประจำตัวผู้เสียภาษี : </span> <b>{easy.identificationId}</b>
                              </div>
                              <div label='ชื่อบริษัท' name='snam' style={{ marginBottom: 3 }}>
                                <span>
                                  {easy?.snam} : </span> <b>{easy.firstname + ' ' + easy?.lastname}</b>
                              </div>
                              <div label='วันจดทะเบียน' name='nickname' style={{ marginBottom: 3 }}>
                                <span>วันจดทะเบียน : </span> <b> {easy?.birthdate}</b>
                              </div>
                            </>
                            :
                            <>
                              <div className='text-left' style={{ margin: 0 }}>
                                <h4><u>รายละเอียดผู้กู้</u></h4>
                              </div><p />
                              <n />
                              <div label='เลขประจำตัวประชาชน' name='identificationId' style={{ marginBottom: 3 }}>
                                <span>เลขประจำตัวประชาชน : </span> <b>{easy.identificationId}</b>
                              </div>
                              <div label='ชื่อ - นามสกุล' name='snam' style={{ marginBottom: 3 }}>
                                <span>ชื่อ - นามสกุล : </span> <b>{easy.snam + ' ' + easy.firstname + ' ' + easy.lastname}</b>
                              </div>
                              <div label='ชื่อเล่น' name='nickname' style={{ marginBottom: 3 }}>
                                <span>ชื่อเล่น : </span> <b> {easy.nickname}</b>
                              </div>
                              <div label='เพศ' name='gender' style={{ marginBottom: 3 }}>
                                <span>เพศ : </span> <b>{
                                  easy.gender === "M" && ("ชาย")
                                }
                                  {
                                    easy.gender === "F" && ("หญิง")
                                  }</b>
                              </div>
                              <div label='เกิดวันที่' name='birthdate' style={{ marginBottom: 3 }}>
                                <span>เกิดวันที่ : </span><b>{dayjs(easy.birthdate).format("DD-MM-YYYY")}</b>
                              </div>
                              <div label='เบอร์โทรติดต่อ' name='telp' style={{ marginBottom: 3 }}>
                                <span>เบอร์โทรติดต่อ : </span><b> {easy.telp}</b>
                              </div>
                              <div label='สถานที่ออกบัตรประชาชน' name='issuby' style={{ marginBottom: 3 }}>
                                <span>สถานที่ออกบัตรประชาชน : </span><b>{easy.issuby}</b>
                              </div>
                              <div label='วันออกบัตร' name='issudt' style={{ marginBottom: 3 }}>
                                <span>วันออกบัตร : </span><b>{dayjs(easy.issudt).format("DD-MM-YYYY")}</b>
                              </div>
                              <div label='วันบัตรหมดอายุ' name='expdt' style={{ marginBottom: 3 }}>
                                <span>วันบัตรหมดอายุ : </span><b>{dayjs(easy.expdt).format("DD-MM-YYYY")}</b>
                              </div>

                            </>
                          }
                        </aside>
                        <Divider style={{ margin: 5 }} />
                        <aside style={{ width: '100%' }}>
                          <div className='text-left'>
                            <h4><u>ประเภทสินค้า</u></h4>
                          </div><p />
                          <n />
                          <div label='กลุ่มสินค้า' name='productTypeId' style={{ marginBottom: 3 }}>
                            <span>กลุ่มสินค้า : </span><b>{
                              easy.productTypeId === 1 && ("รถ")
                            }
                              {
                                easy.productTypeId === 2 && ("ที่ดิน")
                              }</b>
                          </div>
                          <div label='ชนิดรถ' name='productTypeCar' style={{ marginBottom: 3 }}>
                            <span>ชนิดรถ : </span><b>{
                              easy.productTypeCar === 1 && ("รถยนต์")
                            }
                              {
                                easy.productTypeCar === 2 && ("รถเครื่องจักการเกษตร")
                              }
                              {
                                easy.productTypeCar === 3 && ("รถบรรทุก")
                              }</b>
                          </div>
                          <div label='ยี่ห้อ' name='carBrand' style={{ marginBottom: 3 }}>
                            <span>ยี่ห้อ : </span><b>{easy.carBrand}</b>
                          </div>
                          <div label='รุ่นรถ' name='carModel' style={{ marginBottom: 3 }}>
                            <span>รุ่นรถ : </span><b>{easy.carModel}</b>
                          </div>
                          <div label='แบบรถ' name='carBaab' style={{ marginBottom: 3 }}>
                            <span>แบบรถ : </span><b>{easy.carBaab}</b>
                          </div>
                          <div label='ปีรถ' name='carYear' style={{ marginBottom: 3 }}>
                            <span>ปีรถ : </span><b>{easy.carYear}</b>
                          </div>
                          <div label='เลขทะเบียน' name='carPlateNumber' style={{ marginBottom: 3 }}>
                            <span>เลขทะเบียน : </span><b>{easy.carPlateNumber}</b>
                          </div>
                          <div label='จังหวัด' name='carPlateNumber' style={{ marginBottom: 3 }}>
                            <span>จังหวัด : </span><b>{easy.carProvince}</b>
                          </div>
                          <div label='ราคารถ' name='carPrice' style={{ marginBottom: 3 }}>
                            <span>ราคารถ : </span><b>{currencyFormatOne(easy.carPrice)}</b> บาท
                          </div>
                        </aside>
                        <Divider style={{ margin: 5 }} />
                        <aside style={{ width: '100%' }}>
                          <div className='text-left' >
                            <h4><u>รายละเอียดยอดกู้</u></h4>
                          </div><p />
                          <n />
                          {/* <div label='ประเภทขอกู้' name='productLoanType' style={{ marginBottom: 3 }}>
                <span>ประเภทขอกู้ : </span><b>{easy.productLoanType}</b>
              </div> */}
                          <div label='ประเภทค่างวด' name='productType' style={{ marginBottom: 3 }}>
                            <span>ประเภทค่างวด : </span><b>{easy.productType}</b>
                          </div>
                          <div label='ยอดจัด' name='loanAmountt' style={{ marginBottom: 3 }}>
                            <span>ยอดจัด : </span><b>{isNaN(easy.loanAmountt) ? "-" : currencyFormatOne(easy.loanAmountt) + " บาท"}</b>
                          </div>
                          <div label='จำนวนงวด' name='installmentAmountt' style={{ marginBottom: 3 }}>
                            <span>จำนวนงวด : </span><b>{easy.installmentAmountt}</b> งวด
                          </div>
                          <div label='ดอกเบี้ย' name='interestt' style={{ marginBottom: 3 }}>
                            <span>ดอกเบี้ย : </span><b>{isNaN(easy.interestt) ? "-" : currencyFormatOne(easy.interestt) + " บาท"}</b>
                          </div>
                          <div label='ปีรถ' name='carYear' style={{ marginBottom: 3 }}>
                            <span>ปีรถ : </span><b>{easy.carYear}</b>
                          </div>
                          <div label='งวดละ' name='monthlyPayment' style={{ marginBottom: 3 }}>
                            <span>งวดละ : </span><b>{isNaN(easy.monthlyPayment) ? "-" : currencyFormatOne(easy.monthlyPayment) + " บาท"}</b>
                          </div>
                          <div label='ราคารวม' name='installmentWithInterestt' style={{ marginBottom: 3 }}>
                            <span>ราคารวม : </span><b>{isNaN(easy.installmentWithInterestt) ? "-" : currencyFormatOne(easy.installmentWithInterestt) + " บาท"}</b>
                          </div>
                        </aside>
                        <Divider style={{ margin: 5 }} />
                        {(easy && easy.productLoanType === 'ย้ายไฟแนนซ์') || (easy && easy.productLoanType === 'Pre-Aaprove-ย้ายไฟแนนซ์') ? (
                          <>
                            <aside style={{ width: '100%' }}>
                              <div className='text-left' >
                                <h4><u>รายละเอียดย้ายไฟแนนซ์เดิม</u></h4>
                              </div>
                              <n />
                              <div label='ปิดไฟแนนซ์จาก' name='issuno' style={{ marginBottom: 3 }}>
                                <span>ปิดไฟแนนซ์จาก : </span><b>{easy.issuno}</b>
                              </div>
                              <div label='ยอดปิดไฟแนนซ์' name='oldLoanAmount' style={{ marginBottom: 3 }}>
                                <span>ยอดปิดไฟแนนซ์ : </span><b>{isNaN(easy.oldLoanAmount) ? "-" : currencyFormatOne(easy.oldLoanAmount) + " บาท"}</b>
                              </div>

                              <div label='ค่างวด/เดือน' name='oldMonthlyPayment' style={{ marginBottom: 3 }}>
                                <span>ค่างวด/เดือน : </span><b>{isNaN(easy.oldMonthlyPayment) ? "-" : currencyFormatOne(easy.oldMonthlyPayment) + " บาท"}</b>
                              </div>

                              <div label='จำนวนผ่อนกี่งวด' name='oldLoanTerm' style={{ marginBottom: 3 }}>
                                <span>จำนวนผ่อนกี่งวด : </span> <b>{isNaN(easy.oldLoanTerm) ? "-" : easy.oldLoanTerm + " งวด"}</b>
                              </div>


                            </aside>
                            <Divider style={{ margin: 5 }} />

                          </>
                        ) : null}
                        {dataAddOccupations ?
                          <>
                            <aside style={{ width: '100%' }}>
                              <div className='text-left' >
                                <h4><u>อาชีพ - รายได้</u></h4>
                              </div><p />
                              <n />
                              <div className='text-left'>
                                {/* <span>ยอดปิดไฟแนนซ์ : </span><b>{isNaN(easy.oldLoanAmount) ? "-" : currencyFormatOne(easy.oldLoanAmount) + " บาท"}</b> */}
                                <span>กลุ่มอาชีพ : </span><b>{OJS?.occupationalGroupTH}</b>
                              </div>
                              <div className='text-left'>
                                <span>อาชีพ : </span><b>{OJS?.governmentPrivateTH}</b>
                              </div>
                              <div className='text-left'>
                                <span>สาขาอาชีพ : </span><b>{OJS?.jobPositionTH}</b>
                              </div>
                              <div className='text-left'>
                                <span>ชื่อบริษัท(สถานที่ทำงาน) : </span><b>{OJS?.companyName}</b>
                              </div>
                              <div className='text-left'>
                                <span>รายได้ / เดือน : </span><b>{currencyFormatOne(OJS?.income?.incomeMonth)}</b>
                              </div>
                              <div className='text-left'>
                                <span>รายจ่าย / เดือน : </span><b>{currencyFormatOne(OJS?.income?.expenses?.expensesMonth)}</b>
                              </div>
                            </aside>
                          </>
                          :
                          null
                        }
                        <Divider />
                        {dataAddGuarantors?.length > 0 ?
                          <>
                            <Card>
                              <Row justify={'center'}>
                                <b><u>รายละเอียดคนค้ำ</u></b>
                              </Row>
                              <Row justify={'center'}>
                                {
                                  guarantors1?.map((item, index) => {
                                    return renderGuarantor({ item, index, key: `{item.identificationId} - ${index}` })
                                  })
                                }
                              </Row>
                            </Card>
                            <Divider style={{ margin: 5 }} />
                          </>
                          : null
                        }

                        <Row gutter={24}>
                          <Col span={23}>

                            {fileList?.map((imageData, index) => (
                              <>
                                {/* <Row> */}
                                <div>
                                  {fileList[index].type === "4" ?
                                    <>
                                      <Row justify={'left'} ><span><b>รูปบัตรประชาชนผู้ขอกู้ :</b></span></Row>
                                      <Row justify={'center'}>
                                        {/* <img width={'300px'} key={index} style={{ margin: 5 }} src={createImageUrl(imageData)} alt={imageData.name} /> */}
                                        <Image width={'300px'} key={index} src={`${getImagess}/${imageData.image}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                      </Row>
                                      <Divider style={{ margin: 5 }} />
                                    </>
                                    : null
                                  }
                                </div>
                                <div>
                                  {fileList[index].type === 5 ?
                                    <>
                                      <Row style={{ marginLeft: 5 }}><span><b>รูปบัตรประชาชนคนค้ำประกัน :</b></span></Row>
                                      <Row justify={'center'}>
                                        <img width={'200px'} style={{ margin: 5 }} src={createImageUrl(imageData)} alt={imageData.name} />
                                      </Row>
                                      <Divider style={{ margin: 5 }} />
                                    </>
                                    : null
                                  }
                                </div>
                                {/* </Row> */}
                                {/* <Row justify={'center'}> */}
                                <div>
                                  {fileList[index].type === "6" ?
                                    <>
                                      <Row justify={'left'}><span><b>ประวัติผู้กู้ / ทะเบียนบ้าน / อาชีพ :</b></span></Row>
                                      <Row justify={'center'}>
                                        {/* <img width={'200px'} key={index} src={createImageUrl(imageData)} alt={imageData.name} /> */}
                                        <Image width={'300px'} key={index} src={`${getImagess}/${imageData.image}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                      </Row>
                                      <Divider style={{ margin: 5 }} />
                                    </>
                                    : null
                                  }
                                </div>
                                <div>
                                  {dataAddCars ?
                                    <>
                                      {fileList[index].type === "1" ?
                                        <>
                                          <Row justify={'left'}><span><b>ใบประเมิน / รูปรถ / สัญญาไฟแนนซ์เดิม / ใบเสร็จไฟแนนซ์เดิม :</b></span></Row>
                                          <Row justify={'center'}>
                                            {/* <img width={'300px'} key={index} style={{ margin: 5 }} src={createImageUrl(imageData)} alt={imageData.name} /> */}
                                            <Image width={'300px'} key={index} src={`${getImagess}/${imageData.image}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                          </Row>
                                          <Divider style={{ margin: 5 }} />
                                        </>
                                        : null
                                      }
                                      {fileList[index].type === "2" ?
                                        <>
                                          <Row justify={'left'}><span><b>หน้าคู่มือ :</b></span></Row>
                                          <Row justify={'center'}>
                                            {/* <img width={'200px'} key={index} src={createImageUrl(imageData)} alt={imageData.name} /> */}
                                            <Image width={'300px'} key={index} src={`${getImagess}/${imageData.image}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                          </Row>
                                          <Divider style={{ margin: 5 }} />
                                        </>
                                        : null
                                      }
                                    </>
                                    :
                                    null
                                  }
                                </div>
                                {/* </Row> */}
                                {/* <Row justify={'center'}> */}
                                <div>
                                  {fileList[index].type === "8" ?
                                    <>
                                      <Row justify={'left'} style={{ marginLeft: 5 }}><span><b>รูปสเตทเมนต์ผู้กู้ :</b></span></Row>
                                      <Row justify={'center'}>
                                        {/* <img width={'200px'} key={index} style={{ marginLeft: 5 }} src={createImageUrl(imageData)} alt={imageData.name} /> */}
                                        <Image width={'300px'} key={index} src={`${getImagess}/${imageData.image}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                      </Row>
                                      <Divider style={{ margin: 5 }} />

                                    </>
                                    : null
                                  }
                                </div>
                                <div>
                                  {fileList[index].type === "7" ?
                                    <>
                                      <Row justify={'left'} style={{ marginLeft: 5 }}><span><b>รูปสเตทเมนต์ผู้กู้ PDF :</b></span></Row>
                                      <Row justify={'center'}>
                                        {/* <img width={'200px'} key={index} style={{ marginLeft: 5 }} src={createPDFUrl(imageData.image64)} alt={imageData.filename} >
                                        </img> */}
                                        <object data={`${getImagess}/${imageData.image}?token=${token}`} type="application/pdf" width="350px" height="450px">
                                          <p>Alternative text - include a link <a href={`${getImagess}/${imageData.image}?token=${token}`}>to the PDF!</a></p>
                                        </object>
                                        <p></p>
                                      </Row>
                                      <Divider style={{ margin: 5 }} />

                                    </>
                                    : null
                                  }
                                </div>

                                {/* </Row> */}
                                {/* <Row className='main3' justify={'center'}> */}

                                <div>
                                  {fileList[index].type === "9" ?
                                    <>
                                      <Row justify={'left'}><span><b>ประวัติคนค้ำ / ทะเบียนบ้าน / อาชีพ :</b></span></Row>
                                      <Row justify={'center'}>
                                        {/* <img width={'200px'} key={index} src={createImageUrl(imageData)} alt={imageData.name} /> */}
                                        <Image width={'300px'} key={index} src={`${getImagess}/${imageData.image}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                      </Row>
                                      <Divider />

                                    </>
                                    : null
                                  }
                                </div>
                                {/* </Row> */}
                              </>
                            ))}
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  <Row justify={'center'}>
                    <Col span={22}>
                      <Form.Item name="offercaseNote" label="">

                        <span><b style={{ fontSize: '25' }}><u>หมายเหตุ</u></b></span> <p />
                        <span><u style={{ fontSize: '20', color: 'red' }}>กรณีที่เอกสารส่วนไหนไม่ครบหรือไม่มี ให้พิมพ์หมายเหตุตรงนี้</u></span>
                        <TextArea
                          name='offercaseNote'
                          style={{ color: 'blue' }}
                          rows={8}
                          onChange={(e) => setDataPost({ offercaseNote: e.target.value })}
                        >
                        </TextArea>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider />
                  <Row justify={'center'}>
                    <Space>
                      <Button type="text" onClick={handleGoBlack} style={{ background: '#bfbfbf', color: '#000000', }} >
                        ย้อนกลับ
                      </Button>
                      {

                        loading === false ?
                          <>
                            <Button type="text" htmlType="submit" style={{ background: '#389e0d', color: '#ffffff' }} >
                              บันทึก
                            </Button>
                          </> :
                          <>
                            <Button type='primary' htmlType="submit" disabled
                              style={{ color: 'success' }}
                            >
                              บันทึก
                            </Button>
                          </>
                      }
                    </Space>
                  </Row>
                </Card>
              </Row>
            </Form>
          </Row>
        </Spin >
      </Card >
    </Row >

  )
};
