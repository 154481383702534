import React, { useState, useEffect } from 'react'
import { Button, Form, Input, Divider, Row, Col, Modal, Radio, message, Select } from 'antd';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { updatesocial1 } from '../../file_mid/all_api';


function EditSocial({ open, close, socialedit, editsocialdata, dataindex }) {
    //dispatch
    const dispatch = useDispatch();
    //useForm
    const [formeditsocial] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    //state
    const [editsocial, setEditSocial] = useState({ ...socialedit.socialdataedit });
    const [editindex, setEditIndex] = useState(dataindex);

    //useEffect เพื่อ refresh data
    useEffect(() => {
        setEditSocial({ ...socialedit.socialdataedit })
        setEditIndex(dataindex)
        formeditsocial.setFieldsValue(
            {
                ...editsocial
            }
        )
    }, [socialedit]);

    //elements
    const handleCancel = () => { //กดปิด Modal
        close(false);
    };
    //handleSubmit
    const handleSubmit = async () => {
        await axios.put(updatesocial1, editsocial)
            .then((res) => {
                if (res.status === 200) {
                    messageApi.open({
                        type: 'success',
                        content: 'แก้ไขสำเร็จ',
                    });
                } else {
                    messageApi.open({
                        type: 'error',
                        content: 'แก้ไขไม่สำเร็จ',
                    });
                }
            })
            .catch((err) => {
                if (err.response.request.status === 400) {
                    messageApi.open({
                        type: 'error',
                        content: 'บันทึกไม่สำเร็จ',
                    });
                }
                console.log("err", err)
            })
        editsocialdata({ editsocial, editindex }) // ส่งข้อมูลไปหน้า Address
        close(false);
    }

    return (
        <>
            {/* Modal แก้ไข้ */}
            <Modal
                open={open}
                onCancel={handleCancel}
                style={{ background: '#2f54eb' }}
                centered
                width={600}
                footer={
                    [
                        <Row
                            justify={'center'}
                        >
                            <Button key="back"
                                onClick={handleCancel}
                            >
                                ยกเลิก
                            </Button>
                            <Button
                                type="primary"
                                onClick={handleSubmit}
                            >
                                บันทึก
                            </Button>
                        </Row>
                    ]}
            >
                <Form
                    form={formeditsocial}
                    name='editsocial'
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    style={{
                        maxWidth: '100%',
                    }}
                >
                    <Row
                        justify={'center'}
                    >
                        <h3 style={{ marginBottom: 0 }}>
                            <u>แก้ไข Socials</u>
                        </h3>
                    </Row>

                    <Row
                        gutter={{ sx: 8, sm: 16, md: 24, lg: 32 }}
                    >
                        <Col className='gutter-row' span={24}>
                            <Form.Item label='social' name='socialType' rules={[
                                {
                                    required: true,
                                    message: 'Please input !',
                                },]}>
                                <Select
                                    placeholder={'เลือก'}
                                    defaultValue={editsocial.socialType}
                                    onChange={(value) => setEditSocial({ ...editsocial, socialType: value })}
                                    options={[
                                        {
                                            label: 'Facebook',
                                            value: 'Facebook',
                                        },
                                        {
                                            label: 'Instragram',
                                            value: 'Instragram',
                                        },
                                        {
                                            label: 'Twitter',
                                            value: 'Twitter',
                                        },
                                        {
                                            label: 'Line',
                                            value: 'Line',
                                        }
                                    ]}
                                />
                            </Form.Item>
                            <Form.Item label='ชื่อ' name='socialData' rules={[
                                {
                                    required: true,
                                    message: 'Please input !',
                                },]}>
                                <Input
                                    defaultValue={editsocial.socialData}
                                    onChange={(e) => setEditSocial({ ...editsocial, socialData: e.target.value })} />
                            </Form.Item>
                            <Form.Item label='รับข่าวสาร' name='sendGetNews' rules={[
                                {
                                    required: true,
                                    message: 'Please input !',
                                },]}>
                                <Radio.Group
                                    defaultValue={editsocial.sendGetNews}
                                    onChange={(e) => setEditSocial({ ...editsocial, sendGetNews: e.target.value })} >
                                    <Radio value="1"> รับ </Radio>
                                    <Radio value="0"> ไม่รับ </Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item label="หมายเหตุ" name='note'>
                                <Input
                                    // defaultValue={editsocial.note}
                                    onChange={(e) => setEditSocial({ ...editsocial, note: e.target.value })} />
                            </Form.Item>

                            <Divider />
                        </Col>
                    </Row>
                </Form>
                {contextHolder}
            </Modal>
        </>
    )

};

export default EditSocial
