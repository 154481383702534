import React from 'react'
import { Routes, Route } from "react-router-dom"
import TabelLoanLocat from "./report_pn/modal/tabelLoanLocat";

export default function Main_report() {
    return (
        <Routes>
            <Route path="/" element={<TabelLoanLocat />} />
        </Routes>
    )
}
