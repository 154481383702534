import React, { useEffect, useState } from 'react'
import { Form, Card, Col, Row, Button, Space, Modal, Spin, Divider, Image, Input } from 'antd'
import dayjs from 'dayjs'
import { SaveFilled, EditOutlined } from '@ant-design/icons'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios'
//import Compressor from 'compressorjs';

import { useDispatch, useSelector } from 'react-redux'
import '../../css/Media.css'
import { addImage } from '../../../redux/Image';
import { addEmail } from '../../../redux/Email';
import { addSocial } from '../../../redux/Social';
import TextArea from 'antd/es/input/TextArea';
import { newallcreateland, getImagess, getAllFolderGuaImg } from '../../file_mid/all_api';

export default function DetailLand() {
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const customers = useSelector(state => state.customers)
    const lands = useSelector(state => state.lands)
    const loansLand = useSelector(state => state.loansLand)
    const Addresss = useSelector(state => state.Addresss)
    const Phones = useSelector(state => state.Phones)
    const oldLoansLand = useSelector(state => state.oldLoansLand)
    const imgs = useSelector(state => state.imgs)
    const guarantor = useSelector(state => state.guarantor)
    const careerIncome = useSelector(state => state.careerIncome)
    const occupations = useSelector(state => state.occupations)
    const Email = useSelector(state => state.Email)
    const Social = useSelector(state => state.Social)

    const addCustomers = localStorage.getItem('addCustomer')
    const addLands = localStorage.getItem('addLand')
    const addLoanLands = localStorage.getItem('addLoanLand')
    const addAddresss = localStorage.getItem('addAddress')
    const addPhones = localStorage.getItem('addPhone')
    const addOldLoanLands = localStorage.getItem('addOldLoanLand')
    const addGuarantors = localStorage.getItem('addGuarantor')
    const addOccupations = localStorage.getItem('addOccupation')
    const addCareerIncomes = localStorage.getItem('addCareerIncome')
    const addImages = localStorage.getItem('addImage')
  
    const dataAddCustomer = JSON.parse(addCustomers)
    const dataAddLands = JSON.parse(addLands)
    const dataAddLoanLands = JSON.parse(addLoanLands)
    const dataAddAddresss = JSON.parse(addAddresss)
    const dataAddPhones = JSON.parse(addPhones)
    const dataAddOldLoanLands = JSON.parse(addOldLoanLands)
    const dataAddGuarantors = JSON.parse(addGuarantors)
    const dataAddOccupations = JSON.parse(addOccupations)
    const dataAddCareerIncomes = JSON.parse(addCareerIncomes)
    const dataAddImages = JSON.parse(addImages)

    // const result1 = { ...customers.data, guarantors: guarantor.data, expenses: occupations.data.expenses, income: occupations.data.income, ojs: occupations.data.ojs, address: [{ ...Addresss.data }], phones: [{ ...Phones.data }], landLoanDetails: [{ ...loansLand.data, land: [{ ...lands.data }] }] }
    // const result2 = { ...customers.data, guarantors: guarantor.data, expenses: occupations.data.expenses, income: occupations.data.income, ojs: occupations.data.ojs, address: [{ ...Addresss.data }], phones: [{ ...Phones.data }], landLoanDetails: [{ ...loansLand.data, land: [{ ...lands.data, oldLandLoanDetails: oldLoansLand.data }] }] }
    // const result3 = { ...customers.data, guarantors: guarantor.data, expenses: occupations.data.expenses, income: occupations.data.income, ojs: occupations.data.ojs, phones: [{ ...Phones.data }], landLoanDetails: [{ ...loansLand.data, land: [{ ...lands.data }] }] }
    // const result4 = { ...customers.data, guarantors: guarantor.data, expenses: occupations.data.expenses, income: occupations.data.income, ojs: occupations.data.ojs, phones: [{ ...Phones.data }], landLoanDetails: [{ ...loansLand.data, land: [{ ...lands.data, oldLandLoanDetails: oldLoansLand.data }] }] }
    // const show = { ...customers.data, ...lands.data, ...loansLand.data, ...Addresss.data, ...Phones.data, ...oldLoansLand.data, ...guarantor.data, ...occupations.data }

    const result1 = { ...dataAddCustomer, guarantors: dataAddGuarantors, expenses: dataAddOccupations.expenses, income: dataAddOccupations.income, ojs: dataAddOccupations.ojs, address: [{ ...dataAddAddresss }], phones: [{ ...dataAddPhones }], landLoanDetails: [{ ...dataAddLoanLands, land: [{ ...dataAddLands }] }] }
    const result2 = { ...dataAddCustomer, guarantors: dataAddGuarantors, expenses: dataAddOccupations.expenses, income: dataAddOccupations.income, ojs: dataAddOccupations.ojs, address: [{ ...dataAddAddresss }], phones: [{ ...dataAddPhones }], landLoanDetails: [{ ...dataAddLoanLands, land: [{ ...dataAddLands, oldLandLoanDetails: dataAddOldLoanLands }] }] }
    const result3 = { ...dataAddCustomer, guarantors: dataAddGuarantors, expenses: dataAddOccupations.expenses, income: dataAddOccupations.income, ojs: dataAddOccupations.ojs, phones: [{ ...dataAddPhones }], landLoanDetails: [{ ...dataAddLoanLands, land: [{ ...dataAddLands }] }] }
    const result4 = { ...dataAddCustomer, guarantors: dataAddGuarantors, expenses: dataAddOccupations.expenses, income: dataAddOccupations.income, ojs: dataAddOccupations.ojs, phones: [{ ...dataAddPhones }], landLoanDetails: [{ ...dataAddLoanLands, land: [{ ...dataAddLands, oldLandLoanDetails: dataAddOldLoanLands }] }] }
    const show = { ...dataAddCustomer, ...dataAddLands, ...dataAddLoanLands, ...dataAddAddresss, ...dataAddPhones, ...dataAddOldLoanLands, ...dataAddGuarantors, ...dataAddOccupations }

    const [resultNO, setResultNO] = useState({}) // ข้อมูลที่จะส่งไปหลังบ้าน 
    const [resultO, setResultO] = useState({}) // ข้อมูลที่จะส่งไปหลังบ้าน มีโอโลน
    const [easy, setEasy] = useState({}) // โชว์ในหน้าเว็บ
    const [fileList, setFileList] = useState([]) // โชว์รูป
    const [guarantors1, setGuarantors] = useState([]) // โชว์คนค้ำ
    const [imagesomlong, setImageSomlong] = useState(Email?.data);
    //const [fileListCustomer, setFileListCustomer] = useState([]);
    const [dataPost, setDataPost] = useState({ offercaseNote: "" })
    const token = localStorage.getItem('token')
    const menu = localStorage.getItem('menu')
    const [getimages, setGetImages] = useState([]);

    useEffect(() => {
        form.setFieldsValue(
            {
                storyLand: dataAddLands.storyLand ? dataAddLands.storyLand : '',
            }
        )
        if (Email?.data) {
            setImageSomlong(Email?.data)
        }
        setEasy(show)
        if (dataAddGuarantors) {
            getImg()
            setGuarantors(dataAddGuarantors)
        }
        if (dataAddGuarantors === 0 || dataAddGuarantors === '') {
            delete result1.guarantors
            delete result2.guarantors
            delete result3.guarantors
            delete result4.guarantors
        }
        // if (imgs.data) {
        //     setFileList(imgs.data)
        //     //resultImg()
        // }
        // if (imgs?.data && Social?.data) {
        //     //resultImg()
        //     setFileList([...imgs?.data, ...Social?.data])
        // }
        if (dataAddImages) {
            setFileList(dataAddImages)
          }
        //console.log("address", Addresss.data)
        if (dataAddAddresss !== undefined || dataAddAddresss !== null) {
            setResultNO(result1)
            setResultO(result2)
        }
        else {
            setResultNO(result3)
            setResultO(result4)
        }
    }, [])

    const getImg = async () => {
        const path = `one_images/land/${dataAddCustomer?.preImg}/5`
        // console.log("path-customer", path)
        setLoading(true)
        await axios.get(`${getAllFolderGuaImg}/${path}?token=${token}`)
            .then(res => {
                if (res.data) {
                    // console.log("res.data", res.data)
                    setGetImages(res.data)
                    setLoading(false)
                }
            })
            .catch((err) => {
                console.log("err", err)
                setLoading(false)
            })
        setLoading(false)
    }

    const currencyFormatOne = (amount) => {
        return Number(amount).toFixed(0).replace(/\d(?=(\d{3})+(?!\d))/g, '$&,')
    }
    const currencyFormat = (amount) => {
        return Number(amount)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }

    const success = () => {
        Modal.success({
            content: 'บันทึกสำเร็จ',
            path: navigate('/checkInfoLand')
        })
    }
    const error = () => {
        Modal.error({
            title: 'บันทึกไม่สำเร็จ',
            content: 'กรุณาลองใหม่อีกครั้ง',
        })
    }
    const unauthorized = () => {
        Modal.error({
            title: 'unauthorized',
            content: 'คุณไม่มีสิทธิ์ใช้งานเมนูนี้',
        })
    }
    const handleGoBlack = () => {
        navigate('/offercase/addCareerincome')
        //dispatch(addImage(imagesomlong))
    }

    const backInsertcustomer = (e) => {
        Modal.success({
            title: 'ข้อมูลลูกค้าไม่ครบถ้วน',
            // content: `กรุณาตรวจกรอกข้อมูล`,
            onOk: () => {
                navigate('/offercase/addcustomer')
                setLoading(false)
            }
        })
    }
    const backInsertloanland = (e) => {
        Modal.success({
            title: 'ข้อมูลขอกู้ไม่ครบถ้วน',
            // content: `กรุณาตรวจกรอกข้อมูล`,
            onOk: () => {
                navigate('/offercase/addDetail')
                setLoading(false)
            }
        })
    }
    const backInsertland = (e) => {
        Modal.success({
            title: 'ข้อมูลที่ดินไม่ครบถ้วน',
            // content: `กรุณาตรวจกรอกข้อมูล`,
            onOk: () => {
                navigate('/offercase/addDetail')
                setLoading(false)
            }
        })
    }

    const handleSubmit = async (e) => {
        setLoading(true)
        if (dataAddCustomer?.identificationId === "" || dataAddCustomer?.firstname === "" || dataAddCustomer?.lastname === "") {
            backInsertcustomer()
        }
        else if (dataAddLoanLands?.productLoanLandType === "" || dataAddLoanLands?.loanAmounttLand === "" || dataAddLoanLands?.loanAmounttLand === 0 || dataAddLoanLands?.loanAmounttLand < 50000) {
            backInsertloanland()
        }
        else if (dataAddLands?.productTypeLand === "" || dataAddLands?.province === "" || dataAddLands?.district === "") {
            backInsertland()
        }
        else if ((dataAddLoanLands?.productLoanType === "ย้ายไฟแนนซ์" || dataAddLoanLands?.productLoanType === "Pre-Aaprove-ย้ายไฟแนนซ์") && (dataAddOldLoanLands?.issuno === "" || dataAddOldLoanLands?.oldLandLoanAmount === "")) {
            backInsertloanland()
        }
        else {
            dispatch(addEmail())
            dispatch(addSocial())
            if (Object.keys(dataAddAddresss).length === 0) {
                delete resultNO.address
                delete resultO.address
            }
            if (JSON.stringify(dataAddAddresss) === '{}' || JSON.stringify(resultNO.address[0]) === '{}' || JSON.stringify(resultO.address[0]) === '{}') {
                delete resultNO.address
                delete resultO.address
            }

            if (dataAddOldLoanLands) {
                console.log("ที่ดิน ข้อมูลทั้งหมด มีโอโลน")
                const resultOnew = { ...resultO, offercaseNote: dataPost?.offercaseNote.trim() }
                await axios.post(newallcreateland, resultOnew) /*อัพรูปใหม่*/
                    .then(res => {
                        if (res.status === 200) {
                            localStorage.removeItem('addCustomer');
                            localStorage.removeItem('addAddress');
                            localStorage.removeItem('addPhone');
                            localStorage.removeItem('addLand');
                            localStorage.removeItem('addGuarantor');
                            localStorage.removeItem('addCareerIncome');
                            localStorage.removeItem('addLoanLand');
                            localStorage.removeItem('addOldLoanLand');
                            localStorage.removeItem('addOccupation');
                            localStorage.removeItem('addEmail');
                            localStorage.removeItem('addSocial');
                            localStorage.removeItem('addImage');
                            //console.log("result2", result2)
                            success()
                            setLoading(false)
                            // navigate('/checkInfoLand')
                        }
                    })
                    .catch((err) => {
                        if (err.response.request.status === 401) {
                            unauthorized()
                        }
                        else {
                            error()
                        }
                        console.log("err", err)
                        setLoading(false)
                    })
                setLoading(false)
            }
            else {
                console.log("ที่ดิน ข้อมูลทั้งหมด ไม่มีโอโลน")
                const resultNonew = { ...resultNO, offercaseNote: dataPost?.offercaseNote.trim() }
                //console.log("resultNonew", resultNonew)
                await axios.post(newallcreateland, resultNonew) /*อัพรูปใหม่*/
                    .then(res => {
                        if (res.status === 200) {
                            localStorage.removeItem('addCustomer');
                            localStorage.removeItem('addAddress');
                            localStorage.removeItem('addPhone');
                            localStorage.removeItem('addLand');
                            localStorage.removeItem('addGuarantor');
                            localStorage.removeItem('addCareerIncome');
                            localStorage.removeItem('addLoanLand');
                            localStorage.removeItem('addOldLoanLand');
                            localStorage.removeItem('addOccupation');
                            localStorage.removeItem('addEmail');
                            localStorage.removeItem('addSocial');
                            localStorage.removeItem('addImage');
                            //console.log("result1", result1)
                            success()
                            setLoading(false)
                            // navigate('/checkInfoLand')
                        }
                    })
                    .catch((err) => {
                        if (err.response.request.status === 401) {
                            unauthorized()
                        }
                        else {
                            error()
                        }
                        console.log("err", err)
                        setLoading(false)
                    })
                setLoading(false)
            }
        }
        setLoading(false)
    }

    function createImageUrl(imageData) {
        return imageData.image64;
    }
    //////////// โชว์ชื่อไฟล์ PDF ////////////////
    function createPDFUrl(imageData) {
        return imageData.pdf;
    }
    // Function to load and render the PDF
    const renderGuarantor = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
        var itemSplit = []
        if (item?.pathImg) {
            itemSplit = item.pathImg.split("/")
        }
        return (
            <>
                <Form
                    name='basic'
                    labelCol={{
                        span: 12,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    style={{
                        maxWidth: '100%',
                    }}
                    autoComplete="off"
                    initialValues={{ remember: true }}
                >
                    <Card style={{ width: '300px' }}>
                        <b><u>
                            <div style={{ marginBottom: 0, textAlign: 'left' }}
                            >
                                <span>ลำดับที่ : </span>
                                {/* {item.garno} */}
                                {index + 1}
                            </div>
                        </u></b>
                        <div style={{ marginBottom: 0, textAlign: 'left' }}
                        >
                            <span>ชื่อ-นามสกุล : </span>
                            <b>{item.snam + ' ' + item.firstname + ' ' + item.lastname}</b>
                        </div>
                        <p />
                        <n></n>
                        {/* <div
                            style={{ marginBottom: 0, textAlign: 'left' }}
                        >
                            <span>วัน-เดือน-ปีเกิด : </span>
                            <b>{item.birthdate}</b>
                        </div>

                        <div
                            style={{ marginBottom: 0, textAlign: 'left' }}
                        >
                            <span>เลขบัตรประชาชน : </span>
                            <b>{item.identificationId}</b>
                        </div> */}
                        <div
                            style={{ marginBottom: 0, textAlign: 'left' }}
                        >
                            <span>ชื่อเล่น : </span>
                            <b>{item.nickname}</b>
                        </div>

                        <div
                            style={{ marginBottom: 0, textAlign: 'left' }}
                        >
                            <span>เบอร์โทร : </span>
                            <b>{item?.phones[index]?.telp}</b>
                        </div>
                        {itemSplit?.length > 0 ? (
                            <>
                                <Row justify={"center"}>
                                    {getimages?.map((items, index) => {
                                        const segments = items.pathImage.split("/")
                                        if (itemSplit[4] === segments[4]) {
                                            return (
                                                <Image width={'100px'} key={index} src={`data:${items?.Attr};base64,${items?.data?.toString('base64')}`} alt={`Image ${index + 1}`} />
                                            )
                                        }
                                    }
                                    )}
                                </Row>
                            </>
                        ) : null
                        }

                    </Card>
                </Form>
            </>
        )
    }

    return (
        <Row justify={'center'} gutter={32}>
            <Card style={{ width: '1000px' }}>
                <Spin spinning={loading} size='large' tip=" Loading... ">
                    <div className='text-center'>
                        <h2>รายละเอียด</h2>
                    </div>
                    <Divider style={{ margin: 5 }} />
                    <Form
                        style={{
                            width: '100%',
                        }}
                        form={form}
                        onFinish={handleSubmit}
                        autoComplete="off"
                        initialValues={{ remember: true }}
                    >
                        <div className='main'>
                            <div id="pdfContent"></div>

                            <Row gutter={32} justify={'center'}>
                                <aside style={{ width: '90%' }}>
                                    {easy?.nationalId === "นิติบุคคล" ?
                                        <>
                                            <div className='text-left' style={{ margin: 0 }}>
                                                <h4><u>รายละเอียดผู้กู้</u></h4>
                                            </div><p />
                                            <n />
                                            <div label='ประเภท' name='identificationId' style={{ marginBottom: 3 }}>
                                                <span>ประเภท : </span> <b>{easy?.nationalId}</b>
                                            </div>
                                            <div label='เลขประจำตัวผู้เสียภาษี' name='identificationId' style={{ marginBottom: 3 }}>
                                                <span>เลขประจำตัวผู้เสียภาษี : </span> <b>{easy.identificationId}</b>
                                            </div>
                                            <div label='ชื่อบริษัท' name='snam' style={{ marginBottom: 3 }}>
                                                <span>
                                                    {easy?.snam} : </span> <b>{easy.firstname + ' ' + easy?.lastname}</b>
                                            </div>
                                            <div label='วันจดทะเบียน' name='nickname' style={{ marginBottom: 3 }}>
                                                <span>วันจดทะเบียน : </span> <b> {easy?.birthdate}</b>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <Row className='text-left' style={{ marginTop: '20px' }}>
                                                <b><u>รายละเอียดผู้กู้</u></b>
                                            </Row><p />
                                            <n />
                                            <div label='เลขประจำตัวประชาชน' name='identificationId'>
                                                <span>เลขประจำตัวประชาชน : </span><b>{easy.identificationId}</b>
                                            </div>
                                            <div label='ชื่อ - นามสกุล' name='snam'>
                                                <span>ชื่อ - นามสกุล : </span><b>{easy.snam + ' ' + easy.firstname + ' ' + easy.lastname}</b>
                                            </div>

                                            <div label='เกิดวันที่' name='birthdate'>
                                                <span>เกิดวันที่ : </span><b>{dayjs(easy.birthdate).format("DD-MM-YYYY")}</b>
                                            </div>

                                            <div label='เพศ' name='gender'>
                                                <span>เพศ : </span><b>{easy.gender}</b>
                                            </div>

                                            <div label='เบอร์โทรติดต่อ' name='telp'>
                                                <span>เบอร์โทรติดต่อ : </span><b>{easy.telp}</b>
                                            </div>

                                            <div label='สถานที่ออกบัตรประชาชน' name='issuby'>
                                                <span>สถานที่ออกบัตรประชาชน : </span><b>{easy.issuby}</b>
                                            </div>

                                            <div label='วันออกบัตร' name='issudt'>
                                                <span>วันออกบัตร : </span><b>{dayjs(easy.issudt).format("DD-MM-YYYY")}</b>
                                            </div>

                                            <div label='วันบัตรหมดอายุ' name='expdt'>
                                                <span>วันบัตรหมดอายุ : </span><b>{dayjs(easy.expdt).format("DD-MM-YYYY")}</b>
                                            </div>
                                        </>
                                    }
                                </aside>
                            </Row>
                            <Divider style={{ margin: 5 }} />
                            <Row gutter={32} justify={'center'}>
                                <aside style={{ width: '90%' }}>
                                    <Row className='text-left' style={{ marginTop: '20px' }}>
                                        <b><u>ประเภทสินค้า</u></b>
                                    </Row><p />
                                    <n />
                                    <div label='กลุ่มสินค้า' name='productTypeId'>
                                        {/* {easy.gcode} */}
                                        <span>กลุ่มสินค้า : </span>
                                        <b>
                                            {
                                                easy.productTypeId === 1 ? <>รถ</> : <>ที่ดิน</>
                                            }
                                        </b>

                                    </div>

                                    <div label='ประเภทเอกสาร' name='landTypeId'>
                                        <span>ประเภทเอกสาร : </span>
                                        <b>
                                            {
                                                easy.landTypeId === 1 ? <>นส.3</> : <>นส.4</>
                                            }
                                        </b>
                                    </div>

                                    <div label='เลขโฉนดที่ดิน' name='numberLand'>
                                        <span>เลขโฉนดที่ดิน : </span><b>{easy.numberLand}</b>
                                    </div>

                                    <div label='เลขที่ดินหรือระวาง' name='numberLandLawang' >
                                        <span>เลขที่ดินหรือระวาง : </span><b>{easy.numberLandLawang}</b>
                                    </div>

                                    <div label='จังหวัด' name='province' >
                                        <span>จังหวัด : </span><b>{dataAddLands?.province}</b>
                                    </div>

                                    <div label='อำเภอ' name='district'>
                                        <span>อำเภอ : </span><b>{dataAddLands?.district}</b>
                                    </div>

                                    {/* <div label='ตำบล' name='subdistrict'>
                                    <span>เลขประจำตัวประชาชน : </span><b>{easy.subdistrict}</b>
                                </div> */}

                                    <div label='พื้นที่ไร่' name='rai'>
                                        <span>พื้นที่ไร่ : </span><b>{isNaN(easy.rai) ? "-" : currencyFormatOne(easy.rai) + " ไร่"}</b>
                                    </div>
                                    <div label='พื้นที่งาน' name='workArea'>
                                        <span>พื้นที่งาน : </span><b>{isNaN(easy.workArea) ? "-" : currencyFormatOne(easy.workArea) + " งาน"}</b>
                                    </div>
                                    <div label='พื้นที่ตารางวา' name='squareWaArea'>
                                        <span>พื้นที่ตารางวา : </span><b>{isNaN(easy.squareWaArea) ? "-" : currencyFormat(easy.squareWaArea) + " ตารางวา"}</b>
                                    </div>
                                    <div label='ราคาประเมินที่ดิน' name='landPrice'>
                                        <span>ราคาประเมินที่ดิน : </span><b>{isNaN(easy.landPrice) ? "-" : currencyFormat(easy.landPrice) + " บาท / ตารางวา"}</b>
                                    </div>
                                    <div label='ราคารวม' name='resultLandPrice'>
                                        <span>ราคารวม : </span><b>{isNaN(easy.resultLandPrice) ? "-" : currencyFormat(easy.resultLandPrice) + " บาท"}</b>
                                    </div>
                                </aside>
                            </Row>

                            <Row justify={'center'} style={{ marginTop: '15px' }}>
                                <Col span={22}>
                                    <span><b style={{ fontSize: '25' }}>สตอรี่รายละเอียดที่ดิน</b></span> <p />
                                    <Form.Item name="storyLand" label="">
                                        <TextArea
                                            name='storyLand'
                                            style={{ color: 'blue' }}
                                            rows={8}
                                            disabled
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>


                            <Divider style={{ margin: 5 }} />

                            {easy.productLoanLandType === "ย้ายไฟแนนซ์" || easy.productLoanLandType === "Pre-Aaprove-ย้ายไฟแนนซ์" ?
                                <>
                                    <Row justify={'center'} gutter={32}>
                                        <aside style={{ width: '90%' }}>
                                            <Row className='text-left' style={{ marginTop: '20px' }}>
                                                <b><u>รายละเอียดขอกู้ย้ายไฟแนนซ์</u></b>
                                            </Row><p />
                                            <n />
                                            <div label='ประเภทขอกู้' name='productLoanLandType'>
                                                <span>ประเภทขอกู้ : </span><b>{easy.productLoanLandType}</b>
                                            </div>

                                            <div label='ยอดจัด' name='loanAmounttLand'>
                                                <span>ยอดจัด : </span><b>{isNaN(easy.loanAmounttLand) ? "-" : currencyFormat(easy.loanAmounttLand) + " บาท"}</b>
                                            </div>

                                            <div label='อัตราดอกเบี้ย' name='interestRateLand'>
                                                <span>อัตราดอกเบี้ย : </span><b>{isNaN(easy.interestRateLand) ? "-" : currencyFormat(easy.interestRateLand) + " % +VAT"}</b>
                                            </div>

                                            <div label='จำนวนงวด' name='loanLandTerm'>
                                                <span>จำนวนงวด : </span><b>{isNaN(easy.loanLandTerm) ? "-" : currencyFormat(easy.loanLandTerm) + " งวด"}</b>
                                            </div>

                                            <div label='ดอกเบี้ย' name='interesttLand'>
                                                <span>ดอกเบี้ย : </span><b>{isNaN(easy.interesttLand) ? "-" : currencyFormat(easy.interesttLand) + " บาท"}</b>
                                            </div>

                                            <div label='งวดละ' name='monthlyPaymentLand'>
                                                <span>งวดละ : </span><b>{isNaN(easy.loanAmounttLand) ? "-" : currencyFormat(easy.monthlyPaymentLand) + " บาท"}</b>
                                            </div>

                                            <div label='ราคารวม' name='installmentWithInterestLand'>
                                                <span>ราคารวม : </span><b>{isNaN(easy.installmentWithInterestLand) ? "-" : currencyFormat(easy.installmentWithInterestLand) + " บาท"}</b>
                                            </div>
                                        </aside>
                                    </Row>
                                    <Divider style={{ margin: 5 }} />
                                    <Row justify={'center'} gutter={32}>
                                        <aside style={{ width: '90%' }}>
                                            <Row className='text-left' style={{ marginTop: '20px' }}>
                                                <b><u>รายละเอียดย้ายไฟแนนซ์</u></b>
                                            </Row><p />
                                            <n />
                                            <div label='ปิดไฟแนนซ์จาก' name='issuno' style={{ marginBottom: 3 }}>
                                                <span>ปิดไฟแนนซ์จาก : </span><b>{easy.issuno}</b>
                                            </div>
                                            <div label='ยอดปิดไฟแนนซ์' name='oldLandLoanAmount' style={{ marginBottom: 3 }}>
                                                <span>ยอดปิดไฟแนนซ์ : </span><b>{isNaN(easy.oldLandLoanAmount) ? "-" : currencyFormatOne(easy.oldLandLoanAmount) + " บาท"}</b>
                                            </div>

                                            <div label='ค่างวด/เดือน' name='oldLandMonthlyPayment' style={{ marginBottom: 3 }}>
                                                <span>ค่างวด/เดือน : </span><b>{isNaN(easy.oldLandMonthlyPayment) ? "-" : currencyFormatOne(easy.oldLandMonthlyPayment) + " บาท"}</b>
                                            </div>

                                            <div label='จำนวนผ่อนกี่งวด' name='oldLandLoanTerm' style={{ marginBottom: 3 }}>
                                                <span>จำนวนผ่อนกี่งวด : </span><b>{isNaN(easy.oldLandLoanTerm) ? "-" : easy.oldLandLoanTerm + " งวด"}</b>
                                            </div>

                                        </aside>
                                    </Row>

                                </>
                                :
                                <>
                                    <Row justify={'center'} gutter={32}>
                                        <aside style={{ width: '90%' }}>
                                            <Row className='text-left' style={{ marginTop: '20px' }}>
                                                <b><u>รายละเอียดยอดกู้</u></b>
                                            </Row><p />
                                            <n />
                                            <div label='ประเภทขอกู้' name='productLoanLandType'>
                                                <span>ประเภทขอกู้ : </span><b>{easy.productLoanLandType}</b>
                                            </div>

                                            <div label='ยอดจัด' name='loanAmounttLand'>
                                                <span>ยอดจัด : </span><b>{isNaN(easy.loanAmounttLand) ? "-" : currencyFormat(easy.loanAmounttLand) + " บาท"}</b>
                                            </div>

                                            <div label='อัตราดอกเบี้ย' name='interestRateLand'>
                                                <span>อัตราดอกเบี้ย : </span><b>{isNaN(easy.interestRateLand) ? "-" : currencyFormat(easy.interestRateLand) + " % +VAT"}</b>
                                            </div>

                                            <div label='จำนวนงวด' name='loanLandTerm'>
                                                <span>จำนวนงวด : </span><b>{isNaN(easy.loanLandTerm) ? "-" : currencyFormat(easy.loanLandTerm) + " งวด"}</b>
                                            </div>

                                            <div label='ดอกเบี้ย' name='interesttLand'>
                                                <span>ดอกเบี้ย : </span><b>{isNaN(easy.interesttLand) ? "-" : currencyFormat(easy.interesttLand) + " บาท"}</b>
                                            </div>

                                            <div label='งวดละ' name='monthlyPaymentLand'>
                                                <span>งวดละ : </span><b>{isNaN(easy.loanAmounttLand) ? "-" : currencyFormat(easy.monthlyPaymentLand) + " บาท"}</b>
                                            </div>

                                            <div label='ราคารวม' name='installmentWithInterestLand'>
                                                <span>ราคารวม : </span><b>{isNaN(easy.installmentWithInterestLand) ? "-" : currencyFormat(easy.installmentWithInterestLand) + " บาท"}</b>
                                            </div>
                                        </aside>
                                    </Row>
                                </>
                            }

                            {dataAddOccupations ?
                                <>
                                    <Divider style={{ margin: 5 }} />
                                    <Row justify={'center'} gutter={32}>
                                        <aside style={{ width: '90%' }}>
                                            <div className='text-left' >
                                                <h4><u>อาชีพ - รายได้</u></h4>
                                            </div><p />
                                            <n />
                                            <div className='text-left'>
                                                {/* <span>ยอดปิดไฟแนนซ์ : </span><b>{isNaN(easy.oldLoanAmount) ? "-" : currencyFormatOne(easy.oldLoanAmount) + " บาท"}</b> */}
                                                <span>กลุ่มอาชีพ : </span><b>{easy?.ojs?.occupationalGroupTH}</b>
                                            </div>
                                            <div className='text-left'>
                                                <span>อาชีพ : </span><b>{easy?.ojs?.governmentPrivateTH}</b>
                                            </div>
                                            <div className='text-left'>
                                                <span>สาขาอาชีพ : </span><b>{easy?.ojs?.jobPositionTH}</b>
                                            </div>
                                            <div className='text-left'>
                                                <span>ชื่อบริษัท(สถานที่ทำงาน) : </span><b>{easy?.ojs?.companyName}</b>
                                            </div>
                                            <div className='text-left'>
                                                <span>รายได้ / เดือน : </span><b>{currencyFormatOne(easy?.income?.incomeMonth)}</b>
                                            </div>
                                            <div className='text-left'>
                                                <span>รายจ่าย / เดือน : </span><b>{currencyFormatOne(easy?.expenses?.expensesMonth)}</b>
                                            </div>
                                        </aside>
                                    </Row>

                                </>
                                :
                                null
                            }

                            {guarantors1?.length >= 1 ?
                                <>
                                    <Divider style={{ margin: 5 }} />
                                    <Card>
                                        <Row justify={'center'}>
                                            <b><u>รายละเอียดคนค้ำ</u></b>
                                        </Row>
                                        <Row justify={'center'}>
                                            {
                                                guarantors1?.map((item, index) => {
                                                    return renderGuarantor({ item, index, key: `{item.identificationId} - ${index}` })
                                                })
                                            }
                                        </Row>
                                    </Card>
                                    <Divider style={{ margin: 5 }} />
                                </>
                                :
                                null

                            }

                            {fileList?.map((imageData, index) => (
                                <>
                                    <div className='main'>
                                        <div>
                                            {fileList[index].type === "4" ?
                                                <>
                                                    <Row justify={'center'}><span><b>รูปบัตรประชาชนผู้ขอกู้ :</b></span></Row>
                                                    <Row justify={'center'}>
                                                        {/* <img width={'350px'} style={{ margin: 5 }} src={createImageUrl(imageData)} alt={imageData.name} /> */}
                                                        <Image width={'300px'} key={index} src={`${getImagess}/${imageData.image}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                    </Row>
                                                </>
                                                : null
                                            }

                                            {fileList[index].type === 5 ?
                                                <>
                                                    <Row justify={'center'} style={{ marginLeft: 5 }}><span><b>รูปบัตรประชาชนคนค้ำประกัน :</b></span></Row>
                                                    <Row justify={'center'}>
                                                        <img width={'200px'} style={{ margin: 5 }} src={createImageUrl(imageData)} alt={imageData.name} />
                                                    </Row>
                                                </>
                                                : null
                                            }
                                            {fileList[index].type === "6" ?
                                                <>
                                                    <Row justify={'center'}><span><b>ประวัติผู้กู้ / ทะเบียนบ้าน / อาชีพ :</b></span></Row>
                                                    <Row justify={'center'}>
                                                        {/* <img width={'350px'} src={createImageUrl(imageData)} alt={imageData.name} /> */}
                                                        <Image width={'300px'} key={index} src={`${getImagess}/${imageData.image}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                    </Row>
                                                </>
                                                : null
                                            }

                                            {dataAddLands ?
                                                <>
                                                    {fileList[index].type === "1" ?
                                                        <>
                                                            <Row justify={'center'}><span><b>รูปที่ดิน/สิ่งปลูกสร้าง-ที่ดินปัจจุบัน :</b></span></Row>
                                                            <Row justify={'center'}>
                                                                {/* <img width={'350px'} src={createImageUrl(imageData)} alt={imageData.name} /> */}
                                                                <Image width={'300px'} key={index} src={`${getImagess}/${imageData.image}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                            </Row>
                                                        </>
                                                        : null
                                                    }
                                                    {fileList[index].type === "3" ?
                                                        <>
                                                            <Row justify={'center'}><span><b>รูปโฉนด/หน้า-หลัง :</b></span></Row>
                                                            <Row justify={'center'}>
                                                                {/* <img width={'350px'} src={createImageUrl(imageData)} alt={imageData.name} /> */}
                                                                <Image width={'300px'} key={index} src={`${getImagess}/${imageData.image}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                            </Row>
                                                        </>
                                                        : null
                                                    }
                                                    {fileList[index].type === "10" ?
                                                        <>
                                                            <Row justify={'center'}><span><b>ใบแบ่งใบต่อ :</b></span></Row>
                                                            <Row justify={'center'}>
                                                                {/* <img width={'350px'} src={createImageUrl(imageData)} alt={imageData.name} /> */}
                                                                <Image width={'300px'} key={index} src={`${getImagess}/${imageData.image}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                            </Row>
                                                        </>
                                                        : null
                                                    }
                                                    {fileList[index].type === "11" ?
                                                        <>
                                                            <Row justify={'center'}><span><b>ระวาง :</b></span></Row>
                                                            <Row justify={'center'}>
                                                                {/* <img width={'350px'} src={createImageUrl(imageData)} alt={imageData.name} /> */}
                                                                <Image width={'300px'} key={index} src={`${getImagess}/${imageData.image}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                            </Row>
                                                        </>
                                                        : null
                                                    }
                                                </>
                                                : null
                                            }
                                            {fileList[index].type === "8" ?
                                                <>
                                                    <Row justify={'center'} style={{ marginLeft: 5 }}><span><b>รูปสเตทเมนต์ผู้กู้ :</b></span></Row>
                                                    <Row justify={'center'}>
                                                        {/* <img width={'200px'} style={{ marginLeft: 5 }} src={createImageUrl(imageData)} alt={imageData.name} /> */}
                                                        <Image width={'300px'} key={index} src={`${getImagess}/${imageData.image}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                    </Row>
                                                </>
                                                : null
                                            }
                                            {fileList[index].type === "7" ?
                                                <>
                                                    <Row justify={'center'} style={{ marginLeft: 5 }}><span><b>รูปสเตทเมนต์ผู้กู้ PDF  :</b></span></Row>
                                                    <Row justify={'center'}>
                                                        {/* <img width={'200px'} style={{ marginLeft: 5 }} src={createPDFUrl(imageData.image64)} alt={imageData.filename} /> */}
                                                        <object data={`${getImagess}/${imageData.image}?token=${token}`} type="application/pdf" width="350px" height="450px">
                                                            <p>Alternative text - include a link <a href={`${getImagess}/${imageData.image}?token=${token}`}>to the PDF!</a></p>
                                                        </object>
                                                        <p></p>
                                                    </Row>
                                                </>
                                                : null
                                            }
                                        </div>
                                        {fileList[index].type === "9" ?
                                            <>
                                                <Row justify={'center'}><span><b>ประวัติคนค้ำ / ทะเบียนบ้าน / อาชีพ :</b></span></Row>
                                                <Row justify={'center'}>
                                                    {/* <img width={'350px'} src={createImageUrl(imageData)} alt={imageData.name} /> */}
                                                    <Image width={'300px'} key={index} src={`${getImagess}/${imageData.image}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                </Row>
                                            </>
                                            : null
                                        }

                                    </div>
                                </>
                            ))}
                        </div>
                        <Row justify={'center'}>
                            <Col span={22}>
                                <Form.Item name="offercaseNote" label="">

                                    <span><b style={{ fontSize: '25' }}>หมายเหตุ</b></span> <p />
                                    <span><u style={{ fontSize: '20', color: 'red' }}>กรณีที่เอกสารส่วนไหนไม่ครบหรือไม่มี ให้พิมพ์แจ้งตรงนี้ได้เลย</u></span>
                                    <TextArea
                                        name='offercaseNote'
                                        style={{ color: 'blue' }}
                                        rows={8}
                                        onChange={(e) => setDataPost({ offercaseNote: e.target.value })}
                                    >
                                        {/* <b style={{ color: 'blue' }}>{dataModalCars?.cars?.carMemo}</b> */}
                                    </TextArea>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider />

                        <Row gutter={32}>
                            <Col className="gutter-row" span={24} style={{ textAlign: 'center' }}>
                                <Space>
                                    <Button type="text" onClick={handleGoBlack} style={{ background: "#bfbfbf", color: '#000000' }} >
                                        ย้อนกลับ
                                    </Button>
                                    {

                                        loading === false ?
                                            <>
                                                <Button type="text" htmlType="submit" style={{ background: '#389e0d', color: '#ffffff' }} >
                                                    บันทึก
                                                </Button>
                                            </> :
                                            <>
                                                <Button type='primary' htmlType="submit" disabled
                                                    style={{ color: 'success' }}
                                                >
                                                    บันทึก
                                                </Button>
                                            </>
                                    }
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Card>
        </Row >
    )
};
